import React, {useEffect, useState} from 'react';
import { Modal } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { Arrays } from '../../utils/Arrays';
import { AlertError } from '../bootstrap/AlertError';
import { TextInputField } from '../bootstrap/TextInputField';

export function EconomicViabilitySubmissionModal({
	show,
	handleClose,
	errors,
	submitAll,
}) {
	const [modalError, setModalError] = useState(null);
	const [user, setUser] = useState();
	const [password, setPassword] = useState();

	function handleCloseModal() {
		setUser('');
		setPassword('');
		handleClose();
	}



	return (
		<Modal show={show} onHide={handleCloseModal}>
			<Modal.Header closeButton>
				<Modal.Title>
					<FormattedMessage id='economicViabilityForm.submissionModal.title' />
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<AlertError error={modalError} />

				{Arrays.isNotEmpty(errors) ? (
					<FormattedMessage id='candidatureForm.submissionModal.errors.info' />
				) : (
					<div>
						<FormattedMessage id='economicViabilityForm.submissionModal.body' />
						<TextInputField
							controlId={'user'}
							labelId={'messages.emailText'}
							name={'user'}
							handleChange={(e) => setUser(e.target.value)}
						/>
						<TextInputField
							controlId={'password'}
							labelId={'all.password'}
							name={'password'}
							handleChange={(e) => setPassword(e.target.value)}
							isPassword={true}
						/>
					</div>
				)}

				<div className='form-actions mt-5'>
					<button
						className='btn btn-link'
						type='button'
						onClick={() => handleCloseModal()}
					>
						<FormattedMessage id='all.back' />
					</button>
					{Arrays.isEmpty(errors) && (
						<button
							className='btn btn-primary'
							type='button'
							onClick={() => submitAll(user, password, setModalError)}
						>
							<FormattedMessage id='all.submitButtonText' />
						</button>
					)}
				</div>
			</Modal.Body>
		</Modal>
	);
}
