import React from 'react';
import { Table } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { InvestmentType } from '../../models/InvestmentType';
import { EconomicViabilityUtils } from '../../utils/EconomicViabilityUtils';
import DecimalInputField from '../bootstrap/DecimalInputField';
import { TextAreaInputField } from '../bootstrap/TextAreaInputField';
import ToolTip from '../bootstrap/ToolTip';

export function EconomicViabilityFormStep3({
  handleChange,
  setFieldValue,
  errors,
  values,
  error,
  setError,
  numberOfSteps,
  handleSave,
  handleGoBack
}) {
  const intl = useIntl();

  const KEYS = [
    'investmentProperties',
    'investmentPropertiesNaturalResources',
    'investmentPropertiesBuildings',
    'investmentPropertiesOthers',
    'tangibleAssets',
    'tangibleNaturalResources',
    'tangibleBuildings',
    'tangibleBasicEquipment',
    'tangibleTransportEquipment',
    'tangibleAdministrativeEquipment',
    'tangibleBiologicEquipment',
    'tangibleOthers',
    'intangibleAssets',
    'intangibleGoodwill',
    'intangibleDevelopmentProjects',
    'intangibleComputerPrograms',
    'intangibleIntellectualProperty',
    'intangibleOthers',
    'several',
    'pettyCash',
    'globalTotal'
  ];

  const BOLD_KEYS = [
    'investmentProperties',
    'tangibleAssets',
    'intangibleAssets',
    'several',
    'pettyCash',
    'globalTotal'
  ];

  function calculateInvestmentDetailTotals() {
    values.investmentPlanDetails.forEach((ipd) => {
      EconomicViabilityUtils.calculateInvestmentPlanDetailTotals(ipd);
    });
  }

  function getValueByInvestmentTypeAndFieldName(investmentType, fieldName) {
    return values.investmentPlanDetails.filter((ipd) => ipd.investmentType === investmentType)[0][
      fieldName
    ];
  }

  function handleInvestmentChange(e, investmentType, fieldName) {
    values.investmentPlanDetails.filter((ipd) => ipd.investmentType === investmentType)[0][
      fieldName
    ] = e.target.value;

    calculateInvestmentDetailTotals();

    setFieldValue('investmentPlanDetails', [...values.investmentPlanDetails]);
  }

  function getTableRowsByKey(key) {
    return (
      <tr>
        <td className={`w-50 ${BOLD_KEYS.includes(key) && 'table-item-bold'}`}>
          <FormattedMessage id={`economicViabilityFormStep3.${key}`} />
        </td>
        <td>
          <DecimalInputField
            controlId={`investmentPlanDetails.${key}`}
            name={`investmentPlanDetails.${key}`}
            handleChange={(e) => handleInvestmentChange(e, InvestmentType.TOTAL, key)}
            value={getValueByInvestmentTypeAndFieldName(InvestmentType.TOTAL, key)}
            suffixLabel='€'
          />
        </td>
        <td>
          <DecimalInputField
            controlId={`investmentPlanDetails.${key}`}
            name={`investmentPlanDetails.${key}`}
            handleChange={(e) => handleInvestmentChange(e, InvestmentType.TOTAL_REPROGRAMMED, key)}
            value={getValueByInvestmentTypeAndFieldName(InvestmentType.TOTAL_REPROGRAMMED, key)}
            suffixLabel='€'
          />
        </td>
        <td>
          <DecimalInputField
            controlId={`investmentPlanDetails.${key}`}
            name={`investmentPlanDetails.${key}`}
            handleChange={(e) => handleInvestmentChange(e, InvestmentType.TOTAL_ELIGIBLE, key)}
            value={getValueByInvestmentTypeAndFieldName(InvestmentType.TOTAL_ELIGIBLE, key)}
            suffixLabel='€'
          />
        </td>
      </tr>
    );
  }

  return (
    <div>
      <h4 className='text-primary mb-0'>
        <FormattedMessage id='economicViabilityForm.investmentPlanTab' />
      </h4>
      <small className='text-secondary text-uppercase d-block'>
        {intl.formatMessage(
          { id: 'all.step' },
          { step: values.step, numberOfSteps: numberOfSteps }
        )}
      </small>

      <Table className='mt-4' bordered>
        <thead>
          <tr>
            <th className='w-50'>
              <FormattedMessage id='economicViabilityFormStep3.investmentHeadings' />
            </th>
            <th>
              <FormattedMessage id='investmentType.TOTAL' />
              <ToolTip message={<FormattedMessage id='investmentType.TOTAL.toolTip' />} />
            </th>
            <th>
              <FormattedMessage id='investmentType.TOTAL_REPROGRAMMED' />
              <ToolTip
                message={<FormattedMessage id='investmentType.TOTAL_REPROGRAMMED.toolTip' />}
              />
            </th>
            <th>
              <FormattedMessage id='investmentType.TOTAL_ELIGIBLE' />
              <ToolTip message={<FormattedMessage id='investmentType.TOTAL_ELIGIBLE.toolTip' />} />
            </th>
          </tr>
        </thead>
        <tbody>{KEYS.map((key) => getTableRowsByKey(key))}</tbody>
      </Table>

      <TextAreaInputField
        labelId={'economicViabilityFormStep3.changesJustification'}
        name={'changesJustification'}
        value={values.changesJustification}
        handleChange={handleChange}
        toolTip={
          <ToolTip
            message={
              <FormattedMessage id='economicViabilityFormStep3.changesJustification.toolTip' />
            }
          />
        }
        isInvalid={errors.changesJustification}
        errorMessage={errors.changesJustification}
      />
    </div>
  );
}
