import { apiAxios as authAxios } from '../axios.config';
import {da} from "date-fns/locale";
import {AccreditationDecisionState} from "../models/AccreditationDecisionState";

export const getPriorEntitiesByDistrictCode = (params) => {
  return authAxios.get('/api/entity', { params: params }); //params variable include: districtCode
};

export const getPriorSupportRequest = (index, size, state, orderBy, orderType) => {
  return authAxios.get('/api/entity/priorSupportRequests', {
    params: {
      index: index,
      size: size,
      state: state,
      orderBy: orderBy,
      orderType: orderType
    }
  });
};


export const getPriorSupportRequestWithNif = (index, size, state, orderBy, orderType,entityNif ) => {
  return authAxios.get(`/api/entity/${entityNif}/priorSupportRequests`, {
    params: {
      index: index,
      size: size,
      state: state,
      orderBy: orderBy,
      orderType: orderType
    }
  });
};



export const getEntity = () => {
  return authAxios.get('/api/entity/user');
};

export const uploadNib = (nib) => {
  return authAxios.post('/api/entity/uploadNib', nib, {
    headers: { 'Content-Type': 'text/plain' }
  });
};

export const saveEntityAccreditation = (eaAccreditationParsedFormValues, queryParams) => {
  return authAxios.post(
    '/api/entity/accreditation',
    eaAccreditationParsedFormValues,
    queryParams ? { params: queryParams } : null
  );
};

export const decideAccreditationValidity = (entityNif, approved, reason) => {
  const data = {
    result: approved ? 'ACCEPTED' : 'REJECTED',
    comment: reason
  }
  return authAxios.post(`api/entity/${entityNif}/accreditation/validity/decision`, data);
}
export const decideAccreditationResult = (entityNif, approved, score, reason) => {
  const data = {
    state: approved ? AccreditationDecisionState.ACCEPTED : AccreditationDecisionState.REJECTED,
    score: score,
    decisionComment: reason
  }
  return authAxios.post(`api/entity/${entityNif}/accreditation/decision`, data);
}

export const decideAccreditationResultWithFiles = (entityNif, formData) => {
  return authAxios.post(`api/entity/${entityNif}/accreditation/decision`, formData);
}

export const getEntityAccreditation = (version) => {
  let qp = {};
  if(version!=null) qp.version = version;
  return authAxios.get('/api/entity/accreditation', {params:  qp});
};

export const getEntityAccreditationByNif = (entityNif, version) => {
  let qp = {};
  if(version!=null) qp.version = version;
  return authAxios.get(`/api/entity/${entityNif}/accreditation`, {params:  qp});
};

export const getEntityAccreditationDecision = (queryParams) => {
  return authAxios.get(`/api/entity/accreditation/decision`, {params:  queryParams});
};

export const getEntityGetList = (queryParams) => {
  return authAxios.get('/api/entity/technicalTeamManagers', {params:  queryParams})
}

export const getEntityTgpList = (queryParams) => {
  return authAxios.get('/api/entity/projectManagers', {params:  queryParams})
}

export const getEntityList = (queryParams) => {
  return authAxios.get('/api/entity/list', {params:  queryParams})
}

export const getEntityDetails = (entityNif) => {
  return authAxios.get(`/api/entity/${entityNif}/details`)
}

export const getEntityDistricts = (entityNif) => {
  return authAxios.get(`/api/entity/${entityNif}/districts`)
}


export const getEntityLocations = (entityNif) => {
  return authAxios.get(`/api/entity/${entityNif}/locations`)
}



export const revokeEntity = (entityNif) => {
  return authAxios.post(`/api/entity/${entityNif}/revoke`)
}

export const setNIPC = (nipc) => {
  return authAxios.post('/api/entity/setNIPC', nipc);
};

export const uploadEntities = (formData) => {
  return authAxios.post(`/api/entity/uploadAllowedEntityNIPCs`, formData);
};

export const addDecisionDocument = (entityNif, formData) => {
  return authAxios.post(`api/entity/${entityNif}/accreditation/decision/documents`, formData);
};

export const deleteDecisionDocument = (entityNif, documentExternalId) => {
  return authAxios.delete(`api/entity/${entityNif}/accreditation/decision/documents/${documentExternalId}`);
};

export const appeal = (entityNif, appeal) => {
  return authAxios.post(`api/entity/${entityNif}/accreditation/appeal`, appeal);
}
export const uploadFinalDocument = (entityNif, formData) => {
  return authAxios.post(`api/entity/${entityNif}/accreditation/documents`, formData);
}
export const fullyApproveEntity = (entityNif, dates) => {
  return authAxios.post(`api/entity/${entityNif}/accreditation/fully_approve`, dates);
}

export const getHistory = (entityNif) => {
  return authAxios.get(`api/entity/${entityNif}/accreditation/history`);
}

export const getRenewal = (entityNif) => {
  return authAxios.get(`api/entity/${entityNif}/renewal`);
}

export const getRenewalOverview = (entityNif) => {
  return authAxios.get(`api/entity/${entityNif}/renewal/overview`);
}

export const saveRenewal = (entityNif, eaAccreditationParsedFormValues) => {
  return authAxios.post(`/api/entity/${entityNif}/renewal`, eaAccreditationParsedFormValues);
};

export const submitRenewal = (entityNif, eaAccreditationParsedFormValues) => {
  return authAxios.post(`/api/entity/${entityNif}/renewal/submit`, eaAccreditationParsedFormValues);
};

export const uploadRenewalDocument = (entityNif, formData) => {
  return authAxios.post(`api/entity/${entityNif}/renewal/documents`, formData);
};

export const deleteRenewalDocument = (entityNif, documentExternalId) => {
  return authAxios.delete(`api/entity/${entityNif}/renewal/documents/${documentExternalId}`);
};
