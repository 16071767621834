import {FormattedMessage, useIntl} from "react-intl";
import React from "react";
import {Col, Row} from "react-bootstrap";
import {SingleUploadArea} from "../../SingleUploadArea";
import {DocumentType} from "../../../models/DocumentType";
import {AuthenticatedDownloadLink} from "../../document/AuthenticatedDownloadLink";

export function EaRenewalDocuments({
     formStep,
     numberOfSteps,
     errors,
     formikValues,
     handleChange,
     handlePreviousStep,
     handleNextStep,
     setError,
     documents,
     setDocuments,
     uploadFunction,
     deleteFunction,
     readMode = false
 }) {
    const intl = useIntl();
    const requiredFileTypes = [
        DocumentType.RENEWAL_INVITATION_LETTER,
        DocumentType.RENEWAL_ENTITY_RESPONSE_TO_INVITATION,
        DocumentType.RENEWAL_DIRECTIVE_COUNCIL_SERVICE_INFORMATION,
        DocumentType.RENEWAL_DIRECTIVE_COUNCIL_DELIBERATION,
        DocumentType.RENEWAL_NOTIFICATION_LETTER_AND_PROTOCOL,
        DocumentType.RENEWAL_SIGNED_PROTOCOL
    ];



    return (
        <div>
            <h4 className='text-primary mb-0'>
                <FormattedMessage id='eaAccreditationForm.sideNavBar.renewal.docs' />
            </h4>
            <small className='text-secondary text-uppercase'>
                {intl.formatMessage(
                    { id: 'all.step' },
                    { step: formStep, numberOfSteps: numberOfSteps }
                )}
            </small>
            <Row>
                <Col>
                    <AsyncDocumentLinkCombo
                        documentType={DocumentType.RENEWAL_INVITATION_LETTER}
                        documents={documents}
                        setDocuments={setDocuments}
                        setError={setError}
                        uploadFunction={uploadFunction}
                        deleteFunction={deleteFunction}
                        entityRelated={true}
                        readMode={readMode}
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    <AsyncDocumentLinkCombo
                        documentType={DocumentType.RENEWAL_ENTITY_RESPONSE_TO_INVITATION}
                        documents={documents}
                        setDocuments={setDocuments}
                        setError={setError}
                        uploadFunction={uploadFunction}
                        deleteFunction={deleteFunction}
                        entityRelated={true}
                        readMode={readMode}
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    <AsyncDocumentLinkCombo
                        documentType={DocumentType.RENEWAL_DIRECTIVE_COUNCIL_SERVICE_INFORMATION}
                        documents={documents}
                        setDocuments={setDocuments}
                        setError={setError}
                        uploadFunction={uploadFunction}
                        deleteFunction={deleteFunction}
                        entityRelated={true}
                        readMode={readMode}
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    <AsyncDocumentLinkCombo
                        documentType={DocumentType.RENEWAL_DIRECTIVE_COUNCIL_DELIBERATION}
                        documents={documents}
                        setDocuments={setDocuments}
                        setError={setError}
                        uploadFunction={uploadFunction}
                        deleteFunction={deleteFunction}
                        entityRelated={true}
                        readMode={readMode}
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    <AsyncDocumentLinkCombo
                        documentType={DocumentType.RENEWAL_NOTIFICATION_LETTER_AND_PROTOCOL}
                        documents={documents}
                        setDocuments={setDocuments}
                        setError={setError}
                        uploadFunction={uploadFunction}
                        deleteFunction={deleteFunction}
                        entityRelated={true}
                        readMode={readMode}
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    <AsyncDocumentLinkCombo
                        documentType={DocumentType.RENEWAL_SIGNED_PROTOCOL}
                        documents={documents}
                        setDocuments={setDocuments}
                        setError={setError}
                        uploadFunction={uploadFunction}
                        deleteFunction={deleteFunction}
                        entityRelated={true}
                        readMode={readMode}
                    />
                </Col>
            </Row>
            <div className='form-actions mt-5'>
                {handlePreviousStep != null && <button
                    className='btn btn-link'
                    type='button'
                    onClick={handlePreviousStep}
                >
                    <FormattedMessage id='all.back' />
                </button>}

                {handleNextStep != null && <button
                    className='btn btn-primary'
                    type='button'
                    onClick={handleNextStep}
                >
                    <FormattedMessage id='all.nextStep' />
                </button>}
            </div>
        </div>
    );
}


function AsyncDocumentLinkCombo({
    documentType,
    documents,
    setDocuments,
    setError,
    uploadFunction,
    deleteFunction,
    entityRelated,
    readMode = false
}) {
    if(readMode) {
        let d = documents.find((document) => document.documentType === documentType);
        if(d.documentPath) return (
            <>
                <label><FormattedMessage id={`documentType.${d.documentType}`}/></label>
                <AuthenticatedDownloadLink url={d.documentPath} filename={d.name}>
                    <small>{d.name}</small>
                </AuthenticatedDownloadLink>
            </>
        );
    } else {
        return (
            <SingleUploadArea
                documents={documents}
                setDocuments={setDocuments}
                documentType={documentType}
                setError={setError}
                processUploadCallback={uploadFunction}
                processDeleteCallback={deleteFunction}
                entityRelated={entityRelated}
            />
        );
    }
}