import React, {useState} from 'react';
import {Accordion, Col, Row} from 'react-bootstrap';
import {FormattedMessage, useIntl} from 'react-intl';
import {SellInformationViewer} from "./SellInformationViewer";
import {Arrays} from "../../utils/Arrays";
import {ProviderType} from "../../models/ProviderType";
import {PeopleType} from "../../models/PeopleType";
import {FunctionType} from "../../models/FunctionType";
import {ProviderInformationAccordionItem} from "./ProviderInformationAccordionItem";
import {RemunerationType} from "../../models/RemunerationType";

export function PeopleRemunerationAccordionItem({peopleRemunerationList,peopleRemunerationTotals,yearNames}) {
    const intl = useIntl();
    const peopleRemunerationFinal = getTransformInformation();


    function getTransformInformation() {
        if (peopleRemunerationList?.length > 0) {
            let grouped = Arrays.groupBy(peopleRemunerationList[0].peopleRemunerationCellList, pRemuneration => pRemuneration.functionType + pRemuneration.remunerationType);
            grouped.forEach(pRemuneration => (pRemuneration.sort((a, b) => a.year - b.year)));
            return grouped;
        }
        return null;
    }







    return (
        <>
            {(peopleRemunerationFinal != null) && (
                <Accordion.Item eventKey="peopleRemuneration">
                    <Accordion.Header><FormattedMessage
                        id='candidatureTabs.accordion.peopleRemuneration'/></Accordion.Header>
                    <Accordion.Body>

                        {RemunerationType.values().map((rType,index) => (
                            <div  key={index} className='tableWithBorders'>
                                <p>
                                    <strong>{intl.formatMessage({id: `remunerationType.${rType}`})}</strong>
                                </p>
                                <table className='w-100 mb-5 border-top border-1'>

                                    <thead>
                                    <tr className='border-1 border-bottom '>
                                        <th><FormattedMessage id='peopleRemuneration.function'/></th>
                                        {peopleRemunerationFinal.get('ADMIN_DIRMONTHLY').map((year,index1) => (
                                            <th key={index1} className='text-center'>{yearNames[year.year]}</th>
                                        ))}
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {FunctionType.values().map((fType,index1) => (
                                            <tr key={index1} className='border-1 border-bottom '>
                                                <td>{intl.formatMessage({id: `functionType.${fType}`})}</td>
                                                {peopleRemunerationFinal.get(fType + rType).map((year,index2) => (
                                                    <td key={index2} className='text-center withBorderLeft'>{year.value.toFixed(2)} €</td>
                                                ))}
                                            </tr>
                                    ))}
                                    <tr className='border-1 border-bottom '>
                                        <td className='text-end'><strong><FormattedMessage
                                            id='peopleRemuneration.total'/></strong></td>

                                        {peopleRemunerationFinal.get('ADMIN_DIRMONTHLY').map((year,index1) => (
                                            <td key={index1} className='text-center withBorderLeft'>{peopleRemunerationTotals[rType + year.year].toFixed(2)} €</td>
                                        ))}

                                    </tr>
                                    </tbody>
                                </table>


                            </div>


                        ))


                        }


                        <Row>
                            <Col md='12'>
                                <label>
                                    <FormattedMessage id='peopleRemuneration.reason'/>
                                </label>
                                <p>
                                    {peopleRemunerationList[0].reasonToRemuneration}
                                </p>
                            </Col>
                        </Row>
                    </Accordion.Body>
                </Accordion.Item>

            )}
        </>
    );
}
