import React, { useState } from 'react';
import { Accordion, Col, Form, Row, Table } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { DateTimeInputField } from '../bootstrap/DateTimeInputField';
import { NumberInputField } from '../bootstrap/NumberInputField';
import { TextAreaInputField } from '../bootstrap/TextAreaInputField';
import { TextInputField } from '../bootstrap/TextInputField';

export function ProjectManagerStep({
	step,
	numberOfSteps,
	errors,
	handleChange,
	formikValues,
	handleGoBack,
	handleNextStep,
	setFieldValue,
	tgpList,
	readMode,
}) {
	const intl = useIntl();

	function addProjectManager() {
		formikValues.tgpExternalIdList.push('-1');
		setFieldValue({ ...formikValues });
	}

	function removeProjectManager(index) {
		formikValues.tgpExternalIdList.splice(index, 1);
		setFieldValue({ ...formikValues });
	}

	return (
		<div>
			<h4 className='text-primary mb-0'>
				<FormattedMessage id='developmentPlan.managersIdentification' />
			</h4>
			<small className='text-secondary text-uppercase'>
				{intl.formatMessage(
					{ id: 'all.step' },
					{ step: step, numberOfSteps: numberOfSteps }
				)}
			</small>
			<fieldset disabled={readMode==true}>
				{formikValues.tgpExternalIdList.map((tgpExternalId, index) => (
					<React.Fragment key={index}>
						<Row className='mt-4'>
							<Col md='9'>
								<Form.Group>
									<Form.Label>
										<FormattedMessage id='all.name' />
									</Form.Label>
									<Form.Control
										as='select'
										name={`tgpExternalIdList[${index}]`}
										onChange={handleChange}
										value={tgpExternalId}
										isInvalid={errors.tgpExternalIdList?.[index]}
									>
										<option value={'-1'}>
											<FormattedMessage id='all.chooseOne' />
										</option>
										{tgpList.map((tgp) => (
											<option key={tgp.externalId} value={tgp.externalId}>
												{tgp.name}
											</option>
										))}
									</Form.Control>
									<Form.Control.Feedback type='invalid'>
										{errors.tgpExternalIdList?.[index]}
									</Form.Control.Feedback>
								</Form.Group>
							</Col>
							<Col md='3'>
								{tgpExternalId !== '-1' && (
									<NumberInputField
										label='NIF'
										disabled={true}
										value={
											tgpList.filter(
												(tgp) => tgp.externalId === tgpExternalId
											)[0].hre.nif
										}
									/>
								)}
							</Col>
						</Row>
						<Row>
							<Col>
								<button
									className='mt-3 px-0 btn btn-link'
									type='button'
									onClick={() => removeProjectManager(index)}
								>
									<FormattedMessage id='all.remove' />
								</button>
							</Col>
						</Row>
					</React.Fragment>
				))}

				{formikValues.tgpExternalIdList.length < 2 &&
					formikValues.tgpExternalIdList.length < tgpList.length && (
						<Row className='mt-4'>
							<Col>
								<button
									className='btn btn-outline-primary'
									type='button'
									onClick={addProjectManager}
								>
									<FormattedMessage id='all.addButton' />
								</button>
							</Col>
						</Row>
					)}
			</fieldset>
			<div className='form-actions mt-5'>
				<button
					className='btn btn-link'
					type='button'
					onClick={() => handleGoBack()}
				>
					<FormattedMessage id='all.back' />
				</button>

				<button
					className='btn btn-primary'
					type='button'
					onClick={() => handleNextStep()}
				>
					<FormattedMessage id='all.nextStep' />
				</button>
			</div>
		</div>
	);
}
