import React, { useEffect, useRef, useState } from 'react';
import {
	Form,
	Row,
	Container,
	Col,
	ToastContainer,
	Toast, Button,
} from 'react-bootstrap';
import { Formik } from 'formik';
import { FormattedMessage, useIntl } from 'react-intl';
import Loading from '../../components/general/Loading';
import { SubTemplate } from '../../components/general/SubTemplate';
import {FaCheck, FaRegCheckCircle, FaRegSave, FaRegTimesCircle, FaTimes} from 'react-icons/fa';
import { handleError, isNotBusinessError } from '../../utils/handleError';
import { FormSideNavBar } from '../../components/general/FormSideNavBar';
import * as formSteps from '../../components/eaAccreditation';
import { getDistricts } from '../../rest/administrativeDivision';
import { listQualificationLevels } from '../../rest/qualificationLevel';
import {
	decideAccreditationResult, decideAccreditationResultWithFiles,
	decideAccreditationValidity,
	getEntityAccreditation, getEntityAccreditationByNif, getEntityAccreditationDecision, getHistory, getRenewalOverview,
	saveEntityAccreditation,
} from '../../rest/entity';
import { Validator } from '../../utils/Validator';
import * as yup from 'yup';
import { listEntityDocuments } from '../../rest/document';
import { EaAccreditationFormCustomValidator } from '../../utils/EaAccreditationFormCustomValidator';
import { AlertError } from '../../components/bootstrap/AlertError';
import {Link, Navigate, useNavigate, useParams, useSearchParams} from 'react-router-dom';
import {isValidCae} from "../../rest/candidature";
import {createCustomErrorMessage} from "../../hooks/errorMessage";
import {EntityState} from "../../models/EntityState";
import {SuccessToast} from "../../components/general/SuccessToast";
import {getUserInfo, isEntityAndUnaccredited, isIEFPUser} from "../../authentication/authenticationHelper";
import {DecisionDialog} from "../../components/bootstrap/DecisionDialog";
import {ProcessState} from "../../models/ProcessState";
import {AccreditationDecisionState} from "../../models/AccreditationDecisionState";
import {EADecisionDialog} from "../../components/eaAccreditation/EaDecisionDialog";
import {EaADAppealModal} from "../../components/eaAccreditation/EaADAppealModal";
import {AlertSuccess} from "../../components/bootstrap/AlertSuccess";
import {EAAResults} from "../../components/eaAccreditation/EAAResults";
import {CloseableModalDialog} from "../../components/general/CloseableModalDialog";
import {Arrays} from "../../utils/Arrays";
import {EaARejectWithFilesDialog} from "../../components/eaAccreditation/EaARejectWithFilesDialog";
import {StateAndDateBadge} from "../../components/general/stateBadge/StateAndDateBadge";
import {DocumentType} from "../../models/DocumentType";
import {generateSingleUploadAreaDocumentObjectList} from "../../components/SingleUploadArea";
import {EaProtocolModal} from "../../components/eaAccreditation/EaProtocolModal";
import {DateUtils} from "../../utils/DateUtils";
import {ConfigObject} from "../../config";
import {UserState} from "../../models/UserState";

function onlyIndexValidation(context,value) {
	if(context.options.index!==0){
		return true;
	}
	return value!==undefined && value!==null && value!=='';
}

const eaAccreditationFormSchema = yup.object().shape({
	entity: yup.object().shape({
		description: yup
			.string()
			.required(<FormattedMessage id='errors.fieldRequiredText' />),

		nif: yup
			.string()
			.required(<FormattedMessage id='errors.fieldRequiredText' />)
			.test(
				'isNifValid',
				<FormattedMessage id='errors.eaAccreditationForm.nipc' />,
				(value) => Validator.validateNif(value)
			),

		nationalMemberYear: yup
			.string()
			.required(<FormattedMessage id='errors.fieldRequiredText' />)
			.test(
				'isYearValid',
				<FormattedMessage id='errors.eaAccreditationForm.nationalMemberYear' />,
				(value) => Validator.validateFixedSizeNumber(value, 4)
			),

		address: yup
			.string()
			.required(<FormattedMessage id='errors.fieldRequiredText' />),

		postalCode4Digits: yup
			.string()
			.required(<FormattedMessage id='errors.fieldRequiredText' />)
			.test(
				'isPostalCode4DigitsValid',
				<FormattedMessage id='errors.eaAccreditationForm.postalCode4Digits' />,
				(value) => Validator.validateFixedSizeNumber(value, 4)
			),

		postalCode3Digits: yup
			.string()
			.required(<FormattedMessage id='errors.fieldRequiredText' />)
			.test(
				'isPostalCode3DigitsValid',
				<FormattedMessage id='errors.eaAccreditationForm.postalCode3Digits' />,
				(value) => Validator.validateFixedSizeNumber(value, 3)
			),

		location: yup
			.string()
			.required(<FormattedMessage id='errors.fieldRequiredText' />),

		phone: yup
			.string()
			.required(<FormattedMessage id='errors.fieldRequiredText' />)
			.test(
				'isPhoneValid',
				<FormattedMessage id='errors.eaAccreditationForm.phoneAndMobile' />,
				(value) => Validator.validateCommsNumber(value)
			),

		email: yup
			.string()
			.required(<FormattedMessage id='errors.fieldRequiredText' />)
			.test(
				'isEmailValid',
				<FormattedMessage id='errors.candidatureForm.email' />,
				(value) => Validator.validateEmail(value)
			),

		districtCode: yup
			.string()
			.test(
				'isDistrictValid',
				<FormattedMessage id='errors.eaAccreditationForm.districtCode' />,
				(value) => value !== '-1'
			),

		profile: yup
			.string()
			.test(
				'isProfileValid',
				<FormattedMessage id='errors.eaAccreditationForm.profile' />,
				(value) => value !== '-1'
			),

		profileDescription: yup.mixed().when('profile', {
			is: (profile) => profile === 'OTHER',
			then: yup
				.string()
				.required(<FormattedMessage id='errors.fieldRequiredText' />),
		}),

		// score: yup.number().required(<FormattedMessage id='errors.fieldRequiredText' />)
		// .min(0.01,<FormattedMessage id='errors.notPositive' />),

		cae: yup
			.string()
			.required(<FormattedMessage id='errors.fieldRequiredText' />)
			.test(
				'isCAEValid',
				<FormattedMessage id='errors.eaAccreditationForm.cae' />,
				(value) => Validator.validateFixedSizeNumber(value, 5)
			).test(
				'isCaeOnDb',
				<FormattedMessage id='errors.candidatureForm.caeNotOnDB' />,
				async (value) => {
					const { data: valid } = await isValidCae(value);
					return valid.flag;
				}
			),

		connectionWithIEFP: yup.boolean(),

		connectionWithIEFPDesc: yup.mixed().when('connectionWithIEFP', {
			is: (connectionWithIEFP) => connectionWithIEFP === true,
			then: yup
				.string()
				.required(<FormattedMessage id='errors.fieldRequiredText' />),
		}),

		consultingAreas: yup
			.array()
			.of(
				yup
					.string()
					.required(<FormattedMessage id='errors.fieldRequiredText' />)
			),
		atDeclarationAuthorized: yup.boolean(),
		ssDeclarationAuthorized: yup.boolean(),
		responsibles: yup.array().of(
			yup.object().shape({
				name: yup
					.string().trim()
					.nullable().test('responsibleRequiredName', <FormattedMessage id='errors.fieldRequiredText'/>,
						(value,context) => {
							return onlyIndexValidation(context,value);
						} ),
				duty: yup
					.string().trim()
					.nullable().test('responsibleRequiredDuty', <FormattedMessage id='errors.fieldRequiredText'/>,
						(value,context) => {
							return onlyIndexValidation(context,value);
						} ),
			})
		),
	}),

	getList: yup.array().of(
		yup.object().shape({
			name: yup
				.string()
				.required(<FormattedMessage id='errors.fieldRequiredText' />),
			phone: yup
				.string()
				.test(
					'phoneRequired',
					<FormattedMessage id='errors.fieldRequiredText' />,
					(value, context) => value !== undefined || context.parent.mobile !== undefined
				)
				.test(
					'isPhoneValid',
					<FormattedMessage id='errors.eaAccreditationForm.phoneAndMobile' />,
					(value, context) => Validator.validateCommsNumber(value) || (context.parent.mobile !== undefined && value === undefined)
				),
			mobile: yup
				.string()
				.test(
					'mobileRequired',
					<FormattedMessage id='errors.fieldRequiredText' />,
					(value, context) => value !== undefined || context.parent.phone !== undefined
				)
				.test(
					'isMobileValid',
					<FormattedMessage id='errors.eaAccreditationForm.phoneAndMobile' />,
					(value, context) => Validator.validateCommsNumber(value) || (context.parent.phone !== undefined && value === undefined)
				),
			email: yup
				.string()
				.required(<FormattedMessage id='errors.fieldRequiredText' />)
				.test(
					'isEmailValid',
					<FormattedMessage id='errors.candidatureForm.email' />,
					(value) => Validator.validateEmail(value)
				),
			managementExperience: yup.boolean(),
			qualificationLevelCode: yup
				.string()
				.test(
					'isQualificationLevelValid',
					<FormattedMessage id='errors.eaAccreditationForm.qualificationLevelCode' />,
					(value) => value !== '-1'
				),
		})
	),

	interventionDistrictCodes: yup
		.array()
		.of(yup.string())
		.required(
			<FormattedMessage id='errors.eaAccreditationForm.interventionDistrictCodes' />
		),

	tgpList: yup.array().of(
		yup.object().shape({
			name: yup
				.string()
				.required(<FormattedMessage id='errors.fieldRequiredText' />),
			qualificationLevelCode: yup
				.string()
				.test(
					'isQualificationLevelValid',
					<FormattedMessage id='errors.eaAccreditationForm.qualificationLevelCode' />,
					(value) => value !== '-1'
				),
			hre: yup.object().shape({
				nif: yup
					.string()
					.required(<FormattedMessage id='errors.fieldRequiredText' />)
					.test(
						'isNifValid',
						<FormattedMessage id='errors.promoterForm.nif' />,
						(value) => Validator.validateNif(value)
					),
				bondType: yup
					.string()
					.test(
						'isBondTypeValid',
						<FormattedMessage id='errors.eaAccreditationForm.bondType' />,
						(value) => value !== '-1'
					),
				projectCreationCount: yup
					.number()
					.required(<FormattedMessage id='errors.fieldRequiredText' />)
					.test(
						'isNumberValid',
						<FormattedMessage id='errors.eaAccreditationForm.number' />,
						(value) => value >= 0
					),
				approvedProjectFollowUpCount: yup
					.number()
					.required(<FormattedMessage id='errors.fieldRequiredText' />)
					.test(
						'isNumberValid',
						<FormattedMessage id='errors.eaAccreditationForm.number' />,
						(value) => value >= 0
					),
				consultingCount: yup
					.number()
					.required(<FormattedMessage id='errors.fieldRequiredText' />)
					.test(
						'isNumberValid',
						<FormattedMessage id='errors.eaAccreditationForm.number' />,
						(value) => value >= 0
					),
				expandSkillsCount: yup
					.number()
					.required(<FormattedMessage id='errors.fieldRequiredText' />)
					.test(
						'isNumberValid',
						<FormattedMessage id='errors.eaAccreditationForm.number' />,
						(value) => value >= 0
					),
				analysisCount: yup
					.number()
					.required(<FormattedMessage id='errors.fieldRequiredText' />)
					.test(
						'isNumberValid',
						<FormattedMessage id='errors.eaAccreditationForm.number' />,
						(value) => value >= 0
					),
			}),
			hrMapList: yup.array().of(
				yup.object().shape({
					districtCode: yup
						.string()
						.test(
							'isDistrictValid',
							<FormattedMessage id='errors.eaAccreditationForm.districtCode' />,
							(value) => value !== '-1'
						),
					mappingType: yup
						.string()
						.test('checkMappingTypes', 'Something is wrong', (value) =>
							[
								'CREATION',
								'ANALYSIS',
								'CONSULTING',
								'EXPAND_SKILLS',
								'FOLLOW_UP',
							].includes(value)
						),
				})
			),
		})
	),

	incubatorLocations: yup.array().of(
		yup.object().shape({
			parishCode: yup
				.string()
				.test(
					'isValidParish',
					<FormattedMessage id='errors.promoterForm.parish' />,
					(value) => value !== '-1'
				),
			extraLocation: yup
				.string()
				.required(<FormattedMessage id='errors.fieldRequiredText' />),
		})
	),

	equipmentDistribution: yup.lazy((obj) => {
		const validationObject = {
			attendanceRooms: yup.object({
				value: yup
					.number()
					.test(
						'isNumberValid',
						<FormattedMessage id='errors.eaAccreditationForm.number' />,
						(value) => value >= 0
					),
			}),
			meetingRooms: yup.object({
				value: yup
					.number()
					.test(
						'isNumberValid',
						<FormattedMessage id='errors.eaAccreditationForm.number' />,
						(value) => value >= 0
					),
			}),
			formationRooms: yup.object({
				value: yup
					.number()
					.test(
						'isNumberValid',
						<FormattedMessage id='errors.eaAccreditationForm.number' />,
						(value) => value >= 0
					),
			}),
			incubators: yup.object({
				value: yup
					.number()
					.test(
						'isNumberValid',
						<FormattedMessage id='errors.eaAccreditationForm.number' />,
						(value) => value >= 0
					),
			}),
			incubationSpaces: yup.object({
				value: yup
					.number()
					.test(
						'isNumberValid',
						<FormattedMessage id='errors.eaAccreditationForm.number' />,
						(value) => value >= 0
					),
			}),
			phones: yup.object({
				value: yup
					.number()
					.test(
						'isNumberValid',
						<FormattedMessage id='errors.eaAccreditationForm.number' />,
						(value) => value >= 0
					),
			}),
			computers: yup.object({
				value: yup
					.number()
					.test(
						'isNumberValid',
						<FormattedMessage id='errors.eaAccreditationForm.number' />,
						(value) => value >= 0
					),
			}),
			internetPoints: yup.object({
				value: yup
					.number()
					.test(
						'isNumberValid',
						<FormattedMessage id='errors.eaAccreditationForm.number' />,
						(value) => value >= 0
					),
			}),
			wifis: yup.object({
				value: yup
					.number()
					.test(
						'isNumberValid',
						<FormattedMessage id='errors.eaAccreditationForm.number' />,
						(value) => value >= 0
					),
			}),
			scanners: yup.object({
				value: yup
					.number()
					.test(
						'isNumberValid',
						<FormattedMessage id='errors.eaAccreditationForm.number' />,
						(value) => value >= 0
					),
			}),
			printers: yup.object({
				value: yup
					.number()
					.test(
						'isNumberValid',
						<FormattedMessage id='errors.eaAccreditationForm.number' />,
						(value) => value >= 0
					),
			}),
			vehicles: yup.object({
				value: yup
					.number()
					.test(
						'isNumberValid',
						<FormattedMessage id='errors.eaAccreditationForm.number' />,
						(value) => value >= 0
					),
			}),
		};
		const newEntries = Object.keys(obj).reduce(
			(acc, val) => ({
				...acc,
				[val]: yup.object(validationObject),
			}),
			{}
		);

		return yup.object().shape(newEntries);
	}),

	entitiesPerYearInvestment: yup.lazy((obj) => {
		const validationObject = {
			value: yup
				.number()
				.required(<FormattedMessage id='errors.fieldRequiredText' />)
				.test(
					'isNumberValid',
					<FormattedMessage id='errors.eaAccreditationForm.number' />,
					(value) => value >= 0
				),
		};
		const newEntries = Object.keys(obj).reduce(
			(acc, val) => ({
				...acc,
				[val]: yup.object(validationObject),
			}),
			{}
		);

		return yup.object().shape(newEntries);
	}),

	entitiesPerYearFollowUp: yup.lazy((obj) => {
		const validationObject = {
			value: yup
				.number()
				.required(<FormattedMessage id='errors.fieldRequiredText' />)
				.test(
					'isNumberValid',
					<FormattedMessage id='errors.eaAccreditationForm.number' />,
					(value) => value >= 0
				),
		};
		const newEntries = Object.keys(obj).reduce(
			(acc, val) => ({
				...acc,
				[val]: yup.object(validationObject),
			}),
			{}
		);

		return yup.object().shape(newEntries);
	}),

	entitiesPerYearConsultancy: yup.lazy((obj) => {
		const validationObject = {
			value: yup
				.number()
				.required(<FormattedMessage id='errors.fieldRequiredText' />)
				.test(
					'isNumberValid',
					<FormattedMessage id='errors.eaAccreditationForm.number' />,
					(value) => value >= 0
				),
		};
		const newEntries = Object.keys(obj).reduce(
			(acc, val) => ({
				...acc,
				[val]: yup.object(validationObject),
			}),
			{}
		);

		return yup.object().shape(newEntries);
	}),

	entitiesPerYearIncubation: yup.lazy((obj) => {
		const validationObject = {
			value: yup
				.number()
				.required(<FormattedMessage id='errors.fieldRequiredText' />)
				.test(
					'isNumberValid',
					<FormattedMessage id='errors.eaAccreditationForm.number' />,
					(value) => value >= 0
				),
		};
		const newEntries = Object.keys(obj).reduce(
			(acc, val) => ({
				...acc,
				[val]: yup.object(validationObject),
			}),
			{}
		);

		return yup.object().shape(newEntries);
	}),

	actionsPerYearWorkshops: yup.lazy((obj) => {
		const validationObject = {
			value: yup
				.number()
				.required(<FormattedMessage id='errors.fieldRequiredText' />)
				.test(
					'isNumberValid',
					<FormattedMessage id='errors.eaAccreditationForm.number' />,
					(value) => value >= 0
				),
		};
		const newEntries = Object.keys(obj).reduce(
			(acc, val) => ({
				...acc,
				[val]: yup.object(validationObject),
			}),
			{}
		);

		return yup.object().shape(newEntries);
	}),

	actionsPerYearSeminaries: yup.lazy((obj) => {
		const validationObject = {
			value: yup
				.number()
				.required(<FormattedMessage id='errors.fieldRequiredText' />)
				.test(
					'isNumberValid',
					<FormattedMessage id='errors.eaAccreditationForm.number' />,
					(value) => value >= 0
				),
		};
		const newEntries = Object.keys(obj).reduce(
			(acc, val) => ({
				...acc,
				[val]: yup.object(validationObject),
			}),
			{}
		);

		return yup.object().shape(newEntries);
	}),

	actionsPerYearDidacticMaterials: yup.lazy((obj) => {
		const validationObject = {
			value: yup
				.number()
				.required(<FormattedMessage id='errors.fieldRequiredText' />)
				.test(
					'isNumberValid',
					<FormattedMessage id='errors.eaAccreditationForm.number' />,
					(value) => value >= 0
				),
		};
		const newEntries = Object.keys(obj).reduce(
			(acc, val) => ({
				...acc,
				[val]: yup.object(validationObject),
			}),
			{}
		);

		return yup.object().shape(newEntries);
	}),

	actionsPerYearBootCamps: yup.lazy((obj) => {
		const validationObject = {
			value: yup
				.number()
				.required(<FormattedMessage id='errors.fieldRequiredText' />)
				.test(
					'isNumberValid',
					<FormattedMessage id='errors.eaAccreditationForm.number' />,
					(value) => value >= 0
				),
		};
		const newEntries = Object.keys(obj).reduce(
			(acc, val) => ({
				...acc,
				[val]: yup.object(validationObject),
			}),
			{}
		);

		return yup.object().shape(newEntries);
	}),
});

export function getEaAccreditationFormSchema() {
	return eaAccreditationFormSchema;
}

export function AddEaAccreditation() {
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);
	const [navErrors, setNavErrors] = useState([]);
	const [formStep, setFormStep] = useState(0);
	const [districList, setDistrictList] = useState();
	const [districtOptions, setDistrictOptions] = useState();
	const [qualificationLevelList, setQualificationLevelList] = useState([]);
	const [documents, setDocuments] = useState([]);
	const [protocolDocuments, setProtocolDocuments] = useState([]);
	const [appealDocuments, setAppealDocuments] = useState([]);
	const [approvalDocuments, setApprovalDocuments] = useState([]);
	const [formikInitialValues, setFormikInitialValues] = useState(null);
	const [accreditationDecision, setAccreditationDecision] = useState(null);
	const [delegationDecision, setDelegationDecision] = useState(null);
	const [history, setHistory] = useState(null);
	const [showHistory, setShowHistory] = useState(false);
	const [submitted, setSubmitted] = useState(false);
	const submittedRef = useRef(submitted);
	const [regionalDelegationCode, setRegionalDelegationCode] = useState(false);
	const [showSaveToast, setShowSaveToast] = useState(false);
	const [showSubmittedToast, setShowSubmittedToast] = useState(false);
	const [showValidate, setShowValidate] = useState(false);
	const [showInvalidate, setShowInvalidate] = useState(false);
	const [showAccept, setShowAccept]= useState(false);
	const [showReject, setShowReject] = useState(false);
	const [showProtocolModal, setShowProtocolModal]  = useState(false);
	const [appealModalShow, setAppealModalShow]  = useState(false);
	const [appealModalSubmitted, setAppealModalSubmitted] = useState(false);
	const [lsItem, setLsItem] = useState(null);
	const [selfNipc, setSelfNipc] = useState(null);
	const {entityNIPC} = useParams();
	const [searchParams, setSearchParams] = useSearchParams();
	const [version, setVersion] = useState(null);
	const [hasRenewal, setHasRenewal] = useState(false);
	const [eaAccreditation, setEaAccreditation] = useState();
	const [originalEntity, setOriginalEntity] = useState(null) //I called this original entity, cause formik values removes stuff.

	const intl = useIntl();
	const navigate = useNavigate();
	const isIEFP = isIEFPUser();

	const formikPropsRef = useRef(null);

	const acceptedQualificationLevels = ['4', '6', '7', '8'];
	const managerAcceptedQualificationLevels = ['6', '7', '8'];

	const tabsIds = [
		'eaAccreditationForm.sideNavBar.identificationEA',
		'eaAccreditationForm.sideNavBar.experienceEA',
		'eaAccreditationForm.sideNavBar.geographicalArea',
		'eaAccreditationForm.sideNavBar.resources1',
		'eaAccreditationForm.sideNavBar.resources2',
		'eaAccreditationForm.sideNavBar.resources3',
		'eaAccreditationForm.sideNavBar.declaration',
	];

	const requiredDocuments = ['AT_DECLARATION', 'SS_DECLARATION'];

	const requiredApprovalFileTypes = [
		DocumentType.EAA_SERVICE_INFO,
		DocumentType.EAA_DECISION_NOTIFICATION,
		DocumentType.EAA_IEFP_CONFIRMATION,
		DocumentType.EAA_DIRECTIVE_COUNCIL_DELIBERATION
	];



	useEffect(() => {
		setLoading(true);
		if (submitted) {
			setSubmitted(() => false);
		} else {
			fetchData();
			checkLocalStorageItems();
		}
	}, [submitted, searchParams]);



	function checkLocalStorageItems() {
		[
			'eaAccreditationSubmited',
			'eaAccreditationApproved',
			'eaAccreditationDocsNeeded',
			'eaAccreditationAppealed',
			'eaAccreditationRejected',
			'eaAccreditationRejectedFinal',
			'eaAccreditationFullyApproved'
		].forEach((s) => {
			if (sessionStorage.getItem(s)) {
				setLsItem(s);
			}
		});
	}

	function loadDocument(data, documentType) {
		if (data.length !== 0) {
			const document = data[0];
			return {
				documentType: document.documentType,
				externalId: document.externalId,
				name: document.name,
				documentPath: document.documentPath,
				content: '',
				submited: true,
				error: '',
			};
		}

		return {
			documentType: documentType,
			externalId: '',
			name: '',
			content: null,
			submited: false,
			error: '',
		};
	}

	async function fetchData() {
		//Clear LsItems because of history caused reloads
		setLsItem(null);
		let version = searchParams.get("version") !==null ? searchParams.get("version") : null
		setVersion(version);
		try {
			let [
				{ data: districts },
				{ data: qualificationLevels },
				{ data: eaAccreditation },
				{ data: eaAccreditationDecision },
				{ data: approvalDocs },
			] = await Promise.all([
				await getDistricts(true),
				await listQualificationLevels(),
				await (entityNIPC === undefined ? getEntityAccreditation(version) : getEntityAccreditationByNif(entityNIPC, version)),
				await getEntityAccreditationDecision(entityNIPC === undefined ? null : {entityNif: entityNIPC}),
				await listEntityDocuments(entityNIPC == null ? null : {entityNif: entityNIPC}),
			]);
			if(version) {
				let lastIndex = eaAccreditation.accreditationDecisions.length - 1;
				eaAccreditationDecision = eaAccreditation.accreditationDecisions[lastIndex];
			}
			setAccreditationDecision(eaAccreditationDecision === '' ? null : eaAccreditationDecision);
			setEaAccreditation(eaAccreditation);
			setApprovalDocuments(generateSingleUploadAreaDocumentObjectList(requiredApprovalFileTypes, approvalDocs));
			setProtocolDocuments(
				generateSingleUploadAreaDocumentObjectList([DocumentType.COOPERATION_PROTOCOL], approvalDocs)
			);
			setOriginalEntity(eaAccreditation?.entity);

			if(entityNIPC == null && eaAccreditation != null && eaAccreditation?.entity != null) {
				setSelfNipc(eaAccreditation?.entity?.nif);
			}
			const rdCode = getUserInfo()?.rd;
			setRegionalDelegationCode(rdCode);
			if(isIEFP && eaAccreditationDecision != null && eaAccreditationDecision?.delegationDecisions != null) {
				setDelegationDecision(eaAccreditationDecision.delegationDecisions.filter(dd => rdCode === dd?.regionalDelegationCode)[0]);
			}
			if(eaAccreditationDecision != null && eaAccreditationDecision?.documents != null) {
				setAppealDocuments(eaAccreditationDecision?.documents);
			}

			if(selfNipc != null) {
				try {
					let [
						{ data: renewalOverview }
					] = await Promise.all([
						await getRenewalOverview(entityNIPC || selfNipc)
					]);
					if(renewalOverview != null) {
						setHasRenewal(true);
					} else{
						setHasRenewal(false);
					}
				} catch (err) {
					console.log(err);
				}
			}

			let {data: histr} = await getHistory(entityNIPC || eaAccreditation?.entity?.nif);
			setHistory(histr);
			const { data: entityDocuments } = await listEntityDocuments(entityNIPC === undefined ? null : {entityNif: entityNIPC});
			let entityAccreditationDocuments = requiredDocuments.map((rd) => {
				let dTypeIndex = entityDocuments.findIndex(
					(d) => d.documentType === rd
				);
				if (dTypeIndex < 0) {
					//it means the document wasnt found
					return {
						externalId: '',
						documentType: rd,
						name: '',
						content: null,
						submited: false,
						error: '',
					};
				} else {
					return {
						externalId: entityDocuments[dTypeIndex].externalId,
						documentType: entityDocuments[dTypeIndex].documentType,
						documentPath: entityDocuments[dTypeIndex].documentPath,
						name: entityDocuments[dTypeIndex].name,
						content: '',
						submited: true,
						error: '',
					};
				}
			});
			setDocuments(entityAccreditationDocuments);

			setFormikInitialValues(
				getFormikInitialValues(eaAccreditation, districts)
			);

			setDistrictList(districts);
			let districtNamesList = [];
			districts.map((districtObj) =>
				districtNamesList.push(districtObj.description)
			);
			setDistrictOptions(districtNamesList);
			setQualificationLevelList(qualificationLevels);
		} catch (error) {
			setError(error);
		} finally {
			setLoading(false);
		}
	}

	function scrollPageToTop() {
		document.body.scrollTop = 0;
		document.documentElement.scrollTop = 0;
	}

	function handleNavError(error) {
		let errors = error.response.data.message
			.split(',')
			.map((value) => Number.parseInt(value));
		setNavErrors(errors);
	}

	function handlePreviousStep() {
		scrollPageToTop();
		setFormStep(formStep - 1);
	}

	function handleNextStep() {
		scrollPageToTop();
		setFormStep(formStep + 1);
	}


	function parseBackendInterventionTypeToFrontendKeys(type) {
		switch (type) {
			case 'Investment_Business_Plans':
				return 'entitiesPerYearInvestment';

			case 'FOLLOW_UP':
				return 'entitiesPerYearFollowUp';

			case 'CONSULTING':
				return 'entitiesPerYearConsultancy';

			case 'INCUBATOR':
				return 'entitiesPerYearIncubation';

			case 'DOING_WORKSHOPS':
				return 'actionsPerYearWorkshops';

			case 'DOING_SEMINARS':
				return 'actionsPerYearSeminaries';

			case 'DOING_COURSEWARE':
				return 'actionsPerYearDidacticMaterials';

			case 'DOING_BOOTCAMP':
				return 'actionsPerYearBootCamps';

			default:
				console.log("Something's wrong");
		}
	}

	function parseBackendMaterialResourceTypeToFrontendKeys(type) {
		switch (type) {
			case 'ATTENDANCE_ROOM':
				return 'attendanceRooms';

			case 'MEET_ROOM':
				return 'meetingRooms';

			case 'FORMATION_ROOM':
				return 'formationRooms';

			case 'INCUBATOR':
				return 'incubators';

			case 'INCUBATOR_SPACE':
				return 'incubationSpaces';

			case 'PHONES_NUMBER':
				return 'phones';

			case 'COMPUTER':
				return 'computers';

			case 'INTERNET_SPOT':
				return 'internetPoints';

			case 'WIFI':
				return 'wifis';

			case 'SCANNER':
				return 'scanners';

			case 'PHOTOCOPIER':
				return 'printers';

			case 'VEHICLE':
				return 'vehicles';

			default:
				console.log("Something's wrong");
		}
	}

	function getFormikInitialValues(eaAccreditation, districts) {
		let interventions = {};
		eaAccreditation.interventions.forEach((intervention) => {
			interventions[
				parseBackendInterventionTypeToFrontendKeys(intervention.type)
			] = {
				...interventions[
					parseBackendInterventionTypeToFrontendKeys(intervention.type)
				],
				[intervention.year]: {
					value: intervention.value,
					externalId: intervention.externalId,
				},
			};
		});

		let equipmentDistribution = {};
		eaAccreditation.equipmentDistribution?.forEach((distribution) => {
			let districtName = districts.filter(
				(d) => d.code === distribution.districtCode
			)[0].description;

			equipmentDistribution[districtName] = {
				...equipmentDistribution[districtName],
				[parseBackendMaterialResourceTypeToFrontendKeys(
					distribution.materialResourceType
				)]: {
					externalId: distribution.externalId,
					value: distribution.value,
				},
			};
		});

		let entityResponsibles = eaAccreditation.entity.responsibles;
		entityResponsibles.forEach((er) => {
			er.name = er.name ? er.name : '';
			er.duty = er.duty ? er.duty : '';
		});

		return {
			entity: {
				description: eaAccreditation.entity.description
					? eaAccreditation.entity.description
					: '',
				nif: eaAccreditation.entity.nif ? eaAccreditation.entity.nif : '',
				address: eaAccreditation.entity.address
					? eaAccreditation.entity.address
					: '',
				postalCode4Digits: eaAccreditation.entity.postalCode4Digits
					? eaAccreditation.entity.postalCode4Digits
					: '',
				postalCode3Digits: eaAccreditation.entity.postalCode3Digits
					? eaAccreditation.entity.postalCode3Digits
					: '',
				location: eaAccreditation.entity.location
					? eaAccreditation.entity.location
					: '',
				phone: eaAccreditation.entity.phone ? eaAccreditation.entity.phone : '',
				email: eaAccreditation.entity.email ? eaAccreditation.entity.email : '',
				districtCode: eaAccreditation.entity.districtCode
					? eaAccreditation.entity.districtCode
					: '-1',
				profile: eaAccreditation.entity.profile
					? eaAccreditation.entity.profile
					: '-1',
				profileDescription: eaAccreditation.entity.profileDescription
					? eaAccreditation.entity.profileDescription
					: '',
				cae: eaAccreditation.entity.cae ? eaAccreditation.entity.cae : '',
				score: eaAccreditation.entity.score ? eaAccreditation.entity.score:'',
				state: eaAccreditation.entity.state,
				connectionWithIEFP: eaAccreditation.entity.connectionWithIEFP,
				connectionWithIEFPDesc: eaAccreditation.entity.connectionWithIEFPDesc
					? eaAccreditation.entity.connectionWithIEFPDesc
					: '',
				consultingAreas: eaAccreditation.entity.consultingAreas
					? eaAccreditation.entity.consultingAreas
					: [],
				atDeclarationAuthorized: eaAccreditation.entity.atDeclarationAuthorized,
				ssDeclarationAuthorized: eaAccreditation.entity.ssDeclarationAuthorized,
				nationalMemberYear: eaAccreditation.entity.nationalMemberYear
					? eaAccreditation.entity.nationalMemberYear
					: '',
				responsibles: entityResponsibles,
			},
			...interventions,
			getList: eaAccreditation.getList ? eaAccreditation.getList : [],
			interventionDistrictCodes: eaAccreditation.interventionDistrictCodes
				? eaAccreditation.interventionDistrictCodes
				: [],
			tgpList: eaAccreditation.tgpList ? eaAccreditation.tgpList : [],
			incubatorLocations: eaAccreditation.incubatorLocations
				? eaAccreditation.incubatorLocations
				: [],
			equipmentDistribution: eaAccreditation.equipmentDistribution
				? equipmentDistribution
				: {},
		};
	}

	if (submitted) {
		//return <Navigate replace to={'/'} />;
	}

	if (loading) {
		return <Loading />;
	}

	const readMode = version || isIEFP || formikInitialValues.entity.state !== EntityState.DRAFT;

	if (error && isNotBusinessError(error)) {
		return handleError(error);
	}

	function parseInterventionKey(key) {
		switch (key) {
			case 'entitiesPerYearInvestment':
				return 'Investment_Business_Plans';

			case 'entitiesPerYearFollowUp':
				return 'FOLLOW_UP';

			case 'entitiesPerYearConsultancy':
				return 'CONSULTING';

			case 'entitiesPerYearIncubation':
				return 'INCUBATOR';

			case 'actionsPerYearWorkshops':
				return 'DOING_WORKSHOPS';

			case 'actionsPerYearSeminaries':
				return 'DOING_SEMINARS';

			case 'actionsPerYearDidacticMaterials':
				return 'DOING_COURSEWARE';

			case 'actionsPerYearBootCamps':
				return 'DOING_BOOTCAMP';
			default:
				console.log("Something's wrong");
		}
	}

	function parseMaterialResourceTypeKey(key) {
		switch (key) {
			case 'attendanceRooms':
				return 'ATTENDANCE_ROOM';

			case 'meetingRooms':
				return 'MEET_ROOM';

			case 'formationRooms':
				return 'FORMATION_ROOM';

			case 'incubators':
				return 'INCUBATOR';

			case 'incubationSpaces':
				return 'INCUBATOR_SPACE';

			case 'phones':
				return 'PHONES_NUMBER';

			case 'computers':
				return 'COMPUTER';

			case 'internetPoints':
				return 'INTERNET_SPOT';

			case 'wifis':
				return 'WIFI';

			case 'scanners':
				return 'SCANNER';

			case 'printers':
				return 'PHOTOCOPIER';

			case 'vehicles':
				return 'VEHICLE';

			default:
				console.log("Something's wrong");
		}
	}

	function parseInterventions(formikValues) {
		let interventions = [];

		Object.keys(formikValues)
			.filter(
				(key) =>
					key.startsWith('entitiesPerYear') || key.startsWith('actionsPerYear')
			)
			.map((interventionKey) =>
				Object.keys(formikValues[interventionKey]).map((year) => {
					interventions.push({
						type: parseInterventionKey(interventionKey),
						year: year,
						value: formikValues[interventionKey][year].value,
						externalId: formikValues[interventionKey][year].externalId,
					});
				})
			);

		return interventions;
	}

	function parseEquipmentDistribution(formikValues) {
		let equipmentDistribution = [];

		Object.keys(formikValues.equipmentDistribution).map((key) =>
			Object.keys(formikValues.equipmentDistribution[key]).map((material) =>
				equipmentDistribution.push({
					districtCode: districList.filter(
						(district) => district.description === key
					)[0].code,
					materialResourceType: parseMaterialResourceTypeKey(material),
					value: formikValues.equipmentDistribution[key][material].value,
					externalId:
						formikValues.equipmentDistribution[key][material].externalId,
				})
			)
		);

		return equipmentDistribution;
	}

	function parseFormikValuesToAPICall() {
		let formikValues = formikPropsRef.current.values;

		return {
			entity: formikValues.entity,

			getList: formikValues.getList,

			interventions: parseInterventions(formikValues),

			interventionDistrictCodes: formikValues.interventionDistrictCodes,

			tgpList: formikValues.tgpList,

			incubatorLocations: formikValues.incubatorLocations,

			equipmentDistribution: parseEquipmentDistribution(formikValues),
		};
	}

	async function handleSave() {
		try {
			let formikParsedValues = parseFormikValuesToAPICall();
			let { data: res } = await saveEntityAccreditation(formikParsedValues);
			formikPropsRef.current.setValues(
				getFormikInitialValues(res, districList)
			);
			setNavErrors([]);
			formikPropsRef.current.setErrors({});

			setShowSaveToast(true);
		} catch (error) {
			setError(error);
		}
	}

	async function handleSubmit() {
		let errors = await validateForm();
		setNavErrors(errors);

		if (errors.length === 0) {
			let formikParsedValues = parseFormikValuesToAPICall();
			let queryParams = {
				submit: true,
			};
			try {
				let { data: res } = await saveEntityAccreditation(formikParsedValues, queryParams);
				const initialValues = getFormikInitialValues(res, districList);
				formikPropsRef.current.setValues(initialValues);
				setFormikInitialValues(initialValues);
				setSubmitted(true);
				sessionStorage.setItem('eaAccreditationSubmited', true);
				setShowSubmittedToast(true);
			} catch (err) {
				if (err.response.data.exception === 'ValidationException') {
					handleNavError(err);
				}
				setError(err);
			}
		}
	}

	async function submitValidate() {
		try{
			await decideAccreditationValidity(entityNIPC, true, null);
			setDelegationDecision(prevState => ({
				...prevState,
				result: 'ACCEPTED'
			}));
			setSubmitted(true);
		} catch (error) {
			setError(error);
		}
		setShowValidate(false);
	}


	async function submitInvalidate(reason) {
		try{
			await decideAccreditationValidity(entityNIPC, false, reason);
			setDelegationDecision(prevState => ({
				...prevState,
				result: 'REJECTED'
			}));
			setSubmitted(true);
		} catch (error) {
			setError(error);
		}
		setShowInvalidate(false);
	}

	async function submitAccept(score) {
		try{
			await decideAccreditationResult(entityNIPC, true, score, null);
			setAccreditationDecision(prevState => ({
				...prevState,
				state: AccreditationDecisionState.ACCEPTED
			}));
			sessionStorage.setItem('eaAccreditationApproved', true);
			setSubmitted(true);
		} catch (error) {
			setError(error);
		}
		setShowAccept(false);
	}


	async function submitReject(formData) {
		try{
			await decideAccreditationResultWithFiles(entityNIPC, formData);
			setAccreditationDecision(prevState => ({
				...prevState,
				state: AccreditationDecisionState.REJECTED
			}));
			if(isFinalRejection) {
				sessionStorage.setItem('eaAccreditationRejectedFinal', true);
			} else {
				sessionStorage.setItem('eaAccreditationRejected', true);
			}
			setSubmitted(true);
		} catch (error) {
			setError(error);
		}
		setShowReject(false);
	}

	async function afterAppeal() {
		setAppealModalSubmitted(true);
		setSubmitted(true);
	}

	async function afterFullyApprove() {
		setSubmitted(true);
	}





	async function validateForm() {
		let errors = [];

		const formikErrors = await formikPropsRef.current.validateForm();

		Object.keys(formikErrors).forEach((key) => {
			switch (key) {
				case 'entity':
					Object.keys(formikErrors[key]).forEach((entityErrorKey) => {
						if (
							entityErrorKey === 'connectionWithIEFP' ||
							entityErrorKey === 'connectionWithIEFPDesc' ||
							entityErrorKey === 'consultingAreas'
						)
							errors.push(1);
						else if (entityErrorKey === 'responsibles') {
							errors.push(6);
						} else {
							errors.push(0);
						}
					});
					break;
				case 'getList':
					if (Array.isArray(formikErrors[key])) {
						formikErrors[key].forEach(
							(getErrorObj) =>
								getErrorObj !== undefined &&
								Object.keys(getErrorObj).forEach((getErrorKey) => {
									if (getErrorKey === 'qualificationLevelCode') {
										errors.push(3);
									} else {
										errors.push(0);
									}
								})
						);
					} else {
						errors.push(0);
					}
					break;
				case 'entitiesPerYearInvestment':
					errors.push(1);
					break;
				case 'entitiesPerYearFollowUp':
					errors.push(1);
					break;
				case 'entitiesPerYearConsultancy':
					errors.push(1);
					break;
				case 'entitiesPerYearIncubation':
					errors.push(1);
					break;
				case 'actionsPerYearWorkshops':
					errors.push(1);
					break;
				case 'actionsPerYearSeminaries':
					errors.push(1);
					break;
				case 'actionsPerYearDidacticMaterials':
					errors.push(1);
					break;
				case 'actionsPerYearBootCamps':
					errors.push(1);
					break;
				case 'interventionDistrictCodes':
					errors.push(2);
					break;
				case 'tgpList':
					errors.push(3);
					break;
				case 'incubatorLocations':
					errors.push(4);
					break;
				case 'equipmentDistribution':
					errors.push(5);
					break;
				default:
					break;
			}
		});

		let errorMessages=[];

		if (
			!EaAccreditationFormCustomValidator.validateGETListSize(
				formikPropsRef.current.values,
				setError,
				intl,errorMessages
			)
		) {
			errors.push(0);
		}

		if (
			!EaAccreditationFormCustomValidator.validateTGPListSize(
				formikPropsRef.current.values,
				setError,
				intl,errorMessages
			)
		) {
			errors.push(3);
		}

		if (
			!EaAccreditationFormCustomValidator.validateSsAndAtAuthorizations(
				formikPropsRef.current.values,
				documents,
				setError,
				intl,errorMessages
			)
		) {
			errors.push(6);
		}

		if (
			!EaAccreditationFormCustomValidator.validateGETAndDistricts(
				formikPropsRef.current.values,
				setError,
				intl,
				errorMessages,
				formikPropsRef.current.setFieldError,
				districList
			)
		) {
			errors.push(0);
			errors.push(2);
		}

		if(errorMessages.length>0){
			let message = (errorMessages.length === 1) ? createCustomErrorMessage(errorMessages[0]) : createCustomErrorMessage( '<p>'+  intl.formatMessage({
				id: 'errors.more'
			})+'</p><p> - '+ errorMessages.join('</p><p> - ')+'</p>') ;

			setError(message);
		}

		return errors;
	}

	function renderStep(formikProps) {
		formikPropsRef.current = formikProps;

		switch (formStep) {
			case 0:
				return (
					<formSteps.EaAccreditationFormStep1
						formStep={1}
						numberOfSteps={tabsIds.length}
						errors={formikProps.errors}
						formikValues={formikProps.values}
						handleChange={formikProps.handleChange}
						handleNextStep={handleNextStep}
						setFieldValue={formikProps.setFieldValue}
						districts={districList}
						districtOptions={districtOptions}
						readMode={readMode}
					/>
				);
			case 1:
				return (
					<formSteps.EaAccreditationFormStep2
						formStep={2}
						numberOfSteps={tabsIds.length}
						errors={formikProps.errors}
						formikValues={formikProps.values}
						handleChange={formikProps.handleChange}
						handlePreviousStep={handlePreviousStep}
						handleNextStep={handleNextStep}
						setFieldValue={formikProps.setFieldValue}
						tableHeaders={Object.keys(
							formikProps.values.entitiesPerYearInvestment
						)}
						readMode={readMode}
					/>
				);
			case 2:
				return (
					<formSteps.EaAccreditationFormStep3
						formStep={3}
						numberOfSteps={tabsIds.length}
						errors={formikProps.errors}
						formikValues={formikProps.values}
						handlePreviousStep={handlePreviousStep}
						handleNextStep={handleNextStep}
						districts={districList}
						setFieldValue={formikProps.setFieldValue}
						readMode={readMode}
					/>
				);
			case 3:
				return (
					<formSteps.EaAccreditationFormStep4
						formStep={4}
						numberOfSteps={tabsIds.length}
						errors={formikProps.errors}
						formikValues={formikProps.values}
						handlePreviousStep={handlePreviousStep}
						handleNextStep={handleNextStep}
						handleChange={formikProps.handleChange}
						setFieldValue={formikProps.setFieldValue}
						qualificationLevelOptions={qualificationLevelList.filter(ql => acceptedQualificationLevels.includes(ql.code)).map(ql => ql.code)}
						managerQualificationLevelOptions={qualificationLevelList.filter(ql => managerAcceptedQualificationLevels.includes(ql.code)).map(ql => ql.code)}
						districts={districList}
						readMode={readMode}
					/>
				);
			case 4:
				return (
					<formSteps.EaAccreditationFormStep5
						formStep={5}
						numberOfSteps={tabsIds.length}
						errors={formikProps.errors}
						formikValues={formikProps.values}
						handleChange={formikProps.handleChange}
						handlePreviousStep={handlePreviousStep}
						handleNextStep={handleNextStep}
						setFieldValue={formikProps.setFieldValue}
						districts={districList.filter((d) =>
							formikProps.values.interventionDistrictCodes.includes(d.code)
						)}
						setError={setError}
						readMode={readMode}
					/>
				);
			case 5:
				return (
					<formSteps.EaAccreditationFormStep6
						formStep={6}
						numberOfSteps={tabsIds.length}
						errors={formikProps.errors}
						formikValues={formikProps.values}
						handleChange={formikProps.handleChange}
						handlePreviousStep={handlePreviousStep}
						handleNextStep={handleNextStep}
						setFieldValue={formikProps.setFieldValue}
						districts={districList.filter((d) =>
							formikProps.values.interventionDistrictCodes.includes(d.code)
						)}
						districtOptions={districtOptions}
						readMode={readMode}
					/>
				);
			case 6:
				return (
					<formSteps.EaAccreditationFormStep7
						formStep={7}
						numberOfSteps={tabsIds.length}
						errors={formikProps.errors}
						formikValues={formikProps.values}
						handleChange={formikProps.handleChange}
						handlePreviousStep={handlePreviousStep}
						setError={setError}
						documents={documents}
						setDocuments={setDocuments}
						readMode={readMode}
					/>
				);
			default:
				console.log('Something went wrong rendering the form step');
		}
	}

	const analysisStates = [AccreditationDecisionState.VALIDATED, AccreditationDecisionState.INVALIDATED, AccreditationDecisionState.APPEALED, AccreditationDecisionState.APPEAL_EXPIRED];
	const finalRejectionStates = [AccreditationDecisionState.APPEALED, AccreditationDecisionState.APPEAL_EXPIRED];
	const isFinalRejection = accreditationDecision?.state != null && finalRejectionStates.includes(accreditationDecision.state);
	const canRenew = eaAccreditation != null && eaAccreditation?.canRenew;
	const isInAnalysis = accreditationDecision?.state != null && analysisStates.includes(accreditationDecision.state) &&  formikInitialValues.entity.state === EntityState.EVALUATION;
	const isMainDelegation = isIEFP && accreditationDecision != null && accreditationDecision?.mainRegionalDelegationCode === regionalDelegationCode;
	const isHeadQuartersDelegation = isIEFP && originalEntity != null && originalEntity?.regionalDelegationCode === regionalDelegationCode;
	const isRevoked = originalEntity != null && originalEntity?.revoked === true;
	const isUnaccreditedEntity = isEntityAndUnaccredited();

	return (
		<SubTemplate
			titleId={'eaAccreditationForm.title'}
			subTitleId={'eaAccreditationForm.subTitle'}
			centerContent={true}
			hasBackButton={!isUnaccreditedEntity}
			stateBadgeProps={StateAndDateBadge.propsFromAccreditation(eaAccreditation)}
		>
			<Container>
				<DecisionDialog
					show={showValidate}
					handleConfirm={submitValidate}
					handleClose={() => {
						setShowValidate(false)
					}}
					titleId='ea.accreditation.info.validate.dialog.title'
					bodyId='ea.accreditation.info.validate.dialog.body'
					needReason={false}
				/>
				<DecisionDialog
					show={showInvalidate}
					handleConfirm={submitInvalidate}
					handleClose={() => {
						setShowInvalidate(false)
					}}
					titleId='ea.accreditation.info.invalidate.dialog.title'
					bodyId='ea.accreditation.info.invalidate.dialog.body'
					needReason={true}
					reasonLabelId={'eaAccreditationForm.decisionDialog.reject.reason'}
				/>
				<EADecisionDialog
					show={showAccept}
					handleConfirm={submitAccept}
					handleClose={() => {
						setShowAccept(false)
					}}
					entityNIPC={entityNIPC || selfNipc}
					requiredFileTypes={requiredApprovalFileTypes}
					approvalDocuments={approvalDocuments}
					setApprovalDocuments={setApprovalDocuments}
					titleId='accreditationDecisionState.accept.action'
					bodyId='eaAccreditationForm.decisionDialog.accept.body'
					needReason={false}
				/>
				<EaARejectWithFilesDialog
					show={showReject}
					handleConfirm={submitReject}
					handleClose={() => {
						setShowReject(false)
					}}
					titleId={isFinalRejection ? 'eaAccreditationForm.decisionDialog.rejectFinal.title' : 'eaAccreditationForm.decisionDialog.reject.title'}
					bodyId={isFinalRejection ? 'eaAccreditationForm.decisionDialog.rejectFinal.body' : 'eaAccreditationForm.decisionDialog.reject.body'}
					reasonLabelId={'eaAccreditationForm.decisionDialog.reject.reason'}
					isFinal={isFinalRejection}
				/>
				<EaADAppealModal
					entityNif={selfNipc}
					show={appealModalShow}
					documents={appealDocuments}
					setDocuments={setAppealDocuments}
					setShow={setAppealModalShow}
					onSubmitCallback={afterAppeal}
				/>
				<EaProtocolModal
					entityNif={selfNipc || entityNIPC}
					show={showProtocolModal}
					documents={protocolDocuments}
					setDocuments={setProtocolDocuments}
					setShow={setShowProtocolModal}
					onSubmitCallback={afterFullyApprove}
					entity={originalEntity}
				/>
				<CloseableModalDialog
					titleId={'candidatureHistory.title'}
				  	show={showHistory}
					setShow={setShowHistory}
				>
					{!Arrays.isEmpty(history) && history.map((h, index) =>
						<Link key={h} className='d-flex align-items-left justify-content-left w-100 mb-4 mt-3' to={`?version=${index}`} onClick={() => setShowHistory(false)}>
							{h}
						</Link>
					)}
					<Link className='d-flex align-items-left justify-content-left w-100 mb-4 mt-3' to={`?`} onClick={() => setShowHistory(false)}>
						<FormattedMessage id={'ea.accreditation.history.currentVersion'}/>
					</Link>
				</CloseableModalDialog>
				<Row className='mb-5'>
					<Col md='2'>
						<FormSideNavBar
							tabsIdsArray={tabsIds}
							formStep={formStep}
							setFormStep={setFormStep}
							navErrors={navErrors}
							setNavErrors={setNavErrors}
						/>
					</Col>
					<Col md='8'>
						<AlertError error={error} />
						{lsItem && <AlertSuccess lsItem={lsItem} />}
						<SuccessToast
							setShow={setShowSaveToast}
							show={showSaveToast}
							message={<FormattedMessage id='eaAccreditationForm.saveToastInformation' />}
						/>
						<SuccessToast
							setShow={setShowSubmittedToast}
							show={showSubmittedToast}
							message={<FormattedMessage id='eaAccreditationForm.submitToastInformation' />}
						/>
						<EAAResults accreditationDecision={accreditationDecision} />
						<Formik
							enableReinitialize={readMode}
							initialValues={formikInitialValues}
							validateOnBlur={false}
							validateOnChange={false}
							validationSchema={eaAccreditationFormSchema}
							onSubmit={handleSubmit}
						>
							{(formikProps) => (
								<Form onSubmit={formikProps.handleSubmit}>
									{renderStep(formikProps)}
								</Form>
							)}
						</Formik>
					</Col>
					<Col md='2'>
						{!version && !isIEFP && formikInitialValues.entity.state === EntityState.DRAFT && (
						<div>
							<button
								className='btn btn-outline-primary d-flex align-items-center justify-content-center mb-4 w-100'
								type='button'
								onClick={() => handleSave()}
							>
								<FaRegSave />
								<FormattedMessage id='all.save' />
							</button>
							<button
								className='btn btn-primary d-flex align-items-center justify-content-center mb-4 w-100'
								type='button'
								onClick={() => handleSubmit()}
							>
								<FaRegCheckCircle />
								<FormattedMessage id='all.submitButtonText' />
							</button>
						</div>
						)}
						{!version && isIEFP && formikInitialValues.entity.state === EntityState.SUBMITTED
						&& delegationDecision != null && delegationDecision?.result === undefined && (
							<div>
								<button
									className='btn btn-primary d-flex align-items-center justify-content-center mb-4 w-100'
									type='button'
									onClick={() => setShowInvalidate(true)}
								>
									<FaTimes /> <FormattedMessage id='all.invalidateButtonText' />
								</button>
								<button
									className='btn btn-primary d-flex align-items-center justify-content-center mb-4 w-100'
									type='button'
									onClick={() => setShowValidate(true)}
								>
									<FaCheck /> <FormattedMessage id='all.validateButtonText' />
								</button>
							</div>
						)}
						{!version && isInAnalysis && isMainDelegation && (
							<div>
								<button
									className='btn btn-primary d-flex align-items-center justify-content-center mb-4 w-100'
									type='button'
									onClick={() => setShowReject(true)}

								>
									<FaTimes /> <FormattedMessage id='accreditationDecisionState.reject.action' />
								</button>
								<button
									className='btn btn-primary d-flex align-items-center justify-content-center mb-4 w-100'
									type='button'
									onClick={() => setShowAccept(true)}

								>
									<FaCheck /> <FormattedMessage id='accreditationDecisionState.accept.action' />
								</button>
							</div>
						)}
						{!version && ((isMainDelegation && entityNIPC != null) || selfNipc) && accreditationDecision!==null && accreditationDecision.state === AccreditationDecisionState.ACCEPTED && (
							<div>
								<button
									className='btn btn-outline-primary d-flex align-items-center justify-content-center mb-4 w-100'
									type='button'
									onClick={async () =>  navigate(`/credenciacaoEntidade/documentos/${entityNIPC || selfNipc}`)}
								>
									<FormattedMessage id='candidatureValidation.decisionDocuments' />
								</button>
							</div>
						)}


						{!version && !isIEFP && accreditationDecision != null && formikInitialValues.entity.state === EntityState.EVALUATION
						&& accreditationDecision?.state === AccreditationDecisionState.AWAITING_APPEAL && (
							<div>
								<button
									className='btn btn-outline-primary d-flex align-items-center justify-content-center mb-4 w-100'
									type='button'
									onClick={() => setAppealModalShow(true)}
								>
									<FormattedMessage id='candidature.button.contest' />
								</button>
							</div>
						)}
						{!Arrays.isEmpty(history) &&
							<div>
								<button
									className='btn btn-outline-primary d-flex align-items-center justify-content-center mb-4 w-100'
									type='button'
									onClick={() => setShowHistory(true)}
								>
									<FormattedMessage id='candidature.button.history' />
								</button>
							</div>
						}

						{!version && isMainDelegation && isIEFP && accreditationDecision != null && accreditationDecision?.state === AccreditationDecisionState.ACCEPTED
						&& formikInitialValues.entity.state === EntityState.EVALUATION &&
							<div>
								<button
									className='btn btn-outline-primary d-flex align-items-center justify-content-center mb-4 w-100'
									type='button'
									onClick={() => setShowProtocolModal(true)}
								>
									<FormattedMessage id='eaAccreditationForm.button.uploadProtocol' />
								</button>
							</div>
						}
					</Col>
				</Row>
			</Container>
		</SubTemplate>
	);
}
