import { Row, Col, Form, Modal, Dropdown } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { SearchInputDropdown } from '../general/SearchInputDropdown';
import {
	getEntitySupportedApprovedCandidatureProjectInfo,
	getIasValueAtContractCelebration,
	listEntitySupportedApprovedCandidaturesProcessNumbers,
} from '../../rest/priorSupport';
import React, { useState } from 'react';

export function PriorSupportPaymentRequestStep2({
	formStep,
	numberOfSteps,
	formikValues,
	handlePreviousStep,
	handleNextStep,
	setError,
	setFieldValue,
	readMode,
	candidatureProjectInfo,
	setCandidatureProjectInfo,
}) {
	const intl = useIntl();

	const [
		showPriorSupportAlreadyHasPaymentModal,
		setShowPriorSupportAlreadyHasPaymentModal,
	] = useState(false);

	const [
		priorSupportThatAlreadyHasPayment,
		setPriorSupportThatAlreadyHasPayment,
	] = useState(null);

	function handleModalClose() {
		setShowPriorSupportAlreadyHasPaymentModal(false);
		setPriorSupportThatAlreadyHasPayment(null);
	}

	function dropdownItemsGenerator(candidatureProcessNumbersMappings) {
		return Object.keys(candidatureProcessNumbersMappings).map((key, index) => (
			<Dropdown.Item
				key={index}
				onClick={() =>
					candidatureProcessNumberSelectionHandler(
						key,
						candidatureProcessNumbersMappings[key]
					)
				}
			>
				{key}
			</Dropdown.Item>
		));
	}

	async function candidatureProcessNumberSelectionHandler(
		processNumber,
		candidatureMapping
	) {
		if (candidatureMapping.b7Link) {
			candidatureMapping.candidatureProcessNumber = processNumber;
			setShowPriorSupportAlreadyHasPaymentModal(true);
			setPriorSupportThatAlreadyHasPayment(candidatureMapping);
			return;
		}

		let queryParams = {
			candidatureProcessNumber: processNumber,
		};

		const [{ data: projectInfo }, { data: iasAtContractCelebration }] =
			await Promise.all([
				await getEntitySupportedApprovedCandidatureProjectInfo(processNumber),
				await getIasValueAtContractCelebration(queryParams),
			]);

		setCandidatureProjectInfo(projectInfo);
		setFieldValue('candidatureProcessNumber', processNumber);
		setFieldValue('iasAtContractCelebration', iasAtContractCelebration);
	}

	function resetCandidatureProcessNumber() {
		setFieldValue('candidatureProcessNumber', '');
		setFieldValue('iasAtContractCelebration', '');
	}

	return (
		<div>
			<h4 className='text-primary mb-0'>
				<FormattedMessage id='priorSupportPaymentRequest.step3.title' />
			</h4>
			<small className='text-secondary text-uppercase'>
				{intl.formatMessage(
					{ id: 'all.step' },
					{ step: formStep, numberOfSteps: numberOfSteps }
				)}
			</small>
			{showPriorSupportAlreadyHasPaymentModal && (
				<Modal
					show={showPriorSupportAlreadyHasPaymentModal}
					onHide={handleModalClose}
				>
					<Modal.Header closeButton>
						<Modal.Title>
							<FormattedMessage id='candidature.text' />{' '}
							{' (' +
								priorSupportThatAlreadyHasPayment.candidatureProcessNumber +
								')'}{' '}
						</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<p>
							<FormattedMessage id='priorSupportPaymentRequest.step2.modal.candidatureAlreadyHasPayment' />
						</p>
						<p>
							<FormattedMessage id='priorSupportPaymentRequest.step2.modal.candidatureAlreadyHasPaymentLinkMessage.part1' />
							<a
								href={`${priorSupportThatAlreadyHasPayment.b7Link}`}
								target='_blank'
								rel='noopener noreferrer'
							>
								<FormattedMessage id='priorSupportPaymentRequest.step2.modal.candidatureAlreadyHasPaymentLinkMessage.part2' />
							</a>
							<FormattedMessage id='priorSupportPaymentRequest.step2.modal.candidatureAlreadyHasPaymentLinkMessage.part3' />
						</p>
					</Modal.Body>
				</Modal>
			)}
			<fieldset disabled={readMode==true}>
				{!readMode && (
					<Row>
						<Col md='4'>
							<Form.Group>
								<Form.Label>
									<FormattedMessage id='priorSupportPaymentRequest.step3.processNumber' />
								</Form.Label>
								<SearchInputDropdown
									filterQueryParamName='candidatureProcessNumber'
									searchCallback={
										listEntitySupportedApprovedCandidaturesProcessNumbers
									}
									optionChosenHandler={candidatureProcessNumberSelectionHandler}
									setError={setError}
									initialFilterValue={formikValues.candidatureProcessNumber}
									setSelectedOptionInfo={setCandidatureProjectInfo}
									resetFormValueHandler={resetCandidatureProcessNumber}
									itemsFunctionGenerator={dropdownItemsGenerator}
								/>
							</Form.Group>
						</Col>
					</Row>
				)}
				{candidatureProjectInfo !== null && (
					<>
						<Row>
							<Col>
								<Form.Group>
									<Form.Label>
										<FormattedMessage id='candidature.text' />
									</Form.Label>
									<p>{formikValues.candidatureProcessNumber}</p>
								</Form.Group>
							</Col>
							<Col md='4'>
								<Form.Group>
									<Form.Label>
										<FormattedMessage id='priorSupportPaymentRequest.step3.approvalDate' />
									</Form.Label>
									<p>{candidatureProjectInfo.projectApprovalDate}</p>
								</Form.Group>
							</Col>
						</Row>
						<Row>
							<Col md='8'>
								<Form.Group>
									<Form.Label>
										<FormattedMessage id='priorSupportPaymentRequest.step3.name' />
									</Form.Label>
									<p>{candidatureProjectInfo.promoterName}</p>
								</Form.Group>
							</Col>
							<Col md='4'>
								<Form.Group>
									<Form.Label>
										<FormattedMessage id='priorSupportPaymentRequest.step3.cc' />
									</Form.Label>
									<p>{candidatureProjectInfo.promoterCCBI}</p>
								</Form.Group>
							</Col>
						</Row>
						<Row>
							<Col md='8'>
								<Form.Group>
									<Form.Label>
										<FormattedMessage id='priorSupportPaymentRequest.step3.email' />
									</Form.Label>
									<p>{candidatureProjectInfo.promoterEmail}</p>
								</Form.Group>
							</Col>
							<Col md='4'>
								<Form.Group>
									<Form.Label>
										<FormattedMessage id='priorSupportPaymentRequest.step3.niss' />
									</Form.Label>
									<p>{candidatureProjectInfo.promoterNISS}</p>
								</Form.Group>
							</Col>
						</Row>
						<Row>
							<Col md='12'>
								<Form.Group>
									<Form.Label>
										<FormattedMessage id='priorSupportPaymentRequest.step3.address' />
									</Form.Label>
									<p>{candidatureProjectInfo.promoterAddress}</p>
								</Form.Group>
							</Col>
						</Row>
						<Row>
							<Col md='4'>
								<Form.Group>
									<Form.Label>
										<FormattedMessage id='priorSupportPaymentRequest.step3.contractStartDate' />
									</Form.Label>
									<p>{candidatureProjectInfo.effectiveContractStartDate}</p>
								</Form.Group>
							</Col>
							<Col md='4'>
								<Form.Group>
									<Form.Label>NIF</Form.Label>
									<p>{candidatureProjectInfo.promoterNIF}</p>
								</Form.Group>
							</Col>
						</Row>
					</>
				)}
			</fieldset>
			<div className='form-actions mt-5'>
				<button
					className='btn btn-link'
					type='button'
					onClick={handlePreviousStep}
				>
					<FormattedMessage id='all.back' />
				</button>

				<button
					className='btn btn-primary'
					type='button'
					onClick={handleNextStep}
				>
					<FormattedMessage id='all.nextStep' />
				</button>
			</div>
		</div>
	);
}
