import {apiAxios as authAxios} from "../axios.config";


export const getParentProcessId = (payableUntypedExternalId) => {
    return authAxios.get(`/api/process/${payableUntypedExternalId}/payments/parentProcessExternalId`);
};

export const getOverview = (processExternalId) => {
    return authAxios.get(`/api/process/${processExternalId}/payments/overview`);
};

export const getPayments = (processExternalId) => {
    return authAxios.get(`/api/process/${processExternalId}/payments`);
};

export const getPayment = (processExternalId, paymentExternalId) => {
    return authAxios.get(`/api/process/${processExternalId}/payments/${paymentExternalId}`);
};

export const addPayment = (processExternalId, data) => {
    return authAxios.post(`/api/process/${processExternalId}/payments`, data);
};