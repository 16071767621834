import React, { useEffect, useState } from 'react';
import { SubTemplate } from '../../../components/general/SubTemplate';
import { BoundType } from '../../../models/BoundType';
import { MessageType } from '../../../models/MessageType';
import { handleError, isNotBusinessError } from '../../../utils/handleError';

import { ComponentLoading } from '../../../components/ComponentLoading';
import { MessageFilterBar } from '../../../components/messages/MessageFilterBar';
import { MessageList } from '../../../components/messages/MessageList';
import { PaginationComponent } from '../../../components/PaginationComponent';
import { getUserMessages } from '../../../rest/messages';
import { Col, Container, Row } from 'react-bootstrap';

const MAX_RESULTS = 15;
const DEFAULT_INITIAL_PAGE = 0;

export function MessagesNotification() {
	const [messagesData, setMessagesData] = useState({});
	const [currentPage, setCurrentPage] = useState(DEFAULT_INITIAL_PAGE);
	const [currentFilters, setCurrentFilters] = useState(null);
	const [isFiltered, setIsFiltered] = useState(false);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);

	const fetchMessages = async (index) => {
		try {
			const { data: messagesData } = await getUserMessages(
				null,
				MessageType.INTERNAL,
				BoundType.IN,
				null,
				null,
				index,
				MAX_RESULTS
			);

			setMessagesData(messagesData);
			setCurrentPage(currentPage);
		} catch (error) {
			setError(error);
		} finally {
			setLoading(false);
		}
	};

	const filterMessages = async (filters, index) => {
		setMessagesData({});
		setLoading(true);
		try {
			const { data: messagesData } = await getUserMessages(
				filters.keyword,
				filters.type,
				filters.boundType,
				filters.state,
				filters.parentExternalId,
				index ? index : currentPage,
				MAX_RESULTS
			);
			setMessagesData(messagesData);
			setIsFiltered(true);
			setCurrentFilters(filters);
			setCurrentPage(index);
		} catch (error) {
			setError(error);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		if (isFiltered) {
			filterMessages(currentFilters, currentPage);
		} else {
			fetchMessages(currentPage);
		}
	}, [currentPage]);

	const onPageChange = (pageNumber) => {
		setCurrentPage(pageNumber);
	};

	if (error && isNotBusinessError(error)) {
		return handleError(error);
	}

	return (
		<SubTemplate hasBackButton titleId='messages.messagesText'>
			<Container>
				<MessageFilterBar
					defaultBoundTypeFilter={BoundType.IN}
					onSubmit={filterMessages}
				/>
				<Row className='mt-5'>
					<Col>
						{loading ? (
							<ComponentLoading />
						) : (
							<>
								<PaginationComponent
									dataPerPage={MAX_RESULTS}
									totalData={messagesData.numberOfMessages}
									onPageChange={onPageChange}
									currentPage={currentPage}
								/>
								<MessageList messages={messagesData.messages} />
							</>
						)}
					</Col>
				</Row>
			</Container>
		</SubTemplate>
	);
}
