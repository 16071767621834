import {FormattedMessage, useIntl} from "react-intl";
import React from "react";
import {Col, Row} from "react-bootstrap";
import {DateTimeInputField} from "../../bootstrap/DateTimeInputField";

export function EaRenewalDates({
     formStep,
     numberOfSteps,
     errors,
     handleChange,
     formikValues,
     handlePreviousStep,
     handleNextStep,
     setFieldValue,
     readMode = false
 }) {
    const intl = useIntl();
    return (
        <div>
            <h4 className='text-primary mb-0'>
                <FormattedMessage id='eaAccreditationForm.sideNavBar.renewal.dates' />
            </h4>
            <small className='text-secondary text-uppercase'>
                {intl.formatMessage(
                    { id: 'all.step' },
                    { step: formStep, numberOfSteps: numberOfSteps }
                )}
            </small>
            <Row>
                <Col>
                    <DateTimeInputField
                        disabled={readMode}
                        labelId='eaAccreditationForm.renewal.date.begin'
                        value={formikValues.startDate}
                        name='startDate'
                        setFieldValue={setFieldValue}
                        isDateWithNoTime={true}
                        isInvalid={errors.startDate}
                        errorMessage={errors.startDate}
                    />
                </Col>
                <Col>
                    <DateTimeInputField
                        disabled={readMode}
                        labelId='eaAccreditationForm.renewal.date.end'
                        value={formikValues.endDate}
                        name='endDate'
                        setFieldValue={setFieldValue}
                        isDateWithNoTime={true}
                        isInvalid={errors.endDate}
                        errorMessage={errors.endDate}
                    />
                </Col>
            </Row>
            <div className='form-actions mt-5'>
                <button
                    className='btn btn-link'
                    type='button'
                    onClick={handlePreviousStep}
                >
                    <FormattedMessage id='all.back' />
                </button>

                <button
                    className='btn btn-primary'
                    type='button'
                    onClick={handleNextStep}
                >
                    <FormattedMessage id='all.nextStep' />
                </button>
            </div>
        </div>
    );
}