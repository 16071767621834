import React, { useEffect, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import useIntl from 'react-intl/lib/src/components/useIntl';
import { Link, Navigate, useParams } from 'react-router-dom';
import { AlertError } from '../../../components/bootstrap/AlertError';
import { AuthenticatedDownloadLink } from '../../../components/document/AuthenticatedDownloadLink';
import Loading from '../../../components/general/Loading';
import { SubTemplate } from '../../../components/general/SubTemplate';
import { createCustomErrorMessage } from '../../../hooks/errorMessage';
import { DocumentTypeDescriptor } from '../../../models/DocumentTypeDescriptor';
import { getCandidatureByExternalId, setToAcceptanceTermIssuance } from '../../../rest/candidature';
import { listCandidatureDocumentsByDescriptions } from '../../../rest/document';
import { handleError, isNotBusinessError } from '../../../utils/handleError';
import { Strings } from '../../../utils/Strings';
import { TranslateBoolean } from '../../../utils/TranslateBoolean';
import { DecisionDialog } from '../../../components/bootstrap/DecisionDialog';

export function ValidateCandidatureApprovedRequiredDocuments() {
  const DOCUMENT_TYPES = [
    'NEW_COMPANY_REGISTRATION_PROOF',
    'NEW_COMPANY_START_ACTIVITY_PROOF',
    'ACTIVITY_EXERCISE_DOCS',
    'FACILITIES_OWNERSHIP_DOC',
    'TRANSFER_PROPERTY_AGREEMENT_DOC',
    'FRANCHISE_AGREEMENT_DOC',
    'IBAN_DOC',
    'CALENDARING',
    'NO_DEBT_AT_DECLARATION',
    'NO_DEBT_SS_DECLARATION'
  ];

  const MULTIPLE_UPLOAD_DOCUMENT_TYPES = ['ACTIVITY_EXERCISE_DOCS'];

  const intl = useIntl();

  const { externalId } = useParams();

  const [files, setFiles] = useState([]);
  const [filesDocumentTypes, setFilesDocumentTypes] = useState([]);
  const [candidature, setCandidature] = useState([]);
  const [linkUrlValid, setLinkUrlValid] = useState(null);


  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [submited, setSubmited] = useState(false);

  const [showFileMarkedAsInvalidModal, setShowFileMarkedAsInvalidModal] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);

  async function fetchData() {
    try {

      const [{ data: candidature }] = await Promise.all([
        await getCandidatureByExternalId(externalId)
      ]);

      if(candidature.candidatureDetails.wasCreated!==true){
        DOCUMENT_TYPES.push('NEW_COMPANY_REGISTRATION_PROOF','NEW_COMPANY_START_ACTIVITY_PROOF','MINIMIS_DECLARATION')
      }


      const [{ data: candidatureDocuments }] = await Promise.all([
        await listCandidatureDocumentsByDescriptions(
          DocumentTypeDescriptor.CANDIDATURE,
          externalId,
          DOCUMENT_TYPES
        )
      ]);

      setFiles(getInitialFiles(candidatureDocuments));
      setFilesDocumentTypes(candidatureDocuments.map((doc) => doc.documentType))
      setCandidature(candidature);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  }

  function getInitialFiles(candidatureDocuments) {
    let filesToReturn = [];

    candidatureDocuments.forEach((cd) => {
      filesToReturn.push({
        externalId: cd.externalId,
        documentType: cd.documentType,
        documentPath: cd.documentPath,
        name: cd.name,
        content: '',
        submited: true,
        error: '',
        validation: ''
      });
    });

    return filesToReturn;
  }

  function isAnyFileMarkedAsInvalid(){
    if(candidature.candidatureDetails.wasCreated!==true && linkUrlValid ==='NO'){
      return true;
    }

    for (let index in files){
      if(files[index].validation === "NO"){
        return true 
      }
    }
    return false;
  }

  async function submitValidationOfRequiredDocuments() {
    setLoading(true);
    try {
      const dataToSend = getDataToSend();
      await setToAcceptanceTermIssuance(externalId, dataToSend);
      setSubmited(true);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  }

  function getDataToSend() {
    let filesToSend = [];

    files.forEach((file) => {
      filesToSend.push({
        externalId: file.externalId,
        documentType: file.documentType,
        name: file.name,
        validation: file.validation
      });
    });

    let dataToSend={documentsMetaData: filesToSend};
    if(candidature.candidatureDetails.wasCreated!==true){
      dataToSend.urlValidation=linkUrlValid;
    }




    return dataToSend;
  }

  function handleValidation(file, validation) {
    file.validation = validation;
    setFiles([...files]);
  }



  function handleValidationUrl(validation) {
    setLinkUrlValid(validation);
  }

  function validateFields() {
    let valid = true;

    files.forEach((file) => {
      if (Strings.isBlank(file.validation)) {
        setError(
          createCustomErrorMessage(
            intl.formatMessage({ id: 'errors.submitDeclarationModal.required' })
          )
        );
        valid = false;
      }
    });

    return valid;
  }

  function modalTriage(){
    if (validateFields()){
      if(isAnyFileMarkedAsInvalid()){
        setShowFileMarkedAsInvalidModal(true)
      }
      else{
        submitValidationOfRequiredDocuments()
      } 
    }
  }

  if (submited) {
    if(isAnyFileMarkedAsInvalid()){
      sessionStorage.setItem('candidatureApprovedDocumentsNotValidated', submited);
    }
    else{
      sessionStorage.setItem('candidatureApprovedDocumentsValidated', submited);
    }
    return <Navigate replace to={`/candidatura/${externalId}`} />;
  }

  if (loading) {
    return <Loading />;
  }

  if (error && isNotBusinessError(error)) {
    return handleError(error);
  }

  return (
    <SubTemplate hasBackButton>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-8 offset-lg-2 mb-5'>
            <AlertError error={error} />
            { showFileMarkedAsInvalidModal && (
                <DecisionDialog 
                  show = {showFileMarkedAsInvalidModal}
                  handleConfirm={submitValidationOfRequiredDocuments}
                  handleClose = {() => {setShowFileMarkedAsInvalidModal(false)}}
                  titleId = 'decisionDialog.validateRequiredDocuments.title'
                  bodyId = 'decisionDialog.validateRequiredDocuments.body'
                  needReason = {false}
                />
            )}
            <h4 className='text-primary mb-0'>
              <FormattedMessage id='candidature.validateApprovedRequiredDocuments.title'></FormattedMessage>
            </h4>
            <small className='text-secondary text-uppercase'>
              <FormattedMessage id='candidature.validateApprovedRequiredDocuments.subTitle'></FormattedMessage>
            </small>

            <div className='border p-4 mt-4 mb-5'>
              <Row className='mb-4'>
                <Col md='12'>
                  <label className='d-inline-block'>
                    <FormattedMessage id='candidature.submitApprovedRequiredDocuments.repaymentPlanModality' />
                    :{' '}
                    <span className='text-dark'>
                      <FormattedMessage
                        id={`repaymentPlanModality.${candidature.candidatureDetails.repaymentPlanModality}`}
                      />
                    </span>
                  </label>
                </Col>
                <Col md='12'>
                  <label className='d-inline-block'>
                    <FormattedMessage id='candidature.validateApprovedRequiredDocuments.isIRSServicesAuthorized' />{' '}
                    <span className='text-dark'>
                      <TranslateBoolean
                        value={candidature.candidatureDetails.IRSServicesAuthorized}
                      />
                    </span>
                  </label>
                </Col>
                <Col md='12'>
                  <label className='d-inline-block'>
                    <FormattedMessage id='candidature.validateApprovedRequiredDocuments.isSocialServicesAuthorized' />{' '}
                    <span className='text-dark'>
                      <TranslateBoolean
                        value={candidature.candidatureDetails.socialServicesAuthorized}
                      />
                    </span>
                  </label>
                </Col>
              </Row>

              <Row>
                <Col className='border-top pt-3'>
                  <label className='mb-3'>Documentos a validar</label>
                </Col>
              </Row>

              {files
                .filter((file) => !MULTIPLE_UPLOAD_DOCUMENT_TYPES.includes(file.documentType))
                .map((file, index) => (
                  <div key={index} className='bg-light mb-3 px-3'>
                    <Row>
                      <Col md='9'>
                        <label className='mb-0'>
                          <FormattedMessage id={`documentType.${file.documentType}`} />
                        </label>
                      </Col>
                    </Row>
                    {getDocumentValidationContent(file, handleValidation)}
                  </div>
                ))}

              { filesDocumentTypes.includes('ACTIVITY_EXERCISE_DOCS') &&
                <div className='bg-light mb-3 px-3'>
                  <Row>
                    <Col md='9'>
                      <label className='mb-0'>
                        <FormattedMessage id='documentType.ACTIVITY_EXERCISE_DOCS' />
                      </label>
                    </Col>
                  </Row>
                  {files
                    .filter((file) => file.documentType === 'ACTIVITY_EXERCISE_DOCS')
                    .map((file, index) => (
                      <div key={index}>{getDocumentValidationContent(file, handleValidation)}</div>
                    ))}
                </div>
              }


              {(candidature.candidatureDetails.wasCreated!==true) &&
              <React.Fragment>
                <Row>
                  <Col className='border-top pt-3'>
                    <label className='mb-3'><FormattedMessage id={'candidatureDetails.registerLink'}/></label>
                  </Col>
                </Row>

                <div className='d-flex justify-content-between align-items-center'>
                  <p>
                    {candidature.candidatureDetails.registerLink}
                  </p>
                  <div>
                    <Form.Check
                        inline
                        type={'radio'}
                        label={<FormattedMessage id='all.according' />}
                        checked={linkUrlValid === 'YES'}
                        onChange={() => handleValidationUrl('YES')}
                    />
                    <Form.Check
                        inline
                        type={'radio'}
                        label={<FormattedMessage id='all.notAccording' />}
                        checked={linkUrlValid === 'NO'}
                        onChange={() => handleValidationUrl('NO')}
                    />
                  </div>

                </div>




              </React.Fragment>
              }

            </div>




            <div className='form-actions mt-5'>
              <Link className='btn btn-link' to={`/candidatura/${externalId}`}>
                <FormattedMessage id='all.back' />
              </Link>

              <button
                className='btn btn-primary'
                type='button'
                onClick={() => modalTriage()}>
                <FormattedMessage id='all.submitButtonText' />
              </button>
            </div>
          </div>
        </div>
      </div>
    </SubTemplate>
  );
}

function getDocumentValidationContent(file, handleValidation) {
  return (
    <div className='d-flex justify-content-between align-items-center'>
      <p>
        <AuthenticatedDownloadLink url={file.documentPath} filename={file.name}>
          {file.name}
        </AuthenticatedDownloadLink>
      </p>
      <div>
        <Form.Check
          inline
          type={'radio'}
          label={<FormattedMessage id='all.according' />}
          checked={file.validation === 'YES'}
          onChange={() => handleValidation(file, 'YES')}
        />
        <Form.Check
          inline
          type={'radio'}
          label={<FormattedMessage id='all.notAccording' />}
          checked={file.validation === 'NO'}
          onChange={() => handleValidation(file, 'NO')}
        />
      </div>
    </div>
  );
}
