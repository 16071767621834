import React, { useState } from 'react';
import {Col, Modal, Row, Form} from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { createCustomErrorMessage } from '../../hooks/errorMessage';
import { Strings } from '../../utils/Strings';
import {AlertError} from "../bootstrap/AlertError";
import {TextAreaInputField} from "../bootstrap/TextAreaInputField";
import DecimalInputField from "../bootstrap/DecimalInputField";
import {EntityAccreditationDocumentsForm} from "./EntityAccreditationDocumentsForm";

export function EADecisionDialog({
    show,
    handleClose,
    handleConfirm,
    titleId,
    bodyId,
    needReason,
    reasonLabelId,
    approvalDocuments,
    setApprovalDocuments,
    requiredFileTypes,
    entityNIPC,
    bodyValue
}) {
    const intl = useIntl();
    const [reason, setReason] = useState(null);
    const [score, setScore] = useState(null);
    const [error, setError] = useState(null);

    function handleReason(event) {
        setReason(event.target.value);
    }

    function handleScore(event) {
        setScore(parseFloat(event.target.value));
    }

    function validateAndConfirm() {
        if(Strings.isBlank(score)) {
            setError(
                createCustomErrorMessage(
                    intl.formatMessage({ id: 'errors.eaDecision.scoreRequired' })
                )
            );
            return;
        }

        let submittedTypes = approvalDocuments.filter(d => d.submited).map(d => d.documentType);

        if(!requiredFileTypes.every( rft => submittedTypes.includes(rft))) {
            setError(
                createCustomErrorMessage(
                    intl.formatMessage({ id: 'errors.candidatureForm.requiredFiles' })
                )
            );
            return;
        }

        if(needReason) {
            if (Strings.isBlank(reason)) {
                setError(
                    createCustomErrorMessage(
                        intl.formatMessage({ id: 'errors.rejectPriorSupportModal.motiveRequired' })
                    )
                );
                return;
            } else {
                handleConfirm(score, reason);
            }
        } else {
            handleConfirm(score);
        }
    }

    function close() {
        setError(null);
        handleClose();
    }

    if (!show) {
        return <></>;
    } else {
        return (
            <Modal show={show} onHide={close} dialogClassName='modal-35'>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <FormattedMessage id={titleId} />
                    </Modal.Title>
                </Modal.Header>
                <AlertError error={error} />
                <Modal.Body>
                    {bodyValue ? <FormattedMessage id={bodyId}  values={{value:bodyValue}}/> : <FormattedMessage id={bodyId} />}
                    {needReason && (
                    <Row>
                        <Col>
                            <TextAreaInputField
                                controlId={'reason'}
                                label={reasonLabelId? <FormattedMessage id={reasonLabelId}/> :'Motivo'}
                                name={'reason'}
                                value={reason ?? ''}
                                handleChange={handleReason}
                            />
                        </Col>
                    </Row>
                    )}
                    <Row>
                        <Col>
                            <DecimalInputField
                                labelId={'eaAccreditationForm.step1.score'}
                                controlId={'score'}
                                name={'score'}
                                handleChange={handleScore}
                                value={score}
                                placeHolder={0}
                                maxValue={100}
                                suffixLabel={'%'}
                            />
                            <Form.Range
                                name={'score'}
                                value={score || 0}
                                min={0}
                                max={100}
                                onChange={handleScore}
                            />
                        </Col>
                    </Row>
                    <Row className={'mt-3 mb-3'}>
                        <Col>
                            <FormattedMessage id={'ea.accreditation.accept.documents.title'} />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <EntityAccreditationDocumentsForm
                                entityNIPC={entityNIPC}
                                documents={approvalDocuments}
                                setDocuments={setApprovalDocuments}
                                error={error}
                                setError={setError}
                                handleSubmit={() => alert('hey!')}
                            />
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer className='justify-content-between'>
                    <button className={'btn btn-outline-primary px-5'} onClick={close}>
                        <FormattedMessage id='all.no' />
                    </button>
                    <button className={'btn btn-outline-primary px-5'} onClick={() => validateAndConfirm()}>
                        <FormattedMessage id='all.yes' />
                    </button>
                </Modal.Footer>
            </Modal>
        );
    }
}
