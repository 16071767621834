import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import ToolTip from '../bootstrap/ToolTip';
import { TextAreaInputField } from '../bootstrap/TextAreaInputField';
import { DateTimeInputField } from '../bootstrap/DateTimeInputField';
import { EnumSelectField } from '../bootstrap/EnumSelectField';
import { TextInputField } from '../bootstrap/TextInputField';
import { MceActivityType } from '../../models/MceActivityType';
import { ProcessState } from '../../models/ProcessState';
import { SubmittedByEntity } from '../general/SubmittedByEntity';
import { ApprovedByPromoter } from '../general/ApprovedByPromoter';

export function ActivitySheetFormStep3({
	formStep,
	numberOfSteps,
	errors,
	handleChange,
	formikValues,
	handlePreviousStep,
	setFieldValue,
	entityGetList,
	readMode,
}) {
	const intl = useIntl();

	return (
		<div>
			<h4 className='text-primary mb-0'>
				<FormattedMessage id='activitySheetForm.sideNavBar.visitPurpose' />
				<ToolTip
					message={
						<FormattedMessage id='activitySheetForm.sideNavBar.visitPurpose.toolTip' />
					}
				/>
			</h4>
			<small className='text-secondary text-uppercase'>
				{intl.formatMessage(
					{ id: 'all.step' },
					{ step: formStep, numberOfSteps: numberOfSteps }
				)}
			</small>
			<fieldset disabled={readMode==true}>
				<Row>
					<Col md='6'>
						<EnumSelectField
							nullOption={true}
							preffixDescriptionId='mceActivityType'
							labelId='activitySheetForm.activity'
							name='activity'
							options={MceActivityType.values()}
							value={formikValues.activity}
							handleChange={handleChange}
							isInvalid={errors.activity}
							errorMessage={errors.activity}
						/>
					</Col>
					<Col md='6'>
						<DateTimeInputField
							labelId='activitySheetForm.date'
							name='activityDate'
							value={formikValues.activityDate}
							setFieldValue={setFieldValue}
							isDateWithNoTime={true}
							isInvalid={errors.activityDate}
							errorMessage={errors.activityDate}
						/>
					</Col>
				</Row>
				<Row>
					<Col md='12'>
						<TextAreaInputField
							name='description'
							value={formikValues.description}
							labelId='activitySheetForm.visitDescription'
							handleChange={handleChange}
							isInvalid={errors.description}
							errorMessage={errors.description}
						/>
					</Col>
					<Col md='12'>
						<TextAreaInputField
							name='results'
							value={formikValues.results}
							labelId='activitySheetForm.results'
							handleChange={handleChange}
							isInvalid={errors.results}
							errorMessage={errors.results}
						/>
					</Col>
				</Row>
				{formikValues.identificationEa.technicalTeamManagerExternalId !==
					'-1' && (
					<Row>
						<Col md='8'>
							<TextInputField
								labelId='activitySheetForm.technicalTeamManager'
								value={
									entityGetList.filter(
										(get) =>
											get.externalId ===
											formikValues.identificationEa
												.technicalTeamManagerExternalId
									)?.[0]?.name
								}
								disabled
							/>
						</Col>
					</Row>
				)}
				{(formikValues.processState === ProcessState.SUBMITTED ||
					formikValues.processState === ProcessState.APPROVED) && (
					<SubmittedByEntity
						className='mt-5'
						entityName={formikValues.identificationEa.entity.description}
						submissionDate={formikValues.submissionDate}
					/>
				)}

				{formikValues.processState === ProcessState.APPROVED && (
					<ApprovedByPromoter
						approvalDate={formikValues.approvalDate}
						className='mt-4'
						promoterName={formikValues.promoterName}
					/>
				)}
			</fieldset>
			<div className='form-actions mt-5'>
				<button
					className='btn btn-link'
					type='button'
					onClick={handlePreviousStep}
				>
					<FormattedMessage id='all.back' />
				</button>
			</div>
		</div>
	);
}
