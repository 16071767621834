import React, {useEffect, useState} from 'react';
import {Container} from 'react-bootstrap';
import {useNavigate, useParams} from 'react-router-dom';
import {AlertError} from '../../components/bootstrap/AlertError';
import Loading from '../../components/general/Loading';
import {SubTemplate} from '../../components/general/SubTemplate';
import {getCandidatureByExternalId} from '../../rest/candidature';
import {handleError, isNotBusinessError} from '../../utils/handleError';
import {CandidatureState} from "../../models/CandidatureState";
import {AdditionalDueDiligenceAnalysisForm} from "../../components/candidature/AdditionalDueDiligenceAnalysisForm";
import {getAdditionalDueDiligenceByCandidature} from "../../rest/additionalDueDiligences";


export function CandidatureAdditionalDueDiligenceAnalysis() {

    const {candidatureExternalId} = useParams();
    const navigate = useNavigate();

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [userDocuments, setUserDocuments] = useState([]);
    const [additionalDueDiligence, setAdditionalDueDiligence] = useState(null);

    async function fetchData() {
        try {
            const [
                {data: additionalDueDiligence}
            ] = await Promise.all([
                getAdditionalDueDiligenceByCandidature(candidatureExternalId)
            ]);
            setAdditionalDueDiligence(additionalDueDiligence);
        } catch (error) {
            setError(error);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        fetchData();
    }, []);


    if (loading) {
        return <Loading/>;
    }

    if (error && isNotBusinessError(error)) {
        return handleError(error);
    }

    return (
        <SubTemplate hasBackButton
                     titleId='candidature.additionalDueDiligenceAnalysis.title'>
            <Container>
                <AlertError error={error}/>
                    <AdditionalDueDiligenceAnalysisForm
                        userDocuments={userDocuments}
                        externalId={candidatureExternalId}
                        additionalDueDiligence={additionalDueDiligence}
                    />
            </Container>
        </SubTemplate>
    );
}
