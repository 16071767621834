import { apiAxios as authAxios } from '../axios.config';

export const deleteParameter = (externalId) => {
  return authAxios.delete(`api/parameter/${externalId}`);
};

export const getParameters = () => {
  return authAxios.get('api/parameter');
};

export const updateParameter = (externalId, parameter) => {
  return authAxios.put(`/api/parameter/${externalId}`, parameter);
};

export const getParameterByExternalId = (externalId) => {
  return authAxios.get(`/api/parameter/externalId/${externalId}`);
};

export const addParameter = (parameter) => {
  return authAxios.post('/api/parameter', parameter);
};
