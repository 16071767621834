import { FormattedMessage } from 'react-intl';
import * as yup from 'yup';

export const MceFinancingFormUtils = {
	validationObject: () => validationObject(),
	getFormikInitialValuesMceFinancing: (initialValues) =>
		getFormikInitialValuesMceFinancing(initialValues),
};

function validationObject() {
	let validationObject = {
		contractDuration: yup
			.number()
			.required(<FormattedMessage id='errors.fieldRequiredText' />)
			.test(
				'isNumberValid',
				<FormattedMessage id='errors.mceFinancing.contractDuration' />,
				(value) => value > 0 && value < 37
			),
	};

	return yup.object().shape(validationObject);
}

function getFormikInitialValuesMceFinancing(mceFinancing) {
	mceFinancing.contractDuration ??= '';
}
