import {Modal, Button, Form, Col} from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import React, {useEffect, useState} from "react";
import useIntl from "react-intl/lib/src/components/useIntl";
import {SingleUploadArea} from "../SingleUploadArea";
import {deleteDocument, getRevocationDocumentsByType, uploadDocument} from "../../rest/revocation";
import Loading from "../general/Loading";
import {MultipleUploadArea} from "../MultipleUploadArea";
import {DateTimeInputField} from "../bootstrap/DateTimeInputField";

export function DecisionRevocation({show,titleLabelId,submitButtonLabelId, handleClose,submitHandler,setError, externalId,type,finalState}) {


  const [validated, setValidated] = useState(false);
  const [reason, setReason] = useState('');
  const [privateDocuments, setPrivateDocuments] = useState([]);
  const [clarificationDocuments, setClarificationDocuments] = useState([]);
  const [publicDocuments, setPublicDocuments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [invalidDateMessage,setInvalidDateMessage] = useState(null);
  const [initialDate,setInitialDate] = useState(null);
  const [invalidDocumentMessage,setInvalidDocumentMessage] = useState(null);

  const uploadCallback = async (formData) => {
    return await uploadDocument(externalId, formData);
  };

  const deleteCallback = async (documentExternalId) => {
    return await deleteDocument(externalId, documentExternalId);
  };

  useEffect(() => {
    fetchData();
  }, []);


  const fetchData = async () => {
    try {

      if (type === 'clarification' || type==='finalDocument') {
        const [{data: constestationDocuments}] =
            await Promise.all([
              getRevocationDocumentsByType(externalId,  type==='finalDocument'? 'REVOCATION_DOCUMENT': 'CONTESTATION_REVOCATION'),
            ]);
        loadDocuments(constestationDocuments.results, setClarificationDocuments,type==='finalDocument'? 'REVOCATION_DOCUMENT':  'CONTESTATION_REVOCATION');
      } else {
        const [{data: privateDocuments}, {data: publicDocuments}] =
            await Promise.all([
              getRevocationDocumentsByType(externalId, finalState === true ? 'FINAL_P_DECISION_REVOCATION' : 'DECISION_P_REVOCATION'),
              getRevocationDocumentsByType(externalId, finalState===true ? 'FINAL_DECISION_REVOCATION' : 'DECISION_REVOCATION'),
            ]);


        loadDocument(publicDocuments.results, setPublicDocuments, finalState===true ? 'FINAL_DECISION_REVOCATION' : 'DECISION_REVOCATION');
        loadDocument(privateDocuments.results, setPrivateDocuments, finalState===true ? 'FINAL_P_DECISION_REVOCATION' : 'DECISION_P_REVOCATION');
      }


    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };



  function loadDocuments (data,setDocument, documentType){
    let documentsFinal = [];
    if (data.length !== 0) {
      data.forEach((document) => {
        documentsFinal.push({
          documentType: document.documentType,
          externalId: document.externalId,
          name: document.name,
          documentPath: document.documentPath,
          content: '',
          submited: true,
          error: ''
        });
      });
      setDocument(documentsFinal);
    }
  }


  function loadDocument(data, setDocument, documentType) {
    if (data.length !== 0) {
      const document = data[0];
      setDocument([
        {
          documentType: document.documentType,
          externalId: document.externalId,
          name: document.name,
          documentPath: document.documentPath,
          content: '',
          submited: true,
          error: ''
        }
      ]);
    } else {
      setDocument([
        {
          documentType: documentType,
          externalId: '',
          name: '',
          content: null,
          submited: false,
          error: ''
        }
      ]);
    }
  }


  if (loading) {
    return <Loading />;
  }

  function onSubmit(event) {
    event.preventDefault();
    const form = event.currentTarget;
    setInvalidDateMessage(null);
    if(type==='finalDocument' &&  (initialDate===null || initialDate ===undefined)){
      setInvalidDateMessage(<FormattedMessage id={'errors.fieldRequiredText'}/>);
    }if(type==='finalDocument' &&  (clarificationDocuments.length===0)) {
      setInvalidDocumentMessage( <FormattedMessage id={'errors.fieldRequiredText'}/> );
    }else if (form.checkValidity() === true) {
        submitHandler(type==='finalDocument'? initialDate : reason);
        handleClose();
      } else {
        event.stopPropagation();
      }

      setValidated(true);
    }


  function canSubmit() {

    if(type==='clarification'){
      return reason.length===0;
    }else if(type!=='finalDocument'){
      return publicDocuments[0].submited===false || privateDocuments[0].submited===false
    }
  }



  if(!show){
  return <>
    </>
}else{
  return (
      <>
        <Modal size="lg" show={show} onHide={handleClose}>
          <Form noValidate validated={validated} onSubmit={onSubmit}>
            <Modal.Header>
              <FormattedMessage id={titleLabelId} />
            </Modal.Header>
            <Modal.Body>

              <Form.Group>



                {type=== 'finalDocument' ?

                    <DateTimeInputField
                        name={'sendDate'}
                        labelId={'revocationForm.initialDate'}
                        value={initialDate}
                        isDateWithNoTime={true}
                        setFieldValue={(name,value) => setInitialDate(value)}
                        isInvalid={invalidDateMessage}
                        errorMessage={invalidDateMessage}
                    />


                    :
                    <>
                      <Form.Label><FormattedMessage id={type==='clarification'? 'revocationForm.contestation' : 'revocationForm.validationJustification'} /></Form.Label>
                      <Form.Control as="textarea" name="reason" value={reason} onChange={event => setReason(event.target.value)} rows={4} />
                    </>


                }


              </Form.Group>

              {(type==='clarification'  || type==='finalDocument') ?
                  (<div className={"mt-5"}>
                    <Form.Label><FormattedMessage id={type ==='clarification' ? 'documentType.CONTESTATION_REVOCATION': 'documentType.REVOCATION_DOCUMENT'} /></Form.Label>
                    <MultipleUploadArea
                        documents={clarificationDocuments}
                        setDocuments={setClarificationDocuments}
                        documentType= {type ==='clarification' ?  'CONTESTATION_REVOCATION' : 'REVOCATION_DOCUMENT'}
                        uploadCallback={uploadCallback}
                        deleteCallback={deleteCallback}
                        overrideClass={'pt-0'}
                    />
                    {invalidDocumentMessage!== null &&

                    <div className={'invalid-feedback'}>{invalidDocumentMessage}</div>
                    }


                  </div>)
                  :
                  (
                      <>
                        <div className={"mt-3"}>
                          <SingleUploadArea
                              documents={publicDocuments}
                              setDocuments={setPublicDocuments}
                              documentType={finalState===true ? 'FINAL_DECISION_REVOCATION':  'DECISION_REVOCATION'}
                              setError={setError}
                              processUploadCallback={uploadCallback}
                              processDeleteCallback={deleteCallback}
                          />
                        </div>
                        <div className={"mt-3"}>
                          <SingleUploadArea
                              documents={privateDocuments}
                              setDocuments={setPrivateDocuments}
                              documentType={finalState===true ? 'FINAL_P_DECISION_REVOCATION':  'DECISION_P_REVOCATION'}
                              setError={setError}
                              processUploadCallback={uploadCallback}
                              processDeleteCallback={deleteCallback}
                          />
                        </div>
                      </>
                  )

              }


            </Modal.Body>
            <Modal.Footer>


              <Button variant="outline-secondary" onClick={handleClose}   >
                <FormattedMessage id="all.cancel" />
              </Button>
              <Button type="submit" variant="outline-primary" disabled={canSubmit()}>
                <FormattedMessage     id={submitButtonLabelId} />
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>

      </>
  );
}


}
