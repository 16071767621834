import { apiAxios as authAxios } from '../axios.config';

export const listDocumentsByDescriptor = (descriptor) => {
    return authAxios.get(`/api/document/descriptortype/${descriptor}`)
};

export const listDocumentsByDocumentType = (documentType) => {
  return authAxios.get(`/api/document/type/${documentType}`);
};

export const listDocumentsByDescriptorAndCandidatureId = (descriptor, externalId) => {
    return authAxios.get(`/api/document/${descriptor}/${externalId}`)
};


export const listDocumentsByDescriptorAndCandidatureIdGrouped = (descriptor, externalId) => {
    return authAxios.get(`/api/document/${descriptor}/${externalId}/grouped`)
};

export const listCandidatureDocumentsByDescriptions = (
  descriptor,
  externalId,
  descriptions
) => {
  return authAxios.post(
    `/api/document/${descriptor}/${externalId}/descriptions`,
    descriptions
  );
};

export const uploadDocument = (formData, entityRelated) => {
    return entityRelated ? authAxios.post('/api/document/entity', formData) : authAxios.post('/api/document', formData);
};

export const deleteDocument = (externalId, entityRelated) => {
    return entityRelated ? authAxios.delete(`/api/document/entity/${externalId}`) : authAxios.delete(`/api/document/${externalId}`)
};

export const uploadDocumentByCandidatureId = (formData, candidatureExternalId) => {
    return authAxios.post(`/api/candidature/${candidatureExternalId}/documents`, formData);
};

export const deleteDocumentbyCandidatureId = (externalId, candidatureExternalId) => {
    return authAxios.delete(`/api/candidature/${candidatureExternalId}/documents/${externalId}`);
};

export const listDocumentsByTypeAndCandidatureExternalId = (documentType, candidatureExternalId) => {
    return authAxios.get(`/api/candidature/${candidatureExternalId}/documents/type/${documentType}`)
};

export const listEntityDocuments = (queryParams) => {
  return authAxios.get(`/api/document/entity`, {params:  queryParams})
};

export const listEntityDetailDocuments = (queryParams) => {
  return authAxios.get(`/api/document/entityDetail`, {params:  queryParams})
};

export const listCandidatureIncubationDocuments = (candidatureExternalId) => {
  return authAxios.get(`/api/document/candidature/${candidatureExternalId}/incubation/`)
};
