import { FormattedMessage } from 'react-intl';
import * as yup from 'yup';

export const CommercialActivityColumnFormUtils = {
	validationObject: () => validationObject(),
	getFormikInitialValuesCommercialActivityColumns: (initialValues) =>
		getFormikInitialValuesCommercialActivityColumns(initialValues),
	validatePercentages: (formikValues,intl,
		errorMessages) =>
	validatePercentages(formikValues, intl,
		errorMessages),
};

function validationObject() {
	let validationObject = {
		local: yup
			.number()
			.required(<FormattedMessage id='errors.fieldRequiredText' />)
			.test(
				'isPercentage',
				<FormattedMessage id='errors.percentageNumber' />,
				(value) => value >= 0 && value <= 100
			),
		regional: yup
			.number()
			.required(<FormattedMessage id='errors.fieldRequiredText' />)
			.test(
				'isPercentage',
				<FormattedMessage id='errors.percentageNumber' />,
				(value) => value >= 0 && value <= 100
			),
		national: yup
			.number()
			.required(<FormattedMessage id='errors.fieldRequiredText' />)
			.test(
				'isPercentage',
				<FormattedMessage id='errors.percentageNumber' />,
				(value) => value >= 0 && value <= 100
			),
		otherSalesType: yup
			.number()
			.required(<FormattedMessage id='errors.fieldRequiredText' />)
			.test(
				'isPercentage',
				<FormattedMessage id='errors.percentageNumber' />,
				(value) => value >= 0 && value <= 100
			),

		warehouses: yup
			.number()
			.required(<FormattedMessage id='errors.fieldRequiredText' />)
			.test(
				'isPercentage',
				<FormattedMessage id='errors.percentageNumber' />,
				(value) => value >= 0 && value <= 100
			),
		retailers: yup
			.number()
			.required(<FormattedMessage id='errors.fieldRequiredText' />)
			.test(
				'isPercentage',
				<FormattedMessage id='errors.percentageNumber' />,
				(value) => value >= 0 && value <= 100
			),
		endConsumer: yup
			.number()
			.required(<FormattedMessage id='errors.fieldRequiredText' />)
			.test(
				'isPercentage',
				<FormattedMessage id='errors.percentageNumber' />,
				(value) => value >= 0 && value <= 100
			),
		industry: yup
			.number()
			.required(<FormattedMessage id='errors.fieldRequiredText' />)
			.test(
				'isPercentage',
				<FormattedMessage id='errors.percentageNumber' />,
				(value) => value >= 0 && value <= 100
			),
		otherClients: yup
			.number()
			.required(<FormattedMessage id='errors.fieldRequiredText' />)
			.test(
				'isPercentage',
				<FormattedMessage id='errors.percentageNumber' />,
				(value) => value >= 0 && value <= 100
			),

		standardEstablishedMeans: yup
			.number()
			.required(<FormattedMessage id='errors.fieldRequiredText' />)
			.test(
				'isPercentage',
				<FormattedMessage id='errors.percentageNumber' />,
				(value) => value >= 0 && value <= 100
			),
		salesPersons: yup
			.number()
			.required(<FormattedMessage id='errors.fieldRequiredText' />)
			.test(
				'isPercentage',
				<FormattedMessage id='errors.percentageNumber' />,
				(value) => value >= 0 && value <= 100
			),
		byCatalog: yup
			.number()
			.required(<FormattedMessage id='errors.fieldRequiredText' />)
			.test(
				'isPercentage',
				<FormattedMessage id='errors.percentageNumber' />,
				(value) => value >= 0 && value <= 100
			),
		fairs: yup
			.number()
			.required(<FormattedMessage id='errors.fieldRequiredText' />)
			.test(
				'isPercentage',
				<FormattedMessage id='errors.percentageNumber' />,
				(value) => value >= 0 && value <= 100
			),
		atFacilities: yup
			.number()
			.required(<FormattedMessage id='errors.fieldRequiredText' />)
			.test(
				'isPercentage',
				<FormattedMessage id='errors.percentageNumber' />,
				(value) => value >= 0 && value <= 100
			),
		commissioners: yup
			.number()
			.required(<FormattedMessage id='errors.fieldRequiredText' />)
			.test(
				'isPercentage',
				<FormattedMessage id='errors.percentageNumber' />,
				(value) => value >= 0 && value <= 100
			),
		internet: yup
			.number()
			.required(<FormattedMessage id='errors.fieldRequiredText' />)
			.test(
				'isPercentage',
				<FormattedMessage id='errors.percentageNumber' />,
				(value) => value >= 0 && value <= 100
			),
		otherSalesMeans: yup
			.number()
			.required(<FormattedMessage id='errors.fieldRequiredText' />)
			.test(
				'isPercentage',
				<FormattedMessage id='errors.percentageNumber' />,
				(value) => value >= 0 && value <= 100
			),
	};

	return yup.array().of(yup.object().shape(validationObject));
}

function getFormikInitialValuesCommercialActivityColumns(initialValues) {
	initialValues.commercialActivities.forEach((activity) => {
		activity.local ??= '';
		activity.regional ??= '';
		activity.national ??= '';
		activity.otherSalesType ??= '';

		activity.warehouses ??= '';
		activity.retailers ??= '';
		activity.endConsumer ??= '';
		activity.industry ??= '';
		activity.otherClients ??= '';

		activity.standardEstablishedMeans ??= '';
		activity.salesPersons ??= '';
		activity.byCatalog ??= '';
		activity.fairs ??= '';
		activity.atFacilities ??= '';
		activity.commissioners ??= '';
		activity.internet ??= '';
		activity.otherSalesMeans ??= '';
	});
}

function validatePercentages(formikValues, intl, errorMessages){
	
		for(let commercialActivityColumn of formikValues.commercialActivities){
			let salesByMarketTypeSum = parseFloat(commercialActivityColumn.local) + parseFloat(commercialActivityColumn.regional) + parseFloat(commercialActivityColumn.national) + parseFloat(commercialActivityColumn.otherSalesType);
			
			let salesByClientTypeSum = parseFloat(commercialActivityColumn.warehouses) + parseFloat(commercialActivityColumn.retailers) + parseFloat(commercialActivityColumn.endConsumer) + parseFloat(commercialActivityColumn.industry) + parseFloat(commercialActivityColumn.otherClients);
			
			let salesByMeansSum = parseFloat(commercialActivityColumn.standardEstablishedMeans) + parseFloat(commercialActivityColumn.salesPersons) + parseFloat(commercialActivityColumn.byCatalog) 
			+ parseFloat(commercialActivityColumn.fairs) + parseFloat(commercialActivityColumn.atFacilities) + parseFloat(commercialActivityColumn.commissioners) + parseFloat(commercialActivityColumn.internet) +
			+ parseFloat(commercialActivityColumn.otherSalesMeans);

			if(salesByMarketTypeSum !== 100 || salesByClientTypeSum !== 100 || salesByMeansSum !== 100){
				errorMessages.push(
					intl.formatMessage({
						id: 'errors.commercialActivities.percentageDifferentThan100',
					})
				);
				return false;
			}
		}
		
		return true;
}
