import React, { useEffect, useRef, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link, Navigate, useParams } from 'react-router-dom';
import { AlertError } from '../../components/bootstrap/AlertError';
import { EnumSelectField } from '../../components/bootstrap/EnumSelectField';
import Loading from '../../components/general/Loading';
import { SubTemplate } from '../../components/general/SubTemplate';
import { MultipleUploadArea } from '../../components/MultipleUploadArea';
import { SingleUploadArea } from '../../components/SingleUploadArea';
import { createCustomErrorMessage } from '../../hooks/errorMessage';
import { DocumentTypeDescriptor } from '../../models/DocumentTypeDescriptor';
import { RepaymentPlanModality } from '../../models/RepaymentPlanModality';
import {getCandidatureByExternalId, setToValidateRequiredDocuments} from '../../rest/candidature';
import { listCandidatureDocumentsByDescriptions } from '../../rest/document';
import { listDocumentTypesByDescriptions } from '../../rest/documentType';
import { Arrays } from '../../utils/Arrays';
import { handleError, isNotBusinessError } from '../../utils/handleError';
import {TextInputField} from "../../components/bootstrap/TextInputField";
import {Validator} from "../../utils/Validator";

export function CandidatureApprovedSubmitRequiredDocuments() {
  const REQUIRED_DOCUMENT_TYPES = [

    'ACTIVITY_EXERCISE_DOCS',
    'FACILITIES_OWNERSHIP_DOC',
    'TRANSFER_PROPERTY_AGREEMENT_DOC',
    'FRANCHISE_AGREEMENT_DOC',
    'IBAN_DOC',
    'CALENDARING',
    'NO_DEBT_AT_DECLARATION',
    'NO_DEBT_SS_DECLARATION'
  ];

  const NON_APPLICABLE_DOCUMENT_TYPES = [
    'ACTIVITY_EXERCISE_DOCS',
    'FACILITIES_OWNERSHIP_DOC',
    'TRANSFER_PROPERTY_AGREEMENT_DOC',
    'FRANCHISE_AGREEMENT_DOC'
  ];

  const CANDIDATURE_DETAILS_DOCUMENT_TYPES = [
    'CALENDARING',
    'NO_DEBT_AT_DECLARATION',
    'NO_DEBT_SS_DECLARATION'
  ];

  const MULTIPLE_UPLOAD_DOCUMENT_TYPES = ['ACTIVITY_EXERCISE_DOCS'];

  const { externalId } = useParams();
  const intl = useIntl();

  const [documentTypes, setDocumentTypes] = useState([]);
  const [files, setFiles] = useState([]);
  const [candidatureDetails, setCandidatureDetails] = useState(null);
  const [candidature, setCandidature] = useState(null);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [submited, setSubmited] = useState(false);

  const nonApplicableMultipleRef = useRef(false);

  useEffect(() => {
    fetchData();
  }, []);

  async function fetchData() {
    try {

      const [{ data: candidature }] = await Promise.all([
        await getCandidatureByExternalId(externalId)
      ]);
      setCandidature(candidature);

      if(candidature.candidatureDetails.wasCreated!==true){
        REQUIRED_DOCUMENT_TYPES.push('NEW_COMPANY_REGISTRATION_PROOF','NEW_COMPANY_START_ACTIVITY_PROOF','MINIMIS_DECLARATION')
      }



      const [{ data: documentTypes }, { data: candidatureDocuments }] = await Promise.all([
        await listDocumentTypesByDescriptions(REQUIRED_DOCUMENT_TYPES),
        await listCandidatureDocumentsByDescriptions(
          DocumentTypeDescriptor.CANDIDATURE,
          externalId,
          REQUIRED_DOCUMENT_TYPES
        )
      ]);
      setDocumentTypes(documentTypes);
      setFiles(getInitialFiles(documentTypes, candidatureDocuments));
      setCandidatureDetails(getInitialValues(candidatureDocuments));
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  }

  function getInitialFiles(documentTypes, candidatureDocuments) {
    let filesToReturn = [];

    let candidatureDocumentTypes = candidatureDocuments.map((cd) => cd.documentType);

    documentTypes
      .filter((dt) => !MULTIPLE_UPLOAD_DOCUMENT_TYPES.includes(dt.description))
      .forEach((dt) => {
        if (candidatureDocumentTypes.includes(dt.description)) {
          const document = candidatureDocuments.find((cd) => cd.documentType === dt.description);

          filesToReturn.push({
            externalId: document.externalId,
            documentType: document.documentType,
            fileDescription: document.fileDescription ? document.fileDescription : '',
            name: document.name,
            content: '',
            submited: true,
            error: '',
            nonApplicable: CANDIDATURE_DETAILS_DOCUMENT_TYPES.includes(dt.description)
              ? 'false'
              : false
          });
        } else {
          filesToReturn.push({
            externalId: '',
            documentType: dt.description,
            name: '',
            content: null,
            submited: false,
            error: '',
            nonApplicable: CANDIDATURE_DETAILS_DOCUMENT_TYPES.includes(dt.description)
              ? 'false'
              : false
          });
        }
      });

    const multipleUploadDocuments = candidatureDocuments.filter((cd) =>
      MULTIPLE_UPLOAD_DOCUMENT_TYPES.includes(cd.documentType)
    );

    if (Arrays.isNotEmpty(multipleUploadDocuments)) {
      multipleUploadDocuments.forEach((mud) => {
        filesToReturn.push({
          externalId: mud.externalId,
          documentType: mud.documentType,
          fileDescription: mud.fileDescription,
          name: mud.name,
          content: '',
          submited: true,
          error: '',
          nonApplicable: false
        });
      });
    }
    return filesToReturn;
  }

  function getInitialValues(candidatureDocuments) {
    return {
      repaymentPlanModality: {
        value:
          candidatureDocuments.filter((cd) => cd.documentType === 'CALENDARING').length > 0
            ? RepaymentPlanModality.AMORTIZATION_PROPOSAL
            : '-1',
        hasErrors: false,
        errorMessage: ''
      },
      isSocialServicesAuthorized: {
        value:
          candidatureDocuments.filter((cd) => cd.documentType === 'NO_DEBT_SS_DECLARATION').length >
          0
            ? 'false'
            : null,
        hasErrors: false,
        errorMessage: ''
      },
      isIRSServicesAuthorized: {
        value:
          candidatureDocuments.filter((cd) => cd.documentType === 'NO_DEBT_AT_DECLARATION').length >
          0
            ? 'false'
            : null,
        hasErrors: false,
        errorMessage: ''
      },
      link: {
        value:'',
        hasErrors: false,
        errorMessage: ''
      }

    };
  }

  async function submitRequiredDocuments() {
    setLoading(true);
    try {
      const dataToSend = getDataToSend();
      await setToValidateRequiredDocuments(externalId, dataToSend);
      setSubmited(true);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  }

  function getDataToSend() {
    let dataToSend = {
      documentsMetaData: [],
      repaymentPlanModality: candidatureDetails.repaymentPlanModality.value,
      IRSServicesAuthorized: candidatureDetails.isIRSServicesAuthorized.value === 'true',
      socialServicesAuthorized: candidatureDetails.isSocialServicesAuthorized.value === 'true',
      link:candidatureDetails.link.value
    };

    files.forEach((file) => {
      dataToSend.documentsMetaData.push({
        documentType: file.documentType,
        nonApplicable: file.nonApplicable
      });
    });

    MULTIPLE_UPLOAD_DOCUMENT_TYPES.forEach((mudt) => {
      if (!files.find((file) => file.documentType === mudt)) {
        dataToSend.documentsMetaData.push({
          documentType: mudt,
          nonApplicable: true
        });
      }
    });

    return dataToSend;
  }

  function handleSubmit() {
    const isFilesValid = validateFiles();
    const isCandidatureDetailsValid = validateCandidatureDetails();

    if (isFilesValid && isCandidatureDetailsValid) {
      setError(null);
      submitRequiredDocuments();
    }
  }

  function validateFiles() {
    let valid = true;

    let documentTypesToExclude = []

    if(candidatureDetails.repaymentPlanModality.value !== RepaymentPlanModality.AMORTIZATION_PROPOSAL){
      documentTypesToExclude.push('CALENDARING');
    }

    if(candidatureDetails.isSocialServicesAuthorized.value !==false){
      documentTypesToExclude.push('NO_DEBT_SS_DECLARATION');
    }

    if(candidatureDetails.isIRSServicesAuthorized.value !==false){
      documentTypesToExclude.push('NO_DEBT_AT_DECLARATION');
    }





   let filesFiltered = files
      .filter((file) => (!file.nonApplicable || file.nonApplicable === 'false') && !documentTypesToExclude.includes(file.documentType))
      .map((file) => {
        if (!file.submited) {
          console.log(file)
          valid = false;

        }
      });
    console.log(filesFiltered)
    console.log(nonApplicableMultipleRef)
    console.log((!files.find((file) => file.documentType === 'ACTIVITY_EXERCISE_DOCS') &&
        nonApplicableMultipleRef.current===false
    ))

    if (!files.find((file) => file.documentType === 'ACTIVITY_EXERCISE_DOCS') &&
      nonApplicableMultipleRef.current===false
    ) {
      valid = false;
    }
    if(!valid){
      setError(
          createCustomErrorMessage(
              intl.formatMessage({
                id: 'errors.candidatureForm.requiredFiles'
              })
          )
      );
    }

    return valid;
  }

  function validateCandidatureDetails() {
    let valid = true;


    if(candidatureDetails.repaymentPlanModality.value === '-1'){
      candidatureDetails.repaymentPlanModality.hasErrors=true;
      candidatureDetails.repaymentPlanModality.errorMessage= intl.formatMessage({
        id: 'errors.fieldRequiredText'
      });
      valid=false;
    }else{
      candidatureDetails.repaymentPlanModality.hasErrors=false;
      candidatureDetails.repaymentPlanModality.errorMessage= '';
    }


    if(candidatureDetails.isSocialServicesAuthorized.value=== null){
      candidatureDetails.isSocialServicesAuthorized.hasErrors=true;
      candidatureDetails.isSocialServicesAuthorized.errorMessage= intl.formatMessage({
        id: 'errors.fieldRequiredText'
      });
      valid=false;
    }else{
      candidatureDetails.isSocialServicesAuthorized.hasErrors=false;
      candidatureDetails.isSocialServicesAuthorized.errorMessage= '';
    }


    if(candidatureDetails.isIRSServicesAuthorized.value === null){
      candidatureDetails.isIRSServicesAuthorized.hasErrors=true;
      candidatureDetails.isIRSServicesAuthorized.errorMessage= intl.formatMessage({
        id: 'errors.fieldRequiredText'
      });
      valid=false;
    }else{
      candidatureDetails.isIRSServicesAuthorized.hasErrors=false;
      candidatureDetails.isIRSServicesAuthorized.errorMessage= '';
    }


    if(candidature.candidatureDetails.wasCreated===false){
      if(candidatureDetails.link.value === null || candidatureDetails.link.value===undefined || candidatureDetails.link.value===''){
        candidatureDetails.link.hasErrors=true;
        candidatureDetails.link.errorMessage= intl.formatMessage({
          id: 'errors.candidatureForm.invalidLink'
        });
        valid=false;
      }else{
        candidatureDetails.link.hasErrors=false;
        candidatureDetails.link.errorMessage= '';
      }
    }


    setCandidatureDetails({...candidatureDetails});


    return valid;
  }

  function handleCandidatureDetailsChange(e, name) {
    switch (name) {
      case 'repaymentPlanModality':
        validateAndChange(e, name, 'CALENDARING');
        break;

      case 'isSocialServicesAuthorized':
        validateAndChange(e, name, 'NO_DEBT_SS_DECLARATION');
        break;

      case 'isIRSServicesAuthorized':
        validateAndChange(e, name, 'NO_DEBT_AT_DECLARATION');
        break;
      case 'registerLink':
        candidatureDetails.link.value = e.target.value;


      default:
        break;
    }
    setCandidatureDetails({ ...candidatureDetails });
  }

  function validateAndChange(e, name, documentType) {
    if (
      (name === 'repaymentPlanModality' &&
        e.target.value !== RepaymentPlanModality.AMORTIZATION_PROPOSAL) ||
      (name === 'isSocialServicesAuthorized' && e.target.value === 'true') ||
      (name === 'isIRSServicesAuthorized' && e.target.value === 'true')
    ) {
      if (getFileByDocumentType(documentType).submited) {
        setError(
          createCustomErrorMessage(
            intl.formatMessage({
              id: `errors.removeDocumentFirst.${documentType}`
            })
          )
        );
        return;
      } else {
        getFileByDocumentType(documentType).nonApplicable = 'true';
      }
    } else {
      getFileByDocumentType(documentType).nonApplicable = 'false';
    }

    candidatureDetails[name].value = e.target.value;
    setFiles([...files]);
  }

  function getFileByDocumentType(documentType) {
    return files.find((file) => file.documentType === documentType);
  }

  function canShowUploadArea(documentType) {
    switch (documentType) {
      case 'CALENDARING':
        return (
          getFileByDocumentType(documentType).nonApplicable === 'false' &&
          candidatureDetails.repaymentPlanModality.value ===
            RepaymentPlanModality.AMORTIZATION_PROPOSAL
        );

      case 'NO_DEBT_AT_DECLARATION':
        return (
          getFileByDocumentType(documentType).nonApplicable === 'false' &&
          candidatureDetails.isIRSServicesAuthorized.value === 'false'
        );

      case 'NO_DEBT_SS_DECLARATION':
        return (
          getFileByDocumentType(documentType).nonApplicable === 'false' &&
          candidatureDetails.isSocialServicesAuthorized.value === 'false'
        );

      default:
        break;
    }
  }

  if (submited) {
    sessionStorage.setItem('candidatureApprovedDocumentsSubmited', submited);
    return <Navigate replace to={`/candidatura/${externalId}`} />;
  }

  if (loading) {
    return <Loading />;
  }

  if (error && isNotBusinessError(error)) {
    return handleError(error);
  }

  return (
    <SubTemplate>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-8 offset-lg-2 mb-5'>
            <AlertError error={error} />
            <h4 className='text-primary mb-0'>
              <FormattedMessage id='candidature.submitApprovedRequiredDocuments.title' />
            </h4>
            <small className='text-secondary text-uppercase'>
              <FormattedMessage id='candidature.submitApprovedRequiredDocuments.subTitle' />
            </small>
            <div className='border p-4 mt-4'>
              <h5 className='text-primary mb-3'>
                <FormattedMessage id='candidature.submitApprovedRequiredDocuments.subtitle' />
              </h5>

              {documentTypes
                .filter(
                  (dt) =>
                    dt.description !== 'ACTIVITY_EXERCISE_DOCS' &&
                    !CANDIDATURE_DETAILS_DOCUMENT_TYPES.includes(dt.description)
                )
                .map((dt, index) => (
                  <div key={index}>
                    <SingleUploadArea
                      documents={files}
                      setDocuments={setFiles}
                      documentType={dt.description}
                      documentTypeDescriptor={DocumentTypeDescriptor.CANDIDATURE}
                      setError={setError}
                      candidatureExternalId={externalId}
                      nonApplicableOptionValue={NON_APPLICABLE_DOCUMENT_TYPES.includes(
                        dt.description || files.filter((f)=> f.documentType===dt.description)?.[0].nonApplicable
                      )}
                    />
                  </div>
                ))}

              <MultipleUploadArea
                documents={files}
                setDocuments={setFiles}
                candidatureExternalId={externalId}
                documentType={
                  documentTypes.find((d) => d.description === 'ACTIVITY_EXERCISE_DOCS').description
                }
                labelId='documentType.ACTIVITY_EXERCISE_DOCS'
                nonApplicableOptionValue={true}
                nonApplicableMultipleRef={nonApplicableMultipleRef}
              />

              <h5 className='text-primary mt-5 mb-0'>
                <FormattedMessage id='candidature.submitApprovedRequiredDocuments.subtitle1' />
              </h5>

              <Row className='mb-4'>
                <Col>
                  <EnumSelectField
                    name='repaymentPlanModality'
                    labelId='candidature.submitApprovedRequiredDocuments.repaymentPlanModality'
                    preffixDescriptionId='repaymentPlanModality'
                    options={RepaymentPlanModality.values()}
                    value={candidatureDetails.repaymentPlanModality.value}
                    handleChange={(e) => handleCandidatureDetailsChange(e, 'repaymentPlanModality')}
                    isInvalid={candidatureDetails.repaymentPlanModality.hasErrors}
                    errorMessage={candidatureDetails.repaymentPlanModality.errorMessage}
                    nullOption={true}
                  />
                </Col>
              </Row>

              {canShowUploadArea('CALENDARING') && (
                <SingleUploadArea
                  documents={files}
                  setDocuments={setFiles}
                  documentType={'CALENDARING'}
                  documentTypeDescriptor={DocumentTypeDescriptor.CANDIDATURE}
                  setError={setError}
                  candidatureExternalId={externalId}
                />
              )}


              {candidature.candidatureDetails?.wasCreated===false  &&(
                  <>
                  <h5 className='text-primary mt-5 mb-0'>
                    <FormattedMessage id='candidature.submitApprovedRequiredDocuments.subtitle3' />
                  </h5>
                  <Row>
                    <Col md='12'>
                      <TextInputField
                          controlId={'registerLink'}
                          labelId={'candidatureDetails.registerLink'}
                          name={'registerLink'}
                          handleChange={(e) =>  handleCandidatureDetailsChange(e, 'registerLink')}
                          value={candidatureDetails.link.value}
                          isInvalid={candidatureDetails.link.hasErrors}
                          errorMessage={candidatureDetails.link.errorMessage}
                          maxLength={250}
                      />

                    </Col>
                  </Row>
                  </>
              )

              }






              <h5 className='text-primary mt-5 mb-3'>
                <FormattedMessage id='candidature.submitApprovedRequiredDocuments.subtitle2' />
              </h5>

              <div className='mt-4 mb-4'>
                <label>
                  <FormattedMessage id='candidature.submitApprovedRequiredDocuments.isSocialServicesAuthorized' />
                </label>
                <Form.Check
                  inline
                  type={'radio'}
                  label='Sim'
                  value={'true'}
                  checked={candidatureDetails.isSocialServicesAuthorized.value === 'true'}
                  onChange={(e) => handleCandidatureDetailsChange(e, 'isSocialServicesAuthorized')}
                  isInvalid={candidatureDetails.isSocialServicesAuthorized.hasErrors}
                />
                <Form.Check
                  inline
                  type={'radio'}
                  label='Não'
                  value={'false'}
                  checked={candidatureDetails.isSocialServicesAuthorized.value === 'false'}
                  onChange={(e) => handleCandidatureDetailsChange(e, 'isSocialServicesAuthorized')}
                  isInvalid={candidatureDetails.isSocialServicesAuthorized.hasErrors}
                />
              </div>
              <div className='mt-4 mb-4'>
                <label>
                  <FormattedMessage id='candidature.submitApprovedRequiredDocuments.isIRSServicesAuthorized' />
                </label>
                <Form.Check
                  inline
                  type={'radio'}
                  label='Sim'
                  value={'true'}
                  checked={candidatureDetails.isIRSServicesAuthorized.value === 'true'}
                  onChange={(e) => handleCandidatureDetailsChange(e, 'isIRSServicesAuthorized')}
                  isInvalid={candidatureDetails.isIRSServicesAuthorized.hasErrors}

                />
                <Form.Check
                  inline
                  type={'radio'}
                  label='Não'
                  value={'false'}
                  checked={candidatureDetails.isIRSServicesAuthorized.value === 'false'}
                  onChange={(e) => handleCandidatureDetailsChange(e, 'isIRSServicesAuthorized')}
                  isInvalid={candidatureDetails.isIRSServicesAuthorized.hasErrors}
                />
              </div>

              {CANDIDATURE_DETAILS_DOCUMENT_TYPES.filter(
                (cddt) => cddt === 'NO_DEBT_AT_DECLARATION' || cddt === 'NO_DEBT_SS_DECLARATION'
              ).map((cddt, index) => (
                <div key={index}>
                  {canShowUploadArea(cddt) && (
                    <SingleUploadArea
                      documents={files}
                      setDocuments={setFiles}
                      documentType={cddt}
                      documentTypeDescriptor={DocumentTypeDescriptor.CANDIDATURE}
                      setError={setError}
                      candidatureExternalId={externalId}
                    />
                  )}
                </div>
              ))}
            </div>

            <div className='form-actions mt-5'>
              <Link className='btn btn-link' to={`/candidatura/${externalId}`}>
                <FormattedMessage id='all.back' />
              </Link>

              <button className='btn btn-primary' type='button' onClick={() => handleSubmit()}>
                <FormattedMessage id='all.submitButtonText' />
              </button>
            </div>
          </div>
        </div>
      </div>
    </SubTemplate>
  );
}
