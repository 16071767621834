import React, {useEffect, useState} from 'react';
import { Accordion, Col, Form, Row, Table } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { CandidatureFinancialUtils } from '../../utils/CandidatureFinancialUtils';
import { CandidatureFormUtils } from '../../utils/CandidatureFormUtils';
import DecimalInputField from '../bootstrap/DecimalInputField';
import { IntInputField } from '../bootstrap/IntInputField';
import { TextAreaInputField } from '../bootstrap/TextAreaInputField';
import {currencyFormat, maxFixed} from '../../utils/CurrencyUtils';
import ToolTip from "../bootstrap/ToolTip";
import {DateTimeInputField} from "../bootstrap/DateTimeInputField";
import {DateUtils} from "../../utils/DateUtils";
import {AccretionViewer} from "./AccretionViewer";

export function CandidatureFormStep14({
  errors,
  setFieldValue,
  values,
  handleSave,
  handleGoBack,
  numberOfSteps
}) {
  const intl = useIntl();


  const[freeLoans,setFreeLoans] = useState(0);



  async function fetchData() {
    const [ subRepresentation,inSide,levels,iefpAccretionIn ] = await Promise.all([
      await CandidatureFinancialUtils.getSubRepresentation(
          values.candidatureDetails.cae,values.promotersMetaData
      ),
      await CandidatureFinancialUtils.getIsInside(values.parish),
      await  CandidatureFinancialUtils.getLevelsAccretion(values.candidatureEmployeeSupportOtherDetails),
      await  CandidatureFinancialUtils.getIefpAccretion(values.candidatureEmployeeSupportOtherDetails)

    ]);
    const accretionTotal = await CandidatureFinancialUtils.getAccretionTotal(subRepresentation,inSide,levels,iefpAccretionIn,values.candidatureEmployeeSupportOtherDetails)

    const freeLoans2  =
      await CandidatureFinancialUtils.getInterestFreeLoan(values.financialInvestmentDetails.find(
          (ipd) => ipd.financialInvestmentType === 'TOTAL'
      ),values.investmentPlanDetails.filter((ipd) => ( ipd.investmentType === 'TOTAL'))[0],accretionTotal);


    setFreeLoans(freeLoans2<0 ? 0 : freeLoans2)

    if(values.earlyAmortizationList[0].isRequired === undefined){
      YEARS.forEach((year) => {
        getEarlyAmortizationCellListByYear(year).forEach((cell) => {
          calculateDebtCapital(year, cell.month, cell.value,freeLoans2<0 ? 0 : freeLoans2);
        });
      });
    }

    values.earlyAmortizationList[0].isRequired= freeLoans2 >0;

    if(values.earlyAmortizationList[0].isRequired === false || ((values.earlyAmortizationList[0].isRequired === true && getEarlyAmortizationCellListByYear(0)[0].debtCapital !==  freeLoans2 )) ){
      YEARS.forEach((year) => {
        getEarlyAmortizationCellListByYear(year).forEach((cell) => {
          calculateDebtCapital(year, cell.month, cell.value,freeLoans2<0 ? 0 : freeLoans2);
        });
      });
    }



    setFieldValue('earlyAmortizationList', [...values.earlyAmortizationList]);


  }







  useEffect(() => {

    fetchData();
  }, []);


  if(values.earlyAmortizationList[0].appliedDeferralPeriodBenefits ===true){
    updateInitialDate();
  }

  const YEARS = [
    ...new Set(values.earlyAmortizationList[0].earlyAmortizationCellList.map((eacl) => eacl.year))
  ];

  const MONTHS = [
    ...new Set(values.earlyAmortizationList[0].earlyAmortizationCellList.map((eacl) => eacl.month))
  ];

  function getEarlyAmortizationCellByYearAndMonth(year, month) {
    return values.earlyAmortizationList[0].earlyAmortizationCellList.find(
      (eacl) => eacl.month === month && eacl.year === year
    );
  }

  function getEarlyAmortizationCellListByYear(year) {
    return values.earlyAmortizationList[0].earlyAmortizationCellList.filter(
      (eacl) => eacl.year === year
    );
  }

  function getEarlyAmortizationCellValue(year, month, fieldName) {
    return getEarlyAmortizationCellByYearAndMonth(year, month)[fieldName];
  }

  function getTableRowsByYearAndMonth(year, month, index) {
    return (
      <tr key={index}>
        <td className='w-20'>
          <FormattedMessage id={`month.${month}`} />
        </td>
        <td className='w-35'>
          <DecimalInputField
            controlId={`value.${month}`}
            name={`value.${month}`}
            value={getEarlyAmortizationCellValue(year, month, 'value')}
            handleChange={(e) => handleEarlyAmortizationCellChange(e, year, month, 'value')}
            suffixLabel='€'
          />
        </td>
        <td>
          <span>
            {currencyFormat(parseFloat(getEarlyAmortizationCellValue(year, month, 'debtCapital').toFixed(2)))}
          </span>
        </td>
      </tr>
    );
  }

  function handleEarlyAmortizationCellChange(e, yearIN, month, fieldName) {
    if (values.earlyAmortizationList[0].appliedEarlyAmortization === false) {
      YEARS.forEach((year) => {
        getEarlyAmortizationCellListByYear(year).forEach((cell) => {
          if(freeLoans!==0 && (year> yearIN || (year=== yearIN && cell.month>=month) )){
            cell.value = e.target.value;
          }else{
            cell.value=0;
          }
        });
      });
    } else {

      getEarlyAmortizationCellByYearAndMonth(yearIN, month)[fieldName] = e.target.value;
    }
    YEARS.forEach((year) => {
      getEarlyAmortizationCellListByYear(year).forEach((cell) => {
        calculateDebtCapital(year, cell.month, cell.value);
      });
    });
    setFieldValue('earlyAmortizationList', [...values.earlyAmortizationList]);
  }

  function calculateDebtCapital(year, month, value,initalValue) {
    let loads = (initalValue !== undefined)  ? initalValue : freeLoans;
    if(loads  === 0){
      getEarlyAmortizationCellByYearAndMonth(year, month)['debtCapital'] = 0
      getEarlyAmortizationCellByYearAndMonth(year, month)['value']= 0
    }else {
      if (year === 0 && month === 1) {

        getEarlyAmortizationCellByYearAndMonth(year, month)['debtCapital'] =
            (initalValue ? initalValue : loads) - value;
      } else if (year > 0 && month === 1) {
        const previousMonthCell = getEarlyAmortizationCellByYearAndMonth(year - 1, 12);
        getEarlyAmortizationCellByYearAndMonth(year, month)['debtCapital'] =
            previousMonthCell.debtCapital - value;
      } else {
        const previousMonthCell = getEarlyAmortizationCellByYearAndMonth(year, month - 1);
        getEarlyAmortizationCellByYearAndMonth(year, month)['debtCapital'] =
            previousMonthCell.debtCapital - value;
      }

      if(getEarlyAmortizationCellByYearAndMonth(year, month)['debtCapital'] <0){
        let extraValue = getEarlyAmortizationCellByYearAndMonth(year, month)['debtCapital'];
        getEarlyAmortizationCellByYearAndMonth(year, month)['debtCapital'] =0;
        getEarlyAmortizationCellByYearAndMonth(year, month)['value']= parseFloat(getEarlyAmortizationCellByYearAndMonth(year, month)['value']) + parseFloat(extraValue)
      }
    }





  }


  function updateInitialDate(){
    if(values.candidatureDetails?.forecastDate !== '' && values.candidatureDetails?.forecastDate !== null){
      values.earlyAmortizationList[0].initialYear = DateUtils.addMonths(new Date(values.candidatureDetails.forecastDate),((isNaN(values.earlyAmortizationList[0].deferralPeriod))?0 : parseInt(values.earlyAmortizationList[0].deferralPeriod))).getFullYear()
    }else{
      values.earlyAmortizationList[0].initialYear=0
    }
  }

  function handleEarlyAmortizationChange(e, fieldName, booleanValue) {
    switch (fieldName) {
      case 'appliedDeferralPeriodBenefits':
        values.earlyAmortizationList[0].appliedDeferralPeriodBenefits = booleanValue;
        if (booleanValue === false) {
          values.earlyAmortizationList[0].deferralPeriod = 0;

        }
        break;

      case 'deferralPeriod':
        values.earlyAmortizationList[0].deferralPeriod = e.target.value;
        setFieldValue('earlyAmortizationList', [...values.earlyAmortizationList]);
        updateInitialDate();
        break;

      case 'appliedEarlyAmortization':

        values.earlyAmortizationList[0].appliedEarlyAmortization = booleanValue;
        if(!booleanValue){
          YEARS.forEach((year) => {
            getEarlyAmortizationCellListByYear(year).forEach((cell) => {
              cell.value=0;
              calculateDebtCapital(year, cell.month, cell.value);
            });
          });
          updateInitialDate();


          setFieldValue('earlyAmortizationList', [...values.earlyAmortizationList]);
        }
        break;

      case 'reasonToEarlyAmortization':
        values.earlyAmortizationList[0].reasonToEarlyAmortization = e.target.value;
        break;

      default:
        break;
    }
    setFieldValue('earlyAmortizationList', [...values.earlyAmortizationList]);
  }

  function handleNextStep() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    handleSave(true);
  }

  function handleInitialYearChange(e) {
    values.earlyAmortizationList[0].initialYear = e.target.value;
    setFieldValue('earlyAmortizationList', [...values.earlyAmortizationList]);
  }

  return (
    <div className='financialBoards'>
      <h4 className='text-primary mb-0'>
        <FormattedMessage id='candidatureTabs.accordion.earlyAmortization' />
      </h4>
      <small className='text-secondary text-uppercase d-block'>
        {intl.formatMessage(
          { id: 'all.step' },
          { step: values.step, numberOfSteps: numberOfSteps }
        )}
      </small>

      <Row>
        <Col>
          <div className='d-flex justify-content-between align-items-center mt-4'>
            <label>
              <FormattedMessage id='candidatureForm.earlyAmortization.isDeferralPeriodBenefits' />
              <ToolTip message={<FormattedMessage id={'step12.tooltip.maxPeriod'}/>}/>
            </label>

            <div>
              <Form.Check
                inline
                type={'radio'}
                label={<FormattedMessage id='all.yes' />}
                checked={values.earlyAmortizationList[0].appliedDeferralPeriodBenefits === true}
                onChange={(e) =>
                  handleEarlyAmortizationChange(e, 'appliedDeferralPeriodBenefits', true)
                }
                isInvalid={errors.earlyAmortizationList?.[0]?.appliedDeferralPeriodBenefits}
              />
              <Form.Check
                inline
                type={'radio'}
                label={<FormattedMessage id='all.no' />}
                checked={values.earlyAmortizationList[0].appliedDeferralPeriodBenefits === false}
                onChange={(e) =>
                  handleEarlyAmortizationChange(e, 'appliedDeferralPeriodBenefits', false)
                }
                isInvalid={errors.earlyAmortizationList?.[0]?.appliedDeferralPeriodBenefits}
              />

            </div>
          </div>
        </Col>
      </Row>

      {values.earlyAmortizationList[0].appliedDeferralPeriodBenefits && (
        <Row>
          <Col md='3'>
            <IntInputField
              labelId='economicViabilityFormStep4.monthsNumber'
              controlId={`deferralPeriod`}
              name={`deferralPeriod`}
              value={values.earlyAmortizationList[0].deferralPeriod}
              handleChange={(e) => handleEarlyAmortizationChange(e, 'deferralPeriod', null)}
              isInvalid={errors.earlyAmortizationList?.[0]?.deferralPeriod}
              errorMessage={errors.earlyAmortizationList?.[0]?.deferralPeriod}
              maxValue={24}
            />
          </Col>
        </Row>

      )}



      <Row>
        <Col>
          <div className='d-flex justify-content-between align-items-center mt-4'>
            <label>
              <FormattedMessage id='candidatureForm.earlyAmortization.isEarlyAmortization' />
            </label>
            <div>
              <Form.Check
                inline
                type={'radio'}
                label={<FormattedMessage id='all.yes' />}
                checked={values.earlyAmortizationList[0].appliedEarlyAmortization === true}
                onChange={(e) => handleEarlyAmortizationChange(e, 'appliedEarlyAmortization', true)}
                isInvalid={errors.earlyAmortizationList?.[0]?.appliedEarlyAmortization}
              />
              <Form.Check
                inline
                type={'radio'}
                label={<FormattedMessage id='all.no' />}
                checked={values.earlyAmortizationList[0].appliedEarlyAmortization === false}
                onChange={(e) =>
                  handleEarlyAmortizationChange(e, 'appliedEarlyAmortization', false)
                }
                isInvalid={errors.earlyAmortizationList?.[0]?.appliedEarlyAmortization}
              />
            </div>
          </div>
        </Col>
      </Row>

      <Row>
        <Col md='6' className='intIput'>


          <IntInputField
              labelId={'candidatureForm.initialYear'}
              name={'initialYear'}
              value={values.earlyAmortizationList[0].initialYear}
              handleChange={(e) => handleInitialYearChange(e)}
              maxSize={4}
              isInvalid={errors.earlyAmortizationList?.[0]?.initialYear}
              errorMessage={errors.earlyAmortizationList?.[0]?.initialYear}
              disabled={values.earlyAmortizationList[0].appliedDeferralPeriodBenefits === true}
          />




        </Col>
      </Row>

      <Accordion defaultActiveKey='0' alwaysOpen>
        {YEARS.map((year, index) => (
          <React.Fragment key={index}>
            {!CandidatureFormUtils.is3YearsCoverageOnly(values, year) && (
              <Accordion.Item eventKey={index}>
                <Accordion.Header>
                  {values.earlyAmortizationList[0].initialYear ?  parseInt(values.earlyAmortizationList[0].initialYear) + parseInt(year) : <FormattedMessage id={`all.yearN${year}`} />}
                </Accordion.Header>
                <Accordion.Body>
                  <Row>
                    <Col>
                      <Table className='mt-2 d-table'>
                        <thead>
                          <tr>
                            <th className='w-20'>
                              <FormattedMessage id='candidatureForm.earlyAmortization.period' />
                            </th>
                            <th className='w-35'>
                              <FormattedMessage id='candidatureForm.earlyAmortization.value' />
                              {year===0 &&(<ToolTip message={<FormattedMessage id={'step12.tooltip.firstYearAmortization'}/>}/>)}

                            </th>
                            <th>
                              <FormattedMessage id='candidatureForm.earlyAmortization.debtCapital' />
                              {year===0 &&(<ToolTip message={<FormattedMessage id={'step12.tooltip.firstYearDebtCapital'}/>}/>)}

                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {MONTHS.map((month, index) =>
                            getTableRowsByYearAndMonth(year, month, index)
                          )}
                          <tr>
                            <td className='table-item-bold'>
                              <FormattedMessage id='all.total' />
                            </td>
                            <td>
                              {currencyFormat(maxFixed(
                                CandidatureFinancialUtils.calculateEarlyAmortizationTotalValueByYear(
                                  values,
                                  year
                                ))
                              )}
                            </td>
                            <td>
                              {currencyFormat(maxFixed(
                                getEarlyAmortizationCellByYearAndMonth(year, 12).debtCapital
                              ))}
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </Col>
                  </Row>
                </Accordion.Body>
              </Accordion.Item>
            )}
          </React.Fragment>
        ))}
      </Accordion>

      <Row>
        <Col md='12'>
          <TextAreaInputField
            labelId={'candidatureForm.earlyAmortization.reasonToEarlyAmortization'}
            name={'candidatureForm.earlyAmortization.reasonToEarlyAmortization'}
            value={values.earlyAmortizationList[0].reasonToEarlyAmortization}
            handleChange={(e) =>
              handleEarlyAmortizationChange(e, 'reasonToEarlyAmortization', null)
            }
            placeholder={intl.formatMessage({ id: 'all.maxLength.placeholder' })}
            maxLength={10000}
            isInvalid={errors.earlyAmortizationList?.[0]?.reasonToEarlyAmortization}
            errorMessage={errors.earlyAmortizationList?.[0]?.reasonToEarlyAmortization}
          />
        </Col>
      </Row>

      <div className='form-actions mt-5'>
        <button
          className='btn btn-link'
          type='button'
          onClick={() => handleGoBack(values, setFieldValue)}>
          <FormattedMessage id='all.back' />
        </button>

        <button className='btn btn-primary' type='button' onClick={() => handleNextStep()}>
          <FormattedMessage id='all.nextStep' />
        </button>
      </div>
    </div>
  );
}
