import { apiAxios as authAxios } from '../axios.config';

export const listAccreditationRevocations = (index, size, filters,nif) => {
  return authAxios.get('/api/accreditationRevocation/'+nif, {
    params: {
      index: index,
      size: size,
      state: filters.state ==='-1'? null : filters.state
    }
  });
};

export const addAccreditationRevocations = (nif, formData) => {
  return authAxios.post(`/api/accreditationRevocation/${nif}`, formData);
};



export const getAccreditationRevocation = (nif, externalId) => {
  return authAxios.get(`/api/accreditationRevocation/${nif}/${externalId}`);
};


export const uploadAccreditationRevocationDocument = (nif, externalId, formData) => {
  return authAxios.post(`/api/accreditationRevocation/${nif}/${externalId}/document`, formData)
}


export const deleteAccreditationRevocationDocument = (nif, externalId, documentExternalId) => {
  return authAxios.delete(`/api/accreditationRevocation/${nif}/${externalId}/document/${documentExternalId}`)
}


export const getAccreditationRevocationDocument = (nif, externalId, documentExternalId) => {
  return authAxios.get(`/api/accreditationRevocation/${nif}/${externalId}/document/${documentExternalId}`)
}



export const decisionAccrediationRevocation = (nif,externalId, formData) => {
  return authAxios.post(`/api/accreditationRevocation/${nif}/${externalId}/decision`, formData);
};




export const submitAccreditationRevocationontestation = (nif, externalId , clarificationDto) => {
  return authAxios.post(`/api/accreditationRevocation/${nif}/${externalId}/submitContestation`, clarificationDto)
}

export const restitutionAccreditationRevocation= (nif, externalId, values) => {
  return authAxios.post(`api/accreditationRevocation/${nif}/${externalId}/restitution`, values);
}

export const unilateralAccrediationRevocation = (nif, formData) => {
  return authAxios.post(`/api/accreditationRevocation/${nif}/unilateral`, formData);
};




