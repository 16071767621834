import React from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { NumberInputField } from '../bootstrap/NumberInputField';
import { SelectBooleanField } from '../bootstrap/SelectBooleanField';
import { TextAreaInputField } from '../bootstrap/TextAreaInputField';
import { TextInputField } from '../bootstrap/TextInputField';
import ToolTip from '../bootstrap/ToolTip';

export function EAPromoterStep({
	formStep,
	numberOfSteps,
	errors,
	handleChange,
	setFieldValue,
	formikValues,
	handleNextStep,
	entityGetList,
	entityTgpList,
	readMode,
	goBackPath,
}) {
	const intl = useIntl();

	function handleTgpChange(e){
		handleChange(e)
		setFieldValue('identificationEa.projectManagerEmail','')
		setFieldValue('identificationEa.projectManagerPhone','')
	}
	function showGetInformation() {
		let getInfo = entityGetList.filter(
			(get) =>
				get.externalId ===
				formikValues.identificationEa.technicalTeamManagerExternalId
		)?.[0];

		return (
			<>
				<Row>
					<Col md='8'>
						<TextInputField
							labelId={'all.name'}
							value={getInfo?.name}
							disabled
						/>
					</Col>
					<Col md='4'>
						<NumberInputField
							labelId={'all.phone'}
							value={getInfo?.phone}
							disabled
						/>
					</Col>
				</Row>
				<Row>
					<Col md='8'>
						<TextInputField
							labelId={'all.email'}
							value={getInfo?.email}
							disabled
						/>
					</Col>
				</Row>
			</>
		);
	}

	function showTgpInformation() {
		let tgpInfo = entityTgpList.filter(
			(tgp) =>
				tgp.externalId ===
				formikValues.identificationEa.projectManagerExternalId
		)?.[0];

		return (
			<>
				<Row>
					<Col md='8'>
						<TextInputField
							labelId={'all.name'}
							value={tgpInfo?.name}
							disabled
						/>
					</Col>
					<Col md='4'>
						<NumberInputField
							labelId={'all.phone'}
							name={`identificationEa.projectManagerPhone`}
							handleChange={handleChange}
							value={formikValues.identificationEa.projectManagerPhone}
							isInvalid={errors.identificationEa?.projectManagerPhone}
							errorMessage={errors.identificationEa?.projectManagerPhone}
							maxLength={9}
						/>
					</Col>
				</Row>
				<Row>
					<Col md='8'>
						<TextInputField
							labelId={'all.email'}
							name={`identificationEa.projectManagerEmail`}
							handleChange={handleChange}
							value={formikValues.identificationEa.projectManagerEmail}
							isInvalid={errors.identificationEa?.projectManagerEmail}
							errorMessage={errors.identificationEa?.projectManagerEmail}
						/>
					</Col>
				</Row>
			</>
		);
	}

	return (
		<div>
			<h4 className='text-primary mb-0'>
				<FormattedMessage id='eaevaluationform.eaidentification' />
			</h4>
			<small className='text-secondary text-uppercase'>
				{intl.formatMessage(
					{ id: 'all.step' },
					{ step: formStep, numberOfSteps: numberOfSteps }
				)}
			</small>
			<fieldset disabled={readMode==true}>
				<Row>
					<Col md='4'>
						<TextInputField
							disabled={true}
							labelId={'eaPromoterStep.Designation'}
							value={formikValues.identificationEa.entity.description}
						/>
					</Col>
					<Col md='4'>
						<TextInputField
							disabled={true}
							labelId={'eaPromoterStep.nipc'}
							value={formikValues.identificationEa.entity.nif}
						/>
					</Col>
					<Col md='4'>
						<TextInputField
							disabled={true}
							labelId={'semestralReportForm.step1.eaProcessId'}
							value={formikValues.identificationEa.entity.processId}
						/>
					</Col>
				</Row>
				<Row>
					<p className='mt-5 mb-0 '>
						<FormattedMessage id='eaPromoterStep.projectManager' />
					</p>
				</Row>
				<Row className='mt-4'>
					<Col>
						<Form.Group>
							<Form.Control
								as='select'
								name={'identificationEa.projectManagerExternalId'}
								onChange={(e) => handleTgpChange(e)}
								value={formikValues.identificationEa.projectManagerExternalId}
								isInvalid={errors.identificationEa?.projectManagerExternalId}
							>
								<option value={'-1'}>
									<FormattedMessage id='all.chooseOne' />
								</option>
								{entityTgpList.map((tgp) => (
									<option key={tgp.externalId} value={tgp.externalId}>
										{tgp.name}
									</option>
								))}
							</Form.Control>
							<Form.Control.Feedback type='invalid'>
								{errors.identificationEa?.projectManagerExternalId}
							</Form.Control.Feedback>
						</Form.Group>
					</Col>
				</Row>
				{formikValues.identificationEa.projectManagerExternalId !== '-1' &&
					showTgpInformation()}
				<Row>
					<p className='mt-5 mb-0 '>
						<FormattedMessage id='eaPromoterStep.teamManager' />
					</p>
				</Row>
				<Row className='mt-4'>
					<Col>
						<Form.Group>
							<Form.Control
								as='select'
								name={'identificationEa.technicalTeamManagerExternalId'}
								onChange={handleChange}
								value={
									formikValues.identificationEa.technicalTeamManagerExternalId
								}
								isInvalid={
									errors.identificationEa?.technicalTeamManagerExternalId
								}
							>
								<option value={'-1'}>
									<FormattedMessage id='all.chooseOne' />
								</option>
								{entityGetList.map((get) => (
									<option key={get.externalId} value={get.externalId}>
										{get.name}
									</option>
								))}
							</Form.Control>
							<Form.Control.Feedback type='invalid'>
								{errors.identificationEa?.technicalTeamManagerExternalId}
							</Form.Control.Feedback>
						</Form.Group>
					</Col>
				</Row>
				{formikValues.identificationEa.technicalTeamManagerExternalId !==
					'-1' && showGetInformation()}
			</fieldset>
			<div className='form-actions mt-5'>
				<Link to={goBackPath}>
					<button className='btn btn-link' type='button'>
						<FormattedMessage id='all.back' />
					</button>
				</Link>

				<button
					className='btn btn-primary'
					type='button'
					onClick={() => handleNextStep()}
				>
					<FormattedMessage id='all.nextStep' />
				</button>
			</div>
		</div>
	);
}
