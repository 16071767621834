import {useNavigate, useParams} from "react-router-dom";
import {ViewEaAccreditation} from "./ViewEaAccreditation";
import React, {useEffect, useState} from "react";
import {useIntl} from "react-intl";
import Loading from "../../general/Loading";
import {AlertError} from "../../bootstrap/AlertError";
import {AlertSuccess} from "../../bootstrap/AlertSuccess";
import {SubTemplate} from "../../general/SubTemplate";
import {Col, Container, Row} from "react-bootstrap";
import {getEntityAccreditation, getEntityAccreditationByNif} from "../../../rest/entity";
import {getDistricts} from "../../../rest/administrativeDivision";
import {DetailEntityAccreditationMenu} from "./DetailEntityAccreditationMenu";
import {listEntityDocuments} from "../../../rest/document";
import {EntityRevokedAlert} from "../EntityRevokedAlert";

export function DetailEntityAccreditationPage() {
    const {entityNIPC} = useParams();
    const intl = useIntl();
    const navigate = useNavigate();

    const [lsItem, setLsItem] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [accreditation, setAccreditation] = useState();
    const [districts, setDistricts] = useState();
    const [documents, setDocuments] = useState();

    async function fetchData() {
        try {
            let [
                {data: eaAccreditation},
                {data: dist},
                { data: entityDocuments },
            ] = await Promise.all([
                await (entityNIPC === undefined ? getEntityAccreditation() : getEntityAccreditationByNif(entityNIPC)),
                await getDistricts(true),
                await listEntityDocuments({entityNif: entityNIPC}),
            ]);
            setAccreditation(eaAccreditation);
            setDistricts(dist);
            setDocuments(entityDocuments);
        } catch (er) {
            setError(er);
        } finally {
            setLoading(false);
        }
    }

    function checkLocalStorageItems() {
        [].forEach((s) => {
            if (sessionStorage.getItem(s)) setLsItem(s);
        });
    }


    useEffect(() => {
        fetchData();
        checkLocalStorageItems();
    }, []);

    if(loading) return <Loading/>

    return (
        <SubTemplate titleId={'eaAccreditation.View'}  hasBackButton>
            <Container>
                <AlertError error={error} />
                {lsItem && <AlertSuccess lsItem={lsItem} />}
                {accreditation != null && accreditation?.entity?.revoked === true &&
                    <Row>
                        <Col>
                            <EntityRevokedAlert/>
                        </Col>
                    </Row>}
                <Row>
                    <Col md='10' className='mb-5 summaryTabs'>
                        <ViewEaAccreditation accreditation={accreditation} documents={documents} districtOptions={districts}/>
                    </Col>
                    <Col md="2">
                        <DetailEntityAccreditationMenu accreditation={accreditation}/>
                    </Col>
                </Row>

            </Container>
        </SubTemplate>
    );
}

