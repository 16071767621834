import React, { useEffect } from 'react';
import { Col, Form, Row, Table } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { FinancialInvestmentType } from '../../models/FinancialInvestmentType';
import { EconomicViabilityUtils } from '../../utils/EconomicViabilityUtils';
import DecimalInputField from '../bootstrap/DecimalInputField';
import IntInputField from '../bootstrap/IntInputField';
import { TextAreaInputField } from '../bootstrap/TextAreaInputField';
import ToolTip from '../bootstrap/ToolTip';

export function EconomicViabilityFormStep4({
  handleChange,
  setFieldValue,
  errors,
  values,
  error,
  setError,
  numberOfSteps,
  handleSave,
  handleGoBack
}) {
  const intl = useIntl();

  useEffect(() => {}, [values.financialInvestmentDetails]);

  const KEYS = [
    'ownCapital',
    'ownSocialCapital',
    'ownCapitalUnemploymentBenefits',
    'ownCapitalOthers',
    'foreignCapital',
    'foreignCapitalBankLoans',
    'foreignCapitalPartnerLoans',
    'foreignCapitalProvidersLoans',
    'foreignCapitalOthers',
    'iefpAskedAmount',
    'investmentSupport',
    'workStationsCreationBenefits',
    'globalTotal'
  ];

  const BOLD_KEYS = ['ownCapital', 'foreignCapital', 'iefpAskedAmount', 'globalTotal'];

  function calculateFinancialInvestmentDetailTotals() {
    values.financialInvestmentDetails.forEach((fid) => {
      EconomicViabilityUtils.calculateFinancialInvestmentDetailTotals(fid);
    });
  }

  function getValueByFinancialInvestmentTypeAndFieldName(financialInvestmentType, fieldName) {
    return values.financialInvestmentDetails.filter(
      (fid) => fid.financialInvestmentType === financialInvestmentType
    )[0][fieldName];
  }

  function handleFinancialInvestmentChange(e, financialInvestmentType, fieldName) {
    values.financialInvestmentDetails.filter(
      (fid) => fid.financialInvestmentType === financialInvestmentType
    )[0][fieldName] = e.target.value;

    calculateFinancialInvestmentDetailTotals();

    setFieldValue('financialInvestmentDetails', [...values.financialInvestmentDetails]);
  }

  function handleInputRadioChange(fieldName, value) {
    setFieldValue(fieldName, value);
  }

  function getTableRowsByKey(key) {
    return (

      <tr>
        <td className={`w-50 ${BOLD_KEYS.includes(key) && 'table-item-bold'}`}>
          <FormattedMessage id={`economicViabilityFormStep4.${key}`} />
        </td>
        <td>
          <DecimalInputField
            controlId={`financialInvestmentDetails.${key}`}
            name={`financialInvestmentDetails.${key}`}
            handleChange={(e) =>
              handleFinancialInvestmentChange(e, FinancialInvestmentType.CANDIDATURE, key)
            }
            value={getValueByFinancialInvestmentTypeAndFieldName(
              FinancialInvestmentType.CANDIDATURE,
              key
            )}
            suffixLabel='€'
          />
        </td>
        <td>
          <DecimalInputField
            controlId={`financialInvestmentDetails.${key}`}
            name={`financialInvestmentDetails.${key}`}
            handleChange={(e) =>
              handleFinancialInvestmentChange(e, FinancialInvestmentType.ANALYSIS, key)
            }
            value={getValueByFinancialInvestmentTypeAndFieldName(
              FinancialInvestmentType.ANALYSIS,
              key
            )}
            suffixLabel='€'
          />
        </td>
      </tr>
    );
  }

  return (
    <div>
      <h4 className='text-primary mb-0'>
        <FormattedMessage id='economicViabilityForm.financialInvestmentTab' />
      </h4>
      <small className='text-secondary text-uppercase d-block'>
        {intl.formatMessage(
          { id: 'all.step' },
          { step: values.step, numberOfSteps: numberOfSteps }
        )}
      </small>

      <Table className='mt-4' bordered>
        <thead>
          <tr>
            <th className='w-50'>
              <FormattedMessage id='economicViabilityFormStep4.financialHeadings' />
            </th>
            <th>
              <FormattedMessage id='financialInvestmentType.CANDIDATURE' />
            </th>
            <th>
              <FormattedMessage id='financialInvestmentType.ANALYSIS' />
            </th>
          </tr>
        </thead>
        <tbody>{KEYS.map((key) => getTableRowsByKey(key))}</tbody>
      </Table>

      <Row>
        <Col md='12'>
          <div className='mt-3'>
            <label>
              <FormattedMessage id='economicViabilityFormStep4.earlyLoanRepayment' />
            </label>
            <div>
              <Form.Check
                inline
                type={'radio'}
                label={<FormattedMessage id='all.yes' />}
                checked={values.earlyLoanRepayment === true}
                onChange={() => handleInputRadioChange('earlyLoanRepayment', true)}
                isInvalid={errors.earlyLoanRepayment}
              />
              <Form.Check
                inline
                type={'radio'}
                label={<FormattedMessage id='all.no' />}
                checked={values.earlyLoanRepayment === false}
                onChange={() => handleInputRadioChange('earlyLoanRepayment', false)}
                isInvalid={errors.earlyLoanRepayment}
              />
            </div>
          </div>
        </Col>
        <Col md='12'>
          <TextAreaInputField
            labelId={'economicViabilityFormStep4.earlyLoanDescription'}
            name={'earlyLoanDescription'}
            value={values.earlyLoanDescription}
            handleChange={handleChange}
            isInvalid={errors.earlyLoanDescription}
            errorMessage={errors.earlyLoanDescription}
          />
        </Col>
        <Col md='12'>
          <div className='mt-3'>
            <label>
              <FormattedMessage id='economicViabilityFormStep4.deferral' />
            </label>
            <div>
              <Form.Check
                inline
                type={'radio'}
                label={<FormattedMessage id='all.yes' />}
                checked={values.deferral === true}
                onChange={() => handleInputRadioChange('deferral', true)}
                isInvalid={errors.deferral}
              />
              <Form.Check
                inline
                type={'radio'}
                label={<FormattedMessage id='all.no' />}
                checked={values.deferral === false}
                onChange={() => handleInputRadioChange('deferral', false)}
                isInvalid={errors.deferral}
              />
            </div>
          </div>
        </Col>
      </Row>

      <Row>
        <Col md='4'>
          <IntInputField
            labelId={'economicViabilityFormStep4.monthsNumber'}
            name={'monthsNumber'}
            value={values.monthsNumber}
            handleChange={handleChange}
          />
        </Col>
        <Col md='4'>
          <IntInputField
            labelId={'economicViabilityFormStep4.monthlyPaymentNumber'}
            name={'monthlyPaymentNumber'}
            value={values.monthlyPaymentNumber}
            handleChange={handleChange}
          />
        </Col>
        <Col md='4'>
          <DecimalInputField
            labelId={'economicViabilityFormStep4.monthlyPaymentValue'}
            controlId={'monthlyPaymentValue'}
            name={'monthlyPaymentValue'}
            handleChange={handleChange}
            value={values.monthlyPaymentValue}
            placeHolder={0}
          />
        </Col>
      </Row>

      <Row>
        <Col md='12'>
          <TextAreaInputField
            labelId={'economicViabilityFormStep4.financialDescription'}
            name={'financialDescription'}
            value={values.financialDescription}
            handleChange={handleChange}
            toolTip={
              <ToolTip
                message={
                  <FormattedMessage id='economicViabilityFormStep4.financialDescription.toolTip' />
                }
              />
            }
            isInvalid={errors.financialDescription}
            errorMessage={errors.financialDescription}
          />
        </Col>
      </Row>
    </div>
  );
}
