import React, { useState } from 'react';
import { Col, Form, Row, Table } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { CandidatureFormUtils } from '../../utils/CandidatureFormUtils';
import DecimalInputField from '../bootstrap/DecimalInputField';
import { NumberInputField } from '../bootstrap/NumberInputField';
import { TextAreaInputField } from '../bootstrap/TextAreaInputField';
import { TextInputField } from '../bootstrap/TextInputField';
import IntInputField from "../bootstrap/IntInputField";
import {formatValue} from "react-currency-input-field";
import {ConfigObject} from "../../config";
import { FaInfoCircle } from 'react-icons/fa';
import {currencyFormat} from "../../utils/CurrencyUtils";

export function CandidatureFormSellInformation({
  errors,
  handleChange,
  setFieldValue,
  values,
  handleSave,
  handleGoBack,
  numberOfSteps,
  sellerInformationType
}) {
  const intl = useIntl();

  const [nonApplicable, setNonApplicable] = useState(getSellInformationValues().nonApplicable);

  const YEARS = [0, 1, 2, 3, 4];

  function getSellInformationValues() {
    return values.sellInformationList.find((sil) => sil.type === sellerInformationType);
  }

  function getSellInformationLineYearsValueByYear(sellInformationLine, year) {
    const sellInformationLineAux = getSellInformationValues().sellInformationLines.find(
      (sil) => sil === sellInformationLine
    );
    return sellInformationLineAux.sellInformationLineYears.find((sily) => sily.year === year);
  }

  function getTotalValuesByYearAndType(year, type) {
    let total = 0;

    getSellInformationValues().sellInformationLines.forEach((sil) => {
      const yearLine = sil.sellInformationLineYears.find((sily) => sily.year === year);
      const valueToAdd = type === 'quantity' ? yearLine.quantity : yearLine.value;
      if(!isNaN(valueToAdd) && valueToAdd!=='' ){
        total += parseFloat(valueToAdd);
      }
    });


    return total;
  }

  function addSellInformationLine() {
    getSellInformationValues().sellInformationLines.push({
      externalId: '',
      designation: '',
      unit: '',
      sellInformationLineYears: [
        {
          externalId: '',
          year: 0,
          quantity: 0,
          unitPrice:0,
          value: 0
        },
        { externalId: '', year: 1, quantity: 0,  unitPrice:0,value: 0 },
        { externalId: '', year: 2, quantity: 0,  unitPrice:0,value: 0 },
        { externalId: '', year: 3, quantity: 0, unitPrice:0, value: 0 },
        { externalId: '', year: 4, quantity: 0,  unitPrice:0,value: 0 }
      ]
    });
    setFieldValue('sellInformationList', [...values.sellInformationList]);
  }

  function removeSellInformationLine(sellInformation) {
    getSellInformationValues().sellInformationLines =
      getSellInformationValues().sellInformationLines.filter((si) => si !== sellInformation);
    setFieldValue('sellInformationList', [...values.sellInformationList]);
  }

  function handleSellInformationLineChange(e, index, fieldName) {
    getSellInformationValues().sellInformationLines[index][fieldName] = e.target.value;
    setFieldValue('sellInformationList', [...values.sellInformationList]);
  }

  function handleSellInformationLineYearChange(sellInformationLine, e, year, fieldName) {




    getSellInformationLineYearsValueByYear(sellInformationLine, year)[fieldName] =  e.target.value;
    let unitPrice = getSellInformationLineYearsValueByYear(sellInformationLine, year)['unitPrice'];
    let quantity = getSellInformationLineYearsValueByYear(sellInformationLine, year)['quantity']


    getSellInformationLineYearsValueByYear(sellInformationLine, year)['value'] = ((isNaN(unitPrice) || unitPrice==='') ? 0 : parseFloat(unitPrice)) *((isNaN(quantity) || quantity==='')? 0 : parseFloat(quantity)) ;

    setFieldValue('sellInformationList', [...values.sellInformationList]);
  }

  function handleNextStep() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    handleSave(true);
  }

  function getTableRowsByYear(sellInformationLine, year, index) {


    return (
      <React.Fragment>
        <td>
          <IntInputField

            name={'quantity'}
            handleChange={(e) =>
              handleSellInformationLineYearChange(sellInformationLine, e, year, 'quantity')
            }
            value={getSellInformationLineYearsValueByYear(sellInformationLine, year).quantity}
            isInvalid={
              errors.sellInformationList?.[getSellInformationIndex()]
                  ?.sellInformationLines?.[index]?.sellInformationLineYears?.[year]?.quantity
            }
            errorMessage={
              errors.sellInformationList?.[getSellInformationIndex()]
                  ?.sellInformationLines?.[index]?.sellInformationLineYears?.[year]?.quantity
            }
          />

        </td>
        <td>
          <DecimalInputField
              controlId={'unitPrice'}
              name={'unitPrice'}
              handleChange={(e) =>
                  handleSellInformationLineYearChange(sellInformationLine, e, year, 'unitPrice')
              }
              value={getSellInformationLineYearsValueByYear(sellInformationLine, year).unitPrice}
              suffixLabel='€'
              placeHolder={0}
              isInvalid={
                errors.sellInformationList?.[getSellInformationIndex()]
                    ?.sellInformationLines?.[index]?.sellInformationLineYears?.[year]?.unitPrice
              }
              errorMessage={
                errors.sellInformationList?.[getSellInformationIndex()]
                    ?.sellInformationLines?.[index]?.sellInformationLineYears?.[year]?.unitPrice
              }
          />

        </td>
        <td>
          <DecimalInputField
            controlId={'value'}
            name={'value'}
            value={getSellInformationLineYearsValueByYear(sellInformationLine, year).value}
            placeHolder={0}
            suffixLabel='€'
            disabled={true}
          />
        </td>
      </React.Fragment>
    );
  }

  function getTotalTableRowsByYear(year) {
    return (
      <>
        <td>
          <span>{getTotalValuesByYearAndType(year, 'quantity')}</span>
        </td>
        <td>
          <span>{currencyFormat(getTotalValuesByYearAndType(year, 'value'))}</span>
        </td>
      </>
    );
  }

  function handleReasonChange(e) {
    getSellInformationValues().reasonToSell = e.target.value;
    setFieldValue('sellInformationList', [...values.sellInformationList]);
  }

  function handleNonApplicableChange() {
    getSellInformationValues().nonApplicable = !nonApplicable;
    setNonApplicable(!nonApplicable);
    setFieldValue('sellInformationList', [...values.sellInformationList]);
  }

  function getSellInformationIndex() {
    return values.sellInformationList.indexOf(getSellInformationValues());
  }

  return (
    <div>
      <h4 className='text-primary mb-0'>
        <FormattedMessage
          id={`candidatureForm.sellInformation.${
            sellerInformationType === 'SELL' ? 'sell' : 'consume'
          }.title`}
        />
      </h4>
      <small className='text-secondary text-uppercase d-block'>
        {intl.formatMessage(
          { id: 'all.step' },
          { step: values.step, numberOfSteps: numberOfSteps }
        )}
      </small>


      <p className='mt-3 mb-0 text-secondary infoText'>
        <FaInfoCircle size={70} /> {intl.formatMessage(
          { id: 'step9.infoText' },
          { value:   formatValue({
              value: ConfigObject.get().limitValueFor5Years,
              groupSeparator: ' ',
              decimalSeparator: '.',
              suffix: '€',
            })     }
      )}
      </p>








      {sellerInformationType === 'CONSUME' && (
        <div className='d-flex align-items-center mt-4'>
          <Form.Check
            type='checkbox'
            checked={nonApplicable ? 1 : 0}
            onChange={() => handleNonApplicableChange()}
          />

          <label>
            <FormattedMessage id='candidatureForm.sellInformation.nonApplicable' />
          </label>
        </div>
      )}

      {!nonApplicable && (
        <div>
          {getSellInformationValues().sellInformationLines.map((sil, index) => (
            <div key={index} className='border-bottom pb-4 mx-1 mb-3'>
              <Row>
                <Col>
                  <p className='mt-4 mb-0 text-uppercase'> Entrada {index + 1} </p>
                </Col>
                <Col className='d-flex justify-content-end'>
                  <button
                    className='mt-4 py-0 btn btn-link'
                    type='button'
                    onClick={() => removeSellInformationLine(sil)}>
                    <FormattedMessage id='all.remove' />
                  </button>
                </Col>
              </Row>

              <Row>
                <Col>
                  <TextInputField
                    controlId={'designation'}
                    labelId={'candidatureForm.sellInformationLine.designation'}
                    name={'designation'}
                    handleChange={(e) => handleSellInformationLineChange(e, index, 'designation')}
                    value={sil.designation}
                    isInvalid={
                      errors.sellInformationList?.[getSellInformationIndex()]
                        ?.sellInformationLines?.[index]?.designation
                    }
                    errorMessage={
                      errors.sellInformationList?.[getSellInformationIndex()]
                        ?.sellInformationLines?.[index]?.designation
                    }
                  />
                </Col>
                <Col>
                  <TextInputField
                    controlId={'unit'}
                    labelId={'candidatureForm.sellInformationLine.unit'}
                    name={'unit'}
                    handleChange={(e) => handleSellInformationLineChange(e, index, 'unit')}
                    value={sil.unit}
                    isInvalid={
                      errors.sellInformationList?.[getSellInformationIndex()]
                        ?.sellInformationLines?.[index]?.unit
                    }
                    errorMessage={
                      errors.sellInformationList?.[getSellInformationIndex()]
                        ?.sellInformationLines?.[index]?.unit
                    }
                  />
                </Col>
              </Row>

              <Row>
                <Col>
                  <Table className='mt-4 w-100 d-table'>
                    <thead>
                    <tr>
                      <th className='w-15'><FormattedMessage id='all.year' /></th>
                      <th className='text-center'>
                        <FormattedMessage id='all.quantity.full' />
                      </th>
                      <th className='text-center'>
                        <FormattedMessage id='all.unitPrice' />
                      </th>
                      <th className='text-center'>
                        <FormattedMessage id='all.value' />
                      </th>
                    </tr>
                    </thead>
                    <tbody>
                    {YEARS.map((year, index2) => (
                        <React.Fragment key={index2}>
                          {!CandidatureFormUtils.is3YearsCoverageOnly(values, year) && (
                              <tr key={index}>
                                <td>
                                  {CandidatureFormUtils.getYearLabel(values, year)}
                                </td>
                                {getTableRowsByYear(sil, year, index)}
                              </tr>
                          )}
                        </React.Fragment>
                    ))}
                    </tbody>



                  </Table>
                </Col>
              </Row>
            </div>
          ))}

          <button
            className='mt-4 btn btn-outline-primary'
            type='button'
            onClick={() => addSellInformationLine()}>
            <FormattedMessage id='all.plusSymbol' /> <FormattedMessage id='all.addEntry' />
          </button>

          <Row>
            <Col className='tableWithBorders'>
              <h5 className='mt-5 text-secondary'>
                <FormattedMessage
                  id={`candidatureForm.sellInformation.${sellerInformationType}.total`}
                />
              </h5>
              <Table className='mt-4 d-table text-center'>
                <thead>
                  <tr>
                    {YEARS.map((year, index) => (
                      <React.Fragment key={index}>
                        {!CandidatureFormUtils.is3YearsCoverageOnly(values, year) && (
                          <th colSpan='2' className='text-center'>
                            {CandidatureFormUtils.getYearLabel(values, year)}
                          </th>
                        )}
                      </React.Fragment>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    {YEARS.map((year, index) => (
                      <React.Fragment key={index}>
                        {!CandidatureFormUtils.is3YearsCoverageOnly(values, year) && (
                          <>
                            <td className='text-center'>
                              <FormattedMessage id='all.quantity.full' />
                            </td>
                            <td className='text-center'>
                              <FormattedMessage id='all.value' />
                              &nbsp;€
                            </td>
                          </>
                        )}
                      </React.Fragment>
                    ))}
                  </tr>

                  <tr>
                    {YEARS.map((year, index) => (
                      <React.Fragment key={index}>
                        {!CandidatureFormUtils.is3YearsCoverageOnly(values, year) &&
                          getTotalTableRowsByYear(year)}
                      </React.Fragment>
                    ))}
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Row>

          <Row>
            <Col md='12'>
              <TextAreaInputField
                labelId={`candidatureForm.sellInformation.${sellerInformationType}.reasoning`}
                name={`candidatureForm.sellInformation.${sellerInformationType}.reasoning`}
                value={getSellInformationValues().reasonToSell}
                handleChange={(e) => handleReasonChange(e)}
                placeholder={intl.formatMessage({ id: 'all.maxLength.placeholder' })}
                maxLength={10000}
                isInvalid={errors.sellInformationList?.[getSellInformationIndex()]?.reasonToSell}
                errorMessage={errors.sellInformationList?.[getSellInformationIndex()]?.reasonToSell}
              />
            </Col>
          </Row>
        </div>
      )}

      <div className='form-actions mt-5'>
        <button
          className='btn btn-link'
          type='button'
          onClick={() => handleGoBack(values, setFieldValue)}>
          <FormattedMessage id='all.back' />
        </button>

        <button className='btn btn-primary' type='button' onClick={() => handleNextStep()}>
          <FormattedMessage id='all.nextStep' />
        </button>
      </div>
    </div>
  );
}
