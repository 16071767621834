import { apiAxios as authAxios } from '../axios.config';


export const getSemestralReport = ( b11ExternalId,consultingRequestExternalId) => {
	return authAxios.get(`/api/semestralReport/${b11ExternalId !== undefined ? b11ExternalId : ''}`,{params:{consultingRequestExternalId:consultingRequestExternalId}})
}


export const saveSemestralReport = (b11ExternalID, data, queryParams) => {
	return authAxios.post(`api/semestralReport/${b11ExternalID}`, data, { params: queryParams });
};

export const listSemestralReports = ( queryParams) => {
	return authAxios.get(`api/semestralReport/list`, { params: queryParams });
};

 export const submitSemestralReportDecision= (b11ExternalId, data) => {
	return authAxios.post(`api/consultingRequest/${b11ExternalId}/submitDecision/`, data);
};


export const semestralReportFormStatus = (b11ExternalId) => {
  return authAxios.get(`api/semestralReport/${b11ExternalId}/allowActions/`)
}

export const canListSemestralReport = (consultingRequestExternalId) => {
	return authAxios.get(`/api/semestralReport/canList`,{params:{consultingRequestExternalId:consultingRequestExternalId}})
};

export const canCreateSemestralReport = (consultingRequestExternalId) => {
	if(consultingRequestExternalId=== undefined){
		return ;
	}
	return authAxios.get(`/api/semestralReport/canCreate`,{params:{consultingRequestExternalId:consultingRequestExternalId}})
};


export const submitDecisionSemestralReport = (externalId, data) => {
	return authAxios.post(`/api/semestralReport/${externalId}/submitDecision`, data);
};





