import {reject, submitExpirationApprovalDecision, submitProposalDecisionExpiration} from '../../rest/candidature';
import {useParams} from 'react-router-dom';
import {CandidatureExpiration} from "../../components/candidature/CandidatureExpiration";

export function EmitExpirationFinalDecision() {
    const {externalId} = useParams();

    const submit = async () => {
        await submitExpirationApprovalDecision(externalId);
        sessionStorage.setItem('submitExpirationApprovalDecision','true');
    }

    return (
        <CandidatureExpiration
            validationTitle='candidatureExpirationFinal.title.reject'
            messageConfirmation='candidatureExpirationFinal.message.reject'
            callbackHandler={submit}
            isApprove={false}
        />
    );

}
