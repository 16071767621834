import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { FaSearch } from 'react-icons/fa';
import { FormattedMessage, useIntl } from 'react-intl';
import { ProcessState } from '../../models/ProcessState';
import { OptionsUtils } from '../../utils/OptionsUtils';
import { EnumSelectField } from '../bootstrap/EnumSelectField';
import { NumberInputField } from '../bootstrap/NumberInputField';

export function ListFilterBar({ filters, setFilters, search }) {
  const intl = useIntl();

  function handleFilterChange(e, name) {
    filters[name] = e.target.value;
    setFilters({ ...filters });
  }



  return (
    <>
      <Row className='searchFilters'>
        <Col md='3'>
          <EnumSelectField
            preffixDescriptionId={'processState'}
            handleChange={(e) => handleFilterChange(e, 'state')}
            options={ProcessState.values()}
            value={filters.state}
            nullOption={true}
            nullOptionSuffix={'state'}
          />
        </Col>
        <Col md='2'>
          <EnumSelectField
            preffixDescriptionId={'month'}
            handleChange={(e) => handleFilterChange(e, 'month')}
            value={filters.month}
            nullOption={true}
            nullOptionSuffix={'month'}
            options={OptionsUtils.months1To12}
          />
        </Col>
        <Col md='2'>
          <NumberInputField
            handleChange={(e) => handleFilterChange(e, 'year')}
            value={filters.year}
            maxLength={4}
            placeholder={intl.formatMessage({id:'all.year'})}
          />
        </Col>
        <Col md='3'>
          <button className='btn btn-outline-primary' onClick={() => search(true)} type='button'>
            <FaSearch /> <FormattedMessage id='all.search' />
          </button>
        </Col>
      </Row>
    </>
  );
}
