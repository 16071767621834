import { FormattedMessage } from 'react-intl';
import * as yup from 'yup';
import { Validator } from './Validator';

export const CompanyIdentificationFormUtils = {
	validationObject: (hasPostalCode) => validationObject(hasPostalCode),
	getFormikInitialValuesCompanyIdentification: (initialValues, setPostalCode) => getFormikInitialValuesCompanyIdentification(initialValues, setPostalCode),

};

function validationObject(hasPostalCode) {
	let validationObject = {
		name: yup
			.string()
			.required(<FormattedMessage id='errors.fieldRequiredText' />),

		nipc: yup
			.string()
			.required(<FormattedMessage id='errors.fieldRequiredText' />)
			.test(
				'isNifValid',
				<FormattedMessage id='errors.eaAccreditationForm.nipc' />,
				(value) => Validator.validateNif(value)
			),

		address: yup
			.string()
			.required(<FormattedMessage id='errors.fieldRequiredText' />),

		email: yup
			.string()
			.required(<FormattedMessage id='errors.fieldRequiredText' />)
			.test(
				'isEmailValid',
				<FormattedMessage id='errors.candidatureForm.email' />,
				(value) => Validator.validateEmail(value)
			),

		phone: yup
			.string()
			.required(<FormattedMessage id='errors.fieldRequiredText' />)
			.test(
				'isPhoneValid',
				<FormattedMessage id='errors.eaAccreditationForm.phoneAndMobile' />,
				(value) => Validator.validateCommsNumber(value)
			),

		personInChargeName: yup
			.string()
			.required(<FormattedMessage id='errors.fieldRequiredText' />),
	};

	if (hasPostalCode === true) {
		let postalCodeValidationObject = {
			postalCode4Digits: yup
				.string()
				.required(<FormattedMessage id='errors.fieldRequiredText' />)
				.test(
					'isPostalCode4DigitsValid',
					<FormattedMessage id='errors.eaAccreditationForm.postalCode4Digits' />,
					(value) => Validator.validateFixedSizeNumber(value, 4)
				),

			postalCode3Digits: yup
				.string()
				.required(<FormattedMessage id='errors.fieldRequiredText' />)
				.test(
					'isPostalCode3DigitsValid',
					<FormattedMessage id='errors.eaAccreditationForm.postalCode3Digits' />,
					(value) => Validator.validateFixedSizeNumber(value, 3)
				),
		};

		Object.assign(validationObject, postalCodeValidationObject);
	}

	return yup.object().shape(validationObject);
}

function getFormikInitialValuesCompanyIdentification(
	initialValues,
	setPostalCode
) {
	initialValues.company.name ??= '';
	initialValues.company.nipc ??= '';
	initialValues.company.address ??= '';
	initialValues.company.email ??= '';
	initialValues.company.phone ??= '';
	initialValues.company.personInChargeName ??= '';

	if (setPostalCode) {
		initialValues.company.postalCode4Digits ??= '';
		initialValues.company.postalCode3Digits ??= '';
	}
}
