import React, { useEffect, useState } from 'react';
import { TextInputField } from '../../bootstrap/TextInputField';
import { NumberInputField } from '../../bootstrap/NumberInputField';
import { Row, Col, Form, Table } from 'react-bootstrap';
import { FaInfoCircle } from 'react-icons/fa';
import { FormattedMessage, useIntl } from 'react-intl';
import {DistrictMap} from "../DistrictMap";

export function EaAccreditationFormStep3({
	formStep,
	numberOfSteps,
	errors,
	formikValues,
	handlePreviousStep,
	handleNextStep,
	districts,
	setFieldValue,
	readMode = false
}) {
	const intl = useIntl();

	function toggleDistrict(code) {
		const currentState = formikValues.interventionDistrictCodes.includes(code);
		if(currentState) { // Deactivate
			formikValues.interventionDistrictCodes = formikValues.interventionDistrictCodes.filter(dc => dc !== code);
			//check if the removed district was associated to a tgp in a valency other than ANALYSIS and remove the association
			formikValues.tgpList.forEach(tgp => tgp.hrMapList.forEach(mapping => {
				if(mapping.mappingType !== 'ANALYSIS' && mapping.districtCode === code){
					mapping.districtCode = '-1'
				}
			}))

			formikValues.incubatorLocations.forEach(loc => {
				if(loc.parishCode.startsWith(code)){
					loc.parishCode = '-1'
				}
			})


			let nameOfDistrictBeingRemoved = districts.filter(d => d.code === code)[0].description
			Object.keys(formikValues.equipmentDistribution).forEach(districtNameKey =>{
				if(districtNameKey === nameOfDistrictBeingRemoved){
					delete formikValues.equipmentDistribution[districtNameKey]
				}
			})
		} else { // Activate
			formikValues.interventionDistrictCodes.push(code);
			//check if the added district was associated to a tgp in the ANALYSIS valency and remove the association
			formikValues.tgpList.forEach(tgp => tgp.hrMapList.forEach(mapping => {
				if(mapping.mappingType === 'ANALYSIS' && mapping.districtCode === code){
					mapping.districtCode = '-1'
				}
			}))
		}
		setFieldValue({ ...formikValues });
	}

	function handleCheckboxChange(e, districtCode) {
		if (e.target.checked) {
			formikValues.interventionDistrictCodes.push(districtCode);

			//check if the added district was associated to a tgp in the ANALYSIS valency and remove the association
			formikValues.tgpList.forEach(tgp => tgp.hrMapList.forEach(mapping => {
				if(mapping.mappingType === 'ANALYSIS' && mapping.districtCode === districtCode){
					mapping.districtCode = '-1'
				}

			}))
		} else {
			formikValues.interventionDistrictCodes.splice(
				formikValues.interventionDistrictCodes.indexOf(districtCode),
				1
			);

			//check if the removed district was associated to a tgp in a valency other than ANALYSIS and remove the association
			formikValues.tgpList.forEach(tgp => tgp.hrMapList.forEach(mapping => {
				if(mapping.mappingType !== 'ANALYSIS' && mapping.districtCode === districtCode){
					mapping.districtCode = '-1'
				}
			}))

			formikValues.incubatorLocations.forEach(loc => {
				if(loc.parishCode.startsWith(districtCode)){
					loc.parishCode = '-1' 
				}
			})

			
			let nameOfDistrictBeingRemoved = districts.filter(d => d.code === districtCode)[0].description
			Object.keys(formikValues.equipmentDistribution).forEach(districtNameKey =>{
				if(districtNameKey === nameOfDistrictBeingRemoved){
					delete formikValues.equipmentDistribution[districtNameKey]
				}
			})

		}
		setFieldValue({ ...formikValues });
	}

	return (
		<div>
			<h4 className='text-primary mb-0'>
				<FormattedMessage id='eaAccreditationForm.step3.title' />
			</h4>
			<small className='text-secondary text-uppercase'>
				{intl.formatMessage(
					{ id: 'all.step' },
					{ step: formStep, numberOfSteps: numberOfSteps }
				)}
			</small>
			<Row>
				<Col>
					<Form.Label>
						<FormattedMessage id='eaAccreditationForm.step3.entityDistricts'/>
					</Form.Label>
				</Col>
				</Row>
			<Row className='mt-3'>
				<Col>
					<Table>
						<thead>
							<tr>
								<th />
								<th>
									<FormattedMessage id='eaAccreditationForm.step3.continentDistricts' />
								</th>
								<th>
									<FormattedMessage id='eaAccreditationForm.step3.qualifiedRegionalDelegation' />
								</th>
							</tr>
						</thead>
						<tbody>
							{districts.map((district) => (
								<tr key={district.description} className={'align-items-center' + (errors?.interventionDistrictCodes?.[district.code] ? ' invalid-row' : '')}>
									<td>
										<Form.Check
											checked={formikValues.interventionDistrictCodes.includes(district.code)}
											onChange={(e) =>
												handleCheckboxChange(e, district.code)
											}
											disabled={readMode}
											isInvalid={errors?.interventionDistrictCodes?.[district.code]}
										/>
									</td>
									<td>{district.description}</td>
									<td>{district.regionalDelegationCode}</td>
								</tr>
							))}
						</tbody>
						<tfoot>
							<tr>
								<td colSpan={3}>
									{formikValues.interventionDistrictCodes.length}{' '}
									<FormattedMessage id='eaAccreditationForm.step3.total' />
								</td>
							</tr>
						</tfoot>
					</Table>
				</Col>
				<Col md={"auto"} className={'align-content-center'}>
					<DistrictMap
						className={'align-content-center'}
						activeDistrictCodes={formikValues.interventionDistrictCodes}
						handleDistrictClick={toggleDistrict}
						disabled={readMode}
					/>
				</Col>
			</Row>
			<Row className='mt-4'>
				<Col>
					<small>
						<FormattedMessage id='eaAccreditationForm.step1.getList.regionalDelegationsLegend' />
					</small>
				</Col>
			</Row>
			<div className='form-actions mt-5'>
				<button
					className='btn btn-link'
					type='button'
					onClick={handlePreviousStep}
				>
					<FormattedMessage id='all.back' />
				</button>

				<button
					className='btn btn-primary'
					type='button'
					onClick={handleNextStep}
				>
					<FormattedMessage id='all.nextStep' />
				</button>
			</div>
		</div>
	);
}
