import React from 'react';
import { Modal } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

export function ValidateEconomicViabilityModal({ show, handleClose, validateEconomicViability }) {
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>
          <FormattedMessage id='economicViabilityForm.submissionModal.title' />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <FormattedMessage id='candidature.message.confirmationToAcceptFeasibilityOpinion' />

        <div className='form-actions mt-5'>
          <button className='btn btn-link' type='button' onClick={() => handleClose()}>
            <FormattedMessage id='all.no' />
          </button>

          <button
            className='btn btn-primary'
            type='button'
            onClick={() => validateEconomicViability()}>
            <FormattedMessage id='all.yes' />
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
}
