import React, { useEffect, useRef, useState } from 'react';
import {Col, Container, Form, Nav, Row, Toast, ToastContainer} from 'react-bootstrap';
import { FaAngleLeft, FaCheck, FaExclamationCircle, FaRegCheckCircle, FaRegSave } from 'react-icons/fa';
import { FormattedMessage, useIntl } from 'react-intl';
import {Link, Navigate, useNavigate, useParams, useSearchParams} from 'react-router-dom';
import { isEntityUser } from '../../authentication/authenticationHelper';
import { AlertError } from '../../components/bootstrap/AlertError';
import { EconomicViabilityForm } from '../../components/economicViability/EconomicViabilityForm';
import { EconomicViabilitySubmissionModal } from '../../components/economicViability/EconomicViabilitySubmissionModal';
import { ValidateEconomicViabilityModal } from '../../components/economicViability/ValidateEconomicViabilityModal';
import Loading from '../../components/general/Loading';
import { SubTemplate } from '../../components/general/SubTemplate';
import { setToValidation } from '../../rest/candidature';
import {
  getCandidatureEconomicViability,
  saveEconomicViability
} from '../../rest/economicViability';
import { handleError, isNotBusinessError } from '../../utils/handleError';
import {CandidatureState} from "../../models/CandidatureState";
import {Arrays} from "../../utils/Arrays";
import {ProcessState} from "../../models/ProcessState";

export function AddEconomicViability() {
  const intl = useIntl();

  const { externalId } = useParams();

  const formikPropsRef = useRef(null);

  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [submited, setSubmited] = useState(false);

  const [economicViability, setEconomicViability] = useState(null);
  const [navErrors, setNavErrors] = useState([]);
  const [showSaveToast, setShowSaveToast] = useState(false);
  const [showSubmissionModal, setShowSubmissionModal] = useState(false);
  const [showValidateEconomicViabilityModal, setShowValidateEconomicViabilityModal] =
    useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    fetchData();
  }, [searchParams]);

  async function fetchData() {
    try {

      let queryParams = searchParams.get("version") !==null ? {version:searchParams.get("version")}:null ;
      let { data: economicViability } = await getCandidatureEconomicViability(externalId,queryParams);

      economicViability.step = 1;
      setEconomicViability(economicViability);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  }

  function handleNavClick(value) {
    setNavErrors(navErrors.filter((navError) => navError !== value));
    economicViability.step = value;
  }

  function handleNavError(error) {
    let errors = error.response.data.message.split(',').map((value) => Number.parseInt(value));
    setNavErrors(errors);
  }

  async function handleSave() {
    let values = formikPropsRef.current.values;
    values.candidatureExternalId = externalId;
    values.validate = false;

    try {
      const { data: economicViability } = await saveEconomicViability(values);
      economicViability.step = values.step;
      setEconomicViability(economicViability);
      setShowSaveToast(true);
    } catch (error) {
      setError(error);
    }
  }

  async function handleSubmitAll() {
    let errors = await validateAllForm();
    console.log(errors);
    setNavErrors(errors);
    setShowSubmissionModal(true);
  }



  async function submitAll(user, password, setModalError) {
    try {
      let values = formikPropsRef.current.values;
      values.candidatureExternalId = externalId;
      values.validate = true;
      values.authenticationCredentials = {
        user: user,
        password: password
      }
      await saveEconomicViability(values);

      setNavErrors([]);
      setSubmited(true);
    } catch (error) {
      if (error.response.data.exception === 'ValidationException') {
        handleNavError(error);
      } else {
        setModalError(error);
      }
    }
  }

  async function validateEconomicViability() {
    try {
      await setToValidation(externalId);
      setSubmited(true);
    } catch (error) {
      setError(error);
    }
  }

  async function validateAllForm() {
    let errors = [];

    await formikPropsRef.current.validateForm();
    await formikPropsRef.current.validateForm();

    Object.keys(formikPropsRef.current.errors).forEach((key) => {
      switch (key) {
        case 'processNumber':
        case 'legalNature':
        case 'activity':
        case 'cae':
        case 'contactedPromoterDetails':
          errors.push(1);
          break;

        case 'projectDescription':
        case 'projectLocalization':
        case 'projectInstallations':
        case 'projectEquipments':
        case 'innovativeCharacter':
        case 'promotersProfileEvaluation':
          errors.push(2);
          break;

        case 'changesJustification':
          errors.push(3);
          break;

        case 'earlyLoanRepayment':
        case 'earlyLoanDescription':
        case 'deferral':
        case 'financialDescription':
          errors.push(4);
          break;
        case 'economicViabilityAccretionValidation':
          errors.push(5);
          break;

        case 'analysisMarkersDetails':
          errors.push(7);
          break;

        case 'globalOpinion':
        case 'technicianName':
          errors.push(8);
          break;

        default:
          break;
      }
    });

    return errors;
  }

  function getErrorInformation(value) {
    if (navErrors.includes(value)) {
      return (
        <span>
          <FaExclamationCircle />
        </span>
      );
    }
  }

  function getSaveToastInformation(message) {
    return (
      <ToastContainer className='fixed-top'>
        <Toast autohide delay={3000} show={showSaveToast} onClose={() => setShowSaveToast(false)}>
          <Toast.Header>
            <strong className='me-auto text-primary'>
              <span className='mr-2'>
                <FaCheck />
              </span>
              {message}
            </strong>
          </Toast.Header>
        </Toast>
      </ToastContainer>
    );
  }

  if (submited) {
    isEntityUser()
      ? sessionStorage.setItem('economicViabilitySubmitted', submited)
      : sessionStorage.setItem('economicViabilityValidated', submited);
    return <Navigate replace to={`/candidatura/${externalId}`} />;
  }

  if (loading) {
    return <Loading />;
  }

  if (error && isNotBusinessError(error)) {
    return handleError(error);
  }

  return (
    <SubTemplate
      hasBackButton
      titleId='economicViabilityForm.title'
      centerContent
      subTitle={
            <span
                className="badge rounded-pill bg-secondary fw-normal ms-2"><span
                className="text-uppercase fw-bold"><FormattedMessage
                id={`processState.${economicViability.state}`}
            /> </span>  {economicViability.submissionDate !== undefined && economicViability.submissionDate !== null && (<> · <FormattedMessage id={'submitted.process.at'} values={{date:economicViability.submissionDate}}/>  </>) } </span>
        }
      >

      <Container>

        <EconomicViabilitySubmissionModal
          show={showSubmissionModal}
          handleClose={() => setShowSubmissionModal(false)}
          errors={navErrors}
          submitAll={submitAll}
        />
        <ValidateEconomicViabilityModal
          show={showValidateEconomicViabilityModal}
          handleClose={() => setShowValidateEconomicViabilityModal(false)}
          validateEconomicViability={validateEconomicViability}
        />

        <Row className='mb-5'>
          <Col md='2'>
            <Nav
              variant='pills'
              defaultActiveKey={4}
              className='flex-column'
              activeKey={economicViability.step}>
              <Nav.Link
                className={`${navErrors.includes(1) ? 'activeError' : ''}`}
                eventKey={1}
                onClick={() => handleNavClick(1)}>
                <FormattedMessage id='economicViabilityForm.identificationTab' />
                {getErrorInformation(1)}
              </Nav.Link>
              <Nav.Link
                className={`${navErrors.includes(2) ? 'activeError' : ''}`}
                eventKey={2}
                onClick={() => handleNavClick(2)}>
                <FormattedMessage id='economicViabilityForm.evaluationTab' />
                {getErrorInformation(2)}
              </Nav.Link>
              <Nav.Link
                className={`${navErrors.includes(3) ? 'activeError' : ''}`}
                eventKey={3}
                onClick={() => handleNavClick(3)}>
                <FormattedMessage id='economicViabilityForm.investmentPlanTab' />
                {getErrorInformation(3)}
              </Nav.Link>
              <Nav.Link
                className={`${navErrors.includes(4) ? 'activeError' : ''}`}
                eventKey={4}
                onClick={() => handleNavClick(4)}>
                <FormattedMessage id='economicViabilityForm.financialInvestmentTab' />
                {getErrorInformation(4)}
              </Nav.Link>
              <Nav.Link
                  className={`${navErrors.includes(5) ? 'activeError' : ''}`}
                  eventKey={5}
                  onClick={() => handleNavClick(5)}>
                <FormattedMessage id='economicViabilityForm.accretionTab' />
                {getErrorInformation(5)}
              </Nav.Link>
              <Nav.Link
                className={`${navErrors.includes(6) ? 'activeError' : ''}`}
                eventKey={6}
                onClick={() => handleNavClick(6)}>
                <FormattedMessage id='economicViabilityForm.analysisMarkersTab1' />
                {getErrorInformation(6)}
              </Nav.Link>
              <Nav.Link
                className={`${navErrors.includes(7) ? 'activeError' : ''}`}
                eventKey={7}
                onClick={() => handleNavClick(7)}>
                <FormattedMessage id='economicViabilityForm.analysisMarkersTab2' />
                {getErrorInformation(7)}
              </Nav.Link>
              <Nav.Link
                className={`${navErrors.includes(8) ? 'activeError' : ''}`}
                eventKey={8}
                onClick={() => handleNavClick(8)}>
                <FormattedMessage id='economicViabilityForm.overallLookTab' />
                {getErrorInformation(8)}
              </Nav.Link>
            </Nav>
          </Col>
          <Col md='8'>

            <AlertError error={error} />
            {getSaveToastInformation(intl.formatMessage({ id: 'all.saveToastInformation' }))}
            {economicViability &&
            <EconomicViabilityForm
                candidatureExternalId={externalId}
                economicViability={economicViability}
                formikPropsRef={formikPropsRef}
                error={error}
                setError={setError}
                handleSave={handleSave}
                numberOfSteps={8}
            />

            }

          </Col>
          <Col md='2'>
            <div>
              {(economicViability.state === 'DRAFT' &&   (economicViability.candidatureState===CandidatureState.EMIT_OPINION || economicViability.candidatureState===CandidatureState.NEW_EMIT_OPINION )) &&(
                <div>
                  <button
                    className='btn btn-outline-primary d-flex align-items-center justify-content-center mb-4 w-100'
                    type='button'
                    onClick={() => handleSave()}>
                    <FaRegSave />
                    <FormattedMessage id='all.save' />
                  </button>

                  <button
                    className='btn btn-primary d-flex align-items-center justify-content-center w-100'
                    type='button'
                    onClick={() => handleSubmitAll()}>
                    <FaRegCheckCircle />
                    <FormattedMessage id='all.submitButtonText' />
                  </button>
                </div>
              )}

              {Arrays.isNotEmpty(economicViability.economicViabilitiesVersions) && (
                  <div className='border-start p-1 pe-0 mt-3 text-secondary'>
                    <FormattedMessage id={'other.economicViabilities'}/>

                    {economicViability.economicViabilitiesVersions.map((version,index) => (

                          <Link
                              to={`/candidatura/${externalId}/emitirParecer?version=${version.version}`}
                              className='d-flex align-items-left justify-content-left w-100 mb-4 mt-3'>
                            {version.date}
                          </Link>
                    ))}




                  </div>
              )


              }




            </div>
          </Col>
        </Row>
      </Container>
    </SubTemplate>
  );
}
