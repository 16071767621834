import React, { useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { TextInputField } from './bootstrap/TextInputField';

export function UploadDocument({ onSubmit, index, acceptExtensions }) {
  const hiddenFileInput = React.useRef(null);

  const [file, setFile] = useState({
    content: null,
    fileDescription: ''
  });

  function handleChange(e, field) {
    if (field === 'content') {
      file[field] = e.target.files[0];
    } else {
      file[field] = e.target.value;
    }
    setFile({ ...file });
  }

  return (
    <Row>
      <Col md='6' className='mb-3'>
        <TextInputField
          labelClass={'mt-0'}
          labelId={'candidatureForm.documentDescription'}
          name={'fileDescription'}
          handleChange={(e) => handleChange(e, 'fileDescription')}
          value={file.fileDescription}
        />
      </Col>
      <Col md='6' className='mb-3'>
        <Form.Group controlId='file'>
          <Form.Label className='mt-0'>
            <FormattedMessage id='all.file' />
          </Form.Label>
          <div className='input-group'>
            <label className='input-group-text my-0' htmlFor={`inputGroupFile${index && index}`}>
              <FormattedMessage id='all.selectFile' />
            </label>
            <input
              type='file'
              name='file'
              accept={acceptExtensions}
              ref={hiddenFileInput}
              onChange={(e) => handleChange(e, 'content')}
              className={`form-control ${file.content === null ? 'empty' : ''}`}
              id={`inputGroupFile${index && index}`}></input>
          </div>
        </Form.Group>
      </Col>

      <Col md='12'>
        <button
          className='btn btn-outline-primary btn-block mt-2'
          type='button'
          onClick={() => onSubmit(file, setFile, hiddenFileInput)}>
          <FormattedMessage id='all.upload' />
        </button>
      </Col>
    </Row>
  );
}
