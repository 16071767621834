import React from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { TextInputField } from '../bootstrap/TextInputField';
import { FaInfoCircle } from 'react-icons/fa';

export function FinancialModalityStep({
	step,
	numberOfSteps,
	errors,
	handleChange,
	formikValues,
	handleGoBack,
	handleNextStep,
	readMode,
}) {
	const intl = useIntl();

	function handlefinancialModalityRadioChange(type, value) {
		formikValues.financialModality[type] = value;
	}

	return (
		<div>
			<h4 className='text-primary mb-0'>
				<FormattedMessage id='eaevaluationform.modalityidentification' />
			</h4>
			<small className='text-secondary text-uppercase'>
				{intl.formatMessage(
					{ id: 'all.step' },
					{ step: step, numberOfSteps: numberOfSteps }
				)}
			</small>
			<fieldset disabled={readMode==true}>
				<p className='mt-3 mb-0 text-secondary infoText'>
					<FaInfoCircle />
					<FormattedMessage id='financialModality.emprendeSub' />
				</p>
				<Row className='mt-4'>
					<Col md='12'>
						<label>
							<FormattedMessage id='financialModality.unemploymentAntecipation' />
						</label>
						<div>
							<Form.Check
								inline
								name={'financialModality.unemploymentAntecipation'}
								type={'radio'}
								label={<FormattedMessage id='all.yes' />}
								defaultChecked={
									formikValues.financialModality.unemploymentAntecipation ===
									true
								}
								onChange={() =>
									handlefinancialModalityRadioChange(
										'unemploymentAntecipation',
										true
									)
								}
								isInvalid={errors.financialModality?.unemploymentAntecipation}
							/>
							<Form.Check
								inline
								name={'financialModality.unemploymentAntecipation'}
								type={'radio'}
								label={<FormattedMessage id='all.no' />}
								defaultChecked={
									formikValues.financialModality.unemploymentAntecipation ===
									false
								}
								onChange={() =>
									handlefinancialModalityRadioChange(
										'unemploymentAntecipation',
										false
									)
								}
								isInvalid={errors.financialModality?.unemploymentAntecipation}
							/>
						</div>
					</Col>
				</Row>
				<p className='mt-4'>
					<FormattedMessage id='financialModality.emprende' />
				</p>
				<Row className='mb-2'>
					<Col>
						<div className='d-flex align-items-center'>
							<div>
								<Form.Check
									type='checkbox'
									name='financialModality.interestFreeLoan'
									defaultChecked={
										formikValues.financialModality.interestFreeLoan === true
									}
									onChange={handleChange}
									isInvalid={errors.financialModality?.interestFreeLoan}
									label={
										<FormattedMessage id='financialModality.interestFreeLoan' />
									}
								/>
							</div>
						</div>
					</Col>
				</Row>
				<Row className='mb-2'>
					<Col>
						<div className='d-flex align-items-center'>
							<div>
								<Form.Check
									type='checkbox'
									name='financialModality.nonRefundableSubsidy'
									defaultChecked={
										formikValues.financialModality.nonRefundableSubsidy === true
									}
									onChange={handleChange}
									isInvalid={errors.financialModality?.nonRefundableSubsidy}
									label={
										<FormattedMessage id='financialModality.nonRrefundableSubsidy' />
									}
								/>
							</div>
						</div>
					</Col>
				</Row>
				<Row className='mb-2'>
					<Col md='12'>
						<div className='d-flex align-items-center'>
							<div>
								<Form.Check
									type='checkbox'
									name='financialModality.financialEmployment'
									defaultChecked={
										formikValues.financialModality.financialEmployment === true
									}
									onChange={handleChange}
									isInvalid={errors.financialModality?.financialEmployment}
									label={
										<FormattedMessage id='financialModality.financialEmployment' />
									}
								/>
							</div>
						</div>
					</Col>
				</Row>
				<Row className='mb-2'>
					<Col md='12'>
						<div className='d-flex align-items-center'>
							<div>
								<Form.Check
									type='checkbox'
									name='financialModality.classificationAxis'
									defaultChecked={
										formikValues.financialModality.classificationAxis === true
									}
									onChange={handleChange}
									isInvalid={errors.financialModality?.classificationAxis}
									label={
										<FormattedMessage id='financialModality.classificationAxis' />
									}
								/>
							</div>
						</div>
					</Col>
				</Row>
				<p className='mt-5'>
					{' '}
					<FormattedMessage id='financialModality.othersModality' />
				</p>
				<Row className='mb-2'>
					<Col md='12'>
						<div className='d-flex align-items-center'>
							<div>
								<Form.Check
									type='checkbox'
									name='financialModality.mais'
									defaultChecked={formikValues.financialModality.mais === true}
									onChange={handleChange}
									isInvalid={errors.financialModality?.mais}
									label={<FormattedMessage id='financialModality.MAIS' />}
								/>
							</div>
						</div>
					</Col>
				</Row>
				<Row className='mb-2'>
					<Col md='12'>
						<div className='d-flex align-items-center'>
							<div>
								<Form.Check
									type='checkbox'
									name='financialModality.emigrantSupport'
									defaultChecked={
										formikValues.financialModality.emigrantSupport === true
									}
									onChange={handleChange}
									isInvalid={errors.financialModality?.emigrantSupport}
									label={
										<FormattedMessage id='financialModality.emigrantSupport' />
									}
								/>
							</div>
						</div>
					</Col>
				</Row>
				<Row className='mb-2'>
					<Col md='12'>
						<div className='d-flex align-items-center'>
							<div>
								<Form.Check
									type='checkbox'
									name='financialModality.contractSupport'
									defaultChecked={
										formikValues.financialModality.contractSupport === true
									}
									onChange={handleChange}
									isInvalid={errors.financialModality?.contractSupport}
									label={
										<FormattedMessage id='financialModality.contractSupport' />
									}
								/>
							</div>
						</div>
					</Col>
				</Row>
				<Row className='mb-2'>
					<Col md='12'>
						<div>
							<label>
								<FormattedMessage id='financialModality.others' />
							</label>
							<div>
								<TextInputField
									name='financialModality.others'
									handleChange={handleChange}
									value={formikValues.financialModality.others}
									isInvalid={errors.financialModality?.others}
									errorMessage={errors.financialModality?.others}
								/>
							</div>
						</div>
					</Col>
				</Row>
			</fieldset>
			<div className='form-actions mt-5'>
				<button
					className='btn btn-link'
					type='button'
					onClick={() => handleGoBack()}
				>
					<FormattedMessage id='all.back' />
				</button>

				<button
					className='btn btn-primary'
					type='button'
					onClick={() => handleNextStep()}
				>
					<FormattedMessage id='all.nextStep' />
				</button>
			</div>
		</div>
	);
}
