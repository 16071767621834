import { apiAxios as authAxios } from '../axios.config';

export const getPromoter = () => {
    return authAxios.get('/api/promoter')
}

export const savePromoter = (promoter) => {
    return authAxios.post('api/promoter', promoter);
}

export const canValidateCandidature = (candidatureExternalId) => {
    return authAxios.get(`api/promoter/canValidateCandidature/${candidatureExternalId}`);
}
