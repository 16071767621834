import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Row, Tab, Tabs } from 'react-bootstrap';
import { FaInfoCircle } from 'react-icons/fa';
import { FormattedMessage } from 'react-intl';
import {Link, Navigate, useNavigate, useParams} from 'react-router-dom';
import { AlertError } from '../../../components/bootstrap/AlertError';
import { DecisionDialog } from '../../../components/bootstrap/DecisionDialog';
import { PriorSupportMessageToPromoterModal } from '../../../components/candidature/PriorSupportMessageToPromoterModal ';
import Loading from '../../../components/general/Loading';
import { SubTemplate } from '../../../components/general/SubTemplate';
import { MessageList } from '../../../components/messages/MessageList';
import { PaginationComponent } from '../../../components/PaginationComponent';
import ContratoMCE from '../../../documents/ANEXO B3-A -CONTRATO DE PRESTAÇÃO DE MCE APOIO Á CONSOLIDAÇÃO DO PROJETO.docx';
import { ConsultingRequestState } from '../../../models/ConsultingRequestState';
import {
  acceptConsultingRequest,
  confirmConsultingRequestDecision,
  getAllowActionsFinalReportByConsultingRequest,
  getAllowActionsInvestmentConfirmationByConsultingRequest,
  getConsultingRequestByExternalId,
  getConsultingRequestMessages, listConsultingRequestsByCandidatureExternalId,
  rejectConsultingRequest
} from '../../../rest/consultingRequest';
import { handleError, isNotBusinessError } from '../../../utils/handleError';
import {AlertSuccess} from "../../../components/bootstrap/AlertSuccess";
import {isBackOfficeAdmin, isEntityUser, isPromoterUser} from "../../../authentication/authenticationHelper";
import {canListSemestralReport} from "../../../rest/semestralReport";
import {PriorSupportState} from "../../../models/PriorSupportState";
import {ProcessState} from "../../../models/ProcessState";
import {DeclarationEndFirstYearButton} from "../../../components/declarationEndFirstYear/DeclarationEndFirstYearButton";
import {canReadFunction} from "../../../rest/declarationEndOfFirstYear";
import {AuthenticatedDownloadLink} from "../../../components/document/AuthenticatedDownloadLink";
import {Arrays} from "../../../utils/Arrays";
import {CloseableModalDialog} from "../../../components/general/CloseableModalDialog";

const MAX_RESULTS = 15;
const DEFAULT_INITIAL_PAGE = 0;

export function RequestConsultingDetail() {
  const { externalId } = useParams();

  const [consultingRequest, setConsultingRequest] = useState(null);
  const [allowActionsFinalReport, setAllowActionsFinalReport] = useState(null);
  const [allowActionsInvestmentConfirmation, setAllowActionsInvestmentConfirmation] = useState(null);
  const [canListSemesterReport, setCanListSemesterReport] = useState(null);
  const [consultingRequestMessages, setConsultingRequestMessages] = useState([]);
  const [revokedRequests, setRevokedRequests] = useState([]);
  const [showAccept, setShowAccept] = useState(false);
  const [showReject, setShowReject] = useState(false);
  const [showRevoked, setShowRevoked] = useState(false);
  const [canReadB15, setCanReadB15] = useState(null);
  const [showMCEEntityMessagePromoterModal, setShowMCEEntityMessagePromoterModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(DEFAULT_INITIAL_PAGE);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [submited, setSubmited] = useState(false);
  const [lsItem, setLsItem] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    fetchData();
  }, [externalId]);

  async function fetchData() {
    try {
      const [{ data: consultingRequest }, { data: messages }, { data: allowActions },{data:allowActionsInvestment}, { data: canList },{data:canReadIn}] = await Promise.all([
        getConsultingRequestByExternalId(externalId),
        isBackOfficeAdmin() ? [] : getConsultingRequestMessages(externalId, currentPage, MAX_RESULTS),
        getAllowActionsFinalReportByConsultingRequest(externalId),
        getAllowActionsInvestmentConfirmationByConsultingRequest(externalId),
        canListSemestralReport(externalId),
          canReadFunction(externalId),
      ]);
      setConsultingRequest(consultingRequest);
      setConsultingRequestMessages(messages);
      setAllowActionsFinalReport(allowActions);
      setAllowActionsInvestmentConfirmation(allowActionsInvestment);
      setCanListSemesterReport(canList);
      setCanReadB15(canReadIn)

      const {data: revokedRequests} = await listConsultingRequestsByCandidatureExternalId(consultingRequest.candidatureExternalId);
      setRevokedRequests(revokedRequests);
      checkLocalStorageItems();
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  }

  async function retrieveMCEMessages() {
    try {
      const { data: messages } = await getConsultingRequestMessages(
        externalId,
        currentPage,
        MAX_RESULTS
      );
      setConsultingRequestMessages(messages);
    } catch (error) {
      setError(error);
    }
  }

  async function handleSubmit(data) {
    try {
      await confirmConsultingRequestDecision(externalId, data);
      sessionStorage.setItem(`consultingRequest${data.flag ? 'Accepted' : 'Declined'}`, submited);
      setSubmited(true);
    } catch (error) {
      setError(error);
    }
  }

  async function acceptRequest() {
    try {
      await acceptConsultingRequest(externalId);
      sessionStorage.setItem('consultingRequestAccepted', submited);
      setSubmited(true);
    } catch (error) {
      setError(error);
    }
  }

  async function rejectRequest(reason) {
    let body = {
      reason: reason
    };
    try {
      await rejectConsultingRequest(externalId, body);
      sessionStorage.setItem('consultingRequestDeclined', submited);
      setSubmited(true);
    } catch (error) {
      setError(error);
    }
  }

  const onPageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  function canVisualizeCandidature() {
    return (
      (consultingRequest.state !== ConsultingRequestState.REJECTED &&
        consultingRequest.canEntityViewCandidature) ||
      consultingRequest.state === ConsultingRequestState.COMPLETED || consultingRequest.state === ConsultingRequestState.AWAIT_REVOKE_DECISION
    );
  }


  function checkLocalStorageItems() {
    [
      'finalReportSubmited','finalReportAccept','finalReportReject','investmentConfirmation','investmentConfirmationAccept','investmentConfirmationReject','declarationFirstYear'
    ].forEach((s) => {
      if (sessionStorage.getItem(s)) {
        setLsItem(s);
      }
    });
  }


  if (submited) {
    return <Navigate replace to='/pedidosMCE' />;
  }

  if (loading) {
    return <Loading />;
  }

  if (error && isNotBusinessError(error)) {
    return handleError(error);
  }

  function redirectToFormPage() {
			navigate(
				`/pedidosMCE/${externalId}/relatorioSemestral/lista`
			);
	}

  function redirectToPaymentRequestsPage() {
    navigate(
        `/pedidosMCE/${externalId}/pedidosPagamento`
    );
  }



  return (
    <SubTemplate titleId='consultingRequest.detail.title' hasBackButton>
      <Container>
        <div className='mx-2 mb-4'>
          <AlertSuccess lsItem={lsItem} />
        </div>
        <PriorSupportMessageToPromoterModal
          show={showMCEEntityMessagePromoterModal}
          setShow={setShowMCEEntityMessagePromoterModal}
          externalId={consultingRequest.externalId}
          updatePriorSupportMessagesCallback={retrieveMCEMessages}
          isMCE={true}
        />
        <DecisionDialog
          bodyId={'acceptConsultingRequest.modal.question'}
          titleId={'acceptConsultingRequest.modal.title'}
          handleConfirm={acceptRequest}
          show={showAccept}
          handleClose={() => setShowAccept(false)}
        />
        <DecisionDialog
          bodyId={'rejectConsultingRequest.modal.question'}
          titleId={'rejectConsultingRequest.modal.title'}
          handleConfirm={rejectRequest}
          show={showReject}
          handleClose={() => setShowReject(false)}
          needReason={true}
        />
        <CloseableModalDialog
            titleId={'candidatureHistory.title'}
            show={showRevoked}
            setShow={setShowRevoked}
        >
          {Arrays.isNotEmpty(revokedRequests) && revokedRequests
              .sort((a, b) => new Date(a.creationDate) - new Date(b.creationDate))
              .map( (rr, index) => (
          <Link
              key={index}
              className='d-flex align-items-left justify-content-left w-100 mb-4 mt-3'
              to={`/pedidosMCE/${rr.externalId}`}
          >
            {rr.entityDescription}: <FormattedMessage id={`consultingRequestState.${rr.state}`} />
          </Link>
          ))}
        </CloseableModalDialog>

        <Row>
          <Col md='9' className='mb-4'>
            <AlertError error={error} />
            <Tabs defaultActiveKey={'resume'} justify>
              <Tab
                eventKey='resume'
                title={<FormattedMessage id='detailPriorSupport.tabs.resume' />}
                className='mb-4 p-3 border'>
                {consultingRequest.state === ConsultingRequestState.APPROVED && (
                  <div>
                    <Row>
                      <Col>
                        <p className='mt-3 text-secondary infoText'>
                          <FaInfoCircle size={40} />
                          {isEntityUser() ? <FormattedMessage id='contratactMCE.entity.info' /> : <FormattedMessage id='contratactMCE.promoter.info' />}

                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <a target='_blank' rel='noreferrer' href={ContratoMCE}>
                          <FormattedMessage id='contractMCE.download' />
                        </a>
                      </Col>
                    </Row>
                  </div>
                )}
                <Row className='mt-5'>
                  <Col md='6'>
                    <label>
                      <FormattedMessage id='priorSupport.externalId' />
                    </label>
                    <p>{consultingRequest.externalId}</p>
                  </Col>
                  <Col md='3'>
                    <label>
                      <FormattedMessage id='priorSupport.state' />
                    </label>
                    <FormattedMessage id={`consultingRequestState.${consultingRequest.state}`} />
                  </Col>
                </Row>
                {canVisualizeCandidature() && (
                  <Row>
                    <Col md='6'>
                      <label>
                        <FormattedMessage id='consultingRequest.candidatureProcessId' />
                      </label>
                      <Link to={`/candidatura/${consultingRequest.candidatureExternalId}`}>
                        {consultingRequest.candidatureProcessId}
                      </Link>
                    </Col>
                    <Col md='3'>
                      <label>
                        <FormattedMessage id='consultingRequest.initialDate' />
                      </label>
                      {consultingRequest.initialContractDate}
                    </Col>


                  </Row>
                )}

                  <Row className={'mt-4'}>
                    <Col md='6'>
                      <label>
                        <FormattedMessage id='consultingRequest.selectedEntity' />
                      </label>
                      <p>{consultingRequest.entityDescription}</p>
                    </Col>
                    <Col md='6'>
                      <label>
                        <FormattedMessage id='consultingRequest.canEntityViewCandidature.viewMode' />
                      </label>
                      <p>
                        <FormattedMessage
                          id={`all.${consultingRequest.canEntityViewCandidature ? 'yes' : 'no'}`}
                        />
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <label>
                        <FormattedMessage id='consultingRequest.owner.name' />
                      </label>
                      <p>{consultingRequest.promoterName}</p>
                    </Col>
                    <Col md={6}>
                      <label>
                        <FormattedMessage id='consultingRequest.owner.email' />
                      </label>
                      <p>{consultingRequest.promoterEmail}</p>
                    </Col>

                  </Row>


                  <Row>
                    <Col md={6}>
                      <label>
                        <FormattedMessage id='consultingRequest.owner.NIF' />
                      </label>
                      <p>{consultingRequest.nifTitular}</p>
                    </Col>
                    <Col>
                      <label>
                        <FormattedMessage id='candidatureTabs.submissionDate' />
                      </label>
                      <p>{consultingRequest.creationDate}</p>
                    </Col>
                    {(consultingRequest.state !== ConsultingRequestState.SUBMITTED && consultingRequest.state !== ConsultingRequestState.COMPLETED && consultingRequest.state!==ConsultingRequestState.AWAIT_REVOKE_DECISION) && (
                      <Col>
                        <label>
                          <FormattedMessage
                            id={`consultingRequest.${
                              consultingRequest.state !== ConsultingRequestState.REJECTED
                                ? 'acceptedDate'
                                : 'rejectionDate'
                            }`}
                          />
                        </label>
                        <p>
                          {consultingRequest.state !== ConsultingRequestState.REJECTED
                            ? consultingRequest.acceptedDate
                            : consultingRequest.rejectionDate}
                        </p>
                      </Col>
                    )}
                  </Row>
                {consultingRequest.mceContract !=undefined &&
                <Row>

                  <div>
                    <FormattedMessage id={'documentType.MCE_CONTRACT'}/>
                    <br/>

                    <AuthenticatedDownloadLink
                        className='payment-entry-document-link'
                        url={consultingRequest.mceContract.documentPath}
                        filename={consultingRequest.mceContract.name}
                    >
                      {consultingRequest.mceContract.name}
                    </AuthenticatedDownloadLink>
                  </div>

                </Row>

                }

              </Tab>
              {!isBackOfficeAdmin() && <Tab
                eventKey='messages'
                title={<FormattedMessage id='detailPriorSupport.tabs.messages' />}
                className='mb-4 p-3 border'>
                <div className='mt-3'>
                  <PaginationComponent
                    dataPerPage={MAX_RESULTS}
                    totalData={consultingRequestMessages.numberOfMessages}
                    onPageChange={onPageChange}
                    currentPage={currentPage}
                  />
                  <MessageList messages={consultingRequestMessages.messages} />
                </div>
              </Tab>}
            </Tabs>
          </Col>
          <Col md='3'>
            <div className='px-2 mb-5'>
              {consultingRequest.state === ConsultingRequestState.SUBMITTED && (
                <>
                  <Button
                    className='btn btn-outline-primary d-flex align-items-center justify-content-center mb-4 w-100'
                    variant='outline-primary'
                    onClick={() => setShowAccept(true)}>
                    <FormattedMessage id='acceptConsultingRequest.accept.button' />
                  </Button>
                  <Button
                    className='btn btn-outline-primary d-flex align-items-center justify-content-center mb-4 w-100'
                    variant='outline-primary mt-2'
                    onClick={() => setShowReject(true)}>
                    <FormattedMessage id='rejectConsultingRequest.modal.title' />
                  </Button>
                </>
              )}
              {(consultingRequest.state !== ConsultingRequestState.REJECTED  && isEntityUser()) &&(
                <button
                  className='btn btn-outline-primary d-flex align-items-center justify-content-center mb-4 w-100'
                  type='button'
                  onClick={() => {
                    setShowMCEEntityMessagePromoterModal(true);
                  }}>
                  <FormattedMessage id='candidatureForm.messagePriorSupportPromoter' />
                </button>
              )}

              {!isBackOfficeAdmin() && ([ConsultingRequestState.REVOKED,ConsultingRequestState.COMPLETED,ConsultingRequestState.AWAIT_REVOKE_DECISION].includes(consultingRequest.state) &&

              (isEntityUser() || ProcessState.DRAFT !== consultingRequest.b9ProcessState)) &&

                  <button
                      className='btn btn-outline-primary d-flex align-items-center justify-content-center mb-4 w-100'
                      type='button'
                      onClick={() => {
                        navigate('/planodesenvolvimento/'+ consultingRequest.b9ExternalId,{ replace: true });
                      }}>
                    <FormattedMessage id='processType.B9_FORM' />
                  </button>
              }


              {
                canReadB15.flag===true && (
                    <DeclarationEndFirstYearButton
                        consultingRequestExternalId={externalId}

                    />
                )}



              {allowActionsFinalReport.canView ===true && (
                  <button
                      className='btn btn-outline-primary d-flex align-items-center justify-content-center mb-4 w-100'
                      type='button'
                      onClick={() => {
                        navigate('/pedidosMCE/'+ externalId+ '/relatorioFinal',{ replace: true });
                      }}>
                    <FormattedMessage id='processType.B13_FORM' />
                  </button>
              )}

              {allowActionsInvestmentConfirmation.canView ===true && (
                  <button
                      className='btn btn-outline-primary d-flex align-items-center justify-content-center mb-4 w-100'
                      type='button'
                      onClick={() => {
                        navigate('/pedidosMCE/'+ externalId+ '/investimento/confirmacao',{ replace: true });
                      }}>
                    <FormattedMessage id='processType.B16_FORM' />
                  </button>
              )}
              {canListSemesterReport.flag===true &&
              <>
              <button
                  className='btn btn-outline-primary d-flex align-items-center justify-content-center mb-4 w-100'
                  type='button'
                  onClick={() => {
                    redirectToFormPage();
                  }} >
                <FormattedMessage id='semestralReportForm.button.list' />
              </button>
                {!isPromoterUser() &&
                <button
                    className='btn btn-outline-primary d-flex align-items-center justify-content-center mb-4 w-100'
                    type='button'
                    onClick={() => {
                      redirectToPaymentRequestsPage();
                    }} >
                  <FormattedMessage id='paymentRequestMCE.button.list' />
                </button>
                }



              </>
              }

              {!isBackOfficeAdmin() &&
                [ConsultingRequestState.REVOKED,ConsultingRequestState.COMPLETED,ConsultingRequestState.AWAIT_REVOKE_DECISION].includes(consultingRequest.state) &&(
                    <button
                        className='btn btn-outline-primary d-flex align-items-center justify-content-center mb-4 w-100'
                        type='button'
                        onClick={() => {
                          navigate('/pedidosMCE/'+externalId+'/revogacoes');
                        }}>

                      <FormattedMessage id='revocationForm.list.button' />
                    </button>

                )
              }

              {!isBackOfficeAdmin() && Arrays.isNotEmpty(revokedRequests) && <button
                  className='btn btn-outline-primary d-flex align-items-center justify-content-center mb-4 w-100'
                  type='button'
                  onClick={() => setShowRevoked(true)} >
                <FormattedMessage id='consultingRequest.revokedRequests' />
              </button>}

            </div>
          </Col>
        </Row>
      </Container>
    </SubTemplate>
  );
}
