import React from 'react';
import { SinglePromoterDetails } from './SinglePromoterDetails';

export function PromoterFormDetails({ promotersMetaDataList }) {
	return promotersMetaDataList.map((promoter, index) => (
		<span key={index}>
			<p className='mt-5 mb-0 text-uppercase'>Promotor {index + 1} </p>
			<SinglePromoterDetails promoter={promoter} />
		</span>
	));
}
