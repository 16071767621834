import React, { useEffect, useState } from 'react';
import { Row, Col, Table, Accordion } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { TextInputField } from '../../bootstrap/TextInputField';
import { NumberInputField } from '../../bootstrap/NumberInputField';
import { TextAreaInputField } from '../../bootstrap/TextAreaInputField';
import { EnumSelectField } from '../../bootstrap/EnumSelectField';
import { MceYearType } from '../../../models/MceYearType';
import yearsToMonths from 'date-fns/yearsToMonths/index';
import { FaRegTimesCircle } from 'react-icons/fa';

export function SemestralReportFormStep8({
	formStep,
	numberOfSteps,
	errors,
	handleChange,
	formikValues,
	handlePreviousStep,
	handleNextStep,
	setFieldValue,
	readMode
}) {
	console.log(formikValues);
	const intl = useIntl();

	const mceActivitiesOptions = ['FOLLOW_UP', 'CONSULTANCY', 'SKILL_EXTENSION'];

	const year = formikValues.mceYearType === MceYearType.FIRST && '1'
	|| formikValues.mceYearType === MceYearType.SECOND && '2'
	|| formikValues.mceYearType === MceYearType.THIRD && '3'

	console.log(formikValues.semester)

	const semester = formikValues.semester===false ? '1' : '2'


	function addTimelineEntry() {
		formikValues.semesterTimelineEntryB11s.push({
			activityType: '',
			actions: '',
			forecastSemester: '',
			realSemester: '',
		});
		setFieldValue({ ...formikValues });
	}

	function removeTimelineEntry(index) {
		formikValues.semesterTimelineEntryB11s.splice(index, 1);
		setFieldValue({ ...formikValues });
	}

	return (
		<div>
			<h4 className='text-primary mb-0'>
				<FormattedMessage id='semestralReportForm.step8.title' />
			</h4>
			<small className='text-secondary text-uppercase'>
				{intl.formatMessage(
					{ id: 'all.step' },
					{ step: formStep, numberOfSteps: numberOfSteps }
				)}
			</small>
			<fieldset disabled={readMode==true}>
			<Row>
				<Col md='2'>
					<NumberInputField
						disabled={true}
						value={year}
						labelId='semestralReportForm.step4.year'
					/>
				</Col>
				<Col md='3'>
					<NumberInputField
						disabled={true}
						value={semester}
						labelId='semestralReportForm.step4.semester'
					/>
				</Col>
				<Col md='7'>
					<TextInputField
						disabled={true}
						value={formikValues.company.name}
						labelId='semestralReportForm.step8.companyDesignation'
					/>
				</Col>
			</Row>
			


			<Row className='mt-4'>
				<Col>
					<Table striped responsive='md'>
						<thead>
							<tr>
								<th className='w-35'>
									<FormattedMessage id='semestralReportForm.step8.accordion.MCEActivities' />
								</th>
								<th>
									<FormattedMessage id='semestralReportForm.step8.accordion.activitiesOrActions' />
								</th>
								<th className='w-15'>
									<FormattedMessage id='semestralReportForm.step8.accordion.predicted' />
								</th>
								<th className='w-15' colSpan={2}>
									<FormattedMessage id='semestralReportForm.step8.accordion.executed' />
								</th>
							</tr>
						</thead>
						<tbody>
							{formikValues.semesterTimelineEntryB11s.length > 0 ? (
								formikValues.semesterTimelineEntryB11s.map((entry, index) => (
										<tr key={index}>
											<td>
											<EnumSelectField
												nullOption={true}
												options={mceActivitiesOptions}
												preffixDescriptionId='semestralReportForm.step8.table.MCEActivities.option'
												name={`semesterTimelineEntryB11s[${index}].activityType`}
												handleChange={handleChange}
												value={formikValues.semesterTimelineEntryB11s[index].activityType}
												isInvalid={errors.semesterTimelineEntryB11s?.[index]?.activityType}

												errorMessage={
													errors.semesterTimelineEntryB11s?.[index]?.activityType
												}
												showErrorOnToolTip={true}
											/>
											</td>

											<td>
											<TextAreaInputField
												rows={1}
												name={`semesterTimelineEntryB11s[${index}].actions`}
												handleChange={handleChange}
												value={formikValues.semesterTimelineEntryB11s[index].actions}
												isInvalid={
													errors.semesterTimelineEntryB11s?.[index]?.actions
												}
												errorMessage={
													errors.semesterTimelineEntryB11s?.[index]?.actions
												}

											/>
											</td>

											<td>
											<TextInputField
												name={`semesterTimelineEntryB11s[${index}].forecastSemester`}
												handleChange={handleChange}
												value={formikValues.semesterTimelineEntryB11s[index].forecastSemester}
												isInvalid={errors.semesterTimelineEntryB11s?.[index]?.forecastSemester}
												errorMessage={
													errors.semesterTimelineEntryB11s?.[index]?.forecastSemester
												}
											/>
											</td>

											<td>
											<TextInputField
												name={`semesterTimelineEntryB11s[${index}].realSemester`}
												handleChange={handleChange}
												value={formikValues.semesterTimelineEntryB11s[index].realSemester}
												isInvalid={
													errors.semesterTimelineEntryB11s?.[index]?.realSemester
												}
												errorMessage={
													errors.semesterTimelineEntryB11s?.[index]?.realSemester
												}
											/>
											</td>
											<td>{readMode!== true &&

											<FaRegTimesCircle
												onClick={() =>
													removeTimelineEntry(index)
												}
												color={"#eb4f24"}
												cursor={"pointer"}
											/>
											}


									</td>
										</tr>
									)
								)
							) : (
								<tr>
									<td colSpan={6}>
										<FormattedMessage id='semestralReportForm.step6.noConsultancyActivitiesResults' />
									</td>
								</tr>
							)}
						</tbody>
						<tfoot>
							<tr>
								<td colSpan={6}>
									<Row>
										<Col>
											<button
												className='btn btn-outline-primary'
												type='button'
												onClick={() => addTimelineEntry()}
											>
												<FormattedMessage id='all.addButton' />
											</button>
										</Col>
									</Row>
								</td>
							</tr>
						</tfoot>
					</Table>
				</Col>
			</Row>


</fieldset>





			<div className='form-actions mt-5'>
				<button
					className='btn btn-link'
					type='button'
					onClick={handlePreviousStep}
				>
					<FormattedMessage id='all.back' />
				</button>

				<button
					className='btn btn-primary'
					type='button'
					onClick={handleNextStep}
				>
					<FormattedMessage id='all.nextStep' />
				</button>
			</div>
		</div>
	);
}
