import React, { useState } from 'react';

import { Button, Col, Container, Modal, Row } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import {Navigate, useNavigate} from 'react-router-dom';

import {
  getAccessInfo,
  getUserInfo,
  hasPermission,
} from '../../authentication/authenticationHelper';
import Manual from '../../documents/Manual de Credenciacao das EA.pdf';
import Portaria from '../../documents/Portaria.pdf';
import Regulamento from '../../documents/Regulamento Empreende XXI.pdf';
import ConceitoProjetoInovador from '../../documents/EMPREENDEEXXI_CONCEITO-DE-PROJETO-INOVADOR.pdf';
import RegraMinimis from '../../documents/Minuta Declaração de Empresa .docx';
import EADistrict from '../../documents/eas_distrito.pdf';
import EAOrder from '../../documents/eas_alfabetica.pdf';
import Faqs from '../../documents/FAQs.pdf'
import { UserState } from '../../models/UserState';
import {ConfigObject} from "../../config";

function Home() {
  const [promoShow, setPromoShow] = useState(false);
  const [entShow, setEntShow] = useState(false);



  const accessInfo = getAccessInfo();
  const userInfo = getUserInfo();

  const sleep = (ms) => new Promise((r) => setTimeout(r, ms));

  async function openDocumentation() {
    window.location.href = "#documentacao"
    await sleep(100);
    await setPromoShow(false);

  }


  const firstTimeHome = sessionStorage.getItem('firstTimeHome') || 'yes';

  if (
    userInfo !== null &&
    userInfo.userState === UserState.VALIDATE &&
    hasPermission('COMPLETE_INFORMATION', accessInfo)
  ) {
    return <Navigate to='/completarRegisto' />;
  }

  if (
    userInfo !== null && userInfo.userType==='PROMOTER' &&
    userInfo.userState === UserState.CREATED &&
    firstTimeHome === 'yes'
  ) {
    sessionStorage.setItem('firstTimeHome', 'no');
    return <Navigate replace to='/candidatura' />;
  }

  return (
    <div>
      <header className='masthead'>
        <Container>
          <Row>
            <Col md='12'>
              <p className='masthead-subheading mx-1'>
                <FormattedMessage id='home.text1' />
              </p>
              <h1 className='masthead-heading'>
                <FormattedMessage id='home.text2' />
              </h1>
            </Col>
          </Row>
        </Container>
        <div className='banner'></div>
      </header>

      <section className="bg-warning">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-8 offset-lg-3 offset-md-2 py-4">
              <h4 className="mt-5 mb-5"><FormattedMessage id='home.text40' /></h4>
              <p><FormattedMessage id='home.text41' />
              </p>
              <p>
                <FormattedMessage id='home.text42' />
              </p>
              <p className="mb-5">
                <FormattedMessage id='home.text43' />
                <strong>
                  <FormattedMessage id='home.text44' />
                </strong>
                .
              </p>
            </div>
          </div>
        </div>
      </section>


      <section id='o-que-e' className='bg-white'>
        <Container>
          <Row>
            <Col md={{ span: 8, offset: 2 }} lg={{ span: 6, offset: 3 }}>
              <h4 className='mt-5 mb-5'>
                <FormattedMessage id='home.text3' />
              </h4>
              <p>
                <FormattedMessage id='all.Ocharacter' />
                <strong className='text-primary'>
                  <FormattedMessage id='all.undertakesxxi' />
                </strong>
                <FormattedMessage id='home.text4' />
              </p>
              <p>
                <FormattedMessage id='home.text5' />
              </p>
              <p>
                <FormattedMessage id='all.Ocharacter' />
                <strong className='text-primary'>
                  <FormattedMessage id='all.undertakesxxi' />
                </strong>
                <FormattedMessage id='home.text6' />
              </p>
              <p>
                <FormattedMessage id='home.text7' />
                <strong>
                  <FormattedMessage id='home.text8' />
                </strong>
                <FormattedMessage id='home.text9' />
              </p>
            </Col>
          </Row>
        </Container>
      </section>

      <section id='destinatarios'>
        <Container>
          <Row>
            <Col md='12' className='text-center'>
              <h2>
                <FormattedMessage id='home.text10' />
              </h2>
            </Col>
          </Row>
          <Row>
            <Col
              md={{ span: 8, offset: 2 }}
              lg={{ span: 4, offset: 1 }}
              className='mb-5'
            >
              <div className='text-center mb-3'>
                <img
                  className=''
                  alt='Promotores'
                  src={require('../../images/promotores.jpeg')}
                />
              </div>
              <h3 className='text-center mb-3'>
                <FormattedMessage id='home.text11' />
              </h3>
              <ul>
                <li>
                  <FormattedMessage id='home.text12' />
                </li>
              </ul>

              <div className='text-center mt-4 mb-5'>
                <Button
                  onClick={() => setPromoShow(true)}
                  className='btn btn-primary'
                >
                  <FormattedMessage id='all.knowMore' />
                </Button>
              </div>

              <Modal
                size='lg'
                show={promoShow}
                onHide={() => setPromoShow(false)}
                aria-labelledby='promotores'
              >
                <Modal.Header closeButton>
                  <Modal.Title id='promotores'>
                    <FormattedMessage id='home.text16' />
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <h5 className='mt-4'>
                    <FormattedMessage id='home.text18' />
                  </h5>
                  <p className='mb-3'>
                    <FormattedMessage id='home.text19' />
                  </p>
                  <p className='mb-3'>
                    <FormattedMessage id='home.text20' />
                  </p>
                  <p className='mb-3'>
                    <FormattedMessage id='home.text21' />
                  </p>
                  <p className='mb-3'>
                    <FormattedMessage id='home.text22' />
                  </p>
                  <p>
                    <FormattedMessage id='home.text23' />
                  </p>
                  <p>
                    <FormattedMessage id='home.text24' />
                  </p>
                  <p>
                    <FormattedMessage id='home.text25' />
                  </p>
                  <p>
                    <FormattedMessage id='home.text26' />
                  </p>
                  <a
                    className='btn btn-outline-primary mb-4 mr-3'
                    target='_blank'
                    rel='noreferrer'
                    href={Portaria}
                    aria-label='Portaria'
                  >
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='16'
                      height='16'
                      fill='currentColor'
                      class='bi bi-file-earmark-text mb-1'
                      viewBox='0 0 16 16'
                    >
                      <path d='M5.5 7a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zM5 9.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5z' />
                      <path d='M9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.5L9.5 0zm0 1v2A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5z' />
                    </svg>
                    <FormattedMessage id='home.text27' />
                  </a>
                  <a
                    className='btn btn-outline-primary mb-4 mr-3'
                    target='_blank'
                    rel='noreferrer'
                    href={Regulamento}
                    aria-label='Regulamento'
                  >
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='16'
                      height='16'
                      fill='currentColor'
                      class='bi bi-book mb-1'
                      viewBox='0 0 16 16'
                    >
                      <path d='M1 2.828c.885-.37 2.154-.769 3.388-.893 1.33-.134 2.458.063 3.112.752v9.746c-.935-.53-2.12-.603-3.213-.493-1.18.12-2.37.461-3.287.811V2.828zm7.5-.141c.654-.689 1.782-.886 3.112-.752 1.234.124 2.503.523 3.388.893v9.923c-.918-.35-2.107-.692-3.287-.81-1.094-.111-2.278-.039-3.213.492V2.687zM8 1.783C7.015.936 5.587.81 4.287.94c-1.514.153-3.042.672-3.994 1.105A.5.5 0 0 0 0 2.5v11a.5.5 0 0 0 .707.455c.882-.4 2.303-.881 3.68-1.02 1.409-.142 2.59.087 3.223.877a.5.5 0 0 0 .78 0c.633-.79 1.814-1.019 3.222-.877 1.378.139 2.8.62 3.681 1.02A.5.5 0 0 0 16 13.5v-11a.5.5 0 0 0-.293-.455c-.952-.433-2.48-.952-3.994-1.105C10.413.809 8.985.936 8 1.783z' />
                    </svg>
                    <FormattedMessage id='home.text28' />
                  </a>
                  <a
                      id='documentationButton'
                      className='btn btn-outline-primary mb-4'

                      onClick={openDocumentation}
                      aria-label='Documentação'
                  >
                    <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='16'
                        height='16'
                        fill='currentColor'
                        className='bi bi-book mb-1 mr-2'
                        viewBox='0 0 16 16'
                    >
                      <path
                          d='M1 2.828c.885-.37 2.154-.769 3.388-.893 1.33-.134 2.458.063 3.112.752v9.746c-.935-.53-2.12-.603-3.213-.493-1.18.12-2.37.461-3.287.811V2.828zm7.5-.141c.654-.689 1.782-.886 3.112-.752 1.234.124 2.503.523 3.388.893v9.923c-.918-.35-2.107-.692-3.287-.81-1.094-.111-2.278-.039-3.213.492V2.687zM8 1.783C7.015.936 5.587.81 4.287.94c-1.514.153-3.042.672-3.994 1.105A.5.5 0 0 0 0 2.5v11a.5.5 0 0 0 .707.455c.882-.4 2.303-.881 3.68-1.02 1.409-.142 2.59.087 3.223.877a.5.5 0 0 0 .78 0c.633-.79 1.814-1.019 3.222-.877 1.378.139 2.8.62 3.681 1.02A.5.5 0 0 0 16 13.5v-11a.5.5 0 0 0-.293-.455c-.952-.433-2.48-.952-3.994-1.105C10.413.809 8.985.936 8 1.783z'/>
                    </svg >
                    <FormattedMessage id={'documentationHelp.title'} />
                  </a>
                </Modal.Body>
              </Modal>
            </Col>

            <Col
              md={{ span: 8, offset: 2 }}
              lg={{ span: 4, offset: 2 }}
              className='mb-5'
            >
              <div className='text-center mb-3'>
                <img
                  className=''
                  alt='Entidades'
                  src={require('../../images/incubadoras.jpeg')}
                />
              </div>
              <h3 className='text-center mb-0'>
                <FormattedMessage id='home.text29' />
              </h3>
              <p className='text-center mb-3'>
                <FormattedMessage id='home.text30' />
              </p>
              <ul>
                <li className='mb-1'>
                  <FormattedMessage id='home.text31A' />

                </li>
              </ul>
              <div className='text-center mt-4 mb-5'>
                <Button onClick={() => setEntShow(true)}>
                  <FormattedMessage id='all.knowMore' />
                </Button>
              </div>

              <Modal
                size='lg'
                show={entShow}
                onHide={() => setEntShow(false)}
                aria-labelledby='entidades-de-acompanhamento'
              >
                <Modal.Header closeButton>
                  <Modal.Title id='entidades-de-acompanhamento'>
                    <FormattedMessage id='home.text32' />
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <h5 className='mt-4'>
                    <FormattedMessage id='home.text33' />
                  </h5>
                  <p>
                    <FormattedMessage id='home.text34' />
                  </p>
                  <p>
                    <FormattedMessage id='home.text37' />
                  </p>
                  <a
                    className='btn btn-outline-primary mr-3 mb-4'
                    target='_blank'
                    rel='noreferrer'
                    href={Portaria}
                    aria-label='Portaria'
                  >
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='16'
                      height='16'
                      fill='currentColor'
                      class='bi bi-file-earmark-text mb-1'
                      viewBox='0 0 16 16'
                    >
                      <path d='M5.5 7a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zM5 9.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5z' />
                      <path d='M9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.5L9.5 0zm0 1v2A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5z' />
                    </svg>
                    <FormattedMessage id='home.text27' />
                  </a>
                  <a
                    className='btn btn-outline-primary mb-4'
                    target='_blank'
                    rel='noreferrer'
                    href={Regulamento}
                    aria-label='Regulamento'
                  >
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='16'
                      height='16'
                      fill='currentColor'
                      class='bi bi-book mb-1'
                      viewBox='0 0 16 16'
                    >
                      <path d='M1 2.828c.885-.37 2.154-.769 3.388-.893 1.33-.134 2.458.063 3.112.752v9.746c-.935-.53-2.12-.603-3.213-.493-1.18.12-2.37.461-3.287.811V2.828zm7.5-.141c.654-.689 1.782-.886 3.112-.752 1.234.124 2.503.523 3.388.893v9.923c-.918-.35-2.107-.692-3.287-.81-1.094-.111-2.278-.039-3.213.492V2.687zM8 1.783C7.015.936 5.587.81 4.287.94c-1.514.153-3.042.672-3.994 1.105A.5.5 0 0 0 0 2.5v11a.5.5 0 0 0 .707.455c.882-.4 2.303-.881 3.68-1.02 1.409-.142 2.59.087 3.223.877a.5.5 0 0 0 .78 0c.633-.79 1.814-1.019 3.222-.877 1.378.139 2.8.62 3.681 1.02A.5.5 0 0 0 16 13.5v-11a.5.5 0 0 0-.293-.455c-.952-.433-2.48-.952-3.994-1.105C10.413.809 8.985.936 8 1.783z' />
                    </svg>
                    <FormattedMessage id='home.text28' />
                  </a>
                </Modal.Body>
              </Modal>
            </Col>
          </Row>
        </Container>
      </section>
      <section id="documentacao">
        <div className="container">
          <hr className="mt-0"/>
          <div className="row">
            <div className="col-md-4">
              <h3 className="mb-3 px-1"><FormattedMessage id={'documentationHelp.title'}/></h3>
            </div>
            <div className="col-md-8">
              <ul>


                <li className="mt-2 mb-2">
                  <a href={Faqs}
                     target="_blank"><FormattedMessage id={'documentationHelp.Faqs'}/></a>
                </li>
                <li className="mt-2 mb-2">
                  <a href="https://www.iefp.pt/documents/10181/11555740/Empreende_Aviso+de+abertura+de+Candidaturas_2023.pdf/329ec6c2-0f9d-4164-acfc-6e53fba92611"
                     target="_blank"><FormattedMessage id={'documentationHelp.CandidatureOpen'}/></a>
                </li>


                <li className="mb-2">
                  <a href="https://files.dre.pt/1s/2023/02/03000/0023900253.pdf" target="_blank"><FormattedMessage id={'documentationHelp.document2'}/></a>
                </li>
                <li className="mb-2">
                  <a href="https://drive.google.com/file/d/1YQKDW44aFt6NaFmMdNlMWZ5gxNe5FIt_/view"
                     target="_blank"><FormattedMessage id={'documentationHelp.document3'}/></a>
                </li>
                <li className="mb-2">
                  <a href="https://drive.google.com/file/d/1YHd6-pRBwBTRkz9iqX6WZToWGb8iN76x/view"
                     target="_blank"><FormattedMessage id={'documentationHelp.document4'}/></a> - <FormattedMessage id={'documentationHelp.document5'}/>
                </li>
                <li className="mb-2">
                  <a href={EAOrder}
                     target="_blank"><FormattedMessage id={'documentationHelp.document6'}/></a>
                </li>
                <li className="mb-2">
                  <a href={EADistrict}
                     target="_blank"><FormattedMessage id={'documentationHelp.document6.1'}/></a>
                </li>
                <li className="mb-2">
                  <a href="https://drive.google.com/open?id=1ZUvxPSN9fVp7Q2fIS38YLHmr7PWLHwAj&authuser=patricia.roque%40startupportugal.com&usp=drive_fs"
                     target="_blank"><FormattedMessage id={'documentationHelp.document7'}/>
                  </a> <FormattedMessage id={'documentationHelp.document8'}/>
                </li>
                <li className="mb-2">
                  <a href="https://drive.google.com/open?id=1YmowpmcTqP-o-pHGFLsu4g64YwseaeyC&authuser=patricia.roque%40startupportugal.com&usp=drive_fs"
                     target="_blank"><FormattedMessage id={'documentationHelp.document9'}/></a> - <FormattedMessage id={'documentationHelp.document10'}/>
                </li>
                <li className="mb-2">
                  <a href="https://drive.google.com/open?id=1Yl1m3rYt7nTxcLEUIk9Fq6sXEaIZv5iv&authuser=patricia.roque%40startupportugal.com&usp=drive_fs"
                     target="_blank"><FormattedMessage id={'documentationHelp.document11'}/></a> - <FormattedMessage id={'documentationHelp.document12'}/>
                </li>
                <li className="mb-2">
                  <a href="https://docs.google.com/document/d/1jg-jy4AD8K9zaYs2yUubtzVge-yEWgSg/edit?usp=sharing&ouid=109860826081939699655&rtpof=true&sd=true"
                     target="_blank"><FormattedMessage id={'documentationHelp.document13'}/></a> - <FormattedMessage id={'documentationHelp.document14'}/>
                </li>
                <li className="mb-2">
                  <a href="https://docs.google.com/document/d/1qp7z5gc85Ek-508u1xMESClXT2oBkKvG/edit?usp=sharing&ouid=109860826081939699655&rtpof=true&sd=true"
                     target="_blank"><FormattedMessage id={'documentationHelp.document15'}/></a> - <FormattedMessage id={'documentationHelp.document16'}/>
                </li>
                <li className="mb-2">
                  <FormattedMessage id={'documentationHelp.document17'}/>
                  <a href={RegraMinimis}
                     target="_blank"><FormattedMessage id={'documentationHelp.document18'}/></a> <FormattedMessage id={'documentationHelp.document19'}/>
                </li>
                <li className="mb-2">
                  <a href="https://drive.google.com/open?id=1ZTueJdkCezu1aFEBKJ8dp-q88ZiFXl_d&authuser=patricia.roque%40startupportugal.com&usp=drive_fs"
                     target="_blank"><FormattedMessage id={'documentationHelp.document20'}/></a> <FormattedMessage id={'documentationHelp.document21'}/>
                </li>
                <li className="mb-2">
                  <a href={ConceitoProjetoInovador}
                     target="_blank"><FormattedMessage id={'documentationHelp.document22'}/></a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      {ConfigObject.get().message &&
        <div className="homepage-message">{ConfigObject.get().message}</div>
      }

    </div>
  );
}

export default Home;
