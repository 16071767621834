import React, {useEffect, useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {AccretionViewer} from "../../../components/candidature/AccretionViewer";
import {CandidatureFinancialUtils} from "../../../utils/CandidatureFinancialUtils";
import {getCandidatureByExternalId} from "../../../rest/candidature";

export function ResumeValidationTab({
                                        handleChange,
                                        setFieldValue,
                                        errors,
                                        values,
                                        error,
                                        setError,
                                        numberOfSteps,
                                        handleSave,
                                        handleGoBack,
                                        context
                                    }) {
    const [totals, setTotals] = useState(getTotals());
    const intl = useIntl();


    async function getTotals() {
          let total= parseFloat(getSubRepresentation()) + parseFloat(isInsideLevel()) + parseFloat(levelValue()) + parseFloat(iefpValue()) + parseFloat(innovationValue());
          console.log(total);
          return total;
    }


    function getSubRepresentation() {
        return (values.economicViabilityAccretionValidation.isSubRepresentationValid === true) ? 15 : 0;
    }

    function isInsideLevel() {
        return (values.economicViabilityAccretionValidation.isInsideValid === true) ? 25 : 0;
    }


    function levelValue() {
        return (values.economicViabilityAccretionValidation.isLevelsValid === true) ? values.economicViabilityAccretionValidation.levelsValue : 0;
    }


    function iefpValue() {
        return (values.economicViabilityAccretionValidation.isIefpWorkstationsValid === true) ? values.economicViabilityAccretionValidation.iefpWorkstationsValue : 0;
    }

    function innovationValue() {
        return (values.economicViabilityAccretionValidation.isInnovationProjectValid === true) ? 15 : 0;
    }


    async function fetchData() {
        const total  = await getTotals();
        setTotals(total)
    }


    useEffect(() => {
        fetchData();
    }, []);

    return (
        <div>
            <h4 className='text-primary mb-0'>
                <FormattedMessage id='candidatureForm.accretion.resume'/>
            </h4>
            <small className='text-secondary text-uppercase d-block'>
                {intl.formatMessage(
                    {id: 'all.step'},
                    {step: values.step, numberOfSteps: numberOfSteps}
                )}
            </small>
            <AccretionViewer investment={values.financialInvestmentDetails.filter(
                (ipd) => ipd.financialInvestmentType === 'TOTAL'
            )[0]}  plan={ values.investmentPlanDetails.filter((ipd) => ( ipd.investmentType === 'TOTAL'))[0]} index={0} accretionTotal={parseFloat(totals)} noTitle={true}/>


        </div>

    );
}
