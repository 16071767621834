import axios from 'axios';
import {apiAxios as authAxios} from "../axios.config";

export const getOrCreateUser = (accessToken) => {
  const config = {
    Authorization: `Bearer ${accessToken}`,
    'Content-Type': 'application/json',
  };

  return axios({
    method: 'post',
    url: '/api/user',
    headers: config,
  });
};

export const userHasPriorSupport = () => {

  return authAxios.get(`/api/user/hasPriorSupport`);
};


