import React, { useState } from 'react';
import { Button, Dropdown, Form, InputGroup } from 'react-bootstrap';
import { FaSearch } from 'react-icons/fa';
import Loading from './Loading';

const SearchInputDropdownToggle = React.forwardRef(
	(
		{
			children,
			onClick,
			searchCallback,
			setResults,
			setError,
			filterQueryParamName,
			searchFilter,
			setFilter,
			setSelectedOptionInfo,
			resetFormValueHandler,
		},
		ref
	) => {
		const [loading, setLoading] = useState(false);

		async function onSearchHandler(e) {
			e.preventDefault();
			let searchResults;

			try {
				let queryParams = {};
				queryParams[filterQueryParamName] = searchFilter;

				let res = await searchCallback(queryParams);
				searchResults = res.data;

				setResults(searchResults);

				if(resetFormValueHandler !== undefined){
					resetFormValueHandler();
				}
			} catch (error) {
				setError(error);
			} finally {
				setLoading(false);
				onClick(e);
			}
		}

		return (
			<>
				<InputGroup>
					<Form.Control
						value={searchFilter}
						onChange={(e) => setFilter(e.target.value)}
					/>
					<Button
						type='button'
						variant='outline-primary'
						className='px-4'
						onClick={(e) => {
							setResults([]);
							if(setSelectedOptionInfo !== undefined){
								setSelectedOptionInfo(null);
							}
							setLoading(true);
							onSearchHandler(e);
						}}
					>
						<FaSearch />
					</Button>
				</InputGroup>
				{loading ? <Loading noScroll={true}/> : children}
			</>
		);
	}
);

export function SearchInputDropdown({
	searchCallback,
	filterQueryParamName,
	optionChosenHandler,
	setError,
	initialFilterValue,
	setSelectedOptionInfo,
	resetFormValueHandler,
	itemsFunctionGenerator,
}) {
	const [showDropdown, setShowDropDown] = useState(false);
	const [searchResults, setSearchResults] = useState([]);
	const [searchFilter, setFilter] = useState((initialFilterValue ??= ''));

	function onToggleHandler(nextShow, eventAndSourceObject) {
		if (eventAndSourceObject.source === 'click') {
			setShowDropDown(true);
		} else {
			setShowDropDown(nextShow);
		}
	}

	return (
		<Dropdown show={showDropdown} onToggle={onToggleHandler}>
			<Dropdown.Toggle
				as={SearchInputDropdownToggle}
				searchCallback={searchCallback}
				setResults={setSearchResults}
				setError={setError}
				filterQueryParamName={filterQueryParamName}
				searchFilter={searchFilter}
				setFilter={setFilter}
				setSelectedOptionInfo={setSelectedOptionInfo}
				resetFormValueHandler={resetFormValueHandler}
			></Dropdown.Toggle>
			<Dropdown.Menu>
				{itemsFunctionGenerator !== undefined
					? itemsFunctionGenerator(searchResults)
					: searchResults.map((optionText, index) => (
							<Dropdown.Item
								key={index}
								onClick={() => {
									setFilter(optionText);
									optionChosenHandler(optionText);
								}}
							>
								{optionText}
							</Dropdown.Item>
					  ))}
			</Dropdown.Menu>
		</Dropdown>
	);
}
