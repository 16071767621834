import { Formik } from 'formik';
import React, { useState } from 'react'
import * as yup from 'yup';
import { Form, Col, Row, Modal, Button } from 'react-bootstrap';
import {Link, useNavigate} from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { AuthenticatedDownloadLink } from '../document/AuthenticatedDownloadLink';
import { TextAreaInputField } from '../bootstrap/TextAreaInputField';
import { Arrays } from '../../utils/Arrays';
import {CandidatureFlowSendToEntityOpinionReview} from "./flow/CandidatureFlowSendToEntityOpinionReview";
import {CandidatureState} from "../../models/CandidatureState";
import {hasPermission, isIEFPUser} from "../../authentication/authenticationHelper";
import Loading from "../general/Loading";
import {handleError, isNotBusinessError} from "../../utils/handleError";

export function ContestationAnalysisForm({ onOpinionReviewSubmit,
  userDocuments, externalId, iefpDocuments, contestationMotive }) {

  const navigate = useNavigate();


  const schema = yup.object().shape({
    reason: yup
      .string()
      .required(<FormattedMessage id='errors.fieldRequiredText' />),
  });

  const [showReject, setShowReject] = useState(false);


  const handleShowReject = () => setShowReject(true);
  const handleCloseReject = () => setShowReject(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [showApprovalChoicesModal, setShowApprovalChoicesModal] = useState(false);

  function goToIssueFeasibility() {
    navigate(`/candidatura/${externalId}/emitirParecer`);
  }


  function handleCloseApprovalChoicesModal() {
    setShowApprovalChoicesModal(false);
  };

  const callbackHandler = async (callback, sessionStorageItem) => {
    setLoading(true);

    try {
      const response = await callback();
      if (sessionStorageItem) {
        sessionStorage.setItem(sessionStorageItem, true);
      }
      navigate('/candidatura/'+externalId);

    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const initialData = {
    reason: '',
  };


  if (loading) {
    return <Loading />;
  }

  if (error && isNotBusinessError(error)) {
    return handleError(error);
  }


  return (
    <>
      <Row>
        <Col md={{ span: 8}}>
          <div className='form mb-5'>
            <label>
              <FormattedMessage id='candidature.contestationAnalysis.motive' />
            </label>
            <p>
              {contestationMotive}
            </p>

            <div className='border-top py-2'>
              {Arrays.isNotEmpty(userDocuments) ? (
                <label>
                  <FormattedMessage id='candidature.contestationAnalysis.documents' />
                </label>
              ) : (
                <p className='text-secondary'>
                  <FormattedMessage id='candidature.contestationAnalysis.emptyDocuments' />
                </p>
              )}
            </div>
            {userDocuments.map((d, index) => (
              <div key={index}>
                <Row className='mb-3'>
                  <Col>
                    <label>
                      {d.fileDescription}
                    </label>
                    <div>
                      <AuthenticatedDownloadLink
                        url={d.documentPath}
                        filename={d.name}
                      >
                        {d.name}
                      </AuthenticatedDownloadLink>
                    </div>
                  </Col>
                </Row>
              </div>

            ))}

            {(iefpDocuments!==undefined &&  Arrays.isNotEmpty(iefpDocuments)) && (
            <div className='border-top py-2'>

                  <label>
                    <FormattedMessage id={`documentType.${iefpDocuments[0].documentType}`} />
                  </label>


            </div>
            )}
            {iefpDocuments.map((d, index) => (
                <div key={index}>
                  <Row className='mb-3'>
                    <Col>
                      <label>
                        {d.fileDescription}
                      </label>
                      <div>
                        <AuthenticatedDownloadLink
                            url={d.documentPath}
                            filename={d.name}
                        >
                          {d.name}
                        </AuthenticatedDownloadLink>
                      </div>
                    </Col>
                  </Row>
                </div>

            ))}




          </div>




        </Col>
        <Col md='3'>
          <div class="leftButtonsContainer">

            <Button variant='outline-primary' onClick={goToIssueFeasibility} className='btn btn-outline-primary d-flex align-items-center justify-content-center w-100 mb-4'>
              <FormattedMessage id='candidature.flow.visualizeFeasibilityOpinion' />
            </Button>


            <CandidatureFlowSendToEntityOpinionReview
                className={'btn btn-outline-primary d-flex align-items-center justify-content-center w-100 mb-4 btn btn-outline-primary'}
                callbackHandler={callbackHandler}
                setError={setError}
            />




                <Button
                    variant='btn btn-outline-primary d-flex align-items-center justify-content-center w-100 mb-4 mt-4'
                    onClick={() => {
                      setShowApprovalChoicesModal(true);
                    }}>
                  <FormattedMessage id='candidatureForm.emitDecision' />
                </Button>











          </div>
        </Col>
      </Row>

      <Modal show={showApprovalChoicesModal} onHide={handleCloseApprovalChoicesModal}>
        <Modal.Header closeButton>
          <Modal.Title>
            <FormattedMessage id='candidatureForm.emitDecision' />
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className='text-center'>
            <Link
                to={`/candidatura/${externalId}/aprovar`}
                className='btn btn-outline-primary d-flex align-items-center justify-content-center w-100 mb-4 mt-4'>
              <FormattedMessage id='candidature.flow.approve' />
            </Link>





            <Link to={'parcial'} className='btn btn-outline-primary d-flex align-items-center justify-content-center w-100 mb-4'>
              <FormattedMessage id='candidature.button.acceptContestationPartial' />
            </Link>


            <Link
                to={`/candidatura/${externalId}/indeferir`}
                className='btn btn-outline-primary d-flex align-items-center justify-content-center w-100 mb-4'>
              <FormattedMessage id='candidature.flow.rejectCandidature' />
            </Link>


          </div>
        </Modal.Body>
      </Modal>

    </>
  )
}
