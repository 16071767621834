import React from 'react';
import { Nav } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { FaExclamationCircle } from 'react-icons/fa';

export function FormSideNavBar({
	tabsIdsArray,
	formStep,
	setFormStep,
	navErrors,
	setNavErrors,
}) {

	function handleNavClick(step) {
		setNavErrors(navErrors.filter((navError) => navError !== step));
		setFormStep(step);
	}

	function getErrorInformation(step) {
		if (navErrors.includes(step)) {
			return (
				<span>
					<FaExclamationCircle />
				</span>
			);
		}
	}

	return (
		<Nav
			variant='pills'
			defaultActiveKey={0}
			className='flex-column'
			activeKey={formStep}
		>
			{tabsIdsArray.map((tabId, index) => (
				<Nav.Link
					className={`${navErrors.includes(index) ? 'activeError' : ''}`}
					eventKey={index}
					onClick={() => handleNavClick(index)}
					key={index}
				>
					<FormattedMessage id= {tabId} />
					{getErrorInformation(index)}
				</Nav.Link>
			))}
		</Nav>
	);
}
