import React from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { MultipleUploadArea } from '../MultipleUploadArea';

export function CandidatureFormStep20({ values, setFieldValue, handleGoBack, numberOfSteps,handleSave }) {
  const intl = useIntl();

  console.log(values)

  function handleCheckBoxChange(pm, fieldName) {
      console.log(values)
      if (pm === null) {
          values.candidatureDetails[fieldName] = values.candidatureDetails[fieldName]? false : true;
          setFieldValue('candidatureDetails.'+fieldName,values.candidatureDetails[fieldName] );
      } else {
          pm[fieldName] = pm[fieldName] ? false : true;
          setFieldValue('promotersMetaData', [...values.promotersMetaData]);
      }
  }


    function handleNextStep() {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
        handleSave(true);
    }

  return (
    <div>
      <h4 className='text-primary mb-0'>
        <FormattedMessage id='candidatureForm.regroupPromotersDeclarations' />
      </h4>
      <small className='text-secondary text-uppercase'>
        {intl.formatMessage(
          { id: 'all.step' },
          { step: values.step, numberOfSteps: numberOfSteps }
        )}
      </small>

      <div className='border-bottom pb-5 mb-3 mt-4'>
        <label className='mb-3'>
          Caso tenha dado as autorizações de consulta da Situação Tributária e Situação Contributiva
          nos respectivos portais ao IEFP (<strong>NIPC 501442600</strong>), assinale-as para cada
          promotor. Caso contrário, carregue as declarações abaixo
        </label>
        <Row className='text-secondary font-weight-bold'>
          <Col md='6'>Promotor</Col>
          <Col md='3' className='text-center'>
            Autoridade Tributária
          </Col>
          <Col md='3' className='text-center'>
            Segurança Social
          </Col>
        </Row>
        {values.promotersMetaData.map((pm, index) => (
          <div key={index}>
            <Row className='border-top py-1'>
              <Col md='6'>{pm.name}</Col>
              <Col md='3' className='text-center'>
                <Form.Check
                  type='checkbox'
                  checked={pm.atDeclarationAuthorized ? 1 : 0}
                  onChange={() => handleCheckBoxChange(pm, 'atDeclarationAuthorized')}
                />
              </Col>
              <Col md='3' className='text-center'>
                <Form.Check
                  type='checkbox'
                  checked={pm.ssDeclarationAuthorized ? 1 : 0}
                  onChange={() => handleCheckBoxChange(pm, 'ssDeclarationAuthorized')}
                />
              </Col>
            </Row>
          </div>
        ))}
          {values.candidatureDetails.wasCreated === true && (
              <div>
                  <Row className='border-top py-1'>
                      <Col md='6'><FormattedMessage id={'all.company'}/></Col>
                      <Col md='3' className='text-center'>
                          <Form.Check
                              type='checkbox'
                              checked={values.candidatureDetails.atDeclarationAuthorized ? 1 : 0}
                              onChange={() => handleCheckBoxChange(null, 'atDeclarationAuthorized')}
                          />
                      </Col>
                      <Col md='3' className='text-center'>
                          <Form.Check
                              type='checkbox'
                              checked={values.candidatureDetails.ssDeclarationAuthorized ? 1 : 0}
                              onChange={() => handleCheckBoxChange(null, 'ssDeclarationAuthorized')}
                          />
                      </Col>

                  </Row>
              </div>
          )}




      </div>

      <div className='border-bottom pb-5 mb-3'>
        <MultipleUploadArea
          documents={values.step10Documents}
          setFieldValue={setFieldValue}
          documentType={'REGULARIZED_STATUS_DECLARATION'}
          candidatureExternalId={values.externalId}
          labelId={values.candidatureDetails.wasCreated===true ? 'documentType.REGULARIZED_STATUS_DECLARATION_COMPANY' : 'documentType.REGULARIZED_STATUS_DECLARATION'}
          index={1}
          candidatureFormStep={values.step}
        />
      </div>

      <MultipleUploadArea
        documents={values.step10Documents}
        setFieldValue={setFieldValue}
        documentType={'SOCIAL_REGULARIZED_STATUS_DECLARATION'}
        candidatureExternalId={values.externalId}
        labelId={values.candidatureDetails.wasCreated===true ?  'documentType.SOCIAL_REGULARIZED_STATUS_DECLARATION_COMPANY' :  'documentType.SOCIAL_REGULARIZED_STATUS_DECLARATION'}
        index={2}
        candidatureFormStep={values.step}
      />

        <div className='form-actions mt-5'>
            <button
                className='btn btn-link'
                type='button'
                onClick={() => handleGoBack(values, setFieldValue)}>
                <FormattedMessage id='all.back' />
            </button>

            <button className='btn btn-primary' type='button' onClick={() => handleNextStep()}>
                <FormattedMessage id='all.nextStep' />
            </button>
        </div>



    </div>
  );
}
