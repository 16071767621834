import React, { useEffect, useState } from 'react';
import Loading from "../../../components/general/Loading";


import { useNavigate,useParams } from 'react-router-dom';
import {getActionNotification, updateActionNotification} from "../../../rest/templates";
import {handleError, isBusinessError} from "../../../utils/handleError";
import {SubTemplate} from "../../../components/general/SubTemplate";
import {ActionNotificationForm} from "../../../components/actionNotification/ActionNotificationForm";
import {AlertError} from "../../../components/bootstrap/AlertError";

export function EditActionNotification() {
  const navigate = useNavigate();
  const { actionNotificationCode } = useParams();

  const [actionNotification, setActionNotification] = useState(null);

  const [loading, setLoading] = useState(true);
  const [submited, setSubmited] = useState(false);
  const [error, setError] = useState(null);

  const fetchData = async () => {
    try {
      const { data: actionNotificationData } = await getActionNotification(
        actionNotificationCode
      );

      setActionNotification(actionNotificationData);
      setLoading(false);
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const update = async (actionNotification, setSubmitting, setErrors) => {
    try {
      await updateActionNotification(
        actionNotificationCode,
        actionNotification
      );

      setSubmitting(false);
      setSubmited(true);
      setLoading(false);
    } catch (error) {
      setError(error);
      setSubmitting(false);
      setLoading(false);
    }
  };

  const onSubmit = (actionNotification, setSubmitting, setErrors) => {
    setLoading(true);
    update(actionNotification, setSubmitting, setErrors);
  };

  if (loading) {
    return <Loading />;
  }

  if (error && !isBusinessError(error)) {
    return handleError(error);
  }

  if (submited) {
    navigate('/templates');
  }

  return (
    <SubTemplate hasBackButton titleId={'template.edit'}>
      <div className={'container'}>
        <div className={'row'}>
          <div className={'col-lg-8 offset-lg-2'}>
            <AlertError error={error} />
            <ActionNotificationForm
              actionNotification={actionNotification}
              isUpdate={true}
              onSubmit={onSubmit}
            />
          </div>
        </div>
      </div>
    </SubTemplate>
  );
}
