import {apiAxios as authAxios} from "../axios.config";

export const getAdditionalDueDiligences = (externalId) => {
    return authAxios.get(`/api/candidature/${externalId}/additionalDueDiligences/list`);
};

export const getAdditionalDueDiligenceByCandidature = (externalId) => {
    return authAxios.get(`/api/candidature/${externalId}/additionalDueDiligences`);
};

export const getAdditionalDueDiligence = (candidatureExternalId, additionalDueDiligenceExternalId) => {
    return authAxios.get(`/api/candidature/${candidatureExternalId}/additionalDueDiligences/${additionalDueDiligenceExternalId}`);
};

export const addAdditionalDueDiligence = (externalId, additionalDueDiligence) => {
    return authAxios.post(`/api/candidature/${externalId}/additionalDueDiligences`, additionalDueDiligence);
};

export const addAdditionalDueDiligenceWithDocuments = (externalId, formData) => {
    return authAxios.post(`/api/candidature/${externalId}/additionalDueDiligences`, formData);
};


export const responseAdditionalDueDiligence = (externalId, additionalDueDiligence) => {
    return authAxios.post(`/api/candidature/${externalId}/additionalDueDiligences/response`, additionalDueDiligence);
};

export const responseAdditionalDueDiligenceWithDocuments = (externalId, formData) => {
    return authAxios.post(`/api/candidature/${externalId}/additionalDueDiligences/response`, formData);
};

