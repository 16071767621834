import { apiAxios as authAxios } from '../axios.config';

export const getCandidatureConsultingRequest = (candidatureExternalId) => {
  return authAxios.get(`/api/consultingRequest/candidature/${candidatureExternalId}`);
};

export const getConsultingRequestByExternalId = (externalId) => {
  return authAxios.get(`/api/consultingRequest/${externalId}`);
};

export const askMCEConsulting = (candidatureExternalId, consultingRequest) => {
  return authAxios.post(
    `/api/consultingRequest/candidature/${candidatureExternalId}`,
    consultingRequest
  );
};

export const getConsultingRequestActors = (externalId) => {
  return authAxios.get(`/api/consultingRequest/${externalId}/actors`)
};

export const hasConsultingRequest = (candidatureExternalId) => {
  return authAxios.get(`/api/consultingRequest/candidature/${candidatureExternalId}/hasMCE`);
};

export const listEntityConsultingRequests = (index, size, state, orderBy, orderType) => {
  return authAxios.get('/api/consultingRequest', {
    params: {
      index: index,
      size: size,
      state: state,
      orderBy: orderBy,
      orderType: orderType
    }
  });
};

export const listConsultingRequestsByCandidatureExternalId = (externalId) => {
  return authAxios.get(`/api/consultingRequest/candidature/${externalId}/list`);
};

export const listEntityConsultingRequestsByNif = (index, size, state, orderBy, orderType,entityNif ) => {
  return authAxios.get(`/api/entity/${entityNif}/consultingRequests`, {
    params: {
      index: index,
      size: size,
      state: state,
      orderBy: orderBy,
      orderType: orderType
    }
  });
};


export const listEntityEconomicViability = (entityNif ) => {
  return entityNif=== undefined ? authAxios.get(`/api/entity/economicViability`) : authAxios.get(`/api/entity/${entityNif}/economicViability`);
};



export const confirmConsultingRequestDecision = (externalId, data) => {
  return authAxios.patch(`/api/consultingRequest/${externalId}/confirmDecision`, data);
};

export const acceptConsultingRequest = (externalId) => {
  return authAxios.patch(`/api/consultingRequest/${externalId}/accept`);
};

export const rejectConsultingRequest = (externalId, data) => {
  return authAxios.patch(`/api/consultingRequest/${externalId}/reject`, data);
};;

export const submitConsultingRequestContract = (externalId, candidatureExternalId, data) => {
  return authAxios.patch(
    `/api/consultingRequest/${externalId}/candidature/${candidatureExternalId}/submitContract`,
    data
  );
};

export const sendMessageToPromoterAsMCEEntity = (externalId, message) => {
  return authAxios.post(`/api/consultingRequest/${externalId}/sendMessageToPromoter`, message);
};

export const getConsultingRequestMessages = (externalId, index, size) => {

  return authAxios.get(`/api/consultingRequest/${externalId}/messages`, {
    params: {
      index: index,
      size: size
    }
  });
};

export const getConsultingRequestsCandidatureMappingToMainPromoterName = (queryParams) => {
  return authAxios.get(`/api/consultingRequest/candidatureAndMainPromoter/list`,  {params: queryParams});
};

export const getFinalReportByConsultingRequest = (externalId) => {
  return authAxios.get(`/api/consultingRequest/${externalId}/finalReport`);
};

export const getInvestmentConfirmationByConsultingRequest = (externalId) => {
  return authAxios.get(`/api/consultingRequest/${externalId}/investmentConfirmation`);
};


export const getPaymentRequestByConsultingRequest = (externalId) => {
  return authAxios.get(`/api/consultingRequest/${externalId}/paymentRequests`);
};


export const getAllowActionsFinalReportByConsultingRequest = (externalId) => {
  return authAxios.get(`/api/consultingRequest/${externalId}/finalReport/allowActions`);
};

export const getAllowActionsInvestmentConfirmationByConsultingRequest = (externalId) => {
  return authAxios.get(`/api/consultingRequest/${externalId}/investmentConfirmation/allowActions`);
};





export const submitFinalReportDecision = (externalId, data) => {
  return authAxios.post(`/api/consultingRequest/${externalId}/finalReport/submitDecision`, data);
};


export const submitInvestmentConfirmationReportDecision = (externalId, data) => {
  return authAxios.post(`/api/consultingRequest/${externalId}/investmentConfirmation/submitDecision`, data);
};

export const saveFinalReportByConsultingRequest  = (externalId,finalReport,queryParams) => {
  return authAxios.post(`/api/consultingRequest/${externalId}/finalReport`, finalReport, queryParams ? {params: queryParams}: null)
}

export const saveInvestmentConfirmationByConsultingRequest  = (externalId,investmentConfirmation,queryParams) => {
  return authAxios.post(`/api/consultingRequest/${externalId}/investmentConfirmation`, investmentConfirmation, queryParams ? {params: queryParams}: null)
}



export const getConsultingRequestDocumentsByType = (externalId,type) => {
  return authAxios.get(`/api/consultingRequest/${externalId}/documents/type/${type}`);
};

