import React from 'react';
import { Nav } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';

export function NavLinkFilter({ tabs, defaultActiveKey, setActiveEventKey }) {
	return (
		<Nav variant="tabs" defaultActiveKey={defaultActiveKey}>
			{Object.keys(tabs).map((name) =>
				<Nav.Item key={tabs[name].value}>
					<Nav.Link onClick={() => setActiveEventKey(tabs[name].value)} eventKey={tabs[name].value} ><FormattedMessage id={`navLink.${tabs[name].labelId}`} /></Nav.Link>
				</Nav.Item>
			)}
		</Nav>
	);
}
