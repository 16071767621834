export const EntityState = {
    DRAFT: 'DRAFT',
    SUBMITTED: 'SUBMITTED',
    EVALUATION: 'EVALUATION',
    FULLY_APPROVED: 'FULLY_APPROVED',

    values: () => {
        return ['DRAFT', 'SUBMITTED', 'EVALUATION', 'FULLY_APPROVED'];
    },

    requireDecision : () => {
        return ['SUBMITTED', 'EVALUATION'];
    },
};
