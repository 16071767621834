import {FaInfoCircle} from "react-icons/fa";
import {FormattedMessage} from "react-intl";
import {Alert} from "react-bootstrap";
import React from "react";

export function EntityRevokedAlert() {
    return <Alert className='d-flex align-items-center mb-2' variant='danger'>
       <div className='px-3'><FaInfoCircle /></div>
       <div className='px-3'>
           <strong><FormattedMessage id='entity.revoked.details.alert.title'/></strong>
           <p className='mb-0'><FormattedMessage id='entity.revoked.details.alert.body'/></p>
       </div>
    </Alert>;
}
