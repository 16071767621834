import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { handleError, isNotBusinessError } from '../../../utils/handleError';
import {
	yearStatus,
	listAdditionalActivitiesPlan,
	listAdditionalActivitiesPlanNotStarted,
} from '../../../rest/additionalActivitiesPlan';
import { AlertError } from '../../../components/bootstrap/AlertError';
import { Arrays } from '../../../utils/Arrays';
import { FormattedMessage, useIntl } from 'react-intl';
import { EnumSelectField } from '../../../components/bootstrap/EnumSelectField';
import { AlertSuccess } from '../../../components/bootstrap/AlertSuccess';
import { useNavigate } from 'react-router-dom';
import { LabelToolTip } from '../../../components/bootstrap/LabelToolTip';
import { EmptyResults } from '../../../components/general/EmptyResults';
import { SubTemplate } from '../../../components/general/SubTemplate';
import Loading from '../../../components/general/Loading';
import { CustomPagination } from '../../../components/general/CustomPagination';
import { Strings } from '../../../utils/Strings';
import { ProcessState } from '../../../models/ProcessState';
import { ListFilterBar } from '../../../components/general/ListFilterBar';
import {
	additionalActivitiesPlanStartCommunicationFormStatus,
	listAdditionalActivitiesPlanStartCommunication,
} from '../../../rest/additionalActivitiesPlanStartCommunication';
import { isIEFPUser } from '../../../authentication/authenticationHelper';

const MAX_RESULTS = 10;
const DEFAULT_INITIAL_PAGE = 0;

export function ListAnnualComplementaryActivitiesPlanStartCommunication({ entityNif }) {
	const isIEFP = isIEFPUser();

	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);
	const [numberOfResults, setNumberOfResults] = useState(null);
	const [totalResults, setTotalResults] = useState(null);
	const [currentPage, setCurrentPage] = useState(DEFAULT_INITIAL_PAGE);

	const [b17StartCommunicationList, setB17StartCommunicationList] = useState(
		[]
	);

	const orderByOptionsIdPrefix =
		'annualComplementaryActivitiesPlanStartCommunication.list.orderOption';
	const orderByOptions = ['b17.year_desc', 'b17.year_asc'];

	const [currentOrderBy, setCurrentOrderBy] = useState(orderByOptions[0]);

	const [filters, setFilters] = useState({
		state: {
			type: 'enum',
			preffixDescriptionId: 'processState',
			options: ProcessState.values(),
			value: '-1',
			nullOption: true,
			nullOptionSuffix: 'state',
			colSpace: 3,
		},
		year: {
			type: 'number',
			value: '',
			maxLength: 4,
			placeholderId: 'all.year',
			colSpace: 3,
		},
	});

	const hoverpreffixId =
		'annualComplementaryActivitiesPlanStartCommunication.list.hover';

	const [statusFormB17StartCommunication, setStatusFormB17StartCommunication] =
		useState(null);

	const navigate = useNavigate();

	const intl = useIntl();

	const [lsItem, setLsItem] = useState(null);

	async function getB17StartCommunicationStatus() {
		const { data: b17StartCommunicationStatus } =
			await additionalActivitiesPlanStartCommunicationFormStatus(entityNif);

		setStatusFormB17StartCommunication(b17StartCommunicationStatus);
	}

	async function fetchData() {
		try {
			setCurrentPage(0);

			let orderParams = currentOrderBy.split('_');

			let queryparams = {
				index: currentPage,
				size: MAX_RESULTS,
				orderBy: orderParams[0],
				orderType: orderParams[1],
			};

			if (filters.state.value !== '-1') {
				queryparams.state = filters.state.value;
			}

			if (!Strings.isBlank(filters.year.value)) {
				queryparams.year = filters.year.value;
			}

			if(entityNif !== undefined && entityNif !== null){
				queryparams.entityNif = entityNif;
			}

			const [{ data: formList }] = await Promise.all([
				await listAdditionalActivitiesPlanStartCommunication(queryparams),
			]);

			setB17StartCommunicationList( isIEFP ? formList.results.filter(b17 => b17.processState !== ProcessState.DRAFT) : formList.results);
			setNumberOfResults(formList.numberOfResults);
			setTotalResults(formList.totalResults);
		} catch (error) {
			setError(error);
		} finally {
			setLoading(false);
		}
	}

	const onPageChange = (pageNumber) => {
		setCurrentPage(pageNumber);
	};

	function handleOrderByChange(e) {
		setCurrentOrderBy(e.target.value);
	}

	useEffect(() => {
		checkStorageItems();
		getB17StartCommunicationStatus();
	}, []);

	useEffect(() => {
		fetchData();
	}, [currentPage]);

	if (loading) {
		return <Loading />;
	}

	if (error && isNotBusinessError(error)) {
		return handleError(error);
	}

	function redirectToFormPage(b17StartCommunicationExternalId) {
		if (isIEFP) {
			navigate(`/atividadesComplementares/entidade/${entityNif}/comunicacaoInicio/${b17StartCommunicationExternalId ? b17StartCommunicationExternalId : ''}`);
		} else {
			navigate(`/atividadesComplementares/comunicacaoInicio/${b17StartCommunicationExternalId ? b17StartCommunicationExternalId : ''}`);
		}
	}

	function checkStorageItems() {
		if (sessionStorage.getItem('complementaryActivitiesStartCommunicationDecisionSubmited')) {
			setLsItem('complementaryActivitiesStartCommunicationDecisionSubmited');
		}
	}

	return (
		<SubTemplate
			titleId={'annualComplementaryActivitiesPlanStartCommunication.list.title'}
			forceClass={isIEFP && 'd-none'}
			hasBackButton={!isIEFP}
		>
			<Container>
				<div className='mx-2 mb-4'>
					<AlertSuccess lsItem={lsItem} />
				</div>
				<Row>
					<Col md={isIEFP ? '12' : '10'}>
						<ListFilterBar
							filters={filters}
							setFilters={setFilters}
							search={fetchData}
						/>
					</Col>
				</Row>
				<Row className='searchResults'>
					<Col md={isIEFP ? '12' : '10'}>
						<AlertError error={error} />

						{Arrays.isNotEmpty(b17StartCommunicationList) ? (
							<>
								<Row className='mt-5 mb-1 d-flex justify-content-between px-2'>
									<Col className='text-muted'>
										{totalResults}
										<FormattedMessage
											id={`annualComplementaryActivitiesPlanStartCommunication.list.header.requests.${
												totalResults === 1 ? 'singular' : 'plural'
											}`}
										/>
									</Col>
									<Col md='4'>
										<EnumSelectField
											preffixDescriptionId={orderByOptionsIdPrefix}
											handleChange={handleOrderByChange}
											options={orderByOptions}
											value={currentOrderBy}
										/>
									</Col>
								</Row>

								<ul className='results'>
									{b17StartCommunicationList.map((b17StartComm, index) => (
										<li key={b17StartComm.externalId}>
											<Row
												className='py-3'
												onClick={() =>
													redirectToFormPage(b17StartComm.externalId)
												}
											>
												<Col md='7' className='d-flex align-items-center'>
													<LabelToolTip
														labelId={`${hoverpreffixId}.activitiesCommunication`}
														value={
															intl.formatMessage({
																id: 'communication.text',
															}) + ` - ${b17StartComm.b17 ? b17StartComm.b17.year : intl.formatMessage({id:'all.yearToDefine'})}` 
														}
													/>
												</Col>
												<Col md='2' className='d-flex align-items-center'>
													<LabelToolTip
														labelId={`${hoverpreffixId}.state`}
														value={
															<FormattedMessage
																id={`processState.${b17StartComm.processState}`}
															/>
														}
													/>
												</Col>
												<Col
													md='3'
													className='d-flex align-items-center justify-content-end'
												>
													{ProcessState.DRAFT !== b17StartComm.processState  &&
													<LabelToolTip
														labelId={`${hoverpreffixId}.paymentStatus`}
														value={
															<FormattedMessage
																id={`paymentStatus.${
																	b17StartComm.paymentRecord ? 'paid' : 'toPay'
																}`}
															/>
														}
													/>
													}

												</Col>
											</Row>
										</li>
									))}
								</ul>
							</>
						) : (
							<EmptyResults />
						)}
					</Col>
					{statusFormB17StartCommunication !== null && statusFormB17StartCommunication.canFill && (
						<Col md='2' className={isIEFP && 'd-none'}>
							<div className='mt-4 py-3'>
								<button
									className='btn btn-outline-primary d-flex align-items-center justify-content-center mb-4 w-100'
									type='button'
									onClick={() => redirectToFormPage()}
								>
									<FormattedMessage id={`annualComplementaryActivitiesPlanStartCommunication.button.${statusFormB17StartCommunication.inDraft ? 'continue' : 'create'}`} />
								</button>
							</div>
						</Col>
					)}
				</Row>
				<Row className='mb-5'>
					<Col md={isIEFP ? '12' : '10'} className='d-flex'>
						<div className='mx-2'>
							<CustomPagination
								dataPerPage={MAX_RESULTS}
								totalData={totalResults}
								onPageChange={onPageChange}
								currentPage={currentPage}
							/>
						</div>
					</Col>
				</Row>
			</Container>
		</SubTemplate>
	);
}
