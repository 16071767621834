import React, {useEffect, useState} from 'react';

import {useNavigate, useParams} from 'react-router-dom';
import {handleError, isBusinessError} from "../../../utils/handleError";
import Loading from "../../../components/general/Loading";
import {SubTemplate} from "../../../components/general/SubTemplate";
import {AlertError} from "../../../components/bootstrap/AlertError";
import {getEntityGetList, getEntityTgpList} from "../../../rest/entity";
import {Arrays} from "../../../utils/Arrays";
import {createMultiPartWithFilesAndJsonObject} from "../../../components/SyncMultipleUploadArea";
import {addEntityChange, addEntityChangeWithFiles} from "../../../rest/entityChanges";
import {listQualificationLevels} from "../../../rest/qualificationLevel";
import {EntityChangeHRForm} from "../../../components/entityChange/EntityChangeHRForm";

export function AddEntityChangeHR() {

    const {nif} = useParams();
    const [ec, setEc] = useState(null);
    const [documents, setDocuments] = useState([]);

    const [tgps, setTgps] = useState([]);
    const [gets, setGets] = useState([]);
    const [qualificationLevelList, setQualificationLevelList] = useState([]);
    const [qualificationLevelOptions, setQualificationLevelOptions] = useState([]);
	const [managerQualificationLevelOptions, setManagerQualificationLevelOptions] = useState([]);

    const acceptedQualificationLevels = ['4', '6', '7', '8'];
	const managerAcceptedQualificationLevels = ['6', '7', '8'];

    async function fetchData() {

        try {
            let entityHumanResourceParamsForNonEntityUsers = {
                entityNif: nif,
                all: false
            };

            let [
                {data: entityGetList},
                {data: entityTPList},
                {data: qualificationLevels},

            ] = await Promise.all([
                await getEntityGetList(entityHumanResourceParamsForNonEntityUsers),
                await getEntityTgpList(entityHumanResourceParamsForNonEntityUsers),
                await listQualificationLevels(),
            ]);

            setTgps(entityTPList);
            setGets(entityGetList);


            qualificationLevels = qualificationLevels.filter((qualificationLevel) =>
                acceptedQualificationLevels.includes(qualificationLevel.code)
            );
            setQualificationLevelList(qualificationLevels);

            let qualificationLevelCodeList = [];
            qualificationLevels.map((qualificationLevel) =>
                qualificationLevelCodeList.push(qualificationLevel.code)
            );
            setQualificationLevelOptions(qualificationLevelCodeList);

            qualificationLevels = qualificationLevels.filter((qualificationLevel) =>
				managerAcceptedQualificationLevels.includes(qualificationLevel.code)
			);

			let managerQualificationLevelCodeList = [];
			qualificationLevels.map((qualificationLevel) =>
			managerQualificationLevelCodeList.push(qualificationLevel.code)
			);
			setManagerQualificationLevelOptions(managerQualificationLevelCodeList);

            let entityChange = {
                getList: entityGetList,
                tgpList: entityTPList,
                regionalDelegationCode: (entityGetList.length > 1 ? null : entityGetList[0].regionalDelegationCode),
                reason: '',
                administrativeDivisionChanges: []
            }
            setEc(entityChange)

        } catch (error) {
            setError(error);
        } finally {
            setLoading(false);
        }

        //setShowSubmitModal(false);
    };


    useEffect(() => {
        fetchData();
    }, []);


    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [submited, setSubmited] = useState(false);

    const onSubmit = async (values, setSubmitting, setErrors) => {
        try {
            let initialValues = values;
            initialValues.type = 'HR';

            const hasFiles = Arrays.isNotEmpty(documents);
            if (hasFiles) {
                const formData = createMultiPartWithFilesAndJsonObject(documents, initialValues, 'EntityChangeDto');
                await addEntityChangeWithFiles(nif, formData);
            } else {
                await addEntityChange(nif, initialValues);
            }
            setSubmited(true);

            /*await createActionNotification(actionNotification);

            setActionNotification(actionNotification);*/
            /*setSubmitting(false);

            setLoading(false);*/
        } catch (error) {
            /* setSubmitting(false);
             setError(error);
             setLoading(false);*/
        }
    };


    if (loading) {
        return <Loading/>;
    }

    if (error && !isBusinessError(error)) {
        return handleError(error);
    }

    if (submited) {
        sessionStorage.setItem('entityChangeSubmitHr', submited);
        navigate('/alteracoesEntidade/' + nif);
    }

    return (
        <SubTemplate hasBackButton titleId={'entityChange.title.HR'}>
            <div className={'container'}>
                <div className={'row'}>
                    <div className={'col-lg-8 offset-lg-2'}>
                        <AlertError error={error}/>
                        {ec &&
                        <EntityChangeHRForm entityChange={ec} documents={documents} setDocuments={setDocuments}
                                            setError={error} onSubmit={onSubmit}
                                            qualificationLevelOptions={qualificationLevelOptions}
                                            managerQualificationLevelOptions={managerAcceptedQualificationLevels}/>
                        }
                    </div>
                </div>
            </div>
        </SubTemplate>
    );
}
