import React, {useState} from 'react';
import {Form} from 'react-bootstrap';
import {FormattedMessage, useIntl} from 'react-intl';
import {Link} from 'react-router-dom';
import {TextAreaInputField} from '../bootstrap/TextAreaInputField';
import {SyncMultipleUploadArea} from "../SyncMultipleUploadArea";
import {DocumentType} from "../../models/DocumentType";

export function ContestationForm({
                                     onSubmit,
                                     contestation,
                                     setContestation,
                                     candidatureExternalId,
                                     documentType,
                                     candidatureState
                                 }) {
    const intl = useIntl();
    const [documents, setDocuments] = useState([]);

    const handleChangeMotive = (event) => {
        const newMotive = event.target.value;
        setContestation(prevObject => ({
            ...prevObject,
            motive: newMotive,
        }));
    };

    return (
        <div className='mb-5'>
            <h2 className='mb-1 text-secondary'>
                <FormattedMessage
                    id={`contestation.${candidatureState}`}
                />
            </h2>
            <p className='mb-5 text-secondary'>
                <FormattedMessage
                    id={`candidature.constestation.instructions`}
                />
            </p>
            <Form
                onSubmit={(e) => {
                    e.preventDefault();
                    onSubmit(contestation, documents)
                }}
            >
                <TextAreaInputField
                    controlId={'motive'}
                    label={'Pronúncia'}
                    name={'motive'}
                    value={contestation.motive ??= ''}
                    handleChange={handleChangeMotive}
                />

                <div className="mt-3">
                    <span
                        className={"labelCompact"}>{intl.formatMessage({id: `documentType.DUE_DILIGENCE_DOCUMENT`})}</span>
                    <SyncMultipleUploadArea
                        name='files'
                        documents={documents}
                        setDocuments={setDocuments}
                        documentType={documentType}
                    />
                </div>

                <div className='form-actions'>
                    <Link to={`/candidatura/${candidatureExternalId}`}>
                        <button className='btn btn-link' type='button'>
                            <FormattedMessage id='all.backButtonText'/>
                        </button>
                    </Link>

                    <button
                        className='btn btn-primary'
                        type='submit'
                        disabled={contestation.motive.length < 1}
                    >
                        <FormattedMessage id='all.submitButtonText'/>
                    </button>
                </div>
            </Form>
        </div>
    );
}
