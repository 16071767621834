export const DateUtils = {

    addMonths: (date,monthsToAdd) => {
        return addMonths(date,monthsToAdd);
    },

    addDays: (date,daysToAdd) => {
        return addDays(date,daysToAdd);
    },
    addYears: (date,yearsToAdd) => {
        return addYears(date,yearsToAdd);
    },
    removeDays: (date,daysToRemov) => {
        return removeDays(date,daysToRemov);
    },
    formatDate: (date) => {
        return formatDate(date);
    },
    monthsBetweenDates:(sDate,eDate) =>{
        return monthsBetweenDates(sDate,eDate)
    },
    inclusiveMonthsBetweenDates:(sDate,eDate) =>{
        return inclusiveMonthsBetweenDates(sDate,eDate)
    },
    nextDay:(date) => nextDay(date)
};

function formatDate(date) {
    return date.toLocaleDateString('en-GB', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
    }).split('/').reverse().join('-');
}


function addMonths(date,monthsToAdd) {

   date.setMonth(date.getMonth() + monthsToAdd);
    date.setMinutes(0);
    date.setHours(0);
    date.setSeconds(0);
    date.setMilliseconds(0);
    return date;
}

function addYears(date,yearsToAdd) {
    date.setFullYear(date.getFullYear() + yearsToAdd);
    date.setMinutes(0);
    date.setHours(0);
    date.setSeconds(0);
    date.setMilliseconds(0);
    return date;
}

function addDays(date,daysToAdd) {
    date.setDate(date.getDate() + daysToAdd);
    return date;
}

function nextDay(date) {
    const nd = addDays(date, 1);
    nd.setHours(0,0,0,0);
    return nd;
}

function removeDays(date,daysToRemove) {
    date.setDate(date.getDate()-daysToRemove);
    return date;
}

function monthsBetweenDates(sDate, eDate) {
    if (sDate > eDate) eDate = [sDate, sDate = eDate][0];
    return eDate.getMonth() - sDate.getMonth() + 12 * Math.abs(eDate.getFullYear() - sDate.getFullYear()) - (eDate.getDate() < sDate.getDate() ? 1 : 0);
}

function inclusiveMonthsBetweenDates(sDate, eDate) {
    if (sDate > eDate) return 0;

    const diffMonths = eDate.getMonth() - sDate.getMonth();
    const diffYears = 12 * Math.abs(eDate.getFullYear() - sDate.getFullYear())

    return diffMonths + diffYears - (getDaysBetweenDates(sDate, eDate));
}

function getDaysBetweenDates(sDate, eDate) {

    // Look for complete months
    if (sDate.getDate() === 1) {
        if (eDate.getMonth() === 0 || eDate.getMonth() === 2 || eDate.getMonth() === 4 || eDate.getMonth() === 6 || eDate.getMonth() === 7 || eDate.getMonth() === 9 || eDate.getMonth() === 11) {
            if (eDate.getDate() === 31) {
                return -1;
            } else {
                return 0;
            }

        } else if (eDate.getMonth() === 3 || eDate.getMonth() === 5 || eDate.getMonth() === 8 || eDate.getMonth() === 10) {
            if (eDate.getDate() === 30) {
                return -1;
            } else {
                return 0;
            }
        } else {
            if (eDate.getDate() === 28 || eDate.getDate() === 29) {
                return -1;
            } else {
                return 0;
            }
        }

    // Look for the previous day in every month except february
    } else if (eDate.getMonth() !== 1) {

        if (eDate.getDate() - sDate.getDate() >= -1) {
            return 0;
        } else {
            return 1;
        }

    } else {
        // Look for the days 28 or 29 in specific case of february
        if ((sDate.getDate() === 31 && eDate.getDate() - sDate.getDate() >= -3) ||
            (sDate.getDate() === 30 && eDate.getDate() - sDate.getDate() >= -2) ||
            eDate.getDate() - sDate.getDate() >= -1) {
            return 0;
        } else {
            return 1;
        }
    }

}