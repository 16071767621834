import React from 'react';
import { CandidatureFormSellInformation } from './CandidatureFormSellInformation';

export function CandidatureFormStep9Test({
  errors,
  setFieldValue,
  values,
  handleSave,
  handleGoBack,
  numberOfSteps
}) {
  return (
    <div>
      <CandidatureFormSellInformation
        errors={errors}
        setFieldValue={setFieldValue}
        values={values}
        handleSave={handleSave}
        handleGoBack={handleGoBack}
        numberOfSteps={numberOfSteps}
        sellerInformationType={'CONSUME'}
      />
    </div>
  );
}
