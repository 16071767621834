import {Formik} from 'formik';
import React, {useRef, useState} from 'react'
import {Button, Form} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import {FormattedMessage, useIntl} from 'react-intl';
import {DocumentType} from '../../models/DocumentType';
import {MultipleUploadArea} from '../MultipleUploadArea';
import {Arrays} from "../../utils/Arrays";
import {MceActivityType} from "../../models/MceActivityType";
import * as yup from "yup";
import {CandidatureFormStep5Test} from "./CandidatureFormStep5Test";
import {CandidatureFormStep6Test} from "./CandidatureFormStep6Test";
import {CandidatureFormStep3} from "./CandidatureFormStep3";
import {EconomicViabilityFormStep5} from "../economicViability/EconomicViabilityFormStep5";
import {ResumeValidationTab} from "../../pages/backoffice/validation/ResumeValidationTab";
import {ContestationAnalysisPartialDocuments} from "./ContestationAnalysisPartialDocuments";
import ToolTip from "../bootstrap/ToolTip";
import {DateTimeInputField} from "../bootstrap/DateTimeInputField";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {AlertError} from "../bootstrap/AlertError";
import {createCustomErrorMessage} from "../../hooks/errorMessage";
import {
    deleteCandidatureChangeRequestDocument,
    uploadCandidatureChangeRequestDocument
} from "../../rest/candidatureChangeRequest";
import {SingleUploadArea} from "../SingleUploadArea";

export function AddTermOfAcceptForm({onSubmit, userDocuments, setDocuments, externalId,candidatureExternalId, term,isChange}) {

    const formikPropsRef = useRef(null);
    const intl = useIntl();
    const [error, setError] = useState(null);
    console.log(userDocuments)

    const termFormSchema = yup.object().shape({
        isAccordingTo: yup
            .boolean()
            .test(
                'isAccordingToValid',
                <FormattedMessage id='errors.fieldRequiredText'/>,
                (value) => {
                    console.log(value)
                    return value !== '-1' && value !== undefined
                }
            ),
        sendDate: yup
            .date().transform(v => (v instanceof Date && !isNaN(v) ? v : undefined))
            .nullable()
            .required(<FormattedMessage id='errors.fieldRequiredText'/>)
            .test(
                'isValidSendDate',
                <FormattedMessage id='errors.registerAcceptTerm.sendDateBeforeToday'/>,
                (value) => {
                    const currentDate = new Date();
                    currentDate.setHours(23, 59, 59, 999);
                    return value && value.getTime() <= currentDate.getTime();
                }
            ),
        receptionDate: yup
            .date().transform(v => (v instanceof Date && !isNaN(v) ? v : undefined))
            .nullable()
            .when('isAccordingTo',{
                is: (isAccordingTo) => isAccordingTo == true,
                then: yup.date().required(<FormattedMessage id='errors.fieldRequiredText'/>)
                    .test(
                        'isValidReceptionDate',
                        <FormattedMessage id='errors.registerAcceptTerm.receptionDateBeforeSendDate'/>,
                        (value,context) => {
                            return value && value.getTime() >= context.parent.sendDate;
                        }
                    ).test(
                        'isValidReceptionDateBeforeToday',
                        <FormattedMessage id='errors.registerAcceptTerm.receptionDateBeforeToday'/>,
                        (value) => {
                            const currentDate = new Date();
                            currentDate.setHours(23, 59, 59, 999);
                            return value && value.getTime() <= currentDate.getTime();
                        }
                    )
            }),









    });


    const uploadCallback = async (formData) => {
        return await uploadCandidatureChangeRequestDocument(
            candidatureExternalId,
            externalId,
            formData
        );
    };

    const deleteCallback = async (fileExternalId) => {
        return await deleteCandidatureChangeRequestDocument(
            candidatureExternalId,
            externalId,
            fileExternalId
        );
    };


    async function onSubmitIntern() {
        const formikErrors = await formikPropsRef?.current?.validateForm();
       if(formikPropsRef?.current.values.isAccordingTo ===true && userDocuments[0].submited==false){
            setError(createCustomErrorMessage(intl.formatMessage({
                id: 'errors.termAcceptanceRegister.document'
            })));
            console.log('enter')
        }
       console.log(Object.keys(formikErrors).length && !(formikPropsRef?.current.values.isAccordingTo ===true && userDocuments[0].submited==false))
        if (Object.keys(formikErrors).length === 0 && !(formikPropsRef?.current.values.isAccordingTo ===true && Arrays.isEmpty(userDocuments))){//} && Arrays.isNotEmpty(userDocuments)) {
            console.log(formikPropsRef.current.values)
            onSubmit(formikPropsRef.current.values);


        }


        console.log(error);
        console.log(formikErrors)


    }


    function handleChange(e, name) {
        let value = e.target.value;
        if(name==='isAccordingTo'){

            value= e.target.value==='-1' ? undefined : value=== 'true';
        }


        formikPropsRef.current.values[name]=value;
        formikPropsRef.current.setFieldValue(name,value)

    }

    function renderStep(
        props,
        formikPropsRef,
        handleSubmit,
        setError,
    ) {
        formikPropsRef.current = props;


        return (
            <>
                <Row>
                    <Col md={4}>
                        <Form.Group controlId={'isAccordingToGrounp'}>
                            <Form.Label>
                                <FormattedMessage id='termAcceptanceRegister.isAccordingTo' />
                            </Form.Label>
                            <Form.Control
                                as='select'
                                name={'isAccordingTo'}
                                value={formikPropsRef.current.values.isAccordingTo}
                                isInvalid={formikPropsRef.current.errors.isAccordingTo}
                                onChange={(e) => handleChange(e, 'isAccordingTo')}>
                                <option value={'-1'}>
                                    <FormattedMessage id='all.chooseOne' />
                                </option>
                                <option value={'true'}>
                                    <FormattedMessage id='all.yes' />
                                </option>
                                <option value={'false'}>
                                    <FormattedMessage id='all.no' />
                                </option>

                            </Form.Control>
                            <Form.Control.Feedback type='invalid'>
                                {formikPropsRef.current.errors.isAccordingTo}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col md={4}>
                        <DateTimeInputField
                            name={'sendDate'}
                            labelId={'termAcceptanceRegister.sendDate'}
                            value={formikPropsRef.current.values.sendDate}
                            isDateWithNoTime={true}
                            setFieldValue={formikPropsRef.current.setFieldValue}
                            isInvalid={formikPropsRef.current.errors.sendDate}
                            errorMessage={formikPropsRef.current.errors.sendDate}
                        />
                    </Col>
                    <Col md={4}>
                        <DateTimeInputField
                            name={'receptionDate'}
                            labelId={'termAcceptanceRegister.receptionDate'}
                            value={formikPropsRef.current.values.receptionDate}
                            isDateWithNoTime={true}
                            setFieldValue={formikPropsRef.current.setFieldValue}
                            isInvalid={formikPropsRef.current.errors.receptionDate}
                            errorMessage={formikPropsRef.current.errors.receptionDate}
                        />
                    </Col>

                </Row>








                {isChange ===true ?
                    (
                        <SingleUploadArea
                            documents={userDocuments}
                            setDocuments={setDocuments}
                            documentType={DocumentType.CANDIDATURE_CHANGE_TERM_ACCEPT_SIGNED}
                            setError={setError}
                            processUploadCallback={uploadCallback}
                            processDeleteCallback={deleteCallback}
                        />

                    )
                    :
                    (<MultipleUploadArea
                        documents={userDocuments}
                        documentType={DocumentType.TERM_ACCEPT}
                        candidatureExternalId={externalId}
                        setDocuments={setDocuments}

                    />)

                }




                <div className='form-actions'>
                    <Link to={'/'}>
                        <button
                            className='btn btn-link'
                            type='button'
                        >
                            <FormattedMessage id="all.backButtonText"/>

                        </button>
                    </Link>


                    <button
                        className='btn btn-primary'
                        type='button'
                        onClick={(values)=>onSubmitIntern()}
                    >
                        <FormattedMessage id='all.submitButtonText'/>

                    </button>




                </div>
            </>

        )




    }





    return (
        <div className='mb-5'>
            <h2 className='mb-1 text-secondary'><FormattedMessage id="candidature.submitTermsOfAccept.title"/></h2>
            <p className='mb-5 text-secondary'>
                <FormattedMessage id="candidature.submitTermsOfAccept.addDocuments"/>
            </p>


            <AlertError error={error} />
            <Formik
                initialValues={term}
                validateOnBlur={false}
                validateOnChange={false}
                validationSchema={termFormSchema}>
                {(formikProps) => (
                    <Form onSubmit={formikProps.handleSubmit}>
                        <fieldset>
                            {renderStep(
                                { ...formikProps },
                                formikPropsRef,
                            )}
                        </fieldset>
                    </Form>
                )}
            </Formik>

        </div>
    )
}
