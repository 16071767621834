export const B4FormCustomValidator = {
	validateContractsLengthAndNoNifRepetition: (
		formikValues,
		intl,
		errorMessages
	) =>
		validateContractsLengthAndNoNifRepetition(
			formikValues,
			intl,
			errorMessages
		),
};

function validateContractsLengthAndNoNifRepetition(
	formikValues,
	intl,
	errorMessages
) {
	let errors = new Set();
	if (formikValues.contractIdentificationList.length === 0) {
		errors.add(1);
		errorMessages.push(
			intl.formatMessage({
				id: 'errors.contractListing.noContracts',
			})
		);
	} else {
		errors = validateNoRepetitionOfNifs(formikValues, intl, errorMessages);
	}

	return errors;
}

function validateNoRepetitionOfNifs(formikValues, intl, errorMessages) {
	let errors = new Set();

	const contractIdentificationProjectCompanyNIFOccurrences =
		formikValues.contractIdentificationList.reduce((acc, contract) => {
			return (
				acc[contract.projectCompanyNIF]
					? ++acc[contract.projectCompanyNIF]
					: (acc[contract.projectCompanyNIF] = 1),
				acc
			);
		}, {});

	Object.keys(contractIdentificationProjectCompanyNIFOccurrences).forEach(
		(nif) => {
			if (contractIdentificationProjectCompanyNIFOccurrences[nif] > 1) {
				errors.add(1);
				errorMessages.push(
					intl.formatMessage(
						{
							id: 'errors.contractListing.contractNifRepeated',
						},
						{ nifNumber: nif }
					)
				);
			}
		}
	);

	return errors;
}
