import React from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { ResumeTab } from './ResumeTab';
import { ContestationTab } from './ContestationTab';
import { DocumentationTab } from './DocumentationTab';
import { FinancialTab } from './FinancialTab';

export function CandidatureChangeTabs({candidatureChange, candidatureChangeDocuments }) {
  return (
    <Tabs defaultActiveKey='resume' justify>
      <Tab
        eventKey='resume'
        title={<FormattedMessage id='candidatureTabs.resume' />}
        className='mb-4 p-3 border'>
        <ResumeTab candidatureChange={candidatureChange} candidatureChangeDocuments={candidatureChangeDocuments} />
      </Tab>
      <Tab
        eventKey='contestation'
        title={<FormattedMessage id='candidature.button.contest' />}
        className='mb-4 p-3 border'>
        <ContestationTab candidatureChange={candidatureChange} candidatureChangeDocuments={candidatureChangeDocuments} />
      </Tab>
      <Tab
        eventKey='documentation'
        title={<FormattedMessage id='candidatureTabs.documentation' />}
        className='mb-4 p-3 border'>
        <DocumentationTab candidatureChange={candidatureChange} candidatureChangeDocuments={candidatureChangeDocuments} />
      </Tab>
      <Tab
        eventKey='financial'
        title={<FormattedMessage id='candidatureTabs.investment' />}
        className='mb-4 p-3 border'>
        <FinancialTab candidatureChange={candidatureChange}/>
      </Tab>
    </Tabs>
  );
}
