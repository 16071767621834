import {Modal} from "react-bootstrap";
import React, {useState} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {NonComplianceAppealForm} from "./NonComplianceAppealForm";
import {NonComplianceForm} from "./NonComplianceForm";
import {NonComplianceDecisionForm} from "./NonComplianceDecisionForm";

export function NonComplianceDecisionModal({show, setShow, candidatureExternalId, nonCompliance, onSubmitCallback,nonComplianceCauseToRegulationMap}) {
    const intl = useIntl();
    const [error, setError] = useState(null);
    const [entity, setEntity] = useState(null);

    async function onSubmit() {
        try {
            setShow(false);
            onSubmitCallback?.()
        } catch (error) {
            setError(error);
        }
    }

    function handleClose() {
        setShow(false);
    }

    return (
        <Modal show={show} onHide={handleClose} dialogClassName='modal-65'>
            <Modal.Header closeButton>
                <Modal.Title><FormattedMessage id='candidatureTabs.oversight.tabs.noncompliance.decision' /></Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <NonComplianceDecisionForm
                    candidatureExternalId={candidatureExternalId}
                    onSubmitCallback={onSubmit}
                    onCancel={handleClose}
                    nonCompliance={nonCompliance}
                    nonComplianceCauseToRegulationMap={nonComplianceCauseToRegulationMap}
                />
            </Modal.Body>
        </Modal>

    );
}