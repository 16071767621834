import React, { useEffect, useState } from 'react';
import { TextInputField } from '../../bootstrap/TextInputField';
import { NumberInputField } from '../../bootstrap/NumberInputField';
import { Row, Col, Form } from 'react-bootstrap';
import { FaInfoCircle } from 'react-icons/fa';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

export function SemestralReportFormStep1({
	formStep,
	numberOfSteps,
	errors,
	handleChange,
	formikValues,
	handleNextStep,
	entityGetList,
	readMode,
	goBackPath
}) {
	const intl = useIntl();
	
	function showGetInformation() {
		let getInfo = entityGetList.filter(
			(get) =>
				get.externalId ===
				formikValues.identificationEa.technicalTeamManagerExternalId
		)?.[0];

		return (
			<>
				<Row>
					<Col md='8'>
						<TextInputField
							labelId={'all.name'}
							value={getInfo?.name}
							disabled
						/>
					</Col>
					<Col md='4'>
						<NumberInputField
							labelId={'all.phone'}
							value={getInfo?.phone}
							disabled
						/>
					</Col>
				</Row>
				<Row>
					<Col md='8'>
						<TextInputField
							labelId={'all.email'}
							value={getInfo?.email}
							disabled
						/>
					</Col>
					<Col md='4'>
						<NumberInputField
							labelId={'all.mobile'}
							value={getInfo?.mobile}
							disabled
						/>
					</Col>
				</Row>
			</>
		);
	}

	return (
		<div>
			<h4 className='text-primary mb-0'>
				<FormattedMessage id='semestralReportForm.step1.title' />
			</h4>
			<small className='text-secondary text-uppercase'>
				{intl.formatMessage(
					{ id: 'all.step' },
					{ step: formStep, numberOfSteps: numberOfSteps }
				)}
			</small>
			<fieldset disabled={readMode==true}>
			<Row>
				<Col md='4'>
					<TextInputField
						disabled={true}
						labelId={'semestralReportForm.step1.eaProcessId'}
						value={formikValues.identificationEa.entity.processId}
					/>
				</Col>
				<Col md='8'>
					<TextInputField
						disabled={true}
						labelId={'semestralReportForm.step1.name'}
						value={formikValues.identificationEa.entity.description}
					/>
				</Col>
			</Row>
			<Row>
				<Col md='4'>
					<TextInputField
						disabled={true}
						label={'NIPC'}
						value={formikValues.identificationEa.entity.nif}
					/>
				</Col>
			</Row>
			<Row>
				<Col>
					<Form.Group>
						<Form.Label>
							<FormattedMessage id='semestralReportForm.step1.technicalTeamManager' />
						</Form.Label>
						<Form.Control
							as='select'
							name={'identificationEa.technicalTeamManagerExternalId'}
							onChange={handleChange}
							value={
								formikValues.identificationEa.technicalTeamManagerExternalId
							}
							isInvalid={
								errors.identificationEa?.technicalTeamManagerExternalId
							}
						>
							<option value={'-1'}>
								<FormattedMessage id='all.chooseOne' />
							</option>
							{entityGetList.map((get) => (
								<option key={get.externalId} value={get.externalId}>
									{get.name}
								</option>
							))}
						</Form.Control>
						<Form.Control.Feedback type='invalid'>
							{errors.identificationEa?.technicalTeamManagerExternalId}
						</Form.Control.Feedback>
					</Form.Group>
				</Col>
			</Row>
			{formikValues.identificationEa.technicalTeamManagerExternalId !== '-1' &&
				showGetInformation()}
			</fieldset>
			<div className='form-actions mt-5'>
				<Link to={goBackPath}>
					<button className='btn btn-link' type='button'>
						<FormattedMessage id='all.back' />
					</button>
				</Link>
				<button
					className='btn btn-primary'
					type='button'
					onClick={handleNextStep}
				>
					<FormattedMessage id='all.nextStep' />
				</button>
			</div>
		</div>
	);
}
