import {Accordion, Col, Row} from "react-bootstrap";
import {FaAngleDown} from "react-icons/fa";
import {FormattedMessage} from "react-intl";
import React from "react";

export function HomeAccordion({
    icon,
    titleId,
    subtitleId,
    children
}) {
    return (
        <Accordion>
            <Accordion.Item eventKey='0' className='mb-4'>
                <Accordion.Header>
                    <Row>
                        <Col className='d-flex justify-content-end py-2 col-lg-1 col-2'>
                            {icon}
                        </Col>
                        <Col className="col-lg-10 col-8">
                            <h3 className='mb-1'>
                                <FormattedMessage id={titleId} />
                            </h3>
                            <p className='text-secondary mb-1'>
                                <FormattedMessage id={subtitleId} />
                            </p>
                        </Col>
                        <Col className='after-icon col-lg-1 col-2'>
                            <FaAngleDown />
                        </Col>
                    </Row>
                </Accordion.Header>
                <Accordion.Body>
                    <Row>
                        <Col>
                            {children}
                        </Col>
                    </Row>
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
    )
}
