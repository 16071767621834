import React from 'react';
import { Col, Row, Form } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';

export function CandidatureFormStep21({
	errors,
	handleChange,
	values,
	handleGoBack,
	numberOfSteps,
	setFieldValue,
}) {
	const intl = useIntl();

	function checkboxChangeHandler(e) {
		setFieldValue(e.target.name, e.target.checked);
	}

	return (
		<div>
			<h4 className='text-primary mb-0'>
				<FormattedMessage id='candidatureTabs.declaration' />
			</h4>
			<small className='text-secondary text-uppercase'>
				{intl.formatMessage(
					{ id: 'all.step' },
					{ step: values.step, numberOfSteps: numberOfSteps }
				)}
			</small>
			<Form.Label className='mb-3'>
				<FormattedMessage id='candidatureForm.veracityDeclaration.label' />
			</Form.Label>
			<div className='p-3 bg-light mb-3'>
			<p >
				<FormattedMessage id='candidatureForm.veracityDeclaration.text1' />
			</p>
			<p >
				<FormattedMessage id='candidatureForm.veracityDeclaration.text2' />
			</p>
			<p >
				<FormattedMessage id='candidatureForm.veracityDeclaration.text3' />
			</p>
			<p >
				<FormattedMessage id='candidatureForm.veracityDeclaration.text4' />
			</p>
			<p >
				<FormattedMessage id='candidatureForm.veracityDeclaration.text5' />
			</p>
			<p>
				<FormattedMessage id='candidatureForm.veracityDeclaration.text6' />
			</p>
			</div>
			<Row>
				<Col>
					<Form.Check
						name={'veracityDeclaration'}
						label={intl.formatMessage({
							id: 'candidatureForm.veracityDeclaration',
						})}
						defaultChecked={values.veracityDeclaration}
						onChange={(e) => checkboxChangeHandler(e)}
						isInvalid={errors.veracityDeclaration}
					/>
				</Col>
			</Row>
			<Row>
				<Col>
					<div className='form-actions mt-5'>
						<button
							className='btn btn-link'
							type='button'
							onClick={() => handleGoBack(values, setFieldValue)}
						>
							<FormattedMessage id='all.back' />
						</button>
					</div>
				</Col>
			</Row>
		</div>
	);
}
