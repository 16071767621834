import { apiAxios as authAxios } from '../axios.config';

export const canCreateEntityChange = (nif) => {
  return authAxios.get(`/api/entityChange/${nif}/canCreate`);
};

export const canCreateGeoChange = (nif) => {
  return authAxios.get(`/api/entityChange/${nif}/canCreateGeoChange`);
};

export const getEntityChangeAvailableLocations = (entityNif) => {
  return authAxios.get(`/api/entityChange/${entityNif}/locations`)
}

export const getEntityChangeAvailableDistricts = (entityNif) => {
  return authAxios.get(`/api/entityChange/${entityNif}/districts`)
}

export const listEntityChanges = (index, size, filters,nif) => {
  return authAxios.get('/api/entityChange/'+nif, {
    params: {
      index: index,
      size: size,
      state: filters.state ==='-1'? null : filters.state,
      type: filters.type ==='-1' ? null :filters.type,
    }
  });
};

  export const addEntityChange = (nif, values) => {
    return authAxios.post(`/api/entityChange/${nif}`, values);
  };

export const decisionEntityChange = (nif,type,externalId, values) => {
  return authAxios.post(`/api/entityChange/${nif}/${type}/${externalId}/decision`, values);
};


export const getEntityChange = (nif, type,externalId) => {
  return authAxios.get(`/api/entityChange/${nif}/${type}/${externalId}`);
};

  export const addEntityChangeWithFiles = (nif, formData) => {
    return authAxios.post(`/api/entityChange/${nif}`, formData);
  };


export const uploadEntityChangeRequestDocument = (nif, externalId, formData) => {
  return authAxios.post(`/api/entityChange/${nif}/${externalId}/document`, formData)
}


export const listEntityChangeDocuments = (nif, externalId) => {
  return authAxios.get(`/api/entityChange/${nif}/${externalId}/document`)
}



export const deleteEntityChangeChangeRequestDocument = (nif, externalId, documentExternalId) => {
  return authAxios.delete(`/api/entityChange/${nif}/${externalId}/document/${documentExternalId}`)
}

export const getEntityChangeChangeRequestDocument = (nif, externalId, documentExternalId) => {
  return authAxios.get(`/api/entityChange/${nif}/${externalId}/document/${documentExternalId}`)
}



export const submitEntityChangeRequestContestation = (nif, externalId , clarificationDto) => {
  return authAxios.post(`/api/entityChange/${nif}/${externalId}/submitContestation`, clarificationDto)
}




