import {Modal} from "react-bootstrap";
import {IrregularityForm} from "./IrregularityForm";
import {useState} from "react";
import {FormattedMessage, useIntl} from "react-intl";

export function AddIrregularityModal({show, setShow, candidature, irregularityCauseToRegulationMap, onSubmitCallback}) {
    const intl = useIntl();
    const [error, setError] = useState(null);
    const [entity, setEntity] = useState(null);

    async function onSubmit() {
        try {
            setShow(false);
            onSubmitCallback?.()
        } catch (error) {
            setError(error);
        }
    }

    function handleClose() {
        setShow(false);
    }

    return (
        <Modal show={show} onHide={handleClose} dialogClassName='modal-65'>
            <Modal.Header closeButton>
                <Modal.Title><FormattedMessage id='candidatureTabs.oversight.tabs.irregularities.add' /></Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <IrregularityForm
                    candidatureExternalId={candidature.externalId}
                    irregularityCauseToRegulationMap={irregularityCauseToRegulationMap}
                    onSubmitCallback={onSubmit}
                    onCancel={handleClose}
                />
            </Modal.Body>
        </Modal>

    );
}