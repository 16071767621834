import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { useIntl } from 'react-intl';

export function ApprovedByPromoter({ className, promoterName, approvalDate }) {
	const intl = useIntl();

	return (
		<Row className={className}>
			<Col>
				<b>
					{intl.formatMessage(
						{
							id: 'all.approvedByPromoterOnDate',
						},
						{
							promoterName: promoterName,
							approvalDate: approvalDate,
						}
					)}
				</b>
			</Col>
		</Row>
	);
}
