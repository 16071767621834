import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { AlertError } from '../../components/bootstrap/AlertError';
import { AlertSuccess } from '../../components/bootstrap/AlertSuccess';
import { LabelToolTip } from '../../components/bootstrap/LabelToolTip';
import { EmptyResults } from '../../components/general/EmptyResults';
import Loading from '../../components/general/Loading';
import { SubTemplate } from '../../components/general/SubTemplate';
import { ProcessState } from '../../models/ProcessState';
import { listEaEvaluations } from '../../rest/evaluationEa';
import { Arrays } from '../../utils/Arrays';
import { handleError, isNotBusinessError } from '../../utils/handleError';
import { B8Type } from '../../models/B8Type';

const MAX_RESULTS = 10;
const DEFAULT_INITIAL_PAGE = 0;

export function ListEaEvaluation() {
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);
	const [numberOfResults, setNumberOfResults] = useState(null);
	const [totalResults, setTotalResults] = useState(null);

	const [b8List, setB8List] = useState([]);

	const [b8Statuses, setB8Statuses] = useState([]);

	const hoverpreffixId = 'eaEvaluation.list.hover';

	const navigate = useNavigate();

	const intl = useIntl();

	const [lsItem, setLsItem] = useState(null);

	async function fetchData() {
		try {
			const [{ data: formList }] = await Promise.all([
				await listEaEvaluations(),
			]);

			setB8List(formList.results);
			setNumberOfResults(formList.numberOfResults);
			setTotalResults(formList.totalResults);

			let b8StatusesTemp = {};
			formList.results.forEach((b8) => {
				b8StatusesTemp[b8.type] = b8.processState;
			});
			setB8Statuses(b8StatusesTemp);
		} catch (error) {
			setError(error);
		} finally {
			setLoading(false);
		}
	}

	useEffect(() => {
		fetchData();
		checkStorageItems();
	}, []);

	if (loading) {
		return <Loading />;
	}

	if (error && isNotBusinessError(error)) {
		return handleError(error);
	}

	function redirectToFormPage(b8ExternalId) {
		navigate(`/avaliacoes/${b8ExternalId}`);
	}

	function checkStorageItems() {
		if (sessionStorage.getItem('evaluationEaSubmited')) {
			setLsItem('evaluationEaSubmited');
		}
	}

	function getFormExternalIdCorrespondingToTypeAndRedirect(b8Type) {
		let correspondingForm = b8List.filter((b8) => b8.type === b8Type)[0];

		redirectToFormPage(correspondingForm.externalId);
	}

	return (
		<SubTemplate titleId={'eaEvaluation.list.title'} hasBackButton>
			<Container>
				<div className='mx-2 mb-4'>
					<AlertSuccess lsItem={lsItem} />
				</div>
				<Row className='searchResults'>
					<Col md='10'>
						{error && <AlertError error={error} />}

						{Arrays.isNotEmpty(b8List) ? (
							<>
								<Row className='mt-3 mb-1 d-flex justify-content-between px-2'>
									<Col className='text-muted'>
										{totalResults}{' '}
										<FormattedMessage
											id={`all.results.lowCase.${
												totalResults === 1 ? 'singular' : 'plural'
											}`}
										/>
									</Col>
								</Row>

								<ul className='results'>
									{b8List.map((b8, index) => (
										<li key={b8.externalId}>
											<Row
												className='py-3'
												onClick={() => redirectToFormPage(b8.externalId)}
											>
												<Col md='7' className='d-flex align-items-center'>
													<LabelToolTip
														labelId={`${hoverpreffixId}.request`}
														value={intl.formatMessage({
															id: `b8.type.${b8.type}`,
														})}
													/>
												</Col>
												<Col md='2' className='d-flex align-items-center'>
													<LabelToolTip
														labelId={`${hoverpreffixId}.state`}
														value={
															<FormattedMessage
																id={`processState.${b8.processState}`}
															/>
														}
													/>
												</Col>
												<Col
													md='3'
													className='d-flex align-items-center justify-content-end'
												>
													<LabelToolTip
														labelId={`${hoverpreffixId}.changeDate`}
														value={b8.changeDate}
													/>
												</Col>
											</Row>
										</li>
									))}
								</ul>
							</>
						) : (
							<EmptyResults />
						)}
					</Col>
					{b8List.length > 0 && (
						<Col md='2'>
							<div className='mt-4 py-3'>
								{b8Statuses[B8Type.PRIOR] === ProcessState.DRAFT && (
									<button
										className='btn btn-outline-primary d-flex align-items-center justify-content-center mb-4 w-100'
										type='button'
										onClick={() =>
											getFormExternalIdCorrespondingToTypeAndRedirect(
												B8Type.PRIOR
											)
										}
									>
										<FormattedMessage id='eaEvaluation.list.button.fillPriorSupportEvaluation' />
									</button>
								)}

								{b8Statuses[B8Type.MCE_1ST_YEAR] === ProcessState.DRAFT && (
									<button
										className='btn btn-outline-primary d-flex align-items-center justify-content-center mb-4 w-100'
										type='button'
										onClick={() =>
											getFormExternalIdCorrespondingToTypeAndRedirect(
												B8Type.MCE_1ST_YEAR
											)
										}
									>
										<FormattedMessage id='eaEvaluation.list.button.fillPostProjectApprovalEvaluationFirstYear' />
									</button>
								)}
								{b8Statuses[B8Type.MCE_3RD_YEAR] === ProcessState.DRAFT && (
									<button
										className='btn btn-outline-primary d-flex align-items-center justify-content-center mb-4 w-100'
										type='button'
										onClick={() =>
											getFormExternalIdCorrespondingToTypeAndRedirect(
												B8Type.MCE_3RD_YEAR
											)
										}
									>
										<FormattedMessage id='eaEvaluation.list.button.fillPostProjectApprovalEvaluationThirdYear' />
									</button>
								)}
							</div>
						</Col>
					)}
				</Row>
			</Container>
		</SubTemplate>
	);
}
