import React, { useEffect, useRef, useState } from 'react';
import { Form, Row, Container, Col } from 'react-bootstrap';
import { Formik } from 'formik';
import { FormattedMessage, useIntl } from 'react-intl';
import Loading from '../../components/general/Loading';
import { SubTemplate } from '../../components/general/SubTemplate';
import {
	FaCheck,
	FaInfoCircle,
	FaRegCheckCircle,
	FaRegSave,
	FaTimes,
} from 'react-icons/fa';
import { handleError, isNotBusinessError } from '../../utils/handleError';
import { FormSideNavBar } from '../../components/general/FormSideNavBar';
import * as formSteps from '../../components/annualComplementaryActivitiesPlan';
import { SemestralReportFormStep1 } from '../../components/semestralReport/form/SemestralReportFormStep1';
import { getDistricts } from '../../rest/administrativeDivision';
import { getEntityGetList } from '../../rest/entity';
import {
	getAdditionalActivitiesPlanByExternalId,
	getAdditionalActivitiesPlanByProcessExternalId,
	getAdditionalActivitiesPlanReplacement,
	getDefaultAdditionalActivitiesPlan,
	getUserAdditionalActivitiesPlan,
	listAdditionalActivitiesPlanDocuments,
	saveAdditionalActivitiesPlan,
	withdrawAdditionalActivitiesPlan,
} from '../../rest/additionalActivitiesPlan';
import { isIEFPUser } from '../../authentication/authenticationHelper';
import { AdditionalActivityType } from '../../models/AdditionalActivityType';
import * as yup from 'yup';
import { createCustomErrorMessage } from '../../hooks/errorMessage';
import { AlertError } from '../../components/bootstrap/AlertError';
import {
	Navigate,
	useLocation,
	useNavigate,
	useParams,
} from 'react-router-dom';
import { getHumanResourceByExternalId } from '../../rest/humanResource';
import { ProcessState } from '../../models/ProcessState';
import { ConfirmationAuthenticationModal } from '../../components/general/ConfirmationAuthenticationModal';
import { SuccessToast } from '../../components/general/SuccessToast';
import AlertInfo from '../../components/bootstrap/AlertInfo';

const validationSchema = yup.object().shape({
	technicalAnalysis: yup.object().shape({
		iasConfirmation: yup
			.number()
			.required(<FormattedMessage id='errors.fieldRequiredText' />)
			.test(
				'isNumberValid',
				<FormattedMessage id='errors.eaAccreditationForm.number' />,
				(value) => value > 0
			),

		approvedAmount: yup
			.number()
			.test(
				'isNumberValid',
				<FormattedMessage id='errors.eaAccreditationForm.number' />,
				(value) => value >= 0
			),

		advanceAmount: yup
			.number()
			.test(
				'isNumberValid',
				<FormattedMessage id='errors.eaAccreditationForm.number' />,
				(value) => value >= 0
			)
			.test(
				'isLessOrEqualToApprovedAmount',
				<FormattedMessage id='errors.annualComplementaryActivitiesPlan.advanceHigherThanApproved' />,
				(value, context) => !(value > context.parent.approvedAmount)
			),

		technicalProposalJustification: yup
			.string()
			.required(<FormattedMessage id='errors.fieldRequiredText' />),

		proposalDispatchDate: yup
			.date()
			.nullable()
			.required(<FormattedMessage id='errors.fieldRequiredText' />),

		decisionDispatchNumber: yup
			.string()
			.required(<FormattedMessage id='errors.fieldRequiredText' />),

		decisionDispatchDate: yup
			.date()
			.nullable()
			.required(<FormattedMessage id='errors.fieldRequiredText' />),

		technicianName: yup
			.string()
			.required(<FormattedMessage id='errors.fieldRequiredText' />),

		directorName: yup
			.string()
			.required(<FormattedMessage id='errors.fieldRequiredText' />),

		regionalDelegateName: yup
			.string()
			.required(<FormattedMessage id='errors.fieldRequiredText' />),

		additionalActivityControlList: yup.array().of(
			yup.object({
				limitsControl: yup
					.number()
					.test(
						'isNumberValid',
						<FormattedMessage id='errors.eaAccreditationForm.number' />,
						(value) => value >= 0
					),

				decisionProposal: yup
					.number()
					.test(
						'isNumberValid',
						<FormattedMessage id='errors.eaAccreditationForm.number' />,
						(value) => value >= 0
					)
					.test(
						'isLessOrEqualToLimitsControl',
						<FormattedMessage id='errors.annualComplementaryActivitiesPlan.decisionHigherThanLimit' />,
						(value, context) => !(value > context.parent.limitsControl)
					),
			})
		),
	}),

	activityIdentification: yup.object().shape({
		additionalActivityList: yup.array().of(
			yup.lazy((obj) => {
				const validationObj = {
					participants:
						obj.type !== AdditionalActivityType.DIDACTIC
							? yup
									.number()
									.required(<FormattedMessage id='errors.fieldRequiredText' />)
									.test(
										'isNumberOfParticipantsValid',
										<FormattedMessage id='errors.annualComplementaryActivitiesPlan.numberOfParticipants' />,
										(value) => value >= 10
									)
							: null,

					hours:
						obj.type === AdditionalActivityType.BOOTCAMP
							? yup
									.number()
									.required(<FormattedMessage id='errors.fieldRequiredText' />)
									.test(
										'isNumberValid',
										<FormattedMessage id='errors.annualComplementaryActivitiesPlan.bootcamp.hours' />,
										(value) => value >= 14
									)
							: obj.type === AdditionalActivityType.DISCLOSURE
							? yup
									.number()
									.required(<FormattedMessage id='errors.fieldRequiredText' />)
							: null,
					onlineHours:
						obj.type === AdditionalActivityType.DISCLOSURE
							? yup
									.number()
									.required(<FormattedMessage id='errors.fieldRequiredText' />)
							: null,
					description: yup
						.string()
						.required(<FormattedMessage id='errors.fieldRequiredText' />),

					supportForecastList: yup
						.array()
						.of(
							yup.object().shape({
								description: yup
									.string()
									.required(<FormattedMessage id='errors.fieldRequiredText' />),

								value: yup
									.number()
									.required(<FormattedMessage id='errors.fieldRequiredText' />)
									.test(
										'isNumberValid',
										<FormattedMessage id='errors.eaAccreditationForm.number' />,
										(value) =>
											obj.type === AdditionalActivityType.DISCLOSURE
												? value >= 0
												: value > 0
									),

								onlineValue:
									obj.type === AdditionalActivityType.DISCLOSURE
										? yup
												.number()
												.required(
													<FormattedMessage id='errors.fieldRequiredText' />
												)
												.test(
													'isNumberValid',
													<FormattedMessage id='errors.eaAccreditationForm.number' />,
													(value) => value >= 0
												)
										: null,
							})
						)
						.min(1, 'Tem de adicionar pelo menos um evento de previsão'),
				};

				let hasValidation = false;
				if (typeof obj.participants === 'number') {
					hasValidation = true;
				}

				if (typeof obj.hours === 'number') {
					hasValidation = true;
				}

				if (typeof obj.onlineHours === 'number') {
					hasValidation = true;
				}
				if (typeof obj.description === 'string') {
					hasValidation = true;
				}
				if (obj.supportForecastList.length > 0) {
					hasValidation = true;
				}

				return yup.object().shape(hasValidation ? validationObj : {});
			})
		),

		incubationProjectList: yup.array().of(
			yup.object().shape({
				districtCode: yup
					.string()
					.test(
						'isDistrictCodeValid',
						<FormattedMessage id='errors.annualComplementaryActivitiesPlan.district' />,
						(value) => value !== '-1'
					),

				startMonth: yup
					.string()
					.test(
						'isMonthValid',
						<FormattedMessage id='errors.annualComplementaryActivitiesPlan.month' />,
						(value) => value !== '-1'
					),

				endMonth: yup
					.string()
					.test(
						'isMonthValid',
						<FormattedMessage id='errors.annualComplementaryActivitiesPlan.month' />,
						(value) => value !== '-1'
					),

				description: yup
					.string()
					.required(<FormattedMessage id='errors.fieldRequiredText' />),
			})
		),
	}),
	observationsEa: yup
		.string()
		.required(<FormattedMessage id='errors.fieldRequiredText' />),

	identificationEa: yup.object().shape({
		technicalTeamManagerExternalId: yup
			.string()
			.test(
				'isGETValid',
				<FormattedMessage id='errors.annualComplementaryActivitiesPlan.GET' />,
				(value) => value !== '-1'
			),
	}),
});

export function AddAnnualComplementaryActivitiesPlan() {
	const isIEFP = isIEFPUser();

	//for both iefp and entity users. In case of iefp, it is the processExternalid. Also, if it got here without the externalId, then its value is undefined
	const { externalId: externalId, entityNif: entityNif } = useParams();

	//for entity users. If case externalID is not defined, 'replacement' tells to invoke the endpoint for the replacement version
	//and getDraft to invoke the endpoint for the default (aka 1st) version.
	const location = useLocation();
	const getDraft = externalId === undefined ? location.state.getDraft : null;
	const getReplacement =
		externalId === undefined ? location.state.replacement : null;

	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);
	const [navErrors, setNavErrors] = useState([]);
	const [formStep, setFormStep] = useState(0);
	const [districts, setDistricts] = useState([]);
	const [districtsOptions, setDistrictsOptions] = useState([]);
	const [formikInitialValues, setFormikInitialValues] = useState(null);

	const [submited, setSubmited] = useState(false);
	const [showSaveToast, setShowSaveToast] = useState(false);
	const [showWithdrawModal, setShowWithdrawModal] = useState(false);
	const [showSubmitModal, setShowSubmitModal] = useState(false);
	const [showApproveModal, setShowApproveModal] = useState(false);
	const [showRejectModal, setShowRejectModal] = useState(false);

	const [getList, setGetList] = useState([]);

	const requiredIEFPDocuments = ['SERVICE_INFORMATION', 'NOTIFICATION_TO_EA'];
	const [documents, setDocuments] = useState([]);

	const intl = useIntl();
	const navigate = useNavigate();

	const formikPropsRef = useRef(null);

	const additionalTabsIds = [
		'annualComplementaryActivitiesPlanForm.sideNavBar.technicalAnalysis',
	];

	const [tabsIds, setTabsIds] = useState([
		'annualComplementaryActivitiesPlanForm.sideNavBar.identificationEA',
		'annualComplementaryActivitiesPlanForm.sideNavBar.activityIdentification',
		'annualComplementaryActivitiesPlanForm.sideNavBar.globalBudget',
	]);

	const months = [
		'1',
		'2',
		'3',
		'4',
		'5',
		'6',
		'7',
		'8',
		'9',
		'10',
		'11',
		'12',
	];

	const bootcampIndex = 0;
	const didaticIndex = 1;
	const disclosureIndex = 2;

	async function fetchData() {
		try {
			let [
				{ data: districtsFromAPI },
				{ data: additionalActivitiesPlan },
			] = await Promise.all([
				await getDistricts(),

				externalId !== undefined
					? (isIEFP && entityNif === undefined)
						? await getAdditionalActivitiesPlanByProcessExternalId(externalId)
						: await getAdditionalActivitiesPlanByExternalId(externalId, entityNif)
					: getDraft !== undefined
					? await getUserAdditionalActivitiesPlan()
					: getReplacement
					? await getAdditionalActivitiesPlanReplacement()
					: await getDefaultAdditionalActivitiesPlan(),

			]);

			let [
				{ data: entityGetList },
			] = await Promise.all([
				isIEFP ? { data: [] } : await getEntityGetList({all: additionalActivitiesPlan.processState !== ProcessState.DRAFT }),
			]);




			if (isIEFP) {
				const { data: get } = await getHumanResourceByExternalId(
					additionalActivitiesPlan.identificationEa
						.technicalTeamManagerExternalId
				);
				entityGetList.push(get);
			}

			if (
				(isIEFP &&
					additionalActivitiesPlan.processState !== ProcessState.DRAFT) ||
				ProcessState.finalStates().includes(
					additionalActivitiesPlan.processState
				)
			) {
				setTabsIds((prevStateValue) => {
					return prevStateValue.concat(additionalTabsIds);
				});
			}

			if (
				ProcessState.finalStates().includes(
					additionalActivitiesPlan.processState
				) ||
				isIEFP
			) {
				let { data: processDocuments } =
					await listAdditionalActivitiesPlanDocuments(
						additionalActivitiesPlan.externalId
					);
				let formDocumentList = requiredIEFPDocuments.map((rd) => {
					let dTypeIndex = processDocuments.findIndex(
						(d) => d.documentType === rd
					);
					if (dTypeIndex < 0) {
						//it means the document wasnt found
						return {
							externalId: '',
							documentType: rd,
							name: '',
							content: null,
							submited: false,
							error: '',
						};
					} else {
						return {
							externalId: processDocuments[dTypeIndex].externalId,
							documentType: processDocuments[dTypeIndex].documentType,
							documentPath: processDocuments[dTypeIndex].documentPath,
							name: processDocuments[dTypeIndex].name,
							content: '',
							submited: true,
							error: '',
						};
					}
				});
				setDocuments(formDocumentList);
			}

			setGetList(entityGetList);
			setFormikInitialValues(getFormikInitialValues(additionalActivitiesPlan));

			setDistricts(districtsFromAPI);
			let districtsNames = [];

			districtsFromAPI.forEach((districtObj) => {
				districtsNames.push(districtObj.description);
			});
			setDistrictsOptions(districtsNames);
		} catch (error) {
			setError(error);
		} finally {
			setLoading(false);
		}
	}

	function getFormikInitialValues(additionalActivitiesPlan) {
		let initialValues = {
			activityIdentification: {},
			technicalAnalysis: {},
		};

		initialValues.approvalDate= additionalActivitiesPlan.approvalDate;

		if (
			isIEFP ||
			ProcessState.finalStates().includes(additionalActivitiesPlan.processState)
		) {
			initialValues.technicalAnalysis.iasConfirmation =
				additionalActivitiesPlan.technicalAnalysis.iasConfirmation !== undefined
					? additionalActivitiesPlan.technicalAnalysis.iasConfirmation
					: '';
			initialValues.technicalAnalysis.approvedAmount =
				additionalActivitiesPlan.technicalAnalysis.approvedAmount !== undefined
					? additionalActivitiesPlan.technicalAnalysis.approvedAmount
					: '';
			initialValues.technicalAnalysis.advanceAmount =
				additionalActivitiesPlan.technicalAnalysis.advanceAmount !== undefined
					? additionalActivitiesPlan.technicalAnalysis.advanceAmount
					: '';
			initialValues.technicalAnalysis.technicalProposalJustification =
				additionalActivitiesPlan.technicalAnalysis
					.technicalProposalJustification
					? additionalActivitiesPlan.technicalAnalysis
							.technicalProposalJustification
					: '';
			initialValues.technicalAnalysis.additionalActivityControlList =
				additionalActivitiesPlan.technicalAnalysis.additionalActivityControlList;
			initialValues.technicalAnalysis.proposalDispatchNumber =
				additionalActivitiesPlan.technicalAnalysis.proposalDispatchNumber
					? additionalActivitiesPlan.technicalAnalysis.proposalDispatchNumber
					: '';
			initialValues.technicalAnalysis.proposalDispatchDate =
				additionalActivitiesPlan.technicalAnalysis.proposalDispatchDate
					? additionalActivitiesPlan.technicalAnalysis.proposalDispatchDate
					: null;
			initialValues.technicalAnalysis.decisionDispatchNumber =
				additionalActivitiesPlan.technicalAnalysis.decisionDispatchNumber
					? additionalActivitiesPlan.technicalAnalysis.decisionDispatchNumber
					: '';
			initialValues.technicalAnalysis.decisionDispatchDate =
				additionalActivitiesPlan.technicalAnalysis.decisionDispatchDate
					? additionalActivitiesPlan.technicalAnalysis.decisionDispatchDate
					: null;

			initialValues.technicalAnalysis.technicianName = additionalActivitiesPlan
				.technicalAnalysis.technicianName
				? additionalActivitiesPlan.technicalAnalysis.technicianName
				: '';
			initialValues.technicalAnalysis.directorName = additionalActivitiesPlan
				.technicalAnalysis.directorName
				? additionalActivitiesPlan.technicalAnalysis.directorName
				: '';
			initialValues.technicalAnalysis.regionalDelegateName =
				additionalActivitiesPlan.technicalAnalysis.regionalDelegateName
					? additionalActivitiesPlan.technicalAnalysis.regionalDelegateName
					: '';

			initialValues.technicalAnalysis.additionalActivityControlList.forEach(
				(activityControl) => {
					if (activityControl.limitsControl === undefined) {
						activityControl.limitsControl = '';
					}

					if (activityControl.decisionProposal === undefined) {
						activityControl.decisionProposal = '';
					}
				}
			);
		}

		initialValues.submissionDate = additionalActivitiesPlan.submissionDate;
		initialValues.replacement = additionalActivitiesPlan.replacement;
		initialValues.processState = additionalActivitiesPlan.processState;
		initialValues.activityIdentification.ias = additionalActivitiesPlan
			.activityIdentification.ias
			? additionalActivitiesPlan.activityIdentification.ias
			: '';
		initialValues.observationsEa = additionalActivitiesPlan.observationsEa
			? additionalActivitiesPlan.observationsEa
			: '';

		initialValues.activityIdentification.additionalActivityList =
			additionalActivitiesPlan.activityIdentification.additionalActivityList;

		initialValues.activityIdentification.incubationProjectList =
			additionalActivitiesPlan.activityIdentification.incubationProjectList;
		initialValues.year = additionalActivitiesPlan.year;
		initialValues.externalId = additionalActivitiesPlan.externalId;
		initialValues.identificationEa = additionalActivitiesPlan.identificationEa;
        initialValues.headQuartersDelegation = additionalActivitiesPlan.headQuartersDelegation

		initialValues.activityIdentification.incubationProjectList.forEach(
			(proj) => {
				proj.startMonth = proj.startMonth.toString(10);
				proj.endMonth = proj.endMonth.toString(10);
			}
		);

		initialValues.activityIdentification.additionalActivityList.forEach(
			(activity) => {
				if (
					activity.type !== AdditionalActivityType.DIDACTIC &&
					activity.participants === undefined
				) {
					activity.participants = '';
				}

				if (
					activity.type !== AdditionalActivityType.DIDACTIC &&
					activity.hours === undefined
				) {
					activity.hours = '';
				}

				if (
					activity.type === AdditionalActivityType.DISCLOSURE &&
					activity.onlineHours === undefined
				) {
					activity.onlineHours = '';
				}

				if (activity.description === undefined) {
					activity.description = '';
				}
			}
		);
		return initialValues;
	}

	function scrollPageToTop() {
		document.body.scrollTop = 0;
		document.documentElement.scrollTop = 0;
	}

	function handlePreviousStep() {
		scrollPageToTop();
		setFormStep(formStep - 1);
	}

	function handleNextStep() {
		scrollPageToTop();
		setFormStep(formStep + 1);
	}

	useEffect(() => {
		fetchData();
	}, []);

	if (submited) {
		if (isIEFP) {
			if (formikInitialValues.replacement) {
				sessionStorage.setItem(
					'additionalActivitiesPlanDecisionSubmitedReplacement',
					submited
				);
			} else {
				sessionStorage.setItem(
					'additionalActivitiesPlanDecisionSubmited',
					submited
				);
			}
		} else {
			if (formikInitialValues.replacement) {
				sessionStorage.setItem(
					'annualComplementaryActivitiesPlanSubmitedReplacement',
					submited
				);
			} else {
				sessionStorage.setItem(
					'annualComplementaryActivitiesPlanSubmited',
					submited
				);
			}
		}
		return isIEFP ? (
			<Navigate to='/processos' />
		) : (
			<Navigate to='/atividadesComplementares/plano/lista' />
		);
	}

	if (loading) {
		return <Loading />;
	}

	if (error && isNotBusinessError(error)) {
		return handleError(error);
	}

	async function handleWithdrawal(user, password, setModalError) {
		try {
			let authenticationCredentials = {
				user: user,
				password: password,
			};

			await withdrawAdditionalActivitiesPlan(
				formikPropsRef.current.values.externalId,
				authenticationCredentials
			);

			sessionStorage.setItem('additionalActivitiesPlanWithdrawed', true);
			navigate('/atividadesComplementares/plano/lista');
		} catch (error) {
			setModalError(error);
		}
	}

	async function handleSave() {
		try {
			let { data: res } = await saveAdditionalActivitiesPlan(
				formikPropsRef.current.values
			);

			let processedRes = getFormikInitialValues(res);

			formikPropsRef.current.setValues(processedRes);
			setNavErrors([]);
			formikPropsRef.current.setErrors({});

			setShowSaveToast(true);
		} catch (error) {
			setError(error);
		}
	}

	async function formValidationHandler(setShowModal) {
		let errors = await validateForm();
		setNavErrors(Array.from(errors));
		if (errors.size === 0) {
			setShowModal(true);
		}
	}

	async function handleSubmit(user, password, setModalError, decisionApprove) {
		let values = formikPropsRef.current.values;
		const submissionValues = { ...values };

		submissionValues.credentials = {
			user: user,
			password: password,
		};

		if (isIEFP) {
			submissionValues.decisionApprove = decisionApprove;
		}

		let queryParams = {
			submit: true,
		};

		try {
			await saveAdditionalActivitiesPlan(submissionValues, queryParams);
			setSubmited(true);
		} catch (err) {
			if (err.response.data.exception === 'ValidationException') {
				handleNavError(err);
			} else {
				setModalError(err);
			}
		}
	}

	async function validateForm() {
		let errors = new Set();

		const formikErrors = await formikPropsRef.current.validateForm();

		Object.keys(formikErrors).forEach((key) => {
			switch (key) {
				case 'identificationEa':
					!isIEFP && errors.add(0);
					break;
				case 'activityIdentification':
					!isIEFP && errors.add(1);
					break;
				case 'observationsEa':
					!isIEFP && errors.add(2);
					break;
				default:
					isIEFP && errors.add(3);
					break;
			}
		});

		let errorMessages = new Set();

		//validate that both dispatch documents have been uploaded. For the entity's user, documents = []
		documents.forEach((d) => {
			if (d.submited === false) {
				errors.add(3);
				errorMessages.add(
					intl.formatMessage({
						id:
							d.documentType === 'SERVICE_INFORMATION'
								? 'errors.annualComplementaryActivitiesPlan.serviceInformationDocument'
								: 'errors.annualComplementaryActivitiesPlan.notificationDocument',
					})
				);
			}
		});

		let values = formikPropsRef.current.values;
		if (!isIEFP) {
			let isAnyActivityFilled = false;
			values.activityIdentification.additionalActivityList.forEach(
				(activity) => {
					if (activity.supportForecastList.length > 0) {
						isAnyActivityFilled = true;
					}
				}
			);

			if (
				!isAnyActivityFilled &&
				values.activityIdentification.incubationProjectList.length > 0
			) {
				isAnyActivityFilled = true;
			}

			if (
				formikErrors.activityIdentification === undefined &&
				!isAnyActivityFilled
			) {
				errors.add(1);
				errorMessages.add(
					intl.formatMessage({
						id: 'errors.annualComplementaryActivitiesPlan.noActivitiesFilled',
					})
				);
			}

			//support forecast list always has to have one element when the acitivty type (bootcamp, disclosure or didatic) is mandatory. Then, we can check its length
			//to know if we should validate the next ifs
			if (
				values.activityIdentification.additionalActivityList[disclosureIndex]
					.supportForecastList.length > 0
			) {
				let disclosureHours =
					values.activityIdentification.additionalActivityList[disclosureIndex]
						.hours;
				let disclosureOnlineHours =
					values.activityIdentification.additionalActivityList[disclosureIndex]
						.onlineHours;

				let hasDisclosureRoom = undefined;
				let hasDisclosureOnline = undefined;

				if (disclosureHours !== 0 && disclosureHours < 3) {
					errors.add(1);
					errorMessages.add(
						intl.formatMessage({
							id: 'errors.annualComplementaryActivitiesPlan.disclosure.hours',
						})
					);
				} else if (disclosureHours > 0) {
					hasDisclosureRoom = true;
				} else {
					hasDisclosureRoom = false;
				}

				if (disclosureOnlineHours !== 0 && disclosureOnlineHours < 3) {
					errors.add(1);
					errorMessages.add(
						intl.formatMessage({
							id: 'errors.annualComplementaryActivitiesPlan.disclosure.hours',
						})
					);
				} else if (disclosureOnlineHours > 0) {
					hasDisclosureOnline = true;
				} else {
					hasDisclosureOnline = false;
				}

				if (hasDisclosureRoom === false && hasDisclosureOnline === false) {
					errors.add(1);
					errorMessages.add(
						intl.formatMessage({
							id: 'errors.annualComplementaryActivitiesPlan.disclosure.hours',
						})
					);
				}

				let diclosureAmountInRoom = 0;
				let diclosureAmountOnline = 0;
				values.activityIdentification.additionalActivityList[
					disclosureIndex
				].supportForecastList.forEach((supportForecast) => {
					diclosureAmountInRoom += parseFloat(supportForecast.value) || 0;
					diclosureAmountOnline += parseFloat(supportForecast.onlineValue) || 0;
				});

				if (
					(hasDisclosureRoom && !(diclosureAmountInRoom > 0)) ||
					(hasDisclosureOnline && !(diclosureAmountOnline > 0))
				) {
					errors.add(1);
					errorMessages.add(
						intl.formatMessage({
							id: 'errors.annualComplementaryActivitiesPlan.disclosure.supportForecast',
						})
					);
				} else if (
					(hasDisclosureRoom === false && diclosureAmountInRoom > 0) ||
					(hasDisclosureOnline === false && diclosureAmountOnline > 0)
				) {
					errors.add(1);
					errorMessages.add(
						intl.formatMessage({
							id: 'errors.annualComplementaryActivitiesPlan.disclosure.supportForecastAndHoursInconsistent',
						})
					);
				}
			}
		}

		if (errorMessages.size > 0) {
			let message =
				errorMessages.size === 1
					? createCustomErrorMessage(Array.from(errorMessages)[0])
					: createCustomErrorMessage(
							'<p>' +
								intl.formatMessage({
									id: 'errors.more',
								}) +
								'</p><p> - ' +
								Array.from(errorMessages).join('</p><p> - ') +
								'</p>'
					  );

			setError(message);
		}

		return errors;
	}

	function handleNavError(error) {
		let errors = error.response.data.message
			.split(',')
			.map((value) => Number.parseInt(value));
		setNavErrors(errors);
	}

	function renderStep(formikProps) {
		formikPropsRef.current = formikProps;

		const readModeStep1To3 =
			formikProps.values.processState !== ProcessState.DRAFT || isIEFP;

		switch (formStep) {
			case 0:
				return (
					<SemestralReportFormStep1
						formStep={1}
						numberOfSteps={tabsIds.length}
						errors={formikProps.errors}
						formikValues={formikProps.values}
						handleChange={formikProps.handleChange}
						handleNextStep={handleNextStep}
						entityGetList={getList}
						readMode={readModeStep1To3}
						goBackPath={
							isIEFP ? '/processos' : '/atividadesComplementares/plano/lista'
						}
					/>
				);
			case 1:
				return (
					<formSteps.AnnualComplementaryActivitiesPlanFormStep2
						formStep={2}
						numberOfSteps={tabsIds.length}
						errors={formikProps.errors}
						formikValues={formikProps.values}
						handleChange={formikProps.handleChange}
						handlePreviousStep={handlePreviousStep}
						handleNextStep={handleNextStep}
						setFieldValue={formikProps.setFieldValue}
						districts={districts}
						districtsOptions={districtsOptions}
						months={months}
						formText={'AddAnnualComplementaryActivitiesPlan'}
						readMode={readModeStep1To3}
					/>
				);
			case 2:
				return (
					<formSteps.AnnualComplementaryActivitiesPlanFormStep3
						formStep={3}
						numberOfSteps={tabsIds.length}
						errors={formikProps.errors}
						formikValues={formikProps.values}
						handleChange={formikProps.handleChange}
						handlePreviousStep={handlePreviousStep}
						handleNextStep={handleNextStep}
						months={months}
						showNextStepButton={
							ProcessState.finalStates().includes(
								formikProps.values.processState
							) ||
							(isIEFP && formikProps.values.processState !== ProcessState.DRAFT)
						}
						entityGetList={getList}
						readMode={readModeStep1To3}
					/>
				);
			case 3:
				return (
					(ProcessState.finalStates().includes(
						formikProps.values.processState
					) ||
						(isIEFP &&
							formikProps.values.processState === ProcessState.SUBMITTED)) && (
						<formSteps.AnnualComplementaryActivitiesPlanFormStep4
							formStep={4}
							numberOfSteps={tabsIds.length}
							errors={formikProps.errors}
							formikValues={formikProps.values}
							handleChange={formikProps.handleChange}
							handlePreviousStep={handlePreviousStep}
							months={months}
							setError={setError}
							setFieldValue={formikProps.setFieldValue}
							documents={documents}
							setDocuments={setDocuments}
							readMode={ProcessState.finalStates().includes(
								formikProps.values.processState
							)}
							isIEFP={isIEFP}
						/>
					)
				);
			default:
				console.log('Something went wrong rendering the form step');
		}
	}

	return (
		<SubTemplate>
			<Container>
				<ConfirmationAuthenticationModal
					show={
						showWithdrawModal ||
						showSubmitModal ||
						showApproveModal ||
						showRejectModal
					}
					handleClose={() =>
						showSubmitModal
							? setShowSubmitModal(false)
							: showApproveModal
							? setShowApproveModal(false)
							: showWithdrawModal
							? setShowWithdrawModal(false)
							: setShowRejectModal(false)
					}
					submitHandler={showWithdrawModal ? handleWithdrawal : handleSubmit}
					idsPreffix={
						showApproveModal
							? 'annualComplementaryActivitiesPlan.iefp.acceptModal'
							: showRejectModal
							? 'annualComplementaryActivitiesPlan.iefp.rejectModal'
							: showWithdrawModal
							? 'annualComplementaryActivitiesPlan.withdrawModal'
							: 'annualComplementaryActivitiesPlan.submitModal'
					}
					confirmButtonCompleteLabelId={
						showApproveModal
							? 'all.button.approve'
							: showRejectModal
							? 'all.button.reject'
							: showWithdrawModal
							? 'all.candidatureWithdrawal'
							: 'all.submitButtonText'
					}
					IEFPdecisionApprove={
						showApproveModal ? true : showRejectModal ? false : undefined
					}
				/>
				<Row>
					<Col md={{ span: 8, offset: 2 }}>
						<h2 className='mb-1 text-secondary'>
							{formikInitialValues &&
								intl.formatMessage({
									id: 'annualComplementaryActivitiesPlanForm.title',
								}) +
									` ${formikInitialValues.year}${
										formikInitialValues.replacement
											? ' - ' +
											  intl.formatMessage({
													id: 'versionType.replacement',
											  })
											: ''
									}`}
						</h2>
						<p className='mb-5 text-secondary'>
							<FormattedMessage id='annualComplementaryActivitiesPlanForm.subTitle' />
							<span
								className="badge rounded-pill bg-secondary fw-normal ms-2"><span
								className="text-uppercase fw-bold"><FormattedMessage
								id={`processState.${formikInitialValues.processState}`}
							/> </span>  {formikInitialValues.submissionDate !== undefined && formikInitialValues.submissionDate !== null && (<> · <FormattedMessage id={'submitted.process.at'} values={{date:formikInitialValues.submissionDate}}/>  </>) } {formikInitialValues.approvalDate !== undefined && formikInitialValues.approvalDate !== null && (<> · {ProcessState.APPROVED===formikInitialValues.processState ? <FormattedMessage id={'approvedIEFP.process.at'} values={{date:formikInitialValues.approvalDate}}/>:<FormattedMessage id={'rejectedIEFP.process.at'} values={{date:formikInitialValues.approvalDate}}/>}   </>) }  </span>
						</p>
					</Col>
				</Row>
				<Row className='mb-5'>
					<Col md='2'>
						<FormSideNavBar
							tabsIdsArray={tabsIds}
							formStep={formStep}
							setFormStep={setFormStep}
							navErrors={navErrors}
							setNavErrors={setNavErrors}
						/>
					</Col>
					<Col md='8'>
						<AlertError error={error} />
						<SuccessToast
							message={intl.formatMessage({
								id: 'annualComplementaryActivitiesPlan.saveToastInformation',
							})}
							show={showSaveToast}
							setShow={setShowSaveToast}
						/>
						<AlertInfo
							show={formikInitialValues.replacement &&  formikInitialValues.processState === ProcessState.DRAFT && !isIEFP}
							message={
								<FormattedMessage id='annualComplementaryActivitiesPlan.replacement.info' />
							}
						/>
						<Formik
							initialValues={formikInitialValues}
							validateOnBlur={false}
							validateOnChange={false}
							validationSchema={validationSchema}
							onSubmit={handleSubmit}
						>
							{(formikProps) => (
								<Form onSubmit={formikProps.handleSubmit}>
									{renderStep(formikProps)}
								</Form>
							)}
						</Formik>
					</Col>
                    {isIEFP && formikInitialValues.processState === ProcessState.SUBMITTED && formikInitialValues.headQuartersDelegation && (
                        <Col md='2'>
                            <div>
                                <button
                                    className='btn btn-outline-primary d-flex align-items-center justify-content-center mb-4 w-100'
                                    type='button'
                                    onClick={() => handleSave()}
                                >
                                    <FaRegSave/>
                                    <FormattedMessage id='all.save'/>
                                </button>
                                <button
                                    className='btn btn-primary d-flex align-items-center justify-content-center mb-4 w-100'
                                    type='button'
                                    onClick={() => formValidationHandler(setShowRejectModal)}
                                >
                                    <FaTimes/> <FormattedMessage id='all.button.reject'/>
                                </button>
                                <button
                                    className='btn btn-primary d-flex align-items-center justify-content-center mb-4 w-100'
                                    type='button'
                                    onClick={() => formValidationHandler(setShowApproveModal)}
                                >
                                    <FaCheck/> <FormattedMessage id='all.button.approve'/>
                                </button>
                            </div>
                        </Col>
                    )}


                    {!isIEFP && formikInitialValues.processState === ProcessState.DRAFT && (
                            <Col md='2'>
                                <div>
                                    {formikInitialValues.replacement && (
                                        <button
                                            className='btn btn-outline-primary d-flex align-items-center justify-content-center mb-4 w-100'
                                            type='button'
                                            onClick={() => setShowWithdrawModal(true)}
                                        >
                                            <FormattedMessage id='all.candidatureWithdrawal'/>
                                        </button>
                                    )}
                                    <button
                                        className='btn btn-outline-primary d-flex align-items-center justify-content-center mb-4 w-100'
                                        type='button'
                                        onClick={() => handleSave()}
                                    >
                                        <FaRegSave/>
                                        <FormattedMessage id='all.save'/>
                                    </button>
                                    <button
                                        className='btn btn-primary d-flex align-items-center justify-content-center w-100'
                                        type='button'
                                        onClick={() => formValidationHandler(setShowSubmitModal)}
                                    >
                                        <FaRegCheckCircle/>
                                        <FormattedMessage id='all.submitButtonText'/>
                                    </button>
                                </div>
                            </Col>
                        )}
				</Row>
			</Container>
		</SubTemplate>
	);
}
