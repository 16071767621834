import { CandidatureChangeStateWithReason } from "../CandidatureChangeStateWithReason";
import { useParams } from "react-router-dom";
import { sendToOpinionReview } from "../../../rest/candidature";

export function CandidatureFlowSendToEntityOpinionReview({ callbackHandler,className }) {
  const { externalId } = useParams();

  const sendCandidatureToEntityReviewOpinionHandler = (reasonData) => {
    callbackHandler(() => {
      return sendToOpinionReview(externalId, reasonData);
    });
  }

  return (
    <>
      <CandidatureChangeStateWithReason
          className={className}
        modalTitleId="candidature.title.sendToEntityForOpinionReview"
        modalReasonTextId="candidature.message.sendToEntityForOpinionReview"
        flowActionId="candidature.flow.sendToEntityForOpinionReview"
        submitHandler={sendCandidatureToEntityReviewOpinionHandler}
      />
    </>
  );
}
