import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  isEntityUser,
  isIEFPUser,
  isPromoterUser
} from '../../authentication/authenticationHelper';
import { AlertError } from '../../components/bootstrap/AlertError';
import { AlertSuccess } from '../../components/bootstrap/AlertSuccess';
import { CandidatureAlertInfo } from '../../components/bootstrap/CandidatureAlertInfo';
import { CandidatureActionsMenuBar } from '../../components/candidature/CandidatureActionsMenuBar';
import { CandidatureTabs } from '../../components/candidature/CandidatureTabs';
import Loading from '../../components/general/Loading';
import { SubTemplate } from '../../components/general/SubTemplate';
import { CandidatureState } from '../../models/CandidatureState';
import { DocumentTypeDescriptor } from '../../models/DocumentTypeDescriptor';
import {getCandidatureByExternalId} from '../../rest/candidature';
import {
  getCandidatureConsultingRequest,
  hasConsultingRequest
} from '../../rest/consultingRequest';
import {
  listDocumentsByDescriptorAndCandidatureId,
  listDocumentsByDescriptorAndCandidatureIdGrouped
} from '../../rest/document';
import { getPromoterHasPendingEvaluationsForCandidature } from '../../rest/evaluationEa';
import { canValidateCandidature } from '../../rest/promoter';
import { isJustMentioned } from '../../rest/promoterMetaData';
import { handleError, isNotBusinessError } from '../../utils/handleError';

export function DetailCandidature() {
  const { externalId } = useParams();
  const navigate = useNavigate();

  const location = useLocation();

  const isEntity = location.state ? true : false;

  const [candidature, setCandidature] = useState();
  const [candidatureDocuments, setCandidatureDocuments] = useState();
  const [consultingRequest, setConsultingRequest] = useState(null);
  const [lsItem, setLsItem] = useState(null);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [justMentioned, setJustMentioned] = useState(false);
  const [canValidate, setCanValidate] = useState(true);
  const [hasPendingEvaluations, setHasPendingEvaluations] = useState(false);

  const fetchData = async () => {
    try {
      setLoading(true);
      const [{ data: candidature }, { data: candidatureDocuments }] = await Promise.all([
        getCandidatureByExternalId(externalId),
        listDocumentsByDescriptorAndCandidatureIdGrouped(DocumentTypeDescriptor.CANDIDATURE, externalId)
      ]);
      console.log(candidatureDocuments)

      if (isPromoterUser()) {

        const [ 
          { data: isMentioned },
          { data: promoterHasEvaluationsInDraftConcerningCandidature }
        ] = await Promise.all([
          await isJustMentioned(externalId),
          candidature?.mainPromoter === true ? await getPromoterHasPendingEvaluationsForCandidature(externalId) : {data:false}
        ]);

        setJustMentioned(isMentioned.flag);
        setHasPendingEvaluations(promoterHasEvaluationsInDraftConcerningCandidature.flag);

        if (isMentioned.flag) {
          const { data: canValidate } = await canValidateCandidature(externalId);
          setCanValidate(canValidate.flag);
        }
      }

      if (candidature.state === CandidatureState.FULLY_APPROVED) {
        await checkPromoterConsultingRequest(candidature);
      }

      setCandidature(candidature);
      setCandidatureDocuments(candidatureDocuments);
      checkLocalStorageItems();
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const processCallback = async (callback, sessionStorageItem) => {
    setLoading(true);

    try {
      const response = await callback();
      if (sessionStorageItem) {
        sessionStorage.setItem(sessionStorageItem, true);
      }
      if (CandidatureState.DRAFT === response.data.state && isIEFPUser()) {
        navigate('/candidaturas');
      }
      fetchData();
    } catch (error) {
      console.log('DetailCandidature::processCallback', error);
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  async function checkPromoterConsultingRequest(candidature) {
    try {
      const { data: consultingRequest } = await getCandidatureConsultingRequest(
        candidature.externalId
      );
      setConsultingRequest(consultingRequest);
    } catch (error) {
      setError(error);
    }
  }

  function checkLocalStorageItems() {
    [
      'candidatureSubmited',
      'economicViabilitySubmitted',
      'economicViabilityValidated',
      'promoterCandidatureValidated',
      'candidatureApprovedDocumentsSubmited',
      'candidatureApprovedDocumentsValidated',
      'candidatureApprovedDocumentsNotValidated',
      'proposalDecisionExpiration',
      'saveContestation',
      'cancelApprovalDecisionExpiration',
      'submitExpirationApprovalDecision',
      'consultingRequestSubmitted',
      'consultingRequestContractSubmitted',
      'developmentPlanDecisionSubmited',
      'activitySheetDecisionSubmited',
      'sentFinalDeleteDecision',
      'addAdditionalDueDiligence',
      'responseAdditionalDueDiligence'
    ].forEach((s) => {
      if (sessionStorage.getItem(s)) {
        setLsItem(s);
      }
    });
  }

  if (candidature?.mainPromoter !== true && candidature?.state === CandidatureState.DRAFT) {
    sessionStorage.setItem('promoterRemoved', true);
    navigate('/candidatura');
  }


  if (isEntity && candidature?.state === CandidatureState.DRAFT) {
    navigate('/candidatura/adicionar', {
      state: { isEntity: isEntity, candidatureExternalId: externalId }
    });
  }

  if (loading) {
    return <Loading />;
  }

  if (error && isNotBusinessError(error)) {
    return handleError(error);
  }

  const hasAlert =
      (CandidatureState.SUBMITTED === candidature.state &&
          (justMentioned || candidature?.mainPromoter === true)) ||
      (isEntityUser() && (CandidatureState.EMIT_OPINION === candidature.state || CandidatureState.NEW_EMIT_OPINION === candidature.state)) ||
      ((CandidatureState.WAIT_CONTESTATION === candidature.state ||
              CandidatureState.WAIT_CONTESTATION_EXPIRATION === candidature.state) &&
          candidature?.mainPromoter) ||
      (CandidatureState.WAIT_ADDITIONAL_DUE_DILIGENCE_RESPONSE === candidature.state &&
          isPromoterUser());

  return (
    <SubTemplate>
      <Container>
        {hasAlert && (
          <CandidatureAlertInfo
            candidatureState={candidature.state}
            dateSubmissionLimit={candidature.submissionDeadlineDate}
            isMainPromoter={
              candidature?.mainPromoter === true &&
              (CandidatureState.EMIT_OPINION !== candidature.state && CandidatureState.NEW_EMIT_OPINION !== candidature.state)
            }
          />
        )}

        <AlertError error={error} />
        {lsItem && <AlertSuccess lsItem={lsItem} />}

        <Row>
          <Col md='9' className='mb-5 summaryTabs'>
            <CandidatureTabs candidature={candidature} documents={candidatureDocuments} />
          </Col>
          <Col md='3'>
            <div className='px-2 mb-5'>
              <CandidatureActionsMenuBar
                candidature={candidature}
                canValidate={{ canValidate }}
                callbackHandler={processCallback}
                consultingRequest={consultingRequest}
                promoterHasPendingEvaluations= {hasPendingEvaluations}
              />
            </div>
          </Col>
        </Row>
      </Container>
    </SubTemplate>
  );
}
