import React from 'react';
import {Col, Form, Row, Table} from 'react-bootstrap';
import {FormattedMessage, useIntl} from 'react-intl';
import {TextAreaInputField} from '../../bootstrap/TextAreaInputField';
import {FaRegTimesCircle} from 'react-icons/fa';
import ToolTip from '../../bootstrap/ToolTip';
import {TextInputField} from '../../bootstrap/TextInputField';
import IntInputField from "../../bootstrap/IntInputField";

export function FinalReportFormStep7({
                                         formStep,
                                         numberOfSteps,
                                         errors,
                                         formikValues,
                                         handleChange,
                                         handlePreviousStep,
                                         handleNextStep,
                                         setFieldValue,
                                         readMode
                                     }) {
    const intl = useIntl();

    const providedMCEServicesOptions = [
        'followUp',
        'consultancy',
        'skillsExtension',
    ];

    const followUpVisitsHeaders = ['months', 'yearN', 'yearNplus1', 'yearNplus2'];

    const followUpVisitsRowsIds = [
        'january',
        'february',
        'march',
        'april',
        'may',
        'june',
        'july',
        'august',
        'september',
        'october',
        'november',
        'december',
    ];


    for (let i = 0; i < new Date(formikValues.mceSupportPeriod.initialDateMceSupport).getMonth(); i++) {

        const element = followUpVisitsRowsIds.splice(0, 1)[0];
        followUpVisitsRowsIds.splice(11, 0, element);
    }

    const difficultiesOvercomeActivityHeaders = ['areas', 'succintDescription'];

    const difficultiesOvercomeActivityRowsIds = [
        'followupIDObservations',
        'followupManagementObservations',
        'followupInfSystemObservations',
        'followupHRObservations',
        'followupMarketingObservations',
        'followupLogisticObservations',
        'followupFinanceObservations',
        'followupOtherObservations',
    ];

    const actionsDevelopmentSummaryHeaders = [
        'designation',
        'objectives',
        'results',
        'forecastHours',
        'realHours',
        'hoursDeviation',
    ];

    function handleCheckBoxesChanges(e, option) {
        switch (option) {
            case 'followUp':
                formikValues.activityResume.isFollowup = !formikValues.activityResume.isFollowup;
                break;
            case 'consultancy':
                formikValues.activityResume.isConsulting = !formikValues.activityResume.isConsulting;
                break;
            case 'skillsExtension':
                formikValues.activityResume.isCompetencesExpansion = !formikValues.activityResume.isCompetencesExpansion;


        }

        setFieldValue({...formikValues});
    }


    function handleActionDevelopment(e, index, header) {
        formikValues.activityResume.actionDevelopments[index][header] = header.endsWith('Hours') ? parseInt(e.target.value) : e.target.value;
        setFieldValue({...formikValues});
    }


    function handleVisitChange(e, year, month) {
        formikValues.activityResume.visits[year][month].visitCount = parseInt(e.target.value);
        setFieldValue({...formikValues});
    }

    function generateTableHeaders(headers, prefix) {
        return (
            <thead>
            <tr>
                {headers.map((header, index) => (
                    <th className={index === 0 ? 'w-25' : ''} key={header}>
                        <FormattedMessage id={`${prefix}.${header}`}/>
                    </th>
                ))}
            </tr>
            </thead>
        );
    }

    function generateTableRows(
        rowsIds,
        rowsIdsPreffix,
        headers
    ) {
        return rowsIds.map((rowId, index2) => {
            return (
                <tr key={rowId}>
                    <td>
                        <FormattedMessage id={`${rowsIdsPreffix}.${rowId}`}/>
                    </td>
                    {headers.map((header, index3) => (
                        <td key={index3}>
                            {

                                <IntInputField
                                    name={`activityResume.visits.${index3}.${index2}`}
                                    value={formikValues.activityResume.visits[index3][index2].visitCount}

                                    handleChange={(e) => handleVisitChange(e, index3, index2)}
                                    isInvalid={
                                        errors?.activityResume?.visits?.[index3]?.[index2]?.visitCount
                                    }
                                    errorMessage={
                                        errors?.activityResume?.visits?.[index3]?.[index2]?.visitCount
                                    }

                                />

                            }
                        </td>
                    ))}
                </tr>
            );
        });
    }


    function generateTableRowsText(
        columns,
        rowsIdsPreffix
    ) {
        return columns.map((rowId) => {
            return (
                <tr key={rowId}>
                    <td>
                        <FormattedMessage id={`${rowsIdsPreffix}.${rowId}`}/>
                    </td>
                    <td>
                        <TextAreaInputField
                            handleChange={handleChange}
                            name={`activityResume.${rowId}`}
                            value={formikValues.activityResume[rowId]}
                            isInvalid={errors?.activityResume?.[rowId]}
                            errorMessage={errors?.activityResume?.[rowId]}
                        />
                    </td>
                </tr>
            );
        });
    }

    function totalVisits(yearProp) {
        let sum = 0;
        formikValues.activityResume.visits[yearProp].forEach(m => {
                sum += m.visitCount;
            }
        )


        return sum;
    }

    function hoursDeviation(index) {
        return (
            formikValues.activityResume.actionDevelopments[index].realHours -
            formikValues.activityResume.actionDevelopments[index].forecastHours || 0
        );
    }


    function removeActionDevelopment(index) {
        formikValues.activityResume.actionDevelopments.splice(index, 1);
        setFieldValue({...formikValues});
    }

    function handlerAddActionDevelopment() {
        formikValues.activityResume.actionDevelopments.push({
            designation: '',
            objectives: '',
            results: '',
            forecastHours: 0,
            realHours: 0
        });


        setFieldValue({...formikValues});
    }

    return (
        <div>
            <h4 className='text-primary mb-0'>
                <FormattedMessage id='finalReportForm.step7.title'/>
            </h4>
            <small className='text-secondary text-uppercase'>
                {intl.formatMessage(
                    {id: 'all.step'},
                    {step: formStep, numberOfSteps: numberOfSteps}
                )}
            </small>
            <fieldset disabled={readMode == true}>
            <Row>
                <Col>
                    <Form.Label>
                        <FormattedMessage id='finalReportForm.step7.markMCEService'/>
                    </Form.Label>
                </Col>
            </Row>
            <Row>
                {providedMCEServicesOptions.map((option) => (
                    <Col md='4' key={option}>
                        <Form.Check
                            className='d-flex align-items-center'
                            checked={formikValues.activityResume[option === 'followUp' ? 'isFollowup' : option === 'consultancy' ? 'isConsulting' : 'isCompetencesExpansion'] === true}
                            label={
                                <FormattedMessage id={`finalReportForm.step7.${option}`}/>
                            }


                            onChange={(e) => handleCheckBoxesChanges(e, option)}
                        />
                    </Col>
                ))}
            </Row>

            <Row className='mt-5'>
                <small className='text-primary text-uppercase'>
                    <FormattedMessage id='finalReportForm.step7.followUpSummary'/>
                </small>
            </Row>
            <Row className='mt-2'>
                <Col>
                    <Form.Label>
                        <FormattedMessage id='finalReportForm.step7.followUpVisits'/>
                    </Form.Label>
                    <Table className='d-table' striped responsive='md'>
                        {generateTableHeaders(
                            followUpVisitsHeaders,
                            'finalReportForm.step7.table'
                        )}
                        <tbody>
                        {generateTableRows(
                            followUpVisitsRowsIds,
                            'all.months',
                            followUpVisitsHeaders.slice(1),
                            'number',
                            'followUpVisits'
                        )}
                        <tr>

                            <td>
                                <FormattedMessage id='finalReportForm.step7.table.total'/>
                            </td>
                            {followUpVisitsHeaders.slice(1).map((header, index2) => (
                                <td key={'total' + header}>
                                    <p className='mx-2 mb-0'>{totalVisits(index2)}</p></td>
                            ))}

                        </tr>
                        </tbody>
                    </Table>
                </Col>
            </Row>


            <Row>
                <Col>
                    <TextAreaInputField
                        name='activityResume.followupObservations'
                        labelId='finalReportForm.step7.followUpVisitsObservationsDescription'
                        handleChange={handleChange}
                        value={formikValues.activityResume.followupObservations}
                        isInvalid={errors?.activityResume?.followupObservations}
                        errorMessage={errors?.activityResume?.followupObservations}
                    />
                </Col>
            </Row>
            <Row className='mt-4'>
                <Col>
                    <Form.Label>
                        <FormattedMessage id='finalReportForm.step7.activityToOvercomeDifficulties'/>
                        <ToolTip
                            message={
                                <FormattedMessage id='finalReportForm.step7.activityToOvercomeDifficulties.toolTip'/>
                            }
                        />
                    </Form.Label>
                    <Table className='d-table ' striped responsive='md'>
                        {generateTableHeaders(
                            difficultiesOvercomeActivityHeaders,
                            'finalReportForm.step7.table'
                        )}
                        <tbody>
                        {generateTableRowsText(
                            difficultiesOvercomeActivityRowsIds,
                            'finalReportForm.step7.table',
                            difficultiesOvercomeActivityHeaders.slice(1),
                            'followUpVisits'
                        )}
                        </tbody>
                    </Table>
                </Col>
            </Row>
                <Row className='mt-5'>
                    <small className='text-primary text-uppercase'>
                        <FormattedMessage id='finalReportForm.step7.consultancySummary'/>
                    </small>
                </Row>

            <Row className='mt-2'>
                <Col>
                    <Form.Label>
                        <FormattedMessage id='finalReportForm.step7.actionsDevelopmentSummaryBorder'/>
                    </Form.Label>
                    <Table className='d-table ' striped responsive='md'>
                        {generateTableHeaders(
                            actionsDevelopmentSummaryHeaders,
                            'finalReportForm.step7.table'
                        )}
                        <tbody>
                        {formikValues.activityResume.actionDevelopments.length > 0 ? (
                            formikValues.activityResume.actionDevelopments.map((action, index) => (
                                <tr key={index}>
                                    {actionsDevelopmentSummaryHeaders
                                        .slice(0, -1)
                                        .map((header) => (
                                            <td key={header}>
                                                {header.endsWith('Hours') ? (

                                                    <IntInputField
                                                        name={`activityResume.actionDevelopments${index}.${header}`}
                                                        value={formikValues.activityResume.actionDevelopments[index][header]}

                                                        handleChange={(e) => handleActionDevelopment(e, index, header)}
                                                        isInvalid={
                                                            errors?.activityResume?.actionDevelopments?.[index]?.[header]
                                                        }
                                                        errorMessage={
                                                            errors?.activityResume?.actionDevelopments?.[index]?.[header]
                                                        }

                                                    />

                                                ) : (
                                                    <TextInputField
                                                        name={`activityResume.actionDevelopments${index}.${header}`}
                                                        value={formikValues.activityResume.actionDevelopments[index][header]}

                                                        handleChange={(e) => handleActionDevelopment(e, index, header)}
                                                        isInvalid={
                                                            errors?.activityResume?.actionDevelopments?.[index]?.[header]
                                                        }
                                                        errorMessage={
                                                            errors?.activityResume?.actionDevelopments?.[index]?.[header]
                                                        }
                                                        showErrorOnToolTip={true}
                                                    />
                                                )}
                                            </td>
                                        ))}

                                    <td>{hoursDeviation(index) + ' h'}</td>
                                    <td><FaRegTimesCircle
                                        onClick={() =>
                                            removeActionDevelopment(index)
                                        }
										color={"#eb4f24"}
                                    /></td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan={actionsDevelopmentSummaryHeaders.length}>
                                    <FormattedMessage id='finalReportForm.step7.actionsDevelopmentSummary.noData'/>
                                </td>
                            </tr>
                        )}
                        </tbody>
                        <tfoot>
                        <tr>
                            <td colSpan={actionsDevelopmentSummaryHeaders.length}>
                                <Row>
                                    <Col>
                                        <button
                                            className='btn btn-outline-primary'
                                            type='button'
                                            onClick={handlerAddActionDevelopment}
                                        >
                                            <FormattedMessage id='all.addButton'/>
                                        </button>
                                    </Col>
                                </Row>
                            </td>
                        </tr>
                        </tfoot>
                    </Table>
                </Col>
            </Row>
            <Row>
                <Col>
                    <TextAreaInputField
                        name='activityResume.summaryDescriptionConsulting'
                        labelId='finalReportForm.step7.consultancySummaryDescription'
                        handleChange={handleChange}
                        value={formikValues.activityResume.summaryDescriptionConsulting}
                        isInvalid={errors?.activityResume?.summaryDescriptionConsulting}
                        errorMessage={errors?.activityResume?.summaryDescriptionConsulting}
                    />
                </Col>
            </Row>
            </fieldset>

            <div className='form-actions mt-5'>
                <button
                    className='btn btn-link'
                    type='button'
                    onClick={handlePreviousStep}
                >
                    <FormattedMessage id='all.back'/>
                </button>

                <button
                    className='btn btn-primary'
                    type='button'
                    onClick={handleNextStep}
                >
                    <FormattedMessage id='all.nextStep'/>
                </button>
            </div>
        </div>
    );
}
