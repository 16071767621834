import React, { useEffect, useState } from 'react';
import { Row, Col, Table, Form } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import DecimalInputField from '../../bootstrap/DecimalInputField';
import ToolTip from '../../bootstrap/ToolTip';
import { maxFixed } from '../../../utils/CurrencyUtils';
import {
	totalCostsDisclosureActivities,
	totalCostsGeneral,
	totalCostsIncubationSupport,
	totalEABudget,
} from '../../../utils/AnnualComplementaryActivitiesPlanFormUtils';
import { TextAreaInputField } from '../../bootstrap/TextAreaInputField';
import { TextInputField } from '../../bootstrap/TextInputField';
import { SingleUploadArea } from '../../SingleUploadArea';
import { DateTimeInputField } from '../../bootstrap/DateTimeInputField';
import { AdditionalActivityControlType } from '../../../models/AdditionalActivityControlType';
import {
	deleteDocument,
	uploadDocument,
} from '../../../rest/additionalActivitiesPlan';

export function AnnualComplementaryActivitiesPlanFormStep4({
	formStep,
	numberOfSteps,
	errors,
	handleChange,
	formikValues,
	handlePreviousStep,
	months,
	setError,
	setFieldValue,
	documents,
	setDocuments,
	readMode,
	isIEFP,
}) {
	const bootcampIndex = 0;
	const didaticIndex = 1;

	const bootcampControlIndex = 0;
	const didaticControlIndex = 1;
	const disclosureControlIndex = 2;
	const incubationProjectsControlIndex = 3;

	const intl = useIntl();

	const uploadCallback = async (formData) => {
		return await uploadDocument(formikValues.externalId, formData);
	};

	const deleteCallback = async (documentExternalId) => {
		return await deleteDocument(formikValues.externalId, documentExternalId);
	};

	function totalDeviation() {
		let sum = 0;
		sum +=
			calculateDeviation(
				AdditionalActivityControlType.BOOTCAMP,
				bootcampControlIndex
			) +
			calculateDeviation(
				AdditionalActivityControlType.DISCLOSURE,
				disclosureControlIndex
			) +
			calculateDeviation(
				AdditionalActivityControlType.DIDACTIC,
				didaticControlIndex
			) +
			calculateDeviation(
				AdditionalActivityControlType.INCUBATION_PROJECTS,
				incubationProjectsControlIndex
			);
		return sum;
	}

	function calculateDeviation(activityControlType, activityControlIndex) {
		let limitsControl = parseFloat(
			formikValues.technicalAnalysis.additionalActivityControlList[
				activityControlIndex
			].limitsControl
		);
		let EAActivityBudget;
		if (limitsControl) {
			if (activityControlType === AdditionalActivityControlType.BOOTCAMP) {
				EAActivityBudget = totalCostsGeneral(
					formikValues,
					bootcampIndex,
					'value'
				);
			} else if (
				activityControlType === AdditionalActivityControlType.DISCLOSURE
			) {
				EAActivityBudget = totalCostsDisclosureActivities(formikValues);
			} else if (
				activityControlType === AdditionalActivityControlType.DIDACTIC
			) {
				EAActivityBudget = totalCostsGeneral(
					formikValues,
					didaticIndex,
					'value'
				);
			} else {
				EAActivityBudget = totalCostsIncubationSupport(
					formikValues,
					formikValues.activityIdentification.ias,
					months
				);
			}

			return limitsControl - EAActivityBudget;
		} else {
			return 0;
		}
	}

	function totalComplementaryActivities(field) {
		let sum = 0;
		formikValues.technicalAnalysis.additionalActivityControlList.forEach(
			(activityControl) => (sum += parseFloat(activityControl[field]) || 0)
		);
		return sum;
	}

	return (
		<div>
			<h4 className='text-primary mb-0'>
				<FormattedMessage id='annualComplementaryActivitiesPlanForm.sideNavBar.technicalAnalysis' />
			</h4>
			<small className='text-secondary text-uppercase'>
				{intl.formatMessage(
					{ id: 'all.step' },
					{ step: formStep, numberOfSteps: numberOfSteps }
				)}
			</small>
			<fieldset disabled={readMode==true}>
				<Row className='mt-4'>
					<Col md='4'>
						<Form.Label>
							<FormattedMessage id='annualComplementaryActivitiesPlanForm.globalBudgetVerification' />
							<ToolTip
								message={
									<FormattedMessage id='annualComplementaryActivitiesPlanForm.globalBudgetVerification.toolTip' />
								}
							/>
						</Form.Label>
						{maxFixed(
							(formikValues.technicalAnalysis.iasConfirmation || 0) * 60
						) + ' €'}
					</Col>
					<Col md='8'>
						<DecimalInputField
							suffixLabel=' €'
							name={'technicalAnalysis.iasConfirmation'}
							handleChange={handleChange}
							labelId='annualComplementaryActivitiesPlanForm.IASconfirmedAtTheTime'
							value={formikValues.technicalAnalysis.iasConfirmation}
							isInvalid={errors.technicalAnalysis?.iasConfirmation}
							errorMessage={errors.technicalAnalysis?.iasConfirmation}
							inputClass='w-50'
						/>
					</Col>
				</Row>

				<Row className={'mt-4'}>
					<Col>
						<Form.Label>
							<FormattedMessage id='annualComplementaryActivitiesPlanForm.globalBudgetActivitiesControl' />
						</Form.Label>
					</Col>
				</Row>
				<Row>
					<Col>
						<Table striped responsive='md'>
							<thead>
								<tr>
									<th className='w-35'>
										<FormattedMessage id='annualComplementaryActivitiesPlanForm.complementaryActivities' />
									</th>
									<th className='w-15  text-center'>
										<FormattedMessage id='annualComplementaryActivitiesPlanForm.EABudget' />
									</th>
									<th className='text-center'>
										<FormattedMessage id='annualComplementaryActivitiesPlanForm.limitsControl' />
									</th>
									<th className='w-15  text-center'>
										<FormattedMessage id='annualComplementaryActivitiesPlanForm.deviation' />
									</th>
									<th className='text-center'>
										<FormattedMessage id='annualComplementaryActivitiesPlanForm.technicalProposal' />
									</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>Bootcamp</td>

									<td className='text-center'>
										{maxFixed(
											totalCostsGeneral(formikValues, bootcampIndex, 'value')
										) + ' €'}
									</td>

									<td>
										<DecimalInputField
											name={`technicalAnalysis.additionalActivityControlList[${bootcampControlIndex}].limitsControl`}
											value={
												formikValues.technicalAnalysis
													.additionalActivityControlList[bootcampControlIndex]
													.limitsControl
											}
											handleChange={handleChange}
											isInvalid={
												errors.technicalAnalysis
													?.additionalActivityControlList?.[
													bootcampControlIndex
												]?.limitsControl
											}
											errorMessage={
												errors.technicalAnalysis
													?.additionalActivityControlList?.[
													bootcampControlIndex
												]?.limitsControl
											}
											suffixLabel=' €'
										/>
									</td>

									<td className='text-center'>
										{maxFixed(
											calculateDeviation(
												AdditionalActivityControlType.BOOTCAMP,
												bootcampControlIndex
											)
										) + ' €'}
									</td>

									<td>
										<DecimalInputField
											name={`technicalAnalysis.additionalActivityControlList[${bootcampControlIndex}].decisionProposal`}
											value={
												formikValues.technicalAnalysis
													.additionalActivityControlList[bootcampControlIndex]
													.decisionProposal
											}
											handleChange={handleChange}
											isInvalid={
												errors.technicalAnalysis
													?.additionalActivityControlList?.[
													bootcampControlIndex
												]?.decisionProposal
											}
											errorMessage={
												errors.technicalAnalysis
													?.additionalActivityControlList?.[
													bootcampControlIndex
												]?.decisionProposal
											}
											suffixLabel=' €'
										/>
									</td>
								</tr>

								<tr>
									<td>
										<FormattedMessage id='annualComplementaryActivitiesPlanForm.disclosureActivities' />
									</td>

									<td className='text-center'>
										{maxFixed(totalCostsDisclosureActivities(formikValues)) +
											' €'}
									</td>

									<td>
										<DecimalInputField
											name={`technicalAnalysis.additionalActivityControlList[${disclosureControlIndex}].limitsControl`}
											value={
												formikValues.technicalAnalysis
													.additionalActivityControlList[disclosureControlIndex]
													.limitsControl
											}
											handleChange={handleChange}
											isInvalid={
												errors.technicalAnalysis
													?.additionalActivityControlList?.[
													disclosureControlIndex
												]?.limitsControl
											}
											errorMessage={
												errors.technicalAnalysis
													?.additionalActivityControlList?.[
													disclosureControlIndex
												]?.limitsControl
											}
											suffixLabel=' €'
										/>
									</td>

									<td className='text-center'>
										{maxFixed(
											calculateDeviation(
												AdditionalActivityControlType.DISCLOSURE,
												disclosureControlIndex
											)
										) + ' €'}
									</td>

									<td>
										<DecimalInputField
											name={`technicalAnalysis.additionalActivityControlList[${disclosureControlIndex}].decisionProposal`}
											value={
												formikValues.technicalAnalysis
													.additionalActivityControlList[disclosureControlIndex]
													.decisionProposal
											}
											handleChange={handleChange}
											isInvalid={
												errors.technicalAnalysis
													?.additionalActivityControlList?.[
													disclosureControlIndex
												]?.decisionProposal
											}
											errorMessage={
												errors.technicalAnalysis
													?.additionalActivityControlList?.[
													disclosureControlIndex
												]?.decisionProposal
											}
											suffixLabel=' €'
										/>
									</td>
								</tr>

								<tr>
									<td>
										<FormattedMessage id='annualComplementaryActivitiesPlanForm.didacticMaterials' />
									</td>

									<td className='text-center'>
										{maxFixed(
											totalCostsGeneral(formikValues, didaticIndex, 'value')
										) + ' €'}
									</td>

									<td>
										<DecimalInputField
											name={`technicalAnalysis.additionalActivityControlList[${didaticControlIndex}].limitsControl`}
											value={
												formikValues.technicalAnalysis
													.additionalActivityControlList[didaticControlIndex]
													.limitsControl
											}
											handleChange={handleChange}
											isInvalid={
												errors.technicalAnalysis
													?.additionalActivityControlList?.[didaticControlIndex]
													?.limitsControl
											}
											errorMessage={
												errors.technicalAnalysis
													?.additionalActivityControlList?.[didaticControlIndex]
													?.limitsControl
											}
											suffixLabel=' €'
										/>
									</td>

									<td className='text-center'>
										{maxFixed(
											calculateDeviation(
												AdditionalActivityControlType.DIDACTIC,
												didaticControlIndex
											)
										) + ' €'}
									</td>

									<td>
										<DecimalInputField
											name={`technicalAnalysis.additionalActivityControlList[${didaticControlIndex}].decisionProposal`}
											value={
												formikValues.technicalAnalysis
													.additionalActivityControlList[didaticControlIndex]
													.decisionProposal
											}
											handleChange={handleChange}
											isInvalid={
												errors.technicalAnalysis
													?.additionalActivityControlList?.[didaticControlIndex]
													?.decisionProposal
											}
											errorMessage={
												errors.technicalAnalysis
													?.additionalActivityControlList?.[didaticControlIndex]
													?.decisionProposal
											}
											suffixLabel=' €'
										/>
									</td>
								</tr>

								<tr>
									<td>
										<FormattedMessage id='annualComplementaryActivitiesPlanForm.incubationCosts' />
									</td>

									<td className='text-center'>
										{maxFixed(
											totalCostsIncubationSupport(
												formikValues,
												formikValues.activityIdentification.ias,
												months
											)
										) + ' €'}
									</td>

									<td>
										<DecimalInputField
											name={`technicalAnalysis.additionalActivityControlList[${incubationProjectsControlIndex}].limitsControl`}
											value={
												formikValues.technicalAnalysis
													.additionalActivityControlList[
													incubationProjectsControlIndex
												].limitsControl
											}
											handleChange={handleChange}
											isInvalid={
												errors.technicalAnalysis
													?.additionalActivityControlList?.[
													incubationProjectsControlIndex
												]?.limitsControl
											}
											errorMessage={
												errors.technicalAnalysis
													?.additionalActivityControlList?.[
													incubationProjectsControlIndex
												]?.limitsControl
											}
											suffixLabel=' €'
										/>
									</td>

									<td className='text-center'>
										{maxFixed(
											calculateDeviation(
												AdditionalActivityControlType.INCUBATION_PROJECTS,
												incubationProjectsControlIndex
											)
										) + ' €'}
									</td>

									<td>
										<DecimalInputField
											name={`technicalAnalysis.additionalActivityControlList[${incubationProjectsControlIndex}].decisionProposal`}
											value={
												formikValues.technicalAnalysis
													.additionalActivityControlList[
													incubationProjectsControlIndex
												].decisionProposal
											}
											handleChange={handleChange}
											isInvalid={
												errors.technicalAnalysis
													?.additionalActivityControlList?.[
													incubationProjectsControlIndex
												]?.decisionProposal
											}
											errorMessage={
												errors.technicalAnalysis
													?.additionalActivityControlList?.[
													incubationProjectsControlIndex
												]?.decisionProposal
											}
											suffixLabel=' €'
										/>
									</td>
								</tr>

								<tr>
									<td>Total</td>

									<td className='text-center'>
										{maxFixed(
											totalEABudget(
												formikValues,
												formikValues.activityIdentification.ias,
												months
											)
										) + ' €'}
									</td>

									<td className='text-center'>
										{maxFixed(totalComplementaryActivities('limitsControl')) +
											' €'}
									</td>

									<td className='text-center'>
										{maxFixed(totalDeviation()) + ' €'}
									</td>

									<td className='text-center'>
										{maxFixed(
											totalComplementaryActivities('decisionProposal')
										) + ' €'}
									</td>
								</tr>
							</tbody>
						</Table>
					</Col>
				</Row>

				<Row className='mt-5'>
					<small className='text-primary text-uppercase'>
						<FormattedMessage id='annualComplementaryActivitiesPlanForm.analysisAndOpinion' />
					</small>
				</Row>
				<Row>
					<Col>
						<TextAreaInputField
							name='technicalAnalysis.technicalProposalJustification'
							value={
								formikValues.technicalAnalysis.technicalProposalJustification
							}
							labelId='annualComplementaryActivitiesPlanForm.proposalReason'
							handleChange={handleChange}
							isInvalid={
								errors.technicalAnalysis?.technicalProposalJustification
							}
							errorMessage={
								errors.technicalAnalysis?.technicalProposalJustification
							}
							maxLength={5000}
							placeholder={intl.formatMessage(
								{
									id: 'annualComplementaryActivitiesPlanForm.characters.placeholder',
								},
								{ nChars: '5000' }
							)}
						/>
					</Col>
				</Row>

				<Row>
					<Col>
						<TextInputField
							labelId='annualComplementaryActivitiesPlanForm.technician'
							name='technicalAnalysis.technicianName'
							value={formikValues.technicalAnalysis.technicianName}
							handleChange={handleChange}
							isInvalid={errors.technicalAnalysis?.technicianName}
							errorMessage={errors.technicalAnalysis?.technicianName}
						/>
					</Col>
				</Row>

				<Row className='mt-5'>
					<small className='text-primary text-uppercase'>
						<FormattedMessage id='annualComplementaryActivitiesPlanForm.opinionAnalysisAndDecisionProposal' />
					</small>
				</Row>
				<Row className='mb-3'>
					<Col md='6'>
						<DateTimeInputField
							labelId='annualComplementaryActivitiesPlanForm.disptachDate'
							name='technicalAnalysis.proposalDispatchDate'
							value={formikValues.technicalAnalysis.proposalDispatchDate}
							setFieldValue={setFieldValue}
							isDateWithNoTime={true}
							isInvalid={errors.technicalAnalysis?.proposalDispatchDate}
							errorMessage={errors.technicalAnalysis?.proposalDispatchDate}
						/>
					</Col>
				</Row>
				<Row>
					<Col>
						<TextInputField
							labelId='annualComplementaryActivitiesPlanForm.director'
							name='technicalAnalysis.directorName'
							value={formikValues.technicalAnalysis.directorName}
							handleChange={handleChange}
							isInvalid={errors.technicalAnalysis?.directorName}
							errorMessage={errors.technicalAnalysis?.directorName}
						/>
					</Col>
				</Row>

				<Row className='mt-5'>
					<small className='text-primary text-uppercase'>
						<FormattedMessage id='annualComplementaryActivitiesPlanForm.decision' />
					</small>
				</Row>
				<Row>
					<Col md='6'>
						<DecimalInputField
							name='technicalAnalysis.approvedAmount'
							value={formikValues.technicalAnalysis.approvedAmount}
							handleChange={handleChange}
							isInvalid={errors.technicalAnalysis?.approvedAmount}
							errorMessage={errors.technicalAnalysis?.approvedAmount}
							labelId='annualComplementaryActivitiesPlanForm.totalApprovedAmount'
							suffixLabel=' €'
						/>
					</Col>
					<Col md='6'>
						<DecimalInputField
							name='technicalAnalysis.advanceAmount'
							value={formikValues.technicalAnalysis.advanceAmount}
							handleChange={handleChange}
							isInvalid={errors.technicalAnalysis?.advanceAmount}
							errorMessage={errors.technicalAnalysis?.advanceAmount}
							labelId='annualComplementaryActivitiesPlanForm.advanceAmount'
							suffixLabel=' €'
							toolTip={
								<ToolTip
									message={
										<FormattedMessage id='annualComplementaryActivitiesPlanForm.advanceAmount.toolTip' />
									}
								/>
							}
						/>
					</Col>
				</Row>
				<Row className='mb-3'>
					{isIEFP && (
						<Col md='6'>
							<TextInputField
								className='w-25'
								name='technicalAnalysis.decisionDispatchNumber'
								value={formikValues.technicalAnalysis.decisionDispatchNumber}
								labelId='annualComplementaryActivitiesPlanForm.disptachInformationNumber'
								handleChange={handleChange}
								isInvalid={errors.technicalAnalysis?.decisionDispatchNumber}
								errorMessage={errors.technicalAnalysis?.decisionDispatchNumber}
							/>
						</Col>
					)}
					<Col md='6'>
						<DateTimeInputField
							labelId='annualComplementaryActivitiesPlanForm.disptachDate'
							name='technicalAnalysis.decisionDispatchDate'
							value={formikValues.technicalAnalysis.decisionDispatchDate}
							setFieldValue={setFieldValue}
							isDateWithNoTime={true}
							isInvalid={errors.technicalAnalysis?.decisionDispatchDate}
							errorMessage={errors.technicalAnalysis?.decisionDispatchDate}
						/>
					</Col>
				</Row>
				{isIEFP && (
					<Row>
						<Col md='12'>
							<SingleUploadArea
								documents={documents}
								setDocuments={setDocuments}
								documentType={'SERVICE_INFORMATION'}
								setError={setError}
								processUploadCallback={uploadCallback}
								processDeleteCallback={deleteCallback}
							/>
						</Col>
					</Row>
				)}

				<Row>
					<Col md='12'>
						<SingleUploadArea
							documents={documents}
							setDocuments={setDocuments}
							documentType={'NOTIFICATION_TO_EA'}
							setError={setError}
							processUploadCallback={uploadCallback}
							processDeleteCallback={deleteCallback}
						/>
					</Col>
				</Row>
				<Row>
					<Col>
						<TextInputField
							labelId={'annualComplementaryActivitiesPlanForm.deputy'}
							name='technicalAnalysis.regionalDelegateName'
							value={formikValues.technicalAnalysis.regionalDelegateName}
							handleChange={handleChange}
							isInvalid={errors.technicalAnalysis?.regionalDelegateName}
							errorMessage={errors.technicalAnalysis?.regionalDelegateName}
						/>
					</Col>
				</Row>
			</fieldset>
			<div className='form-actions mt-5'>
				<button
					className='btn btn-link'
					type='button'
					onClick={handlePreviousStep}
				>
					<FormattedMessage id='all.back' />
				</button>
			</div>
		</div>
	);
}
