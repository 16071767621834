import React, { useEffect, useState } from 'react';
import { FaEdit, FaTrashAlt } from 'react-icons/fa';
import { TiPlus } from 'react-icons/ti';
import { FormattedMessage } from 'react-intl';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from 'react-router-dom';
import { AlertError } from '../../../components/bootstrap/AlertError';
import { DeleteDialog } from '../../../components/bootstrap/DeleteDialog';
import Loading from '../../../components/general/Loading';
import { SubTemplate } from '../../../components/general/SubTemplate';
import { deleteParameter, getParameters } from '../../../rest/parameter';
import { handleError, isNotBusinessError } from '../../../utils/handleError';

export function ListParameters() {
  const [parameters, setParameters] = useState([]);
  const [parameterToDelete, setParameterToDelete] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);

  const handleClose = () => setShowDeleteDialog(false);
  const handleShow = () => setShowDeleteDialog(true);

  async function fetchData() {
    try {
      const { data } = await getParameters();
      setParameters(data);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  const deleteParam = async (externalId) => {
    setLoading(true);
    try {
      const { data: deletedParameter } = await deleteParameter(externalId);
      setParameters(
        parameters.filter((p) => p.externalId !== deletedParameter.externalId)
      );
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const handleOnClickDelete = (parameter) => {
    setParameterToDelete(parameter);
    handleShow();
  };

  const handleConfirmDelete = () => {
    deleteParam(parameterToDelete.externalId);
    handleClose();
  };

  if (loading) {
    return <Loading />;
  }

  if (error && isNotBusinessError(error)) {
    return handleError(error);
  }

  return (
    <SubTemplate hasBackButton titleId={'parameter.manage'}>
      <div className={'container'}>
        <Row>
          <Col md="9">
            <AlertError error={error} />
            <Link
              to={`/parametros/adicionar`}
              className={'btn btn-outline-primary mb-3'}
            >
              <TiPlus className="mb-1" aria-hidden='true' />
              <FormattedMessage id='parameter.add' />
            </Link>
            <ul className={'results'}>
              {parameters.map((parameter) => (
                <li key={parameter.code}>
                  <Row className='py-3'>
                    <Col md="8 d-flex align-items-center">{parameter.name}</Col>
                    <Col md="4" className='d-flex justify-content-end'>
                      {!parameter.systemOperational && (
                        <button
                          onClick={() => handleOnClickDelete(parameter)}
                          className="btn btn-link"
                        >
                          <FaTrashAlt />
                        </button>
                      )}

                      <Link className="btn btn-link" to={`/parametros/${parameter.externalId}/editar`}>
                        <FaEdit />
                      </Link>
                    </Col>
                  </Row>
                </li>
              ))}
              <DeleteDialog
                titleId='parameterDeleteDialog.title'
                bodyId='parameterDeleteDialog.body'
                show={showDeleteDialog}
                handleClose={handleClose}
                handleConfirmDelete={handleConfirmDelete}
              />
            </ul>
          </Col>
        </Row>
      </div>
    </SubTemplate>
  );
}
