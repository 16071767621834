import {FormattedMessage} from "react-intl";
import React from "react";
import {useNavigate} from "react-router-dom";

export function DeclarationEndFirstYearButton({consultingRequestExternalId}) {
    const navigate = useNavigate();

    return (
        <button
            className='btn btn-outline-primary d-flex align-items-center justify-content-center mb-4 w-100'
            type='button'
            onClick={() => navigate('/pedidosMCE/' + consultingRequestExternalId + '/declaracaofim1ano', {state: { refresh: true }})}
        >
            <FormattedMessage id='declarationEndFirstYear.candidature.button' />
        </button>
    )
}