import {Col, Form, Row} from "react-bootstrap";
import {TextInputField} from "./bootstrap/TextInputField";
import {FormattedMessage, useIntl} from "react-intl";
import React, {useState} from "react";
import {ConfigObject} from "../config";
import {UploadDocument} from "./UploadDocument";
import {DocumentType} from "../models/DocumentType";
import {Arrays} from "../utils/Arrays";
import {createCustomErrorMessage} from "../hooks/errorMessage";
import {AlertError} from "./bootstrap/AlertError";



export function createMultiPartWithFilesAndJsonObject(files, object, objectFormParamName) {
    const formData = createMultiPart(files);
    formData.append(objectFormParamName, JSON.stringify(object));
    return formData;
}
export function createMultiPart(files) {
    const formData = new FormData();

    files.forEach( (file) => {
        formData.append('file', file.content);
        formData.append('fileName', file.content.name);
        formData.append('documentType', file.documentType);
        file.fileDescription && formData.append('fileDescription', file.fileDescription);
        formData.append('size', file.content.size);
        formData.append('extension', file.content.type);
    });
    return formData;
}

export function SyncFileEntry({
    file,
    index,
    removeCallback
}) {
    return (
        <div key={index} className='border-top py-2'>
            <div className='d-flex justify-content-between'>
                <div>
                    {file.fileDescription}
                    <span className='mx-2'>·</span>
                    <span>{file.content.name}</span>
                </div>
                <div>
                    <button
                        className='btn btn-link p-0'
                        type='button'
                        onClick={removeCallback}
                    >
                        <FormattedMessage id='all.remove' />
                    </button>
                </div>
            </div>
        </div>
    );
}

export function SyncMultipleUploadArea({
    name,
    documents,
    setDocuments,
    acceptExtensions,
    readMode,
    labelId,
    documentType = DocumentType.DOCUMENT_TYPE_NODE
}) {
    const index = 1;
    const intl = useIntl();
    const [error, setError] = useState(null);


    function removeFile(index) {
        const fs = documents;
        const sfs = setDocuments;
        return () => {
            fs.splice(index, 1);
            sfs([...fs]);
        }
    }




    async function handleUpload(file, setFile, hiddenFileInput) {

        if(file === null || file.content === null || file.fileDescription === null || file.fileDescription.length === 0 ) {
            return;
        }

        const isFileValid = validateFile(hiddenFileInput);

        if (!isFileValid) {
            return;
        }

        file.documentType = documentType;
        documents.push({...file});
        setDocuments([...documents]);
        setFile({
            content: null,
            fileDescription: '',
        });
        hiddenFileInput.current.value = '';
    }

    function validateFile(hiddenFileInput) {
        if (hiddenFileInput.current.files.length === 0) {
            return false;
        }

        const documentExtensions = ConfigObject.get().documentExtensions.split('|');
        const documentsSize = ConfigObject.get().documentsSize * 1000000;

        const file = hiddenFileInput.current.files[0];

        let lastIndex = file.name.lastIndexOf('.');
        let extension = file.name.substring(lastIndex+1);

        if (!documentExtensions.includes(extension)) {
            setError(
                createCustomErrorMessage(intl.formatMessage({ id: 'errors.uploadArea.fileExtension' }))
            );
            return false;
        }

        if (file.size > documentsSize) {
            setError(createCustomErrorMessage(intl.formatMessage({ id: 'errors.uploadArea.fileSize'},{value: ConfigObject.get().documentsSize} )));
            return false;
        }

        return true;
    }

    if (acceptExtensions === undefined) {
        acceptExtensions = ConfigObject.get().documentExtensions.split('|').map(e => '.' + e).join(',');
    }

    return (
        <>
            <div className={'bg-light px-3 pt-1 pb-2 my-4'}>
                {!Arrays.isEmpty(documents) ? (
                    <div className='row mt-3'>
                        <div className='col-lg-12'>
								<span className='font-small text-secondary'>
									{documents.length}{' '}
                                    {documents.length > 1
                                        ? 'ficheiros carregados'
                                        : 'ficheiro carregado'}
								</span>
                        </div>
                    </div>
                ) : (
                    <div className='row my-3'>
                        <div className='col-lg-12'>
                            <p className='font-small text-secondary font-weight-italic text-center mb-0'>
                                <FormattedMessage id='all.noFilesUploaded' />
                            </p>
                        </div>
                    </div>
                )}
                <p>
                    <small className='text-secondary'>
                        <FormattedMessage id='all.documentTypesAllowed' />
                        {ConfigObject.get().documentExtensions.split('|').join(', ')}
                    </small>
                </p>
                {documents.map((file, index) =>
                <SyncFileEntry
                    file={file}
                    index={index}
                    key={index}
                    removeCallback={removeFile(index)}
                />
                )}
            </div>
            {!readMode &&
                <div className='px-3'>
                    <AlertError error={error} />
                    <UploadDocument
                        acceptExtensions={acceptExtensions}
                        onSubmit={handleUpload}
                        index={index && index}
                    />
                </div>
            }
        </>
    );
}