import React from 'react';
import {Accordion, Col, Row} from 'react-bootstrap';
import {FormattedMessage, useIntl} from 'react-intl';
import {Arrays} from "../../utils/Arrays";
import {ProviderType} from "../../models/ProviderType";

export function ProviderInformationAccordionItem({provideInformationList,totalProvideInformation,yearNames}) {
    const intl = useIntl();
    const providerInformationFinal = getProviderInformation();



    function getProviderInformation() {
        if (provideInformationList?.length > 0) {
            let providerListGrouped = Arrays.groupBy(provideInformationList[0].provideInformationCellList, pInformation => pInformation.type);

            return ProviderType.values().map((v) => providerListGrouped.get(v)?.sort((a, b) => a['year'] - (b['year'])));
        }
        return null;
    }


    return (
        <>
            {(providerInformationFinal != null) && (
                <Accordion.Item eventKey="provideInformation">
                    <Accordion.Header><FormattedMessage
                        id='candidatureTabs.accordion.provideInformation'/></Accordion.Header>
                    <Accordion.Body>
                        <div className='tableWithBorders'>
                            <table className='w-100 mb-5'>
                                <thead>
                                <tr className='border-1 border-bottom '>
                                    <th className='p-lg-1'><FormattedMessage id='provideInformation.description'/>
                                    </th>
                                    {providerInformationFinal[0].map((year,index) => (
                                        <th key={index}> {yearNames[year.year]} </th>
                                    ))}
                                </tr>
                                </thead>
                                <tbody>

                                {providerInformationFinal.map(((providerInformation,index1) => (
                                    <tr className='border-1 border-bottom' key={index1}>
                                        <td>  {intl.formatMessage({id: `providerType.${providerInformation[0].type}`})}    </td>
                                        {providerInformation.map((year,index) => (
                                            <td  key={index} className='withBorderLeft'>
                                                {year.value.toFixed(2)} €
                                            </td>
                                        ))}
                                    </tr>

                                )))

                                }
                                <tr className='border-1 border-bottom'>
                                    <td><strong> <FormattedMessage
                                        id='provideInformation.total'/> </strong></td>
                                    {totalProvideInformation.map((total,index) => (
                                        <td key={index} className='withBorderLeft'>
                                            {total}
                                        </td>
                                    ))}
                                </tr>


                                </tbody>


                            </table>
                        </div>


                        <Row>
                            <Col md='12'>
                                <label>
                                    <FormattedMessage id='provideInformation.reason'/>
                                </label>
                                <p>
                                    {provideInformationList[0].reasonToProvide}
                                </p>
                            </Col>
                        </Row>
                    </Accordion.Body>
                </Accordion.Item>

            )}
        </>
    );
}
