import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Modal, Row } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { AlertError } from '../../../components/bootstrap/AlertError';
import { AlertSuccess } from '../../../components/bootstrap/AlertSuccess';
import { CandidatureChangeTabs } from '../../../components/candidatureChangeRequestDetail/CandidatureChangeTabs';
import Loading from '../../../components/general/Loading';
import { SubTemplate } from '../../../components/general/SubTemplate';
import {
	getCandidatureChangeRequestWithOrWithoutExternalId,
	listCandidatureChangeRequestDocuments,
} from '../../../rest/candidatureChangeRequest';
import { handleError, isNotBusinessError } from '../../../utils/handleError';
import { CandidatureChangeRequestValidationModal } from './CandidatureChangeRequestValidation';
import { CandidatureChangeRequestContestation } from './CandidatureChangeRequestContestation';
import { CandidatureState } from '../../../models/CandidatureState';
import { isIEFPUser } from '../../../authentication/authenticationHelper';
import { CandidatureChangeRequestAcceptanceTermSubmission } from './CandidatureChangeRequestAcceptanceTermSubmission';
import { DecisionDialog } from '../../../components/bootstrap/DecisionDialog';

export function DetailCandidatureChange() {
	const { candidatureExternalId, externalId } = useParams();
	const navigate = useNavigate();
	const isIEFP = isIEFPUser();

	const [candidatureChange, setCandidatureChange] = useState();
	const [candidatureChangeDocuments, setCandidatureChangeDocuments] =
		useState();
	const [lsItem, setLsItem] = useState(null);

	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);

	const [showDecisionModal, setShowDecisionModal] = useState(false);
	const [showContestationModal, setShowContestationModal] = useState(false);
	const [showSubmitAcceptanceTermModal, setShowSubmitAcceptanceTermModal] =
		useState(false);

	const [isApprove, setIsApprove] = useState(null);

	const [showApprovalDecisionDialog, setShowApprovalDecisionDialog] =
		useState(false);

	const fetchData = async () => {
		try {
			let [{ data: candidatureChangeRequest }, { data: requestDocuments }] =
				await Promise.all([
					await getCandidatureChangeRequestWithOrWithoutExternalId(
						candidatureExternalId,
						externalId
					),
					await listCandidatureChangeRequestDocuments(
						candidatureExternalId,
						externalId
					),
				]);

			setCandidatureChange(candidatureChangeRequest);
			setCandidatureChangeDocuments(requestDocuments);

			checkLocalStorageItems();
		} catch (error) {
			setError(error);
		} finally {
			setLoading(false);
		}
	};

	function checkLocalStorageItems() {
		[
			'candidatureChangeRequestSubmited',
			'candidatureChangeRequestDecisionSubmited',
			'saveContestation'
		].forEach((s) => {
			if (sessionStorage.getItem(s)) {
				setLsItem(s);
			}
		});
	}

	useEffect(() => {
		fetchData();
	}, []);

	if (loading) {
		return <Loading />;
	}

	if (error && isNotBusinessError(error)) {
		return handleError(error);
	}

	function handleCloseDecisionModal() {
		setShowDecisionModal(false);
		setIsApprove(null);
	}

	function modalOpenningHandler(isApprove) {
		setIsApprove(isApprove);
		setShowDecisionModal(true);
	}

	const candidatureChangeIsWaitContestation = () => {
		return (
			!isIEFP && CandidatureState.WAIT_CONTESTATION === candidatureChange?.state
		);
	};

	const candidatureChangeIsWaitTermAcceptSubmission = () => {
		return (
			!isIEFP &&
			CandidatureState.WAIT_TERM_ACCEPT_SUBMISSION === candidatureChange?.state
		);
	};

	const showApproveAndRejectButtons = () => {
		return (
			isIEFP &&
			(CandidatureState.VALIDATION === candidatureChange?.state ||
				CandidatureState.CONTESTATION_ANALYSIS === candidatureChange?.state)
		);
	};


	const showRegisterTerm = () => {
		return (
			isIEFP &&
			CandidatureState.WAIT_TERM_ACCEPT_SUBMISSION === candidatureChange?.state
		);
	};


	function goToSubmitTermsOfAccept() {
		navigate('/candidatura/' + candidatureExternalId + '/alterarCandidatura/' + externalId+"/termoAceitacao", {
			state: { refresh: true }
		});
	}



	function approvalDecisionDialog() {
		return (
			<Modal
				show={showApprovalDecisionDialog}
				onHide={() => setShowApprovalDecisionDialog(false)}
			>
				<Modal.Header closeButton>
					<Modal.Title>
						<FormattedMessage
							id={'candidatureChangeRequest.decisionDialog.title'}
						/>
					</Modal.Title>
				</Modal.Header>
				<AlertError error={error} />
				<Modal.Body>
					<FormattedMessage
						id={'candidatureChangeRequest.decisionDialog.body'}
					/>
				</Modal.Body>
				<Modal.Footer className='justify-content-between'>
					<button
						className={'btn btn-outline-primary px-5'}
						onClick={() => approvalDecisionDialogTriage(false)}
					>
						<FormattedMessage id='all.no' />
					</button>
					<button
						className={'btn btn-outline-primary px-5'}
						onClick={() => approvalDecisionDialogTriage(true)}
					>
						<FormattedMessage id='all.yes' />
					</button>
				</Modal.Footer>
			</Modal>
		);
	}

	function approvalDecisionDialogTriage(hasFinancialChanges) {
		if (hasFinancialChanges) {
			navigate(
				`/candidatura/${candidatureExternalId}/alterarCandidatura/${externalId}/deferir`
			);
		} else {
			modalOpenningHandler(true);
		}
		setShowApprovalDecisionDialog(false);
	}

	return (
		<>
			{showContestationModal && (
				<CandidatureChangeRequestContestation
					candidatureChange={candidatureChange}
					setShowModal={setShowContestationModal}
				/>
			)}
			{showDecisionModal && (
				<CandidatureChangeRequestValidationModal
					candidatureChange={candidatureChange}
					isApprove={isApprove}
					handleClose={handleCloseDecisionModal}
				/>
			)}
			{showSubmitAcceptanceTermModal && (
				<CandidatureChangeRequestAcceptanceTermSubmission
					candidatureChange={candidatureChange}
					setShowModal={setShowSubmitAcceptanceTermModal}
				/>
			)}
			{approvalDecisionDialog()}
			<SubTemplate hasBackButton>
				<Container>
					<AlertError error={error} />
					{lsItem && <AlertSuccess lsItem={lsItem} />}

					<Row>
						<Col md='9' className='mb-5 summaryTabs'>
							<CandidatureChangeTabs
								candidatureChange={candidatureChange}
								candidatureChangeDocuments={candidatureChangeDocuments}
							/>
						</Col>
						<Col md='3'>
							<div className='px-2 mb-5'>
								<div className='d-grid gap-4'>
									{showApproveAndRejectButtons() && (
										<>
											<button
												onClick={() => setShowApprovalDecisionDialog(true)}
												className='btn btn-outline-primary mb-2'
											>
												<FormattedMessage id='candidature.change.approve' />
											</button>
											<button
												onClick={() => modalOpenningHandler(false)}
												className='btn btn-outline-primary mb-2'
											>
												<FormattedMessage id='candidature.change.deny' />
											</button>
										</>
									)}
									{showRegisterTerm() && (
										<>

											<Button variant='outline-primary mt-5' onClick={goToSubmitTermsOfAccept}>
												<FormattedMessage id='candidature.flow.submitTermsOfAccept' />
											</Button>
										</>
									)}

									{candidatureChangeIsWaitContestation() && (
										<button
											onClick={() => setShowContestationModal(true)}
											className='btn btn-outline-primary mb-2'
										>
											<FormattedMessage id='candidature.button.contest' />
										</button>
									)}
									{candidatureChangeIsWaitTermAcceptSubmission() && (
										<button
											onClick={() => setShowSubmitAcceptanceTermModal(true)}
											className='btn btn-outline-primary mb-2'
										>
											<FormattedMessage id='candidature.flow.submitTermsOfAccept' />
										</button>
									)}
								</div>
							</div>
						</Col>
					</Row>
				</Container>
			</SubTemplate>
		</>
	);
}
