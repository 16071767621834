import React from 'react';
import { Modal } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

export function AlertPriorSupportSubmissionModal({ show, handleClose, setShowSubmissionModal }) {
  function handleClick() {
    setShowSubmissionModal(true);
    handleClose();
  }

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>
          <FormattedMessage id='candidatureForm.submissionModal.title' />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <FormattedMessage id='alertPriorSupportSubmissionModal.body' />

        <div className='form-actions mt-5'>
          <button className={'btn btn-outline-primary'} onClick={handleClose}>
            <FormattedMessage id='all.cancel' />
          </button>
          <button className={'btn btn-primary'} onClick={() => handleClick()}>
            <FormattedMessage id='all.yes' />
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
}
