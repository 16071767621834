import React from 'react';
import { Col, Row, Table } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { ProviderType } from '../../models/ProviderType';
import { CandidatureFinancialUtils } from '../../utils/CandidatureFinancialUtils';
import { CandidatureFormUtils } from '../../utils/CandidatureFormUtils';
import DecimalInputField from '../bootstrap/DecimalInputField';
import { TextAreaInputField } from '../bootstrap/TextAreaInputField';
import {formatValue} from "react-currency-input-field";
import {ConfigObject} from "../../config";
import { FaInfoCircle } from 'react-icons/fa';
import ToolTip from "../bootstrap/ToolTip";
import {currencyFormat, maxFixed} from "../../utils/CurrencyUtils";

export function CandidatureFormStep10Test({
  errors,
  setFieldValue,
  handleChange,
  values,
  handleSave,
  handleGoBack,
  numberOfSteps
}) {
  const KEYS = [...ProviderType.values(), 'TOTAL'];

  const BOLD_KEYS = 'TOTAL';

  const YEARS = [
    ...new Set(values.provideInformationList[0].provideInformationCellList.map((picl) => picl.year))
  ];

  const intl = useIntl();

  function getCellByYearAndFieldName(year, fieldName) {
    return values.provideInformationList[0].provideInformationCellList.find(
      (picl) => picl.year === year && picl.type === fieldName
    );
  }

  function handleProvideInformationChange(e, year, fieldName) {
    getCellByYearAndFieldName(year, fieldName).value = e.target.value;

    setFieldValue('provideInformationList', [...values.provideInformationList]);
  }

  function getValueByYearAndFieldName(year, fieldName) {
    return getCellByYearAndFieldName(year, fieldName).value;
  }

  function getTableRowsByKey(key) {
    return (
      <tr key={key}>
        <td className={`w-20 ${BOLD_KEYS.includes(key) && 'table-item-bold'}`}>
          <FormattedMessage id={`providerType.${key}`} />
          {BOLD_KEYS.includes(key) && (
              <ToolTip message={   intl.formatMessage(
          { id: 'step8.tooltip.total' }
            )  }/>
          )}
        </td>
        {YEARS.map((year,index) => (
          <React.Fragment key={index}>
            {!CandidatureFormUtils.is3YearsCoverageOnly(values, year) && (
              <td className='w-10'>
                {key === 'TOTAL' ? (
                  <div className='text-center'>
                    {currencyFormat(maxFixed(CandidatureFinancialUtils.calculateProvideInformationTotalValueByYear(
                      values,
                      year
                    )))}
                  </div>
                ) : (
                  <DecimalInputField
                    controlId={`provideInformationCell.${key}`}
                    name={`provideInformationCell.${key}`}
                    handleChange={(e) => handleProvideInformationChange(e, year, key)}
                    value={getValueByYearAndFieldName(year, key)}
                    suffixLabel='€'
                  />
                )}
              </td>
            )}
          </React.Fragment>
        ))}
      </tr>
    );
  }

  function handleReasonChange(e) {
    values.provideInformationList[0].reasonToProvide = e.target.value;
    setFieldValue('provideInformationList', [...values.provideInformationList]);
  }

  function handleNextStep() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    handleSave(true);
  }

  return (
    <div>
      <h4 className='text-primary mb-0'>
        <FormattedMessage id='candidatureForm.provideInformation.title' />
      </h4>
      <small className='text-secondary text-uppercase'>
        {intl.formatMessage(
          { id: 'all.step' },
          { step: values.step, numberOfSteps: numberOfSteps }
        )}
      </small>
      <p className='mt-3 mb-0 text-secondary infoText'>
        <FaInfoCircle size={70} /> {intl.formatMessage(
          { id: 'step9.infoText' },
          { value:   formatValue({
              value: ConfigObject.get().limitValueFor5Years,
              groupSeparator: ' ',
              decimalSeparator: '.',
              suffix: '€',
            })     }
      )}
      </p>

      <Table className='mt-4'>
        <thead>
          <tr>
            <th className='w-20'>
              <FormattedMessage id='all.description' />
            </th>
            {YEARS.map((year) => (
              <React.Fragment key={year}>
                {!CandidatureFormUtils.is3YearsCoverageOnly(values, year) && (
                  <th className='w-10 text-center' key={year}>
                    {CandidatureFormUtils.getYearLabel(values, year)}
                  </th>
                )}
              </React.Fragment>
            ))}
          </tr>
        </thead>
        <tbody>{KEYS.map((key) => getTableRowsByKey(key))}</tbody>
      </Table>

      <>
        <Row>
          <Col md='12'>
            <TextAreaInputField
              labelId={'candidatureForm.provideInformation.reasonToProvide'}
              name={'candidatureForm.provideInformation.reasonToProvide'}
              value={values.provideInformationList[0].reasonToProvide}
              handleChange={(e) => handleReasonChange(e)}
              placeholder={intl.formatMessage({ id: 'all.maxLength.placeholder' })}
              maxLength={10000}
              isInvalid={
                errors.provideInformationList
                  ? errors.provideInformationList[0]?.reasonToProvide
                  : false
              }
              errorMessage={
                errors.provideInformationList
                  ? errors.provideInformationList[0]?.reasonToProvide
                  : ''
              }
            />
          </Col>
        </Row>

        <div className='form-actions mt-5'>
          <button
            className='btn btn-link'
            type='button'
            onClick={() => handleGoBack(values, setFieldValue)}>
            <FormattedMessage id='all.back' />
          </button>

          <button className='btn btn-primary' type='button' onClick={() => handleNextStep()}>
            <FormattedMessage id='all.nextStep' />
          </button>
        </div>
      </>
    </div>
  );
}
