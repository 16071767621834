import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { EnumSelectField } from '../../bootstrap/EnumSelectField';
import { NumberInputField } from '../../bootstrap/NumberInputField';
import { TextAreaInputField } from '../../bootstrap/TextAreaInputField';
import IntInputField from "../../bootstrap/IntInputField";

export function FinalReportFormStep5({
	formStep,
	numberOfSteps,
	errors,
	handleChange,
	formikValues,
	handlePreviousStep,
	handleNextStep,
										 readMode
}) {
	const intl = useIntl();

	const operationOptions = ['ONGOING_PLANNED', 'ONGOING_OTHER', 'INACTIVE'];

	const facilitiesOptions = [
		'GOOD',
		'ACCEPTABLE',
		'DEFICIENT',
	];

	const facilitiesHistoryOptions = [
		'HAS_PLANNED',
		'SMALL_CHANGES',
		'NOT_MATCH'
	];




	return (
		<div>
			<h4 className='text-primary mb-0'>
				<FormattedMessage id='finalReportForm.sideNavBar.companyStatus' />
			</h4>
			<small className='text-secondary text-uppercase'>
				{intl.formatMessage(
					{ id: 'all.step' },
					{ step: formStep, numberOfSteps: numberOfSteps }
				)}
			</small>
			<fieldset disabled={readMode == true}>
			<Row className='mt-4'>
				<small className='text-primary text-uppercase'>
					<FormattedMessage id='finalReportForm.step5.companyDescription' />
				</small>
			</Row>
			<Row className='mb-5'>
				<Col md='4'>
					<EnumSelectField
						labelId='finalReportForm.step5.operation'
						name={`companyStatus.operationState`}
						handleChange={handleChange}
						value={formikValues.companyStatus.operationState}
						isInvalid={errors.companyStatus?.operationState}
						preffixDescriptionId={'finalReportForm.step5'}
						nullOption={true}
						options={operationOptions}
						errorMessage={
							errors.companyStatus?.operationState
						}
					/>
				</Col>
				<Col md='4'>
					<EnumSelectField
						labelId='semestralReportForm.step5.facilities'
						name={`companyStatus.installationState`}
						handleChange={handleChange}
						value={formikValues.companyStatus.installationState}
						isInvalid={errors.companyStatus?.installationState}
						preffixDescriptionId={'finalReportForm.step5.installationState'}
						nullOption={true}
						options={facilitiesOptions}
						errorMessage={
							errors.companyStatus?.installationState
						}
					/>
					<EnumSelectField
						name={`companyStatus.installationHistoryState`}
						labelId='semestralReportForm.step5.facilitiesPrediction'
						handleChange={handleChange}
						value={formikValues.companyStatus.installationHistoryState}
						isInvalid={errors.companyStatus?.installationHistoryState}
						preffixDescriptionId={'finalReportForm.step5.installationHistoryState'}
						nullOption={true}
						options={facilitiesHistoryOptions}
						errorMessage={
							errors.companyStatus?.installationHistoryState
						}
					/>
				</Col>
				<Col md='4'>
					<EnumSelectField
						labelId='semestralReportForm.step5.equipment'
						name={`companyStatus.equipmentState`}
						handleChange={handleChange}
						value={formikValues.companyStatus.equipmentState}
						isInvalid={errors.companyStatus?.equipmentState}
						preffixDescriptionId={'finalReportForm.step5.equipmentState'}
						nullOption={true}
						options={facilitiesOptions}
						errorMessage={
							errors.companyStatus?.equipmentState
						}
					/>
					<EnumSelectField
						name={`companyStatus.equipmentHistoryState`}
						labelId='semestralReportForm.step5.equipmentPrediction'
						handleChange={handleChange}
						value={formikValues.companyStatus.equipmentHistoryState}
						isInvalid={errors.companyStatus?.equipmentHistoryState}
						preffixDescriptionId={'finalReportForm.step5.equipmentHistoryState'}
						nullOption={true}
						options={facilitiesHistoryOptions}
						errorMessage={
							errors.companyStatus?.equipmentHistoryState
						}
					/>
				</Col>
			</Row>
			<Row className='mb-2'>
				<small className='text-uppercase d-block'>
					<FormattedMessage id='finalReportForm.step5.numberOfWorkPositions' />
				</small>
			</Row>
			<Row className='mb-4'>
				<Col md='4'>
					<label>
						<FormattedMessage id='finalReportForm.step5.numberOfWorkPositions.planned' />
					</label>
					<IntInputField
						className={'w-50'}
						name={'companyStatus.workstationForeseen'}
						handleChange={handleChange}
						value={formikValues.companyStatus.workstationForeseen}
						isInvalid={errors.companyStatus?.workstationForeseen}
						errorMessage={
							errors.companyStatus?.workstationForeseen
						}
					/>
				</Col>
				<Col md='4'>
					<label>
						<FormattedMessage id='finalReportForm.step5.numberOfWorkPositions.created' />
					</label>
					<IntInputField
						className={'w-50'}
						name={'companyStatus.workstationCreated'}
						handleChange={handleChange}
						value={formikValues.companyStatus.workstationCreated}
						isInvalid={errors.companyStatus?.workstationCreated}
						errorMessage={
							errors.companyStatus?.workstationCreated
						}
					/>
				</Col>
				<Col md='4'>
					<label>
						<FormattedMessage id='finalReportForm.step5.numberOfWorkPositions.maintained' />
					</label>
					<IntInputField
						className={'w-50'}
						name={'companyStatus.workstationKept'}
						handleChange={handleChange}
						value={formikValues.companyStatus.workstationKept}
						isInvalid={errors?.companyStatus?.workstationKept}
						errorMessage={
							errors?.companyStatus?.workstationKept
						}
					/>
				</Col>
			</Row>
			<Row className='mb-4'>
				<Col>
					<TextAreaInputField
						labelId='finalReportForm.step5.promotersMaintainHalfOfSocialCapitalAndVotes'
						name={'companyStatus.keptMore50Validation'}
						handleChange={handleChange}
						value={formikValues.companyStatus.keptMore50Validation}
						placeholder={intl.formatMessage({
							id: 'finalReportForm.step5.describe',
						})}
						isInvalid={
							errors?.companyStatus?.keptMore50Validation
						}
						errorMessage={
							errors?.companyStatus?.keptMore50Validation

						}
					/>
				</Col>
			</Row>
			<Row className='mb-4'>
				<Col>
					<TextAreaInputField
						labelId='finalReportForm.step5.companyStatusObservations'
						name={'companyStatus.companySituationObs'}
						handleChange={handleChange}
						value={formikValues.companyStatus.companySituationObs}
						isInvalid={errors?.companyStatus?.companySituationObs}
						errorMessage={
							errors?.companyStatus?.companySituationObs
						}
					/>
				</Col>
			</Row>
			</fieldset>
			<div className='form-actions mt-5'>
				<button
					className='btn btn-link'
					type='button'
					onClick={handlePreviousStep}
				>
					<FormattedMessage id='all.back' />
				</button>

				<button
					className='btn btn-primary'
					type='button'
					onClick={handleNextStep}
				>
					<FormattedMessage id='all.nextStep' />
				</button>
			</div>

		</div>
	);
}
