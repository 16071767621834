export const RevocationState = {
    SUBMITTED: 'SUBMITTED',
    ACCEPT:'ACCEPT',
    WAIT_CONTESTATION:'WAIT_CONTESTATION',
    CONTEST_ANALYSIS:'CONTEST_ANALYSIS',
    REFUSE:'REFUSE',
    SENT_REVOCATION:'SENT_REVOCATION',
    FINISH:'FINISH',




values: () => {
    return [
        'SUBMITTED',
        'ACCEPT',
        'WAIT_CONTESTATION',
        'CONTEST_ANALYSIS',
        'REFUSE',
        'SENT_REVOCATION',
        'FINISH'

    ];
  },


};
