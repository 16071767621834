import React, { useEffect, useState } from 'react';
import { Form, Row, Container, Col } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { AlertError } from '../../components/bootstrap/AlertError';
import Loading from '../../components/general/Loading';
import { SubTemplate } from '../../components/general/SubTemplate';
import { getPriorEntityContacts } from '../../rest/entityCandidature';
import { getUserCandidatureExternalId } from '../../rest/candidature';
import { handleError, isNotBusinessError } from '../../utils/handleError';
import {PriorSupportMessageModal} from "../../components/candidature/PriorSupportMessageModal ";
import {PriorSupportState} from "../../models/PriorSupportState";
import {useNavigate} from "react-router-dom";

export function PriorSupportContacts() {
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);
	const [priorEntityContacts, setPriorEntityContacts] = useState(null);
	const [showPriorEntityMessageModal, setShowPriorEntityMessageModal] = useState(false);
	const [candidatureExternalId,setCandidatureExternalId] = useState(null);
	const navigate = useNavigate();

	async function fetchData() {
		try {
			let { data: candidatureExternalId } =
				await getUserCandidatureExternalId();
			let params = { candidatureExternalId: candidatureExternalId };
			setCandidatureExternalId(candidatureExternalId);
			let { data: associatedPriorEntityContacts } =
				await getPriorEntityContacts(params);
			setPriorEntityContacts(associatedPriorEntityContacts);
		} catch (error) {
			setError(error);
		} finally {
			setLoading(false);
		}
	}


	function goToSubmitDocumentPriorSupport() {
		navigate('/candidatura/' + candidatureExternalId + '/revisaoApoio', {
			state: { refresh: true }
		});
	}

	function goToSubmitContractPriorSupport() {
		navigate('/candidatura/' + candidatureExternalId + '/contratoApoio', {
			state: { refresh: true }
		});
	}

	useEffect(() => {
		fetchData();
	}, []);

	if (loading) {
		return <Loading />;
	}

	if (error && isNotBusinessError(error)) {
		return handleError(error);
	}

	return (
		<SubTemplate hasBackButton={true} titleId='candidatureForm.showPriorSupportContactsDetails'>
			<Container>
				<Row>
					<Col lg={{ span: 8, offset: 2 }} className='mb-5'>
						<AlertError error={error} />
						<Form>
							<h4 className='text-primary mb-0'>
								<FormattedMessage id='candidatureForm.showPriorSupportContacts' />
							</h4>
							<Row>
								<Col>
									<Form.Label className='text-secondary'>
										<FormattedMessage id='candidatureForm.PriorSupportContactsModal.body.state' />
									</Form.Label>
									<p>
										{priorEntityContacts.waitingDoc ? (
											<FormattedMessage
												id='priorSupportState.WAIT_DOC'
											/>
										) : (
											<FormattedMessage
												id={`priorSupportState.${priorEntityContacts.state}`}
											/>
										)}
									</p>
								</Col>
							</Row>
							<Row>
								<Col md='6'>
									<Form.Label className='text-secondary'>
										<FormattedMessage id='candidatureForm.PriorSupportContactsModal.body.name' />
									</Form.Label>
									<p>{priorEntityContacts.description}</p>
								</Col>
								<Col md='6'>
									<Form.Label className='text-secondary'>
										<FormattedMessage id='candidatureForm.PriorSupportContactsModal.body.nif' />
									</Form.Label>
									<p>{priorEntityContacts.nif}</p>
								</Col>
							</Row>
							<Row>
								<Col md='6'>
									<Form.Label className='text-secondary'>
										<FormattedMessage id='candidatureForm.PriorSupportContactsModal.body.phone' />
									</Form.Label>
									<p>{priorEntityContacts.phone}</p>
								</Col>
								<Col md='6'>
									<Form.Label className='text-secondary'>
										<FormattedMessage id='candidatureForm.PriorSupportContactsModal.body.email' />
									</Form.Label>
									<p>{priorEntityContacts.email}</p>
								</Col>
							</Row>
							<Row>
								<Form.Label className='text-secondary'>
									<FormattedMessage id='candidatureForm.PriorSupportContactsModal.body.address' />
								</Form.Label>
								<p>{priorEntityContacts.address}</p>
							</Row>
						</Form>
					</Col>

					<Col>
						<button
							className='btn btn-outline-primary d-flex align-items-center justify-content-center mb-4 w-100'
							type='button'
							onClick={() => {
								setShowPriorEntityMessageModal(true);
							}}>
							<FormattedMessage id='candidatureForm.messagePriorSupportEntity' />
						</button>
						{priorEntityContacts.state=== PriorSupportState.WAIT_DOC && (
							<div>
								<button
									className='btn btn-primary d-flex align-items-center justify-content-center mb-4 w-100'
									type='button'
									onClick={goToSubmitDocumentPriorSupport}>
									<FormattedMessage id='candidatureForm.submitReviewPriorSupport' />
								</button>
							</div>
						)}

						{priorEntityContacts.state=== PriorSupportState.ACCEPTED && (
							<div>
								<button
									className='btn btn-outline-primary d-flex align-items-center justify-content-center mb-4 w-100'
									type='button'
									onClick={goToSubmitContractPriorSupport}>
									<FormattedMessage id='candidatureForm.submitContractPriorSupport' />
								</button>
							</div>
						)}
						{
							[PriorSupportState.REVOKED,PriorSupportState.FINISH,PriorSupportState.AWAIT_REVOKE_DECISION].includes(priorEntityContacts.state) &&(
								<button
									className='btn btn-outline-primary d-flex align-items-center justify-content-center mb-4 w-100'
									type='button'
									onClick={() => {
										navigate('/apoioPrevio/'+priorEntityContacts.priorSupportExternalId+'/revogacoes');
									}}>

									<FormattedMessage id='revocationForm.list.button' />
								</button>

							)
						}
					</Col>
				</Row>

				<PriorSupportMessageModal
					show={showPriorEntityMessageModal}
					setShow={setShowPriorEntityMessageModal}
					candidatureExternalId={candidatureExternalId}
				/>



			</Container>
		</SubTemplate>
	);
}
