import React from 'react';
import { Form } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { DocumentType } from '../../models/DocumentType';
import { Arrays } from '../../utils/Arrays';
import { MultipleUploadArea } from '../MultipleUploadArea';

export function AddExpirationApprovalDecisionForm({
  onSubmit,
  userDocuments,
  setDocuments,
  externalId
}) {
  return (
    <div className='mb-5'>
      <h2 className='mb-1 text-secondary'>
        <FormattedMessage id='candidature.submitExpirationApprovalDecision.title' />
      </h2>
      <p className='mb-5 text-secondary'>
        <FormattedMessage id='candidature.submitTermsOfAcceptDraft.addDocuments' />
      </p>

      <Form>
        <MultipleUploadArea
          documents={userDocuments}
          documentType={DocumentType.EXPIRATION_DECISION_APPROVAL}
          externalId={externalId}
          candidatureExternalId={externalId}
          setDocuments={setDocuments}
        />
        <div className='form-actions'>
          <Link to={`/candidatura/${externalId}`}>
            <button className='btn btn-link' type='button'>
              <FormattedMessage id='all.backButtonText' />
            </button>
          </Link>

          {Arrays.isNotEmpty(userDocuments) && (
            <button className='btn btn-primary' type='button' onClick={onSubmit}>
              <FormattedMessage id='all.submitButtonText' />
            </button>
          )}
        </div>
      </Form>
    </div>
  );
}
