import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { AlertError } from '../../components/bootstrap/AlertError';
import Loading from '../../components/general/Loading';
import { SubTemplate } from '../../components/general/SubTemplate';
import { getCandidatureByExternalId, rejectContestation,  sendToOpinionReview } from '../../rest/candidature';
import { listDocumentsByTypeAndCandidatureExternalId } from '../../rest/document';
import { handleError, isNotBusinessError } from '../../utils/handleError';
import {ContestationAnalysisExpirationForm} from "../../components/candidature/ContestationAnalysisExpirationForm";


export function CandidatureContestationAnalysisExpiration() {

  const { externalId } = useParams();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [userDocuments, setUserDocuments] = useState([]);
  const [stateContestationAnalysis, setStateContestationAnalysis] = useState(false);
  const [contestationMotive, setContestationMotive] = useState(null);

  async function fetchData() {
    try {
      const [
        { data: userDocuments }
      ] = await Promise.all([
        await listDocumentsByTypeAndCandidatureExternalId('CONTESTATION_EXPIRATION', externalId)
      ]);
      setUserDocuments(userDocuments);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  }

  async function checkCandidatureState() {
    try {
      const [{ data: candidature }] = await Promise.all([
        await getCandidatureByExternalId(externalId),
      ]);
      if (candidature.state === 'CONTESTATION_ANALYSIS_EXPIRATION') {
        setStateContestationAnalysis(true);
        setContestationMotive(candidature.contestationMotive);
        fetchData();
      } else {
        setLoading(false);
      }
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  }

  useEffect(() => {
    checkCandidatureState();
  }, []);







  if (loading) {
    return <Loading />;
  }

  if (error && isNotBusinessError(error)) {
    return handleError(error);
  }

  return (
    <SubTemplate  hasBackButton
      titleId='candidature.title.contestationAnalysisExpiration'>
      <Container>
        <AlertError error={error} />
        {stateContestationAnalysis && <ContestationAnalysisExpirationForm
          userDocuments={userDocuments}
          externalId={externalId}
          contestationMotive={contestationMotive}
        />}
      </Container>
    </SubTemplate>
  );
}
