export const RevocationDirection = {
  TO_ENTITY: 'TO_ENTITY',
  FROM_ENTITY: 'FROM_ENTITY',




values: () => {
    return [
      'TO_ENTITY',
      'FROM_ENTITY'
    ];
  },


};
