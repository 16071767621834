import {CandidaturePaymentPurpose} from "../../models/CandidaturePaymentPurpose";

let INVESTMENT = CandidaturePaymentPurpose.INVESTMENT;
let JOB_CREATION = CandidaturePaymentPurpose.JOB_CREATION;

function getFinalFinancialDetails(candidature) {
    return candidature.financialInvestmentDetails
        .filter(f => f.kind === 'FINAL' && 'financialInvestmentType' in f && f.financialInvestmentType === 'TOTAL')[0];
}
export function calculateTotals(candidature, payments) {
    let finalFinancialDetail = getFinalFinancialDetails(candidature);
    return {
        'approved': {
            [INVESTMENT]: finalFinancialDetail.investmentSupport,
            [JOB_CREATION]: finalFinancialDetail.workStationsCreationBenefits
        },
        'paid': {
            [INVESTMENT]: payments.filter(p => p.purpose === INVESTMENT).map(p => p.value).reduce((a, b) => a + b, 0),
            [JOB_CREATION]: payments.filter(p => p.purpose === JOB_CREATION).map(p => p.value).reduce((a, b) => a + b, 0)
        }
    };
}

export function requestedUnemploymentBenefitsPayment(candidature) {
    return getFinalFinancialDetails(candidature).ownCapitalUnemploymentBenefits > 0;
}

export function hasUnemploymentBenefitsRecord(candidature) {
    return 'unemploymentDelegationRecord' in candidature;
}
export function canAddUnemploymentBenefitsPaymentRecord(candidature) {
    return requestedUnemploymentBenefitsPayment(candidature) && !hasUnemploymentBenefitsRecord(candidature);
}