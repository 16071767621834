import { AmortizationTopic } from './AmortizationTopic';

export const AmortizationComponent = {
  getComponentsByTopic: (topic) => getComponentsByTopic(topic)
};

function getComponentsByTopic(topic) {
  switch (topic) {
    case AmortizationTopic.INTANGIBLE_ASSETS:
      return [
        'GOODWILL',
        'DEVELOPMENT_PROJECT',
        'SOFTWARE',
        'INDUSTRIAL_PROPERTY',
        'OTHER_INTANGIBLE'
      ];

    case AmortizationTopic.TANGIBLE_ASSETS:
      return [
        'LAND_NATURAL_RESOURCE_TANGIBLE',
        'BUILDING_CONSTRUCTION_TANGIBLE',
        'BASIC_MATERIAL',
        'TRANSPORT_MATERIAL',
        'ADMIN_MATERIAL',
        'BIOLOGIC_MATERIAL',
        'OTHER_TANGIBLE'
      ];

    case AmortizationTopic.INVESTIMENT_PROPERTY:
      return [
        'LAND_NATURAL_RESOURCE_INVESTMENT',
        'BUILDING_CONSTRUCTION_INVESTMENT',
        'OTHER_INVESTMENT'
      ];

    default:
      break;
  }
}
