import { createCustomErrorMessage } from '../hooks/errorMessage';
import {DocumentType} from "../models/DocumentType";

export const EaAccreditationFormCustomValidator = {
	validateEmailsNifsAndNipc: (formikValues, setError, intl,errorMessages) =>
		validateEmailsNifsAndNipc(formikValues, setError, intl,errorMessages),
	validateGETListSize: (formikValues, setError, intl,errorMessages) =>
		validateGETListSize(formikValues, setError, intl,errorMessages),
	validateTGPListSize: (formikValues, setError, intl, errorMessages) =>
		validateTGPListSize(formikValues, setError, intl,errorMessages),
	validateGETAndDistricts: (formikValues, setError, intl, errorMessages, setFieldError, districtList) =>
		validateGETAndDistricts(formikValues, setError, intl, errorMessages, setFieldError, districtList),
	validateSsAndAtAuthorizations: (
		formikValues,
		entityAccreditationDocuments,
		setError,
		intl,errorMessages
	) =>
		validateSsAndAtAuthorizations(
			formikValues,
			entityAccreditationDocuments,
			setError,
			intl,errorMessages
		),
	validateRenewalDocuments: (formikValues, renewalDocuments, setError, intl, errorMessages) =>
		validateRenewalDocuments(formikValues, renewalDocuments, setError, intl, errorMessages),
};

function validateEmailsNifsAndNipc(formikValues, setError, intl,errorMessages) {
	let emails = [formikValues.entity.email];
	let nifs = [formikValues.entity.nif];
	formikValues.getList.forEach((get) => emails.push(get.email));
	formikValues.tgpList.forEach((tgp) => nifs.push(tgp.hre.nif));

	const emailOccurrences = emails.reduce((acc, email) => {
		return acc[email] ? ++acc[email] : (acc[email] = 1), acc;
	}, {});

	const nifOccurrences = nifs.reduce((acc, nif) => {
		return acc[nif] ? ++acc[nif] : (acc[nif] = 1), acc;
	}, {});

	if (
		Object.values(emailOccurrences).filter(
			(numberOfOccurrences) => numberOfOccurrences > 1
		).length > 0 ||
		Object.values(nifOccurrences).filter(
			(numberOfOccurrences) => numberOfOccurrences > 1
		).length > 0
	) {
		errorMessages.push(intl.formatMessage({
			id: 'errors.eaAccreditationForm.inconsistentNifsAndEmails',
		}));
		return false;
	}

	return true;
}

function validateGETListSize(formikValues, setError, intl,errorMessages) {
	if (formikValues.getList.length === 0) {
		errorMessages.push(intl.formatMessage({
			id: 'errors.eaAccreditationForm.getList',
		}));
		return false;
	}
	return true;
}

function validateTGPListSize(formikValues, setError, intl,errorMessages) {
	if (formikValues.tgpList.length === 0) {
		errorMessages.push(intl.formatMessage({
			id: 'errors.eaAccreditationForm.tgpList',
		}));
		return false;
	}
	return true;
}

function validateSsAndAtAuthorizations(
	formikValues,
	entityAccreditationDocuments,
	setError,
	intl,errorMessages
) {
	if (!formikValues.entity.atDeclarationAuthorized) {
		if (
			entityAccreditationDocuments.filter(
				(d) => d.documentType === 'AT_DECLARATION'
			)[0].submited === false
		) {
			errorMessages.push(intl.formatMessage({
				id: 'errors.eaAccreditationForm.ssAndAt',
			}))

			return false;
		}
	}

	if (!formikValues.entity.ssDeclarationAuthorized) {
		if (
			entityAccreditationDocuments.filter(
				(d) => d.documentType === 'SS_DECLARATION'
			)[0].submited === false
		) {
			errorMessages.push(intl.formatMessage({
				id: 'errors.eaAccreditationForm.ssAndAt',
			}))
			return false;
		}
	}

	return true;
}

function districtToDelegationMap(districtList) {
	let map = {};
	districtList.forEach(d => map[d.code] = d.regionalDelegationCode);
	return map;
}

function validateGETAndDistricts(formikValues, setError, intl, errorMessages, setFieldError, districtList) {
	let d2RDMap = districtToDelegationMap(districtList);
	let getRDSet = new Set(formikValues.getList.map(get => get.regionalDelegationCode));
	let disRDSet = new Set(formikValues.interventionDistrictCodes.map(d => d2RDMap[d]));

	let getError = false;
	let disError = false;

	getRDSet.forEach( get => {
		if( !disRDSet.has(get) ) {
			setFieldError(get, intl.formatMessage({id:'errors.eaAccreditationForm.GETWithoutDistrict'}));
			getError = true;
		}
	});

	formikValues.interventionDistrictCodes.forEach(dist => {
		if( !getRDSet.has(d2RDMap[dist]) ) {
			setFieldError(`interventionDistrictCodes.${dist}`, intl.formatMessage({id:'errors.eaAccreditationForm.DistrictWithoutGet'}));
			disError = true;
		}
	});

	if( getError ) {
		errorMessages.push(intl.formatMessage({id:'errors.eaAccreditationForm.GETWithoutDistrict'}));
	}

	if( disError ) {
		errorMessages.push(intl.formatMessage({id:'errors.eaAccreditationForm.DistrictWithoutGet'}));
	}

	if( getError || disError) return false;

	return true;
}


function validateRenewalDocuments(formikValues, renewalDocuments, setError, intl, errorMessages) {
	if (renewalDocuments.filter((d) => d.documentType === DocumentType.RENEWAL_INVITATION_LETTER)[0].submited === false) {
		errorMessages.push(intl.formatMessage({id: 'ea.accreditation.renewal.error.uploadDocuments'}))
		return false;
	}
	if (renewalDocuments.filter((d) => d.documentType === DocumentType.RENEWAL_ENTITY_RESPONSE_TO_INVITATION)[0].submited === false) {
		errorMessages.push(intl.formatMessage({id: 'ea.accreditation.renewal.error.uploadDocuments'}))
		return false;
	}
	if (renewalDocuments.filter((d) => d.documentType === DocumentType.RENEWAL_DIRECTIVE_COUNCIL_SERVICE_INFORMATION)[0].submited === false) {
		errorMessages.push(intl.formatMessage({id: 'ea.accreditation.renewal.error.uploadDocuments'}))
		return false;
	}
	if (renewalDocuments.filter((d) => d.documentType === DocumentType.RENEWAL_DIRECTIVE_COUNCIL_DELIBERATION)[0].submited === false) {
		errorMessages.push(intl.formatMessage({id: 'ea.accreditation.renewal.error.uploadDocuments'}))
		return false;
	}
	if (renewalDocuments.filter((d) => d.documentType === DocumentType.RENEWAL_NOTIFICATION_LETTER_AND_PROTOCOL)[0].submited === false) {
		errorMessages.push(intl.formatMessage({id: 'ea.accreditation.renewal.error.uploadDocuments'}))
		return false;
	}
	if (renewalDocuments.filter((d) => d.documentType === DocumentType.RENEWAL_SIGNED_PROTOCOL)[0].submited === false) {
		errorMessages.push(intl.formatMessage({id: 'ea.accreditation.renewal.error.uploadDocuments'}))
		return false;
	}
	return true;
}