import { FormattedMessage } from 'react-intl';
import {PromoterType} from "../models/PromoterType";
import {ConfigObject} from "../config";
import {currencyFormat, maxFixed} from "./CurrencyUtils";
import {CandidatureFinancialUtils} from "./CandidatureFinancialUtils";
import {DateUtils} from "./DateUtils";

export const CandidatureFormUtils = {
  getStep8RequiredDocumentTypes: (candidature, candidatureDocuments) =>
    getStep8RequiredDocumentTypes(candidature, candidatureDocuments),

  getStep8Documents: (candidature, candidatureDocuments) =>
    getStep8Documents(candidature, candidatureDocuments),

  getStep19RequiredDocumentTypes: (candidature, candidatureDocuments) =>
    getStep19RequiredDocumentTypes(candidature, candidatureDocuments),

  getStep19Documents: (candidature, candidatureDocuments) =>
    getStep19Documents(candidature, candidatureDocuments),

  is3YearsCoverageOnly: (values, year) => is3YearsCoverageOnly(values, year),
  isProjectTotalValueAboveLimitValue: (values) => isProjectTotalValueAboveLimitValue(values),
  getYearLabel: (values, yearIndex) => getYearLabel(values, yearIndex),
  canAskUnemploymentBenefits :(promoters) =>canAskUnemploymentBenefits(promoters)
};





function canAskUnemploymentBenefits(promoters){
 return promoters.filter((promoter)=> promoter.promoterType !== PromoterType.OTHER && promoter.promoterType !== '-1' && promoter.applyUnemploymentBenefits).length > 0;
}



function getStep8RequiredDocumentTypes(candidature, candidatureDocuments) {
  let requiredDocumentTypes = [
    'INVESTMENT_FINANCING_PLAN',
    'GOODS_CONSUMPTION',
    'EXTERNAL_SERVICES',
    'PERSONNEL_EXPENSES',
    'AMORTIZATION_EXPENSES',
    'FINANCIAL_CHARGES',
    'DEMONSTRATION_FORECASTED_RESULTS'
  ];

  if (candidature.candidatureFinancialDetails) {


    // if (
    //   candidature.candidatureFinancialDetails.applyUnemploymentBenefits === 'true' ||
    //   candidature.candidatureFinancialDetails.applyUnemploymentBenefits === true
    // ) {
    //   requiredDocumentTypes.push('DISTRICT_APPLICATION');
    // }

    if (
      candidatureDocuments.filter((cd) => cd.documentType === 'DISTRICT_APPLICATION').length > 0 ||
      candidature.promotersMetaData.filter((pm) => pm.applyUnemploymentBenefits).length > 0
    ) {
      requiredDocumentTypes.push('DISTRICT_APPLICATION');
    }
  }

  if (candidature.candidatureDetails) {
    if (
      candidature.candidatureDetails.artsAndCrafts === 'true' ||
      candidature.candidatureDetails.artsAndCrafts === true
    ) {
      requiredDocumentTypes.push('ARTISAN_STATUS_PROOF');
    }
  }

  requiredDocumentTypes.push('BUDGETS_INVOICES');

  return requiredDocumentTypes;
}

function getStep19RequiredDocumentTypes(candidature, candidatureDocuments) {


  let requiredDocumentTypes = [];




    if (
      candidatureDocuments.filter((cd) => cd.documentType === 'DISTRICT_APPLICATION').length > 0 ||
      candidature.promotersMetaData.filter((pm) => pm.applyUnemploymentBenefits).length > 0
    ) {
      requiredDocumentTypes.push('DISTRICT_APPLICATION');
    }

  if (candidature.candidatureDetails) {
    if (
      candidature.candidatureDetails.artsAndCrafts === 'true' ||
      candidature.candidatureDetails.artsAndCrafts === true
    ) {
      requiredDocumentTypes.push('ARTISAN_STATUS_PROOF');
    }
    if (candidature.candidatureDetails.wasCreated ===true) {
      requiredDocumentTypes.push('SUPPORTING_ACTIVITY_START','SOCIAL_PACT_CERTIFICATION','MINIMIS_DECLARATION');
    }

  }


  requiredDocumentTypes.push(
    'BUDGETS_INVOICES',
    'AUTHORIZED_SELLER_DECLARATION',
    'OWNERSHIP_COPY',
    'SPACE_ALLOCATION_PROOF',
    'CONSTRUCTIONS_AUTHORIZATION',
    'PROMISSORY_CONTRACT_COPY',
    'FRANCHISE_AGREEMENT_COPY',
    '8.7_DECLARATION',

  );

  return requiredDocumentTypes;
}

function getStep8Documents(candidature, candidatureDocuments) {
  let files = [];


  let candidatureDocumentsTypes = candidatureDocuments.map((cd) => cd.documentType);

  getStep8RequiredDocumentTypes(candidature, candidatureDocuments)
    .filter((rdt) => rdt !== 'DISTRICT_APPLICATION')
    .forEach((fdt) => {
      if (candidatureDocumentsTypes.includes(fdt)) {
        const document = candidatureDocuments.find((cd) => cd.documentType === fdt);
        files.push({
          externalId: document.externalId,
          documentType: document.documentType,
          documentPath: document.documentPath,
          name: document.name,
          content: '',
          submited: true,
          error: ''
        });


      } else {
        files.push({
          externalId: '',
          documentType: fdt,
          name: '',
          content: null,
          submited: false,
          error: ''
        });
      }
    });

  const districtApplicationDocuments = candidatureDocuments.filter(
    (cd) => cd.documentType === 'DISTRICT_APPLICATION'
  );

  districtApplicationDocuments.forEach((fd) => {
    files.push({
      externalId: fd.externalId,
      documentType: fd.documentType,
      documentPath: fd.documentPath,
      name: fd.name,
      fileDescription: fd.fileDescription,
      content: '',
      submited: true,
      error: ''
    });
  });

  return files;
}

function getStep19Documents(candidature, candidatureDocuments) {
  let files = [];
  let candidatureDocumentsTypes = candidatureDocuments.map((cd) => cd.documentType);


  getStep19RequiredDocumentTypes(candidature, candidatureDocuments)
    .filter((rdt) => rdt !== 'DISTRICT_APPLICATION')
    .forEach((fdt) => {

      if (candidatureDocumentsTypes.includes(fdt)) {
        const document = candidatureDocuments.find((cd) => cd.documentType === fdt);
        if (document.externalId !== '') {
          files.push({
            externalId: document.externalId,
            documentType: document.documentType,
            documentPath: document.documentPath,
            name: document.name,
            content: '',
            submited: true,
            error: ''
          });
        } else {
            files.push({
              externalId: '',
              documentType: fdt,
              name: '',
              content: null,
              submited: false,
              error: ''
            });
        }
      } else {
        files.push({
          externalId: '',
          documentType: fdt,
          name: '',
          content: null,
          submited: false,
          error: ''
        });
      }
    });



  const districtApplicationDocuments = candidatureDocuments.filter(
    (cd) => cd.documentType === 'DISTRICT_APPLICATION'
  );

  districtApplicationDocuments.forEach((fd) => {
    files.push({
      externalId: fd.externalId,
      documentType: fd.documentType,
      documentPath: fd.documentPath,
      name: fd.name,
      fileDescription: fd.fileDescription,
      content: '',
      submited: true,
      error: ''
    });
  });

  return files;
}

function is3YearsCoverageOnly(values, year) {
  const projectValue = values.investmentPlanDetails.find(
    (ipd) => ipd.investmentType === 'TOTAL'
  ).globalTotal;

  return projectValue < parseInt(ConfigObject.get().limitValueFor5Years) && year > 2;
}

function isProjectTotalValueAboveLimitValue(values) {
  return (
    values.investmentPlanDetails.find((ipd) => ipd.investmentType === 'TOTAL').globalTotal >= parseInt(ConfigObject.get().limitValueFor5Years)
  );
}

function getYearLabel(values, yearIndex) {
  if (values.candidatureDetails.forecastDate) {
    return (
      <span>
        {     DateUtils.removeDays(new Date(values.candidatureDetails.forecastDate),15).getFullYear() + yearIndex}
      </span>
    );
  } else {
    return <FormattedMessage id={`all.yearN${yearIndex}`} />;
  }
}
