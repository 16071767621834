import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { TextAreaInputField } from '../bootstrap/TextAreaInputField';
import { ToolTip } from '../bootstrap/ToolTip';

export function EconomicViabilityFormStep2({
  handleChange,
  errors,
  values,
  error,
  setError,
  numberOfSteps,
  handleSave,
  handleGoBack
}) {

  const intl = useIntl();

  return (
    <div>
      <h4 className='text-primary mb-0'>
        <FormattedMessage id='economicViabilityForm.evaluationTab' />
      </h4>
      <small className='text-secondary text-uppercase d-block'>
        {intl.formatMessage(
          { id: 'all.step' },
          { step: values.step, numberOfSteps: numberOfSteps }
        )}
      </small>

      <Row>
        <small className='text-primary text-uppercase mt-4'>
          <FormattedMessage id='economicViabilityFormStep2.businessIdeaTitle' />
        </small>
        <Col md='12'>
          <TextAreaInputField
            labelId={'economicViabilityFormStep2.projectDescription'}
            name={'projectDescription'}
            value={values.projectDescription}
            handleChange={handleChange}
            toolTip={
              <ToolTip
                message={
                  <FormattedMessage id='economicViabilityFormStep2.projectDescription.toolTip' />
                }
              />
            }
            isInvalid={errors.projectDescription}
            errorMessage={errors.projectDescription}
          />
        </Col>
        <Col md='12'>
          <TextAreaInputField
            labelId={'economicViabilityFormStep2.projectLocalization'}
            name={'projectLocalization'}
            value={values.projectLocalization}
            handleChange={handleChange}
            toolTip={
              <ToolTip
                message={
                  <FormattedMessage id='economicViabilityFormStep2.projectLocalization.toolTip' />
                }
              />
            }
            isInvalid={errors.projectLocalization}
            errorMessage={errors.projectLocalization}
          />
        </Col>
        <Col md='12'>
          <TextAreaInputField
            labelId={'economicViabilityFormStep2.projectInstallations'}
            name={'projectInstallations'}
            value={values.projectInstallations}
            handleChange={handleChange}
            toolTip={
              <ToolTip
                message={
                  <FormattedMessage id='economicViabilityFormStep2.projectInstallations.toolTip' />
                }
              />
            }
            isInvalid={errors.projectInstallations}
            errorMessage={errors.projectInstallations}
          />
        </Col>
        <Col md='12'>
          <TextAreaInputField
            labelId={'economicViabilityFormStep2.projectEquipments'}
            name={'projectEquipments'}
            value={values.projectEquipments}
            handleChange={handleChange}
            toolTip={
              <ToolTip
                message={
                  <FormattedMessage id='economicViabilityFormStep2.projectEquipments.toolTip' />
                }
              />
            }
            isInvalid={errors.projectEquipments}
            errorMessage={errors.projectEquipments}
          />
        </Col>
      </Row>

      <Row>
        <small className='text-primary text-uppercase mt-5 mb-4'>
          <FormattedMessage id='economicViabilityFormStep2.innovativeCharacter' />
          <ToolTip
            message={
              <FormattedMessage id='economicViabilityFormStep2.innovativeCharacter.toolTip' />
            }
          />
        </small>

        <TextAreaInputField
          name={'innovativeCharacter'}
          value={values.innovativeCharacter}
          handleChange={handleChange}
          isInvalid={errors.innovativeCharacter}
          errorMessage={errors.innovativeCharacter}
        />
      </Row>

      <Row>
        <small className='text-primary text-uppercase mt-5 mb-4'>
          <FormattedMessage id='economicViabilityFormStep2.promotersProfileEvaluation' />
          <ToolTip
            message={
              <FormattedMessage id='economicViabilityFormStep2.promotersProfileEvaluation.toolTip' />
            }
          />
        </small>
        <TextAreaInputField
          name={'promotersProfileEvaluation'}
          value={values.promotersProfileEvaluation}
          handleChange={handleChange}
          isInvalid={errors.promotersProfileEvaluation}
          errorMessage={errors.promotersProfileEvaluation}
        />
      </Row>
    </div>
  );
}
