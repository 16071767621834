import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { FaInfoCircle } from 'react-icons/fa';
import { FormattedMessage } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { AlertError } from '../../components/bootstrap/AlertError';
import PriorSuppotSubmissionModal from '../../components/candidature/PriorSuppotSubmissionModal';
import Loading from '../../components/general/Loading';
import { SubTemplate } from '../../components/general/SubTemplate';
import { PromoterType } from '../../models/PromoterType';
import {
  getCountiesByDistrictCode,
  getDistricts,
  getParishesByCountyCode
} from '../../rest/administrativeDivision';
import {
  askPriorSupport,
  getUserCandidature,
  saveCandidature
} from '../../rest/candidature';
import { getPriorEntitiesByDistrictCode } from '../../rest/entity';
import { getPromoter } from '../../rest/promoter';
import { handleError, isNotBusinessError } from '../../utils/handleError';
import { useGlobalContext } from '../../context';

export function PriorSupport() {
  const navigate = useNavigate();

  // get variables sent (in react-router-dom's navigate state variable) from the previous page
  const { parish, step } = useLocation().state;

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [districtEntities, setDistrictEntities] = useState([]);
  const [candidature, setCandidature] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [counties, setCounties] = useState([]);
  const [parishes, setParishes] = useState([]);
  const [submissionModal, setSubmissionModal] = useState({
    show: false,
    values: {},
    formikObj: {},
  });

  const {
    setHasPriorSupport,
  } = useGlobalContext();


  async function fetchData() {
    try {



      const [
        { data: promoter },
        { data: candidature },
        { data: districts },
      ] = await Promise.all([
        await getPromoter(),
        await getUserCandidature(),
        await getDistricts(),
      ]);

      let candidatureInitial = null;

      if (!candidature) {
        const { data: newCandidature } = await saveCandidature(
          getCandidatureRequestBody(promoter)
        );
        setCandidature(newCandidature);
        candidatureInitial = newCandidature;
      } else {
        setCandidature(candidature);
        candidatureInitial= candidature;
      }

      let params = { districtCode: parish.substring(0, 2) , type:'PRIOR',candidatureExternalId: candidatureInitial.externalId};
      const [
        { data: entitiesByDistrictList },
      ] = await Promise.all([
        await getPriorEntitiesByDistrictCode(params),
      ]);






      if (parish !== '-1') {
        fetchCounties(parish.substring(0, 2));
        fetchParishes(parish.substring(0, 4));
      }

      setDistricts(districts);
      setDistrictEntities(entitiesByDistrictList);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  function goBackToAddCandidaturePage() {
    //redirect to add candidature page on the form's section the user was
    navigate('/candidatura/adicionar', { state: { step: step } });
  }

  async function handleSubmit() {
    const values = submissionModal.values;
    try {
      //set entityCandidature structure and its values
      let reqBody = {
        nif: values.entity,
        candidatureExternalId: candidature.externalId,
        parish: values.parish,
        multiPromoters: values.multiPromoters==='true'
      };

      let res = await askPriorSupport(candidature.externalId, reqBody);

      submissionModal.formikObj.setSubmitting(false);

      //If the request was sucessful
      if (res.status > 199 && res.status < 300) {
        //redirect to add candidature page
        sessionStorage.setItem('priorSupportAsked','true');
        setHasPriorSupport(true);
        goBackToAddCandidaturePage();
      }
    } catch (error) {
      setError(error);
    }
  }

  function getInitialFormikValues() {
    return {
      entity: '-1',
      district: parish !== '-1' ? parish.substring(0, 2) : '-1',
      county: parish !== '-1' ? parish.substring(0, 4) : '-1',
      parish: parish,
      multiPromoters:'-1'
    };
  }

  function handleDistrictChange(e, setFieldValue) {
    setFieldValue('county', '-1');
    setFieldValue('parish', '-1');
    setFieldValue('district', e.target.value);
    fetchCounties(e.target.value);
  }

  function handleCountyChange(e, setFieldValue) {
    setFieldValue('parish', '-1');
    setFieldValue('county', e.target.value);
    fetchParishes(e.target.value);
  }

  function handleParishChange(e, setFieldValue) {
    setFieldValue('parish', e.target.value);
    fetchPriorEntitiesByDistrictCode(e.target.value.substring(0, 2));
  }

  async function fetchPriorEntitiesByDistrictCode(districtCode) {
    try {
      let params = { districtCode: districtCode, type: 'PRIOR' , candidatureExternalId: candidature.externalId};

      const { data: priorEntities } = await getPriorEntitiesByDistrictCode(
        params
      );
      setDistrictEntities(priorEntities);
    } catch (error) {
      setError(error);
    }
  }

  async function fetchCounties(districtCode) {
    try {
      const { data: counties } = await getCountiesByDistrictCode(districtCode);
      setCounties(counties);
      setParishes([]);
    } catch (error) {
      setError(error);
    }
  }

  async function fetchParishes(countyCode) {
    try {
      const { data: parishes } = await getParishesByCountyCode(countyCode);
      setParishes(parishes);
    } catch (error) {
      setError(error);
    }
  }

  function getCandidatureRequestBody(promoter) {
    return {
      validate: false,
      setByStep: false,
      promotersMetaData: [
        {
          externalId: '',
          name: promoter.user.name,
          nif: promoter.nif,
          email: promoter.user.email,
          promoterType: PromoterType.PRIMARY,
        },
      ],
      candidatureDetails: {},
      candidatureFinancialDetails: [{ kind: 'PROMOTER' }],
      candidatureEmployeeSupportDetails: [],
    };
  }

  function openSubmissionModal(values, formikObj) {
    setSubmissionModal({ show: true, values: values, formikObj: formikObj });
  }

  if (loading) {
    return <Loading />;
  }

  if (error && isNotBusinessError(error)) {
    return handleError(error);
  }

  return (
    <SubTemplate>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-8 offset-lg-2 mb-5'>
            <AlertError error={error} />
            <div className='mb-5'>
              <h2 className='mb-2 text-secondary'>
                <FormattedMessage id='priorSupportForm.title' />
              </h2>
              <p className='text-secondary'>
                <FormattedMessage id='priorSupportForm.subTitle' />
              </p>
              <p className='text-secondary'>
                <FormattedMessage id='priorSupportForm.subTitle2' />
              </p>
              <p className='text-secondary'>
                <FormattedMessage id='priorSupportForm.subTitle3' />
              </p>
              <p className='mb-4 text-secondary'>
                <FormattedMessage id='priorSupportForm.subTitle4' />
              </p>
              <p className='mb-4 text-secondary'>
                <FormattedMessage id='priorSupportForm.subTitle5' /><b><FormattedMessage id='priorSupportForm.subTitle5.1' /></b><FormattedMessage id='priorSupportForm.subTitle5.2' /><b><FormattedMessage id='priorSupportForm.subTitle5.3' /></b>
              </p>

              <PriorSuppotSubmissionModal
                show={submissionModal.show}
                handleClose={() => setSubmissionModal({ show: false })}
                handleSubmit={handleSubmit}
              />

              <Formik
                initialValues={getInitialFormikValues()}
                validateOnBlur={false}
                validateOnChange={false}
                validationSchema={priorSupportFormSchema}
                onSubmit={(values, formikObj) => {
                  openSubmissionModal(values, formikObj);
                }}
              >
                {(formikProps) => (
                  <Form onSubmit={formikProps.handleSubmit}>
                    <div>
                      <Row>
                        <Col>
                          <h4 className='text-primary mb-0'>
                            Seleção de Entidade de Acompanhamento
                          </h4>
                          <p className='mt-3 text-secondary infoText'>
                            <FaInfoCircle size={40} /> Deve selecionar a
                            localização onde vai sediar/desenvolver o negócio.
                            Após a submissão do pedido a localização não poderá
                            ser alterada.
                          </p>
                        </Col>
                      </Row>
                      <Row>
                        <Col md='4'>
                          <Form.Group controlId={'district'}>
                            <Form.Label>
                              <FormattedMessage id='promoterForm.district' />
                            </Form.Label>
                            <Form.Control
                              as='select'
                              name={'district'}
                              onChange={(e) =>
                                handleDistrictChange(
                                  e,
                                  formikProps.setFieldValue
                                )
                              }
                              value={formikProps.values.district}
                              isInvalid={formikProps.errors.district}
                            >
                              <option value={'-1'}>
                                <FormattedMessage id='all.chooseOne' />
                              </option>
                              {districts.map((district) => (
                                <option
                                  key={district.code}
                                  value={district.code}
                                >
                                  {district.description}
                                </option>
                              ))}
                            </Form.Control>
                            <Form.Control.Feedback type='invalid'>
                              {formikProps.errors.district}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                        <Col md='4'>
                          <Form.Group controlId={'county'}>
                            <Form.Label>
                              <FormattedMessage id='promoterForm.county' />
                            </Form.Label>
                            <Form.Control
                              as='select'
                              name={'county'}
                              onChange={(e) =>
                                handleCountyChange(e, formikProps.setFieldValue)
                              }
                              value={formikProps.values.county}
                              isInvalid={formikProps.errors.county}
                              disabled={formikProps.values.district === '-1'}
                            >
                              <option value={'-1'}>
                                {formikProps.values.district !== '-1' ? (
                                  <FormattedMessage id='all.chooseOne' />
                                ) : (
                                  <FormattedMessage id='all.chooseOneDistrict' />
                                )}
                              </option>
                              {counties.map((county) => (
                                <option key={county.code} value={county.code}>
                                  {county.description}
                                </option>
                              ))}
                            </Form.Control>
                            <Form.Control.Feedback type='invalid'>
                              {formikProps.errors.county}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                        <Col md='4'>
                          <Form.Group controlId={'parish'}>
                            <Form.Label>
                              <FormattedMessage id='promoterForm.parish' />
                            </Form.Label>
                            <Form.Control
                              as='select'
                              name={'parish'}
                              onChange={(e) =>
                                handleParishChange(e, formikProps.setFieldValue)
                              }
                              value={formikProps.values.parish}
                              isInvalid={formikProps.errors.parish}
                              disabled={formikProps.values.county === '-1'}
                            >
                              <option value={'-1'}>
                                {formikProps.values.county !== '-1' ? (
                                  <FormattedMessage id='all.chooseOne' />
                                ) : (
                                  <FormattedMessage id='all.chooseOneCounty' />
                                )}
                              </option>
                              {parishes.map((parish) => (
                                <option key={parish.code} value={parish.code}>
                                  {parish.description}
                                </option>
                              ))}
                            </Form.Control>
                            <Form.Control.Feedback type='invalid'>
                              {formikProps.errors.parish}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                      </Row>

                      <Row>
                        <Col md={6}>
                          <Form.Group controlId={'priorSupportEntity'}>
                            <Form.Label>
                              <FormattedMessage id='priorSupportForm.entity' />
                            </Form.Label>
                            <Form.Control
                              as='select'
                              name={'entity'}
                              value={formikProps.values.entity}
                              onChange={formikProps.handleChange}
                              isInvalid={formikProps.errors.entity}
                              disabled={formikProps.values.parish === '-1'}
                            >
                              <option value={'-1'}>
                                {formikProps.values.parish !== '-1' ? (
                                  <FormattedMessage id='all.chooseOne' />
                                ) : (
                                  <FormattedMessage id='all.chooseOneParish' />
                                )}
                              </option>
                              {districtEntities.map((entity) => (
                                <option key={entity.nif} value={entity.nif}>
                                  {entity.description}
                                </option>
                              ))}
                            </Form.Control>
                            <Form.Control.Feedback type='invalid'>
                              {formikProps.errors.entity}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                        <Col md={6}>
                          <Form.Group controlId={'priorSupport.otherPromoters'}>
                            <Form.Label>
                              <FormattedMessage id='priorSupportForm.multiPromoters' />
                            </Form.Label>
                            <Form.Control
                                as='select'
                                name={'multiPromoters'}
                                value={formikProps.values.multiPromoters}
                                onChange={formikProps.handleChange}
                                isInvalid={formikProps.errors.multiPromoters}
                            >
                              <option value='-1'><FormattedMessage id={'all.select'}/></option>
                              <option value='true'><FormattedMessage id={'all.yes'}/></option>
                              <option value='false'><FormattedMessage id={'all.no'}/></option>
                            </Form.Control>
                            <Form.Control.Feedback type='invalid'>
                              {formikProps.errors.multiPromoters}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                      </Row>

                      <div className='form-actions mt-5'>
                        <button
                          className='btn btn-link'
                          type='button'
                          onClick={goBackToAddCandidaturePage}
                        >
                          <FormattedMessage id='all.back' />
                        </button>

                        <button className='btn btn-primary' type='submit'>
                          <FormattedMessage id='all.submitButtonText' />
                        </button>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </SubTemplate>
  );
}

const priorSupportFormSchema = yup.object().shape({
  entity: yup
    .string()
    .test(
      'isValidEntity',
      <FormattedMessage id='errors.priorSupportForm.entity' />,
      (value) => value !== '-1'
    ),
  multiPromoters: yup
      .string()
      .test(
          'isValidMultiPromoters',
          <FormattedMessage id='errors.priorSupportForm.multiPromoters' />,
          (value) => value !== '-1'
      ),
  district: yup
    .string()
    .test(
      'isValidDistrict',
      <FormattedMessage id='errors.promoterForm.district' />,
      (value) => value !== '-1'
    ),

  county: yup
    .string()
    .test(
      'isValidCounty',
      <FormattedMessage id='errors.promoterForm.county' />,
      (value) => value !== '-1'
    ),

  parish: yup
    .string()
    .test(
      'isValidParish',
      <FormattedMessage id='errors.promoterForm.parish' />,
      (value) => value !== '-1'
    ),
});
