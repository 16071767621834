import { differenceInYears } from 'date-fns';
import { Formik } from 'formik';
import React from 'react';
import { Form } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import * as yup from 'yup';
import { IdentificationType } from '../../models/IdentificationType';
import { Validator } from '../../utils/Validator';
import { PromoterFormStep1 } from './PromoterFormStep1';
import { PromoterFormStep2 } from './PromoterFormStep2';
import { PromoterFormStep3 } from './PromoterFormStep3';

export function PromoterForm({
  promoter,
  profiles,
  countries,
  districts,
  counties,
  parishes,
  userDocuments,
  schoolQualifications,
  handleDistrictChange,
  handleCountyChange,
  onSubmit,
  savePromoter,
  setError,
  numberOfSteps
}) {
  return (
    <div className='mb-5'>
      <h2 className='mb-1 text-secondary'>
        <FormattedMessage id='promoterForm.title' />
      </h2>
      <p className='mb-5 text-secondary'>
        <FormattedMessage id='promoterForm.subTitle' />
      </p>

      <Formik
        initialValues={promoter}
        validateOnBlur={false}
        validateOnChange={false}
        validationSchema={promoterFormSchema}
        onSubmit={(values, { setSubmitting, setErrors }) => {
          onSubmit(values, setSubmitting, setErrors);
        }}>
        {(formikProps) => (
          <Form onSubmit={formikProps.handleSubmit}>
            {renderStep(
              { ...formikProps },
              profiles,
              countries,
              districts,
              counties,
              parishes,
              userDocuments,
              schoolQualifications,
              handleDistrictChange,
              handleCountyChange,
              savePromoter,
              setError,
              numberOfSteps
            )}
          </Form>
        )}
      </Formik>
    </div>
  );
}

function renderStep(
  props,
  profiles,
  countries,
  districts,
  counties,
  parishes,
  userDocuments,
  schoolQualifications,
  handleDistrictChange,
  handleCountyChange,
  savePromoter,
  setError,
  numberOfSteps
) {
  switch (props.values.step) {
    case 1:
      return (
        <PromoterFormStep1
          {...props}
          values={props.values}
          profiles={profiles}
          countries={countries}
          districts={districts}
          counties={counties}
          parishes={parishes}
          onDistrictChange={handleDistrictChange}
          onCountyChange={handleCountyChange}
          savePromoter={savePromoter}
          numberOfSteps={numberOfSteps}
        />
      );
    case 2:
      return (
        <PromoterFormStep2
          {...props}
          values={props.values}
          countries={countries}
          schoolQualifications={schoolQualifications}
          savePromoter={savePromoter}
          numberOfSteps={numberOfSteps}
        />
      );
    case 3:
      return (
        <PromoterFormStep3
          {...props}
          values={props.values}
          userDocuments={userDocuments}
          savePromoter={savePromoter}
          setError={setError}
          numberOfSteps={numberOfSteps}
        />
      );
    default:
      return (
        <PromoterFormStep1
          {...props}
          values={props.values}
          profiles={profiles}
          countries={countries}
          districts={districts}
          counties={counties}
          parishes={parishes}
          onDistrictChange={handleDistrictChange}
          onCountyChange={handleCountyChange}
          savePromoter={savePromoter}
          numberOfSteps={numberOfSteps}
        />
      );
  }
}

const promoterFormSchema = yup.object().shape({
  step: yup.number(),
  profile: yup.object().shape({
    code: yup.string().required(<FormattedMessage id='errors.fieldRequiredText' />)
  }),
  country: yup.mixed().when('profile', {
    is: (profile) => profile.code === 'D',
    then: yup
      .string()
      .test(
        'isValidCountry',
        <FormattedMessage id='errors.promoterForm.country' />,
        (value) => value !== '-1'
      )
  }),
  city: yup.mixed().when('profile', {
    is: (profile) => profile.code === 'D',
    then: yup.string().required(<FormattedMessage id='errors.fieldRequiredText' />)
  }),
  address: yup.string().required(<FormattedMessage id='errors.fieldRequiredText' />),
  district: yup.mixed().when('profile', {
    is: (profile) => profile.code !== 'D',
    then: yup
      .string()
      .test(
        'isValidDistrict',
        <FormattedMessage id='errors.promoterForm.district' />,
        (value) => value !== '-1'
      )
  }),
  county: yup.mixed().when('profile', {
    is: (profile) => profile.code !== 'D',
    then: yup
      .string()
      .test(
        'isValidCounty',
        <FormattedMessage id='errors.promoterForm.county' />,
        (value) => value !== '-1'
      )
  }),
  parish: yup.mixed().when('profile', {
    is: (profile) => profile.code !== 'D',
    then: yup
      .string()
      .test(
        'isValidParish',
        <FormattedMessage id='errors.promoterForm.parish' />,
        (value) => value !== '-1'
      )
  }),
  postalCode: yup.mixed().when('profile', {
    is: (profile) => profile.code !== 'D',
    then: yup
      .string()
      .required(<FormattedMessage id='errors.fieldRequiredText' />)
      .test(
        'isValidPostCode',
        <FormattedMessage id='errors.postalCode'/>,
        (postalCode) => new RegExp(/^\d{4}-\d{3}$/).test(postalCode)
      ),
  }),
  birthDate: yup.mixed().when('step', {
    is: 2,
    then: yup
      .date().transform(v => (v instanceof Date && !isNaN(v) ? v : undefined))
      .test(
        'isAtLeast18YearOld',
        <FormattedMessage id='errors.promoterForm.birthDate' />,
        (value) => differenceInYears(new Date(), new Date(value)) >= 18
      )
  }),
  gender: yup.mixed().when('step', {
    is: 2,
    then: yup
      .string()
      .test(
        'isValidGender',
        <FormattedMessage id='errors.promoterForm.gender' />,
        (value) => value !== '-1'
      )
      .required(<FormattedMessage id='errors.fieldRequiredText' />)
  }),
  niss: yup.mixed().when('step', {
    is: 2,
    then: yup
      .string()
      .required(<FormattedMessage id='errors.fieldRequiredText' />)
      .test('isNissValid', <FormattedMessage id='errors.promoterForm.niss' />, (value) =>
        Validator.validateNiss(value)
      )
  }),
  nif: yup.mixed().when('step', {
    is: 2,
    then: yup
      .string()
      .required(<FormattedMessage id='errors.fieldRequiredText' />)
      .test('isNifValid', <FormattedMessage id='errors.promoterForm.nif' />, (value) =>
        Validator.validateNif(value)
      )
  }),
  identificationType: yup.mixed().when('step', {
    is: 2,
    then: yup
      .string()
      .test(
        'isValidGender',
        <FormattedMessage id='errors.promoterForm.identificationType' />,
        (value) => value !== '-1'
      )
      .required(<FormattedMessage id='errors.fieldRequiredText' />)
  }),
  identificationNumber: yup.mixed().when(['step', 'identificationType'], {
    is: (step, identificationType) =>
      step === 2 &&
      (identificationType === IdentificationType.CITIZEN_CARD),
    then: yup
      .string()
      .required(<FormattedMessage id='errors.fieldRequiredText' />)
      .test(
        'isCCValid',
        <FormattedMessage id='errors.promoterForm.identificationNumber' />,
        (value) => {
          try {
            return Validator.validateId(value);
          } catch (error) {
            return false;
          }
        }
      ),
    otherwise: yup.mixed().when(['step', 'identificationType'], {
      is: (step, identificationType) =>
          step === 2 &&
          (identificationType === IdentificationType.IDENTITY_CARD),
      then: yup
          .string()
          .required(<FormattedMessage id='errors.fieldRequiredText' />)
          .test(
              'isCCValid',
              <FormattedMessage id='errors.promoterForm.identificationNumber2' />,
              (value) => {
                try {
                  return Validator.validateId(value);
                } catch (error) {
                  return false;
                }
              }
          ),
      otherwise: yup.mixed().when(['step', 'identificationType'], {
        is: 2,
        then: yup.string().required(<FormattedMessage id='errors.fieldRequiredText' />)
      })
    })
  }),
  emissionDate: yup.mixed().when(['step', 'identificationType'], {
    is: (step, identificationType) =>
      step === 2 && identificationType === IdentificationType.IDENTITY_CARD,
    then: yup.date().required(<FormattedMessage id='errors.fieldRequiredText' />).transform(v => (v instanceof Date && !isNaN(v) ? v : undefined)),
  }),
  identificationValidity: yup.mixed().when('step', {
    is: 2,
    then: yup.date().required(<FormattedMessage id='errors.fieldRequiredText' />).transform(v => (v instanceof Date && !isNaN(v) ? v : undefined)),
  }),
  nationality: yup.mixed().when('step', {
    is: 2,
    then: yup
      .string()
      .test(
        'isValidNationality',
        <FormattedMessage id='errors.promoterForm.nationality' />,
        (value) => value !== '-1'
      )
      .required(<FormattedMessage id='errors.fieldRequiredText' />)
  }),
  naturality: yup.mixed().when('step', {
    is: 2,
    then: yup.string().required(<FormattedMessage id='errors.fieldRequiredText' />)
  }),
  schoolQualification: yup.mixed().when('step', {
    is: 2,
    then: yup
      .string()
      .test(
        'isValidSchoolQualification',
        <FormattedMessage id='errors.promoterForm.schoolQualification' />,
        (value) => value !== '-1'
      )
      .required(<FormattedMessage id='errors.fieldRequiredText' />)
  }),
});
