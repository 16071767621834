import {Formik} from 'formik';
import React from 'react';
import {Button, Form, Modal, Row} from 'react-bootstrap';
import {FormattedMessage, useIntl} from 'react-intl';
import {FaInfoCircle} from 'react-icons/fa';
import Col from "react-bootstrap/Col";
import parse from 'html-react-parser';

export function InterestExpressionDialog({
                                             possibleEntities,
                                             show,
                                             handleClose,
                                             handleSubmit
                                         }) {

    const intl = useIntl();
    return (
        <Modal show={show} onHide={handleClose} dialogClassName={'modal-6'}>
            <Modal.Header closeButton>
                <Modal.Title>
                    <FormattedMessage id={`interestExpressions.add`}/>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Formik
                    initialValues={{entityNif: '-1'}}
                    validateOnBlur={false}
                    validateOnChange={false}
                    onSubmit={(values, {setSubmitting, setErrors}) => {
                        handleSubmit(values, setSubmitting, setErrors);
                    }}
                >
                    {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleSubmit,
                          setFieldValue,
                      }) => (
                        <form onSubmit={handleSubmit}>
                            <p className='mt-3 mb-3 text-secondary'>
                                { parse(intl.formatMessage(
                                { id: 'interestExpressions.add.infoText' }
                            ))}
                            </p>
                            <Form.Group controlId='entityNif'>
                                <Form.Select
                                    as='select'
                                    name='entityNif'
                                    value={values.entityNif}
                                    onChange={handleChange}
                                >
                                    <option value={-1}>
                                        <FormattedMessage id={'interestExpressions.entities.all'}/>
                                    </option>
                                    {possibleEntities.map((possibleEntity) => (
                                        <option key={possibleEntity.nif} value={possibleEntity.nif}>
                                            {possibleEntity.description}
                                        </option>
                                    ))}
                                </Form.Select>
                            </Form.Group>

                            <Row className='form-actions'>
                                <Col className='justify-content-between d-flex'>
                                    <button className='btn btn-outline-primary' type='button' onClick={handleClose}>
                                        <FormattedMessage id='all.cancel'/>
                                    </button>
                                    <Button variant='primary' type='submit'>
                                        <FormattedMessage id='all.submitButtonText'/>
                                    </Button>
                                </Col>
                            </Row>
                        </form>
                    )}
                </Formik>
            </Modal.Body>
        </Modal>
    );
}
