import {useKeycloak} from '@react-keycloak/web';
import React, { useEffect } from 'react';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import {hasRole} from './authentication/authenticationHelper';
import {CandidatureHistoric} from './components/candidature/CandidatureHistoric';
import Loading from './components/general/Loading';
import {ScrollToTop} from './components/general/ScrollToTop';
import {Template} from './components/general/Template';
import {AddActionNotification} from './pages/backoffice/actionNotification/AddActionNotification';
import {EditActionNotification} from './pages/backoffice/actionNotification/EditActionNotification';
import {ListActionNotifications} from './pages/backoffice/actionNotification/ListActionNotifications';
import {ValidateCandidatureApprovedRequiredDocuments} from './pages/backoffice/candidature/ValidateCandidatureApprovedRequiredDocuments';
import {HomeEntity} from './pages/backoffice/entity/HomeEntity';
import {ViewCooperationProtocol} from './pages/backoffice/entity/ViewCooperationProtocol';
import {UploadNib} from './pages/backoffice/entity/UploadNib';
import {Holidays} from './pages/backoffice/holidays/Holidays';
import {MessageDetails} from './pages/backoffice/messages/MessageDetails';
import {MessagesNotification} from './pages/backoffice/messages/MessagesNotification';
import {AddParameter} from './pages/backoffice/parameters/AddParameter';
import {EditParameter} from './pages/backoffice/parameters/EditParameter';
import {ListParameters} from './pages/backoffice/parameters/ListParameters';
import {EntityUpload} from './pages/backoffice/users/EntityUpload';
import {AddActivitySheet} from './pages/public/AddActivitySheet';
import {AddAnnualComplementaryActivitiesPlan} from './pages/public/AddAnnualComplementaryActivitiesPlan';
import {AddCandidature} from './pages/public/AddCandidature';
import {AddComplementaryActivitiesPaymentRequest} from './pages/public/AddComplementaryActivitiesPaymentRequest';
import {AddDeclarationEndFirstYear} from './pages/public/AddDeclarationEndFirstYear';
import {AddDevelopmentPlan} from './pages/public/AddDevelopmentPlan';
import {AddEAEvaluation} from './pages/public/AddEAEvaluation';
import {AddEconomicViability} from './pages/public/AddEconomicViability';
import {AddFinalPaymentRequest} from './pages/public/AddFinalPaymentRequest';
import {AddFinalReport} from './pages/public/AddFinalReport';
import {AddInvestmentConfirmation} from './pages/public/AddInvestmentConfirmation';
import {AddProjectIdentification} from './pages/public/AddProjectIdentification';
import {AddSemestralPaymentRequest} from './pages/public/AddSemestralPaymentRequest';
import {AddSemestralReport} from './pages/public/AddSemestralReport';
import {AddUserData} from './pages/public/AddUserData';
import {ApproveCandidature} from './pages/public/ApproveCandidature';
import {CancelExpirationFinalDecision} from './pages/public/CancelExpirationFinalDecision';
import {Candidature} from './pages/public/Candidature';
import {CandidatureApprovedSubmitRequiredDocuments} from './pages/public/CandidatureApprovedSubmitRequiredDocuments';
import {CandidatureContestation} from './pages/public/CandidatureContestation';
import {CandidatureContestationAnalysis} from './pages/public/CandidatureContestationAnalysis';
import {CandidatureContestationAnalysisExpiration} from './pages/public/CandidatureContestationAnalysisExpiration';
import {CandidatureContestationAnalysisPartialApproval} from './pages/public/CandidatureContestationAnalysisPartialApproval';
import {CandidatureFinalDecision} from './pages/public/CandidatureFinalDecision';
import {CandidatureSubmitArtsCraftsOpinion} from './pages/public/CandidatureSubmitArtsCraftsOpinion';
import {CandidatureSubmitContractPriorSupport} from './pages/public/CandidatureSubmitContractPriorSupport';
import {CandidatureSubmitExpirationApprovalDecision} from './pages/public/CandidatureSubmitExpirationApprovalDecision';
import {CandidatureSubmitReviewPriorSupport} from './pages/public/CandidatureSubmitReviewPriorSupport';
import {CandidatureSubmitTermOfAccept} from './pages/public/CandidatureSubmitTermOfAccept';
import {CandidatureSubmitTermOfAcceptDraft} from './pages/public/CandidatureSubmitTermOfAcceptDraft';
import {ConsultingContacts} from './pages/public/ConsultingContacts';
import {DetailCandidature} from './pages/public/DetailCandidature';
import {DetailPriorSupport} from './pages/public/DetailPriorSupport';
import {EmitExpirationDecision} from './pages/public/EmitExpirationDecision';
import {EmitExpirationFinalDecision} from './pages/public/EmitExpirationFinalDecision';
import Home from './pages/public/Home';
import {ListCandidatures} from './pages/public/ListCandidatures';
import {ListContracts} from './pages/public/ListContracts';
import {CandidatureChange} from './pages/public/candidatureChange/CandidatureChange';
import {ListPriorSupportRequest} from './pages/public/ListPriorSupportRequest';
import {PriorSupport} from './pages/public/PriorSupport';
import {PriorSupportContacts} from './pages/public/PriorSupportContacts';
import {PriorSupportPaymentRequest} from './pages/public/PriorSupportPaymentRequest';
import {RejectCandidature} from './pages/public/RejectCandidature';
import {RequestConsulting} from './pages/public/RequestConsulting';
import {PrivateRoute} from './PrivateRoute';
import KeycloakAuthentication from './security/KeycloakAuthentication';
import Login from './security/Login';

import {ListProcesses} from './pages/backoffice/process/ListProcesses';
import {AddEaAccreditation} from './pages/public/AddEaAccreditation';
import {ListAnnualComplementaryActivitiesPlan} from './pages/backoffice/entity/ListAnnualComplementaryActivitiesPlan';
import {ListConsultingRequests} from './pages/backoffice/entity/ListConsultingRequests';
import {RequestConsultingDetail} from './pages/backoffice/entity/RequestConsultingDetail';
import {ListAnnualComplementaryActivitiesPaymentRequests} from './pages/backoffice/entity/ListAnnualComplementaryActivitiesPaymentRequests';

import {CandidatureContestationEligibility} from "./pages/public/CandidatureContestationEligibility";
import {AcceptEligibility} from "./pages/public/AcceptEligibility";
import {RejectEligibility} from "./pages/public/RejectEligibility";
import {ListContractListing} from './pages/backoffice/entity/ListContractListing';
import {HomeIEFP} from './pages/backoffice/iefp/HomeIEFP';
import {ListEntities} from './pages/backoffice/iefp/ListEntities';
import {DetailEntity} from './pages/public/DetailEntity';
import { ListPriorSupportPaymentRequest } from './pages/backoffice/entity/ListPriorSupportPaymentRequest';
import { ListEaEvaluation } from './pages/public/ListEaEvaluation';
import { EconomicViabilityPaymentRequest } from './pages/public/EconomicViabilityPaymentRequest';
import { ListEconomicViabilityPaymentRequest } from './pages/backoffice/entity/ListEconomicViabilityPaymentRequest';
import { ListEconomicViability } from './pages/backoffice/entity/ListEconomicViability';
import {OpinionEntityContacts} from "./pages/public/OpinionEntityContacts";
import { ListAnnualComplementaryActivitiesPlanStartCommunication } from './pages/backoffice/entity/ListAnnualComplementaryActivitiesPlanStartCommunication';
import { ComplementaryActivitiesStartCommunication } from './pages/public/ComplementaryActivitiesStartCommunication';
import {Indicators} from "./pages/backoffice/indicators/Indicators";
import { ListDevelopmentPlans } from './pages/backoffice/entity/ListDevelopmentPlans';
import {CandidaturePayments} from "./pages/backoffice/iefp/CandidaturePayments";
import {PaymentRegistration} from "./components/candidaturePayments/PaymentRegistration";
import { ListActivitySheet } from './pages/backoffice/entity/ListActivitySheet';
import {Oversight} from "./components/oversight/Oversight";
import {IrregularityDetail} from "./components/oversight/tabs/irregularities/IrregularityDetail";
import {NonComplianceDetail} from "./components/oversight/tabs/noncompliance/NonComplianceDetail";
import { ListCandidatureChanges } from './pages/public/candidatureChange/ListCandidatureChanges';
import { DetailCandidatureChange } from './pages/public/candidatureChange/DetailCandidatureChange';
import { CandidatureChangeRequestApproval } from './pages/public/candidatureChange/CandidatureChangeRequestApproval';
import {CandidatureChangeSubmitTermOfAccept} from "./pages/public/CandidatureChangeSubmitTermOfAccept";
import {CandidatureAddExtraDocuments} from "./pages/public/CandidatureAddExtraDocuments";
import { ListSemestralReport } from './pages/backoffice/entity/ListSemestralReport';
import {AddRevocation} from "./components/revocation/AddRevocation";
import {RevocationDirection} from "./models/RevocationDirection";
import {ListRevocation} from "./pages/revocations/ListRevocation";
import {DetailRevocation} from "./pages/revocations/DetailRevocation";
import {ListEconomicViabilityRequests} from "./pages/backoffice/entity/ListEconomicViabilityRequests";
import {PayableProcessPayments} from "./components/payableProcess/PayableProcessPayments";
import {ListPaymentRequest} from "./pages/backoffice/entity/ListPaymentRequest";
import {ListEntityChanges} from "./pages/backoffice/entityChange/ListEntityChanges";
import {AddEntityChangeGeo} from "./pages/backoffice/entityChange/AddEntityChangeGeo";
import {DetailEntityChange} from "./pages/public/entityChange/DetailEntityChange";
import {AddEntityChangeContact} from "./pages/backoffice/entityChange/AddEntityChangeContact";
import {AddEntityChangeLocation} from "./pages/backoffice/entityChange/AddEntityChangeLocation";
import {AddEntityChangeHR} from "./pages/backoffice/entityChange/AddEntityChangeHR";
import {ListInterestExpression} from "./pages/backoffice/candidature/ListInterestExpression";
import {ListContractIncubation} from "./pages/backoffice/entity/ListContractIncubation";
import {ListAccreditationRevocation} from "./pages/accreditationRevocation/ListAccreditationRevocation";
import {DetailAccredidationRevocation} from "./pages/public/accreditationRevocation/DetailAccreditationRevocation";
import {EntityIdentification} from "./pages/backoffice/entity/EntityIdentification";
import {EaAccreditationDocuments} from "./components/eaAccreditation/EaAccreditationDocuments";
import {AddAccreditationRenewal} from "./components/eaAccreditation/AddAccreditationRenewal";
import {ProcessPaymentRequest} from "./components/paymentRequest/ProcessPaymentRequest";
import {Error403} from "./components/Error403";
import {DetailEntityAccreditationPage} from "./components/eaAccreditation/view/DetailEntityAccreditationPage";
import {AdditionalDueDiligence} from "./pages/public/AdditionalDueDiligence";
import {CandidatureAdditionalDueDiligenceAnalysis} from "./pages/public/CandidatureAdditionalDueDiligenceAnalysis";
import {AdditionalDueDiligenceAnswer} from "./pages/public/AdditionalDueDiligenceAnswer";




function AppRouter() {
    useEffect(() => {
        const rootUrl = window.location.origin;
        console.log('Root URL:', rootUrl);
      }, []);


    const {keycloak, initialized} = useKeycloak();

    if (!initialized) {
        return <Loading complex/>;
    }

    function getHomeElement() {
        if (initialized && keycloak.authenticated) {
            if (hasRole('ENTITY')) {
                return <HomeEntity/>;
            }
            if (hasRole('IEFP')) {
                return <HomeIEFP/>;
            }
        }

        return <Home/>;
    }

    return (
        <BrowserRouter>
            <Template>
                <ScrollToTop/>
                <Routes>
                    <Route
                        path='/feriados'
                        element={
                            <PrivateRoute permissions={['GET_HOLIDAY']}>
                                <Holidays/>
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path='/parametros'
                        element={
                            <PrivateRoute permissions={['GET_PARAMETER']}>
                                <ListParameters/>
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path='/notificacoes'
                        element={
                            <PrivateRoute permissions={['LIST_TEMPLATES']}>
                                <ListActionNotifications/>
                            </PrivateRoute>
                        }
                    />

                    <Route
                        path='/indicadores'
                        element={
                            <PrivateRoute permissions={['VIEW_INDICATORS']}>
                                <Indicators/>
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path='/notificacoes/adicionar'
                        element={
                            <PrivateRoute permissions={['CREATE_TEMPLATE']}>
                                <AddActionNotification/>
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path='/notificacoes/:actionNotificationCode/editar'
                        element={
                            <PrivateRoute permissions={['EDIT_TEMPLATE']}>
                                <EditActionNotification/>
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path='/perfil/mensagens'
                        element={
                            <PrivateRoute permissions={['GET_USER_MESSAGE']}>
                                <MessagesNotification/>
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path='/perfil/mensagem/:externalId'
                        element={
                            <PrivateRoute permissions={['GET_MESSAGE']}>
                                <MessageDetails/>
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path='/parametros/adicionar'
                        element={
                            <PrivateRoute permissions={['ADD_PARAMETER']}>
                                <AddParameter/>
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path='/parametros/:externalId/editar'
                        element={
                            <PrivateRoute permissions={['UPDATE_PARAMETER']}>
                                <EditParameter/>
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path='/completarRegisto'
                        element={
                            <PrivateRoute permissions={['COMPLETE_INFORMATION']}>
                                <AddUserData/>
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path='/candidatura/:externalId/contestacao'
                        element={
                            <PrivateRoute permissions={['ADD_CONTESTATION']}>
                                <CandidatureContestation/>
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path='/candidatura/:candidatureExternalId/diligenciasComplementares'
                        element={
                            <PrivateRoute permissions={['ADD_ADDITIONAL_DUE_DILIGENCE']}>
                                <AdditionalDueDiligence/>
                            </PrivateRoute>

                        }
                    />
                    <Route
                        path='/candidatura/:candidatureExternalId/analiseDiligenciasComplementares'
                        element={
                            <PrivateRoute permissions={['ADD_ADDITIONAL_DUE_DILIGENCE_RESPONSE']}>
                                <CandidatureAdditionalDueDiligenceAnalysis/>
                            </PrivateRoute>

                        }
                    />
                    <Route
                        path='/candidatura/:candidatureExternalId/responderDiligenciasComplementares'
                        element={
                            <PrivateRoute permissions={['ADD_ADDITIONAL_DUE_DILIGENCE_RESPONSE']}>
                                <AdditionalDueDiligenceAnswer/>
                            </PrivateRoute>

                        }
                    />
                    <Route
                        path='/candidatura/:externalId/documentosObrigatorios'
                        element={
                            <PrivateRoute permissions={['SUBMIT_REQUIRED_DOCUMENTS']}>
                                <CandidatureApprovedSubmitRequiredDocuments/>
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path='/candidatura/:externalId/parecerArtesOficios'
                        element={
                            <PrivateRoute permissions={['ADD_ART_CRAFT_OPINION']}>
                                <CandidatureSubmitArtsCraftsOpinion/>
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path='/candidatura/:externalId/minutaTermoAceitacao'
                        element={
                            <PrivateRoute permissions={['ADD_TERM_ACCEPT_DRAFT']}>
                                <CandidatureSubmitTermOfAcceptDraft/>
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path='/candidatura/:externalId/termoAceitacao'
                        element={
                            <PrivateRoute permissions={['ADD_TERM_ACCEPT_DRAFT']}>
                                <CandidatureSubmitTermOfAccept/>
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path='/candidatura/:externalId/caducidadeAprovacao'
                        element={
                            <PrivateRoute permissions={['ADD_APPROVAL_DECISION_EXPIRATION']}>
                                <CandidatureSubmitExpirationApprovalDecision/>
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path='/candidatura/:externalId/documentosExtra'
                        element={
                            <PrivateRoute permissions={['ADD_FINAL_DECISION']}>
                                <CandidatureAddExtraDocuments/>
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path='/candidatura/:externalId/finalDecision'
                        element={
                            <PrivateRoute permissions={['ADD_FINAL_DECISION']}>
                                <CandidatureFinalDecision/>
                            </PrivateRoute>
                        }
                    />

                    <Route
                        path='/candidatura/:externalId/incubacao'
                        element={
                                <ListInterestExpression/>
                        }
                    />

                    <Route
                        path='/candidatura/:externalId/incubacao'
                        element={
                            <ListInterestExpression/>
                        }
                    />




                    <Route
                        path='/incubacao/contratos'
                        element={
                                <ListContractIncubation/>
                        }
                    />


                    <Route
                        path='/candidatura/:candidatureExternalId/alterarCandidatura'
                        element={
                            <CandidatureChange />
                        }
                    />

                    <Route
                        path='/candidatura/:candidatureExternalId/alterarCandidatura/:externalId'
                        element={
                                <CandidatureChange />
                        }
                    />
                    <Route
                        path='/candidatura/:candidatureExternalId/alterarCandidatura/:externalId/detalhe'
                        element={
                                <DetailCandidatureChange />
                        }
                    />
                    <Route
                        path='/candidatura/:candidatureExternalId/alterarCandidatura/:externalId/deferir'
                        element={
                                <CandidatureChangeRequestApproval />
                        }
                    />
                    <Route
                        path='/candidatura/:candidatureExternalId/alterarCandidatura/:externalId/termoAceitacao'
                        element={
                            <CandidatureChangeSubmitTermOfAccept/>
                        }
                    />





                    <Route
                        path='/candidatura/:externalId'
                        element={
                            <PrivateRoute permissions={['GET_CANDIDATURE']} forceRedirect={true}>
                                <DetailCandidature/>
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path='/alteracoesEntidade/:nif'
                        element={
                                <ListEntityChanges/>
                        }
                    />

                    <Route
                        path='/revogacoesEA/:nif'
                        element={
                            <ListAccreditationRevocation/>
                        }
                    />

                    <Route
                        path='/revogacoesEA/:nif/:externalId'
                        element={
                            <DetailAccredidationRevocation/>
                        }
                    />




                    <Route
                        path='/alteracoesEntidade/:nif/adicionar/GEO'
                        element={
                            <AddEntityChangeGeo/>
                        }
                    />

                    <Route
                        path='/alteracoesEntidade/:nif/adicionar/CONTACT'
                        element={
                            <AddEntityChangeContact/>
                        }
                    />

                    <Route
                        path='/alteracoesEntidade/:nif/adicionar/LOCATION'
                        element={
                            <AddEntityChangeLocation/>
                        }
                    />

                    <Route
                        path='/alteracoesEntidade/:nif/adicionar/HR'
                        element={
                            <AddEntityChangeHR/>
                        }
                    />


                    <Route
                        path='/alteracoesEntidade/:nif/:type/:externalId'
                        element={
                            <DetailEntityChange/>
                        }
                    />



                    <Route
                        path='/entidades/adicionar'
                        element={
                            <PrivateRoute permissions={['ADD_ENTITIES']}>
                                <EntityUpload/>
                            </PrivateRoute>
                        }
                    />

                    <Route
                        path='/candidatura'
                        element={
                            <PrivateRoute permissions={['ADD_CANDIDATURE']}>
                                <Candidature/>
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path='/candidatura/adicionar'
                        element={
                            <PrivateRoute permissions={['ADD_CANDIDATURE', 'VIEW_CANDIDATURE_DRAFT']}>
                                <AddCandidature/>
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path='/candidatura/:externalId/validarDocumentosObrigatorios'
                        element={
                            <PrivateRoute permissions={['VALIDATE_REQUIRED_DOCUMENTS']}>
                                <ValidateCandidatureApprovedRequiredDocuments/>
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path='/candidatura/:externalId/aprovar'
                        element={
                            <PrivateRoute permissions={['FLOW_CANDIDATURE_APPROVE']}>
                                <ApproveCandidature/>
                            </PrivateRoute>
                        }
                    />

                    <Route
                        path='/candidatura/:externalId/aprovar/parcial'
                        element={
                            <PrivateRoute permissions={['FLOW_CANDIDATURE_PARTIAL_VALIDATION']}>
                                <CandidatureContestationAnalysisPartialApproval isYearChange={true}/>
                            </PrivateRoute>
                        }
                    />


                    <Route
                        path='/candidatura/:externalId/aceitarElegibilidade'
                        element={
                            <PrivateRoute permissions={['FLOW_CANDIDATURE_APPROVE']}>
                                <AcceptEligibility/>
                            </PrivateRoute>
                        }
                    />

                    <Route
                        path='/candidatura/:externalId/rejeitarElegibilidade'
                        element={
                            <PrivateRoute permissions={['FLOW_CANDIDATURE_APPROVE']}>
                                <RejectEligibility/>
                            </PrivateRoute>
                        }
                    />

                    <Route
                        path='/candidatura/:externalId/historico'
                        element={
                            <PrivateRoute permissions={['GET_CANDIDATURE_HISTORY']}>
                                <CandidatureHistoric/>
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path='/candidatura/:externalId/indeferir'
                        element={
                            <PrivateRoute permissions={['FLOW_CANDIDATURE_REJECT']}>
                                <RejectCandidature/>
                            </PrivateRoute>
                        }
                    />

                    <Route
                        path='/candidatura/:externalId/emitirCaducidade'
                        element={
                            <PrivateRoute permissions={['ADD_APPROVAL_DECISION_EXPIRATION']}>
                                <EmitExpirationDecision/>
                            </PrivateRoute>
                        }
                    />

                    <Route
                        path='/candidaturas'
                        element={
                            <PrivateRoute permissions={['GET_CANDIDATURE_LIST']}>
                                <ListCandidatures/>
                            </PrivateRoute>
                        }
                    />

                    <Route
                        path='/candidaturas/:entityNif/:entityType/:inputState'
                        element={
                            <PrivateRoute permissions={['GET_CANDIDATURE_LIST']}>
                                <ListCandidatures/>
                            </PrivateRoute>
                        }
                    />


                    <Route
                        path='/pedidos'
                        element={
                            <PrivateRoute permissions={['VALIDATE_PRIOR_SUPPORT']}>
                                <ListPriorSupportRequest/>
                            </PrivateRoute>
                        }
                    />

                    <Route
                        path='/pedidos/:externalId'
                        element={
                            <PrivateRoute permissions={['VALIDATE_PRIOR_SUPPORT','ASK_PRIOR_SUPPORT','LIST_PROCESSES']} forceRedirect={true}>
                                <DetailPriorSupport/>
                            </PrivateRoute>
                        }
                    />

                    <Route
                        path='/candidatura/:externalId/revisaoApoio'
                        element={
                            <PrivateRoute permissions={['ASK_PRIOR_SUPPORT']}>
                                <CandidatureSubmitReviewPriorSupport/>
                            </PrivateRoute>
                        }
                    />

                    <Route
                        path='/candidatura/:externalId/contratoApoio'
                        element={
                            <PrivateRoute permissions={['ASK_PRIOR_SUPPORT']}>
                                <CandidatureSubmitContractPriorSupport/>
                            </PrivateRoute>
                        }
                    />

                    <Route
                        path='/candidatura/:externalId/analiseContestacao'
                        element={
                            <PrivateRoute permissions={['FLOW_CANDIDATURE_CONTESTATION_ANALYSIS']}>
                                <CandidatureContestationAnalysis/>
                            </PrivateRoute>
                        }
                    />

                    <Route
                        path='/candidatura/:externalId/analiseContestacaoEligibilidade'
                        element={
                            <PrivateRoute permissions={['FLOW_CANDIDATURE_CONTESTATION_ANALYSIS']}>
                                <CandidatureContestationEligibility/>
                            </PrivateRoute>
                        }
                    />

                    <Route
                        path='/candidatura/:externalId/analiseContestacaoCaducidade'
                        element={
                            <PrivateRoute permissions={['FLOW_CANDIDATURE_CONTESTATION_ANALYSIS']}>
                                <CandidatureContestationAnalysisExpiration/>
                            </PrivateRoute>
                        }
                    />

                    <Route
                        path='/candidatura/:externalId/emitirDecisaoFinalCadudcidade'
                        element={
                            <PrivateRoute permissions={['FLOW_CANDIDATURE_CONTESTATION_ANALYSIS']}>
                                <EmitExpirationFinalDecision/>
                            </PrivateRoute>
                        }
                    />

                    <Route
                        path='/candidatura/:externalId/cancelarDecisaoFinalCadudcidade'
                        element={
                            <PrivateRoute permissions={['FLOW_CANDIDATURE_CONTESTATION_ANALYSIS']}>
                                <CancelExpirationFinalDecision/>
                            </PrivateRoute>
                        }
                    />

                    <Route
                        path='/candidatura/:externalId/analiseContestacao/parcial'
                        element={
                            <PrivateRoute permissions={['FLOW_CANDIDATURE_CONTESTATION_ANALYSIS']}>
                                <CandidatureContestationAnalysisPartialApproval isContestation={true}/>
                            </PrivateRoute>
                        }
                    />

                    <Route
                        path='/candidatura/:externalId/validacao/parcial'
                        element={
                            <PrivateRoute permissions={['FLOW_CANDIDATURE_PARTIAL_VALIDATION']}>
                                <CandidatureContestationAnalysisPartialApproval/>
                            </PrivateRoute>
                        }
                    />

                    <Route
                        path='/candidatura/:externalId/emitirParecer'
                        element={
                            <PrivateRoute
                                permissions={[
                                    'FLOW_CANDIDATURE_SET_TO_OPINION_VALIDATION',
                                    'FLOW_CANDIDATURE_SET_IN_VALIDATION'
                                ]}>
                                <AddEconomicViability/>
                            </PrivateRoute>
                        }
                    />

                    <Route
                        path='/candidatura/:externalId/pagamentos/registar'
                        element={
                            <PrivateRoute permissions={['REGISTER_CANDIDATURE_PAYMENT']}>
                                <PaymentRegistration/>
                            </PrivateRoute>
                        }
                    />

                    <Route
                        path='/candidatura/:externalId/acompanhamento'
                        element={
                            <PrivateRoute  permissions={['GET_CANDIDATURE']}>
                                <Oversight/>
                            </PrivateRoute>
                        }
                    />

                    <Route
                        path='/candidatura/:externalId/acompanhamento/:step'
                        element={
                            <PrivateRoute  permissions={['GET_CANDIDATURE']}>
                                <Oversight/>
                            </PrivateRoute>
                        }
                    />


                    <Route
                        path='/candidatura/:candidatureExternalId/acompanhamento/irregularidade/:irregularityExternalId'
                        element={
                            <PrivateRoute permissions={['GET_CANDIDATURE']}>
                                <IrregularityDetail/>
                            </PrivateRoute>
                        }
                    />

                    <Route
                        path='/candidatura/:candidatureExternalId/acompanhamento/incumprimento/:nonComplianceExternalId'
                        element={
                            <PrivateRoute permissions={['GET_CANDIDATURE']}>
                                <NonComplianceDetail/>
                            </PrivateRoute>
                        }
                    />

                    <Route
                        path='/viabilidadeEconomica/lista'
                        element={
                        <PrivateRoute permissions={['VALIDATE_PRIOR_SUPPORT']}>
                            <ListEconomicViability />
                        </PrivateRoute>
                        }
                    />


                    <Route
                        path='/viabilidadeEconomica'
                        element={
                            <PrivateRoute permissions={['GET_CANDIDATURE']}>
                                <ListEconomicViabilityRequests />
                            </PrivateRoute>
                        }
                    />

                    <Route
                        path='/viabilidadeEconomica/lista/:entityNif/:inputState'
                        element={
                            <PrivateRoute permissions={['VALIDATE_PRIOR_SUPPORT','GET_CANDIDATURE']}>
                                <ListEconomicViability />
                            </PrivateRoute>
                        }
                    />


                    <Route
                        path='/viabilidadeEconomica/lista/:entityNif/:inputState/:candidatureState'
                        element={
                            <PrivateRoute permissions={['VALIDATE_PRIOR_SUPPORT','GET_CANDIDATURE']}>
                                <ListEconomicViability />
                            </PrivateRoute>
                        }
                    />



                    {/*Formulario B7 - FICHA DE AVALIAÇÃO DA ENTIDADE DE ACOMPANHAMENTO PELOS PROMOTORES/EMPRESA*/}
                    <Route path='/avaliacoes'
                        element={
                            <PrivateRoute permissions={['ASK_MCE_CONSULTING']}>
                                <ListEaEvaluation/>
                            </PrivateRoute>
                        }
                    />

                    <Route path='/avaliacoes/:externalId'
                        element={
                            <PrivateRoute permissions={['ASK_MCE_CONSULTING','LIST_PROCESSES']}>
                                <AddEAEvaluation/>
                            </PrivateRoute>
                        }
                    />

                    {/* Formulario B6 - Ficha de Identificação do Projeto*/}
                    <Route path='/identificacaoprojeto/:priorSupportExternalId/adicionar' element={<AddProjectIdentification/>}/>

                    <Route path='/identificacaoprojeto/:processExternalId/validar' element={<AddProjectIdentification/>}/>

                    <Route
                        path='/entidade/nib'
                        element={
                            <PrivateRoute permissions={['UPLOAD_ENTITY_NIB']}>
                                <UploadNib/>
                            </PrivateRoute>
                        }
                    />

                    <Route
                        path='/entidade/nib/:processExternalId'
                        element={
                                <UploadNib/>
                        }
                    />


                    <Route
                        path='/entidade/minutaProtocolo'
                        element={
                            <PrivateRoute permissions={['UPLOAD_ENTITY_COOPERATION_PROTOCOL']}>
                                <ViewCooperationProtocol/>
                            </PrivateRoute>
                        }
                    />

                    <Route
                        path='/processos'
                        element={
                            <PrivateRoute permissions={['LIST_PROCESSES']}>
                                <ListProcesses/>
                            </PrivateRoute>
                        }
                    />

                    {/* Formulario B8 - Plano de Desenvolvimento*/}
                    <Route
                        path='/planodesenvolvimento/lista'
                        element={
                            <PrivateRoute permissions={['VALIDATE_PRIOR_SUPPORT']}>
                                <ListDevelopmentPlans/>
                            </PrivateRoute>
                        }
                    />

                    <Route
                        path='/planodesenvolvimento/:externalId'
                        element={
                            <PrivateRoute permissions={['GET_CANDIDATURE', 'LIST_PROCESSES']}>
                                <AddDevelopmentPlan/>
                            </PrivateRoute>
                        }
                    />

                    <Route
                        path='/candidatura/:candidatureExternalId/consultoria'
                        element={
                            <PrivateRoute permissions={['ASK_MCE_CONSULTING']}>
                                <RequestConsulting/>
                            </PrivateRoute>
                        }
                    />

                    <Route
                        path='/entidade/pedidosMCE'
                        element={
                            <PrivateRoute permissions={['LIST_CONSULTING_REQUESTS']}>
                                <ListConsultingRequests/>
                            </PrivateRoute>
                        }
                    />

                    <Route
                        path='/pedidosMCE/:externalId'
                        element={
                            <PrivateRoute permissions={['GET_CONSULTING_REQUEST']}>
                                <RequestConsultingDetail/>
                            </PrivateRoute>
                        }
                    />

                    <Route
                        path='/pedidosMCE/:externalId/adicionarRevogacao'
                        element={
                                <AddRevocation isPriorSupport={false}/>
                        }
                    />


                    <Route
                        path='/revogacoes/:externalId'
                        element={
                            <DetailRevocation/>
                        }
                    />


                    <Route
                        path='/apoioPrevio/:externalId/adicionarRevogacao'
                        element={
                            <AddRevocation isPriorSupport={true}/>
                        }
                    />


                    <Route
                        path='/pedidosMCE/:externalId/revogacoes'
                        element={
                            <ListRevocation isPriorSupport={false}/>
                        }
                    />


                    <Route
                        path='/apoioPrevio/:externalId/revogacoes'
                        element={
                            <ListRevocation isPriorSupport={true}/>
                        }
                    />




                    <Route path='/consultoria/contactos' element={<ConsultingContacts/>}/>

          <Route path='/apoioPrevio/contactos' element={<PriorSupportContacts />} />
          <Route path='/candidatura/:candidatureExternalId/parecer/contactos' element={<OpinionEntityContacts />} />

          <Route path='/apoioPrevio' element={<PriorSupport />} />
          <Route path='/pedidosMCE/:consultingRequestExternaId/relatorioSemestral' element={

                <AddSemestralReport />  

          } />
          <Route path='/relatorioSemestral/:externalId' element={

                <AddSemestralReport />
          } />

          <Route path='/relatorioSemestral/lista' element={
										<ListSemestralReport/>
						}/>


        <Route path='/pedidosMCE/:consultingRequestExternalId/relatorioSemestral/lista' element={
            <ListSemestralReport/>
        }/>


        <Route path='/pedidosMCE/:consultingRequestExternalId/pedidosPagamento' element={
            <ListPaymentRequest/>
        }/>


          <Route
            path='/atividadesComplementares/plano/'
            element={
              <PrivateRoute permissions={['VALIDATE_PRIOR_SUPPORT']}>
                <AddAnnualComplementaryActivitiesPlan />
                </PrivateRoute>
                }
          />
          <Route
            path='/atividadesComplementares/plano/:externalId'
            element={
              <PrivateRoute permissions={['VALIDATE_PRIOR_SUPPORT', 'LIST_PROCESSES']}>
                <AddAnnualComplementaryActivitiesPlan />
              </PrivateRoute>
              }
          />

        <Route
            path='/atividadesComplementares/entidade/:entityNif/plano/:externalId'
            element={
              <PrivateRoute permissions={['VALIDATE_PRIOR_SUPPORT', 'LIST_PROCESSES']}>
                <AddAnnualComplementaryActivitiesPlan />
              </PrivateRoute>
              }
          />


          <Route
						path='/fichaAtividade/lista'
						element={
								<PrivateRoute permissions={['VALIDATE_PRIOR_SUPPORT']}>
										<ListActivitySheet/>
								</PrivateRoute>
						}
					/>

					<Route
						path='/fichaAtividade/'
						element={
								<PrivateRoute permissions={['VALIDATE_PRIOR_SUPPORT']}>
										<AddActivitySheet/>
								</PrivateRoute>
						}
					/>

					<Route
						path='/fichaAtividade/:externalId'
						element={
								<PrivateRoute permissions={['GET_CANDIDATURE', 'LIST_PROCESSES']}>
										<AddActivitySheet/>
								</PrivateRoute>
						}
					/>


            <Route path='/relatorioSemestral/:externalId/pagamento' element={
                <PrivateRoute permissions={['VIEW_PAYMENT_REQUEST']}>
                    <AddSemestralPaymentRequest />
                </PrivateRoute>
            } />

            <Route path='/relatorioSemestral/:externalId/pagamentoFinal' element={
                <PrivateRoute permissions={['VIEW_PAYMENT_REQUEST']}>
                    <AddFinalPaymentRequest />
                </PrivateRoute>
            } />

            <Route path='/pedidoDePagamento/:externalId' element={
                <PrivateRoute permissions={['VIEW_PAYMENT_REQUEST']}>
                    <ProcessPaymentRequest />
                </PrivateRoute>
            } />

          {/* Formulario B13 - Pedido de Pagamento Final*/}
          <Route
            path='/pedidosMCE/:externalId/declaracaofim1ano'
            element={<AddDeclarationEndFirstYear />}
          />
          <Route path='/pedidosMCE/:externalId/relatorioFinal' element={<AddFinalReport />} />
          <Route path='/pedidosMCE/:externalId/investimento/confirmacao' element={<AddInvestmentConfirmation />} />

          <Route
            path='/pedidoPagamento/atividadesComplementares'
            element={
              <PrivateRoute permissions={['VALIDATE_PRIOR_SUPPORT']}>
                <AddComplementaryActivitiesPaymentRequest />
              </PrivateRoute>
            }
          />
          <Route
            path='/pedidoPagamento/atividadesComplementares/:externalId'
            element={
              <PrivateRoute permissions={['VALIDATE_PRIOR_SUPPORT', 'LIST_PROCESSES']}>
                <AddComplementaryActivitiesPaymentRequest />
              </PrivateRoute>
            }
          />

        <Route
            path='/pedidoPagamento/atividadesComplementares/:externalId/entidade/:entityNif'
            element={
              <PrivateRoute permissions={['VALIDATE_PRIOR_SUPPORT', 'LIST_PROCESSES']}>
                <AddComplementaryActivitiesPaymentRequest />
              </PrivateRoute>
            }
          />


          <Route path='/listagemContratos/lista' element={<PrivateRoute permissions={['VALIDATE_PRIOR_SUPPORT']}><ListContractListing /></PrivateRoute>}/>
          <Route path='/listagemContratos' element={<PrivateRoute permissions={['VALIDATE_PRIOR_SUPPORT']}><ListContracts /></PrivateRoute>} />
          <Route path='/listagemContratos/:externalId' element={<PrivateRoute permissions={['VALIDATE_PRIOR_SUPPORT', 'LIST_PROCESSES']}><ListContracts /></PrivateRoute>} />


          <Route
            path='pagamento/apoioPrevio/lista'
            element={
              <PrivateRoute permissions={['VALIDATE_PRIOR_SUPPORT']}>
                <ListPriorSupportPaymentRequest />
              </PrivateRoute>
            }
          />

          <Route path='/pagamento/apoioPrevio'
            element={
              <PrivateRoute permissions={['VALIDATE_PRIOR_SUPPORT']}>
                <PriorSupportPaymentRequest />
              </PrivateRoute>
            }
          />

          <Route path='/pagamento/apoioPrevio/:externalId'
            element={
              <PrivateRoute permissions={['VALIDATE_PRIOR_SUPPORT', 'LIST_PROCESSES']}>
                <PriorSupportPaymentRequest />
              </PrivateRoute>
            }
          />

        <Route
            path='pagamento/viabilidadeEconomica/lista'
            element={
              <PrivateRoute permissions={['VALIDATE_PRIOR_SUPPORT']}>
                <ListEconomicViabilityPaymentRequest />
              </PrivateRoute>
            }
          />

          <Route path='/pagamento/viabilidadeEconomica'
            element={
              <PrivateRoute permissions={['VALIDATE_PRIOR_SUPPORT']}>
                <EconomicViabilityPaymentRequest />
              </PrivateRoute>
            }
          />

          <Route path='/pagamento/viabilidadeEconomica/:externalId'
            element={
              <PrivateRoute permissions={['VALIDATE_PRIOR_SUPPORT', 'LIST_PROCESSES']}>
                <EconomicViabilityPaymentRequest />
              </PrivateRoute>
            }
          />

                    <Route
                        path='/credenciacaoEntidade/adicionar/:entityNIPC'
                        element={
                            <PrivateRoute permissions={['VIEW_ENTITY_ACCREDITATION']}>
                                <AddEaAccreditation/>
                            </PrivateRoute>
                        }
                    />

                    <Route
                        path='/credenciacaoEntidade/documentos/:entityNIPC'
                        element={
                            <PrivateRoute permissions={['VIEW_ENTITY_ACCREDITATION']}>
                                <EaAccreditationDocuments />
                            </PrivateRoute>
                        }
                    />

                    <Route
                        path='/credenciacaoEntidade/adicionar'
                        element={
                            <PrivateRoute permissions={['VIEW_ENTITY_ACCREDITATION']}>
                                <AddEaAccreditation/>
                            </PrivateRoute>
                        }
                    />

                    <Route
                        path='/credenciacaoEntidade'
                        element={
                            <PrivateRoute permissions={['VIEW_ENTITY_ACCREDITATION']}>
                                <AddEaAccreditation/>
                            </PrivateRoute>
                        }
                    />

                    <Route
                        path='/credenciacaoEntidade/renovar/:entityNIPC'
                        element={
                            <PrivateRoute permissions={['VIEW_ENTITY_ACCREDITATION']}>
                                <AddAccreditationRenewal/>
                            </PrivateRoute>
                        }
                    />

                    <Route
                        path='pedidoPagamento/atividadesComplementares/lista'
                        element={
                            <PrivateRoute permissions={['VALIDATE_PRIOR_SUPPORT']}>
                                <ListAnnualComplementaryActivitiesPaymentRequests/>
                            </PrivateRoute>
                        }
                    />

                    <Route
                        path='/atividadesComplementares/plano/lista'
                        element={
                            <PrivateRoute permissions={['VALIDATE_PRIOR_SUPPORT']}>
                                <ListAnnualComplementaryActivitiesPlan/>
                            </PrivateRoute>
                        }
                    />

                    <Route
                        path='/entidades'
                        element={
                            <PrivateRoute permissions={['LIST_PROCESSES']}>
                                <ListEntities/>
                            </PrivateRoute>
                        }
                    />

                    <Route
                        path='/entidades/:entityNif'
                        element={
                            <PrivateRoute permissions={['LIST_PROCESSES']}>
                                <DetailEntity/>
                            </PrivateRoute>
                        }
                    />

                    <Route
                        path='/entidades/:entityNIPC/credenciacao'
                        element={
                            <PrivateRoute permissions={['VIEW_ENTITY_ACCREDITATION']}>
                                <DetailEntityAccreditationPage/>
                            </PrivateRoute>
                        }
                    />

                    <Route
                        path='/entidade/credenciacao'
                        element={
                            <PrivateRoute permissions={['VIEW_ENTITY_ACCREDITATION']}>
                                <DetailEntityAccreditationPage/>
                            </PrivateRoute>
                        }
                    />

                    <Route
                        path='/atividadesComplementares/comunicacaoInicio/lista'
                        element={
                            <PrivateRoute permissions={['VALIDATE_PRIOR_SUPPORT']}>
                                <ListAnnualComplementaryActivitiesPlanStartCommunication/>
                            </PrivateRoute>
                        }
                    />

                    <Route
                        path='/atividadesComplementares/comunicacaoInicio/'
                        element={
                            <PrivateRoute permissions={['VALIDATE_PRIOR_SUPPORT']}>
                                <ComplementaryActivitiesStartCommunication/>
                            </PrivateRoute>
                        }
                    />

                    <Route
                        path='/atividadesComplementares/comunicacaoInicio/:externalId'
                        element={
                            <PrivateRoute permissions={['VALIDATE_PRIOR_SUPPORT', 'LIST_PROCESSES']}>
                                <ComplementaryActivitiesStartCommunication/>
                            </PrivateRoute>
                        }
                    />

                    <Route
                        path='/atividadesComplementares/entidade/:entityNif/comunicacaoInicio/:externalId'
                        element={
                            <PrivateRoute permissions={['VALIDATE_PRIOR_SUPPORT', 'LIST_PROCESSES']}>
                                <ComplementaryActivitiesStartCommunication/>
                            </PrivateRoute>
                        }
                    />

                    <Route path='/processos/:payableProcessExternalId/pagamentos' element={
                        <PrivateRoute permissions={['VIEW_PAYMENT_REQUEST']}>
                            <PayableProcessPayments/>
                        </PrivateRoute>
                    } />

                    <Route path='/identificarEntidadeDeApoio' element={
                       <EntityIdentification />
                    }/>


                    <Route path='/error/:responseStatusCode' element={<Error403 />}/>
                    <Route path='/login/keycloak' element={<KeycloakAuthentication/>}/>
                    <Route path='/login' element={<Login/>}/>
                    <Route path='/' element={getHomeElement()}/>
                    <Route path='*' element={getHomeElement()}/>
                </Routes>
            </Template>
        </BrowserRouter>
    );
}

export default AppRouter;
