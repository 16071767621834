import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { SingleUploadArea } from '../SingleUploadArea';
import {
	deleteCandidatureChangeRequestDocument,
	uploadCandidatureChangeRequestDocument,
} from '../../rest/candidatureChangeRequest';
import { useParams } from 'react-router-dom';
import { DocumentType } from '../../models/DocumentType';
import { Col, Form, Row } from 'react-bootstrap';
import {Arrays} from "../../utils/Arrays";

export function ApprovalDecisionDocumentsStep({
	values,
	candidatureChangeDocuments,
	setCandidatureChangeDocuments,
	documentTypes,
	setError,
	numberOfSteps,
	newAcceptanceTerm,
	setNewAcceptanceTerm,
}) {
	const intl = useIntl();




	const { candidatureExternalId, externalId } = useParams();

	const uploadCallback = async (formData) => {
		return await uploadCandidatureChangeRequestDocument(
			candidatureExternalId,
			externalId,
			formData
		);
	};

	const deleteCallback = async (fileExternalId) => {
		return await deleteCandidatureChangeRequestDocument(
			candidatureExternalId,
			externalId,
			fileExternalId
		);
	};

	return (
		<div>
			<h4 className='text-primary mb-0'>
				<FormattedMessage id='candidatureForm.regroupRequiredDocuments' />
			</h4>
			<small className='text-secondary text-uppercase'>
				{intl.formatMessage(
					{ id: 'all.step' },
					{ step: values.step, numberOfSteps: numberOfSteps }
				)}
			</small>
			<Row className='mt-4 mb-4'>
				<Col>

					<Form.Check
						type='checkbox'
						defaultChecked={newAcceptanceTerm}
						onChange={(e) => {
							setNewAcceptanceTerm(e.target.checked);
						}}
						label={
							<FormattedMessage id='candidatureChangeRequestValidation.newAcceptanceTerm' />
						}
						disabled={candidatureChangeDocuments.filter(d=> d.documentType === DocumentType.CANDIDATURE_CHANGE_TERM_ACCEPT)[0].submited}
					/>
				</Col>
			</Row>

			<div className='mt-3'>
				{documentTypes.map((dt, index) => (
					<div
						key={index}
						className={
							dt === DocumentType.CANDIDATURE_CHANGE_TERM_ACCEPT && !newAcceptanceTerm
								? 'd-none'
								: ''
						}
					>
						<SingleUploadArea
							documents={candidatureChangeDocuments}
							setDocuments={setCandidatureChangeDocuments}
							documentType={dt}
							setError={setError}
							processUploadCallback={uploadCallback}
							processDeleteCallback={deleteCallback}
						/>
					</div>
				))}
			</div>
		</div>
	);
}
