import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { handleError, isNotBusinessError } from '../../../utils/handleError';
import { AlertError } from '../../../components/bootstrap/AlertError';
import { Arrays } from '../../../utils/Arrays';
import { FormattedMessage, useIntl } from 'react-intl';
import { EnumSelectField } from '../../../components/bootstrap/EnumSelectField';
import { AlertSuccess } from '../../../components/bootstrap/AlertSuccess';
import { useNavigate } from 'react-router-dom';
import { LabelToolTip } from '../../../components/bootstrap/LabelToolTip';
import { EmptyResults } from '../../../components/general/EmptyResults';
import { SubTemplate } from '../../../components/general/SubTemplate';
import Loading from '../../../components/general/Loading';
import { CustomPagination } from '../../../components/general/CustomPagination';
import { Strings } from '../../../utils/Strings';
import { getEntityList } from '../../../rest/entity';
import { ListFilterBar } from '../../../components/general/ListFilterBar';
import { EntityState } from "../../../models/EntityState";
import Nav from 'react-bootstrap/Nav';
import { NavLinkFilter } from '../../../components/general/NavLinkFilter';
import {el} from "date-fns/locale";

const MAX_RESULTS = 10;
const DEFAULT_INITIAL_PAGE = 0;

export function ListEntities() {
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);
	const [numberOfResults, setNumberOfResults] = useState(null);
	const [totalResults, setTotalResults] = useState(null);
	const [currentPage, setCurrentPage] = useState(DEFAULT_INITIAL_PAGE);
	const [activeEventKey, setActiveEventKey] = useState('FULLY_APPROVED');

	const [entityList, setEntityList] = useState([]);

	const orderByOptionsIdPrefix = 'listEntities.list.orderOption';
	const orderByOptions = ['description_asc', 'description_desc', 'accreditationDate_asc', 'accreditationDate_desc'];

	const [currentOrderBy, setCurrentOrderBy] = useState(orderByOptions[0]);

	const [filters, setFilters] = useState({
		name: { type: 'text', value: '', placeholderId: 'all.name', colSpace: 3 },
		nipc: { type: 'number', value: '', placeholder: 'NIPC', maxLength: 9, colSpace: 3 },
	});

	const tabs = {
		SUBMITTED: { labelId: 'entityState.SUBMITTED', value: 'SUBMITTED'},
		EVALUATION: { labelId: 'entityState.EVALUATION', value: 'EVALUATION'},
		FULLY_APPROVED: { labelId: 'entityState.FULLY_APPROVED', value: 'FULLY_APPROVED'},
		REVOKED: { labelId: 'revocationState.REVOKED', value: 'REVOKED'},
	}
	

	const hoverpreffixId = 'listEntities.list.hover';

	const navigate = useNavigate();

	const intl = useIntl();

	const [lsItem, setLsItem] = useState(null);

	async function fetchData() {
		try {
			if(filters.name.value !=='' || filters.nipc.value!==''){
				setCurrentPage(0)
			}

			let orderParams = currentOrderBy.split('_');

			let queryparams = {
				index: (filters.name.value !=='' || filters.nipc.value!=='') ? 0 : currentPage,
				size: MAX_RESULTS,
				orderBy: orderParams[0],
				orderType: orderParams[1],
				revoked: false
			};

			if (!Strings.isBlank(filters.name.value)) {
				queryparams.name = filters.name.value;
			}

			if (!Strings.isBlank(filters.nipc.value)) {
				queryparams.nipc = filters.nipc.value;
			}

			if (!Strings.isBlank(activeEventKey) ) {
				if(EntityState.values().includes(activeEventKey)) {
					if (activeEventKey)
						queryparams.state = activeEventKey;
				} else if(activeEventKey === 'REVOKED') {
					queryparams.revoked = true;
				}
			}

			const [{ data: formList }] = await Promise.all([
				await getEntityList(queryparams),
			]);

			setEntityList(formList.results);
			setNumberOfResults(formList.numberOfResults);
			setTotalResults(formList.totalResults);
		} catch (error) {
			setError(error);
		} finally {
			setLoading(false);
		}
	}

	const onPageChange = (pageNumber) => {
		setCurrentPage(pageNumber);
	};

	function handleOrderByChange(e) {
		setCurrentOrderBy(e.target.value);
	}

	useEffect(() => {
		checkStorageItems();
	}, []);

	useEffect(() => {
		fetchData();
	}, [currentPage, activeEventKey]);

	if (loading) {
		return <Loading />;
	}

	if (error && isNotBusinessError(error)) {
		return handleError(error);
	}

	function checkStorageItems() {
		if (sessionStorage.getItem('eaAccreditationSubmited')) {
			setLsItem('eaAccreditationSubmited');
		}
	}

	console.log(entityList)

	return (
		<SubTemplate
			titleId={'listEntities.list.title'}
			hasBackButton
		>
			<Container>
				<div className='mx-2 mb-4'>
					<AlertSuccess lsItem={lsItem} />
				</div>
				<Row className='mb-4'>
					<NavLinkFilter tabs={tabs} defaultActiveKey={activeEventKey} setActiveEventKey={setActiveEventKey} />
				</Row>
				<Row>
					<Col md='10'>
						<ListFilterBar
							filters={filters}
							setFilters={setFilters}
							search={fetchData}
						/>
					</Col>
				</Row>
				<Row className='searchResults'>
					<Col md='12'>
						<AlertError error={error} />

						{Arrays.isNotEmpty(entityList) ? (
							<>
								<Row className='mt-5 mb-1 d-flex justify-content-between px-2'>
									<Col className='text-muted'>
										{totalResults}{' '}
										<FormattedMessage
											id={`all.results.lowCase.${
												totalResults === 1 ? 'singular' : 'plural'
											}`}
										/>
									</Col>
									<Col md='4'>
										<EnumSelectField
											preffixDescriptionId={orderByOptionsIdPrefix}
											handleChange={handleOrderByChange}
											options={orderByOptions}
											value={currentOrderBy}
										/>
									</Col>
								</Row>
								<div className='list-header d-lg-block d-md-block d-sm-none d-xs-none'>
									<Row>
										<Col md='4' className='d-flex align-items-center'>
											<FormattedMessage id={'listEntities.list.hover.name'}/>
										</Col>
										<Col md='2' className='d-flex align-items-center'>
											<FormattedMessage id={'listEntities.list.hover.nipc'}/>
										</Col>
										<Col md='2' className='d-flex align-items-center'>
											<FormattedMessage id={'listEntities.list.hover.processId'}/>
										</Col>
										<Col md='2' className='d-flex align-items-center'>
											<FormattedMessage id={'listEntities.list.hover.state'}/>
										</Col>
										<Col md='2' className='d-flex align-items-center justify-content-center'>
											<FormattedMessage id={'listEntities.list.hover.accreditationDate'}/>

										</Col>
									</Row>
								</div>
								<ul className='results'>
									{entityList.map((entity, index) => (
										<li key={index}>
											<Row
												className='py-3'
												onClick={() => navigate(`/entidades/${entity.nif}`)}
											>
												<Col md='4' className='d-flex align-items-center'>
													<LabelToolTip
														labelId={`${hoverpreffixId}.name`}
														value={entity.description
														}
													/>
												</Col>
												<Col md='2' className='d-flex align-items-center'>
													<LabelToolTip
														labelId={`${hoverpreffixId}.nipc`}
														value={entity.nif}
													/>
												</Col>
												<Col md='2' className='d-flex align-items-center '>
													<LabelToolTip
														labelId={`${hoverpreffixId}.processId`}
														value={entity.processId}
													/>
												</Col>
												<Col md='2' className='d-flex align-items-center '>
													<LabelToolTip
														labelId={`${hoverpreffixId}.processId`}

														value={<FormattedMessage id={entity.revoked ? 'accreditationRevocationState.APPROVED' : `entityState.${entity.state}`}/>}
													/>
												</Col>
												<Col
													md='2'
													className='d-flex align-items-center justify-content-center'
												>
													<LabelToolTip
														labelId={`${hoverpreffixId}.accreditationDate`}
														value={entity.accreditationDate=== undefined ? ' — ' : entity.accreditationDate}
													/>
												</Col>
											</Row>
										</li>
									))}
								</ul>
							</>
						) : (
							<EmptyResults />
						)}
					</Col>
				</Row>
				<Row className='mb-5'>
					<Col md='10' className='d-flex'>
						<div className='mx-2'>
							<CustomPagination
								dataPerPage={MAX_RESULTS}
								totalData={totalResults}
								onPageChange={onPageChange}
								currentPage={currentPage}
							/>
						</div>
					</Col>
				</Row>
			</Container>
		</SubTemplate>
	);
}
