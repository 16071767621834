import React, { useEffect, useState } from 'react';
import { Row, Col, Form, Table } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import {FaArrowRight, FaRegTimesCircle,FaInfoCircle} from 'react-icons/fa';
import { TextAreaInputField } from '../../bootstrap/TextAreaInputField';
import { DateTimeInputField } from '../../bootstrap/DateTimeInputField';
import { EnumSelectField } from '../../bootstrap/EnumSelectField';
import { DecimalInputField } from '../../bootstrap/DecimalInputField';
import {currencyFormat, maxFixed} from '../../../utils/CurrencyUtils';
import { NumberInputField } from '../../bootstrap/NumberInputField';
import { default as DivTable } from '../../divTable/Table';
import { TextInputField } from '../../bootstrap/TextInputField';
import {isIEFPUser, refreshToken} from "../../../authentication/authenticationHelper";
import IntInputField from "../../bootstrap/IntInputField";
import {Month} from "../../../models/Month";
import {ConfigObject} from "../../../config";

export function SemestralPaymentRequestFormStep4({
	formStep,
	numberOfSteps,
	errors,
	handleChange,
	formikValues,
	handlePreviousStep,
	handleNextStep,
	setFieldValue,
	readMode = false,
}) {
	const intl = useIntl();

	const [monthsThatHadVisits, setMonthsThatHadVisits] = useState([]);

	const months = Month.values();


	const confirmationOptions = [true, false];

	function handlerAddVisit() {



		formikValues.visits.push({
			month: '',
			bimonthly: '',
			otherActivities: '',
			requestedValue: 0,
		});
		setFieldValue({ ...formikValues });
	}

	function handlerRemoveVisit(index) {

		formikValues.visits.splice(index, 1);
		setFieldValue({ ...formikValues });
		refreshBimestralFollowUp();
		return false;
	}


	function refreshBimestralFollowUp(e,index,name){
		console.log(e)
		if(e!==undefined){
			formikValues.visits[index][name] =e.target.value;
			setFieldValue({ ...formikValues });

		}
		let count = 0;
		formikValues.visits.forEach((visit)=>{
			if((visit.bimonthly === true || visit.bimonthly === 'true') && count<3){
				visit.requestedValue=maxFixed(1/(parseFloat(formikValues.mceFinancing.contractDuration)/2)*parseFloat(formikValues.plafondFollowUp));
				count++;
			}else{
				visit.requestedValue=0;
			}
		})

		let bimestralFollowup=  formikValues.visits.reduce((n, visit) => (visit.bimonthly === true || visit.bimonthly === 'true') ? n+(visit.requestedValue !==undefined ? parseFloat(visit.requestedValue):0) : n, 0);
		formikValues.bimestralFollowUpPayment=bimestralFollowup;
		setFieldValue({ ...formikValues });

	}


	function changeHours(e) {

		formikValues.spentHours = e.target.value;
		setFieldValue({...formikValues});
		let consultingPaymentAsk = maxFixed(parseFloat(formikValues.plafondConsulting) / 120 * ((parseInt(formikValues.spentHours===''?0:formikValues.spentHours))>120 ? 120 : parseInt(formikValues.spentHours===''?0:formikValues.spentHours)) );
		formikValues.consultingPayment = consultingPaymentAsk;
		setFieldValue({...formikValues});

	}







	function handleMonthChange(e, index) {
		let previousMonth = formikValues.visits[index].month;

		if (previousMonth !== '') {
			monthsThatHadVisits.splice(monthsThatHadVisits.indexOf(previousMonth), 1);
		}

		if (e.target.value !== '-1') {
			monthsThatHadVisits.push(e.target.value);
		}
		setMonthsThatHadVisits([...monthsThatHadVisits]);
		handleChange(e);
	}

	function getOptionsSet(visit) {
		return months.filter(
			(month) => !monthsThatHadVisits.includes(month) || month === visit.month
		);
	}

	function totalPaymentRequest() {
		console.log(formikValues.consultingPayment)
		let sum =
			maxFixed((parseFloat(maxFixed(formikValues.bimestralFollowUpPayment)) || 0) +
			(parseFloat(maxFixed(formikValues.consultingPayment))) || 0);


		if(sum> formikValues.plafond){
			sum =formikValues.plafond;
		}

		return sum;
	}

	return (
		<div>
			<h4 className='text-primary mb-0'>
				<FormattedMessage id='semestralPaymentRequestForm.sideNavBar.paymentRequest' />
			</h4>
			<small className='text-secondary text-uppercase'>
				{intl.formatMessage(
					{ id: 'all.step' },
					{ step: formStep, numberOfSteps: numberOfSteps }
				)}
			</small>
			<Row>
				<Col md='6'>
					<DateTimeInputField
						labelId='semestralPaymentRequestForm.reportPeriodStartDate'
						isDateWithNoTime={true}
						setFieldValue={setFieldValue}
						name='initialDateMceSupport'
						value={formikValues.initialDateMceSupport}
						isInvalid={errors.initialDateMceSupport ? true : false}
						errorMessage={errors.initialDateMceSupport ? errors.initialDateMceSupport : ''}
						disabled={true}
					/>
				</Col>
				<Col md='6'>
					<DateTimeInputField
						labelId='semestralPaymentRequestForm.reportPeriodEndDate'
						isDateWithNoTime={true}
						setFieldValue={setFieldValue}
						name='endDateMceSupport'
						value={formikValues.endDateMceSupport}
						isInvalid={errors.endDateMceSupport ? true : false}
						errorMessage={errors.endDateMceSupport ? errors.endDateMceSupport : ''}
						disabled={true}
					/>
				</Col>
			</Row>
			<Row>
				<Col md={12}>
					<h5 className='text-secondary mt-4'>
						<FormattedMessage id={`semestralPaymentRequestForm.bimestralFollowUp`}/>
					</h5>

				</Col>

			</Row>


			<Row>
				<Col md='6'>
					<DecimalInputField
						suffixLabel='€'
						labelId='semestralPaymentRequestForm.payment'
						name='bimestralFollowUpPayment'
						value={formikValues.bimestralFollowUpPayment}
						handleChange={handleChange}
						isInvalid={errors.bimestralFollowUpPayment ? true : false}
						errorMessage={
							errors.bimestralFollowUpPayment
								? errors.bimestralFollowUpPayment
								: ''
						}
						disabled={true}
					/>
				</Col>

			</Row>
			<Row>
				<Col md='12'>
					<p className='mt-4 mb-0 text-secondary infoText'>
						<FaInfoCircle size={30} />
						<FormattedMessage id={'semestralPaymentRequestForm.payment.bimestralVisit.infoText'}/>
					</p>
				</Col>
			</Row>
			<Row className='mt-4'>
				<Col md='12'>
					<Table className='d-table' striped responsive='md'>
						<thead>
						<tr>
							<th className='w-25'>
								<FormattedMessage id='semestralPaymentRequestForm.month' />
							</th>

							<th>
								<FormattedMessage id='semestralPaymentRequestForm.bimestralVisit' />
							</th>

							<th>
								<FormattedMessage id='semestralPaymentRequestForm.otherActivities' />
							</th>

							<th>
								<FormattedMessage id='semestralPaymentRequestForm.amountRequested' />
							</th>
							{!readMode &&
							<th></th>
							}
						</tr>
						</thead>
						<tbody>
						{formikValues.visits.map((visit, index) => (
							<tr key={index}>
								<td className='w-25'>
									<EnumSelectField
										nullOption={true}
										handleChange={(e) => handleMonthChange(e, index)}
										name={`visits[${index}].month`}
										options={getOptionsSet(visit)}
										preffixDescriptionId={'all.months'}
										value={formikValues.visits[index].month}
										isInvalid={errors.visits?.[index]?.month !== undefined}
										disabled={readMode}
									/>
								</td>

								<td>
									<EnumSelectField
										nullOption={true}
										handleChange={(e) => refreshBimestralFollowUp(e,index,'bimonthly')}
										name={`visits[${index}].bimonthly`}
										options={confirmationOptions}
										preffixDescriptionId='all'
										value={formikValues.visits[index].bimonthly}
										isInvalid={errors.visits?.[index]?.bimonthly !== undefined}
										disabled={readMode}
									/>
								</td>

								<td>
									<EnumSelectField
										nullOption={true}
										handleChange={handleChange}
										name={`visits[${index}].otherActivities`}
										options={confirmationOptions}
										preffixDescriptionId='all'
										value={formikValues.visits[index].otherActivities}
										isInvalid={errors.visits?.[index]?.otherActivities !== undefined}
										disabled={readMode}
									/>
								</td>

								<td>
									<DecimalInputField
										suffixLabel='€'
										name={`visits[${index}].requestedValue`}
										value={formikValues.visits[index].requestedValue}
										handleChange={e=>refreshBimestralFollowUp(e,index, 'requestedValue')}
										isInvalid={errors.visits?.[index]?.requestedValue !== undefined}
										disabled={true}
									/>
								</td>
								{!readMode &&
								<td>
									<FaRegTimesCircle
										onClick={() => handlerRemoveVisit(index)}
										color={"#eb4f24"}
										cursor={"pointer"}
									/>

								</td>
								}
							</tr>
						))}
						{errors.visits !== undefined && !Array.isArray(errors.visits) && (
							<tr>
								<td colSpan={5}>
									<Form.Control.Feedback type='invalid'>{errors.visits}</Form.Control.Feedback>
								</td>
							</tr>
						)}
						{formikValues.visits.length === 0 && (
							<tr>
								<td colSpan={5}>
									<FormattedMessage id='semestralPaymentRequestForm.visits.noData' />
								</td>
							</tr>
						)}
						</tbody>
						<tfoot>
						{!readMode &&
						<tr>
							<td colSpan={5}>
								<button
									className='btn btn-outline-primary'
									type='button'
									onClick={handlerAddVisit}
								>
									<FormattedMessage id='all.addButton' />
								</button>
							</td>
						</tr>
						}
						</tfoot>
					</Table>
				</Col>
			</Row>

			<Row>
				<Col md={12}>
					<h5 className='text-secondary mt-4'>
						<FormattedMessage id={`semestralPaymentRequestForm.skillsExtension`}/>
					</h5>

				</Col>

			</Row>
			<Row className={'pb-5 border-1 border-bottom'}>
				<Col md='4'>
					<DecimalInputField
						suffixLabel='€'
						labelId='semestralPaymentRequestForm.payment'
						name='consultingPayment'
						value={formikValues.consultingPayment}
						handleChange={handleChange}
						isInvalid={errors.consultingPayment ? true : false}
						errorMessage={
							errors.consultingPayment ? errors.consultingPayment : ''
						}
						disabled={true}
					/>
				</Col>
				<Col md='4'>
					<IntInputField
						labelId='semestralPaymentRequestForm.spentHours'
						name='spentHours'
						value={formikValues.spentHours}
						handleChange={e=>changeHours(e)}
						isInvalid={errors.spentHours ? true : false}
						errorMessage={errors.spentHours ? errors.spentHours : ''}
						disabled={readMode}
					/>
				</Col>
				<Col md='4'>
					<DecimalInputField
						labelId='semestralPaymentRequestForm.availableAmount'
						suffixLabel='€'
						name='availableAmount'
						value={formikValues.plafondConsulting}
						disabled={true}
						handleChange={handleChange}
						isInvalid={errors.plafondConsulting ? true : false}
						errorMessage={errors.plafondConsulting ? errors.plafondConsulting : ''}
					/>
				</Col>
			</Row>

			<Row className='mt-3'>
				<Col md='12'>
					<Form.Label>
						<FormattedMessage id='semestralPaymentRequestForm.paymentRequestFromTotalAmount' />
					</Form.Label>
				</Col>
				<Col md='12'>
					{currencyFormat(totalPaymentRequest())}
					<FaArrowRight className='text-primary mx-2' />
					{maxFixed((parseFloat(totalPaymentRequest())/parseFloat(formikValues.plafond))*100)}%&nbsp;
					<b><FormattedMessage id='semestralPaymentRequestForm.fromPlafond'/></b>
				</Col>
			</Row>
			<Row className='mt-3'>
				<Col>
					<TextAreaInputField
						labelId='semestralPaymentRequestForm.observationsEA'
						name='observationsEA'
						value={formikValues.observationsEA}
						handleChange={handleChange}
						isInvalid={errors.observationsEA ? true : false}
						errorMessage={errors.observationsEA ? errors.observationsEA : ''}
						disabled={readMode}
					/>
				</Col>
			</Row>
			<Row>
				<Col>
						<TextInputField
							disabled={true}
							labelId='semestralPaymentRequestForm.technicalTeamManager'
							value={formikValues.technicalTeamManager.name}
						/>
				</Col>
			</Row>
			<div className='form-actions mt-5'>
				<button
					className='btn btn-link'
					type='button'
					onClick={handlePreviousStep}
				>
					<FormattedMessage id='all.back' />
				</button>
				{formStep < numberOfSteps && (
				<button
					className='btn btn-primary'
					type='button'
					onClick={handleNextStep}
				>
					<FormattedMessage id='all.nextStep' />
				</button>
				)}
			</div>
		</div>
	);
}
