import { FormattedMessage } from 'react-intl';
import { Row, Col, Table, Form } from 'react-bootstrap';
import { TextInputField } from '../components/bootstrap/TextInputField';
import React from 'react';
import DecimalInputField from '../components/bootstrap/DecimalInputField';
import {MceActivityType} from "../models/MceActivityType";
import {FaRegTimesCircle} from "react-icons/fa";

function addTimelineEntry(yearTimeline, formikValues, setFormikValues) {
	console.log(formikValues.yearTimelineEntryB13s)
	formikValues.yearTimelineEntryB13s[yearTimeline].push({
		activityType: '',
		actions: '',
		foreCastFirstSemester: 0,
		foreCastSecondSemester: 0,
		realFirstSemester: 0,
		realSecondSemester: 0,
	});



	setFormikValues({ ...formikValues });
	console.log(formikValues.yearTimelineEntryB13s)

}




function removeYearTimeline(yearTimeline,index,formikValues,setFieldValue) {
	formikValues.yearTimelineEntryB13s[yearTimeline].splice(index, 1);
	setFieldValue({...formikValues});
}



export function generateTimelineTableHeaders() {
	return (
		<thead>
			<tr>
				<th>
					<FormattedMessage id='finalReportForm.timeline.mceActivities' />
				</th>

				<th colSpan={2}>
					<FormattedMessage id='finalReportForm.timeline.activities' />
				</th>
				<th>
					<FormattedMessage id='finalReportForm.timeline.semester' />
				</th>
				<th>
					<FormattedMessage id='finalReportForm.timeline.semester' />
				</th>
			</tr>
		</thead>
	);
}

export function generateTimelineTableBody(
	yearTimeline,
	formikValues,
	handleChange,
	errors,setFieldValue
) {
	function handleActityType(e, yearTimeline, index) {
		formikValues.yearTimelineEntryB13s[yearTimeline][index]['activityType']=  e.target.value==='-1'? null : e.target.value;
		setFieldValue({...formikValues});


	}

	return (
		<tbody>
			{formikValues.yearTimelineEntryB13s[yearTimeline].length > 0 ? (
				formikValues.yearTimelineEntryB13s[yearTimeline].map((entry, index) => (
					<React.Fragment key={index}>
						<tr>
							<td rowSpan={2} className={'border-bottom-0'}>


								<Form.Group>
									<Form.Control
										as='select'
										name={`yearTimelineEntryB13s.${yearTimeline}[${index}].activityType`}
										onChange={(e) => handleActityType(e, yearTimeline, index)}
										value={formikValues.yearTimelineEntryB13s[yearTimeline][index]['activityType']}
										isInvalid={
											errors?.yearTimelineEntryB13s?.[yearTimeline]?.[index]?.activityType
										}
									>
										<option value={'-1'}>
											<FormattedMessage id='all.chooseOne'/>
										</option>
										{MceActivityType.values().map((action) => (
											<option key={action} value={action}>
												<FormattedMessage id={'mceActivityType.'+action}/>
											</option>
										))}

									</Form.Control>
									<Form.Control.Feedback type='invalid'>
										{errors?.yearTimelineEntryB13s?.[yearTimeline]?.[index]?.activityType}
									</Form.Control.Feedback>
								</Form.Group>
							</td>

							<td rowSpan={2} className={'border-bottom-0'}>
								<TextInputField
									handleChange={handleChange}
									name={`yearTimelineEntryB13s.${yearTimeline}[${index}].actions`}
									value={formikValues.yearTimelineEntryB13s[yearTimeline][index].actions}
									isInvalid={errors?.yearTimelineEntryB13s?.[yearTimeline]?.[index]?.actions}
									errorMessage={errors?.yearTimelineEntryB13s?.[yearTimeline]?. [index]?.actions }
								/>
							</td>
							<th>
								<FormattedMessage id='finalReportForm.timeline.predicted' />
							</th>
							<td >
								<DecimalInputField
									handleChange={handleChange}
									name={`yearTimelineEntryB13s.${yearTimeline}[${index}].foreCastFirstSemester`}
									value={
										formikValues.yearTimelineEntryB13s[yearTimeline][index].foreCastFirstSemester
									}
									isInvalid={errors?.yearTimelineEntryB13s?.[yearTimeline]?.[index]?.foreCastFirstSemester}
									errorMessage={errors?.yearTimelineEntryB13s?.[yearTimeline]?.[index]?.foreCastFirstSemester}
								/>
							</td>
							<td >
								<DecimalInputField
									handleChange={handleChange}
									name={`yearTimelineEntryB13s.${yearTimeline}[${index}].foreCastSecondSemester`}
									value={
										formikValues.yearTimelineEntryB13s[yearTimeline][index].foreCastSecondSemester
									}
									isInvalid={errors?.yearTimelineEntryB13s?.[yearTimeline]?.[index]?.foreCastSecondSemester}
									errorMessage={errors?.yearTimelineEntryB13s?.[yearTimeline]?.[index]?.foreCastSecondSemester}
								/>
							</td>
						</tr>


						<tr>
							<th className={'border-bottom-0'}>
								<FormattedMessage id='finalReportForm.timeline.executed' />
							</th>
							<td className={'border-bottom-0'}>
								<DecimalInputField
									handleChange={handleChange}
									name={`yearTimelineEntryB13s.${yearTimeline}[${index}].realFirstSemester`}
									value={
										formikValues.yearTimelineEntryB13s[yearTimeline][index].realFirstSemester
									}
									isInvalid={errors?.yearTimelineEntryB13s?.[yearTimeline]?.[index]?.realFirstSemester}
									errorMessage={errors?.yearTimelineEntryB13s?.[yearTimeline]?.[index]?.realFirstSemester}
								/>
							</td>
							<td className={'border-bottom-0'}>
								<DecimalInputField
									handleChange={handleChange}
									name={`yearTimelineEntryB13s.${yearTimeline}[${index}].realSecondSemester`}
									value={
										formikValues.yearTimelineEntryB13s[yearTimeline][index].realSecondSemester
									}
									isInvalid={errors?.yearTimelineEntryB13s?.[yearTimeline]?.[index]?.realSecondSemester}
									errorMessage={errors?.yearTimelineEntryB13s?.[yearTimeline]?.[index]?.realSecondSemester}
								/>
							</td>
						</tr>
						<tr className={'mt-0 mb-3'}>
							<td colSpan={5}><button
								className='mt-0 px-0 btn btn-link'
								type='button'
								onClick={() => removeYearTimeline(yearTimeline,index,formikValues,setFieldValue)}
							>
								<FormattedMessage id='all.remove' />
							</button></td>
						</tr>
					</React.Fragment>
				))
			) : (
				<tr>
					<td colSpan={5}>
						<FormattedMessage id='finalReportForm.noData' />
					</td>
				</tr>
			)}
		</tbody>
	);
}

export function generateTimelineTableFoot(
	yearTimeline,
	formikValues,
	setFormikValues
) {
	return (
		<tfoot>
			<tr>
				<td colSpan={5}>
					<Row>
						<Col>
							<button
								className='btn btn-outline-primary'
								type='button'
								onClick={() =>
									addTimelineEntry(yearTimeline, formikValues, setFormikValues)
								}
							>
								<FormattedMessage id='all.addButton' />
							</button>
						</Col>
					</Row>
				</td>
			</tr>
		</tfoot>
	);
}
