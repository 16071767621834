import React, {useEffect, useState} from 'react';
import {Col, Container, Row} from 'react-bootstrap';
import {FormattedMessage, useIntl} from 'react-intl';
import {useNavigate, useParams} from 'react-router-dom';
import {AlertError} from '../../../components/bootstrap/AlertError';
import {AlertSuccess} from '../../../components/bootstrap/AlertSuccess';
import {LabelToolTip} from '../../../components/bootstrap/LabelToolTip';
import {EmptyResults} from '../../../components/general/EmptyResults';
import Loading from '../../../components/general/Loading';
import {SubTemplate} from '../../../components/general/SubTemplate';
import {ProcessState} from '../../../models/ProcessState';
import {listEaEvaluations} from '../../../rest/evaluationEa';
import {Arrays} from '../../../utils/Arrays';
import {handleError, isNotBusinessError} from '../../../utils/handleError';
import {B8Type} from '../../../models/B8Type';
import {CustomPagination} from '../../../components/general/CustomPagination';
import {candidatureChangeRequestStatus, listCandidatureChangeRequests} from '../../../rest/candidatureChangeRequest';
import {CandidatureState} from '../../../models/CandidatureState';
import {ListFilterBar} from '../../../components/general/ListFilterBar';
import {EnumSelectField} from '../../../components/bootstrap/EnumSelectField';
import {isIEFPUser} from '../../../authentication/authenticationHelper';

const MAX_RESULTS = 10;
const DEFAULT_INITIAL_PAGE = 0;

export function ListCandidatureChanges({candidature}) {
    const isIEFP = isIEFPUser();

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [numberOfResults, setNumberOfResults] = useState(null);
    const [totalResults, setTotalResults] = useState(null);
    const [currentPage, setCurrentPage] = useState(DEFAULT_INITIAL_PAGE);

    const [candidatureChangeList, setCandidatureChangeList] = useState([]);

    const [changeStatus, setChangeStatus] = useState(null);

    const hoverpreffixId = 'candidatureChange.list.hover';

    const navigate = useNavigate();

    const intl = useIntl();


    const orderByOptionsIdPrefix = 'candidatureChange.list.orderOption';
    const orderByOptions = ['changeDate_desc', 'changeDate_asc'];

    const [currentOrderBy, setCurrentOrderBy] = useState(orderByOptions[0]);

    const [filters, setFilters] = useState({
        state: {
            type: 'enum',
            preffixDescriptionId: 'candidatureChangeState',
            options: CandidatureState.changeRequestValues(),
            value: '-1',
            nullOption: true,
            nullOptionSuffix: 'state',
            colSpace: 3,
        },
    });

    const onPageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    function handleOrderByChange(e) {
        setCurrentOrderBy(e.target.value);
    }

    async function getChangesStatus() {
        if (!isIEFP) {
            const {data: status} = await candidatureChangeRequestStatus(candidature.externalId);
            setChangeStatus(status);
        }
    }

    async function fetchData(fromFilter) {
        try {
            if (fromFilter === true) {
                setCurrentPage(0);
            }

            let orderParams = currentOrderBy.split('_');

            let queryparams = {
                index: fromFilter ? 0 : currentPage,
                size: MAX_RESULTS,
                orderBy: orderParams[0],
                orderType: orderParams[1],
            };

            if (filters.state.value !== '-1') {
                queryparams.state = filters.state.value;
            }

            const [{data: formList}] = await Promise.all([
                await listCandidatureChangeRequests(candidature.externalId, queryparams),
            ]);

            setCandidatureChangeList(formList.results);
            setNumberOfResults(formList.numberOfResults);
            setTotalResults(formList.totalResults);
        } catch (error) {
            setError(error);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        getChangesStatus();
    }, []);

    useEffect(() => {
        fetchData();
    }, [currentPage]);

    if (loading) {
        return <Loading/>;
    }

    if (error && isNotBusinessError(error)) {
        return handleError(error);
    }

    function redirectToFormPage(candidatureChangeObj) {
        if (candidatureChangeObj === undefined || candidatureChangeObj.state === CandidatureState.DRAFT) {
            navigate(
                `/candidatura/${candidature.externalId}/alterarCandidatura/${
                    candidatureChangeObj !== undefined ? candidatureChangeObj.externalId : ''
                }`
            );
        } else {
            navigate(
                `/candidatura/${candidature.externalId}/alterarCandidatura/${
                    candidatureChangeObj !== undefined ? candidatureChangeObj.externalId : ''
                }/detalhe`
            );
        }
    }


    return (
        <Container>
            <Row>
                <Col md='10'>
                    <ListFilterBar
                        filters={filters}
                        setFilters={setFilters}
                        search={fetchData}
                    />
                </Col>
                {changeStatus !== null && changeStatus.canFill && (
                    <Col md='2'>
                        <button
                            className='btn btn-outline-primary d-flex align-items-center justify-content-center mb-4 w-100'
                            type='button'
                            onClick={() => redirectToFormPage()}
                        >
                            <FormattedMessage
                                id={`candidatureChangeRequest.button.${
                                    changeStatus.inDraft ? 'continue' : 'create'
                                }`}
                            />
                        </button>
                    </Col>
                )}
            </Row>
            <Row className='searchResults'>
                <Col md='12'>
                    {error && <AlertError error={error}/>}

                    {Arrays.isNotEmpty(candidatureChangeList) ? (
                        <>
                            <Row className='mt-3 mb-1 d-flex justify-content-between px-2'>
                                <Col className='text-muted'>
                                    {totalResults}{' '}
                                    <FormattedMessage
                                        id={`all.results.lowCase.${
                                            totalResults === 1 ? 'singular' : 'plural'
                                        }`}
                                    />
                                </Col>
                                <Col md='4'>
                                    <EnumSelectField
                                        preffixDescriptionId={orderByOptionsIdPrefix}
                                        handleChange={handleOrderByChange}
                                        options={orderByOptions}
                                        value={currentOrderBy}
                                    />
                                </Col>
                            </Row>

                            <ul className='results'>
                                {candidatureChangeList.map((candidatureChange, index) => (
                                    <li key={candidatureChange.externalId}>
                                        <Row
                                            className='py-3'
                                            onClick={() => redirectToFormPage(candidatureChange)}
                                        >
                                            <Col md='6' className='d-flex align-items-center'>
                                                <LabelToolTip
                                                    labelId={`${hoverpreffixId}.request`}
                                                    value={intl.formatMessage({
                                                        id: `${hoverpreffixId}.request`,
                                                    })}
                                                />
                                            </Col>
                                            <Col md='4' className='d-flex align-items-center'>
                                                <LabelToolTip
                                                    labelId={`${hoverpreffixId}.state`}
                                                    value={
                                                        <FormattedMessage
                                                            id={`candidatureChangeState.${candidatureChange.state}`}
                                                        />
                                                    }
                                                />
                                            </Col>
                                            <Col
                                                md='2'
                                                className='d-flex align-items-center justify-content-end'
                                            >
                                                <LabelToolTip
                                                    labelId={`${hoverpreffixId}.changeDate`}
                                                    value={candidatureChange.changeDate}
                                                />
                                            </Col>
                                        </Row>
                                    </li>
                                ))}
                            </ul>
                        </>
                    ) : (
                        <EmptyResults/>
                    )}
                </Col>

            </Row>
            <Row className='mb-5'>
                <Col md='10' className='d-flex'>
                    <div className='mx-2'>
                        <CustomPagination
                            dataPerPage={MAX_RESULTS}
                            totalData={totalResults}
                            onPageChange={onPageChange}
                            currentPage={currentPage}
                        />
                    </div>
                </Col>
            </Row>
        </Container>
    );
}
