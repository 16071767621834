import { apiAxios as authAxios } from '../axios.config';

export const listActivitySheets = (queryParams) => {
	return authAxios.get(`api/activitySheet/list`, { params: queryParams });
};

export const saveActivitySheet = (data, queryParams) => {
	return authAxios.post('api/activitySheet', data, { params: queryParams });
};

export const getActivitySheet = (b10ExternalId) => {
  return authAxios.get(`/api/activitySheet/${b10ExternalId !== undefined ? b10ExternalId : ''}`)
}

export const getActivitySheetByProcessExternalId = (b9ProcessExternalId) => {
	return authAxios.get(`/api/activitySheet/process/${b9ProcessExternalId}`);
};

export const submitActivitySheetDecision = (b10ExternalId, data) => {
	return authAxios.post(`api/activitySheet/${b10ExternalId}/submitDecision/`, data);
};

export const activitySheetFormStatus = () => {
  return authAxios.get('/api/activitySheet/formStatus/')
}
