import React from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { TextAreaInputField } from '../bootstrap/TextAreaInputField';

export function StrategicDiagnosisStep({
	step,
	numberOfSteps,
	errors,
	handleChange,
	formikValues,
	handleGoBack,
	handleNextStep,
	readMode,
}) {
	const intl = useIntl();

	return (
		<div>
			<h4 className='text-primary mb-0'>
				<FormattedMessage id='developmentPlan.strategicDiagnosis' />
			</h4>
			<small className='text-secondary text-uppercase'>
				{intl.formatMessage(
					{ id: 'all.step' },
					{ step: step, numberOfSteps: numberOfSteps }
				)}
			</small>
			<fieldset disabled={readMode == true}>
				<Row>
					<Col md='12'>
						<Form.Label>
							<FormattedMessage id='strategicDiagnosis.companyDescription' />
							<br />
							<small>
								<FormattedMessage id='strategicDiagnosis.companyDescriptionSub' />
							</small>
						</Form.Label>
						<TextAreaInputField
							placeholder={intl.formatMessage(
								{
									id: 'annualComplementaryActivitiesPlanForm.characters.placeholder',
								},
								{ nChars: '1500' }
							)}
							name={`strategicDiagnosis.companyCharacterization`}
							handleChange={handleChange}
							value={formikValues.strategicDiagnosis.companyCharacterization}
							isInvalid={errors.strategicDiagnosis?.companyCharacterization}
							errorMessage={errors.strategicDiagnosis?.companyCharacterization}
							maxLength={1500}
						/>
					</Col>
				</Row>
				<Row>
					<Col md='12'>
						<Form.Label>
							<FormattedMessage id='strategicDiagnosis.marketDescription' />
							<br />
							<small>
								<FormattedMessage id='strategicDiagnosis.marketDescriptionSub' />
							</small>
						</Form.Label>
						<TextAreaInputField
							placeholder={intl.formatMessage(
								{
									id: 'annualComplementaryActivitiesPlanForm.characters.placeholder',
								},
								{ nChars: '1500' }
							)}
							name={`strategicDiagnosis.marketCharacterization`}
							handleChange={handleChange}
							value={formikValues.strategicDiagnosis.marketCharacterization}
							isInvalid={errors.strategicDiagnosis?.marketCharacterization}
							errorMessage={errors.strategicDiagnosis?.marketCharacterization}
							maxLength={1500}
						/>
					</Col>
				</Row>

				<Row>
					<Col md='6'>
						<TextAreaInputField
							labelId='strategicDiagnosis.opportunities'
							placeholder={intl.formatMessage(
								{
									id: 'annualComplementaryActivitiesPlanForm.characters.placeholder',
								},
								{ nChars: '1500' }
							)}
							name={`strategicDiagnosis.opportunities`}
							handleChange={handleChange}
							value={formikValues.strategicDiagnosis.opportunities}
							isInvalid={errors.strategicDiagnosis?.opportunities}
							errorMessage={errors.strategicDiagnosis?.opportunities}
							maxLength={1500}
						/>
					</Col>
					<Col md='6'>
						<TextAreaInputField
							labelId='strategicDiagnosis.threats'
							placeholder={intl.formatMessage(
								{
									id: 'annualComplementaryActivitiesPlanForm.characters.placeholder',
								},
								{ nChars: '1500' }
							)}
							name={`strategicDiagnosis.threats`}
							handleChange={handleChange}
							value={formikValues.strategicDiagnosis.threats}
							isInvalid={errors.strategicDiagnosis?.threats}
							errorMessage={errors.strategicDiagnosis?.threats}
							maxLength={1500}
						/>
					</Col>
				</Row>
				<Row>
					<Col md='12'>
						<TextAreaInputField
							labelId='strategicDiagnosis.installationsEquipment'
							placeholder={intl.formatMessage(
								{
									id: 'annualComplementaryActivitiesPlanForm.characters.placeholder',
								},
								{ nChars: '1500' }
							)}
							name={`strategicDiagnosis.facilitiesAndEquipments`}
							handleChange={handleChange}
							value={formikValues.strategicDiagnosis.facilitiesAndEquipments}
							isInvalid={errors.strategicDiagnosis?.facilitiesAndEquipments}
							errorMessage={errors.strategicDiagnosis?.facilitiesAndEquipments}
							maxLength={1500}
						/>
					</Col>
				</Row>
			</fieldset>
			<div className='form-actions mt-5'>
				<button
					className='btn btn-link'
					type='button'
					onClick={() => handleGoBack()}
				>
					<FormattedMessage id='all.back' />
				</button>

				<button
					className='btn btn-primary'
					type='button'
					onClick={() => handleNextStep()}
				>
					<FormattedMessage id='all.nextStep' />
				</button>
			</div>
		</div>
	);
}
