import {SubTemplate} from '../../components/general/SubTemplate';
import {Button, Col, Container, Modal, Row} from 'react-bootstrap';
import {AlertError} from '../../components/bootstrap/AlertError';
import React, {useEffect, useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {Link, useNavigate, useParams} from 'react-router-dom';
import {DocumentType} from '../../models/DocumentType';
import {getCandidatureByExternalId} from '../../rest/candidature';
import {currencyFormat} from '../../utils/CurrencyUtils';
import {CandidatureState} from "../../models/CandidatureState";
import {CandidatureFinancialUtils} from "../../utils/CandidatureFinancialUtils";
import {SyncSingleUploadArea} from "../SyncSingleUploadArea";

export function CandidatureValidation({ validationTitle, messageConfirmation, callbackHandler, isApprove }) {
  const intl = useIntl();

  const { externalId } = useParams();

  const navigate = useNavigate();

  const [show, setShow] = useState(false);
  const [error, setError] = useState(null);

  const [publicDocument, setPublicDocument] = useState(null);
  const [privateDocument, setPrivateDocument] = useState(null);
  const [publicDocumentType, setPublicDocumentType] = useState(DocumentType.VALIDATION_DOCUMENT);
  const [privateDocumentType, setPrivateDocumentType] = useState(DocumentType.VALIDATION_DOCUMENT_PRIVATE);
  const [accretionResults, setAccretionResults] = useState(null);



  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  async function fetchData() {
    try {

      const { data: candidature } = await getCandidatureByExternalId(
          externalId
      );

      const publicType = (CandidatureState.CONTESTATION_ANALYSIS === candidature.state || isApprove===true) ?DocumentType.FINAL_DECISION : DocumentType.VALIDATION_DOCUMENT;
      const privateType= (CandidatureState.CONTESTATION_ANALYSIS === candidature.state || isApprove===true) ?DocumentType.FINAL_DECISION_PRIVATE : DocumentType.VALIDATION_DOCUMENT_PRIVATE;
      setPublicDocumentType(publicType);
      setPrivateDocumentType(privateType);

      let candidatureEmployeeSupportOtherDetails = getInitialEmployeeSupportOtherValues(candidature?.candidatureEmployeeSupportOtherDetails.filter(detail => detail.kind === 'PROMOTER') )

      await fetchDataAccretion(candidature, candidatureEmployeeSupportOtherDetails);

    } catch (error) {
      setError(error);
    }
  }


  async function fetchDataAccretion(candidature,candidatureEmployeeSupportOtherDetails) {
    let result = [];

      for (const detail of candidatureEmployeeSupportOtherDetails) {
        let valueToPush = {}
        valueToPush['kind'] = detail.kind;
        let candidatureEmployeeSupportOtherDetailsArray = [];
        candidatureEmployeeSupportOtherDetailsArray.push(detail)


        const [
          subRepresentation, isInside, levelsAccretion, iefpAccretion
        ] = await Promise.all([
          await CandidatureFinancialUtils.getSubRepresentation(candidature.candidatureDetails.cae, candidature.promotersMetaData),
          await CandidatureFinancialUtils.getIsInside(candidature.parish),
          await CandidatureFinancialUtils.getLevelsAccretion(candidatureEmployeeSupportOtherDetailsArray),
          await CandidatureFinancialUtils.getIefpAccretion(candidatureEmployeeSupportOtherDetailsArray)
        ]);
        valueToPush['subRepresentation'] = subRepresentation;
        valueToPush['isInside'] = isInside;

        valueToPush['levelsAccretion'] = levelsAccretion;
        valueToPush['iefpAccretion'] = iefpAccretion;
        valueToPush['iSInnovationProject'] = detail.iSInnovationProject;
        valueToPush['reasonToInnovationProject'] = detail.reasonToInnovationProject;

        console.log(detail)

        if (detail.kind !== 'PROMOTER') {
          const details = candidature.candidatureEmployeeSupportOtherDetails.filter(detail => detail.kind === 'PROMOTER')[0];
          const economicDetails = candidature.economicViabilityAccretionValidations.filter(d => d.kind === detail.kind)[0]
          valueToPush['subRepresentation'] = economicDetails.isSubRepresentationValid === true ? subRepresentation : !subRepresentation;
          valueToPush['isInside'] = economicDetails.isInsideValid === true ? isInside : !isInside;
          valueToPush['levelsAccretion'] = economicDetails.isLevelsValid === true ? CandidatureFinancialUtils.getLevelsAccretion(details) : economicDetails.levelsValue;

          valueToPush['iefpAccretion'] = economicDetails.isIefpWorkstationsValid === true ? CandidatureFinancialUtils.getIefpAccretion(details) : economicDetails.iefpWorkstationsValue;
          valueToPush['iSInnovationProject'] = economicDetails.isInnovationProjectValid === true ? detail.iSInnovationProject : !detail.iSInnovationProject;
        }

        const accretionTotal = await CandidatureFinancialUtils.getAccretionTotal(valueToPush['subRepresentation'], valueToPush['isInside'], valueToPush['levelsAccretion'], valueToPush['iefpAccretion'], {iSInnovationProject: valueToPush['iSInnovationProject']});
        valueToPush['accretionTotal'] = accretionTotal;


        let investment = candidature.financialInvestmentDetails.filter(investment => investment.kind === detail.kind && investment.financialInvestmentType == 'TOTAL')
        let plan = candidature.investmentPlanDetails.filter(plan => plan.kind === detail.kind && plan.investmentType == 'TOTAL')
        console.log(plan);
        console.log(candidature.investmentPlanDetails)
        if (investment?.length !== 0) {
          valueToPush['investment'] = investment[0];
          valueToPush['plan'] = plan[0];
        }
        result.push(valueToPush);

      }
    setAccretionResults(result);


  }



  function getInitialEmployeeSupportOtherValues(employeeSupportOtherDetails) {
    let values = [];

    if(isApprove==true){
      return employeeSupportOtherDetails.filter(detail => detail.kind === 'PROMOTER')
    }

    const finalValues = employeeSupportOtherDetails.filter(detail => detail.kind === 'FINAL');
    if(finalValues?.length>0){
      return finalValues;
    }

    const iefpValues = employeeSupportOtherDetails.filter(detail => detail.kind === 'IEFP');
    if(iefpValues?.length>0){
      return iefpValues;
    }
    return  employeeSupportOtherDetails;
  }

  function canSubmit() {
    return publicDocument !== null && privateDocument !== null;
  }

  async function handleConfirm() {
    try {
      await callbackHandler(publicDocument, privateDocument);
      navigate(`/candidatura/${externalId}`);
    } catch (error) {
      setError(error);
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <SubTemplate hasBackButton>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header>
          <FormattedMessage id={`${validationTitle}`} />
        </Modal.Header>
        <Modal.Body>
          <FormattedMessage id={`${messageConfirmation}`} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant='outline-secondary' onClick={handleClose}>
            <FormattedMessage id='all.no' />
          </Button>
          <Button variant='outline-primary' onClick={handleConfirm}>
            <FormattedMessage id='all.yes' />
          </Button>
        </Modal.Footer>
      </Modal>

      <Container>
        <h2 className='mb-1 text-secondary md-10 offset-1'>
          <FormattedMessage id={`${validationTitle}`} />
        </h2>

        <AlertError error={error} />
        <Row className='md-10 offset-1 mt-4'>
          <small className='text-primary text-uppercase'>
            {intl.formatMessage(
              { id: 'candidatureValidation.requestedIEFPAmount' },
              { value: isApprove ? 'aprovados' : 'não aprovados' }
            )}
          </small>
          <Col md='10'>
            <p className='mt-1 mb-2'>
              <span className='text-secondary'>
                <FormattedMessage id='candidatureForm.investmentSupport' />
              </span>
              <span>
                {accretionResults?.[0].investment !== undefined ? (currencyFormat((  (CandidatureFinancialUtils.getInterestFreeLoan(accretionResults?.[0].investment,accretionResults?.[0].plan,accretionResults?.[0].accretionTotal) + CandidatureFinancialUtils.calculateNotRefundWithAccretion(accretionResults?.[0].investment,accretionResults?.[0].plan,accretionResults?.[0].accretionTotal)) )       )):0}
                </span>

            </p>
          </Col>
          <Col md='10'>
            <p className='mt-1 mb-2'>
              <span className='text-secondary'>
                <FormattedMessage id='candidatureForm.interestFreeLoan' />
              </span>
              <span>
                {accretionResults?.[0].investment !== undefined ? currencyFormat(CandidatureFinancialUtils.getInterestFreeLoan(accretionResults?.[0].investment,accretionResults?.[0].plan,accretionResults?.[0].accretionTotal)  ) : 0}
                </span>
            </p>
          </Col>
          <Col md='10'>
            <p className='mt-1 mb-2'>
              <span className='text-secondary'>
                <FormattedMessage id='candidatureForm.nonRefundableSubsidy' />
              </span>

              <span>
                  {accretionResults?.[0].investment !== undefined ? currencyFormat(CandidatureFinancialUtils.calculateNotRefundWithAccretion(accretionResults?.[0].investment,accretionResults?.[0].plan,accretionResults?.[0].accretionTotal)):0}
                </span>

            </p>
          </Col>
        </Row>

        <Row className='md-10 offset-1 mt-4'>
          <small className='text-primary text-uppercase'>
            {intl.formatMessage(
              { id: 'candidatureValidation.requestedWorkStationsAmount' },
              { value: isApprove ? 'aprovados' : 'não aprovados' }
            )}
          </small>
          {accretionResults && (
            <Col className='mt-2'>
              <span className='text-secondary'>
                <FormattedMessage id='candidatureTabs.totalJobSubsidyValue' />
              </span>
              <span>
                  {currencyFormat(accretionResults?.[0].investment.workStationsCreationBenefits)}
                </span>
            </Col>
          )}
        </Row>

        <Row className='md-10 offset-1 mt-4'>
          <Col md='10'>
            <small className='text-primary text-uppercase'>
              <FormattedMessage id='candidatureValidation.decisionDocuments' />
            </small>
            <SyncSingleUploadArea
                name='files'
                document={publicDocument}
                setDocument={setPublicDocument}
                acceptExtensions='.pdf,.docx'
                documentType={publicDocumentType}
            />
            <SyncSingleUploadArea
                name='files'
                document={privateDocument}
                setDocument={setPrivateDocument}
                acceptExtensions='.pdf,.docx'
                documentType={privateDocumentType}
            />
          </Col>
        </Row>
        <div className='form-actions'>
          <Link to={`/candidatura/${externalId}`}>
            <button className='btn btn-link' type='button'>
              <FormattedMessage id='all.backButtonText' />
            </button>
          </Link>
          <Button variant='primary' onClick={handleShow} disabled={!canSubmit()}>
            <FormattedMessage id='all.submitButtonText' />
          </Button>
        </div>
      </Container>
    </SubTemplate>
  );
}
