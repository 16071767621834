import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import {
  BsFacebook,
  BsInstagram,
  BsLinkedin,
  BsTwitter,
  BsYoutube,
  BsEnvelope
} from 'react-icons/bs';
import { FormattedMessage } from 'react-intl';

function Footer() {
  return (
    <footer id='contactos'>
      <div className='container'>
        <Row className='py-5'>
          <Col md={{ span: 6, offset: 3 }} lg={{ span: 5, offset: 0 }}>

            <p className='text-muted'>
              <small className='d-block'><FormattedMessage id='footer.text5' /></small>
              <BsEnvelope className='normalIconSize' />
              <a href="mailto:empreendexxi@startupportugal.com"
                target='_blank'
                rel='noreferrer'
                className='font-weight-bold'><FormattedMessage id='footer.link1' />
              </a>
            </p>
            <p className='text-muted'>
              <small className='d-block'><FormattedMessage id='footer.text6' /></small>
              <BsEnvelope className='normalIconSize' />
              <a href="mailto:empreendeXXI.empe@iefp.pt"
                target='_blank'
                rel='noreferrer'
                className='font-weight-bold'><FormattedMessage id='footer.link2' />
              </a>
            </p>
            <p className='text-muted'>
              <small>
                <FormattedMessage id='footer.text1' />
                <a
                  href='https://startupportugal.com/'
                  target='_blank'
                  rel='noreferrer'
                >
                  <FormattedMessage id='footer.text2' />
                </a>
                <FormattedMessage id='footer.text3' />
              </small>
            </p>
            <p className='text-muted'>
              <small>
                <FormattedMessage id='footer.text4' />
              </small>
            </p>
          </Col>
          <Col lg='4' className='text-center my-lg-0 my-sm-4 py-4'>
            <div className='mb-3'>
              <a href='https://www.iefp.pt/' target='_blank' rel='noreferrer'>
                <img alt='IEFP' src={require('../../images/iefp-logo.png')} />
              </a>
            </div>
            <a
              href='https://www.facebook.com/iefp.pt/'
              target='_blank'
              rel='noreferrer'
              className='btn btn-success mx-2 social-contact'
            >
              <BsFacebook />
            </a>
            <a
              href='https://www.youtube.com/channel/UCTfurXy2KHH6heIaqb7mjrA'
              target='_blank'
              rel='noreferrer'
              className='btn btn-success mx-2 social-contact'
            >
              <BsYoutube />
            </a>
            <a
              href='https://www.instagram.com/iefp_portugal/'
              target='_blank'
              rel='noreferrer'
              className='btn btn-success mx-2 social-contact'
            >
              <BsInstagram />
            </a>
          </Col>
          <Col lg='3' className='text-center my-lg-0 my-sm-4 py-4'>
            <div className='mb-3'>
              <a
                href='https://startupportugal.com/'
                target='_blank'
                rel='noreferrer'
              >
                <img
                  alt='Startup Portugal'
                  src={require('../../images/startup-portugal-logo.png')}
                />
              </a>
            </div>
            <a
              className='btn btn-warning text-white mx-2 social-contact'
              href='https://twitter.com/startupportugal'
              target='_blank'
              rel='noreferrer'
            >
              <BsTwitter />
            </a>
            <a
              className='btn btn-warning text-white mx-2 social-contact'
              href='https://www.instagram.com/startupportugal_/'
              target='_blank'
              rel='noreferrer'
            >
              <BsInstagram />
            </a>
            <a
              className='btn btn-warning text-white mx-2 social-contact'
              href='https://www.linkedin.com/company/startupportugal/'
              target='_blank'
              rel='noreferrer'
            >
              <BsLinkedin />
            </a>
          </Col>
        </Row>
      </div>
    </footer>
  );
}

export default Footer;
