import {useState} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {UnemploymentPaymentDelegate} from "./UnemploymentPaymentDelegate";
import {sendMessage, sendMessageToMCEEntity} from "../../rest/messages";
import {Modal} from "react-bootstrap";

export function UnemploymentPaymentDelegationRecordModal({show, setShow, candidature, onSubmitCallback}) {
    const intl = useIntl();
    const [error, setError] = useState(null);
    const [entity, setEntity] = useState(null);

    async function onSubmit() {
        try {
            //TODO(jaime)🧔🏻: How deal with toast?
            //setShowSaveToast(true);
            setShow(false);
            onSubmitCallback?.()
        } catch (error) {
            setError(error);
        }
    }

    function handleClose() {
        setShow(false);
    }

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title><FormattedMessage id='candidature.payments.unemployment.modal.title' /></Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <UnemploymentPaymentDelegate candidature={candidature} onSubmitCallback={onSubmit} />
            </Modal.Body>
        </Modal>

    );
}

