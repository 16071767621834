import React, { useEffect, useRef, useState } from 'react';
import {
    Form,
    Row,
    Container,
    Col,

} from 'react-bootstrap';
import { Formik } from 'formik';
import { FormattedMessage, useIntl } from 'react-intl';
import Loading from '../../components/general/Loading';
import { SubTemplate } from '../general/SubTemplate';
import {FaRegCheckCircle, FaRegSave} from 'react-icons/fa';
import { handleError, isNotBusinessError } from '../../utils/handleError';
import { FormSideNavBar } from '../general/FormSideNavBar';
import * as formSteps from '../../components/eaAccreditation';
import { getDistricts } from '../../rest/administrativeDivision';
import { listQualificationLevels } from '../../rest/qualificationLevel';
import {
    decideAccreditationValidity, deleteRenewalDocument, getEntityAccreditation, getEntityAccreditationByNif,
    getEntityAccreditationDecision, getRenewal,
    saveRenewal, submitRenewal, uploadRenewalDocument,
} from '../../rest/entity';
import {getEaAccreditationFormSchema} from '../../pages/public/AddEaAccreditation';
import { listEntityDocuments } from '../../rest/document';
import { EaAccreditationFormCustomValidator } from '../../utils/EaAccreditationFormCustomValidator';
import { AlertError } from '../bootstrap/AlertError';
import {useNavigate, useParams} from 'react-router-dom';
import {createCustomErrorMessage} from "../../hooks/errorMessage";
import {SuccessToast} from "../general/SuccessToast";
import {isIEFPUser} from "../../authentication/authenticationHelper";
import {DecisionDialog} from "../bootstrap/DecisionDialog";
import {AlertSuccess} from "../bootstrap/AlertSuccess";
import {EaRenewalDocuments} from "./form/EaRenewalDocuments";
import {EaRenewalDates} from "./form/EaRenewalDates";
import {DocumentType} from "../../models/DocumentType";
import * as yup from "yup";
import {StateAndDateBadge} from "../general/stateBadge/StateAndDateBadge";
import {DateUtils} from "../../utils/DateUtils";

export function AddAccreditationRenewal() {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [navErrors, setNavErrors] = useState([]);
    const [formStep, setFormStep] = useState(0);
    const [districList, setDistrictList] = useState();
    const [districtOptions, setDistrictOptions] = useState();
    const [qualificationLevelList, setQualificationLevelList] = useState([]);
    const [documents, setDocuments] = useState([]);
    const [renewalDocuments, setRenewalDocuments] = useState([]);
    const [renewal, setRenewal] = useState(null);
    const [accreditation, setAccreditation] = useState(null);
    const [formikInitialValues, setFormikInitialValues] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [showSaveToast, setShowSaveToast] = useState(false);
    const [showSubmittedToast, setShowSubmittedToast] = useState(false);
    const [showSubmitModal, setShowSubmitModal] = useState(false);

    const [lsItem, setLsItem] = useState(null);
    const {entityNIPC} = useParams();

    const intl = useIntl();
    const navigate = useNavigate();
    const isIEFP = isIEFPUser();

    const formikPropsRef = useRef(null);

    const acceptedQualificationLevels = ['4', '6', '7', '8'];
    const managerAcceptedQualificationLevels = ['6', '7', '8'];

    const tabsIds = [
        'eaAccreditationForm.sideNavBar.identificationEA',
        'eaAccreditationForm.sideNavBar.experienceEA',
        'eaAccreditationForm.sideNavBar.geographicalArea',
        'eaAccreditationForm.sideNavBar.resources1',
        'eaAccreditationForm.sideNavBar.resources2',
        'eaAccreditationForm.sideNavBar.resources3',
        'eaAccreditationForm.sideNavBar.declaration',
        'eaAccreditationForm.sideNavBar.renewal.dates',
        'eaAccreditationForm.sideNavBar.renewal.docs'
    ];

    const requiredDocuments = [
        'AT_DECLARATION',
        'SS_DECLARATION',
    ];

    const requiredRenewalDocuments = [
        DocumentType.RENEWAL_INVITATION_LETTER,
        DocumentType.RENEWAL_ENTITY_RESPONSE_TO_INVITATION,
        DocumentType.RENEWAL_DIRECTIVE_COUNCIL_SERVICE_INFORMATION,
        DocumentType.RENEWAL_DIRECTIVE_COUNCIL_DELIBERATION,
        DocumentType.RENEWAL_NOTIFICATION_LETTER_AND_PROTOCOL,
        DocumentType.RENEWAL_SIGNED_PROTOCOL
    ];


    useEffect(() => {
        setLoading(true);
        if (submitted) {
            setSubmitted(() => false);
        } else {
            fetchData();
            checkLocalStorageItems();
        }
    }, [submitted]);



    function checkLocalStorageItems() {
        [
            'eaAccreditationSubmited',
            'eaAccreditationApproved',
            'eaAccreditationDocsNeeded',
            'eaAccreditationAppealed',
            'eaAccreditationRejected',
            'eaAccreditationFullyApproved'
        ].forEach((s) => {
            if (sessionStorage.getItem(s)) {
                setLsItem(s);
            }
        });
    }

    async function fetchData() {
        try {
            let [
                { data: renewalRes },
                { data: districts },
                { data: qualificationLevels },
                { data: currAccred }
            ] = await Promise.all([
                await getRenewal(entityNIPC === undefined ? null : entityNIPC),
                await getDistricts(true),
                await listQualificationLevels(),
                await (entityNIPC === undefined ? getEntityAccreditation() : getEntityAccreditationByNif(entityNIPC)),
            ]);

            setRenewal(renewalRes);
            setAccreditation(currAccred);


            const { data: entityDocuments } = await listEntityDocuments(entityNIPC === undefined ? null : {entityNif: entityNIPC});
            let entityAccreditationDocuments = requiredDocuments.map((rd) => {
                let dTypeIndex = entityDocuments.findIndex(
                    (d) => d.documentType === rd
                );
                if (dTypeIndex < 0) {
                    //it means the document wasnt found
                    return {
                        externalId: '',
                        documentType: rd,
                        name: '',
                        content: null,
                        submited: false,
                        error: '',
                    };
                } else {
                    return {
                        externalId: entityDocuments[dTypeIndex].externalId,
                        documentType: entityDocuments[dTypeIndex].documentType,
                        documentPath: entityDocuments[dTypeIndex].documentPath,
                        name: entityDocuments[dTypeIndex].name,
                        content: '',
                        submited: true,
                        error: '',
                    };
                }
            });
            setDocuments(entityAccreditationDocuments);

            let renDocs = renewalRes.documents && Array.isArray(renewalRes.documents) ? renewalRes.documents : [];
            let renewalDocumentsLoad = requiredRenewalDocuments.map((rd) => {
                let dTypeIndex = renDocs.findIndex(
                    (d) => d.documentType === rd
                );
                if (dTypeIndex < 0) {
                    //it means the document wasnt found
                    return {
                        externalId: '',
                        documentType: rd,
                        name: '',
                        content: null,
                        submited: false,
                        error: '',
                    };
                } else {
                    return {
                        externalId: renDocs[dTypeIndex].externalId,
                        documentType: renDocs[dTypeIndex].documentType,
                        documentPath: renDocs[dTypeIndex].documentPath,
                        name: renDocs[dTypeIndex].name,
                        content: '',
                        submited: true,
                        error: '',
                    };
                }
            });
            setRenewalDocuments(renewalDocumentsLoad);


            setFormikInitialValues(
                getFormikInitialValues(renewalRes, districts)
            );

            setDistrictList(districts);
            let districtNamesList = [];
            districts.map((districtObj) =>
                districtNamesList.push(districtObj.description)
            );
            setDistrictOptions(districtNamesList);
            setQualificationLevelList(qualificationLevels);
        } catch (error) {
            console.log(error);
            setError(error);
        } finally {
            setLoading(false);
        }
    }

    function scrollPageToTop() {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }

    function handleNavError(error) {
        let errors = error.response.data.message
            .split(',')
            .map((value) => Number.parseInt(value));
        setNavErrors(errors);
    }

    function handlePreviousStep() {
        scrollPageToTop();
        setFormStep(formStep - 1);
    }

    function handleNextStep() {
        scrollPageToTop();
        setFormStep(formStep + 1);
    }


    function parseBackendInterventionTypeToFrontendKeys(type) {
        switch (type) {
            case 'Investment_Business_Plans':
                return 'entitiesPerYearInvestment';

            case 'FOLLOW_UP':
                return 'entitiesPerYearFollowUp';

            case 'CONSULTING':
                return 'entitiesPerYearConsultancy';

            case 'INCUBATOR':
                return 'entitiesPerYearIncubation';

            case 'DOING_WORKSHOPS':
                return 'actionsPerYearWorkshops';

            case 'DOING_SEMINARS':
                return 'actionsPerYearSeminaries';

            case 'DOING_COURSEWARE':
                return 'actionsPerYearDidacticMaterials';

            case 'DOING_BOOTCAMP':
                return 'actionsPerYearBootCamps';

            default:
                console.log("Something's wrong");
        }
    }

    function parseBackendMaterialResourceTypeToFrontendKeys(type) {
        switch (type) {
            case 'ATTENDANCE_ROOM':
                return 'attendanceRooms';

            case 'MEET_ROOM':
                return 'meetingRooms';

            case 'FORMATION_ROOM':
                return 'formationRooms';

            case 'INCUBATOR':
                return 'incubators';

            case 'INCUBATOR_SPACE':
                return 'incubationSpaces';

            case 'PHONES_NUMBER':
                return 'phones';

            case 'COMPUTER':
                return 'computers';

            case 'INTERNET_SPOT':
                return 'internetPoints';

            case 'WIFI':
                return 'wifis';

            case 'SCANNER':
                return 'scanners';

            case 'PHOTOCOPIER':
                return 'printers';

            case 'VEHICLE':
                return 'vehicles';

            default:
                console.log("Something's wrong");
        }
    }

    function getFormikInitialValues(eaRenewal, districts) {
        let interventions = {};
        let eaAccreditation = eaRenewal.accreditation;

        eaAccreditation.interventions.forEach((intervention) => {
            interventions[
                parseBackendInterventionTypeToFrontendKeys(intervention.type)
                ] = {
                ...interventions[
                    parseBackendInterventionTypeToFrontendKeys(intervention.type)
                    ],
                [intervention.year]: {
                    value: intervention.value,
                    externalId: intervention.externalId,
                },
            };
        });

        let equipmentDistribution = {};
        eaAccreditation.equipmentDistribution?.forEach((distribution) => {
            let districtName = districts.filter(
                (d) => d.code === distribution.districtCode
            )[0].description;

            equipmentDistribution[districtName] = {
                ...equipmentDistribution[districtName],
                [parseBackendMaterialResourceTypeToFrontendKeys(
                    distribution.materialResourceType
                )]: {
                    externalId: distribution.externalId,
                    value: distribution.value,
                },
            };
        });

        let entityResponsibles = eaAccreditation.entity.responsibles;
        entityResponsibles.forEach((er) => {
            er.name = er.name ? er.name : '';
            er.duty = er.duty ? er.duty : '';
        });

        return {
            entity: {
                description: eaAccreditation.entity.description
                    ? eaAccreditation.entity.description
                    : '',
                nif: eaAccreditation.entity.nif ? eaAccreditation.entity.nif : '',
                address: eaAccreditation.entity.address
                    ? eaAccreditation.entity.address
                    : '',
                postalCode4Digits: eaAccreditation.entity.postalCode4Digits
                    ? eaAccreditation.entity.postalCode4Digits
                    : '',
                postalCode3Digits: eaAccreditation.entity.postalCode3Digits
                    ? eaAccreditation.entity.postalCode3Digits
                    : '',
                location: eaAccreditation.entity.location
                    ? eaAccreditation.entity.location
                    : '',
                phone: eaAccreditation.entity.phone ? eaAccreditation.entity.phone : '',
                email: eaAccreditation.entity.email ? eaAccreditation.entity.email : '',
                districtCode: eaAccreditation.entity.districtCode
                    ? eaAccreditation.entity.districtCode
                    : '-1',
                profile: eaAccreditation.entity.profile
                    ? eaAccreditation.entity.profile
                    : '-1',
                profileDescription: eaAccreditation.entity.profileDescription
                    ? eaAccreditation.entity.profileDescription
                    : '',
                cae: eaAccreditation.entity.cae ? eaAccreditation.entity.cae : '',
                score: eaAccreditation.entity.score ? eaAccreditation.entity.score:'',
                state: eaAccreditation.entity.state,
                connectionWithIEFP: eaAccreditation.entity.connectionWithIEFP,
                connectionWithIEFPDesc: eaAccreditation.entity.connectionWithIEFPDesc
                    ? eaAccreditation.entity.connectionWithIEFPDesc
                    : '',
                consultingAreas: eaAccreditation.entity.consultingAreas
                    ? eaAccreditation.entity.consultingAreas
                    : [],
                atDeclarationAuthorized: eaAccreditation.entity.atDeclarationAuthorized,
                ssDeclarationAuthorized: eaAccreditation.entity.ssDeclarationAuthorized,
                nationalMemberYear: eaAccreditation.entity.nationalMemberYear
                    ? eaAccreditation.entity.nationalMemberYear
                    : '',
                responsibles: entityResponsibles,
            },
            ...interventions,
            getList: eaAccreditation.getList ? eaAccreditation.getList : [],
            interventionDistrictCodes: eaAccreditation.interventionDistrictCodes
                ? eaAccreditation.interventionDistrictCodes
                : [],
            tgpList: eaAccreditation.tgpList ? eaAccreditation.tgpList : [],
            incubatorLocations: eaAccreditation.incubatorLocations
                ? eaAccreditation.incubatorLocations
                : [],
            equipmentDistribution: eaAccreditation.equipmentDistribution
                ? equipmentDistribution
                : {},
            startDate: eaRenewal?.startDate,
            endDate: eaRenewal?.endDate
        };
    }

    if (submitted) {
        //return <Navigate replace to={'/'} />;
    }

    if (loading) {
        return <Loading />;
    }

    const eaAccreditationFormSchema = getEaAccreditationFormSchema().shape({
        startDate: yup
            .date()
            .min(DateUtils.nextDay(new Date(accreditation?.startDate || new Date(0))), <FormattedMessage id='ea.accreditation.renewal.startDate.bellowMin' />)
            .required(<FormattedMessage id='errors.fieldRequiredText' />),
        endDate: yup
            .date()
            .min(DateUtils.nextDay(new Date(accreditation?.endDate || new Date(0))), <FormattedMessage id='ea.accreditation.renewal.endDate.bellowMin' />)
            .required(<FormattedMessage id='errors.fieldRequiredText' />)
            .test('is-after-start', <FormattedMessage id={'ea.accreditation.renewal.endDate.bellowStartDate'}/>, (endDate, context) => {
                const { startDate } = context.parent;
                return !startDate || !endDate || startDate < endDate;
            }),
    });


    const readMode = !isIEFP || !(['DRAFT'].includes(renewal.state));
    if (error && isNotBusinessError(error)) {
        return handleError(error);
    }

    function parseInterventionKey(key) {
        switch (key) {
            case 'entitiesPerYearInvestment':
                return 'Investment_Business_Plans';

            case 'entitiesPerYearFollowUp':
                return 'FOLLOW_UP';

            case 'entitiesPerYearConsultancy':
                return 'CONSULTING';

            case 'entitiesPerYearIncubation':
                return 'INCUBATOR';

            case 'actionsPerYearWorkshops':
                return 'DOING_WORKSHOPS';

            case 'actionsPerYearSeminaries':
                return 'DOING_SEMINARS';

            case 'actionsPerYearDidacticMaterials':
                return 'DOING_COURSEWARE';

            case 'actionsPerYearBootCamps':
                return 'DOING_BOOTCAMP';
            default:
                console.log("Something's wrong");
        }
    }

    function parseMaterialResourceTypeKey(key) {
        switch (key) {
            case 'attendanceRooms':
                return 'ATTENDANCE_ROOM';

            case 'meetingRooms':
                return 'MEET_ROOM';

            case 'formationRooms':
                return 'FORMATION_ROOM';

            case 'incubators':
                return 'INCUBATOR';

            case 'incubationSpaces':
                return 'INCUBATOR_SPACE';

            case 'phones':
                return 'PHONES_NUMBER';

            case 'computers':
                return 'COMPUTER';

            case 'internetPoints':
                return 'INTERNET_SPOT';

            case 'wifis':
                return 'WIFI';

            case 'scanners':
                return 'SCANNER';

            case 'printers':
                return 'PHOTOCOPIER';

            case 'vehicles':
                return 'VEHICLE';

            default:
                console.log("Something's wrong");
        }
    }

    function parseInterventions(formikValues) {
        let interventions = [];

        Object.keys(formikValues)
            .filter(
                (key) =>
                    key.startsWith('entitiesPerYear') || key.startsWith('actionsPerYear')
            )
            .map((interventionKey) =>
                Object.keys(formikValues[interventionKey]).map((year) => {
                    interventions.push({
                        type: parseInterventionKey(interventionKey),
                        year: year,
                        value: formikValues[interventionKey][year].value,
                        externalId: formikValues[interventionKey][year].externalId,
                    });
                })
            );

        return interventions;
    }

    function parseEquipmentDistribution(formikValues) {
        let equipmentDistribution = [];

        Object.keys(formikValues.equipmentDistribution).map((key) =>
            Object.keys(formikValues.equipmentDistribution[key]).map((material) =>
                equipmentDistribution.push({
                    districtCode: districList.filter(
                        (district) => district.description === key
                    )[0].code,
                    materialResourceType: parseMaterialResourceTypeKey(material),
                    value: formikValues.equipmentDistribution[key][material].value,
                    externalId:
                    formikValues.equipmentDistribution[key][material].externalId,
                })
            )
        );

        return equipmentDistribution;
    }

    function parseFormikValuesToAPICall() {
        let formikValues = formikPropsRef.current.values;

        return {
            entity: formikValues.entity,

            getList: formikValues.getList,

            interventions: parseInterventions(formikValues),

            interventionDistrictCodes: formikValues.interventionDistrictCodes,

            tgpList: formikValues.tgpList,

            incubatorLocations: formikValues.incubatorLocations,

            equipmentDistribution: parseEquipmentDistribution(formikValues),

            startDate: formikValues.startDate,

            endDate: formikValues.endDate
        };
    }

    async function handleSave() {
        try {
            let formikParsedValues = parseFormikValuesToAPICall();
            let accreditationRenewalDto = {
                startDate: formikParsedValues.startDate,
                endDate: formikParsedValues.endDate,
                accreditation: formikParsedValues
            }
            let { data: res } = await saveRenewal(entityNIPC, accreditationRenewalDto);
            formikPropsRef.current.setValues(
                getFormikInitialValues(res, districList)
            );
            setRenewal(res);
            setNavErrors([]);
            formikPropsRef.current.setErrors({});

            setShowSaveToast(true);
        } catch (error) {
            console.log(error);
            setError(error);
        }
    }

    async function validateAndOpenSubmissionModal() {
        let errors = await validateForm();
        setNavErrors(errors);
        if (errors.length === 0) {
            setShowSubmitModal(true);
        }
    }

    async function handleSubmit() {
        let formikParsedValues = parseFormikValuesToAPICall();
        let accreditationRenewalDto = {
            startDate: formikParsedValues.startDate,
            endDate: formikParsedValues.endDate,
            accreditation: formikParsedValues
        }
        try {
            let { data: res } = await submitRenewal(entityNIPC, accreditationRenewalDto);
            setRenewal(res);
            setFormikInitialValues(getFormikInitialValues(res, districList));
            setSubmitted(true);
            sessionStorage.setItem('eaAccreditationSubmited', true);
            setShowSubmittedToast(true);
        } catch (err) {
            console.log(err)
            if (err?.response?.data?.exception === 'ValidationException') {
                handleNavError(err);
            }
            setError(err);
        }

    }

    async function submitValidate() {
        try{
            await decideAccreditationValidity(entityNIPC, true, null);
            setSubmitted(true);
        } catch (error) {
            console.log(error)
            setError(error);
        }
    }







    async function validateForm() {
        let errors = [];

        const formikErrors = await formikPropsRef.current.validateForm();

        Object.keys(formikErrors).forEach((key) => {
            switch (key) {
                case 'entity':
                    Object.keys(formikErrors[key]).forEach((entityErrorKey) => {
                        if (
                            entityErrorKey === 'connectionWithIEFP' ||
                            entityErrorKey === 'connectionWithIEFPDesc' ||
                            entityErrorKey === 'consultingAreas'
                        )
                            errors.push(1);
                        else if (entityErrorKey === 'responsibles') {
                            errors.push(6);
                        } else {
                            errors.push(0);
                        }
                    });
                    break;
                case 'getList':
                    if (Array.isArray(formikErrors[key])) {
                        formikErrors[key].forEach(
                            (getErrorObj) =>
                                getErrorObj !== undefined &&
                                Object.keys(getErrorObj).forEach((getErrorKey) => {
                                    if (getErrorKey === 'qualificationLevelCode') {
                                        errors.push(3);
                                    } else {
                                        errors.push(0);
                                    }
                                })
                        );
                    } else {
                        errors.push(0);
                    }
                    break;
                case 'entitiesPerYearInvestment':
                    errors.push(1);
                    break;
                case 'entitiesPerYearFollowUp':
                    errors.push(1);
                    break;
                case 'entitiesPerYearConsultancy':
                    errors.push(1);
                    break;
                case 'entitiesPerYearIncubation':
                    errors.push(1);
                    break;
                case 'actionsPerYearWorkshops':
                    errors.push(1);
                    break;
                case 'actionsPerYearSeminaries':
                    errors.push(1);
                    break;
                case 'actionsPerYearDidacticMaterials':
                    errors.push(1);
                    break;
                case 'actionsPerYearBootCamps':
                    errors.push(1);
                    break;
                case 'interventionDistrictCodes':
                    errors.push(2);
                    break;
                case 'tgpList':
                    errors.push(3);
                    break;
                case 'incubatorLocations':
                    errors.push(4);
                    break;
                case 'equipmentDistribution':
                    errors.push(5);
                    break;
                case 'startDate':
                case 'endDate':
                    errors.push(7);
                    break;
                default:
                    break;
            }
        });

        let errorMessages=[];

        if (
            !EaAccreditationFormCustomValidator.validateGETListSize(
                formikPropsRef.current.values,
                setError,
                intl,errorMessages
            )
        ) {
            errors.push(0);
        }

        if (
            !EaAccreditationFormCustomValidator.validateTGPListSize(
                formikPropsRef.current.values,
                setError,
                intl,errorMessages
            )
        ) {
            errors.push(3);
        }

        if (
            !EaAccreditationFormCustomValidator.validateSsAndAtAuthorizations(
                formikPropsRef.current.values,
                documents,
                setError,
                intl,errorMessages
            )
        ) {
            errors.push(6);
        }

        if (
            !EaAccreditationFormCustomValidator.validateGETAndDistricts(
                formikPropsRef.current.values,
                setError,
                intl,
                errorMessages,
                formikPropsRef.current.setFieldError,
                districList
            )
        ) {
            errors.push(0);
            errors.push(2);
        }

        if (
            !EaAccreditationFormCustomValidator.validateRenewalDocuments(
                formikPropsRef.current.values,
                renewalDocuments,
                setError,
                intl,errorMessages
            )
        ) {
            errors.push(8);
        }

        if(errorMessages.length>0){
            let message = (errorMessages.length === 1) ? createCustomErrorMessage(errorMessages[0]) : createCustomErrorMessage( '<p>'+  intl.formatMessage({
                id: 'errors.more'
            })+'</p><p> - '+ errorMessages.join('</p><p> - ')+'</p>') ;

            setError(message);
        }

        return errors;
    }

    function renderStep(formikProps) {
        formikPropsRef.current = formikProps;

        switch (formStep) {
            case 0:
                return (
                    <formSteps.EaAccreditationFormStep1
                        formStep={1}
                        numberOfSteps={tabsIds.length}
                        errors={formikProps.errors}
                        formikValues={formikProps.values}
                        handleChange={formikProps.handleChange}
                        handleNextStep={handleNextStep}
                        setFieldValue={formikProps.setFieldValue}
                        districts={districList}
                        districtOptions={districtOptions}
                        readMode={readMode}
                    />
                );
            case 1:
                return (
                    <formSteps.EaAccreditationFormStep2
                        formStep={2}
                        numberOfSteps={tabsIds.length}
                        errors={formikProps.errors}
                        formikValues={formikProps.values}
                        handleChange={formikProps.handleChange}
                        handlePreviousStep={handlePreviousStep}
                        handleNextStep={handleNextStep}
                        setFieldValue={formikProps.setFieldValue}
                        tableHeaders={Object.keys(
                            formikProps.values.entitiesPerYearInvestment
                        )}
                        readMode={readMode}
                    />
                );
            case 2:
                return (
                    <formSteps.EaAccreditationFormStep3
                        formStep={3}
                        numberOfSteps={tabsIds.length}
                        errors={formikProps.errors}
                        formikValues={formikProps.values}
                        handlePreviousStep={handlePreviousStep}
                        handleNextStep={handleNextStep}
                        districts={districList}
                        setFieldValue={formikProps.setFieldValue}
                        readMode={readMode}
                    />
                );
            case 3:
                return (
                    <formSteps.EaAccreditationFormStep4
                        formStep={4}
                        numberOfSteps={tabsIds.length}
                        errors={formikProps.errors}
                        formikValues={formikProps.values}
                        handlePreviousStep={handlePreviousStep}
                        handleNextStep={handleNextStep}
                        handleChange={formikProps.handleChange}
                        setFieldValue={formikProps.setFieldValue}
                        qualificationLevelOptions={qualificationLevelList.filter(ql => acceptedQualificationLevels.includes(ql.code)).map(ql => ql.code)}
                        managerQualificationLevelOptions={qualificationLevelList.filter(ql => managerAcceptedQualificationLevels.includes(ql.code)).map(ql => ql.code)}
                        districts={districList}
                        readMode={readMode}
                    />
                );
            case 4:
                return (
                    <formSteps.EaAccreditationFormStep5
                        formStep={5}
                        numberOfSteps={tabsIds.length}
                        errors={formikProps.errors}
                        formikValues={formikProps.values}
                        handleChange={formikProps.handleChange}
                        handlePreviousStep={handlePreviousStep}
                        handleNextStep={handleNextStep}
                        setFieldValue={formikProps.setFieldValue}
                        districts={districList.filter((d) =>
                            formikProps.values.interventionDistrictCodes.includes(d.code)
                        )}
                        setError={setError}
                        readMode={readMode}
                    />
                );
            case 5:
                return (
                    <formSteps.EaAccreditationFormStep6
                        formStep={6}
                        numberOfSteps={tabsIds.length}
                        errors={formikProps.errors}
                        formikValues={formikProps.values}
                        handleChange={formikProps.handleChange}
                        handlePreviousStep={handlePreviousStep}
                        handleNextStep={handleNextStep}
                        setFieldValue={formikProps.setFieldValue}
                        districts={districList.filter((d) =>
                            formikProps.values.interventionDistrictCodes.includes(d.code)
                        )}
                        districtOptions={districtOptions}
                        readMode={readMode}
                    />
                );
            case 6:
                return (
                    <formSteps.EaAccreditationFormStep7
                        formStep={7}
                        numberOfSteps={tabsIds.length}
                        errors={formikProps.errors}
                        formikValues={formikProps.values}
                        handleChange={formikProps.handleChange}
                        handlePreviousStep={handlePreviousStep}
                        handleNextStep={handleNextStep}
                        setError={setError}
                        documents={documents}
                        setDocuments={setDocuments}
                        readMode={true}
                    />
                );
            case 7:
                return (
                    <EaRenewalDates
                        formStep={8}
                        numberOfSteps={tabsIds.length}
                        errors={formikProps.errors}
                        formikValues={formikProps.values}
                        handleChange={formikProps.handleChange}
                        handlePreviousStep={handlePreviousStep}
                        handleNextStep={handleNextStep}
                        setFieldValue={formikProps.setFieldValue}
                        readMode={readMode}
                    />
                );
            case 8:
                return (
                    <EaRenewalDocuments
                        formStep={9}
                        numberOfSteps={tabsIds.length}
                        errors={formikProps.errors}
                        formikValues={formikProps.values}
                        handleChange={formikProps.handleChange}
                        handlePreviousStep={handlePreviousStep}
                        setError={setError}
                        documents={renewalDocuments}
                        setDocuments={setRenewalDocuments}
                        uploadFunction={(formData) => uploadRenewalDocument(entityNIPC, formData)}
                        deleteFunction={(documentExternalId) => deleteRenewalDocument(entityNIPC, documentExternalId)}
                        readMode={readMode}
                    />
                );
            default:
                console.log('Something went wrong rendering the form step');
        }
    }

    return (
        <SubTemplate
            titleId={'ea.accreditation.renewal.title'}
            subTitleId={'eaAccreditationForm.subTitle'}
            hasBackButton={true}
            centerContent={true}
            stateBadgeProps={StateAndDateBadge.propsFromAccreditationRenewal(renewal)}
        >
            <Container>
                <DecisionDialog
                    dialogClassName='modal-35'
                    show = {showSubmitModal}
                    handleConfirm={() => {setShowSubmitModal(false); handleSubmit()}}
                    handleClose = {() => {setShowSubmitModal(false)}}
                    titleId = {'ea.accreditation.renewal.submitModal.title'}
                    bodyId = {'ea.accreditation.renewal.submitModal.body'}
                    needReason = {false}
                />
                <Row className='mb-5'>
                    <Col md='2'>
                        <FormSideNavBar
                            tabsIdsArray={tabsIds}
                            formStep={formStep}
                            setFormStep={setFormStep}
                            navErrors={navErrors}
                            setNavErrors={setNavErrors}
                        />
                    </Col>
                    <Col md='8'>
                        <AlertError error={error} />
                        {lsItem && <AlertSuccess lsItem={lsItem} />}
                        <SuccessToast
                            setShow={setShowSaveToast}
                            show={showSaveToast}
                            message={<FormattedMessage id='eaAccreditationForm.saveToastInformation' />}
                        />
                        <SuccessToast
                            setShow={setShowSubmittedToast}
                            show={showSubmittedToast}
                            message={<FormattedMessage id='eaAccreditationForm.submitToastInformation' />}
                        />
                        <Formik
                            enableReinitialize={readMode}
                            initialValues={formikInitialValues}
                            validateOnBlur={false}
                            validateOnChange={false}
                            validationSchema={eaAccreditationFormSchema}
                            onSubmit={validateAndOpenSubmissionModal}
                        >
                            {(formikProps) => (
                                <Form onSubmit={formikProps.handleSubmit}>
                                    {renderStep(formikProps)}
                                </Form>
                            )}
                        </Formik>
                    </Col>
                    <Col md='2'>
                        {isIEFP && (
                            <div>
                                {['DRAFT'].includes(renewal.state) && <button
                                    className='btn btn-outline-primary d-flex align-items-center justify-content-center mb-4 w-100'
                                    type='button'
                                    onClick={() => handleSave()}
                                >
                                    <FaRegSave />
                                    <FormattedMessage id='all.save' />
                                </button>}
                                {['DRAFT'].includes(renewal.state) &&<button
                                    className='btn btn-primary d-flex align-items-center justify-content-center mb-4 w-100'
                                    type='button'
                                    onClick={validateAndOpenSubmissionModal}
                                >
                                    <FaRegCheckCircle />
                                    <FormattedMessage id='all.submitButtonText' />
                                </button>}
                            </div>
                        )}

                    </Col>
                </Row>
            </Container>
        </SubTemplate>
    );
}
