import Keycloak from 'keycloak-js';
import {
  cleanAccessInfo,
  cleanUserInfo,
  constructAccessInfoFromKeycloak,
  getAccessInfo,
  saveInStorageAccessInfo, saveUserInfoInStorage,
} from './authentication/authenticationHelper';
import {getUserPermissions} from "./rest/authentication";
import {getOrCreateUser} from "./rest/user";
import {useGlobalContext} from "./context";

export const KeycloakObject = (function () {
  let keycloak;



  function create(configs) {
    keycloak = new Keycloak(configs);
  }

  function get() {
    return keycloak;
  }

  const onEvent = async (event, error) => {
    if (typeof error !== undefined) {
      console.log('-----------------------EVENT-------------------', event)
      switch (event) {
        case 'onAuthSuccess':
          break;
        case 'onTokenExpired':

          await keycloak.updateToken(100);

          const [{data: permissions}, {data: user}] = await Promise.all([
            getUserPermissions(keycloak.token),
            getOrCreateUser(keycloak.token),
          ]);

          const access_info = constructAccessInfoFromKeycloak(
              keycloak,
              permissions
          );

          saveInStorageAccessInfo(access_info);
          if (user.userType) {
            console.log(user.userType)
            const userInfo = {
              name: user.name,
              email: user.email,
              userState: user.userState,
              nif: user.nif,
              userType: user.userType,
              rd:user.rd
            };
            saveUserInfoInStorage(userInfo);
          }


          break;
        case 'onAuthLogout':
          cleanAccessInfo();
          cleanUserInfo();
          break;
        case 'onAuthRefreshSuccess':
          break;
        default:
          break;
      }
    }
  };

  const onTokenChanged = (changedToken) => {
    let accessInfo = getAccessInfo();

    if (changedToken && accessInfo) {
      let accessInfo = getAccessInfo();
      accessInfo = constructAccessInfoFromKeycloak(
        changedToken,
        accessInfo.permissions
      );
      saveInStorageAccessInfo(accessInfo);
    }
  };

  return {
    create: create,
    get: get,
    onEvent: onEvent,
    onTokenChanged: onTokenChanged,
  };
})();
