import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { CustomPagination } from '../../components/general/CustomPagination';
import { List } from '../../components/general/List';
import Loading from '../../components/general/Loading';
import { SubTemplate } from '../../components/general/SubTemplate';
import { PriorSupportState } from '../../models/PriorSupportState';
import {getPriorSupportRequest, getPriorSupportRequestWithNif} from '../../rest/entity';
import { handleError, isNotBusinessError } from '../../utils/handleError';

const MAX_RESULTS = 10;
const DEFAULT_INITIAL_PAGE = 0;

export function ListPriorSupportRequest({entityNif}) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [priorSupportList, setPriorSupportList] = useState([]);
  const [numberOfResults, setNumberOfResults] = useState(null);
  const [totalResults, setTotalResults] = useState(null);
  const [currentPage, setCurrentPage] = useState(DEFAULT_INITIAL_PAGE);

  const states = ['ALL', ...PriorSupportState.values()];
  const [state, setState] = useState(states[0]);

  const orderByOptions = ['creationDate_ASC', 'creationDate_DESC'];

  const [currentOrderBy, setCurrentOrderBy] = useState(orderByOptions[0]);

  async function fetchData() {
    try {

      const [{ data: priorSupportList }] = await Promise.all([
        entityNif===undefined ?
        await getPriorSupportRequest(
          currentPage,
          MAX_RESULTS,
          state === 'ALL' ? null : state,
          currentOrderBy.split('_')[0],
          currentOrderBy.split('_')[1]
        ):
              await getPriorSupportRequestWithNif(
                  currentPage,
                  MAX_RESULTS,
                  state === 'ALL' ? null : state,
                  currentOrderBy.split('_')[0],
                  currentOrderBy.split('_')[1],entityNif
              )
      ]);
      setPriorSupportList(priorSupportList.results);
      setNumberOfResults(priorSupportList.numberOfResults);
      setTotalResults(priorSupportList.totalResults);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  }

  const onPageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
    fetchData();
  }, [currentPage, state, currentOrderBy]);

  if (loading) {
    return <Loading />;
  }

  if (error && isNotBusinessError(error)) {
    return handleError(error);
  }

  return (
    <SubTemplate titleId={'priorSupport.list.title'} hasBackButton internal={entityNif!==undefined}>
      <Container>
        <List
          dataList={priorSupportList}
          filteringOptionsIdPrefix='priorSupportState'
          filteringOptions={states}
          currentFilter={state}
          setCurrentFilter={setState}
          setCurrentPage={setCurrentPage}
          alertError={error}
          isPriorSupport={true}
          listTotal={totalResults}
          orderByOptionsIdPrefix='priorSupportRequest.list.orderOption'
          orderByOptions={orderByOptions}
          currentOrderBy={currentOrderBy}
          setCurrentOrderBy={setCurrentOrderBy}
          hoverpreffixId='priorSupportRequest.list.hover'
          fullPage
        />

        <Row className='mb-5'>
          <Col md='12' className='d-flex'>
            <div className='mx-2'>
              <CustomPagination
                dataPerPage={MAX_RESULTS}
                totalData={totalResults}
                onPageChange={onPageChange}
                currentPage={currentPage}
              />
            </div>
          </Col>
        </Row>
      </Container>
    </SubTemplate>
  );
}
