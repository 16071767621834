import {CloseableModalDialog} from "../general/CloseableModalDialog";
import {ProcessState} from "../../models/ProcessState";
import {Link} from "react-router-dom";
import {FormattedMessage} from "react-intl";

export function PaymentRequestLinksModal({show, setShow, paymentRequestList}) {
    let prs = paymentRequestList.filter(pr => pr.processState === ProcessState.REJECTED);
    return (
        <CloseableModalDialog
            show={show}
            setShow={setShow}
            titleId={'semestralReport.action.rejectedPaymentList'}
        >
            {prs.map( (pr, index) => (
                <div>
                    <Link key={index} to={`/pedidoDePagamento/${pr.processExternalId}`}>
                        <FormattedMessage id={`processState.${pr.processState}`}/>: {pr.changeDate}
                    </Link>
                </div>
            ))}
        </CloseableModalDialog>
    );
}