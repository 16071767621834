import React from 'react';
import {Alert, Col, Row, Table} from 'react-bootstrap';
import {FormattedMessage} from 'react-intl';
import {FaInfoCircle} from "react-icons/fa";

export function EntityChangeGeoData({entityChange, kind, showInfo}) {


    const districts = entityChange.administrativeDivisionChanges.filter(e => e.kind = kind);


    return (
        <>
            {showInfo &&
                <Row className="mt-1">
                    <Col>
                        <Alert className='d-flex align-items-center justified' variant='danger'>
                            <div className='px-3'><FaInfoCircle/></div>
                            <div className='px-3 '>
                                <strong><FormattedMessage id='entityChange.removeDistricts.alert.title'/></strong>
                                <p className='mb-0'><FormattedMessage
                                    id="entityChange.removeDistricts.alert.body.iefp"/>
                                </p>
                            </div>
                        </Alert>
                    </Col>
                </Row>
            }
            <Row>
                <Col><Table striped className={'d-table'}>
                    <thead>
                    <tr>
                        <th>
                            <FormattedMessage id='eaAccreditationForm.step3.continentDistricts'/>
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    {districts.map((district) => (
                        <tr key={district.description} className='align-items-center'>
                            <td>{district.description}</td>
                        </tr>
                    ))}
                    </tbody>
                    <tfoot>
                    <tr>
                        <td colSpan={3}>
                            {districts.length}{' '}
                            <FormattedMessage id='eaAccreditationForm.step3.total'/>
                        </td>
                    </tr>
                    </tfoot>
                </Table>
                </Col>
            </Row>
        </>
    );
}
