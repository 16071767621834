export const AccreditationRevocationState = {


  WAIT_CONTESTATION:'WAIT_CONTESTATION',
  ANALYZE_CONTESTATION:'ANALYZE_CONTESTATION',
  APPROVED: 'APPROVED',
  REJECTED: 'REJECTED',

  values: () => {
    return ['WAIT_CONTESTATION','ANALYZE_CONTESTATION', 'APPROVED', 'REJECTED'];
  },

  finalStates: () => {
    return ['APPROVED', 'REJECTED'];
  }


};
