import {FormattedMessage} from "react-intl";
import React, {useEffect, useState} from "react";
import {EmptyResults} from "../../../general/EmptyResults";
import {NonComplianceEntry} from "./NonComplianceEntry";

export function NonComplianceList({nonCompliances, nonComplianceCauseToRegulationMap}) {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    console.log(nonCompliances);
    return nonCompliances?.length ? (
        <table className='table d-table'>
            <thead>
            <tr>
                <th>
                    <label>
                        <FormattedMessage id='all.dateDescription' />
                    </label>
                </th>
                <th>
                    <label>
                        <FormattedMessage id='candidatureTabs.oversight.tabs.noncompliance.form.leadsToRevogation' />
                    </label>
                </th>
                <th>
                    <label>
                        <FormattedMessage id='candidatureTabs.oversight.tabs.noncompliance.form.aidRestitution' />
                    </label>
                </th>

                <th>
                    <label>
                        <FormattedMessage id='candidatureTabs.oversight.tabs.noncompliance.form.restitutionValue' />
                    </label>
                </th>
                <th>
                    <label>
                        <FormattedMessage id='candidatureTabs.oversight.tabs.irregularities.causes' />
                    </label>
                </th>
                <th>
                    <label>
                        <FormattedMessage id='candidature.stateText' />
                    </label>
                </th>
            </tr>
            </thead>
            <tbody>
            {nonCompliances.map((n) => <NonComplianceEntry
                key={n.externalId} nonCompliance={n}
                nonComplianceCauseToRegulationMap={nonComplianceCauseToRegulationMap}
            />)}
            </tbody>
        </table>
    ) : (<EmptyResults />);
}