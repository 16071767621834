import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { CustomPagination } from '../../../components/general/CustomPagination';
import { List } from '../../../components/general/List';
import Loading from '../../../components/general/Loading';
import { SubTemplate } from '../../../components/general/SubTemplate';
import { ConsultingRequestState } from '../../../models/ConsultingRequestState';
import { listEntityConsultingRequests, listEntityConsultingRequestsByNif } from '../../../rest/consultingRequest';
import { handleError, isNotBusinessError } from '../../../utils/handleError';

const MAX_RESULTS = 10;
const DEFAULT_INITIAL_PAGE = 0;

export function ListConsultingRequests({ entityNif }) {
  const [consultingRequests, setConsultingRequests] = useState([]);

  const [totalResults, setTotalResults] = useState(null);
  const [currentPage, setCurrentPage] = useState(DEFAULT_INITIAL_PAGE);
  const [numberOfResults, setNumberOfResults] = useState(null);

  const [stateFilter, setStateFilter] = useState('ALL');
  const [currentOrderBy, setCurrentOrderBy] = useState('creationDate_ASC');

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const states = ['ALL', ...ConsultingRequestState.values()];
  const orderByOptions = ['creationDate_ASC', 'creationDate_DESC'];

  useEffect(() => {
    fetchData();
  }, [currentPage, stateFilter, currentOrderBy]);

  const fetchData = async () => {
    try {
      const { data: requests } =
        entityNif === undefined ?

          await listEntityConsultingRequests(
            currentPage,
            MAX_RESULTS,
            stateFilter === 'ALL' ? null : stateFilter,
            currentOrderBy.split('_')[0],
            currentOrderBy.split('_')[1]
          ) : await listEntityConsultingRequestsByNif(
            currentPage,
            MAX_RESULTS,
            stateFilter === 'ALL' ? null : stateFilter,
            currentOrderBy.split('_')[0],
            currentOrderBy.split('_')[1],
            entityNif
          );

      setConsultingRequests(requests.results);
      setNumberOfResults(requests.numberOfResults);
      setTotalResults(requests.totalResults);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const onPageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  if (loading) {
    return <Loading />;
  }

  if (error && isNotBusinessError(error)) {
    return handleError(error);
  }

  return (
    <SubTemplate titleId='consultingRequest.list.title' hasBackButton internal={entityNif !== undefined}>
      <Container>
        <List
          dataList={consultingRequests}
          filteringOptionsIdPrefix='consultingRequestState'
          filteringOptions={states}
          currentFilter={stateFilter}
          setCurrentFilter={setStateFilter}
          setCurrentPage={setCurrentPage}
          alertError={error}
          isPriorSupport={false}
          isConsultingRequest={true}
          listTotal={totalResults}
          orderByOptionsIdPrefix='priorSupportRequest.list.orderOption'
          orderByOptions={orderByOptions}
          currentOrderBy={currentOrderBy}
          setCurrentOrderBy={setCurrentOrderBy}
          hoverpreffixId='priorSupportRequest.list.hover'
          fullPage
        />

        <Row className='mb-5'>
          <Col md='12' className='d-flex'>
            <div className='mx-2'>
              <CustomPagination
                dataPerPage={MAX_RESULTS}
                totalData={totalResults}
                onPageChange={onPageChange}
                currentPage={currentPage}
              />
            </div>
          </Col>
        </Row>
      </Container>
    </SubTemplate>
  );
}
