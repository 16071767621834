import {Formik} from "formik";
import {Col, Container, Form, Row} from "react-bootstrap";
import {FormattedMessage} from "react-intl";
import React, {useState} from "react";
import {useNavigate} from "react-router-dom";
import * as yup from "yup";
import {addPayment} from "../../rest/payableProcess";
import {AlertError} from "../bootstrap/AlertError";
import {DateTimeInputField} from "../bootstrap/DateTimeInputField";
import DecimalInputField from "../bootstrap/DecimalInputField";


export function PayableProcessRecordForm ({overview, onCancel, onSubmitCallback}) {

    async function onSubmit(values, setSubmitting, setErrors) {
        setSubmitting(true);
        try {
            const newPaymentRecord = {
                date: values.date,
                value: values.value,
                processExternalId: overview.processExternalId
            }
            await addPayment(overview.processExternalId, newPaymentRecord);
            onSubmitCallback?.()
        } catch (err) {
            console.log(err);
            setErrors(err);
        }
        setSubmitting(false);
    }

    const validationSchema = yup.object().shape({
        date: yup
            .date()
            .typeError(<FormattedMessage id='errors.fieldRequiredText'/>)
            .required(<FormattedMessage id='errors.fieldRequiredText'/>)
            .max(new Date(), <FormattedMessage id='candidature.payments.form.error.future'/>),
        value: yup
            .number()
            .typeError(<FormattedMessage id='errors.fieldRequiredText'/>)
            .test(
                'isValidValue',
                <FormattedMessage id='candidature.payments.form.error.zeroPayment' />,
                (value) =>  value !== 0.00,
            )
            .max(overview.payableAmount - overview.paid, <FormattedMessage id='candidature.payments.form.error.tooHigh' />)
            .required(<FormattedMessage id='errors.fieldRequiredText'/>),
    });

    return (
        <>
            {/*<AlertError error={error} />*/}
            <Formik
                initialValues={{date: null, value: overview.payableAmount - overview.paid}}
                validationSchema={validationSchema}
                validateOnBlur={false}
                validateOnChange={false}
                onSubmit={(values, { setSubmitting, setErrors }) => {
                    onSubmit(values, setSubmitting, setErrors);
                }}>
                {({ values,
                      errors,
                      touched,
                      handleChange,
                      handleSubmit,
                      setFieldValue
                  }) => (
                    <Form onSubmit={handleSubmit}>
                        <AlertError error={errors} />
                        <Row>
                            <Col md={{span:6}}>
                                <DateTimeInputField
                                    name='date'
                                    labelId='candidature.payment.list.dateText'
                                    value={values.date}
                                    isDateWithNoTime={true}
                                    setFieldValue={setFieldValue}
                                    errorMessage={errors.date}
                                />
                            </Col>
                            <Col md={{span:6}}>
                                <DecimalInputField
                                    name='value'
                                    labelId='candidature.payment.list.valueText'
                                    suffixLabel='€'
                                    errorMessage={errors.value}
                                    handleChange={(e) => setFieldValue('value', e.target.value)}
                                    allowNegative={true}
                                    value ={values.value}
                                    disabled={true}

                                />
                            </Col>
                        </Row>
                        <Row className='form-actions'>
                            <Col className='justify-content-between d-flex'>
                                <button className='btn btn-outline-primary' type='button' onClick={() => onCancel?.()}>
                                    <FormattedMessage id='all.cancel' />
                                </button>
                                <button className='btn btn-primary' type='submit' >
                                    <FormattedMessage id='all.submitButtonText' />
                                </button>
                            </Col>
                        </Row>
                    </Form>
                )}
            </Formik>
        </>
    );
}