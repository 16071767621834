import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {getOverview, getParentProcessId, getPayments} from "../../rest/payableProcess";
import Loading from "../general/Loading";
import {SubTemplate} from "../general/SubTemplate";
import {Container} from "react-bootstrap";
import {AlertSuccess} from "../bootstrap/AlertSuccess";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {AlertError} from "../bootstrap/AlertError";
import {FormattedMessage} from "react-intl";
import {PayableProcessPaymentsList} from "./PayableProcessPaymentsList";
import {PayableProcessOverview} from "./PayableProcessOverview";
import {useGlobalContext} from "../../context";
import {AddPayableProcessPaymentRecordModal} from "./AddPayableProcessPaymentRecordModal";
import {isEntityUser, isIEFPUser} from "../../authentication/authenticationHelper";

export function PayableProcessPayments() {
    const {payableProcessExternalId: payableProcessExternalId} = useParams();

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [item, setItem] = useState(null);
    const [navErrors, setNavErrors] = useState([]);
    const [paymentRecords, setPaymentRecords] = useState();
    const [overview, setOverview] = useState();
    const [addModalShow, setAddModalShow]  = useState(false);
    const [addModalSubmitted, setAddModalSubmitted] = useState(false);
    const { accessInfo } = useGlobalContext();

    const isIEFP = isIEFPUser();
    const isEntity = isEntityUser();


    async function fetchData() {
        setLoading(true);
        try {
            const [{ data: ov }, {data: payments}] = await Promise.all([
                getOverview(payableProcessExternalId),
                getPayments(payableProcessExternalId)
            ]);
            console.log(ov);
            setOverview(ov);
            setPaymentRecords(payments);
            checkStorageItems();
        } catch (error) {
            setError(error);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        setAddModalSubmitted(false);
        fetchData();
    }, [addModalSubmitted]);

    function checkStorageItems() {
        if (sessionStorage.getItem('saveRevocation')) {
            setItem('saveRevocation');
        }
    }

    if (loading) {
        return <Loading />;
    }



    return (
        <SubTemplate titleId={'candidature.button.payments'} hasBackButton>
            <Container>
                <div className='mx-2 mb-4'>
                    <AlertSuccess lsItem={item} />
                </div>
                <Row className='searchResults'>
                    <Col md='10'>
                        <AlertError error={error} />
                        <PayableProcessOverview payableProcessOverview={overview}  payableProcessPayments={paymentRecords}/>
                    </Col>
                    {(isIEFP && (paymentRecords===undefined ||  paymentRecords.length ===0) && overview.payable) &&(
                    <Col md={2}>
                        <button
                            className='btn btn-outline-primary d-flex align-items-center justify-content-center mb-4 mt-4 w-100'
                            type='button'
                            onClick={() => setAddModalShow(true)}
                        >
                            <FormattedMessage id='all.registerPayment' />
                        </button>
                    </Col>
                    )}
                </Row>
                <Row>
                    <Col>
                        <AddPayableProcessPaymentRecordModal
                            show={addModalShow}
                            setShow={setAddModalShow}
                            onSubmitCallback={() => setAddModalSubmitted(true)}
                            overview={overview}
                        />
                    </Col>
                </Row>
            </Container>
        </SubTemplate>
    )
}