import React from 'react';
import { Modal } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';

export function PriorSupportModal({ show, handleClose, candidature }) {
  const navigate = useNavigate();

  return (
    <Modal show={show} onHide={handleClose} backdrop='static'>
      <Modal.Header closeButton>
        <Modal.Title>
          <FormattedMessage id='candidatureForm.priorSupport' />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          <FormattedMessage id='candidatureForm.priorSupportModal.body1' />
        </p>
          <p>
            <FormattedMessage id={'candidatureForm.priorSupportModal.body1.1'}/> <b><FormattedMessage id={'candidatureForm.priorSupportModal.body1.2'}/></b> <FormattedMessage id={'candidatureForm.priorSupportModal.body1.3'}/>
          </p>
        <p>
          <FormattedMessage id='candidatureForm.priorSupportModal.body2' />
        </p>
        <div className='form-actions mt-5'>
          <button
            className='btn btn-outline-primary px-3'
            type='button'
            onClick={() => handleClose()}
          >
            <FormattedMessage id='all.later' />
          </button>

          <button
            className='btn btn-primary px-5'
            type='button'
            onClick={() =>
              navigate('/apoioPrevio', {
                state: {
                  parish: candidature?.parish || '-1',
                  step: 1,
                },
              })
            }
          >
            <FormattedMessage id='all.yes' />
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
}
