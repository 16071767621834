import React from 'react';
import { Accordion, Col, Row, Table } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { AmortizationComponent } from '../../models/AmortizationComponent';
import { AmortizationTopic } from '../../models/AmortizationTopic';
import { CandidatureFinancialUtils } from '../../utils/CandidatureFinancialUtils';
import { CandidatureFormUtils } from '../../utils/CandidatureFormUtils';
import DecimalInputField from '../bootstrap/DecimalInputField';
import { EnumSelectField } from '../bootstrap/EnumSelectField';
import { TextAreaInputField } from '../bootstrap/TextAreaInputField';
import { TextInputField } from '../bootstrap/TextInputField';
import {currencyFormat, maxFixed} from "../../utils/CurrencyUtils";
import ToolTip from "../bootstrap/ToolTip";
import {IntInputField} from "../bootstrap/IntInputField";

export function CandidatureFormStep13({
  errors,
  setFieldValue,
  values,
  handleSave,
  handleGoBack,
  numberOfSteps
}) {
  const intl = useIntl();

  const YEARS = [0, 1, 2, 3, 4];

  function addAmortizationExpensesLine(topic) {
    let objectToPush = {
      externalId: '',
      investmentType: '',
      topic: topic,
      invoice: '',
      value: 0,
      rate: 0,
      amortizationFirstYear: 0,
      initialYear:0,
      amortizationSecondYear: 0,
      amortizationThirdYear: 0
    };

    if (CandidatureFormUtils.isProjectTotalValueAboveLimitValue(values)) {
      objectToPush.amortizationFourthYear = 0;
      objectToPush.amortizationFifthYear = 0;
    }


    values.amortizationExpensesList[0].amortizationExpensesLines.push(objectToPush);

    setFieldValue('amortizationExpensesList', [...values.amortizationExpensesList]);
  }

  function removeAmortizationExpensesLine(amortizationExpensesLine) {
    values.amortizationExpensesList[0].amortizationExpensesLines =
      values.amortizationExpensesList[0].amortizationExpensesLines.filter(
        (ael) => ael !== amortizationExpensesLine
      );
    setFieldValue('amortizationExpensesList', [...values.amortizationExpensesList]);
  }

  function getAmortizationExpensesLinesByTopic(topic) {
    return values.amortizationExpensesList[0].amortizationExpensesLines.filter(
      (ael) => ael.topic === topic
    );
  }

  function getTableRowsByYearAndTopic(year, amortizationExpensesLine) {
    return (
        <td className='text-center'>
          {currencyFormat(maxFixed(amortizationExpensesLine[getPropertyNameByYear(year)]))}
        </td>
    );
  }

  function getPropertyNameByYear(year) {
    switch (year) {
      case 0:
        return 'amortizationFirstYear';

      case 1:
        return 'amortizationSecondYear';

      case 2:
        return 'amortizationThirdYear';

      case 3:
        return 'amortizationFourthYear';

      case 4:
        return 'amortizationFifthYear';

      default:
        break;
    }
  }

  function getNumberOfEntries(topic) {
    return getAmortizationExpensesLinesByTopic(topic).length;
  }

  function getYears() {
    return YEARS.filter(year=> !CandidatureFormUtils.is3YearsCoverageOnly(values, year))
  }




  function getTotalTableRows(topic) {
    return (
      <>
        <td>
          {currencyFormat(CandidatureFinancialUtils.calculateAmortizationExpensesTotalValue(
            getAmortizationExpensesLinesByTopic(topic))
          )}
        </td>
        {YEARS?.map(
          (year,index) =>
              (<React.Fragment key={index}>
                    {
                      !CandidatureFormUtils.is3YearsCoverageOnly(values, year) && (
                          <td>
                            {currencyFormat(maxFixed(CandidatureFinancialUtils.calculateAmortizationExpensesYearTotalValue(
                                getAmortizationExpensesLinesByTopic(topic),
                                year
                            )))}
                          </td>
                      )
                    }

                  </React.Fragment>
                    )

        )}
      </>
    );
  }

  function handleAmortizationExpensesLineChange(e, amortizationExpensesLine, year, fieldName) {

    if (year !== null && fieldName === null) {
      amortizationExpensesLine[getPropertyNameByYear(year)] = e.target.value;
    } else {
      amortizationExpensesLine[fieldName] = e.target.value;
      if(fieldName ==='rate' || fieldName ==='value' || fieldName==='initialYear'){

        let amortizationValue = amortizationExpensesLine['value']*amortizationExpensesLine['rate']/100;
        let count =0;
        YEARS.forEach((year) => {
          if(year>= amortizationExpensesLine['initialYear'] && count !== parseFloat(amortizationExpensesLine['value'])){
            if(amortizationValue + count < amortizationExpensesLine['value']){
              amortizationExpensesLine[getPropertyNameByYear(year)] = amortizationValue;
              count+=parseFloat(amortizationValue)
            }else{
              if( parseFloat(parseFloat(amortizationExpensesLine['value']) - parseFloat(count)) >0) {
                amortizationExpensesLine[getPropertyNameByYear(year)] = parseFloat(amortizationExpensesLine['value']) - parseFloat(count)
                count += (amortizationExpensesLine['value'] - count)
              }

            }



            if( count!==  parseFloat(amortizationExpensesLine['value']) && (parseFloat(amortizationExpensesLine['value']) - count)>0 && (parseFloat(amortizationExpensesLine['value']) - count)<1){
              amortizationExpensesLine[getPropertyNameByYear(year)] = amortizationExpensesLine[getPropertyNameByYear(year)] + (parseFloat(amortizationExpensesLine['value']) - count);
              count+=(parseFloat(amortizationExpensesLine['value']) - count)
            }
          }else{
            amortizationExpensesLine[getPropertyNameByYear(year)]=0;
          }




        });


      }
    }

    setFieldValue('amortizationExpensesList', [...values.amortizationExpensesList]);
  }

  function handleReasonChange(e) {
    values.amortizationExpensesList[0].reasonToAmortizationExpenses = e.target.value;
    setFieldValue('amortizationExpensesList', [...values.amortizationExpensesList]);
  }

  function handleNextStep() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    handleSave(true);
  }

  function getIndexOnTopic(index,topic) {

    var first = getAmortizationExpensesLinesByTopic(topic)[index];
    index= values.amortizationExpensesList[0].amortizationExpensesLines.findIndex(line=> Object.is(line,first));
    return index;
  }



  return (
    <div className='financialBoards'>
      <h4 className='text-primary mb-0'>
        <FormattedMessage id='candidatureTabs.accordion.amortizationExpenses' />
      </h4>
      <small className='text-secondary text-uppercase d-block'>
        {intl.formatMessage(
          { id: 'all.step' },
          { step: values.step, numberOfSteps: numberOfSteps }
        )}
      </small>

      <Accordion defaultActiveKey='0' alwaysOpen>
        {AmortizationTopic.values()?.map((topic, index) => (
          <Accordion.Item eventKey={index} key={index}>
            <Accordion.Header>
              <FormattedMessage id={`amortizationComponent.${topic}`} />
              {getNumberOfEntries(topic) > 0 && (
                <>
                  {' ('}
                  {getNumberOfEntries(topic)}{' '}
                  <FormattedMessage
                    id={getNumberOfEntries(topic) === 1 ? 'all.entry.lowerCase' : 'all.entries'}
                  />
                  {')'}
                </>
              )}
            </Accordion.Header>
            <Accordion.Body>
              <div key={topic} className='pb-4 mx-1 mb-3'>
                {getAmortizationExpensesLinesByTopic(topic)?.map((ael, index) => (
                  <div key={index} className='pb-4 mb-2'>
                    <Row>
                      <Col>
                        <p className='mt-2 mb-0 text-uppercase'>Entrada {index + 1} </p>
                      </Col>
                      <Col className='d-flex justify-content-end'>
                        <button
                          className='mt-2 p-0 btn btn-link'
                          type='button'
                          onClick={() => removeAmortizationExpensesLine(ael)}>
                          <FormattedMessage id='all.remove' />
                        </button>
                      </Col>
                    </Row>

                    <Row>
                      <Col md='5'>
                        <EnumSelectField
                          controlId={'investmentType'}
                          labelId={'candidatureForm.amortizationExpenses.component'}
                          preffixDescriptionId={'investmentType'}
                          name={'investmentType'}
                          handleChange={(e) =>
                            handleAmortizationExpensesLineChange(e, ael, null, 'investmentType')
                          }
                          options={AmortizationComponent.getComponentsByTopic(topic)}
                          value={ael.investmentType}
                          nullOption={true}
                          isInvalid={
                            errors.amortizationExpensesList?.[0]?.amortizationExpensesLines?.[getIndexOnTopic(index,topic)]?.investmentType
                          }
                          errorMessage={
                            errors.amortizationExpensesList?.[0]?.amortizationExpensesLines?.[getIndexOnTopic(index,topic)]?.investmentType
                          }
                        />
                      </Col>
                      <Col md='3'>
                        <TextInputField
                          controlId={'invoice'}
                          labelId={'candidatureForm.amortizationExpenses.invoice'}
                          name={'invoice'}
                          handleChange={(e) =>
                            handleAmortizationExpensesLineChange(e, ael, null, 'invoice')
                          }
                          value={ael.invoice}
                          isInvalid={
                            errors.amortizationExpensesList?.[0]?.amortizationExpensesLines?.[getIndexOnTopic(index,topic)]?.invoice
                          }
                          errorMessage={
                            errors.amortizationExpensesList?.[0]?.amortizationExpensesLines?.[getIndexOnTopic(index,topic)]?.invoice
                          }
                        />
                      </Col>
                      <Col md='2'>

                        <EnumSelectField
                            labelId={'candidatureForm.amortizationExpenses.initialYear'}
                            controlId={'initialYear'}
                            name={'initialYear'}
                            value={ael.initialYear}
                            preffixDescriptionId={'candidatureForm.amortizationExpenses.initialYear'}
                            handleChange={(e) => handleAmortizationExpensesLineChange(e, ael, null, 'initialYear')}
                            options={getYears()}
                            isInvalid={ errors.amortizationExpensesList?.[0]?.amortizationExpensesLines?.[getIndexOnTopic(index,topic)]?.initialYear}
                            errorMessage={errors.amortizationExpensesList?.[0]?.amortizationExpensesLines?.[getIndexOnTopic(index,topic)]?.initialYear}
                            toolTip={<ToolTip message={<FormattedMessage id={'candidatureForm.amortizationExpenses.initialYear.tooltip'}/>}/> }
                        />


                      </Col>

                      <Col md='2'>
                        <DecimalInputField
                          labelId='all.tax'
                          controlId={'rate'}
                          name={'rate'}
                          handleChange={(e) =>
                            handleAmortizationExpensesLineChange(e, ael, null, 'rate')
                          }
                          value={ael.rate}
                          placeHolder={0}
                          suffixLabel='%'
                          maxValue={100}
                          toolTip={<ToolTip message={ <   FormattedMessage id={'step13.tooltip.rate'}/>}/>}
                          isInvalid={
                            errors.amortizationExpensesList?.[0]?.amortizationExpensesLines?.[getIndexOnTopic(index,topic)]?.rate
                          }
                          errorMessage={
                            errors.amortizationExpensesList?.[0]?.amortizationExpensesLines?.[getIndexOnTopic(index,topic)]?.rate
                          }
                        />

                      </Col>
                    </Row>

                    <Row>
                      <Col>
                        <Table className='mt-4 d-table w-100'>
                          <thead>
                            <tr>
                              <th className='w-40'>
                                <FormattedMessage id='all.value' /><ToolTip message={ <   FormattedMessage id={'step13.tooltip.value'}/>}/>
                              </th>
                              {YEARS?.map((year, index) => (
                                <React.Fragment key={index}>
                                  {!CandidatureFormUtils.is3YearsCoverageOnly(values, year) && (
                                    <th key={index} className='text-center'>
                                      {CandidatureFormUtils.getYearLabel(values, year)}
                                    </th>
                                  )}
                                </React.Fragment>
                              ))}
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                <DecimalInputField
                                  controlId={'value'}
                                  name={'value'}
                                  handleChange={(e) =>
                                    handleAmortizationExpensesLineChange(e, ael, null, 'value')
                                  }
                                  value={ael.value}
                                  placeHolder={0}
                                  suffixLabel='€'
                                  isInvalid={
                                    errors.amortizationExpensesList?.[0]?.amortizationExpensesLines?.[getIndexOnTopic(index,topic)]?.value
                                  }
                                  errorMessage={
                                    errors.amortizationExpensesList?.[0]?.amortizationExpensesLines?.[getIndexOnTopic(index,topic)]?.value
                                  }
                                />
                              </td>
                              {YEARS?.map(
                                (year,index1) =>(
                                    <React.Fragment key={index1}>
                                    { !CandidatureFormUtils.is3YearsCoverageOnly(values, year) &&
                                        getTableRowsByYearAndTopic(year, ael)}
                                    </React.Fragment>
                                    )

                              )}
                            </tr>
                          </tbody>
                        </Table>
                      </Col>
                    </Row>
                  </div>
                ))}
                <button
                  className='mt-4 btn btn-outline-primary'
                  type='button'
                  onClick={() => addAmortizationExpensesLine(topic)}>
                  <FormattedMessage id='all.plusSymbol' /> <FormattedMessage id='all.addEntry' />
                </button>

                <Row>
                  <Col>
                    <h5 className='mt-5 text-secondary'>
                      <FormattedMessage id='all.total' />{' '}
                      <FormattedMessage id={`amortizationComponent.${topic}`} />
                    </h5>
                    <Table className='mt-4 d-table text-center'>
                      <thead>
                        <tr>
                          <th className='w-40'>
                            <FormattedMessage id='all.value' />
                          </th>
                          {YEARS?.map(
                            (year, index) =>(
                                <React.Fragment key={index}>
                                  {
                                    !CandidatureFormUtils.is3YearsCoverageOnly(values, year) && (
                                        <th key={index}>
                                          {CandidatureFormUtils.getYearLabel(values, year)}
                                        </th>
                                    )
                                  }
                                </React.Fragment>
                            )

                          )}
                        </tr>
                      </thead>
                      <tbody>
                        <tr>{getTotalTableRows(topic)}</tr>
                      </tbody>
                    </Table>
                  </Col>
                </Row>
              </div>
            </Accordion.Body>
          </Accordion.Item>
        ))}
      </Accordion>

      <Row>
        <Col md='12'>
          <TextAreaInputField
            labelId={'candidatureForm.amortizationExpenses.reasonToAmortizationExpenses'}
            name={'candidatureForm.amortizationExpenses.reasonToAmortizationExpenses'}
            value={values.amortizationExpensesList[0].reasonToAmortizationExpenses}
            handleChange={(e) => handleReasonChange(e)}
            placeholder={intl.formatMessage({ id: 'all.maxLength.placeholder' })}
            maxLength={10000}
            isInvalid={
              errors.amortizationExpensesList
                ? errors.amortizationExpensesList[0]?.reasonToAmortizationExpenses
                : false
            }
            errorMessage={
              errors.amortizationExpensesList
                ? errors.amortizationExpensesList[0]?.reasonToAmortizationExpenses
                : ''
            }
          />
        </Col>
      </Row>

      <div className='form-actions mt-5'>
        <button
          className='btn btn-link'
          type='button'
          onClick={() => handleGoBack(values, setFieldValue)}>
          <FormattedMessage id='all.back' />
        </button>

        <button className='btn btn-primary' type='button' onClick={() => handleNextStep()}>
          <FormattedMessage id='all.nextStep' />
        </button>
      </div>
    </div>
  );
}
