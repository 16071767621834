import { apiAxios as authAxios } from '../axios.config';


export const getIncubatorDocuments = (index, size, entityNif) => {
  return authAxios.get(`/api/incubator/documents`, {
    params: {
      index: index,
      size: size,
      entityNif: entityNif !== undefined ? entityNif : '',
    },
  });
};



export const createIncubatorDocument = (formData) => {
  return authAxios.post(`/api/incubator/documents`, formData);
};




