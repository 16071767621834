import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { AlertError } from '../../components/bootstrap/AlertError';
import { EmptyResults } from '../../components/general/EmptyResults';
import { PriorSupportState } from '../../models/PriorSupportState';
import { Arrays } from '../../utils/Arrays';
import { EnumSelectField } from '../bootstrap/EnumSelectField';
import { LabelToolTip } from '../bootstrap/LabelToolTip';

export function List({
  dataList,
  filteringOptionsIdPrefix,
  filteringOptions,
  currentFilter,
  setCurrentFilter,
  setCurrentPage,
  alertError,
  isPriorSupport,
  isConsultingRequest,
  listTotal,
  orderByOptionsIdPrefix,
  orderByOptions,
  currentOrderBy,
  setCurrentOrderBy,
  hoverpreffixId,
  fullPage
}) {
  const intl = useIntl();

  const handleFilterChange = (e) => {
    if (e.target.value === '') {
      setCurrentFilter(null);
    } else {
      setCurrentFilter(e.target.value);
    }
    setCurrentPage(0);
  };

  function handleOrderByChange(e) {
    setCurrentOrderBy(e.target.value);
    setCurrentPage(0);
  }

  function getLinkPath(externalId) {
    if (isPriorSupport) {
      return `/pedidos/${externalId}`;
    }

    if (isConsultingRequest) {
      return `/pedidosMCE/${externalId}`;
    }
  }

  function getStateColumn(dataObj) {
    if (isPriorSupport) {
      return (
        <LabelToolTip
          labelId={`${hoverpreffixId}.state`}
          value={intl.formatMessage({
            id:
              dataObj.state === PriorSupportState.ASKED &&
              dataObj.documents?.filter(
                (document) => document.documentType === 'REVIEW_PRIOR_SUPPORT'
              ).length > 0
                ? 'priorSupportState.WAIT_DOC'
                : `priorSupportState.${dataObj.state}`
          })}
        />
      );
    }

    if (isConsultingRequest) {
      return (
        <LabelToolTip
          labelId='priorSupportRequest.list.hover.state'
          value={intl.formatMessage({
            id: `consultingRequestState.${dataObj.state}`
          })}
        />
      );
    }
  }

  return (
    <>
      <Row className='searchFilters'>
        <Col md='3' className='mb-2'>
          <EnumSelectField
            controlId={'filter'}
            preffixDescriptionId={filteringOptionsIdPrefix}
            handleChange={handleFilterChange}
            options={filteringOptions}
            value={currentFilter}
          />
        </Col>
      </Row>
      <Row className='searchResults'>
      <Col md={fullPage ? '12' : '10'}>
          <AlertError error={alertError} />

          {Arrays.isNotEmpty(dataList) ? (
            <>
              <Row className='mt-5 mb-1 d-flex justify-content-between px-2'>
                <Col className='text-muted'>
                  {listTotal} 
                  <FormattedMessage id={`list.header.requests.${listTotal === 1 ? 'singular' : 'plural'}`} />
                </Col>
                <Col md='4'>
                  <EnumSelectField
                    preffixDescriptionId={orderByOptionsIdPrefix}
                    handleChange={handleOrderByChange}
                    options={orderByOptions}
                    value={currentOrderBy}
                  />
                </Col>
              </Row>

              <ul className='results'>
                {dataList.map((dataObj, index) => (
                  <li key={dataObj.externalId}>
                    <Row className='py-3'>
                      <Col md='6' className='d-flex align-items-center'>
                        <Link to={getLinkPath(dataObj.externalId)}>
                          <LabelToolTip
                              labelId={'priorSupport.owner.name'}
                            value={dataObj.promoterName}
                          />
                        </Link>
                      </Col>
                      <Col md='3' className='d-flex align-items-center'>
                        {getStateColumn(dataObj)}
                      </Col>
                      <Col md='3' className='d-flex align-items-center justify-content-end'>
                        <LabelToolTip
                          labelId={`${hoverpreffixId}.creationDate`}
                          value={dataObj.creationDate}
                        />
                      </Col>
                    </Row>
                  </li>
                ))}
              </ul>
            </>
          ) : (
            <EmptyResults />
          )}
        </Col>
      </Row>
    </>
  );
}
