import {SubTemplate} from "../general/SubTemplate";
import {Col, Container, Row} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {EntityAccreditationDocumentsForm} from "./EntityAccreditationDocumentsForm";
import {
    fullyApproveEntity, getEntity, getEntityDetails
} from "../../rest/entity";
import {listEntityDocuments} from "../../rest/document";
import Loading from "../general/Loading";
import {DecisionDialog} from "../bootstrap/DecisionDialog";
import {DocumentType} from "../../models/DocumentType";
import {generateSingleUploadAreaDocumentObjectList} from "../SingleUploadArea";
import {AlertError} from "../bootstrap/AlertError";
import {createCustomErrorMessage} from "../../hooks/errorMessage";
import {FormattedMessage, useIntl} from "react-intl";
import {EntityState} from "../../models/EntityState";
import {AuthenticatedDownloadLink} from "../document/AuthenticatedDownloadLink";
export function EaAccreditationDocuments({
    entityNif = null
}) {
    const [loading, setLoading] = useState(true);
    const [documents, setDocuments] = useState([]);
    const [error, setError] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const {entityNIPC} = useParams() || entityNif;
    const navigate = useNavigate();

    if(submitted) {
        navigate(`/credenciacaoEntidade/adicionar/${entityNIPC}`);
    }

    useEffect(() => {
        fetchData();
    }, []);

    const fileTypes = [
        DocumentType.EAA_SERVICE_INFO,
        DocumentType.EAA_IEFP_CONFIRMATION,
        DocumentType.EAA_DIRECTIVE_COUNCIL_DELIBERATION,
        DocumentType.EAA_DECISION_NOTIFICATION,
        DocumentType.COOPERATION_PROTOCOL
    ];



    async function fetchData() {
        try {
            let [
                { data: entityDocuments },
            ] = await Promise.all([
                await listEntityDocuments({entityNif: entityNIPC}),
            ]);
            console.log(entityDocuments);
            let docs = generateSingleUploadAreaDocumentObjectList(fileTypes, entityDocuments);
            setDocuments(docs);
        } catch (error) {
            setError(error);
        } finally {
            setLoading(false);
        }
    }

    if(loading) {
        return <Loading />
    }


    return (
        <SubTemplate
            hasBackButton={true}
            titleId={'ea.accreditation.accept.documents.title'}
        >
            <Container>
                <Row>
                    <AlertError error={error} />
                </Row>
                {fileTypes.map(ft => documents.filter( d => d.documentType === ft).filter(d=> d.content!== null).map(d => (
                    <Row key={d.externalId}>
                        <Col className={'mb-4'}>
                            <label><FormattedMessage id={`documentType.${d.documentType}`}/></label>
                            <AuthenticatedDownloadLink url={d.documentPath} filename={d.name}>
                                <small>{d.name}</small>
                            </AuthenticatedDownloadLink>
                        </Col>
                    </Row>
                )))}
            </Container>
        </SubTemplate>
    )
}
