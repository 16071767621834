export const Arrays = {
  /**
   * @param  {Array} arr
   */
  isEmpty: (arr) => {
    return checkEmpty(arr);
  },

  /**
   * @param  {Array} arr
   */
  isNotEmpty: (arr) => {
    return !checkEmpty(arr);
  },

  groupBy:(list, keyGetter)=>{
    return groupBy(list,keyGetter);
  }

};

function checkEmpty(arr) {
  return !arr || arr.length === 0;
}


function groupBy(list, keyGetter) {
  const map = new Map();
  list.forEach((item) => {
    const key = keyGetter(item);
    const collection = map.get(key);
    if (!collection) {
      map.set(key, [item]);
    } else {
      collection.push(item);
    }
  });
  return map;
}
