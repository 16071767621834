import { Row, Col, Form } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { NumberInputField } from '../bootstrap/NumberInputField';
import { TextInputField } from '../bootstrap/TextInputField';
import React from "react";

export function ListContractsStep1({
	formStep,
	numberOfSteps,
	errors,
	handleChange,
	formikValues,
	handleNextStep,
	entityGetList,
	readMode,
	goBackPath,
	setFieldValue,
}) {
	const intl = useIntl();

	function showGetInformation() {
		let getInfo = entityGetList.filter(
			(get) =>
				get.externalId ===
				formikValues.identificationEa.technicalTeamManagerExternalId
		)?.[0];

		return (
			<>
				<Row>
					<Col md='8'>
						<TextInputField
							labelId={'listContracts.step1.technicalTeamManager'}
							value={getInfo?.name}
							disabled
						/>
					</Col>
					<Col md='4'>
						<TextInputField
							labelId='listContracts.step1.post'
							value={formikValues.technicalTeamManagerPosition}
							name='technicalTeamManagerPosition'
							handleChange={handleChange}
							isInvalid={errors.technicalTeamManagerPosition}
							errorMessage={errors.technicalTeamManagerPosition}
						/>
					</Col>
				</Row>
				<Row>
					<Col md='8'>
						<TextInputField
							labelId={'listContracts.step1.email'}
							value={getInfo?.email}
							disabled
						/>
					</Col>
					<Col md='4'>
						<NumberInputField
							labelId={'listContracts.step1.phone'}
							value={getInfo?.phone}
							disabled
						/>
					</Col>
				</Row>
			</>
		);
	}

	function getChangeHandler(e) {
		setFieldValue('technicalTeamManagerPosition', '');
		handleChange(e);
	}

	return (
		<div>
			<h4 className='text-primary mb-0'>
				<FormattedMessage id='listContracts.step1.title' />
			</h4>
			<small className='text-secondary text-uppercase'>
				{intl.formatMessage(
					{ id: 'all.step' },
					{ step: formStep, numberOfSteps: numberOfSteps }
				)}
			</small>
			<fieldset disabled={readMode==true}>
				<Row>
					<Col md='4'>
						<TextInputField
							disabled={true}
							labelId={'listContracts.step1.processNumber'}
							value={formikValues.identificationEa.entity.processId}
						/>
					</Col>
					<Col md='8'>
						<TextInputField
							disabled={true}
							labelId={'listContracts.step1.entity'}
							value={formikValues.identificationEa.entity.description}
						/>
					</Col>
				</Row>
				<Row>
					<Col md='4'>
						<TextInputField
							disabled={true}
							labelId='listContracts.step1.nipc'
							value={formikValues.identificationEa.entity.nif}
						/>
					</Col>
				</Row>
				<Row>
					<Col>
						<Form.Group>
							<Form.Label>
								<FormattedMessage id='semestralReportForm.step1.technicalTeamManager' />
							</Form.Label>
							<Form.Control
								as='select'
								name={'identificationEa.technicalTeamManagerExternalId'}
								onChange={(e) => {
									getChangeHandler(e);
								}}
								value={
									formikValues.identificationEa.technicalTeamManagerExternalId
								}
								isInvalid={
									errors.identificationEa?.technicalTeamManagerExternalId
								}
							>
								<option value={'-1'}>
									<FormattedMessage id='all.chooseOne' />
								</option>
								{entityGetList.map((get) => (
									<option key={get.externalId} value={get.externalId}>
										{get.name}
									</option>
								))}
							</Form.Control>
							<Form.Control.Feedback type='invalid'>
								{errors.identificationEa?.technicalTeamManagerExternalId}
							</Form.Control.Feedback>
						</Form.Group>
					</Col>
				</Row>
				{formikValues.identificationEa.technicalTeamManagerExternalId !==
					'-1' && showGetInformation()}
			</fieldset>
			<div className='form-actions mt-5'>
				<Link to={goBackPath}>
					<button className='btn btn-link' type='button'>
						<FormattedMessage id='all.back' />
					</button>
				</Link>
				<button
					className='btn btn-primary'
					type='button'
					onClick={handleNextStep}
				>
					<FormattedMessage id='all.nextStep' />
				</button>
			</div>
		</div>
	);
}
