import { apiAxios as authAxios } from '../axios.config';

export const listPriorSupportPaymentRequest = (queryParams) => {
  return authAxios.get('/api/priorSupport/payment/list/', {params:  queryParams})
}

export const getPriorSupportPaymentRequest = (b7ExternalId) => {
  return authAxios.get(`/api/priorSupport/payment/${b7ExternalId !== undefined ? b7ExternalId : ''}`)
}

export const getPriorSupportPaymentRequestByProcessExternalId = (b7ProcessExternalId) => {
  return authAxios.get(`/api/priorSupport/payment/process/${b7ProcessExternalId}`)
}

export const savePriorSupportPaymentRequest = (b7, queryParams) => {
  return authAxios.post('/api/priorSupport/payment/', b7, queryParams ? {params: queryParams}: null)
}

export const withdrawPriorSupportPaymentRequest = (b7ExternalId, authenticationCredentials) => {
  return authAxios.patch(`/api/priorSupport/payment/${b7ExternalId}/withdrawal`, authenticationCredentials)
}

export const priorSupportPaymentRequestFormStatus = () => {
  return authAxios.get('/api/priorSupport/payment/formStatus/')
}

export const listPriorSupportPaymentRequestDocuments = (b7ExternalId) => {
  return authAxios.get(`/api/priorSupport/payment/${b7ExternalId}/document`)
}

export const uploadDocumentB7 = (b7ExternalId, formData) => {
  return authAxios.post(`/api/priorSupport/payment/${b7ExternalId}/document`, formData)
}

export const deleteDocumentB7 = (b7ExternalId, documentExternalId) => {
  return authAxios.delete(`/api/priorSupport/payment/${b7ExternalId}/document/${documentExternalId}`)
}