import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
	deleteCandidatureChangeRequestDocument,
	uploadCandidatureChangeRequestDocument,
} from '../../rest/candidatureChangeRequest';
import { MultipleUploadArea } from '../MultipleUploadArea';
import { TextAreaInputField } from '../bootstrap/TextAreaInputField';
import { Col, Form, Row } from 'react-bootstrap';

export function CandidatureChangeForm({
	formStep,
	numberOfSteps,
	documents,
	setDocuments,
	candidatureExternalId,
	formikValues,
	readMode,
	handlePreviousStep,
	handleNextStep,
	showNextStepButton,
	handleChange,
	errors,
}) {
	const intl = useIntl();

	const docsUploadCallback = async (formData) => {
		return await uploadCandidatureChangeRequestDocument(
			candidatureExternalId,
			formikValues.externalId,
			formData
		);
	};

	const docsDeleteCallback = async (documentExternalId) => {
		return await deleteCandidatureChangeRequestDocument(
			candidatureExternalId,
			formikValues.externalId,
			documentExternalId
		);
	};

	return (
		<>
			<h4 className='text-primary mb-0'>
				<FormattedMessage id='candidature.changeCadidature.instructions' />
			</h4>

			<small className='text-secondary text-uppercase'>
				{intl.formatMessage(
					{ id: 'all.step' },
					{ step: formStep, numberOfSteps: numberOfSteps }
				)}
			</small>

			<fieldset disabled={readMode==true}>
				<Row>
					<Col>
						<TextAreaInputField
							labelId={'candidatureChangeRequest.step1.description'}
							name={'description'}
							handleChange={handleChange}
							value={formikValues.description}
							isInvalid={errors.description}
							errorMessage={errors.description}
							placeholder={intl.formatMessage(
								{
									id: 'annualComplementaryActivitiesPlanForm.characters.placeholder',
								},
								{ nChars: '5000' }
							)}
						/>
					</Col>
				</Row>
				<Row>
					<Col>
						<MultipleUploadArea
							documents={documents}
							setDocuments={setDocuments}
							documentType='CANDIDATURE_CHANGE_ANNEX'
							uploadCallback={docsUploadCallback}
							deleteCallback={docsDeleteCallback}
							readMode={readMode}
						/>
					</Col>
				</Row>
			</fieldset>
			<div className='form-actions mt-5'>
				<button
					className='btn btn-link'
					type='button'
					onClick={handlePreviousStep}
				>
					<FormattedMessage id='all.back' />
				</button>
				{showNextStepButton && (
					<button
						className='btn btn-primary'
						type='button'
						onClick={handleNextStep}
					>
						<FormattedMessage id='all.nextStep' />
					</button>
				)}
			</div>
		</>
	);
}
