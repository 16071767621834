import {AuthenticatedDownloadLink} from "../document/AuthenticatedDownloadLink";
import React from "react";
import {FormattedMessage} from "react-intl";

export function PaymentEntryDocumentLink({document: d}) {
    const alt = (d.fileDescription ? (d.fileDescription + ' : ') : '') + d.name;
    return (
        <AuthenticatedDownloadLink
            title={alt} className='payment-entry-document-link'
            url={d.documentPath}
            filename={d.name}
        >
            [<FormattedMessage id='candidature.payments.serviceInformation' />]
        </AuthenticatedDownloadLink>
    );
}