import {Col, Form, Row, Table} from "react-bootstrap";
import {FormattedMessage} from "react-intl";
import React from "react";
import {FaInfoCircle} from "react-icons/fa";
import {MappingType} from "../../../models/MappingType";

export function hrmDtos(map) {
    const l = [];
    map.forEach( (v, k) => {v.forEach(s => {l.push({mappingType: s, districtCode: k})})});
    return l;
}

export function EaHRMapping({
    tgp,
    index,
    otherDistrictOptions,
    districtOptions,
    setFieldValue,
    errors
}) {
    const districtNames = districtOptions.map(d => d.description);
    const otherdistrictNames = otherDistrictOptions.map(d => d.description);
    const hrMap = new Map();

    tgp.hrMapList.forEach(m => setSkill(m.mappingType, m.districtCode))

    function setSkill(skillType, districtCode) {
        let skillSet = new Set();
        if(hrMap.has(districtCode)) {
            skillSet = hrMap.get(districtCode);
        } else {
            hrMap.set(districtCode, skillSet);
        }
        skillSet.add(skillType);
    }

    function setSkillMapping(skillType, district) {
        return (e) => {
            const checked = e.target.checked;
            let skillSet = new Set();
            if(hrMap.has(district.code)) {
                skillSet = hrMap.get(district.code);
            } else {
                hrMap.set(district.code, skillSet);
            }
            if(checked){
                skillSet.add(skillType);
            } else {
                skillSet.delete(skillType);
            }
            setFieldValue(`tgpList[${index}].hrMapList`, hrmDtos(hrMap))
        }
    }

    const SMCheckBox = ({skillType, district}) => {
        return <Form.Check checked={hrMap.get(district.code)?.has(skillType)} onChange={setSkillMapping(skillType, district)} />
    }

    return (
        <>
        <Row>
            <Col md={12}>
                <Table className={'w-100 d-table'}>
                    <thead>
                        <tr>
                            <th align={'center'} className={'text-left'}><label><FormattedMessage id={'eaAccreditation.View.Geo.locations.district'}/></label></th>
                            <th align={'center'} className={'text-center'}><label><FormattedMessage id={'eaAccreditationForm.step4.accordion.header.projectCreation.short'}/></label></th>
                            <th align={'center'} className={'text-center'}><label><FormattedMessage id={'eaAccreditationForm.step4.accordion.header.followUp.short'}/></label></th>
                            <th align={'center'} className={'text-center'}><label><FormattedMessage id={'eaAccreditationForm.step4.accordion.header.consultancy.short'}/></label></th>
                            <th align={'center'} className={'text-center'}><label><FormattedMessage id={'eaAccreditationForm.step4.accordion.header.skillsExtension.short'}/></label></th>
                        </tr>
                    </thead>
                    <tbody>
                        {districtOptions.map( d => (
                        <tr key={d.code}>
                            <td className={'text-left'}>{d.description}</td>
                            <td align={'center'} className={'text-center'}><SMCheckBox skillType={MappingType.CREATION} district={d}/></td>
                            <td align={'center'} className={'text-center'}><SMCheckBox skillType={MappingType.FOLLOW_UP} district={d}/></td>
                            <td align={'center'} className={'text-center'}><SMCheckBox skillType={MappingType.CONSULTING} district={d}/></td>
                            <td align={'center'} className={'text-center'}><SMCheckBox skillType={MappingType.EXPAND_SKILLS} district={d}/></td>
                        </tr>
                        ))}
                    </tbody>
                </Table>
            </Col>
        </Row>
        <Row>
            <Col className={'text-secondary'}>
                <FaInfoCircle/> <FormattedMessage id={'eaAccreditationForm.step4.accordion.header.tgpAllReview'}/>
            </Col>
        </Row>
        </>
    );
}