import { apiAxios as authAxios } from '../axios.config';


/*

export const saveSemestralReport = (b11ExternalID, data, queryParams) => {
	return authAxios.post(`api/semestralReport/${b11ExternalID}`, data, { params: queryParams });
};

export const listSemestralReports = ( queryParams) => {
	return authAxios.get(`api/semestralReport/list`, { params: queryParams });
};

 export const submitSemestralReportDecision= (b11ExternalId, data) => {
	return authAxios.post(`api/consultingRequest/${b11ExternalId}/submitDecision/`, data);
};


export const semestralReportFormStatus = (b11ExternalId) => {
  return authAxios.get(`api/semestralReport/${b11ExternalId}/allowActions/`)
}

export const canListSemestralReport = (consultingRequestExternalId) => {
	return authAxios.get(`/api/semestralReport/canList`,{params:{consultingRequestExternalId:consultingRequestExternalId}})
};

export const canCreateSemestralReport = (consultingRequestExternalId) => {
	if(consultingRequestExternalId=== undefined){
		return ;
	}
	return authAxios.get(`/api/semestralReport/canCreate`,{params:{consultingRequestExternalId:consultingRequestExternalId}})
};




*/

export const saveRevocation = (data) => {
	return authAxios.post(`api/revocation`, data);
};

export const getRevocation = (externalId) => {
	return authAxios.get(`api/revocation/${externalId}`)
}


export const getRevocationDocumentsByType = (externalId,type) => {
	return authAxios.get(`api/revocation/${externalId}/documents/type/${type}`)
}

export const getRevocationDocuments = (externalId) => {
	return authAxios.get(`api/revocation/${externalId}/documents`)
}


export const uploadDocument = (externalId, formData) => {
	return authAxios.post(`/api/revocation/${externalId}/documents`, formData)
}

export const deleteDocument = (externalId, documentExternalId) => {
	return authAxios.delete(`/api/revocation/${externalId}/documents/${documentExternalId}`)
}


export const submitDecisionRevocation = (externalId, data) => {
	return authAxios.post(`/api/revocation/${externalId}/submitDecision`, data);
};

export const submitClarificationRevocation = (externalId, data) => {
	return authAxios.post(`/api/revocation/${externalId}/submitClarification`, data);
};

export const submitCommunicationRevocation = (externalId, data) => {
	return authAxios.post(`/api/revocation/${externalId}/submitCommunication`, data);
};


export const listRevocations = (index, size, filters) => {
	let params = {...filters};
	params.index=index;
	params.size=size;
	return authAxios.get('/api/revocation', {
		params: params
	});
};




