import React, { useEffect, useState } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { AlertError } from '../../components/bootstrap/AlertError';
import { AddTermOfAcceptForm } from '../../components/candidature/AddTermOfAcceptForm';
import Loading from '../../components/general/Loading';
import { SubTemplate } from '../../components/general/SubTemplate';
import { DocumentType } from '../../models/DocumentType';
import {getTermOfAccept, submitTermOfAccept} from '../../rest/candidature';
import { listDocumentsByTypeAndCandidatureExternalId } from '../../rest/document';
import { handleError, isNotBusinessError } from '../../utils/handleError';
import {
  getTermOfAcceptOfChange,
  listCandidatureChangeRequestDocumentsByType, submitTermOfAcceptOnChange
} from "../../rest/candidatureChangeRequest";

export function CandidatureChangeSubmitTermOfAccept() {
  const { externalId,candidatureExternalId } = useParams();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [userDocuments, setUserDocuments] = useState([]);
  const [term, setTerm] = useState([]);
  const [submit, setSubmit] = useState(false);

  async function fetchData() {
    try {
      const [{ data: userDocumentsIn },{data:termIn}] = await Promise.all([
        await listCandidatureChangeRequestDocumentsByType(
            DocumentType.CANDIDATURE_CHANGE_TERM_ACCEPT_SIGNED,
            candidatureExternalId,
            externalId
        ),
          await getTermOfAcceptOfChange(candidatureExternalId,externalId)
      ]);
      setUserDocuments([loadDocument(userDocumentsIn,DocumentType.CANDIDATURE_CHANGE_TERM_ACCEPT_SIGNED)] );
      console.log(userDocuments)
      setTerm(termIn);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  }
  useEffect(() => {
    fetchData();
  }, []);

  function loadDocument(data, documentType) {
    if (data.length !== 0) {
      const document = data[0];
      return {
        documentType: document.documentType,
        externalId: document.externalId,
        name: document.name,
        documentPath: document.documentPath,
        content: '',
        submited: true,
        error: '',
      };
    }

    return {
      documentType: documentType,
      externalId: '',
      name: '',
      content: null,
      submited: false,
      error: '',
    };
  }

  if (submit) {
    return <Navigate replace to={`/candidatura/${candidatureExternalId}/alterarCandidatura/${externalId}/detalhe`} />;
  }

  async function submitTerms(values) {
    try {
      console.log(externalId)
      await submitTermOfAcceptOnChange(candidatureExternalId,externalId,values);
      setSubmit(true);
    } catch (error) {
      setError(error);
    }
  }

  function handleSubmit(values) {
    console.log(values)
    submitTerms(values);
  }

  if (loading) {
    return <Loading />;
  }

  if (error && isNotBusinessError(error)) {
    return handleError(error);
  }

  return (
    <SubTemplate hasBackButton={true}>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-8 offset-lg-2 mb-5'>
            <AlertError error={error} />
            <AddTermOfAcceptForm
                term={term}
              onSubmit={handleSubmit}
              userDocuments={userDocuments}
              externalId={externalId}
                candidatureExternalId={candidatureExternalId}
              setDocuments={setUserDocuments}
                isChange={true}
            />
          </div>
        </div>
      </div>
    </SubTemplate>
  );
}
