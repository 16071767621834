import {Formik} from 'formik';
import React, {useEffect, useRef, useState} from 'react';
import {Col, Container, Form, Row} from 'react-bootstrap';
import {FaAngleLeft, FaCheck, FaRegCheckCircle, FaRegSave, FaTimes} from 'react-icons/fa';
import {FormattedMessage, useIntl} from 'react-intl';
import {Navigate, useNavigate, useParams} from 'react-router-dom';
import * as yup from 'yup';
import {isEntityUser, isIEFPUser, isPromoterUser,} from '../../authentication/authenticationHelper';
import {AlertError} from '../../components/bootstrap/AlertError';
import {BusinessActivityStep} from '../../components/developmentPlan/BusinessActivityStep';
import {CompanyMentoringDiagnosisStep} from '../../components/developmentPlan/CompanyMentoringDiagnosisStep';
import {FinancialSituationStep} from '../../components/developmentPlan/FinancialSituationStep';
import {HumanResourcesStep} from '../../components/developmentPlan/HumanResourcesStep';
import {ProjectManagerStep} from '../../components/developmentPlan/ProjectManagerStep';
import {StrategicDiagnosisStep} from '../../components/developmentPlan/StrategicDiagnosisStep';
import {YearTimeline} from '../../components/developmentPlan/YearTimeline';
import {ConfirmationAuthenticationModal} from '../../components/general/ConfirmationAuthenticationModal';
import {DecisionDialog} from '../../components/bootstrap/DecisionDialog';
import {FormSideNavBar} from '../../components/general/FormSideNavBar';
import Loading from '../../components/general/Loading';
import {SubTemplate} from '../../components/general/SubTemplate';
import {SuccessToast} from '../../components/general/SuccessToast';
import {FinancialModalityStep} from '../../components/promoter/FinancialModalityStep';
import {
	SemestralReportFormStep1,
	SemestralReportFormStep2,
	SemestralReportFormStep3,
} from '../../components/semestralReport';
import {createCustomErrorMessage} from '../../hooks/errorMessage';
import {
	getDevelopmentPlanByExternalId,
	saveDevelopmentPlan,
	submitDevelopmentPlanDecision,
} from '../../rest/developmentPlan';
import {getEntityGetList, getEntityTgpList} from '../../rest/entity';
import {listSchoolQualifications} from '../../rest/schoolQualification';
import {CommercialActivityColumnFormUtils} from '../../utils/CommercialActivityColumnFormUtils';
import {CompanyIdentificationFormUtils} from '../../utils/CompanyIdentificationFormUtils';
import {EconomicStatusRatioColumnFormUtils} from '../../utils/EconomicStatusRatioColumnFormUtils';
import {FinancialModalityFormUtils} from '../../utils/FinancialModalityFormUtils';
import {handleError, isNotBusinessError} from '../../utils/handleError';
import {MceFinancingFormUtils} from '../../utils/MceFinancingFormUtils';
import {MceYearType} from '../../models/MceYearType';
import {ProcessState} from '../../models/ProcessState';
import {DateUtils} from "../../utils/DateUtils";


export function AddDevelopmentPlan() {
	const isEntity = isEntityUser();
	const isPromoter = isPromoterUser();

	//for both iefp and entity users. In case of iefp, it is the processExternalId. Also, if it got here without the externalId, then its value is undefined
	const { externalId: externalId } = useParams();

	const [loading, setLoading] = useState(true);
	const [submited, setSubmited] = useState(false);

	//errors
	const [error, setError] = useState(null);
	const [navErrors, setNavErrors] = useState([]);

	const [formStep, setFormStep] = useState(0);

	const [formikInitialValues, setFormikInitialValues] = useState(null);

	const [showSaveToast, setShowSaveToast] = useState(false);

	//modals
	const [showSubmitModal, setShowSubmitModal] = useState(false);
	const [showApproveModal, setShowApproveModal] = useState(false);
	const [showRejectModal, setShowRejectModal] = useState(false);

	const [getList, setGetList] = useState([]);
	const [tgpList, setTgpList] = useState([]);

	const [schoolQualifications, setSchoolQualifications] = useState([]);

	const navigate = useNavigate();
	const intl = useIntl();

	const formikPropsRef = useRef(null);

	const [tabsIds, setTabsIds] = useState([
		'developmentPlan.eaidentification',
		'developmentPlan.companyidentification',
		'developmentPlan.modalityidentificationMenu',
		'developmentPlan.projectapproval',
		'developmentPlan.strategicDiagnosis',
		'developmentPlan.humanResources',
		'developmentPlan.businessActivity',
		'developmentPlan.financialSituation',
		'developmentPlan.companyMentoringDiagnosisMenu',
		'developmentPlan.managersIdentificationMenu',
		'developmentPlan.firstYearTimelineMenu',
		'developmentPlan.secondYearTimelineMenu',
		'developmentPlan.thirdYearTimelineMenu',
	]);

	function isNotAfter3Years(value) {
		return DateUtils.addDays(new Date(value), 1) <= DateUtils.addYears(new Date(formikPropsRef.current.values.mceSupportPeriod.initialActivityDate), 3);

	}

	const validationSchema = yup.object().shape({
		identificationEa: yup.object().shape({
			technicalTeamManagerExternalId: yup
				.string()
				.test(
					'isGETValid',
					<FormattedMessage id='errors.annualComplementaryActivitiesPlan.GET' />,
					(value) => value !== '-1'
				),
		}),
		company: CompanyIdentificationFormUtils.validationObject(true),

		financialModality: FinancialModalityFormUtils.validationObject(),

		strategicDiagnosis: yup.object().shape({
			facilitiesAndEquipments: yup
				.string()
				.required(<FormattedMessage id='errors.fieldRequiredText' />),

			opportunities: yup
				.string()
				.required(<FormattedMessage id='errors.fieldRequiredText' />),

			threats: yup
				.string()
				.required(<FormattedMessage id='errors.fieldRequiredText' />),

			marketCharacterization: yup
				.string()
				.required(<FormattedMessage id='errors.fieldRequiredText' />),
			companyCharacterization: yup
				.string()
				.required(<FormattedMessage id='errors.fieldRequiredText' />),

			val: yup
				.number()
				.required(<FormattedMessage id='errors.fieldRequiredText' />),

			tir: yup
				.number()
				.required(<FormattedMessage id='errors.fieldRequiredText' />),

			prc: yup
				.number()
				.required(<FormattedMessage id='errors.fieldRequiredText' />)
				.test(
					'isNumberValid',
					<FormattedMessage id='errors.eaAccreditationForm.number' />,
					(value) => value >= 0
				),

			strengths: yup
				.string()
				.required(<FormattedMessage id='errors.fieldRequiredText' />),
			weaknesses: yup
				.string()
				.required(<FormattedMessage id='errors.fieldRequiredText' />),

			humanResources: yup.array().of(
				yup.object().shape({
					name: yup
						.string()
						.required(<FormattedMessage id='errors.fieldRequiredText' />),

					gender: yup
						.string()
						.required(<FormattedMessage id='errors.fieldRequiredText' />),

					age: yup
						.number()
						.required(<FormattedMessage id='errors.fieldRequiredText' />)
						.test(
							'isAgeValid',
							<FormattedMessage id='errors.developmentPlan.humanResourceAge' />,
							(value) => value >= 18
						),

					role: yup
						.string()
						.required(<FormattedMessage id='errors.fieldRequiredText' />),

					schoolQualificationCode: yup
						.string()
						.test(
							'isSchoolQualificationValid',
							<FormattedMessage id='errors.developmentPlan.schoolQualification' />,
							(value) => value !== '-1'
						),
				})
			),
		}),

		mceSupportPeriod: yup.object({
			initialActivityDate: yup.date()
				.nullable()
				.required(<FormattedMessage id='errors.fieldRequiredText' />)
				.test(
					'isBeforeInitialDateMceSupport',
					<FormattedMessage id='errors.evaluationEa.initialDateAfterContract' />,
					(value, context) =>
						!(value >= context.parent.initialDateMceSupport)
				),
			endDateMceSupport: yup
				.date()
				.nullable()
				.required(<FormattedMessage id='errors.fieldRequiredText' />)
				.test(
					'isNotBeforeInitialDateMceSupport',
					<FormattedMessage id='errors.evaluationEa.endDateMceSupportCanNotBeBeforeInitialDate' />,
					(value, context) =>
						!(value <= new Date(context.parent.initialDateMceSupport))
				).test('isNotAfter3Years',
					<FormattedMessage id='errors.evaluationEa.endDateMceSupportCanNotBeAfter3Years'/>,
					(value) =>
						isNotAfter3Years(value)),
		}),

		mentoringAndConsultancy: yup.object().shape({
			description: yup
				.string()
				.required(<FormattedMessage id='errors.fieldRequiredText' />),

			mceFinancing: MceFinancingFormUtils.validationObject(),

			aspects: yup.array().of(
				yup.object().shape({
					description: yup
						.string()
						.required(<FormattedMessage id='errors.fieldRequiredText' />),

					resultsToAchieve: yup
						.string()
						.required(<FormattedMessage id='errors.fieldRequiredText' />),

					area: yup
						.string()
						.required(<FormattedMessage id='errors.fieldRequiredText' />),

					hours: yup
						.number()
						.required(<FormattedMessage id='errors.fieldRequiredText' />)
						.test(
							'isNumberValid',
							<FormattedMessage id='errors.positiveNumber' />,
							(value) => value > 0
						),
				})
			),
		}),

		timelines: yup.array().of(
			yup.object().shape({
				entries: yup.array().of(
					yup.object().shape({
						mceActivityType: yup
							.string()
							.test(
								'isSchoolQualificationValid',
								<FormattedMessage id='errors.selectOption' />,
								(value) => value !== '-1'
							),

						actions: yup
							.string()
							.required(<FormattedMessage id='errors.fieldRequiredText' />),

						firstSemesterPrediction: yup
							.number()
							.required(<FormattedMessage id='errors.fieldRequiredText' />)
							.test(
								'isAgeValid',
								<FormattedMessage id='errors.numberHigherOrEqualToZero' />,
								(value) => value >= 0
							),

						secondSemesterPrediction: yup
							.number()
							.required(<FormattedMessage id='errors.fieldRequiredText' />)
							.test(
								'isAgeValid',
								<FormattedMessage id='errors.numberHigherOrEqualToZero' />,
								(value) => value >= 0
							),
					})
				),
			})
		),

		economicStatusRatios: EconomicStatusRatioColumnFormUtils.validationObject(),

		commercialActivities: CommercialActivityColumnFormUtils.validationObject(),
	});




	async function fetchData() {
		try {
			let {data: form} = await getDevelopmentPlanByExternalId(externalId);

			let entityHumanResourceParamsForNonEntityUsers = {
				entityNif: form.identificationEa.entity.nif,
				all:form.processState !== ProcessState.DRAFT
			};

			let [
				{ data: entityGetList },
				{ data: qualifications },
				{ data: entityTgps },
			] = await Promise.all([
				isEntity
					? await getEntityGetList({all:form.processState !== ProcessState.DRAFT})
					: await getEntityGetList(entityHumanResourceParamsForNonEntityUsers),
				await listSchoolQualifications(),
				isEntity
					? await getEntityTgpList({all:form.processState !== ProcessState.DRAFT})
					: await getEntityTgpList(entityHumanResourceParamsForNonEntityUsers),
			]);

			setSchoolQualifications(qualifications);
			setGetList(entityGetList);
			setTgpList(entityTgps);
			setFormikInitialValues(getFormikInitialValues(form));
		} catch (error) {
			setError(error);
		} finally {
			setLoading(false);
		}
	}

	useEffect(() => {
		fetchData();
	}, []);


	function updateYearPeriods (values) {

		const  endFirstYear = DateUtils.addYears(new Date(values.mceSupportPeriod.initialDateMceSupport),1);
		const  endSecondYear =DateUtils.addYears(new Date(values.mceSupportPeriod.initialDateMceSupport),2);

		let finalDate = values.mceSupportPeriod.endDateMceSupport;




		if(finalDate===undefined){
			finalDate = DateUtils.addYears(new Date(values.mceSupportPeriod.initialDateMceSupport),3);
			finalDate.setDate(finalDate.getDate()-1);
			values.mentoringAndConsultancy.mceFinancing.contractDuration =0
		}else{
			finalDate = new Date(finalDate)
			values.mentoringAndConsultancy.mceFinancing.contractDuration = DateUtils.inclusiveMonthsBetweenDates(new Date(values.mceSupportPeriod.initialDateMceSupport),finalDate);
		}



		let numberOfYears = 1;


		if(finalDate> endSecondYear){
			numberOfYears=3;
			values.timelines[2].firstSemesterInitialDate= DateUtils.formatDate(endSecondYear);

			let dateToClone =  DateUtils.addMonths(new Date(endSecondYear.getTime()),6);
			dateToClone.setDate(dateToClone.getDate()-1);


			values.timelines[2].firstSemesterEndDate = DateUtils.formatDate( (dateToClone> finalDate)?  finalDate : dateToClone);

			if(dateToClone>finalDate){
				values.timelines[2].secondSemesterInitialDate=null;
				values.timelines[2].secondSemesterEndDate=null;
			}else{
				let second = new Date(values.timelines[2].firstSemesterEndDate);
				second.setDate(second.getDate()+1)
				values.timelines[2].secondSemesterInitialDate=DateUtils.formatDate(second);
				values.timelines[2].secondSemesterEndDate= DateUtils.formatDate(finalDate);
			}

		}
		if(finalDate>endFirstYear){
			if(numberOfYears===1){
				numberOfYears=2;
			}
			values.timelines[1].firstSemesterInitialDate= DateUtils.formatDate(endFirstYear);

			let dateToClone =  DateUtils.addMonths(new Date(endFirstYear.getTime()),6);
			dateToClone.setDate(dateToClone.getDate()-1);


			values.timelines[1].firstSemesterEndDate = DateUtils.formatDate( (dateToClone> finalDate)?  finalDate : dateToClone);

			if(dateToClone>finalDate){
				values.timelines[1].secondSemesterInitialDate=null;
				values.timelines[1].secondSemesterEndDate=null;
			}else{
				let second = new Date(values.timelines[1].firstSemesterEndDate);
				second.setDate(second.getDate()+1)
				values.timelines[1].secondSemesterInitialDate=DateUtils.formatDate(second);
				if(numberOfYears===2){
					values.timelines[1].secondSemesterEndDate= DateUtils.formatDate(finalDate);
				}else{
					let dateToClone =  DateUtils.addMonths(new Date(values.timelines[1].secondSemesterInitialDate),6);
					dateToClone.setDate(dateToClone.getDate()-1);
					values.timelines[1].secondSemesterEndDate= DateUtils.formatDate(dateToClone);
				}

			}
		}
		values.timelines[0].firstSemesterInitialDate= values.mceSupportPeriod.initialDateMceSupport;

		let dateToClone =  DateUtils.addMonths(new Date(values.timelines[0].firstSemesterInitialDate),6);
		dateToClone.setDate(dateToClone.getDate()-1);


		values.timelines[0].firstSemesterEndDate = DateUtils.formatDate( (dateToClone> finalDate)?  finalDate : dateToClone);


		if(dateToClone>finalDate){
			values.timelines[0].secondSemesterInitialDate=null;
			values.timelines[0].secondSemesterEndDate=null;
		}else{
			let second = new Date(values.timelines[0].firstSemesterEndDate);
			second.setDate(second.getDate()+1)
			values.timelines[0].secondSemesterInitialDate=DateUtils.formatDate(second);
			if(numberOfYears===1){
				values.timelines[0].secondSemesterEndDate= DateUtils.formatDate(finalDate);
			}else{
				let dateToClone =  DateUtils.addMonths(new Date(values.timelines[0].secondSemesterInitialDate),6);
				dateToClone.setDate(dateToClone.getDate()-1);
				values.timelines[0].secondSemesterEndDate= DateUtils.formatDate(dateToClone);
			}
		}

		if(numberOfYears<3){
			values.timelines[2].entries = [];
			values.timelines[2].firstSemesterInitialDate=null;
			values.timelines[2].firstSemesterEndDate=null;
			values.timelines[2].secondSemesterInitialDate=null;
			values.timelines[2].secondSemesterEndDate=null;

		}
		if(numberOfYears<2){
			values.timelines[1].entries = [];
			values.timelines[1].firstSemesterInitialDate=null;
			values.timelines[1].firstSemesterEndDate=null;
			values.timelines[1].secondSemesterInitialDate=null;
			values.timelines[1].secondSemesterEndDate=null;
		}

		values.numberOfYears=numberOfYears;
		console.log(values);
	}



	function getFormikInitialValues(form) {

		let initialValues = form;
		updateYearPeriods(initialValues);




		CompanyIdentificationFormUtils.getFormikInitialValuesCompanyIdentification(
			form,
			true
		);
		FinancialModalityFormUtils.getFormikInitialValuesFinancialModality(form);

		//strategicDiagnosis
		initialValues.strategicDiagnosis.facilitiesAndEquipments ??= '';
		initialValues.strategicDiagnosis.opportunities ??= '';
		initialValues.strategicDiagnosis.threats ??= '';
		initialValues.strategicDiagnosis.marketCharacterization ??= '';
		initialValues.strategicDiagnosis.companyCharacterization ??= '';
		initialValues.strategicDiagnosis.val ??= '';
		initialValues.strategicDiagnosis.tir ??= '';
		initialValues.strategicDiagnosis.prc ??= '';
		initialValues.strategicDiagnosis.prcType ??= '-1';
		initialValues.strategicDiagnosis.strengths ??= '';
		initialValues.strategicDiagnosis.weaknesses ??= '';

		initialValues.strategicDiagnosis.humanResources.forEach((hr) => {
			hr.age ??= '';
		});

		//mentoringAndConsultancy
		initialValues.mentoringAndConsultancy.description ??= '';
		MceFinancingFormUtils.getFormikInitialValuesMceFinancing(
			initialValues.mentoringAndConsultancy.mceFinancing
		);
		initialValues.mentoringAndConsultancy.aspects.forEach((aspect) => {
			aspect.hours ??= '';
		});

		//timelines
		initialValues.timelines.forEach((timeline) => {
			timeline.entries.forEach((entry) => {
				entry.mceActivityType ??= '-1';
				entry.firstSemesterPrediction ??= '';
				entry.secondSemesterPrediction ??= '';
			});
		});

		//economicStatusRatios
		EconomicStatusRatioColumnFormUtils.getFormikInitialValuesEconomicStatusRatioColumns(
			initialValues
		);

		//commercialActivities
		CommercialActivityColumnFormUtils.getFormikInitialValuesCommercialActivityColumns(
			initialValues
		);

		return initialValues;
	}

	function scrollPageToTop() {
		document.body.scrollTop = 0;
		document.documentElement.scrollTop = 0;
	}

	function handlePreviousStep() {
		scrollPageToTop();
		setFormStep(formStep - 1);
	}

	function handleNextStep() {
		scrollPageToTop();
		setFormStep(formStep + 1);
	}

	if (submited) {
		if (isEntity) {
			sessionStorage.setItem('developmentPlanSubmited', submited);
			return <Navigate to='/planodesenvolvimento/lista' />;
		} else {
			//promoter
			sessionStorage.setItem('developmentPlanDecisionSubmited', submited);
			return <Navigate to='/candidatura' />;
		}
	}

	if (loading) {
		return <Loading />;
	}

	if (error && isNotBusinessError(error)) {
		return handleError(error);
	}

	async function handleSave() {
		if (!validateNonDuplicatesAtTgpList(true)) {
			return;
		}

		try {
			let { data: res } = await saveDevelopmentPlan(
				formikPropsRef.current.values
			);

			let processedRes = getFormikInitialValues(res);

			formikPropsRef.current.setValues(processedRes);
			setNavErrors([]);
			formikPropsRef.current.setErrors({});

			setShowSaveToast(true);
		} catch (error) {
			setError(error);
		}
	}

	async function submitDecision(decisionApprove) {
		try {
			let data = {
				flag: decisionApprove,
			};
			await submitDevelopmentPlanDecision(
				formikPropsRef.current.values.externalId,
				data
			);
			setSubmited(true);
		} catch (error) {
			setError(error);
		}
	}

	async function handleSubmit(user, password, setModalError) {
		let values = formikPropsRef.current.values;
		const submissionValues = { ...values };

		submissionValues.credentials = {
			user: user,
			password: password,
		};

		let queryParams = {
			submit: true,
		};

		try {
			await saveDevelopmentPlan(submissionValues, queryParams);
			setSubmited(true);
		} catch (err) {
			let exceptionType = err.response.data.exception;
			if (exceptionType === 'ValidationException') {
				handleNavError(err);
			} else {
				setModalError(err);
			}
		}
	}

	async function formValidationHandler(setShowModal) {
		let errors = await validateForm();
		setNavErrors(Array.from(errors));
		if (errors.size === 0) {
			setShowModal(true);
		}
	}

	function validateNonDuplicatesAtTgpList(onSave, errorMessages) {
		let values = formikPropsRef.current.values;
		const externalIdsCount = values.tgpExternalIdList.reduce((acc, val) => {
			return acc[val] ? ++acc[val] : (acc[val] = 1), acc;
		}, {});

		for (let externalIdCount of Object.values(externalIdsCount)) {
			if (externalIdCount > 1) {
				if (onSave === true) {
					setError(
						createCustomErrorMessage(
							intl.formatMessage({
								id: 'errors.developmentPlan.duplicatedTgps',
							})
						)
					);
				}
				//onSubmit
				else {
					errorMessages.push(
						intl.formatMessage({
							id: 'errors.developmentPlan.duplicatedTgps',
						})
					);
				}
				return false;
			}
		}

		return true;
	}

	async function validateForm() {
		let errors = new Set();

		const formikErrors = await formikPropsRef.current.validateForm();
		console.log(formikErrors)

		Object.keys(formikErrors).forEach((key) => {
			switch (key) {
				case 'identificationEa':
					isEntity && errors.add(0);
					break;
				case 'company':
					isEntity && errors.add(1);
					break;
				case 'financialModality':
					isEntity && errors.add(2);
					break;
				case 'strategicDiagnosis':
					if (isEntity) {
						if (
							formikErrors.strategicDiagnosis.facilitiesAndEquipments ||
							formikErrors.strategicDiagnosis.opportunities ||
							formikErrors.strategicDiagnosis.threats ||
							formikErrors.strategicDiagnosis.marketCharacterization ||
							formikErrors.strategicDiagnosis.companyCharacterization
						) {
							errors.add(4);
						}

						if (formikErrors.strategicDiagnosis.humanResources) {
							errors.add(5);
						}

						if (
							formikErrors.strategicDiagnosis.val ||
							formikErrors.strategicDiagnosis.tir ||
							formikErrors.strategicDiagnosis.pcr ||
							formikErrors.strategicDiagnosis.strengths ||
							formikErrors.strategicDiagnosis.weaknesses
						) {
							errors.add(7);
						}
					}
					break;

				case 'commercialActivities':
					isEntity && errors.add(6);
					break;

				case 'economicStatusRatios':
					isEntity && errors.add(7);
					break;

				case 'mentoringAndConsultancy':
				case 'mceSupportPeriod':
					isEntity && errors.add(8);
					break;

				case 'tgpExternalIdList':
					isEntity && errors.add(9);
					break;

				default:
					//timelines
					if (isEntity && formikErrors.timelines) {
						if (formikErrors.timelines[0]) {
							errors.add(10);
						}

						if (formikErrors.timelines[1]) {
							errors.add(11);
						}

						if (formikErrors.timelines[2]) {
							errors.add(12);
						}
					}
					break;
			}
		});

		let values = formikPropsRef.current.values;

		let errorMessages = [];

		if (
			!FinancialModalityFormUtils.validateAtLeastOneOptionFromEmpreendeXXIMeasureIsSelected(
				values,
				intl,
				errorMessages
			)
		) {
			errors.add(2);
		}

		if (values.strategicDiagnosis.humanResources.length === 0) {
			errorMessages.push(
				intl.formatMessage({
					id: 'errors.developmentPlan.humanResourcesLength',
				})
			);
			errors.add(5);
		}

		if (
			!CommercialActivityColumnFormUtils.validatePercentages(
				values,
				intl,
				errorMessages
			)
		) {
			errors.add(6);
		}

		if (
			!(
				values.mentoringAndConsultancy.followUp ||
				values.mentoringAndConsultancy.consultancy ||
				values.mentoringAndConsultancy.skillExtension
			)
		) {
			errorMessages.push(
				intl.formatMessage({
					id: 'errors.developmentPlan.mceOptionToProvide',
				})
			);
			errors.add(8);
		}

		if (values.mentoringAndConsultancy.aspects.length === 0) {
			errorMessages.push(
				intl.formatMessage({
					id: 'errors.developmentPlan.aspectsToImproveLength',
				})
			);
			errors.add(8);
		}

		if (values.tgpExternalIdList.length === 0) {
			errorMessages.push(
				intl.formatMessage({
					id: 'errors.developmentPlan.tgpListLength',
				})
			);
			errors.add(9);
		} else if (!validateNonDuplicatesAtTgpList(false, errorMessages)) {
			errors.add(9);
		}

		values.timelines.forEach((timeline, index) => {
			if(index+1<=values.numberOfYears) {
				if (timeline.entries.length === 0) {
					errors.add(10 + index);
					errorMessages.push(
						intl.formatMessage(
							{id: 'errors.developmentPlan.timelineLength'},
							{year: index + 1}
						)
					);
				}
			}
		});

		if (errorMessages.length > 0) {
			let message =
				errorMessages.length === 1
					? createCustomErrorMessage(errorMessages[0])
					: createCustomErrorMessage(
					'<p>' +
					intl.formatMessage({
						id: 'errors.more',
					}) +
					'</p><p> - ' +
					errorMessages.join('</p><p> - ') +
					'</p>'
					);

			setError(message);
		}

		return errors;
	}

	function handleNavError(error) {
		let errors = error.response.data.message
			.split(',')
			.map((value) => Number.parseInt(value));
		setNavErrors(errors);
	}

	function renderStep(formikProps) {
		formikPropsRef.current = formikProps;

		const readMode = !(
			isEntity && formikProps.values.processState === ProcessState.DRAFT
		);

		switch (formStep) {
			case 0:
				return (
					<SemestralReportFormStep1
						formStep={formStep + 1}
						numberOfSteps={tabsIds.length}
						errors={formikProps.errors}
						formikValues={formikProps.values}
						handleChange={formikProps.handleChange}
						handleNextStep={handleNextStep}
						entityGetList={getList}
						readMode={readMode}
						goBackPath={
							isEntity
								? '/planodesenvolvimento/lista'
								: isPromoter
								? '/'
								: '/processos'
						}
					/>
				);
			case 1:
				return (
					<SemestralReportFormStep2
						formStep={formStep + 1}
						numberOfSteps={tabsIds.length}
						handlePreviousStep={handlePreviousStep}
						errors={formikProps.errors}
						formikValues={formikProps.values}
						handleChange={formikProps.handleChange}
						handleNextStep={handleNextStep}
						readMode={readMode}
					/>
				);
			case 2:
				return (
					<FinancialModalityStep
						step={formStep + 1}
						numberOfSteps={tabsIds.length}
						handleGoBack={handlePreviousStep}
						errors={formikProps.errors}
						formikValues={formikProps.values}
						handleChange={formikProps.handleChange}
						handleNextStep={handleNextStep}
						readMode={readMode}
					/>
				);

			case 3:
				return (
					<SemestralReportFormStep3
						formStep={formStep + 1}
						numberOfSteps={tabsIds.length}
						handlePreviousStep={handlePreviousStep}
						errors={formikProps.errors}
						formikValues={formikProps.values}
						handleChange={formikProps.handleChange}
						setFieldValue={formikProps.setFieldValue}
						readMode={readMode}
						handleNextStep={handleNextStep}
					/>
				);
			case 4:
				return (
					<StrategicDiagnosisStep
						step={formStep + 1}
						numberOfSteps={tabsIds.length}
						handleGoBack={handlePreviousStep}
						errors={formikProps.errors}
						formikValues={formikProps.values}
						handleChange={formikProps.handleChange}
						handleNextStep={handleNextStep}
						readMode={readMode}
					/>
				);
			case 5:
				return (
					<HumanResourcesStep
						step={formStep + 1}
						numberOfSteps={tabsIds.length}
						handleGoBack={handlePreviousStep}
						errors={formikProps.errors}
						formikValues={formikProps.values}
						handleChange={formikProps.handleChange}
						handleNextStep={handleNextStep}
						setFieldValue={formikProps.setFieldValue}
						schoolQualifications={schoolQualifications}
						readMode={readMode}
					/>
				);
			case 6:
				return (
					<BusinessActivityStep
						step={formStep + 1}
						numberOfSteps={tabsIds.length}
						handleGoBack={handlePreviousStep}
						errors={formikProps.errors}
						formikValues={formikProps.values}
						handleChange={formikProps.handleChange}
						handleNextStep={handleNextStep}
						readMode={readMode}
					/>
				);
			case 7:
				return (
					<FinancialSituationStep
						step={formStep + 1}
						numberOfSteps={tabsIds.length}
						handleGoBack={handlePreviousStep}
						errors={formikProps.errors}
						formikValues={formikProps.values}
						handleChange={formikProps.handleChange}
						handleNextStep={handleNextStep}
						readMode={readMode}
					/>
				);
			case 8:
				return (
					<CompanyMentoringDiagnosisStep
						step={formStep + 1}
						numberOfSteps={tabsIds.length}
						handleGoBack={handlePreviousStep}
						errors={formikProps.errors}
						formikValues={formikProps.values}
						handleChange={formikProps.handleChange}
						handleNextStep={handleNextStep}
						setFieldValue={formikProps.setFieldValue}
						readMode={readMode}
						updatePeriods={updateYearPeriods}
					/>
				);
			case 9:
				return (
					<ProjectManagerStep
						step={formStep + 1}
						numberOfSteps={tabsIds.length}
						handleGoBack={handlePreviousStep}
						errors={formikProps.errors}
						handleChange={formikProps.handleChange}
						formikValues={formikProps.values}
						handleNextStep={handleNextStep}
						setFieldValue={formikProps.setFieldValue}
						tgpList={tgpList}
						readMode={readMode}
					/>
				);
			case 10:
				return (
					<YearTimeline
						step={formStep + 1}
						numberOfSteps={tabsIds.length}
						handlePreviousStep={handlePreviousStep}
						errors={formikProps.errors}
						handleChange={formikProps.handleChange}
						formikValues={formikProps.values}
						handleNextStep={handleNextStep}
						setFieldValue={formikProps.setFieldValue}
						mceYearType={MceYearType.FIRST}
						finalStep={false}
						readMode={readMode}
					/>
				);
			case 11:
				return (
					<YearTimeline
						step={formStep + 1}
						numberOfSteps={tabsIds.length}
						handlePreviousStep={handlePreviousStep}
						errors={formikProps.errors}
						handleChange={formikProps.handleChange}
						formikValues={formikProps.values}
						handleNextStep={handleNextStep}
						setFieldValue={formikProps.setFieldValue}
						mceYearType={MceYearType.SECOND}
						finalStep={false}
						readMode={readMode}
					/>
				);
			case 12:
				return (
					<YearTimeline
						step={formStep + 1}
						numberOfSteps={tabsIds.length}
						handlePreviousStep={handlePreviousStep}
						errors={formikProps.errors}
						handleChange={formikProps.handleChange}
						formikValues={formikProps.values}
						handleNextStep={handleNextStep}
						setFieldValue={formikProps.setFieldValue}
						mceYearType={MceYearType.THIRD}
						finalStep={true}
						readMode={readMode}
						entityGetList={getList}
					/>
				);

			default:
				console.log('Something went wrong rendering the form step');
		}
	}

	return (
		<SubTemplate>
			<Container>
				<ConfirmationAuthenticationModal
					show={showSubmitModal}
					handleClose={() => setShowSubmitModal(false)}
					submitHandler={handleSubmit}
					idsPreffix={'annualComplementaryActivitiesPlan.submitModal'}
					confirmButtonCompleteLabelId={'all.submitButtonText'}
				/>

				<DecisionDialog
					show={showApproveModal || showRejectModal}
					handleConfirm={
						showApproveModal
							? () => submitDecision(true)
							: () => submitDecision(false)
					}
					handleClose={() => {
						showApproveModal
							? setShowApproveModal(false)
							: setShowRejectModal(false);
					}}
					titleId={
						showApproveModal
							? 'developmentPlan.accept.title'
							: 'developmentPlan.reject.title'
					}
					bodyId={'developmentPlan.decisionDialog.body'}
					needReason={false}
				/>
				<Row>
					<Col md={{ span: 8, offset: 2 }}>
						<h2 className='mb-1 text-secondary'>
							<button className={'back-button btn-link'} onClick={()=>navigate(-1)}>
								<FaAngleLeft />
							</button>
							{intl.formatMessage({
								id: `developmentPlan.title`,
							}) + formikInitialValues.candidatureProcessNumber}
						</h2>
						<p className='mb-5 text-secondary'>
							<FormattedMessage id='developmentPlan.subTitle' />
							<span
								className="badge rounded-pill bg-secondary fw-normal ms-2"><span
								className="text-uppercase fw-bold"><FormattedMessage
								id={`processState.${formikInitialValues.processState}`}

							/> </span> {formikInitialValues.submissionDate !== undefined && formikInitialValues.submissionDate !== null && (<> · <FormattedMessage
								id={'submitted.process.at'}
								values={{date: formikInitialValues.submissionDate}}/>  </>)} {formikInitialValues.approvalDate !== undefined && formikInitialValues.approvalDate !== null && (<> · {ProcessState.APPROVED === formikInitialValues.processState ?
								<FormattedMessage id={'approvedIEFP.process.at'}
												  values={{date: formikInitialValues.approvalDate}}/> :
								<FormattedMessage id={'rejectedIEFP.process.at'}
												  values={{date: formikInitialValues.approvalDate}}/>}   </>)}  </span>
						</p>
					</Col>
				</Row>
				<Row className='mb-5'>
					<Col md='2'>
						<FormSideNavBar
							tabsIdsArray={tabsIds}
							formStep={formStep}
							setFormStep={setFormStep}
							navErrors={navErrors}
							setNavErrors={setNavErrors}
						/>
					</Col>
					<Col md='8'>
						<AlertError error={error} />
						<SuccessToast
							message={intl.formatMessage({
								id: 'annualComplementaryActivitiesPlan.saveToastInformation',
							})}
							show={showSaveToast}
							setShow={setShowSaveToast}
						/>
						<Formik
							initialValues={formikInitialValues}
							validateOnBlur={false}
							validateOnChange={false}
							validationSchema={validationSchema}
							onSubmit={handleSubmit}
						>
							{(formikProps) => (
								<Form onSubmit={formikProps.handleSubmit}>
									{renderStep(formikProps)}
								</Form>
							)}
						</Formik>
					</Col>
					<Col md='2'>
						<div>
							{isEntity &&
							formikInitialValues.processState === ProcessState.DRAFT && (
								<button
									className='btn btn-outline-primary d-flex align-items-center justify-content-center mb-4 w-100'
									type='button'
									onClick={() => handleSave()}
								>
									<FaRegSave />
									<FormattedMessage id='all.save' />
								</button>
							)}
							{isPromoter &&
							formikInitialValues.processState === ProcessState.SUBMITTED && (
								<>
									<button
										className='btn btn-primary d-flex align-items-center justify-content-center mb-4 w-100'
										type='button'
										onClick={() => formValidationHandler(setShowRejectModal)}
									>
										<FaTimes /> <FormattedMessage id='all.button.reject' />
									</button>
									<button
										className='btn btn-primary d-flex align-items-center justify-content-center mb-4 w-100'
										type='button'
										onClick={() => formValidationHandler(setShowApproveModal)}
									>
										<FaCheck /> <FormattedMessage id='all.button.approve' />
									</button>
								</>
							)}
							{isEntity &&
							formikInitialValues.processState === ProcessState.DRAFT && (
								<button
									className='btn btn-primary d-flex align-items-center justify-content-center w-100'
									type='button'
									onClick={() => formValidationHandler(setShowSubmitModal)}
								>
									<FaRegCheckCircle />
									<FormattedMessage id='all.requestPromoterValidationButtonText' />
								</button>
							)}
						</div>
					</Col>
				</Row>
			</Container>
		</SubTemplate>
	);
}
