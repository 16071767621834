import React, { useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { Strings } from '../../utils/Strings';
import { EnumSelectField } from '../bootstrap/EnumSelectField';
import { TextInputField } from '../bootstrap/TextInputField';
import { ProfileDescriptionDialog } from './ProfileDescriptionDialog';

export function PromoterFormStep1({
  values,
  profiles,
  countries,
  districts,
  counties,
  parishes,
  onDistrictChange,
  onCountyChange,
  setFieldValue,
  validateForm,
  errors,
  handleChange,
  savePromoter,
  numberOfSteps
}) {
  const intl = useIntl();
  const [selectedProfileDescription, setSelectedProfileDescription] = useState('');
  const [showProfileDescriptionDialog, setShowProfileDescriptionDialog] = useState(false);

  const countriesDescriptions = countries.map((country) => country.description);

  function getProfileLabelDescription(description) {
    if (description.length < 80) {
      return description;
    }
    return description.substring(0, 80) + '...';
  }

  function isChecked(code) {
    if (code === values['profile'].code) {
      return true;
    }
    return false;
  }

  function handleProfileChange(code) {
    if (values['profile'].code === code) {
      setFieldValue('profile', { code: '', checked: false });
    } else {
      setFieldValue('profile', { code: code, checked: true });
    }
  }

  function handleDistrictChange(e) {
    setFieldValue('county', '-1');
    setFieldValue('parish', '-1');
    onDistrictChange(e.target.value);
    setFieldValue('district', e.target.value);
  }

  function handleCountyChange(e) {
    setFieldValue('parish', '-1');
    onCountyChange(e.target.value);
    setFieldValue('county', e.target.value);
  }

  function handleParishChange(e) {
    setFieldValue('parish', e.target.value);
  }

  const handleNextStep = async () => {
    const errors = await validateForm();
    if (Object.keys(errors).length === 0) {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
      savePromoter(values, setFieldValue);
    }
  };

  return (
    <div>
      {showProfileDescriptionDialog && (
        <ProfileDescriptionDialog
          description={selectedProfileDescription}
          setShowProfileDescriptionDialog={setShowProfileDescriptionDialog}
        />
      )}
      <h4 className='text-primary mb-0'>
        <FormattedMessage id='promoterForm.profile' />
      </h4>
      <small className='text-secondary text-uppercase'>
        {intl.formatMessage(
          { id: 'all.step' },
          { step: values.step, numberOfSteps: numberOfSteps }
        )}
      </small>
      <Form.Group controlId='profile'>
        <Form.Label>
          <FormattedMessage id='profileDialog.title' />
        </Form.Label>
        {profiles.map((profile) => (
          <div key={profile.code} className='d-flex align-items-center mt-2'>
            <Form.Check
              className='d-flex align-items-center mb-0'
              key={profile.code}
              type={'radio'}
              id={`${profile.code}profile`}
              label={getProfileLabelDescription(profile.description)}
              checked={isChecked(profile.code)}
              onChange={() => handleProfileChange(profile.code)}
              isInvalid={errors.profile}
            />
            {profile.description.length > 80 && (
              <button
                className='btn btn-link p-0 mx-2'
                type='button'
                onClick={() => {
                  setSelectedProfileDescription(profile.description);
                  setShowProfileDescriptionDialog(true);
                }}>
                <FormattedMessage id='all.readMore' />
              </button>
            )}
          </div>
        ))}
      </Form.Group>
      <Row>
        {!Strings.isBlank(values['profile'].code) && (
          <>
            <label className='mt-4 mb-0'>Indique a sua residência</label>
            <Col md='4'>
              <Form.Group controlId={'district'}>
                <Form.Label>
                  <FormattedMessage id='promoterForm.district' />
                </Form.Label>
                <Form.Control
                  as='select'
                  name={'district'}
                  onChange={(e) => handleDistrictChange(e)}
                  value={values.district}
                  isInvalid={errors.district}>
                  <option value={'-1'}>
                    <FormattedMessage id='all.chooseOne' />
                  </option>
                  {districts.map((district) => (
                    <option key={district.code} value={district.code}>
                      {district.description}
                    </option>
                  ))}
                </Form.Control>
                <Form.Control.Feedback type='invalid'>{errors.district}</Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col md='4'>
              <Form.Group controlId={'county'}>
                <Form.Label>
                  <FormattedMessage id='promoterForm.county' />
                </Form.Label>
                <Form.Control
                  as='select'
                  name={'county'}
                  onChange={(e) => handleCountyChange(e)}
                  value={values.county}
                  isInvalid={errors.county}
                  disabled={values.district === '-1'}>
                  <option value={'-1'}>
                    {values.district !== '-1' ? (
                      <FormattedMessage id='all.chooseOne' />
                    ) : (
                      <FormattedMessage id='all.chooseOneDistrict' />
                    )}
                  </option>
                  {counties.map((county) => (
                    <option key={county.code} value={county.code}>
                      {county.description}
                    </option>
                  ))}
                </Form.Control>
                <Form.Control.Feedback type='invalid'>{errors.county}</Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col md='4'>
              <Form.Group controlId={'parish'}>
                <Form.Label>
                  <FormattedMessage id='promoterForm.parish' />
                </Form.Label>
                <Form.Control
                  as='select'
                  name={'parish'}
                  onChange={(e) => handleParishChange(e)}
                  value={values.parish}
                  isInvalid={errors.parish}
                  disabled={values.county === '-1'}>
                  <option value={'-1'}>
                    {values.county !== '-1' ? (
                      <FormattedMessage id='all.chooseOne' />
                    ) : (
                      <FormattedMessage id='all.chooseOneCounty' />
                    )}
                  </option>
                  {parishes.map((parish) => (
                    <option key={parish.code} value={parish.code}>
                      {parish.description}
                    </option>
                  ))}
                </Form.Control>
                <Form.Control.Feedback type='invalid'>{errors.parish}</Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col md='4'>
              <TextInputField
                controlId={'postalCode'}
                labelId={'promoterForm.postalCode'}
                name={'postalCode'}
                handleChange={handleChange}
                value={values.postalCode}
                isInvalid={errors.postalCode}
                errorMessage={errors.postalCode}
                placeholder={intl.formatMessage({ id: 'all.postalCode.placeholder' })}
              />
            </Col>
          </>
        )}
        {!Strings.isBlank(values['profile'].code) && (
          <Col md='8'>
            <TextInputField
              controlId={'address'}
              labelId={'promoterForm.address'}
              name={'address'}
              handleChange={handleChange}
              value={values.address}
              isInvalid={errors.address}
              errorMessage={errors.address}
            />
          </Col>
        )}
      </Row>
      <div className='form-actions justify-content-end'>
        <button className='btn btn-primary' type='button' onClick={(e) => handleNextStep(e)}>
          <FormattedMessage id='all.nextStep' />
        </button>
      </div>
    </div>
  );
}
