import {apiAxios as authAxios} from "../axios.config";

export const getIrregularities = (externalId) => {
    return authAxios.get(`/api/candidature/${externalId}/irregularities`);
};

export const getIrregularity = (candidatureExternalId, irregularityExternalId) => {
    return authAxios.get(`/api/candidature/${candidatureExternalId}/irregularities/${irregularityExternalId}`);
};

export const decideIrregularity = (candidatureExternalId, irregularityExternalId, irregularity) => {
    return authAxios.patch(`/api/candidature/${candidatureExternalId}/irregularities/${irregularityExternalId}`, irregularity);
};

export const addIrregularity = (externalId, irregularity) => {
    return authAxios.post(`/api/candidature/${externalId}/irregularities`, irregularity);
};

export const addIrregularityWithDocument = (externalId, formData) => {
    return authAxios.post(`/api/candidature/${externalId}/irregularities`, formData);
};


export const responseIrregularity = (externalId,irregularityExternalId, irregularity) => {
    return authAxios.post(`/api/candidature/${externalId}/irregularities/${irregularityExternalId}`, irregularity);
};

export const responseIrregularityWithDocument = (externalId, irregularityExternalId,formData) => {
    return authAxios.post(`/api/candidature/${externalId}/irregularities/${irregularityExternalId}`, formData);
};

export const getIrregularityCauseToRegulationMap = (externalId) => {
    return authAxios.get(`/api/candidature/${externalId}/irregularities/causemap`);
};

