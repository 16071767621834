import {apiAxios as authAxios} from "../axios.config";

export const getPayments = (externalId) => {
    return authAxios.get(`/api/candidature/${externalId}/payments`);
};

export const savePayment = (externalId, payment) => {
    return authAxios.post(`/api/candidature/${externalId}/payments`, payment);
};

export const savePaymentWithDocument = (externalId, formData) => {
    return authAxios.post(`/api/candidature/${externalId}/payments`, formData);
};

export const saveUnemploymentDelegationRecord = (externalId, unemploymentDelegationRecord) => {
    return authAxios.post(`/api/candidature/${externalId}/payments/unemploymentDelegationRecord`, unemploymentDelegationRecord);
};

export const doesNotHaveIrregularitiesNorNonCompliances = (externalId) => {
    return authAxios.get(`/api/candidature/${externalId}/payments/doesNotHaveIrregularitiesNorNonCompliances`);
}
