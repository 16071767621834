import { Formik } from 'formik';
import React from 'react';
import { Form } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import * as yup from 'yup';
import { Validator } from '../../utils/Validator';
import { CandidatureFormStep3 } from './CandidatureFormStep3';
import { CandidatureFormStep5Test } from './CandidatureFormStep5Test';
import { CandidatureFormStep6Test } from './CandidatureFormStep6Test';
import { ContestationAnalysisPartialDocuments } from './ContestationAnalysisPartialDocuments';
import {CandidatureFormStep4} from "./CandidatureFormStep4";
import {EconomicViabilityFormStep5} from "../economicViability/EconomicViabilityFormStep5";
import {ResumeValidationTab} from "../../pages/backoffice/validation/ResumeValidationTab";

export function ContestationPartialApprovalForm({
                                                  candidature,
                                                  documentTypes,
                                                  candidatureDocuments,
                                                  setCandidatureDocuments,
                                                  formikPropsRef,
                                                  setError,
                                                  handleSubmit,
                                                  numberOfSteps,
                                                  currentStep,
                                                  isContestation,
                                                    isYearChange
                                                }) {
  candidature.step8Documents = getStep8Documents(candidatureDocuments, documentTypes);

  return (
      <div className='mb-5'>
        <Formik
            initialValues={candidature}
            validateOnBlur={false}
            validateOnChange={false}
            validationSchema={candidatureFormSchema}>
          {(formikProps) => (
              <Form onSubmit={formikProps.handleSubmit}>
                <fieldset>
                  {renderStep(
                      { ...formikProps },
                      documentTypes,
                      candidatureDocuments,
                      setCandidatureDocuments,
                      formikPropsRef,
                      handleSubmit,
                      setError,
                      numberOfSteps,
                      currentStep,
                      isContestation,
                      isYearChange
                  )}
                </fieldset>
              </Form>
          )}
        </Formik>
      </div>
  );
}

function renderStep(
    props,
    documentTypes,
    candidatureDocuments,
    setCandidatureDocuments,
    formikPropsRef,
    handleSubmit,
    setError,
    numberOfSteps,
    currentStep,
    isContestation,
    isYearChange
) {
  props.values.step = currentStep;
  formikPropsRef.current = props;

  switch (currentStep) {
    case 1:
      return (
          // Old investment step

          // <CandidatureFormStep3
          //   {...props}
          //   values={props.values}
          //   handleSubmit={handleSubmit}
          //   numberOfSteps={numberOfSteps}
          //   setError={setError}
          //   isValidate={true}
          // />

          <CandidatureFormStep5Test
              {...props}
              values={props.values}
              handleSubmit={handleSubmit}
              // handleSave={handleSave}
              // handleGoBack={handleGoBack}
              numberOfSteps={numberOfSteps}
              setError={setError}
              isValidate={true}
          />
      );
    case 2:
      return (
          // Transport this to future step if needed

          // <CandidatureFormStep4
          //   {...props}
          //   values={props.values}
          //   handleSubmit={handleSubmit}
          //   numberOfSteps={numberOfSteps}
          //   setError={setError}
          //   isValidate={true}
          //   isContestation={isContestation}
          // />

          <CandidatureFormStep6Test
              {...props}
              values={props.values}
              handleSubmit={handleSubmit}
              // handleSave={handleSave}
              // handleGoBack={handleGoBack}
              numberOfSteps={numberOfSteps}
              setError={setError}
              isValidate={true}
          />
      );

    case 3:
      return (
          <CandidatureFormStep3
              {...props}
              values={props.values}
              handleSubmit={handleSubmit}
              numberOfSteps={numberOfSteps}
              setError={setError}
              isValidate={true}
              isContestation={isContestation}
              isYearChange={{isYearChange}}
          />
      );

    case 4:
      return (

          <EconomicViabilityFormStep5
              {...props}
              values={props.values}
              setError={setError}
              numberOfSteps={numberOfSteps}
              context={ isContestation ? 'contestation' : 'validation'}
          />

      )
    case 5:
      return (

          <ResumeValidationTab
              {...props}
              values={props.values}
              setError={setError}
              numberOfSteps={numberOfSteps}
          />

      )

    // case 6:
    //   return (
    //       <ContestationAnalysisPartialDocuments
    //           {...props}
    //           values={props.values}
    //           documentTypes={documentTypes}
    //           candidatureDocuments={candidatureDocuments}
    //           setCandidatureDocuments={setCandidatureDocuments}
    //           handleSubmit={handleSubmit}
    //           setError={setError}
    //           numberOfSteps={numberOfSteps}
    //       />
    //   );

      // case 3:
      //   return (
      //     <ContestationAnalysisPartialDocuments
      //       {...props}
      //       values={props.values}
      //       documentTypes={documentTypes}
      //       candidatureDocuments={candidatureDocuments}
      //       setCandidatureDocuments={setCandidatureDocuments}
      //       handleSubmit={handleSubmit}
      //       setError={setError}
      //       numberOfSteps={numberOfSteps}
      //     />
      //   );
    default:
      return (
          // <CandidatureFormStep3
          //   {...props}
          //   values={props.values}
          //   handleSubmit={handleSubmit}
          //   setError={setError}
          //   numberOfSteps={numberOfSteps}
          // />

          <CandidatureFormStep5Test
              {...props}
              values={props.values}
              handleSubmit={handleSubmit}
              // handleSave={handleSave}
              // handleGoBack={handleGoBack}
              numberOfSteps={numberOfSteps}
              setError={setError}
              isValidate={false}
          />
      );
  }
}

function getStep8Documents(candidatureDocuments, documentTypes) {
  let files = [];

  let candidatureDocumentsTypes = candidatureDocuments.map((cd) => cd.documentType);

  documentTypes.forEach((fdt) => {
    if (candidatureDocumentsTypes.includes(fdt)) {
      const document = candidatureDocuments.find((cd) => cd.documentType === fdt);
      files.push({
        externalId: document.externalId,
        documentType: document.documentType,
        documentPath: document.documentPath,
        name: document.name,
        content: '',
        submited: true,
        error: ''
      });
    } else {
      files.push({
        externalId: '',
        documentType: fdt,
        name: '',
        content: null,
        submited: false,
        error: ''
      });
    }
  });
  return files;
}

const candidatureFormSchema = yup.object().shape({
  promotersMetaData: yup.array().of(
      yup.object().shape({
        name: yup.string().required(<FormattedMessage id='errors.fieldRequiredText' />),
        nif: yup
            .string()
            .required(<FormattedMessage id='errors.fieldRequiredText' />)
            .test('isNifValid', <FormattedMessage id='errors.promoterForm.nif' />, (value) =>
                Validator.validateNif(value)
            ),
        email: yup
            .string()
            .required(<FormattedMessage id='errors.fieldRequiredText' />)
            .test('isEmailValid', <FormattedMessage id='errors.candidatureForm.email' />, (value) =>
                Validator.validateEmail(value)
            ),
        promoterType: yup
            .string()
            .test(
                'isValidPromoterType',
                <FormattedMessage id='errors.fieldRequiredText' />,
                (value) => value !== '-1'
            )
      })
  ),

  candidatureDetails: yup.object().shape({
    district: yup
        .string()
        .test(
            'isValidDistrict',
            <FormattedMessage id='errors.promoterForm.district' />,
            (value) => value !== '-1'
        ),

    county: yup
        .string()
        .test(
            'isValidCounty',
            <FormattedMessage id='errors.promoterForm.county' />,
            (value) => value !== '-1'
        ),

    parish: yup
        .string()
        .test(
            'isValidParish',
            <FormattedMessage id='errors.promoterForm.parish' />,
            (value) => value !== '-1'
        ),

    companyTypology: yup
        .string()
        .test(
            'isValidCompanyTypology',
            <FormattedMessage id='errors.fieldRequiredText' />,
            (value) => {
              return value !== '-1';
            }
        ),
    projectCharacterization: yup
        .string()
        .required(<FormattedMessage id='errors.fieldRequiredText' />),

    targetMarket: yup.string().required(<FormattedMessage id='errors.fieldRequiredText' />),

    installations: yup.string().required(<FormattedMessage id='errors.fieldRequiredText' />),

    possibleIncentives: yup.string().required(<FormattedMessage id='errors.fieldRequiredText' />),

    artsAndCrafts: yup
        .mixed()
        .test(
            'isValidArtsAndCrafts',
            <FormattedMessage id='errors.fieldRequiredText' />,
            (value) => value !== '-1'
        )
  }),

  candidatureFinancialDetails: yup.object().shape({
    investmentProperties: yup.number().required(<FormattedMessage id='errors.fieldRequiredText' />),
    tangibleAssets: yup.number().required(<FormattedMessage id='errors.fieldRequiredText' />),
    intangibleAssets: yup.number().required(<FormattedMessage id='errors.fieldRequiredText' />),
    several: yup.number().required(<FormattedMessage id='errors.fieldRequiredText' />),
    pettyCash: yup.number().required(<FormattedMessage id='errors.fieldRequiredText' />),
    socialCapital: yup.number().required(<FormattedMessage id='errors.fieldRequiredText' />),
    unemploymentBenefits: yup.number().required(<FormattedMessage id='errors.fieldRequiredText' />),
    otherBenefits: yup.number().required(<FormattedMessage id='errors.fieldRequiredText' />),
    bankLoans: yup.number().required(<FormattedMessage id='errors.fieldRequiredText' />),
    partnerLoans: yup.number().required(<FormattedMessage id='errors.fieldRequiredText' />),
    supplierCredits: yup.number().required(<FormattedMessage id='errors.fieldRequiredText' />),
    otherForeignCapital: yup.number().required(<FormattedMessage id='errors.fieldRequiredText' />),
    investmentSupport: yup.number().required(<FormattedMessage id='errors.fieldRequiredText' />),
    applyNonRefundableSubsidy: yup
        .mixed()
        .test(
            'isValidApplyNonRefundableSubsidy',
            <FormattedMessage id='errors.fieldRequiredText' />,
            (value) => value !== '-1'
        ),
    interestFreeLoan: yup.number().required(<FormattedMessage id='errors.fieldRequiredText' />),
    nonRefundableSubsidy: yup.number().required(<FormattedMessage id='errors.fieldRequiredText' />),
    jobCreationSubsidy: yup.number().required(<FormattedMessage id='errors.fieldRequiredText' />),
    applyUnemploymentBenefits: yup
        .mixed()
        .test(
            'isValidApplyUnemploymentBenefits',
            <FormattedMessage id='errors.fieldRequiredText' />,
            (value) => value !== '-1'
        )
  }).nullable(),

  economicViabilityAccretionValidation: yup.object().shape({
    isSubRepresentationValid: yup
        .mixed()
        .test(
            'isValidAisSubRepresentationValid',
            <FormattedMessage id='errors.fieldRequiredText' />,
            (value) => value !== '-1' && typeof value !== 'undefined' && value!==null
        ),
    isInnovationProjectValid: yup
        .mixed()
        .test(
            'isInnovationProjectValid',
            <FormattedMessage id='errors.fieldRequiredText' />,
            (value) => value !== '-1' && typeof value !== 'undefined' && value!==null
        ),
    isLevelsValid: yup
        .mixed()
        .test(
            'isLevelsValid',
            <FormattedMessage id='errors.fieldRequiredText' />,
            (value) => value !== '-1' && typeof value !== 'undefined' && value!==null
        ),

    levelsValue:yup.number().when('isLevelsValid',{
      is: (val) => val !== undefined && val!=='-1' && val ===true && val !== null,
      then : yup.number().test(
          'isValidlevelsValue',
          <FormattedMessage id='errors.invalidValue' />,
          (value) =>  value !== '-1' &&  value!=='' && value%2.5===0,

      ).nullable()
    }).nullable(),


    isInsideValid: yup
        .mixed()
        .test(
            'isInsideValid',
            <FormattedMessage id='errors.fieldRequiredText' />,
            (value) => value !== '-1' && typeof value !== 'undefined' && value!==null
        ),
    isIefpWorkstationsValid: yup
        .mixed()
        .test(
            'isIefpWorkstationsValid',
            <FormattedMessage id='errors.fieldRequiredText' />,
            (value) => value !== '-1' && typeof value !== 'undefined' && value!==null
        ),
    iefpWorkstationsValue:yup.number().when('isIefpWorkstationsValid',{
      is: (val) => val !== undefined && val!=='-1' && val ===true && val !== null,
      then : yup.number().test(
          'iefpWorkstationsValue',
          <FormattedMessage id='errors.invalidValue' />,
          (value) =>  value !== '-1' &&  value!=='' && value%2.5===0,

      ).nullable()
    }).nullable(),


  }),

  candidatureEmployeeSupportDetails: yup.array().of(
      yup.object().shape({
        promoterMetaData: yup
            .mixed()
            .test(
                'isValidValue',
                <FormattedMessage id='errors.fieldRequiredText'/>,
                (value) => value !== '-1'
            ),
        timeType: yup
            .mixed()
            .test(
                'isValidValue',
                <FormattedMessage id='errors.fieldRequiredText'/>,
                (value) => value !== '-1'
            ),


        hours: yup.number().when('timeType',{
          is: 'PARTIAL',
          then: yup.number().min(1,<FormattedMessage id='errors.notPositive' />,)
        })
      })
  ),

});
