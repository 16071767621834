import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import {
    FaCogs,
    FaFileAlt,
    FaUsers,
} from 'react-icons/fa';
import { FormattedMessage } from 'react-intl';
import { AlertSuccess } from '../../../components/bootstrap/AlertSuccess';
import {HomeCard} from "../../../components/home/HomeCard";

export function HomeIEFP() {
	const [lsItem, setLsItem] = useState(null);

	useEffect(() => {
		checkLocalStorageItems();
	}, []);

  function checkLocalStorageItems() {
  }

  return (
    <Container>
      <Row className='py-4'>
        <Col></Col>
      </Row>
      <Row>
        <Col md={{ span: 8, offset: 2 }}>
          <h2 className='mb-1 text-secondary'>
            <FormattedMessage id='entityHome.userArea' />
          </h2>
          <p className='mb-2 text-secondary'>
            <FormattedMessage id='entityHome.myActions' />
          </p>
          <div className='user-area pb-5'>
            {lsItem && <AlertSuccess lsItem={lsItem} />}
            <Row>
              <Col>
                <HomeCard icon={<FaFileAlt/>} titleId={'iefpHome.candidatures'} linkAddress={'/candidaturas'}/>
                <HomeCard icon={<FaCogs/>} titleId={'iefpHome.processes'} linkAddress={'/processos'}/>
                <HomeCard icon={<FaUsers/>} titleId={'iefpHome.entities'} linkAddress={'/entidades'}/>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </Container>
  );
}
