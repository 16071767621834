import React from 'react';
import {Col, Row, Table} from 'react-bootstrap';
import {FormattedMessage, useIntl} from 'react-intl';
import {CandidatureFormUtils} from '../../utils/CandidatureFormUtils';
import DecimalInputField from '../bootstrap/DecimalInputField';
import {TextAreaInputField} from '../bootstrap/TextAreaInputField';
import {currencyFormat, maxFixed} from '../../utils/CurrencyUtils';
import {FaInfoCircle} from 'react-icons/fa';
import ToolTip from "../bootstrap/ToolTip";

export function CandidatureFormStepOtherCharge({
  errors,
  setFieldValue,
  handleChange,
  values,
  handleSave,
  handleGoBack,
  numberOfSteps
}) {
  const totals = getTotal();

  const intl = useIntl();

  function handleNextStep() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    handleSave(true);
  }

  function handleField(e, year, key) {
    values.otherChargeList[0].otherChargeLineList.find((oC) => oC.year === year)[key] =
      e.target.value;
    setFieldValue('otherChargeList', [...values.otherChargeList]);
  }

  function handleReason(e) {
    values.otherChargeList[0].reasonToOtherCharge = e.target.value;
    setFieldValue('otherChargeList', [...values.otherChargeList]);
  }

  function getTotal() {
    let totals = values.otherChargeList[0].otherChargeLineList.reduce(
      function (obj, item) {
        if (!CandidatureFormUtils.is3YearsCoverageOnly(values, item.year)) {
          obj.refund += parseFloat(item.refund);
          obj.financialCharges += parseFloat(item.financialCharges);
          obj.debtCapitalInitial += parseFloat(item.debtCapitalInitial);
          obj.debtCapitalFinal += parseFloat(item.debtCapitalFinal);
        }

        return obj;
      },
      { refund: 0, financialCharges: 0, debtCapitalInitial: 0, debtCapitalFinal: 0 }
    );

    return totals;
  }



  return (
    <div>
      <h4 className='text-primary mb-0'>
        <FormattedMessage id='candidatureTabs.accordion.otherCharge' />
      </h4>
      <small className='text-secondary text-uppercase'>
        {intl.formatMessage(
          { id: 'all.step' },
          { step: values.step, numberOfSteps: numberOfSteps }
        )}
      </small>

      <p className='mt-3 mb-0 text-secondary infoText'>
        <FaInfoCircle size={50} />
        <FormattedMessage id={'step15.infoText'}/>
      </p>

      <div className=''>
        <Table className='w-100 mb-5 mt-4 '>
          <thead>
            <tr>
              <th rowSpan='2' className='p-lg-1 text-center'>
                <FormattedMessage id='otherCharge.year' />
              </th>
              <th className='text-center'>
                <FormattedMessage id='otherCharge.refundComplete' />
              </th>
              <th className='text-center'>
                <FormattedMessage id='otherCharge.financialChargesComplete' />
              </th>
              <th className='text-center'>
                <FormattedMessage id='otherCharge.totalComplete' />
                <ToolTip message={<FormattedMessage id={'step15.tooltip.total'}/>}/>
              </th>
              <th className='text-center'>
                <FormattedMessage id='otherCharge.debtCapitalInitialComplete' />
              </th>
              <th className='text-center'>
                <FormattedMessage id='otherCharge.debtCapitalFinalComplete' />
              </th>
            </tr>
          </thead>
          <tbody>
            {values.otherChargeList[0].otherChargeLineList.map((line, index) => (
              <React.Fragment key={index}>
                {!CandidatureFormUtils.is3YearsCoverageOnly(values, line.year) && (
                  <tr className='text-center'>
                    <td> {CandidatureFormUtils.getYearLabel(values, line.year)}</td>
                    <td>
                      <DecimalInputField
                        controlId={`otherChargeLine.${line.year}.refund`}
                        name={`otherChargeLine.${line.year}.refund`}
                        handleChange={(e) => handleField(e, line.year, 'refund')}
                        value={line.refund}
                        suffixLabel={'€'}
                        onlyPositive={true}
                        isInvalid={
                          errors.otherChargeList?.[0]?.otherChargeLineList?.[index]
                              ?.refund
                        }
                        errorMessage={
                          errors.otherChargeList?.[0]?.otherChargeLineList?.[index]
                              ?.refund
                              ? errors.otherChargeList?.[0]?.otherChargeLineList?.[index]
                                  ?.refund
                              : ''
                        }
                        showErrorOnToolTip={true}
                      />
                    </td>
                    <td>
                      <DecimalInputField
                        controlId={`otherChargeLine.${line.year}.financialCharges`}
                        name={`otherChargeLine.${line.year}.financialCharges`}
                        handleChange={(e) => handleField(e, line.year, 'financialCharges')}
                        value={line.financialCharges}
                        suffixLabel={'€'}
                        onlyPositive={true}
                        showErrorOnToolTip={true}
                        isInvalid={
                          errors.otherChargeList?.[0]?.otherChargeLineList?.[index]
                              ?.financialCharges
                        }
                        errorMessage={
                          errors.otherChargeList?.[0]?.otherChargeLineList?.[index]
                              ?.financialCharges
                              ? errors.otherChargeList?.[0]?.otherChargeLineList?.[index]
                                  ?.financialCharges
                              : ''
                        }
                      />
                    </td>
                    <td>
                      {currencyFormat(maxFixed(parseFloat(line.financialCharges) + parseFloat(line.refund)))}
                    </td>
                    <td>
                      <DecimalInputField
                        controlId={`otherChargeLine.${line.year}.debtCapitalInitial`}
                        name={`otherChargeLine.${line.year}.debtCapitalInitial`}
                        handleChange={(e) => handleField(e, line.year, 'debtCapitalInitial')}
                        value={line.debtCapitalInitial}
                        suffixLabel={'€'}
                        onlyPositive={true}
                        isInvalid={
                          errors.otherChargeList?.[0]?.otherChargeLineList?.[index]
                              ?.debtCapitalInitial
                        }
                        errorMessage={
                          errors.otherChargeList?.[0]?.otherChargeLineList?.[index]
                              ?.debtCapitalInitial
                              ? errors.otherChargeList?.[0]?.otherChargeLineList?.[index]
                                  ?.debtCapitalInitial
                              : ''
                        }
                        showErrorOnToolTip={true}
                      />
                    </td>
                    <td>
                      <DecimalInputField
                        controlId={`otherChargeLine.${line.year}.debtCapitalFinal`}
                        name={`otherChargeLine.${line.year}.debtCapitalFinal`}
                        handleChange={(e) => handleField(e, line.year, 'debtCapitalFinal')}
                        value={line.debtCapitalFinal}
                        suffixLabel={'€'}
                        onlyPositive={true}
                        showErrorOnToolTip={true}
                        isInvalid={
                          errors.otherChargeList?.[0]?.otherChargeLineList?.[index]
                            ?.debtCapitalFinal
                        }
                        errorMessage={
                          errors.otherChargeList?.[0]?.otherChargeLineList?.[index]
                            ?.debtCapitalFinal
                            ? errors.otherChargeList?.[0]?.otherChargeLineList?.[index]
                                ?.debtCapitalFinal
                            : ''
                        }
                        showErrorOnToolTip={true}
                      />
                    </td>
                  </tr>
                )}
              </React.Fragment>
            ))}

            <tr className='text-center '>
              <td>
                <strong>
                  <FormattedMessage id='otherCharge.total' />
                </strong>
              </td>
              <td>{currencyFormat(maxFixed(totals.refund))} </td>
              <td>{currencyFormat(maxFixed(totals.financialCharges))} </td>
              <td>{currencyFormat(maxFixed(totals.financialCharges + totals.refund))}</td>

            </tr>
          </tbody>
        </Table>

        <Row>
          <Col md='12'>
            <TextAreaInputField
              labelId={'otherCharge.reason'}
              name={'candidatureForm.otherCharge.reasonToOtherCharge'}
              value={values.otherChargeList[0].reasonToOtherCharge}
              handleChange={(e) => handleReason(e)}
              placeholder={intl.formatMessage({ id: 'all.maxLength.placeholder' })}
              maxLength={10000}
              toolTip={<ToolTip message={<FormattedMessage id={'reasonOtherCharges.tooltip'}/>} />}
              isInvalid={
                errors.otherChargeList ? errors.otherChargeList[0]?.reasonToOtherCharge : false
              }
              errorMessage={
                errors.otherChargeList ? errors.otherChargeList[0]?.reasonToOtherCharge : ''
              }
            />
          </Col>
        </Row>

        <div className='form-actions mt-5'>
          <button
            className='btn btn-link'
            type='button'
            onClick={() => handleGoBack(values, setFieldValue)}>
            <FormattedMessage id='all.back' />
          </button>

          <button className='btn btn-primary' type='button' onClick={() => handleNextStep()}>
            <FormattedMessage id='all.nextStep' />
          </button>
        </div>
      </div>
    </div>
  );
}
