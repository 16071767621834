import React from "react";
import {useNavigate} from "react-router-dom";
import {FormattedMessage} from "react-intl";
import {currencyFormat} from "../../../../utils/CurrencyUtils";

export function NonComplianceEntry({nonCompliance, nonComplianceCauseToRegulationMap}) {
    const navigate = useNavigate();

    return (
        <tr style={{cursor: 'pointer'}} onClick={() => navigate('incumprimento/' + nonCompliance.externalId)}>
            <td>{nonCompliance.date}</td>
            <td><FormattedMessage id={'candidatureTabs.oversight.tabs.noncompliance.form.revVal.' + nonCompliance.revocation}/></td>
            <td><FormattedMessage id={'candidatureTabs.oversight.tabs.noncompliance.form.restVal.' + nonCompliance.restitution}/></td>
            <td>{'restitutionValue' in nonCompliance ? currencyFormat(nonCompliance.restitutionValue.toFixed(2)) : '' }</td>
            <td>
                <ul className="list-inline">
                    {nonCompliance.causes.map(c =>
                        <li key={nonCompliance.externalId + c} className="list-inline-item">
                            <small>{nonComplianceCauseToRegulationMap[c]}</small>
                        </li>
                    )}
                </ul>
            </td>
            <td><FormattedMessage id={'candidatureTabs.oversight.tabs.noncompliance.state.' + nonCompliance.state}/></td>
        </tr>
    );
}