import {Modal} from "react-bootstrap";
import {useState} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {NonComplianceForm} from "../oversight/tabs/noncompliance/NonComplianceForm";
import {PayableProcessRecordForm} from "./PayableProcessRecordForm";


export function AddPayableProcessPaymentRecordModal({show, setShow, onSubmitCallback, overview}) {
    const intl = useIntl();
    const [error, setError] = useState(null);
    const [entity, setEntity] = useState(null);

    async function onSubmit() {
        try {
            setShow(false);
            onSubmitCallback?.()
        } catch (error) {
            setError(error);
        }
    }

    function handleClose() {
        setShow(false);
    }

    return (
        <Modal show={show} onHide={handleClose} dialogClassName='modal-6'>
            <Modal.Header closeButton>
                <Modal.Title><FormattedMessage id='all.registerPayment' /></Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <PayableProcessRecordForm
                    overview={overview}
                    onSubmitCallback={onSubmit}
                    onCancel={handleClose}
                />
            </Modal.Body>
        </Modal>

    );
}
