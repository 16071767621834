import { Formik } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import { Col, Container, Form, Row } from 'react-bootstrap';
import { FaRegCheckCircle, FaRegSave } from 'react-icons/fa';
import { FormattedMessage, useIntl } from 'react-intl';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import * as yup from 'yup';
import { AlertError } from '../../components/bootstrap/AlertError';
import { ConfirmationAuthenticationModal } from '../../components/general/ConfirmationAuthenticationModal';
import { FormSideNavBar } from '../../components/general/FormSideNavBar';
import Loading from '../../components/general/Loading';
import { SubTemplate } from '../../components/general/SubTemplate';
import { SuccessToast } from '../../components/general/SuccessToast';
import { ListContractsStep1 } from '../../components/listContracts/ListContractsStep1';
import { ListContractsStep2 } from '../../components/listContracts/ListContractsStep2';
import { createCustomErrorMessage } from '../../hooks/errorMessage';
import { ProcessState } from '../../models/ProcessState';
import {
	getContractsListingByProcessExternalId,
	getUserContractsListing,
	saveContractsListing,
	withdrawContractListing,
} from '../../rest/contractsListing';
import { getEntityGetList, getEntityTgpList } from '../../rest/entity';
import { B4FormCustomValidator } from '../../utils/B4FormCustomValidator';
import { Validator } from '../../utils/Validator';
import { handleError, isNotBusinessError } from '../../utils/handleError';
import {
	getCountiesByDistrictCode,
	getDistricts,
	getParishesByCountyCode,
} from '../../rest/administrativeDivision';
import { isIEFPUser } from '../../authentication/authenticationHelper';

const validationSchema = yup.object().shape({
	contractIdentificationList: yup.array().of(
		yup.object().shape({
			contractNumber: yup
				.string()
				.required(<FormattedMessage id='errors.fieldRequiredText' />),

			contractModality: yup
				.string()
				.test(
					'isModalityValid',
					<FormattedMessage id='errors.contractListing.modality' />,
					(value) => value !== '-1'
				),

			projectCompanyName: yup
				.string()
				.required(<FormattedMessage id='errors.fieldRequiredText' />),

			projectCompanyNIF: yup
				.string()
				.required(<FormattedMessage id='errors.fieldRequiredText' />)
				.test(
					'isNifValid',
					<FormattedMessage id='errors.contractListing.nifOrNipc' />,
					(value) => Validator.validateNif(value)
				),

			projectManagerExternalId: yup
				.string()
				.test(
					'isProjectManagerExternalIdValid',
					<FormattedMessage id='errors.contractListing.projectManagerExternalId' />,
					(value) => value !== '-1'
				),

			anProjects: yup
				.number()
				.required(<FormattedMessage id='errors.fieldRequiredText' />)
				.test(
					'isNumberValid',
					<FormattedMessage id='errors.eaAccreditationForm.number' />,
					(value) => value >= 0
				),

			currentProjects: yup
				.number()
				.required(<FormattedMessage id='errors.fieldRequiredText' />)
				.test(
					'isNumberValid',
					<FormattedMessage id='errors.eaAccreditationForm.number' />,
					(value) => value >= 0
				),

			totalProjects: yup
				.number()
				.required(<FormattedMessage id='errors.fieldRequiredText' />)
				.test(
					'isNumberValid',
					<FormattedMessage id='errors.eaAccreditationForm.number' />,
					(value) => value >= 0
				),

			companyFiscalActivityStartDate: yup.date().nullable(),



			effectiveContractEndDate: yup
				.mixed().when('contractModality', {
					is: (val) => val === 'POST_PROJECT_APPROVAL',
					then: yup.date().transform((v) => (v instanceof Date && !isNaN(v) ? v : undefined)).required(<FormattedMessage id='errors.fieldRequiredText'/>).nullable()
				}),

			effectiveContractStartDate: yup
				.date()
				.nullable()
				.transform((v) => (v instanceof Date && !isNaN(v) ? v : undefined))
				.required(<FormattedMessage id='errors.fieldRequiredText'/>).when('contractModality', {
					is: (val) => val === 'POST_PROJECT_APPROVAL',
					then: yup.date().transform((v) => (v instanceof Date && !isNaN(v) ? v : undefined))
						.test(
							'isNotAfterEffectiveContractStartDate',
							<FormattedMessage
								id='errors.contractListing.effectiveContractStartDateCanNotBeAfterEndDate'/>,
							(value, context) =>
								(context.parent.effectiveContractEndDate &&
									(value < context.parent.effectiveContractEndDate))
						)
				}).nullable(),

			unemploymentAnticipation: yup
				.string()
				.test(
					'isArtsAndCraftsValid',
					<FormattedMessage id='errors.contractListing.dropDownSelection' />,
					(value) => value !== '-1'
				),

			interestFreeLoan: yup
				.number().
				when('contractModality', {
					is: (val) => val === 'POST_PROJECT_APPROVAL',
					then: yup.number().test(
					'isNumberValid',
					<FormattedMessage id='errors.eaAccreditationForm.number' />,
					(value) => value >= 0
				)}),

			nonRefundableSubsidy: yup
				.number().
				when('contractModality', {
					is: (val) => val === 'POST_PROJECT_APPROVAL',
					then: yup.number().test(
					'isNumberValid',
					<FormattedMessage id='errors.eaAccreditationForm.number' />,
					(value) => value >= 0
					)}),

			workStations: yup
				.number().
				when('contractModality', {
					is: (val) => val === 'POST_PROJECT_APPROVAL',
					then: yup.number().test(
					'isNumberValid',
					<FormattedMessage id='errors.eaAccreditationForm.number' />,
					(value) => value >= 0
				)}),

			artsAndCrafts: yup
				.string().
				when('contractModality', {
					is: (val) => val === 'POST_PROJECT_APPROVAL',
					then: yup.string().test(
					'isArtsAndCraftsValid',
					<FormattedMessage id='errors.contractListing.dropDownSelection' />,
					(value) => value !== '-1'
				)}),
		})
	),
	identificationEa: yup.object().shape({
		technicalTeamManagerExternalId: yup
			.string()
			.test(
				'isGETValid',
				<FormattedMessage id='errors.annualComplementaryActivitiesPlan.GET' />,
				(value) => value !== '-1'
			),
	}),

	technicalTeamManagerPosition: yup
		.string()
		.required(<FormattedMessage id='errors.fieldRequiredText' />),
});

export function ListContracts() {
	//for entity users. Also, if it got here without the externalId, then its value is undefined
	const { externalId: externalId } = useParams();
	const isIEFP = isIEFPUser();

	//errors
	const [error, setError] = useState(null);
	const [navErrors, setNavErrors] = useState([]);

	//modals
	const [showWithdrawModal, setShowWithdrawModal] = useState(false);
	const [showSubmitModal, setShowSubmitModal] = useState(false);

	//formik
	const [formikInitialValues, setFormikInitialValues] = useState(null);
	const formikPropsRef = useRef(null);

	//form control
	const [formStep, setFormStep] = useState(0);
	const [showSaveToast, setShowSaveToast] = useState(false);
	const [loading, setLoading] = useState(true);
	const [submited, setSubmited] = useState(false);

	const [getList, setGetList] = useState([]);
	const [tgpList, setTgpList] = useState([]);

	const [districtList, setDistrictList] = useState([]);
	const [loadedDistrictCounties, setLoadedDistrictCounties] = useState({});
	const [loadedCountyParishes, setLoadedCountyParishes] = useState({});
	const [locationCurrentChosenOptions, setLocationCurrentChosenOptions] =
		useState({});

	async function setDistrictsAndCountiesFromParishCodes(contractsListing) {
		try {
			let districtCode;
			let countyCode;

			let newLoadedDistrictCounties = {};
			let newLoadedCountyParishes = {};
			let newLocationCurrentChosenOptions = {};

			for (let index in contractsListing.contractIdentificationList) {
				let contract = contractsListing.contractIdentificationList[index];

				if (contract.parishCode !== '-1') {
					districtCode = contract.parishCode.substring(0, 2);
					const { data: countyList } = await getCountiesByDistrictCode(
						districtCode
					);
					newLoadedDistrictCounties[districtCode] = countyList;

					countyCode = contract.parishCode.substring(0, 4);
					const { data: parishList } = await getParishesByCountyCode(
						countyCode
					);
					newLoadedCountyParishes[countyCode] = parishList;

					newLocationCurrentChosenOptions[index] = {
						districtCode: districtCode,
						countyCode: countyCode,
					};
				} else {
					newLocationCurrentChosenOptions[index] = {
						districtCode: '-1',
						countyCode: '-1',
					};
				}
			}

			setLocationCurrentChosenOptions(newLocationCurrentChosenOptions);
			setLoadedDistrictCounties(newLoadedDistrictCounties);
			setLoadedCountyParishes(newLoadedCountyParishes);
		} catch (error) {
			setError(error);
		} finally {
			setLoading(false);
		}
	}

	const intl = useIntl();
	const navigate = useNavigate();

	const tabsIds = [
		'listContracts.sideMenu.identificationEA',
		'listContracts.sideMenu.contractIdentification',
	];

	async function fetchData() {
		try {
			let { data: contractsListing } = isIEFP
				? await getContractsListingByProcessExternalId(externalId)
				: await getUserContractsListing(externalId);

			let entityHumanResourceParamsForNonEntityUsers = {
				entityNif: contractsListing.identificationEa.entity.nif,
				all:contractsListing.processState !== ProcessState.DRAFT
			};

			let [
				{ data: districts },
				{ data: entityGetList },
				{ data: entityTgpList },
			] = await Promise.all([
				await getDistricts(true),
				isIEFP
					? await getEntityGetList(entityHumanResourceParamsForNonEntityUsers)
					: await getEntityGetList({all:contractsListing.processState !== ProcessState.DRAFT}),
				isIEFP
					? await getEntityTgpList(entityHumanResourceParamsForNonEntityUsers)
					: await getEntityTgpList({all:contractsListing.processState !== ProcessState.DRAFT}),
			]);

			setDistrictList(districts);
			setGetList(entityGetList);
			setTgpList(entityTgpList);
			setFormikInitialValues(getFormikInitialValues(contractsListing));

			setDistrictsAndCountiesFromParishCodes(contractsListing);
		} catch (error) {
			setError(error);
		} finally {
			setLoading(false);
		}
	}

	function getFormikInitialValues(contractsListing) {
		let initialValues = contractsListing;

		//set values that may be undefined and need to be set
		initialValues.technicalTeamManagerPosition =
			contractsListing.technicalTeamManagerPosition
				? contractsListing.technicalTeamManagerPosition
				: '';
		initialValues.contractIdentificationList =
			contractsListing.contractIdentificationList
				? contractsListing.contractIdentificationList
				: [];

		let contractIdentificationKeysThatMayBeUndefinedAndNeedToBeSet = [
			'contractNumber',
			'projectCompanyName',
			'projectCompanyNIF',
			'anProjects',
			'currentProjects',
			'totalProjects',
			'interestFreeLoan',
			'nonRefundableSubsidy',
			'workStations',
		];
		initialValues.contractIdentificationList.forEach((contract) => {
			contractIdentificationKeysThatMayBeUndefinedAndNeedToBeSet.forEach(
				(key) => {
					if (contract[key] === undefined) {
						contract[key] = '';
					}
				}
			);

			//dates may also be undefined, but we need to set its initial value as null
			contract.companyFiscalActivityStartDate =
				contract.companyFiscalActivityStartDate === undefined
					? null
					: contract.companyFiscalActivityStartDate;
			contract.effectiveContractStartDate =
				contract.effectiveContractStartDate === undefined
					? null
					: contract.effectiveContractStartDate;
			contract.effectiveContractEndDate =
				contract.effectiveContractEndDate === undefined
					? null
					: contract.effectiveContractEndDate;

			if (contract.unemploymentAnticipation === undefined) {
				contract.unemploymentAnticipation = '-1';
			}

			if (contract.artsAndCrafts === undefined) {
				contract.artsAndCrafts = '-1';
			}
		});

		return initialValues;
	}

	useEffect(() => {
		fetchData();
	}, []);

	if (submited) {
		sessionStorage.setItem('contractListingSubmited', submited);
		return <Navigate to={'/listagemContratos/lista'} />;
	}

	function scrollPageToTop() {
		document.body.scrollTop = 0;
		document.documentElement.scrollTop = 0;
	}

	function handlePreviousStep() {
		scrollPageToTop();
		setFormStep(formStep - 1);
	}

	function handleNextStep() {
		scrollPageToTop();
		setFormStep(formStep + 1);
	}

	if (loading) {
		return <Loading />;
	}

	if (error && isNotBusinessError(error)) {
		return handleError(error);
	}

	async function handleWithdrawal(user, password, setModalError) {
		try {
			let authenticationCredentials = {
				user: user,
				password: password,
			};

			await withdrawContractListing(
				formikPropsRef.current.values.externalId,
				authenticationCredentials
			);

			sessionStorage.setItem('contractListingWithdrawed', true);
			navigate('/listagemContratos/lista');
		} catch (error) {
			setModalError(error);
		}
	}

	function getProcessedFormikValues() {
		let formikValues = formikPropsRef.current.values;

		//converts boolean values from selects
		formikValues.contractIdentificationList.forEach((contract) => {
			if (contract.unemploymentAnticipation === '-1') {
				contract.unemploymentAnticipation = null;
			}

			if (contract.artsAndCrafts === '-1') {
				contract.artsAndCrafts = null;
			}
		});

		return formikValues;
	}

	async function handleSave() {
		try {
			let { data: res } = await saveContractsListing(
				getProcessedFormikValues()
			);

			let processedRes = getFormikInitialValues(res);

			formikPropsRef.current.setValues(processedRes);
			setNavErrors([]);
			formikPropsRef.current.setErrors({});

			setShowSaveToast(true);
		} catch (error) {
			setError(error);
		}
	}

	async function formValidationHandler(setShowModal) {
		let errors = await validateForm();
		setNavErrors(Array.from(errors));
		if (errors.size === 0) {
			setShowModal(true);
		}
	}

	async function validateForm() {
		let errors = new Set();

		const formikErrors = await formikPropsRef.current.validateForm();

		Object.keys(formikErrors).forEach((key) => {
			switch (key) {
				case 'identificationEa':
					errors.add(0);
					break;
				case 'technicalTeamManagerPosition':
					errors.add(0);
					break;
				default:
					errors.add(1);
					break;
			}
		});

		let errorMessages = [];

		B4FormCustomValidator.validateContractsLengthAndNoNifRepetition(
			formikPropsRef.current.values,
			intl,
			errorMessages
		).forEach((error) => errors.add(error));

		if (errorMessages.length > 0) {
			let message =
				errorMessages.length === 1
					? createCustomErrorMessage(errorMessages[0])
					: createCustomErrorMessage(
							'<p>' +
								intl.formatMessage({
									id: 'errors.more',
								}) +
								'</p><p> - ' +
								errorMessages.join('</p><p> - ') +
								'</p>'
					  );

			setError(message);
		}

		return errors;
	}

	async function handleSubmit(user, password, setModalError) {
		let values = getProcessedFormikValues();
		const submissionValues = { ...values };

		submissionValues.credentials = {
			user: user,
			password: password,
		};

		let queryParams = {
			submit: true,
		};

		try {
			await saveContractsListing(submissionValues, queryParams);
			setSubmited(true);
		} catch (err) {
			if (err.response.data.exception === 'ValidationException') {
				handleNavError(err);
			} else {
				setModalError(err);
			}
		}
	}

	function handleNavError(error) {
		let errors = error.response.data.message
			.split(',')
			.map((value) => Number.parseInt(value));
		setNavErrors(errors);
	}

	function renderStep(formikProps) {
		const readMode = formikProps.values.processState !== ProcessState.DRAFT || isIEFP;
		formikPropsRef.current = formikProps;

		switch (formStep) {
			case 0:
				return (
					<ListContractsStep1
						formStep={1}
						numberOfSteps={tabsIds.length}
						errors={formikProps.errors}
						formikValues={formikProps.values}
						handleChange={formikProps.handleChange}
						handleNextStep={handleNextStep}
						entityGetList={getList}
						readMode={readMode}
						goBackPath={'/listagemContratos/lista'}
						setFieldValue={formikProps.setFieldValue}
					/>
				);
			case 1:
				return (
					<ListContractsStep2
						formStep={2}
						numberOfSteps={tabsIds.length}
						errors={formikProps.errors}
						formikValues={formikProps.values}
						handleChange={formikProps.handleChange}
						handlePreviousStep={handlePreviousStep}
						setFieldValue={formikProps.setFieldValue}
						entityTGPList={tgpList}
						readMode={readMode}
						districts={districtList}
						districtToCountiesMap={loadedDistrictCounties}
						countyToParishesMap={loadedCountyParishes}
						setDistrictToCountyMap={setLoadedDistrictCounties}
						setCountyToParishMap={setLoadedCountyParishes}
						currentChosenLocations={locationCurrentChosenOptions}
						setCurrentChosenLocations={setLocationCurrentChosenOptions}
						setError={setError}
					/>
				);
			default:
				console.log('Something went wrong rendering the form step');
		}
	}
	return (
		<SubTemplate>
			<Container>
				<ConfirmationAuthenticationModal
					show={showWithdrawModal || showSubmitModal}
					handleClose={() =>
						showWithdrawModal
							? setShowWithdrawModal(false)
							: setShowSubmitModal(false)
					}
					submitHandler={showWithdrawModal ? handleWithdrawal : handleSubmit}
					idsPreffix={
						showWithdrawModal
							? 'contractListing.withdrawModal'
							: 'contractListing.submitModal'
					}
					confirmButtonCompleteLabelId={
						showWithdrawModal
							? 'all.candidatureWithdrawal'
							: 'all.submitButtonText'
					}
				/>
				<Row>
					<Col md={{ span: 8, offset: 2 }}>
						<h2 className='mb-1 text-secondary'>
							<FormattedMessage id='listContracts.title' />
						</h2>
						<p className='mb-5 text-secondary'>
							<FormattedMessage id='listContracts.subTitle' />
							<span
								className="badge rounded-pill bg-secondary fw-normal ms-2"><span
								className="text-uppercase fw-bold"><FormattedMessage
								id={`processState.${formikInitialValues.processState}`}
							/> </span>  {formikInitialValues.submittedDate !== undefined && (<> · <FormattedMessage id={'submitted.process.at'} values={{date:formikInitialValues.submittedDate}}/>  </>) } </span>
						</p>
					</Col>
				</Row>

				<Row className='mb-5'>
					<Col md='2'>
						<FormSideNavBar
							tabsIdsArray={tabsIds}
							formStep={formStep}
							setFormStep={setFormStep}
							navErrors={navErrors}
							setNavErrors={setNavErrors}
						/>
					</Col>
					<Col md='8'>
						<AlertError error={error} />
						<SuccessToast
							message={intl.formatMessage({
								id: 'annualComplementaryActivitiesPlan.saveToastInformation',
							})}
							show={showSaveToast}
							setShow={setShowSaveToast}
						/>
						<Formik
							initialValues={formikInitialValues}
							validateOnBlur={false}
							validateOnChange={false}
							validationSchema={validationSchema}
							onSubmit={handleSubmit}
						>
							{(formikProps) => (
								<Form onSubmit={formikProps.handleSubmit}>
									{renderStep(formikProps)}
								</Form>
							)}
						</Formik>
					</Col>

						<Col md='2'>
							<div>
								{formikInitialValues.save===true && formikInitialValues.processState === ProcessState.DRAFT && !isIEFP && (
									<>
								<button
									className='btn btn-outline-primary d-flex align-items-center justify-content-center mb-4 w-100'
									type='button'
									onClick={() => setShowWithdrawModal(true)}
								>
									<FormattedMessage id='all.candidatureWithdrawal' />
								</button>
								<button
									className='btn btn-outline-primary d-flex align-items-center justify-content-center mb-4 w-100'
									type='button'
									onClick={() => handleSave()}
								>
									<FaRegSave />
									<FormattedMessage id='all.save' />
								</button>
								</>
								)}
								{formikInitialValues.canSubmit===true && formikInitialValues.processState === ProcessState.DRAFT && !isIEFP && (
								<button
									className='btn btn-primary d-flex align-items-center justify-content-center w-100'
									type='button'
									onClick={() => formValidationHandler(setShowSubmitModal)}
								>
									<FaRegCheckCircle />
									<FormattedMessage id='all.submitButtonText' />
								</button>
								)}
							</div>
						</Col>

				</Row>
				<Row className='py-4'>
					<Col></Col>
				</Row>
			</Container>
		</SubTemplate>
	);
}
