import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { FaSearch } from 'react-icons/fa';
import { FormattedMessage, useIntl } from 'react-intl';

import { BoundType } from '../../models/BoundType';
import { MessageState } from '../../models/MessageState';
import { MessageType } from '../../models/MessageType';
import {
	TranslateMessageBoundTypeFilter,
	TranslateMessageStateFilter,
	TranslateMessageTypeFilter,
} from '../../utils/TranslateStates';

export function MessageFilterBar({ defaultBoundTypeFilter, onSubmit }) {
	const intl = useIntl();

	const [keywordFilter, setKeywordFilter] = useState('');
	const [boundTypeFilter, setBoundTypeFilter] = useState(
		defaultBoundTypeFilter
	);
	const [typeFilter, setTypeFilter] = useState(MessageType.INTERNAL);
	const [stateFilter, setStateFilter] = useState('-1');

	const handleOnClick = (e) => {
		e.preventDefault();
		let typeF = typeFilter === '-1' ? null : typeFilter;
		let boundTypeF = boundTypeFilter === '-1' ? null : boundTypeFilter;
		let stateF = stateFilter === '-1' ? null : stateFilter;

		let filters = {
			keyword: keywordFilter,
			type: typeF,
			boundType: boundTypeF,
			state: stateF,
			parentExternalId: null,
		};

		onSubmit(filters, 0);
	};

	return (
		<Row className='searchFilters'>
			<Col md='4'>
				<input
					type='text'
					className={'form-control  rounded-0'}
					onChange={(e) => setKeywordFilter(e.target.value)}
					placeholder={intl.formatMessage({
						id: 'messages.keyword',
					})}
				/>
			</Col>

			<Col md='2'>
				<select
					className={'form-control  rounded-0'}
					onChange={(e) => setBoundTypeFilter(e.target.value)}
					value={boundTypeFilter}
				>
					<option value={'-1'}>
						{intl.formatMessage({
							id: 'messages.allToText',
						})}
					</option>
					{BoundType.values().map((boundType) => {
						return (
							<option key={boundType} value={boundType}>
								<TranslateMessageBoundTypeFilter bound={boundType}/>
							</option>
						);
					})}
				</select>
			</Col>

			<Col md='2'>
				<select
					className={'form-control rounded-0'}
					onChange={(e) => setTypeFilter(e.target.value)}
					value={typeFilter}
				>
					<option value={'-1'}>
						{intl.formatMessage({
							id: 'messages.allTypeText',
						})}
					</option>
					{MessageType.values().map((type) => {
						return (
							<option key={type} value={type}>
								{TranslateMessageTypeFilter(type)}
							</option>
						);
					})}
				</select>
			</Col>

			<Col md='2'>
				<select
					className={'form-control rounded-0'}
					onChange={(e) => setStateFilter(e.target.value)}
					value={stateFilter}
				>
					<option value={'-1'}>
						{intl.formatMessage({
							id: 'messages.allStateText',
						})}
					</option>
					{MessageState.values().map((state) => {
						return (
							<option key={state} value={state}>
								{TranslateMessageStateFilter(state)}
							</option>
						);
					})}
				</select>
			</Col>

			<Col md='2'>
				<button
					className={'btn btn-outline-primary'}
					onClick={handleOnClick}
					type='submit'
				>
					<FaSearch className={'mx-0'} /> <FormattedMessage id='all.search' />
				</button>
			</Col>
		</Row>
	);
}
