import { apiAxios as authAxios } from '../axios.config';

export const listDevelopmentPlans = (queryParams) => {
	return authAxios.get(`api/developmentPlan/list`, { params: queryParams });
};

export const saveDevelopmentPlan = (data, queryParams) => {
	return authAxios.post('api/developmentPlan', data, { params: queryParams });
};

export const getDevelopmentPlanByExternalId = (b9ExternalId) => {
	return authAxios.get(`/api/developmentPlan/${b9ExternalId}`);
};


export const submitDevelopmentPlanDecision = (b9ExternalId, data) => {
	return authAxios.post(`api/developmentPlan/${b9ExternalId}/submitDecision/`, data);
};

export const developmentPlanStatus = () => {
	return authAxios.get(`api/developmentPlan/formStatus`);
};