export const EconomicViabilityUtils = {
  calculateInvestmentPlanDetailTotals: (investmentPlanDetail) =>
    calculateInvestmentPlanDetailTotals(investmentPlanDetail),
  calculateFinancialInvestmentDetailTotals: (financialInvestmentDetail) =>
    calculateFinancialInvestmentDetailTotals(financialInvestmentDetail),
  calculateInvestmentPlanDetailTotalFields: (currentYearDetail, nextYearDetail, totalDetail) =>
    calculateInvestmentPlanDetailTotalFields(currentYearDetail, nextYearDetail, totalDetail),
  calculateFinancialInvestmentDetailTotalFields: (currentYearDetail, nextYearDetail, totalDetail) =>
    calculateFinancialInvestmentDetailTotalFields(currentYearDetail, nextYearDetail, totalDetail)
};

function calculateInvestmentPlanDetailTotals(investmentPlanDetail) {
  const investmentProperties = (
      parseFloat(investmentPlanDetail.investmentPropertiesNaturalResources) +
          parseFloat(investmentPlanDetail.investmentPropertiesBuildings) +
              parseFloat(investmentPlanDetail.investmentPropertiesOthers)
  ).toFixed(2);

  investmentPlanDetail.investmentProperties = parseFloat(investmentProperties);

  const tangibleAssets = (
    parseFloat(investmentPlanDetail.tangibleNaturalResources) +
        parseFloat(investmentPlanDetail.tangibleBuildings) +
            parseFloat(investmentPlanDetail.tangibleBasicEquipment) +
                parseFloat(investmentPlanDetail.tangibleTransportEquipment) +
                    parseFloat(investmentPlanDetail.tangibleAdministrativeEquipment) +
                        parseFloat(investmentPlanDetail.tangibleBiologicEquipment) +
                            parseFloat(investmentPlanDetail.tangibleOthers)
  ).toFixed(2);

  investmentPlanDetail.tangibleAssets = parseFloat(tangibleAssets);

  const intangibleAssets = (
    parseFloat(investmentPlanDetail.intangibleGoodwill) +
        parseFloat(investmentPlanDetail.intangibleDevelopmentProjects) +
            parseFloat(investmentPlanDetail.intangibleComputerPrograms) +
                parseFloat(investmentPlanDetail.intangibleIntellectualProperty) +
                    parseFloat(investmentPlanDetail.intangibleOthers)
  ).toFixed(2);

  investmentPlanDetail.intangibleAssets = parseFloat(intangibleAssets);

  const globalTotal = (
    investmentPlanDetail.investmentProperties +
    investmentPlanDetail.tangibleAssets +
    investmentPlanDetail.intangibleAssets +
      parseFloat(investmentPlanDetail.several) +
      parseFloat(investmentPlanDetail.pettyCash)
  ).toFixed(2);

  investmentPlanDetail.globalTotal = parseFloat(globalTotal);
}

function calculateFinancialInvestmentDetailTotals(financialInvestmentDetail) {
  const ownCapital = (
      parseFloat(financialInvestmentDetail.ownSocialCapital) +
          parseFloat(financialInvestmentDetail.ownCapitalUnemploymentBenefits) +
              parseFloat(financialInvestmentDetail.ownCapitalOthers)
  ).toFixed(2);

  financialInvestmentDetail.ownCapital = parseFloat(ownCapital);

  const iefpAskedAmount = (
      parseFloat(financialInvestmentDetail.investmentSupport) +
          parseFloat(financialInvestmentDetail.workStationsCreationBenefits)
  ).toFixed(2);

  financialInvestmentDetail.iefpAskedAmount = parseFloat(iefpAskedAmount);

  const foreignCapital = (
      parseFloat(financialInvestmentDetail.foreignCapitalBankLoans) +
          parseFloat(financialInvestmentDetail.foreignCapitalPartnerLoans) +
              parseFloat(financialInvestmentDetail.foreignCapitalProvidersLoans) +
                  parseFloat(financialInvestmentDetail.foreignCapitalOthers) +
      financialInvestmentDetail.iefpAskedAmount
  ).toFixed(2);

  financialInvestmentDetail.foreignCapital = parseFloat(foreignCapital);

  const globalTotal =  (
      financialInvestmentDetail.ownCapital +
          financialInvestmentDetail.foreignCapital
  ).toFixed(2);

  financialInvestmentDetail.globalTotal = parseFloat(globalTotal);
}

function calculateInvestmentPlanDetailTotalFields(currentYearDetail, nextYearDetail, totalDetail) {
  totalDetail.investmentPropertiesNaturalResources =
      parseFloat(currentYearDetail.investmentPropertiesNaturalResources) +
      parseFloat(nextYearDetail.investmentPropertiesNaturalResources);

  totalDetail.investmentPropertiesBuildings =
      parseFloat(currentYearDetail.investmentPropertiesBuildings) + parseFloat(nextYearDetail.investmentPropertiesBuildings);

  totalDetail.investmentPropertiesOthers =
      parseFloat(currentYearDetail.investmentPropertiesOthers) + parseFloat(nextYearDetail.investmentPropertiesOthers);

  const investmentProperties = (
      parseFloat(totalDetail.investmentPropertiesNaturalResources) +
          parseFloat(totalDetail.investmentPropertiesBuildings) +
              parseFloat(totalDetail.investmentPropertiesOthers)
  ).toFixed(2);

  totalDetail.investmentProperties = parseFloat(investmentProperties);

  totalDetail.tangibleNaturalResources =
      parseFloat(currentYearDetail.tangibleNaturalResources) + parseFloat(nextYearDetail.tangibleNaturalResources);

  totalDetail.tangibleBuildings =
      parseFloat(currentYearDetail.tangibleBuildings) + parseFloat(nextYearDetail.tangibleBuildings);

  totalDetail.tangibleBasicEquipment =
      parseFloat(currentYearDetail.tangibleBasicEquipment) + parseFloat(nextYearDetail.tangibleBasicEquipment);

  totalDetail.tangibleTransportEquipment =
      parseFloat(currentYearDetail.tangibleTransportEquipment) + parseFloat(nextYearDetail.tangibleTransportEquipment);

  totalDetail.tangibleAdministrativeEquipment =
      parseFloat( currentYearDetail.tangibleAdministrativeEquipment) +
      parseFloat( nextYearDetail.tangibleAdministrativeEquipment);

  totalDetail.tangibleBiologicEquipment =
      parseFloat(currentYearDetail.tangibleBiologicEquipment) + parseFloat(nextYearDetail.tangibleBiologicEquipment);

  totalDetail.tangibleOthers = parseFloat(currentYearDetail.tangibleOthers) + parseFloat(nextYearDetail.tangibleOthers);

  totalDetail.tangibleAssets = parseFloat(currentYearDetail.tangibleAssets) + parseFloat(nextYearDetail.tangibleAssets);

  totalDetail.intangibleGoodwill =
      parseFloat(currentYearDetail.intangibleGoodwill) + parseFloat(nextYearDetail.intangibleGoodwill);

  totalDetail.intangibleDevelopmentProjects =
      parseFloat(currentYearDetail.intangibleDevelopmentProjects) + parseFloat(nextYearDetail.intangibleDevelopmentProjects);

  totalDetail.intangibleComputerPrograms =
      parseFloat(currentYearDetail.intangibleComputerPrograms) + parseFloat(nextYearDetail.intangibleComputerPrograms);

  totalDetail.intangibleIntellectualProperty =
      parseFloat(currentYearDetail.intangibleIntellectualProperty) +
      parseFloat(nextYearDetail.intangibleIntellectualProperty);

  totalDetail.intangibleOthers =
      parseFloat(currentYearDetail.intangibleOthers) + parseFloat(nextYearDetail.intangibleOthers);

  totalDetail.intangibleAssets =
      parseFloat(currentYearDetail.intangibleAssets) + parseFloat(nextYearDetail.intangibleAssets);

  totalDetail.several = parseFloat(currentYearDetail.several) + parseFloat(nextYearDetail.several);

  totalDetail.pettyCash = parseFloat(currentYearDetail.pettyCash) + parseFloat(nextYearDetail.pettyCash);

  totalDetail.globalTotal = parseFloat(currentYearDetail.globalTotal) + parseFloat(nextYearDetail.globalTotal);
}

function calculateFinancialInvestmentDetailTotalFields(
  currentYearDetail,
  nextYearDetail,
  totalDetail
) {
  totalDetail.ownSocialCapital =
      parseFloat(currentYearDetail.ownSocialCapital) + parseFloat(nextYearDetail.ownSocialCapital);

  totalDetail.ownCapitalUnemploymentBenefits =
      parseFloat(currentYearDetail.ownCapitalUnemploymentBenefits) +
      parseFloat(nextYearDetail.ownCapitalUnemploymentBenefits);

  totalDetail.ownCapitalOthers =
      parseFloat(currentYearDetail.ownCapitalOthers) + parseFloat(nextYearDetail.ownCapitalOthers);

  totalDetail.ownCapital = parseFloat(currentYearDetail.ownCapital) + parseFloat(nextYearDetail.ownCapital);

  totalDetail.foreignCapitalBankLoans =
      parseFloat(currentYearDetail.foreignCapitalBankLoans) + parseFloat(nextYearDetail.foreignCapitalBankLoans);

  totalDetail.foreignCapitalPartnerLoans =
      parseFloat(currentYearDetail.foreignCapitalPartnerLoans) + parseFloat(nextYearDetail.foreignCapitalPartnerLoans);

  totalDetail.foreignCapitalProvidersLoans =
      parseFloat(currentYearDetail.foreignCapitalProvidersLoans )+ parseFloat(nextYearDetail.foreignCapitalProvidersLoans);

  totalDetail.foreignCapitalOthers =
      parseFloat(currentYearDetail.foreignCapitalOthers) + parseFloat(nextYearDetail.foreignCapitalOthers);

  totalDetail.foreignCapital = parseFloat(currentYearDetail.foreignCapital) + parseFloat(nextYearDetail.foreignCapital);

  totalDetail.investmentSupport =
      parseFloat(currentYearDetail.investmentSupport) + parseFloat(nextYearDetail.investmentSupport);

  totalDetail.workStationsCreationBenefits =
      parseFloat(currentYearDetail.workStationsCreationBenefits) + parseFloat(nextYearDetail.workStationsCreationBenefits);

  totalDetail.iefpAskedAmount = parseFloat(currentYearDetail.iefpAskedAmount) + parseFloat(nextYearDetail.iefpAskedAmount);

  totalDetail.globalTotal = parseFloat(currentYearDetail.globalTotal) + parseFloat(nextYearDetail.globalTotal);
}
