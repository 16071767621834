import {SubTemplate} from "../../../components/general/SubTemplate";
import {Alert, Col, Container, Row} from "react-bootstrap";

import React, {useEffect, useState} from "react";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {getCandidatureByExternalId} from "../../../rest/candidature";
import {doesNotHaveIrregularitiesNorNonCompliances, getPayments} from "../../../rest/candidaturePayments";
import {listDocumentsByDescriptorAndCandidatureId} from "../../../rest/document";
import {DocumentTypeDescriptor} from "../../../models/DocumentTypeDescriptor";
import {hasPermission, isPromoterUser} from "../../../authentication/authenticationHelper";
import {isJustMentioned} from "../../../rest/promoterMetaData";
import {getPromoterHasPendingEvaluationsForCandidature} from "../../../rest/evaluationEa";
import {canValidateCandidature} from "../../../rest/promoter";
import {CandidatureState} from "../../../models/CandidatureState";
import {PaymentEntry} from "../../../components/candidaturePayments/PaymentEntry";
import Loading from "../../../components/general/Loading";
import {FormattedMessage} from "react-intl";
import {PaymentList} from "../../../components/candidaturePayments/PaymentList";
import {PaymentActionsMenuBar} from "../../../components/candidaturePayments/PaymentActionsMenuBar";
import {useGlobalContext} from "../../../context";
import {AlertError} from "../../../components/bootstrap/AlertError";
import {PaymentTotals} from "../../../components/candidaturePayments/PaymentTotals";
import {UnemploymentPaymentDelegate} from "../../../components/candidaturePayments/UnemploymentPaymentDelegate";
import {
    canAddUnemploymentBenefitsPaymentRecord
} from "../../../components/candidaturePayments/candidaturePaymentFunctions";import {
    UnemploymentPaymentDelegationRecordModal
} from "../../../components/candidaturePayments/UnemploymentPaymentDelegationRecordModal";
import {FaInfoCircle} from "react-icons/fa";


export function CandidaturePayments({candidature}) {

    const [payments, setPayments] = useState();
    const [hasNoIrregNorNonComp, setHasNoIrregNorNonComp] = useState();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [unemploymentPaymentDelegationShow, setUnemploymentPaymentDelegationShow]
        = useState(false);
    const [modalSubmited, setModalSubmited] = useState(false);

    const fetchData = async () => {
        try {
            const [ { data: payments }, {data: noIrregNonComp}] = await Promise.all([
                getPayments(candidature.externalId),
                doesNotHaveIrregularitiesNorNonCompliances(candidature.externalId)
            ]);

            setPayments(payments);
            setHasNoIrregNorNonComp(noIrregNonComp);

        } catch (error) {
            setError(error);
        } finally {
            setLoading(false);
        }
    };
    



    useEffect(() => {
        fetchData();
    }, [modalSubmited]);

    if (loading) {
        return <Loading />;
    }


    return (
            <Container>
                <Row>
                    <Col md='9'>
                    </Col>
                    <Col md='3'>
                        <div className='px-2 mb-5'>
                            <PaymentActionsMenuBar
                                candidature={candidature}
                                setUnemploymentPaymentDelegationShow={setUnemploymentPaymentDelegationShow}
                                hasNoIrregNorNonComp={hasNoIrregNorNonComp}
                            />
                        </div>
                    </Col>
                </Row>

                {!hasNoIrregNorNonComp&&(
                <Row>
                    <Col>
                        <Alert
                            className='d-flex align-items-center mb-5'
                            variant='warning'
                            onClose={() => (console.log("closed the alert"))}
                        >
                            <div className='px-3'>
                                <FaInfoCircle />
                            </div>

                            <div className='px-3'>
                                <p className='mb-0'>
                                    <strong>
                                        <FormattedMessage id='candidature.payment.notPayable.title'/>
                                    </strong>
                                </p>
                                <p className='mb-0'><FormattedMessage id='candidature.payment.notPayable.text'/></p>
                            </div>
                        </Alert>
                    </Col>
                </Row>
                )}
                <Row>
                    <AlertError error={error} />
                    <Col md='12' className='mb-5'>
                        <PaymentList payments={payments} />
                        <Row>
                            <Col className='mt-4'>
                                <PaymentTotals candidature={candidature} payments={payments} />
                            </Col>
                        </Row>
                        {canAddUnemploymentBenefitsPaymentRecord(candidature) && (
                        <Row>
                            <Col className='mt-4'>
                                <UnemploymentPaymentDelegationRecordModal
                                    candidature={candidature}
                                    show={unemploymentPaymentDelegationShow}
                                    setShow={setUnemploymentPaymentDelegationShow}
                                    onSubmitCallback={() => setModalSubmited(true)}
                                />
                            </Col>
                        </Row>
                        )}

                        { candidature.unemploymentDelegationRecord && (
                            <>
                                <h5 className='mt-3'><FormattedMessage id='candidature.payments.unemployment.delegatedToSS' /></h5>
                                <Row>
                                    <Col>
                                        <label><FormattedMessage id='candidature.payments.unemployment.sendDate' /></label>
                                        {candidature.unemploymentDelegationRecord.date}
                                    </Col>
                                    <Col>
                                        <label><FormattedMessage id='candidature.payments.unemployment.referenceNumber' /></label>
                                        {candidature.unemploymentDelegationRecord.referenceNumber}
                                    </Col>
                                </Row>
                            </>
                        )}

                    </Col>

                </Row>
            </Container>
    );
}