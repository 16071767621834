import React, {Fragment} from "react";


const frag = (key) => <Fragment key={key}>&nbsp;·&nbsp;</Fragment>
export function GenericBadge({children}) {
    return (
        <span className="badge rounded-pill bg-secondary fw-normal ms-2">
            {(children ?? []).flatMap( (e, i) => (i !== 0 ? [frag(i), e] : e))}
        </span>
    )
}