export const ProviderType = {
  SUBCONTRACT: 'SUBCONTRACT',
  ELECTRICITY: 'ELECTRICITY',
  FUEL: 'FUEL',
  WATER: 'WATER',
  TOOLS: 'TOOLS',
  OFFICE_MATERIAL: 'OFFICE_MATERIAL',
  RENT: 'RENT',
  COMMUNICATION: 'COMMUNICATION',
  INSURANCE: 'INSURANCE',
  TRAVEL_STAY: 'TRAVEL_STAY',
  COMMISSION: 'COMMISSION',
  PUBLICITY: 'PUBLICITY',
  OTHER: 'OTHER',

  values: () => {
    return [
      'SUBCONTRACT',
      'ELECTRICITY',
      'FUEL',
      'WATER',
      'TOOLS',
      'OFFICE_MATERIAL',
      'RENT',
      'COMMUNICATION',
      'INSURANCE',
      'TRAVEL_STAY',
      'COMMISSION',
      'PUBLICITY',
      'OTHER'
    ];
  }
};
