import React, {useState} from 'react';
import {Accordion, Col, Row} from 'react-bootstrap';
import {FormattedMessage, useIntl} from 'react-intl';
import {SellInformationViewer} from "./SellInformationViewer";
import {Arrays} from "../../utils/Arrays";
import {ProviderInformationAccordionItem} from "./ProviderInformationAccordionItem";
import {PeopleBoardAccordionItem} from "./PeopleBoardAccordionItem";
import {PeopleRemunerationAccordionItem} from "./PeopleRemunerationAccordionItem";
import {AmortizationExpensesAccordionItem} from "./AmortizationExpensesAccordionItem";
import {EarlyAmortizationAccordionItem} from "./EarlyAmortizationAccordionItem";
import {OtherChargeAccordionItem} from "./OtherChargeAccordionItem";
import {ForeCastDemonstrationAccordionItem} from "./ForeCastDemonstrationAccordionItem";
import {ForeCastBalanceAccordionItem} from "./ForeCastBalanceAccordionItem";
import {DateUtils} from "../../utils/DateUtils";
import {maxFixed} from "../../utils/CurrencyUtils";

export function FinancialBoardsTab( {candidature}) {
    const intl = useIntl();
    const sellInformations = candidature.sellInformationList.filter((sellInformation)=>sellInformation.type==='SELL');
    const consumeInformations = candidature.sellInformationList.filter((sellInformation)=>sellInformation.type==='CONSUME');
    const otherIncentives = candidature.otherIncentives;
    const [totalsSellInformation, setTotalsSellInformation] = useState(getTotalSellInformation(sellInformations));
    const [totalsConsumeInformation, setTotalsConsumeInformation] = useState(getTotalSellInformation(consumeInformations));

    const yearNames = [];
    if(candidature?.candidatureDetails?.forecastDate){
        const date = DateUtils.removeDays(new Date(candidature.candidatureDetails.forecastDate),15).getFullYear()
        yearNames[0] = date;
        yearNames[1] = date+1;
        yearNames[2] = date+2;
        yearNames[3] = date+3;
        yearNames[4] = date+4;

    }else{
        yearNames[0] = intl.formatMessage({
            id: 'all.year',
        }) +' n';
        yearNames[1] = intl.formatMessage({
            id: 'all.year',
        }) +' n+1';
        yearNames[2] = intl.formatMessage({
            id: 'all.year',
        }) +' n+2';
        yearNames[3] = intl.formatMessage({
            id: 'all.year',
        }) +' n+3';
        yearNames[4] = intl.formatMessage({
            id: 'all.year',
        }) +' n+4';
    }

    const peopleRemunerationTotals = getPeopleRemunerationTotals();

    function getPeopleRemunerationTotals() {
        if (candidature.peopleRemunerationList?.length > 0) {
            let grouped = Arrays.groupBy(candidature.peopleRemunerationList[0].peopleRemunerationCellList, pRemuneration => pRemuneration.remunerationType + pRemuneration.year);


            let finalGrouped = [];

            grouped.forEach(group => {
                finalGrouped[group[0].remunerationType + group[0].year] = group.map(t2 => (t2.value)).reduce((total, count) => total + count)
            })
            return finalGrouped;
        }
        return null;
    }


    const totalsProvideInformation = getProviderInformationTotal();


    function getProviderInformationTotal() {
        if (candidature.provideInformationList?.length > 0) {
            let totals = [];
            let providerListGrouped = Arrays.groupBy(candidature.provideInformationList[0].provideInformationCellList, pInformation => pInformation.year);
            providerListGrouped.forEach(yearGroup => {
                    totals[yearGroup[0].year] = yearGroup.map(y => y.value).reduce((total, count) => (total + count)).toFixed(2);
                }
            );
            return totals;
        }
    }


    function getTotalSellInformation(sellInformationsParam) {
        let values = [];


        if (sellInformationsParam?.length > 0 && sellInformationsParam[0].sellInformationLines.length > 0) {
            sellInformationsParam[0].sellInformationLines[0].sellInformationLineYears.forEach((year) => {
                values.push({year: year.year, quantity: 0, value: 0});
            });

            sellInformationsParam.forEach((sellInformation) => {
                sellInformation.sellInformationLines?.forEach((line) => {
                    line.sellInformationLineYears.sort((a, b) => a['year'] - (b['year']));
                    line.sellInformationLineYears.forEach((year) => {
                        values.forEach((value => {
                            if (value.year === year.year) {
                                value.quantity += year.quantity;
                                console.log('year.value',year.value)
                                console.log('value.value',value.value)

                                value.value = parseFloat(year.value) + parseFloat(value.value);

                            }
                        }));
                        values.sort((a, b) => a.year - b.year);

                    });


                });
            });


        }
        return values;
    }

    return (

        <div className='py-3 mb-4 financialBoards'>


            <Accordion defaultActiveKey={['otherIncentive']}   alwaysOpen>
                {otherIncentives?.length > 0 && (
                    <Accordion.Item eventKey="otherIncentive" >
                        <Accordion.Header> <FormattedMessage
                            id='candidatureTabs.accordion.otherIncentive'/></Accordion.Header>
                        <Accordion.Body>


                            {otherIncentives.map((incentive,index) => (
                                <div  key={index} className='otherIncentive border-top'>
                                    <Row>
                                        <Col md='6'>
                                            <label>
                                                <FormattedMessage
                                                    id='candidatureTabs.accordion.otherIncentive.supportOrganization'/>
                                            </label>
                                            <p>
                                                {incentive.supportOrganization}
                                            </p>
                                        </Col>
                                        <Col md='6'>
                                            <label>
                                                <FormattedMessage
                                                    id='candidatureTabs.accordion.otherIncentive.organism'/>
                                            </label>
                                            <p>
                                                {incentive.organism}
                                            </p>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md='6'>
                                            <label>
                                                <FormattedMessage
                                                    id='candidatureTabs.accordion.otherIncentive.dispatchDate'/>
                                            </label>
                                            <p>
                                                {incentive.dispatchDate}
                                            </p>
                                        </Col>
                                        <Col md='6'>
                                            <label>
                                                <FormattedMessage id='candidatureTabs.accordion.otherIncentive.amount'/>
                                            </label>
                                            <p>
                                                {incentive.amount}
                                            </p>
                                        </Col>
                                    </Row>
                                </div>


                            ))

                            }


                        </Accordion.Body>
                    </Accordion.Item>
                )}
                <Accordion.Item eventKey="sellInformation">
                    <Accordion.Header><FormattedMessage
                        id='candidatureTabs.accordion.sellInformation'/></Accordion.Header>
                    <Accordion.Body>

                        <SellInformationViewer
                            sellInformations={sellInformations}
                            totals={totalsSellInformation}
                            totalLabel={intl.formatMessage({
                                id: 'sellInformation.total',
                            })}

                            reasonLabel={intl.formatMessage({
                                id: 'sellInformation.reason',
                            })}
                            yearNames={yearNames}

                        />


                    </Accordion.Body>
                </Accordion.Item>
                {(consumeInformations.length > 0 && consumeInformations[0].sellInformationLines.length > 0) && (


                    <Accordion.Item eventKey="consumeInformation">
                        <Accordion.Header><FormattedMessage
                            id='candidatureTabs.accordion.consumeInformation'/></Accordion.Header>
                        <Accordion.Body>

                            <SellInformationViewer
                                sellInformations={consumeInformations}
                                totals={totalsConsumeInformation}
                                totalLabel={intl.formatMessage({
                                    id: 'consumeInformation.total',
                                })}
                                reasonLabel={intl.formatMessage({
                                    id: 'consumeInformation.reason',
                                })}
                                yearNames={yearNames}

                            />


                        </Accordion.Body>
                    </Accordion.Item>
                )}

                <ProviderInformationAccordionItem provideInformationList={candidature.provideInformationList} totalProvideInformation={totalsProvideInformation} yearNames={yearNames}/>
                <PeopleBoardAccordionItem peopleBoardList={candidature.peopleBoardList} yearNames={yearNames}/>
                <PeopleRemunerationAccordionItem peopleRemunerationList={candidature.peopleRemunerationList} peopleRemunerationTotals={peopleRemunerationTotals} yearNames={yearNames}/>
                <AmortizationExpensesAccordionItem amortizationExpensesList={candidature.amortizationExpensesList} yearNames={yearNames}/>
                <EarlyAmortizationAccordionItem earlyAmortizationList={candidature.earlyAmortizationList} yearNames={yearNames}/>
                <OtherChargeAccordionItem otherChargeList={candidature.otherChargeList} yearNames={yearNames}/>
                <ForeCastDemonstrationAccordionItem otherChargeList={candidature.otherChargeList} forecastDemonstrationList={candidature.forecastDemonstrationList} totalsSellInformation={totalsSellInformation} totalsConsumeInformation={totalsConsumeInformation} totalProvideInformation={totalsProvideInformation} peopleRemunerationTotals={peopleRemunerationTotals} yearNames={yearNames}/>
                <ForeCastBalanceAccordionItem forecastBalanceList={candidature.forecastBalanceList}    yearNames={yearNames}/>





            </Accordion>


        </div>
    );
}
