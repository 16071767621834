import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { AlertError } from '../bootstrap/AlertError';
import { TextInputField } from '../bootstrap/TextInputField';
import {CandidatureState} from "../../models/CandidatureState";

export function CandidatureWithdrawModal({ show, handleClose, withdrawSubmitHandler,candidature}) {

  const [user, setUser] = useState();
  const [password, setPassword] = useState();
  const [modalError, setModalError] = useState(null);

  function handleCloseModal() {
    setUser('');
    setPassword('');
    handleClose()
  }


  return (
    <Modal show={show} onHide={handleCloseModal}>
      <Modal.Header closeButton>
        <Modal.Title>
          <FormattedMessage  id={(candidature.state === CandidatureState.DRAFT) ? 'candidatureForm.cancel.title':'candidatureForm.withdrawModal.title' } />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <AlertError error={modalError} />
        <div>
          <FormattedMessage id={(candidature.state === CandidatureState.DRAFT) ? 'candidatureForm.cancel.body':'candidatureForm.withdrawModal.body' } />
          <TextInputField
            controlId={'user'}
            labelId={'messages.emailText'}
            name={'user'}
            handleChange={(e) => setUser(e.target.value)}
          />
          <TextInputField
            controlId={'password'}
            labelId={'all.password'}
            name={'password'}
            handleChange={(e) => setPassword(e.target.value)}
            isPassword={true}
          />
        </div>

        <div className='form-actions mt-5'>
          <button
            className='btn btn-link'
            type='button'
            onClick={() => handleCloseModal()}
          >
            <FormattedMessage id='all.back' />
          </button>
          <button
            className='btn btn-primary'
            type='button'
            onClick={() => withdrawSubmitHandler(user, password, setModalError)}
          >


            <FormattedMessage id={(candidature.state === CandidatureState.DRAFT) ? 'all.cancel':'all.candidatureWithdrawal' } />
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
}
