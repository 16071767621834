import React, { useEffect, useState } from 'react';
import { NumberInputField } from '../../bootstrap/NumberInputField';
import { Row, Col, Table, Form, Button } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { EnumSelectField } from '../../bootstrap/EnumSelectField';
import { OptionsUtils } from '../../../utils/OptionsUtils';
import { MceYearType } from '../../../models/MceYearType';
import { FaRegTimesCircle } from 'react-icons/fa';

export function SemestralReportFormStep4({
	formStep,
	numberOfSteps,
	errors,
	handleChange,
	formikValues,
	handlePreviousStep,
	handleNextStep,
	setFieldValue,
											 readMode
}) {
	const intl = useIntl();

	const year = formikValues.mceYearType === MceYearType.FIRST && '1'
		|| formikValues.mceYearType === MceYearType.SECOND && '2'
		|| formikValues.mceYearType === MceYearType.THIRD && '3'

	console.log(formikValues)

	const semester = formikValues.semester===false ? '1' : '2'


	const semesterMonths = getSemesterMonths();

	function getSemesterMonths() {
		const startDate = new Date(formikValues.semesterInitialDate);
		const endDate = new Date(formikValues.semesterEndDate);
		const months = [];

		while (startDate <= endDate) {
			months.push(startDate.getMonth() + 1);
			startDate.setMonth(startDate.getMonth() + 1);
		}
		months.push(startDate.getMonth() + 1);

		return months;
	}

	function total(propertyName) {
		let total = 0;
		formikValues.semesterFollowUpVisits.forEach((monthStatus) => {
			if (typeof monthStatus[propertyName] === 'number')
				total += monthStatus[propertyName];
		});
		return total;
	}

	function addNewFollowUpVisitHandler() {
		formikValues.semesterFollowUpVisits.push({
			month: '-1',
			numberOfVisits: '',
			duration: '',

		});
		setFieldValue({ ...formikValues });
	}

	function removeFollowUpVisitHandler(index) {
		formikValues.semesterFollowUpVisits.splice(index, 1);
		setFieldValue({ ...formikValues });
	}

	return (
		<div>
			<h4 className='text-primary mb-0'>
				<FormattedMessage id='semestralReportForm.step4.title' />
			</h4>
			<small className='text-secondary text-uppercase'>
				{intl.formatMessage(
					{ id: 'all.step' },
					{ step: formStep, numberOfSteps: numberOfSteps }
				)}
			</small>
			<fieldset disabled={readMode==true}>
			<Row className={'mb-4'}>
				<Col md='3'>
					<NumberInputField
						labelId={'semestralReportForm.step4.year'}
						value={year}
						disabled
					/>
				</Col>
				<Col md='3'>
					<NumberInputField
						value={semester}
						labelId={'semestralReportForm.step4.semester'}
						disabled
					/>
				</Col>
			</Row>
			<Row>
				<Col md='12'>
					<Table className='d-table' striped hover responsive='md'>
						<thead>
							<tr>
								<th className='w-50'>
									<FormattedMessage id='semestralReportForm.step4.month' />
								</th>
								<th>
									<FormattedMessage id='semestralReportForm.step4.numberOfVisits' />
								</th>
								<th colSpan={2}>
									<FormattedMessage id='semestralReportForm.step4.duration' />
								</th>
							</tr>
						</thead>
						<tbody>
							{formikValues.semesterFollowUpVisits.map((el, arrayIndex) => (
								<tr key={arrayIndex}>
									<td className='w-50'>
										<EnumSelectField
											name={`semesterFollowUpVisits[${arrayIndex}].month`}
											handleChange={handleChange}
											value={formikValues.semesterFollowUpVisits[arrayIndex].month}
											isInvalid={
												errors.semesterFollowUpVisits
													? [arrayIndex]?.month
														? true
														: false
													: false
											}
											nullOption={true}
											options={semesterMonths}
											preffixDescriptionId={'month'}
											errorMessage={
												errors.semesterFollowUpVisits
													? [arrayIndex]?.month
														? errors.semesterFollowUpVisits[arrayIndex].month
														: ''
													: ''
											}
										/>
									</td>
									<td>
										<NumberInputField
											controlId={`semesterFollowUpVisits[${arrayIndex}].numberOfVisits`}
											name={`semesterFollowUpVisits[${arrayIndex}].numberOfVisits`}
											handleChange={handleChange}
											value={
												formikValues.semesterFollowUpVisits[arrayIndex].numberOfVisits
											}
											maxLength={2}
											isInvalid={
												errors.semesterFollowUpVisits
													? [arrayIndex]?.numberOfVisits
														? true
														: false
													: false
											}
											errorMessage={
												errors.semesterFollowUpVisits
													? [arrayIndex]?.numberOfVisits
														? errors.semesterFollowUpVisits[arrayIndex].numberOfVisits
														: ''
													: ''
											}
										/>
									</td>
									<td>
										<NumberInputField
											controlId={`semesterFollowUpVisits[${arrayIndex}].duration`}
											name={`semesterFollowUpVisits[${arrayIndex}].duration`}
											handleChange={handleChange}
											value={formikValues.semesterFollowUpVisits[arrayIndex].duration}
											maxLength={2}
											isInvalid={
												errors.semesterFollowUpVisits
													? [arrayIndex]?.duration
														? true
														: false
													: false
											}
											errorMessage={
												errors.semesterFollowUpVisits
													? [arrayIndex]?.duration
														? errors.semesterFollowUpVisits[arrayIndex].duration
														: ''
													: ''
											}
										/>
									</td>
									<td>
										<FaRegTimesCircle
											onClick={() =>
												removeFollowUpVisitHandler(arrayIndex)
											}
											color={"#eb4f24"}
											cursor={"pointer"}
										/>
									</td>
								</tr>
							))}
							<tr>
								<td>Total</td>
								<td>{total('numberOfVisits')}</td>
								<td colSpan={2}>{total('duration')}</td>
							</tr>
						</tbody>
					</Table>
				</Col>
			</Row>
			<Row className='py-4'>
				<Col md='4'>
					<Button variant='outline-primary' onClick={addNewFollowUpVisitHandler}>
						<FormattedMessage id='semestralReportForm.step4.btn.addVisit' />
					</Button>
				</Col>
			</Row>
			</fieldset>
			<div className='form-actions mt-5'>
				<button
					className='btn btn-link'
					type='button'
					onClick={handlePreviousStep}
				>
					<FormattedMessage id='all.back' />
				</button>

				<button
					className='btn btn-primary'
					type='button'
					onClick={handleNextStep}
				>
					<FormattedMessage id='all.nextStep' />
				</button>
			</div>

		</div>
	);
}
