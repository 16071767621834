export const EntityChangeState = {


  DRAFT: 'DRAFT',
  SUBMITTED: 'SUBMITTED',
  WAIT_CONTESTATION:'WAIT_CONTESTATION',
  ANALYZE_CONTESTATION:'ANALYZE_CONTESTATION',
  APPROVED: 'APPROVED',
  REJECTED: 'REJECTED',

  values: () => {
    return ['DRAFT', 'SUBMITTED','WAIT_CONTESTATION','ANALYZE_CONTESTATION', 'APPROVED', 'REJECTED'];
  },

  finalStates: () => {
    return ['APPROVED', 'REJECTED'];
  }


};
