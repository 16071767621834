import React, { useEffect, useState } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { AlertError } from '../../components/bootstrap/AlertError';
import { AddArtsCraftOpinionForm } from '../../components/candidature/AddArtsCraftOpinionForm';
import Loading from '../../components/general/Loading';
import { SubTemplate } from '../../components/general/SubTemplate';
import { DocumentType } from '../../models/DocumentType';
import {
    getCandidatureByExternalId,
    submitArtsCraftOpinion
} from '../../rest/candidature';
import { listDocumentsByTypeAndCandidatureExternalId } from '../../rest/document';
import { handleError, isNotBusinessError } from '../../utils/handleError';

export function CandidatureSubmitArtsCraftsOpinion() {
  const { externalId } = useParams();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [userDocuments, setUserDocuments] = useState([]);
  const [canSubmitArtsCraftsOpinion, setCanSubmitArtsCraftsOpinion] =
    useState(false);
  const [submit, setSubmit] = useState(false);

  async function fetchData() {
    try {
      const [{ data: userDocuments }] = await Promise.all([
        await listDocumentsByTypeAndCandidatureExternalId(
          DocumentType.ARTS_CRAFTS_OPINION,
          externalId
        ),
      ]);
      setUserDocuments(userDocuments);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  }
  useEffect(() => {
    checkCandidature();
  }, []);

  async function checkCandidature() {
    try {
      const [{ data: candidature }] = await Promise.all([
        await getCandidatureByExternalId(externalId),
      ]);
      if (candidature.canSentOpinionArtsAndCrafts === true) {
        setCanSubmitArtsCraftsOpinion(true);
        fetchData();
      } else {
        setLoading(false);
      }
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  }

  if (submit) {
    return <Navigate replace to={`/candidatura/${externalId}`} />;
  }

  async function submitOpinion() {
    try {
      await submitArtsCraftOpinion(externalId);
      setSubmit(true);
    } catch (error) {
      setError(error);
    }
  }

  function handleSubmit() {
    submitOpinion();
  }

  if (loading) {
    return <Loading />;
  }

  if (error && isNotBusinessError(error)) {
    return handleError(error);
  }

  return (
    <SubTemplate>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-8 offset-lg-2 mb-5'>
            <AlertError error={error} />
            {canSubmitArtsCraftsOpinion && (
              <AddArtsCraftOpinionForm
                onSubmit={handleSubmit}
                userDocuments={userDocuments}
                externalId={externalId}
                setDocuments={setUserDocuments}
              />
            )}
          </div>
        </div>
      </div>
    </SubTemplate>
  );
}
