import { apiAxios as authAxios } from '../axios.config';

export const listDocumentTypesByDescriptor = (descriptor) => {
    return authAxios.get(`/api/documentType/${descriptor}`);
}

export const listDocumentTypesByDescriptions = (descriptions) => {
  return authAxios.post(
    `/api/documentType/descriptions`,
    descriptions
  );
};