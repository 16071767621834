import React, { useEffect, useState } from 'react';
import { Row, Col, Form, Table } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { FaArrowDown } from 'react-icons/fa';
import { TextInputField } from '../../bootstrap/TextInputField';
import { EnumSelectField } from '../../bootstrap/EnumSelectField';
import { NumberInputField } from '../../bootstrap/NumberInputField';
import { TextAreaInputField } from '../../bootstrap/TextAreaInputField';
import ToolTip from '../../bootstrap/ToolTip';
import IntInputField from "../../bootstrap/IntInputField";

export function SemestralReportFormStep5({
	formStep,
	numberOfSteps,
	errors,
	handleChange,
	formikValues,
	handlePreviousStep,
	handleNextStep,
	setFieldValue,
	readMode
}) {
	const intl = useIntl();

	const operationOptions = ['ON_GOING_AS_PLANNED', 'ON_GOING_ANOTHER', 'INACTIVE'];

	const materialHistoryState = [
		'HAS_PLANNED',
		'SMALL_CHANGES',
		'NOT_MATCH',
	];

	const facilitiesOptions = [
		'GOOD_PLURAL',
		'ACCEPTABLE_PLURAL',
		'DEFFECTIVE_PLURAL',
	];

	const equipmentOptions = [
		'GOOD',
		'ACCEPTABLE',
		'DEFFECTIVE',
	];


	const difficultyAreasOptions = [
		'research',
		'management',
		'informationSystems',
		'people',
		'marketing',
		'equipment',
		'financial',
		'otherDifficulty',
	];

	const [
		difficultyAreasCheckedMissingActivity,
		setDifficultyAreasCheckedMissingActivity,
	] = useState([]);

	const [difficultyAreaActivityToAdd, setDifficultyAreaActivityToAdd] =
		useState('-1');

	const [otherDifficultyExistance, setOtherDifficultyExistance] =
		useState(false);


	function initializeDifficultyAreasCheckedMissingActivity() {
		let areas = [];
		for (let i = 0; i < difficultyAreasOptions.length; i++) {
			const area = difficultyAreasOptions[i];

			if (formikValues.semesterFollowUpResults[area] === true) {
				areas.push(area);

			} else if (area === 'otherDifficulty'
				&& formikValues.semesterFollowUpResults[area] !== undefined
				&& formikValues.semesterFollowUpResults[area] !== '') {
				setOtherDifficultyExistance(true);
				areas.push(area);
			}

			for (let semesterDifficultyOvercomeActivity of formikValues.semesterFollowUpResults.semesterDifficultyOvercomeActivities) {

				if (semesterDifficultyOvercomeActivity.area === area) {
					areas = areas.filter((difficultyArea) => difficultyArea !== area);
				}
			}
		}

		setDifficultyAreasCheckedMissingActivity(areas);

	}

	useEffect(() => {
		initializeDifficultyAreasCheckedMissingActivity();
	}, []);

	function handleDifficultyAreasSelection(e) {
		const area = e.target.name;
		const isChecked = e.target.checked;

		if (isChecked) {
			setDifficultyAreasCheckedMissingActivity([...difficultyAreasCheckedMissingActivity, area]);

			if (area === 'otherDifficulty') {
				setOtherDifficultyExistance(true);
				formikValues.semesterFollowUpResults[area] = 'Qual?';
			} else {
				formikValues.semesterFollowUpResults[area] = isChecked;
			}

		} else {

			setDifficultyAreasCheckedMissingActivity(difficultyAreasCheckedMissingActivity.filter(difficultyArea => difficultyArea !== area));

			if (area === 'otherDifficulty') {
				setOtherDifficultyExistance(false);
				formikValues.semesterFollowUpResults[area] = '';
			} else {
				formikValues.semesterFollowUpResults[area] = isChecked;
			}

			formikValues.semesterFollowUpResults.semesterDifficultyOvercomeActivities =
				formikValues.semesterFollowUpResults.semesterDifficultyOvercomeActivities.filter(
					(value) => value.area !== e.target.name
				);

		}

		setFieldValue({ ...formikValues });

	}

	function handlerAddNewDifficultyAreaActivity() {
		if (difficultyAreaActivityToAdd != -1) {

			formikValues.semesterFollowUpResults.semesterDifficultyOvercomeActivities.push({
				area: difficultyAreaActivityToAdd,
				description: '',
			});

			setDifficultyAreasCheckedMissingActivity(
				difficultyAreasCheckedMissingActivity.filter(
					(value) => value !== difficultyAreaActivityToAdd
				));

			setDifficultyAreaActivityToAdd('-1');
			setFieldValue({ ...formikValues });

		}

	}

	function removeDifficultyAreaActivityHandler(difficultyArea, index) {
		formikValues.semesterFollowUpResults.semesterDifficultyOvercomeActivities.splice(index, 1);

		setDifficultyAreasCheckedMissingActivity([...difficultyAreasCheckedMissingActivity, difficultyArea]);
		setFieldValue({ ...formikValues });
	}


	function renderDifficultyAreasOptionsRow(startIndex, endIndex) {
		let toRender = [];
		for (let i = startIndex; i < endIndex; i++) {
			toRender.push(
				<Form.Check
					key={i}
					inline
					className='d-flex align-items-center'
					checked={difficultyAreasOptions[i] !== 'otherDifficulty' ?
						formikValues.semesterFollowUpResults[difficultyAreasOptions[i]] === true :
						(formikValues.semesterFollowUpResults[difficultyAreasOptions[i]] !== ''
							&& formikValues.semesterFollowUpResults[difficultyAreasOptions[i]] !== undefined)
					}
					label={
						<FormattedMessage
							id={`semestralReportForm.step5.difficultyArea.option.${difficultyAreasOptions[i]}`}
						/>
					}
					name={difficultyAreasOptions[i]}
					onChange={handleDifficultyAreasSelection}
				/>
			);
		}
		return toRender;
	}
	return (
		<div>
			<h4 className='text-primary mb-0'>
				<FormattedMessage id='semestralReportForm.step5.title' />
			</h4>
			<small className='text-secondary text-uppercase'>
				{intl.formatMessage(
					{ id: 'all.step' },
					{ step: formStep, numberOfSteps: numberOfSteps }
				)}
			</small>
			<Row className='mt-4'>
				<small className='text-primary text-uppercase'>
					<FormattedMessage id='semestralReportForm.step5.companyDescription' />
				</small>
			</Row>
			<fieldset disabled={readMode==true}>
			<Row className='mb-5'>
				<Col md='4'>
					<EnumSelectField
						labelId='semestralReportForm.step5.operation'
						name={`semesterFollowUpResults.companyStatus.operation`}
						handleChange={handleChange}
						value={formikValues.semesterFollowUpResults.companyStatus.operation}
						isInvalid={errors.semesterFollowUpResults?.companyStatus?.operation ? true : false}
						preffixDescriptionId={'semestralReportForm.step5'}
						nullOption={true}
						options={operationOptions}
						errorMessage={
							errors.semesterFollowUpResults?.companyStatus?.operation
								? errors.semesterFollowUpResults.companyStatus.operation
								: ''
						}
					/>
				</Col>
				<Col md='4'>
					<EnumSelectField
						labelId='semestralReportForm.step5.facilities'
						name={`semesterFollowUpResults.companyStatus.facilitiesQuality`}
						handleChange={handleChange}
						value={formikValues.semesterFollowUpResults.companyStatus.facilitiesQuality}
						isInvalid={errors.semesterFollowUpResults?.companyStatus?.facilitiesQuality ? true : false}
						preffixDescriptionId={'semestralReportForm.step5'}
						nullOption={true}
						options={materialHistoryState}
						errorMessage={
							errors.semesterFollowUpResults?.companyStatus?.facilitiesQuality
								? errors.semesterFollowUpResults.companyStatus.facilitiesQuality
								: ''
						}
					/>
					<EnumSelectField
						labelId='semestralReportForm.step5.facilitiesPrediction'
						name={`semesterFollowUpResults.companyStatus.facilitiesPrediction`}
						handleChange={handleChange}
						value={formikValues.semesterFollowUpResults.companyStatus.facilitiesPrediction}
						isInvalid={errors.semesterFollowUpResults?.companyStatus?.facilitiesPrediction ? true : false}
						preffixDescriptionId={'semestralReportForm.step5'}
						nullOption={true}
						options={facilitiesOptions}
						errorMessage={
							errors.semesterFollowUpResults?.companyStatus?.facilitiesPrediction
								? errors.semesterFollowUpResults.companyStatus.facilitiesPrediction
								: ''
						}
					/>
				</Col>
				<Col md='4'>
					<EnumSelectField
						labelId='semestralReportForm.step5.equipment'
						name={`semesterFollowUpResults.companyStatus.equipmentQuality`}
						handleChange={handleChange}
						value={formikValues.semesterFollowUpResults.companyStatus.equipmentQuality}
						isInvalid={errors.semesterFollowUpResults?.companyStatus?.equipmentQuality ? true : false}
						preffixDescriptionId={'semestralReportForm.step5'}
						nullOption={true}
						options={materialHistoryState}
						errorMessage={
							errors.semesterFollowUpResults?.companyStatus?.equipmentQuality
								? errors.semesterFollowUpResults.companyStatus.equipmentQuality
								: ''
						}
					/>
					<EnumSelectField
						labelId='semestralReportForm.step5.equipmentPrediction'
						name={`semesterFollowUpResults.companyStatus.equipmentPrediction`}
						handleChange={handleChange}
						value={formikValues.semesterFollowUpResults.companyStatus?.equipmentPrediction}
						isInvalid={errors.semesterFollowUpResults?.companyStatus?.equipmentPrediction ? true : false}
						preffixDescriptionId={'semestralReportForm.step5'}
						nullOption={true}
						options={equipmentOptions}
						errorMessage={
							errors.semesterFollowUpResults?.companyStatus?.equipmentPrediction
								? errors.semesterFollowUpResults.companyStatus.equipmentPrediction
								: ''
						}
					/>
				</Col>
			</Row>
			<Row className='mb-4'>
				<small className='text-uppercase d-block'>
					<FormattedMessage id='semestralReportForm.step5.numberOfWorkPositions' />
				</small>
				<Col md='4'>
					<label>
						<FormattedMessage id='semestralReportForm.step5.numberOfWorkPositions.planned' />
					</label>
					<IntInputField
						className={'w-50'}
						controlId={'semesterFollowUpResults.companyStatus.workPositionsPredicted'}
						name={'semesterFollowUpResults.companyStatus.workPositionsPredicted'}
						handleChange={handleChange}
						value={formikValues.semesterFollowUpResults.companyStatus.workPositionsPredicted}
						isInvalid={errors.semesterFollowUpResults?.companyStatus?.workPositionsPredicted ? true : false}
						errorMessage={
							errors.semesterFollowUpResults?.companyStatus?.workPositionsPredicted ? errors.semesterFollowUpResults.companyStatus.workPositionsPredicted : ''
						}
					/>
				</Col>
				<Col md='4'>
					<label>
						<FormattedMessage id='semestralReportForm.step5.numberOfWorkPositions.created' />
					</label>
					<IntInputField
						className={'w-50'}
						controlId={'semesterFollowUpResults.companyStatus.workPositionsCreated'}
						name={'semesterFollowUpResults.companyStatus.workPositionsCreated'}
						handleChange={handleChange}
						value={formikValues.semesterFollowUpResults.companyStatus.workPositionsCreated}
						isInvalid={errors.semesterFollowUpResults?.companyStatus?.workPositionsCreated ? true : false}
						errorMessage={
							errors.semesterFollowUpResults?.companyStatus?.workPositionsCreated ? errors.semesterFollowUpResults.companyStatus.workPositionsCreated : ''
						}
					/>
				</Col>
				<Col md='4'>
					<label>
						<FormattedMessage id='semestralReportForm.step5.numberOfWorkPositions.maintained' />
					</label>
					<IntInputField
						className={'w-50'}
						controlId={'semesterFollowUpResults.companyStatus.workPositionsKept'}
						name={'semesterFollowUpResults.companyStatus.workPositionsKept'}
						handleChange={handleChange}
						value={formikValues.semesterFollowUpResults.companyStatus.workPositionsKept}
						isInvalid={errors.semesterFollowUpResults?.companyStatus?.workPositionsKept ? true : false}
						errorMessage={
							errors.semesterFollowUpResults?.companyStatus?.workPositionsKept
								? errors.semesterFollowUpResults.companyStatus?.workPositionsKept
								: ''
						}
					/>
				</Col>
			</Row>
			<Row className='mb-5'>
				<Col>
					<TextAreaInputField
						labelId='semestralReportForm.step5.promotersMaintainHalfOfSocialCapitalAndVotes'
						name={'semesterFollowUpResults.companyStatus.promotersHaveMajority'}
						handleChange={handleChange}
						value={formikValues.semesterFollowUpResults.companyStatus.promotersHaveMajority}
						isInvalid={
							errors.semesterFollowUpResults?.companyStatus?.promotersHaveMajority ? true : false
						}
						errorMessage={
							errors.semesterFollowUpResults?.companyStatus?.promotersHaveMajority
								? errors.semesterFollowUpResults?.companyStatus?.promotersHaveMajority
								: ''
						}
						placeholder={intl.formatMessage({
							id: 'semestralReportForm.step5.describe',
						})}
					/>
				</Col>
			</Row>
			<Row className='mb-1'>
				<small className='text-primary text-uppercase'>
					<FormattedMessage id='semestralReportForm.step5.identificationOfDifficultyAreas' />
					<ToolTip
						message={
							<FormattedMessage id='semestralReportForm.step5.identificationOfDifficultyAreas.toolTip' />
						}
					/>

				</small>
			</Row>
			<Row>
				<Col md='6'>{renderDifficultyAreasOptionsRow(0, 4)}</Col>
				<Col md='6'>
					{renderDifficultyAreasOptionsRow(4, difficultyAreasOptions.length)}
					{otherDifficultyExistance && (
						<TextInputField
							value={formikValues.semesterFollowUpResults.otherDifficulty}
							name='semesterFollowUpResults.otherDifficulty'
							handleChange={handleChange}
							placeholder={intl.formatMessage({
								id: 'semestralReportForm.step5.difficultyArea.anotherOption.placeholder',
							})}
						/>
					)}
				</Col>
			</Row>
			<Row className='mb-4 mt-5'>
				<small className='text-primary text-uppercase'>
					<FormattedMessage id='semestralReportForm.step5.activitiesToOvercomeFoundDifficulties' />
					<ToolTip
						message={
							<FormattedMessage id='semestralReportForm.step5.activitiesToOvercomeFoundDifficulties.toolTip' />
						}
					/>
				</small>
			</Row>
			<Row>
				<Col>
					<Table className='d-table' striped responsive='md'>
						<thead>
							<tr>
								<th>
									<FormattedMessage id='semestralReportForm.step5.difficultyArea' />
								</th>
								<th>
									<FormattedMessage id='semestralReportForm.step5.descriptionOfActivity' />
								</th>
							</tr>
						</thead>
						<tbody>
							{Object.keys(formikValues.semesterFollowUpResults.semesterDifficultyOvercomeActivities).length >
								0 ? (
								Object.values(formikValues.semesterFollowUpResults.semesterDifficultyOvercomeActivities).map(
									(difficultyAreaActivity, index) => (
										<tr key={index}>
											<td className='w-50'>
												<FormattedMessage
													id={`semestralReportForm.step5.difficultyArea.option.${difficultyAreaActivity.area}`}
												/>
											</td>
											<td>
												<TextInputField
													value={
														formikValues.semesterFollowUpResults.semesterDifficultyOvercomeActivities[index].description
													}
													name={`semesterFollowUpResults.semesterDifficultyOvercomeActivities[${index}].description`}
													handleChange={handleChange}
													isInvalid={
														errors.semesterFollowUpResults?.semesterDifficultyOvercomeActivities
															? [index].description
																? true
																: false
															: false
													}
													errorMessage={
														errors.semesterFollowUpResults?.semesterDifficultyOvercomeActivities
															? [index].description
																? errors.semesterFollowUpResults?.semesterDifficultyOvercomeActivities[index].description
																: ''
															: ''
													}
												/>
											</td>
											<td>
												<button
													className='mt-0 px-0 btn btn-link'
													type='button'
													onClick={() => removeDifficultyAreaActivityHandler(difficultyAreaActivity.area, index)}
												>
													<FormattedMessage id='all.remove' />
												</button>
											</td>
										</tr>
									)
								)
							) : (
								<tr>
									<td colSpan={2}>
										{formikValues.semesterFollowUpResults.semesterDifficultyOvercomeActivities.length > 0 ? (
											<FormattedMessage id='semestralReportForm.step5.noDifficultyActivitiesPresent' />
										) : (
											<FormattedMessage id='semestralReportForm.step5.noDifficultyAreasSelected' />
										)}
									</td>
								</tr>
							)}
						</tbody>
						{difficultyAreasCheckedMissingActivity.length > 0 && (
							<tfoot>
								<tr>
									<td className='w-50'>
										<EnumSelectField
											handleChange={(e) =>
												setDifficultyAreaActivityToAdd(e.target.value)
											}
											isInvalid={
												errors.semesterFollowUpResults?.operation ? true : false
											}
											nullOption={true}
											preffixDescriptionId={
												'semestralReportForm.step5.difficultyArea.option'
											}
											options={difficultyAreasCheckedMissingActivity}
										/>
									</td>
									<td>
										<button
											className='btn btn-outline-primary'
											type='button'
											onClick={handlerAddNewDifficultyAreaActivity}
										>
											<FormattedMessage id='all.addButton' />
										</button>
									</td>
								</tr>
							</tfoot>
						)}
					</Table>
				</Col>
			</Row>
			</fieldset>
			<div className='form-actions mt-5'>
				<button
					className='btn btn-link'
					type='button'
					onClick={handlePreviousStep}
				>
					<FormattedMessage id='all.back' />
				</button>

				<button
					className='btn btn-primary'
					type='button'
					onClick={handleNextStep}
				>
					<FormattedMessage id='all.nextStep' />
				</button>
			</div>
		</div>
	);
}
