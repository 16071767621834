import React, { useState } from 'react';
import {
	Row,
	Col,
	Form,
	Button,
	Accordion,
	Dropdown,
	Modal,
} from 'react-bootstrap';
import { FaExclamationCircle, FaInfoCircle } from 'react-icons/fa';
import { FormattedMessage, useIntl } from 'react-intl';
import { MCESupportKind } from '../../models/MCESupportKind';
import { EnumSelectField } from '../bootstrap/EnumSelectField';
import { NumberInputField } from '../bootstrap/NumberInputField';
import { TextInputField } from '../bootstrap/TextInputField';
import { SearchInputDropdown } from '../general/SearchInputDropdown';
import { SinglePromoterDetails } from '../general/SinglePromoterDetails';
import {
	getCandidatureProcessNumberExternalIdMappingForEconomicViabilitiesThatHaveNoPayment,
	getIasValueWhenEconomicViabilityWasCreated,
} from '../../rest/economicViability';
import { getPrimaryPromoterByCandidatureExternalId } from '../../rest/candidature';

export function EconomicViabilityPaymentRequestFormStep2({
	formStep,
	numberOfSteps,
	handleChange,
	formikValues,
	handlePreviousStep,
	handleNextStep,
	setFieldValue,
	readMode,
	setError,
}) {
	const intl = useIntl();
	const [showDropdown, setShowDropdown] = useState(
		formikValues.candidatures.length > 0 ? false : true
	);

	const [
		showCandidatureAlreadyHasPaymentModal,
		setShowCandidatureAlreadyHasPaymentModal,
	] = useState(false);
	const [
		candidatureThatAlreadyHasPayment,
		setCandidatureThatAlreadyHasPayment,
	] = useState(null);

	async function candidatureProcessNumberSelectionHandler(
		candidatureProcessNumber,
		candidatureMapping
	) {

		if (candidatureMapping.b5Link) {
			candidatureMapping.candidatureProcessNumber = candidatureProcessNumber;
			setShowCandidatureAlreadyHasPaymentModal(true);
			setCandidatureThatAlreadyHasPayment(candidatureMapping);
			return;
		}
		
		try {
			const [{ data: mainPromoterInfo }, { data: iasOfTheFirstCandidature }] =
				await Promise.all([
					await getPrimaryPromoterByCandidatureExternalId(
						candidatureMapping.externalId
					),
					formikValues.candidatures.length === 0
						? await getIasValueWhenEconomicViabilityWasCreated(
								candidatureMapping.externalId
						  )
						: { data: formikValues.iasAtContractCelebration },
				]);

			let newMention = {
				candidatureExternalId: candidatureMapping.externalId,
				candidatureProcessNumber: candidatureProcessNumber,
				regionalDelegation: candidatureMapping.regionalDelegation,
				mainPromoter: mainPromoterInfo,
			};
			formikValues.candidatures.push(newMention);
			setFieldValue('candidatures', [...formikValues.candidatures]);

			setFieldValue('iasAtContractCelebration', iasOfTheFirstCandidature);

			setShowDropdown(false);
		} catch (error) {
			setError(error);
		}
	}

	function dropdownItemsGenerator(candidatureProcessNumbersMappings) {
		let selectedCandidaturesProcessNumbers = formikValues.candidatures.map(
			(candidatureMention) => candidatureMention.candidatureProcessNumber
		);
		for (let candidatureProcessNumber of selectedCandidaturesProcessNumbers) {
			delete candidatureProcessNumbersMappings[candidatureProcessNumber];
		}

		return Object.keys(candidatureProcessNumbersMappings).map((key, index) => (
			<Dropdown.Item
				key={index}
				onClick={() =>
					candidatureProcessNumberSelectionHandler(
						key,
						candidatureProcessNumbersMappings[key]
					)
				}
			>
				{key}
			</Dropdown.Item>
		));
	}

	function removeCandidatureMention(index) {
		formikValues.candidatures.splice(index, 1);
		if (formikValues.candidatures.length === 0) {
			setFieldValue('iasAtContractCelebration', '');
		}
		setFieldValue('candidatures', [...formikValues.candidatures]);
	}

	function handleModalClose() {
		setShowCandidatureAlreadyHasPaymentModal(false);
		setCandidatureThatAlreadyHasPayment(null);
	}

	return (
		<div>
			<h4 className='text-primary mb-0'>
				<FormattedMessage id='economicViabilityPaymentRequest.step2.title' />
			</h4>
			<small className='text-secondary text-uppercase'>
				{intl.formatMessage(
					{ id: 'all.step' },
					{ step: formStep, numberOfSteps: numberOfSteps }
				)}
			</small>
			<p className='mt-3 text-secondary infoText'>
				<FaInfoCircle size={40} />
				<FormattedMessage id='economicViabilityPaymentRequest.step2.infoText' />
			</p>
			{showCandidatureAlreadyHasPaymentModal && (
				<Modal
					show={showCandidatureAlreadyHasPaymentModal}
					onHide={handleModalClose}
				>
					<Modal.Header closeButton>
						<Modal.Title>
							<FormattedMessage id='candidature.text' />{' '}
							{' (' +
								candidatureThatAlreadyHasPayment.candidatureProcessNumber +
								')'}{' '}
						</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<p>
							<FormattedMessage id='priorSupportPaymentRequest.step2.modal.candidatureAlreadyHasPayment' />
						</p>
						<p>
							<FormattedMessage id='priorSupportPaymentRequest.step2.modal.candidatureAlreadyHasPaymentLinkMessage.part1' />
							<a
								href={`${candidatureThatAlreadyHasPayment.b5Link}`}
								target='_blank'
								rel='noopener noreferrer'
							>
								<FormattedMessage id='priorSupportPaymentRequest.step2.modal.candidatureAlreadyHasPaymentLinkMessage.part2' />
							</a>
							<FormattedMessage id='priorSupportPaymentRequest.step2.modal.candidatureAlreadyHasPaymentLinkMessage.part3' />
						</p>
					</Modal.Body>
				</Modal>
			)}
			{!readMode && showDropdown && (
				<Row>
					<Col md='4'>
						<SearchInputDropdown
							filterQueryParamName='candidatureProcessNumber'
							searchCallback={
								getCandidatureProcessNumberExternalIdMappingForEconomicViabilitiesThatHaveNoPayment
							}
							setError={setError}
							itemsFunctionGenerator={dropdownItemsGenerator}
						/>
					</Col>
				</Row>
			)}
			{formikValues.candidatures.map((candidature, index) => (
				<Row key={index}>
					<Col md='12'>
						<Accordion>
							<Accordion.Item eventKey='0'>
								<Accordion.Header>
									<FormattedMessage id='candidature.text' />
									{' (' + candidature.candidatureProcessNumber + ')'}{' '}
								</Accordion.Header>
								<Accordion.Body>
									<Row>
										<Col md='6'>
											<TextInputField
												labelId='economicViabilityFormStep1.processNumber'
												value={candidature.candidatureProcessNumber}
												disabled
											/>
										</Col>

										<Col md='6'>
											<TextInputField
												labelId='economicViabilityFormStep1.regionalDelegation'
												value={candidature.regionalDelegation}
												disabled
											/>
										</Col>
									</Row>
									<Row className='mt-5'>
										<small className='text-primary text-uppercase'>
											<FormattedMessage id='economicViabilityPaymentRequest.step2.responsiblePromoter' />
										</small>
									</Row>
									<SinglePromoterDetails promoter={candidature.mainPromoter} />
									{!readMode && (
										<Row className='mt-4'>
											<Col md='2'>
												<button
													className='btn btn-link'
													type='button'
													onClick={() => removeCandidatureMention(index)}
												>
													<FormattedMessage id='all.remove' />
												</button>
											</Col>
										</Row>
									)}
								</Accordion.Body>
							</Accordion.Item>
						</Accordion>
					</Col>
				</Row>
			))}
			{!showDropdown && !readMode && (
				<Row className='py-4'>
					<Col md='4'>
						<Button
							variant='outline-primary'
							onClick={() => setShowDropdown(true)}
						>
							<FormattedMessage id='economicViabilityPaymentRequest.step2.button.addCandidature' />
						</Button>
					</Col>
				</Row>
			)}
			<div className='form-actions mt-5'>
				<button
					className='btn btn-link'
					type='button'
					onClick={handlePreviousStep}
				>
					<FormattedMessage id='all.back' />
				</button>

				<button
					className='btn btn-primary'
					type='button'
					onClick={handleNextStep}
				>
					<FormattedMessage id='all.nextStep' />
				</button>
			</div>
		</div>
	);
}
