import { AdditionalActivityType } from '../models/AdditionalActivityType';

const bootcampIndex = 0;
const didacticIndex = 1;
const disclosureIndex = 2;

export function totalCostsGeneral(
	formikValues,
	additionalActivityIndex,
	amountType
) {
	let sum = 0;

	formikValues.activityIdentification.additionalActivityList[
		additionalActivityIndex
	].supportForecastList.forEach(
		/* If 'elem[amountType]' holds a "falsey" value (false, null, undefined, 0, "", NaN),
    assume 0 for the sum. */
		(elem) => (sum += parseFloat(elem[amountType]) || 0)
	);

	return sum;
}

export function incubationSupportPredictionTotalOfMonths(formikValues, months) {
	let sum = 0;
	formikValues.activityIdentification.incubationProjectList.forEach((project) => {
		if (project.endMonth !== '-1' && project.startMonth !== '-1') {
			sum +=
				((months.indexOf(project.endMonth) - months.indexOf(project.startMonth)) + 1);
		}
	});
	return sum;
}

export function totalCostsIncubationSupport(formikValues, IAS, months) {
	return (
		incubationSupportPredictionTotalOfMonths(formikValues, months) *
		calculateProportional(formikValues)
	);
}

export function totalCostsDisclosureActivities(formikValues) {
	return (
		totalCostsGeneral(formikValues, disclosureIndex, 'value') +
		totalCostsGeneral(formikValues, disclosureIndex, 'onlineValue')
	);
}

export function totalEABudget(formikValues, IAS, months) {
	return (
		totalCostsGeneral(
			formikValues,
			bootcampIndex,
			'value'
		) +
		totalCostsDisclosureActivities(formikValues) +
		totalCostsGeneral(
			formikValues,
			didacticIndex,
			'value'
		) +
		totalCostsIncubationSupport(formikValues, IAS, months)
	);
}

export function calculateProportional(formikValues){
	return (parseFloat(formikValues.activityIdentification.ias) || 0) * 3 / 12
}
