import React, { useEffect, useRef, useState } from 'react';
import { Form, Row, Container, Col } from 'react-bootstrap';
import { Formik } from 'formik';
import { FormattedMessage, useIntl } from 'react-intl';
import Loading from '../../components/general/Loading';
import { SubTemplate } from '../../components/general/SubTemplate';
import { FaCheck, FaRegCheckCircle, FaRegSave, FaTimes } from 'react-icons/fa';
import { handleError, isNotBusinessError } from '../../utils/handleError';
import { FormSideNavBar } from '../../components/general/FormSideNavBar';
import {
	SemestralReportFormStep1,
	SemestralReportFormStep2,
} from '../../components/semestralReport/';
import { ActivitySheetFormStep3 } from '../../components/activitySheet/activitySheetFormStep3';
import {
	isIEFPUser,
	isPromoterUser,
} from '../../authentication/authenticationHelper';
import { isEntityUser } from '../../authentication/authenticationHelper';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { SuccessToast } from '../../components/general/SuccessToast';
import { AlertError } from '../../components/bootstrap/AlertError';
import {
	getActivitySheet,
	getActivitySheetByProcessExternalId,
	saveActivitySheet,
	submitActivitySheetDecision,
} from '../../rest/activitySheet';
import { getEntityGetList } from '../../rest/entity';
import { createCustomErrorMessage } from '../../hooks/errorMessage';
import { ProcessState } from '../../models/ProcessState';
import { ConfirmationAuthenticationModal } from '../../components/general/ConfirmationAuthenticationModal';
import { DecisionDialog } from '../../components/bootstrap/DecisionDialog';
import { CompanyIdentificationFormUtils } from '../../utils/CompanyIdentificationFormUtils';
import * as yup from 'yup';

const validationSchema = yup.object().shape({
	identificationEa: yup.object().shape({
		technicalTeamManagerExternalId: yup
			.string()
			.test(
				'isGETValid',
				<FormattedMessage id='errors.annualComplementaryActivitiesPlan.GET' />,
				(value) => value !== '-1'
			),
	}),
	company: CompanyIdentificationFormUtils.validationObject(true),

	activity: yup
	.string()
	.test(
		'isOptionSelected',
		<FormattedMessage id='errors.selectOption' />,
		(value) => value !== '-1'
	),

	activityDate: yup
		.date()
		.nullable()
		.required(<FormattedMessage id='errors.fieldRequiredText' />)
		.test(
			'isDateNotAfterToday',
			'A data não pode ser superior à data de hoje',
			(value) => !(new Date(value) > Date.now())
		),

	description: yup
		.string()
		.required(<FormattedMessage id='errors.fieldRequiredText' />),

	results: yup
		.string()
		.required(<FormattedMessage id='errors.fieldRequiredText' />),
});

export function AddActivitySheet() {
	const isEntity = isEntityUser();
	const isPromoter = isPromoterUser();
	const isIEFP = isIEFPUser();

	//for both iefp and entity users. In case of iefp, it is the processExternalId. Also, if it got here without the externalId, then its value is undefined
	const { externalId: externalId } = useParams();

	const [loading, setLoading] = useState(true);
	const [submited, setSubmited] = useState(false);

	//errors
	const [error, setError] = useState(null);
	const [navErrors, setNavErrors] = useState([]);

	const [formStep, setFormStep] = useState(0);

	const [formikInitialValues, setFormikInitialValues] = useState(null);

	const [showSaveToast, setShowSaveToast] = useState(false);

	//modals
	const [showSubmitModal, setShowSubmitModal] = useState(false);
	const [showApproveModal, setShowApproveModal] = useState(false);
	const [showRejectModal, setShowRejectModal] = useState(false);

	const [getList, setGetList] = useState([]);

	const navigate = useNavigate();
	const intl = useIntl();

	const formikPropsRef = useRef(null);

	const tabsIds = [
		'annualComplementaryActivitiesPlanForm.sideNavBar.identificationEA',
		'semestralReportForm.sideNavBar.identificationCompany',
		'activitySheetForm.sideNavBar.visitPurpose',
	];

	async function fetchData() {
		try {
			let { data: form } = isIEFP
				? await getActivitySheetByProcessExternalId(externalId)
				: await getActivitySheet(externalId);




			let entityHumanResourceParamsForNonEntityUsers = {
				entityNif: form.identificationEa.entity.nif,
				all:form.processState !== ProcessState.DRAFT
			};

			let [{ data: entityGetList }] = await Promise.all([
				isEntity
					? await getEntityGetList({all:form.processState !== ProcessState.DRAFT})
					: await getEntityGetList(entityHumanResourceParamsForNonEntityUsers),
			]);

			setGetList(entityGetList);
			setFormikInitialValues(getFormikInitialValues(form));
		} catch (error) {
			setError(error);
		} finally {
			setLoading(false);
		}
	}

	useEffect(() => {
		fetchData();
	}, []);

	function getFormikInitialValues(form) {
		let initialValues = form;
		CompanyIdentificationFormUtils.getFormikInitialValuesCompanyIdentification(
			form,
			true
		);

		initialValues.activity ??= '';
		initialValues.activityDate ??= null;
		initialValues.description ??= '';
		initialValues.results ??= '';

		return initialValues;
	}

	function scrollPageToTop() {
		document.body.scrollTop = 0;
		document.documentElement.scrollTop = 0;
	}

	function handlePreviousStep() {
		scrollPageToTop();
		setFormStep(formStep - 1);
	}

	function handleNextStep() {
		scrollPageToTop();
		setFormStep(formStep + 1);
	}

	if (submited) {
		if (isEntity) {
			sessionStorage.setItem('activitySheetSubmited', submited);
			return <Navigate to='/fichaAtividade/lista' />;
		} else {
			//promoter
			sessionStorage.setItem('activitySheetDecisionSubmited', submited);
			return <Navigate to='/candidatura' />;
		}
	}

	if (loading) {
		return <Loading />;
	}

	if (error && isNotBusinessError(error)) {
		return handleError(error);
	}

	async function handleSave() {

		console.log(formikPropsRef.current.values);

		try {
			let { data: res } = await saveActivitySheet(
				formikPropsRef.current.values
			);

			let processedRes = getFormikInitialValues(res);

			formikPropsRef.current.setValues(processedRes);
			setNavErrors([]);
			formikPropsRef.current.setErrors({});

			setShowSaveToast(true);
		} catch (error) {
			setError(error);
		}
	}

	async function submitDecision(decisionApprove) {
		try {
			let data = {
				flag: decisionApprove,
			};
			await submitActivitySheetDecision(
				formikPropsRef.current.values.externalId,
				data
			);
			setSubmited(true);
		} catch (error) {
			setError(error);
		}
	}

	async function handleSubmit(user, password, setModalError) {
		let values = formikPropsRef.current.values;
		const submissionValues = { ...values };

		submissionValues.credentials = {
			user: user,
			password: password,
		};

		let queryParams = {
			submit: true,
		};

		try {
			await saveActivitySheet(submissionValues, queryParams);
			setSubmited(true);
		} catch (err) {
			let exceptionType = err.response.data.exception;
			if (exceptionType === 'ValidationException') {
				handleNavError(err);
			} else {
				setModalError(err);
			}
		}
	}

	async function formValidationHandler(setShowModal) {
		let errors = await validateForm();
		setNavErrors(Array.from(errors));
		if (errors.size === 0) {
			setShowModal(true);
		}
	}

	async function validateForm() {
		let errors = new Set();

		const formikErrors = await formikPropsRef.current.validateForm();

		Object.keys(formikErrors).forEach((key) => {
			switch (key) {
				case 'identificationEa':
					isEntity && errors.add(0);
					break;
				case 'company':
					isEntity && errors.add(1);
					break;
				default:
					isEntity && errors.add(2);
					break;
			}
		});

		let values = formikPropsRef.current.values;
		let errorMessages = [];
		
		if(values.candidatureProcessNumber === '-1'){
			errorMessages.push(intl.formatMessage({id: 'errors.associateCandidature'}))
			isEntity && errors.add(1);
		}

		if (errorMessages.length > 0) {
			let message =
				errorMessages.length === 1
					? createCustomErrorMessage(errorMessages[0])
					: createCustomErrorMessage(
							'<p>' +
								intl.formatMessage({
									id: 'errors.more',
								}) +
								'</p><p> - ' +
								errorMessages.join('</p><p> - ') +
								'</p>'
					  );

			setError(message);
		}

		return errors;
	}

	function handleNavError(error) {
		let errors = error.response.data.message
			.split(',')
			.map((value) => Number.parseInt(value));
		setNavErrors(errors);
	}

	function renderStep(formikProps) {
		formikPropsRef.current = formikProps;

		const readMode = !(
			isEntity && formikProps.values.processState === ProcessState.DRAFT
		);

		switch (formStep) {
			case 0:
				return (
					<SemestralReportFormStep1
						formStep={formStep + 1}
						numberOfSteps={tabsIds.length}
						errors={formikProps.errors}
						formikValues={formikProps.values}
						handleChange={formikProps.handleChange}
						handleNextStep={handleNextStep}
						entityGetList={getList}
						readMode={readMode}
						goBackPath={
							isEntity
								? '/fichaAtividade/lista'
								: isPromoter
								? '/candidature'
								: '/processos'
						}
					/>
				);
			case 1:
				return (
					<SemestralReportFormStep2
						formStep={formStep + 1}
						numberOfSteps={tabsIds.length}
						handlePreviousStep={handlePreviousStep}
						errors={formikProps.errors}
						formikValues={formikProps.values}
						handleChange={formikProps.handleChange}
						handleNextStep={handleNextStep}
						readMode={readMode}
						setError={setError}
						setFieldValue={formikProps.setFieldValue}
						hasCandidatureSearch={true}
					/>
				);
			case 2:
				return (
					<ActivitySheetFormStep3
						formStep={formStep + 1}
						numberOfSteps={tabsIds.length}
						errors={formikProps.errors}
						formikValues={formikProps.values}
						handleChange={formikProps.handleChange}
						handlePreviousStep={handlePreviousStep}
						setFieldValue={formikProps.setFieldValue}
						entityGetList={getList}
						readMode={readMode}
					/>
				);
			default:
				console.log('Something went wrong rendering the form step');
		}
	}

	return (
		<SubTemplate>
			<Container>
				<ConfirmationAuthenticationModal
					show={showSubmitModal}
					handleClose={() => setShowSubmitModal(false)}
					submitHandler={handleSubmit}
					idsPreffix={'annualComplementaryActivitiesPlan.submitModal'}
					confirmButtonCompleteLabelId={'all.submitButtonText'}
				/>

				<DecisionDialog
					show={showApproveModal || showRejectModal}
					handleConfirm={
						showApproveModal
							? () => submitDecision(true)
							: () => submitDecision(false)
					}
					handleClose={() => {
						showApproveModal
							? setShowApproveModal(false)
							: setShowRejectModal(false);
					}}
					titleId={
						showApproveModal
							? 'developmentPlan.accept.title'
							: 'developmentPlan.reject.title'
					}
					bodyId={'developmentPlan.decisionDialog.body'}
					needReason={false}
				/>
				<Row>
					<Col md={{ span: 8, offset: 2 }}>
						<h2 className='mb-1 text-secondary'>
							<FormattedMessage id='activitySheetForm.title' />
						</h2>
						<p className='mb-5 text-secondary'>
							<FormattedMessage id='activitySheetForm.subTitle' />
							<span
								className="badge rounded-pill bg-secondary fw-normal ms-2"><span
								className="text-uppercase fw-bold"><FormattedMessage
								id={`processState.${formikInitialValues.processState}`}
							/> </span>  {formikInitialValues.submissionDate !== undefined && formikInitialValues.submissionDate !== null && (<> · <FormattedMessage id={'submitted.process.at'} values={{date:formikInitialValues.submissionDate}}/>  </>) } {formikInitialValues.approvalDate !== undefined && formikInitialValues.approvalDate !== null && (<> · {ProcessState.APPROVED===formikInitialValues.processState ? <FormattedMessage id={'approvedPromoter.process.at'} values={{date:formikInitialValues.approvalDate}}/>:<FormattedMessage id={'rejectedPromoter.process.at'} values={{date:formikInitialValues.approvalDate}}/>}   </>) }  </span>
						</p>
					</Col>
				</Row>
				<Row className='mb-5'>
					<Col md='2'>
						<FormSideNavBar
							tabsIdsArray={tabsIds}
							formStep={formStep}
							setFormStep={setFormStep}
							navErrors={navErrors}
							setNavErrors={setNavErrors}
						/>
					</Col>
					<Col md='8'>
						<AlertError error={error} />
						<SuccessToast
							message={intl.formatMessage({
								id: 'annualComplementaryActivitiesPlan.saveToastInformation',
							})}
							show={showSaveToast}
							setShow={setShowSaveToast}
						/>
						<Formik
							initialValues={formikInitialValues}
							validateOnBlur={false}
							validateOnChange={false}
							validationSchema={validationSchema}
							onSubmit={handleSubmit}
						>
							{(formikProps) => (
								<Form onSubmit={formikProps.handleSubmit}>
									{renderStep(formikProps)}
								</Form>
							)}
						</Formik>
					</Col>
					<Col md='2'>
						<div>
							{isEntity &&
								formikInitialValues.processState === ProcessState.DRAFT && (
									<button
										className='btn btn-outline-primary d-flex align-items-center justify-content-center mb-4 w-100'
										type='button'
										onClick={() => handleSave()}
									>
										<FaRegSave />
										<FormattedMessage id='all.save' />
									</button>
								)}
							{isPromoter &&
								formikInitialValues.processState === ProcessState.SUBMITTED && (
									<>
										<button
											className='btn btn-primary d-flex align-items-center justify-content-center mb-4 w-100'
											type='button'
											onClick={() => formValidationHandler(setShowRejectModal)}
										>
											<FaTimes /> <FormattedMessage id='all.button.reject' />
										</button>
										<button
											className='btn btn-primary d-flex align-items-center justify-content-center mb-4 w-100'
											type='button'
											onClick={() => formValidationHandler(setShowApproveModal)}
										>
											<FaCheck /> <FormattedMessage id='all.button.approve' />
										</button>
									</>
								)}
							{isEntity &&
								formikInitialValues.processState === ProcessState.DRAFT && (
									<button
										className='btn btn-primary d-flex align-items-center justify-content-center w-100'
										type='button'
										onClick={() => formValidationHandler(setShowSubmitModal)}
									>
										<FaRegCheckCircle />
										<FormattedMessage id='all.requestPromoterValidationButtonText' />
									</button>
								)}
						</div>
					</Col>
				</Row>
			</Container>
		</SubTemplate>
	);
}
