import { apiAxios as authAxios } from '../axios.config';



export const getPriorEntityAssociatedToCandidature = (params) => {
  return authAxios.get(`api/entityCandidature/`, {"params": params}); //params variable include: candidatureExternalId
};

export const getPriorEntityContacts = (params) => {
  return authAxios.get(`/api/entityCandidature/priorSupportContacts`, {"params": params}); //params variable include: candidatureExternalId
}

export const getOpinionEntityContacts = (params) => {
  return authAxios.get(`/api/entityCandidature/opinionEntityContacts`, {"params": params}); //params variable include: candidatureExternalId
}