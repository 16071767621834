import React from 'react';
import {Col, Row, Table} from 'react-bootstrap';
import {FormattedMessage, useIntl} from 'react-intl';
import {CandidatureFormUtils} from '../../utils/CandidatureFormUtils';
import {EconomicViabilityUtils} from '../../utils/EconomicViabilityUtils';
import DecimalInputField from '../bootstrap/DecimalInputField';
import {TextAreaInputField} from '../bootstrap/TextAreaInputField';
import ToolTip from '../bootstrap/ToolTip';
import {currencyFormat, maxFixed} from "../../utils/CurrencyUtils";

export function CandidatureFormStep5Test({
  values,
  setFieldValue,
  handleChange,
  handleSave,
  handleGoBack,
  numberOfSteps,
  errors,
  isValidate
}) {

  const CURRENT_YEAR = 0;
  const NEXT_YEAR = 1;

  const intl = useIntl();

  const KEYS = [
    'investmentProperties',
    'investmentPropertiesNaturalResources',
    'investmentPropertiesBuildings',
    'investmentPropertiesOthers',
    'tangibleAssets',
    'tangibleNaturalResources',
    'tangibleBuildings',
    'tangibleBasicEquipment',
    'tangibleTransportEquipment',
    'tangibleAdministrativeEquipment',
    'tangibleBiologicEquipment',
    'tangibleOthers',
    'intangibleAssets',
    'intangibleGoodwill',
    'intangibleDevelopmentProjects',
    'intangibleComputerPrograms',
    'intangibleIntellectualProperty',
    'intangibleOthers',
    'several',
    'pettyCash',
    'globalTotal'
  ];

  const BOLD_KEYS = [
    'investmentProperties',
    'tangibleAssets',
    'intangibleAssets',
    'several',
    'pettyCash',
    'globalTotal'
  ];

  const LOCK_KEYS = [
    'investmentProperties',
    'tangibleAssets',
    'intangibleAssets',
    'globalTotal'
  ];


  function calculateInvestmentDetailTotals() {
    values.investmentPlanDetails.forEach((ipd) => {
      EconomicViabilityUtils.calculateInvestmentPlanDetailTotals(ipd);
    });
  }

  function getValueByYearAndFieldName(year, fieldName) {
    return maxFixed(getInvestmentDetailByYear(year)[fieldName]);
  }

  function handleInvestmentChange(e, year, fieldName) {
    getInvestmentDetailByYear(year)[fieldName] = e.target.value;

    calculateInvestmentDetailTotals();

    EconomicViabilityUtils.calculateInvestmentPlanDetailTotalFields(
      getInvestmentDetailByYear(CURRENT_YEAR),
      getInvestmentDetailByYear(NEXT_YEAR),
      getInvestmentDetailByYear('-1')
    );

    setFieldValue('investmentPlanDetails', [...values.investmentPlanDetails]);
  }

  function getInvestmentDetailByYear(year) {
    const investmentDetail = values.investmentPlanDetails.filter((ipd) => {
      if (year === '-1') {
        return ipd.investmentType === 'TOTAL';
      } else {
        return ipd.year === year;
      }
    });
    return investmentDetail[0];
  }

  function handleApplyUnemploymentBenefitsChange(index, value) {
    values.promotersMetaData[index].applyUnemploymentBenefits = value;
    setFieldValue('promoters', [...values.promotersMetaData]);
  }

  function handleNextStep() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    handleSave(true);
  }






  function getTableRowsByKey(key) {
    return (
      <tr key={key}>
        <td className={`w-40 ${BOLD_KEYS.includes(key) && 'table-item-bold'}`}>
          <FormattedMessage id={`economicViabilityFormStep3.${key}`} />
        </td>
        <td className='w-20'>
          <DecimalInputField

            name={`investmentPlanDetails.${key}`}
            handleChange={(e) => handleInvestmentChange(e, CURRENT_YEAR, key)}
            value={getValueByYearAndFieldName(CURRENT_YEAR, key)}
            suffixLabel='€'
            disabled={LOCK_KEYS.includes(key)}
          />
        </td>
        <td className='w-20'>
          <DecimalInputField
            name={`investmentPlanDetails.${key}`}
            handleChange={(e) => handleInvestmentChange(e, NEXT_YEAR, key)}
            value={getValueByYearAndFieldName(NEXT_YEAR, key)}
            suffixLabel='€'
            disabled={LOCK_KEYS.includes(key)}
          />
        </td>
        <td className='text-end'>
          <span>{currencyFormat(getValueByYearAndFieldName('-1', key))}</span>
        </td>
      </tr>
    );
  }

  return (
    <div>
      <h4 className='text-primary mb-0'>
        {isValidate ? (
          <FormattedMessage id='economicViabilityForm.investmentPlanTabApproved' />
        ) : (
          <FormattedMessage id='economicViabilityForm.investmentPlanTab' />
        )}
      </h4>
      <small className='text-secondary text-uppercase'>
        {intl.formatMessage(
          { id: 'all.step' },
          { step: values.step, numberOfSteps: numberOfSteps }
        )}
      </small>

      <Table className='mt-4'>
        <thead>
          <tr>
            <th className='w-40'>
              <FormattedMessage id='candidatureTabs.investment' />
            </th>
            <th>
              {CandidatureFormUtils.getYearLabel(values, 0)} <ToolTip message={<FormattedMessage id='step3.tooltip.firstYear'/>}/>
            </th>
            <th>
              {CandidatureFormUtils.getYearLabel(values, 1)}
            </th>
            <th className='text-end'>
              <FormattedMessage id='finalReportForm.step7.table.total' />
            </th>
          </tr>
        </thead>
        <tbody>{KEYS.map((key) => getTableRowsByKey(key))}</tbody>
      </Table>


      {!isValidate && (
        <>
          <Row>
            <Col md='12'>
              <TextAreaInputField
                labelId={'candidatureForm.investmentPlanReasoning'}
                name={'candidatureDetails.investmentPlanReasoning'}
                value={values.candidatureDetails.investmentPlanReasoning}
                handleChange={handleChange}
                placeholder={intl.formatMessage({ id: 'all.maxLength.placeholder' })}
                maxLength={10000}
                isInvalid={errors.candidatureDetails?.investmentPlanReasoning}
                errorMessage={errors.candidatureDetails?.investmentPlanReasoning}
              />
            </Col>
          </Row>

          <div className='form-actions mt-5'>
            <button
              className='btn btn-link'
              type='button'
              onClick={() => handleGoBack(values, setFieldValue)}>
              <FormattedMessage id='all.back' />
            </button>

            <button className='btn btn-primary' type='button' onClick={() => handleNextStep()}>
              <FormattedMessage id='all.nextStep' />
            </button>
          </div>
        </>
      )}
    </div>
  );
}
