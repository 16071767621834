import axios from 'axios';
import { apiAxios as authAxios } from '../axios.config';

/**
 * @param  {{title:String, body: String, emailAddressFrom: String, personName: String, mobile: String}} message
 * @returns {AxiosResponse<String>}
 */
export const sendPublicMessage = (message) => {
  return axios.post('api/messages', message);
};




export const sendMessage = (message, params) => {
  return authAxios.post('api/messages/create', message, {"params": params}); //params include: candidatureExternalId
};

export const sendMessageWithFiles = (formData, params) => {
  return authAxios.post('api/messages/create', formData, {"params": params}); //params include: candidatureExternalId
};

export const sendMessageToMCEEntity = (message, params) => {
  return authAxios.post('api/messages/create/mce', message, { params: params }); //params include: candidatureExternalId
};

export const sendMessageToMCEEntityWithFiles = (formData, params) => {
  return authAxios.post('api/messages/create/mce', formData, { params: params }); //params include: candidatureExternalId
};



export const getUserMessages = (
  keyword,
  type,
  boundType,
  state,
  parentExternalId,
  index,
  size
) => {
  let queryParams = constructQueryParams(
    keyword,
    type,
      boundType,
    state,
    parentExternalId,
    index,
    size
  );
  return authAxios.get(`/api/messages/user${queryParams}`);
};


export const getUserMessagesPending = () => {

  return authAxios.get(`/api/messages/user/pending`);
};




export const getMessage = (externalId) => {
  return authAxios.get(`/api/messages/${externalId}`);
};



export const getTypesMessages = () => {
  return authAxios.get(`/api/messages/types`);
};

export const getStatesMessages = () => {
  return authAxios.get(`/api/messages/states`);
};

export const getBoundTypeMessages = () => {
  return authAxios.get(`/api/messages/boundTypes`);
};



const constructQueryParams = (
  keyword,
  type,
  boundType,
  state,
  parentExternalId,
  index,
  size
) => {
  let queryParams = '?';
  if (keyword) {
    queryParams = queryParams + 'keyword=' + keyword;
  }
  if (type) {
    queryParams =
      queryParams.length > 1
        ? queryParams + '&type=' + type
        : queryParams + 'type=' + type;
  }
  if (boundType) {
    queryParams =
      queryParams.length > 1
        ? queryParams + '&boundType=' + boundType
        : queryParams + 'boundType=' + boundType;
  }
  if (state) {
    queryParams =
      queryParams.length > 1
        ? queryParams + '&state=' + state
        : queryParams + 'state=' + state;
  }

  if (parentExternalId) {
    queryParams =
      queryParams.length > 1
        ? queryParams + '&parentExternalId=' + parentExternalId
        : queryParams + 'parentExternalId=' + parentExternalId;
  }



  if (index !== null) {
    queryParams =
      queryParams.length > 1
        ? queryParams + '&index=' + index
        : queryParams + 'index=' + index;
  }
  if (size) {
    queryParams =
      queryParams.length > 1
        ? queryParams + '&size=' + size
        : queryParams + 'size=' + size;
  }
  if (queryParams.length <= 0) {
    return '';
  }
  return queryParams;
};

/**
 * @param  {String} parentExternalId
 * @param  {{title: string, body: string}} replyMessage
 * @returns  {Promise<AxiosResponse<{}>>} created message
 */
export const replyMessage = (parentExternalId, replyMessage) => {
  return authAxios.post(`/api/messages/${parentExternalId}`, replyMessage);
};

export const replyMessageWithFiles = (parentExternalId, formData) => {
  return authAxios.post(`/api/messages/${parentExternalId}`, formData);
};

