import React, {useEffect, useRef, useState} from 'react';
import {Form, Row, Container, Col, ToastContainer, Toast} from 'react-bootstrap';
import { Formik } from 'formik';
import {FormattedMessage, useIntl} from 'react-intl';
import Loading from '../../components/general/Loading';
import { SubTemplate } from '../../components/general/SubTemplate';
import { FaRegCheckCircle, FaRegSave, FaCheck } from 'react-icons/fa';
import { handleError, isNotBusinessError } from '../../utils/handleError';
import { FormSideNavBar } from '../../components/general/FormSideNavBar';
import { useNavigate } from 'react-router-dom';
import {
	SemestralReportFormStep1,
	SemestralReportFormStep2,
	SemestralReportFormStep3,
} from '../../components/semestralReport';
import { FinancialModalityStep } from '../../components/promoter/FinancialModalityStep';
import { InvestmentConfirmationStep } from '../../components/investmentConfirmation/InvestmentConfirmationStep';
import {useParams} from "react-router-dom";
import {ProcessState} from "../../models/ProcessState";
import {isEntityUser} from "../../authentication/authenticationHelper";
import {
	getAllowActionsFinalReportByConsultingRequest, getAllowActionsInvestmentConfirmationByConsultingRequest,
	getFinalReportByConsultingRequest,
	getInvestmentConfirmationByConsultingRequest,
	saveFinalReportByConsultingRequest,
	saveInvestmentConfirmationByConsultingRequest, submitFinalReportDecision, submitInvestmentConfirmationReportDecision
} from "../../rest/consultingRequest";
import {getEntityGetList, getEntityTgpList} from "../../rest/entity";
import {CompanyIdentificationFormUtils} from "../../utils/CompanyIdentificationFormUtils";
import {FinancialModalityFormUtils} from "../../utils/FinancialModalityFormUtils";
import {AlertError} from "../../components/bootstrap/AlertError";
import * as yup from "yup";
import {createCustomErrorMessage} from "../../hooks/errorMessage";
import {ConfirmationAuthenticationModal} from "../../components/general/ConfirmationAuthenticationModal";
import {DecisionDialog} from "../../components/bootstrap/DecisionDialog";
import {TextAreaInputField} from "../../components/bootstrap/TextAreaInputField";

export function AddInvestmentConfirmation() {
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);
	const [navErrors, setNavErrors] = useState([]);
	const [formStep, setFormStep] = useState(0);
	const [formikInitialValues, setFormikInitialValues] = useState(null);
	const [investmentConfirmation,setInvestmentConfirmation]= useState(null);
	const [showSubmitModal, setShowSubmitModal] = useState(false);
	const [showSaveToast, setShowSaveToast] = useState(false);
	const [getList, setGetList] = useState([]);
	const {externalId} = useParams();
	const formikPropsRef = useRef(null);
	const [submited, setSubmited] = useState(false);
	const [allowActions, setAllowActions] = useState(null);
	const [showAccept, setShowAccept] = useState(false);
	const [showReject, setShowReject] = useState(false);
	const [accept, setAccept] = useState(false);
	const [reject, setReject] = useState(false);
	const intl = useIntl();
	const navigate = useNavigate();
	const tabsIds = [
		'semestralReportForm.sideNavBar.identificationEA',
		'semestralReportForm.sideNavBar.identificationCompany',
		'eaevaluationform.sideMenu.modalityidentification',
		'semestralReportForm.sideNavBar.projectApproval',
		'investmentConfirmationForm.sideNavBar.investmentConfirmationStep',
	];


	function getSaveToastInformation(message) {
		return (
			<ToastContainer className='fixed-top'>
				<Toast autohide delay={3000} show={showSaveToast} onClose={() => setShowSaveToast(false)}>
					<Toast.Header>
						<strong className='me-auto text-primary'>
              <span className='mr-2'>
                <FaCheck/>
              </span>
							{message}
						</strong>
					</Toast.Header>
				</Toast>
			</ToastContainer>
		);
	}

	async function fetchData() {
		try {

			console.log(externalId);

			const {data: investmentConfirmationIn} = await getInvestmentConfirmationByConsultingRequest(externalId);
			setInvestmentConfirmation(investmentConfirmationIn);


			setFormikInitialValues(getFormikInitialValues(investmentConfirmationIn));


			let entityHumanResourceParamsForNonEntityUsers = {
				entityNif: investmentConfirmationIn.identificationEa.entity.nif,
				all:investmentConfirmationIn.processState !== ProcessState.DRAFT
			};


			let [
				{data: entityGetList},
				{data: allowActionsIn}
			] = await Promise.all([
				isEntityUser()
					? await getEntityGetList({all:investmentConfirmationIn.processState !== ProcessState.DRAFT})
					: await getEntityGetList(entityHumanResourceParamsForNonEntityUsers),
				getAllowActionsInvestmentConfirmationByConsultingRequest(externalId)


			]);
			setGetList(entityGetList);
			setAllowActions(allowActionsIn)

			/*let { data: candidatureExternalId } =
				await getUserCandidatureExternalId();
			let params = { candidatureExternalId: candidatureExternalId };
			let { data: associatedPriorEntityContacts } =
				await getPriorEntityContacts(params);
			setPriorEntityContacts(associatedPriorEntityContacts);*/
		} catch (error) {
			setError(error);
		} finally {
			setLoading(false);
		}
	}







	function getFormikInitialValues(investmentConfirmation) {
		let initialValues = investmentConfirmation;
		CompanyIdentificationFormUtils.getFormikInitialValuesCompanyIdentification(
			initialValues,
			true
		);
		FinancialModalityFormUtils.getFormikInitialValuesFinancialModality(initialValues);
		initialValues.externalId = investmentConfirmation.externalId;
		initialValues.reasonReservations = investmentConfirmation.reasonReservations;
			initialValues.identificationEa = investmentConfirmation.identificationEa;
			initialValues.company = investmentConfirmation.company;
			initialValues.processState = investmentConfirmation.processState;
			initialValues.projectApproval= {};
			initialValues.projectApproval.projectApprovalDate = investmentConfirmation.projectApprovalDate;
		initialValues.projectApproval.candidatureProcessNumber = investmentConfirmation.candidatureProcessNumber;
		initialValues.observations=investmentConfirmation.observations;



		console.log(initialValues)
		return initialValues;

	}



	const validationSchema = yup.object().shape({
		identificationEa: yup.object().shape({
			technicalTeamManagerExternalId: yup
				.string()
				.test(
					'isGETValid',
					<FormattedMessage id='errors.finalReport.GET'/>,
					(value) => value !== '-1'
				),
		}),
		company: CompanyIdentificationFormUtils.validationObject(true),
		financialModality: FinancialModalityFormUtils.validationObject(),
		observations: yup
			.string()
			.required(<FormattedMessage id='errors.fieldRequiredText' />),
	});





	function scrollPageToTop() {
		document.body.scrollTop = 0;
		document.documentElement.scrollTop = 0;
	}

	function handlePreviousStep() {
		scrollPageToTop();
		setFormStep(formStep - 1);
		handleSave(true);
	}

	function handleNextStep() {
		scrollPageToTop();
		setFormStep(formStep + 1);
		handleSave(true);
	}


	useEffect(() => {
		fetchData();
	}, []);


	if (submited) {
		sessionStorage.setItem(
			'investmentConfirmation',
			submited
		);
		return navigate('/pedidosMCE/' + externalId, {
			state: {refresh: true}
		});
	}


	if (accept) {
		sessionStorage.setItem(
			'investmentConfirmationAccept',
			accept
		);
		return navigate('/pedidosMCE/' + externalId, {
			state: {refresh: true}
		});
	}


	if (reject) {
		sessionStorage.setItem(
			'investmentConfirmationtReject',
			reject
		);
		return navigate('/pedidosMCE/' + externalId, {
			state: {refresh: true}
		});
	}






	if (loading) {
		return <Loading />;
	}

	if (error && isNotBusinessError(error)) {
		return handleError(error);
	}



	async function handleSave(switchStep) {
		if (!isEntityUser() || (isEntityUser() && formikPropsRef.current.values.processState !== ProcessState.DRAFT))
			return;

		let toSend = objectToSend();

		if (switchStep) {
			toSend.step = formStep;
		}
		let {data: res} = await saveInvestmentConfirmationByConsultingRequest(externalId, toSend);
		setShowSaveToast(true)
	}


	function objectToSend() {
		const values = formikPropsRef.current.values;
		let objectToSendInt = {
			externalId: values.externalId,
			identificationEa: values.identificationEa,
			company: values.company,
			projectApprovalDate: values.projectApproval.projectApprovalDate,
			candidatureProcessNumber: values.projectApproval.candidatureProcessNumber,
			financialModality: values.financialModality,
			realIntangibleComputerPrograms: values.realIntangibleComputerPrograms,
			realIntangibleDevelopmentProjects: values.realIntangibleDevelopmentProjects,
			realIntangibleGoodwill: values.realIntangibleGoodwill,
			realIntangibleIntellectualProperty: values.realIntangibleIntellectualProperty,
			realIntangibleOthers: values.realIntangibleOthers,
			realInvestmentPropertiesBuildings: values.realInvestmentPropertiesBuildings,
			realInvestmentPropertiesNaturalResources: values.realInvestmentPropertiesNaturalResources,
			realInvestmentPropertiesOthers: values.realInvestmentPropertiesOthers,
			realPettyCash: values.realPettyCash,
			realSeveral: values.realSeveral,
			realTangibleAdministrativeEquipment: values.realTangibleAdministrativeEquipment,
			realTangibleBasicEquipment: values.realTangibleBasicEquipment,
			realTangibleBiologicEquipment: values.realTangibleBiologicEquipment,
			realTangibleBuildings: values.realTangibleBuildings,
			realTangibleNaturalResources: values.realTangibleNaturalResources,
			realTangibleOthers: values.realTangibleOthers,
			realTangibleTransportEquipment: values.realTangibleTransportEquipment,
			observations: values.observations
		}


		return objectToSendInt;
	}


	async function validateForm() {
		let errors = new Set();

		console.log(formikPropsRef.current.values);
		const formikErrors = await formikPropsRef.current.validateForm();
		console.log(formikErrors)




		Object.keys(formikErrors).forEach((key) => {
			switch (key) {
				case 'identificationEa':
					isEntityUser() && errors.add(0);
					break;
				case 'company':
					isEntityUser() && errors.add(1);
					break;
				case 'financialModality':
					isEntityUser() && errors.add(2);
					break;
				case 'observations':
					isEntityUser() && errors.add(4);
					break;

			}
			if(key.startsWith('real')){
				isEntityUser() && errors.add(4);
			}
		});

		let values = formikPropsRef.current.values;

		let errorMessages = [];
		if(!FinancialModalityFormUtils.validateAtLeastOneOptionFromEmpreendeXXIMeasureIsSelected(values, intl, errorMessages)){
			isEntityUser() && errors.add(2);
		}




		if (errorMessages.length > 0) {
			let message =
				errorMessages.length === 1
					? createCustomErrorMessage(errorMessages[0])
					: createCustomErrorMessage(
					'<p>' +
					intl.formatMessage({
						id: 'errors.more',
					}) +
					'</p><p> - ' +
					errorMessages.join('</p><p> - ') +
					'</p>'
					);

			setError(message);
		}


		return errors;
	}

	async function submitDecision(reason) {
		try {
			let data = {
				reason: reason
			};


			await submitInvestmentConfirmationReportDecision(
				externalId,
				data
			);
			if (reason === '') {
				setAccept(true);
			} else {
				setReject(true);
			}
		} catch (error) {
			setError(error);
		}
	}



	async function submitAccept() {
		await submitDecision('');
	}


	async function submitReject(reason) {
		setShowReject(false);
		await submitDecision(reason);
	}



	async function formValidationHandler(setShowModal) {
		let errors = await validateForm();
		setNavErrors(Array.from(errors));
		if (errors.size === 0) {
			setShowModal(true);
		}
	}




	async function handleSubmit(user, password, setModalError) {
		let toSend = objectToSend();

		toSend.credentials = {
			user: user,
			password: password,
		};

		let queryParams = {
			submit: true,
		};

		try {
			await saveInvestmentConfirmationByConsultingRequest(externalId, toSend, queryParams);
			setSubmited(true);
		} catch (err) {
			if (err.response.data.exception === 'ValidationException') {
				let errors = error.response.data.message
					.split(',')
					.map((value) => Number.parseInt(value));
				setNavErrors(errors);
			} else {
				setModalError(err);
			}
		}


		console.log('Send form for submission');
	}

	function renderStep(formikProps) {
		formikPropsRef.current = formikProps;

		const readMode = !(
			isEntityUser() && formikProps.values.processState === ProcessState.DRAFT
		);

		switch (formStep) {
			case 0:
				return (
					<SemestralReportFormStep1
						formStep={1}
						numberOfSteps={tabsIds.length}
						errors={formikProps.errors}
						formikValues={formikProps.values}
						handleChange={formikProps.handleChange}
						handleNextStep={handleNextStep}
						entityGetList={getList}
						goBackPath={'/pedidosMCE/' + externalId}
						readMode={readMode}
					/>
				);
			case 1:
				return (
					<SemestralReportFormStep2
						formStep={2}
						numberOfSteps={tabsIds.length}
						errors={formikProps.errors}
						formikValues={formikProps.values}
						handleChange={formikProps.handleChange}
						handlePreviousStep={handlePreviousStep}
						handleNextStep={handleNextStep}
						readMode={readMode}
					/>
				);
			case 2:
				return (
					<FinancialModalityStep
						step={3}
						numberOfSteps={tabsIds.length}
						handleGoBack={handlePreviousStep}
						errors={formikProps.errors}
						formikValues={formikProps.values}
						handleChange={formikProps.handleChange}
						handleNextStep={handleNextStep}
						readMode={readMode}
					/>
				);
			case 3:
				return (
					<SemestralReportFormStep3
						formStep={4}
						numberOfSteps={tabsIds.length}
						errors={formikProps.errors}
						formikValues={formikProps.values}
						handleChange={formikProps.handleChange}
						handlePreviousStep={handlePreviousStep}
						handleNextStep={handleNextStep}
						setFieldValue={formikProps.setFieldValue}
						readMode={readMode}
					/>
				);
			case 4:
				return (
					<InvestmentConfirmationStep
						formStep={5}
						numberOfSteps={tabsIds.length}
						errors={formikProps.errors}
						formikValues={formikProps.values}
						handleChange={formikProps.handleChange}
						handlePreviousStep={handlePreviousStep}
						handleNextStep={handleNextStep}
						entityGetList={getList}
						readMode={readMode}
					/>
				);
			default:
				console.log('Something went wrong rendering the form step');
		}
	}

	return (
		<SubTemplate>
			<Container>
				<ConfirmationAuthenticationModal
					show={showSubmitModal}
					handleClose={() => setShowSubmitModal(false)}
					submitHandler={handleSubmit}
					idsPreffix={'investmentConfirmation.submitModal'}
					confirmButtonCompleteLabelId={'all.submitButtonText'}
				/>
				<DecisionDialog
					show={showAccept}
					handleConfirm={submitAccept}
					handleClose={() => {
						setShowAccept(false)
					}}
					titleId='decisionDialog.investmentConfirmation.accept.title'
					bodyId='decisionDialog.investmentConfirmation.accept.body'
					needReason={false}
				/>
				<DecisionDialog
					show={showReject}
					handleConfirm={submitReject}
					handleClose={() => {
						setShowReject(false)
					}}
					titleId='decisionDialog.investmentConfirmation.reject.title'
					bodyId='decisionDialog.investmentConfirmation.reject.body'
					needReason={true}
					reasonLabelId={'decisionDialog.investmentConfirmation.reject.reason'}
				/>
				<Row>
					<Col md={{ span: 8, offset: 2 }}>
						<h2 className='mb-1 text-secondary'>
							<FormattedMessage id='investmentConfirmationForm.title' />
						</h2>
						<p className='mb-5 text-secondary'>
							<FormattedMessage id='investmentConfirmationForm.subTitle' />
							<span
								className="badge rounded-pill bg-secondary fw-normal ms-2"><span
								className="text-uppercase fw-bold"><FormattedMessage
								id={`processState.${formikInitialValues.processState}`}
							/> </span>  {formikInitialValues.submissionDate !== undefined && formikInitialValues.submissionDate !== null && (<> · <FormattedMessage id={'submitted.process.at'} values={{date:formikInitialValues.submissionDate}}/>  </>) } {formikInitialValues.approvalDate !== undefined && formikInitialValues.approvalDate !== null && ProcessState.SUBMITTED!==formikInitialValues.processState && (<> · {ProcessState.APPROVED===formikInitialValues.processState ? <FormattedMessage id={'approvedPromoter2.process.at'} values={{date:formikInitialValues.approvalDate}}/>:<FormattedMessage id={'rejectedPromoter2.process.at'} values={{date:formikInitialValues.approvalDate}}/>}   </>) }  </span>
						</p>
					</Col>
				</Row>
				<Row className={'pb-3 text-center'}>
					{(formikInitialValues?.processState === ProcessState.DRAFT || formikInitialValues?.processState === ProcessState.APPROVED) &&
					<>
						{
							formikInitialValues?.reasonReservations?.length > 0 ? (
								<>
									<Col md='2'></Col>
									<Col md='8'>

										<h6><FormattedMessage id={'finalReport.reservations.legend'}/></h6>


										<TextAreaInputField
											name={'reasonReservations'}
											value={formikInitialValues.reasonReservations}
											maxLength={10000}
											disabled={true}
										/>
									</Col>


								</>

							) : (
								<>
									{formikInitialValues?.processState === ProcessState.APPROVED &&
									<h6><FormattedMessage id={'finalReport.noReservation.legend'}/></h6>
									}
								</>

							)
						}

					</>


					}


				</Row>
				<Row className='mb-5'>
					<Col md='2'>
						<FormSideNavBar
							tabsIdsArray={tabsIds}
							formStep={formStep}
							setFormStep={setFormStep}
							navErrors={navErrors}
							setNavErrors={setNavErrors}
						/>
					</Col>
					<Col md='8'>
						<AlertError error={error}/>
						{getSaveToastInformation(
							intl.formatMessage({id: 'investmentConfirmation.saveToastInformation'})
						)}
						<Formik
							initialValues={formikInitialValues}
							validateOnBlur={false}
							validateOnChange={false}
							validationSchema={validationSchema}
							onSubmit={handleSubmit}
						>
							{(formikProps) => (
								<Form onSubmit={formikProps.handleSubmit}>
									{renderStep(formikProps)}
								</Form>
							)}
						</Formik>
					</Col>
					<Col md='2'>
						{isEntityUser() && formikInitialValues?.processState === ProcessState.DRAFT &&
						<div>
							<button
								className='btn btn-outline-primary d-flex align-items-center justify-content-center mb-4 w-100'
								type='button'
								onClick={() => handleSave()}
							>
								<FaRegSave/>
								<FormattedMessage id='all.save'/>
							</button>
							<button
								className='btn btn-primary d-flex align-items-center justify-content-center w-100'
								type='button'
								onClick={() => formValidationHandler(setShowSubmitModal)}
							>
								<FaRegCheckCircle/>
								<FormattedMessage id='all.submitButtonText'/>
							</button>
						</div>
						}


						{allowActions.canValidate === true &&
						<>

							<button
								className='btn btn-outline-primary d-flex align-items-center justify-content-center mb-4 w-100'
								type='button'
								onClick={() => setShowAccept(true)}
							>
								<FormattedMessage id='finalReport.accept.button'/>
							</button>
							<button
								className='btn btn-primary d-flex align-items-center justify-content-center w-100'
								type='button'
								onClick={() => setShowReject(true)}
							>
								<FormattedMessage id='finalReport.reject.button'/>
							</button>
						</>

						}
					</Col>
				</Row>
			</Container>
		</SubTemplate>
	);
}
