import { FormattedMessage } from 'react-intl';
import * as yup from 'yup';

export const EconomicStatusRatioColumnFormUtils = {
	validationObject: () => validationObject(),
	getFormikInitialValuesEconomicStatusRatioColumns: (initialValues) =>
		getFormikInitialValuesEconomicStatusRatioColumns(initialValues),
};

function validationObject() {
	let validationObject = {
		salesGrossMargin: yup
			.number()
			.required(<FormattedMessage id='errors.fieldRequiredText' />),
		netResults: yup
			.number()
			.required(<FormattedMessage id='errors.fieldRequiredText' />),

		salesProfitability: yup
			.number()
			.required(<FormattedMessage id='errors.fieldRequiredText' />),
		generalLiquidity: yup
			.number()
			.required(<FormattedMessage id='errors.fieldRequiredText' />),
		solvency: yup
			.number()
			.required(<FormattedMessage id='errors.fieldRequiredText' />),
		financialAutonomy: yup
			.number()
			.required(<FormattedMessage id='errors.fieldRequiredText' />),
		paymentMeanTerm: yup
			.number()
			.required(<FormattedMessage id='errors.fieldRequiredText' />)
			.test(
				'isHigherOrEqualToZero',
				<FormattedMessage id='errors.numberHigherOrEqualToZero' />,
				(value) => value >= 0
			),

		receiptMeanTerm: yup
			.number()
			.required(<FormattedMessage id='errors.fieldRequiredText' />)
			.test(
				'isHigherOrEqualToZero',
				<FormattedMessage id='errors.numberHigherOrEqualToZero' />,
				(value) => value >= 0
			),
	};

	return yup.array().of(yup.object().shape(validationObject));
}

function getFormikInitialValuesEconomicStatusRatioColumns(initialValues) {
	initialValues.economicStatusRatios.forEach((ratio) => {
		ratio.salesGrossMargin ??= '';
		ratio.netResults ??= '';
		ratio.salesProfitability ??= '';
		ratio.generalLiquidity ??= '';
		ratio.solvency ??= '';
		ratio.financialAutonomy ??= '';
		ratio.paymentMeanTerm ??= '';
		ratio.paymentMeanTermType ??= '-1';
		ratio.receiptMeanTerm ??= '';
		ratio.receiptMeanTermType ??= '-1';
	});
}
