import { CandidatureChangeStateWithReason } from "../CandidatureChangeStateWithReason";
import { useParams } from "react-router-dom";
import { sendToDoubtsClarificationForOpinion } from "../../../rest/candidature";

export function CandidatureFlowSendToClarificationDoubtsForOpinion({ callbackHandler, candidatureState }) {
  const { externalId } = useParams();

  const sendCandidatureToPromoterForDoubtsClarificationHandler = (reasonData) => {
    callbackHandler(() => {
        return sendToDoubtsClarificationForOpinion(externalId, reasonData);
    });
  }

  return (
    <>
      <CandidatureChangeStateWithReason
        modalTitleId="candidature.title.sendToPromoterForDoubtsClarification"
        modalReasonTextId="candidature.message.sendToPromoterForDoubtsClarification"
        flowActionId="candidature.flow.sendToPromoterForDoubtsClarification"
        submitHandler={sendCandidatureToPromoterForDoubtsClarificationHandler}
      />
    </>
  );
}
