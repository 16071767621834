import React from 'react';
import {Col, Form, Row} from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import DecimalInputField from '../bootstrap/DecimalInputField';
import { TextAreaInputField } from '../bootstrap/TextAreaInputField';
import { TextInputField } from '../bootstrap/TextInputField';
import ToolTip from '../bootstrap/ToolTip';
import {Strings} from "../../utils/Strings";

export function EconomicViabilityFormStep7({
  handleChange,
  setFieldValue,
  errors,
  values,
  error,
  setError,
  numberOfSteps,
  handleSave,
  handleGoBack
}) {
  const intl = useIntl();

  const KINDS = [1, 2, 3];

  function getValueByKindAndFieldName(kind, fieldName) {
    return fieldName.startsWith('nA') ? values.sensitivityMarkerDetails.filter((smd) => smd.kind === kind)[0][fieldName]===true : values.sensitivityMarkerDetails.filter((smd) => smd.kind === kind)[0][fieldName];
  }

  function handleSensitivityMarkerChange(e, kind, fieldName) {
    values.sensitivityMarkerDetails.filter((smd) => smd.kind === kind)[0][fieldName] =
        fieldName.startsWith('nA')?   e.target.value=== 'true' : e.target.value;

    if(fieldName.startsWith('nA') && e.target.value=== 'true'){
      let result = fieldName.slice(2);
      let finalResult = result.charAt(0).toLowerCase() + result.slice(1);
      values.sensitivityMarkerDetails.filter((pmd) => pmd.kind === kind)[0][finalResult]= (finalResult==='comments' || finalResult==='scenario')?'':0;
    }

    setFieldValue('sensitivityMarkerDetails', [...values.sensitivityMarkerDetails]);
  }



  function getTableRowsByKind(kind) {
    return (


        <>
        <Row>
          <Col md={6}>
            <Row>
              <Col md={9}>
                <DecimalInputField
                    controlId={'sensitivityMarkerDetails.val'}
                    labelId={'economicViabilityFormStep6.val'}
                    name={'sensitivityMarkerDetails.val'}
                    handleChange={(e) => handleSensitivityMarkerChange(e, kind, 'val')}
                    value={getValueByKindAndFieldName(kind, 'val')}
                    placeHolder={0}
                    disabled={     getValueByKindAndFieldName(kind, 'nAVal')===true}
                    allowNegative={true}
                />
              </Col>
              <Col md={3}>
                <Form.Label className=''>
                  &nbsp;
                </Form.Label>
                <Form.Control
                    as='select'
                    name={'sensitivityMarkerDetails.nAVal'}
                    onChange={(e) => handleSensitivityMarkerChange(e, kind, 'nAVal')}
                    value={getValueByKindAndFieldName(kind, 'nAVal')}
                    className={'ps-1'}
                >

                  <option value='true'>{intl.formatMessage({ id: 'all.na' })}</option>
                  <option value='false'>{intl.formatMessage({ id: 'all.applicable' })}</option>
                </Form.Control>
              </Col>

            </Row>




          </Col>
          <Col md={6}>
            <Row>
              <Col md={9}>

                <DecimalInputField
                    labelId={'finalReportForm.step6.table.TIR'}
                    controlId={'sensitivityMarkerDetails.tir'}
                    name={'sensitivityMarkerDetails.tir'}
                    handleChange={(e) => handleSensitivityMarkerChange(e, kind, 'tir')}
                    value={getValueByKindAndFieldName(kind, 'tir')}
                    placeHolder={0}
                    disabled={     getValueByKindAndFieldName(kind, 'nATir')===true}
                    allowNegative={true}
                />
              </Col>
              <Col md={3}>
                <Form.Label className=''>
                  &nbsp;
                </Form.Label>
                <Form.Control
                    as='select'
                    name={'sensitivityMarkerDetails.nATir'}
                    onChange={(e) => handleSensitivityMarkerChange(e, kind, 'nATir')}
                    value={getValueByKindAndFieldName(kind, 'nATir')}
                    className={'ps-1'}
                >

                  <option value='true'>{intl.formatMessage({ id: 'all.na' })}</option>
                  <option value='false'>{intl.formatMessage({ id: 'all.applicable' })}</option>
                </Form.Control>
              </Col>

            </Row>


          </Col>
        </Row>
          <Row>
            <Col md={12}>

              <Row>
                <Col md={10}>
                  <TextAreaInputField
                      labelId={'economicViabilityFormStep6.scenario'+kind}
                      name={'sensitivityMarkerDetails.scenario'}
                      value={getValueByKindAndFieldName(kind, 'scenario')}
                      handleChange={(e) => handleSensitivityMarkerChange(e, kind, 'scenario')}
                      disabled={     getValueByKindAndFieldName(kind, 'nAScenario')===true}
                  />

                </Col>
                <Col md={2}>
                  <Form.Label className=''>
                    &nbsp;
                  </Form.Label>
                  <Form.Control
                      as='select'
                      name={'sensitivityMarkerDetails.nAScenario'}
                      onChange={(e) => handleSensitivityMarkerChange(e, kind, 'nAScenario')}
                      value={getValueByKindAndFieldName(kind, 'nAScenario')}
                      className={'ps-1'}
                  >

                    <option value='true'>{intl.formatMessage({ id: 'all.na' })}</option>
                    <option value='false'>{intl.formatMessage({ id: 'all.applicable' })}</option>
                  </Form.Control>
                </Col>

              </Row>




            </Col>
          </Row>
          <Row>
            <Col md={12}>


              <Row>
                <Col md={10}>


                  <TextAreaInputField
                      labelId={'economicViabilityFormStep6.comments'}
                      name={'sensitivityMarkerDetails.comments'}
                      handleChange={(e) => handleSensitivityMarkerChange(e, kind, 'comments')}
                      value={getValueByKindAndFieldName(kind, 'comments')}
                      disabled={     getValueByKindAndFieldName(kind, 'nAComments')===true}

                  />


                </Col>
                <Col md={2}>
                  <Form.Label className=''>
                    &nbsp;
                  </Form.Label>
                  <Form.Control
                      as='select'
                      name={'sensitivityMarkerDetails.nAComments'}
                      onChange={(e) => handleSensitivityMarkerChange(e, kind, 'nAComments')}
                      value={getValueByKindAndFieldName(kind, 'nAComments')}
                      className={'ps-1'}
                  >

                    <option value='true'>{intl.formatMessage({ id: 'all.na' })}</option>
                    <option value='false'>{intl.formatMessage({ id: 'all.applicable' })}</option>
                  </Form.Control>
                </Col>

              </Row>




            </Col>
          </Row>
          <hr className='mt-5'/>
      </>
    );
  }

  return (
    <div>
      <h4 className='text-primary mb-0'>
        <FormattedMessage id='economicViabilityForm.analysisMarkersTab2' />
      </h4>
      <small className='text-secondary text-uppercase d-block'>
        {intl.formatMessage(
          { id: 'all.step' },
          { step: values.step, numberOfSteps: numberOfSteps }
        )}
      </small>

      <div className='mt-4 mb-4'>
        <small className='text-primary text-uppercase'>
          <FormattedMessage id='economicViabilityFormStep5.sensitivityMarker.title' />
        </small>
      </div>

      {KINDS.map((kind) => (
          getTableRowsByKind(kind)
      ))}

      <Row>
        <Col md='12'>
          <small className='text-primary text-uppercase mt-5 mb-4 d-block'>
            <FormattedMessage id='economicViabilityFormStep5.consistencyAnalysis.title' />
            <ToolTip
              message={
                <FormattedMessage id='economicViabilityFormStep6.consistencyAnalysis.toolTip' />
              }
            />
          </small>
          <TextAreaInputField
            name={'analysisMarkersDetails.consistencyAnalysis'}
            handleChange={handleChange}
            value={values.analysisMarkersDetails.consistencyAnalysis}
            isInvalid={errors.analysisMarkersDetails?.consistencyAnalysis}
            errorMessage={errors.analysisMarkersDetails?.consistencyAnalysis}
          />
        </Col>
      </Row>
    </div>
  );
}
