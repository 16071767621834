import { Button, Row, Col, Form } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { TextAreaInputField } from '../bootstrap/TextAreaInputField';
import { TextInputField } from '../bootstrap/TextInputField';
import ToolTip from '../bootstrap/ToolTip';
import { maxFixed } from '../../utils/CurrencyUtils';
import { DateTimeInputField } from '../bootstrap/DateTimeInputField';
import { ProcessState } from '../../models/ProcessState';
import { IAS_MULTIPLIER_B5 } from '../../utils/ConstantsUtils';
import React from "react";

export function PriorSupportPaymentRequestStep3({
	formStep,
	numberOfSteps,
	errors,
	handleChange,
	formikValues,
	handlePreviousStep,
	handleNextStep,
	showNextStepButton,
	readMode,
	entityGetList,
	b5,
}) {
	const intl = useIntl();

	function calculateTotalAmount() {
		let total;
		if (b5) {
			total =
				formikValues.candidatures.length *
				formikValues.iasAtContractCelebration * IAS_MULTIPLIER_B5;
		} else {
			total = formikValues.iasAtContractCelebration;
		}
		return total;
	}

	return (
		<div>
			<h4 className='text-primary mb-0'>
				<FormattedMessage id='priorSupportPaymentRequest.step4.title' />
			</h4>
			<small className='text-secondary text-uppercase'>
				{intl.formatMessage(
					{ id: 'all.step' },
					{ step: formStep, numberOfSteps: numberOfSteps }
				)}
			</small>
			<fieldset disabled={readMode==true}>
				<Row>
					<Col md='4'>
						<Form.Group>
							<Form.Label>
								<FormattedMessage id='priorSupportPaymentRequest.step4.totalAmount' />
								<ToolTip
									message={
										<FormattedMessage
											id={
												b5
													? 'economicViabilityPaymentRequest.step3.totalAmount.tooltip'
													: 'priorSupportPaymentRequest.step4.totalAmount.placeholder'
											}
										/>
									}
								/>
							</Form.Label>
							<p> {maxFixed(calculateTotalAmount()) + ' €'}</p>
						</Form.Group>
					</Col>
					<Col md={b5 ? '6' : '4'}>
						<Form.Group>
							<Form.Label>
								<FormattedMessage
									id={
										b5
											? 'economicViabilityPaymentRequest.step3.iasAtAnalysisDispatch'
											: 'priorSupportPaymentRequest.step4.iasAtTheContractDate'
									}
								/>
							</Form.Label>
							<p> {maxFixed(formikValues.iasAtContractCelebration) + ' €'}</p>
						</Form.Group>
					</Col>
				</Row>
				<Row>
					<Col md='12'>
						<TextAreaInputField
							labelId='priorSupportPaymentRequest.step4.eaObservations'
							handleChange={handleChange}
							name='observationsEa'
							placeholder={intl.formatMessage(
								{
									id: 'annualComplementaryActivitiesPlanForm.characters.placeholder',
								},
								{ nChars: 1000 }
							)}
							maxLength={1000}
							value={formikValues.observationsEa}
							isInvalid={errors.observationsEa}
							errorMessage={errors.observationsEa}
						/>
					</Col>
				</Row>
				{formikValues.identificationEa.technicalTeamManagerExternalId !==
					'-1' && (
					<Row>
						<Col md='8'>
							<TextInputField
								labelId='priorSupportPaymentRequest.step4.technicalTeamManager'
								value={
									entityGetList.filter(
										(get) =>
											get.externalId ===
											formikValues.identificationEa
												.technicalTeamManagerExternalId
									)?.[0]?.name
								}
								disabled
							/>
						</Col>
						{(ProcessState.finalStates().includes(formikValues.processState) ||
							formikValues.processState === ProcessState.SUBMITTED) && (
							<Col md='4'>
								<DateTimeInputField
									disabled
									labelId='candidatureTabs.submissionDate'
									value={formikValues.submissionDate}
									isDateWithNoTime={true}
								/>
							</Col>
						)}
					</Row>
				)}
			</fieldset>
			<div className='form-actions mt-5'>
				<button
					className='btn btn-link'
					type='button'
					onClick={handlePreviousStep}
				>
					<FormattedMessage id='all.back' />
				</button>
				{showNextStepButton && (
					<button
						className='btn btn-primary'
						type='button'
						onClick={handleNextStep}
					>
						<FormattedMessage id='all.nextStep' />
					</button>
				)}
			</div>
		</div>
	);
}
