import {AlertError} from "../../../bootstrap/AlertError";
import {Formik} from "formik";
import {Col, Container, Form, Row} from "react-bootstrap";
import DecimalInputField from "../../../bootstrap/DecimalInputField";
import {FormattedMessage} from "react-intl";
import React, {useState} from "react";
import {useNavigate} from "react-router-dom";
import * as yup from "yup";
import {EnumSelectField} from "../../../bootstrap/EnumSelectField";
import {RevocationType} from "./RevocationType";
import {RestitutionType} from "./RestitutionType";
import {SingleUploadArea} from "../../../SingleUploadArea";
import {addNonCompliance} from "../../../../rest/nonCompliance";
import {DocumentType} from "../../../../models/DocumentType";


export function NonComplianceForm ({candidatureExternalId, nonComplianceCauseToRegulationMap, onCancel, onSubmitCallback}) {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [navErrors, setNavErrors] = useState([]);
    const navigate = useNavigate();
    const [documents, setDocuments] = useState([]);

    async function onSubmit(values, setSubmitting, setErrors) {
        setSubmitting(true);
        try {

            let {docNT: formDataNT, docSI: formDataSI, ...rest} = values;
            formDataSI.set('nonCompliance', JSON.stringify(rest));
            formDataSI.set('file2', formDataNT.get('file'));
            formDataSI.set('fileName2', formDataNT.get('fileName'));
            formDataSI.set('documentType2', formDataNT.get('documentType'));
            formDataSI.set('size2', formDataNT.get('size'));
            formDataSI.set('extension2', formDataNT.get('extension'));

            await addNonCompliance(candidatureExternalId, formDataSI);
            onSubmitCallback?.()
            setSubmitted(true);
        } catch (error) {
            console.log(error);
            setErrors(error);
        }
        setSubmitting(false);
    }

    const validationSchema = yup.object().shape({
        revocation: yup
            .mixed()
            .oneOf(RevocationType.values(), <FormattedMessage id='errors.fieldRequiredText'/>)
            .required(<FormattedMessage id='errors.fieldRequiredText'/>),
        restitution: yup
            .mixed()
            .oneOf(RestitutionType.values(), <FormattedMessage id='errors.fieldRequiredText'/>)
            .required(<FormattedMessage id='errors.fieldRequiredText'/>),
        restitutionValue: yup
            .number()
            .when('restitution', {
                is: (r) => (r === RestitutionType.PARTIAL || r === RestitutionType.TOTAL),
                then: (schema) => schema.typeError(<FormattedMessage id='errors.fieldRequiredText'/>)
                    .required(<FormattedMessage id='errors.fieldRequiredText'/>)
                    .min(0.01, <FormattedMessage id='candidature.payments.form.error.zeroPayment'/>),
                otherwise: (schema) => schema.nullable()
            }),
        causes: yup
            .array()
            .of(yup.string())
            .min(1, <FormattedMessage id='candidatureTabs.oversight.tabs.noncompliance.causes.error' />)
            .required(<FormattedMessage id='errors.fieldRequiredText'/>),

    });

    const initialValues = {
        revocation: null,
        restitution: null,
        restitutionValue: null,
        causes: [],
        docSI: null,
        docNT: null,
    };

    function validation (values, props) {
        const errors = {};

        if (values.docSI === null) errors.docSI = <FormattedMessage id='need.documents'/>;
        if (values.docNT === null) errors.docNT = <FormattedMessage id='need.documents'/>;
        return errors;
    }

    function changeCauses(target, cause,values,setFieldValue) {
        if(target.checked===true){
            values.causes.push(cause);
        }else{
            values.causes.splice(values.causes.indexOf(cause), 1)
        }
        setFieldValue('causes', values.causes);
    }



    return (
        <>
            <AlertError error={error} />
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                validateOnBlur={false}
                validate={validation}
                validateOnChange={false}
                onSubmit={(values, { setSubmitting, setErrors }) => {
                    onSubmit(values, setSubmitting, setErrors);
                }}>
                {({ values,
                      errors,
                      touched,
                      handleChange,
                      handleSubmit,
                      setFieldValue
                  }) => (
                    <Form onSubmit={handleSubmit}>
                        <AlertError error={errors} />
                        <Row>
                            <Col>
                                <EnumSelectField
                                    name='revocation'
                                    labelId='candidatureTabs.oversight.tabs.noncompliance.form.leadsToRevogation'
                                    options={RevocationType.values()}
                                    preffixDescriptionId='candidatureTabs.oversight.tabs.noncompliance.form.revVal'
                                    nullOption={true}
                                    errorMessage={errors.revocation}
                                    handleChange={(e) => setFieldValue('revocation', e.target.value)}
                                />
                            </Col>
                            <Col>
                                <EnumSelectField
                                    name='restitution'
                                    labelId='candidatureTabs.oversight.tabs.noncompliance.form.aidRestitution'
                                    options={RestitutionType.values()}
                                    preffixDescriptionId='candidatureTabs.oversight.tabs.noncompliance.form.restVal'
                                    nullOption={true}
                                    errorMessage={errors.restitution}
                                    handleChange={(e) => setFieldValue('restitution', e.target.value)}
                                />
                            </Col>
                            <Col>
                                { (values?.restitution === RestitutionType.PARTIAL || values?.restitution === RestitutionType.TOTAL) && (
                                <DecimalInputField
                                    name='restitutionValue'
                                    labelId='candidatureTabs.oversight.tabs.noncompliance.form.restitutionValue'
                                    suffixLabel='€'
                                    errorMessage={errors.restitutionValue}
                                    handleChange={(e) => setFieldValue('restitutionValue', e.target.value)}
                                />
                                )}
                            </Col>
                        </Row>


                        <Row className='mt-3'>
                            <Col>
                                <label>
                                    <FormattedMessage id='candidatureTabs.oversight.tabs.noncompliances' />
                                </label>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                {Object.keys(nonComplianceCauseToRegulationMap).map( (cause) => (
                                    <Form.Group key={cause} controlId={"nonComplianceCauses"+cause}>
                                        <Form.Check
                                            inline
                                            name={cause}
                                            type={'checkbox'}
                                            className={'d-flex align-items-center'}
                                            label={<FormattedMessage id={'candidatureTabs.oversight.tabs.noncompliance.cause.' + cause} />}
                                                checked={values.causes.includes(cause)}
                                                onChange={({target}) => changeCauses(target,cause,values,setFieldValue)

                                            }
                                        />
                                    </Form.Group>
                                ))}
                                <Form.Control.Feedback type='invalid'>
                                    {errors.causes}
                                </Form.Control.Feedback>
                            </Col>
                        </Row>
                        <Row className='mt-3'>
                            <Col>
                                <label>
                                    <FormattedMessage id='documents.list' />
                                </label>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <SingleUploadArea
                                    documents={documents}
                                    setDocuments={setDocuments}
                                    documentType={DocumentType.NON_COMP_SERVICE_INFO}
                                    setError={setError}
                                    acceptExtensions='.pdf,.docx'
                                    processUploadCallback={formData => setFieldValue('docSI', formData)}
                                />
                                {"docSI" in errors &&
                                    <Form.Control.Feedback type='invalid'>{errors.docSI}</Form.Control.Feedback>
                                }
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <SingleUploadArea
                                    documents={documents}
                                    setDocuments={setDocuments}
                                    documentType={DocumentType.NON_COMP_NOTIFICATION}
                                    setError={setError}
                                    acceptExtensions='.pdf,.docx'
                                    processUploadCallback={formData => setFieldValue('docNT', formData)}
                                />
                                {"docNT" in errors &&
                                    <Form.Control.Feedback type='invalid'>{errors.docNT}</Form.Control.Feedback>
                                }
                            </Col>
                        </Row>

                        <Row className='form-actions'>
                            <Col className='justify-content-between d-flex'>
                                <button className='btn btn-outline-primary' type='button' onClick={() => onCancel?.()}>
                                    <FormattedMessage id='all.cancel' />
                                </button>
                                <button className='btn btn-primary' type='submit' >
                                    <FormattedMessage id='all.submitButtonText' />
                                </button>
                            </Col>
                        </Row>
                    </Form>
                )}
            </Formik>
        </>
    );
}
