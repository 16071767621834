import {Accordion, Button, Container, Row, Tab, Tabs} from "react-bootstrap";
import {FormattedMessage} from "react-intl";
import React, {useEffect, useState} from "react";
import {IrregularitiesTab} from "./tabs/IrregularitiesTab";
import {useParams} from "react-router-dom";
import {getCandidatureByExternalId} from "../../rest/candidature";
import {getPayments} from "../../rest/candidaturePayments";
import Loading from "../general/Loading";
import {SubTemplate} from "../general/SubTemplate";
import {NonComplianceTab} from "./tabs/NonComplianceTab";
import {getUserInfo, hasPermission, isIEFPUser} from "../../authentication/authenticationHelper";
import {CandidatureState} from "../../models/CandidatureState";
import {ListCandidatureChanges} from "../../pages/public/candidatureChange/ListCandidatureChanges";
import {AlertSuccess} from "../bootstrap/AlertSuccess";
import {useGlobalContext} from "../../context";
import {CandidaturePayments} from "../../pages/backoffice/iefp/CandidaturePayments";

export function Oversight() {
    const { externalId ,step} = useParams();
    const [candidature, setCandidature] = useState();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const userInfo = getUserInfo();
    const [lsItem, setLsItem] = useState(null);
    const { accessInfo } = useGlobalContext();

    const fetchData = async () => {
        try {
            checkStorageItems();
            const [{ data: candidature }] = await Promise.all([getCandidatureByExternalId(externalId)]);
            setCandidature(candidature);
        } catch (error) {
            setError(error);
        } finally {
            setLoading(false);
        }
    }



    function checkStorageItems() {
        if (sessionStorage.getItem('candidatureChangeRequestWithdrawed')) {
            setLsItem('candidatureChangeRequestWithdrawed');
        }
    }


    const isMainPromoter = () => {
        return candidature?.mainPromoter === true;
    };

    useEffect(() => {
        fetchData();
    }, []);

    if (loading) {
        return <Loading />;
    }

    return (
        <SubTemplate titleId={'candidatureTabs.oversight'} hasBackButton>
            <Container>
                <div className='mx-2 mb-4'>
                    <AlertSuccess lsItem={lsItem} />
                </div>
                <Tabs  defaultActiveKey={[step===undefined ?  'irregularities': step]} justify>
                    <Tab
                        eventKey='irregularities'
                        title={<FormattedMessage id='candidatureTabs.oversight.tabs.irregularities' />}
                        className='mb-4 p-3 border'>
                        <IrregularitiesTab candidature={candidature} />
                    </Tab>

                    <Tab
                        eventKey='nonCompliance'
                        title={<FormattedMessage id='candidatureTabs.oversight.tabs.noncompliances' />}
                        className='mb-4 p-3 border'>
                        <NonComplianceTab candidature={candidature} />
                    </Tab>
                    {(isIEFPUser() && [CandidatureState.FULLY_APPROVED, CandidatureState.REVOKED_NON_COMPLIANCE].includes(candidature.state) && candidature.regionalDelegationCode === userInfo?.rd) &&
                    hasPermission('REGISTER_CANDIDATURE_PAYMENT', accessInfo) && (
                        <Tab eventKey='payments' className='mb-4 p-3 border'
                             title={<FormattedMessage id='candidature.button.payments'/>}>
                            <CandidaturePayments candidature={candidature}/>
                        </Tab>
                    )}


                    {(((isIEFPUser() && candidature.regionalDelegationCode === userInfo?.rd) || isMainPromoter()) &&   candidature && CandidatureState.FULLY_APPROVED === candidature.state) &&
                    <Tab eventKey='changes' className='mb-4 p-3 border'
                         title={<FormattedMessage id='candidatureTabs.oversight.tabs.changes'/>}>
                        <ListCandidatureChanges candidature={candidature}/>
                    </Tab>
                    }

                </Tabs>
            </Container>
        </SubTemplate>
    );
}