export const RepaymentPlanModality = {
  FORESEEN_DEFERRAL_PERIOD_USE: 'FORESEEN_DEFERRAL_PERIOD_USE',
  REIMBURSEMENT_PERIOD_CONVERSION: 'REIMBURSEMENT_PERIOD_CONVERSION',
  AMORTIZATION_PROPOSAL: 'AMORTIZATION_PROPOSAL',

  values: () => {
    return [
      'FORESEEN_DEFERRAL_PERIOD_USE',
      'REIMBURSEMENT_PERIOD_CONVERSION',
      'AMORTIZATION_PROPOSAL',
    ];
  },
};