
export const MappingType = {
    CREATION: 'CREATION',
    FOLLOW_UP: 'FOLLOW_UP',
    CONSULTING: 'CONSULTING',
    EXPAND_SKILLS: 'EXPAND_SKILLS',
    ANALYSIS: 'ANALYSIS',

    values: () => {
        return ['CREATION', 'FOLLOW_UP', 'CONSULTING', 'EXPAND_SKILLS', 'ANALYSIS'];
    }
}
