import React, { useEffect, useState } from 'react';
import { Alert, Button } from 'react-bootstrap';
import { FaRegCheckCircle } from 'react-icons/fa';
import { FormattedMessage, useIntl } from 'react-intl';
import { ScrollToTop } from '../ScrollToTop';

export function AlertSuccess({ header, message, lsItem }) {
  const intl = useIntl();
  const [messageObject, setMessageObject] = useState({ show: false });

  useEffect(() => {
    setMessageObject(getMessageObjectValue());
  }, [header, message,lsItem]);

  if (!messageObject.show || !messageObject.message) {
    return <></>;
  }

  function getMessageObjectValue() {
    let messageObjectValue = { show: true, header: header, message: message };

    if (lsItem) {
      messageObjectValue.header = intl.formatMessage({
        id: `alert.header.${lsItem}`,
      });
      messageObjectValue.message = intl.formatMessage({
        id: `alert.message.${lsItem}`,
      });
    }
    return messageObjectValue;
  }

  function handleClose() {
    if (lsItem) {
      sessionStorage.removeItem(lsItem);
    }
    setMessageObject({
      show: false,
    });
  }

  return (
    <>
      <ScrollToTop />
      <Alert
        dismissible
        className='text-center'
        onClose={() => handleClose()}
        variant='success'
      >
        <Alert.Heading>
          <div className='mt-2 mb-3'>
            <FaRegCheckCircle />
          </div>
          {messageObject.header}
        </Alert.Heading>
        <p>{messageObject.message}</p>
        <div className='d-flex justify-content-center'>
          <Button onClick={() => handleClose()} variant='outline-success'>
            <FormattedMessage id='all.close' />
          </Button>
        </div>
      </Alert>
    </>
  );
}
