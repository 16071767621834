import React, {useEffect, useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import Loading from '../../../components/general/Loading';
import { FaSearch } from 'react-icons/fa';
import {SubTemplate} from '../../../components/general/SubTemplate';
import {handleError, isNotBusinessError} from '../../../utils/handleError';
import {
    getIndicatorAgeCounter,
    getIndicatorCaes,
    getIndicatorCandidaturesAdCounter,
    getIndicatorCandidaturesAgesAdCounter,
    getIndicatorCandidaturesGenderAdCounter,
    getIndicatorCandidaturesQualificationLevelsAdCounter,
    getIndicatorCandidaturesSchoolQualificationAdCounter,
    getIndicatorCandidaturesStatesAdCounter,
    getIndicatorConsulting,
    getIndicatorGenderCounter,
    getIndicatorInvestments,
    getIndicatorOpinionSupport,
    getIndicatorPayments,
    getIndicatorPriorSupport,
    getIndicatorQualificationLevelsCounter,
    getIndicatorRegionalDelegationGroupsCounter,
    getIndicatorRestitutions,
    getIndicatorSchoolQualificationsCounter,
    getIndicatorStatesCounter,
    getIndicatorWorkStations
} from "../../../rest/indicator";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {Form} from "react-bootstrap";
import {getCountiesByDistrictCode, getDistricts} from "../../../rest/administrativeDivision";
import IntInputField from "../../../components/bootstrap/IntInputField";
import {CustomPagination} from "../../../components/general/CustomPagination";
import {EnumSelectField} from "../../../components/bootstrap/EnumSelectField";
import {TextInputField} from "../../../components/bootstrap/TextInputField";
import {currencyFormat} from "../../../utils/CurrencyUtils";
import {AuthenticatedDownloadLink} from "../../../components/document/AuthenticatedDownloadLink";
import {Arrays} from "../../../utils/Arrays";

const MAX_RESULTS = 10;
const DEFAULT_INITIAL_PAGE = 0;

export function Indicators() {
    const intl = useIntl();


    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [indicatorsStates, setIndicatorsStates] = useState(null)
    const [indicatorsGenders, setIndicatorsGenders] = useState(null)
    const [indicatorsAges, setIndicatorsAges] = useState(null)
    const [indicatorsSchool, setIndicatorsSchool] = useState(null)
    const [indicatorsQualificationLevels, setIndicatorsQualificationLevels] = useState(null)
    const [districts, setDistricts] = useState(null);
    const [districtCode, setDistrictCode] = useState(null);
    const [counties, setCounties] = useState(null)
    const [countyCode, setCountyCode] = useState(null);
    const [indicatorsCandidatureADCounter, setIndicatorsCandidatureADCounter] = useState(null)
    const [indicatorsCandidatureStatesADCounter, setIndicatorsCandidatureStatesADCounter] = useState(null)
    const [indicatorsCandidatureGenderADCounter, setIndicatorsCandidatureGenderADCounter] = useState(null)
    const [indicatorsCandidatureAgeADCounter, setIndicatorsCandidatureAgeADCounter] = useState(null)
    const [indicatorsCandidatureSchoolQualificationADCounter, setIndicatorsCandidatureSchoolQualificationADCounter] = useState(null)
    const [indicatorsCandidatureQualificationLevelsADCounter, setIndicatorsCandidatureQualificationLevelsADCounter] = useState(null)
    const [indicatorGroupRegionalDelegation,setIndicatorGroupRegionalDelegation] = useState(null)
    const [numberOfResults, setNumberOfResults] = useState(null);
    const [totalResults, setTotalResults] = useState(null);
    const [indicatorByCae, setIndicatorByCae] = useState(null);
    const [currentPage, setCurrentPage] = useState(DEFAULT_INITIAL_PAGE);
    const [cae, setCae] = useState(null);
    const [caeIn, setCaeIn] = useState(null);


    //EA filter

    const [nif, setNif] = useState(null);
    const [nifIn, setNifIn] = useState(null);


    const [regionalDelegationCode, setRegionalDelegationCode] = useState(null);
    const [regionalDelegationCodeIn, setRegionalDelegationCodeIn] = useState('-1');



    //PriorSupport
    const [numberOfResultsPriorSupport, setNumberOfResultsPriorSupport] = useState(null);
    const [totalResultsPriorSupport, setTotalResultsPriorSupport] = useState(null);
    const [currentPagePriorSupport, setCurrentPagePriorSupport] = useState(DEFAULT_INITIAL_PAGE);

    const [indicatorPriorSupport, setIndicatorPriorSupport] = useState(null);



    //OpinionSupport
    const [numberOfResultsOpinionSupport, setNumberOfResultsOpinionSupport] = useState(null);
    const [totalResultsOpinionSupport, setTotalResultsOpinionSupport] = useState(null);
    const [currentPageOpinionSupport, setCurrentPageOpinionSupport] = useState(DEFAULT_INITIAL_PAGE);

    const [indicatorOpinionSupport, setIndicatorOpinionSupport] = useState(null);


    //ConsultingRequest
    const [numberOfResultsConsultingRequest, setNumberOfResultsConsultingRequest] = useState(null);
    const [totalResultsConsultingRequest, setTotalResultsConsultingRequest] = useState(null);
    const [currentPageConsultingRequest, setCurrentPageConsultingRequest] = useState(DEFAULT_INITIAL_PAGE);

    const [indicatorConsultingRequest, setIndicatorConsultingRequest] = useState(null);

    //Workstations
    const [indicatorWorkStations, setIndicatorWorkStations] = useState(null);

    //Investments
    const [indicatorInvestments, setIndicatorInvestments] = useState(null);

    const [indicatorPayments, setIndicatorPayments] = useState(null);


    const [indicatorRestitutions, setIndicatorRestitutions] = useState(null);



    async function handleChangeCae(e) {
        setCaeIn(e.target.value==''?null:e.target.value);
    }


    async function handleChangeRegionalDelegation(e) {
        setRegionalDelegationCodeIn(e.target.value);
    }

    async function handleChangeNif(e) {
        setNifIn(e.target.value);
    }



    async function handleSearch() {
        setCurrentPage(0)
        setCae(caeIn==='0' ? null : caeIn);

    }


    async function handleSearchEa() {
        setRegionalDelegationCode(regionalDelegationCodeIn ==='-1'? null: regionalDelegationCodeIn)
        setNif(nifIn==='' ? null : nifIn);
        setCurrentPagePriorSupport(0)
        setCurrentPageOpinionSupport(0)
    }


    async function handleDistrictChange(e) {
        let code = e.target.value;
        if (e.target.value === '-1') {
            code = null;
        }
        setDistrictCode(code)
        setCountyCode(null)
        try {
            const [{data: countiesIn}, {data: indicatorCandidaturesAdIn}, {data: indicatorCandidaturesStateAdIn}, {data: indicatorCandidaturesGenderAdIn}, {data: indicatorCandidaturesAgeAdIn},
                {data: indicatorCandidaturesSQualificationAdIn}, {data: indicatorCandidaturesQualificationLevelsAdIn}]
                = await Promise.all([await getCountiesByDistrictCode(districtCode), await getIndicatorCandidaturesAdCounter(code), await getIndicatorCandidaturesStatesAdCounter(code),
                await getIndicatorCandidaturesGenderAdCounter(code),await getIndicatorCandidaturesAgesAdCounter(code),await getIndicatorCandidaturesSchoolQualificationAdCounter(code),await getIndicatorCandidaturesQualificationLevelsAdCounter(code)]);
            setCounties(countiesIn);
            setIndicatorsCandidatureADCounter(indicatorCandidaturesAdIn);
            setIndicatorsCandidatureStatesADCounter(indicatorCandidaturesStateAdIn)
            setIndicatorsCandidatureGenderADCounter(indicatorCandidaturesGenderAdIn)
            setIndicatorsCandidatureAgeADCounter(indicatorCandidaturesAgeAdIn)
            setIndicatorsCandidatureSchoolQualificationADCounter(indicatorCandidaturesSQualificationAdIn)
            setIndicatorsCandidatureQualificationLevelsADCounter(indicatorCandidaturesQualificationLevelsAdIn)
            setCountyCode(null);
            console.log(countiesIn)
        } catch (error) {
            setError(error);
        }
    }


    async function handleCountyChange(e) {
        let code = e.target.value;
        if (e.target.value === '-1') {
            code = null;
        }
        setCountyCode(code)
        try {
            const [{data: indicatorCandidaturesAdIn}, {data: indicatorCandidaturesStateAdIn}, {data: indicatorCandidaturesGenderAdIn}, {data: indicatorCandidaturesAgeAdIn}, {data: indicatorCandidaturesSQualificationAdIn}, {data: indicatorCandidaturesQualificationLevelsAdIn}]
                = await Promise.all([await getIndicatorCandidaturesAdCounter(code), await getIndicatorCandidaturesStatesAdCounter(code), await getIndicatorCandidaturesGenderAdCounter(code),await getIndicatorCandidaturesAgesAdCounter(code)
                ,await getIndicatorCandidaturesSchoolQualificationAdCounter(code),await getIndicatorCandidaturesQualificationLevelsAdCounter(code)]);
            setIndicatorsCandidatureADCounter(indicatorCandidaturesAdIn);
            setIndicatorsCandidatureStatesADCounter(indicatorCandidaturesStateAdIn)
            setIndicatorsCandidatureGenderADCounter(indicatorCandidaturesGenderAdIn)
            setIndicatorsCandidatureAgeADCounter(indicatorCandidaturesAgeAdIn)
            setIndicatorsCandidatureSchoolQualificationADCounter(indicatorCandidaturesSQualificationAdIn)
            setIndicatorsCandidatureQualificationLevelsADCounter(indicatorCandidaturesQualificationLevelsAdIn)
        } catch (error) {
            setError(error);
        }
    }


    const fetchData = async () => {
        setError(null);
        try {
            let code = null;
            if (countyCode) {
                code = countyCode;
            } else if (districtCode) {
                code = districtCode;
            }
            const [{data: indicatorsStatesIn}, {data: indicatorsGenderIn}, {data: indicatorsAgeIn}, {data: indicatorSchoolIn}, {data: indicatorQualificationIn},
                {data: indicatorCandidaturesAdIn}, {data: indicatorCandidaturesStateAdIn}, {data: indicatorCandidaturesGenderAdIn}, {data: indicatorCandidaturesAgeAdIn},
                {data: indicatorCandidaturesSQualificationAdIn}, {data: indicatorCandidaturesQualificationLevelsAdIn} ,{data: districtsIn},{data: indicatorGroupRegionalDelegationIn}
                ,{data: indicatorCaeIn},{data: indicatorPriorSupportIn},{data: indicatorOpinionSupportIn},{data: indicatorConsultingRequestIn},{data:workstationsIn}, {data:investmentsIn},  {data:paymentsIn},{data:restitutionsIn} ] = await Promise.all([
                await getIndicatorStatesCounter(),
                await getIndicatorGenderCounter(),
                await getIndicatorAgeCounter(),
                await getIndicatorSchoolQualificationsCounter(),
                await getIndicatorQualificationLevelsCounter(),
                await getIndicatorCandidaturesAdCounter(code),
                await getIndicatorCandidaturesStatesAdCounter(code),
                await getIndicatorCandidaturesGenderAdCounter(code),
                await getIndicatorCandidaturesAgesAdCounter(code),
                await getIndicatorCandidaturesSchoolQualificationAdCounter(code),
                await getIndicatorCandidaturesQualificationLevelsAdCounter(code),
                await getDistricts(),
                await     getIndicatorRegionalDelegationGroupsCounter(),
                await getIndicatorCaes(currentPage,MAX_RESULTS,cae),
                await getIndicatorPriorSupport(currentPagePriorSupport,MAX_RESULTS,nif,regionalDelegationCode),
                await getIndicatorOpinionSupport(currentPageOpinionSupport,MAX_RESULTS,nif,regionalDelegationCode),
                await getIndicatorConsulting(currentPageConsultingRequest,MAX_RESULTS,nif,regionalDelegationCode),
                await getIndicatorWorkStations(),
                await getIndicatorInvestments(),
                await getIndicatorPayments(),
                await getIndicatorRestitutions()
            ]);




            setIndicatorsStates(indicatorsStatesIn)
            setIndicatorsGenders(indicatorsGenderIn)
            setIndicatorsAges(indicatorsAgeIn)
            setIndicatorsSchool(indicatorSchoolIn)
            setIndicatorsQualificationLevels(indicatorQualificationIn)
            setIndicatorsCandidatureADCounter(indicatorCandidaturesAdIn)
            setIndicatorsCandidatureStatesADCounter(indicatorCandidaturesStateAdIn)
            setIndicatorsCandidatureGenderADCounter(indicatorCandidaturesGenderAdIn)
            setIndicatorsCandidatureAgeADCounter(indicatorCandidaturesAgeAdIn)
            setIndicatorsCandidatureSchoolQualificationADCounter(indicatorCandidaturesSQualificationAdIn)
            setIndicatorsCandidatureQualificationLevelsADCounter(indicatorCandidaturesQualificationLevelsAdIn)
            districtsIn.sort((a,b)=> (a.code > b.code) ? 1 : -1);
            setDistricts(districtsIn)
            setIndicatorGroupRegionalDelegation(indicatorGroupRegionalDelegationIn)
            if (districtCode !== null) {
                const {data: countiesIn} = await getCountiesByDistrictCode(districtCode);
                setCounties(countiesIn);
            }
            setIndicatorByCae(indicatorCaeIn.results)
            setNumberOfResults(indicatorCaeIn.numberOfResults);
            setTotalResults(indicatorCaeIn.totalResults);

            setIndicatorPriorSupport(indicatorPriorSupportIn.results)
            setNumberOfResultsPriorSupport(indicatorPriorSupportIn.numberOfResults)
            setTotalResultsPriorSupport(indicatorPriorSupportIn.totalResults)

            setIndicatorOpinionSupport(indicatorOpinionSupportIn.results)
            setNumberOfResultsOpinionSupport(indicatorOpinionSupportIn.numberOfResults)
            setTotalResultsOpinionSupport(indicatorOpinionSupportIn.totalResults)


            setIndicatorConsultingRequest(indicatorConsultingRequestIn.results)
            setNumberOfResultsConsultingRequest(indicatorConsultingRequestIn.numberOfResults)
            setTotalResultsConsultingRequest(indicatorConsultingRequestIn.totalResults)

            setIndicatorWorkStations(workstationsIn);
            setIndicatorInvestments(investmentsIn);
            setIndicatorPayments(paymentsIn);
            setIndicatorRestitutions(restitutionsIn);


        } catch (error) {
            setError(error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, [districtCode,countyCode,cae,currentPage,currentPagePriorSupport, currentPageOpinionSupport, currentPageConsultingRequest, nif,regionalDelegationCode]);


    const onPageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const onPageChangePriorSupport = (pageNumber) => {
        setCurrentPagePriorSupport(pageNumber);
    };

    const onPageChangeOpinionSupport = (pageNumber) => {
        setCurrentPageOpinionSupport(pageNumber);
    };

    const onPageChangeConsulting= (pageNumber) => {
        setCurrentPageConsultingRequest(pageNumber);
    };





    if (loading) {
        return <Loading/>;
    }

    if (error && isNotBusinessError(error)) {
        return handleError(error);
    }

    return (
        <SubTemplate hasBackButton={true} titleId={'indicators.title'} forceClass={'container'}>
            <div className='container py-4 indicators'>

                <Row>
                    <Col md={12} className={'text-end'}>
                        <AuthenticatedDownloadLink
                            url={'api/indicator/asExcel'}
                            filename={'indicadores.xlsx'}
                        >
                            Descarregar Excel
                        </AuthenticatedDownloadLink>
                    </Col>
                </Row>


                <Row className={'mb-4'}>
                    <Col md='12' className='border-1 border p-2'>
                        <h5 className='text-secondary mt-1 mb-3'><FormattedMessage
                            id={'candidatures.byRegionalDelegation'}/></h5>
                        <table className='w-100'>
                            <thead>
                            <tr>
                                <th><FormattedMessage id={`regionalDelegation.column`}/></th>
                                <th><FormattedMessage id={`groupState.0`}/></th>
                                <th><FormattedMessage id={`groupState.1`}/></th>
                                <th><FormattedMessage id={`groupState.2`}/></th>
                                <th><FormattedMessage id={`groupState.3`}/></th>
                                <th><FormattedMessage id={`groupState.4`}/></th>
                                <th><FormattedMessage id={`groupState.5`}/></th>
                                <th><FormattedMessage id={`groupState.6`}/></th>
                                <th><FormattedMessage id={`groupState.7`}/></th>
                                <th><FormattedMessage id={'candidatureNumber.column'}/></th>
                            </tr>
                            </thead>
                            <tbody>
                            {indicatorGroupRegionalDelegation.map((indicator, index) => (
                                <tr key={index}>
                                    <td className='ps-2'><FormattedMessage id={`regionalDelegation.${indicator.code}`}/></td>
                                    <td className='ps-2'>{indicator.sumSubmitted}</td>
                                    <td className='ps-2'>{indicator.sumDeletedNoValidation}</td>
                                    <td className='ps-2'>{indicator.sumEligibility}</td>
                                    <td className='ps-2'>{indicator.sumApproved}</td>
                                    <td className='ps-2'>{indicator.sumRevocation}</td>
                                    <td className='ps-2'>{indicator.sumRejected}</td>
                                    <td className='ps-2'>{indicator.sumDeleted}</td>
                                    <td className='ps-2'>{indicator.sumOthers}</td>
                                    <td className='ps-2'>{indicator.counter}</td>
                                </tr>
                            ))}
                            <tr>
                                <td className='ps-2'>Total</td>
                                <td className='ps-2'>  {indicatorGroupRegionalDelegation.map(indicator => indicator.sumSubmitted).reduce((total, count) => (total + count),0)}</td>
                                <td className='ps-2'>{indicatorGroupRegionalDelegation.map(indicator => indicator.sumDeletedNoValidation).reduce((total, count) => (total + count),0)}</td>
                                <td className='ps-2'>{indicatorGroupRegionalDelegation.map(indicator => indicator.sumEligibility).reduce((total, count) => (total + count),0)}    </td>
                                <td className='ps-2'>{indicatorGroupRegionalDelegation.map(indicator => indicator.sumApproved).reduce((total, count) => (total + count),0)}  </td>
                                <td className='ps-2'>{indicatorGroupRegionalDelegation.map(indicator => indicator.sumRevocation).reduce((total, count) => (total + count),0)} </td>
                                <td className='ps-2'>{indicatorGroupRegionalDelegation.map(indicator => indicator.sumRejected).reduce((total, count) => (total + count),0)}  </td>
                                <td className='ps-2'>{indicatorGroupRegionalDelegation.map(indicator => indicator.sumDeleted).reduce((total, count) => (total + count),0)}  </td>
                                <td className='ps-2'>{indicatorGroupRegionalDelegation.map(indicator => indicator.sumOthers).reduce((total, count) => (total + count),0)}</td>
                                <td className='ps-2'>{indicatorGroupRegionalDelegation.map(indicator => indicator.counter).reduce((total, count) => (total + count),0)}</td>

                            </tr>

                            </tbody>
                        </table>
                        <p className="mt-3 text-secondary"><small><FormattedMessage id={'groupState.legend'}/></small></p>
                    </Col>
                </Row>




                <Row>
                    <Col md='12' className='border-1 border p-2'>
                        <h5 className='text-secondary mt-1 mb-3'><FormattedMessage
                            id={'candidatures.byCae'}/></h5>
                        <Row>
                            <Col md='3'>
                                <IntInputField
                                    maxSize={5}
                                    name={'cae'}
                                    placeholder={intl.formatMessage({
                                        id: 'candidatureForm.cae',
                                    })}
                                    value={caeIn}
                                    handleChange={(e) => handleChangeCae(e)}
                                    leadingZero={true}
                                />
                            </Col>
                            <Col md='3'>
                                <button
                                    className='btn btn-outline-primary'
                                    onClick={() => handleSearch()} //the true parameter indicates that the search was triggered by the filters. Consequently, currentPage will have to chgange to 0
                                    type='button'
                                >
                                    <FaSearch/> <FormattedMessage id='all.search'/>
                                </button>
                            </Col>

                        </Row>
                        <table className='w-100 mt-4'>
                            <thead>
                            <tr>
                                <th><FormattedMessage id={`cae.column`}/></th>
                                <th><FormattedMessage id={`groupState.2`}/></th>
                                <th><FormattedMessage id={`groupState.3`}/></th>
                                <th><FormattedMessage id={`groupState.4`}/></th>
                                <th><FormattedMessage id={`groupState.5`}/></th>
                                <th><FormattedMessage id={`groupState.7`}/></th>
                                <th><FormattedMessage id={'candidatureNumber.column'}/></th>
                            </tr>
                            </thead>
                            <tbody>
                            {indicatorByCae.map((indicator, index) => (
                                <tr key={index}>
                                    <td className='ps-2'>{indicator.code} - {indicator.description}</td>
                                    <td className='ps-2'>{indicator.sumEligibility}</td>
                                    <td className='ps-2'>{indicator.sumApproved}</td>
                                    <td className='ps-2'>{indicator.sumRevocation}</td>
                                    <td className='ps-2'>{indicator.sumRejected}</td>
                                    <td className='ps-2'>{indicator.sumOthers}</td>
                                    <td className='ps-2'>{indicator.counter}</td>
                                </tr>
                            ))}

                            <tr>
                                <td className='ps-2'>Total</td>
                                <td className='ps-2'>{indicatorGroupRegionalDelegation.map(indicator => indicator.sumEligibility).reduce((total, count) => (total + count),0)}    </td>
                                <td className='ps-2'>{indicatorGroupRegionalDelegation.map(indicator => indicator.sumApproved).reduce((total, count) => (total + count),0)}  </td>
                                <td className='ps-2'>{indicatorGroupRegionalDelegation.map(indicator => indicator.sumRevocation).reduce((total, count) => (total + count),0)} </td>
                                <td className='ps-2'>{indicatorGroupRegionalDelegation.map(indicator => indicator.sumRejected).reduce((total, count) => (total + count),0)}  </td>
                                <td className='ps-2'>{indicatorGroupRegionalDelegation.map(indicator => indicator.sumOthers).reduce((total, count) => (total + count),0)}</td>
                                <td className='ps-2'>{indicatorGroupRegionalDelegation.map(indicator => indicator.sumEligibility + indicator.sumApproved + indicator.sumRevocation+ indicator.sumRejected + indicator.sumOthers).reduce((total, count) => (total + count),0)}</td>

                            </tr>

                            </tbody>
                        </table>
                        <Row className='mb-3'>
                            <Col md='10' className='d-flex'>
                                    <CustomPagination
                                        dataPerPage={MAX_RESULTS}
                                        totalData={totalResults}
                                        onPageChange={onPageChange}
                                        currentPage={currentPage}
                                    />
                            </Col>
                        </Row>

                    </Col>
                </Row>




                <Row className='mt-5'>

                    <Col className='border-1 border p-2'>
                        <h5 className='text-secondary mt-1 mb-3'><FormattedMessage id={'candidatures.byState'}/></h5>

                        <table className='w-100 ms-auto me-auto'>
                            <thead>
                            <tr>
                                <th><FormattedMessage id={`state.column`}/></th>
                                <th><FormattedMessage id={'candidatureNumber.column'}/></th>
                            </tr>
                            </thead>
                            <tbody>
                            {indicatorsStates.map((indicator, index) => (
                                <tr key={index}>
                                    <td><FormattedMessage id={`candidatureState.${indicator.state}`}/></td>
                                    <td>{indicator.counter}</td>
                                </tr>
                            ))}
                            </tbody>
                            <tr>
                                <td>Total</td>
                                <td>{indicatorsStates.map(indicator => indicator.counter).reduce((total, count) => (total + count))} </td>
                            </tr>
                        </table>


                    </Col>
                </Row>

                <Row className='mt-5'>
                    <Col md='12' className='border-1 border p-2'>
                        <h5 className='text-secondary mt-1 mb-3'><FormattedMessage id={'candidatures.byPromoter'}/></h5>
                        <Row>
                            <Col md='6'>

                                <table className='w-100 border-1 border p-5'>
                                    <thead>
                                    <tr>
                                        <th><FormattedMessage id={`gender.column`}/>
                                        </th>
                                        <th><FormattedMessage id={'candidatureNumber.column'}/></th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {indicatorsGenders.map((indicator, index) => (
                                        <tr key={index}>
                                            <td className='pe-2 ps-2'><FormattedMessage
                                                id={`promoterForm.gender.${indicator.gender}`}/></td>
                                            <td>{indicator.counter}</td>
                                        </tr>
                                    ))}
                                    </tbody>
                                    <tr>
                                        <td className={'ps-2'}>Total</td>
                                        <td>{indicatorsGenders.map(indicator => indicator.counter).reduce((total, count) => (total + count))} </td>
                                    </tr>
                                </table>

                                <table className='w-100 border-1 border mt-4'>
                                    <thead>
                                    <tr>

                                        <th><FormattedMessage
                                            id={`schoolQualification.column`}/></th>
                                        <th><FormattedMessage id={'candidatureNumber.column'}/></th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {indicatorsSchool.map((indicator, index) => (
                                        <tr key={index}>
                                            <td className='ps-2'><FormattedMessage
                                                id={`schoolQualification.${indicator.code}`}/></td>
                                            <td>{indicator.counter}</td>
                                        </tr>
                                    ))}
                                    </tbody>
                                    <tr>
                                        <td className='ps-2'>Total</td>
                                        <td>{indicatorsSchool.map(indicator => indicator.counter).reduce((total, count) => (total + count))} </td>
                                    </tr>
                                </table>
                                <table className='w-100 border-1 border mt-4'>
                                    <thead>
                                    <tr>
                                        <th><FormattedMessage
                                            id={`qualificationLevel.column`}/></th>
                                        <th><FormattedMessage id={'candidatureNumber.column'}/></th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {indicatorsQualificationLevels.map((indicator, index) => (
                                        <tr key={index}>
                                            <td className='ps-2'>{indicator.code}</td>
                                            <td>{indicator.counter}</td>
                                        </tr>
                                    ))}
                                    </tbody>
                                    <tr>
                                        <td className='ps-2'>Total</td>
                                        <td>{indicatorsQualificationLevels.map(indicator => indicator.counter).reduce((total, count) => (total + count))} </td>
                                    </tr>
                                </table>

                            </Col>

                            <Col md='6'>
                                <table className='w-100 border-1 border'>
                                    <thead>
                                    <tr>
                                        <th className='ps-2 pe-2'><FormattedMessage id={`ageGroup.column`}/>
                                        </th>
                                        <th><FormattedMessage id={'candidatureNumber.column'}/></th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>

                                        <td>15-24</td>
                                        <td>{indicatorsAges.from15To24} </td>
                                    </tr>
                                    <tr>

                                        <td>25-34</td>
                                        <td>{indicatorsAges.from25To34} </td>
                                    </tr>
                                    <tr>

                                        <td>35-44</td>
                                        <td>{indicatorsAges.from35To44} </td>
                                    </tr>
                                    <tr>

                                        <td>45-54</td>
                                        <td>{indicatorsAges.from45To54} </td>
                                    </tr>
                                    <tr>
                                        <td>55-64</td>
                                        <td>{indicatorsAges.from55To64} </td>
                                    </tr>
                                    <tr>

                                        <td> > 65</td>
                                        <td>{indicatorsAges.more65} </td>
                                    </tr>

                                    </tbody>
                                    <tr>
                                        <td className='ps-2'>Total</td>
                                        <td>{indicatorsAges.from15To24 + indicatorsAges.from25To34  + indicatorsAges.from35To44 + indicatorsAges.from45To54  + indicatorsAges.from55To64  +  indicatorsAges.more65} </td>
                                    </tr>
                                </table>

                            </Col>


                        </Row>


                    </Col>
                </Row>

                <Row className='mt-5'>
                    <Col md='12' className='border-1 border p-2'>


                        <h5 className='text-secondary mt-1 mb-3'><FormattedMessage
                            id={'candidatures.byAdministrativeDivision'}/></h5>
                        <Row className='mb-3'>
                            <Col md='6'>
                                <Form.Group controlId={'district'}>
                                    <Form.Label>
                                        <FormattedMessage id='promoterForm.district'/>
                                    </Form.Label>
                                    <Form.Control
                                        as='select'
                                        name={'district'}
                                        onChange={(e) => handleDistrictChange(e)}
                                        value={districtCode}>
                                        <option value={'-1'}>
                                            <FormattedMessage id='all.chooseOne'/>
                                        </option>
                                        {districts.map((district) => (
                                            <option key={district.code} value={district.code}>
                                                {district.description}
                                            </option>
                                        ))}
                                    </Form.Control>
                                </Form.Group>

                            </Col>
                            <Col md='6'>
                                <Form.Group controlId={'county'}>
                                    <Form.Label>
                                        <FormattedMessage id='promoterForm.county'/>
                                    </Form.Label>
                                    <Form.Control
                                        as='select'
                                        name={'county'}
                                        onChange={(e) => handleCountyChange(e)}
                                        value={countyCode}
                                        disabled={districtCode === null}
                                    >
                                        <option value={'-1'}>
                                            <FormattedMessage id='all.chooseOne'/>
                                        </option>
                                        {counties?.map((county) => (
                                            <option key={county.code} value={county.code}>
                                                {county.description}
                                            </option>
                                        ))}
                                    </Form.Control>
                                </Form.Group>

                            </Col>
                        </Row>
                        <Row>
                            <Col md='6'>
                                <table className='w-100 border-1 border'>
                                    <thead>
                                    <tr>
                                        <th>{districtCode === null ?
                                            <FormattedMessage id={`promoterForm.district`}/> : (countyCode === null ?
                                                <FormattedMessage id={`promoterForm.county`}/> :
                                                <FormattedMessage id={`promoterForm.parish`}/>)} </th>
                                        <th><FormattedMessage id={'candidatureNumber.column'}/></th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {indicatorsCandidatureADCounter.map((indicator, index) => (
                                        <tr key={index}>
                                            <td className='ps-2'>{indicator.description}</td>
                                            <td>{indicator.counter}</td>
                                        </tr>
                                    ))}
                                    </tbody>
                                    <tr>
                                        <td className='ps-2'>Total</td>
                                        <td>{indicatorsCandidatureADCounter.map(indicator => indicator.counter).reduce((total, count) => (total + count), 0)} </td>
                                    </tr>
                                </table>

                            </Col>

                            <Col md='6'>
                                <table className='border-1 border w-100'>
                                    <thead>
                                    <tr>
                                        <th> {districtCode === null ?
                                            <FormattedMessage id={`promoterForm.district`}/> : (countyCode === null ?
                                                <FormattedMessage id={`promoterForm.county`}/> :
                                                <FormattedMessage id={`promoterForm.parish`}/>)} </th>
                                        <th><FormattedMessage id={`state.column`}/></th>
                                        <th><FormattedMessage id={'candidatureNumber.column'}/></th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {indicatorsCandidatureStatesADCounter.map((indicator, index) => (
                                        <tr key={index}>
                                            <td>{indicator.description}</td>
                                            <td><FormattedMessage id={`candidatureState.${indicator.state}`}/></td>
                                            <td>{indicator.counter}</td>
                                        </tr>
                                    ))}
                                    </tbody>
                                    <tr>
                                        <td colSpan='2'>Total</td>
                                        <td>{Arrays.isEmpty(indicatorsCandidatureStatesADCounter) ? 0 : indicatorsCandidatureStatesADCounter.map(indicator => indicator.counter).reduce((total, count) => (total + count))} </td>
                                    </tr>
                                </table>
                            </Col>

                            <h5 className='text-secondary mt-5 mb-3'>Candidaturas por Divisão Administrativa e por Dados do Representante</h5>
                            <Row className='mt-3'>

                                <Col md='6'>
                                    <table className='w-100 border-1 border'>
                                        <thead>
                                        <tr>
                                            <th>{districtCode === null ? <FormattedMessage
                                                id={`promoterForm.district`}/> : (countyCode === null ?
                                                <FormattedMessage id={`promoterForm.county`}/> :
                                                <FormattedMessage id={`promoterForm.parish`}/>)} </th>
                                            <th><FormattedMessage
                                                id={`gender.column`}/></th>
                                            <th><FormattedMessage id={'candidatureNumber.column'}/>
                                            </th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {indicatorsCandidatureGenderADCounter.map((indicator, index) => (
                                            <tr key={index}>
                                                <td className='ps-2'>{indicator.description}</td>
                                                <td><FormattedMessage id={`promoterForm.gender.${indicator.gender}`}/>
                                                </td>
                                                <td>{indicator.counter}</td>
                                            </tr>
                                        ))}
                                        </tbody>
                                        <tr>
                                            <td className='ps-2' colSpan='2'>Total</td>
                                            <td>{indicatorsCandidatureADCounter.map(indicator => indicator.counter).reduce((total, count) => (total + count), 0)} </td>
                                        </tr>
                                    </table>

                                </Col>

                                <Col md='6'>
                                    <table className='w-100 border-1 border'>
                                        <thead>
                                        <tr>
                                            <th>{districtCode === null ? <FormattedMessage
                                                id={`promoterForm.district`}/> : (countyCode === null ?
                                                <FormattedMessage id={`promoterForm.county`}/> :
                                                <FormattedMessage id={`promoterForm.parish`}/>)} </th>
                                            <th><FormattedMessage
                                                id={`ageGroup.column`}/></th>
                                            <th><FormattedMessage id={'candidatureNumber.column'}/>
                                            </th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {indicatorsCandidatureAgeADCounter.map((indicator, index) => (
                                            <tr key={index}>
                                                <td className='ps-2'>{indicator.description}</td>
                                                <td><FormattedMessage id={`indicator.groupAge${indicator.secondGroupFilter}`}/>
                                                </td>
                                                <td>{indicator.counter}</td>
                                            </tr>
                                        ))}
                                        </tbody>
                                        <tr>
                                            <td className='ps-2' colSpan='2'>Total</td>
                                            <td>{indicatorsCandidatureAgeADCounter.map(indicator => indicator.counter).reduce((total, count) => (total + count), 0)} </td>
                                        </tr>
                                    </table>

                                </Col>

                            </Row>
                            <Row className='mt-3'>
                                <Col md='6'>
                                    <table className='w-100 border-1 border'>
                                        <thead>
                                        <tr>
                                            <th>{districtCode === null ? <FormattedMessage
                                                id={`promoterForm.district`}/> : (countyCode === null ?
                                                <FormattedMessage id={`promoterForm.county`}/> :
                                                <FormattedMessage id={`promoterForm.parish`}/>)} </th>
                                            <th><FormattedMessage
                                                id={`schoolQualification.column`}/></th>
                                            <th><FormattedMessage id={'candidatureNumber.column'}/>
                                            </th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {indicatorsCandidatureSchoolQualificationADCounter.map((indicator, index) => (
                                            <tr key={index}>
                                                <td className='ps-2'>{indicator.description}</td>
                                                <td><FormattedMessage
                                                    id={`schoolQualification.${indicator.secondGroupFilter}`}/>
                                                </td>
                                                <td>{indicator.counter}</td>
                                            </tr>
                                        ))}
                                        </tbody>
                                        <tr>
                                            <td className='ps-2' colSpan='2'>Total</td>
                                            <td>{indicatorsCandidatureSchoolQualificationADCounter.map(indicator => indicator.counter).reduce((total, count) => (total + count), 0)} </td>
                                        </tr>
                                    </table>

                                </Col>
                                <Col md='6'>
                                    <table className='w-100 border-1 border'>
                                        <thead>
                                        <tr>
                                            <th>{districtCode === null ? <FormattedMessage
                                                id={`promoterForm.district`}/> : (countyCode === null ?
                                                <FormattedMessage id={`promoterForm.county`}/> :
                                                <FormattedMessage id={`promoterForm.parish`}/>)} </th>
                                            <th><FormattedMessage
                                                id={`qualificationLevel.column`}/></th>
                                            <th><FormattedMessage id={'candidatureNumber.column'}/>
                                            </th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {indicatorsCandidatureQualificationLevelsADCounter.map((indicator, index) => (
                                            <tr key={index}>
                                                <td className='ps-2'>{indicator.description}</td>
                                                <td>{indicator.secondGroupFilter}
                                                </td>
                                                <td>{indicator.counter}</td>
                                            </tr>
                                        ))}
                                        </tbody>
                                        <tr>
                                            <td className='ps-2' colSpan='2'>Total</td>
                                            <td>{indicatorsCandidatureQualificationLevelsADCounter.map(indicator => indicator.counter).reduce((total, count) => (total + count), 0)} </td>
                                        </tr>
                                    </table>

                                </Col>
                            </Row>






                        </Row>
                    </Col>



                </Row>
                <Row className={'mt-5 border-1 border'}>
                    <h5 className='text-secondary mt-3 mb-3'><FormattedMessage
                        id={'candidatures.byEA'}/></h5>

                    <Col md={'4'}>
                        <EnumSelectField
                            name={`regionalDelegation`}
                            handleChange={(e) => handleChangeRegionalDelegation(e)}
                            value={regionalDelegationCodeIn}
                            nullOptionSuffix={'regionalDelegation'}

                            nullOption={true}
                            options={['DN','DC','DL','DA','DG']}
                            preffixDescriptionId={'regionalDelegation'}
                        />

                    </Col>
                    <Col md={'4'}>
                        <TextInputField
                            controlId={'nif'}
                            name={'nif'}
                            handleChange={(e) => handleChangeNif(e)}
                            value={nifIn}
                            maxLength={9}
                            placeholder={'NIF'}
                        />

                    </Col>

                    <Col md='3' >
                        <button
                            className='btn btn-outline-primary'
                            onClick={() => handleSearchEa()} //the true parameter indicates that the search was triggered by the filters. Consequently, currentPage will have to chgange to 0
                            type='button'
                        >
                            <FaSearch /> <FormattedMessage id='all.search' />
                        </button>
                    </Col>

                    <Col md={'12'}>
                    <table className='w-100 border-1 border mt-4'>
                        <thead>
                        <tr>
                            <th><FormattedMessage id={'entity.column.priorSupport'}/>    </th>
                            <th><FormattedMessage
                                id={`contract.column`}/></th>
                            <th><FormattedMessage id={'pay.column'}/>
                            </th>
                            <th><FormattedMessage id={'pay.value.column'}/>
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        {indicatorPriorSupport.map((indicator, index) => (
                            <tr key={index}>
                                <td className='ps-2'>{indicator.description}</td>
                                <td>{indicator.counter}
                                </td>
                                <td>{indicator.paymentCount===undefined ? 0 : indicator.paymentCount}</td>
                                <td>{currencyFormat(indicator.value===undefined? 0 : indicator.value)}</td>
                            </tr>
                        ))}
                        </tbody>
                        <tr>
                            <td className='ps-2'>Total</td>
                            <td>{totalResultsPriorSupport.counter} </td>
                            <td>{totalResultsPriorSupport.paymentCount} </td>
                            <td>{currencyFormat(totalResultsPriorSupport.value===undefined? 0 : totalResultsPriorSupport.value)} </td>
                        </tr>
                    </table>
                    </Col>

                    <Row className='mb-5'>
                        <Col md='10' className='d-flex'>
                                <CustomPagination
                                    dataPerPage={MAX_RESULTS}
                                    totalData={totalResultsPriorSupport.totalResults}
                                    onPageChange={onPageChangePriorSupport}
                                    currentPage={currentPagePriorSupport}
                                />
                        </Col>
                    </Row>

                    <Col md={'12'}>
                    <table className='w-100 border-1 border'>
                        <thead>
                        <tr>
                            <th><FormattedMessage id={'entity.column.opinion'}/> </th>
                            <th><FormattedMessage
                                id={`assigned.column`}/></th>
                            <th><FormattedMessage
                                id={`submitted.column`}/></th>
                            <th><FormattedMessage id={'pay.column'}/>
                            </th>
                            <th><FormattedMessage id={'pay.value.column'}/>
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        {indicatorOpinionSupport.map((indicator, index) => (
                            <tr key={index}>
                                <td className='ps-2'>{indicator.description}</td>
                                <td>{indicator.assigned}</td>
                                <td>{indicator.counter}
                                </td>
                                <td>{indicator.paymentCount===undefined ? 0 : indicator.paymentCount}</td>
                                <td>{currencyFormat(indicator.value===undefined? 0 : indicator.value)}</td>
                            </tr>
                        ))}
                        </tbody>
                        <tr>
                            <td className='ps-2'>Total</td>
                            <td>{totalResultsOpinionSupport.assigned} </td>
                            <td>{totalResultsOpinionSupport.counter} </td>
                            <td>{totalResultsOpinionSupport.paymentCount} </td>
                            <td>{currencyFormat(totalResultsOpinionSupport.value===undefined? 0 : totalResultsOpinionSupport.value)} </td>
                        </tr>
                    </table>
                    </Col>
                    <Row className='mb-5'>
                        <Col md='10' className='d-flex'>
                                <CustomPagination
                                    dataPerPage={MAX_RESULTS}
                                    totalData={totalResultsOpinionSupport.totalResults}
                                    onPageChange={onPageChangeOpinionSupport}
                                    currentPage={currentPageOpinionSupport}
                                />
                        </Col>
                    </Row>


                    <Col md={'12'}>
                        <table className='w-100 border-1 border'>
                            <thead>
                            <tr>
                                <th><FormattedMessage id={'entity.column.consulting'}/> </th>
                                <th><FormattedMessage
                                    id={`contract.column`}/></th>
                                <th><FormattedMessage id={'pay.value.column'}/>
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            {indicatorConsultingRequest.map((indicator, index) => (
                                <tr key={index}>
                                    <td className='ps-2'>{indicator.description}</td>
                                    <td>{indicator.counter}
                                    </td>
                                    <td>{currencyFormat(indicator.value===undefined? 0 : indicator.value)}</td>
                                </tr>
                            ))}
                            </tbody>
                            <tr>
                                <td className='ps-2'>Total</td>
                                <td>{totalResultsConsultingRequest.counter} </td>
                                <td>{currencyFormat(totalResultsConsultingRequest.value===undefined? 0 : totalResultsConsultingRequest.value)} </td>
                            </tr>
                        </table>
                    </Col>
                    <Row className='mb-5'>
                        <Col md='10' className='d-flex'>
                            <CustomPagination
                                dataPerPage={MAX_RESULTS}
                                totalData={totalResultsConsultingRequest.totalResults}
                                onPageChange={onPageChangeConsulting}
                                currentPage={currentPageConsultingRequest}
                            />
                        </Col>
                    </Row>




                </Row>
                <Row className={'mt-5 border-1 border'}>
                    <Col md={12} className='border-1 border p-2'>
                    <h5 className='text-secondary mt-3 mb-3'><FormattedMessage
                        id={'indicators.workstations'}/></h5>

                    <table className='w-100'>
                        <thead>
                        <tr>
                            <th> </th>
                            <th><FormattedMessage
                                id={`workstations.own`}/></th>
                            <th><FormattedMessage id={'workstations.other'}/>
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td><FormattedMessage id={`workstations.forecast`}/></td>
                            <td>{indicatorWorkStations.promoterOwnWorkStations}</td>
                            <td>{indicatorWorkStations.promoterOtherWorkStations}</td>
                        </tr>
                        <tr>
                            <td><FormattedMessage id={`workstations.approved`}/></td>
                            <td>{indicatorWorkStations.finalOwnWorkStations}</td>
                            <td>{indicatorWorkStations.finalOtherWorkStations}</td>
                        </tr>

                        </tbody>
                    </table>
                    </Col>


                </Row>


                <Row className={'mt-5 border-1 border'}>
                    <Col md={12} className='border-1 border p-2'>
                        <h5 className='text-secondary mt-3 mb-3'><FormattedMessage
                            id={'indicators.investment'}/></h5>

                        <table className='w-100'>
                            <thead>
                            <tr>
                                <th> </th>
                                <th><FormattedMessage
                                    id={`indicators.investment.investmentTotal`}/></th>
                                <th><FormattedMessage id={'indicators.investment.investmentIefp'}/></th>
                                <th><FormattedMessage id={'indicators.investment.investment'}/></th>
                                <th><FormattedMessage id={'indicators.investment.workstations'}/></th>

                            </tr>
                            </thead>
                            <tbody>
                            {indicatorInvestments.map((indicator, index) => (
                                <tr key={index}>
                                    <td className='ps-2'>  <FormattedMessage    id={`indicator.InvestmentKind.${indicator.kind}`}/></td>
                                    <td>{ currencyFormat(indicator.sumPlanInvestment===undefined?0 : indicator.sumPlanInvestment)}</td>
                                    <td>{ currencyFormat(indicator.sumInvestmentIefp===undefined?0 : indicator.sumInvestmentIefp)}</td>
                                    <td>{ currencyFormat(indicator.sumFinancialInvestment===undefined?0 : indicator.sumFinancialInvestment)}</td>
                                    <td>{ currencyFormat(indicator.sumWorkstationInvestment===undefined?0 : indicator.sumWorkstationInvestment)}</td>
                                </tr>
                            ))}

                            </tbody>
                        </table>
                    </Col>


                </Row>




                <Row className={'mt-5 border-1 border'}>
                    <Col md={12} className='border-1 border p-2'>
                        <h5 className='text-secondary mt-3 mb-3'><FormattedMessage
                            id={'indicators.payments'}/></h5>

                        <table className='w-100'>
                            <thead>
                            <tr>

                                <th><FormattedMessage
                                    id={`indicators.payments.investment`}/></th>
                                <th><FormattedMessage id={'indicators.payments.jobCreation'}/>
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>{currencyFormat(indicatorPayments.investment)}</td>
                                <td>{currencyFormat(indicatorPayments.workstations)}</td>
                            </tr>

                            </tbody>
                        </table>
                    </Col>


                </Row>

                <Row className={'mt-5 border-1 border'}>
                    <Col md={12} className='border-1 border p-2'>
                        <h5 className='text-secondary mt-3 mb-3'><FormattedMessage
                            id={'indicators.restitutions'}/></h5>

                        <table className='w-100'>
                            <thead>
                            <tr>

                                <th><FormattedMessage
                                    id={`indicators.restitutions.asked`}/></th>
                                <th><FormattedMessage id={'indicators.restitutions.payed'}/>
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>{currencyFormat(indicatorRestitutions.asked)}</td>
                                <td>{currencyFormat(indicatorRestitutions.payed)}</td>
                            </tr>

                            </tbody>
                        </table>
                    </Col>


                </Row>


            </div>
        </SubTemplate>
    );
}
