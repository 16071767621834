import { Toast, ToastContainer } from 'react-bootstrap';
import { FaCheck } from 'react-icons/fa';

export function SuccessToast({ message, show, setShow }) {
	return (
		<ToastContainer className='fixed-top'>
			<Toast autohide delay={3000} show={show} onClose={() => setShow(false)}>
				<Toast.Header>
					<strong className='me-auto text-primary'>
						<span className='mr-2'>
							<FaCheck />
						</span>
						{message}
					</strong>
				</Toast.Header>
			</Toast>
		</ToastContainer>
	);
}
