import { Formik } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import { Col, Container, Form, Row } from 'react-bootstrap';
import { FaRegCheckCircle, FaRegSave } from 'react-icons/fa';
import { FormattedMessage, useIntl } from 'react-intl';
import Loading from '../../components/general/Loading';
import { SubTemplate } from '../../components/general/SubTemplate';
import { handleError, isNotBusinessError } from '../../utils/handleError';
import { EAPromoterStep } from '../../components/promoter/EAPromoterStep';
import { FinancialModalityStep } from '../../components/promoter/FinancialModalityStep';
import { MCEInstallmentEA } from '../../components/promoter/MCEInstallmentEA';
import { PromoterIdentification } from '../../components/promoter/PromoterIdentification';
import {
	SemestralReportFormStep2,
	SemestralReportFormStep3,
} from '../../components/semestralReport';
import { FinalReportFormStep4 } from '../../components/finalReport';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { createCustomErrorMessage } from '../../hooks/errorMessage';
import { ProcessState } from '../../models/ProcessState';
import { FormSideNavBar } from '../../components/general/FormSideNavBar';
import { ConfirmationAuthenticationModal } from '../../components/general/ConfirmationAuthenticationModal';
import { SuccessToast } from '../../components/general/SuccessToast';
import { AlertError } from '../../components/bootstrap/AlertError';
import {
	getEvaluationByExternalId,
	getEvaluationByProcessExternalId,
	saveEvaluation,
} from '../../rest/evaluationEa';
import * as yup from 'yup';
import { isIEFPUser } from '../../authentication/authenticationHelper';
import { getEntityGetList, getEntityTgpList } from '../../rest/entity';
import { MCESupportKind } from '../../models/MCESupportKind';
import { CompanyIdentificationFormUtils } from '../../utils/CompanyIdentificationFormUtils';
import { FinancialModalityFormUtils } from '../../utils/FinancialModalityFormUtils';
import { Validator } from '../../utils/Validator';

const validationSchema = yup.object().shape({
	identificationEa: yup.object().shape({
		technicalTeamManagerExternalId: yup
			.string()
			.test(
				'isGETValid',
				<FormattedMessage id='errors.annualComplementaryActivitiesPlan.GET' />,
				(value) => value !== '-1'
			),
		projectManagerExternalId: yup
			.string()
			.test(
				'isTGPValid',
				<FormattedMessage id='errors.evaluationEa.TGP' />,
				(value) => value !== '-1'
			),
		projectManagerEmail: yup.mixed().when('projectManagerExternalId', {
			is: (tgp) => tgp !== '-1',
			then: yup
				.string()
				.required(<FormattedMessage id='errors.fieldRequiredText' />)
				.test(
					'isEmailValid',
					<FormattedMessage id='errors.candidatureForm.email' />,
					(value) => Validator.validateEmail(value)
				),
		}),
		projectManagerPhone: yup.mixed().when('projectManagerExternalId', {
			is: (tgp) => tgp !== '-1',
			then: yup
				.string()
				.required(<FormattedMessage id='errors.fieldRequiredText' />)
				.test(
					'isPhoneValid',
					<FormattedMessage id='errors.eaAccreditationForm.phoneAndMobile' />,
					(value) => Validator.validateCommsNumber(value)
				),
		}),
	}),

	company:yup.mixed().when('evaluationEa.type', {
		is: (evaluationType) => evaluationType !== MCESupportKind.PRIOR,
		then: CompanyIdentificationFormUtils.validationObject(true),
	}),




	financialModality: FinancialModalityFormUtils.validationObject(),

	projectApproval: yup.mixed().when('evaluationEa.type', {
		is: (evaluationType) => evaluationType !== MCESupportKind.PRIOR,
		then: yup.object({
			projectApprovalDate: yup
				.date()
				.nullable()
				.required(<FormattedMessage id='errors.fieldRequiredText' />),
		}),
	}),

	mceSupportPeriod: yup.object({
		initialDateMceSupport: yup
			.mixed()
			.test(
				'ads',
				<FormattedMessage id='errors.fieldRequiredText' />,
				(value, context) =>
					context.from[1].value.evaluationEa.type === MCESupportKind.PRIOR
						? value !== null
						: true
			),
		endDateMceSupport: yup
			.date()
			.nullable()
			.required(<FormattedMessage id='errors.fieldRequiredText' />)
			.test(
				'isNotBeforeInitialDateMceSupport',
				<FormattedMessage id='errors.evaluationEa.endDateMceSupportCanNotBeBeforeInitialDate' />,
				(value, context) =>
					context.parent.initialDateMceSupport &&
					!(value <= new Date(context.parent.initialDateMceSupport))
			),
	}),

	evaluationEa: yup.object({
		globalEvaluation: yup
			.number()
			.min(1, <FormattedMessage id='errors.fieldRequiredText' />),
		wouldReaskEaServices: yup
			.boolean()
			.required(<FormattedMessage id='errors.fieldRequiredText' />),
		observations: yup
			.string()
			.required(<FormattedMessage id='errors.fieldRequiredText' />),
		priorSupportEvaluation: yup.mixed().when('type', {
			is: (evaluationType) => evaluationType === MCESupportKind.PRIOR,
			then: yup.object({
				supportNeedOnInvestmentArchiveCreation: yup
					.number()
					.min(1, <FormattedMessage id='errors.fieldRequiredText' />),
				hadFundChanges: yup
					.boolean()
					.required(<FormattedMessage id='errors.fieldRequiredText' />),
				projectGotMoreSustainable: yup
					.number()
					.min(1, <FormattedMessage id='errors.fieldRequiredText' />),
				investmentElaborationOvercomeDegree: yup
					.number()
					.min(1, <FormattedMessage id='errors.fieldRequiredText' />),
			}),
		}),
		postProjectApprovalEvaluation: yup.mixed().when('type', {
			is: (evaluationType) =>
				evaluationType === MCESupportKind.POST_PROJECT_APPROVAL,
			then: yup.object({
				followUpVisits: yup
					.number()
					.min(1, <FormattedMessage id='errors.fieldRequiredText' />),
				investmentConfirmation: yup
					.number()
					.min(1, <FormattedMessage id='errors.fieldRequiredText' />),
				otherFollowUpActivities: yup
					.number()
					.min(1, <FormattedMessage id='errors.fieldRequiredText' />),
				followUpGlobalEvaluation: yup
					.number()
					.min(1, <FormattedMessage id='errors.fieldRequiredText' />),

				requestedConsultingBeforeEaSugestion: yup
					.boolean()
					.required(<FormattedMessage id='errors.fieldRequiredText' />),
				consultancyNecessity: yup
					.number()
					.min(1, <FormattedMessage id='errors.fieldRequiredText' />),
				consultancyAdequacy: yup
					.number()
					.min(1, <FormattedMessage id='errors.fieldRequiredText' />),
				consultancyDifficultiesOvercomeDegree: yup
					.number()
					.min(1, <FormattedMessage id='errors.fieldRequiredText' />),
				consultancyGlobalEvaluation: yup
					.number()
					.min(1, <FormattedMessage id='errors.fieldRequiredText' />),

				requestedExpansionBeforeEaSugestion: yup
					.boolean()
					.required(<FormattedMessage id='errors.fieldRequiredText' />),
				expansionNecessity: yup
					.number()
					.min(1, <FormattedMessage id='errors.fieldRequiredText' />),
				expansionInterventionAdequacy: yup
					.number()
					.min(1, <FormattedMessage id='errors.fieldRequiredText' />),
				expansionDifficultiesOvercomeDegree: yup
					.number()
					.min(1, <FormattedMessage id='errors.fieldRequiredText' />),
				expansionGlobalEvaluation: yup
					.number()
					.min(1, <FormattedMessage id='errors.fieldRequiredText' />),
			}),
		}),
	}),
});

export function AddEAEvaluation() {
	const isIEFP = isIEFPUser();

	//for promoter users and IEFP. For IEFP it is the processExternalId.
	const { externalId: externalId } = useParams();

	//errors
	const [error, setError] = useState(null);
	const [navErrors, setNavErrors] = useState([]);

	//modals
	const [showSubmitModal, setShowSubmitModal] = useState(false);

	//formik
	const [formikInitialValues, setFormikInitialValues] = useState(null);
	const formikPropsRef = useRef(null);

	//form control
	const [formStep, setFormStep] = useState(0);
	const [showSaveToast, setShowSaveToast] = useState(false);
	const [loading, setLoading] = useState(true);
	const [submited, setSubmited] = useState(false);

	const [getList, setGetList] = useState([]);
	const [tgpList, setTgpList] = useState([]);

	const intl = useIntl();
	const navigate = useNavigate();

	const tabsIds = [
		'eaevaluationform.eaidentification',
		'eaevaluationform.promotersidentification',
		'eaevaluationform.companyidentification',
		'eaevaluationform.sideMenu.modalityidentification',
		'eaevaluationform.projectapproval',
		'eaevaluationform.mcesupport',
		'eaevaluationform.sideMenu.mceinstallmentea',
	];

	async function fetchData() {
		try {
			let [{ data: evaluationEa }] = await Promise.all([
				isIEFP
					? getEvaluationByProcessExternalId(externalId)
					: await getEvaluationByExternalId(externalId),
			]);

			let tgpListParams = {
				entityNif: evaluationEa.identificationEa.entity.nif,
				toIdentificationStep: true,
			};
			let getListParams = {
				entityNif: evaluationEa.identificationEa.entity.nif,
				all:evaluationEa.processState !== ProcessState.DRAFT
			};

			let [{ data: entityGetList }, { data: entityTgpList }] =
				await Promise.all([
					await getEntityGetList(getListParams),
					await getEntityTgpList(tgpListParams),
				]);
			setGetList(entityGetList);
			setTgpList(entityTgpList);

			setFormikInitialValues(getFormikInitialValues(evaluationEa));
		} catch (error) {
			setError(error);
		} finally {
			setLoading(false);
		}
	}

	function getFormikInitialValues(evaluationEaForm) {
		let initialValues = evaluationEaForm;

		initialValues.identificationEa.projectManagerEmail ??= '';
		initialValues.identificationEa.projectManagerPhone ??= '';

		CompanyIdentificationFormUtils.getFormikInitialValuesCompanyIdentification(
			initialValues,
			true
		);

		FinancialModalityFormUtils.getFormikInitialValuesFinancialModality(
			initialValues
		);

		//getFormikInitialValuesFinancingModality
		if (initialValues.evaluationEa.type === MCESupportKind.PRIOR) {
			initialValues.projectApproval.projectApprovalDate ??= null;
			initialValues.mceSupportPeriod.initialDateMceSupport ??= null;
		}
		initialValues.mceSupportPeriod.endDateMceSupport ??= null;

		getFormikInitialValuesEaEvaluation(initialValues);
		return initialValues;
	}

	function getFormikInitialValuesEaEvaluation(initialValues) {
		initialValues.evaluationEa.globalEvaluation ??= 0;
		initialValues.evaluationEa.wouldReaskEaServices ??= null;
		initialValues.evaluationEa.observations ??= '';

		if (initialValues.evaluationEa.type === MCESupportKind.PRIOR) {
			initialValues.evaluationEa.priorSupportEvaluation.supportNeedOnInvestmentArchiveCreation ??= 0;
			initialValues.evaluationEa.priorSupportEvaluation.hadFundChanges ??= null;
			initialValues.evaluationEa.priorSupportEvaluation.projectGotMoreSustainable ??= 0;
			initialValues.evaluationEa.priorSupportEvaluation.investmentElaborationOvercomeDegree ??= 0;
			initialValues.projectApproval.projectApprovalDate??=null;
		} else {
			//POST_PROJECT_APPROVAL_TYPE
			initialValues.evaluationEa.postProjectApprovalEvaluation.followUpVisits ??= 0;
			initialValues.evaluationEa.postProjectApprovalEvaluation.investmentConfirmation ??= 0;
			initialValues.evaluationEa.postProjectApprovalEvaluation.otherFollowUpActivities ??= 0;
			initialValues.evaluationEa.postProjectApprovalEvaluation.followUpGlobalEvaluation ??= 0;

			initialValues.evaluationEa.postProjectApprovalEvaluation.requestedConsultingBeforeEaSugestion ??=
				null;
			initialValues.evaluationEa.postProjectApprovalEvaluation.consultancyNecessity ??= 0;
			initialValues.evaluationEa.postProjectApprovalEvaluation.consultancyAdequacy ??= 0;
			initialValues.evaluationEa.postProjectApprovalEvaluation.consultancyDifficultiesOvercomeDegree ??= 0;
			initialValues.evaluationEa.postProjectApprovalEvaluation.consultancyGlobalEvaluation ??= 0;

			initialValues.evaluationEa.postProjectApprovalEvaluation.requestedExpansionBeforeEaSugestion ??=
				null;
			initialValues.evaluationEa.postProjectApprovalEvaluation.expansionNecessity ??= 0;
			initialValues.evaluationEa.postProjectApprovalEvaluation.expansionInterventionAdequacy ??= 0;
			initialValues.evaluationEa.postProjectApprovalEvaluation.expansionDifficultiesOvercomeDegree ??= 0;
			initialValues.evaluationEa.postProjectApprovalEvaluation.expansionGlobalEvaluation ??= 0;
		}
	}


	useEffect(() => {
		fetchData();
	}, []);

	if (submited) {
		sessionStorage.setItem('evaluationEaSubmited', submited);
		return <Navigate to={'/avaliacoes/'} />;
	}

	function scrollPageToTop() {
		document.body.scrollTop = 0;
		document.documentElement.scrollTop = 0;
	}

	function handlePreviousStep() {
		scrollPageToTop();
		setFormStep(formStep - 1);
	}

	function handleNextStep() {
		scrollPageToTop();
		setFormStep(formStep + 1);
	}

	if (loading) {
		return <Loading />;
	}

	if (error && isNotBusinessError(error)) {
		return handleError(error);
	}

	async function handleSave() {
		try {
			let { data: res } = await saveEvaluation(formikPropsRef.current.values);

			let processedRes = getFormikInitialValues(res);

			formikPropsRef.current.setValues(processedRes);
			setNavErrors([]);
			formikPropsRef.current.setErrors({});

			setShowSaveToast(true);
		} catch (error) {
			setError(error);
		}
	}

	async function formValidationHandler(setShowModal) {
		let errors = await validateForm();
		setNavErrors(Array.from(errors));
		if (errors.size === 0) {
			setShowModal(true);
		}
	}

	async function validateForm() {
		let errors = new Set();

		const formikErrors = await formikPropsRef.current.validateForm();
		Object.keys(formikErrors).forEach((key) => {
			switch (key) {
				case 'identificationEa':
					errors.add(0);
					break;
				case 'company':
					errors.add(2);
					break;
				case 'financialModality':
					errors.add(3);
					break;
				case 'projectApproval':
					errors.add(4);
					break;
				case 'mceSupportPeriod':
					errors.add(5);
					break;
				default:
					errors.add(6);
					break;
			}
		});

		let errorMessages = [];

		if (
			!FinancialModalityFormUtils.validateAtLeastOneOptionFromEmpreendeXXIMeasureIsSelected(
				formikPropsRef.current.values,
				intl,
				errorMessages
			)
		) {
			errors.add(3);
		}

		if (errorMessages.length > 0) {
			let message =
				errorMessages.length === 1
					? createCustomErrorMessage(errorMessages[0])
					: createCustomErrorMessage(
							'<p>' +
								intl.formatMessage({
									id: 'errors.more',
								}) +
								'</p><p> - ' +
								errorMessages.join('</p><p> - ') +
								'</p>'
					  );

			setError(message);
		}

		return errors;
	}

	async function handleSubmit(user, password, setModalError) {
		const submissionValues = { ...formikPropsRef.current.values };

		submissionValues.credentials = {
			user: user,
			password: password,
		};

		let queryParams = {
			submit: true,
		};

		try {
			await saveEvaluation(submissionValues, queryParams);
			setSubmited(true);
		} catch (err) {
			if (err.response.data.exception === 'ValidationException') {
				handleNavError(err);
			} else {
				setModalError(err);
			}
		}
	}

	function handleNavError(error) {
		let errors = error.response.data.message
			.split(',')
			.map((value) => Number.parseInt(value));
		setNavErrors(errors);
	}

	function renderStep(formikProps) {
		const readMode = formikProps.values.processState !== ProcessState.DRAFT || isIEFP;
		formikPropsRef.current = formikProps;

		switch (formStep) {
			case 0:
				return (
					<EAPromoterStep
						formStep={formStep + 1}
						numberOfSteps={tabsIds.length}
						errors={formikProps.errors}
						formikValues={formikProps.values}
						handleChange={formikProps.handleChange}
						setFieldValue={formikProps.setFieldValue}
						handleNextStep={handleNextStep}
						entityGetList={getList}
						entityTgpList={tgpList}
						readMode={readMode}
						goBackPath={isIEFP ? '/processos' : '/avaliacoes/'}
					/>
				);
			case 1:
				return (
					<PromoterIdentification
						step={formStep + 1}
						numberOfSteps={tabsIds.length}
						handleGoBack={handlePreviousStep}
						formikValues={formikProps.values}
						handleNextStep={handleNextStep}
					/>
				);
			case 2:
				return (
					<SemestralReportFormStep2
						formStep={formStep + 1}
						numberOfSteps={tabsIds.length}
						handleGoBack={handlePreviousStep}
						errors={formikProps.errors}
						formikValues={formikProps.values}
						handleChange={formikProps.handleChange}
						handleNextStep={handleNextStep}
						readMode={readMode}
					/>
				);
			case 3:
				return (
					<FinancialModalityStep
						step={formStep + 1}
						numberOfSteps={tabsIds.length}
						handleGoBack={handlePreviousStep}
						errors={formikProps.errors}
						formikValues={formikProps.values}
						handleChange={formikProps.handleChange}
						handleNextStep={handleNextStep}
						readMode={readMode}
					/>
				);
			case 4:
				return (
					<span>
						<SemestralReportFormStep3
							formStep={formStep + 1}
							numberOfSteps={tabsIds.length}
							handleGoBack={handlePreviousStep}
							errors={formikProps.errors}
							formikValues={formikProps.values}
							handleChange={formikProps.handleChange}
							handleNextStep={handleNextStep}
							setFieldValue={formikProps.setFieldValue}
							projectApprovalDateAsInput={
								formikProps.values.evaluationEa.type === MCESupportKind.PRIOR
									? true
									: undefined
							}
							readMode={readMode}
						/>
					</span>
				);
			case 5:
				return (
					<span>
						<FinalReportFormStep4
							formStep={formStep + 1}
							numberOfSteps={tabsIds.length}
							errors={formikProps.errors}
							formikValues={formikProps.values}
							handlePreviousStep={handlePreviousStep}
							handleNextStep={handleNextStep}
							setFieldValue={formikProps.setFieldValue}
							initialDateAsInput={
								formikProps.values.evaluationEa.type === MCESupportKind.PRIOR
									? true
									: undefined
							}
							isFinalReport={false}
							readMode={readMode}
						/>
					</span>
				);
			case 6:
				return (
					<span>
						<MCEInstallmentEA
							step={formStep + 1}
							numberOfSteps={tabsIds.length}
							errors={formikProps.errors}
							formikValues={formikProps.values}
							handleChange={formikProps.handleChange}
							setFieldValue={formikProps.setFieldValue}
							handleGoBack={handlePreviousStep}
							readMode={readMode}
						/>
					</span>
				);

			default:
				return <span></span>;
		}
	}

	return (
		<SubTemplate>
			<Container>
				<ConfirmationAuthenticationModal
					show={showSubmitModal}
					handleClose={() => setShowSubmitModal(false)}
					submitHandler={handleSubmit}
					idsPreffix='eaEvaluation.submitModal'
					confirmButtonCompleteLabelId='all.submitButtonText'
				/>
				<Row>
					<Col md={{ span: 8, offset: 2 }}>
						<h2 className='mb-1 text-secondary'>
							<FormattedMessage id='eaevaluationform.title' />
						</h2>
						<p className='mb-5 text-secondary'>
							<FormattedMessage id='eaevaluationform.subTitle' />
							<span
								className="badge rounded-pill bg-secondary fw-normal ms-2"><span
								className="text-uppercase fw-bold"><FormattedMessage
								id={`processState.${formikInitialValues.processState}`}
							/> </span>  {formikInitialValues.submissionDate !== undefined && formikInitialValues.submissionDate !== null && (<> · <FormattedMessage id={'submitted.process.at'} values={{date:formikInitialValues.submissionDate}}/>  </>) } </span>

						</p>
					</Col>
				</Row>

				<Row className='mb-5'>
					<Col md='2'>
						<FormSideNavBar
							tabsIdsArray={tabsIds}
							formStep={formStep}
							setFormStep={setFormStep}
							navErrors={navErrors}
							setNavErrors={setNavErrors}
						/>
					</Col>
					<Col md='8'>
						<AlertError error={error} />
						<SuccessToast
							message={intl.formatMessage({
								id: 'annualComplementaryActivitiesPlan.saveToastInformation',
							})}
							show={showSaveToast}
							setShow={setShowSaveToast}
						/>
						<Formik
							initialValues={formikInitialValues}
							validateOnBlur={false}
							validateOnChange={false}
							validationSchema={validationSchema}
							onSubmit={handleSubmit}
						>
							{(formikProps) => (
								<Form onSubmit={formikProps.handleSubmit}>
									{renderStep(formikProps)}
								</Form>
							)}
						</Formik>
					</Col>
					{formikInitialValues.processState === ProcessState.DRAFT && !isIEFP && (
						<Col md='2'>
							<div>
								<button
									className='btn btn-outline-primary d-flex align-items-center justify-content-center mb-4 w-100'
									type='button'
									onClick={() => handleSave()}
								>
									<FaRegSave />
									<FormattedMessage id='all.save' />
								</button>
								<button
									className='btn btn-primary d-flex align-items-center justify-content-center w-100'
									type='button'
									onClick={() => formValidationHandler(setShowSubmitModal)}
								>
									<FaRegCheckCircle />
									<FormattedMessage id='all.submitButtonText' />
								</button>
							</div>
						</Col>
					)}
				</Row>
			</Container>
		</SubTemplate>
	);
}
