import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';
import { SubTemplate } from './general/SubTemplate';
import { useGlobalContext } from '../context';
import { getUserInfo } from '../authentication/authenticationHelper';
import { BsFillEmojiFrownFill } from 'react-icons/bs';


export function Error403() {
    const { accessInfo, name } = useGlobalContext();

    const userInfo = getUserInfo();

    const intl = useIntl();
    const navigate = useNavigate();

    const { responseStatusCode } = useParams();

    return (
        <SubTemplate
            centerContent
            hasBackButton
            forceRedirect={-2}
            titleId={`error.messages.responseStatusCode.${responseStatusCode}`}
        >
            <Container>
                <Row>
                    <Col md={{ span: 8, offset: 2 }}>
                        <div className='form bg-white text-center py-5'>
                            <div className='text-secondary h1 mt-5 mb-3'>
                                <BsFillEmojiFrownFill />
                            </div>
                            <div className='h4 mb-5' >
                                {responseStatusCode === "403" && <FormattedMessage id='error.messages.FORBIDDEN' />}
                            </div>
                        </div>



                        <div className='form-actions'>
                            <div className='mr-auto'>
                                <button
                                    className='btn btn-link'
                                    type='button'
                                    onClick={() => {
                                        navigate('/');
                                    }}
                                >
                                    <FormattedMessage id='all.backToHomePage' />
                                </button>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </SubTemplate>
    );
}