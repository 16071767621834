import React, {useState} from "react";
import {SubTemplate} from "../general/SubTemplate";
import {Col, Container, Form, Row} from "react-bootstrap";
import {AlertError} from "../bootstrap/AlertError";
import {Formik} from "formik";
import {TextInputField} from "../bootstrap/TextInputField";
import {DateTimeInputField} from "../bootstrap/DateTimeInputField";
import {FormattedMessage} from "react-intl";
import {logout} from "../../authentication/authenticationHelper";
import * as yup from "yup";
import {CandidaturePaymentType} from "../../models/CandidaturePaymentType";
import {CandidaturePaymentPurpose} from "../../models/CandidaturePaymentPurpose";
import {savePayment, savePaymentWithDocument, saveUnemploymentDelegationRecord} from "../../rest/candidaturePayments";
import {useNavigate} from "react-router-dom";


export function UnemploymentPaymentDelegate ({candidature, onSubmitCallback}) {
    const [error, setError] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const navigate = useNavigate();

    async function onSubmit(values, setSubmitting, setErrors) {

        setSubmitting(true);
        try {
            if(!(submitting || submitted)) {
                const unemploymentDelegationRecord = await saveUnemploymentDelegationRecord(candidature.externalId, values);
            }
            setSubmitted(true);
            //If this is set, invoke callback for parent
            onSubmitCallback?.();
        } catch (error) {
            console.log(error);
            setErrors(error);
        } finally {
            setSubmitting(false);
        }

    }

    if (submitted) {
        navigate('/candidatura/' + candidature.externalId + '/acompanhamento/payments', { state: { refresh: true } });
    }

    const initialValues =  {
        checked: false,
        date: null,
        referenceNumber: null //Nº de Ofício
    }

    const validationSchema = yup.object().shape({
        checked: yup
            .boolean()
            .test('isChecked', <FormattedMessage id='errors.fieldRequiredText'/>, v => v === true)
            .required(<FormattedMessage id='errors.fieldRequiredText'/>),
        date: yup
            .date()
            .typeError(<FormattedMessage id='errors.fieldRequiredText'/>)
            .max(new Date(), <FormattedMessage id='candidature.payments.form.error.future' />)
            .min(candidature.creationDate, <FormattedMessage id='candidature.payments.form.error.past'/>)
            .required(<FormattedMessage id='errors.fieldRequiredText'/>),
        referenceNumber: yup
            .string()
            .typeError(<FormattedMessage id='errors.fieldRequiredText'/>)
            .test('isNotEmpty', <FormattedMessage id='errors.fieldRequiredText'/>, v => v !== undefined && v.length > 0)
            .required(<FormattedMessage id='errors.fieldRequiredText'/>)
    });

    return (
        <>
            <AlertError error={error} />
            <Formik
                initialValues={initialValues}
                validateOnBlur={false}
                validateOnChange={false}
                validationSchema={validationSchema}
                onSubmit={(values, { setSubmitting, setErrors }) => {
                    onSubmit(values, setSubmitting, setErrors);
                }}>
                {({ values,
                      errors,
                      touched,
                      handleChange,
                      handleSubmit,
                      setFieldValue,
                  }) => (
                    <Form onSubmit={handleSubmit}>
                        <AlertError error={errors} />
                        <Form.Group controlId="unemploymentAnticipationPaymentDelegationForm">
                            <Row>
                                <Col>
                                    <Form.Check
                                        inline
                                        name={'checked'}
                                        type={'checkbox'}
                                        className={'d-flex align-items-center'}
                                        label={
                                            <FormattedMessage id='candidature.payments.unemployment.delegatedToSS' />
                                        }
                                        checked={values.checked}
                                        onChange={({target}) => setFieldValue('checked', target.checked)}
                                    />
                                    <Form.Control.Feedback type='invalid'>{errors.checked}</Form.Control.Feedback>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <DateTimeInputField
                                        name='date'
                                        labelId='candidature.payments.unemployment.sendDate'
                                        value={values.date}
                                        isDateWithNoTime={true}
                                        setFieldValue={setFieldValue}
                                        errorMessage={errors.date}
                                        disabled={!values.checked}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <TextInputField
                                        controlId={'referenceNumber'}
                                        label={<FormattedMessage id='candidature.payments.unemployment.referenceNumber'/>}
                                        name={'referenceNumber'}
                                        handleChange={({target: t}) => setFieldValue('referenceNumber', t.value)}
                                        disabled={!values.checked}
                                        isInvalid={false}
                                        errorMessage={errors.referenceNumber}
                                    />
                                </Col>
                            </Row>
                            <Row className='form-actions justify-content-end'>
                                <Col className='justify-content-end' md={{offset: 10}}>
                                    <button className='btn btn-primary' type='submit' >
                                        <FormattedMessage id='all.submitButtonText' />
                                    </button>
                                </Col>
                            </Row>
                        </Form.Group>
                    </Form>
                )}
            </Formik>
        </>
    );
}