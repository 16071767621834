import React, {useState} from 'react';
import { Form } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import {NumericFormat} from "react-number-format";
import {maxFixed} from "../../utils/CurrencyUtils";

const convertToInteger = (value,leadingZero) => {
  if (value) {
    if(leadingZero && value.toString().length > 1 &&  value.toString().startsWith('0')){
     if(value.toString()==='00'){
       return 0;
     }
     return '0'+parseInt(value);
    }
    return parseInt(value);
  }
  return value;
};

export function IntInputField({
  controlId,
  labelId,
  label,
  name,
  handleChange,
  value,
  errorMessage,
  isInvalid,
  disabled,
    maxSize,
    maxValue,
                                toolTip,
                                leadingZero,
    onBlur,
    allowZero,className,placeholder
}) {
  const [lastValue, setLastValue] = useState(0);
  const onChange = (e) => {
    e.currentTarget.value = e.currentTarget.value.replace(/\D/g, '');
    if(e.target.value.length >maxSize){
      e.target.value=e.target.value.substring(0,maxSize)
    }

    if(maxValue && maxValue< e.target.value){
      e.target.value= lastValue;
    }

    e.target.value = convertToInteger(e.currentTarget.value,leadingZero);
    setLastValue(e.target.value);
    //console.log(e);
    handleChange(e);
  };

  const Onout = (e) => {
    if(maxValue && maxValue< e.target.value){
      e.target.value= maxValue;
    }

    if(e.target.value=='' && !allowZero){
      e.target.value=0;
    }
    handleChange(e);
  };

  const onkeyPress = (e) => {
    if((e.target.value==='0' || e.target.value===0) && e.code==='ArrowDown'){
      e.preventDefault();
    }
  };

  return (
    <Form.Group controlId={controlId}>
      {(label || labelId) && (
        <Form.Label>
          {labelId && <FormattedMessage id={labelId} />}
          {label && label}
          {toolTip && toolTip}
        </Form.Label>
      )}
      <Form.Control
        type='number'
        name={name}
        className={className? className:''}
        value={value}
        onChange={onChange}
        onKeyDown={onkeyPress}
        isInvalid={isInvalid}
        disabled={disabled}
        onBlur={onBlur ? onBlur :Onout}
        placeholder={placeholder ? placeholder :''}

      />
      {errorMessage && <Form.Control.Feedback type='invalid'>{errorMessage}</Form.Control.Feedback>}
    </Form.Group>
  );
}

export default IntInputField;
