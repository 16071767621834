import React, { useState } from 'react';
import {Col, Modal, Row} from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { createCustomErrorMessage } from '../../hooks/errorMessage';
import { Strings } from '../../utils/Strings';
import {AlertError} from "../bootstrap/AlertError";
import {TextAreaInputField} from "../bootstrap/TextAreaInputField";
import DecimalInputField from "../bootstrap/DecimalInputField";
import {SingleUploadArea} from "../SingleUploadArea";
import {DocumentType} from "../../models/DocumentType";
import {createMultiPartWithFilesAndJsonObject} from "../SyncMultipleUploadArea";
import {AccreditationDecisionState} from "../../models/AccreditationDecisionState";

export function EaARejectWithFilesDialog({ show, handleClose, handleConfirm, titleId, bodyId, needReason, reasonLabelId, bodyValue, isFinal}) {
    const intl = useIntl();
    const [reason, setReason] = useState(null);
    const [error, setError] = useState(null);
    const [SIDoc, setSIDoc] = useState([]);
    const [NFDoc, setNFDoc] = useState([]);


    function genFormData() {
        let formData = SIDoc[0];
        for (const pair of NFDoc[0].entries()) {
            formData.append(pair[0], pair[1]);
        }
        formData.append('AccreditationDecisionDto', JSON.stringify(
        {
                state: AccreditationDecisionState.REJECTED,
                score: 0,
                decisionComment: reason
            }
        ));
        return formData;
    }

    function handleReason(event) {
        setReason(event.target.value);
    }


    function validateAndConfirm() {
        if(SIDoc.length !== 1 || NFDoc.length !== 1) {
            setError(
                createCustomErrorMessage(
                    intl.formatMessage({ id: 'all.noFilesUploaded' })
                )
            );
            return;
        }
        if (needReason && Strings.isBlank(reason)) {
            setError(
                createCustomErrorMessage(
                    intl.formatMessage({ id: 'errors.rejectPriorSupportModal.motiveRequired' })
                )
            );
            return;
        }
        let formData = genFormData();
        handleConfirm(formData);
    }

    function close() {
        setError(null);
        handleClose();
    }

    if (!show) {
        return <></>;
    } else {
        return (
            <Modal show={show} onHide={close} dialogClassName='modal-35'>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <FormattedMessage id={titleId} />
                    </Modal.Title>
                </Modal.Header>
                <AlertError error={error} />
                <Modal.Body>
                    {bodyValue ? <FormattedMessage id={bodyId}  values={{value:bodyValue}}/> : <FormattedMessage id={bodyId} />}
                    {needReason && (
                        <Row className={'mb-3'}>
                            <Col>
                                <TextAreaInputField
                                    controlId={'reason'}
                                    label={reasonLabelId? <FormattedMessage id={reasonLabelId}/> :'Motivo'}
                                    name={'reason'}
                                    value={reason ?? ''}
                                    handleChange={handleReason}
                                />
                            </Col>
                        </Row>
                    )}
                    <Row className={!needReason && 'mt-3'}>
                        <Col>
                            <SingleUploadArea
                                documents={SIDoc}
                                setDocuments={setSIDoc}
                                documentType={isFinal ? DocumentType.EAA_SERVICE_INFO : DocumentType.EAA_PROP_SERVICE_INFO}
                                processUploadCallback={formData => setSIDoc([formData])}
                                processDeleteCallback={() => setSIDoc([])}
                                setError={setError}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <SingleUploadArea
                                documents={NFDoc}
                                setDocuments={setNFDoc}
                                documentType={isFinal ? DocumentType.EAA_DECISION_NOTIFICATION : DocumentType.EAA_PROP_DECISION_NOTIFICATION}
                                processUploadCallback={formData => setNFDoc([formData])}
                                processDeleteCallback={() => setNFDoc([])}
                                setError={setError}
                            />
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer className='justify-content-between'>
                    <button className={'btn btn-outline-primary px-5'} onClick={close}>
                        <FormattedMessage id='all.no' />
                    </button>
                    <button className={'btn btn-outline-primary px-5'} onClick={() => validateAndConfirm()}>
                        <FormattedMessage id='all.yes' />
                    </button>
                </Modal.Footer>
            </Modal>
        );
    }
}
