import check, { Strings} from './Strings';

export const Validator = {
  validateNiss: (niss) => validateNiss(niss),
  validateNif: (nif) => validateNif(nif),
  validateId: (cc) => validateId(cc),
  validateEmail: (email) => validateEmail(email),
  validateNib: (nib) => validateNib(nib),
  validateFixedSizeNumber: (value, numberOfDigits) => validateFixedSizeNumber(value,numberOfDigits),
  validatePhone: (value) => validatePhone(value),
  validateMobile: (value) => validateMobile(value),
  validateUrl: (value) => validateUrl(value),
  validateCommsNumber: (value) => validateCommsNumber(value),
};




function validateUrl(url){
  let test ;
  try {
    test = new URL(url);
  } catch(err) {
    return false;
  }
  return test.protocol === "http:" || test.protocol === "https:";
}


function validateNiss(niss) {
  const VALIDATION_FACTOR = [29, 23, 19, 17, 13, 11, 7, 5, 3, 2, 0];
  const BASE_10 = 10;
  const BASE_CHECK_DIGIT = 9;

  if (Strings.isBlank(niss)) {
    return false;
  }

  if (niss.length != 11) {
    return false;
  }
  let n = parseInt(niss, 10);
  if (n === undefined) {
    return false;
  }

  if (niss.charAt(0) != 1 && niss.charAt(0) != 2) {
    return false;
  }

  let sum = 0;
  for (let index = 0; index < VALIDATION_FACTOR.length; index++) {
    sum += niss.charAt(index) * VALIDATION_FACTOR[index];
  }
  let remaining = sum % BASE_10;
  let checkDigit = BASE_CHECK_DIGIT - remaining;
  let nissCD_Is_Valid = parseInt(niss.charAt(VALIDATION_FACTOR.length - 1)) === checkDigit;
  return nissCD_Is_Valid;
}

function validateNif(nif) {
  if (Strings.isBlank(nif)) {
    return false;
  }

  if (
    !['1', '2', '3', '5', '6', '8'].includes(nif.substr(0, 1)) &&
    !['45', '70', '71', '72', '77', '79', '90', '91', '98', '99'].includes(nif.substr(0, 2))
  )
    return false;

  let total =
    nif[0] * 9 +
    nif[1] * 8 +
    nif[2] * 7 +
    nif[3] * 6 +
    nif[4] * 5 +
    nif[5] * 4 +
    nif[6] * 3 +
    nif[7] * 2;

  let modulo11 = total - parseInt(total / 11) * 11;
  let comparador = modulo11 == 1 || modulo11 == 0 ? 0 : 11 - modulo11;

  return nif[8] == comparador;
}

function validateId(number) {
  return check(number);
}

function validateEmail(email) {
  return String(email)
    .toLowerCase()
    .match(
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
    );
}




function validateNib(nib) {
  if (!nib.match(/^\d{1,21}$/)) {
    return false;
  }

  const pesos = [
    0, 1, 10, 3, 30, 9, 90, 27, 76, 81, 34, 49, 5, 50, 15, 53, 45, 62, 38, 89, 17, 73, 51, 25, 56,
    75, 71, 31, 19, 93, 57
  ];

  let s = nib.split('');
  let r = s.reverse();
  let tot = 0;

  for (let i = 2; i < r.length; i++) {
    tot += r[parseInt(i)] * pesos[parseInt(i) + 1];
  }

  let rest = tot % 97;
  let val = parseInt(98 - rest);

  if (val === parseInt(nib.substring(nib.length - 2), 10)) {
    return true;
  } else {
    return false;
  }
}

function validateFixedSizeNumber(value, numberOfDigits) {
  let regexPattern;
  if(numberOfDigits === 4){
    regexPattern = `^(?!0)\\d{${numberOfDigits}}$`;
  }else{
    regexPattern = `^(?=.*[1-9])\\d{${numberOfDigits}}$`;
  }
  
  const regex = new RegExp(regexPattern);

  // const regex= new RegExp(`^[0-9]{${numberOfDigits}}$`);
  return regex.test(value);
}

function validatePhone(value) {
  const regex= new RegExp(`^[0-9]{9}$`);
  return regex.test(value) && (value.startsWith("2") || value.startsWith("3") || value.startsWith("4"))
}

function validateMobile(value) {
  const regex= new RegExp(`^[0-9]{9}$`);
  return regex.test(value) && value.startsWith("9")
}

function validateCommsNumber(value) {
  const regex= new RegExp(`^[0-9]{9}$`);
  return regex.test(value) && (value.startsWith("2") || value.startsWith("3") || value.startsWith("4") || value.startsWith("9"))
}