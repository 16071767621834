import React, { useEffect, useState } from 'react';
import parse from 'html-react-parser';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link, useParams } from 'react-router-dom';
import { getCandidatureHistory } from "../../rest/candidature";
import Loading from "../general/Loading";
import { handleError, isNotBusinessError } from "../../utils/handleError";
import { SubTemplate } from "../general/SubTemplate";
import { AlertError } from "../bootstrap/AlertError";
import { BoundType } from "../../models/BoundType";
import { FaArrowCircleDown, FaArrowCircleUp } from "react-icons/fa";
import { Col, Form, Row } from 'react-bootstrap';
import { AuthenticatedDownloadLink } from "../document/AuthenticatedDownloadLink";
import { getAcceptedPriorSupportByCandidature } from '../../rest/priorSupport';
import { isIEFPUser } from '../../authentication/authenticationHelper';


export function CandidatureHistoric() {
    const { externalId } = useParams();
    const [loading, setLoading] = useState(true);
    const [candidatureHistory, setCandidatureHistory] = useState({});
    const [priorSupport, setPriorSupport] = useState({});
    const [error, setError] = useState(null);

    const intl = useIntl();

    const fetchData = async () => {
        try {
            const [
                { data: candidatureHistory },
                { data: priorSupport }
            ] = await Promise.all([
                await getCandidatureHistory(externalId),
                await getAcceptedPriorSupportByCandidature(externalId),
            ]);

            setPriorSupport(priorSupport);
            setCandidatureHistory(candidatureHistory);
        } catch (error) {
            setError(error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    if (loading) {
        return <Loading />;
    }

    if (error && isNotBusinessError(error)) {
        return handleError(error);
    }

    return (
        <SubTemplate
            hasBackButton
            titleId='candidatureHistory.title'
            titleValues={{
                candidatureCode: candidatureHistory.candidatures[0].externalId
            }}
        >
            <div className={'container'}>
                <div className={'row accordion-panel'}>
                    <div className={'col-lg-12'}>
                        <AlertError error={error} />
                        <div>
                            <h4>Candidatura{' '}
                                {candidatureHistory.candidatures[1] ?
                                    (
                                        candidatureHistory.candidatures[1].processId
                                    ) : (
                                        <FormattedMessage id='candidature.history.processState.DRAFT' />
                                    )}
                            </h4>
                        </div>

                        <div className='mb-4'>
                            {priorSupport ?
                                <>
                                    <span className='text-secondary'>
                                        <FormattedMessage id='candidature.history.priorSupport' />:{' '}
                                    </span>
                                    {isIEFPUser() ?
                                        <Link
                                            to={`/entidades/${priorSupport.nif}`}
                                        >
                                            {priorSupport.entityName}
                                        </Link>
                                        :
                                        <span>
                                            {priorSupport.entityName}
                                        </span>
                                    }
                                </>
                                :
                                <span>
                                    <FormattedMessage id='candidature.history.noPriorSupport' />
                                </span>
                            }

                        </div>



                        {candidatureHistory.candidatures.map((candidature, index) => (
                            <div key={index} className='form row accordion  pt-3 pb-3 mb-4'>
                                <div className='col-lg-4'>
                                    <label>
                                        <FormattedMessage id='candidature.stateText' />
                                    </label>
                                    <span>
                                        {' '}
                                        {intl.formatMessage({
                                            id: 'candidatureState.' + candidature.state,
                                        })}
                                    </span>
                                </div>
                                <div className='col-lg-4'>
                                    <label>
                                        <FormattedMessage id='candidature.changeDateText' />
                                    </label>
                                    <span> {candidature.changeDate}</span>
                                </div>
                                {candidature.emitOpinionEntity &&
                                    <div className='col-lg-4'>
                                        <label>
                                            <FormattedMessage id='candidature.flow.opinionEntity' />
                                        </label>
                                        <span> {candidature.emitOpinionEntity}</span>
                                    </div>
                                }

                                {candidature.messages?.length !== 0 && (
                                    <small className='text-primary text-uppercase mt-5 d-block mb-1'>
                                        <FormattedMessage id='messages.list' />
                                    </small>
                                )}

                                <div className='messagesHistoric'>
                                    {candidature.messages?.map((message) => (
                                        <div key={message.externalId} className='row  pb-3 border-bottom' >
                                            <div className='col-lg-9'>
                                                <div className='row'>
                                                    <div className='col-lg-12'>
                                                        <label>
                                                            <FormattedMessage id='candidature.history.MessageTitle' />
                                                        </label>
                                                        <span> {message.title}</span>
                                                    </div>

                                                    <div className='col-lg-12 mt-2'>
                                                        <label>
                                                            <FormattedMessage id='candidature.history.MessageBody' />
                                                        </label>
                                                        <span> {parse(message.body)}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-lg-3 text-end'>
                                                {message.boundType === BoundType.IN ? (
                                                    <><FaArrowCircleDown color={'green'} /><span> {message.creationTs}</span></>
                                                ) : (<><FaArrowCircleUp color={'#EB4F24'} /><span> {message.creationTs}</span></>)}
                                            </div>


                                        </div>
                                    ))}

                                </div>

                                <div className='mt-5'>
                                    {candidature.documents?.length > 0 && (
                                        <>
                                            <Row className='border-bottom'><Col>
                                                <small className='text-primary text-uppercase'>
                                                    <FormattedMessage id='documents.list' />
                                                </small>
                                            </Col></Row>



                                            <Row className='mt-3'>
                                                <Col md={3}>
                                                    <label>
                                                        <FormattedMessage id='candidature.history.document.date' />
                                                    </label>
                                                </Col>

                                                <Col md={5}>
                                                    <label>
                                                        <FormattedMessage id='candidature.history.document.documentType' />
                                                    </label>
                                                </Col>
                                                <Col md={4}>
                                                    <label>
                                                        <FormattedMessage id='candidature.history.document.file' />
                                                    </label>
                                                </Col>
                                            </Row>
                                        </>
                                    )}
                                    {candidature?.documents?.map((d, index) => (
                                        <Row key={index} className='border-top pt-2 pb-2'>
                                            <Col md={3}>{d.creationTs}</Col>
                                            <Col md={5}> {intl.formatMessage({
                                                id: 'documentType.' + d.documentType,
                                            })}</Col>
                                            <Col md={4}>
                                                <AuthenticatedDownloadLink
                                                    url={d.documentPath}
                                                    filename={d.name}
                                                >
                                                    <small>{(d.fileDescription ? (d.fileDescription + ' : ') : '') + d.name}</small>
                                                </AuthenticatedDownloadLink>

                                            </Col>


                                        </Row>
                                    ))}


                                </div>

                            </div>
                        ))}



                    </div>
                </div>
            </div>
        </SubTemplate>
    );
}

