import {CandidatureValidation} from '../../components/candidature/CandidatureValidation';
import {approve} from '../../rest/candidature';
import {useNavigate, useParams} from 'react-router-dom';
import React, {useState} from "react";
import {Arrays} from "../../utils/Arrays";
import {createMultiPart} from "../../components/SyncSingleUploadArea";

export function ApproveCandidature() {
    const {externalId} = useParams();
    const [error, setError] = useState(null);

    async function approveCandidatureHandler(publicDocument, privateDocument) {
        const documents = [];
        documents.push(publicDocument, privateDocument);
        try {
            if (Arrays.isNotEmpty(documents)) {
                const formData = createMultiPart(documents);
                await approve(externalId, formData);
            }

            sessionStorage.setItem('approveCandidature', 'true')
        } catch (error) {
            console.log(error);
            setError(error);
        }
    }


    return (
        <CandidatureValidation
            validationTitle='candidatureValidation.title.approve'
            messageConfirmation='candidatureValidation.message.approve'
            callbackHandler={approveCandidatureHandler}
            isApprove={true}
        />
    );

}
