export const EntityChangeType = {

    HR: 'HR',
    LOCATION: 'LOCATION',
    CONTACT: 'CONTACT',
    GEO: 'GEO',


  values: () => {
    return ['HR', 'LOCATION','CONTACT','GEO'];

  }
};
