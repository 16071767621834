import React, { useEffect, useState } from 'react';
import {Col, Container, Form, InputGroup, Row} from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import {Link, useParams} from 'react-router-dom';
import {
  hasRole, isBackOfficeAdmin,
  isIEFPUser,
  isPromoterUser
} from '../../authentication/authenticationHelper';
import { AlertError } from '../../components/bootstrap/AlertError';
import { CustomPagination } from '../../components/general/CustomPagination';
import { EmptyResults } from '../../components/general/EmptyResults';
import Loading from '../../components/general/Loading';
import { SubTemplate } from '../../components/general/SubTemplate';
import { CandidatureState } from '../../models/CandidatureState';
import { getListCandidatures } from '../../rest/candidature';
import { Arrays } from '../../utils/Arrays';
import { handleError, isNotBusinessError } from '../../utils/handleError';
import {TextInputField} from "../../components/bootstrap/TextInputField";
import ToolTip from "../../components/bootstrap/ToolTip";
import IntInputField from "../../components/bootstrap/IntInputField";
import {KeycloakObject} from "../../keycloak";

const MAX_RESULTS = 10;
const DEFAULT_INITIAL_PAGE = 0;

export function ListCandidatures() {
  const intl = useIntl();
  const { entityNif: entityNif,inputState:inputState,entityType:entityType } = useParams();


  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [candidatureList, setCandidatureList] = useState([]);
  const [numberOfResults, setNumberOfResults] = useState(null);
  const [totalResults, setTotalResults] = useState(null);
  const [currentPage, setCurrentPage] = useState(DEFAULT_INITIAL_PAGE);
  const [state, setState] = useState(null);
  const [rd, setRd] = useState(null);
  const [search, setSearch] = useState('');
  const [states, setStates] = useState(null);
  const rds =['DN','DC','DL','DA','DG'];

  async function fetchData() {
    try {
      if (states === null) {
        setStates(
          (isIEFPUser() || isBackOfficeAdmin())
            ? CandidatureState.valuesSortedByFlux().filter((value) => value !== 'DRAFT'  && value !== 'CANCEL')
            : CandidatureState.valuesSortedByFlux()
        );
      }

      const [{ data: candidatureList }] = await Promise.all([

        await getListCandidatures(currentPage, MAX_RESULTS, inputState === undefined? state: inputState,search,(entityNif!==undefined && entityNif!=='undefined')? entityNif:null  ,entityType,rd),
      ]);
      setCandidatureList(candidatureList.results);
      setNumberOfResults(candidatureList.numberOfResults);
      setTotalResults(candidatureList.totalResults);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  }

  const onPageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleStateChange = (e) => {
    if (e.target.value === '') {
      setState(null);
    } else {
      setState(e.target.value);
    }
    setCurrentPage(0);
  };


  const handleRdChange = (e) => {
    if (e.target.value === '') {
      setRd(null);
    } else {
      setRd(e.target.value);
    }
    setCurrentPage(0);
  };


  const handleSearchChange = (e) => {
    if (e.target.value === '') {
      setSearch(null);
    } else {
      setSearch(e.target.value);
    }
  };


  const handleBlur = (e) => {
    if (e.target.value === '') {
      setSearch(null);
    } else {
      setSearch(e.target.value);
    }
    setCurrentPage(0);
  };



  useEffect(() => {
    fetchData();
  }, [currentPage,]);

  if (loading) {
    return <Loading />;
  }

  if (error && isNotBusinessError(error)) {
    return handleError(error);
  }

  return (
    <SubTemplate titleId={'candidature.list.title'} hasBackButton>
      <Container>
        {(!isPromoterUser() && inputState=== undefined) && (
          <Row>
            <Col md='3'>
              <Form.Group controlId='stateFilter'>
                <Form.Select
                  as='select'
                  name='state'
                  value={state === null ? '' : state}
                  onChange={handleStateChange}
                >
                  <option key='all' value=''>
                    {intl.formatMessage({ id: 'messages.allStateText' })}
                  </option>
                  {states.map((state) => (
                    <option key={state} value={state}>
                      {intl.formatMessage({ id: `candidatureState.${state}` })}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Col>


            {isBackOfficeAdmin() &&


            <Col md='3'>
              <Form.Group controlId='rdFilter'>
                <Form.Select
                    as='select'
                    name='rd'
                    value={rd === null ? '' : rd}
                    onChange={handleRdChange}
                >
                  <option key='all' value=''>
                    {intl.formatMessage({ id: 'messages.allRD' })}
                  </option>
                  {rds.map((rdiT) => (
                      <option key={rdiT} value={rdiT}>
                        {intl.formatMessage({ id: `regionalDelegation.${rdiT}` })}
                      </option>
                  ))}
                </Form.Select>
              </Form.Group>

            </Col>
            }


            {(isIEFPUser() || isBackOfficeAdmin()) &&
            (

                  <Col md='3'>

                    <TextInputField
                        name={'search'}
                        value={search}
                        handleChange={(e) => handleSearchChange(e)}
                        placeholder={'Id Candidatura, ProcessId, Nome Promotor'}
                    />


                  </Col>
            )
            }
            <Col md={3}>
              <button
                  className='btn btn-outline-primary'
                  type='button'
                  onClick={
                    fetchData
                  }
              >
                <FormattedMessage id='all.search' />
              </button>

            </Col>

          </Row>
        )}

        <Row>
          <Col md='10'>
            <AlertError error={error} />

            {Arrays.isNotEmpty(candidatureList) ? (
              <>
                <div className='row mt-5 text-secondary'>
                  <div className='col-md-5 d-flex align-items-center'>
                    <FormattedMessage id='messages.candidatureText' />
                  </div>
                  <div className='col-md-5 d-flex align-items-center'>
                    <FormattedMessage id='messages.stateText' />
                  </div>
                  <div className='col-md-2 d-flex align-items-center justify-content-end'>
                    <FormattedMessage id='candidatureTabs.changeDate' />
                  </div>
                </div>
                <ul className='results'>
                  {candidatureList.map((candidature, index) => (
                    <li key={candidature.externalId}>
                      <div className='row py-2'>
                        <div className='col-md-5 d-flex align-items-center'>
                          <Link
                            to={'/candidatura/' + candidature.externalId}
                            state={hasRole('ENTITY') ? { isEntity: true } : {}}
                          >
                            {(candidature.processId !=null ? candidature.processId : candidature.id)}
                          </Link>
                        </div>
                        <div className='col-md-5 d-flex align-items-center'>
                          <FormattedMessage
                            id={`candidatureState.${candidature.state}`}
                          />
                        </div>
                        <div className='col-md-2 d-flex align-items-center justify-content-end'>
                          {candidature.changeDate}
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              </>
            ) : (
              <EmptyResults />
            )}
          </Col>
        </Row>
        <Row className='mb-5'>
          <Col className='d-flex justify-content-center'>
            <CustomPagination
              dataPerPage={MAX_RESULTS}
              totalData={totalResults}
              onPageChange={onPageChange}
              currentPage={currentPage}
            />
          </Col>
        </Row>
      </Container>
    </SubTemplate>
  );
}
