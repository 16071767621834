import {getCandidatureByExternalId} from "../../../rest/candidature";
import {doesNotHaveIrregularitiesNorNonCompliances, getPayments} from "../../../rest/candidaturePayments";
import React, {useEffect, useState} from "react";
import Loading from "../../../components/general/Loading";
import {getEntity, setNIPC} from "../../../rest/entity";
import {Link, Navigate, useNavigate} from "react-router-dom";
import {submitNibRequest} from "../../../rest/nibRequest";
import {SubTemplate} from "../../../components/general/SubTemplate";
import {ProcessState} from "../../../models/ProcessState";
import {Col, Container, Form, Row} from "react-bootstrap";
import {AlertError} from "../../../components/bootstrap/AlertError";
import {AlertSuccess} from "../../../components/bootstrap/AlertSuccess";
import {FormattedMessage, useIntl} from "react-intl";
import AlertInfo from "../../../components/bootstrap/AlertInfo";
import {FormSideNavBar} from "../../../components/general/FormSideNavBar";
import {SuccessToast} from "../../../components/general/SuccessToast";
import {Formik} from "formik";
import * as yup from "yup";
import {Validator} from "../../../utils/Validator";
import {TextInputField} from "../../../components/bootstrap/TextInputField";
import {UserState} from "../../../models/UserState";
import {getAccessInfo, getUserInfo, saveUserInfoInStorage} from "../../../authentication/authenticationHelper";

const validationSchema = yup.object().shape({
    nipc: yup.string()
            .test(
                'isNIPCValid',
                <FormattedMessage id='errors.promoterForm.nif' />,
                (nipc) => Validator.validateNif(nipc)
            )
});

export function EntityIdentification({}) {
    const [loading, setLoading] = useState(true);
    const [entity, setEntity] = useState();
    const [error, setError] = useState(null);
    const [submited, setSubmited] = useState(false);
    const [showSaveToast, setShowSaveToast] = useState(false);

    const accessInfo = getAccessInfo();
    const userInfo = getUserInfo();
    const intl = useIntl();
    const navigate = useNavigate();

    const fetchData = async () => {
        try {
            const [{ data: entityDto }] = await Promise.all([
                getEntity()
            ]);
            setEntity(entityDto);
        } catch (error) {
            setError(error);
        } finally {
            setLoading(false);
        }
    };

    function needsNIF(entityDto) {
        return entityDto === undefined || entityDto === null || !('nif' in entityDto);
    }

    async function handleSubmit(values, setSubmitting, setFieldError) {
        if(!needsNIF(entity)) {
            return;
        }
        try{
             const { data: nipcResult } = await setNIPC(values.nipc);
             if(nipcResult === true) {
                 setSubmited(true);
             } else {
                 setFieldError('nipc', <FormattedMessage id={'errors.eaAccreditationForm.nipc.notAllowed'} /> );
             }
         } catch (error) {
             setError(error);
         }
        setShowSaveToast(true);
    }



    useEffect(() => {
        fetchData();
    }, []);

    if (loading) {
        return <Loading />;
    }

    if(userInfo.userState === UserState.VALIDATE && !needsNIF(entity)) {
        return (<Navigate to='/credenciacaoEntidade/adicionar' />);
    }

    if (submited || !needsNIF(entity)) {
       return (<Navigate to='/' />);
    }

    return (
        <SubTemplate
            titleId={'entity.nipc.title'}
            subTitleId={`entity.nipc.subTitle`}
            centerContent={true}
        >
            <Container>
                <Row>
                    <Col>
                        <AlertError error={error} />
                        <SuccessToast
                            message={intl.formatMessage({
                                id: submited ? 'entity.nipc.success' : 'errors.eaAccreditationForm.nipc',
                            })}
                            show={showSaveToast}
                            setShow={setShowSaveToast}
                        />
                        <Formik
                            initialValues={{nipc: ''}}
                            validateOnBlur={false}
                            validateOnChange={false}
                            validationSchema={validationSchema}
                            onSubmit={(values, { setSubmitting, setFieldError }) => {
                                handleSubmit(values, setSubmitting, setFieldError);
                            }}
                        >
                            {(formikProps) => (
                                <Form className='offset-md-2 col-md-8' onSubmit={formikProps.handleSubmit}>
                                    <Row>
                                        <Col>
                                            <div>
                                                <label>
                                                    <FormattedMessage id='eaPromoterStep.nipc' />
                                                </label>
                                                <div>
                                                    <TextInputField
                                                        name='nipc'
                                                        value={formikProps.values.nipc}
                                                        isInvalid={formikProps?.errors?.nipc}
                                                        errorMessage={formikProps.errors.nipc}
                                                        handleChange={formikProps.handleChange}
                                                    />
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className='mt-3'>
                                        <Col>
                                            <button className='btn btn-primary' type='submit' >
                                                <FormattedMessage id='all.submitButtonText' />
                                            </button>
                                        </Col>
                                    </Row>
                                </Form>
                            )}
                        </Formik>
                    </Col>
                </Row>
            </Container>
        </SubTemplate>
    );
}