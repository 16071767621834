import {Modal, Button, Form, Col} from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import React, { useState } from "react";
import {MultipleUploadArea} from "../MultipleUploadArea";
import useIntl from "react-intl/lib/src/components/useIntl";
import {SingleUploadArea} from "../SingleUploadArea";

export function CandidatureChangeStateWithReason({ modalTitleId, modalReasonTextId, flowActionId, submitHandler,documentType,userDocuments,setUserDocuments, documentType2,userDocuments2,setUserDocuments2,singles, documentsRequired,setError, externalId,className }) {
  const [show, setShow] = useState(false);
  const [validated, setValidated] = useState(false);
  const intl = useIntl();
  const [reason,setReason] = useState('');

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);




  function onSubmit(event) {
    event.preventDefault();
    const form = event.currentTarget;


    if (form.checkValidity() === true) {
      const formData = new FormData(form),
          formDataObj = Object.fromEntries(formData.entries());
      handleClose();
      if(submitHandler !== undefined){
        submitHandler(formDataObj);
      }
    } else {
      event.stopPropagation();
    }

    setValidated(true);



  }

  return (
    <>
      <Modal size="lg" show={show} onHide={handleClose}>
        <Form noValidate validated={validated} onSubmit={onSubmit}>
          <Modal.Header>
            <FormattedMessage id={modalTitleId} />
          </Modal.Header>
          <Modal.Body>
            {(!documentType || !documentType2) &&
              <Form.Group>
                <Form.Label><FormattedMessage id={modalReasonTextId} /></Form.Label>



                <Form.Control as="textarea" name="reason" value={reason} onChange={event => setReason(event.target.value)} rows={4} required />

              </Form.Group>
            }
            {(documentType && !singles) && (
           <div className={"mt-2"}>
             <span className={"labelCompact"}>
                {intl.formatMessage({ id: `documentType.${documentType}` })}</span>
            <MultipleUploadArea
                documents={userDocuments}
                documentType={documentType}
                candidatureExternalId={externalId}
                setDocuments={setUserDocuments}
            />
           </div>
            )}

            {documentType2 && !singles && (
                <div className={"mt-3"}>
             <span className={"labelCompact"}>
                {intl.formatMessage({ id: `documentType.${documentType2}` })}</span>
                  <MultipleUploadArea
                      documents={userDocuments2}
                      documentType={documentType2}
                      candidatureExternalId={externalId}
                      setDocuments={setUserDocuments2}

                  />
                </div>
            )}

            {documentType && singles && (
                <div className={"mt-2"}>
                  <SingleUploadArea
                      documents={userDocuments}
                      setDocuments={setUserDocuments}
                      documentType={documentType}
                      candidatureExternalId={externalId}
                      setError={setError}
                  />
                </div>
            )}

            {documentType2 && singles && (
                <div className={"mt-3"}>
                  <SingleUploadArea
                      documents={userDocuments2}
                      setDocuments={setUserDocuments2}
                      documentType={documentType2}
                      setError={setError}
                      candidatureExternalId={externalId}
                  />
                </div>
            )}


          </Modal.Body>
          <Modal.Footer>


            <Button variant="outline-secondary" onClick={handleClose}   >
              <FormattedMessage id="all.cancel" />
            </Button>
            <Button type="submit" variant="outline-primary" disabled={((documentsRequired && (userDocuments?.[0].submited!==true || userDocuments2?.[0].submited!==true))  || (!documentsRequired &&   reason=='')) } >
              <FormattedMessage id="all.send" />
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
      {className ? (<Button variant="outline-primary" className={className} onClick={handleShow} >
        <FormattedMessage id={flowActionId} />
      </Button>) : (<Button variant="outline-primary" onClick={handleShow} >
        <FormattedMessage id={flowActionId} />
      </Button>)}

    </>
  );
}
