import React, {useEffect, useState} from 'react';
import {Accordion, Col, Form, Row} from 'react-bootstrap';
import {FaCheckCircle} from 'react-icons/fa';
import {FormattedMessage} from 'react-intl';
import {PromoterType} from '../../models/PromoterType';
import {TranslateBoolean} from '../../utils/TranslateBoolean';
import {maxFixed} from "../../utils/CurrencyUtils";
import {getCae} from "../../rest/candidature";
import {handleError, isNotBusinessError} from "../../utils/handleError";
import {AuthenticatedDownloadLink} from "../document/AuthenticatedDownloadLink";

export function ResumeTab({candidature,documents}) {

    const [caeDescription, setCaeDescription] = useState('');
    const [error, setError] = useState(null);
    const documentTerms = documents?.iefpDocuments?.filter(d=> d.documentType==='TERM_ACCEPT');



    async function fetchData() {
        try {
            const [{data: cae}] = await Promise.all([
                await getCae(
                    candidature.candidatureDetails.cae
                ),
            ]);
            setCaeDescription(cae.description);
        } catch (error) {
            setError(error);
        }
    }

    useEffect(() => {
        fetchData();
    }, []);


    if (error && isNotBusinessError(error)) {
        return handleError(error);
    }

    return (
        <>
            <div className='border-bottom py-3 mb-4'>
                <Row>

                    <Col md='4'>
                        <label>
                            <FormattedMessage id={'economicViabilityFormStep1.regionalDelegation'}/>
                        </label>
                        <p>
                        <FormattedMessage id={'regionalDelegation.'+candidature.regionalDelegationCode}/>
                        </p>
                    </Col>

                    <Col md='4'>
                        <label>
                            <FormattedMessage id='candidatureTabs.processId'/>
                        </label>
                        <p>
                            {candidature.processId ? (
                                candidature.processId
                            ) : (
                                <FormattedMessage id='candidatureTabs.NA'/>
                            )}
                        </p>
                    </Col>
                    <Col md='4'>
                        <label>
                            <FormattedMessage id='candidatureTabs.externalId'/>
                        </label>
                        <p>{candidature.id}</p>
                    </Col>
                    <Col md='4'>
                        <label>
                            <FormattedMessage id='messages.stateText'/>
                        </label>
                        <p>
                            <FormattedMessage id={`candidatureState.${candidature.state}`}/>
                        </p>
                    </Col>

                    {candidature.submissionDate && (
                        <Col md='4'>
                            <label>
                                <FormattedMessage id='candidatureTabs.submissionDate'/>
                            </label>
                            <p>{candidature.submissionDate}</p>
                        </Col>
                    )}
                    <Col md='4'>
                        <label>
                            <FormattedMessage id='candidatureTabs.changeDate'/>
                        </label>
                        <p>{candidature.changeDate}</p>
                    </Col>

                    {candidature.requestOpinionDate && (
                        <Col md='4'>
                            <label>
                                <FormattedMessage id='candidatureTabs.requestOpinionDate'/>
                            </label>
                            <p>{candidature.requestOpinionDate}</p>
                        </Col>
                    )}
                    {candidature.submitOpinionDate && (
                        <Col md='4'>
                            <label>
                                <FormattedMessage id='candidatureTabs.submissionOpinionDate'/>
                            </label>
                            <p>{candidature.submitOpinionDate}</p>
                        </Col>
                    )}

                    <Col md='4'>
                        <label>
                            <FormattedMessage id='candidatureForm.wasCreated'/>
                        </label>
                        <p>{candidature.candidatureDetails.wasCreated === true ? <FormattedMessage id='all.yes'/> :
                            <FormattedMessage id='all.no'/>}</p>
                    </Col>

                    <Col md='4'>
                        <label>
                            <FormattedMessage id='candidatureForm.forecastDate'/>
                        </label>
                        <p>{candidature.candidatureDetails.forecastDate}</p>
                    </Col>
                </Row>
                <Row className={'border-top mt-3 border-1 pt-3'}>
                    {candidature.parishName && (
                        <Col md='6'>
                            <label>
                                <FormattedMessage id='candidatureTabs.location'/>
                            </label>
                            <p>{candidature.parishName}</p>
                        </Col>
                    )}
                    {candidature?.candidatureDetails?.cae && (
                        <Col md='6'>
                            <label>
                                <FormattedMessage id='candidatureTabs.cae'/>
                            </label>
                            <p>{candidature.candidatureDetails.cae} - {caeDescription}</p>
                        </Col>
                    )}

                    <Col md='6'>
                        <label>
                            <FormattedMessage id='candidatureForm.companyTypology'/>
                        </label>
                        <p>
                            {candidature.candidatureDetails?.companyTypology ? (
                                <FormattedMessage
                                    id={`companyTypology.${candidature.candidatureDetails.companyTypology}`}
                                />
                            ) : (
                                ''
                            )}
                        </p>
                    </Col>
                    <Col md='6'>
                        <label>
                            <FormattedMessage id='candidatureForm.artsAndCrafts'/>

                        </label>
                        <p>
                            <TranslateBoolean
                                value={candidature.candidatureDetails?.artsAndCrafts}
                            />
                        </p>
                    </Col>
                </Row>

                {(candidature.candidatureDetails.registerLink !== '' && candidature.candidatureDetails.wasCreated===true) &&
                <Row>
                    <Col md='12'>
                        <label>
                            <FormattedMessage id='candidatureDetails.registerLink'/>
                        </label>
                        <p>{candidature.candidatureDetails.registerLink}</p>
                    </Col>
                </Row>
                }

                <Row>
                    <Col md='12'>
                        <label>
                            <FormattedMessage id='candidatureTabs.possibleIncentives'/>
                        </label>
                        <p>{candidature.candidatureDetails?.possibleIncentives}</p>
                    </Col>
                    <Col md='12'>
                        <label>
                            <FormattedMessage id='candidatureTabs.projectCharacterization'/>
                        </label>
                        <p>
                            {candidature.candidatureDetails?.projectCharacterization
                                ? candidature.candidatureDetails.projectCharacterization
                                : ''}
                        </p>
                    </Col>
                    <Col md='12'>
                        <label>
                            <FormattedMessage id='candidatureForm.installations'/>
                        </label>
                        <p>
                            {candidature.candidatureDetails?.installations
                                ? candidature.candidatureDetails.installations
                                : ''}
                        </p>
                    </Col>
                    <Col md='12'>
                        <label>
                            <FormattedMessage id='candidatureForm.targetMarket'/>
                        </label>
                        <p>
                            {candidature.candidatureDetails?.targetMarket
                                ? candidature.candidatureDetails.targetMarket
                                : ''}
                        </p>
                    </Col>
                </Row>




            </div>

            {candidature.term && (
                <div className='border-bottom border-1'>
                    <p >
                        <strong className='mt-3'><FormattedMessage id={'candidatureForm.resumeTab.termAcceptanceRegister.title'}/></strong>
                    </p>
                    <Row>
                        <Col md='4'>
                            <label>
                                <FormattedMessage id='termAcceptanceRegister.isAccordingTo'/>
                            </label>
                            <p>  {candidature.term.isAccordingTo=== true ? <FormattedMessage id='all.yes'/> : <FormattedMessage id='all.no'/>}</p>
                        </Col>
                        <Col md='4'>
                            <label>
                                <FormattedMessage id='termAcceptanceRegister.sendDate'/>
                            </label>
                            <p>  {candidature.term.sendDate}</p>
                        </Col>
                        <Col md='4'>
                            {candidature.term.receptionDate && (
                                <>
                                    <label>
                                        <FormattedMessage id='termAcceptanceRegister.receptionDate'/>
                                    </label>
                                    <p>  {candidature.term.receptionDate}</p>
                                </>
                            )
                            }

                    </Col>
                    </Row>
                    {documentTerms?.[0]?.documentPath &&
                    <Row className={'mb-3'}>
                        <Col md={12}>
                            <div>
                                <label>
                                    <FormattedMessage id='candidatureForm.resumeTab.termAcceptanceRegister.document'/>
                                </label>
                                <p>
                                <AuthenticatedDownloadLink url={documentTerms[0].documentPath} filename={documentTerms[0].name}>
                                    <small>{documentTerms[0].name}</small>
                                </AuthenticatedDownloadLink>
                                </p>
                            </div>


                        </Col>
                    </Row>
                    }

                </div>
            )

            }

            {candidature.candidatureDetails.wasCreated !== true && (
                <div className='border-bottom border-1'>
                    <p >
                        <strong className='mt-3'><FormattedMessage id={'project.phase'}/></strong>
                    </p>
                    <Row>
                        <Col md='12'>
                            <label>
                                <FormattedMessage id='candidatureDetails.registerLink'/>
                            </label>
                            <p>{candidature.candidatureDetails.registerLink}</p>
                        </Col>
                    </Row>
                </div>
            )




            }
            <Accordion defaultActiveKey={['promoters']} alwaysOpen>
                <Accordion.Item eventKey="promoters">
                    <Accordion.Header> <FormattedMessage
                        id='promoters.title'/></Accordion.Header>
                    <Accordion.Body>

                        {candidature.promotersMetaData.map((pmd, index) => (
                            <Row key={index}>
                                <Col>
                                    <label>
                                        <FormattedMessage
                                            id={`promoterType.${pmd.promoterType}`}/> {pmd.promoterType !== 'OTHER' && (<>- <FormattedMessage
                                        id={`promoterForm.gender.${pmd.gender}`}/></>)}
                                    </label>
                                    <p className='mb-0'>
                                        {pmd.name}{' '}
                                        {pmd.validated && pmd.promoterType !== PromoterType.PRIMARY && (
                                            <FaCheckCircle color='green' margin-booton='2px'/>
                                        )}
                                    </p>

                                    <p className='mb-4 text-secondary'>
                                        <small>NIF: {pmd.nif} - Email: {pmd.email}</small>
                                        {pmd.promoterType !== 'OTHER' && (
                                            <>
                                                <br/>
                                                <small><FormattedMessage
                                                    id={'promoterMetaData.socialCapital'}/>: {maxFixed(pmd.socialCapital)}%
                                                    - <FormattedMessage
                                                        id={'promoterMetaData.voteRight'}/>: {maxFixed(pmd.voteRight)}%
                                                </small>

                                                <br/>
                                                <small>Nivel de Qualificação: <FormattedMessage
                                                    id={`qualificationLevel.${pmd.qualificationLevel}`}
                                                /> </small>
                                            </>
                                        )}
                                        {pmd.age &&
                                        <small>  {pmd.qualificationLevel ? " - " : ""} Idade: {pmd.age}</small>}
                                        <br/>
                                        <small>{TranslateBoolean({
                                            value: pmd.applyUnemploymentBenefits,
                                            trueLabelId: 'requiredUnemploymentBenefits',
                                            falseLabelId: 'notRequiredUnemploymentBenefits'
                                        })}</small><br/>
                                        <small><strong><FormattedMessage id={'authorizations'}/></strong></small><br/>
                                        <small className='mx-3'>- {TranslateBoolean({
                                            value: pmd.ssDeclarationAuthorized,
                                            trueLabelId: 'allowAccess.ss',
                                            falseLabelId: 'not.allowAccess.ss'
                                        })}</small><br/>
                                        <small className='mx-3'> - {TranslateBoolean({
                                            value: pmd.atDeclarationAuthorized,
                                            trueLabelId: 'allowAccess.at',
                                            falseLabelId: 'not.allowAccess.at'
                                        })}</small>

                                    </p>
                                </Col>
                            </Row>
                        ))}


                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="veracityDeclaration">
                    <Accordion.Header> <FormattedMessage
                        id='candidatureTabs.declaration'/></Accordion.Header>
                    <Accordion.Body>

                        <Form.Label className='mb-3'>
                            <FormattedMessage id='candidatureForm.veracityDeclaration.label'/>
                        </Form.Label>
                        <div className='p-3 bg-light mb-3'>
                            <p>
                                <FormattedMessage id='candidatureForm.veracityDeclaration.text1'/>
                            </p>
                            <p>
                                <FormattedMessage id='candidatureForm.veracityDeclaration.text2'/>
                            </p>
                            <p>
                                <FormattedMessage id='candidatureForm.veracityDeclaration.text3'/>
                            </p>
                            <p>
                                <FormattedMessage id='candidatureForm.veracityDeclaration.text4'/>
                            </p>
                            <p>
                                <FormattedMessage id='candidatureForm.veracityDeclaration.text5'/>
                            </p>
                            <p>
                                <FormattedMessage id='candidatureForm.veracityDeclaration.text6'/>
                            </p>
                        </div>
                        <Row>
                            <Col>
                                <Form.Check
                                    name={'veracityDeclaration'}
                                    label={<FormattedMessage id={'candidatureForm.veracityDeclaration'}/>}
                                    defaultChecked={candidature.veracityDeclaration}
                                    disabled={true}
                                />
                            </Col>
                        </Row>


                    </Accordion.Body>
                </Accordion.Item>


            </Accordion>


        </>
    );
}
