import { default as React, useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { TiPlus } from 'react-icons/ti';
import { FormattedMessage, useIntl } from 'react-intl';
import {isEntityUser} from '../../../authentication/authenticationHelper';
import { AlertError } from '../../../components/bootstrap/AlertError';
import { LabelToolTip } from '../../../components/bootstrap/LabelToolTip';
import { IncubatorContractDialog } from "../../../components/candidature/IncubatorContractDialog";
import { AuthenticatedDownloadLink } from "../../../components/document/AuthenticatedDownloadLink";
import { CustomPagination } from '../../../components/general/CustomPagination';
import { EmptyResults } from '../../../components/general/EmptyResults';
import Loading from '../../../components/general/Loading';
import { SubTemplate } from '../../../components/general/SubTemplate';
import { createIncubatorDocument, getIncubatorDocuments } from "../../../rest/incubation";
import { handleError, isNotBusinessError } from '../../../utils/handleError';
import { Arrays } from '../.././../utils/Arrays';


const MAX_RESULTS = 25;
const DEFAULT_INITIAL_PAGE = 0;

export function ListContractIncubation({entityNif}) {
    const [documents, setDocuments] = useState([]);
    const [numberOfResults, setNumberOfResults] = useState(null);
    const [totalResults, setTotalResults] = useState(null);
    const intl = useIntl();

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [showDialog, setShowDialog] = useState(false);
    const [currentPage, setCurrentPage] = useState(DEFAULT_INITIAL_PAGE);

    const fetchData = async () => {
        setError(null);
        try {
            const [{ data: dataResponse }] = await Promise.all([
                await getIncubatorDocuments(currentPage, MAX_RESULTS, entityNif),
            ]);
            setDocuments(dataResponse.results);
            setNumberOfResults(dataResponse.numberOfResults);
            setTotalResults(dataResponse.totalResults);
        } catch (error) {
            setError(error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, [currentPage]);


    const addIncubatorContract = async (values, setSubmitting) => {
        setLoading(true);

        try {
            let { file: formData, ...rest } = values;
            formData.set('incubatorContract', JSON.stringify(rest));

            await createIncubatorDocument(formData);
            fetchData();

        } catch (error) {

            console.log(error)
            if (error.response.data.exception === 'ObjectNotFoundException' && error.response.data.message === 'nif') {
                let error = intl.formatMessage({
                    id: 'errors.economicViabilityPaymentRequest.candidatureMentionsEmpty',
                })
                setError(error);
            } else {
                setError(error);
            }



        } finally {
            setShowDialog(false);
            setLoading(false);
            setSubmitting(false);
        }
    };









    const handleDialogClose = () => {
        setShowDialog(false);
    };


    const handleAddClick = () => {
        setShowDialog(true);
    };


    const onPageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    if (loading) {
        return <Loading />;
    }

    if (error && isNotBusinessError(error)) {
        return handleError(error);
    }

    return (
        <SubTemplate hasBackButton={!entityNif} titleId={'incubator.contract.list'}>
            <Container>
                {isEntityUser() && <Row >
                    <Col md='12'>
                        <button
                            className='btn btn-outline-primary mb-2'
                            onClick={() => handleAddClick()}
                        >
                            <TiPlus className='mb-1' aria-hidden='true' />
                            <FormattedMessage id='incubator.contract.add' />
                        </button>
                    </Col>
                </Row>}
                <Row>
                    <Col>
                        <AlertError error={error} />

                        {Arrays.isNotEmpty(documents) ? (
                            <>
                                <Row className='mt-5 mb-1 d-flex justify-content-between px-2'>
                                    <Col className='text-muted'>
                                        {numberOfResults}
                                        <FormattedMessage
                                            id={`incubator.contract.list.header.requests.${numberOfResults === 1 ? 'singular' : 'plural'
                                                }`}
                                        />
                                    </Col>
                                </Row>

                                <ul className='results'>
                                    {documents.map((document, index) => (
                                        <li key={index}>
                                            <Row
                                                className='py-3'
                                            >
                                                <Col md='4' className='d-flex align-items-center'>
                                                    <LabelToolTip
                                                        labelId={'incubator.nifPromoter'}
                                                        value={document.nifPromoter}
                                                    />
                                                </Col>
                                                <Col md='8' className='d-flex align-items-center'>
                                                    <AuthenticatedDownloadLink
                                                        url={document.documentPath}
                                                        filename={document.name}
                                                    >
                                                        {document.name}
                                                    </AuthenticatedDownloadLink>
                                                </Col>
                                            </Row>
                                        </li>
                                    ))}
                                </ul>
                            </>
                        ) : (
                            <EmptyResults />
                        )}
                    </Col>


                    <IncubatorContractDialog
                        show={showDialog}
                        handleClose={handleDialogClose}
                        handleSubmit={addIncubatorContract}
                    />


                </Row>
                <Row className='mb-5'>
                    <Col className='d-flex'>
                        <div className='mx-2'>
                            <CustomPagination
                                dataPerPage={MAX_RESULTS}
                                totalData={totalResults}
                                onPageChange={onPageChange}
                                currentPage={currentPage}
                            />
                        </div>
                    </Col>
                </Row>
            </Container>
        </SubTemplate>
    );
}
