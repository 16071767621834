import React from 'react';
import { Accordion, Col, Row, Table } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { EnumSelectField } from '../../components/bootstrap/EnumSelectField';
import { ForeCastType } from '../../models/ForeCastType';
import { CandidatureFormUtils } from '../../utils/CandidatureFormUtils';
import {currencyFormat, maxFixed} from '../../utils/CurrencyUtils';
import DecimalInputField from '../bootstrap/DecimalInputField';
import { TextAreaInputField } from '../bootstrap/TextAreaInputField';
import {FaInfoCircle} from 'react-icons/fa';
import {ConfigObject} from "../../config";

export function CandidatureFormStep17({
  errors,
  setFieldValue,
  handleChange,
  values,
  handleSave,
  handleGoBack,
  numberOfSteps
}) {
  const intl = useIntl();

  const rowIdPrefix = 'foreCastBalance';

  const activeNoCurrentRowIds = [
    { id: 'tangibleFixedAssets', type: null },
    { id: 'investmentProperties', type: 'SNC' },
    { id: 'intangibleAssets', type: null },
    { id: 'financialInvestments', type: null },
    { id: 'shareholders', type: null }
  ];

  const activeCurrentRowIds = [
    { id: 'inventories', type: null },
    { id: 'clients', type: null },
    { id: 'activeSuppliersAdvances', type: 'SNC' },
    { id: 'activePublicEntities', type: null },
    { id: 'currentShareholders', type: 'SNC' },
    { id: 'otherAccountsReceivable', type: 'SNC' },
    { id: 'deferrals', type: null },
    { id: 'otherFinancialAssets', type: null },
    { id: 'cashAndBankDeposits', type: null }
  ];

  const ownCapitalRowIds = [
    { id: 'paidInCapital', type: null },
    { id: 'ownShares', type: 'SNC' },
    { id: 'otherEquityInstruments', type: null },
    { id: 'opinionReward', type: 'SNC' },
    { id: 'legalReservations', type: 'SNC' },
    { id: 'otherReservations', type: 'SNC' },
    { id: 'reservations', type: 'NCM' },
    { id: 'transitedResults', type: null },
    { id: 'revaluationSurpluses', type: 'SNC' },
    { id: 'otherChangesOwnCapital', type: null },
    { id: 'netIncome', type: null }
  ];

  const passiveNoCurrentIds = [
    { id: 'provisions', type: null },
    { id: 'financingObtained', type: null },
    { id: 'otherBillsToPay', type: null }
  ];

  const passiveCurrentIds = [
    { id: 'providers', type: null },
    { id: 'advancesFromCustomers', type: 'SNC' },
    { id: 'passivePublicEntities', type: null },
    { id: 'passiveSuppliersAdvances', type: 'SNC' },
    { id: 'currentFinancingObtained', type: 'SNC' },
    { id: 'passiveDeferrals', type: null },
    { id: 'currentOtherBillsToPay', type: 'SNC' },
    { id: 'otherPassives', type: 'SNC' },
    { id: 'otherCurrentPassive', type: 'NCM' }
  ];

  function filterRowsByType(rows, isNCM) {
    if (isNCM) {
      return rows.filter((r) => r.type === null || r.type === ForeCastType.NCM);
    }

    return rows.filter((r) => r.type === null || r.type === ForeCastType.SNC);
  }

  function total(keysToSum, yearForecast) {
    let sum = 0;
    keysToSum.forEach((key) => (sum += parseFloat(yearForecast[key.id])));
    return sum;
  }

  function handleField(e, s, yearForecastIndex, id) {
    let columns;
    if (values.forecastBalanceList[0].type === ForeCastType.SNC) {
      columns = values.forecastBalanceList[0].forecastBalanceSNCColumnList;
    } else {
      columns = values.forecastBalanceList[0].forecastBalanceNCMColumnList;

    }


    columns[yearForecastIndex][id] = e.target.value;
    setFieldValue('forecastBalanceList', [...values.forecastBalanceList]);
  }

  function generateInputRows(rows, subTitleId, yearForecast, isNCM, yearForecastIndex) {
    let filteredRows = filterRowsByType(rows, isNCM);

    return (
      <>
        {subTitleId !== undefined && (
          <tr className='border-end'>
            <td>
              <strong><FormattedMessage id={`${rowIdPrefix}.${subTitleId}`} /></strong>
            </td>
            <td className='text-center'>{maxFixed(total(filteredRows, yearForecast)) + ' €'}</td>
          </tr>
        )}
        {filteredRows.map((row) => (
          <tr key={row.id} className='border-end'>
            <td className='px-lg-5'>

              {rowIdPrefix === 'foreCastBalance' && row.id==='otherFinancialAssets' && isNCM ?(
                  <FormattedMessage id='foreCastBalance.otherFinancialAssets.NCM' />
                  ):
                  (<FormattedMessage id={`${rowIdPrefix}.${row.id}`} />)
              }


            </td>
            {

            }

            <td>
              <DecimalInputField
                suffixLabel='€'
                onlyPositive
                handleChange={handleChange}
                handleChange={(e) => handleField(e, isNCM ? 'forecastBalanceNCMColumnList' : 'forecastBalanceSNCColumnList', yearForecastIndex, row.id)}
                name={`forecastBalanceList[0].${
                  isNCM ? 'forecastBalanceNCMColumnList' : 'forecastBalanceSNCColumnList'
                }[${yearForecastIndex}].${row.id}`}
                value={yearForecast[row.id]}
                isInvalid={
                  errors.forecastBalanceList?.[0]?.[
                    isNCM ? 'forecastBalanceNCMColumnList' : 'forecastBalanceSNCColumnList'
                  ]?.[yearForecastIndex]?.[row.id]
                }
                errorMessage={
                  errors.forecastBalanceList?.[0]?.[
                    isNCM ? 'forecastBalanceNCMColumnList' : 'forecastBalanceSNCColumnList'
                  ]?.[yearForecastIndex]?.[row.id]


                }
                allowNegative={(row.id==='transitedResults' || row.id==='otherChangesOwnCapital' || row.id==='netIncome')}
              />
            </td>
          </tr>
        ))}
      </>
    );
  }

  function generateYearAccordions() {
    let isNCM = values.forecastBalanceList[0].type === ForeCastType.NCM;

    let modelValues = isNCM
      ? values.forecastBalanceList[0].forecastBalanceNCMColumnList
      : values.forecastBalanceList[0].forecastBalanceSNCColumnList;

    modelValues = modelValues.filter(
      (yearForecast) => !CandidatureFormUtils.is3YearsCoverageOnly(values, yearForecast.year)
    );



    return modelValues.map((yearForecast, yearForecastIndex) => (
      <Row key={yearForecastIndex}>
        <Col className='financialBoards'>
          <Accordion>
            <Accordion.Item eventKey='yearForecast'>
              <Accordion.Header>
                {CandidatureFormUtils.getYearLabel(values, yearForecast.year)}
              </Accordion.Header>
              <Accordion.Body>
                <Row>
                  <Col className='tableWithBorders'>
                    <Table className='d-table border-0'>
                        {/* ================== ACTIVO =================== */}
                        <tr className='border-1 border-bottom bg-light border-end border-top'>
                          <th className='w-50'>
                            {values.forecastBalanceList[0].type === ForeCastType.SNC ? (
                              <FormattedMessage id='foreCastBalance.description.SNC' />
                            ) : (
                              <FormattedMessage id='foreCastBalance.description.NCM' />
                            )}
                          </th>
                          <th className='text-center'>
                            <FormattedMessage id='foreCastBalance.table.header.value' />
                          </th>
                        </tr>

                        {generateInputRows(
                          activeNoCurrentRowIds,
                          'active.NoCurrent',
                          yearForecast,
                          isNCM,
                          yearForecastIndex
                        )}

                        {generateInputRows(
                          activeCurrentRowIds,
                          'active.Current',
                          yearForecast,
                          isNCM,
                          yearForecastIndex
                        )}

                        <tr className='border-end'>

                          <td className='text-end'><strong>
                            <FormattedMessage id='foreCastBalance.active.total' /></strong>
                          </td>
                          <td className='text-center'>
                            {maxFixed(
                              total(
                                filterRowsByType(
                                  [...activeNoCurrentRowIds, ...activeCurrentRowIds],
                                  isNCM
                                ),
                                yearForecast
                              )
                            ) + ' €'}
                          </td>
                        </tr>

                        <tr className='border-1 border-bottom '>
                          <td colSpan='2' className='border-start-0 .text-primary'></td>
                        </tr>

                        {/* ================== CAPITAL PRÓPIO =================== */}
                        <tr className='border-1 border-bottom bg-light border-end border-top'>
                          <th>
                            {values.forecastBalanceList[0].type === ForeCastType.SNC ? (
                              <FormattedMessage id='foreCastBalance.ownCapital.SNC' />
                            ) : (
                              <FormattedMessage id='foreCastBalance.ownCapital.NCM' />
                            )}
                          </th>
                          <th className='text-center'>
                            <FormattedMessage id='foreCastBalance.table.header.value' />
                          </th>
                        </tr>

                        {generateInputRows(
                          ownCapitalRowIds,
                          undefined,
                          yearForecast,
                          isNCM,
                          yearForecastIndex
                        )}

                        <tr>
                          <td className='text-end'><strong>
                            <FormattedMessage id='foreCastBalance.ownCapital.total' /></strong>
                          </td>
                          <td className='text-center'>
                            {maxFixed(
                              total(filterRowsByType(ownCapitalRowIds, isNCM), yearForecast)
                            ) + ' €'}
                          </td>
                        </tr>
                      <tr className='border-1 border-bottom '>
                        <td colSpan='2' className='border-start-0 .text-primary'></td>
                      </tr>

                        {/* ================== PASSIVO =================== */}
                        <tr className='border-1 border-bottom bg-light border-end border-top'>
                          <th className='w-50'>
                            {values.forecastBalanceList[0].type === ForeCastType.SNC ? (
                              <FormattedMessage id='foreCastBalance.passive.SNC' />
                            ) : (
                              <FormattedMessage id='foreCastBalance.passive.NCM' />
                            )}
                          </th>
                          <th className='text-center'>
                            <FormattedMessage id='foreCastBalance.table.header.value' />
                          </th>
                        </tr>

                        {generateInputRows(
                          passiveNoCurrentIds,
                          'passive.NoCurrent',
                          yearForecast,
                          isNCM,
                          yearForecastIndex
                        )}

                        {generateInputRows(
                          passiveCurrentIds,
                          'passive.Current',
                          yearForecast,
                          isNCM,
                          yearForecastIndex
                        )}

                        <tr>
                          <td className='text-end'><strong>
                            <FormattedMessage id='foreCastBalance.passive.total' /></strong>
                          </td>
                          <td className='text-center'>
                            {maxFixed(
                              total(
                                filterRowsByType(
                                  [...passiveNoCurrentIds, ...passiveCurrentIds],
                                  isNCM
                                ),
                                yearForecast
                              )
                            ) + ' €'}
                          </td>
                        </tr>
                      <tr className='border-1 border-bottom '>
                        <td colSpan='2' className='border-start-0 .text-primary'></td>
                      </tr>
                        <tr>
                          <td className='text-end'><strong>
                            <FormattedMessage id='foreCastBalance.balance.total' /></strong>
                          </td>
                          <td className='text-center'>
                            {maxFixed(
                              total(
                                filterRowsByType(
                                  [
                                    ...ownCapitalRowIds,
                                    ...passiveNoCurrentIds,
                                    ...passiveCurrentIds
                                  ],
                                  isNCM
                                ),
                                yearForecast
                              )
                            ) + ' €'}
                          </td>
                        </tr>
                    </Table>
                  </Col>
                </Row>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Col>
      </Row>
    ));
  }

  function handleNextStep() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    handleSave(true);
  }

  return (
    <div>
      <h4 className='text-primary mb-0'>
        <FormattedMessage id='candidatureTabs.forecastBalance' />
      </h4>
      <small className='text-secondary text-uppercase'>
        {intl.formatMessage(
          { id: 'all.step' },
          { step: values.step, numberOfSteps: numberOfSteps }
        )}
      </small>
      <p className='mt-3 mb-0 text-secondary infoText'>
        <FaInfoCircle size={50} />
        <FormattedMessage id={'step17.infoText'} values={{value: currencyFormat(ConfigObject.get().limitValueFor5Years)}}/>
      </p>
      <Row>
        <Col>
          <EnumSelectField
            nullOption={true}
            preffixDescriptionId='foreCastBalance.option'
            labelId='foreCastBalance.type'
            name={'forecastBalanceList[0].type'}
            options={ForeCastType.values()}
            value={values.forecastBalanceList[0].type}
            handleChange={handleChange}
            isInvalid={errors.forecastBalanceList?.[0]?.type}
            errorMessage={errors.forecastBalanceList?.[0]?.type}
          />
        </Col>
      </Row>
      {values.forecastBalanceList[0].type !== '-1' && (
        <>
          {generateYearAccordions()}
          <Row>
            <Col>
              <TextAreaInputField
                labelId='foreCastBalance.reason'
                placeholder={intl.formatMessage({ id: 'all.maxLength.placeholder' })}
                name={'forecastBalanceList[0].reasonToForecastBalance'}
                value={values.forecastBalanceList[0].reasonToForecastBalance}
                handleChange={handleChange}
                isInvalid={errors.forecastBalanceList?.[0]?.reasonToForecastBalance}
                errorMessage={errors.forecastBalanceList?.[0]?.reasonToForecastBalance}
                maxLength={10000}
              />
            </Col>
          </Row>
        </>
      )}
      <Row>
        <Col>
          <div className='form-actions mt-5'>
            <button
              className='btn btn-link'
              type='button'
              onClick={() => handleGoBack(values, setFieldValue)}>
              <FormattedMessage id='all.back' />
            </button>

            <button className='btn btn-primary' type='button' onClick={() => handleNextStep()}>
              <FormattedMessage id='all.nextStep' />
            </button>
          </div>
        </Col>
      </Row>
    </div>
  );
}
