import React, {useEffect, useRef, useState} from 'react';
import {Button, Col, Container, Form, Row, Toast, ToastContainer} from 'react-bootstrap';
import {Formik} from 'formik';
import {FormattedMessage, useIntl} from 'react-intl';
import Loading from '../../components/general/Loading';
import {SubTemplate} from '../../components/general/SubTemplate';
import {FaAngleLeft, FaCheck, FaRegCheckCircle, FaRegSave} from 'react-icons/fa';
import {handleError, isNotBusinessError} from '../../utils/handleError';
import {FormSideNavBar} from '../../components/general/FormSideNavBar';
import {
    SemestralReportFormStep1,
    SemestralReportFormStep2,
    SemestralReportFormStep3,
} from '../../components/semestralReport';
import * as formSteps from '../../components/finalReport';
import {Navigate, useNavigate, useParams} from "react-router-dom";
import {
    getAllowActionsFinalReportByConsultingRequest,
    getFinalReportByConsultingRequest,
    saveFinalReportByConsultingRequest, submitFinalReportDecision
} from "../../rest/consultingRequest";
import {getEntityGetList, getEntityTgpList} from "../../rest/entity";
import {ConfirmationAuthenticationModal} from "../../components/general/ConfirmationAuthenticationModal";
import {createCustomErrorMessage} from "../../hooks/errorMessage";
import {ProcessState} from "../../models/ProcessState";
import {hasPermission, isEntityUser, isIEFPUser} from "../../authentication/authenticationHelper";
import * as yup from "yup";
import {CompanyIdentificationFormUtils} from "../../utils/CompanyIdentificationFormUtils";
import {AlertError} from "../../components/bootstrap/AlertError";
import {saveAdditionalActivitiesPlan} from "../../rest/additionalActivitiesPlan";
import {DecisionDialog} from "../../components/bootstrap/DecisionDialog";
import {submitDevelopmentPlanDecision} from "../../rest/developmentPlan";
import {useGlobalContext} from "../../context";
import {DateUtils} from "../../utils/DateUtils";
import {TextAreaInputField} from "../../components/bootstrap/TextAreaInputField";
import {AlertSuccess} from "../../components/bootstrap/AlertSuccess";

export function AddFinalReport() {
    const intl = useIntl();
    const isEntity = isEntityUser();
    const isIEFP = isIEFPUser();
    const [loading, setLoading] = useState(true);
    const [finalReport, setFinalReport] = useState(null);
    const [allowActions, setAllowActions] = useState(null);
    const { accessInfo,setCanEditProfile } = useGlobalContext();

    const [formikInitialValues, setFormikInitialValues] = useState(null);
    const [error, setError] = useState(null);
    const [getList, setGetList] = useState([]);
    const [tgpList, setTgpList] = useState([]);
    const [navErrors, setNavErrors] = useState([]);
    const [formStep, setFormStep] = useState(0);
    const [showSaveToast, setShowSaveToast] = useState(false);
    const [showSubmitModal, setShowSubmitModal] = useState(false);
    const [submited, setSubmited] = useState(false);
    const [showAccept, setShowAccept] = useState(false);
    const [showReject, setShowReject] = useState(false);
    const [accept, setAccept] = useState(false);
    const [reject, setReject] = useState(false);
    const [lsItem, setLsItem] = useState(null);

    const tabsIds = [
        'semestralReportForm.sideNavBar.identificationEA',
        'semestralReportForm.sideNavBar.identificationCompany',
        'semestralReportForm.sideNavBar.projectApproval',
        'finalReportForm.sideNavBar.mceServicesPeriod',
        'finalReportForm.sideNavBar.companyStatus',
        'finalReportForm.sideNavBar.commercialActivity',
        'finalReportForm.sideNavBar.resumeEAActivity',
        'finalReportForm.sideNavBar.globalEvaluation',
        'finalReportForm.sideNavBar.1stYearTimeline',
        'finalReportForm.sideNavBar.2ndYearTimeline',
        'finalReportForm.sideNavBar.3rdYearTimeline',
        'finalReportForm.sideNavBar.projectManagersList',
    ];

    const {externalId} = useParams();
    const formikPropsRef = useRef(null);
    const navigate = useNavigate();


    const validationSchema = yup.object().shape({
        identificationEa: yup.object().shape({
            technicalTeamManagerExternalId: yup
                .string()
                .test(
                    'isGETValid',
                    <FormattedMessage id='errors.finalReport.GET'/>,
                    (value) => value !== '-1'
                ),
        }),
        company: CompanyIdentificationFormUtils.validationObject(true),
        mceSupportPeriod: yup.object({
            initialDateMceSupport: yup.date(),
            endDateMceSupport: yup
                .date().transform(v => (v instanceof Date && !isNaN(v) ? v : undefined))
                .nullable()
                .required(<FormattedMessage id='errors.fieldRequiredText'/>)
                .test(
                    'isNotBeforeInitialDateMceSupport',
                    <FormattedMessage id='errors.evaluationEa.endDateMceSupportCanNotBeBeforeInitialDate'/>,
                    (value, context) =>
                        !(value <= new Date(context.parent.initialDateMceSupport))
                ).test('isNotAfter3Years',
                    <FormattedMessage id='errors.evaluationEa.endDateMceSupportCanNotBeAfter3Years'/>,
                    (value) =>
                        isNotAfter3Years(value)),
        }),
        companyStatus: yup.object().shape({
            operationState: yup
                .string()
                .test(
                    'isOperationValid',
                    <FormattedMessage id='errors.fieldRequiredText'/>,
                    (value) => value !== '-1' && value !== undefined
                ),
            installationState: yup
                .string()
                .test(
                    'isInstalationValid',
                    <FormattedMessage id='errors.fieldRequiredText'/>,
                    (value) => value !== '-1' && value !== undefined
                ),

            equipmentState: yup
                .string()
                .test(
                    'isEquipmentValid',
                    <FormattedMessage id='errors.fieldRequiredText'/>,
                    (value) => value !== '-1' && value !== undefined
                ),
            installationHistoryState: yup
                .string()
                .test(
                    'isInstalationHistoryValid',
                    <FormattedMessage id='errors.fieldRequiredText'/>,
                    (value) => value !== '-1' && value !== undefined
                ),

            equipmentHistoryState: yup
                .string()
                .test(
                    'isEquipmentHistoryValid',
                    <FormattedMessage id='errors.fieldRequiredText'/>,
                    (value) => value !== '-1' && value !== undefined
                ),
            keptMore50Validation: yup
                .string()
                .required(<FormattedMessage id='errors.fieldRequiredText'/>),
            companySituationObs: yup
                .string()
                .required(<FormattedMessage id='errors.fieldRequiredText'/>),


        }),
        indicatorEconomicSituations: yup.array().of(
            yup.object().shape({
                year:yup.number(),
                generalLiquidity: yup.number().test(
                    'isGeneralLiquidity',
                    <FormattedMessage id='errors.notPositive'/>,
                    (value,context) =>  isValidGeneraLiquidity(value,context)
                ),//   .min(0.01, <FormattedMessage id='errors.notPositive'/>)
            })),

        initialVAL: yup.number().min(0.01, <FormattedMessage id='errors.notPositive'/>),
        finalVAL: yup.number().min(0.01, <FormattedMessage id='errors.notPositive'/>),
        initialTIR: yup.number().min(0.01, <FormattedMessage id='errors.notPositive'/>),
        finalTIR: yup.number().min(0.01, <FormattedMessage id='errors.notPositive'/>),
        initialPRC: yup.number().min(1, <FormattedMessage id='errors.notPositive'/>),
        finalPRC: yup.number().min(1, <FormattedMessage id='errors.notPositive'/>),
        companyAppreciationByEA: yup.string()
            .required(<FormattedMessage id='errors.fieldRequiredText'/>),
        activityResume: yup.object().shape({
            isConsulting: yup.boolean(),
            isCompetencesExpansion: yup.boolean(),
            isFollowup: yup.boolean(),
            followupIDObservations: yup.string().test(
                'followupIDObservationsValid',

                <FormattedMessage id='errors.fieldRequiredText'/>,
                (value, context) => isValidActivityResume(value, context)
            ),
            followupManagementObservations: yup.string().test(
                'followupManagementObservationsValid',

                <FormattedMessage id='errors.fieldRequiredText'/>,
                (value, context) => isValidActivityResume(value, context)
            ),
            followupInfSystemObservations: yup.string().test(
                'followupInfSystemObservationsValid',

                <FormattedMessage id='errors.fieldRequiredText'/>,
                (value, context) => isValidActivityResume(value, context)
            ),
            followupHRObservations: yup.string().test(
                'followupHRObservationsValid',

                <FormattedMessage id='errors.fieldRequiredText'/>,
                (value, context) => isValidActivityResume(value, context)
            ),
            followupMarketingObservations: yup.string().test(
                'followupMarketingObservationsValid',

                <FormattedMessage id='errors.fieldRequiredText'/>,
                (value, context) => isValidActivityResume(value, context)
            ),
            followupLogisticObservations: yup.string().test(
                'followupLogisticObservationsValid',

                <FormattedMessage id='errors.fieldRequiredText'/>,
                (value, context) => isValidActivityResume(value, context)
            ),
            followupFinanceObservations: yup.string().test(
                'followupFinanceObservationsValid',

                <FormattedMessage id='errors.fieldRequiredText'/>,
                (value, context) => isValidActivityResume(value, context)
            ),
            followupOtherObservations: yup.string().test(
                'followupOtherObservationsValid',

                <FormattedMessage id='errors.fieldRequiredText'/>,
                (value, context) => isValidActivityResume(value, context)
            ),
            actionDevelopments: yup.array().of(
                yup.object().shape({
                    designation: yup.string().required(<FormattedMessage id='errors.fieldRequiredText'/>),
                    objectives: yup.string().required(<FormattedMessage id='errors.fieldRequiredText'/>),
                    results: yup.string().required(<FormattedMessage id='errors.fieldRequiredText'/>),
                })),
            summaryDescriptionConsulting: yup.string().test(
                'summaryDescriptionConsultingValid',

                <FormattedMessage id='errors.fieldRequiredText'/>,
                (value, context) => isValidActivityResume(value, context)
            ),
        }),
        globalEvaluation: yup.string().required(<FormattedMessage id='errors.fieldRequiredText'/>),
        yearTimelineEntryB13s: yup.object().shape({
            0: yup.array().of(
                yup.object().shape({
                    actions: yup.string().required(<FormattedMessage id='errors.fieldRequiredText'/>),
                    activityType: yup.string()
                        .test(
                            'isActivity0Valid',
                            <FormattedMessage id='errors.fieldRequiredText'/>,
                            (value) => value !== '-1' && value !== undefined
                        ),
                    foreCastFirstSemester: yup.number().required(<FormattedMessage id='errors.fieldRequiredText'/>),
                    foreCastSecondSemester: yup.number().required(<FormattedMessage id='errors.fieldRequiredText'/>),
                    realFirstSemester: yup.number().required(<FormattedMessage id='errors.fieldRequiredText'/>),
                    realSecondSemester: yup.number().required(<FormattedMessage id='errors.fieldRequiredText'/>)
                })).nullable(),
            1: yup.array().of(
                yup.object().shape({
                    actions: yup.string().required(<FormattedMessage id='errors.fieldRequiredText'/>),
                    activityType: yup.string()
                        .test(
                            'isActivity1Valid',
                            <FormattedMessage id='errors.fieldRequiredText'/>,
                            (value) => value !== '-1' && value !== undefined
                        ),
                    foreCastFirstSemester: yup.number().required(<FormattedMessage id='errors.fieldRequiredText'/>),
                    foreCastSecondSemester: yup.number().required(<FormattedMessage id='errors.fieldRequiredText'/>),
                    realFirstSemester: yup.number().required(<FormattedMessage id='errors.fieldRequiredText'/>),
                    realSecondSemester: yup.number().required(<FormattedMessage id='errors.fieldRequiredText'/>)
                })).nullable(),
            2: yup.array().of(
                yup.object().shape({
                    actions: yup.string().required(<FormattedMessage id='errors.fieldRequiredText'/>),
                    activityType: yup.string()
                        .test(
                            'isActivity2Valid',
                            <FormattedMessage id='errors.fieldRequiredText'/>,
                            (value) => value !== '-1' && value !== undefined
                        ),
                    foreCastFirstSemester: yup.number().required(<FormattedMessage id='errors.fieldRequiredText'/>),
                    foreCastSecondSemester: yup.number().required(<FormattedMessage id='errors.fieldRequiredText'/>),
                    realFirstSemester: yup.number().required(<FormattedMessage id='errors.fieldRequiredText'/>),
                    realSecondSemester: yup.number().required(<FormattedMessage id='errors.fieldRequiredText'/>)
                })).nullable()
        }),
        projectManagersList: yup.array().of(
            yup.object().shape({
                name: yup.string().required(<FormattedMessage id='errors.fieldRequiredText'/>),
                actionDesignation: yup.string().required(<FormattedMessage id='errors.fieldRequiredText'/>),
                numberOfHoursByMonth: yup.number().min(1, <FormattedMessage id='errors.notPositive'/>),
                mceActivity: yup.string()
                    .test(
                        'isMceActivityValid',
                        <FormattedMessage id='errors.fieldRequiredText'/>,
                        (value) => value !== '-1' && value !== undefined
                    ),
                internalOrExternal: yup.string()
                    .test(
                        'isInternalOrExternalValid',
                        <FormattedMessage id='errors.fieldRequiredText'/>,
                        (value) => value !== '-1' && value !== undefined
                    ),
                startDateAction: yup.date().test(
                    'isStartDateActionValid',
                    <FormattedMessage id='error.finalReportForm.startDateActionBeforeContract'/>,
                    (value) => {
                        return (isValidStartDateActionByStartDate(value));
                    }
                ).test(
                    'isStartDateActionValidAfter',
                    <FormattedMessage id='error.finalReportForm.endDateActionBeforeContract'/>,
                    (value) => {
                        return (isValidStartDateActionByEndDate(value));
                    }
                ).nullable(),
                endDateAction: yup.date().test(
                    'isEndDateActionValid',
                    <FormattedMessage id='error.finalReportForm.startDateActionBeforeContract'/>,
                    (value) => {
                        return (isValidStartDateActionByStartDate(value));
                    }
                ).test(
                    'isEndDateActionValidAfter',
                    <FormattedMessage id='error.finalReportForm.endDateActionBeforeContract'/>,
                    (value) => {
                        return (isValidStartDateActionByEndDate(value));
                    }
                ).test(
                    'isEndDateActionValidAfterInitial',
                    <FormattedMessage id='error.finalReportForm.dateActionsIncorrect'/>,
                    (value, context) => (value >= new Date(context.parent.startDateAction))
                ).nullable()
            }))


    });

    function isValidStartDateActionByStartDate(value) {
        const fixedDate = new Date(formikPropsRef.current.values.mceSupportPeriod.initialDateMceSupport);
        fixedDate.setHours(0, 0, 0, 0);
        return value >= fixedDate;
    }

    function isValidStartDateActionByEndDate(value) {
        return new Date(value) <= new Date(formikPropsRef.current.values.mceSupportPeriod.endDateMceSupport);
    }

    function isNotAfter3Years(value) {
        return DateUtils.addDays(new Date(value), 1) <= DateUtils.addYears(new Date(formikPropsRef.current.values.initialActivity), 3);

    }


    function isValidActivityResume(value, context) {
        if (context.parent.isFollowup === true) {
            return value !== null && value !== '' && value !== undefined;
        }
        return true;
    }

    function isValidGeneraLiquidity(value, context) {

        let yearToValidate = getYearsToValidate();
        if(yearToValidate ===-1){
            return true;
        }
        if (context.parent.year <= yearToValidate+1) {
            return  value>=0.01;
        }
        return true;
    }






    function getYearsToValidate() {
        if (formikPropsRef.current.values.mceSupportPeriod.endDateMceSupport === undefined || formikPropsRef.current.values.mceSupportPeriod.endDateMceSupport === null || formikPropsRef.current.values.mceSupportPeriod.endDateMceSupport === '' || formikPropsRef.current.values.mceSupportPeriod.endDateMceSupport === '-1') {
            return -1;
        }
        const initialDate = new Date(formikPropsRef.current.values.mceSupportPeriod.initialDateMceSupport);
        const finalDate = new Date(formikPropsRef.current.values.mceSupportPeriod.endDateMceSupport);

        let numberOfYears = new Date(finalDate - initialDate).getFullYear() - 1970;
        if (initialDate.getMonth() !== finalDate.getMonth() && initialDate.getDate() !== finalDate.getDate()) {
            numberOfYears = numberOfYears + 1;
        }

        if (numberOfYears > 3) {
            return 3;
        }

        return numberOfYears;


    }



    function checkLocalStorageItems() {
        [].forEach((s) => {
            if (sessionStorage.getItem(s)) {
                setLsItem(s);
            }
        });
    }







    async function fetchData() {
        try {
            const {data: finalReportInt} = await getFinalReportByConsultingRequest(externalId);
            setFinalReport(finalReportInt);


            setFormikInitialValues(getFormikInitialValues(finalReportInt));


            let entityHumanResourceParamsForNonEntityUsers = {
                entityNif: finalReportInt.identificationEa.entity.nif,
                all:finalReportInt.processState !== ProcessState.DRAFT
            };


            let [
                {data: entityGetList},
                {data: entityTPList},
                {data: allowActionsIn}

            ] = await Promise.all([
                isEntity
                    ? await getEntityGetList({ all:finalReportInt.processState !== ProcessState.DRAFT})
                    : await getEntityGetList(entityHumanResourceParamsForNonEntityUsers),
                isEntity
                    ? await getEntityTgpList({ all:finalReportInt.processState !== ProcessState.DRAFT})
                    : await getEntityTgpList(entityHumanResourceParamsForNonEntityUsers),
                getAllowActionsFinalReportByConsultingRequest(externalId)


            ]);
            setGetList(entityGetList);
            setTgpList(entityTPList);
            setAllowActions(allowActionsIn)

            checkLocalStorageItems();
        } catch (error) {
            setError(error);
        } finally {
            setLoading(false);
        }
    }

    function scrollPageToTop() {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }

    function handlePreviousStep() {
        scrollPageToTop();
        setFormStep(formStep - 1);
        handleSave(true);
    }

    function handleNextStep() {
        scrollPageToTop();
        setFormStep(formStep + 1);
        handleSave(true);
    }


    async function formValidationHandler(setShowModal) {
        let errors = await validateForm();
        setNavErrors(Array.from(errors));
        if (errors.size === 0) {
            setShowModal(true);
        }
    }

    async function validateForm() {
        let errors = new Set();

        console.log(formikPropsRef.current.values);
        const formikErrors = await formikPropsRef.current.validateForm();
        console.log(formikErrors)

        Object.keys(formikErrors).forEach((key) => {
            switch (key) {
                case 'identificationEa':
                    isEntity && errors.add(0);
                    break;
                case 'company':
                    isEntity && errors.add(1);
                    break;
                case 'mceSupportPeriod':
                    isEntity && errors.add(3);
                    break;
                case 'companyStatus':
                    isEntity && errors.add(4);
                    break;
                case 'indicatorEconomicSituations':
                case 'initialVAL':
                case 'finalVAL':
                case 'initialTIR':
                case 'finalTIR':
                case 'initialPRC':
                case 'finalPRC':
                case 'companyAppreciationByEA':
                    isEntity && errors.add(5);
                    break;
                case 'activityResume':
                    isEntity && errors.add(6);
                    break;
                case 'globalEvaluation':
                    isEntity && errors.add(7);
                    break;
                case 'yearTimelineEntryB13s':
                    let yearErrors = Object.keys(formikErrors[key]);
                    if (isEntity) {
                        if (yearErrors.includes('0')) {
                            errors.add(8);
                        }
                        if (yearErrors.includes('1') && formikPropsRef.current.values.yearTimelineEntryB13s[1] !== undefined && formikPropsRef.current.values.yearTimelineEntryB13s[1].length !== 0) {
                            errors.add(9);
                        }
                        if (yearErrors.includes('2')) {
                            errors.add(10);
                        }
                    }
                    break;
                case 'projectManagersList':
                    isEntity && errors.add(11);
                    break;

            }
        });

        let values = formikPropsRef.current.values;

        let errorMessages = [];

        let lastYearToValidate = getYearsToValidate();

        console.log(values.commercialActivityColumns);

        //validate Commercial Activity

        ['salesByMarketStructure', 'clientTypes', 'salesMeans'].forEach(s => {
            values.commercialActivityColumns.every((cA, index) => {

                if (index <= lastYearToValidate && totalPercentCommercialActivity(s, index, values) !== 100) {
                    errorMessages.push(intl.formatMessage({
                        id: 'error.finalReportForm.' + s + '.total.not100'
                    }));
                    errors.add(5);
                    return false;
                }
                return true;
            })
        })

        //Validate ActivityResume
        if (values.activityResume.isFollowup !== true && values.activityResume.isConsulting !== true && values.activityResume.isCompetencesExpansion !== true) {
            errorMessages.push(intl.formatMessage({
                id: 'error.finalReportForm.mceActivity.activityResume.noOneChoose'
            }));
            errors.add(6);
        }
        if ((values.activityResume.isConsulting === true || values.activityResume.isCompetencesExpansion === true) && values.activityResume.actionDevelopments.length === 0) {
            errorMessages.push(intl.formatMessage({
                id: 'error.finalReportForm.mceActivity.activityResume.actionDev.noOneChoose'
            }));
            errors.add(6);
        }


        //Validate YearTimeEntries
        if (lastYearToValidate !== -1) {
            console.log(lastYearToValidate);
            [...Array(lastYearToValidate).keys()].forEach(n => {
                if (values.yearTimelineEntryB13s[n] === undefined || values.yearTimelineEntryB13s[n].length === 0) {
                    errorMessages.push(intl.formatMessage({
                        id: 'error.finalReportForm.yearTimelineEntryB13.' + n + '.noOneChoose'
                    }));
                    errors.add(n + 8);

                }
            })
        }


        //Validate TGP
        if (values.projectManagersList === undefined || values.projectManagersList.length === 0) {
            errorMessages.push(intl.formatMessage({
                id: 'error.finalReportForm.projectManagersList.noOneChoose'
            }));
            errors.add(11);
        }


        if (errorMessages.length > 0) {
            let message =
                errorMessages.length === 1
                    ? createCustomErrorMessage(errorMessages[0])
                    : createCustomErrorMessage(
                    '<p>' +
                    intl.formatMessage({
                        id: 'errors.more',
                    }) +
                    '</p><p> - ' +
                    errorMessages.join('</p><p> - ') +
                    '</p>'
                    );

            setError(message);
        }


        return errors;
    }


    function getFormikInitialValues(finalReportIn) {
        return {
            externalId: finalReportIn.externalId,
            submissionDate: finalReportIn.submissionDate,
            approvalDate:finalReportIn.approvalDate,
            reasonReservations: finalReportIn.reasonReservations,
            identificationEa: finalReportIn.identificationEa,
            company: finalReportIn.company,
            processState: finalReportIn.processState,
            initialActivity: finalReportIn.initialActivity,
            projectApproval: {
                projectApprovalDate: finalReportIn.projectApprovalDate,
                candidatureProcessNumber: finalReportIn.candidatureProcessNumber
            },
            mceSupportPeriod: {
                initialDateMceSupport: finalReportIn.initialDateMceSupport,
                endDateMceSupport: finalReportIn.endDateMceSupport,
            },
            companyStatus: {
                operationState: finalReportIn.operationState,
                installationState: finalReportIn.installationState,
                equipmentState: finalReportIn.equipmentState,
                installationHistoryState: finalReportIn.installationHistoryState,
                equipmentHistoryState: finalReportIn.equipmentHistoryState,
                workstationForeseen: finalReportIn.workstationForeseen,
                workstationCreated: finalReportIn.workstationCreated,
                workstationKept: finalReportIn.workstationKept,
                keptMore50Validation: finalReportIn.keptMore50Validation,
                companySituationObs: finalReportIn.companySituationObs
            },

            commercialActivityColumns: finalReportIn.commercialActivityColumns,
            indicatorEconomicSituations: finalReportIn.indicatorEconomicSituations,
            initialVAL: finalReportIn.initialVAL,
            finalVAL: finalReportIn.finalVAL,
            initialTIR: finalReportIn.initialTIR,
            finalTIR: finalReportIn.finalTIR,
            initialPRC: finalReportIn.initialPRC,
            finalPRC: finalReportIn.finalPRC,
            strongWeakPoints: finalReportIn.strongWeakPoints,
            activityResume: finalReportIn.activityResume,
            globalEvaluation: finalReportIn.globalEvaluation,
            yearTimelineEntryB13s: finalReportIn.yearTimelineEntryB13s,
            projectManagersList: finalReportIn.projectManagersList,
            companyAppreciationByEA: finalReportIn.finalAppreciation,


        }
    }

    function objectToSend() {
        const values = formikPropsRef.current.values;
        let objectToSendInt = {
            externalId: values.externalId,
            identificationEa: values.identificationEa,
            company: values.company,
            projectApprovalDate: values.projectApproval.projectApprovalDate,
            candidatureProcessNumber: values.projectApproval.candidatureProcessNumber,
            initialDateMceSupport: values.mceSupportPeriod.initialDateMceSupport,
            endDateMceSupport: values.mceSupportPeriod.endDateMceSupport,
            operationState: values.companyStatus.operationState === '-1' ? null : values.companyStatus.operationState,
            installationState: values.companyStatus.installationState === '-1' ? null : values.companyStatus.installationState,
            equipmentState: values.companyStatus.equipmentState === '-1' ? null : values.companyStatus.equipmentState,
            installationHistoryState: values.companyStatus.installationHistoryState === '-1' ? null : values.companyStatus.installationHistoryState,
            equipmentHistoryState: values.companyStatus.equipmentHistoryState === '-1' ? null : values.companyStatus.equipmentHistoryState,
            workstationForeseen: values.companyStatus.workstationForeseen,
            workstationCreated: values.companyStatus.workstationCreated,
            workstationKept: values.companyStatus.workstationKept,
            keptMore50Validation: values.companyStatus.keptMore50Validation,
            companySituationObs: values.companyStatus.companySituationObs,
            commercialActivityColumns: values.commercialActivityColumns,
            indicatorEconomicSituations: values.indicatorEconomicSituations,
            initialVAL: values.initialVAL,
            finalVAL: values.finalVAL,
            initialTIR: values.initialTIR,
            finalTIR: values.finalTIR,
            initialPRC: values.initialPRC,
            finalPRC: values.finalPRC,
            strongWeakPoints: values.strongWeakPoints,
            finalAppreciation: values.companyAppreciationByEA,
            activityResume: values.activityResume,
            globalEvaluation: values.globalEvaluation,
            yearTimelineEntryB13s: values.yearTimelineEntryB13s,
            projectManagersList: values.projectManagersList


        }

        objectToSendInt.projectManagersList.forEach(hR => {
            if (hR.internalOrExternal === undefined || hR.internalOrExternal === '') {
                delete hR['internalOrExternal'];
            }
            if (hR.mceActivity === undefined || hR.mceActivity === '') {
                delete hR['mceActivity'];
            }
            if (hR.startDateAction === '') {
                delete hR['startDateAction'];
            }

            if (hR.endDateAction === '') {
                delete hR['endDateAction'];
            }
            if (hR.humanExternalId === '-1' || hR.humanExternalId === undefined) {
                delete hR['humanExternalId'];
            }
        })


        return objectToSendInt;
    }


    useEffect(() => {
        fetchData();
    }, []);

    if (submited) {

        if (formikInitialValues.replacement) {
            sessionStorage.setItem(
                'finalReportSubmitedReplacement',
                submited
            );
        } else {
            sessionStorage.setItem(
                'finalReportSubmited',
                submited
            );
        }
        return navigate('/pedidosMCE/' + externalId, {
            state: {refresh: true}
        });
    }


    if (accept) {
        sessionStorage.setItem(
            'finalReportAccept',
            accept
        );
        return navigate('/pedidosMCE/' + externalId, {
            state: {refresh: true}
        });
    }


    if (reject) {
        sessionStorage.setItem(
            'finalReportReject',
            reject
        );
        return navigate('/pedidosMCE/' + externalId, {
            state: {refresh: true}
        });
    }


    if (loading) {
        return <Loading/>;
    }


    if (error && isNotBusinessError(error)) {
        return handleError(error);
    }

    async function handleSave(switchStep) {
        if (!isEntity || (isEntity && formikPropsRef.current.values.processState !== ProcessState.DRAFT))
            return;

        let toSend = objectToSend();

        if (switchStep) {
            toSend.step = formStep;
        }
        let {data: res} = await saveFinalReportByConsultingRequest(externalId, toSend);
        setShowSaveToast(true)
    }


    async function submitDecision(reason) {
        try {
            let data = {
                reason: reason
            };


            await submitFinalReportDecision(
                externalId,
                data
            );
            if (reason === '') {
                setAccept(true);
            } else {
                setReject(true);
            }
        } catch (error) {
            setError(error);
        }
    }


    async function submitAccept() {
        await submitDecision('');
    }


    async function submitReject(reason) {
        setShowReject(false);
        await submitDecision(reason);
    }

    async function handleSubmit(user, password, setModalError, decisionApprove) {
        let toSend = objectToSend();

        toSend.credentials = {
            user: user,
            password: password,
        };

        if (isIEFP) {
            toSend.decisionApprove = decisionApprove;
        }

        let queryParams = {
            submit: true,
        };

        try {
            await saveFinalReportByConsultingRequest(externalId, toSend, queryParams);
            setSubmited(true);
        } catch (err) {
            if (err.response.data.exception === 'ValidationException') {
                let errors = error.response.data.message
                    .split(',')
                    .map((value) => Number.parseInt(value));
                setNavErrors(errors);
            } else {
                setModalError(err);
            }
        }
    }

    const subRowsIdsOfRowTitle = {
        0: ['local', 'regional', 'national', 'otherSalesType'],
        1: ['warehouses', 'retailers', 'endConsumer', 'industry', 'otherClients'],
        2: ['standardEstablishedMeans', 'salesPersons', 'byCatalog', 'fairs', 'atFacilities', 'commissioners', 'internet', 'otherSalesMeans'],
    };


    function getTotalFromCommercialActivityColumns(arrayAttributes, index, formikValues) {
        let sum = 0.00;
        arrayAttributes.forEach(att => {
            sum += parseFloat(formikValues.commercialActivityColumns[index][att]);
        })
        return sum;
    }

    function totalPercentCommercialActivity(dataAtt, yearAtt, formikValues) {
        switch (dataAtt) {
            case 'salesByMarketStructure':
                return getTotalFromCommercialActivityColumns(subRowsIdsOfRowTitle[0], yearAtt, formikValues)
            case 'clientTypes':
                return getTotalFromCommercialActivityColumns(subRowsIdsOfRowTitle[1], yearAtt, formikValues)
            case 'salesMeans':
                return getTotalFromCommercialActivityColumns(subRowsIdsOfRowTitle[2], yearAtt, formikValues)

        }
    }


    function getSaveToastInformation(message) {
        return (
            <ToastContainer className='fixed-top'>
                <Toast autohide delay={3000} show={showSaveToast} onClose={() => setShowSaveToast(false)}>
                    <Toast.Header>
                        <strong className='me-auto text-primary'>
              <span className='mr-2'>
                <FaCheck/>
              </span>
                            {message}
                        </strong>
                    </Toast.Header>
                </Toast>
            </ToastContainer>
        );
    }


    function renderStep(formikProps) {
        formikPropsRef.current = formikProps;

        const readMode = !(
            isEntity && formikProps.values.processState === ProcessState.DRAFT
        );

        switch (formStep) {
            case 0:
                return (
                    <SemestralReportFormStep1
                        formStep={1}
                        numberOfSteps={tabsIds.length}
                        errors={formikProps.errors}
                        formikValues={formikProps.values}
                        handleChange={formikProps.handleChange}
                        handleNextStep={handleNextStep}
                        entityGetList={getList}
                        goBackPath={'/pedidosMCE/' + externalId}
                        readMode={readMode}
                    />
                );
            case 1:
                return (
                    <SemestralReportFormStep2
                        formStep={2}
                        numberOfSteps={tabsIds.length}
                        errors={formikProps.errors}
                        formikValues={formikProps.values}
                        handleChange={formikProps.handleChange}
                        handlePreviousStep={handlePreviousStep}
                        handleNextStep={handleNextStep}
                        readMode={readMode}
                    />
                );
            case 2:
                return (
                    <SemestralReportFormStep3
                        formStep={3}
                        numberOfSteps={tabsIds.length}
                        errors={formikProps.errors}
                        formikValues={formikProps.values}
                        handleChange={formikProps.handleChange}
                        handlePreviousStep={handlePreviousStep}
                        handleNextStep={handleNextStep}
                        setFieldValue={formikProps.setFieldValue}
                        readMode={readMode}
                    />
                );
            case 3:
                return (
                    <formSteps.FinalReportFormStep4
                        formStep={4}
                        numberOfSteps={tabsIds.length}
                        errors={formikProps.errors}
                        formikValues={formikProps.values}
                        handlePreviousStep={handlePreviousStep}
                        handleNextStep={handleNextStep}
                        setFieldValue={formikProps.setFieldValue}
                        isFinalReport={true}
                        readMode={readMode}
                    />
                );
            case 4:
                return (
                    <formSteps.FinalReportFormStep5
                        formStep={5}
                        numberOfSteps={tabsIds.length}
                        errors={formikProps.errors}
                        formikValues={formikProps.values}
                        handleChange={formikProps.handleChange}
                        handlePreviousStep={handlePreviousStep}
                        handleNextStep={handleNextStep}
                        readMode={readMode}

                    />
                );
            case 5:
                return (
                    <formSteps.FinalReportFormStep6
                        formStep={6}
                        numberOfSteps={tabsIds.length}
                        errors={formikProps.errors}
                        formikValues={formikProps.values}
                        handleChange={formikProps.handleChange}
                        handlePreviousStep={handlePreviousStep}
                        handleNextStep={handleNextStep}
                        setFieldValue={formikProps.setFieldValue}
                        subRowsIdsOfRowTitle={subRowsIdsOfRowTitle}
                        totalPercentComercialActivity={totalPercentCommercialActivity}
                        readMode={readMode}

                    />
                );
            case 6:
                return (
                    <formSteps.FinalReportFormStep7
                        formStep={7}
                        numberOfSteps={tabsIds.length}
                        errors={formikProps.errors}
                        formikValues={formikProps.values}
                        handleChange={formikProps.handleChange}
                        handlePreviousStep={handlePreviousStep}
                        handleNextStep={handleNextStep}
                        setFieldValue={formikProps.setFieldValue}
                        readMode={readMode}


                    />
                );
            case 7:
                return (
                    <formSteps.FinalReportFormStep8
                        formStep={8}
                        numberOfSteps={tabsIds.length}
                        errors={formikProps.errors}
                        formikValues={formikProps.values}
                        handleChange={formikProps.handleChange}
                        handlePreviousStep={handlePreviousStep}
                        handleNextStep={handleNextStep}
                        entityGetList={getList}
                        readMode={readMode}
                    />
                );
            case 8:
                return (
                    <formSteps.FinalReportFormYearTimeline
                        formStep={9}
                        numberOfSteps={tabsIds.length}
                        errors={formikProps.errors}
                        formikValues={formikProps.values}
                        handleChange={formikProps.handleChange}
                        handlePreviousStep={handlePreviousStep}
                        handleNextStep={handleNextStep}
                        setFieldValue={formikProps.setFieldValue}
                        yearTimeline={0}
                        readMode={readMode}
                    />
                );
            case 9:
                return (
                    <formSteps.FinalReportFormYearTimeline
                        formStep={10}
                        numberOfSteps={tabsIds.length}
                        errors={formikProps.errors}
                        formikValues={formikProps.values}
                        handleChange={formikProps.handleChange}
                        handlePreviousStep={handlePreviousStep}
                        handleNextStep={handleNextStep}
                        setFieldValue={formikProps.setFieldValue}
                        yearTimeline={1}
                        readMode={readMode}
                    />
                );
            case 10:
                return (
                    <formSteps.FinalReportFormYearTimeline
                        formStep={11}
                        numberOfSteps={tabsIds.length}
                        errors={formikProps.errors}
                        formikValues={formikProps.values}
                        handleChange={formikProps.handleChange}
                        handlePreviousStep={handlePreviousStep}
                        handleNextStep={handleNextStep}
                        setFieldValue={formikProps.setFieldValue}
                        yearTimeline={2}
                        readMode={readMode}
                    />
                );
            case 11:
                return (
                    <formSteps.FinalReportFormStep12
                        formStep={12}
                        numberOfSteps={tabsIds.length}
                        errors={formikProps.errors}
                        formikValues={formikProps.values}
                        handleChange={formikProps.handleChange}
                        handlePreviousStep={handlePreviousStep}
                        setFieldValue={formikProps.setFieldValue}
                        tpgList={tgpList}
                        readMode={readMode}
                    />
                );
            default:
                console.log('Something went wrong rendering the form step');
        }
    }

    console.log(formikInitialValues)
    return (
        <SubTemplate>
            <Container>
                <div className='mx-2 mb-4'>
                    <AlertSuccess lsItem={lsItem} />
                </div>
                <DecisionDialog
                    show={showAccept}
                    handleConfirm={submitAccept}
                    handleClose={() => {
                        setShowAccept(false)
                    }}
                    titleId='decisionDialog.finalReport.accept.title'
                    bodyId='decisionDialog.finalReport.accept.body'
                    needReason={false}
                />
                <DecisionDialog
                    show={showReject}
                    handleConfirm={submitReject}
                    handleClose={() => {
                        setShowReject(false)
                    }}
                    titleId='decisionDialog.finalReport.reject.title'
                    bodyId='decisionDialog.finalReport.reject.body'
                    needReason={true}
                    reasonLabelId={'decisionDialog.finalReport.reject.reason'}
                />
                <ConfirmationAuthenticationModal
                    show={showSubmitModal}
                    handleClose={() => setShowSubmitModal(false)}
                    submitHandler={handleSubmit}
                    idsPreffix={'finalReport.submitModal'}
                    confirmButtonCompleteLabelId={'all.submitButtonText'}
                />
                <Row>
                    <Col md={{span: 8, offset: 2}}>
                        <h2 className='mb-1 text-secondary'>
                            <button className={'back-button btn-link'} onClick={()=>navigate(-1)}>
                                <FaAngleLeft />
                            </button>
                            <FormattedMessage id='finalReportForm.title'/>
                        </h2>
                        <p className='mb-5 text-secondary'>
                            <FormattedMessage id='finalReportForm.subTitle'/>
                            <span
                                className="badge rounded-pill bg-secondary fw-normal ms-2"><span
                                className="text-uppercase fw-bold"><FormattedMessage
                                id={`processState.${formikInitialValues.processState}`}
                            /> </span>  {formikInitialValues.submissionDate !== undefined && formikInitialValues.submissionDate !== null && (<> · <FormattedMessage id={'submitted.process.at'} values={{date:formikInitialValues.submissionDate}}/>  </>) } {formikInitialValues.approvalDate !== undefined && formikInitialValues.approvalDate !== null && ProcessState.SUBMITTED!==formikInitialValues.processState && (<> · {ProcessState.APPROVED===formikInitialValues.processState ? <FormattedMessage id={'approvedPromoter2.process.at'} values={{date:formikInitialValues.approvalDate}}/>:<FormattedMessage id={'rejectedPromoter2.process.at'} values={{date:formikInitialValues.approvalDate}}/>}   </>) }  </span>
                        </p>
                    </Col>
                </Row>
                <Row className={'pb-3 text-center'}>
                    {(formikInitialValues?.processState === ProcessState.DRAFT || formikInitialValues?.processState === ProcessState.APPROVED) &&
                    <>
                        {
                            formikInitialValues?.reasonReservations?.length > 0 ? (
                                <>
                                    <Col md='2'></Col>
                                    <Col md='8'>

                                        <h6><FormattedMessage id={'finalReport.reservations.legend'}/></h6>


                                        <TextAreaInputField
                                            name={'reasonReservations'}
                                            value={formikInitialValues.reasonReservations}
                                            maxLength={10000}
                                            disabled={true}
                                        />
                                    </Col>


                                </>

                            ) : (
                                <>
                                    {formikInitialValues?.processState === ProcessState.APPROVED &&
                                    <h6><FormattedMessage id={'finalReport.noReservation.legend'}/></h6>
                                    }
                                </>

                            )
                        }

                    </>


                    }


                </Row>
                <Row className='mb-5'>
                    <Col md='2'>
                        <FormSideNavBar
                            tabsIdsArray={tabsIds}
                            formStep={formStep}
                            setFormStep={setFormStep}
                            navErrors={navErrors}
                            setNavErrors={setNavErrors}
                        />
                    </Col>
                    <Col md='8'>
                        <AlertError error={error}/>
                        {getSaveToastInformation(
                            intl.formatMessage({id: 'finaReport.saveToastInformation'})
                        )}
                        <Formik
                            initialValues={formikInitialValues}
                            validateOnBlur={false}
                            validateOnChange={false}
                            validationSchema={validationSchema}
                            onSubmit={handleSubmit}
                        >
                            {(formikProps) => (
                                <Form onSubmit={formikProps.handleSubmit}>
                                    {renderStep(formikProps)}
                                </Form>
                            )}
                        </Formik>
                    </Col>
                    <Col md='2'>

                        <div>
                            {isEntity && formikInitialValues?.processState === ProcessState.DRAFT &&
                            <>

                                <button
                                    className='btn btn-outline-primary d-flex align-items-center justify-content-center mb-4 w-100'
                                    type='button'
                                    onClick={() => handleSave()}
                                >
                                    <FaRegSave/>
                                    <FormattedMessage id='all.save'/>
                                </button>
                                <button
                                    className='btn btn-primary d-flex align-items-center justify-content-center w-100'
                                    type='button'
                                    onClick={() => formValidationHandler(setShowSubmitModal)}
                                >
                                    <FaRegCheckCircle/>
                                    <FormattedMessage id='finalReport.submit.button'/>
                                </button>
                            </>

                            }
                            {allowActions.canValidate === true &&
                            <>

                                <button
                                    className='btn btn-outline-primary d-flex align-items-center justify-content-center mb-4 w-100'
                                    type='button'
                                    onClick={() => setShowAccept(true)}
                                >
                                    <FormattedMessage id='finalReport.accept.button'/>
                                </button>
                                <button
                                    className='btn btn-primary d-flex align-items-center justify-content-center w-100'
                                    type='button'
                                    onClick={() => setShowReject(true)}
                                >
                                    <FormattedMessage id='finalReport.reject.button'/>
                                </button>
                            </>

                            }
                        </div>
                    </Col>
                </Row>
            </Container>
        </SubTemplate>
    );
}
