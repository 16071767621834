import { createCustomErrorMessage } from '../hooks/errorMessage';
import {CandidatureEmployeeSupportUtils, IAS} from './CandidatureEmployeeSupportUtils';
import { CandidatureFinancialUtils } from './CandidatureFinancialUtils';
import { PromoterType } from '../models/PromoterType';
import { CandidatureFormUtils } from './CandidatureFormUtils';
import { PeopleType } from '../models/PeopleType';
import { FunctionType } from '../models/FunctionType';
import { RemunerationType } from '../models/RemunerationType';
import {ConfigObject} from "../config";
import {currencyFormat, maxFixed} from "./CurrencyUtils";
import {formatValue} from "react-currency-input-field";
import * as yup from "yup";
import {Arrays} from "./Arrays";
import {ForeCastType} from "../models/ForeCastType";

export const CandidatureFormCustomValidator = {
  validateStep1: (values, setError, intl,errorMessages) => validateStep1(values, setError, intl,errorMessages),

  validateStep5: (values, setError, intl,errorMessages) => validateStep5(values, setError, intl,errorMessages),

  validateStep6: (values, setError, intl, errorMessages) => validateStep6(values, setError, intl,errorMessages),

  validateStep8: (values, setError, intl, errorMessages) => validateStep8(values, setError, intl,errorMessages),

  validateStep9: (values, setError, intl, errorMessages) => validateStep9(values, setError, intl,errorMessages),

  validateStep10: (values, setError, intl, errorMessages) => validateStep10(values, setError, intl,errorMessages),

  validateStep11: (values, setError, intl, errorMessages) => validateStep11(values, setError, intl,errorMessages),

  validateStep12: (values, setError, intl, errorMessages) => validateStep12(values, setError, intl,errorMessages),

  validateStep14: (values, setError, intl, errorMessages) => validateStep14(values, setError, intl,errorMessages),

  validateStep15: (values, setError, intl, errorMessages) => validateStep15(values, setError, intl,errorMessages),

  validateStep16: (values, setError, intl, errorMessages) => validateStep16(values, setError, intl,errorMessages),

  validateStep17: (values, setError, intl, errorMessages) => validateStep17(values, setError, intl,errorMessages),

  validateStep3: (values, setError, intl, errorMessages) => validateStep3(values, setError, intl,errorMessages),

  validateStep19: (values, setError, intl, errorMessages) => validateStep19(values, setError, intl,errorMessages),

  validateStep20: (values, setError, intl, errorMessages) => validateStep20(values, setError, intl,errorMessages)
};





function validateStep1(values, setError, intl,errorMessages) {
  let valid = true;

  let notDuplicatedNif =   new Set(values.promotersMetaData.map(p=> p.nif));
  let notDuplicatedEmail =   new Set(values.promotersMetaData.map(p=> p.email));

  if(notDuplicatedNif.size!== values.promotersMetaData.length || notDuplicatedEmail.size!== values.promotersMetaData.length){
    errorMessages.push(intl.formatMessage({
      id: 'errors.candidatureForm.inconsistentFields'
    }));
    valid= false;
  }


 let result =  values.promotersMetaData.filter(p=> p.promoterType === PromoterType.SECONDARY || p.promoterType === PromoterType.PRIMARY)
     .reduce(function (obj, item) {

       obj.socialCapital+= parseFloat(item.socialCapital);
       obj.voteRight += parseFloat(item.voteRight);
       return obj;
     }, { socialCapital:0,voteRight:0});





  if(result.socialCapital<=50 || result.voteRight<=50 || result.socialCapital>100 || result.voteRight>100){
    errorMessages.push(intl.formatMessage({
      id: 'errors.candidatureForm.socialCapitalOrVoteRightMoreThan100'
    }));
    valid= false;

  }



  return valid;
}

function validateStep5(values, setError, intl,errorMessages) {
  // TODO: Check if this information will still be used

  // if (
  //   CandidatureFinancialUtils.getTotalEligibleInvestment(values) === 0 ||
  //   CandidatureFinancialUtils.getTotalOwnCapitals(values) +
  //     CandidatureFinancialUtils.getTotalForeignCapitals(values) ===
  //     0
  // ) {
  //   setError(
  //     createCustomErrorMessage(
  //       intl.formatMessage({
  //         id: 'errors.candidatureForm.totalInvestmentsZero'
  //       })
  //     )
  //   );
  //   return false;
  // }

  // if (CandidatureFinancialUtils.getTotalEligibleInvestment(values) > 175000) {
  //   setError(
  //     createCustomErrorMessage(
  //       intl.formatMessage({
  //         id: 'errors.candidatureForm.totalEligibleInvestment'
  //       })
  //     )
  //   );
  //   return false;
  // }

  // if (
  //   CandidatureFinancialUtils.getTotalOwnCapitals(values) +
  //     CandidatureFinancialUtils.getTotalForeignCapitals(values) <
  //   0.15 * CandidatureFinancialUtils.getTotalEligibleInvestment(values)
  // ) {
  //   setError(
  //     createCustomErrorMessage(
  //       intl.formatMessage({
  //         id: 'errors.candidatureForm.totalOwnCapitals'
  //       })
  //     )
  //   );
  //   return false;
  // }

  // if (
  //   CandidatureFinancialUtils.getTotalForeignCapitals(values) >
  //   (85 * CandidatureFinancialUtils.getTotalEligibleInvestment(values)) / 100
  // ) {
  //   setError(
  //     createCustomErrorMessage(
  //       intl.formatMessage({
  //         id: 'errors.candidatureForm.totalForeignCapitals'
  //       })
  //     )
  //   );
  //   return false;
  // }




  return true;
}





function validateStep6(values, setError, intl,errorMessage) {
  let valid = true;
  const totalInvestmentPlanDetail = values.investmentPlanDetails.find(
    (ipd) => ipd.investmentType === 'TOTAL'
  );


  const totalFinancialInvestmentDetail = values.financialInvestmentDetails.find(
    (fid) => fid.financialInvestmentType === 'TOTAL'
  );

  const promoters = values.promotersMetaData.filter(
    (pmd) => pmd.promoterType !== PromoterType.OTHER
  );

  const unemploymentBenefitsPromoters = promoters.filter((p) => p.applyUnemploymentBenefits===true);


  const totalValue = parseFloat(ConfigObject.get().maxFinancedValue);


  if (
      (values.unemploymentBenefitsValidation ===false || values.unemploymentBenefitsValidation ===undefined) && unemploymentBenefitsPromoters.length > 0 &&
    totalFinancialInvestmentDetail.ownCapitalUnemploymentBenefits === 0
  ) {
    errorMessage.push(intl.formatMessage({
      id: 'errors.candidatureForm.ownCapitalUnemploymentBenefitsEqualsZero'
    }));

    valid=false;
  }

  if (
      unemploymentBenefitsPromoters.length === 0 &&
      totalFinancialInvestmentDetail.ownCapitalUnemploymentBenefits> 0
  ) {
    errorMessage.push(intl.formatMessage({
      id: 'errors.candidatureForm.ownCapitalUnemploymentBenefitsBiggerZero'
    }));
    valid=false
  }


  let count = parseFloat(maxFixed(CandidatureEmployeeSupportUtils.getTotalJobSubsidyValue(values.candidatureEmployeeSupportDetails)));



  if(count!=totalFinancialInvestmentDetail.workStationsCreationBenefits){
    errorMessage.push(intl.formatMessage({
      id: 'errors.candidatureForm.differentTotalWorkstationsValue'
    }));
    valid=false;
  }


  if (parseFloat(maxFixed(parseFloat(totalFinancialInvestmentDetail.globalTotal) - parseFloat(totalFinancialInvestmentDetail.workStationsCreationBenefits))) !== maxFixed(totalInvestmentPlanDetail.globalTotal)) {
    errorMessage.push(intl.formatMessage({
      id: 'errors.candidatureForm.differentTotalGlobals'
    }));
    valid= false;
  }


  let investmentTotal = parseFloat(totalInvestmentPlanDetail.globalTotal)  > totalValue ?
      totalValue :
      parseFloat(totalInvestmentPlanDetail.globalTotal);


  console.log(maxFixed(investmentTotal*0.85));
  console.log(maxFixed(totalFinancialInvestmentDetail.investmentSupport))

  if(parseFloat(maxFixed(investmentTotal*0.85))  < parseFloat(maxFixed(totalFinancialInvestmentDetail.investmentSupport))){
    errorMessage.push(intl.formatMessage({
      id: 'errors.candidatureForm.moreThan85'
    },{value: formatValue({
        value: (totalValue*0.85).toString(),
        groupSeparator: ' ',
        decimalSeparator: '.',
        suffix: '€',
      }) }));
    valid= false;
  }
  return valid;
}

function validateStep8(values, setError, intl,errorMessages) {
  let fieldsValid = true;

  const sellInformation = values.sellInformationList.filter((sil) => sil.type === 'SELL')[0];

  if (sellInformation.sellInformationLines.length === 0) {
    errorMessages.push(intl.formatMessage({
      id: 'errors.candidatureForm.emptySellInformation'
    }));
    return false;
  }

  sellInformation.sellInformationLines.every(line=>{
    if(line.sellInformationLineYears.filter(lineYear => !CandidatureFormUtils.is3YearsCoverageOnly(values, lineYear.year) &&  lineYear.quantity !== 0 && lineYear.unitPrice !== 0).length === 0){
      errorMessages.push(intl.formatMessage({
        id: 'errors.candidatureForm.sellInformationNoYear'
      }));
      fieldsValid=false;
      return false;
    }
    return true;
  });


  return fieldsValid;
}


  function validateStep9(values, setError, intl,errorMessages) {

    const sellInformation = values.sellInformationList.filter((sil) => sil.type === 'CONSUME')[0];
    let fieldsValid = true;


    if(sellInformation.sellInformationLines.length!==0){
      sellInformation.sellInformationLines.every(line=>{
        console.log(line.sellInformationLineYears.filter(lineYear => !CandidatureFormUtils.is3YearsCoverageOnly(values, lineYear.year) &&  lineYear.quantity !== 0 && lineYear.unitPrice !== 0))
        if(line.sellInformationLineYears.filter(lineYear => !CandidatureFormUtils.is3YearsCoverageOnly(values, lineYear.year) &&  lineYear.quantity !== 0 && lineYear.unitPrice !== 0).length === 0){
          errorMessages.push(intl.formatMessage({
            id: 'errors.candidatureForm.consumeInformationNoYear'
          }));
          fieldsValid=false;

          return false;
        }
        return true;
      })
    }


      return fieldsValid;

    }

  // Transport this information to future step

  // values.step6Documents
  //   .filter((document) => document.documentType !== 'DISTRICT_APPLICATION')
  //   .forEach((document) => {
  //     if (!document.submited) {
  //       setError(
  //         createCustomErrorMessage(
  //           intl.formatMessage({
  //             id: 'errors.candidatureForm.requiredFiles'
  //           })
  //         )
  //       );
  //       fieldsValid = false;
  //     }
  //   });

  // const unemploymentBenefitsPositiveAnswers = values.promotersMetaData.filter(
  //   (pm) => pm.applyUnemploymentBenefits
  // ).length;

  // const districtApplicationDocsSize = values.step6Documents.filter(
  //   (document) => document.documentType === 'DISTRICT_APPLICATION'
  // ).length;

  // if (unemploymentBenefitsPositiveAnswers !== districtApplicationDocsSize) {
  //   fieldsValid = false;
  //   setError(
  //     createCustomErrorMessage(
  //       intl.formatMessage({
  //         id: 'errors.candidatureForm.inconsistentDistrictApplicationDocuments'
  //       })
  //     )
  //   );
  // }



// Transport this information to future step

// function validateStep10(values, setError, intl) {
//   const irsFiles = values.step8Documents.filter(
//     (file) => file.documentType === 'REGULARIZED_STATUS_DECLARATION'
//   );
//   const socialFiles = values.step8Documents.filter(
//     (file) => file.documentType === 'SOCIAL_REGULARIZED_STATUS_DECLARATION'
//   );

//   let atDeclarationsAuthorized = 0;
//   let ssDeclarationsAuthorized = 0;

//   const promoters = values.promotersMetaData;

//   promoters.forEach((pm) => {
//     if (pm.atDeclarationAuthorized) {
//       atDeclarationsAuthorized += 1;
//     }

//     if (pm.ssDeclarationAuthorized) {
//       ssDeclarationsAuthorized += 1;
//     }
//   });

//   if (
//     !(
//       irsFiles.length === promoters.length - atDeclarationsAuthorized &&
//       socialFiles.length === promoters.length - ssDeclarationsAuthorized
//     )
//   ) {
//     setError(
//       createCustomErrorMessage(
//         intl.formatMessage({
//           id: 'errors.candidatureForm.inconsistentDeclarations'
//         })
//       )
//     );
//     return false;
//   }
//   return true;
// }

function validateStep10(values, setError, intl,errorMessages) {
  const years = [
    ...new Set(
      values.provideInformationList[0].provideInformationCellList
        .filter((picl) => !CandidatureFormUtils.is3YearsCoverageOnly(values, picl.year))
        .map((picl) => picl.year)
    )
  ];

  let count = years.map(t2 => (CandidatureFinancialUtils.calculateProvideInformationTotalValueByYear(values, t2))).reduce((total, count) => total + count);

  if(count<1){
    errorMessages.push( intl.formatMessage({
      id: 'errors.candidatureForm.provideInformation.totalValue'
    }));
   return  false;
  }



  return true;
}

function validateStep11(values, setError, intl,errorMessage) {
  let valid = true;

  const years = [
    ...new Set(
      values.peopleBoardList[0].peopleBoardCellList
        .filter((pbcl) => !CandidatureFormUtils.is3YearsCoverageOnly(values, pbcl.year))
        .map((pbcl) => pbcl.year)
    )
  ];



  const numberOfRecipientsPromoters = values.promotersMetaData.filter(
    (pm) => pm.promoterType === PromoterType.PRIMARY || pm.promoterType === PromoterType.SECONDARY
  ).length;

  let valuesToCompare = [];

  //const yearsTocompare = [0, 1, 2];


  years.forEach((yearToCompare) => {
    const cell1 = values.peopleBoardList[0].peopleBoardCellList.find(
      (pbcl) =>
        pbcl.year === yearToCompare &&
        pbcl.peopleType === PeopleType.DESTINATION_PROMOTER &&
        pbcl.functionType === FunctionType.ADMIN_DIR
    );

    const cell2 = values.peopleBoardList[0].peopleBoardCellList.find(
      (pbcl) =>
        pbcl.year === yearToCompare &&
        pbcl.peopleType === PeopleType.DESTINATION_PROMOTER &&
        pbcl.functionType === FunctionType.ADMIN_FINANCE
    );

    valuesToCompare.push(parseFloat(cell1.value) + parseFloat(cell2.value));


  });

  valuesToCompare.every((value) => {


    if (value !== numberOfRecipientsPromoters) {
      errorMessage.push(intl.formatMessage({
        id: 'errors.candidatureForm.peopleBoard.recipientsPromoters'
      }))
      valid = false;
      return false;
    }
    return valid
  });


  const numberOfRecipientsOtherPromoters = values.promotersMetaData.filter(
      (pm) => pm.promoterType === PromoterType.OTHER
  ).length;

  let grouped = Arrays.groupBy(
      values.peopleBoardList[0].peopleBoardCellList.filter(pB=> years.includes(pB.year) && pB.peopleType==='OTHER_PROMOTER' ) ,
      (pB) => pB.year
  );

  let yearCounters = Array.from(grouped, function (entry) {
    return { value: entry[1].map(yG=> yG.value).reduce((total, count) => parseInt(total) + parseInt(count)) };
  })

  if(yearCounters.filter(i=>i.value > numberOfRecipientsOtherPromoters).length!==0){
    errorMessage.push(intl.formatMessage({
      id: 'errors.candidatureForm.peopleBoard.recipientsOtherPromoters'
    }))
    valid = false;
  }


  return valid;
}

function validateStep12(values, setError, intl,errorMessage) {
  let valid = true;
  const years = [
    ...new Set(
      values.peopleRemunerationList[0].peopleRemunerationCellList
        .filter((prcl) => !CandidatureFormUtils.is3YearsCoverageOnly(values, prcl.year))
        .map((prcl) => prcl.year)
    )
  ];

  RemunerationType.values().every((rt) => {
    years.every((year) => {
      if (CandidatureFinancialUtils.calculatePeopleRemunerationTotalValue(values, year, rt) < 1) {
       errorMessage.push(intl.formatMessage({
          id: 'errors.candidatureForm.peopleRemuneration.totalValue'
        }));

        valid=false;
        return false;
      }
      return true;


    });
    return valid;
  });


  return valid;
}

async function validateStep14(values, setError, intl, errorMessage) {
  let year = 2;

  if (!CandidatureFormUtils.is3YearsCoverageOnly(values, 4)) {
    year = 4;
  }


  const [subRepresentation, inSide, levels, iefpAccretionIn] = await Promise.all([
    await CandidatureFinancialUtils.getSubRepresentation(
        values.candidatureDetails.cae, values.promotersMetaData
    ),
    await CandidatureFinancialUtils.getIsInside(values.parish),
    await CandidatureFinancialUtils.getLevelsAccretion(values.candidatureEmployeeSupportOtherDetails),
    await CandidatureFinancialUtils.getIefpAccretion(values.candidatureEmployeeSupportOtherDetails)

  ]);
  const accretionTotal = await CandidatureFinancialUtils.getAccretionTotal(subRepresentation, inSide, levels, iefpAccretionIn, values.candidatureEmployeeSupportOtherDetails)


  let freeLoans2 = await CandidatureFinancialUtils.getInterestFreeLoan(values.financialInvestmentDetails.find(
      (ipd) => ipd.financialInvestmentType === 'TOTAL'
  ),values.investmentPlanDetails.filter((ipd) => ( ipd.investmentType === 'TOTAL'))[0], accretionTotal);



  if (freeLoans2> 0 && (values.earlyAmortizationList[0].earlyAmortizationCellList.filter(eA => eA.year <= year).map(eA => eA.value).reduce((total, count) => parseFloat(total) + parseFloat(count)) === 0)) {
    errorMessage.push(intl.formatMessage({
      id: 'errors.candidatureForm.earlyAmortizationList.empty'
    }));

    return false;
  }

  return true;
}


function validateStep15(values, setError, intl,errorMessage) {

  let investment = values.financialInvestmentDetails.find(detail => detail.financialInvestmentType === 'TOTAL' && detail.kind === 'PROMOTER');
  let foreignCapital = parseFloat(investment.foreignCapitalBankLoans) + parseFloat(investment.foreignCapitalPartnerLoans) + parseFloat(investment.foreignCapitalProvidersLoans) + parseFloat(investment.foreignCapitalOthers);
  let year = 2;

  if (!CandidatureFormUtils.is3YearsCoverageOnly(values, 4)) {
    year = 4;
  }

  if(values.otherChargeList[0].otherChargeLineList.filter(eA => eA.year <= year && parseFloat(eA.refund)===0  && parseFloat(eA.debtCapitalInitial)!==0).length>0){
    errorMessage.push(intl.formatMessage({
      id: 'errors.candidatureForm.otherChargeList.lineIncorrect'
    }));
    return false;
  }

  if (foreignCapital!==0 && values.otherChargeList[0].otherChargeLineList.filter(eA => eA.year <= year).map(eA => eA.refund).reduce((total, count) => parseFloat(total) + parseFloat(count)) === 0) {
    errorMessage.push(intl.formatMessage({
      id: 'errors.candidatureForm.otherChargeList.empty'
    }));
    return false;
  }
  return true;
}


function validateStep16(values, setError, intl,errorMessage) {

  if (CandidatureFormUtils.is3YearsCoverageOnly(values, 4)) {
    return true;
  }

  let valid = true;

  if(values.forecastDemonstrationList[0].type !== values.forecastBalanceList[0].type){
    errorMessage.push(intl.formatMessage({
      id: 'errors.candidatureForm.balanceDemonstrationDifferent'
    }));
    valid= false;
  }



  return valid;
}


function validateStep17(values, setError, intl,errorMessage) {

  let year = 2;

  if (!CandidatureFormUtils.is3YearsCoverageOnly(values, 4)) {
    year = 4;
  }

  if(year ===2){
    return true;
  }

  let valid = true;

  if(values.forecastBalanceList[0].type ===-1 || values.forecastBalanceList[0].type ==='-1'){
    return true;
  }





  let isNCM = values.forecastBalanceList[0].type === ForeCastType.NCM;

  let modelValues = isNCM
      ? values.forecastBalanceList[0].forecastBalanceNCMColumnList
      : values.forecastBalanceList[0].forecastBalanceSNCColumnList;

  modelValues = modelValues.filter(
      (yearForecast) => !CandidatureFormUtils.is3YearsCoverageOnly(values, yearForecast.year)
  );

  const keysToValidate =[
    'tangibleFixedAssets','intangibleAssets','financialInvestments','shareholders','inventories','clients','activePublicEntities','deferrals','otherFinancialAssets'
      ,'cashAndBankDeposits','paidInCapital','otherEquityInstruments','transitedResults','otherChangesOwnCapital','netIncome','provisions',
      'financingObtained','otherBillsToPay','passivePublicEntities','passiveDeferrals','providers'
  ]

  if(isNCM===true) {
    keysToValidate.push('reservations', 'otherCurrentPassive');
  }else{
    keysToValidate.push('investmentProperties', 'activeSuppliersAdvances','currentShareholders','otherAccountsReceivable',
        'ownShares','opinionReward','legalReservations','otherReservations','revaluationSurpluses','advancesFromCustomers',
        'passiveSuppliersAdvances','currentFinancingObtained','currentOtherBillsToPay','otherPassives');
  }



  modelValues.filter(model=> model.year<=year).every(model=>{
    if(keysToValidate.filter(key=> parseFloat(model[key])!==0).length===0){
      errorMessage.push(intl.formatMessage({
        id: 'error.forecastBalanceAllYears'
      }));
      valid=false;
      return false;
    }
    return true;

  })


  const activeNoCurrentRowIds = [
    { id: 'tangibleFixedAssets', type: null },
    { id: 'investmentProperties', type: 'SNC' },
    { id: 'intangibleAssets', type: null },
    { id: 'financialInvestments', type: null },
    { id: 'shareholders', type: null }
  ];

  const activeCurrentRowIds = [
    { id: 'inventories', type: null },
    { id: 'clients', type: null },
    { id: 'activeSuppliersAdvances', type: 'SNC' },
    { id: 'activePublicEntities', type: null },
    { id: 'currentShareholders', type: 'SNC' },
    { id: 'otherAccountsReceivable', type: 'SNC' },
    { id: 'deferrals', type: null },
    { id: 'otherFinancialAssets', type: null },
    { id: 'cashAndBankDeposits', type: null }
  ];

  const ownCapitalRowIds = [
    { id: 'paidInCapital', type: null },
    { id: 'ownShares', type: 'SNC' },
    { id: 'otherEquityInstruments', type: null },
    { id: 'opinionReward', type: 'SNC' },
    { id: 'legalReservations', type: 'SNC' },
    { id: 'otherReservations', type: 'SNC' },
    { id: 'reservations', type: 'NCM' },
    { id: 'transitedResults', type: null },
    { id: 'revaluationSurpluses', type: 'SNC' },
    { id: 'otherChangesOwnCapital', type: null },
    { id: 'netIncome', type: null }
  ];

  const passiveNoCurrentIds = [
    { id: 'provisions', type: null },
    { id: 'financingObtained', type: null },
    { id: 'otherBillsToPay', type: null }
  ];

  const passiveCurrentIds = [
    { id: 'providers', type: null },
    { id: 'advancesFromCustomers', type: 'SNC' },
    { id: 'passivePublicEntities', type: null },
    { id: 'passiveSuppliersAdvances', type: 'SNC' },
    { id: 'currentFinancingObtained', type: 'SNC' },
    { id: 'passiveDeferrals', type: null },
    { id: 'currentOtherBillsToPay', type: 'SNC' },
    { id: 'otherPassives', type: 'SNC' },
    { id: 'otherCurrentPassive', type: 'NCM' }
  ];


  let filteredRows = filterRowsByTypeBalance([
    ...ownCapitalRowIds,
    ...passiveNoCurrentIds,
    ...passiveCurrentIds
  ], isNCM);

  modelValues.filter(model=> model.year<=year).every(model=>{

    let ownAndPassive = totalBalance(
        filterRowsByTypeBalance(
            [
              ...ownCapitalRowIds,
              ...passiveNoCurrentIds,
              ...passiveCurrentIds
            ],
            isNCM
        ),
        model
    );

    let active = totalBalance(
        filterRowsByTypeBalance(
            [
              ...activeNoCurrentRowIds,
              ...activeCurrentRowIds
            ],
            isNCM
        ),
        model
    );
    if(parseFloat(maxFixed(active)) !== parseFloat(maxFixed(ownAndPassive))){
      errorMessage.push(intl.formatMessage({
        id: 'error.forecastBalanceAllYears.activeEqualPassive'
      }));
      valid=false;
      return false;
    }

    return true;
  });


  return valid;
}


function totalBalance(keysToSum, yearForecast) {
  let sum = 0;
  keysToSum.forEach((key) => (sum = parseFloat(sum) + parseFloat(yearForecast[key.id])));
  return sum;
}

function filterRowsByTypeBalance(rows, isNCM) {
  if (isNCM) {
    return rows.filter((r) => r.type === null || r.type === ForeCastType.NCM);
  }

  return rows.filter((r) => r.type === null || r.type === ForeCastType.SNC);
}


function validateStep3(values, setError, intl,errorMessage) {

  let size = values.promotersMetaData.filter(p=> p.promoterType==='PRIMARY' || p.promoterType==='SECONDARY').length;
  if(size >5){
    size=5;
  }

  if (values.candidatureEmployeeSupportDetails.length !== size) {
    errorMessage.push(intl.formatMessage({
      id: 'errors.candidatureForm.employeeSupportDetails'
    }));

    return false;
  }




  let notDuplicated =  new Set(values.candidatureEmployeeSupportDetails.map(cE=>cE.promoterMetaData));
  if(notDuplicated.size!= values.candidatureEmployeeSupportDetails.length) {
    errorMessage.push( intl.formatMessage({
      id: 'errors.candidatureForm.employeeSupportDetails.duplicatedPromoter'
    }));
    return false;
  }

  return true;
}



function validateStep19(values, setError, intl,errorMessage) {
  let isValid = true;


  const requiredDocumentsTypes = [
    'BUDGETS_INVOICES',
    'ARTISAN_STATUS_PROOF',
    'PROVISIONAL_BALANCE',

  ];

  if(values.candidatureDetails.wasCreated===true){
    requiredDocumentsTypes.push('SUPPORTING_ACTIVITY_START','SOCIAL_PACT_CERTIFICATION','MINIMIS_DECLARATION');
  }

  values.step19Documents
    .filter((document) => requiredDocumentsTypes.includes(document.documentType))
    .every((document) => {
      if (!document.submited) {
        errorMessage.push( intl.formatMessage({
          id: 'errors.candidatureForm.requiredFiles'
        }));

        isValid = false;
        return false;
      }
      return true;
    });

  const unemploymentBenefitsPositiveAnswers = values.promotersMetaData.filter(
    (pm) => pm.applyUnemploymentBenefits
  ).length;

  const districtApplicationDocsSize = values.step19Documents.filter(
    (document) => document.documentType === 'DISTRICT_APPLICATION'
  ).length;

  if (unemploymentBenefitsPositiveAnswers !== districtApplicationDocsSize) {
    errorMessage.push(intl.formatMessage({
      id: 'errors.candidatureForm.inconsistentDistrictApplicationDocuments'
    }))

    isValid = false;
  }

  return isValid;
}

function validateStep20(values, setError, intl,errorMessage) {
  const irsFiles = values.step10Documents.filter(
    (file) => file.documentType === 'REGULARIZED_STATUS_DECLARATION'
  );
  const socialFiles = values.step10Documents.filter(
    (file) => file.documentType === 'SOCIAL_REGULARIZED_STATUS_DECLARATION'
  );

  let atDeclarationsAuthorized = 0;
  let ssDeclarationsAuthorized = 0;

  const promoters = values.promotersMetaData;

  promoters.forEach((pm) => {
    if (pm.atDeclarationAuthorized) {
      atDeclarationsAuthorized += 1;
    }

    if (pm.ssDeclarationAuthorized) {
      ssDeclarationsAuthorized += 1;
    }
  });

  let promoterSize= promoters.length
  if(values.candidatureDetails.wasCreated===true){
    promoterSize++;
    if(values.candidatureDetails.atDeclarationAuthorized===true){
      atDeclarationsAuthorized+=1;
    }
    if(values.candidatureDetails.ssDeclarationAuthorized===true){
      ssDeclarationsAuthorized+=1;
    }
  }


  if (
    !(
      irsFiles.length === promoterSize - atDeclarationsAuthorized &&
      socialFiles.length === promoterSize - ssDeclarationsAuthorized
    )
  ) {
    errorMessage.push(intl.formatMessage({
      id: 'errors.candidatureForm.inconsistentDeclarations'
    }));

    return false;
  }
  return true;
}
