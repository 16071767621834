export const FunctionType = {
  ADMIN_DIR: 'ADMIN_DIR',
  ADMIN_FINANCE: 'ADMIN_FINANCE',
  COMMERCIAL: 'COMMERCIAL',
  PRODUCTION: 'PRODUCTION',
  MAINTENANCE: 'MAINTENANCE',
  OTHER: 'OTHER',

  values: () => {
    return [
      'ADMIN_DIR',
      'ADMIN_FINANCE',
      'COMMERCIAL',
      'PRODUCTION',
      'MAINTENANCE',
      'OTHER'
    ];
  }
};
