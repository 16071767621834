import React, { useEffect, useState } from 'react';
import {Alert, Button, Col, Container, Modal, Row} from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';
import { AlertError } from '../../../components/bootstrap/AlertError';
import { AlertSuccess } from '../../../components/bootstrap/AlertSuccess';
import { EnumSelectField } from '../../../components/bootstrap/EnumSelectField';
import { LabelToolTip } from '../../../components/bootstrap/LabelToolTip';
import { CustomPagination } from '../../../components/general/CustomPagination';
import { EmptyResults } from '../../../components/general/EmptyResults';
import Loading from '../../../components/general/Loading';
import {SubTemplate} from '../../../components/general/SubTemplate';
import {Arrays} from '../../../utils/Arrays';
import {handleError, isNotBusinessError} from '../../../utils/handleError';
import {EntityChangeFilter} from "../../../components/entityChange/EntityChangeFilterBar";
import {canCreateEntityChange, listEntityChanges} from "../../../rest/entityChanges";
import {EntityChangeType} from "../../../models/EntityChangeType";
import {isEntityUser} from "../../../authentication/authenticationHelper";
import {FaInfoCircle} from "react-icons/fa";

const MAX_RESULTS = 25;
const DEFAULT_INITIAL_PAGE = 0;

export function ListEntityChanges() {
	const intl = useIntl();
	const { nif } = useParams();

	const navigate = useNavigate();

    const [changes, setChanges] = useState(null);
    const [availableChanges, setAvailableChanges] = useState([]);
    const [unavailableChanges, setUnavailableChanges] = useState([]);
    const [showCreateDialog, setShowCreateDialog] = useState(false);

	const [filters, setFilters] = useState({
		type: '-1',
		state: '-1',
		ordination: 'ASC',
	});
	const [currentPage, setCurrentPage] = useState(DEFAULT_INITIAL_PAGE);

	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);

	const [lsItem, setLsItem] = useState(null);

	useEffect(() => {
		fetchData();
	}, [currentPage]);

	useEffect(() => {
		checkStorageItems();
	})

	async function fetchData() {
		try {
			const [
			{ data: changes},
			{ data: availableChanges}
			] = await Promise.all([
			listEntityChanges(currentPage,MAX_RESULTS,filters, nif),
			canCreateEntityChange(nif)
			]);
			setChanges(changes);
			setAvailableChanges(availableChanges);
            setUnavailableChanges(getUnavailableChanges(availableChanges));
        } catch (error) {
			setError(error);
		} finally {
			setLoading(false);
		}
	}

	const onPageChange = (pageNumber) => {
		setCurrentPage(pageNumber);
	};

	function handleSearch() {
		fetchData();
	}

    function getUnavailableChanges(availableChanges) {
        const filtered = [];

        for (const type of EntityChangeType.values()) {
            if (!availableChanges.includes(type)) {
                if (type === EntityChangeType.HR || type === EntityChangeType.CONTACT
                    || (type === EntityChangeType.LOCATION && !filtered.includes(EntityChangeType.GEO))
                    || (type === EntityChangeType.GEO && !filtered.includes(EntityChangeType.LOCATION))
                ) {
                    filtered.push(type);
                }
            }
        }
        return filtered;
    }

	function handleOrdinationChange(e) {
		filters.ordination = e.target.value;
		setFilters({ ...filters });

		if (e.target.value === 'ASC') {
			changes.list = changes.list.sort(
				(a, b) => new Date(a.submissionDate) - new Date(b.submissionDate)
			);
		}

		if (e.target.value === 'DESC') {
			changes.list = changes.list.sort(
				(a, b) => new Date(b.submissionDate) - new Date(a.submissionDate)
			);
		}

		setChanges({ ...changes });
	}

	if (loading) {
		return <Loading />;
	}

	if (error && isNotBusinessError(error)) {
		return handleError(error);
	}

	function handleChangeRedirect(entityChange) {
		navigate(`/alteracoesEntidade/${nif}/${entityChange.type}/${entityChange.externalId}`);
	}

	function checkStorageItems() {
		[
			'entityChangeSubmitContact',
			'entityChangeSubmitGeo',
			'entityChangeSubmitHr',
			'entityChangeSubmitLocation',
		].forEach((s) => {
			if (sessionStorage.getItem(s)) {
				setLsItem(s);
			}
		});


	}

	return (
		<SubTemplate titleId={'entityChange.list'} hasBackButton>
			<Container>
				<div className='mx-2 mb-4'>
					<AlertSuccess lsItem={lsItem} />
				</div>
                {isEntityUser() && unavailableChanges.length > 0
                    && unavailableChanges.map((type, index) => (
					<Alert key={index} className='d-flex align-items-center mb-4' variant='warning'>
					<div className='px-3'><FaInfoCircle /></div>
					<div className='px-3'>
						<strong><FormattedMessage id={`entityChange.activeChanges.alert.title`}/></strong>
						<p className='mb-0'><FormattedMessage id={`entityChange.activeChanges.${type}.alert.body`}/></p>
					</div>
				</Alert>
				))}
				<EntityChangeFilter
					filters={filters}
					setFilters={setFilters}
					search={handleSearch}
					handleOrdinationChange={handleOrdinationChange}
				/>
				<Modal show={showCreateDialog} onHide={() => setShowCreateDialog(false)}>
					<Modal.Header closeButton>
						<Modal.Title>
							<FormattedMessage id={'entityChange.create.choose'} />
						</Modal.Title>
					</Modal.Header>
					<AlertError error={error} />
					<Modal.Body>
						<Row>
						{availableChanges.map((type,index)=> (
							<Col md={12}>
								<Button type="submit" variant="btn btn-primary d-flex align-items-center justify-content-center mb-4 w-100" onClick={() => navigate('/alteracoesEntidade/'+nif+"/adicionar/"+type)}>
									<FormattedMessage id={'entityChangeType.'+type} />
								</Button>
							</Col>
						))}
						</Row>

					</Modal.Body>
					<Modal.Footer className='justify-content-between'>
						<button className={'btn btn-outline-primary px-5'} onClick={() => setShowCreateDialog(false)}>
							<FormattedMessage id='all.back' />
						</button>
					</Modal.Footer>
				</Modal>

				<Row className='searchResults'>
					<Col md='10'>
						<AlertError error={error} />

						{Arrays.isNotEmpty(changes.list) ? (
							<>
								<Row className='mt-5 mb-1 d-flex justify-content-between px-2'>
									<Col className='text-muted'>
										{changes.totalResults}{' '}
										<FormattedMessage id={`all.results.lowCase.${changes.totalResults === 1 ? 'singular' : 'plural'}`} />
									</Col>
									<Col md='3'>
										<EnumSelectField
											controlId={'ordination'}
											preffixDescriptionId={'orderType'}
											name={'ordination'}
											handleChange={(e) => handleOrdinationChange(e)}
											options={['ASC', 'DESC']}
											value={filters.ordination}
										/>
									</Col>
								</Row>

								<ul className='results'>
									{changes.list.map((change, index) => (
										<li key={index}>
											<Row className={'py-2'} onClick={() => handleChangeRedirect(change)}>
												<Col md='6' className='d-flex align-items-center'>
													<LabelToolTip
														labelId='entityChange.type'
														value={intl.formatMessage({
															id: `entityChangeType.${change.type}`,
														})}
													/>
												</Col>
												<Col
													md='3'
													className='d-flex align-items-center text-secondary'
												>
													<LabelToolTip
														labelId='priorSupport.state'
														value={intl.formatMessage({
															id: `entityChangeState.${change.state}`,
														})}
													/>
												</Col>
												<Col
													md='3'
													className='d-flex align-items-center justify-content-end text-secondary'
												>
													<LabelToolTip
														labelId='candidatureTabs.submissionDate'
														value={change.submissionDate}
													/>
												</Col>
											</Row>
										</li>
									))}
								</ul>
							</>
						) : (
							<EmptyResults />
						)}
					</Col>
					<Col md={2}>
					{availableChanges.length > 0 && isEntityUser() &&
							<Button type="submit" variant="btn btn-primary d-flex align-items-center justify-content-center mb-4 w-100" onClick={() => setShowCreateDialog(true)}>
								<FormattedMessage id='entityChange.create' />
							</Button>
						}
					</Col>
				</Row>
				<Row className='mb-5'>
					<Col className='d-flex justify-content-center'>
						<CustomPagination
							dataPerPage={MAX_RESULTS}
							totalData={changes.totalResults}
							onPageChange={onPageChange}
							currentPage={currentPage}
						/>
					</Col>
				</Row>
			</Container>
		</SubTemplate>
	);
}
