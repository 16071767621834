import React from 'react';
import { Col, Form, Row, Table } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import {
	generateTimeline,
	getArrayIndexOfMceYearType,
} from '../../utils/DevelopmentPlanTimelineUtils';
import { ProcessState } from '../../models/ProcessState';
import { DateTimeInputField } from '../bootstrap/DateTimeInputField';
import { TextInputField } from '../bootstrap/TextInputField';
import { ApprovedByPromoter } from '../general/ApprovedByPromoter';
import { SubmittedByEntity } from '../general/SubmittedByEntity';

export function YearTimeline({
	step,
	numberOfSteps,
	errors,
	formikValues,
	handleChange,
	handlePreviousStep,
	handleNextStep,
	setFieldValue,
	mceYearType,
	finalStep,
	readMode,
	entityGetList,
}) {
	const intl = useIntl();

	const mceYearTypeIndex = getArrayIndexOfMceYearType(mceYearType);

	return (
		<div>
			<h4 className='text-primary mb-0'>
				<FormattedMessage id={`developmentPlan.mceYearType.${mceYearType}`} />
			</h4>
			<small className='text-secondary text-uppercase'>
				{intl.formatMessage(
					{ id: 'all.step' },
					{ step: step, numberOfSteps: numberOfSteps }
				)}
			</small>
			<fieldset disabled={readMode == true}>
				<Row className='mb-4'>
					<Col md='6'>
						<Form.Label>
							<FormattedMessage id='developmentPlan.timeline.semester.first' />
						</Form.Label>
						<Row>
							<Col>
								{(formikValues.timelines[mceYearTypeIndex]
									.firstSemesterInitialDate!==null) ? (formikValues.timelines[mceYearTypeIndex]
									.firstSemesterInitialDate +
									' a ' +
									formikValues.timelines[mceYearTypeIndex].firstSemesterEndDate) :' - '}
							</Col>
						</Row>
					</Col>

					<Col md='6'>
						<Form.Label>
							<FormattedMessage id='developmentPlan.timeline.semester.second' />
						</Form.Label>
						<Row>
							<Col>
								{(formikValues.timelines[mceYearTypeIndex].secondSemesterInitialDate!==null) ? (   formikValues.timelines[mceYearTypeIndex]
									.secondSemesterInitialDate +
									' a ' +
									formikValues.timelines[mceYearTypeIndex]
										.secondSemesterEndDate) : ' - '}
							</Col>
						</Row>
					</Col>
				</Row>

				{generateTimeline(
					mceYearTypeIndex,
					formikValues,
					setFieldValue,
					handleChange,
					errors,
					intl
				)}
				{finalStep &&
					formikValues.identificationEa.technicalTeamManagerExternalId !==
						'-1' && (
						<>
							<Row>
								<Col>
									<TextInputField
										labelId='priorSupportPaymentRequest.step4.technicalTeamManager'
										value={
											entityGetList.filter(
												(get) =>
													get.externalId ===
													formikValues.identificationEa
														.technicalTeamManagerExternalId
											)?.[0]?.name
										}
										disabled
									/>
								</Col>
							</Row>
							{(formikValues.processState === ProcessState.SUBMITTED ||
								formikValues.processState === ProcessState.APPROVED) && (
								<SubmittedByEntity
									className='mt-5'
									entityName={formikValues.identificationEa.entity.description}
									submissionDate={formikValues.submissionDate}
								/>
							)}

							{formikValues.processState === ProcessState.APPROVED && (
								<ApprovedByPromoter
									approvalDate={formikValues.approvalDate}
									className='mt-4'
									promoterName={formikValues.promoterName}
								/>
							)}
						</>
					)}
			</fieldset>
			<div className='form-actions mt-5'>
				<button
					className='btn btn-link'
					type='button'
					onClick={handlePreviousStep}
				>
					<FormattedMessage id='all.back' />
				</button>
				{!finalStep && (
					<button
						className='btn btn-primary'
						type='button'
						onClick={handleNextStep}
					>
						<FormattedMessage id='all.nextStep' />
					</button>
				)}
			</div>
		</div>
	);
}
