import React, { useEffect, useState } from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { NumberInputField } from '../bootstrap/NumberInputField';
import { TextInputField } from '../bootstrap/TextInputField';
import { Link } from 'react-router-dom';

export function CompanyIdentificationForm({
	errors,
	handleChange,
	formikValues,
	showPostalCode,
	readMode,
	hasCandidatureProcessNumber,
}) {
	const intl = useIntl();

	return (
		<fieldset disabled={readMode==true}>
			<Row>
				<Col md='8'>
					<TextInputField
						labelId={'semestralReportForm.step2.designation'}
						name={'company.name'}
						handleChange={handleChange}
						value={formikValues.company.name}
						isInvalid={errors.company?.name}
						errorMessage={errors.company?.name}
					/>
				</Col>
				<Col md='4'>
					<NumberInputField
						label={'NIPC'}
						name={'company.nipc'}
						handleChange={handleChange}
						value={formikValues.company.nipc}
						maxLength={9}
						isInvalid={errors.company?.nipc}
						errorMessage={errors.company?.nipc}
					/>
				</Col>
			</Row>
			<Row>
				<Col md='8'>
					<TextInputField
						controlId={'company.address'}
						labelId={'all.address'}
						name={'company.address'}
						handleChange={handleChange}
						value={formikValues.company.address}
						isInvalid={errors.company?.address}
						errorMessage={errors.company?.address}
					/>
				</Col>
				{showPostalCode && (
					<Col md='4'>
						{readMode ? (
							<NumberInputField
								disabled
								value={
									formikValues.company.postalCode4Digits +
									' - ' +
									formikValues.company.postalCode3Digits
								}
								labelId='eaAccreditationForm.step1.postalCode'
							/>
						) : (
							<>
								<Form.Label>
									<FormattedMessage id='all.postalCode' />
								</Form.Label>
								<div className='d-inline d-flex align-items-center'>
									<TextInputField
										name={'company.postalCode4Digits'}
										handleChange={handleChange}
										value={formikValues.company.postalCode4Digits}
										isInvalid={errors.company?.postalCode4Digits}
										errorMessage={errors.company?.postalCode4Digits}
										maxLength={4}
									/>
									<span className='ms-2 me-2'>{'-'}</span>
									<TextInputField
										name={'company.postalCode3Digits'}
										handleChange={handleChange}
										value={formikValues.company.postalCode3Digits}
										isInvalid={errors.company?.postalCode3Digits}
										errorMessage={errors.company?.postalCode3Digits}
										maxLength={3}
									/>
								</div>
							</>
						)}
					</Col>
				)}
			</Row>
			<Row>
				<Col md='8'>
					<TextInputField
						labelId={'all.email'}
						name={'company.email'}
						handleChange={handleChange}
						value={formikValues.company.email}
						isInvalid={errors.company?.email}
						errorMessage={errors.company?.email}
					/>
				</Col>
				<Col md='4'>
					<NumberInputField
						labelId={'all.phone'}
						name={'company.phone'}
						handleChange={handleChange}
						value={formikValues.company.phone}
						maxLength={9}
						isInvalid={errors.company?.phone}
						errorMessage={errors.company?.phone}
					/>
				</Col>
				<Col md={!hasCandidatureProcessNumber ? '12' : '8'}>
					<TextInputField
						labelId={'semestralReportForm.step2.companyResponsibleName'}
						name={'company.personInChargeName'}
						handleChange={handleChange}
						value={formikValues.company.personInChargeName}
						isInvalid={errors.company?.personInChargeName}
						errorMessage={errors.company?.personInChargeName}
					/>
				</Col>
				{hasCandidatureProcessNumber &&
					<Col md='4'>
						<Form.Label>
							{intl.formatMessage({
								id: 'consultingRequest.candidatureProcessId',
							})
							}
						</Form.Label>
						<Link
							to={`/candidatura/${formikValues.candidatureExternalId}`}
						>
							{formikValues.candidatureProcessNumber}
						</Link>
					</Col>}
			</Row>
		</fieldset>
	);
}
