import React, { useEffect, useState } from 'react';
import { Badge, Container, Dropdown, Nav, Navbar } from 'react-bootstrap';
import { FaSignOutAlt, FaUserEdit } from 'react-icons/fa';
import { FormattedMessage } from 'react-intl';
import { Link, useNavigate } from 'react-router-dom';
import {
  getAccessInfo,
  getUserInfo,
  hasPermission, isEntityAndUnaccredited, isEntityUser,
  isIEFPUser,
  isPromoterUser,
  logout
} from '../../authentication/authenticationHelper';
import { useGlobalContext } from '../../context';
import { UserState } from '../../models/UserState';
import { getUserMessagesPending } from '../../rest/messages';
import { getCanEditProfile } from '../../rest/promotercandidature';
import { userHasPriorSupport } from '../../rest/user';

function NavBar() {
  const { accessInfo, nMessages, setNMessages, hasPriorSupport, setHasPriorSupport,canEditProfile, setCanEditProfile,name} =
    useGlobalContext();

  const userInfo = getUserInfo();


  const navigate = useNavigate();

  async function getEditProfileAllowance() {

    let res = await getCanEditProfile();
    setCanEditProfile(res.data);
  }

  function handleEditProfile() {
    navigate('/completarRegisto', {
      state: { editProfileAllowed: true }
    });
  }

  const handleLogout = () => {
    logout();
  };

  useEffect(() => {
    const getMessages = async () => {
      if (hasPermission('GET_USER_MESSAGE', accessInfo)) {
        const { data: messagesData } = await getUserMessagesPending();
        setNMessages(messagesData);
      }
    };
    const getHasPriorSupport = async () => {
      if (hasPermission('GET_USER_MESSAGE', accessInfo)) {
        const { data: priorSupport } = await userHasPriorSupport();
        setHasPriorSupport(priorSupport);
      }
    };


    getMessages();
    getHasPriorSupport();
    if (getAccessInfo() !== null && isPromoterUser()) {
      getEditProfileAllowance();
    }
  });

  const isUnaccreditedEntity = getAccessInfo() !== null && isEntityAndUnaccredited();



  return (
    <>
      <a href='#content' className='skip-to-content'>
        <FormattedMessage id='navBar.skipToContent' />
      </a>
      <Navbar className='shadow' expand='lg'>
        <Container>
          <Navbar.Brand href='/' className={'js-scroll-trigger mr-5'}>
            <img src={require('../../images/empreende21-logo.png')} alt='logo' />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls='navbar' />
          <Navbar.Collapse id='navbar' className='justify-content-end'>
            <Nav>
              {!accessInfo ? (
                <>
                  <li className='nav-item'>
                    <a className='nav-link' href='#o-que-e'>
                      <FormattedMessage id='navBar.whatIs' />
                    </a>
                  </li>
                  <li className='nav-item'>
                    <a className='nav-link' href='#destinatarios'>
                      <FormattedMessage id='navBar.recipients' />
                    </a>
                  </li>
                  <li className='nav-item'>
                    <a className='nav-link' href='#contactos'>
                      <FormattedMessage id='navBar.contacts' />
                    </a>
                  </li>
                  <li className='nav-item'>
                    <a className='nav-link' href={'/login'}>
                      <FormattedMessage id='navBar.reservedArea' />
                    </a>
                  </li>
                </>
              ) : (
                <>
                  <li className='nav-item'>
                    <Link className='nav-link' to='/'>
                      <FormattedMessage id='all.home' />
                    </Link>
                  </li>
                  {hasPermission('VIEW_INDICATORS', accessInfo) && !isUnaccreditedEntity && (
                      <li className='nav-item'>
                        <Link className='nav-link' to='/indicadores'>
                          <FormattedMessage id='indicators.title' />
                        </Link>
                      </li>
                  )}
                  {hasPermission('LIST_TEMPLATES', accessInfo) && !isUnaccreditedEntity &&  (
                      <li className='nav-item'>
                        <Link className='nav-link' to='/notificacoes'>
                          <FormattedMessage id='template.list' />
                        </Link>
                      </li>
                  )}

                  {hasPermission('VALIDATE_PRIOR_SUPPORT', accessInfo) && !isUnaccreditedEntity && (
                    <li className='nav-item'>
                      <Link className='nav-link' to='/listagemContratos/lista'>
                        <FormattedMessage id='contracts.list' />
                      </Link>
                    </li>
                  )}
                  {hasPermission('VALIDATE_PRIOR_SUPPORT', accessInfo) && !isUnaccreditedEntity && (
                    <li className='nav-item'>
                      <Link className='nav-link' to='/atividadesComplementares/plano/lista'>
                        <FormattedMessage id='activities.text' />
                      </Link>
                    </li>
                  )}
                  {hasPermission('VALIDATE_PRIOR_SUPPORT', accessInfo) && !isUnaccreditedEntity && (
                    <li className='nav-item'>
                      <Link className='nav-link' to='/viabilidadeEconomica'>
                        <FormattedMessage id='analyses.text' />
                      </Link>
                    </li>
                  )}
                  {hasPermission('VALIDATE_PRIOR_SUPPORT', accessInfo) && !isUnaccreditedEntity && (
                    <li className='nav-item'>
                      <Link className='nav-link' to='/pedidos'>
                        <FormattedMessage id='support.requests.title' />
                      </Link>
                    </li>
                  )}

                  {hasPermission('GET_HOLIDAY', accessInfo) && !isUnaccreditedEntity && (
                    <li className='nav-item'>
                      <Link className='nav-link' to='/feriados'>
                        <FormattedMessage id='holidays.list' />
                      </Link>
                    </li>
                  )}
                  {hasPermission('GET_PARAMETER', accessInfo) && !isUnaccreditedEntity && (
                    <li className='nav-item'>
                      <Link className='nav-link' to='/parametros'>
                        <FormattedMessage id='parameter.list' />
                      </Link>
                    </li>
                  )}
                  {hasPriorSupport && !isUnaccreditedEntity && (
                    <li className='nav-item'>
                      <Link className='nav-link' to='/apoioPrevio/contactos'>
                        <FormattedMessage id='priorSupport.title' />
                      </Link>
                    </li>
                  )}

                  {hasPermission('GET_USER_MESSAGE', accessInfo) && (
                    <li className='nav-item'>
                      <Link className='nav-link' to='/perfil/mensagens'>
                        <FormattedMessage id='messages.list' />
                        {nMessages > 0 && (
                          <Badge pill bg='light' className='text-primary'>
                            {nMessages}
                          </Badge>
                        )}
                      </Link>
                    </li>
                  )}

                  {hasPermission('ADD_ENTITIES', accessInfo) && !isUnaccreditedEntity && (
                    <li className='nav-item'>
                      <Link className='nav-link' to='/entidades/adicionar'>
                        <FormattedMessage id='entities.text' />
                      </Link>
                    </li>
                  )}

                  {isPromoterUser() && (
                    <li className='nav-item'>
                      <Link className='nav-link' to='/avaliacoes'>
                        <FormattedMessage id='evaluations.text' />
                      </Link>
                    </li>
                  )}

                  {isPromoterUser() &&
                    hasPermission('ADD_CANDIDATURE', accessInfo) &&
                    userInfo?.userState === UserState.CREATED && (
                      <li className='nav-item'>
                        <Link className='nav-link' to='/candidatura'>
                          <FormattedMessage id='candidature.text' />
                        </Link>
                      </li>
                    )}
                  {(hasPermission('GET_CANDIDATURE_LIST', accessInfo) && !isEntityUser()) && (
                    <li className='nav-item'>
                      <Link className='nav-link' to='/candidaturas'>
                        {isPromoterUser() ? (
                          <FormattedMessage id='candidature.old.list' />
                        ) :(
                          <FormattedMessage id='candidature.list' />
                        )}
                      </Link>
                    </li>
                  )}
                  {isIEFPUser() &&
                    hasPermission('LIST_PROCESSES', accessInfo) &&
                    userInfo?.userState === UserState.CREATED && (
                      <>
                        <li className='nav-item'>
                          <Link className='nav-link' to='/processos'>
                            <FormattedMessage id='processes.text' />
                          </Link>
                        </li>
                        <li className='nav-item'>
                          <Link className='nav-link' to='/entidades'>
                            <FormattedMessage id='entities.text' />
                          </Link>
                        </li>
                      </>
                    )}
                  <li className={'nav-item exit-btn'}>
                    <Dropdown>
                      <Dropdown.Toggle>
                        {name!==null ? name : (userInfo ? userInfo.name : accessInfo.name)}
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        {canEditProfile && (
                          <Dropdown.Item onClick={handleEditProfile}>
                            <FaUserEdit className='mx-1' />
                            <FormattedMessage id='navBar.editProfileButtonText' />
                          </Dropdown.Item>
                        )}
                        <Dropdown.Item onClick={handleLogout}>
                          <FaSignOutAlt className='mx-1' />
                          <FormattedMessage id='navBar.logoutButtonText' />
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </li>
                </>
              )}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default NavBar;
