import { apiAxios as authAxios } from '../axios.config';

export const saveAdditionalActivitiesPlan = (additionalActivitiesPlan, queryParams) => {
  return authAxios.post('/api/additionalActivitiesPlan', additionalActivitiesPlan, queryParams ? {params: queryParams}: null)
}

export const getUserAdditionalActivitiesPlan = () => {
  return authAxios.get('/api/additionalActivitiesPlan')
}

export const getDefaultAdditionalActivitiesPlan = () => {
  return authAxios.get('/api/additionalActivitiesPlan/default')
}

export const getAdditionalActivitiesPlanReplacement = () => {
  return authAxios.get('/api/additionalActivitiesPlan/replacement')
}

export const getAdditionalActivitiesPlanByExternalId = (b17ExternalId, entityNif) => {
  return authAxios.get(`/api/additionalActivitiesPlan/${b17ExternalId}`, entityNif ? {params: {
    entityNif: entityNif
  }}: null)
}

export const getAdditionalActivitiesPlanByProcessExternalId = (b17ProcessExternalId) => {
  return authAxios.get(`/api/additionalActivitiesPlan/process/${b17ProcessExternalId}`)
}

export const listAdditionalActivitiesPlanDocuments = (b17ExternalId) => {
  return authAxios.get(`/api/additionalActivitiesPlan/${b17ExternalId}/document`)
}

export const uploadDocument = (b17ExternalId, formData) => {
  return authAxios.post(`/api/additionalActivitiesPlan/${b17ExternalId}/document`, formData)
}

export const deleteDocument = (b17ExternalId, documentExternalId) => {
  return authAxios.delete(`/api/additionalActivitiesPlan/${b17ExternalId}/document/${documentExternalId}`)
}

export const yearStatus = (entityNif) => {
  return authAxios.get('/api/additionalActivitiesPlan/yearStatus/', entityNif ? {params: {
    entityNif: entityNif
  }}: null)
}

export const listAdditionalActivitiesPlan = (queryParams) => {
  return authAxios.get('/api/additionalActivitiesPlan/list/', {params:  queryParams})
}

export const withdrawAdditionalActivitiesPlan = (b17ExternalId, authenticationCredentials) => {
  return authAxios.patch(`/api/additionalActivitiesPlan/${b17ExternalId}/withdrawal`, authenticationCredentials)
}

export const listAdditionalActivitiesPlanNotStarted = (entityNif) => {
  return authAxios.get('/api/additionalActivitiesPlan/thatHaveNotStarted', entityNif ? {params: {
    entityNif: entityNif
  }}: null)
}
