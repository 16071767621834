import {AlertError} from "../../../bootstrap/AlertError";
import {Formik} from "formik";
import {Col, Container, Form, Row} from "react-bootstrap";
import {DateTimeInputField} from "../../../bootstrap/DateTimeInputField";
import DecimalInputField from "../../../bootstrap/DecimalInputField";
import {FormattedMessage} from "react-intl";
import React, {useState} from "react";
import {useNavigate} from "react-router-dom";
import * as yup from "yup";
import {EnumSelectField} from "../../../bootstrap/EnumSelectField";
import {RevocationType} from "./RevocationType";
import {RestitutionType} from "./RestitutionType";
import {SingleUploadArea} from "../../../SingleUploadArea";
import {
    addAppealDocument,
    addNonCompliance,
    appealNonCompliance,
    deleteAppealDocument
} from "../../../../rest/nonCompliance";
import {MultipleUploadArea} from "../../../MultipleUploadArea";
import {DocumentType} from "../../../../models/DocumentType";
import {TextAreaInputField} from "../../../bootstrap/TextAreaInputField";


export function NonComplianceAppealForm ({candidatureExternalId, nonCompliance, onCancel, onSubmitCallback, documents, setDocuments}) {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [navErrors, setNavErrors] = useState([]);
    const navigate = useNavigate();


    async function onSubmit(values, setSubmitting, setErrors) {
        setSubmitting(true);
        try {
            await appealNonCompliance(candidatureExternalId, nonCompliance.externalId, values);
            onSubmitCallback?.()
            setSubmitted(true);
        } catch (error) {
            console.log(error);
            setErrors(error);
        }
        setSubmitting(false);
    }

    const validationSchema = yup.object().shape({
        text: yup
            .string()
            .typeError(<FormattedMessage id='errors.fieldRequiredText'/>)
            .required(<FormattedMessage id='errors.fieldRequiredText'/>),

    });


    const initialValues = {
        text: null
    };



    function validation (values, props) {
        const errors = {};
        if(documents.length === 0 || !documents.map(d => d.documentType).includes(DocumentType.NON_COMP_APPEAL)){
            errors.documents = <FormattedMessage id='need.documents'/>;
        }
        return errors;
    }

    return (
        <>
            <AlertError error={error} />
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                validateOnBlur={false}
                validate={validation}
                validateOnChange={false}
                onSubmit={(values, { setSubmitting, setErrors }) => {
                    onSubmit(values, setSubmitting, setErrors);
                }}>
                {({ values,
                      errors,
                      touched,
                      handleChange,
                      handleSubmit,
                      setFieldValue
                  }) => (
                    <Form onSubmit={handleSubmit}>
                        <AlertError error={errors} />
                        <Row>

                            <Col>
                                <TextAreaInputField
                                    controlId={'text'}
                                    label={<FormattedMessage id='candidatureTabs.oversight.tabs.noncompliance.appeal.text' /> }
                                    name={'text'}
                                    handleChange={({target}) => setFieldValue('text', target.value)}
                                    errorMessage={errors.text}
                                />
                            </Col>
                        </Row>


                        <Row className='mt-3'>
                            <Col>
                                <label>
                                    <FormattedMessage id='documents.list' />
                                </label>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <MultipleUploadArea
                                    documents={documents}
                                    documentType={DocumentType.NON_COMP_APPEAL}
                                    setDocuments={setDocuments}
                                    acceptExtensions='.pdf,.docx'
                                    uploadCallback={(formData) => addAppealDocument(candidatureExternalId, nonCompliance.externalId, formData)}
                                    deleteCallback={(documentExternalId) => deleteAppealDocument(candidatureExternalId, nonCompliance.externalId, documentExternalId)}
                                />
                                {"documents" in errors &&
                                    <Form.Control.Feedback type='invalid'>{errors.documents}</Form.Control.Feedback>
                                }
                            </Col>
                        </Row>

                        <Row className='form-actions'>
                            <Col className='justify-content-between d-flex'>
                                <button className='btn btn-outline-primary' type='button' onClick={() => onCancel?.()}>
                                    <FormattedMessage id='all.cancel' />
                                </button>
                                <button className='btn btn-primary' type='submit' >
                                    <FormattedMessage id='all.submitButtonText' />
                                </button>
                            </Col>
                        </Row>
                    </Form>
                )}
            </Formik>
        </>
    );
}