import React from 'react';
import { Col, Form, Row, Table } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { TermType } from '../../models/TermType';
import DecimalInputField from '../bootstrap/DecimalInputField';
import { EnumSelectField } from '../bootstrap/EnumSelectField';
import IntInputField from '../bootstrap/IntInputField';
import { NumberInputField } from '../bootstrap/NumberInputField';
import { TextAreaInputField } from '../bootstrap/TextAreaInputField';
import { TextInputField } from '../bootstrap/TextInputField';
import { EconomicStatusesForm } from '../general/EconomicStatusesForm';

export function FinancialSituationStep({
	step,
	numberOfSteps,
	errors,
	handleChange,
	formikValues,
	handleGoBack,
	handleNextStep,
	readMode,
}) {
	const intl = useIntl();

	return (
		<div>
			<h4 className='text-primary mb-0'>
				<FormattedMessage id='developmentPlan.financialSituation' />
			</h4>
			<small className='text-secondary text-uppercase'>
				{intl.formatMessage(
					{ id: 'all.step' },
					{ step: step, numberOfSteps: numberOfSteps }
				)}
			</small>
			<fieldset disabled={readMode == true}>
				<EconomicStatusesForm
					errors={errors}
					handleChange={handleChange}
					formikValues={formikValues}
					readMode={readMode}
				/>
				<p className='mt-4 mb-0'>
					<FormattedMessage id='strategicDiagnosis.valuationParameters' />
				</p>
				<Row>
					<Col>
						<Table className='mt-2 d-table'>
							<thead>
								<tr>
									<th className='w-33'>
										<FormattedMessage id='strategicDiagnosis.valuationParameters.VAL' />
									</th>
									<th className='w-33'>
										<FormattedMessage id='strategicDiagnosis.valuationParameters.TIR' />
									</th>
									<th className='w-33'>
										<FormattedMessage id='strategicDiagnosis.valuationParameters.PRC' />
									</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<th>
										<DecimalInputField
											name={`strategicDiagnosis.val`}
											value={formikValues.strategicDiagnosis.val}
											handleChange={handleChange}
											isInvalid={errors.strategicDiagnosis?.val}
											errorMessage={errors.strategicDiagnosis?.val}
											allowNegative={true}
										/>
									</th>
									<th>
										<DecimalInputField
											name={`strategicDiagnosis.tir`}
											value={formikValues.strategicDiagnosis.tir}
											handleChange={handleChange}
											isInvalid={errors.strategicDiagnosis?.tir}
											errorMessage={errors.strategicDiagnosis?.tir}
											suffixLabel='%'
										/>
									</th>
									<th>
										<Row>
											<Col md='5' className='pe-0'>
												<IntInputField
													name={`strategicDiagnosis.prc`}
													value={formikValues.strategicDiagnosis.prc}
													handleChange={handleChange}
													isInvalid={errors.strategicDiagnosis?.prc}
													errorMessage={errors.strategicDiagnosis?.prc}
													maxSize={2}
												/>
											</Col>
											<Col md='7' className='ps-0'>
												<EnumSelectField
													className='form-select selectWithIconAtRight'
													preffixDescriptionId={'termType'}
													handleChange={handleChange}
													name={`strategicDiagnosis.prcType`}
													options={TermType.values()}
													value={formikValues.strategicDiagnosis.prcType}
													isInvalid={errors.strategicDiagnosis?.prcType}
													errorMessage={errors.strategicDiagnosis?.prcType}
												/>
											</Col>
										</Row>
									</th>
								</tr>
							</tbody>
						</Table>
					</Col>
				</Row>

				<Row>
					<Col>
						<p className='mt-5 mb-0'>
							<FormattedMessage id='strategicDiagnosis.strengthsWeaknesses' />
						</p>
					</Col>
				</Row>
				<Row>
					<Col md='6'>
						<TextAreaInputField
							name={'strategicDiagnosis.strengths'}
							handleChange={handleChange}
							labelId={'strategicDiagnosis.strengthsWeaknesses.strong'}
							value={formikValues.strategicDiagnosis.strengths}
							isInvalid={errors.strategicDiagnosis?.strengths}
							errorMessage={errors.strategicDiagnosis?.strengths}
							placeholder={intl.formatMessage(
								{
									id: 'annualComplementaryActivitiesPlanForm.characters.placeholder',
								},
								{ nChars: '1000' }
							)}
							maxLength={1000}
						/>
					</Col>
					<Col md='6'>
						<TextAreaInputField
							name={'strategicDiagnosis.weaknesses'}
							handleChange={handleChange}
							labelId={'strategicDiagnosis.strengthsWeaknesses.weak'}
							value={formikValues.strategicDiagnosis.weaknesses}
							isInvalid={errors.strategicDiagnosis?.weaknesses}
							errorMessage={errors.strategicDiagnosis?.weaknesses}
							placeholder={intl.formatMessage(
								{
									id: 'annualComplementaryActivitiesPlanForm.characters.placeholder',
								},
								{ nChars: '1000' }
							)}
							maxLength={1000}
						/>
					</Col>
				</Row>
			</fieldset>
			<div className='form-actions mt-5'>
				<button
					className='btn btn-link'
					type='button'
					onClick={() => handleGoBack()}
				>
					<FormattedMessage id='all.back' />
				</button>

				<button
					className='btn btn-primary'
					type='button'
					onClick={() => handleNextStep()}
				>
					<FormattedMessage id='all.nextStep' />
				</button>
			</div>
		</div>
	);
}
