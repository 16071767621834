import parse from 'html-react-parser';
import React from 'react';
import { FaInfoCircle } from 'react-icons/fa';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import {
	TranslateMessageBoundTypeFilter,
	TranslateMessageType,
} from '../../utils/TranslateStates';

export function MessageList({ messages }) {

	return (
		<>
			{messages?.length > 0 ? (
				<table className='table d-table'>
					<thead>
						<tr>
							<th>
								<label>
									<FormattedMessage id='messages.titleText' />
								</label>
							</th>
							<th>
								<label>
									<FormattedMessage id='messages.boundType' />
								</label>
							</th>

							<th>
								<label>
									<FormattedMessage id='messages.typeText' />
								</label>
							</th>
							<th className='text-end'>
								<label>
									<FormattedMessage id='messages.dateText' />
								</label>
							</th>
						</tr>
					</thead>
					<tbody>
						{messages.map((message) => (
							<tr key={message.externalId}>
								<td>
									<Link
										to={`/perfil/mensagem/${message.externalId}`}
										style={{
											fontWeight:
												message?.state === 'PENDING' ? 'bolder' : 'normal',
										}}
									>
										{message.title ? parse(message.title) : 'No title'}
									</Link>
								</td>
								<td>
									{' '}
									<TranslateMessageBoundTypeFilter bound={message.boundType} />
								</td>

								<td>
									<TranslateMessageType value={message.type} />
								</td>
								<td className='text-end'>{message.creationTs}</td>
							</tr>
						))}
					</tbody>
				</table>
			) : (
				<div className='empty-state my-4'>
					<FaInfoCircle />
					<p>
						<FormattedMessage id='messages.noMessagesText' />
					</p>
				</div>
			)}
		</>
	);
}
