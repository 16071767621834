import { apiAxios as authAxios } from '../axios.config';

export const listContractsListing = (queryParams) => {
  return authAxios.get('/api/contractsListing/list/', {params:  queryParams})
}

export const getUserContractsListing = (b4ExternalId) => {
  return authAxios.get(`/api/contractsListing/${b4ExternalId !== undefined ? b4ExternalId : ''}`)
}

export const saveContractsListing = (contractsListing, queryParams) => {
  return authAxios.post('/api/contractsListing/', contractsListing, queryParams ? {params: queryParams}: null)
}

export const withdrawContractListing = (b4ExternalId, authenticationCredentials) => {
  return authAxios.patch(`/api/contractsListing/${b4ExternalId}/withdrawal`, authenticationCredentials)
}

export const contractListingMonthStatus = () => {
  return authAxios.get('/api/contractsListing/monthStatus/')
}

export const getEntityContractsListingForIefpView = (entityNif, queryParams) => {
  return authAxios.get(`/api/contractsListing/entity/${entityNif}`, {params: queryParams})
}

export const getContractsListingByProcessExternalId = (b4ProcessExternalId) => {
  return authAxios.get(`/api/contractsListing/process/${b4ProcessExternalId}`)
}
