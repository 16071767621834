import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

export function LabelToolTip({ labelId, value }) {
  return (
    <OverlayTrigger
      overlay={
        <Tooltip>
          <FormattedMessage id={labelId} />
        </Tooltip>
      }>
      <span>{value}</span>
    </OverlayTrigger>
  );
}
