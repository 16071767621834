import axios from 'axios';

export const getUserPermissions = (accessToken) => {
  const config = {
    Authorization: `Bearer ${accessToken}`,
    'Content-Type': 'application/json',
  };

  return axios({
    method: 'get',
    url: '/api/permissions/user',
    headers: config,
  });
};
