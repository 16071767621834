import React, {useEffect, useState} from 'react';
import {Col, Form, Row} from 'react-bootstrap';
import { FaInfoCircle } from 'react-icons/fa';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { createCustomErrorMessage } from '../../hooks/errorMessage';
import { PromoterType } from '../../models/PromoterType';
import { EnumSelectField } from '../bootstrap/EnumSelectField';
import { TextInputField } from '../bootstrap/TextInputField';
import ToolTip from "../bootstrap/ToolTip";
import {CandidatureFormUtils} from "../../utils/CandidatureFormUtils";
import {CandidatureState} from "../../models/CandidatureState";
import {GenderType} from "../../models/GenderType";
import {getActionNotificationsMetaData} from "../../rest/templates";
import {listQualificationLevels} from "../../rest/qualificationLevel";
import DecimalInputField from "../bootstrap/DecimalInputField";

export function CandidatureFormStep1({
  values,
  setFieldValue,
  errors,
  setError,
  handleSave,
  numberOfSteps
}) {

  console.log(values)

  const [qualifications, setQualifications] = useState([]);

  const intl = useIntl();

  const fetchData = async () => {
    try {
      const { data:  qualifications } = await listQualificationLevels();

      setQualifications(qualifications);
    } catch (error) {
      setError(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);



  async function addPromoter() {
      values.promotersMetaData.push({
        externalId: '',
        name: '',
        nif: '',
        email: '',
        promoterType: '-1',
        applyUnemploymentBenefits: '-1',
        socialCapital:0,
        voteRight:0
      });
      setFieldValue('promoters', [...values.promotersMetaData]);

  }

  function removePromoter(promoterValue) {
    let promotersFiltered = values.promotersMetaData.filter(p=> p.promoterType===PromoterType.PRIMARY || p.promoterType===PromoterType.SECONDARY);
    let index =promotersFiltered.findIndex(line=> Object.is(line,promoterValue));
    if(index !==-1){
      values.candidatureEmployeeSupportDetails = values.candidatureEmployeeSupportDetails.filter(c=> c.promoterMetaData!==''+index);
      setFieldValue('candidatureEmployeeSupportDetails', [...values.candidatureEmployeeSupportDetails]);
    }


    values.promotersMetaData = values.promotersMetaData.filter(
      (promoter) => promoter !== promoterValue
    );
    setFieldValue('promoters', [...values.promotersMetaData]);
  }

  function handlePromoterChange(e, index, type) {
    values.promotersMetaData[index][type] = e.target.value;
    setFieldValue('promoters', [...values.promotersMetaData]);
  }

  function handleApplyUnemploymentBenefitsChange(index, value) {
    values.promotersMetaData[index].applyUnemploymentBenefits = value;
    setFieldValue('promoters', [...values.promotersMetaData]);
    if(!CandidatureFormUtils.canAskUnemploymentBenefits(values.promotersMetaData) && values.state === CandidatureState.DRAFT){
      values.financialInvestmentDetails.forEach((f)=> f.ownCapitalUnemploymentBenefits=0);
    }
    setFieldValue('financialInvestmentDetails', [...values.financialInvestmentDetails]);
  }

  function handleNextStep() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    handleSave(true);
  }

  return (
    <div>
      <h4 className='text-primary mb-0'>
        <FormattedMessage id='candidatureForm.promotersIdentification' />
      </h4>
      <small className='text-secondary text-uppercase d-block'>
        {intl.formatMessage(
          { id: 'all.step' },
          { step: values.step, numberOfSteps: numberOfSteps }
        )}
      </small>
      <p className='mt-3 mb-0 text-secondary infoText'>
        <FaInfoCircle size={70} /> <FormattedMessage id='candidatureForm.infoText1' />
      </p>
      {values.promotersMetaData.map((promoter, index) => (
        <Row key={index} className='border-bottom pb-4 mx-1 mb-3'>
          <Col>
            <Row>
              <Col>
                <p className='mt-4 mb-0 text-uppercase'>Promotor {index + 1} </p>
              </Col>
              <Col className='d-flex justify-content-end'>
                {index !== 0 && (
                  <button
                    className='mt-4 py-0 btn btn-link'
                    type='button'
                    onClick={() => removePromoter(promoter)}>
                    <FormattedMessage id='all.remove' />
                  </button>
                )}
              </Col>
            </Row>

            <Row>
              <div className={`col-lg-6 col-md-6 ${index ? '0' : 'viewMode'}`}>
                <TextInputField
                    disabled={index===0}
                  controlId={'name'}
                  labelId={'promoter.name'}
                  name={'name'}
                  handleChange={(e) => handlePromoterChange(e, index, 'name')}
                  value={promoter.name}
                  isInvalid={
                    errors.promotersMetaData ? errors.promotersMetaData[index]?.name : false
                  }
                  errorMessage={
                    errors.promotersMetaData ? errors.promotersMetaData[index]?.name : ''
                  }
                />
              </div>


              <div
                  className={
                    index !== 0
                        ? 'col-lg-6 col-md-6'
                        : `col-lg-6 col-md-6 ${index ? '0' : 'viewMode'}`
                  }>
                <EnumSelectField
                    disabled={index===0}
                    controlId={'promoterType'}
                    labelId={'candidatureForm.promoterType'}
                    preffixDescriptionId={'candidatureForm.promoterType'}
                    name={'promoterType'}
                    handleChange={(e) => handlePromoterChange(e, index, 'promoterType')}
                    options={
                      index === 0
                          ? ['PRIMARY', 'SECONDARY', 'OTHER']
                          : PromoterType.values().filter((value) => value !== 'PRIMARY')
                    }
                    value={promoter.promoterType}
                    nullOption={true}
                    isInvalid={
                      errors.promotersMetaData ? errors.promotersMetaData[index]?.promoterType : false
                    }
                    errorMessage={
                      errors.promotersMetaData ? errors.promotersMetaData[index]?.promoterType : ''
                    }
                />
              </div>


              {
                (promoter.promoterType!=='-1' && promoter.promoterType !==PromoterType.OTHER )  && (
                    <>
                    <div className={`col-lg-6 col-md-6 ${index ? '0' : 'viewMode'}`}>
                      <EnumSelectField
                          controlId={'gender'}
                          labelId={'promoterForm.gender'}
                          preffixDescriptionId={'promoterForm.gender'}
                          name={'gender'}
                          handleChange={(e) => handlePromoterChange(e, index, 'gender')}
                          options={GenderType.values()}
                          value={promoter.gender}
                          nullOption={true}
                          isInvalid={
                            errors.promotersMetaData ? errors.promotersMetaData[index]?.gender : false
                          }
                          errorMessage={
                            errors.promotersMetaData ? errors.promotersMetaData[index]?.gender : ''
                          }
                      />

                    </div>
                    <div className={`col-lg-6 col-md-6 ${index ? '0' : 'viewMode'}`}>
                      <Form.Group controlId={'qualificationLevel'}>
                        <Form.Label>
                          <FormattedMessage id='promoterForm.qualificationLevel' />
                        </Form.Label>
                        <Form.Control
                            as='select'
                            name={'qualificationLevel'}
                            onChange={(e) => handlePromoterChange(e, index, 'qualificationLevel')}
                            value={promoter.qualificationLevel}
                            isInvalid={errors.promotersMetaData ? errors.promotersMetaData[index]?.qualificationLevel : false}>
                          <option value={'-1'}>
                            <FormattedMessage id='all.chooseOne' />
                          </option>
                          {qualifications.sort((a,b) => a.code - b.code).map((qualification) => (
                              <option key={qualification.code} value={qualification.code}>
                                <FormattedMessage
                                    id={`qualificationLevel.${qualification.code}`}
                                />
                              </option>
                          ))}
                        </Form.Control>
                        <Form.Control.Feedback type='invalid'>
                          { errors.promotersMetaData ? errors.promotersMetaData[index]?.qualificationLevel : ''}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </div>



                    </>




                )

              }









              <div className={`col-lg-6 col-md-6 ${index ? '0' : 'viewMode'}`}>
                <TextInputField
                  controlId={'nif'}
                  label={'NIF'}
                  name={'nif'}
                  handleChange={(e) => handlePromoterChange(e, index, 'nif')}
                  value={promoter.nif}
                  disabled={index === 0}
                  maxLength={9}
                  isInvalid={
                    errors.promotersMetaData ? errors.promotersMetaData[index]?.nif : false
                  }
                  errorMessage={
                    errors.promotersMetaData ? errors.promotersMetaData[index]?.nif : ''
                  }
                />
              </div>

              <div className={`col-lg-6 col-md-6 ${index ? '0' : 'viewMode'}`}>
                <TextInputField
                    disabled={index===0}
                    controlId={'email'}
                    label={'Email'}
                    name={'email'}
                    handleChange={(e) => handlePromoterChange(e, index, 'email')}
                    value={promoter.email}
                    isInvalid={
                      errors.promotersMetaData ? errors.promotersMetaData[index]?.email : false
                    }
                    errorMessage={
                      errors.promotersMetaData ? errors.promotersMetaData[index]?.email : ''
                    }
                />
              </div>


              {promoter.promoterType !== PromoterType.OTHER && promoter.promoterType !== '-1' && (
                  <>
                    <div className={`col-lg-6 col-md-6 ${index === 0 && 'viewEditMode'}`}>
                      <DecimalInputField
                          labelId={'promoterMetaData.socialCapital'}
                          controlId={'promoterMetaData.socialCapital'}
                          name={'promoterMetaData.socialCapital'}
                          handleChange={(e) => handlePromoterChange(e, index, 'socialCapital')}
                          value={promoter.socialCapital}
                          placeHolder={0}
                          maxValue={100}
                          suffixLabel={'%'}
                          toolTip={<ToolTip
                              message={<FormattedMessage id={'promoterMetaData.socialCapital.tooltip'}/>}/>}
                      />
                    </div>
                    <div className={`col-lg-6 col-md-6 ${index === 0 && 'viewEditMode'}`}>
                      <DecimalInputField
                          labelId={'promoterMetaData.voteRight'}
                          controlId={'promoterMetaData.voteRight'}
                          name={'promoterMetaData.voteRight'}
                          handleChange={(e) => handlePromoterChange(e, index, 'voteRight')}
                          value={promoter.voteRight}
                          placeHolder={0}
                          maxValue={100}
                          suffixLabel={'%'}
                          toolTip={<ToolTip
                              message={<FormattedMessage id={'promoterMetaData.socialCapital.tooltip'}/>}/>}
                      />
                    </div>
                  </>
              )}

            </Row>
            <Row>
              <div className={`col-lg-12 col-md-12 my-2`}>
                <label>
                  <FormattedMessage id='candidatureForm.applyUnemploymentBenefits' />
                  <ToolTip
                      message={
                        <FormattedMessage id='candidatureForm.applyUnemploymentBenefits.toolTip' />
                      }
                  />
                </label>
                <Form.Check
                    inline
                    type={'radio'}
                    label={<FormattedMessage id='all.yes' />}
                    checked={promoter.applyUnemploymentBenefits === true}
                    onChange={() => handleApplyUnemploymentBenefitsChange(index, true)}
                    isInvalid={
                      errors.promotersMetaData
                          ? errors.promotersMetaData[index]?.applyUnemploymentBenefits
                          : false
                    }
                />
                <Form.Check
                    inline
                    type={'radio'}
                    label={<FormattedMessage id='all.no' />}
                    checked={promoter.applyUnemploymentBenefits === false}
                    onChange={() => handleApplyUnemploymentBenefitsChange(index, false)}
                    isInvalid={
                      errors.promotersMetaData
                          ? errors.promotersMetaData[index]?.applyUnemploymentBenefits
                          : false
                    }
                />
              </div>
            </Row>
          </Col>
        </Row>
      ))}

      <button
          className='mt-4 btn btn-outline-primary'
          type='button'
          onClick={() => addPromoter()}>
        <FormattedMessage id='all.plusSymbol'/>
        <FormattedMessage id='candidatureForm.addPromoter'/>
      </button>


      <div className='form-actions mt-5'>
        <Link to={'/'}>
          <button className='btn btn-link' type='button'>
            <FormattedMessage id='all.back' />
          </button>
        </Link>
        <button className='btn btn-primary' type='button' onClick={(e) => handleNextStep()}>
          <FormattedMessage id='all.nextStep' />
        </button>
      </div>
    </div>
  );
}
