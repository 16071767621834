import {CandidatureValidation} from '../../components/candidature/CandidatureValidation';
import {reject} from '../../rest/candidature';
import {useParams} from 'react-router-dom';
import {Arrays} from "../../utils/Arrays";
import {createMultiPart} from "../../components/SyncSingleUploadArea";
import {useState} from "react";

export function RejectCandidature() {
    const {externalId} = useParams();
    const [error, setError] = useState(null);

    const rejectCandidatureHandler = async (publicDocument, privateDocument) => {
        const documents = [];
        documents.push(publicDocument, privateDocument);
        try {
            if (Arrays.isNotEmpty(documents)) {
                const formData = createMultiPart(documents);
                await reject(externalId, formData);
            }

            sessionStorage.setItem('rejectCandidature', 'true')
        } catch (error) {
            console.log(error);
            setError(error);
        }
    }

    return (
        <CandidatureValidation
            validationTitle='candidatureValidation.title.reject'
            messageConfirmation='candidatureValidation.message.reject'
            callbackHandler={rejectCandidatureHandler}
            isApprove={false}
        />
    );

}
