import React from 'react';
import { Form } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

export function TextAreaInputField({
  name,
  value,
  handleChange,
  labelId,
  label,
  errorMessage,
  isInvalid,
  disabled,
  placeholder,
  toolTip,
  maxLength,
  rows
}) {
  return (
    <Form.Group controlId={name}>
      {(labelId || label) && (
        <Form.Label>
          {label && label}
          {labelId && <FormattedMessage id={labelId} />}
          {toolTip && toolTip}
        </Form.Label>
      )}
      <Form.Control
        as='textarea'
        rows={rows ? rows : 4}
        name={name}
        value={value}
        onChange={handleChange}
        isInvalid={isInvalid}
        disabled={disabled}
        placeholder={placeholder && placeholder}
        maxLength={maxLength && maxLength}
      />
      <Form.Control.Feedback type='invalid'>{errorMessage}</Form.Control.Feedback>
    </Form.Group>
  );
}
