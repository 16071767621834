import React from 'react';
import {Col, Row} from 'react-bootstrap';
import {FaSearch} from 'react-icons/fa';
import {FormattedMessage} from 'react-intl';
import {EnumSelectField} from '../bootstrap/EnumSelectField';
import {isIEFPUser} from "../../authentication/authenticationHelper";
import {EntityChangeState} from "../../models/EntityChangeState";
import {EntityChangeType} from "../../models/EntityChangeType";

export function EntityChangeFilter({filters, setFilters, search}) {
    function handleFilterChange(e, name) {
        filters[name] = e.target.value;
        setFilters({...filters});
    }

    const entyChangesStates = isIEFPUser() ? EntityChangeState.values().filter(v => v !== 'DRAFT') : EntityChangeState.values();
    return (
        <>
            <Row className='searchFilters'>
                <Col md='4'>
                    <EnumSelectField
                        controlId={'type'}
                        preffixDescriptionId={'entityChangeType'}
                        name={'type'}
                        handleChange={(e) => handleFilterChange(e, 'type')}
                        options={EntityChangeType.values()}
                        value={filters.type}
                        nullOption={true}
                        nullOptionSuffix={'entityChangeType'}
                    />
                </Col>
                <Col md='2'>
                    <EnumSelectField
                        controlId={'state'}
                        preffixDescriptionId={'entityChangeState'}
                        name={'state'}
                        handleChange={(e) => handleFilterChange(e, 'state')}
                        options={entyChangesStates}
                        value={filters.state}
                        nullOption={true}
                        nullOptionSuffix={'state'}
                    />
                </Col>
                <Col md='3'>
                    <button className='btn btn-outline-primary' onClick={search} type='button'>
                        <FaSearch/> <FormattedMessage id='all.search'/>
                    </button>
                </Col>
            </Row>
        </>
    );
}
