import React from "react";
import {FormattedMessage} from "react-intl";
import {EmptyResults} from "../general/EmptyResults";
import {Col, Row} from "react-bootstrap";
import {currencyFormat} from "../../utils/CurrencyUtils";

export function PayableProcessOverview ({payableProcessOverview: overview,payableProcessPayments: payments }) {
    return (
        <>
            <Row>
                <Col md='6'>
                    <label><FormattedMessage id={'process.type'}/></label>
                    <p><FormattedMessage id={`processTypes.${overview.processType}`} /></p>
                </Col>
                <Col md='6'>
                    <label><FormattedMessage id={'processState.APPROVED'}/></label>
                    <p><FormattedMessage id={overview.payable ? 'all.yes' : 'all.no'} /></p>
                </Col>
                <Col md='6'>
                    <label><FormattedMessage id={'candidature.payments.list.totalApproved'}/></label>
                    <p>{currencyFormat(overview.payableAmount.toFixed(2))}</p>
                </Col>

                <Col md='6'>
                    <label><FormattedMessage id={'paymentStatus.paid'}/></label>
                    <p>{currencyFormat(overview.paid.toFixed(2))}</p>
                </Col>

                <Col md='6'>
                    <label><FormattedMessage id={'paymentStatus.toPay'}/></label>
                    <p>{currencyFormat((overview.payableAmount - overview.paid).toFixed(2))}</p>
                </Col>


                {(payments!==undefined && payments.length===1) &&
                <Col md='6'>
                    <label><FormattedMessage id={'paymentStatus.date'}/></label>
                    <p>{payments[0].date}</p>
                </Col>
                }
            </Row>
        </>
    )
}