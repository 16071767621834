import React, {useEffect, useState} from 'react';
import {Row, Col, Table, Form} from 'react-bootstrap';
import {FormattedMessage, useIntl} from 'react-intl';
import {TextInputField} from '../../bootstrap/TextInputField';
import {NumberInputField} from '../../bootstrap/NumberInputField';
import {TextAreaInputField} from '../../bootstrap/TextAreaInputField';
import IntInputField from "../../bootstrap/IntInputField";

export function SemestralReportFormStep7({
                                             formStep,
                                             numberOfSteps,
                                             errors,
                                             handleChange,
                                             formikValues,
                                             handlePreviousStep,
                                             handleNextStep,
                                             entityGetList,
    readMode
                                         }) {
    const intl = useIntl();

    return (
        <div>
            <h4 className='text-primary mb-0'>
                <FormattedMessage id='semestralReportForm.step7.title'/>
            </h4>
            <small className='text-secondary text-uppercase'>
                {intl.formatMessage(
                    {id: 'all.step'},
                    {step: formStep, numberOfSteps: numberOfSteps}
                )}
            </small>
            <fieldset disabled={readMode==true}>

            <Row className='mt-4'>
                <Col>
                    <small className="text-primary text-uppercase mt-2">
                        <FormattedMessage id={"semestralReportForm.step7.isLast.title"} />
                    </small>
                </Col>
            </Row>
            <Row >
                <Col className='mt-3'>
                    <Form.Check
                        inline
                        className='d-flex align-items-center'
                        name={'last'}
                        checked={formikValues.last}
                        label={<FormattedMessage id={`semestralReportForm.step7.isLast.label`}/>}
                        onChange={handleChange}
                    />
                </Col>
            </Row>
            <Row className='mt-4'>
                <Col md={12}>
                    <small className="text-primary text-uppercase mt-2"><FormattedMessage
                        id='semestralReportForm.step7.followUp'/></small>
                </Col>
                <Col md={12}>
                    <TextAreaInputField
                        labelId={'semestralReportForm.step7.semestralEvaluation'}
                        name={
                            'followUpEvaluation'
                        }
                        handleChange={handleChange}
                        value={
                            formikValues.followUpEvaluation
                        }
                        isInvalid={
                            errors.followUpEvaluation
                                ? true
                                : false
                        }
                        errorMessage={
                            errors.followUpEvaluation
                                ? errors.followUpEvaluation
                                : ''
                        }
                    />


                </Col>

                <Col md={12}>
                    <TextAreaInputField
                        labelId={'semestralReportForm.step7.futureActivities'}
                        name={
                            'followUpFutureActions'
                        }
                        handleChange={handleChange}
                        value={
                            formikValues.followUpFutureActions
                        }
                        isInvalid={
                            errors.followUpFutureActions
                                ? true
                                : false
                        }
                        errorMessage={
                            errors.followUpFutureActions
                                ? errors.followUpFutureActions
                                : ''
                        }
                    />
                </Col>
                <Col md={4}>
                    <IntInputField
                        labelId={'semestralReportForm.step7.hoursPlanned'}
                        name={'followUpPredictionHours'}
                        handleChange={handleChange}
                        value={
                            formikValues.followUpPredictionHours
                        }
                        isInvalid={
                            errors.followUpPredictionHours
                                ? true
                                : false
                        }
                        errorMessage={
                            errors.followUpPredictionHours
                                ? errors.followUpPredictionHours
                                : ''
                        }
                    />

                </Col>

                    <small className='text-primary text-uppercase mt-5'>
                        <FormattedMessage id='semestralReportForm.step7.consultingAndSkillExtension'/>
                    </small>

                <Col md={12}>
                    <TextAreaInputField
                        labelId={'semestralReportForm.step7.semestralEvaluation'}
                        name={
                            'consultancyEvaluation'
                        }
                        handleChange={handleChange}
                        value={
                            formikValues.consultancyEvaluation
                        }
                        isInvalid={
                            errors.consultancyEvaluation
                                ? true
                                : false
                        }
                        errorMessage={
                            errors.consultancyEvaluation
                                ? errors.consultancyEvaluation
                                : ''
                        }
                    />

                </Col>
                <Col md={12}>
                    <TextAreaInputField
                        labelId={'semestralReportForm.step7.futureActivities'}
                        name={
                            'consultancyFutureActions'
                        }
                        handleChange={handleChange}
                        value={
                            formikValues.consultancyFutureActions
                        }
                        isInvalid={
                            errors.consultancyFutureActions
                                ? true
                                : false
                        }
                        errorMessage={
                            errors.consultancyFutureActions
                                ? errors.consultancyFutureActions
                                : ''
                        }
                    />

                </Col>


                <Col md={4}>

                    <IntInputField
                        labelId={'semestralReportForm.step7.hoursPlanned'}
                        name={
                            'consultancyPredictionHours'
                        }
                        handleChange={handleChange}
                        value={
                            formikValues.consultancyPredictionHours
                        }
                        isInvalid={
                            errors.consultancyPredictionHours
                                ? true
                                : false
                        }
                        errorMessage={
                            errors.consultancyPredictionHours
                                ? errors.consultancyPredictionHours
                                : ''
                        }
                    />

                </Col>
            </Row>
            <Row>
                <Col>
                    <TextInputField
                        labelId='semestralReportForm.step7.technicalTeamManager'
                        value={formikValues.identificationEa
                            .technicalTeamManagerExternalId !== '-1' ?
                            entityGetList.filter(
                                (get) =>
                                    get.externalId ===
                                    formikValues.identificationEa
                                        .technicalTeamManagerExternalId
                            )?.[0]?.name : ''
                        }
                        disabled
                    />
                </Col>
            </Row>
            </fieldset>
            <div className='form-actions mt-5'>
                <button
                    className='btn btn-link'
                    type='button'
                    onClick={handlePreviousStep}
                >
                    <FormattedMessage id='all.back'/>
                </button>

                <button
                    className='btn btn-primary'
                    type='button'
                    onClick={handleNextStep}
                >
                    <FormattedMessage id='all.nextStep'/>
                </button>
            </div>
        </div>
    );
}
