import React, { useEffect, useRef, useState } from 'react';
import { Col, Container, Form, Nav, Row } from 'react-bootstrap';
import {
	FaExclamationCircle,
	FaRegCheckCircle,
	FaRegSave,
	FaRegTimesCircle,
} from 'react-icons/fa';
import { FormattedMessage, useIntl } from 'react-intl';
import Loading from '../../components/general/Loading';
import { SubTemplate } from '../../components/general/SubTemplate';
import { handleError, isNotBusinessError } from '../../utils/handleError';
import { FinancialModalityStep } from '../../components/promoter/FinancialModalityStep';
import { PromoterIdentification } from '../../components/promoter/PromoterIdentification';
import { ProjectDescriptionStep } from '../../components/project/ProjectDescriptionStep';
import { PriorSupportApplicationStep } from '../../components/project/PriorSupportApplicationStep';
import { Formik, yupToFormErrors } from 'formik';
import {
	SemestralReportFormStep1,
	SemestralReportFormStep2,
	SemestralReportFormStep3,
} from '../../components/semestralReport';
import {Navigate, useLocation, useNavigate, useParams} from 'react-router-dom';
import { getEntityGetList } from '../../rest/entity';
import { getHumanResourceByExternalId } from '../../rest/humanResource';
import { getDefaultProjectIdentification, getProjectIdentificationProcessExternalId, saveProjectIdentification, validateProjectIdentification } from '../../rest/projectIdentification';
import { FinancialModalityFormUtils } from '../../utils/FinancialModalityFormUtils';
import { CompanyIdentificationFormUtils } from '../../utils/CompanyIdentificationFormUtils';
import { ProcessState } from '../../models/ProcessState';
import * as yup from 'yup';
import { Validator } from '../../utils/Validator';
import { ConfirmationAuthenticationModal } from '../../components/general/ConfirmationAuthenticationModal';
import { createCustomErrorMessage } from '../../hooks/errorMessage';
import { SuccessToast } from '../../components/general/SuccessToast';
import { AlertError } from '../../components/bootstrap/AlertError';
import { FormSideNavBar } from '../../components/general/FormSideNavBar';
import { useGlobalContext } from '../../context';
import { isEntityUser, isPromoterUser } from '../../authentication/authenticationHelper';
import {isValidCae} from "../../rest/candidature";
import {AlertSuccess} from "../../components/bootstrap/AlertSuccess";


/*
  Formulario B6 - Ficha de Identificação do Projeto
*/
const validationSchema = yup.object().shape({
	identificationEa: yup.object().shape({
		technicalTeamManagerExternalId: yup
			.string()
			.test(
				'isGETValid',
				<FormattedMessage id='errors.annualComplementaryActivitiesPlan.GET' />,
				(value) => value !== '-1'
			),
	}),

	company: CompanyIdentificationFormUtils.validationObject(true),

	financialModality: FinancialModalityFormUtils.validationObject(),

	project: yup.object().shape({
		activity: yup
			.string()
			.required(<FormattedMessage id='errors.fieldRequiredText' />),
		cae:yup.mixed()
			.test(
				'isValidCae',
				<FormattedMessage id='errors.candidatureForm.caeNeedDigits' />,
				(value) => new RegExp(/^\d{5}$/).test(value) && typeof value !== 'undefined'
			).test(
				'isCaeOnDb',
				<FormattedMessage id='errors.candidatureForm.caeNotOnDB' />,
				async (value) => {
					const { data: valid } = await isValidCae(value);
					return valid.flag;
				}
			),
		investmentvalue: yup.number().min(0.01,<FormattedMessage id='errors.notPositive' />),
		ownCapitals : yup.number().min(0.01,<FormattedMessage id='errors.notPositive' />),
		county: yup
			.string()
			.required(<FormattedMessage id='errors.fieldRequiredText' />),
		parish: yup
			.string()
			.required(<FormattedMessage id='errors.fieldRequiredText' />),
		postalCode4:yup.mixed()
			.test(
				'isValidPostalCode4',
				<FormattedMessage id='errors.candidatureForm.postalCode4' />,
				(value) => new RegExp(/^\d{4}$/).test(value) && typeof value !== 'undefined'
			),
		postalCode3:yup.mixed()
			.test(
				'isValidPostalCode3',
				<FormattedMessage id='errors.candidatureForm.postalCode3' />,
				(value) => new RegExp(/^\d{3}$/).test(value) && typeof value !== 'undefined'
			),
		fullTimeRecipientPromoters: yup
		.number()
		.required(<FormattedMessage id='errors.fieldRequiredText' />),
		partTimeRecipientPromoters: yup
		.number()
		.required(<FormattedMessage id='errors.fieldRequiredText' />),
		fullTimeOtherPromoters: yup
		.number()
		.required(<FormattedMessage id='errors.fieldRequiredText' />),
		partTimeOtherPromoters: yup
		.number()
		.required(<FormattedMessage id='errors.fieldRequiredText' />)

	}),
	activityDescription: yup
		.string()
		.required(<FormattedMessage id='errors.fieldRequiredText' />),

	contractEffectiveDate: yup
		.date()
		.required(<FormattedMessage id='errors.fieldRequiredText' />).
		max(new Date(),<FormattedMessage id='error.identification.dateBeforeNow' />)
		.transform((v) => (v instanceof Date && !isNaN(v) ? v : undefined)),

})

export function AddProjectIdentification() {
	const intl = useIntl();
	const isEntity = isEntityUser();
	const isPromoter = isPromoterUser();

	const { priorSupportExternalId: priorSupportExternalId } = useParams();
	const { processExternalId: processExternalId } = useParams();
	const { accessInfo, setAccessInfo } = useGlobalContext();
	const location = useLocation();
	const navigate = useNavigate();

	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);
	const [navErrors, setNavErrors] = useState([]);
	const [evaluationForm, setEvaluationForm] = useState(null);
	const [projectIdentification, setProjectIdentification] = useState(null);
	const [formikInitialValues, setFormikInitialValues] = useState(null);
	const [formStep, setFormStep] = useState(0);

	const [getList, setGetList] = useState([]);

	const formikPropsRef = useRef(null);

	const [submited, setSubmited] = useState(false);

	const [showSaveToast, setShowSaveToast] = useState(false);
	const [showWithdrawModal, setShowWithdrawModal] = useState(false);
	const [showSubmitModal, setShowSubmitModal] = useState(false);
	const [lsItem, setLsItem] = useState(null);

	const [tabsIds, setTabsIds] = useState( [
		'projectIdentification.eaidentification',
		'projectIdentification.promotersidentification',
		'projectIdentification.companyIdentification',
		'projectIdentification.projectdescription',
		'eaevaluationform.sideMenu.modalityidentification',	
		'projectIdentification.priorsupportapplication',
		'projectIdentification.projectapproval',
	]);


	async function fetchData() {
		if(isEntity){
			try {
				let queryparams = {
					priorSupportExternalId: priorSupportExternalId,
				};
				let [
					{ data: projectIdentification },
				] = await Promise.all([
					await getDefaultProjectIdentification(queryparams),
				])
				setFormikInitialValues(getFormikInitialValues(projectIdentification));

				let [
					{ data: entityGetList },
				] = await Promise.all([
					await getEntityGetList({all:projectIdentification.processState !== ProcessState.DRAFT}),
				])
				setGetList(entityGetList);
			} catch (error) {
				setError(error);
			} finally {
				setLoading(false);
			}




		}
		else{
			try {
				let [
					{ data: projectIdentification },
				] = await Promise.all([
					await getProjectIdentificationProcessExternalId(processExternalId),
				])
				setFormikInitialValues(getFormikInitialValues(projectIdentification));
				let [
					{ data: entityGetList },
				] = await Promise.all([
					await getHumanResourceByExternalId(projectIdentification.identificationEa.technicalTeamManagerExternalId),
				])
				setGetList([entityGetList])
			} catch (error) {
				setError(error);
			} finally {
				setLoading(false);
			}
		}
		setShowSubmitModal(false);
    };

	function handleNavClick(value) {
		setNavErrors(navErrors.filter((navError) => navError !== value));
		setFormStep(value);
		setEvaluationForm({ ...evaluationForm });
	}

	function handleNavError(error) {
		let errors = error.response.data.message
			.split(',')
			.map((value) => Number.parseInt(value));
		setNavErrors(errors);
	}

	function getErrorInformation(value) {
		if (navErrors.includes(value)) {
			return (
				<span>
					<FaExclamationCircle />
				</span>
			);
		}
	}

	function getFormikInitialValues(projectIdentification) {
		let initialValues = {
			company: {},
			financialModality: {},
			projectApproval: {},
		};

		initialValues.submissionDate= projectIdentification.submissionDate
		initialValues.decisionDispatchDate= projectIdentification.decisionDispatchDate

		initialValues.needValidation= projectIdentification.needValidation;
		initialValues.externalId = projectIdentification.externalId;
		initialValues.identificationEa = projectIdentification.identificationEa;
		initialValues.promotersMetaData = projectIdentification.promotersMetaData;

		initialValues.project = projectIdentification.project
		initialValues.project.fullTimeRecipientPromoters = projectIdentification.project.fullTimeRecipientPromoters
					? projectIdentification.project.fullTimeRecipientPromoters : 0;
		initialValues.project.partTimeRecipientPromoters = projectIdentification.project.partTimeRecipientPromoters
					? projectIdentification.project.partTimeRecipientPromoters : 0;
		initialValues.project.fullTimeOtherPromoters = projectIdentification.project.fullTimeOtherPromoters
					? projectIdentification.project.fullTimeOtherPromoters : 0;
		initialValues.project.partTimeOtherPromoters = projectIdentification.project.partTimeOtherPromoters
					? projectIdentification.project.partTimeOtherPromoters : 0;


		initialValues.company.name = projectIdentification.company?.name !== undefined
					? projectIdentification.company.name : '';
		initialValues.company.nipc = projectIdentification.company?.nipc !== undefined
					? projectIdentification.company.nipc : '';
		initialValues.company.address = projectIdentification.company?.address !== undefined
					? projectIdentification.company.address : '';
		initialValues.company.email = projectIdentification.company?.email !== undefined
					? projectIdentification.company.email : '';
		initialValues.company.phone = projectIdentification.company?.phone !== undefined
					? projectIdentification.company.phone : '';
		initialValues.company.personInChargeName = projectIdentification.company?.personInChargeName !== undefined
					? projectIdentification.company.personInChargeName : '';
		initialValues.company.postalCode3Digits = projectIdentification.company?.postalCode3Digits !== undefined
					? projectIdentification.company.postalCode3Digits : '';
		initialValues.company.postalCode4Digits = projectIdentification.company?.postalCode4Digits !== undefined
					? projectIdentification.company.postalCode4Digits : '';
					

		initialValues.financialModality.unemploymentAntecipation = projectIdentification.financialModality?.unemploymentAntecipation !== undefined
					? projectIdentification.financialModality.unemploymentAntecipation : false;
		initialValues.financialModality.interestFreeLoan = projectIdentification.financialModality?.interestFreeLoan !== undefined
					? projectIdentification.financialModality.interestFreeLoan : false;
		initialValues.financialModality.nonRefundableSubsidy = projectIdentification.financialModality?.nonRefundableSubsidy !== undefined
					? projectIdentification.financialModality.nonRefundableSubsidy : false;
		initialValues.financialModality.financialEmployment = projectIdentification.financialModality?.financialEmployment !== undefined
					? projectIdentification.financialModality.financialEmployment : false;
		initialValues.financialModality.classificationAxis = projectIdentification.financialModality?.classificationAxis !== undefined
					? projectIdentification.financialModality.classificationAxis : false;
		initialValues.financialModality.mais = projectIdentification.financialModality?.mais !== undefined
					? projectIdentification.financialModality.mais : false;
		initialValues.financialModality.emigrantSupport = projectIdentification.financialModality?.emigrantSupport !== undefined
					? projectIdentification.financialModality.emigrantSupport : false;
		initialValues.financialModality.contractSupport = projectIdentification.financialModality?.contractSupport !== undefined
					? projectIdentification.financialModality.contractSupport : false;
		initialValues.financialModality.others = projectIdentification.financialModality?.others !== undefined
					? projectIdentification.financialModality.others : '';

		initialValues.projectApproval.projectApprovalDate = projectIdentification.projectApproval.projectApprovalDate
					? projectIdentification.projectApproval.projectApprovalDate : null;
		initialValues.projectApproval.candidatureProcessNumber = projectIdentification.projectApproval.candidatureProcessNumber
					? projectIdentification.projectApproval.candidatureProcessNumber : '';

		initialValues.activityDescription = projectIdentification.activityDescription
					? projectIdentification.activityDescription : '';
		initialValues.contractEffectiveDate = projectIdentification.contractEffectiveDate
					? projectIdentification.contractEffectiveDate : null;

		initialValues.processState = projectIdentification.processState

		return initialValues;
	}


	function scrollPageToTop() {
		document.body.scrollTop = 0;
		document.documentElement.scrollTop = 0;
	}

	function handlePreviousStep() {
		scrollPageToTop();
		setFormStep(formStep - 1);
	}

	function handleNextStep() {
		scrollPageToTop();
		setFormStep(formStep + 1);
	}

    useEffect(() => {
        fetchData();
    }, [submited]);

	if (loading) {
		return <Loading />;
	}

	if (error && isNotBusinessError(error)) {
		console.log(error)
		return handleError(error);
	}





	if (submited) {
		sessionStorage.setItem('projectIdentificationSubmited', submited);
		return navigate('/pedidos/' + priorSupportExternalId, {
			state: {refresh: true}
		});

	}



	async function handleSave() {
		try {
			let { data: res } = await saveProjectIdentification(formikPropsRef.current.values);
			
			let processedRes = getFormikInitialValues(res);

			formikPropsRef.current.setValues(processedRes);
			setNavErrors([]);
			formikPropsRef.current.setErrors({});

			setShowSaveToast(true);
		} catch (error) {
			setError(error);
		}

	}


	async function formValidationHandler(setShowModal) {
		setError(null);
		let errors = await validateForm();

		setNavErrors(Array.from(errors));
		if (errors.size === 0) {
			setShowModal(true);
		}
	}

	if (error && isNotBusinessError(error)) {
		return handleError(error);
	}

	async function validateForm() {
		let errors = new Set();
		let values= formikPropsRef.current.values;
		let errorMessages = [];
		if(!FinancialModalityFormUtils.validateAtLeastOneOptionFromEmpreendeXXIMeasureIsSelected(values, intl, errorMessages)){
			errors.add(4);
		}

		console.log(errorMessages)


		const formikErrors = await formikPropsRef.current.validateForm();

		Object.keys(formikErrors).forEach((key) => {
			switch (key) {
				case 'identificationEa':
					errors.add(0);
					break;
				case 'company':
					errors.add(2);
					break;
				case 'project':
					errors.add(3);
					break;
				case 'financialModality':
					if(!errors.has('4')){
						errors.add(4);
					}
					break;
				case 'activityDescription': 
					errors.add(5);
					break;
				case 'contractEffectiveDate': 
					errors.add(5);
					break;
				default:
					errors.add(6);
					break;
			}
		});

		console.log(errors)

		if (errorMessages.length > 0) {
			let messageFinal =
				errorMessages.length === 1
					? createCustomErrorMessage(errorMessages[0])
					: createCustomErrorMessage(
							'<p>' +
								intl.formatMessage({
									id: 'errors.more',
								}) +
								'</p><p> - ' +
								errorMessages.join('</p><p> - ') +
								'</p>'
					  );



			setError(messageFinal);
		}

		return errors;
	}

	async function handleSubmit(user, password, setModalError) {
		const submissionValues = { ...formikPropsRef.current.values };

		submissionValues.authenticationCredentials = {
			user: user,
			password: password,
		};

		let queryParams = {
			submit: true,
		};

		try {
			await saveProjectIdentification(submissionValues, queryParams);
			setSubmited(true);
		} catch (err) {
			if (err.response.data.exception === 'ValidationException') {
				handleNavError(err);
			} else {
				setModalError(err);
			}
		}
	}

	async function handleValidation(validation) {
		let queryParams = {
			validation: validation,
		};
		console.log(validation)
		try {
			await validateProjectIdentification(processExternalId, queryParams);
			if(validation){
				setLsItem('projectIdentificationValidated');
				formikPropsRef.current.values.needValidation=false;
			}else{
				setLsItem('projectIdentificationInValidated');
				formikPropsRef.current.values.needValidation=false;
			}
		} catch (err) {
			setError(err);
		}
	}

	return (
		<SubTemplate>
			<Container>
				<ConfirmationAuthenticationModal
					show={showSubmitModal}
					handleClose={() => setShowSubmitModal(false)}
					submitHandler={handleSubmit}
					idsPreffix='eaEvaluation.submitModal'
					confirmButtonCompleteLabelId='all.submitButtonText'
				/>
				{lsItem && (
					<Row>
						<Col md={{ span: 8, offset: 2 }}>
							<div className='mx-2 mb-4'>
								<AlertSuccess lsItem={lsItem} />
							</div>
						</Col>
					</Row>
				)}
				<Row>
					<Col md={{ span: 8, offset: 2 }}>
						<h2 className='mb-1 text-secondary'>
							<FormattedMessage id='projectIdentification.title' />
						</h2>
						<p className='mb-5 text-secondary'>
							<FormattedMessage id='projectIdentification.subTitle' /> <span
							className="badge rounded-pill bg-secondary fw-normal ms-2"><span
							className="text-uppercase fw-bold"><FormattedMessage
							id={`processState.${formikInitialValues.processState}`}
						/> </span>  {formikInitialValues.submissionDate !== undefined && formikInitialValues.submissionDate !== null && (<> · <FormattedMessage id={'submitted.process.at'} values={{date:formikInitialValues.submissionDate}}/>  </>) } {formikInitialValues.decisionDispatchDate !== undefined && formikInitialValues.decisionDispatchDate !== null && formikInitialValues.decisionDispatchDate !== null && (<> · {ProcessState.APPROVED===formikInitialValues.processState ? <FormattedMessage id={'approvedPromoter.process.at'} values={{date:formikInitialValues.decisionDispatchDate}}/>:<FormattedMessage id={'rejectedPromoter.process.at'} values={{date:formikInitialValues.decisionDispatchDate}}/>}   </>) }  </span>
						</p>
					</Col>
				</Row>
				<Row className='mb-5'>
					<Col md='2'>
						<FormSideNavBar
							tabsIdsArray={tabsIds}
							formStep={formStep}
							setFormStep={setFormStep}
							navErrors={navErrors}
							setNavErrors={setNavErrors}
						/>
					</Col>
					<Col md='8'>
					<AlertError error={error} />
						<SuccessToast
							message={intl.formatMessage({
								id: 'annualComplementaryActivitiesPlan.saveToastInformation',
							})}
							show={showSaveToast}
							setShow={setShowSaveToast}
						/>
						<Formik
							initialValues={formikInitialValues}
							validateOnBlur={false}
							validateOnChange={false}
							validationSchema={validationSchema}
							onSubmit={handleSubmit}
						>
							{(formikProps) => (
								<Form onSubmit={formikProps.handleSubmit}>
									{renderStep(formikProps)}
								</Form>
							)}
						</Formik>
					</Col>
					{formikInitialValues.processState === ProcessState.DRAFT && isEntity && (
						<Col md='2'>
							<div>
								<button
									className='btn btn-outline-primary d-flex align-items-center justify-content-center mb-4 w-100'
									type='button'
									onClick={() => handleSave()}
								>
									<FaRegSave />
									<FormattedMessage id='all.save' />
								</button>
								<button
									className='btn btn-primary d-flex align-items-center justify-content-center w-100'
									type='button'
									onClick={() => formValidationHandler(setShowSubmitModal)}
								>
									<FaRegCheckCircle />
									<FormattedMessage id='all.submitButtonText' />
								</button>
							</div>
						</Col>
					)}
					{formikInitialValues.processState === ProcessState.SUBMITTED && isPromoter &&  formikInitialValues.needValidation &&(
						<Col md='2'>
							<div>
								<button
									className='btn btn-outline-primary d-flex align-items-center justify-content-center mb-4 w-100'
									type='button'
									onClick={() => handleValidation(true)}
								>
									<FaRegCheckCircle />
									<FormattedMessage id='all.button.approve' />
								</button>
								<button
									className='btn btn-primary d-flex align-items-center justify-content-center w-100'
									type='button'
									onClick={() => handleValidation(false)}
								>
									<FaRegTimesCircle />
									<FormattedMessage id='all.button.reject' />
								</button>
							</div>
						</Col>
					)}
				</Row>
			</Container>
		</SubTemplate>
	);

	function renderStep(formikProps) {
		formikPropsRef.current = formikProps;
		const readMode = formikProps.values.processState !== ProcessState.DRAFT;

		switch (formStep) {
			case 0:
				return (
					<SemestralReportFormStep1
						formStep={formStep + 1}
						numberOfSteps={tabsIds.length}
						errors={formikProps.errors}
						formikValues={formikProps.values}
						handleChange={formikProps.handleChange}
						handleNextStep={handleNextStep}
						entityGetList={getList}
						readMode={readMode}
						goBackPath={
							'/'
						}
					/>
				);
			case 1:
				return (
					<PromoterIdentification
						step={formStep + 1}
						numberOfSteps={tabsIds.length}
						handleGoBack={handlePreviousStep}
						errors={formikProps.errors}
						formikValues={formikProps.values}
						handleChange={formikProps.handleChange}
						handleNextStep={handleNextStep}
					/>
				);
			case 2:
				return (
					<SemestralReportFormStep2
						formStep={formStep + 1}
						numberOfSteps={tabsIds.length}
						handleGoBack={handlePreviousStep}
						errors={formikProps.errors}
						formikValues={formikProps.values}
						handleChange={formikProps.handleChange}
						handleNextStep={handleNextStep}
						readMode={readMode}
				/>
				);
			case 3:
				return (
					<ProjectDescriptionStep
            			step={formStep + 1}
						numberOfSteps={tabsIds.length}
						handleGoBack={handlePreviousStep}
						handleNextStep={handleNextStep}
						errors={formikProps.errors}
						formikValues={formikProps.values}
						handleChange={formikProps.handleChange}
						readMode={readMode}
					/>
				);
			case 4:
				return (
					<FinancialModalityStep
						step={formStep + 1}
						numberOfSteps={tabsIds.length}
						handleGoBack={handlePreviousStep}
						errors={formikProps.errors}
						formikValues={formikProps.values}
						handleChange={formikProps.handleChange}
						handleNextStep={handleNextStep}
						readMode={readMode}
					/>
				);
			case 5:
				return (
					<PriorSupportApplicationStep
						step={formStep + 1}
						numberOfSteps={tabsIds.length}
						handleGoBack={handlePreviousStep}
						handleNextStep={handleNextStep}
						errors={formikProps.errors}
						formikValues={formikProps.values}
						handleChange={formikProps.handleChange}
						setFieldValue={formikProps.setFieldValue}
						readMode={readMode}
					/>
				);
			case 6:
				return (
					<SemestralReportFormStep3
						formStep={formStep + 1}
						numberOfSteps={tabsIds.length}
						handleGoBack={handlePreviousStep}
						errors={formikProps.errors}
						formikValues={formikProps.values}
						handleChange={formikProps.handleChange}
						setFieldValue={formikProps.setFieldValue}
						readMode={readMode}
						hideNextStep={true}
					/>
				);

			default:
				console.log('Something went wrong rendering the form step');
		}
	}
}
