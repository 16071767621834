import { apiAxios as authAxios } from '../axios.config';

export const getPriorSupportByExternalId = (externalId) => {
	return authAxios.get(`/api/priorSupport/${externalId}`);
};


export const getPriorSupportActors = (priorSupportExternalId) => {
	return authAxios.get(`/api/priorSupport/${priorSupportExternalId}/actors`)
};

export const listDocumentsByTypeAndPriorSupport = (documentType, priorSupportExternalId) => {
	return authAxios.get(`/api/priorSupport/${priorSupportExternalId}/documents/type/${documentType}`)
};

export const getPriorSupportDocuments = (externalId) => {
	return authAxios.get(`/api/priorSupport/${externalId}/documents`);
};

export const acceptPriorSupportRequest = (externalId) => {
	return authAxios.post(`/api/priorSupport/${externalId}/acceptPriorSupport`);
};

export const rejectPriorSupportRequest = (externalId, reason) => {
	return authAxios.post(
		`/api/priorSupport/${externalId}/rejectPriorSupport`,
		reason
	);
};

export const sendReviewPriorSupport = (externalId, reason) => {
	return authAxios.post(
		`/api/priorSupport/${externalId}/sendReviewPriorSupport`,
		reason
	);
};

export const sendMessageToPromoter = (externalId, message) => {
	return authAxios.post(
		`/api/priorSupport/${externalId}/sendMessageToPromoter`,
		message
	);
};

export const allowManageB6 = (externalId) => {
	return authAxios.get(`/api/priorSupport/${externalId}/allowManageB6`);
};

export const getPriorSupportMessages = (externalId, index, size) => {
	return authAxios.get(`/api/priorSupport/${externalId}/messages`, {
		params: {
			index: index,
			size: size,
		},
	});
};

export const getInProgressByCandidature = (externalId) => {
	return authAxios.get(`/api/priorSupport/candidature/${externalId}`);
};

export const getAcceptedPriorSupportByCandidature = (externalId) => {
	return authAxios.get(`/api/priorSupport/candidature/${externalId}/history`);
};

export const listEntitySupportedApprovedCandidaturesProcessNumbers = (
	queryParams
) => {
	return authAxios.get(
		`/api/priorSupport/candidature/processNumber`,
		queryParams ? { params: queryParams } : null
	);
};

export const getEntitySupportedApprovedCandidatureProjectInfo = (
	candidatureProcessNumber,
	processExternalId
) => {
	return authAxios.get(`/api/priorSupport/candidature/projectIdentification`, {
		params: {
			candidatureProcessNumber: candidatureProcessNumber,
			processExternalId: (processExternalId ??= null),
		},
	});
};

export const getIasValueAtContractCelebration = (queryParams) => {
	return authAxios.get(`/api/priorSupport/candidature/ias`, {
		params: queryParams,
	});
};
