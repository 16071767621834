import React, { useEffect, useState } from 'react';
import { Accordion, Col, Form, Row } from 'react-bootstrap';
import { FaCheckCircle } from 'react-icons/fa';
import { FormattedMessage } from 'react-intl';
import { PromoterType } from '../../models/PromoterType';
import { TranslateBoolean } from '../../utils/TranslateBoolean';
import { maxFixed } from '../../utils/CurrencyUtils';
import { AuthenticatedDownloadLink } from '../document/AuthenticatedDownloadLink';
import { DocumentType } from '../../models/DocumentType';
import { EmptyResults } from '../general/EmptyResults';

export function ContestationTab({
	candidatureChange,
	candidatureChangeDocuments,
}) {
	const contestationDocuments = candidatureChangeDocuments.filter(
		(d) => d.documentType === DocumentType.CANDIDATURE_CHANGE_CONTESTATION_ANNEX
	);
	return candidatureChange.contestationMotive === undefined ? (
		<EmptyResults />
	) : (
		<>
			<div className='border-bottom py-3 mb-4'>
				<Row>
					<Col>
						<label>
							<FormattedMessage id='candidature.contestationAnalysis.motive' />
						</label>
						<p>{candidatureChange.contestationMotive}</p>
					</Col>
				</Row>
			</div>
			<div>
				<Row>
					<small className='text-secondary text-uppercase'>
						<FormattedMessage id='candidatureTabs.documentation' />
					</small>
				</Row>
				{contestationDocuments.length === 0 ? (
					<EmptyResults />
				) : (
					contestationDocuments.map((d, index) => (
						<Row key={index} className='mt-4'>
							<Col>
								<label className='mb-0'>{d.fileDescription}</label>
								<div>
									<AuthenticatedDownloadLink
										url={d.documentPath}
										filename={d.name}
									>
										<small>{d.name}</small>
									</AuthenticatedDownloadLink>
								</div>
							</Col>
						</Row>
					))
				)}
			</div>
		</>
	);
}
