import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { NumericFormat } from 'react-number-format';
import { maxFixed } from '../../utils/CurrencyUtils';
import CurrencyInput from 'react-currency-input-field';

export function IntInputFormattedField({
	controlId,
	labelId,
	label,
	name,
	handleChange,
	value,
	errorMessage,
	isInvalid,
	disabled,
	maxSize,
  maxValue,
	toolTip,
	className,
	placeholder,
	placeholderId,
	suffixLabel,
  allowNegative
}) {
	const intl = useIntl();

	const onValueChange = (value, name) => {
		if (typeof value === 'undefined' || value === '') {
			value = 0;
		} else if (allowNegative === true && value === '-0') {
			value = '-';
		} else if (value.length > 1 && value.startsWith('0')) {
			value = value.substring(1, value.length);
		}

		if (value.length > maxSize) {
			value = value.substring(0, maxSize);
		}

		if (maxValue && maxValue < value) {
			value = maxValue;
		}

		handleChange({ target: { value: value, name: name } });
	};

	const handleKeyDown = (e) => {
		if (['k', 'b', 'm', 'K', 'B', 'M', '.'].includes(e.key)) {
			e.preventDefault();
		}
	};

	return (
		<Form.Group controlId={controlId}>
			{(label || labelId) && (
				<Form.Label>
					{labelId && <FormattedMessage id={labelId} />}
					{label && label}
					{toolTip && toolTip}
				</Form.Label>
			)}
			<CurrencyInput
				allowDecimals={false}
				className={className}
				defaultValue={0}
				allowNegativeValue={allowNegative === true}
				name={name}
				value={value}
				isInvalid={isInvalid}
				disabled={disabled}
				placeholder={
					placeholder
						? placeholder
						: placeholderId
						? intl.formatMessage({ id: placeholderId })
						: ''
				}
				customInput={Form.Control}
				onValueChange={onValueChange}
				groupSeparator={' '}
				decimalSeparator={'.'}
				onKeyDown={handleKeyDown}
				suffix={suffixLabel}
			/>
			{errorMessage && (
				<Form.Control.Feedback type='invalid'>
					{errorMessage}
				</Form.Control.Feedback>
			)}
		</Form.Group>
	);
}

export default IntInputFormattedField;
