import React, { useEffect } from 'react';
import {Navigate, useNavigate} from 'react-router-dom';
import {
  getAccessInfo,
  hasPermission,
  isTokenExpired,
  logout,
  refreshToken,
} from './authentication/authenticationHelper';
import Loading from './components/general/Loading';
import { ConfigObject } from './config';
import { useGlobalContext } from './context';

export function PrivateRoute({ forceRedirect,children, permissions, ...rest }) {
  const { accessInfo, setAccessInfo } = useGlobalContext();
  const navigate = useNavigate();

  const hasPermissionsToAccess = (permissions) => {
    if (!permissions) {
      return false;
    }

    if (accessInfo) {
      const userPermissions = accessInfo.permissions;
      if (userPermissions) {
        return permissions.some((p) => {
          return hasPermission(p, accessInfo);
        });
      }
    }
    return false;
  };

  useEffect(() => {
    if(!accessInfo && forceRedirect){
      navigate('/login');
    }
    if (isTokenExpired()) {
      refreshToken()
        .then(() => {
          setAccessInfo(getAccessInfo());
        })
        .catch(() => {
          logout();
          window.location.replace(ConfigObject.get().baseUrl);
        });
    }
  }, [children]);

  if (isTokenExpired()) {
    return <Loading />;
  }

  return (
    <>{ (!accessInfo && forceRedirect) ?  <Navigate to='/login' />  : hasPermissionsToAccess(permissions) ? children : <Navigate to='/' />}</>
  );
}
