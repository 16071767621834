import {AlertError} from "../../../bootstrap/AlertError";
import {Formik} from "formik";
import {Col, Container, Form, Row} from "react-bootstrap";
import {DateTimeInputField} from "../../../bootstrap/DateTimeInputField";
import DecimalInputField from "../../../bootstrap/DecimalInputField";
import {FormattedMessage} from "react-intl";
import React, {useState} from "react";
import {useNavigate} from "react-router-dom";
import * as yup from "yup";
import {savePayment, savePaymentWithDocument} from "../../../../rest/candidaturePayments";
import {addIrregularity, addIrregularityWithDocument} from "../../../../rest/irregularities";
import {SingleUploadArea} from "../../../SingleUploadArea";
import {IrregularityState} from "./IrregularityState";


export function IrregularityForm ({candidatureExternalId, irregularityCauseToRegulationMap, onCancel, onSubmitCallback}) {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [navErrors, setNavErrors] = useState([]);
    const navigate = useNavigate();
    const [documents, setDocuments] = useState([]);

    async function onSubmit(values, setSubmitting, setErrors) {
        setSubmitting(true);
        try {
            if (values.file === null) {
                await addIrregularity(candidatureExternalId, values)
            } else {
                let {file: formData, ...rest} = values;
                formData.set('irregularity', JSON.stringify(rest));
                console.log(formData)
                await addIrregularityWithDocument(candidatureExternalId, formData);
            }
            onSubmitCallback?.()
            setSubmitted(true);
        } catch (error) {
            console.log(error);
            setErrors(error);
        }
        setSubmitting(false);
    }

    const validationSchema = yup.object().shape({
        deadline: yup
            .date()
            .typeError(<FormattedMessage id='errors.fieldRequiredText'/>)
            .min(new Date(), <FormattedMessage id='candidatureTabs.oversight.tabs.irregularities.deadline.past'/>)
            .required(<FormattedMessage id='errors.fieldRequiredText'/>),
        leadsToAidSuspension: yup
            .boolean()
            .typeError(<FormattedMessage id='errors.fieldRequiredText'/>)
            .required(<FormattedMessage id='errors.fieldRequiredText'/>),
        causes: yup
            .array()
            .of(yup.string())
            .min(1, <FormattedMessage id='candidatureTabs.oversight.tabs.irregularities.causes.error' />)
            .required(<FormattedMessage id='errors.fieldRequiredText'/>),

    });

    return (
        <>
            <AlertError error={error} />
            <Formik
                initialValues={{deadline: null, leadsToAidSuspension: null, state: IrregularityState.NOT_FIXED, causes: [], file: null}}
                validationSchema={validationSchema}
                validateOnBlur={false}
                validateOnChange={false}
                onSubmit={(values, { setSubmitting, setErrors }) => {
                    onSubmit(values, setSubmitting, setErrors);
                }}>
                {({ values,
                      errors,
                      touched,
                      handleChange,
                      handleSubmit,
                      setFieldValue
                  }) => (
                    <Form onSubmit={handleSubmit}>
                        <AlertError error={errors} />
                            <Row>
                                <Col>
                                        <label>
                                            <FormattedMessage id='candidatureTabs.oversight.tabs.irregularities.leadsToAidSuspension' />
                                        </label>
                                        <Form.Check
                                            inline
                                            name={'leadsToAidSuspension'}
                                            type={'radio'}
                                            label={<FormattedMessage id='all.yes' />}
                                            checked={values.leadsToAidSuspension === true}
                                            onChange={ ({target}) => setFieldValue('leadsToAidSuspension', true)}
                                        />
                                        <Form.Check
                                            inline
                                            name={'leadsToAidSuspension'}
                                            type={'radio'}
                                            label={<FormattedMessage id='all.no' />}
                                            checked={values.leadsToAidSuspension === false}
                                            onChange={ ({target}) => setFieldValue('leadsToAidSuspension', false)}
                                        />
                                        <Form.Control.Feedback type='invalid'>
                                            {errors.leadsToAidSuspension}
                                        </Form.Control.Feedback>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={{span:4}}>
                                    <DateTimeInputField
                                        name='deadline'
                                        labelId='candidatureTabs.oversight.tabs.irregularities.deadline'
                                        value={values.date}
                                        isDateWithNoTime={true}
                                        setFieldValue={setFieldValue}
                                        errorMessage={errors.deadline}
                                    />
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <SingleUploadArea
                                        documents={documents}
                                        setDocuments={setDocuments}
                                        documentType={'IRREGULARITY_NOTIFICATION'}
                                        setError={setError}
                                        acceptExtensions='.pdf,.docx'
                                        processUploadCallback={formData => setFieldValue('file', formData)}
                                        processDeleteCallback={fileExternalId => setFieldValue('file', null)}
                                    />
                                </Col>
                            </Row>
                            <Row className='mt-3'>
                                <Col>
                                    <label>
                                        <FormattedMessage id='candidatureTabs.oversight.tabs.irregularities.causes' />
                                    </label>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    {Object.keys(irregularityCauseToRegulationMap).map( (irreg) => (
                                        <Form.Group key={irreg} controlId={"irregularityCauses"+irreg}>
                                                <Form.Check
                                                    inline
                                                    name={irreg}
                                                    type={'checkbox'}
                                                    className={'d-flex align-items-center'}
                                                    label={<FormattedMessage id={'candidatureTabs.oversight.tabs.irregularities.cause.' + irreg} />}
                                                    checked={values.causes[irreg]}
                                                    onChange={({target}) =>
                                                        target.checked ? values.causes.push(irreg) : values.causes.splice(values.causes.indexOf(irreg), 1)
                                                    }
                                                />
                                        </Form.Group>
                                    ))}
                                    <Form.Control.Feedback type='invalid'>
                                        {errors.causes}
                                    </Form.Control.Feedback>
                                </Col>
                            </Row>

                            <Row className='form-actions'>
                                <Col className='justify-content-between d-flex'>
                                    <button className='btn btn-outline-primary' type='button' onClick={() => onCancel?.()}>
                                        <FormattedMessage id='all.cancel' />
                                    </button>
                                    <button className='btn btn-primary' type='submit' >
                                        <FormattedMessage id='all.submitButtonText' />
                                    </button>
                                </Col>
                            </Row>
                    </Form>
                )}
            </Formik>
        </>
    );
}