import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { handleError, isNotBusinessError } from '../../../utils/handleError';
import { ListFilterBar } from '../../../components/listContracts/ListFilterBar';
import { AlertError } from '../../../components/bootstrap/AlertError';
import { Arrays } from '../../../utils/Arrays';
import { FormattedMessage, useIntl } from 'react-intl';
import { EnumSelectField } from '../../../components/bootstrap/EnumSelectField';
import { AlertSuccess } from '../../../components/bootstrap/AlertSuccess';
import { useNavigate } from 'react-router-dom';
import { LabelToolTip } from '../../../components/bootstrap/LabelToolTip';
import { EmptyResults } from '../../../components/general/EmptyResults';
import { SubTemplate } from '../../../components/general/SubTemplate';
import Loading from '../../../components/general/Loading';
import { CustomPagination } from '../../../components/general/CustomPagination';
import { Strings } from '../../../utils/Strings';
import {
	contractListingMonthStatus,
	listContractsListing,
} from '../../../rest/contractsListing';

const MAX_RESULTS = 10;
const DEFAULT_INITIAL_PAGE = 0;

export function ListContractListing() {
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);
	const [numberOfResults, setNumberOfResults] = useState(null);
	const [totalResults, setTotalResults] = useState(null);
	const [currentPage, setCurrentPage] = useState(DEFAULT_INITIAL_PAGE);

	const [b4List, setB4List] = useState([]);

	const orderByOptionsIdPrefix = 'contractListing.list.orderOption';
	const orderByOptions = ['changeDate_desc', 'changeDate_asc'];

	const [currentOrderBy, setCurrentOrderBy] = useState(orderByOptions[0]);

	const [filters, setFilters] = useState({
		year: '',
		month: '-1',
		state: '-1',
	});

	const hoverpreffixId = 'contractListing.list.hover';

	const [monthStatusB4, setMonthStatusB4] = useState(null);

	const navigate = useNavigate();

	const intl = useIntl();

	const [lsItem, setLsItem] = useState(null);

	async function fetchData(fromFilter) {
		try {
			if(fromFilter === true){
				setCurrentPage(0);
			}

			let orderParams = currentOrderBy.split('_');

			let queryparams = {
				index: fromFilter ? 0 : currentPage,
				size: MAX_RESULTS,
				orderBy: orderParams[0],
				orderType: orderParams[1],
			};

			if (filters.state !== '-1') {
				queryparams.state = filters.state;
			}

			if (!Strings.isBlank(filters.year)) {
				queryparams.year = filters.year;
			}

			if (filters.month !== '-1') {
				queryparams.month = filters.month -1;
			}

			const [{ data: formList }] = await Promise.all([
				await listContractsListing(queryparams),
			]);

			setB4List(formList.results);
			setNumberOfResults(formList.numberOfResults);
			setTotalResults(formList.totalResults);
		} catch (error) {
			setError(error);
		} finally {
			setLoading(false);
		}
	}

	const onPageChange = (pageNumber) => {
		setCurrentPage(pageNumber);
	};

	function handleOrderByChange(e) {
		setCurrentOrderBy(e.target.value);
	}

	async function getB4MonthStatus() {
		const { data: status } = await contractListingMonthStatus();
		setMonthStatusB4(status);
	}

	useEffect(() => {
		checkStorageItems();
		getB4MonthStatus();
	}, []);

	useEffect(() => {
		fetchData();
	}, [currentPage]);

	if (loading) {
		return <Loading />;
	}

	if (error && isNotBusinessError(error)) {
		return handleError(error);
	}

	function redirectToFormPage(b4ExternalId) {
		navigate(
			`/listagemContratos/${b4ExternalId !== undefined ? b4ExternalId : ''}`
		);
	}

	function checkStorageItems() {
		if (sessionStorage.getItem('contractListingSubmited')) {
			setLsItem('contractListingSubmited');
		}
		if (sessionStorage.getItem('contractListingWithdrawed')) {
			setLsItem('contractListingWithdrawed');
		}
	}

	return (
		<SubTemplate
			titleId={'contractListing.list.title'}
			hasBackButton
		>
			<Container>
				<div className='mx-2 mb-4'>
					<AlertSuccess lsItem={lsItem} />
				</div>
				<Row>
					<Col md='10'>
						<ListFilterBar
							filters={filters}
							setFilters={setFilters}
							search={fetchData}
						/>
					</Col>
				</Row>
				<Row className='searchResults'>
					<Col md='10'>
						<AlertError error={error} />

						{Arrays.isNotEmpty(b4List) ? (
							<>
								<Row className='mt-5 mb-1 d-flex justify-content-between px-2'>
									<Col className='text-muted'>
										{totalResults}{' '}
										<FormattedMessage
											id={`all.results.lowCase.${
												totalResults === 1 ? 'singular' : 'plural'
											}`}
										/>
									</Col>
									<Col md='4'>
										<EnumSelectField
											preffixDescriptionId={orderByOptionsIdPrefix}
											handleChange={handleOrderByChange}
											options={orderByOptions}
											value={currentOrderBy}
										/>
									</Col>
								</Row>

								<ul className='results'>
									{b4List.map((b4, index) => (
										<li key={b4.externalId}>
											<Row
												className='py-3'
												onClick={() => redirectToFormPage(b4.externalId)}
											>
												<Col md='7' className='d-flex align-items-center'>
													<LabelToolTip
														labelId={`${hoverpreffixId}.contractListing`}
														value={
															intl.formatMessage({
																id: 'contractListing.list.contractListing',
															}) +
															' ' +
															intl.formatMessage({
																id: `month.${b4.month + 1}`,
															}) +
															' ' +
															b4.year
														}
													/>
												</Col>
												<Col md='2' className='d-flex align-items-center'>
													<LabelToolTip
														labelId={`${hoverpreffixId}.state`}
														value={
															<FormattedMessage
																id={`processState.${b4.processState}`}
															/>
														}
													/>
												</Col>
												<Col
													md='3'
													className='d-flex align-items-center justify-content-end'
												>
													<LabelToolTip
														labelId={`${hoverpreffixId}.changeDate`}
														value={b4.changeDate}
													/>
												</Col>
											</Row>
										</li>
									))}
								</ul>
							</>
						) : (
							<EmptyResults />
						)}
					</Col>
					{monthStatusB4 !== null && monthStatusB4.canFill && (
						<Col md='2'>
							<div className='mt-4 py-3'>
								<button
									className='btn btn-outline-primary d-flex align-items-center justify-content-center mb-4 w-100'
									type='button'
									onClick={() => redirectToFormPage()}
								>
									<FormattedMessage id={`contractListing.button.${monthStatusB4.inDraft ? 'continue' : 'create'}`} />
								</button>
							</div>
						</Col>
					)}
				</Row>
				<Row className='mb-5'>
					<Col md='10' className='d-flex'>
						<div className='mx-2'>
							<CustomPagination
								dataPerPage={MAX_RESULTS}
								totalData={totalResults}
								onPageChange={onPageChange}
								currentPage={currentPage}
							/>
						</div>
					</Col>
				</Row>
			</Container>
		</SubTemplate>
	);
}
