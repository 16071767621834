export const ForeCastType = {
  SNC: 'SNC',
  NCM: 'NCM',

  values: () => {
    return [
      'SNC',
      'NCM'
    ];
  },
};
