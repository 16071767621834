import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { FaSearch } from 'react-icons/fa';
import { FormattedMessage, useIntl } from 'react-intl';
import { EnumSelectField } from '../bootstrap/EnumSelectField';
import { NumberInputField } from '../bootstrap/NumberInputField';
import { TextInputField } from '../bootstrap/TextInputField';

export function ListFilterBar({ filters, setFilters, search }) {
	const intl = useIntl();
	
  function handleFilterChange (e, filterName, callback){
    if(callback !== undefined){
      filters[filterName].value = callback(e);
    }
    else{
      filters[filterName].value = e.target.value
    }
    setFilters({...filters})
  }

	return (
		<>
			<Row className='searchFilters'>
				{Object.keys(filters).map((name) => (
					<Col md={filters[name].colSpace} key={name}>
						{filters[name].type === 'enum' ? (
							<EnumSelectField
								preffixDescriptionId={filters[name].preffixDescriptionId}
								handleChange={(e) => handleFilterChange(e, name, filters[name].handleChange)}
								options={filters[name].options}
								value={filters[name].value}
								nullOption={filters[name].nullOption}
								nullOptionSuffix={filters[name].nullOptionSuffix}
							/>
						) : filters[name].type === 'number' ? (
							<NumberInputField
                handleChange={(e) => handleFilterChange(e, name, filters[name].handleChange)}
								value={filters[name].value}
								maxLength={filters[name].maxLength}
								placeholder={
									filters[name].placeholderId
										? intl.formatMessage({ id: filters[name].placeholderId })
										:  filters[name].placeholder ? filters[name].placeholder : undefined
								}
							/>
						) : (
							<TextInputField
                handleChange={(e) => handleFilterChange(e, name, filters[name].handleChange)}
								value={filters[name].value}
								maxLength={filters[name].maxLength}
								placeholder={
									filters[name].placeholderId
										? intl.formatMessage({ id: filters[name].placeholderId })
										: filters[name].placeholder ? filters[name].placeholder : undefined
								}
							/>
						)}
					</Col>
              ))}
				<Col md='3'>
					<button
						className='btn btn-outline-primary'
						onClick={() => search(true)} //the true parameter indicates that the search was triggered by the filters. Consequently, currentPage will have to chgange to 0
						type='button'
					>
						<FaSearch /> <FormattedMessage id='all.search' />
					</button>
				</Col>
			</Row>
		</>
	);
}
