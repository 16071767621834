import { Button, Modal } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { acceptByPromoter } from '../../../rest/candidature';
import { useParams } from 'react-router-dom';
import { useState } from 'react';
import { AlertError } from '../../bootstrap/AlertError';
import { createCustomErrorMessage } from '../../../hooks/errorMessage';

export function CandidatureFlowAcceptByPromoter({ callbackHandler, canValidate }) {
  const intl = useIntl();
  const { externalId } = useParams();
  const [show, setShow] = useState(false);

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  const setAcceptByPromoterHandler = async () => {
    callbackHandler(() => {
      return acceptByPromoter(externalId);
    }, 'promoterCandidatureValidated');
  };
  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <FormattedMessage id='candidature.flow.acceptByPromoter.title' />
        </Modal.Header>
        <Modal.Body>
          {canValidate.canValidate ? (
            <FormattedMessage id='candidature.message.confirmationToAcceptByPromoter' />
          ) : (
            <FormattedMessage id='candidature.confirmationToAcceptByPromoter.alertCanNotValidate' />
          )}
        </Modal.Body>
        <Modal.Footer>
          {canValidate.canValidate ? (
            <>
              <Button variant='outline-secondary' onClick={handleClose}>
                <FormattedMessage id='all.no' />
              </Button>
              <Button variant='outline-primary' onClick={setAcceptByPromoterHandler}>
                <FormattedMessage id='all.yes' />
              </Button>
            </>
          ) : (
            <Button variant='outline-secondary' onClick={handleClose}>
              <FormattedMessage id='all.cancel' />
            </Button>
          )}
        </Modal.Footer>
      </Modal>
      <Button variant='outline-primary' onClick={handleShow}>
        <FormattedMessage id='candidature.flow.acceptByPromoter' />
      </Button>
    </>
  );
}
