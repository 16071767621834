import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Row, Tab, Tabs } from 'react-bootstrap';
import { FaInfoCircle } from 'react-icons/fa';
import { FormattedMessage, useIntl } from 'react-intl';
import {Route, useNavigate, useParams} from 'react-router-dom';
import { AlertError } from '../../components/bootstrap/AlertError';
import { DecisionDialog } from '../../components/bootstrap/DecisionDialog';
import { PriorSupportMessageToPromoterModal } from '../../components/candidature/PriorSupportMessageToPromoterModal ';
import { AuthenticatedDownloadLink } from '../../components/document/AuthenticatedDownloadLink';
import Loading from '../../components/general/Loading';
import { SubTemplate } from '../../components/general/SubTemplate';
import { MessageList } from '../../components/messages/MessageList';
import { PaginationComponent } from '../../components/PaginationComponent';
import ContratoMCE from '../../documents/ANEXO B3 - CONTRATO DE PRESTACAO DE MCE A CRIACAO E ESTRUTURACAO DE PROJETOS.docx';
import { PriorSupportState } from '../../models/PriorSupportState';
import {
  acceptPriorSupportRequest,
  allowManageB6,
  getPriorSupportByExternalId,
  getPriorSupportDocuments,
  getPriorSupportMessages,
  rejectPriorSupportRequest,
  sendReviewPriorSupport
} from '../../rest/priorSupport';
import { handleError, isNotBusinessError } from '../../utils/handleError';
import {AlertSuccess} from "../../components/bootstrap/AlertSuccess";
import {isEntityUser} from "../../authentication/authenticationHelper";

const MAX_RESULTS = 15;
const DEFAULT_INITIAL_PAGE = 0;

export function DetailPriorSupport() {
  const { externalId } = useParams();
  const intl = useIntl();
  const navigate = useNavigate();

  const [priorSupport, setPriorSupport] = useState();
  const [priorSupportMessages, setPriorSupportMessages] = useState();
  const [documents, setDocuments] = useState();
  const [canFillB6, setCanFillB6] = useState();
  const [currentPage, setCurrentPage] = useState(DEFAULT_INITIAL_PAGE);

  const [loading, setLoading] = useState(true);
  const [showAccept, setShowAccept] = useState(false);
  const [showReject, setShowReject] = useState(false);
  const [showReview, setShowReview] = useState(false);
  const [error, setError] = useState(null);
  const [lsItem, setLsItem] = useState(null);
  const [showPriorEntityMessagePromoterModal, setShowPriorEntityMessagePromoterModal] =
    useState(false);

  function acceptPriorSupport() {
    setShowAccept(true);
  }

  function handleCloseAccept() {
    setShowAccept(false);
  }

  const handleConfirmAccept = async () => {
    try {
      const [{ data: priorSupport }] = await Promise.all([acceptPriorSupportRequest(externalId)]);
      setPriorSupport(priorSupport);
      setShowAccept(false);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  function rejectPriorSupport() {
    setShowReject(true);
  }

  function handleCloseReject() {
    setShowReject(false);
  }

  const handleConfirmReject = async (reason) => {
    try {
      let reqBody = {
        reason: reason
      };

      const [{ data: priorSupport }] = await Promise.all([
        rejectPriorSupportRequest(externalId, reqBody)
      ]);
      setPriorSupport(priorSupport);
      setShowReject(false);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  function sendToReviewPriorSupport() {
    setShowReview(true);
  }

  function handleCloseReview() {
    setShowReview(false);
  }

  const handleConfirmReview = async (reason) => {
    try {
      let reqBody = {
        reason: reason
      };
      const [{ data: priorSupport }] = await Promise.all([
        sendReviewPriorSupport(externalId, reqBody)
      ]);
      setPriorSupport(priorSupport);
      setShowReview(false);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const fetchData = async () => {
    try {
      const [{ data: priorSupport }, { data: documents }] =
        await Promise.all([
          getPriorSupportByExternalId(externalId),
          getPriorSupportDocuments(externalId)
        ]);
      setPriorSupport(priorSupport);
      setDocuments(documents);

      if(isEntityUser()){
        const [{ data: isB6FillAllowed }] =
            await Promise.all([
              allowManageB6(externalId)
            ]);

        setCanFillB6(isB6FillAllowed);
      }


      await retrievePriorSupportMessages();
      checkStorageItems();
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };


  function checkStorageItems() {
    if (sessionStorage.getItem('projectIdentificationSubmited')) {
      setLsItem('projectIdentificationSubmited');
    }

  }

  async function retrievePriorSupportMessages() {
    try {
      const { data: psMessages } = await getPriorSupportMessages(
        externalId,
        currentPage,
        MAX_RESULTS
      );
      setPriorSupportMessages(psMessages);
    } catch (error) {
      setError(error);
    }
  }

  useEffect(() => {
    fetchData();
  }, [currentPage]);

  if (loading) {
    return <Loading />;
  }

  if (error && isNotBusinessError(error)) {
    return handleError(error);
  }

  const onPageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <SubTemplate titleId={'priorSupport.detail.title'} hasBackButton>
      <PriorSupportMessageToPromoterModal
        show={showPriorEntityMessagePromoterModal}
        setShow={setShowPriorEntityMessagePromoterModal}
        externalId={priorSupport.externalId}
        updatePriorSupportMessagesCallback={retrievePriorSupportMessages}
      />
      {lsItem && (
          <Row>
            <Col md={{ span: 8, offset: 2 }}>
              <div className='mx-2 mb-4'>
                <AlertSuccess lsItem={lsItem} />
              </div>
            </Col>
          </Row>
      )}
      <Container>
        <AlertError error={error} />
        <Row>
          <Col md='9' className='mb-4'>
            <AlertError error={error} />
            <Tabs defaultActiveKey='resume' justify>
              <Tab
                eventKey='resume'
                title={<FormattedMessage id='detailPriorSupport.tabs.resume' />}
                className='mb-4 p-3 border'>
                <div>
                  {priorSupport.state === PriorSupportState.ACCEPTED && (
                    <div>
                      <Row>
                        <Col>
                          <p className='mt-3 text-secondary infoText'>
                            <FaInfoCircle size={40} />
                            <FormattedMessage id='contratactMCE.entity.info' />
                          </p>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <a target='_blank' rel='noreferrer' href={ContratoMCE}>
                            <FormattedMessage id='contractMCE.download' />
                          </a>
                        </Col>
                      </Row>
                    </div>
                  )}
                  <Row className='mt-4'>
                    <Col md={5}>
                      <label>
                        <FormattedMessage id='priorSupport.externalId' />
                      </label>
                      <p>{priorSupport.externalId}</p>
                    </Col>
                    <Col md={4}>
                      <label>
                        <FormattedMessage id='priorSupport.state' />
                      </label>
                      {priorSupport.state === PriorSupportState.ASKED &&
                      documents?.filter(
                        (document) => document.documentType === 'REVIEW_PRIOR_SUPPORT'
                      ).length > 0 ? (
                        <FormattedMessage id={`priorSupportState.WAIT_DOC`} />
                      ) : (
                        <FormattedMessage id={`priorSupportState.${priorSupport.state}`} />
                      )}
                    </Col>
                    <Col md={3}>
                      <label>
                        <FormattedMessage id='priorSupport.candidatureState' />
                      </label>
                      <FormattedMessage id={`candidatureState.${priorSupport.candidatureState}`}/>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={5}>
                      <label>
                        <FormattedMessage id='priorSupport.owner.name' />
                      </label>
                      <p>{priorSupport.promoterName}</p>
                    </Col>
                    <Col md={4}>
                      <label>
                        <FormattedMessage id='priorSupport.owner.email' />
                      </label>
                      <p>{priorSupport.promoterEmail}</p>
                    </Col>
                    <Col md={3}>
                      <label>
                        <FormattedMessage id='priorSupport.owner.NIF' />
                      </label>
                      <p>{priorSupport.nifTitular}</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={5}>
                      <label>
                        <FormattedMessage id='priorSupport.location' />
                      </label>
                      <p>{priorSupport.location}</p>
                    </Col>
                    <Col md={7}>
                      <label>
                        <FormattedMessage id='priorSupportForm.entity' />
                      </label>
                      <p>{priorSupport.entityName}</p>
                    </Col>





                  </Row>

                  {priorSupport.multiPromoters!== undefined &&
                  <Row>
                    <Col md={6}>
                      <label>
                        <FormattedMessage id='priorSupportForm.multiPromoters' />
                      </label>
                      <p>{priorSupport.multiPromoters? <FormattedMessage id={'all.yes'}/> : <FormattedMessage id={'all.no'}/>  }</p>
                    </Col>
                  </Row>

                  }
                  {priorSupport.reasonToReject &&
                  <Row>
                    <Col md={12}>
                      <label>
                        <FormattedMessage id='priorSupport.reasonToReject' />
                      </label>
                      <p>{priorSupport.reasonToReject}</p>
                    </Col>
                  </Row>
                  }

                  {(priorSupport.state === PriorSupportState.ASKED ||
                    priorSupport.state === PriorSupportState.FINISH ||  priorSupport.state===PriorSupportState.AWAIT_REVOKE_DECISION || priorSupport.state === PriorSupportState.REVOKED ) && (
                    <>
                      <Row className='mt-3'>
                        <Col md={5}>
                          <label>
                            <FormattedMessage id='candidature.history.document.file' />
                          </label>
                        </Col>
                        <Col md={7}>
                          <label>
                            <FormattedMessage id='all.file' />
                          </label>
                        </Col>
                      </Row>

                      {documents?.map((d, index) => (
                        <Row key={index} className='border-top pt-2 pb-2'>
                          <Col md={5}>
                            {' '}
                            {intl.formatMessage({
                              id: 'documentType.' + d.documentType
                            })}
                          </Col>
                          <Col md={7}>
                            <AuthenticatedDownloadLink url={d.documentPath} filename={d.name}>
                              <>{(d.fileDescription ? d.fileDescription + ' : ' : '') + d.name}</>
                            </AuthenticatedDownloadLink>
                          </Col>
                        </Row>
                      ))}
                    </>
                  )}
                </div>

                <DecisionDialog
                  bodyId={'acceptPriorSupport.modal.question'}
                  titleId={'acceptPriorSupport.modal.title'}
                  handleConfirm={handleConfirmAccept}
                  show={showAccept}
                  handleClose={handleCloseAccept}
                />

                <DecisionDialog
                  bodyId={'sendToReviewPriorSupport.modal.question'}
                  titleId={'sendToReviewPriorSupport.modal.title'}
                  handleConfirm={handleConfirmReview}
                  show={showReview}
                  handleClose={handleCloseReview}
                  needReason={true}
                />

                <DecisionDialog
                  bodyId={'rejectPriorSupport.modal.question'}
                  titleId={'rejectPriorSupport.modal.title'}
                  handleConfirm={handleConfirmReject}
                  show={showReject}
                  handleClose={handleCloseReject}
                  needReason={true}
                />
              </Tab>

              <Tab
                eventKey='messages'
                title={<FormattedMessage id='detailPriorSupport.tabs.messages' />}
                className='mb-4 p-3 border'>
                <div className='mt-3'>
                  <PaginationComponent
                    dataPerPage={MAX_RESULTS}
                    totalData={priorSupportMessages.numberOfMessages}
                    onPageChange={onPageChange}
                    currentPage={currentPage}
                  />
                  <MessageList messages={priorSupportMessages.messages} />
                </div>
              </Tab>
            </Tabs>
          </Col>
          <Col md='3'>
            <div className='px-2 mb-5'>
              {priorSupport?.state === PriorSupportState.ASKED && (
                <>
                  <Button
                    className='btn btn-outline-primary d-flex align-items-center justify-content-center mb-4 w-100'
                    variant='outline-primary'
                    onClick={acceptPriorSupport}>
                    <FormattedMessage id='acceptPriorSupport.accept.button' />
                  </Button>

                  <Button
                    className='btn btn-outline-primary d-flex align-items-center justify-content-center mb-4 w-100'
                    variant='outline-primary mt-2'
                    onClick={sendToReviewPriorSupport}>
                    <FormattedMessage id='sendToReviewPriorSupport.accept.button' />
                  </Button>

                  <Button
                    className='btn btn-outline-primary d-flex align-items-center justify-content-center mb-4 w-100'
                    variant='outline-primary mt-2'
                    onClick={rejectPriorSupport}>
                    <FormattedMessage id='rejectPriorSupport.accept.button' />
                  </Button>
                </>
              )}
              {canFillB6 && (
                <button
                  className='btn btn-outline-primary d-flex align-items-center justify-content-center mb-4 w-100'
                  type='button'
                  onClick={() => {
                    navigate('/identificacaoprojeto/'+externalId+'/adicionar');
                  }}>
                  <FormattedMessage id='detailPriorSupport.button.identificationSheet' />
                </button>
              )}
              { (isEntityUser() && priorSupport.state !== PriorSupportState.REJECTED && priorSupport.state !== PriorSupportState.CANCEL)  && (
                <button
                  className='btn btn-outline-primary d-flex align-items-center justify-content-center mb-4 w-100'
                  type='button'
                  onClick={() => {
                    setShowPriorEntityMessagePromoterModal(true);
                  }}>
                  <FormattedMessage id='candidatureForm.messagePriorSupportPromoter' />
                </button>
              )}
              {
                [PriorSupportState.REVOKED,PriorSupportState.FINISH,PriorSupportState.AWAIT_REVOKE_DECISION].includes(priorSupport.state) &&(
                    <button
                        className='btn btn-outline-primary d-flex align-items-center justify-content-center mb-4 w-100'
                        type='button'
                        onClick={() => {
                          navigate('/apoioPrevio/'+externalId+'/revogacoes');
                        }}>

                      <FormattedMessage id='revocationForm.list.button' />
                    </button>

                )
              }

            </div>
          </Col>
        </Row>
      </Container>
    </SubTemplate>
  );
}
