import React, {useEffect, useState} from 'react'
import {useNavigate, useParams} from 'react-router-dom';
import {AlertError} from '../../components/bootstrap/AlertError';
import {ContestationForm} from '../../components/candidature/ContestationForm';
import Loading from '../../components/general/Loading';
import {SubTemplate} from '../../components/general/SubTemplate';
import {getPromoterCandidature} from '../../rest/promotercandidature';
import {handleError, isNotBusinessError} from '../../utils/handleError';
import {CandidatureState} from "../../models/CandidatureState";
import {Arrays} from "../../utils/Arrays";
import {createMultiPart} from "../../components/SyncMultipleUploadArea";
import {saveContestation, saveContestationWithDocuments} from '../../rest/candidature';


export function CandidatureContestation() {

    const {externalId} = useParams();
    const navigate = useNavigate();

    const [loading, setLoading] = useState(true);
    const [candidatureState, setCandidatureState] = useState(null);
    const [error, setError] = useState(null);
    const [documentType, setDocumentType] = useState(null);
    const [primaryPromoter, setPrimaryPromoter] = useState(false);
    const [contestation, setContestation] = useState({});

    async function checkPromoter() {
        setLoading(true);

        try {
            const [{data: promoterCandidature}] = await Promise.all([await getPromoterCandidature(externalId)]);
            if (promoterCandidature.promoterType === 'PRIMARY' && (promoterCandidature.candidatureState === 'WAIT_CONTESTATION' || promoterCandidature.candidatureState === CandidatureState.WAIT_CONTESTATION_EXPIRATION || promoterCandidature.candidatureState === CandidatureState.WAIT_CONTESTATION_ELIGIBILITY_VALIDATION)) {
                setCandidatureState(promoterCandidature.candidatureState);
                setPrimaryPromoter(true);
            }
            const typeDoc = promoterCandidature.candidatureState === CandidatureState.WAIT_CONTESTATION ? 'CONTESTATION' : (promoterCandidature.candidatureState === CandidatureState.WAIT_CONTESTATION_EXPIRATION ? 'CONTESTATION_EXPIRATION' : 'CONTESTATION_ELIGIBILITY');
            setDocumentType(typeDoc);
        } catch (error) {
            setError(error);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        checkPromoter();
    }, []);

    async function handleSubmit(contestation, documents) {
        try {
            if (Arrays.isNotEmpty(documents)) {
                const formData = createMultiPart(documents);
                formData.append('ContestationDto', JSON.stringify(contestation));
                await saveContestationWithDocuments(externalId, formData);
            } else {
                await saveContestation(externalId, contestation);
            }

            sessionStorage.setItem('saveContestation', 'true')
            navigate('/candidatura/' + externalId);
        } catch (error) {
            console.log(error);
            setError(error);
        }
    }

    if (loading) {
        return <Loading/>;
    }

    if (error && isNotBusinessError(error)) {
        return handleError(error);
    }

    return (
        <SubTemplate>
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-12 mb-5'>
                        <AlertError error={error}/>
                        {primaryPromoter &&
                            <ContestationForm
                                documentType={documentType}
                                onSubmit={handleSubmit}
                                contestation={contestation}
                                setContestation={setContestation}
                                externalId={externalId}
                                candidatureState={candidatureState}
                            />}
                    </div>
                </div>
            </div>
        </SubTemplate>
    );
}
