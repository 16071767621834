import { apiAxios as authAxios } from '../axios.config';


export const getInterestExpressions = (candidatureExternalId, index, size) => {
  return authAxios.get(`/api/candidature/${candidatureExternalId}/interestExpression/`, {
    params: {
      index: index,
      size: size,
    },
  });
};



export const createInterestExpression = (candidatureExternalId, interestExpression) => {
  return authAxios.post(`/api/candidature/${candidatureExternalId}/interestExpression`, interestExpression);
};

export const interestExpressionWithdrawal = (candidatureExternalId) => {
  return authAxios.delete(`/api/candidature/${candidatureExternalId}/interestExpression`);
};


