import React, {useEffect, useState} from 'react';

import {useNavigate, useParams} from 'react-router-dom';
import {handleError, isBusinessError} from "../../../utils/handleError";
import Loading from "../../../components/general/Loading";
import {SubTemplate} from "../../../components/general/SubTemplate";
import {AlertError} from "../../../components/bootstrap/AlertError";
import {getEntityLocations} from "../../../rest/entity";
import {Arrays} from "../../../utils/Arrays";
import {createMultiPartWithFilesAndJsonObject} from "../../../components/SyncMultipleUploadArea";
import {
    addEntityChange,
    addEntityChangeWithFiles,
    getEntityChangeAvailableLocations
} from "../../../rest/entityChanges";
import {EntityChangeLocationForm} from "../../../components/entityChange/EntityChangeLocationForm";

export function AddEntityChangeLocation() {

    const {nif} = useParams();
    const [dr, setDr] = useState([])
    const [ec, setEc] = useState(null);
    const [documents, setDocuments] = useState([]);


    async function fetchData() {

        try {

            let [
                {data: drLocations}
            ] = await Promise.all([
                await getEntityChangeAvailableLocations(nif)
            ])
            setDr(drLocations);
            let entityChange = {regionalDelegationCode: '-1', reason: '', administrativeDivisionChanges: []}
            setEc(entityChange)
            //setFormikInitialValues(getFormikInitialValues(projectIdentification));
        } catch (error) {
            setError(error);
        } finally {
            setLoading(false);
        }

        //setShowSubmitModal(false);
    };


    useEffect(() => {
        fetchData();
    }, []);


    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [submited, setSubmited] = useState(false);

    const onSubmit = async (values, setSubmitting, setErrors) => {
        try {
            let initialValues = values;
            initialValues.type = 'LOCATION';

            const hasFiles = Arrays.isNotEmpty(documents);
            if (hasFiles) {
                const formData = createMultiPartWithFilesAndJsonObject(documents, initialValues, 'EntityChangeDto');
                await addEntityChangeWithFiles(nif, formData);
            } else {
                await addEntityChange(nif, initialValues);
            }
            setSubmited(true);

            /*await createActionNotification(actionNotification);

            setActionNotification(actionNotification);*/
            /*setSubmitting(false);

            setLoading(false);*/
        } catch (error) {
            /* setSubmitting(false);
             setError(error);
             setLoading(false);*/
        }
    };


    if (loading) {
        return <Loading/>;
    }

    if (error && !isBusinessError(error)) {
        return handleError(error);
    }

    if (submited) {
        sessionStorage.setItem('entityChangeSubmitLocation', submited);
        navigate('/alteracoesEntidade/' + nif);
    }

    return (
        <SubTemplate hasBackButton titleId={'entityChange.title.LOCATION'}>
            <div className={'container'}>
                <div className={'row'}>
                    <div className={'col-lg-8 offset-lg-2'}>
                        <AlertError error={error}/>
                        {Arrays.isNotEmpty(dr) &&
                        <EntityChangeLocationForm entityChange={ec} documents={documents} setDocuments={setDocuments}
                                                  drs={dr} setError={error} onSubmit={onSubmit}/>
                        }

                    </div>
                </div>
            </div>
        </SubTemplate>
    );
}
