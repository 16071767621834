import React, { createContext, useContext, useState } from 'react';
import {getAccessInfo, getUserInfo} from './authentication/authenticationHelper';

const AppContext = createContext();

const AppProvider = ({ children, config }) => {
  const [accessInfo, setAccessInfo] = useState(getAccessInfo());
  const [globalLoading, setGlobalLoading] = useState(false);
  const [nMessages, setNMessages] = useState(0);
  const [canEditProfile, setCanEditProfile] = useState(false);
  const [name, setName] = useState(null);

  const [hasPriorSupport, setHasPriorSupport] = useState(false);


  return (
    <AppContext.Provider
      value={{
        config,
        accessInfo,
        setAccessInfo,
        globalLoading,
        setGlobalLoading,
        nMessages,setNMessages,
        hasPriorSupport,setHasPriorSupport,
          canEditProfile, setCanEditProfile,
          name,setName
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

const useGlobalContext = () => {
  return useContext(AppContext);
};

export { AppContext, AppProvider, useGlobalContext };
