import React from 'react';
import { Col, Row, Table } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { FunctionType } from '../../models/FunctionType';
import { PeopleType } from '../../models/PeopleType';
import { CandidatureFinancialUtils } from '../../utils/CandidatureFinancialUtils';
import { CandidatureFormUtils } from '../../utils/CandidatureFormUtils';
import IntInputField from '../bootstrap/IntInputField';
import { TextAreaInputField } from '../bootstrap/TextAreaInputField';
import ToolTip from "../bootstrap/ToolTip";

export function CandidatureFormStep11({
  errors,
  setFieldValue,
  handleChange,
  values,
  handleSave,
  handleGoBack,
  numberOfSteps
}) {
  const KEYS = [...FunctionType.values(), 'TOTAL'];

  const BOLD_KEYS = 'TOTAL';

  const YEARS = [
    ...new Set(values.peopleBoardList[0].peopleBoardCellList.map((pbcl) => pbcl.year))
  ];

  const intl = useIntl();

  function getPeopleBoardCell(year, fieldName, peopleType) {
    return values.peopleBoardList[0].peopleBoardCellList.find(
      (pbcl) =>
        pbcl.year === year && pbcl.functionType === fieldName && pbcl.peopleType === peopleType
    );
  }

  function handlePoepleBoardChange(e, year, fieldName, peopleType) {
    getPeopleBoardCell(year, fieldName, peopleType).value = e.target.value;

    setFieldValue('peopleBoardList', [...values.peopleBoardList]);
  }

  function handleReasonChange(e) {
    values.peopleBoardList[0].reasonToPeopleBoard = e.target.value;
    setFieldValue('peopleBoardList', [...values.peopleBoardList]);
  }

  function getPeopleBoardCellValue(year, fieldName, peopleType) {
    return getPeopleBoardCell(year, fieldName, peopleType).value;
  }

  function getTableRowsByKey(key, peopleType) {
    return (
      <tr key={key}>
        <td className={`w-20 ${BOLD_KEYS.includes(key) && 'table-item-bold'}`}>
          <FormattedMessage id={`functionType.${key}`} />
          { (BOLD_KEYS.includes(key) && peopleType===PeopleType.DESTINATION_PROMOTER) && (
              <ToolTip message={   intl.formatMessage(
                  { id: 'step8.tooltip.total' }
              )  }/>
          )}
          {
            (peopleType===PeopleType.DESTINATION_PROMOTER && (key === FunctionType.ADMIN_DIR || key=== FunctionType.ADMIN_FINANCE) &&(
                <ToolTip message={   intl.formatMessage(
                    { id: 'step8.tooltip.totalDestinationPromoters' }
                )  }/>
            ))
          }




        </td>
        {YEARS.map((year) => (
          <React.Fragment key={year}>
            {!CandidatureFormUtils.is3YearsCoverageOnly(values, year) && (
              <td className='w-10'>
                {key === 'TOTAL' ? (
                  <div className='text-center'>
                    {CandidatureFinancialUtils.calculatePeopleBoardTotalValue(
                      values,
                      year,
                      peopleType
                    )}
                  </div>
                ) : (
                  <IntInputField
                    controlId={`peopleBoardCell.${key}`}
                    name={`peopleBoardCell.${key}`}
                    value={getPeopleBoardCellValue(year, key, peopleType)}
                    handleChange={(e) => handlePoepleBoardChange(e, year, key, peopleType)}
                  />
                )}
              </td>
            )}
          </React.Fragment>
        ))}
      </tr>
    );
  }

  function handleNextStep() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    handleSave(true);
  }

  return (
    <div>
      <h4 className='text-primary mb-0'>
        <FormattedMessage id='candidatureTabs.peopleBoard' />
      </h4>
      <small className='text-secondary text-uppercase'>
        {intl.formatMessage(
          { id: 'all.step' },
          { step: values.step, numberOfSteps: numberOfSteps }
        )}
      </small>

      {PeopleType.values().map((pt,index) => (
        <Row key={index}>
          <Col>
            <h5 className='text-secondary mt-4'>
              <FormattedMessage id={`peopleType.${pt}`} />
            </h5>
            <Table key={pt} className='mt-4'>
              <thead>
                <tr>
                  <th className='w-20'>
                    <FormattedMessage id='candidatureForm.peopleBoard.functions' />
                  </th>
                  {YEARS.map((year) => (
                    <React.Fragment key={year}>
                      {!CandidatureFormUtils.is3YearsCoverageOnly(values, year) && (
                        <th className='w-10 text-center' key={year}>
                          {CandidatureFormUtils.getYearLabel(values, year)}
                        </th>
                      )}
                    </React.Fragment>
                  ))}
                </tr>
              </thead>
              <tbody>{KEYS.map((key) => getTableRowsByKey(key, pt))}</tbody>
            </Table>
          </Col>
        </Row>
      ))}

      <>
        <Row>
          <Col md='12'>
            <TextAreaInputField
                toolTip={
                  <ToolTip
                      message={<FormattedMessage id='candidatureForm.peopleRemuneration.reasonToRemuneration.tooltip' /> }/>
                }
              labelId={'candidatureForm.peopleBoard.reasonToPeopleBoard'}
              name={'candidatureForm.peopleBoard.reasonToPeopleBoard'}
              value={values.peopleBoardList[0].reasonToPeopleBoard}
              handleChange={(e) => handleReasonChange(e)}
              placeholder={intl.formatMessage({ id: 'all.maxLength.placeholder' })}
              maxLength={10000}
              isInvalid={
                errors.peopleBoardList ? errors.peopleBoardList[0]?.reasonToPeopleBoard : false
              }
              errorMessage={
                errors.peopleBoardList ? errors.peopleBoardList[0]?.reasonToPeopleBoard : ''
              }
            />
          </Col>
        </Row>

        <div className='form-actions mt-5'>
          <button
            className='btn btn-link'
            type='button'
            onClick={() => handleGoBack(values, setFieldValue)}>
            <FormattedMessage id='all.back' />
          </button>

          <button className='btn btn-primary' type='button' onClick={() => handleNextStep()}>
            <FormattedMessage id='all.nextStep' />
          </button>
        </div>
      </>
    </div>
  );
}
