import { default as React, useEffect, useState } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { TiPlus } from 'react-icons/ti';
import { FormattedMessage } from 'react-intl';
import { useNavigate, useParams } from "react-router-dom";
import { AlertError } from '../../../components/bootstrap/AlertError';
import { InterestExpressionDialog } from "../../../components/candidature/InterestExpressionDialog";
import { CustomPagination } from '../../../components/general/CustomPagination';
import { EmptyResults } from '../../../components/general/EmptyResults';
import Loading from '../../../components/general/Loading';
import { SubTemplate } from '../../../components/general/SubTemplate';
import { createInterestExpression, getInterestExpressions, interestExpressionWithdrawal} from "../../../rest/interestExpression";
import { handleError, isNotBusinessError } from '../../../utils/handleError';
import { Arrays } from '../.././../utils/Arrays';
import { DecisionDialog } from "../../../components/bootstrap/DecisionDialog";
import { AuthenticatedDownloadLink } from '../../../components/document/AuthenticatedDownloadLink';
import { listCandidatureIncubationDocuments } from '../../../rest/document';
import { Accordion } from 'react-bootstrap';
import AccordionHeader from 'react-bootstrap/esm/AccordionHeader';
import AccordionBody from 'react-bootstrap/esm/AccordionBody';
import AccordionItem from 'react-bootstrap/esm/AccordionItem';
import {isPromoterUser} from "../../../authentication/authenticationHelper";


const MAX_RESULTS = 25;
const DEFAULT_INITIAL_PAGE = 0;

export function ListInterestExpression() {

    const { externalId } = useParams();

    const navigate = useNavigate();

    const [interestedExpressions, setInterestedExpressions] = useState([]);
    const [incubationDocuments, setIncubationDocuments] = useState([]);
    const [numberOfResults, setNumberOfResults] = useState(null);
    const [totalResults, setTotalResults] = useState(null);
    const [canCreate, setCanCreate] = useState(null);
    const [canDelete, setCanDelete] = useState(false);
    const [possibleEntities, setPossibleEntities] = useState([])


    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [showDialog, setShowDialog] = useState(false);
    const [showCancelDialog, setShowCancelDialog] = useState(false);
    const [currentPage, setCurrentPage] = useState(DEFAULT_INITIAL_PAGE);

    const fetchData = async () => {
        setError(null);
        try {
            const [
                { data: interestedExpressions },
                { data: incubationDocuments },
            ] = await Promise.all([
                await getInterestExpressions(externalId, currentPage, MAX_RESULTS),
                await listCandidatureIncubationDocuments(externalId),
            ]);
            setInterestedExpressions(interestedExpressions.results);
            setNumberOfResults(interestedExpressions.numberOfResults);
            setTotalResults(interestedExpressions.totalResults);
            setCanCreate(interestedExpressions.canCreate);
            setCanDelete(interestedExpressions.canDelete);
            setPossibleEntities(interestedExpressions.possibleEntities);
            setIncubationDocuments(incubationDocuments);
        } catch (error) {
            setError(error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, [currentPage]);


    const addInterestExpression = async (interestExpression, setSubmitting) => {
        setLoading(true);

        try {
            if (interestExpression.entityNif === '-1') {
                delete interestExpression.entityNif;
            }
            await createInterestExpression(externalId, interestExpression);
            fetchData();
            setSubmitting(false);
        } catch (error) {
            setError(error);
        } finally {
            setShowDialog(false);
            setLoading(false);
        }
    };


    const handleDialogClose = () => {
        setShowDialog(false);
    };


    const handleAddClick = () => {
        setShowDialog(true);
    };

    function handleCloseCancelDialog() {
        setShowCancelDialog(false);
    }


    async function handleConfirmCancel() {
        try {
            await interestExpressionWithdrawal(externalId);
            navigate(-1);
            setShowCancelDialog(false);
          } catch (error) {
            setError(error);
          }

    }


    const onPageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    if (loading) {
        return <Loading />;
    }

    if (error && isNotBusinessError(error)) {
        return handleError(error);
    }

    return (
        <SubTemplate hasBackButton={true} titleId={'interestExpressions.list'}>
            <div className='container'>
                <Row>
                    <Col md='12'>
                        {canCreate && (
                            <button
                                className='btn btn-outline-primary mb-2'
                                onClick={() => handleAddClick()}
                            >
                                <TiPlus className='mb-1' aria-hidden='true' />
                                <FormattedMessage id='interestExpressions.add' />
                            </button>
                        )
                        }


                        <AlertError error={error} className='mt-2' />

                        {Arrays.isNotEmpty(interestedExpressions) && (
                            <Row className='mt-5 mb-1 d-flex justify-content-between px-2'>
                                <Col className='text-muted text-capitalize'>
                                    {numberOfResults}
                                    <FormattedMessage
                                        id={`all.results.lowCase.${numberOfResults === 1 ? 'singular' : 'plural'
                                            }`}
                                    />
                                </Col>
                            </Row>
                        )}
                        {Arrays.isNotEmpty(interestedExpressions) ? (
                            <ul className='results'>
                                {interestedExpressions.map((interest) => (
                                    <li key={interest.entityNif}>
                                        <Row
                                            className='py-3'
                                        >
                                            <Col md='4' className='d-flex align-items-center'>
                                                {interest.entityName !== undefined ? (
                                                    <>{interest.entityName}</>) : (
                                                    <FormattedMessage id='interestExpressions.entities.all' />)}
                                            </Col>
                                            <Col md='4' className='d-flex align-items-center'>
                                                {interest.creationDate}
                                            </Col>
                                            <Col md='2' className='d-flex align-items-center'>
                                                {interest.deleted ?
                                                    <FormattedMessage id='interestExpressions.interest.withdraw' />
                                                    :
                                                    <FormattedMessage id='interestExpressions.interest.active' />}
                                            </Col>
                                            {canDelete && !interest.deleted && <Col md='2 py-0' className='text-end'>
                                                <button
                                                    className='btn btn-link'
                                                    type='button'
                                                    onClick={() => setShowCancelDialog(true)}
                                                >
                                                    <FormattedMessage id={'all.cancel'} />
                                                </button>
                                            </Col>}
                                        </Row>
                                    </li>
                                ))}
                            </ul>
                        ) : (
                            <EmptyResults />
                        )}
                    </Col>

                    <InterestExpressionDialog
                        show={showDialog}
                        handleClose={handleDialogClose}
                        handleSubmit={addInterestExpression}
                        possibleEntities={possibleEntities}
                    />

                    <DecisionDialog
                        titleId={'interestExpressions.modal.title'}
                        bodyId={'interestExpressions.modal.body'}
                        handleConfirm={handleConfirmCancel}
                        show={showCancelDialog}
                        handleClose={handleCloseCancelDialog}
                    />


                </Row>
                <Row className='mb-5'>
                    <Col className='d-flex'>
                        <div className='mx-2'>
                            <CustomPagination
                                dataPerPage={MAX_RESULTS}
                                totalData={totalResults}
                                onPageChange={onPageChange}
                                currentPage={currentPage}
                            />
                        </div>
                    </Col>
                </Row>
                {incubationDocuments.length > 0 &&
                    <Row>
                        <Col>
                            <Accordion className='mt-0 mb-3'>
                                <AccordionItem className='mt-0 mb-0' eventKey={'incubationDocuments'}>
                                    <AccordionHeader>
                                        <FormattedMessage id={'candidatureTabs.documentation.INCUBATION'} />
                                    </AccordionHeader>
                                    <AccordionBody>
                                        {incubationDocuments.map((d, index) => (
                                            <div key={index} className={'mb-3'}>
                                                <label className={'mb-0'}>
                                                    <FormattedMessage id={`documentType.show.${d.documentType}`} />
                                                </label>
                                                <small>{d.fileDescription === undefined ? '' : d.fileDescription + ' - '}</small>
                                                <AuthenticatedDownloadLink url={d.documentPath} filename={d.name}>
                                                    <small>{d.name}</small>
                                                </AuthenticatedDownloadLink>
                                                <small>
                                                    {d.candidatureState !== undefined && (<> (<FormattedMessage id={`candidatureState.${d.candidatureState}`} /> - {d.creationTs}) </>)}
                                                </small>
                                            </div>
                                        ))}
                                    </AccordionBody>
                                </AccordionItem>
                            </Accordion>
                        </Col>
                    </Row>
                }
            </div>
        </SubTemplate>
    );
}
