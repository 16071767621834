import {FormattedMessage} from "react-intl";
import {PaymentEntry} from "./PaymentEntry";
import React from "react";


export function PaymentList({payments}) {
    return (
        <table className='table d-table'>
            <thead>
            <tr>
                <th>
                    <label>
                        <FormattedMessage id='candidature.payment.list.dateText' />
                    </label>
                </th>
                <th>
                    <label>
                        <FormattedMessage id='candidature.payment.list.purposeText' />
                    </label>
                </th>

                <th>
                    <label>
                        <FormattedMessage id='candidature.payment.list.typeText' />
                    </label>
                </th>
                <th className='text-end'>
                    <label>
                        <FormattedMessage id='candidature.payment.list.valueText' />
                    </label>
                </th>
            </tr>
            </thead>
            <tbody>
            {payments.sort( (a, b) => new Date(a.date) - new Date(b.date)).map((p,index) => <PaymentEntry payment={p} key={index}/>)}
            </tbody>
        </table>
    );
}