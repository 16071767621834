
import React from 'react';
import {AddPaymentRequest} from "../../components/paymentRequest/AddPaymentRequest";
import {PaymentRequestType} from "../../components/paymentRequest/PaymentRequestType";
import {
	decideSemestralPaymentRequestByReport, deleteSemestralPaymentRequestDocument,
	getSemestralPaymentRequestByReport,
	listSemestralPaymentRequestDocuments,
	saveSemestralPaymentRequestByReport,
	submitSemestralPaymentRequestByReport, uploadSemestralPaymentRequestDocument
} from "../../rest/paymentRequest";


export function AddSemestralPaymentRequest() {
	return (
		<AddPaymentRequest
			paymentRequestType={PaymentRequestType.SEMESTRAL}
			getPaymentRequest={getSemestralPaymentRequestByReport}
			listPaymentRequestDocuments={listSemestralPaymentRequestDocuments}
			uploadDocument={uploadSemestralPaymentRequestDocument}
			deleteDocument={deleteSemestralPaymentRequestDocument}
			savePaymentRequest={saveSemestralPaymentRequestByReport}
			decidePaymentRequest={decideSemestralPaymentRequestByReport}
			submitPaymentRequest={submitSemestralPaymentRequestByReport}
		/>
	);
}