import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { useIntl } from 'react-intl';

export function SubmittedByEntity({ className, entityName, submissionDate }) {
	const intl = useIntl();

	return (
		<Row className={className}>
			<Col>
				<b>
					{intl.formatMessage(
						{
							id: 'all.submittedByEntityOnDate',
						},
						{
							entityName: entityName,
							submissionDate: submissionDate,
						}
					)}
				</b>
			</Col>
		</Row>
	);
}
