import React, { useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { TextInputField } from '../bootstrap/TextInputField';
import { TextAreaInputField } from '../bootstrap/TextAreaInputField';
import ReactStars from 'react-rating-stars-component';
import { MCESupportKind } from '../../models/MCESupportKind';

export function MCEInstallmentEA({
	step,
	numberOfSteps,
	errors,
	formikValues,
	handleChange,
	setFieldValue,
	handleGoBack,
	readMode,
}) {
	const intl = useIntl();

	const evaluationPriorSupportLabelIds = [
		{ id: 'supportNeedOnInvestmentArchiveCreation', isCheck: false },
		{ id: 'hadFundChanges', isCheck: true },
		{ id: 'projectGotMoreSustainable', isCheck: false },
		{ id: 'investmentElaborationOvercomeDegree', isCheck: false },
	];

	const postInterventionEvaluationTitleIds = [
		'attendanceevaluationTitle',
		'consultancyevaluationTitle',
		'skillevaluationTitle',
	];

	const postInterventionEvaluationSubSectionsLabelIds = {
		0: [
			{ id: 'followUpVisits', isCheck: false },
			{ id: 'investmentConfirmation', isCheck: false },
			{ id: 'otherFollowUpActivities', isCheck: false },
			{ id: 'followUpGlobalEvaluation', isCheck: false },
		],

		1: [
			{ id: 'requestedConsultingBeforeEaSugestion', isCheck: true },
			{ id: 'consultancyNecessity', isCheck: false },
			{ id: 'consultancyAdequacy', isCheck: false },
			{ id: 'consultancyDifficultiesOvercomeDegree', isCheck: false },
			{ id: 'consultancyGlobalEvaluation', isCheck: false },
		],
		2: [
			{ id: 'requestedExpansionBeforeEaSugestion', isCheck: true },
			{ id: 'expansionNecessity', isCheck: false },
			{ id: 'expansionInterventionAdequacy', isCheck: false },
			{ id: 'expansionDifficultiesOvercomeDegree', isCheck: false },
			{ id: 'expansionGlobalEvaluation', isCheck: false },
		],
	};

	function generateRows(labelIdsArray, evaluationTypeProperty) {
		return labelIdsArray.map((labelObj, index) => (
			<Row className='d-flex align-items-center mt-2' key={index}>
				<Col md='9'>
					<Form.Label className='mt-0'>
						<FormattedMessage id={`mceinstallmentea.${labelObj.id}`} />
					</Form.Label>
				</Col>
				{labelObj.isCheck ? (
					<Col md='3' className='d-flex justify-content-end'>
						<Form.Check
							inline
							type={'radio'}
							label={<FormattedMessage id='all.yes' />}
							checked={
								formikValues.evaluationEa[evaluationTypeProperty][
									labelObj.id
								] === true
							}
							onChange={() =>
								setFieldValue(
									`evaluationEa.${evaluationTypeProperty}.${labelObj.id}`,
									true
								)
							}
							isInvalid={
								errors.evaluationEa?.[evaluationTypeProperty]?.[labelObj.id]
							}
						/>
						<Form.Check
							className='mr-0'
							inline
							type={'radio'}
							label={<FormattedMessage id='all.no' />}
							checked={
								formikValues.evaluationEa[evaluationTypeProperty][
									labelObj.id
								] === false
							}
							onChange={() =>
								setFieldValue(
									`evaluationEa.${evaluationTypeProperty}.${labelObj.id}`,
									false
								)
							}
							isInvalid={
								errors.evaluationEa?.[evaluationTypeProperty]?.[labelObj.id]
							}
						/>
					</Col>
				) : (
					<Col md='3' className='d-flex justify-content-end'>
						<div
							className={`inline-block ${
								errors.evaluationEa?.[evaluationTypeProperty]?.[labelObj.id]
									? 'form-control is-invalid'
									: ''
							}`}
						>
							<ReactStars
								onChange={(e) =>
									setFieldValue(
										`evaluationEa.${evaluationTypeProperty}.${labelObj.id}`,
										e
									)
								}
								value={
									formikValues.evaluationEa[evaluationTypeProperty][labelObj.id]
								}
								count={5}
								size={24}
								activeColor='#eb4f24'
								edit={!readMode}
							/>
						</div>
					</Col>
				)}
			</Row>
		));
	}

	return (
		<div>
			<h4 className='text-primary mb-0'>
				<FormattedMessage id='eaevaluationform.mceinstallmentea' />
			</h4>
			<small className='text-secondary text-uppercase'>
				{intl.formatMessage(
					{ id: 'all.step' },
					{ step: step, numberOfSteps: numberOfSteps }
				)}
			</small>
			<fieldset disabled={readMode == true}>
				{formikValues.evaluationEa.type === MCESupportKind.PRIOR ? (
					<>
						<Row className='mt-4 mb-2'>
							<Col>
								<small className='text-uppercase text-primary'>
									<FormattedMessage id='mceinstallmentea.evaluationPriorSupport' />
								</small>
							</Col>
						</Row>
						{generateRows(
							evaluationPriorSupportLabelIds,
							'priorSupportEvaluation'
						)}
					</>
				) : (
					<>
						<Row className='mt-4 mb-2'>
							<Col>
								<small className='text-uppercase text-primary'>
									<FormattedMessage id='mceinstallmentea.approveevaluation' />
								</small>
							</Col>
						</Row>
						{postInterventionEvaluationTitleIds.map((titleId, index) => (
							<React.Fragment key={titleId}>
								<Row className='mt-4 mb-1'>
									<Col>
										<small className='text-uppercase text-secondary'>
											<FormattedMessage id={`mceinstallmentea.${titleId}`} />
										</small>
									</Col>
								</Row>
								{generateRows(
									postInterventionEvaluationSubSectionsLabelIds[index],
									'postProjectApprovalEvaluation'
								)}
							</React.Fragment>
						))}
					</>
				)}
				<Row className='mt-3 mb-1'>
					<Col>
						<small className='text-uppercase text-secondary'>
							<FormattedMessage id='mceinstallmentea.globalevaluationTitle' />
						</small>
					</Col>
				</Row>
				<Row>
					<Col md='9'>
						<Form.Label className='mt-0'>
							<FormattedMessage id='mceinstallmentea.globalevaluationLabel' />
						</Form.Label>
					</Col>
					<Col md='3' className='d-flex justify-content-end'>
						<div
							className={
								errors.evaluationEa?.globalEvaluation
									? 'form-control is-invalid'
									: ''
							}
						>
							<ReactStars
								onChange={(e) =>
									setFieldValue(`evaluationEa.globalEvaluation`, e)
								}
								value={formikValues.evaluationEa.globalEvaluation}
								count={5}
								size={24}
								activeColor='#eb4f24'
								edit={!readMode}
							/>
						</div>
					</Col>
				</Row>
				<Row className='mt-4 mb-2'>
					<Col>
						<small className='text-uppercase text-primary'>
							<FormattedMessage id='mceinstallmentea.globalopinion' />
						</small>
					</Col>
				</Row>
				<Row>
					<Col md='9'>
						<Form.Label className='mt-0'>
							<FormattedMessage id='mceinstallmentea.globalopinionLabel' />
						</Form.Label>
					</Col>
					<Col className='d-flex justify-content-end'>
						<Form.Check
							inline
							type={'radio'}
							label={<FormattedMessage id='all.yes' />}
							checked={formikValues.evaluationEa.wouldReaskEaServices === true}
							onChange={() =>
								setFieldValue(`evaluationEa.wouldReaskEaServices`, true)
							}
							isInvalid={errors.evaluationEa?.wouldReaskEaServices}
						/>
						<Form.Check
							className='mr-0'
							inline
							type={'radio'}
							label={<FormattedMessage id='all.no' />}
							checked={formikValues.evaluationEa.wouldReaskEaServices === false}
							onChange={() =>
								setFieldValue(`evaluationEa.wouldReaskEaServices`, false)
							}
							isInvalid={errors.evaluationEa?.wouldReaskEaServices}
						/>
					</Col>
				</Row>

				<Row className='mt-4 mb-2'>
					<Col>
						<small className='text-uppercase text-primary'>
							<FormattedMessage id='mceinstallmentea.observations' />
						</small>
					</Col>
				</Row>
				<Row>
					<Col>
						<TextAreaInputField
							name='evaluationEa.observations'
							value={formikValues.evaluationEa.observations}
							handleChange={handleChange}
							isInvalid={errors.evaluationEa?.observations}
							errorMessage={errors.evaluationEa?.observations}
							placeholder={intl.formatMessage({
								id: 'mceinstallmentea.observations.placeholder',
							})}
						/>
					</Col>
				</Row>
				<Row>
					<Col>
						<TextInputField
							labelId='mceinstallmentea.companyResponsible'
							disabled={true}
							value={formikValues.company.personInChargeName}
						/>
					</Col>
				</Row>
			</fieldset>
			<div className='form-actions mt-5'>
				<button
					className='btn btn-link'
					type='button'
					onClick={() => handleGoBack()}
				>
					<FormattedMessage id='all.back' />
				</button>
			</div>
		</div>
	);
}
