import {FormattedMessage} from "react-intl";
import {AuthenticatedDownloadLink} from "../../document/AuthenticatedDownloadLink";
import React from "react";

export function DocumentTable({documents}) {
    return <table className={'table d-table'}>
        <thead>
        <tr>
            <th><label><FormattedMessage id={'all.type'}/></label></th>
            <th><label><FormattedMessage id={'all.description'}/></label></th>
            <th className={'text-end'}><label></label></th>
        </tr>
        </thead>
        <tbody>
        {documents.map(((doc) => (
            <tr key={doc.externalId}>
                <td className={'small'}><FormattedMessage id={`documentType.${doc.documentType}`}/></td>
                <td>{doc.fileDescription}</td>
                <td className={'text-end'}>
                    <AuthenticatedDownloadLink
                        url={doc.documentPath}
                        filename={doc.name}
                    >
                        {doc.name}
                    </AuthenticatedDownloadLink>
                </td>
            </tr>
        )))}
        </tbody>
    </table>
}