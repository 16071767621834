import React, {useEffect, useState} from 'react';
import { Col,  Row } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import DecimalInputField from '../bootstrap/DecimalInputField';
import { TextAreaInputField } from '../bootstrap/TextAreaInputField';
import ToolTip from '../bootstrap/ToolTip';
import {SelectBooleanField} from "../bootstrap/SelectBooleanField";
import {CandidatureFinancialUtils} from "../../utils/CandidatureFinancialUtils";
import {getActionNotification} from "../../rest/templates";

export function EconomicViabilityFormStep5({
  handleChange,
  setFieldValue,
  errors,
  values,
  error,
  setError,
  numberOfSteps,
  handleSave,
  handleGoBack,
                                             context
}) {
  const intl = useIntl();

  const [valueAfterValidationSubRepresentation,setValueAfterValidationSubRepresentation] = useState(getValueAfterValidationSubRepresentation());
  const [valueAfterValidationInnovation,setValueAfterValidationInnovation] = useState(getValueAfterValidationInnovation());
  const [valueAfterValidationInside,setValueAfterValidationInside] = useState(getValueAfterValidationInside());

  const  [otherDetails,setOtherDetails] =  useState(getOtherDetails());


  const fetchData = async () => {
    if(((values.economicViabilityAccretionValidation.isLevelsValid ===true ) && context!=='opinion')){
      console.log(otherDetails.isLevelsValid)
      values.economicViabilityAccretionValidation.levelsValue=  CandidatureFinancialUtils.getLevelsAccretion(otherDetails)
      setFieldValue('economicViabilityAccretionValidation', values.economicViabilityAccretionValidation);
    }
    if((values.economicViabilityAccretionValidation.isIefpWorkstationsValid ===true && context!=='opinion')){
      values.economicViabilityAccretionValidation.iefpWorkstationsValue=  CandidatureFinancialUtils.getIefpAccretion(otherDetails)
      setFieldValue('economicViabilityAccretionValidation', values.economicViabilityAccretionValidation);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);



  function getOtherDetails (){
    return Array.isArray(values.candidatureEmployeeSupportOtherDetails) ? values.candidatureEmployeeSupportOtherDetails[0] : values.candidatureEmployeeSupportOtherDetails
  }

  function handleChangeAccretionValidation(name,  value) {
    values.economicViabilityAccretionValidation[name]= value;

    setFieldValue('economicViabilityAccretionValidation', values.economicViabilityAccretionValidation);

    switch (name) {
      case 'isSubRepresentationValid':
        setValueAfterValidationSubRepresentation(getValueAfterValidationSubRepresentation())
        break;
      case 'isInnovationProjectValid':
        setValueAfterValidationInnovation(getValueAfterValidationInnovation())
            break;
      case 'isInsideValid':
        setValueAfterValidationInside(getValueAfterValidationInside());
        break;
      case 'isLevelsValid':

        values.economicViabilityAccretionValidation.levelsValue=     (value ===true && context!=='opinion') ? CandidatureFinancialUtils.getLevelsAccretion(otherDetails): 0;
        break;
      case 'isIefpWorkstationsValid':
        values.economicViabilityAccretionValidation.iefpWorkstationsValue=(value ===true && context!=='opinion') ? CandidatureFinancialUtils.getIefpAccretion(otherDetails) : 0;;
        break;
    }
    setFieldValue('economicViabilityAccretionValidation', values.economicViabilityAccretionValidation);
  }




  function  getValueAfterValidationSubRepresentation() {
    if(values.economicViabilityAccretionValidation.isSubRepresentationValid===true) {
      return '15%';
    }else if(values.economicViabilityAccretionValidation.isSubRepresentationValid===false){
      return '0%';
    }
    return '-';
  }


  function getValueAfterValidationInside (){
    if(values.economicViabilityAccretionValidation.isInsideValid===true) {
      return '25%';
    }else if(values.economicViabilityAccretionValidation.isInsideValid===false) {
      return '0%';
    }
      return '-';


  }



  function getValueAfterValidationInnovation(){
    if(values.economicViabilityAccretionValidation.isInnovationProjectValid===true) {
      return '15%';
    }else if(values.economicViabilityAccretionValidation.isInnovationProjectValid===false){
      return '0%';
    }
      return '-';


  }

  return (
    <div>
      <h4 className='text-primary mb-0'>
        <FormattedMessage id='economicViabilityForm.accretionTab' />
      </h4>
      <small className='text-secondary text-uppercase d-block'>
        {intl.formatMessage(
          { id: 'all.step' },
          { step: values.step, numberOfSteps: numberOfSteps }
        )}
      </small>
        <Row>
          <Col md= '9'>


            <SelectBooleanField
                name={'isSubRepresentationValid'}
                labelId='candidatureForm.accretion.isSubRepresentationValid'
                value={values.economicViabilityAccretionValidation.isSubRepresentationValid}

                setFieldValue={(name,value) => handleChangeAccretionValidation('isSubRepresentationValid', value)}
                isInvalid={ errors.economicViabilityAccretionValidation?.isSubRepresentationValid}
                errorMessage={errors.economicViabilityAccretionValidation?.isSubRepresentationValid}
                nullOption={true}
                trueLabelId={'all.applicable'}
                falseLabelId={'all.not.applicable'}
                toolTip={<ToolTip message={<FormattedMessage id={'candidatureForm.accretion.isSubRepresentationValid.tooltip'}/>}/>}
            />




          </Col>
          <Col md= '3'>
            <label className='form-label text-center'><FormattedMessage id={'candidatureForm.accretion'}/></label>
            <p className='text-center' className='text-center'>{ valueAfterValidationSubRepresentation}</p>
          </Col>
        </Row>
      <hr className='mt-5'/>

      <Row>
        <Col md= '9'>


          <SelectBooleanField
              name={'isInnovationProjectValid'}
              labelId='candidatureForm.accretion.isInnovationProjectValid'
              value={values.economicViabilityAccretionValidation.isInnovationProjectValid}
              setFieldValue={(name,value) => handleChangeAccretionValidation('isInnovationProjectValid', value)}
              isInvalid={ errors.economicViabilityAccretionValidation?.isInnovationProjectValid}
              errorMessage={errors.economicViabilityAccretionValidation?.isInnovationProjectValid}
              nullOption={true}
              toolTip={<ToolTip message={<FormattedMessage id={'candidatureForm.accretion.isInnovationProjectValid.tooltip'}/>}/>}
              trueLabelId={'all.applicable'}
              falseLabelId={'all.not.applicable'}
          />




        </Col>
        <Col md= '3'>
          <label className='form-label text-center'><FormattedMessage id={'candidatureForm.accretion'}/></label>
          <p className='text-center'>{ valueAfterValidationInnovation}</p>
        </Col>
      </Row>

      <hr className='mt-5'/>

      <Row>
        <Col md= '9'>

          <SelectBooleanField
              name={'isLevelsValid'}
              labelId='candidatureForm.accretion.isLevelsValid'
              value={values.economicViabilityAccretionValidation.isLevelsValid}
              setFieldValue={(name,value) => handleChangeAccretionValidation('isLevelsValid', value)}
              isInvalid={ errors.economicViabilityAccretionValidation?.isLevelsValid}
              errorMessage={errors.economicViabilityAccretionValidation?.isLevelsValid}
              nullOption={true}
              toolTip={<ToolTip message={<FormattedMessage id={'candidatureForm.accretion.isLevelsValid.tooltip'}/>}/>}
              trueLabelId={'all.applicable'}
              falseLabelId={'all.not.applicable'}
          />




        </Col>

          <Col md='3'>
            <p className='text-center'>
              <DecimalInputField
                  labelId={'candidatureForm.accretion'}
                  controlId={'levelsValue'}
                  name={'levelsValue'}
                  handleChange={(e) => handleChangeAccretionValidation('levelsValue', e.target.value)}
                  value={values.economicViabilityAccretionValidation.levelsValue}
                  placeHolder={0}
                  maxValue={15}
                  suffixLabel={'%'}
                  isInvalid={errors.economicViabilityAccretionValidation?.levelsValue}
                  errorMessage={errors.economicViabilityAccretionValidation?.levelsValue}
                  disabled={values.economicViabilityAccretionValidation.isLevelsValid!==true || context !='opinion'}

              />
            </p>
          </Col>





      </Row>
      <hr className='mt-5'/>

      <Row>
        <Col md= '9'>


          <SelectBooleanField
              name={'isInsideValid'}
              labelId='candidatureForm.accretion.isInsideValid'
              value={values.economicViabilityAccretionValidation.isInsideValid}

              setFieldValue={(name,value) => handleChangeAccretionValidation('isInsideValid', value)}
              isInvalid={ errors.economicViabilityAccretionValidation?.isInsideValid}
              errorMessage={errors.economicViabilityAccretionValidation?.isInsideValid}
              nullOption={true}
              toolTip={<ToolTip message={<FormattedMessage id={'candidatureForm.accretion.isInsideValid.tooltip'}/>}/>}
              trueLabelId={'all.applicable'}
              falseLabelId={'all.not.applicable'}
          />




        </Col>
        <Col md= '3'>
          <label className='form-label text-center'><FormattedMessage id={'candidatureForm.accretion'}/></label>
          <p className='text-center' className='text-center'>{ valueAfterValidationInside}</p>
        </Col>
      </Row>
      <hr className='mt-5'/>


      <Row>
        <Col md= '9'>

          <SelectBooleanField
              name={'isIefpWorkstationsValid'}
              labelId='candidatureForm.accretion.isIefpWorkstationsValid'
              value={values.economicViabilityAccretionValidation.isIefpWorkstationsValid}
              setFieldValue={(name,value) => handleChangeAccretionValidation('isIefpWorkstationsValid', value)}
              isInvalid={ errors.economicViabilityAccretionValidation?.isIefpWorkstationsValid}
              errorMessage={errors.economicViabilityAccretionValidation?.isIefpWorkstationsValid}
              nullOption={true}
              toolTip={<ToolTip message={<FormattedMessage id={'candidatureForm.accretion.isIefpWorkstationsValid.tooltip'}/>}/>}
              trueLabelId={'all.applicable'}
              falseLabelId={'all.not.applicable'}
          />




        </Col>


          <Col md='3'>
            <p className='text-center'>
              <DecimalInputField
                  labelId={'candidatureForm.accretion'}
                  controlId={'iefpWorkstationsValue'}
                  name={'iefpWorkstationsValue'}
                  handleChange={(e) => handleChangeAccretionValidation('iefpWorkstationsValue', e.target.value)}
                  value={values.economicViabilityAccretionValidation.iefpWorkstationsValue}
                  placeHolder={0}
                  maxValue={30}
                  suffixLabel={'%'}
                  isInvalid={errors.economicViabilityAccretionValidation?.iefpWorkstationsValue}
                  errorMessage={errors.economicViabilityAccretionValidation?.iefpWorkstationsValue}
                  disabled={values.economicViabilityAccretionValidation.isIefpWorkstationsValid !== true || context !='opinion'}
              />
            </p>
          </Col>


      </Row>




    </div>

);
}
