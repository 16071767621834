export const AdditionalActivityType = {
  BOOTCAMP: 'BOOTCAMP',
  DISCLOSURE: 'DISCLOSURE',
  DIDACTIC: 'DIDACTIC',

  values: () => {
    return [
      'BOOTCAMP',
      'DISCLOSURE',
      'DIDACTIC'
    ];
  },
};