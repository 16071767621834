import { Formik } from 'formik';
import React from 'react';
import { Form } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import * as yup from 'yup';
import { CandidatureState } from '../../models/CandidatureState';
import { CandidatureFormUtils } from '../../utils/CandidatureFormUtils';
import { Validator } from '../../utils/Validator';
import { CandidatureFormStep1 } from './CandidatureFormStep1';
import { CandidatureFormStep12 } from './CandidatureFormStep12';
import { CandidatureFormStep13 } from './CandidatureFormStep13';
import { CandidatureFormStep14 } from './CandidatureFormStep14';
import { CandidatureFormStep17 } from './CandidatureFormStep17';
import { CandidatureFormStep3 } from './CandidatureFormStep3';
import { CandidatureFormStep18 } from './CandidatureFormStep18';
import { CandidatureFormStep19} from './CandidatureFormStep19';
import { CandidatureFormStep20 } from './CandidatureFormStep20';
import { CandidatureFormStep2 } from './CandidatureFormStep2';
import { CandidatureFormStep21 } from './CandidatureFormStep21';
import { CandidatureFormStep5Test } from './CandidatureFormStep5Test';
import { CandidatureFormStep6Test } from './CandidatureFormStep6Test';
import { CandidatureFormStep7Test } from './CandidatureFormStep7Test';
import { CandidatureFormStep8Test } from './CandidatureFormStep8Test';
import { CandidatureFormStep9Test } from './CandidatureFormStep9Test';
import { CandidatureFormStep10Test } from './CandidatureFormStep10Test';
import { CandidatureFormStep11 } from './CandidatureFormStep11';
import { CandidatureFormStepForeCastDemonstration } from './CandidatureFormStepForeCastDemonstration';
import { CandidatureFormStepOtherCharge } from './CandidatureFormStepOtherCharge';
import {isValidCae} from "../../rest/candidature";
import {CandidatureFormStep4} from "./CandidatureFormStep4";

export function CandidatureForm({
  candidature,
  districts,
  counties,
  parishes,
  companyTypologies,
  documentTypes,
  candidatureDocuments,
  setCandidatureDocuments,
  entityDetails,
  formikPropsRef,
  handleDistrictChange,
  handleCountyChange,
  handleGoBack,
  error,
  setError,
  handleSubmit,
  handleSave,
  numberOfSteps
}) {

  candidature.step8Documents = CandidatureFormUtils.getStep8Documents(
    candidature,
    candidatureDocuments
  );

  candidature.step19Documents = CandidatureFormUtils.getStep19Documents(
    candidature,
    candidatureDocuments
  );

  candidature.step10Documents = getStep10Documents(candidatureDocuments);

  candidature.step18Documents = getStep18Documents(candidatureDocuments)

  return (
    <div className='mb-5'>
      <Formik
        initialValues={candidature}
        validateOnBlur={false}
        validateOnChange={false}
        validationSchema={candidatureFormSchema}>
        {(formikProps) => (
          <Form onSubmit={formikProps.handleSubmit}>
            <fieldset
              disabled={entityDetails?.isEntity || (candidature.state !== CandidatureState.DRAFT && candidature.state !== CandidatureState.WITHDRAW) || (candidature.state === CandidatureState.WITHDRAW && candidature.step===2)}>
              {renderStep(
                { ...formikProps },
                districts,
                counties,
                parishes,
                companyTypologies,
                documentTypes,
                candidatureDocuments,
                setCandidatureDocuments,
                formikPropsRef,
                handleDistrictChange,
                handleCountyChange,
                handleSubmit,
                handleSave,
                handleGoBack,
                error,
                setError,
                numberOfSteps,
                candidature.step
              )}
            </fieldset>
          </Form>
        )}
      </Formik>
    </div>
  );
}

function renderStep(
  props,
  districts,
  counties,
  parishes,
  companyTypologies,
  documentTypes,
  candidatureDocuments,
  setCandidatureDocuments,
  formikPropsRef,
  handleDistrictChange,
  handleCountyChange,
  handleSubmit,
  handleSave,
  handleGoBack,
  error,
  setError,
  numberOfSteps,
  currentStep
) {
  props.values.step = currentStep;
  formikPropsRef.current = props;

  switch (props.values.step) {
    case 1:
      return (
        <CandidatureFormStep1
          {...props}
          values={props.values}
          error={error}
          setError={setError}
          numberOfSteps={numberOfSteps}
          handleSubmit={handleSubmit}
          handleSave={handleSave}
        />
      );
    case 2:
      return (
        <CandidatureFormStep2
          {...props}
          values={props.values}
          districts={districts}
          counties={counties}
          parishes={parishes}
          companyTypologies={companyTypologies}
          onDistrictChange={handleDistrictChange}
          onCountyChange={handleCountyChange}
          handleSubmit={handleSubmit}
          handleSave={handleSave}
          handleGoBack={handleGoBack}
          numberOfSteps={numberOfSteps}
          setError={setError}
          candidatureDocuments={candidatureDocuments}
          formikPropsRef = {formikPropsRef}
        />
      );
    case 4:
      return (
          <CandidatureFormStep4
              {...props}
              values={props.values}
              handleSubmit={handleSubmit}
              handleSave={handleSave}
              handleGoBack={handleGoBack}
              numberOfSteps={numberOfSteps}
              setError={setError}
          />
      );
    case 5:
      return (
        <CandidatureFormStep5Test
          {...props}
          values={props.values}
          handleSubmit={handleSubmit}
          handleSave={handleSave}
          handleGoBack={handleGoBack}
          numberOfSteps={numberOfSteps}
          setError={setError}
          isValidate={false}
        />
      );
    case 6:
      return (
        <CandidatureFormStep6Test
          {...props}
          values={props.values}
          handleSubmit={handleSubmit}
          handleSave={handleSave}
          handleGoBack={handleGoBack}
          numberOfSteps={numberOfSteps}
          setError={setError}
          isValidate={false}
        />
      );
    case 7:
      return (
        <CandidatureFormStep7Test
          {...props}
          values={props.values}
          handleSave={handleSave}
          handleGoBack={handleGoBack}
          numberOfSteps={numberOfSteps}
        />
      );
    case 8:
      return (
        <CandidatureFormStep8Test
          {...props}
          values={props.values}
          handleSave={handleSave}
          handleGoBack={handleGoBack}
          numberOfSteps={numberOfSteps}
        />
      );
    case 9:
      return (
        <CandidatureFormStep9Test
          {...props}
          values={props.values}
          handleSave={handleSave}
          handleGoBack={handleGoBack}
          numberOfSteps={numberOfSteps}
        />
      );
    case 10:
      return (
        <CandidatureFormStep10Test
          {...props}
          values={props.values}
          handleSave={handleSave}
          handleGoBack={handleGoBack}
          numberOfSteps={numberOfSteps}
        />
      );

    case 11:
      return (
        <CandidatureFormStep11
          {...props}
          values={props.values}
          handleSave={handleSave}
          handleGoBack={handleGoBack}
          numberOfSteps={numberOfSteps}
        />
      );

    case 12:
      return (
        <CandidatureFormStep12
          {...props}
          values={props.values}
          handleSave={handleSave}
          handleGoBack={handleGoBack}
          numberOfSteps={numberOfSteps}
        />
      );

    case 13:
      return (
        <CandidatureFormStep13
          {...props}
          values={props.values}
          handleSave={handleSave}
          handleGoBack={handleGoBack}
          numberOfSteps={numberOfSteps}
        />
      );

    case 14:
      return (
        <CandidatureFormStep14
          {...props}
          values={props.values}
          handleSave={handleSave}
          handleGoBack={handleGoBack}
          numberOfSteps={numberOfSteps}
          setFieldValue={props.setFieldValue}
        />
      );

    case 15:
      return (
        <CandidatureFormStepOtherCharge
          {...props}
          values={props.values}
          handleSave={handleSave}
          handleGoBack={handleGoBack}
          numberOfSteps={numberOfSteps}
        />
      );

    case 16:
      return (
        <CandidatureFormStepForeCastDemonstration
          {...props}
          values={props.values}
          handleSave={handleSave}
          handleGoBack={handleGoBack}
          numberOfSteps={numberOfSteps}
        />
      );

    case 17:
      return (
        <CandidatureFormStep17
          errors={props.errors}
          setFieldValue={props.setFieldValue}
          handleChange={props.handleChange}
          values={props.values}
          handleSave={handleSave}
          handleGoBack={handleGoBack}
          numberOfSteps={numberOfSteps}
        />
      );

    case 3:
      return (
        <CandidatureFormStep3
          {...props}
          values={props.values}
          handleSubmit={handleSubmit}
          handleSave={handleSave}
          handleGoBack={handleGoBack}
          numberOfSteps={numberOfSteps}
          setError={setError}
          isValidate={false}
        />
      );

    case 18:
      return (
        <CandidatureFormStep18
          {...props}
          values={props.values}
          candidatureDocuments={candidatureDocuments}
          handleSave={handleSave}
          handleGoBack={handleGoBack}
          numberOfSteps={numberOfSteps}
          setCandidatureDocuments={setCandidatureDocuments}
        />
      );

    case 19:
      return (
        <CandidatureFormStep19
          {...props}
          values={props.values}
          documentTypes={documentTypes}
          candidatureDocuments={candidatureDocuments}
          setCandidatureDocuments={setCandidatureDocuments}
          handleSave={handleSave}
          handleGoBack={handleGoBack}
          setError={setError}
          numberOfSteps={numberOfSteps}
        />
      );
    // return (
    //   // <CandidatureFormStep18
    //   //   {...props}
    //   //   values={props.values}
    //   //   candidatureDocuments={candidatureDocuments}
    //   //   setCandidatureDocuments={setCandidatureDocuments}
    //   //   handleSave={handleSave}
    //   //   handleGoBack={handleGoBack}
    //   //   setError={setError}
    //   //   numberOfSteps={numberOfSteps}
    //   // />
    // );

    case 20:
      return (
        <CandidatureFormStep20
          {...props}
          values={props.values}
          handleGoBack={handleGoBack}
          numberOfSteps={numberOfSteps}
          handleSave={handleSave}
        />
      );

    case 21:
      return (
        <CandidatureFormStep21
          errors={props.errors}
          handleChange={props.handleChange}
          setFieldValue={props.setFieldValue}
          values={props.values}
          handleGoBack={handleGoBack}
          numberOfSteps={numberOfSteps}
        />
      );

    default:
      return (
        <CandidatureFormStep1
          {...props}
          values={props.values}
          handleSubmit={handleSubmit}
          handleSave={handleSave}
          setError={setError}
          numberOfSteps={numberOfSteps}
        />
      );
  }
}

function getStep18Documents(candidatureDocuments) {
  let filesToReturn = [];

  const filteredDocuments = candidatureDocuments.filter(
      (cd) =>
          cd.documentType === 'CANDIDATURE_SUPPORT'
  );

  filteredDocuments.forEach((fd) => {
    filesToReturn.push({
      externalId: fd.externalId,
      documentType: fd.documentType,
      documentPath: fd.documentPath,
      name: fd.name,
      fileDescription: fd.fileDescription,
      content: '',
      submited: true,
      error: ''
    });
  });

  return filesToReturn;
}



function getStep10Documents(candidatureDocuments) {
  let filesToReturn = [];

  const filteredDocuments = candidatureDocuments.filter(
    (cd) =>
      cd.documentType === 'REGULARIZED_STATUS_DECLARATION' ||
      cd.documentType === 'SOCIAL_REGULARIZED_STATUS_DECLARATION'
  );

  filteredDocuments.forEach((fd) => {
    filesToReturn.push({
      externalId: fd.externalId,
      documentType: fd.documentType,
      documentPath: fd.documentPath,
      name: fd.name,
      fileDescription: fd.fileDescription,
      content: '',
      submited: true,
      error: ''
    });
  });

  return filesToReturn;
}

const candidatureFormSchema = yup.object().shape({
  veracityDeclaration: yup
    .boolean()
    .test(
      'hasVeracityDeclaration',
      <FormattedMessage id='errors.candidatureForm.veracityDeclarations' />,
      (value) => value === true
    ),
  promotersMetaData: yup.array().of(
    yup.object().shape({
      name: yup.string().required(<FormattedMessage id='errors.fieldRequiredText' />),
      nif: yup
        .string()
        .required(<FormattedMessage id='errors.fieldRequiredText' />)
        .test('isNifValid', <FormattedMessage id='errors.promoterForm.nif' />, (value) =>
          Validator.validateNif(value)
        ),
      email: yup
        .string()
        .required(<FormattedMessage id='errors.fieldRequiredText' />)
        .test('isEmailValid', <FormattedMessage id='errors.candidatureForm.email' />, (value) =>
          Validator.validateEmail(value)
        ),



      promoterType: yup
          .string()
          .test(
              'isValidPromoterType',
              <FormattedMessage id='errors.fieldRequiredText' />,
              (value) => value !== '-1' && typeof value !== 'undefined'
          ),
      gender: yup.string().when('promoterType',{
        is: (val) => val !== undefined && val!=='-1' && val !=='OTHER' && val !== null,
        then : yup.string().test(
            'isValidGender',
            <FormattedMessage id='errors.promoterForm.gender' />,
            (value) =>  value !== '-1' && typeof value !== 'undefined' && value!== null,

        ).nullable()
      }).nullable(),
      qualificationLevel: yup.string().when('promoterType',{
        is: (val) => val !== undefined && val!=='-1' && val !=='OTHER' && val !== null,
        then : yup.string().test(
            'isValidQualificationLevel',
            <FormattedMessage id='errors.promoterForm.qualificationLevel' />,
            (value) =>  value !== '-1' && typeof value !== 'undefined' && value!== null,

        ).nullable()
      }).nullable(),

      applyUnemploymentBenefits: yup
        .mixed()
        .test(
          'isValidApplyUnemploymentBenefits',
          <FormattedMessage id='errors.fieldRequiredText' />,
          (value) => value !== '-1' && typeof value !== 'undefined'
        )
    })
  ),

  district: yup
    .string()
    .test(
      'isValidDistrict',
      <FormattedMessage id='errors.promoterForm.district' />,
      (value) => value !== '-1' && typeof value !== 'undefined'
    ),

  county: yup
    .string()
    .test(
      'isValidCounty',
      <FormattedMessage id='errors.promoterForm.county' />,
      (value) => value !== '-1' && typeof value !== 'undefined'
    ),

  parish: yup
    .string()
    .test(
      'isValidParish',
      <FormattedMessage id='errors.promoterForm.parish' />,
      (value) => value !== '-1' && typeof value !== 'undefined'
    ),

  candidatureDetails: yup.object().shape({
    companyTypology: yup
      .string()
      .test(
        'isValidCompanyTypology',
        <FormattedMessage id='errors.fieldRequiredText' />,
        (value) => value !== '-1' && typeof value !== 'undefined'
      ),
    projectCharacterization: yup
      .string()
      .required(<FormattedMessage id='errors.fieldRequiredText' />),

    targetMarket: yup.string().required(<FormattedMessage id='errors.fieldRequiredText' />),

    installations: yup.string().required(<FormattedMessage id='errors.fieldRequiredText' />),

    possibleIncentives: yup.string().required(<FormattedMessage id='errors.fieldRequiredText' />),

    artsAndCrafts: yup
      .mixed()
      .test(
        'isValidArtsAndCrafts',
        <FormattedMessage id='errors.fieldRequiredText' />,
        (value) => value !== '-1' && typeof value !== 'undefined'
      ),
  wasCreated: yup
        .mixed()
        .test(
            'isValidWasCreated',
            <FormattedMessage id='errors.fieldRequiredText' />,
            (value) => value !== '-1' && typeof value !== 'undefined'
        ),
    registerLink: yup.string().when('wasCreated',{
      is: (val) => val !== undefined && val!=='-1' && val !==false && val !== null,
      then : yup.string().test(
          'isValidEmptyLink',
          <FormattedMessage id='errors.candidatureForm.registerLink' />,
          (value) =>  value !== '-1' && typeof value !== 'undefined' && value!== null,

      ).nullable()
}).nullable(),


    cae: yup
      .mixed()
      .test(
        'isValidCae',
        <FormattedMessage id='errors.candidatureForm.caeNeedDigits' />,
        (value) => new RegExp(/^\d{5}$/).test(value) && typeof value !== 'undefined'
      ).test(
            'isCaeOnDb',
            <FormattedMessage id='errors.candidatureForm.caeNotOnDB' />,
            async (value) => {
              const { data: valid } = await isValidCae(value);
              return valid.flag;
            }
        ),
    investmentPlanReasoning: yup
      .string()
      .required(<FormattedMessage id='errors.fieldRequiredText' />),

    financialInvestmentReasoning: yup
      .string()
      .required(<FormattedMessage id='errors.fieldRequiredText' />),

    forecastDate: yup
        .date()
        .required(<FormattedMessage id='errors.fieldRequiredText'/>)
        .transform((v) => (v instanceof Date && !isNaN(v) ? v : undefined))
        .when('wasCreated', {
          is: (val) => val !== undefined && val !== '-1' && val !== false && val !== null,
          then: yup.date().test(
              'isValidRegisterLink',
              <FormattedMessage id='errors.candidatureForm.forecastDate180'/>,
              (value) => {
                const currentDate = new Date();
                currentDate.setHours(0, 0, 0, 0);
                currentDate.setDate(currentDate.getDate()-181);
                const currentDate2 = new Date();
                return value && (value.getTime() > currentDate.getTime()) && currentDate2> value.getTime() ;

              },
          ).nullable(),
          otherwise: yup.date().test(
              'isValidForecastDate',
              <FormattedMessage id='errors.candidatureForm.forecastDate'/>,
              (value) => {
                const currentDate = new Date();
                currentDate.setHours(0, 0, 0, 0);
                return value && value.getTime() > currentDate.getTime();
              }
          )
        }).nullable(),

  }),

  candidatureFinancialDetails: yup.object().shape({
    investmentProperties: yup.number().required(<FormattedMessage id='errors.fieldRequiredText' />),
    tangibleAssets: yup.number().required(<FormattedMessage id='errors.fieldRequiredText' />),
    intangibleAssets: yup.number().required(<FormattedMessage id='errors.fieldRequiredText' />),
    several: yup.number().required(<FormattedMessage id='errors.fieldRequiredText' />),
    pettyCash: yup.number().required(<FormattedMessage id='errors.fieldRequiredText' />),
    socialCapital: yup.number().required(<FormattedMessage id='errors.fieldRequiredText' />),
    unemploymentBenefits: yup.number().required(<FormattedMessage id='errors.fieldRequiredText' />),
    otherBenefits: yup.number().required(<FormattedMessage id='errors.fieldRequiredText' />),
    bankLoans: yup.number().required(<FormattedMessage id='errors.fieldRequiredText' />),
    partnerLoans: yup.number().required(<FormattedMessage id='errors.fieldRequiredText' />),
    supplierCredits: yup.number().required(<FormattedMessage id='errors.fieldRequiredText' />),
    otherForeignCapital: yup.number().required(<FormattedMessage id='errors.fieldRequiredText' />),
    investmentSupport: yup.number().required(<FormattedMessage id='errors.fieldRequiredText' />),
    // applyNonRefundableSubsidy: yup
    //   .mixed()
    //   .test(
    //     'isValidApplyNonRefundableSubsidy',
    //     <FormattedMessage id='errors.fieldRequiredText' />,
    //     (value) => {
    //       console.log('value', value)
    //       return value !== '-1' && typeof value !== 'undefined';
    //     }
    //   ),
    interestFreeLoan: yup.number().required(<FormattedMessage id='errors.fieldRequiredText' />),
    nonRefundableSubsidy: yup.number().required(<FormattedMessage id='errors.fieldRequiredText' />)
  }),

  otherIncentives: yup.array().of(
    yup.object().shape({
      supportOrganization: yup
        .string()
        .required(<FormattedMessage id='errors.fieldRequiredText' />),
      organism: yup.string().required(<FormattedMessage id='errors.fieldRequiredText' />),
      dispatchDate: yup
        .date()
        .required(<FormattedMessage id='errors.fieldRequiredText' />)
        .transform((v) => (v instanceof Date && !isNaN(v) ? v : undefined))
    })
  ),

  sellInformationList: yup.array().of(
    yup.object().shape({
      reasonToSell: yup.mixed().when('nonApplicable', {
        is: (nonApplicable) => nonApplicable === false || typeof nonApplicable === 'undefined',
        then: yup.string().required(<FormattedMessage id='errors.fieldRequiredText' />)
      }),
      sellInformationLines: yup.array().of(
        yup.object().shape({
          designation: yup.string().required(<FormattedMessage id='errors.fieldRequiredText' />),
          unit: yup.string().required(<FormattedMessage id='errors.fieldRequiredText' />)
        })
      )
    })
  ),

  provideInformationList: yup.array().of(
    yup.object().shape({
      reasonToProvide: yup.string().required(<FormattedMessage id='errors.fieldRequiredText' />)
    })
  ),

  otherChargeList: yup.array().of(
    yup.object().shape({
      reasonToOtherCharge: yup
        .string()
        .required(<FormattedMessage id='errors.fieldRequiredText' />),
      otherChargeLineList: yup.array().of(
        yup.object().shape({
          financialCharges: yup.number().when('refund', {
            is: (refund) => parseFloat(refund)>0.01,
            then: yup.number().min(0.01,<FormattedMessage id='errors.notPositive' />)
          }),
          debtCapitalInitial: yup.number().when('financialCharges', {
            is: (financialCharges) => parseFloat(financialCharges)>0.01,
            then: yup.number().min(0.01,<FormattedMessage id='errors.notPositive' />)
          }),






          debtCapitalFinal: yup
            .number()
            .required()
            .max(
              yup.ref('debtCapitalInitial'),
              <FormattedMessage id='otherCharge.capitalInvalid' />
            )
        },[['financialCharges','debtCapitalInitial','refund','debtCapitalFinal']])
      )
    })
  ),

  forecastDemonstrationList: yup.array().of(
    yup.object().shape({
      reasonToForecastDemonstration: yup
        .string()
        .required(<FormattedMessage id='errors.fieldRequiredText' />),
      type: yup
        .string()
        .required(<FormattedMessage id='errors.fieldRequiredText' />)
        .nullable()
    })
  ),

  peopleBoardList: yup.array().of(
    yup.object().shape({
      reasonToPeopleBoard: yup.string().required(<FormattedMessage id='errors.fieldRequiredText' />)
    })
  ),

  peopleRemunerationList: yup.array().of(
    yup.object().shape({
      reasonToRemuneration: yup
        .string()
        .required(<FormattedMessage id='errors.fieldRequiredText' />)
    })
  ),

  amortizationExpensesList: yup.array().of(
    yup.object().shape({
      reasonToAmortizationExpenses: yup
        .string()
        .required(<FormattedMessage id='errors.fieldRequiredText' />),

      amortizationExpensesLines: yup.array().of(
        yup.object().shape({
          investmentType: yup
            .string()
            .test(
              'isValidPromoterType',
              <FormattedMessage id='errors.fieldRequiredText' />,
              (value) => value !== '-1' && typeof value !== 'undefined'
            ),
          invoice: yup.string().required(<FormattedMessage id='errors.fieldRequiredText' />),
          rate: yup.number().min(0.01,<FormattedMessage id='errors.notPositive' />),
          value: yup.number().min(0.01,<FormattedMessage id='errors.notPositive' />)
        })
      )
    })
  ),


  earlyAmortizationList: yup.array().of(
    yup.object().shape({
     /* reasonToEarlyAmortization: yup
        .string().when('isRequired',{
          is: true,
            then: yup.string().required(<FormattedMessage id='errors.fieldRequiredText' />)}),*/


      financialCharges: yup.number().when('refund', {
        is: (refund) => parseFloat(refund)>0.01,
        then: yup.number().min(0.01,<FormattedMessage id='errors.notPositive' />)
      }),









      /*appliedEarlyAmortization: yup
        .mixed()
        .test(
          'isValidEarlyAmortization',
          <FormattedMessage id='errors.fieldRequiredText' />,
          (value) => value !== null && value !== '-1' && value !==undefined
        ),
      appliedDeferralPeriodBenefits: yup
        .mixed()
        .test(
          'isValidDeferralPeriodBenefits',
          <FormattedMessage id='errors.fieldRequiredText' />,
          (value) => value !== null && value !== '-1' && value !==undefined
        )*/
    })
  ),

  forecastBalanceList: yup.array().of(
    yup.object().shape({
    })
  ),
  candidatureEmployeeSupportDetails: yup.array().of(
      yup.object().shape({
        promoterMetaData: yup
            .mixed()
            .test(
                'isValidValue',
                <FormattedMessage id='errors.fieldRequiredText'/>,
                (value) => value !== '-1'
            ),
        timeType: yup
            .mixed()
            .test(
                'isValidValue',
                <FormattedMessage id='errors.fieldRequiredText'/>,
                (value) => value !== '-1'
            ),


        hours: yup.number().when('timeType',{
          is: 'PARTIAL',
          then: yup.number().min(1,<FormattedMessage id='errors.notPositive' />,)
        })
    })
  ),
  candidatureEmployeeSupportOtherDetails: yup.array().of(
      yup.object().shape({
        iSInnovationProject: yup
            .mixed()
            .test(
                'isValidValue',
                <FormattedMessage id='errors.fieldRequiredText'/>,
                (value) => value !== 'null' && value !== null && value !== '-1' && value !== undefined
            ),
        reasonToInnovationProject: yup.string().when('iSInnovationProject',{
          is: true,
          then: yup.string().required( <FormattedMessage id='errors.fieldRequiredText'/>)
        })
      })
  )
});
