import React from 'react';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FaQuestionCircle } from 'react-icons/fa';

export function ToolTip({ message, error }) {
  return (
    <OverlayTrigger
      overlay={
        error ? (
          <Tooltip className={'toolTipError'}>
            {' '}
            <span className='alert alert-danger '>{message}</span>
          </Tooltip>
        ) : (
          <Tooltip>{message}</Tooltip>
        )
      }>
      <Button className={error ? 'toolTip px-1' : 'toolTip'}>
        {error ? <FaQuestionCircle color={'#dc3545'} /> : <FaQuestionCircle />}
      </Button>
    </OverlayTrigger>
  );
}

export default ToolTip;
