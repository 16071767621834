import React, { useState } from 'react';
import { Col, Modal, Row } from 'react-bootstrap';
import { FaInfoCircle } from 'react-icons/fa';
import { FormattedMessage, useIntl } from 'react-intl';
import { createCustomErrorMessage } from '../../hooks/errorMessage';
import { Strings } from '../../utils/Strings';
import { AlertError } from '../bootstrap/AlertError';
import { TextInputField } from '../bootstrap/TextInputField';
import { SingleUploadArea } from '../SingleUploadArea';
import {handleError, isNotBusinessError} from "../../utils/handleError";

function SubmitDeclarationModal({ show, handleClose, titleId, updatePromoter,userDocuments,setUserDocuments}) {
  const intl = useIntl();

  const [iefpNumber, setIefpNumber] = useState('');

  const [error, setError] = useState(null);

  const[documents,setDocuments] = useState(getInitialFiles());


  function getInitialFiles() {
    let files =[];
    if(userDocuments.length>0){

      files.push({
        externalId: userDocuments[0].externalId,
        documentType: userDocuments[0].documentType,
        documentPath: userDocuments[0].documentPath,
        name: userDocuments[0].name,
        content: '',
        submited: true,
        error: ''
      });
    }else{
      files.push({
        externalId: '',
        documentType: 'IEFP_DECLARATION',
        name: '',
        content: null,
        submited: false,
        error: '',
      });
    }
    return files;
  }


  function handleIefpNumberChange(e) {
    setIefpNumber(e.target.value);
  }

  function handleSubmit() {
    if (Strings.isBlank(iefpNumber) || !documents[0].submited) {
      setError(createCustomErrorMessage(intl.formatMessage({ id: 'errors.submitDeclarationModal.required' })));
    } else if(!iefpNumber.match(/^\d{1,28}$/)) {
      setError(createCustomErrorMessage(intl.formatMessage({ id: 'exceptions.InconsistentFieldException.iefpNumber' })));
    }else{
        setError(null);
        let dataToSend = {
          iefpNumber: iefpNumber,
          step: 3,
        };
        updatePromoter(dataToSend);
    }
  }

  return (
    <Modal show={show} onHide={handleClose} size='lg'>
      <Modal.Header closeButton>
        <Modal.Title>
          {titleId ? (
            <FormattedMessage id={titleId} />
          ) : (
            <FormattedMessage id='deleteDialog.deleteText' />
          )}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <AlertError error={error} />
        <Row>
          <Col>
            <p className='mt-3 mb-0 text-secondary infoText'>
              <FaInfoCircle /> Para criar uma candidatura, é obrigatório o
              preenchimento da seguinte informação
            </p>
          </Col>
        </Row>
        <Row>
          <Col md='4'>
            <TextInputField
              controlId={'iefpNumber'}
              labelId={'promoterForm.iefpNumber'}
              name={'iefpNumber'}
              handleChange={(e) => handleIefpNumberChange(e)}
              value={iefpNumber}
            />
          </Col>
          <div className='mt-4 px-4'>
            <SingleUploadArea
                setDocuments={setDocuments}
                documents={documents}
              candidatureDocuments={userDocuments}
              setCandidatureDocuments={setUserDocuments}
              documentType={'IEFP_DECLARATION'}
              setError={setError}
            />
          </div>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <button className={'btn btn-outline-primary'} onClick={handleClose}>
          <FormattedMessage id='all.cancel' />
        </button>
        <button
          type='submit'
          className={'btn btn-primary'}
          onClick={() => handleSubmit()}
        >
          <FormattedMessage id='all.submitButtonText' />
        </button>
      </Modal.Footer>
    </Modal>
  );
}

export default SubmitDeclarationModal;
