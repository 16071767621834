import {Card, Col, Row} from "react-bootstrap";
import {Link} from "react-router-dom";
import {FaAngleDoubleRight, FaClipboardCheck} from "react-icons/fa";
import {FormattedMessage} from "react-intl";
import React from "react";

export function HomeCard({
     icon,
     titleId,
     subtitleId,
     linkAddress
}) {
    return(
    <Card className='text-primary mb-4 my-4'>
        <Card.Body>
            <Row>
                <Col className='d-flex justify-content-end py-2 col-lg-1 col-2'>
                    <Link to={linkAddress}>
                        {icon}
                    </Link>
                </Col>
                <Col className={`${subtitleId ? '':'d-flex '}align-items-center col-lg-10 col-8`}>
                    <Link to={linkAddress}>
                        <FormattedMessage id={titleId} />
                    </Link>
                    {subtitleId &&
                    <span className='text-secondary mb-1'>
                      <FormattedMessage id={subtitleId} />
                    </span>}
                </Col>
                <Col className='after-icon col-lg-1 col-2'>
                    <FaAngleDoubleRight />
                </Col>
            </Row>
        </Card.Body>
    </Card>
    )
}