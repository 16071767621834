import {Col, Container, Form, Row} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {FormattedMessage} from "react-intl";
import {LCol} from "../ViewEaAccreditation";
import {
    getCountiesByDistrictCode,
    getDistricts,
    getParishesByCountyCode
} from "../../../../rest/administrativeDivision";
import Loading from "../../../general/Loading";
import {DistrictMap} from "../../DistrictMap";

export function EAGeoTab({districts, interventionDistrictCodes, incubatorLocations}) {
    const interventionDistricts = interventionDistrictCodes.map(id => districts.get(id));
    const activeParishes = Array.from(new Set(incubatorLocations.map(il => il.parishCode)));

    const [loading, setLoading] = useState(true);
    const [parishes, setParishes] = useState(null);
    const [counties, setCounties] = useState(null);

    const getDistrictByParish = (parishCode) => districts.get(parishCode.substring(0, 2));
    const getCountyByParish = (parishCode) => counties.get(parishCode.substring(0, 4));

    async function fetchData() {
        let tc = new Map();
        let tp = new Map();
        try {
            for (let dist of  Array.from(new Set(activeParishes.map(p => p.substring(0, 2))))) {
                const { data: counts } = await getCountiesByDistrictCode(dist);
                counts.forEach(c => tc.set(c.code, c))
            }
            setCounties(tc);
            for (let cnty of Array.from(new Set(activeParishes.map(p => p.substring(0, 4))))) {
                const { data: pari } = await getParishesByCountyCode(cnty);
                pari.forEach(p => tp.set(p.code, p))
            }
            setParishes(tp);
            setLoading(false)
        } catch (er) {
            console.log(er);
        }
    }

    useEffect( () =>{
        fetchData();
    }, []);


    if (loading) {
        return <Loading />;
    }

    return (
        <Container>

            <Row>
                <Col>
                    <Row>
                        <Col>
                            <small className='d-block text-uppercase text-secondary border-bottom mt-5'>
                                <FormattedMessage id={`eaAccreditation.View.Geo.areas`} />
                            </small>
                        </Col>
                    </Row>
                    <Row >
                        {interventionDistricts.map((district) => (
                            <Col key={district.code} md={11}>{district.description} ({district.regionalDelegationCode})</Col>
                        ))}
                    </Row>
                </Col>
                <Col sm={"auto"}>
                    <DistrictMap activeDistrictCodes={interventionDistrictCodes} />
                </Col>
            </Row>
            <Row className='mb-3'>
                <Col>
                    <small className='d-block text-uppercase text-secondary border-bottom mt-5'>
                        <FormattedMessage id={`eaAccreditation.View.Geo.locations`} />
                    </small>
                </Col>
            </Row>
            {incubatorLocations.map( (incLoc) =>
            <div key={incLoc.externalId} className={'mb-3 border-bottom pb-3'}>
                <Row>
                    <LCol id={'eaAccreditation.View.Geo.locations.district'} md={2}>{getDistrictByParish(incLoc.parishCode).description}</LCol>
                    <LCol id={'eaAccreditation.View.Geo.locations.county'} md={2}>{getCountyByParish(incLoc.parishCode).description}</LCol>
                    <LCol id={'eaAccreditation.View.Geo.locations.parish'} md={3}>{parishes.get(incLoc.parishCode).description}</LCol>
                    <LCol id={'eaAccreditation.View.Geo.locations.address'} md={5}>{incLoc.extraLocation}</LCol>
                </Row>
            </div>
            )}
        </Container>
    );
}