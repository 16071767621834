import React from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { NumberInputField } from '../bootstrap/NumberInputField';
import { TextInputField } from '../bootstrap/TextInputField';
import IntInputField from "../bootstrap/IntInputField";
import DecimalInputField from "../bootstrap/DecimalInputField";

export function ProjectDescriptionStep({
	step,
	numberOfSteps,
	errors,
	handleChange,
	formikValues,
	handleGoBack,
	handleNextStep,
	readMode
}) {
	const intl = useIntl();

	return (
		<div>
			<h4 className='text-primary mb-0'>
				<FormattedMessage id='projectIdentification.projectdescription' />
			</h4>
			<small className='text-secondary text-uppercase'>
				{intl.formatMessage(
					{ id: 'all.step' },
					{ step: step, numberOfSteps: numberOfSteps }
				)}
			</small>
			<fieldset disabled={readMode}>
				<Row>
					<Col md='9'>
						<TextInputField
							controlId={'project.activity'}
							labelId={'projectdescription.activity'}
							name={'project.activity'}
							handleChange={handleChange}
							value={formikValues.project.activity}
							isInvalid={errors.project?.activity ? true : false}
							errorMessage={
								errors.project?.activity ? errors.project.activity : ''
							}
						/>
					</Col>
					<Col md='3'>
						<IntInputField
							maxSize={5}
							name={'project.cae'}
							labelId={'projectdescription.cae'}
							handleChange={handleChange}
							value={formikValues.project.cae}
							isInvalid={errors.project?.cae ? true : false}
							errorMessage={errors.project?.cae ? errors.project.cae : ''}
							leadingZero={true}
						/>
					</Col>
				</Row>
				<Row>
					<Col md='6'>


						<DecimalInputField
							controlId={'project.investmentvalue'}
							labelId={'projectdescription.investmentvalue'}
							name={'project.investmentvalue'}
							handleChange={handleChange}
							value={formikValues.project.investmentValue}
							isInvalid={errors.project?.investmentValue ? true : false}
							errorMessage={
								errors.project?.investmentValue
									? errors.project.investmentValue
									: ''
							}
							suffixLabel={'€'}
						/>




					</Col>
					<Col md='6 '>

						<DecimalInputField
							controlId={'project.ownCapitals'}
							labelId={'projectdescription.owncapitals'}
							name={'project.ownCapitals'}
							handleChange={handleChange}
							value={formikValues.project.ownCapitals}
							isInvalid={errors.project?.ownCapitals ? true : false}
							errorMessage={
								errors.project?.ownCapitals ? errors.project.ownCapitals : ''
							}
							suffixLabel={'€'}
						/>
					</Col>
				</Row>
				<Row className='mt-5'>
					<small className='text-primary text-uppercase'>
						<FormattedMessage id='projectdescription.regionProject' />
					</small>
				</Row>
				<Row>
					<Col md='4'>
						<TextInputField
							controlId={'project.county'}
							labelId={'projectdescription.county'}
							name={'project.county'}
							handleChange={handleChange}
							value={formikValues.project.county}
							isInvalid={errors.project?.county ? true : false}
							errorMessage={errors.project?.county ? errors.project.county : ''}
						/>
					</Col>
					<Col md='4'>
						<TextInputField
							controlId={'project.parish'}
							labelId={'projectdescription.parish'}
							name={'project.parish'}
							handleChange={handleChange}
							value={formikValues.project.parish}
							isInvalid={errors.project?.parish ? true : false}
							errorMessage={errors.project?.parish ? errors.project.parish : ''}
						/>
					</Col>
					<Col md='4'>


						<Form.Label>
							 <FormattedMessage id={'projectdescription.postalCode'} />
						</Form.Label>

						<div className='d-inline d-flex align-items-center'>
						<TextInputField
							className={'w-40'}
							name={'project.postalCode4'}
							handleChange={handleChange}
							value={formikValues.project.postalCode4}
							isInvalid={errors.project?.postalCode4 ? true : false}
							errorMessage={
								errors.project?.postalCode4 ? errors.project.postalCode4 : ''
							}
							maxLength={4}
							onlyNumber={true}
						/>
							<span className='ms-2 me-2'>{'-'}</span>
						<TextInputField
							className={'w-35'}
							name={'project.postalCode3'}
							handleChange={handleChange}
							value={formikValues.project.postalCode3}
							isInvalid={errors.project?.postalCode3 ? true : false}
							errorMessage={
								errors.project?.postalCode3 ? errors.project.postalCode3 : ''
							}
							maxLength={3}
							onlyNumber={true}
						/>
						</div>
					</Col>
				</Row>
				<Row className='mt-5'>
					<small className='text-primary text-uppercase'>
						<FormattedMessage id='projectdescription.workstations' />
					</small>
				</Row>
				<Row>
					<Col md='6'>
						<IntInputField
							className='w-25'
							maxSize={9}
							name={'project.fullTimeRecipientPromoters'}
							labelId={'projectdescription.recipientPromotersFulltime'}


							handleChange={handleChange}
							value={formikValues.project.fullTimeRecipientPromoters}
							isInvalid={
								errors.project?.fullTimeRecipientPromoters ? true : false
							}
							errorMessage={
								errors.project?.fullTimeRecipientPromoters
									? errors.project.fullTimeRecipientPromoters
									: ''
							}
						/>
					</Col>
					<Col md='6'>
						<IntInputField
							className='w-25'
							maxSize={9}
							labelId={'projectdescription.recipientPromotersParttime'}
							name={'project.partTimeRecipientPromoters'}
							handleChange={handleChange}
							value={formikValues.project.partTimeRecipientPromoters}
							isInvalid={
								errors.project?.partTimeRecipientPromoters ? true : false
							}
							errorMessage={
								errors.project?.partTimeRecipientPromoters
									? errors.project.partTimeRecipientPromoters
									: ''
							}
						/>
					</Col>
				</Row>
				<Row>
					<Col md='6'>

						<IntInputField
							maxSize={9}
							className='w-25'
							controlId={'project.otherPromoters.fullTime'}
							labelId={'projectdescription.otherPromotersFulltime'}
							name={'project.fullTimeOtherPromoters'}
							handleChange={handleChange}
							value={formikValues.project.fullTimeOtherPromoters}
							isInvalid={errors.project?.fullTimeOtherPromoters ? true : false}
							errorMessage={
								errors.project?.fullTimeOtherPromoters
									? errors.project.fullTimeOtherPromoters
									: ''
							}
						/>
					</Col>
					<Col md='6'>

						<IntInputField
							maxSize={9}
							className='w-25'
							controlId={'project.otherPromoters.partTime'}
							labelId={'projectdescription.otherPromotersParttime'}
							name={'project.partTimeOtherPromoters'}
							handleChange={handleChange}
							value={formikValues.project.partTimeOtherPromoters}
							isInvalid={errors.project?.partTimeOtherPromoters ? true : false}
							errorMessage={
								errors.project?.partTimeOtherPromoters
									? errors.project.partTimeOtherPromoters
									: ''
							}
						/>
					</Col>
				</Row>
			</fieldset>
			<div className='form-actions mt-5'>
				<button
					className='btn btn-link'
					type='button'
					onClick={() => handleGoBack()}
				>
					<FormattedMessage id='all.back' />
				</button>

				<button
					className='btn btn-primary'
					type='button'
					onClick={() => handleNextStep()}
				>
					<FormattedMessage id='all.nextStep' />
				</button>
			</div>
		</div>
	);
}
