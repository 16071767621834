import React, { useEffect } from 'react';
import { Form } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';

export function SelectBooleanField({
  name,
  labelId,
  label,
  isInvalid,
  errorMessage,
  handleChange,
  setFieldValue,
  value,
  nullOption,
  disabled,
  toolTip,
  trueLabelId,
  falseLabelId
}) {
  const intl = useIntl();

  useEffect(() => {
    if (!nullOption && value == null) {
      setFieldValue(name, true);
    }
  }, []);

  return (
    <Form.Group controlId={name}>
      <Form.Label>
        {labelId && <FormattedMessage id={labelId} />}
        {label && label}
        {toolTip && toolTip}
      </Form.Label>
      <Form.Control
        as='select'
        name={name}
        onChange={
          handleChange
            ? handleChange
            : (e) =>
                setFieldValue(
                  name,
                  e.target.value === '-1' ? null :   (e.target.value ==='true')? true: (e.target.value ==='false')? false: e.target.value
                )
        }
        value={value===null ? (nullOption ? '-1': '') : value}
        isInvalid={isInvalid}
        disabled={disabled}
      >
        {nullOption && (
          <option value='-1'>{intl.formatMessage({ id: 'all.select' })}</option>
        )}
        <option value='true'>{intl.formatMessage({ id: trueLabelId ? trueLabelId :  'all.yes' })}</option>
        <option value='false'>{intl.formatMessage({ id: falseLabelId ? falseLabelId : 'all.no' })}</option>
      </Form.Control>
      {errorMessage && (
        <Form.Control.Feedback type='invalid'>
          {errorMessage}
        </Form.Control.Feedback>
      )}
    </Form.Group>
  );
}
