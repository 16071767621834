export const AccreditationDecisionState = {
    SUBMITTED: 'SUBMITTED',
    INVALIDATED: 'INVALIDATED',
    VALIDATED: 'VALIDATED',
    AWAITING_APPEAL: 'AWAITING_APPEAL',
    APPEAL_EXPIRED: 'APPEAL_EXPIRED',
    APPEALED: 'APPEALED',
    REJECTED: 'REJECTED',
    ACCEPTED: 'ACCEPTED',

    values: () => {
        return [
            'SUBMITTED',
            'INVALIDATED',
            'VALIDATED',
            'AWAITING_APPEAL',
            'APPEAL_EXPIRED',
            'APPEALED',
            'REJECTED',
            'ACCEPTED'
        ];
    },

    postValidation: () => {
        return [
            'VALIDATED',
            'AWAITING_APPEAL',
            'APPEAL_EXPIRED',
            'APPEALED',
            'REJECTED',
            'ACCEPTED'
        ];
    },

    decisionStates: () => {
        return [
            'REJECTED',
            'ACCEPTED'
        ];
    }
};

