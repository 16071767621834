import { apiAxios as authAxios } from '../axios.config';

export const listPromotersMetaData = () => {
  return authAxios.get('/api/promoterMetaData');
};

export const isJustMentioned = (candidatureExternalId) => {
  return authAxios.get(`/api/promoterMetaData/isJustMentioned/candidature/${candidatureExternalId}`);
}

export const mustUploadIefpDocument = (candidatureExternalId) => {
  return authAxios.get(`/api/promoterMetaData/mustUploadIefpDocument/candidature/${candidatureExternalId}`);
}