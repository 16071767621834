export const DocumentType = {
  ARTS_CRAFTS_OPINION: 'ARTS_CRAFTS_OPINION',
  TERM_ACCEPT_DRAFT: 'TERM_ACCEPT_DRAFT',
  TERM_ACCEPT: 'TERM_ACCEPT',
  IEFP_DECLARATION: 'IEFP_DECLARATION',
  ECONOMIC_FEASIBILITY_OPINION: 'ECONOMIC_FEASIBILITY_OPINION',
  VALIDATION_DOCUMENT: 'VALIDATION_DOCUMENT',
  VALIDATION_DOCUMENT_PRIVATE: 'VALIDATION_DOCUMENT_PRIVATE',
  REVIEW_PRIOR_SUPPORT: 'REVIEW_PRIOR_SUPPORT',
  CONTRACT_PRIOR_SUPPORT: 'CONTRACT_PRIOR_SUPPORT',
  FINAL_DECISION: 'FINAL_DECISION',
  FINAL_DECISION_PRIVATE: 'FINAL_DECISION_PRIVATE',
  EXPIRATION_DECISION_APPROVAL: 'EXPIRATION_DECISION_APPROVAL',
  EXPIRATION_DECISION_APPROVAL_PRIVATE: 'EXPIRATION_DECISION_APPROVAL_PRIVATE',
  EXPIRATION_DECISION_FINAL: 'EXPIRATION_DECISION_FINAL',
  EXPIRATION_DECISION_FINAL_PRIVATE: 'EXPIRATION_DECISION_FINAL_PRIVATE',
  MCE_CONTRACT: 'MCE_CONTRACT',
  FINAL_DECISION_ELIGIBILITY : 'FINAL_DECISION_ELIGIBILITY',
  FINAL_DECISION_ELIGIBILITY_PRIVATE : 'FINAL_DECISION_ELIGIBILITY_PRIVATE',
  DECISION_ELIGIBILITY : 'DECISION_ELIGIBILITY',
  DECISION_ELIGIBILITY_PRIVATE : 'DECISION_ELIGIBILITY_PRIVATE',
  PAYMENT_SERVICE_INFORMATION: 'PAYMENT_SERVICE_INFORMATION',
  NON_COMP_SERVICE_INFO: 'NON_COMP_SERVICE_INFO',
  NON_COMP_NOTIFICATION: 'NON_COMP_NOTIFICATION',
  NON_COMP_APPEAL: 'NON_COMP_APPEAL',
  NON_COMP_DECISION: 'NON_COMP_DECISION',
  NON_COMP_DECISION_NOTIFICATION: 'NON_COMP_DECISION_NOTIFICATION',
  IRREGULARITY_NOTIFICATION: 'IRREGULARITY_NOTIFICATION',
  CANDIDATURE_CHANGE_ANNEX: 'CANDIDATURE_CHANGE_ANNEX',
  CANDIDATURE_CHANGE_CONTESTATION_ANNEX: 'CANDIDATURE_CHANGE_CONTESTATION_ANNEX',
  CANDIDATURE_CHANGE_TERM_ACCEPT: 'CANDIDATURE_CHANGE_TERM_ACCEPT',
  CANDIDATURE_CHANGE_TERM_ACCEPT_SIGNED:'CANDIDATURE_CHANGE_TERM_ACCEPT_SIGNED',
  OTHER_DOCUMENT_CANDIDATURE:'OTHER_DOCUMENT_CANDIDATURE',
  MESSAGE_ATTACHMENT: 'MESSAGE_ATTACHMENT',
  FINAL_DECISION_CLOSED:'FINAL_DECISION_CLOSED',
  FINAL_DECISION_CLOSED_PRIVATE:'FINAL_DECISION_CLOSED_PRIVATE',
  REASON_ENTITY_CHANGE:'REASON_ENTITY_CHANGE',
  ENTITY_CHANGE_FINAL_DECISION_PRIVATE:'ENTITY_CHANGE_FINAL_DECISION_PRIVATE',
  ENTITY_CHANGE_FINAL_DECISION:'ENTITY_CHANGE_FINAL_DECISION',
  ENTITY_CHANGE_DECISION_PRIVATE:'ENTITY_CHANGE_DECISION_PRIVATE',
  ENTITY_CHANGE_DECISION:'ENTITY_CHANGE_DECISION',
  CLARIFICATION_ENTITY_CHANGE:'CLARIFICATION_ENTITY_CHANGE',
  CLARIFICATION_ACCRED_REVOCATION:'CLARIFICATION_ACCRED_REVOCATION',
  ACCREDITATION_REVOCATION_FINAL_DECISION_PRIVATE:'ACCREDITATION_REVOCATION_FINAL_DECISION_PRIVATE',
  ACCREDITATION_REVOCATION_FINAL_DECISION:'ACCREDITATION_REVOCATION_FINAL_DECISION',
  ACCREDITATION_REVOCATION_DECISION_PRIVATE:'ACCREDITATION_REVOCATION_DECISION_PRIVATE',
  ACCREDITATION_REVOCATION_DECISION:'ACCREDITATION_REVOCATION_DECISION',
  EAA_APPEAL_DOCUMENT: "EAA_APPEAL_DOCUMENT",
  AT_DECLARATION: "AT_DECLARATION",
  SS_DECLARATION: "SS_DECLARATION",
  EAA_PROP_SERVICE_INFO: 'EAA_PROP_SERVICE_INFO',
  EAA_SERVICE_INFO: "EAA_SERVICE_INFO",
  EAA_PROP_DECISION_NOTIFICATION: 'EAA_PROP_DECISION_NOTIFICATION',
  EAA_DECISION_NOTIFICATION: "EAA_DECISION_NOTIFICATION",
  EAA_IEFP_CONFIRMATION: "EAA_IEFP_CONFIRMATION",
  EAA_DIRECTIVE_COUNCIL_DELIBERATION: "EAA_DIRECTIVE_COUNCIL_DELIBERATION",
  RENEWAL_INVITATION_LETTER: "RENEWAL_INVITATION_LETTER",
  RENEWAL_ENTITY_RESPONSE_TO_INVITATION: "RENEWAL_ENTITY_RESPONSE_TO_INVITATION",
  RENEWAL_DIRECTIVE_COUNCIL_SERVICE_INFORMATION: "RENEWAL_DIRECTIVE_COUNCIL_SERVICE_INFORMATION",
  RENEWAL_DIRECTIVE_COUNCIL_DELIBERATION: "RENEWAL_DIRECTIVE_COUNCIL_DELIBERATION",
  RENEWAL_NOTIFICATION_LETTER_AND_PROTOCOL: "RENEWAL_NOTIFICATION_LETTER_AND_PROTOCOL",
  RENEWAL_SIGNED_PROTOCOL: "RENEWAL_SIGNED_PROTOCOL",
  NOTIFICATION_TO_EA: 'NOTIFICATION_TO_EA',
  SERVICE_INFORMATION: 'SERVICE_INFORMATION',
  DECISION_DISPATCH: 'DECISION_DISPATCH',
  PROPOSAL_DISPATCH: 'PROPOSAL_DISPATCH',
  COOPERATION_PROTOCOL: "COOPERATION_PROTOCOL",
  NIB_PROOF: "NIB_PROOF",
  DUE_DILIGENCE_DOCUMENT: "DUE_DILIGENCE_DOCUMENT",
  DUE_DILIGENCE_DOCUMENT_RESPONSE: "DUE_DILIGENCE_DOCUMENT_RESPONSE",

  values: () => {
    return [
      'ARTS_CRAFTS_OPINION',
      'TERM_ACCEPT_DRAFT',
      'TERM_ACCEPT',
      'IEFP_DECLARATION',
      'ECONOMIC_FEASIBILITY_OPINION',
      'VALIDATION_DOCUMENT',
      'VALIDATION_DOCUMENT_PRIVATE',
      'REVIEW_PRIOR_SUPPORT',
      'CONTRACT_PRIOR_SUPPORT',
      'FINAL_DECISION',
      'FINAL_DECISION_PRIVATE',
      'EXPIRATION_DECISION_APPROVAL',
      'EXPIRATION_DECISION_APPROVAL_PRIVATE',
      'EXPIRATION_DECISION_FINAL',
      'EXPIRATION_DECISION_FINAL_PRIVATE',
      'MCE_CONTRACT',
      'FINAL_DECISION_ELIGIBILITY',
      'FINAL_DECISION_ELIGIBILITY_PRIVATE',
      'DECISION_ELIGIBILITY',
      'DECISION_ELIGIBILITY_PRIVATE',
      'PAYMENT_SERVICE_INFORMATION',
      'NON_COMP_SERVICE_INFO',
      'NON_COMP_NOTIFICATION',
      'NON_COMP_APPEAL',
      'NON_COMP_DECISION',
      'NON_COMP_DECISION_NOTIFICATION',
      'IRREGULARITY_NOTIFICATION',
      'CANDIDATURE_CHANGE_ANNEX',
      'CANDIDATURE_CHANGE_CONTESTATION_ANNEX',
      'CANDIDATURE_CHANGE_TERM_ACCEPT',
      'CANDIDATURE_CHANGE_TERM_ACCEPT_SIGNED',
      'OTHER_DOCUMENT_CANDIDATURE',
      'MESSAGE_ATTACHMENT',
      'FINAL_DECISION_CLOSED',
      'FINAL_DECISION_CLOSED_PRIVATE',
      'REASON_ENTITY_CHANGE',
      'ENTITY_CHANGE_FINAL_DECISION_PRIVATE',
      'ENTITY_CHANGE_FINAL_DECISION',
      'ENTITY_CHANGE_DECISION_PRIVATE',
      'ENTITY_CHANGE_DECISION',
      'CLARIFICATION_ENTITY_CHANGE',
      'CLARIFICATION_ACCRED_REVOCATION',
      'ACCREDITATION_REVOCATION_FINAL_DECISION_PRIVATE',
      'ACCREDITATION_REVOCATION_FINAL_DECISION',
      'ACCREDITATION_REVOCATION_DECISION_PRIVATE',
      'ACCREDITATION_REVOCATION_DECISION',
      "EAA_APPEAL_DOCUMENT",
      "AT_DECLARATION",
      "SS_DECLARATION",
      'EAA_PROP_SERVICE_INFO',
      "EAA_SERVICE_INFO",
      'EAA_PROP_DECISION_NOTIFICATION',
      "EAA_DECISION_NOTIFICATION",
      "EAA_IEFP_CONFIRMATION",
      "EAA_DIRECTIVE_COUNCIL_DELIBERATION",
      "RENEWAL_INVITATION_LETTER",
      "RENEWAL_ENTITY_RESPONSE_TO_INVITATION",
      "RENEWAL_DIRECTIVE_COUNCIL_SERVICE_INFORMATION",
      "RENEWAL_DIRECTIVE_COUNCIL_DELIBERATION",
      "RENEWAL_NOTIFICATION_LETTER_AND_PROTOCOL",
      "RENEWAL_SIGNED_PROTOCOL",
      'NOTIFICATION_TO_EA',
      'SERVICE_INFORMATION',
      'DECISION_DISPATCH',
      'PROPOSAL_DISPATCH',
      "COOPERATION_PROTOCOL",
      "NIB_PROOF",
      "DUE_DILIGENCE_DOCUMENT_RESPONSE",
      "DUE_DILIGENCE_DOCUMENT"
    ];
  },

  getCandidatureAfterApprovalDocumentTypes: () => {
    return [
      'NEW_COMPANY_REGISTRATION_PROOF',
      'NEW_COMPANY_START_ACTIVITY_PROOF',
      'ACTIVITY_EXERCISE_DOCS',
      'FACILITIES_OWNERSHIP_DOC',
      'TRANSFER_PROPERTY_AGREEMENT_DOC',
      'FRANCHISE_AGREEMENT_DOC',
      'IBAN_DOC',
      'CALENDARING',
      'NO_DEBT_AT_DECLARATION',
      'NO_DEBT_SS_DECLARATION',
      'EXPIRATION_DECISION_APPROVAL',
      'EXPIRATION_DECISION_APPROVAL_PRIVATE',
      'EXPIRATION_DECISION_FINAL',
      'EXPIRATION_DECISION_FINAL_PRIVATE'
    ];
  }
};
