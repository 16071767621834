import React, {useEffect} from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { ContactType } from '../../models/ContactType';
import { EnumSelectField } from '../bootstrap/EnumSelectField';
import { IntInputField } from '../bootstrap/IntInputField';
import { TextInputField } from '../bootstrap/TextInputField';
import DateTimePicker from 'react-datetime-picker';
import { ConfigObject } from '../../config';
import dateFormat from 'date-fns/format';
import {Link} from "react-router-dom";
import {LabelToolTip} from "../bootstrap/LabelToolTip";
import {hasRole} from "../../authentication/authenticationHelper";

export function EconomicViabilityFormStep1({
  candidatureExternalId,
  handleChange,
  setFieldValue,
  errors,
  values,
  error,
  setError,
  numberOfSteps,
  handleSave
}) {


  const intl = useIntl();

  function handleContactedPromotersChange(e, index, fieldName) {
    values.contactedPromoterDetails[index][fieldName] = e.target.value;
    setFieldValue('contactedPromoterDetails', [...values.contactedPromoterDetails]);
  }

  function handleContactDateChange(e, promoter) {
    promoter.contactDate = dateFormat(e, ConfigObject.get().DATE_PATTERN);
    setFieldValue('contactedPromoterDetails', [...values.contactedPromoterDetails]);
  }
  function handleChangeCae(e){
    setFieldValue('cae',e.target.value)
  }

  return (
    <div>
      <h4 className='text-primary mb-0'>
        <FormattedMessage id='economicViabilityForm.identificationTab' />
      </h4>
      <small className='text-secondary text-uppercase d-block'>
        {intl.formatMessage(
          { id: 'all.step' },
          { step: values.step, numberOfSteps: numberOfSteps }
        )}
      </small>

      <Row>
        <small className='text-primary text-uppercase mt-4'>
          <FormattedMessage id='economicViabilityFormStep1.entityIdentificationTitle' />
        </small>
        <Col md='8'>
          <TextInputField
            labelId={'economicViabilityFormStep1.entityDesignation'}
            name={'entityDesignation'}
            value={values.entityDesignation}
            handleChange={handleChange}
            disabled={true}
          />
        </Col>
        <Col md='4'>
          <TextInputField
            labelId={'economicViabilityFormStep1.entityNIPC'}
            name={'entityNIPC'}
            value={values.entityNIPC}
            handleChange={handleChange}
            disabled={true}
          />
        </Col>
      </Row>

      <Row>
        <small className='text-primary text-uppercase mt-4'>
          <FormattedMessage id='economicViabilityFormStep1.projectIdentificationTitle' />
        </small>
        <Row>
          <Col md='3'>


            <Form.Label >
              <FormattedMessage id={'economicViabilityFormStep1.processNumber'} />
            </Form.Label>
            <Link
                to={'/candidatura/' + values.candidatureExternalId}
                state={hasRole('ENTITY') ? { isEntity: true } : {}}
            >
              <LabelToolTip
                  labelId={'economicViabilityFormStep1.processNumber' }
                  value={values.processNumber}
              />
            </Link>
          </Col>
          <Col md='5'>
            <Form.Label >
              Id
            </Form.Label>
            <Link
                to={'/candidatura/' + values.candidatureExternalId}
                state={hasRole('ENTITY') ? { isEntity: true } : {}}
            >
              <LabelToolTip
                  labelId={'all.code' }
                  value={values.candidatureId}
              />
            </Link>
          </Col>
          <Col md='4'>
            <TextInputField
              labelId={'economicViabilityFormStep1.candidatureDate'}
              name={'candidatureDate'}
              value={values.candidatureDate}
              disabled={true}
            />
          </Col>
        </Row>

        <Row>
          <Col md='6'>
            <TextInputField
              labelId={'economicViabilityFormStep1.legalNature'}
              name={'legalNature'}
              value={values.legalNature}
              handleChange={handleChange}
              isInvalid={errors.legalNature}
              errorMessage={errors.legalNature}
            />
          </Col>
          <Col md='6'>
            <TextInputField
              labelId={'economicViabilityFormStep1.activity'}
              name={'activity'}
              value={values.activity}
              handleChange={handleChange}
              isInvalid={errors.activity}
              errorMessage={errors.activity}
            />
          </Col>
        </Row>

        <Row>
          <Col md='6'>
            <IntInputField
                maxSize={5}
                name={'cae'}
                labelId={'economicViabilityFormStep1.cae'}
                value={values.cae}
                handleChange={(e) => handleChangeCae(e)}
                isInvalid={errors.cae}
                errorMessage={errors.cae}
                leadingZero={true}
            />
          </Col>
          <Col md='3'>
            <IntInputField
              labelId={'economicViabilityFormStep1.promotersNumber'}
              name={'promotersNumber'}
              value={values.promotersNumber}
              handleChange={handleChange}
              disabled={true}
            />
          </Col>
          <Col md='3'>
            <IntInputField
              labelId={'economicViabilityFormStep1.workStationsNumber'}
              name={'workStationsNumber'}
              value={values.workStationsNumber}
              handleChange={handleChange}
              disabled={true}
            />
          </Col>
        </Row>
      </Row>

      <Row>
        <small className='text-primary text-uppercase mt-4'>
          <FormattedMessage id='economicViabilityFormStep1.promotersIdentificationTitle' />
        </small>
        {values.contactedPromoterDetails.map((cpd, index) => (
          <div key={index}>
            <p className='mt-4 mb-0 text-uppercase'>Promotor {index + 1} </p>
            <Row>
              <Col md='6'>
                <TextInputField
                  labelId={'economicViabilityFormStep1.contactedPromoterDetails.name'}
                  name={'name'}
                  value={cpd.name}
                  handleChange={(e) => handleContactedPromotersChange(e, index, 'name')}
                  disabled={true}
                />
              </Col>
              <Col md='6'>
                <TextInputField
                  labelId={
                    'economicViabilityFormStep1.contactedPromoterDetails.functionDescription'
                  }
                  name={'functionDescription'}
                  value={cpd.functionDescription}
                  handleChange={(e) =>
                    handleContactedPromotersChange(e, index, 'functionDescription')
                  }
                  isInvalid={
                    errors.contactedPromoterDetails
                      ? errors.contactedPromoterDetails[index]?.functionDescription
                      : false
                  }
                  errorMessage={
                    errors.contactedPromoterDetails
                      ? errors.contactedPromoterDetails[index]?.functionDescription
                      : ''
                  }
                />
              </Col>
            </Row>

            <Row>
              <Col md='4'>
                <TextInputField
                  labelId={'economicViabilityFormStep1.contactedPromoterDetails.nif'}
                  name={'nif'}
                  value={cpd.nif}
                  handleChange={(e) => handleContactedPromotersChange(e, index, 'nif')}
                  disabled={true}
                />
              </Col>
              <Col md='4'>
                <TextInputField
                  labelId={'economicViabilityFormStep1.contactedPromoterDetails.phone'}
                  name={'phone'}
                  value={cpd.phone}
                  handleChange={(e) => handleContactedPromotersChange(e, index, 'phone')}
                  isInvalid={
                    errors.contactedPromoterDetails
                      ? errors.contactedPromoterDetails[index]?.phone
                      : false
                  }
                  errorMessage={
                    errors.contactedPromoterDetails
                      ? errors.contactedPromoterDetails[index]?.phone
                      : ''
                  }
                />
              </Col>
              <Col md='4'>
                <TextInputField
                  labelId={'economicViabilityFormStep1.contactedPromoterDetails.email'}
                  name={'email'}
                  value={cpd.email}
                  handleChange={(e) => handleContactedPromotersChange(e, index, 'email')}
                  disabled={true}
                />
              </Col>
            </Row>

            <Row>
              <Col md='4'>
                <EnumSelectField
                  controlId={'contactType'}
                  labelId={'economicViabilityFormStep1.contactedPromoterDetails.contactType'}
                  preffixDescriptionId={'contactType'}
                  name={'contactType'}
                  handleChange={(e) => handleContactedPromotersChange(e, index, 'contactType')}
                  options={ContactType.values()}
                  value={cpd.contactType}
                  nullOption={true}
                  isInvalid={
                    errors.contactedPromoterDetails
                      ? errors.contactedPromoterDetails[index]?.contactType
                      : false
                  }
                  errorMessage={
                    errors.contactedPromoterDetails
                      ? errors.contactedPromoterDetails[index]?.contactType
                      : ''
                  }
                />
              </Col>
              <Col md='4'>
                <Form.Group controlId={'contactDate'}>
                  <Form.Label>
                    <FormattedMessage
                      id={'economicViabilityFormStep1.contactedPromoterDetails.contactDate'}
                    />
                  </Form.Label>
                  <Form.Control
                    className='datetimepicker'
                    as={DateTimePicker}
                    format={ConfigObject.get().DATE_PATTERN}
                    clearIcon={null}
                    value={cpd.contactDate ? new Date(cpd.contactDate) : new Date()}
                    onChange={(e) => handleContactDateChange(e, cpd)}
                  />
                </Form.Group>
              </Col>
            </Row>
          </div>
        ))}
      </Row>
    </div>
  );
}
