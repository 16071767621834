import {useNavigate, useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {getCandidatureByExternalId} from "../../rest/candidature";
import {getPayments, savePayment, savePaymentWithDocument} from "../../rest/candidaturePayments";
import Loading from "../general/Loading";
import {SubTemplate} from "../general/SubTemplate";
import {Col, Container, Form, Row} from "react-bootstrap";
import {AlertError} from "../bootstrap/AlertError";
import {FormattedMessage} from "react-intl";
import {EnumSelectField} from "../bootstrap/EnumSelectField";
import {CandidaturePaymentType} from "../../models/CandidaturePaymentType";
import {CandidaturePaymentPurpose} from "../../models/CandidaturePaymentPurpose";
import DecimalInputField from "../bootstrap/DecimalInputField";
import {DateTimeInputField} from "../bootstrap/DateTimeInputField";
import {ErrorMessage, Formik} from "formik";
import {calculateTotals} from "./candidaturePaymentFunctions";
import * as yup from "yup";
import {maxFixed} from "../../utils/CurrencyUtils";
import {SingleUploadArea} from "../SingleUploadArea";
import {roundToTwo} from "../../utils/NumberUtils";

let INVESTMENT = CandidaturePaymentPurpose.INVESTMENT;
let JOB_CREATION = CandidaturePaymentPurpose.JOB_CREATION;

let ADVANCE = CandidaturePaymentType.ADVANCE;
let FINAL = CandidaturePaymentType.FINAL;
let COMPENSATORY = CandidaturePaymentType.COMPENSATORY;

export function PaymentRegistration() {
    const { externalId } = useParams();
    const [candidature, setCandidature] = useState();
    const [payments, setPayments] = useState();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [documents, setDocuments] = useState([]);
    const [submitted, setSubmitted] = useState(false);
    const [navErrors, setNavErrors] = useState([]);
    const navigate = useNavigate();
    const ADVANCE_PERCENTAGE = 0.65;


    const fetchData = async () => {
        try {
            const [{ data: candidature }, {data: payments}] = await Promise.all([getCandidatureByExternalId(externalId), getPayments(externalId)]);
            setCandidature(candidature);
            setPayments(payments);
        } catch (error) {
            setError(error);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        fetchData();
    }, []);

    if (loading) {
        return <Loading />;
    }

    function handleNavError(error) {
        let errors = error.response.data.message.split(',').map((value) => Number.parseInt(value));
        setNavErrors(errors);
    }

    function validatePaymentClosure(candidature, payments) {
        const {approved, paid} = calculateTotals(candidature, payments);

        const FINAL_MAX_PERCENTAGE = 0.35

        return function (values, props) {
            const errors = {};
            if('purpose' in values && 'type' in values && CandidaturePaymentPurpose.values().includes(values.purpose) && CandidaturePaymentType.values().includes(values.type)) {
                const total = approved[values.purpose];
                const hasBeenPaid = {
                    [ADVANCE]: payments.filter(p => p.purpose === values.purpose).some(p => p.type === ADVANCE),
                    [FINAL]: payments.filter(p => p.purpose === values.purpose).some(p => p.type === FINAL)
                };

                console.log(parseFloat(roundToTwo((values.value / total))))
                console.log(values.value)
                console.log(total)
                console.log(roundToTwo((values.value / total)))
                switch (values.type) {
                    case ADVANCE:
                        if(parseFloat(roundToTwo((values.value / total))) !== ADVANCE_PERCENTAGE) errors.value = <FormattedMessage id='candidature.payments.form.error.advancePercentage'/>;
                        if(hasBeenPaid[ADVANCE]) errors.type = <FormattedMessage id='candidature.payments.form.error.duplicateAdvance' />;
                        break;
                    case FINAL:
                        if(parseFloat(roundToTwo((values.value / total))) > FINAL_MAX_PERCENTAGE) errors.value = <FormattedMessage id='candidature.payments.form.error.finalPercentage'/>;
                        if(hasBeenPaid[FINAL]) errors.type = <FormattedMessage id='candidature.payments.form.error.duplicateFinal'/>;
                        if(!hasBeenPaid[ADVANCE]) errors.type = <FormattedMessage id='candidature.payments.form.error.finalWithoutAdvance'/> ;
                        if(!('file' in values) || values.file === null) errors.file = <FormattedMessage id='candidature.payments.form.error.noDocument'/>;
                        break;
                }
            }
            console.log(errors)

            return errors;
        }
    }

    const validationSchema = yup.object().shape({
        date: yup
            .date()
            .typeError(<FormattedMessage id='errors.fieldRequiredText'/>)
            .max(new Date(), <FormattedMessage id='candidature.payments.form.error.future' />)
            .min(candidature.creationDate, <FormattedMessage id='candidature.payments.form.error.past'/>)
            .required(<FormattedMessage id='errors.fieldRequiredText'/>),
        type: yup
            .mixed()
            .oneOf(CandidaturePaymentType.values(), <FormattedMessage id='errors.fieldRequiredText'/>)
            .required(<FormattedMessage id='errors.fieldRequiredText'/>),
        purpose: yup
            .string()
            .oneOf(CandidaturePaymentPurpose.values(), <FormattedMessage id='errors.fieldRequiredText'/>)
            .required(<FormattedMessage id='errors.fieldRequiredText'/>),
        value: yup
            .number()
            .test(
                    'isValidValue',
                <FormattedMessage id='candidature.payments.form.error.zeroPayment' />,
                    (value) =>  value !== 0.00,

            )

    });

    async function onSubmit(values, setSubmitting, setErrors) {
        setSubmitting(true);
        try {
            if (values.type === FINAL) {
                let {file: formData, ...rest} = values;
                formData.set('payment', JSON.stringify(rest));
                await savePaymentWithDocument(externalId, formData)
            } else {
                await savePayment(externalId, values);
            }
            setSubmitted(true);
        } catch (error) {
            console.log(error);
            setErrors(error);
        }
        setSubmitting(false);
    }



    function handleTypeChange(setFieldValue,value,values) {
        if(value ===CandidaturePaymentType.ADVANCE && values.purpose !=='-1'){
            const approved = candidature.financialInvestmentDetails
                .filter(f => f.kind === 'FINAL' && 'financialInvestmentType' in f && f.financialInvestmentType === 'TOTAL')[0]

            setFieldValue('value',roundToTwo((values.purpose === CandidaturePaymentPurpose.INVESTMENT ? approved.investmentSupport : approved.workStationsCreationBenefits)  * ADVANCE_PERCENTAGE) );
        }else{
            setFieldValue('value',0);
        }

        setFieldValue('type', value);
    }


    function handlePurposeChange(setFieldValue,value,values) {
        if(value !=='-1' && values.type !=='-1'){
            const approved = candidature.financialInvestmentDetails
                .filter(f => f.kind === 'FINAL' && 'financialInvestmentType' in f && f.financialInvestmentType === 'TOTAL')[0]
            setFieldValue('value',roundToTwo((value === CandidaturePaymentPurpose.INVESTMENT ? approved.investmentSupport : approved.workStationsCreationBenefits)  * ADVANCE_PERCENTAGE) );
        }else{
            setFieldValue('value',0);
        }

        setFieldValue('purpose', value);
    }





    if (submitted) {
        navigate('/candidatura/' + externalId + '/acompanhamento/payments', { state: { refresh: true } });
    }

    return (
        <SubTemplate
            titleId={'candidature.payment.registration.title'}
            centerContent
            hasBackButton>
            <Container>
                <Row>
                    <Col md={{span:8, offset:2}}>
                        <AlertError error={error} />
                        <Formik
                            initialValues={{date: null, type: '-1', purpose: '-1', value: 0, file: null}}
                            validationSchema={validationSchema}
                            validateOnBlur={false}
                            validateOnChange={false}
                            validate={validatePaymentClosure(candidature, payments)}
                            onSubmit={(values, { setSubmitting, setErrors }) => {
                                onSubmit(values, setSubmitting, setErrors);
                            }}>
                            {({ values,
                                  errors,
                                  touched,
                                  handleChange,
                                  handleSubmit,
                                  setFieldValue
                              }) => (
                                <Form onSubmit={handleSubmit}>
                                    <AlertError error={errors} />
                                    <Form.Group controlId="formPaymentRegistration">
                                        <Row>
                                            <Col md={{span:4}}>
                                                <DateTimeInputField
                                                    name='date'
                                                    labelId='candidature.payment.list.dateText'
                                                    value={values.date}
                                                    isDateWithNoTime={true}
                                                    setFieldValue={setFieldValue}
                                                    errorMessage={errors.date}
                                                />
                                            </Col>
                                            <Col>
                                                <EnumSelectField
                                                    name='type'
                                                    labelId='candidature.payment.list.typeText'
                                                    options={CandidaturePaymentType.values()}
                                                    preffixDescriptionId='candidature.payment.type'
                                                    nullOption={true}
                                                    errorMessage={errors.type}
                                                    handleChange={(e) => handleTypeChange(setFieldValue,e.target.value,values)}
                                                />
                                            </Col>
                                            <Col>
                                                <EnumSelectField
                                                    name='purpose'
                                                    labelId='candidature.payment.list.purposeText'
                                                    options={CandidaturePaymentPurpose.values()}
                                                    preffixDescriptionId='candidature.payment.purpose'
                                                    nullOption={true}
                                                    errorMessage={errors.purpose}
                                                    handleChange={(e) => handlePurposeChange(setFieldValue,e.target.value,values)}
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={{span:4}}>
                                                {values.type===CandidaturePaymentType.ADVANCE ?
                                                <div>
                                                    <Form.Label className='col-sm-8 '>
                                                        <FormattedMessage id={'candidature.payment.list.valueText'} />

                                                    </Form.Label>
                                                    {values.value} €

                                                </div>
                                                    :

                                                    <DecimalInputField
                                                        name='value'
                                                        labelId='candidature.payment.list.valueText'
                                                        suffixLabel='€'
                                                        errorMessage={errors.value}
                                                        handleChange={(e) => setFieldValue('value', e.target.value)}
                                                        allowNegative={values.type===CandidaturePaymentType.FINAL}
                                                        value ={values.value}

                                                    />

                                                }

                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Container className='mt-3'>
                                                    {values.type === FINAL && (
                                                        <SingleUploadArea
                                                            documents={documents}
                                                            setDocuments={setDocuments}
                                                            documentType={'PAYMENT_SERVICE_INFORMATION'}
                                                            setError={setError}
                                                            acceptExtensions='.pdf,.docx'
                                                            processUploadCallback={formData => setFieldValue('file', formData)}
                                                        />

                                                    )}
                                                    {"file" in errors &&
                                                    <Form.Control.Feedback type='invalid'>{errors.file}</Form.Control.Feedback>
                                                    }
                                                </Container>
                                            </Col>
                                        </Row>
                                        <Row className='form-actions justify-content-end'>
                                            <Col className='justify-content-end' md={{offset: 10}}>
                                                <button className='btn btn-primary' type='submit' >
                                                    <FormattedMessage id='all.submitButtonText' />
                                                </button>
                                            </Col>
                                        </Row>
                                    </Form.Group>
                                </Form>
                            )}
                        </Formik>
                    </Col>
                </Row>
            </Container>
        </SubTemplate>
    );
}