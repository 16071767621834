import React from 'react';
import {Col, Form, Row, Table} from 'react-bootstrap';
import {FaSearch} from 'react-icons/fa';
import {FormattedMessage, useIntl} from 'react-intl';
import {EnumSelectField} from '../bootstrap/EnumSelectField';
import {isIEFPUser} from "../../authentication/authenticationHelper";
import {EntityChangeState} from "../../models/EntityChangeState";
import {EntityChangeType} from "../../models/EntityChangeType";
import {TextAreaInputField} from "../bootstrap/TextAreaInputField";
import {SyncMultipleUploadArea} from "../SyncMultipleUploadArea";
import {DocumentType} from "../../models/DocumentType";
import {TextInputField} from "../bootstrap/TextInputField";
import {NumberInputField} from "../bootstrap/NumberInputField";

export function EntityChangeLocationData({entityChange}) {


    const intl = useIntl();

    return (
        <>

            <Row className={'mb-3'}>
                <Col>
                    <Form.Label>
                        <FormattedMessage id='eaAccreditationForm.step5.incubatorLocations' />
                    </Form.Label>
                </Col>
            </Row>
            {entityChange.locationsChange.map((location, index) => (
                <Row  key={index} className={'mb-3 bg-light border p-2'}>
                    <Col>
                <h5 className={'mb-0 text-primary'}><FormattedMessage id='eaAccreditationForm.step5.accordion.title' /> {' '}
                    {index + 1}

                </h5>
                    <Row className={'mt-0 pt-0 mb-0'}>
                        <Col>
                            <TextInputField
                                labelId={'promoterForm.parish'}
                                value={location.allDescriptions}
                                disabled={true}
                            />
                        </Col>
                    </Row>
                    <Row className={'mt-0 pt-0'}>
                        <Col>
                            <TextInputField
                                labelId={'all.address'}
                                value={location.extraLocation}
                                disabled={true}
                            />
                        </Col>
                    </Row>
                    </Col>
                </Row>

                ))}


            </>
    );
}
