import React from "react";
import {FormattedMessage} from "react-intl";
import {Button, Form} from "react-bootstrap";
import {hasPermission} from "../../authentication/authenticationHelper";
import {useGlobalContext} from "../../context";
import {useNavigate} from "react-router-dom";
import {
    canAddUnemploymentBenefitsPaymentRecord,
} from "./candidaturePaymentFunctions";
export function PaymentActionsMenuBar({candidature, setUnemploymentPaymentDelegationShow, hasNoIrregNorNonComp}) {
    const { accessInfo } = useGlobalContext();
    const navigate = useNavigate();

    function goToPaymentRegistration() {
        navigate('/candidatura/' + candidature.externalId + '/pagamentos/registar', { state: { refresh: true } });
    }

    return (
        <div className='d-grid gap-4'>
            {hasPermission('REGISTER_CANDIDATURE_PAYMENT', accessInfo) && hasNoIrregNorNonComp && (
                <>
                    <Button variant='outline-primary' onClick={goToPaymentRegistration}>
                        <FormattedMessage id='candidature.payment.register.button.title' />
                    </Button>
                    {canAddUnemploymentBenefitsPaymentRecord(candidature) && (
                    <Button variant='outline-primary' onClick={() => setUnemploymentPaymentDelegationShow(true)}>
                        <FormattedMessage id='candidature.button.delegatePaymentSS' />
                    </Button>)}
                </>
            )}
        </div>
    );
}