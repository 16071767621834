import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { FaInfoCircle } from 'react-icons/fa';
import { FormattedMessage, useIntl } from 'react-intl';
import { TextInputField } from '../bootstrap/TextInputField';
import ToolTip from '../bootstrap/ToolTip';
import { SingleUploadArea } from '../SingleUploadArea';

export function PromoterFormStep3({
  values,
  userDocuments,
  setFieldValue,
  handleChange,
  handleSubmit,
  setError,
  numberOfSteps,
  errors
}) {
  const documentType = 'IEFP_DECLARATION';
  const intl = useIntl();
  const [files, setFiles] = useState(getInitialFiles());

  function getInitialFiles() {
    let files = [];

    let userDocumentTypes = userDocuments.map((ud) => ud.documentType);

    if (userDocumentTypes.includes(documentType)) {
      const document = userDocuments.find((ud) => ud.documentType === documentType);

      files.push({
        externalId: document.externalId,
        documentType: document.documentType,
        documentPath: document.documentPath,
        name: document.name,
        content: '',
        submited: true,
        error: ''
      });
    } else {
      files.push({
        externalId: '',
        documentType: documentType,
        name: '',
        content: null,
        submited: false,
        error: ''
      });
    }
    return files;
  }

  function handleFinalStep() {
    handleSubmit();
  }

  return (
    <div>
      <h4 className='text-primary mb-0'>
        <FormattedMessage id='promoterForm.step3.title' />
      </h4>
      <small className='text-secondary text-uppercase'>
        {intl.formatMessage(
          { id: 'all.step' },
          { step: values.step, numberOfSteps: numberOfSteps }
        )}
      </small>

      <p className='mt-3 mb-0 text-secondary infoText'>
        <FaInfoCircle /> <FormattedMessage id='promoterForm.infoText1' />
      </p>

      <Row>
        <Col md='4'>
          <TextInputField
            controlId={'iefpNumber'}
            labelId={'promoterForm.iefpNumber'}
            toolTip={<ToolTip message={<FormattedMessage id='promoterForm.iefpNumberToolTip' />} />}
            name={'iefpNumber'}
            handleChange={handleChange}
            value={values.iefpNumber}
          />
        </Col>
      </Row>

      <div className='mt-3 px-2'>
        <SingleUploadArea
          documents={files}
          setDocuments={setFiles}
          documentType={documentType}
          setError={setError}
        />
      </div>

      <div className='form-actions'>
        <button
          className='btn btn-link px-0'
          type='button'
          onClick={(e) => setFieldValue('step', values.step - 1)}>
          <FormattedMessage id='all.back' />
        </button>
        <button className='btn btn-primary' type='button' onClick={(e) => handleFinalStep()}>
          <FormattedMessage id='all.submitButtonText' />
        </button>
      </div>
    </div>
  );
}
