import React, {useEffect, useState} from 'react';
import { FaInfoCircle } from 'react-icons/fa';
import { FormattedMessage, useIntl } from 'react-intl';
import parse from 'html-react-parser';
import {Col, Form, Row} from "react-bootstrap";
import {listDocumentsByTypeAndCandidatureExternalId} from "../../rest/document";
import {getCae} from "../../rest/candidature";
import {PromoterType} from "../../models/PromoterType";
import {TranslateBoolean} from "../../utils/TranslateBoolean";
import {getParishByCode} from "../../rest/administrativeDivision";
import {CandidatureFinancialUtils} from "../../utils/CandidatureFinancialUtils";
import {SelectBooleanField} from "../bootstrap/SelectBooleanField";
import {TextAreaInputField} from "../bootstrap/TextAreaInputField";
import Table from "../divTable/Table";
import {currencyFormat, maxFixed} from "../../utils/CurrencyUtils";
import {formatValue} from "react-currency-input-field";
import {AccretionViewer} from "./AccretionViewer";



export function CandidatureFormStep4({
  errors,
  values,
  setFieldValue,
  handleSave,
  handleGoBack,
  numberOfSteps
}) {






    function handleIsInnovationProjectChange(name, index, value) {


      values.candidatureEmployeeSupportOtherDetails[index][name] = value;
        setFieldValue('candidatureEmployeeSupportOtherDetails', [
            ...values.candidatureEmployeeSupportOtherDetails
        ]);
        setAccretionTotal(CandidatureFinancialUtils.getAccretionTotal(hasSubRepresentation,inInside,levelsAccretion,iefpAccretion,values.candidatureEmployeeSupportOtherDetails));
    }


    function handleReasonChange(e,index) {
        values.candidatureEmployeeSupportOtherDetails[index]['reasonToInnovationProject'] = e.target.value;
        setFieldValue('candidatureEmployeeSupportOtherDetails', [
            ...values.candidatureEmployeeSupportOtherDetails
        ]);
    }

    const [hasSubRepresentation, setHasSubRepresentation] = useState(false);
    const [inInside, setinInside] = useState(false);
    const [levelsAccretion, setLevelsAccretion] = useState(0);
    const [iefpAccretion, setIefpAccretion] = useState(0);
    const[accretionTotal,setAccretionTotal] = useState(0);


    
    async function fetchData() {
        const [ subRepresentation,inSide,levels,iefpAccretionIn ] = await Promise.all([
            await CandidatureFinancialUtils.getSubRepresentation(
                values.candidatureDetails.cae,values.promotersMetaData
            ),
            await CandidatureFinancialUtils.getIsInside(values.parish),
            await  CandidatureFinancialUtils.getLevelsAccretion(values.candidatureEmployeeSupportOtherDetails),
            await  CandidatureFinancialUtils.getIefpAccretion(values.candidatureEmployeeSupportOtherDetails)

        ]);

        setHasSubRepresentation(subRepresentation);
        setinInside(inSide)
        setLevelsAccretion(levels)
        setIefpAccretion(iefpAccretionIn)

        const accretionTotal = await CandidatureFinancialUtils.getAccretionTotal(subRepresentation,inSide,levels,iefpAccretionIn,values.candidatureEmployeeSupportOtherDetails)
        setAccretionTotal(accretionTotal);


    }

    useEffect(() => {
        fetchData();
    }, []);

  

  const intl = useIntl();

  function handleNextStep() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    handleSave(true);
  }



return (
    <div>
      <h4 className='text-primary mb-0'>
          <FormattedMessage id='candidatureForm.accretion.title' />
      </h4>
      <small className='text-secondary text-uppercase'>
        {intl.formatMessage(
          { id: 'all.step' },
          { step: values.step, numberOfSteps: numberOfSteps }
        )}
      </small>
      <br/>
      <FaInfoCircle size={40} className='mt-3'/>


          <label>{parse( intl.formatMessage({id:'candidatureForm.accretion.infoText1'}))}</label>
          <label className='mx-3'><FormattedMessage id={'candidatureForm.accretion.infoText2'}/></label>
          <p className='mx-3'>
              <strong>

                  {hasSubRepresentation=== true ? (intl.formatMessage(
                      { id: 'accretion.with.value' },
                      {value:15}
                  )) : <FormattedMessage id={'accretion.without'}/>}
              </strong>
          </p>

        <label  className='mx-3'><FormattedMessage id={'candidatureForm.accretion.infoText3'}/></label>
        {values.candidatureEmployeeSupportOtherDetails.map((detail, index) => (

            <React.Fragment key={index}>
                <Row className='mb-3'>
                    <Col md={12} className='mx-3 pe-5'>
                        <SelectBooleanField
                            name={'iSInnovationProject'+index}
                            labelId='candidatureForm.accretion.iSInnovationProject'
                            value={detail.iSInnovationProject}
                            setFieldValue={(name,value) => handleIsInnovationProjectChange('iSInnovationProject', index, value)}
                            isInvalid={ errors.candidatureEmployeeSupportOtherDetails?.[index]?.iSInnovationProject}
                            errorMessage={errors.candidatureEmployeeSupportOtherDetails?.[index]?.iSInnovationProject}
                            nullOption={true}
                        />
                    </Col>

                    {detail.iSInnovationProject===true &&
                    <Col md='12' className='mx-3 pe-5'>
                        <TextAreaInputField
                            labelId={'candidatureForm.accretion.reason'}
                            name={'reasonToInnovationProject'+index}
                            handleChange={(e) => handleReasonChange(e,index)}
                            value={detail.reasonToInnovationProject}
                            isInvalid={ errors.candidatureEmployeeSupportOtherDetails?.[index]?.reasonToInnovationProject}
                            errorMessage={errors.candidatureEmployeeSupportOtherDetails?.[index]?.reasonToInnovationProject}
                            maxLength={5000}
                        />
                    </Col>
                    }
                    <p className='mx-3 mt-2'>
                        <strong>

                            {detail.iSInnovationProject===true ? (intl.formatMessage(
                                { id: 'accretion.with.value' },
                                {value:15}
                            )) : <FormattedMessage id={'accretion.without'}/>}
                        </strong>
                    </p>


                </Row>




            </React.Fragment>
            ))}
        <label  className='mx-3'><FormattedMessage id={'candidatureForm.accretion.infoText4'}/></label>
        <p className='mx-3'>
            <strong>

                {levelsAccretion!== 0 ? (intl.formatMessage(
                    { id: 'accretion.with.value' },
                    {value:levelsAccretion}
                )) : <FormattedMessage id={'accretion.without'}/>}
            </strong>
        </p>
        <label  className='mx-3'><FormattedMessage id={'candidatureForm.accretion.infoText5'}/></label>
            <p className='mx-3'>
                <strong>

                    {inInside=== true ? (intl.formatMessage(
                        { id: 'accretion.with.value' },
                        {value:25}
                    )) : <FormattedMessage id={'accretion.without'}/>}
                </strong>
            </p>
          <label className='mx-3'><FormattedMessage id={'candidatureForm.accretion.infoText6'}/></label>
        <p className='mx-3'>
            <strong>

                {iefpAccretion!== 0 ? (intl.formatMessage(
                    { id: 'accretion.with.value' },
                    {value:iefpAccretion}
                )) : <FormattedMessage id={'accretion.without'}/>}
            </strong>
        </p>
          {parse( intl.formatMessage({id:'candidatureForm.accretion.infoText7'}))}





        {values.financialInvestmentDetails.filter(
                (ipd) => ipd.financialInvestmentType === 'TOTAL'
            ).map((investment, index) => (

            <AccretionViewer investment={investment}  plan={ values.investmentPlanDetails.filter((ipd) => ( ipd.investmentType === 'TOTAL' && ipd.kind===investment.kind))[0]} accretionTotal={accretionTotal} index={index}/>
            ))}




        <div className='form-actions mt-5'>
          <button
            className='btn btn-link'
            type='button'
            onClick={() => handleGoBack(values, setFieldValue)}>
            <FormattedMessage id='all.back' />
          </button>

          <button className='btn btn-primary' type='button' onClick={() => handleNextStep()}>
            <FormattedMessage id='all.nextStep' />
          </button>
        </div>

    </div>
  );
}
