import React from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import ToolTip from './ToolTip';
import { NumericFormat } from 'react-number-format';
import {maxFixed} from "../../utils/CurrencyUtils";
import CurrencyInput from 'react-currency-input-field';

export function DecimalInputField({
  controlId,
  labelId,
  label,
  inputLabel,
  name,
  handleChange,
  value,
  errorMessage,
  isInvalid,
  disabled,
  placeHolder,
  vertical,
  suffixLabel,
  toolTip,
  allowNegative,
  showErrorOnToolTip,
    decimalScale,
  size,
    maxValue,
    inputClass
}) {

let stateValue;



  const onValueChange = ((value, name) =>{
   if(typeof value === 'undefined' || value ===''){
      value= 0;
    }else if(allowNegative===true &&  value==='-0'){
     value='-'
   }else if(value.length>1 && value.startsWith('00') ){
     value=value.substring(1,value.length);
   }




  if(!maxValue || value<= maxValue){
    handleChange({target:{value:value, name: name}});
  }




  /*if(e.formattedValue === ''){
      e.value=0;
      e.floatValue=0;
      e.formattedValue='0.00'
    }
    stateValue = e.value;
    e.value= maxFixed(parseFloat(e.value))
    e.floatValue= maxFixed(parseFloat(e.value))

    let indexOf = e.formattedValue.indexOf('.');
    if (indexOf !== -1 && e.formattedValue.length - 1 - indexOf > 2) {
     e.formattedValue = e.formattedValue.substring(0,indexOf+2);
    }*/


  });

  const onChange = (e) => {
    if(e.nativeEvent.data ===','){
      e.currentTarget.value =e.target.value+"."
    }

  };

  const handleKeyDown = (e) => {
    if(['k','b','m','K','B','M'].includes(e.key)){
      e.preventDefault();
    }

  };




  const Onout = (e) => {
    let value =e.target.value;

    if(typeof value === 'undefined' || value ===''){
      value= 0;
    }

    handleChange({target:{value:value}});





  };

  const handleKeyPress = (e) => {

    if(e.key === ','){
      e.target.value = e.target.value+".";
    }
  }

  return (

    <Form.Group controlId={controlId}>
      {vertical ? (
        <div className='form-group row mb-4 d-flex align-items-center'>
          {(label || labelId) && (
            <Form.Label className='col-sm-8 mt-0'>
              {labelId && <FormattedMessage id={labelId} />}
              {label && label}
            </Form.Label>
          )}
          <div className='col-sm-4'>
            {inputLabel && <div className='d-inline w-50 text-secondary pb-1'>{inputLabel}</div>}
            {!suffixLabel ? (
                <CurrencyInput
                    className='decimalInput'  defaultValue={0} allowNegativeValue={allowNegative!==undefined && allowNegative===true}
                    name={name} value={value}  isInvalid ={isInvalid} disabled={disabled}
                    placeholder={placeHolder} customInput={Form.Control}
                    onValueChange={onValueChange} decimalScale={decimalScale? decimalScale :2}
                    decimalsLimit={decimalScale? decimalScale :2}  groupSeparator={' '} decimalSeparator={'.'} onKeyPress= {handleKeyPress} onKeyDown={handleKeyDown}

                />

            ) : (
              <InputGroup className={inputClass}>
                <CurrencyInput
                    className='decimalInput'  defaultValue={0} allowNegativeValue={allowNegative!==undefined &&  allowNegative===true}
                     name={name} value={value}  isInvalid ={isInvalid} disabled={disabled}
                    placeholder={placeHolder} customInput={Form.Control}
                    onValueChange={onValueChange} decimalScale={decimalScale? decimalScale :2}
                    decimalsLimit={decimalScale? decimalScale :2}  groupSeparator={' '} decimalSeparator={'.'} suffix={suffixLabel} onKeyPress= {handleKeyPress} onKeyDown={handleKeyDown}

                />


              </InputGroup>
            )}
          </div>
        </div>
      ) : (
        <>
          {(label || labelId) && (
            <Form.Label>
              {labelId && <FormattedMessage id={labelId} />}
              {label && label}
              {toolTip && toolTip}
            </Form.Label>
          )}
          {inputLabel && <div className='d-inline w-50 text-secondary pb-1'>{inputLabel}</div>}
          {!suffixLabel ? (
            <InputGroup className={inputClass}>
              <CurrencyInput
                  className='decimalInput'  defaultValue={0} allowNegativeValue={allowNegative!==undefined && allowNegative===true}
                   name={name} value={value}  isInvalid ={isInvalid} disabled={disabled}
                  placeholder={placeHolder} customInput={Form.Control}
                  onValueChange={onValueChange} decimalScale={decimalScale? decimalScale :2}
                  decimalsLimit={decimalScale? decimalScale :2}  groupSeparator={' '} decimalSeparator={'.'} onKeyPress= {handleKeyPress} onKeyDown={handleKeyDown}

              />

              {showErrorOnToolTip && isInvalid && <ToolTip error='true' message={errorMessage} />}
            </InputGroup>
          ) : (
            <InputGroup className={inputClass}>




              <CurrencyInput
                  className='decimalInput'  defaultValue={0} allowNegativeValue={allowNegative!==undefined && allowNegative===true}
                   name={name} value={value}  isInvalid ={isInvalid} disabled={disabled}
                  placeholder={placeHolder} customInput={Form.Control}
                  onValueChange={onValueChange} decimalScale={decimalScale? decimalScale :2}
                  decimalsLimit={decimalScale? decimalScale :2}  groupSeparator={' '} decimalSeparator={'.'}  onKeyPress= {handleKeyPress} onKeyDown={handleKeyDown}
                  suffix={suffixLabel}

              />





              {showErrorOnToolTip && isInvalid && <ToolTip error='true' message={errorMessage} />}
            </InputGroup>
          )}
        </>
      )}
      {errorMessage && !showErrorOnToolTip && (
        <Form.Control.Feedback type='invalid'>{errorMessage}</Form.Control.Feedback>
      )}
    </Form.Group>
  );
}

export default DecimalInputField;
