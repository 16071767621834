import { apiAxios as authAxios } from '../axios.config';

export const listProcesses = (index, size, filters) => {
  return authAxios.get('/api/process', {
    params: {
      index: index,
      size: size,
      state: filters.state,
      type: filters.type,
      nif: filters.nif
    }
  });
};

export const getEntityProcessListForIefp = (entityNif, queryParams) => {
  return authAxios.get(`/api/process/entity/${entityNif}`, {params:  queryParams})
}