import React, {useState} from 'react';
import {Col, Row} from 'react-bootstrap';
import {FormattedMessage} from 'react-intl';
import {CandidatureEmployeeSupportUtils, IAS} from '../../utils/CandidatureEmployeeSupportUtils';
import {TranslateBoolean} from '../../utils/TranslateBoolean';
import {EnumSelectField} from '../bootstrap/EnumSelectField';
import {PromoterType} from "../../models/PromoterType";
import {TimeType} from "../../models/TimeType";
import ToolTip from "../bootstrap/ToolTip";
import {Arrays} from "../../utils/Arrays";
import IntInputField from "../bootstrap/IntInputField";

export function EmployeeSupportDetailsTab({candidature}) {
    const [employeeDetails, setEmployeeDetails] = useState(getInitialValues());


    const [employeeOtherDetails, setEmployeeOtherDetails] = useState(candidature.candidatureEmployeeSupportOtherDetails);
    const [title, setTitle] = useState('ALL');

    const promoterValues = getInitialValues().filter((detail) => detail.kind === 'PROMOTER');
    const iefpValues = getInitialValues().filter((detail) => detail.kind === 'IEFP');
    const finalValues = getInitialValues().filter((detail) => detail.kind === 'FINAL');

    console.log(iefpValues)




    function getInitialValues() {



        let values = [];
        let promotersFiltered = candidature.promotersMetaData.filter(p => p.promoterType === PromoterType.PRIMARY || p.promoterType === PromoterType.SECONDARY);
        candidature.candidatureEmployeeSupportDetails.forEach((detail) => {
            let valueToPush = {};


            valueToPush = {
                externalId: detail.externalId,
                internalId: detail.internalId,
                kind: detail.kind,
                promoterMetaData: promotersFiltered.find(
                    (p) => p.externalId === detail.promoterMetaData
                ).name,
                timeType: detail.timeType,
                hours: detail.hours
            };

            let totalSubsidyValue = (detail.timeType === 'TOTAL') ? 15 * detail.IAS : parseFloat(15 * detail.IAS) * parseInt(detail.hours) / 40;


            valueToPush.totalSubsidy = parseFloat(totalSubsidyValue.toFixed(2));


            values.push(valueToPush);
        });

        console.log(values)


        return values;
    }

    function handleChange(e) {
        switch (e.target.value) {
            case 'PROMOTER':
                setEmployeeDetails(promoterValues);
                setEmployeeOtherDetails(candidature.candidatureEmployeeSupportOtherDetails.filter(detail=>detail.kind===e.target.value))
                setTitle('PROMOTER');
                break;

            case 'IEFP':
                setEmployeeDetails(iefpValues);
                setEmployeeOtherDetails(candidature.candidatureEmployeeSupportOtherDetails.filter(detail=>detail.kind===e.target.value))
                setTitle('IEFP');
                break;

            case 'FINAL':
                setEmployeeDetails(finalValues);
                setEmployeeOtherDetails(candidature.candidatureEmployeeSupportOtherDetails.filter(detail=>detail.kind===e.target.value))
                setTitle('FINAL');
                break;

            case 'ALL':
                setEmployeeDetails(getInitialValues());
                setEmployeeOtherDetails(candidature.candidatureEmployeeSupportOtherDetails)
                setTitle('ALL');
                break;

            default:
                break;
        }
    }

    function getWorkstationsTypeFilterOptions() {
        let options = ['ALL', 'PROMOTER'];

        if (iefpValues.length > 0 || candidature.candidatureEmployeeSupportOtherDetails.filter(detail=>detail.kind==='IEFP').length>0) {
            options.push('IEFP');
        }

        if (finalValues.length > 0 || candidature.candidatureEmployeeSupportOtherDetails.filter(detail=>detail.kind==='FINAL').length>0) {
            options.push('FINAL');
        }

        return options;
    }

    function displayDetails(arr,otherArr) {
        if(arr.length===0 && otherArr.length===0){
            return ''
        }
        return (
            <div className='mb-5 border px-3'>
                {arr.map((detail, index) => (
                    <div key={index} className='border-bottom'>


                        {title === 'ALL' && index === 0 && (
                            <React.Fragment>

                                <p className='mt-3'>
                                    <strong>
                                        <FormattedMessage id={`candidatureTabs.workstations.title.${detail.kind}`}/>
                                    </strong>
                                </p>

                            </React.Fragment>

                        )}
                        {index === 0 &&
                        <h5 className='text-secondary mt-4'>
                            <FormattedMessage id={`candidatureForm.workStations.ownJobs`}/>
                            </h5>
                        }

                        <p className='mt-4 mb-3'>Posto de trabalho {index + 1}</p>


                        <Row>
                            <Col md='6'>
                                <label>
                                    <FormattedMessage
                                        id='workStations.promoter'/>
                                </label>
                                <p>
                                    {detail.promoterMetaData}
                                </p>
                            </Col>
                            <Col md={detail.timeType !== TimeType.TOTAL ? '3' : '6'}>
                                <label>
                                    <FormattedMessage
                                        id='workStations.timeType'/>
                                </label>
                                <p>
                                    <FormattedMessage id={`workStations.timeType.${detail.timeType}`}/>
                                </p>
                            </Col>
                            {detail.timeType !== TimeType.TOTAL &&
                            <Col md='3'>
                                <label>
                                    <FormattedMessage
                                        id='workStations.hours'/>
                                </label>
                                <p>
                                    {detail.hours}
                                </p>
                            </Col>
                            }
                        </Row>

                        {employeeDetails.length !== 0 && (
                            <div className='d-flex justify-content-between align-items-center mb-2'>
                                <p className='mb-0'>
                                    <span className='text-secondary'>Total: </span> {detail.totalSubsidy}€
                                </p>
                            </div>
                        )}
                    </div>

                ))}
                {arr.length > 0 &&
                <p className='mt-3'>
                  <span className='text-secondary'>
                    <FormattedMessage id='candidatureTabs.totalJobSubsidyValue'/>
                  </span>
                            {CandidatureEmployeeSupportUtils.getTotalJobSubsidyValue(arr)}€
                </p>
                }


                {otherArr.map((detail, index) => (
                    <React.Fragment key={index}>
                        {(title === 'ALL' && index === 0 && (getInitialValues().filter((detail1) => detail1.kind ===detail.kind ).length===0)) && (
                            <React.Fragment>

                                <p className='mt-3'>
                                    <strong>
                                        <FormattedMessage id={`candidatureTabs.workstations.title.${detail.kind}`}/>
                                    </strong>
                                </p>

                            </React.Fragment>

                        )}
                        {index === 0 &&
                        <h5 className='text-secondary mt-4'>
                            <FormattedMessage id={`candidatureForm.workStations.otherJobs`}/>
                        </h5>
                        }





                        <Row>
                            <Col md='4'>
                                <label>
                                    <FormattedMessage
                                        id='workStations.level5to7'/>
                                </label>
                                <p>
                                    {detail.level5to7}
                                </p>
                            </Col>
                            <Col md='4'>
                                <label>
                                    <FormattedMessage
                                        id='workStations.level8'/>
                                </label>
                                <p>
                                    {detail.level8}
                                </p>
                            </Col>
                            <Col md='4'>
                                <label>
                                    <FormattedMessage
                                        id='workStations.jobsOnIEFP'/>
                                </label>
                                <p>
                                    {detail.jobsOnIEFP}
                                </p>
                            </Col>


                        </Row>
                        <Row>
                            <Col md={'4'}>
                            <label>
                                <FormattedMessage
                                    id='workStations.newJobs'/>
                            </label>
                            <p>
                                {detail.newJobs}
                            </p>

                            </Col>
                            {candidature.candidatureDetails.wasCreated ===true  &&

                            <Col md={'4'}>
                                <label>
                                    <FormattedMessage
                                        id='workStations.oldJobs'/>
                                </label>
                                <p>
                                    {detail.oldJobs}
                                </p>
                            </Col>

                            }


                        </Row>
                    </React.Fragment>





                    ))}


            </div>)


    }


    return (
        <div className='py-3 mb-4'>
            <Row className='mb-5'>
                <Col md='4'>
                    <EnumSelectField
                        labelId={'candidatureTabs.workStations.filter'}
                        controlId={'workStationTypeFilter'}
                        name={'workStationTypeFilter'}
                        preffixDescriptionId={'candidatureTabs.workstations'}
                        handleChange={(e) => handleChange(e)}
                        options={getWorkstationsTypeFilterOptions()}
                    />
                </Col>
            </Row>

            <Row>
                <Col>
                    <small className='text-primary text-uppercase mt-2 mb-2'>
                        <FormattedMessage id={`candidatureTabs.workstations.title.${title}`}/>
                    </small>
                </Col>
            </Row>

            {title === 'ALL' ? (
                <>
                    {displayDetails(employeeDetails.filter((ed) => ed.kind === 'PROMOTER'),candidature.candidatureEmployeeSupportOtherDetails.filter(detail=>detail.kind==='PROMOTER'))}
                    {displayDetails(employeeDetails.filter((ed) => ed.kind === 'IEFP'),candidature.candidatureEmployeeSupportOtherDetails.filter(detail=>detail.kind==='IEFP'))}
                    {displayDetails(employeeDetails.filter((ed) => ed.kind === 'FINAL'),candidature.candidatureEmployeeSupportOtherDetails.filter(detail=>detail.kind==='FINAL'))}
                </>
            ) : (
                displayDetails(employeeDetails,employeeOtherDetails)
            )}


        </div>
    );
}
