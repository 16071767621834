import {Formik} from 'formik';
import React, {useState} from 'react'
import {Form} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import {FormattedMessage, useIntl} from 'react-intl';
import {SingleUploadArea} from "../SingleUploadArea";

export function FinalDecisionForm({ onSubmit, userDocuments, externalId,setError,approvalType }) {


    const [privateDocuments, setPrivateDocuments] = useState(getInitialPrivateDocuments());
    const [publicDocuments, setPublicDocuments] = useState(getInitialPublicDocuments());


    function getInitialPrivateDocuments() {
        let files = [];


        const aux = userDocuments.filter(d => d.documentType === 'FINAL_DECISION_PRIVATE');

        if (aux.length === 0) {
            files.push({
                externalId: '',
                documentType: 'FINAL_DECISION_PRIVATE',
                name: '',
                content: null,
                submited: false,
                error: '',
            });
        } else {
            files.push({
                externalId: aux[0].externalId,
                documentType: aux[0].documentType,
                documentPath: aux[0].documentPath,
                name: aux[0].name,
                content: '',
                submited: true,
                error: '',
            });
        }


        return files;
    }


    function getInitialPublicDocuments() {
        let files = [];


        const aux = userDocuments.filter(d => d.documentType === 'FINAL_DECISION');

        if (aux.length === 0) {
            files.push({
                externalId: '',
                documentType: 'FINAL_DECISION',
                name: '',
                content: null,
                submited: false,
                error: '',
            });
        } else {
            files.push({
                externalId: aux[0].externalId,
                documentType: aux[0].documentType,
                documentPath: aux[0].documentPath,
                name: aux[0].name,
                content: '',
                submited: true,
                error: '',
            });
        }


        return files;
    }

  return (
    <div className='mb-5'>
      <h2 className='mb-1 text-secondary'> <FormattedMessage id='finalDecision.title' /> (<FormattedMessage id={`finalDecision.type.${approvalType}`} />)  </h2>
      <p className='mb-5 text-secondary'>
        Adicione documentos
      </p>

      <Formik
        initialValues={[]}
        validateOnBlur={false}
        validateOnChange={false}
        onSubmit={(values, { setSubmitting, setErrors }) => {
          onSubmit(values, setSubmitting, setErrors);
        }}
      >
        {({ values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          setFieldValue 
        }) => (
          <Form 
            onSubmit={(e) => { 
              e.preventDefault(); 
              handleSubmit(); 
            }}>


              <SingleUploadArea
                  documents={publicDocuments}
                  setDocuments={setPublicDocuments}
                  documentType={'FINAL_DECISION'}
                  setError={setError}
                  candidatureExternalId={externalId}
              />

              <SingleUploadArea
                  documents={privateDocuments}
                  setDocuments={setPrivateDocuments}
                  documentType={'FINAL_DECISION_PRIVATE'}
                  setError={setError}
                  candidatureExternalId={externalId}
              />



            <div className='form-actions'>
              <Link to={`/candidatura/${externalId}`}>
                <button className='btn btn-link' type='button' >
                  <FormattedMessage id='all.backButtonText' />
                </button>
              </Link>


                {(privateDocuments[0].submited===true && publicDocuments[0].submited===true)&&(

                    <button
                        className='btn btn-primary'
                        type='submit'
                        //onClick={() => handleClick(values)}
                    >
                        <FormattedMessage id='all.submitButtonText' />
                    </button>

                )}

            </div>

          </Form>
        )}



      </Formik>
    </div>
  )
}
