import React from 'react';
import {Col, Form, Row} from 'react-bootstrap';
import {FormattedMessage, useIntl} from 'react-intl';
import {TextAreaInputField} from '../../bootstrap/TextAreaInputField';

export function FinalReportFormStep8({
                                         formStep,
                                         numberOfSteps,
                                         errors,
                                         formikValues,
                                         handleChange,
                                         handlePreviousStep,
                                         handleNextStep,
                                         entityGetList,readMode
                                     }) {
    const intl = useIntl();

    return (
        <div>
            <h4 className='text-primary mb-0'>
                <FormattedMessage id='finalReportForm.sideNavBar.globalEvaluation'/>
            </h4>
            <small className='text-secondary text-uppercase'>
                {intl.formatMessage(
                    {id: 'all.step'},
                    {step: formStep, numberOfSteps: numberOfSteps}
                )}
            </small>
            <fieldset disabled={readMode == true}>
            <Row>
                <Col>
                    <TextAreaInputField
                        labelId='finalReportForm.sideNavBar.globalEvaluation'
                        placeholder={intl.formatMessage({id: 'finalReportForm.step8.globalEvaluation.placeholder'})}
                        name='globalEvaluation'
                        handleChange={handleChange}
                        value={formikValues.globalEvaluation}
                        isInvalid={errors.globalEvaluation}
                        errorMessage={errors.globalEvaluation}
                    />
                </Col>
            </Row>
            <Row>
                <Col>
					<Form.Group>
						<Form.Label className='col-sm-8'>
						<FormattedMessage id={'finalReportForm.technicalTeamManager'} />
						</Form.Label>

                    <Form.Control
                        as='select'
                        name={'identificationEa.technicalTeamManagerExternalId'}
                        onChange={handleChange}
                        value={
                            formikValues.identificationEa.technicalTeamManagerExternalId
                        }
                        disabled={true}
                    >
                        <option value={'-1'}>
                            <FormattedMessage id='finalReportForm.choose.firstStep'/>
                        </option>
                        {entityGetList.map((get) => (
                            <option key={get.externalId} value={get.externalId}>
                                {get.name}
                            </option>
                        ))}

                    </Form.Control>
					</Form.Group>
                </Col>
            </Row>
            </fieldset>
            <div className='form-actions mt-5'>
                <button
                    className='btn btn-link'
                    type='button'
                    onClick={handlePreviousStep}
                >
                    <FormattedMessage id='all.back'/>
                </button>

                <button
                    className='btn btn-primary'
                    type='button'
                    onClick={handleNextStep}
                >
                    <FormattedMessage id='all.nextStep'/>
                </button>
            </div>
        </div>
    );
}
