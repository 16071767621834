import {cancelApprovalDecisionExpiration, reject, submitProposalDecisionExpiration} from '../../rest/candidature';
import {useParams} from 'react-router-dom';
import {CandidatureExpiration} from "../../components/candidature/CandidatureExpiration";

export function CancelExpirationFinalDecision() {
    const {externalId} = useParams();

    const submit = async () => {
        await cancelApprovalDecisionExpiration(externalId);
        sessionStorage.setItem('cancelApprovalDecisionExpiration','true');
    }

    return (
        <CandidatureExpiration
            validationTitle='candidatureExpirationFinal.no.title.reject'
            messageConfirmation='candidatureExpirationFinal.no.message.reject'
            callbackHandler={submit}
            isApprove={false}
        />
    );

}
