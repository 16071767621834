import {maxFixed} from "./CurrencyUtils";
import {getCae} from "../rest/candidature";
import {PromoterType} from "../models/PromoterType";
import {getParishByCode} from "../rest/administrativeDivision";
import {AccretionViewer} from "../components/candidature/AccretionViewer";
import React from "react";
import {ConfigObject} from "../config";

export const CandidatureFinancialUtils = {
  getTotalEligibleInvestment: (values) => getTotalEligibleInvestment(values),
  getTotalOwnCapitals: (values) => getTotalOwnCapitals(values),
  getTotalForeignCapitals: (values) => getTotalForeignCapitals(values),
  getInvestmentSupportValue: (values) => getInvestmentSupportValue(values),
  getTotalOwnInvestment: (values) => getTotalOwnInvestment(values),
  getTotalInvestmentSupport: (values) => getTotalInvestmentSupport(values),
  getTotalValueByName: (name, values) => getTotalValueByName(name, values),

  calculateProvideInformationTotalValueByYear: (values, year) =>
    calculateProvideInformationTotalValueByYear(values, year),

  calculatePeopleBoardTotalValue: (values, year, peopleType) =>
    calculatePeopleBoardTotalValue(values, year, peopleType),

  calculatePeopleRemunerationTotalValue: (values, year, remunerationType) =>
    calculatePeopleRemunerationTotalValue(values, year, remunerationType),

  calculateAmortizationExpensesTotalValue: (lines) =>
    calculateAmortizationExpensesTotalValue(lines),

  calculateAmortizationExpensesYearTotalValue: (lines, year) =>
    calculateAmortizationExpensesYearTotalValue(lines, year),

  calculateEarlyAmortizationTotalValueByYear: (values, year) =>
    calculateEarlyAmortizationTotalValueByYear(values, year),

  getSubRepresentation: (caeIn,promotersMetaData) =>
      getSubRepresentation(caeIn,promotersMetaData),

  getIsInside: (parish) =>
      getIsInside(parish),

  getLevelsAccretion: (candidatureEmployeeSupportOtherDetails) =>
      getLevelsAccretion(candidatureEmployeeSupportOtherDetails),

  getIefpAccretion: (candidatureEmployeeSupportOtherDetails) =>
      getIefpAccretion(candidatureEmployeeSupportOtherDetails),

  getAccretionTotal: (hasSubRepresentation,inInside,levelsAccretion,iefpAccretion,candidatureEmployeeSupportOtherDetails) =>
      getAccretionTotal(hasSubRepresentation,inInside,levelsAccretion,iefpAccretion,candidatureEmployeeSupportOtherDetails),


  getOwnCapitalTotalAbsolute: (investment) =>
      getOwnCapitalTotalAbsolute (investment),

  calculateIefpGivePercentage: (investment) =>
      calculateIefpGivePercentage (investment),


  calculateNotRefundTax: (investment,accretionTotal) =>
      calculateNotRefundTax (investment,accretionTotal),


  calculateNotRefundAbsolute: (investment,plan,accretionTotal) =>
      calculateNotRefundAbsolute (investment,plan,accretionTotal),

  calculateNotRefundWithAccretion: (investment,plan,accretionTotal) =>
      calculateNotRefundWithAccretion (investment,plan,accretionTotal),

  getInterestFreeLoan: (investment,plan,accretionTotal) =>
      getInterestFreeLoan (investment,plan,accretionTotal),


  getFreeLoans: (values) =>
      getFreeLoans (values)

};

function getInterestFreeLoan(investment,plan,accretionTotal){
  let maxValue = parseFloat(ConfigObject.get().maxFinancedValue);
  let investmentValue = parseFloat(investment.investmentSupport)
  if(parseFloat(investment.iefpAskedAmount)>maxValue){
    let diff = parseFloat(investment.iefpAskedAmount) - maxValue;
    investmentValue-=diff;
  }

  let value =   maxFixed(parseFloat(calculateNotRefundAbsolute(investment,plan,accretionTotal)) + parseFloat(maxFixed(calculateNotRefundAbsolute(investment,plan,accretionTotal) * accretionTotal / 100))) > maxFixed(parseFloat(maxFixed(investmentValue)) ) ?
     0 :maxFixed(maxFixed(parseFloat(maxFixed(investmentValue)) )  -maxFixed(parseFloat(calculateNotRefundAbsolute(investment,plan,accretionTotal)) + parseFloat(maxFixed(calculateNotRefundAbsolute(investment,plan,accretionTotal) * accretionTotal / 100))) );



  return value;
}

function calculateNotRefundWithAccretion(investment,plan,accretionTotal){
  let maxValue = parseFloat(ConfigObject.get().maxFinancedValue);
  let investmentValue = parseFloat(investment.investmentSupport)
  if(parseFloat(investment.iefpAskedAmount)>maxValue){
    let diff = parseFloat(investment.iefpAskedAmount) - maxValue;
    investmentValue-=diff;
  }



  let calculated =   maxFixed(parseFloat(CandidatureFinancialUtils.calculateNotRefundAbsolute(investment,plan,accretionTotal)) + parseFloat(maxFixed(CandidatureFinancialUtils.calculateNotRefundAbsolute(investment,plan,accretionTotal) * accretionTotal / 100)))

  return  calculated> investmentValue ? investmentValue : calculated;
}






async function getFreeLoans(values) {
  const [subRepresentation, inSide, levels, iefpAccretionIn] = await Promise.all([
    await CandidatureFinancialUtils.getSubRepresentation(
        values.candidatureDetails.cae, values.promotersMetaData
    ),
    await CandidatureFinancialUtils.getIsInside(values.parish),
    await CandidatureFinancialUtils.getLevelsAccretion(values.candidatureEmployeeSupportOtherDetails),
    await CandidatureFinancialUtils.getIefpAccretion(values.candidatureEmployeeSupportOtherDetails)

  ]);
  const accretionTotal = await CandidatureFinancialUtils.getAccretionTotal(subRepresentation, inSide, levels, iefpAccretionIn, values.candidatureEmployeeSupportOtherDetails)


  let freeLoans2 = await CandidatureFinancialUtils.getInterestFreeLoan(values.financialInvestmentDetails.find(
      (ipd) => ipd.financialInvestmentType === 'TOTAL'
  ),values.investmentPlanDetails.filter((ipd) => ( ipd.investmentType === 'TOTAL'))[0], accretionTotal);

  return freeLoans2;
}


function calculateIefpGivePercentage(investment){
  if(investment.globalTotal === 0){
    return 0;
  }
  return maxFixed((parseFloat(investment.iefpAskedAmount)) * 100 / parseFloat(investment.globalTotal));
}

function calculateNotRefundTax(investment){
  return maxFixed(calculateIefpGivePercentage(investment)*40/85);
}

function calculateNotRefundAbsolute(investment,plan,accretionTotal){
  let valueToCompare = parseFloat(plan.globalTotal);
  const totalValueMax = parseFloat(ConfigObject.get().maxFinancedValue);
  if(valueToCompare> totalValueMax){
    valueToCompare = totalValueMax;
  }
  let notRefund= valueToCompare*0.4 ;
  let notRefundWithAccretion = notRefund + (notRefund * parseFloat(accretionTotal/100) )
  return maxFixed(  (notRefundWithAccretion> parseFloat(investment.investmentSupport)) ? parseFloat(investment.investmentSupport) : notRefund );
}





function getOwnCapitalTotalAbsolute (investment){
  return maxFixed( (isNaN(investment.ownCapital) ? 0 : parseFloat(investment.ownCapital )));
}



function getIefpAccretion(candidatureEmployeeSupportOtherDetails) {
  if(!candidatureEmployeeSupportOtherDetails){
    return 0;
  }
  let calculateValue = 0;
  if(Array.isArray(candidatureEmployeeSupportOtherDetails)){

    calculateValue = candidatureEmployeeSupportOtherDetails[0].jobsOnIEFP * 2.5;
  }else{
    calculateValue = candidatureEmployeeSupportOtherDetails.jobsOnIEFP * 2.5;
  }
  return(calculateValue>30? 30 : calculateValue);
}


function getAccretionTotal(hasSubRepresentation,inInside,levelsAccretion,iefpAccretion,candidatureEmployeeSupportOtherDetails){
  console.log("parseFloat(hasSubRepresentation===true ? 15: 0)",parseFloat(hasSubRepresentation===true ? 15: 0))
  console.log('parseFloat(inInside===true ? 25 : 0)',parseFloat(inInside===true ? 25 : 0))
  console.log('parseFloat(levelsAccretion)',parseFloat(levelsAccretion))
  console.log('parseFloat(iefpAccretion)',parseFloat(iefpAccretion))


  let total = parseFloat(hasSubRepresentation===true ? 15: 0) + parseFloat(inInside===true ? 25 : 0) + parseFloat(levelsAccretion) + parseFloat(iefpAccretion);
  console.log(total)
  if(Array.isArray(candidatureEmployeeSupportOtherDetails)) {
    if (candidatureEmployeeSupportOtherDetails?.[0]?.iSInnovationProject === true) {
      total += 15;
    }
  }else{
    if (candidatureEmployeeSupportOtherDetails?.iSInnovationProject === true) {
      total += 15;
    }
  }
  return(total);
}




function getLevelsAccretion(candidatureEmployeeSupportOtherDetails) {
  if(!candidatureEmployeeSupportOtherDetails){
    return 0;
  }
  let calculateValue = 0;
  if(Array.isArray(candidatureEmployeeSupportOtherDetails)){
    calculateValue = candidatureEmployeeSupportOtherDetails[0].level5to7 * 2.5 + candidatureEmployeeSupportOtherDetails[0].level8*5;
  }else{
    calculateValue = candidatureEmployeeSupportOtherDetails.level5to7 * 2.5 + candidatureEmployeeSupportOtherDetails.level8*5;
  }
  return (calculateValue>15? 15 : calculateValue);
}


async function getIsInside(parishIn) {
  let inside = false;
  try {
    if (parishIn) {
      const [{data: parishObject}] = await Promise.all([
        await getParishByCode(
            parishIn
        ),
      ]);
      inside = (parishObject.inside===true);
    }

  } catch (error) {
    // ignore;
  }
 return inside;
}


async function getSubRepresentation(caeIn,promotersMetaData) {
  let representative = false;
  try {
    if (caeIn) {
      const [{data: cae}] = await Promise.all([
        await getCae(
            caeIn
        ),
      ]);
      if (cae.lessRepresentative) {
        let totals = promotersMetaData.filter(pM => (pM.promoterType === PromoterType.PRIMARY
            || pM.promoterType === PromoterType.SECONDARY) && pM.gender === cae.lessRepresentative)
            .reduce(function (obj, item) {
              obj.voteRight += parseFloat(item.voteRight);
              obj.socialCapital += parseFloat(item.socialCapital);
              return obj;
            }, {voteRight: 0, socialCapital: 0});
        representative = (totals.voteRight > 50 && totals.socialCapital > 50);

      }
    }

  } catch (error) {
    // ignore;
    console.error(error)
  }

  return representative;
}










function getTotalEligibleInvestment(values) {

  const total =values.investmentPlanDetails.find((ipd) => ipd.investmentType === 'TOTAL').globalTotal

  return !isFinite(total) || total < 0 || typeof total !== 'number'
    ? 0
    : parseFloat(total.toFixed(2));
}

function getTotalOwnCapitals(values) {
  const total =
    values.candidatureFinancialDetails.socialCapital +
    values.candidatureFinancialDetails.unemploymentBenefits +
    values.candidatureFinancialDetails.otherBenefits;

  return !isFinite(total) || total < 0 || typeof total !== 'number'
    ? 0
    : parseFloat(total.toFixed(2));
}

function getTotalForeignCapitals(values) {
  const total =
    values.candidatureFinancialDetails.bankLoans +
    values.candidatureFinancialDetails.partnerLoans +
    values.candidatureFinancialDetails.supplierCredits +
    values.candidatureFinancialDetails.otherForeignCapital;

  return !isFinite(total) || total < 0 || typeof total !== 'number'
    ? 0
    : parseFloat(total.toFixed(2));
}

function getTotalInvestmentSupport(values) {
  let total =
    getTotalEligibleInvestment(values) -
    (getTotalOwnCapitals(values) + getTotalForeignCapitals(values));

  return !isFinite(total) || total < 0 ? 0 : total;
}

function getInvestmentSupportValue(values) {
  let total = (
    100 -
    (getTotalOwnInvestment(values) / getTotalEligibleInvestment(values)) * 100
  ).toFixed(2);

  return !isFinite(total) || total < 0 ? 0 : parseFloat(total);
}

function getTotalOwnInvestment(values) {
  let total = getTotalOwnCapitals(values) + getTotalForeignCapitals(values);

  return !isFinite(total) || total < 0 ? 0 : parseFloat(total);
}

function getTotalValueByName(name, values) {
  const valuesToSend = { candidatureFinancialDetails: values };

  switch (name) {
    case 'totalEligibleInvestment':
      return getTotalEligibleInvestment(valuesToSend);

    case 'totalOwnCapitals':
      return getTotalOwnCapitals(valuesToSend);

    case 'totalForeignCapitals':
      return getTotalForeignCapitals(valuesToSend);

    default:
      break;
  }
}

function calculateProvideInformationTotalValueByYear(values, year) {
  let total = 0;

  const cells = values.provideInformationList[0].provideInformationCellList.filter(
    (picl) => picl.year === year
  );

  cells.forEach((cell) => (total += parseFloat(cell.value)));

  return total;
}

function calculatePeopleBoardTotalValue(values, year, peopleType) {
  let total = 0;

  const cells = values.peopleBoardList[0].peopleBoardCellList.filter(
    (pbcl) => pbcl.year === year && pbcl.peopleType === peopleType
  );

  cells.forEach((cell) => (total += parseFloat(cell.value)));

  return total;
}

function calculatePeopleRemunerationTotalValue(values, year, remunerationType) {
  let total = 0;

  const cells = values.peopleRemunerationList[0].peopleRemunerationCellList.filter(
    (prcl) => prcl.year === year && prcl.remunerationType === remunerationType
  );

  cells.forEach((cell) => (total += parseFloat(cell.value)));

  return total;
}

function calculateAmortizationExpensesTotalValue(lines) {
  let total = 0;

  lines.forEach((line) => (total += parseFloat(line.value)));

  return total;
}

function calculateAmortizationExpensesYearTotalValue(lines, year) {
  let total = 0;

  let fieldName = '';

  switch (year) {
    case 0:
      fieldName = 'amortizationFirstYear';
      break;

    case  1:
      fieldName = 'amortizationSecondYear';
      break;

    case  2:
      fieldName = 'amortizationThirdYear';
      break;

    case  3:
      fieldName = 'amortizationFourthYear';
      break;

    case  4:
      fieldName = 'amortizationFifthYear';
      break;

    default:
      break;
  }

  lines.forEach((line) => (total += parseFloat(line[fieldName])));

  return total;
}

function calculateEarlyAmortizationTotalValueByYear(values, year) {
  let total = 0;

  values.earlyAmortizationList[0].earlyAmortizationCellList
    .filter((eacl) => eacl.year === year)
    .forEach((eacl) => {
      total += parseFloat(eacl.value);
    });

    return total;
}
