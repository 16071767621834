import React, { useEffect, useState } from 'react';
import { TextInputField } from '../../bootstrap/TextInputField';
import { Row, Col } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { DateTimeInputField } from '../../bootstrap/DateTimeInputField';

export function SemestralReportFormStep3({
	formStep,
	numberOfSteps,
	errors,
	handleChange,
	formikValues,
	handlePreviousStep,
	handleNextStep,
	setFieldValue,
	projectApprovalDateAsInput,
	responsible,
	readMode,
											 hideNextStep
}) {
	const intl = useIntl();

	return (
		<div>
			<h4 className='text-primary mb-0'>
				<FormattedMessage id='semestralReportForm.step3.title' />
			</h4>
			<small className='text-secondary text-uppercase'>
				{intl.formatMessage(
					{ id: 'all.step' },
					{ step: formStep, numberOfSteps: numberOfSteps }
				)}
			</small>
			<fieldset disabled={readMode == true}>
				<Row>
					<Col md='6'>
						<DateTimeInputField
							name='projectApproval.projectApprovalDate'
							disabled={projectApprovalDateAsInput === undefined || readMode}
							labelId={'semestralReportForm.step3.projectApproval'}
							value={formikValues.projectApproval.projectApprovalDate}
							isDateWithNoTime={true}
							setFieldValue={setFieldValue}
							isInvalid={errors.projectApproval?.projectApprovalDate}
							errorMessage={errors.projectApproval?.projectApprovalDate}
						/>
					</Col>
					<Col md='6'>
						<TextInputField
							disabled
							labelId={'semestralReportForm.step3.processNumber'}
							value={formikValues.projectApproval.candidatureProcessNumber}
						/>
					</Col>
				</Row>
				{responsible && (
					<Row>
						<Col>
							<TextInputField
								labelId={responsible.labelId}
								name={'responsible'}
								handleChange={handleChange}
								value={formikValues.responsible}
								isInvalid={errors.responsible ? true : false}
								errorMessage={errors.responsible ? errors.responsible : ''}
							/>
						</Col>
					</Row>
				)}
			</fieldset>
			<div className='form-actions mt-5'>
				<button
					className='btn btn-link'
					type='button'
					onClick={handlePreviousStep}
				>
					<FormattedMessage id='all.back' />
				</button>

				{hideNextStep!==true &&
				<button
					className='btn btn-primary'
					type='button'
					onClick={handleNextStep}
					>
					<FormattedMessage id='all.nextStep' />
					</button>
				}

			</div>
		</div>
	);
}
