export const CandidatureState = {
  DRAFT: 'DRAFT',
  SUBMITTED: 'SUBMITTED',
  WAIT_PROMOTER_ELIGIBILITY_VALIDATION: 'WAIT_PROMOTER_ELIGIBILITY_VALIDATION',
  WAIT_CONTESTATION_ELIGIBILITY_VALIDATION:'WAIT_CONTESTATION_ELIGIBILITY_VALIDATION',
  CONTESTATION_ANALYSIS_ELIGIBILITY_VALIDATION: 'CONTESTATION_ANALYSIS_ELIGIBILITY_VALIDATION',
  EMIT_OPINION: 'EMIT_OPINION',
  NEW_EMIT_OPINION: 'NEW_EMIT_OPINION',
  FINAL_DECISION: 'FINAL_DECISION',
  FINAL_DECISION_WAIT_RESPONSE:'FINAL_DECISION_WAIT_RESPONSE',
  WAIT_CONTESTATION: 'WAIT_CONTESTATION',
  CONTESTATION_ANALYSIS: 'CONTESTATION_ANALYSIS',
  REJECTED: 'REJECTED',
  WAIT_PROMOTER_RESPONSE_TO_OPINION: 'WAIT_PROMOTER_RESPONSE_TO_OPINION',
  VALIDATION: 'VALIDATION',
  WAIT_PROMOTER_RESPONSE_TO_VALIDATION: 'WAIT_PROMOTER_RESPONSE_TO_VALIDATION',
  WAIT_TERM_ACCEPT: 'WAIT_TERM_ACCEPT',
  WAIT_TERM_ACCEPT_SUBMISSION: 'WAIT_TERM_ACCEPT_SUBMISSION',
  FULLY_APPROVED: 'FULLY_APPROVED',
  APPROVED: 'APPROVED',
  VALIDATE_REQUIRED_DOCUMENTS: 'VALIDATE_REQUIRED_DOCUMENTS',
  APPROVAL_DECISION_EXPIRATION: 'APPROVAL_DECISION_EXPIRATION',
  EMIT_DECISION_EXPIRATION: 'EMIT_DECISION_EXPIRATION',
  WAIT_CONTESTATION_EXPIRATION:'WAIT_CONTESTATION_EXPIRATION',
  CONTESTATION_ANALYSIS_EXPIRATION:  'CONTESTATION_ANALYSIS_EXPIRATION',
  APPROVAL_EXPIRED:'APPROVAL_EXPIRED',
  WITHDRAW: 'WITHDRAW',
  CANCEL:'CANCEL',
  DELETED:'DELETED',
  DELETED_NO_VALIDATION:'DELETED_NO_VALIDATION',
  WAIT_PROMOTER_RESPONSE_TO_ELIGIBILITY: 'WAIT_PROMOTER_RESPONSE_TO_ELIGIBILITY',
  REVOKED_NON_COMPLIANCE: 'REVOKED_NON_COMPLIANCE',
  WAIT_ADDITIONAL_DUE_DILIGENCE_RESPONSE: 'WAIT_ADDITIONAL_DUE_DILIGENCE_RESPONSE',



values: () => {
    return [
      'DRAFT',
      'SUBMITTED',
      'WAIT_PROMOTER_ELIGIBILITY_VALIDATION',
      'WAIT_CONTESTATION_ELIGIBILITY_VALIDATION',
      'EMIT_OPINION',
      'NEW_EMIT_OPINION',
      'FINAL_DECISION',
      'FINAL_DECISION_WAIT_RESPONSE',
      'WAIT_CONTESTATION',
      'CONTESTATION_ANALYSIS',
      'REJECTED',
      'WAIT_PROMOTER_RESPONSE_TO_OPINION',
      'VALIDATION',
      'WAIT_PROMOTER_RESPONSE_TO_VALIDATION',
      'WAIT_TERM_ACCEPT',
      'WAIT_TERM_ACCEPT_SUBMISSION',
      'FULLY_APPROVED',
      'APPROVED',
      'VALIDATE_REQUIRED_DOCUMENTS',
      'APPROVAL_DECISION_EXPIRATION',
      'EMIT_DECISION_EXPIRATION',
      'WAIT_CONTESTATION_EXPIRATION',
      'CONTESTATION_ANALYSIS_EXPIRATION',
      'CONTESTATION_ANALYSIS_ELIGIBILITY_VALIDATION',
      'APPROVAL_EXPIRED',
      'WITHDRAW',
      'CANCEL',
      'DELETED',
      'DELETED_NO_VALIDATION',
      'WAIT_PROMOTER_RESPONSE_TO_ELIGIBILITY',
      'WAIT_ADDITIONAL_DUE_DILIGENCE_RESPONSE'
    ];
  },

  statesBeforeVALIDATION: () => {
    return [
      'DRAFT',
      'SUBMITTED',
      'WAIT_PROMOTER_ELIGIBILITY_VALIDATION',
      'EMIT_OPINION',
      'NEW_EMIT_OPINION',
      'WAIT_PROMOTER_RESPONSE_TO_OPINION',
      'WAIT_CONTESTATION_ELIGIBILITY_VALIDATION',
        'CONTESTATION_ANALYSIS_ELIGIBILITY_VALIDATION',
        'WITHDRAW',
        'VALIDATION',
        'WAIT_PROMOTER_RESPONSE_TO_VALIDATION',
      'WAIT_PROMOTER_RESPONSE_TO_ELIGIBILITY'
    ];
  },

  activeStates: () => {
    return [
      'EMIT_DECISION_EXPIRATION',
      'CONTESTATION_ANALYSIS',
      'WAIT_TERM_ACCEPT_SUBMISSION',
      'WAIT_PROMOTER_RESPONSE_TO_OPINION',
      'VALIDATE_REQUIRED_DOCUMENTS',
      'SUBMITTED',
      'WAIT_CONTESTATION',
      'WAIT_TERM_ACCEPT',
      'APPROVED',
      'WAIT_PROMOTER_ELIGIBILITY_VALIDATION',
      'VALIDATION',
      'APPROVAL_DECISION_EXPIRATION',
      'FINAL_DECISION',
      'FINAL_DECISION_WAIT_RESPONSE',
      'WAIT_PROMOTER_RESPONSE_TO_VALIDATION',
      'FULLY_APPROVED',
      'EMIT_OPINION',
      'NEW_EMIT_OPINION',
      'WAIT_CONTESTATION_EXPIRATION',
      'CONTESTATION_ANALYSIS_EXPIRATION',
      'WAIT_CONTESTATION_ELIGIBILITY_VALIDATION',
        'CONTESTATION_ANALYSIS_ELIGIBILITY_VALIDATION',
      'WAIT_PROMOTER_RESPONSE_TO_ELIGIBILITY',
        'WAIT_ADDITIONAL_DUE_DILIGENCE_RESPONSE',
    ];
  },

  changeRequestValues: () => {
    return [
      'DRAFT',
      'VALIDATION',
      'WAIT_CONTESTATION',
      'CONTESTATION_ANALYSIS',
      'REJECTED',
      'WAIT_TERM_ACCEPT_SUBMISSION',
      'APPROVED',
      'CANCEL',
    ];
  },

  valuesSortedByFlux: () => {
    return [
      'DRAFT',
      'SUBMITTED',
      'WAIT_PROMOTER_ELIGIBILITY_VALIDATION',
      'WAIT_CONTESTATION_ELIGIBILITY_VALIDATION',
      'WAIT_PROMOTER_RESPONSE_TO_ELIGIBILITY',
      'CONTESTATION_ANALYSIS_ELIGIBILITY_VALIDATION',
      'FINAL_DECISION',
      'EMIT_OPINION',
      'WAIT_PROMOTER_RESPONSE_TO_OPINION',
      'VALIDATION',
      'WAIT_PROMOTER_RESPONSE_TO_VALIDATION',
      'FINAL_DECISION_WAIT_RESPONSE',
      'NEW_EMIT_OPINION',
      'WAIT_CONTESTATION',
      'CONTESTATION_ANALYSIS',
      'REJECTED',
      'APPROVED',
      'VALIDATE_REQUIRED_DOCUMENTS',
      'EMIT_DECISION_EXPIRATION',
      'WAIT_CONTESTATION_EXPIRATION',
      'APPROVAL_DECISION_EXPIRATION',
      'CONTESTATION_ANALYSIS_EXPIRATION',
      'APPROVAL_EXPIRED',
      'WAIT_TERM_ACCEPT',
      'WAIT_TERM_ACCEPT_SUBMISSION',
      'FULLY_APPROVED',
      'WITHDRAW',
      'DELETED',
      'DELETED_NO_VALIDATION',
      'CANCEL'
    ];
  },


  additionalDueDiligenceStates: () => {
    return [
      'CONTESTATION_ANALYSIS_ELIGIBILITY_VALIDATION',
      'CONTESTATION_ANALYSIS_EXPIRATION',
      'APPROVAL_DECISION_EXPIRATION',
      'FINAL_DECISION',
      'CONTESTATION_ANALYSIS'
    ];
  },
};
