import React from 'react';
import {Accordion, Col, Row} from 'react-bootstrap';
import {FormattedMessage} from 'react-intl';
import {ForeCastType} from "../../models/ForeCastType";
import {Arrays} from '../../utils/Arrays'

export function ForeCastDemonstrationAccordionItem({otherChargeList, forecastDemonstrationList, totalsSellInformation, totalsConsumeInformation, totalProvideInformation, peopleRemunerationTotals, totalsOtherCharge,yearNames}) {

    if (forecastDemonstrationList?.length > 0) {
        forecastDemonstrationList[0].columns = forecastDemonstrationList[0].type === ForeCastType.SNC ?
            forecastDemonstrationList[0].forecastDemonstrationSNCColumnList : forecastDemonstrationList[0].forecastDemonstrationNCMColumnList;
    }

    const resultsBeforeTaxFinance = totalResultsBeforeTaxFinance();

    function totalResultsBeforeTaxFinance() {
        let totais = totalsSellInformation.map((totalsSellInformationYear, index) => (
                (totalsSellInformationYear.value
                    + forecastDemonstrationList[0].columns[index].operationSubvention
                    + forecastDemonstrationList[0].columns[index].inventoryProductionVariation
                    + forecastDemonstrationList[0].columns[index].itselfWork
                    - (Arrays.isEmpty(totalsConsumeInformation) ? 0 : totalsConsumeInformation[index].value)
                    - parseFloat(totalProvideInformation[totalsSellInformationYear.year])
                    - parseFloat(peopleRemunerationTotals['YEARLY' + totalsSellInformationYear.year])
                    - ((forecastDemonstrationList[0].type === ForeCastType.SNC) ? forecastDemonstrationList[0].columns[index].impairmentInventory + forecastDemonstrationList[0].columns[index].impairmentReceivables : forecastDemonstrationList[0].columns[index].impairments)
                    - forecastDemonstrationList[0].columns[index].provisions
                    - ((forecastDemonstrationList[0].type === ForeCastType.SNC) ? forecastDemonstrationList[0].columns[index].otherImpairments + forecastDemonstrationList[0].columns[index].fairValueFluctuation : 0)
                    + forecastDemonstrationList[0].columns[index].otherIncomes
                    + forecastDemonstrationList[0].columns[index].otherSpending
                    + otherChargeList[0].otherChargeLineList[index].financialCharges
                )
            )
        )

        return totais;

    }


    return (
        <>
            {(forecastDemonstrationList?.length > 0) && (
                <Accordion.Item eventKey="forecastDemonstration">
                    <Accordion.Header><FormattedMessage
                        id='candidatureTabs.accordion.forecastDemonstration'/></Accordion.Header>
                    <Accordion.Body>
                        <div className='tableWithBorders'>
                            <table className='w-100 mb-5'>
                                <thead>
                                <tr className='border-1 border-bottom '>
                                    <th className='p-lg-1'>
                                        {forecastDemonstrationList[0].type === ForeCastType.SNC ? (
                                            <FormattedMessage id='foreCastDemonstration.description.SNC'/>) : (
                                            <FormattedMessage id='foreCastDemonstration.description.NCM'/>)}


                                    </th>
                                    {forecastDemonstrationList[0].columns.map((line, index) => (
                                        <th className='text-center' key={index}> {yearNames[line.year]}</th>
                                    ))}
                                </tr>
                                </thead>
                                <tbody>
                                <tr className='border-1 border-bottom '>
                                    <td><FormattedMessage id='foreCastDemonstration.sellInformation'/></td>
                                    {totalsSellInformation.map((totalsSellInformationYear, index) => (
                                            <td className="text-center withBorderLeft"
                                                key={index}>{totalsSellInformationYear.value.toFixed(2)} €</td>
                                        )
                                    )}
                                </tr>
                                <tr className='border-1 border-bottom '>
                                    <td><FormattedMessage id='foreCastDemonstration.operationSubvention'/></td>
                                    {forecastDemonstrationList[0].columns.map((line, index) => (
                                        <td className='text-center withBorderLeft'
                                            key={index}> {line.operationSubvention.toFixed(2)} €</td>
                                    ))}
                                </tr>
                                <tr className='border-1 border-bottom '>
                                    <td><FormattedMessage id='foreCastDemonstration.inventoryProductionVariation'/></td>
                                    {forecastDemonstrationList[0].columns.map((line, index) => (
                                        <td className='text-center withBorderLeft'
                                            key={index}> {line.inventoryProductionVariation.toFixed(2)} €</td>
                                    ))}
                                </tr>
                                <tr className='border-1 border-bottom '>
                                    <td><FormattedMessage id='foreCastDemonstration.itselfWork'/></td>
                                    {forecastDemonstrationList[0].columns.map((line, index) => (
                                        <td className='text-center withBorderLeft'
                                            key={index}> {line.itselfWork.toFixed(2)} €</td>
                                    ))}
                                </tr>
                                <tr className='border-1 border-bottom '>
                                    <td><FormattedMessage id='foreCastDemonstration.consumeInformation'/></td>
                                    {totalsConsumeInformation.map((totalsConsumeInformation, index) => (
                                            <td className="text-center withBorderLeft"
                                                key={index}>{totalsConsumeInformation.value.toFixed(2)} €</td>
                                        )
                                    )}

                                </tr>

                                <tr className='border-1 border-bottom '>
                                    <td><FormattedMessage id='foreCastDemonstration.provideInformation'/></td>
                                    {totalsSellInformation.map((totalsSellInformationYear, index) => (
                                            <td className="text-center withBorderLeft"
                                                key={index}>{parseFloat(totalProvideInformation[totalsSellInformationYear.year]).toFixed(2)} €</td>
                                        )
                                    )}
                                </tr>
                                <tr className='border-1 border-bottom '>
                                    <td><FormattedMessage id='foreCastDemonstration.peopleRemuneration'/></td>
                                    {totalsSellInformation.map((totalsSellInformationYear, index) => (
                                            <td className="text-center withBorderLeft"
                                                key={index}>{parseFloat(peopleRemunerationTotals['YEARLY' + totalsSellInformationYear.year]).toFixed(2)} €</td>
                                        )
                                    )}
                                </tr>


                                {forecastDemonstrationList[0].type === ForeCastType.SNC ? (
                                        <>
                                            <tr className='border-1 border-bottom '>
                                                <td><FormattedMessage id='foreCastDemonstration.impairmentInventory'/></td>
                                                {forecastDemonstrationList[0].columns.map((line, index) => (
                                                    <td className='text-center withBorderLeft'
                                                        key={index}> {line.impairmentInventory.toFixed(2)} €</td>
                                                ))}
                                            </tr>
                                            <tr className='border-1 border-bottom '>
                                                <td><FormattedMessage id='foreCastDemonstration.impairmentReceivables'/>
                                                </td>
                                                {forecastDemonstrationList[0].columns.map((line, index) => (
                                                    <td className='text-center withBorderLeft'
                                                        key={index}> {line.impairmentReceivables.toFixed(2)} €</td>
                                                ))}
                                            </tr>
                                        </>
                                    )
                                    : (
                                        <tr className='border-1 border-bottom '>
                                            <td><FormattedMessage id='foreCastDemonstration.impairments'/></td>
                                            {forecastDemonstrationList[0].columns.map((line, index) => (
                                                <td className='text-center withBorderLeft'
                                                    key={index}> {line.impairments.toFixed(2)} €</td>
                                            ))}
                                        </tr>
                                    )}


                                <tr className='border-1 border-bottom '>
                                    <td><FormattedMessage id='foreCastDemonstration.provisions'/></td>
                                    {forecastDemonstrationList[0].columns.map((line, index) => (
                                        <td className='text-center withBorderLeft'
                                            key={index}> {line.provisions.toFixed(2)} €</td>
                                    ))}
                                </tr>


                                {forecastDemonstrationList[0].type === ForeCastType.SNC && (
                                    <>
                                        <tr className='border-1 border-bottom '>
                                            <td><FormattedMessage id='foreCastDemonstration.otherImpairments'/></td>
                                            {forecastDemonstrationList[0].columns.map((line, index) => (
                                                <td className='text-center withBorderLeft'
                                                    key={index}> {line.otherImpairments.toFixed(2)} €</td>
                                            ))}
                                        </tr>
                                        <tr className='border-1 border-bottom '>
                                            <td><FormattedMessage id='foreCastDemonstration.fairValueFluctuation'/></td>
                                            {forecastDemonstrationList[0].columns.map((line, index) => (
                                                <td className='text-center withBorderLeft'
                                                    key={index}> {line.fairValueFluctuation.toFixed(2)} €</td>
                                            ))}
                                        </tr>

                                    </>
                                )
                                }

                                <tr className='border-1 border-bottom '>
                                    <td><FormattedMessage id='foreCastDemonstration.otherIncomes'/></td>
                                    {forecastDemonstrationList[0].columns.map((line, index) => (
                                        <td className='text-center withBorderLeft'
                                            key={index}> {line.otherIncomes.toFixed(2)} €</td>
                                    ))}
                                </tr>

                                <tr className='border-1 border-bottom '>
                                    <td><FormattedMessage id='foreCastDemonstration.otherSpending'/></td>
                                    {forecastDemonstrationList[0].columns.map((line, index) => (
                                        <td className='text-center withBorderLeft'
                                            key={index}> {line.otherSpending.toFixed(2)} €</td>
                                    ))}
                                </tr>

                                <tr className='border-1 border-bottom '>
                                    <td><FormattedMessage id='foreCastDemonstration.financialCharges'/></td>
                                    {otherChargeList[0].otherChargeLineList.map((line, index) => (

                                        <td key={index}
                                            className="text-center withBorderLeft">{line.financialCharges.toFixed(2)} €</td>

                                    ))}
                                </tr>
                                <tr className='border-1 border-bottom'>
                                    <td><strong><p className='text-end mb-0'><FormattedMessage
                                        id='foreCastDemonstration.resultBeforeTaxFinance1'/></p>
                                        <p className='text-end mb-0'><FormattedMessage
                                            id='foreCastDemonstration.resultBeforeTaxFinance2'/></p></strong></td>

                                    {resultsBeforeTaxFinance.map((resultBeforeTaxFinance, index) => (
                                            <td className="text-center withBorderLeft" key={index}>
                                                {resultBeforeTaxFinance.toFixed(2)}
                                                €</td>
                                        )
                                    )}
                                </tr>
                                <tr className='border-1 border-bottom '>
                                    <td><FormattedMessage id='foreCastDemonstration.depreciationAmortization'/></td>
                                    {forecastDemonstrationList[0].columns.map((line, index) => (
                                        <td className='text-center withBorderLeft'
                                            key={index}> {line.depreciationAmortization.toFixed(2)} €</td>
                                    ))}
                                </tr>
                                <tr className='border-1 border-bottom '>
                                    <td><strong><p className='text-end mb-0'><FormattedMessage
                                        id='foreCastDemonstration.operacionalResult1'/></p>
                                        <p className='text-end mb-0'><FormattedMessage
                                            id='foreCastDemonstration.operacionalResult2'/></p></strong></td>
                                    {forecastDemonstrationList[0].columns.map((line, index) => (
                                        <td className='text-center withBorderLeft'
                                            key={index}> {(resultsBeforeTaxFinance[index] - line.depreciationAmortization).toFixed(2)} €</td>
                                    ))}
                                </tr>


                                {forecastDemonstrationList[0].type === ForeCastType.SNC ? (
                                        <>
                                            <tr className='border-1 border-bottom '>
                                                <td><FormattedMessage id='foreCastDemonstration.earnedFees'/></td>
                                                {forecastDemonstrationList[0].columns.map((line, index) => (
                                                    <td className='text-center withBorderLeft'
                                                        key={index}> {line.earnedFees.toFixed(2)} €</td>
                                                ))}
                                            </tr>
                                            <tr className='border-1 border-bottom '>
                                                <td><FormattedMessage id='foreCastDemonstration.supportedFees'/></td>
                                                {forecastDemonstrationList[0].columns.map((line, index) => (
                                                    <td className='text-center withBorderLeft'
                                                        key={index}> {line.supportedFees.toFixed(2)} €</td>
                                                ))}
                                            </tr>
                                        </>
                                    )
                                    : (
                                        <tr className='border-1 border-bottom '>
                                            <td><FormattedMessage id='foreCastDemonstration.netExpense'/></td>
                                            {forecastDemonstrationList[0].columns.map((line, index) => (
                                                <td className='text-center withBorderLeft'
                                                    key={index}> {line.netExpense.toFixed(2)} €</td>
                                            ))}
                                        </tr>
                                    )}

                                <tr className='border-1 border-bottom '>
                                    <td className='text-end'><strong><FormattedMessage
                                        id='foreCastDemonstration.restultBeforeTax'/></strong></td>
                                    {forecastDemonstrationList[0].columns.map((line, index) => (
                                        <td className='text-center withBorderLeft'
                                            key={index}> {((resultsBeforeTaxFinance[index] - line.depreciationAmortization) + (forecastDemonstrationList[0].type === ForeCastType.SNC ? line.earnedFees : 0) - (forecastDemonstrationList[0].type === ForeCastType.SNC ? line.supportedFees : line.netExpense)).toFixed(2)} €</td>
                                    ))}
                                </tr>
                                <tr className='border-1 border-bottom '>
                                    <td><FormattedMessage id='foreCastDemonstration.taxNoIncomePeriod'/></td>
                                    {forecastDemonstrationList[0].columns.map((line, index) => (
                                        <td className='text-center withBorderLeft'
                                            key={index}> {line.taxNoIncomePeriod.toFixed(2)} €</td>
                                    ))}
                                </tr>
                                <tr className='border-1 border-bottom '>
                                    <td className='text-end'><strong><FormattedMessage
                                        id='foreCastDemonstration.netResult'/></strong></td>
                                    {forecastDemonstrationList[0].columns.map((line, index) => (
                                        <td className='text-center withBorderLeft'
                                            key={index}> {((resultsBeforeTaxFinance[index] - line.depreciationAmortization) + (forecastDemonstrationList[0].type === ForeCastType.SNC ? line.earnedFees : 0) - (forecastDemonstrationList[0].type === ForeCastType.SNC ? line.supportedFees : line.netExpense) - line.taxNoIncomePeriod).toFixed(2)} €</td>
                                    ))}
                                </tr>

                                </tbody>


                            </table>
                        </div>


                        <Row>
                            <Col md='12'>
                                <label>
                                    <FormattedMessage id='foreCastDemonstration.reason'/>
                                </label>
                                <p>
                                    {forecastDemonstrationList[0].reasonToForecastDemonstration}
                                </p>
                            </Col>
                        </Row>
                    </Accordion.Body>
                </Accordion.Item>

            )}
        </>
    );
}
