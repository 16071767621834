import React, {useEffect, useState} from 'react'
import {useNavigate, useParams} from 'react-router-dom';
import {AlertError} from '../../components/bootstrap/AlertError';
import Loading from '../../components/general/Loading';
import {SubTemplate} from '../../components/general/SubTemplate';
import {getPromoterCandidature} from '../../rest/promotercandidature';
import {handleError, isNotBusinessError} from '../../utils/handleError';
import {CandidatureState} from "../../models/CandidatureState";
import {AdditionalDueDiligenceAnswerForm} from "../../components/candidature/AdditionalDueDiligenceAnswerForm";
import {
    addAdditionalDueDiligence,
    getAdditionalDueDiligenceByCandidature,
    responseAdditionalDueDiligence,
    responseAdditionalDueDiligenceWithDocuments
} from "../../rest/additionalDueDiligences";
import {Arrays} from "../../utils/Arrays";
import {createMultiPart} from "../../components/SyncMultipleUploadArea";
import {FormattedMessage} from "react-intl";


export function AdditionalDueDiligenceAnswer() {

    const {candidatureExternalId} = useParams();
    const navigate = useNavigate();

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [additionalDueDiligence, setAdditionalDueDiligence] = useState(null);

    async function fetchData() {
        try {
            const [
                {data: additionalDueDiligence}
            ] = await Promise.all([
                getAdditionalDueDiligenceByCandidature(candidatureExternalId)
            ]);
            setAdditionalDueDiligence(additionalDueDiligence);
        } catch (error) {
            setError(error);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        fetchData();
    }, []);

    async function handleSubmit(newAdditionalDueDiligence, documents) {
        try {
            if (Arrays.isNotEmpty(documents)) {
                const formData = createMultiPart(documents);
                formData.append('AdditionalDueDiligenceDto', JSON.stringify(newAdditionalDueDiligence));
                await responseAdditionalDueDiligenceWithDocuments(candidatureExternalId, formData);
            } else {
                await responseAdditionalDueDiligence(candidatureExternalId, newAdditionalDueDiligence);
            }

            sessionStorage.setItem('responseAdditionalDueDiligence', 'true')
            navigate('/candidatura/' + candidatureExternalId);
        } catch (error) {
            console.log(error);
            setError(error);
        }
    }

    if (loading) {
        return <Loading/>;
    }

    if (error && isNotBusinessError(error)) {
        return handleError(error);
    }

    return (
        <SubTemplate hasBackButton
        titleId="candidature.additionalDueDiligenceAnswer.title"
        subTitleId="candidature.additionalDueDiligenceAnswer.instructions">
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-12 mb-5'>
                        <AlertError error={error}/>
                            <AdditionalDueDiligenceAnswerForm
                                additionalDueDiligence={additionalDueDiligence}
                                setAdditionalDueDiligence={setAdditionalDueDiligence}
                                onSubmit={handleSubmit}
                                candidatureExternalId={candidatureExternalId}
                            />
                    </div>
                </div>
            </div>
        </SubTemplate>
    );
}
