import React from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { DateTimeInputField } from '../bootstrap/DateTimeInputField';
import { TextAreaInputField } from '../bootstrap/TextAreaInputField';
import { TextInputField } from '../bootstrap/TextInputField';


export function PriorSupportApplicationStep({step, numberOfSteps,errors,handleChange,formikValues, handleGoBack,handleNextStep,setFieldValue,readMode}) {
    const intl = useIntl();
    

    return (
        <div>
          <h4 className='text-primary mb-0'>
            <FormattedMessage id='projectIdentification.priorsupportapplication' />
          </h4>
          <small className='text-secondary text-uppercase'>
            {intl.formatMessage(
            { id: 'all.step' },
            { step: step, numberOfSteps: numberOfSteps }
            )}
            </small>
            <fieldset disabled={readMode}>
                <Row>
                    <Col md='6'>
                        <DateTimeInputField
                            name={'contractEffectiveDate'}
                            labelId={'priorsupportapplication.effectivedate'}
                            value={formikValues.contractEffectiveDate}
                            isDateWithNoTime={true}
                            setFieldValue={setFieldValue}
                            isInvalid={errors?.contractEffectiveDate ? true : false}
                            errorMessage={
                                errors?.contractEffectiveDate
                                    ? errors.contractEffectiveDate
                                    : ''
                            } 
                        />
                    </Col>
                </Row>
                <Row>
                    <Col md='12'>
                        <TextAreaInputField
                            labelId={'priorsupportapplication.description'}
                            name={'activityDescription'}
                            handleChange={handleChange}
                            value={formikValues.activityDescription}
                            isInvalid={errors?.activityDescription}
                            errorMessage={errors?.activityDescription}
                        />
                    </Col>
                </Row>
            </fieldset>
            <div className='form-actions mt-5'>
                <button
                className='btn btn-link'
                type='button'
                onClick={() => handleGoBack()}
                >
                <FormattedMessage id='all.back' />
                </button>



                <button
                className='btn btn-primary'
                type='button'
                onClick={() => handleNextStep()}
                >
                <FormattedMessage id='all.nextStep' />
                </button>
            </div>
        </div>
    )
}
