export const PriorSupportState = {


  ASKED: 'ASKED',
  ACCEPTED: 'ACCEPTED',
  REJECTED: 'REJECTED',
  WAIT_DOC: 'WAIT_DOC',
  FINISH: 'FINISH',
  CANCEL:'CANCEL',
  AWAIT_REVOKE_DECISION:'AWAIT_REVOKE_DECISION,',
  REVOKED:'REVOKED',

  values: () => {
    return ['ASKED', 'ACCEPTED', 'REJECTED', 'WAIT_DOC', 'FINISH','CANCEL','AWAIT_REVOKE_DECISION','REVOKED'];
  },

};
