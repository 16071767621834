export const ProcessType = {
  NIB_UPLOAD: 'NIB_UPLOAD',
  ECONOMIC_VIABILITY: 'ECONOMIC_VIABILITY',
  B4_FORM: 'B4_FORM',
  B5_FORM: 'B5_FORM',
  B6_FORM: 'B6_FORM',
  B7_FORM: 'B7_FORM',
  B8_FORM: 'B8_FORM',
  B9_FORM: 'B9_FORM',
  B10_FORM: 'B10_FORM',
  B11_FORM: 'B11_FORM',
  B12_FORM: 'B12_FORM',
  B15_FORM: 'B15_FORM',
  B17_FORM: 'B17_FORM',
  B17StartCommunication_FORM: 'B17StartCommunication_FORM',
  B18_FORM: 'B18_FORM',
  B13_FORM:'B13_FORM',
  B16_FORM:'B16_FORM',
  B14_FORM:'B14_FORM',

  values: () => {
    return ['NIB_UPLOAD', 'ECONOMIC_VIABILITY','B4_FORM','B5_FORM', 'B6_FORM','B7_FORM','B8_FORM', 'B9_FORM', 'B10_FORM','B11_FORM', 'B12_FORM','B15_FORM', 'B17_FORM','B17StartCommunication_FORM','B18_FORM','B13_FORM', 'B14_FORM','B16_FORM'];

  },

  entityOwningTypes: () => {
    return ['B4_FORM', 'B5_FORM', 'B6_FORM', 'B7_FORM', 'B9_FORM', 'B10_FORM','B11_FORM', 'B17_FORM','B17StartCommunication_FORM','B18_FORM','B13_FORM','B16_FORM'];
  }
};
