import React, { useEffect, useState } from 'react';
import { Row, Col, Table, Form } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { TextInputField } from '../../bootstrap/TextInputField';
import { NumberInputField } from '../../bootstrap/NumberInputField';
import { TextAreaInputField } from '../../bootstrap/TextAreaInputField';
import { EnumSelectField } from '../../bootstrap/EnumSelectField';
import { FaRegTimesCircle } from 'react-icons/fa';

export function SemestralReportFormStep6({
	formStep,
	numberOfSteps,
	errors,
	handleChange,
	formikValues,
	handlePreviousStep,
	handleNextStep,
	setFieldValue,
	readMode
}) {
	const intl = useIntl();

	function handlerAddNewConsultingAndSkillExtensionActivity() {
		formikValues.consultancyActivitiesResults.consultancyActivities.push({
			area: '',
			objectives: '',
			results: '',
			description: '',
			hoursPredicted: '',
			hoursSpent: '',
		});

		setFieldValue({ ...formikValues });
	}

	function removeConsultancyActivityHandler(index) {
		formikValues.consultancyActivitiesResults.consultancyActivities.splice(index, 1);
		setFieldValue({ ...formikValues });
	}

	const difficultyAreasOptions = [
		'research',
		'management',
		'informationSystems',
		'people',
		'marketing',
		'equipment',
		'financial',
		'otherDifficulty',
	];

	return (
		<div>
			<h4 className='text-primary mb-0'>
				<FormattedMessage id='semestralReportForm.step6.title' />
			</h4>
			<small className='text-secondary text-uppercase'>
				{intl.formatMessage(
					{ id: 'all.step' },
					{ step: formStep, numberOfSteps: numberOfSteps }
				)}
			</small>
			<fieldset disabled={readMode == true}>
			<Row className='mt-4 mb-2'>
				<Form.Label>
					<FormattedMessage id='semestralReportForm.step6.resumeOfSemesterDevelopedActivity' />
				</Form.Label>
			</Row>
			<Row>
				<Col>
					<Table striped responsive='md'>
						<thead>
							<tr>
								<th>
									<FormattedMessage id='semestralReportForm.step6.area' />
								</th>
								<th>
									<FormattedMessage id='semestralReportForm.step6.objectives' />
								</th>
								<th>
									<FormattedMessage id='semestralReportForm.step6.results' />
								</th>
								<th>
									<FormattedMessage id='semestralReportForm.step6.plannedHours' />
								</th>
								<th>
									<FormattedMessage id='semestralReportForm.step6.hoursSpent' />
								</th>
								<th >
									<FormattedMessage id='semestralReportForm.step6.hoursDeviation' />
								</th>
								<th>
									
								</th>
							</tr>
						</thead>
						<tbody>
							{formikValues.consultancyActivitiesResults.consultancyActivities.length > 0 ? (
								formikValues.consultancyActivitiesResults.consultancyActivities.map(
									(activity, index) => (
										<tr key={index}>
											<td>
												<EnumSelectField
													name={`consultancyActivitiesResults.consultancyActivities[${index}].area`}
													handleChange={handleChange}
													value={formikValues.consultancyActivitiesResults.consultancyActivities[index].area}
													isInvalid={
														errors.consultancyActivitiesResults?.consultancyActivities?.[index]?.area
													}
													nullOption={true}
													preffixDescriptionId={
														'semestralReportForm.step5.difficultyArea.option'
													}
													options={difficultyAreasOptions}
													errorMessage={
														errors.consultancyActivitiesResults?.consultancyActivities?.[index]?.area
													}
												/>
											</td>

											<td>
												<TextInputField
													value={
														formikValues.consultancyActivitiesResults.consultancyActivities[
															index
														].objectives
													}
													name={`consultancyActivitiesResults.consultancyActivities[${index}].objectives`}
													handleChange={handleChange}
													isInvalid={
														errors.consultancyActivitiesResults?.consultancyActivities?.[index]?.objectives
													}
													errorMessage={
														errors.consultancyActivitiesResults?.consultancyActivities?.[index]?.objectives
													}
												/>
											</td>

											<td>
												<TextInputField
													value={
														formikValues.consultancyActivitiesResults.consultancyActivities[
															index
														].results
													}
													name={`consultancyActivitiesResults.consultancyActivities[${index}].results`}
													handleChange={handleChange}
													isInvalid={
														errors.consultancyActivitiesResults?.consultancyActivities?.[index]?.results
													}
													errorMessage={
														errors.consultancyActivitiesResults?.consultancyActivities?.[index]?.results
													}
												/>
											</td>

											<td>
												<NumberInputField
													controlId={`consultancyActivitiesResults.consultancyActivities[${index}].hoursPredicted`}
													name={`consultancyActivitiesResults.consultancyActivities[${index}].hoursPredicted`}
													handleChange={handleChange}
													value={
														formikValues.consultancyActivitiesResults.consultancyActivities[
															index
														].hoursPredicted
													}
													isInvalid={
														errors.consultancyActivitiesResults?.consultancyActivities?.[index]?.hoursPredicted
													}
													errorMessage={
														errors.consultancyActivitiesResults?.consultancyActivities?.[index]?.hoursPredicted
													}
												/>
											</td>

											<td>
												<NumberInputField
													controlId={`consultancyActivitiesResults.consultancyActivities[${index}].hoursSpent`}
													name={`consultancyActivitiesResults.consultancyActivities[${index}].hoursSpent`}
													handleChange={handleChange}
													value={
														formikValues.consultancyActivitiesResults.consultancyActivities[
															index
														].hoursSpent
													}
													isInvalid={
														errors.consultancyActivitiesResults?.consultancyActivities?.[index]?.hoursSpent
													}
													errorMessage={
														errors.consultancyActivitiesResults?.consultancyActivities?.[index]?.hoursSpent
													}
												/>
											</td>

											<td>
												{(isNaN(formikValues.consultancyActivitiesResults.consultancyActivities[
														index
														].hoursSpent -
													formikValues.consultancyActivitiesResults.consultancyActivities[
														index
														].hoursPredicted) ? 0 :    (formikValues.consultancyActivitiesResults.consultancyActivities[
													index
												].hoursSpent -
													formikValues.consultancyActivitiesResults.consultancyActivities[
														index
													].hoursPredicted) )+ ' h'}
											</td>
											<td>
												{readMode!==true &&
												<FaRegTimesCircle
													onClick={() =>
														removeConsultancyActivityHandler(index)
													}
													color={"#eb4f24"}
													cursor={"pointer"}
												/>
												}

											</td>
										</tr>
									)
								)
							) : (
								<tr>
									<td colSpan={6}>
										<FormattedMessage id='semestralReportForm.step6.noConsultancyActivitiesResults' />
									</td>
								</tr>
							)}
						</tbody>
						<tfoot>
							<tr>
								<td colSpan={6}>
									<Row>
										<Col>
											<button
												className='btn btn-outline-primary'
												type='button'
												onClick={
													handlerAddNewConsultingAndSkillExtensionActivity
												}
											>
												<FormattedMessage id='all.addButton' />
											</button>
										</Col>
									</Row>
								</td>
							</tr>
						</tfoot>
					</Table>
				</Col>
			</Row>
			<Row>
				<Col>
					<TextAreaInputField
						labelId='semestralReportForm.step6.summaryDescription'
						name={'consultancyActivitiesResults.semesterConsultancyResultsDescription'}
						handleChange={handleChange}
						value={
							formikValues.consultancyActivitiesResults.semesterConsultancyResultsDescription
						}
						isInvalid={
							errors.consultancyActivitiesResults?.semesterConsultancyResultsDescription
								? true
								: false
						}
						errorMessage={
							errors.consultancyActivitiesResults?.semesterConsultancyResultsDescription
								? errors.consultancyActivitiesResults?.semesterConsultancyResultsDescription
								: ''
						}
					/>
				</Col>
			</Row>
			</fieldset>
			<div className='form-actions mt-5'>
				<button
					className='btn btn-link'
					type='button'
					onClick={handlePreviousStep}
				>
					<FormattedMessage id='all.back' />
				</button>

				<button
					className='btn btn-primary'
					type='button'
					onClick={handleNextStep}
				>
					<FormattedMessage id='all.nextStep' />
				</button>
			</div>
		</div>
	);
}
