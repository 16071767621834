import {Formik} from "formik";
import {Col, Container, Form, Row} from "react-bootstrap";
import React from "react";
import {SingleUploadArea} from "../SingleUploadArea";
import {Link} from "react-router-dom";
import {FormattedMessage} from "react-intl";
import {uploadFinalDocument} from "../../rest/entity";
import {DocumentType} from "../../models/DocumentType";

export function EntityAccreditationDocumentsForm({
    documents,
    setDocuments,
    error,
    setError,
    handleSubmit,
    entityNIPC
}) {

    const uploadFunction = (formData) => uploadFinalDocument(entityNIPC, formData);

    return (
        <Form>
            <Row>
                <Col>
                    <SingleUploadArea
                        documents={documents}
                        setDocuments={setDocuments}
                        documentType={DocumentType.EAA_SERVICE_INFO}
                        setError={setError}
                        processUploadCallback={uploadFunction}
                        entityRelated={true}
                    />
                </Col>
            </Row>

            <Row>
                <Col>
                    <SingleUploadArea
                        documents={documents}
                        setDocuments={setDocuments}
                        documentType={DocumentType.EAA_IEFP_CONFIRMATION}
                        setError={setError}
                        processUploadCallback={uploadFunction}
                        entityRelated={true}
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    <SingleUploadArea
                        documents={documents}
                        setDocuments={setDocuments}
                        documentType={DocumentType.EAA_DIRECTIVE_COUNCIL_DELIBERATION}
                        setError={setError}
                        processUploadCallback={uploadFunction}
                        entityRelated={true}
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    <SingleUploadArea
                        documents={documents}
                        setDocuments={setDocuments}
                        documentType={DocumentType.EAA_DECISION_NOTIFICATION}
                        setError={setError}
                        processUploadCallback={uploadFunction}
                        entityRelated={true}
                    />
                </Col>
            </Row>
        </Form>
    )
}