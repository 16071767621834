import React from 'react';
import {Col, Form, Row} from 'react-bootstrap';
import {FormattedMessage, useIntl} from 'react-intl';
import {SelectBooleanField} from '../bootstrap/SelectBooleanField';
import {TextAreaInputField} from '../bootstrap/TextAreaInputField';
import ToolTip from '../bootstrap/ToolTip';
import {DateTimeInputField} from '../bootstrap/DateTimeInputField';
import IntInputField from "../bootstrap/IntInputField";
import {TextInputField} from "../bootstrap/TextInputField";
import {CandidatureFormUtils} from "../../utils/CandidatureFormUtils";

export function CandidatureFormStep2({
  values,
  districts,
  counties,
  parishes,
  companyTypologies,
  handleChange,
  setFieldValue,
  onDistrictChange,
  onCountyChange,
  handleSave,
  handleGoBack,
  errors,
  numberOfSteps,
                                       candidatureDocuments,formikPropsRef
}) {

  const intl = useIntl();
  values.step19Documents = CandidatureFormUtils.getStep19Documents(
      values,
      candidatureDocuments
  );


  function handleChangeCae(e){
    setFieldValue('candidatureDetails.cae',e.target.value)
  }

  function handleDistrictChange(e) {
    setFieldValue('county', '-1');
    setFieldValue('parish', '-1');
    onDistrictChange(e.target.value);
    setFieldValue('district', e.target.value);
  }

  function handleWasCreatedChange(name,value){
    if(value===false){
      values.candidatureDetails.atDeclarationAuthorized=false;
      values.candidatureDetails.ssDeclarationAuthorized=false;
      values.candidatureEmployeeSupportOtherDetails[0].oldJobs=0;
      setFieldValue('candidatureEmployeeSupportOtherDetails', [
        ...values.candidatureEmployeeSupportOtherDetails
      ]);
    }
    setFieldValue(name,value);



  /*  values.step19Documents = CandidatureFormUtils.getStep19Documents(
        values,
        candidatureDocuments
    );
    setFieldValue('step19Documents',[...values.step19Documents])*/

  }



  function handleRegisterLinkChange(e) {
    setFieldValue('candidatureDetails.registerLink', e.target.value);
  }

  function handleCountyChange(e) {
    setFieldValue('parish', '-1');
    onCountyChange(e.target.value);
    setFieldValue('county', e.target.value);
  }

  function handleParishChange(e) {
    setFieldValue('parish', e.target.value);
    /*
    3rd parameter as true identifies a preventive save of the changes on the parish information. Thus, the prior entity
    association is always synchronized with that information to preserve the integrity and completion of the candidature
    form. The last param is needed because, sometimes, the parish value is not updated due to how react updates the form
    state variables and the parish value is needed at the pre save.
    */
    handleSave(false, true, e.target.value);
  }

  function handleNextStep() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    handleSave(true);
  }

  return (
    <div>
      <h4 className='text-primary mb-0'>
        <FormattedMessage id='candidatureForm.projectIdentification' />
      </h4>
      <small className='text-secondary text-uppercase'>
        {intl.formatMessage(
          { id: 'all.step' },
          { step: values.step, numberOfSteps: numberOfSteps }
        )}
      </small>

      <Row>
        <Col md='4'>
          <SelectBooleanField
              name={'candidatureDetails.wasCreated'}
              labelId={'candidatureForm.wasCreated'}
              value={values.candidatureDetails.wasCreated}

              setFieldValue={(name,value) => handleWasCreatedChange(name,value)}
              nullOption={true}
              isInvalid={errors.candidatureDetails?.wasCreated}
              errorMessage={errors.candidatureDetails?.wasCreated}
          />
        </Col>
        <Col md='4'>
          <DateTimeInputField
              name={'candidatureDetails.forecastDate'}
              labelId={'candidatureForm.forecastDate'}
              value={values.candidatureDetails.forecastDate}
              isDateWithNoTime={true}
              setFieldValue={setFieldValue}
              isInvalid={errors.candidatureDetails?.forecastDate}
              errorMessage={errors.candidatureDetails?.forecastDate}
              tooltip={<ToolTip message={<FormattedMessage id={'candidatureDetails.forecast.tooltip'}/>}/>}
          />

        </Col>
        <Col md='4'>
          <Form.Group controlId={'candidatureDetails.companyTypology'}>
            <Form.Label>
              <FormattedMessage id='candidatureForm.companyTypology' />
            </Form.Label>
            <Form.Control
                as='select'
                name={'candidatureDetails.companyTypology'}
                value={values.candidatureDetails.companyTypology}
                onChange={handleChange}
                isInvalid={errors.candidatureDetails?.companyTypology}>
              <option value={'-1'}>
                <FormattedMessage id='all.chooseOne' />
              </option>
              {companyTypologies.map((companyTypology) => (
                  <option key={companyTypology.code} value={companyTypology.code}>
                    {companyTypology.description}
                  </option>
              ))}
            </Form.Control>
            <Form.Control.Feedback type='invalid'>
              {errors.candidatureDetails?.companyTypology}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>

      {values.candidatureDetails?.wasCreated===true  &&(
          <Row>
            <Col md='12'>
              <TextInputField
                  controlId={'registerLink'}
                  labelId={'candidatureDetails.registerLink'}
                  name={'registerLink'}
                  handleChange={(e) => handleRegisterLinkChange(e)}
                  value={values.candidatureDetails.registerLink}
                  isInvalid={errors.candidatureDetails?.registerLink}
                  errorMessage={errors.candidatureDetails?.registerLink}
                  maxLength={250}
              />

            </Col>
          </Row>
      )

      }


      <Row>
        <Col md='4'>
          <Form.Group controlId={'district'}>
            <Form.Label>
              <FormattedMessage id='promoterForm.district' />
              <ToolTip
                message={intl.formatMessage({
                  id: 'candidatureForm.district.toolTip'
                })}
              />
            </Form.Label>
            <Form.Control
              as='select'
              name={'district'}
              onChange={(e) => handleDistrictChange(e)}
              value={values.district}
              isInvalid={errors.district}
              disabled={!values.canAskPriorSupport}>
              <option value={'-1'}>
                <FormattedMessage id='all.chooseOne' />
              </option>
              {districts.map((district) => (
                <option key={district.code} value={district.code}>
                  {district.description}
                </option>
              ))}
            </Form.Control>
            <Form.Control.Feedback type='invalid'>{errors.district}</Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col md='4'>
          <Form.Group controlId={'county'}>
            <Form.Label>
              <FormattedMessage id='promoterForm.county' />
            </Form.Label>
            <Form.Control
              as='select'
              name={'county'}
              onChange={(e) => handleCountyChange(e)}
              value={values.county}
              isInvalid={errors.county}
              disabled={values.district === '-1' || !values.canAskPriorSupport}>
              <option value={'-1'}>
                {values.district !== '-1' ? (
                  <FormattedMessage id='all.chooseOne' />
                ) : (
                  <FormattedMessage id='all.chooseOneDistrict' />
                )}
              </option>
              {counties.map((county) => (
                <option key={county.code} value={county.code}>
                  {county.description}
                </option>
              ))}
            </Form.Control>
            <Form.Control.Feedback type='invalid'>{errors.county}</Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col md='4'>
          <Form.Group controlId={'parish'}>
            <Form.Label>
              <FormattedMessage id='promoterForm.parish' />
            </Form.Label>
            <Form.Control
              as='select'
              name={'parish'}
              onChange={(e) => handleParishChange(e)}
              value={values.parish}
              isInvalid={errors.parish}
              disabled={values.county === '-1' || !values.canAskPriorSupport}>
              <option value={'-1'}>
                {values.county !== '-1' ? (
                  <FormattedMessage id='all.chooseOne' />
                ) : (
                  <FormattedMessage id='all.chooseOneCounty' />
                )}
              </option>
              {parishes.map((parish) => (
                <option key={parish.code} value={parish.code}>
                  {parish.description}
                </option>
              ))}
            </Form.Control>
            <Form.Control.Feedback type='invalid'>{errors.parish}</Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col md='6'>
          <SelectBooleanField
              name={'candidatureDetails.artsAndCrafts'}
              labelId={'candidatureForm.artsAndCrafts'}
              value={values.candidatureDetails.artsAndCrafts}
              setFieldValue={setFieldValue}
              nullOption={true}
              isInvalid={errors.candidatureDetails?.artsAndCrafts}
              errorMessage={errors.candidatureDetails?.artsAndCrafts}
          />
        </Col>
        <Col md='6'>
          <IntInputField
              maxSize={5}
            name={'candidatureDetails.cae'}
            labelId={'candidatureForm.cae'}
            value={values.candidatureDetails.cae}
              handleChange={(e) => handleChangeCae(e)}
            isInvalid={errors.candidatureDetails?.cae}
            errorMessage={errors.candidatureDetails?.cae}
              leadingZero={true}
          />
        </Col>

      </Row>
      <Row>
        <Col md='12'>
          <TextAreaInputField
            labelId={'candidatureForm.projectCharacterization'}
            name={'candidatureDetails.projectCharacterization'}
            placeholder={intl.formatMessage({
              id: 'candidatureForm.projectCharacterization.placeholder'
            })}
            value={values.candidatureDetails.projectCharacterization}
            handleChange={handleChange}
            isInvalid={errors.candidatureDetails?.projectCharacterization}
            errorMessage={errors.candidatureDetails?.projectCharacterization}
            maxLength={10000}
          />
        </Col>
        <Col md='12'>
          <TextAreaInputField
            labelId={'candidatureForm.targetMarket'}
            name={'candidatureDetails.targetMarket'}
            placeholder={intl.formatMessage({
              id: 'candidatureForm.targetMarket.placeholder'
            })}
            value={values.candidatureDetails.targetMarket}
            handleChange={handleChange}
            isInvalid={errors.candidatureDetails?.targetMarket}
            errorMessage={errors.candidatureDetails?.targetMarket}
            maxLength={10000}
          />
        </Col>
        <Col md='12'>
          <TextAreaInputField
            labelId={'candidatureForm.installations'}
            name={'candidatureDetails.installations'}
            placeholder={intl.formatMessage({
              id: 'candidatureForm.installations.placeholder'
            })}
            handleChange={handleChange}
            value={values.candidatureDetails.installations}
            isInvalid={errors.candidatureDetails?.installations}
            errorMessage={errors.candidatureDetails?.installations}
            maxLength={10000}
          />
        </Col>
        <Col md='12'>
          <TextAreaInputField
            labelId={'candidatureForm.possibleIncentives'}
            name={'candidatureDetails.possibleIncentives'}
            value={values.candidatureDetails.possibleIncentives}
            handleChange={handleChange}
            isInvalid={errors.candidatureDetails?.possibleIncentives}
            errorMessage={errors.candidatureDetails?.possibleIncentives}
            maxLength={10000}
            placeholder={intl.formatMessage({
              id: 'all.maxLength.placeholder'
            })}
          />
        </Col>
      </Row>

      <div className='form-actions mt-5'>
        <button
          className='btn btn-link'
          type='button'
          onClick={() => handleGoBack(values, setFieldValue)}>
          <FormattedMessage id='all.back' />
        </button>

        <button className='btn btn-primary' type='button' onClick={() => handleNextStep()}>
          <FormattedMessage id='all.nextStep' />
        </button>
      </div>
    </div>
  );
}