import {currencyFormat} from "../../utils/CurrencyUtils";
import React from "react";
import {CandidaturePaymentPurpose} from "../../models/CandidaturePaymentPurpose";
import {FormattedMessage} from "react-intl";
import {calculateTotals} from "./candidaturePaymentFunctions";

let INVESTMENT = CandidaturePaymentPurpose.INVESTMENT;
let JOB_CREATION = CandidaturePaymentPurpose.JOB_CREATION;

export function PaymentTotals({candidature, payments}) {
    let {approved, paid} = calculateTotals(candidature, payments);

    return (
        <table className='table d-table'>
            <thead>
            <tr>
                <th></th>
                <th className='text-end'>
                    <label>
                        <FormattedMessage id={`candidature.payment.purpose.${INVESTMENT}`} />
                    </label>
                </th>
                <th className='text-end'>
                    <label>
                        <FormattedMessage id={`candidature.payment.purpose.${JOB_CREATION}`} />
                    </label>
                </th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td>
                    <label>
                        <FormattedMessage id='candidature.payments.list.totalApproved' />
                    </label>
                </td>
                <td className='text-end'>
                    {currencyFormat(approved[INVESTMENT].toFixed(2))}
                </td>
                <td className='text-end'>
                    {currencyFormat(approved[JOB_CREATION].toFixed(2))}
                </td>
            </tr>
            <tr>
                <td>
                    <label>
                        <FormattedMessage id='candidature.payments.list.totalPaid' />
                    </label>
                </td>
                <td className='text-end'>
                    {currencyFormat(paid[INVESTMENT].toFixed(2))}
                </td>
                <td className='text-end'>
                    {currencyFormat(paid[JOB_CREATION].toFixed(2))}
                </td>
            </tr>
            <tr>
                <td>
                    <label>
                        <FormattedMessage id='candidature.payments.list.paidPercentage' />
                    </label>
                </td>
                <td className='text-end'>
                    {( approved[INVESTMENT]===0 ? 0 : (paid[INVESTMENT] / approved[INVESTMENT] * 100) ).toFixed(2)} %
                </td>
                <td className='text-end'>
                    {( approved[JOB_CREATION]===0 ? 0 : (paid[JOB_CREATION] / approved[JOB_CREATION] * 100) ).toFixed(2)} %
                </td>
            </tr>
            </tbody>
        </table>
    );
}