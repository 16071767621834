import React, { useEffect, useState } from 'react';
import { TextInputField } from '../../bootstrap/TextInputField';
import { NumberInputField } from '../../bootstrap/NumberInputField';
import { Row, Col, Table, Form } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import DecimalInputField from '../../bootstrap/DecimalInputField';
import { TextAreaInputField } from '../../bootstrap/TextAreaInputField';
import { ToolTip } from '../../bootstrap/ToolTip';
import { EnumSelectField } from '../../bootstrap/EnumSelectField';
import { maxFixed } from '../../../utils/CurrencyUtils';
import {
	incubationSupportPredictionTotalOfMonths,
	totalCostsGeneral,
	totalCostsIncubationSupport,
	calculateProportional
} from '../../../utils/AnnualComplementaryActivitiesPlanFormUtils';
import { AdditionalActivityType } from '../../../models/AdditionalActivityType';
import {
	FaInfoCircle,
	FaRegArrowAltCircleDown,
	FaRegArrowAltCircleUp,
	FaRegTimesCircle,
} from 'react-icons/fa';

export function AnnualComplementaryActivitiesPlanFormStep2({
	formStep,
	numberOfSteps,
	errors,
	handleChange,
	formikValues,
	handlePreviousStep,
	handleNextStep,
	setFieldValue,
	districts,
	districtsOptions,
	months,
	formText,
	readMode
}) {
	const intl = useIntl();

	const bootcampIndex = 0;
	const didaticIndex = 1;
	const disclosureIndex = 2;

	const buttonStyle = {
		cursor: 'pointer',
		color: '#eb4f24',
		borderColor: '#eb4f24',
		background: 'none',
	};

	function getFormTextPrefix(formText) {
		//returns translation prefix

		if (formText == 'AddComplementaryActivitiesPaymentRequest') {
			return 'complementaryActivitiesPaymentRequest';
		} else if (formText == 'AddAnnualComplementaryActivitiesPlan') {
			return 'annualComplementaryActivitiesPlanForm';
		}
	}

	function setEndMonthOptionsBasedOnStartMonth(index) {
		if (formikValues.activityIdentification.incubationProjectList[index].startMonth !== '-1') {
			return months.slice(
				months.indexOf(formikValues.activityIdentification.incubationProjectList[index].startMonth) + 1
			);
		}
		return [];
	}

	function handleStartMonthChange(e, index) {
		formikValues.activityIdentification.incubationProjectList[index].endMonth = '-1';
		formikValues.activityIdentification.incubationProjectList[index].startMonth = e.target.value;
		setFieldValue({ ...formikValues });
	}

	function handlerAddSupportPredictionEvent(additionalActivityIndex) {
		if (additionalActivityIndex === disclosureIndex) {
			formikValues.activityIdentification.additionalActivityList[
				additionalActivityIndex
			].supportForecastList.push({
				description: '',
				value: '',
				onlineValue: '',
			});
		} else if (additionalActivityIndex === undefined) {
			formikValues.activityIdentification.incubationProjectList.push({
				districtCode: '-1',
				description: '',
				startMonth: '-1',
				endMonth: '-1',
				orderNumber: formikValues.activityIdentification.incubationProjectList.length + 1,
			});
		} else {
			formikValues.activityIdentification.additionalActivityList[
				additionalActivityIndex
			].supportForecastList.push({
				description: '',
				value: '',
			});
		}
		setFieldValue({ ...formikValues });
	}

	function districtChangeHandler(e) {
		let districtCode;
		if (e.target.value === '-1') {
			districtCode = '-1';
		} else {
			districtCode = districts.filter(
				(d) => d.description === e.target.value
			)[0].code;
		}
		setFieldValue(e.target.name, districtCode);
	}

	function removeSupportForecastLine(
		additionalActivityIndex,
		withinActivityIndex
	) {
		formikValues.activityIdentification.additionalActivityList[
			additionalActivityIndex
		].supportForecastList.splice(withinActivityIndex, 1);
		setFieldValue({ ...formikValues });
	}

	function removeIncubationProject(index) {
		formikValues.activityIdentification.incubationProjectList.splice(index, 1);
		for (let i = index; i < formikValues.activityIdentification.incubationProjectList.length; i++) {
			formikValues.activityIdentification.incubationProjectList[i].orderNumber--;
		}
		setFieldValue({ ...formikValues });
	}

	function incrementOrderOfIncubationProject(index) {
		let nextElement = formikValues.activityIdentification.incubationProjectList[index + 1];
		nextElement.orderNumber--;

		formikValues.activityIdentification.incubationProjectList[index].orderNumber++;
		formikValues.activityIdentification.incubationProjectList[index + 1] =
			formikValues.activityIdentification.incubationProjectList[index];

		formikValues.activityIdentification.incubationProjectList[index] = nextElement;
		setFieldValue({ ...formikValues });
	}

	function decrementOrderOfIncubationProject(index) {
		let previousElement = formikValues.activityIdentification.incubationProjectList[index - 1];
		previousElement.orderNumber++;

		formikValues.activityIdentification.incubationProjectList[index].orderNumber--;
		formikValues.activityIdentification.incubationProjectList[index - 1] =
			formikValues.activityIdentification.incubationProjectList[index];

		formikValues.activityIdentification.incubationProjectList[index] = previousElement;
		setFieldValue({ ...formikValues });
	}

	function removeSupportForecastLastElem(additionalActivityIndex) {
		formikValues.activityIdentification.additionalActivityList[
			additionalActivityIndex
		].supportForecastList.splice(-1);
		setFieldValue({ ...formikValues });
	}

	function removeLastElemIncubationProjectList() {
		formikValues.activityIdentification.incubationProjectList.splice(-1);
		setFieldValue({ ...formikValues });
	}

	return (
		<div>
			<h4 className='text-primary mb-0'>
				<FormattedMessage
					id={`${getFormTextPrefix(
						formText
					)}.sideNavBar.activityIdentification`}
				/>
			</h4>
			<small className='text-secondary text-uppercase'>
				{intl.formatMessage(
					{ id: 'all.step' },
					{ step: formStep, numberOfSteps: numberOfSteps }
				)}
			</small>
			<fieldset disabled={readMode==true}>
			<Row>
				<Col md='4'>
					<NumberInputField
						disabled={true}
						className='w-50'
						labelId={'all.year'}
						value={formikValues.year}
					/>
				</Col>
				<Col md='3'>
					<DecimalInputField
						disabled
						suffixLabel='€'
						name={'ias'}
						handleChange={handleChange}
						label='IAS'
						value={formikValues.activityIdentification.ias}
						isInvalid={errors.activityIdentification?.ias}
						errorMessage={errors.activityIdentification?.ias}
					/>
				</Col>
			</Row>

			{/* === BOOTCAMP === */}
			<Row className='mt-5'>
				<small className='text-primary text-uppercase'>Bootcamp</small>
			</Row>
			<Row>
				<Col md='4'>
					<NumberInputField
						className='w-50'
						labelId={`${getFormTextPrefix(formText)}.numberOfParticipants`}
						name={`activityIdentification.additionalActivityList[${bootcampIndex}].participants`}
						handleChange={handleChange}
						value={
							formikValues.activityIdentification.additionalActivityList[bootcampIndex].participants
						}
						isInvalid={
							errors.activityIdentification?.additionalActivityList?.[bootcampIndex]?.participants
						}
						errorMessage={
							errors.activityIdentification?.additionalActivityList?.[bootcampIndex]?.participants
						}
						toolTip={
							<ToolTip
								message={
									<FormattedMessage
										id={`${getFormTextPrefix(
											formText
										)}.numberOfParticipants.toolTip`}
									/>
								}
							/>
						}
					/>
				</Col>
				<Col md='4'>
					<NumberInputField
						className='w-50'
						labelId={`${getFormTextPrefix(formText)}.numberOfHours`}
						name={`activityIdentification.additionalActivityList[${bootcampIndex}].hours`}
						handleChange={handleChange}
						value={formikValues.activityIdentification.additionalActivityList[bootcampIndex].hours}
						isInvalid={errors.activityIdentification?.additionalActivityList?.[bootcampIndex]?.hours}
						errorMessage={errors.activityIdentification?.additionalActivityList?.[bootcampIndex]?.hours}
						toolTip={
							<ToolTip
								message={
									<FormattedMessage
										id={`${getFormTextPrefix(formText)}.bootcampHours.toolTip`}
									/>
								}
							/>
						}
					/>
				</Col>
			</Row>
			<Row>
				<Col md='12'>
					<TextAreaInputField
						labelId={`${getFormTextPrefix(formText)}.activityDescription`}
						placeholder={intl.formatMessage({id:'annualComplementaryActivitiesPlanForm.characters.placeholder'}, {nChars: '1000'})}
						name={`activityIdentification.additionalActivityList[${bootcampIndex}].description`}
						handleChange={handleChange}
						value={
							formikValues.activityIdentification.additionalActivityList[bootcampIndex].description
						}
						isInvalid={
							errors.activityIdentification?.additionalActivityList?.[bootcampIndex]?.description
						}
						errorMessage={
							errors.activityIdentification?.additionalActivityList?.[bootcampIndex]?.description
						}
						maxLength={1000}
					/>
				</Col>
			</Row>
			<Row className='mt-4'>
				<div className={typeof errors.activityIdentification?.additionalActivityList?.[bootcampIndex]?.supportForecastList === 'string'
				? 'form-control is-invalid' : ''}>
				<Col md='12'>
					<Form.Label>
						<FormattedMessage
							id={`${getFormTextPrefix(formText)}.supportPrediction`}
						/>
					</Form.Label>
				</Col>
				<Col md='12'>
					<Table className='d-table' striped responsive='md'>
						<thead>
							<tr>
								<th className='w-75'>
									<FormattedMessage
										id={`${getFormTextPrefix(
											formText
										)}.bootcamp.supportPrediction.predictedEvents`}
									/>
								</th>
								<th>
									<FormattedMessage
										id={`${getFormTextPrefix(
											formText
										)}.supportPrediction.amount`}
									/>
									<ToolTip
										message={
											<FormattedMessage
												id={`${getFormTextPrefix(
													formText
												)}.bootcampSupportPrediction.toolTip`}
											/>
										}
									/>
								</th>
							</tr>
						</thead>
						<tbody>
							{formikValues.activityIdentification.additionalActivityList[bootcampIndex]
								.supportForecastList.length > 0 ? (
								<React.Fragment>
									{formikValues.activityIdentification.additionalActivityList[
										bootcampIndex
									].supportForecastList.map((predictedEvent, index) => (
										<tr key={index}>
											{/* Funcionality to delete elements
											
											<td className='align-items-center'>
												<FaRegTimesCircle
													onClick={() =>
														removeSupportForecastLine(bootcampIndex, index)
													}
													size={25}
													style={buttonStyle}
												/>
												</td>*/}

											<td className='w-75'>
												<TextInputField
													value={
														formikValues.activityIdentification.additionalActivityList[bootcampIndex]
															.supportForecastList[index].description
													}
													name={`activityIdentification.additionalActivityList[${bootcampIndex}].supportForecastList[${index}].description`}
													handleChange={handleChange}
													isInvalid={
														errors.activityIdentification?.additionalActivityList?.[bootcampIndex]
															?.supportForecastList?.[index]?.description
													}
													errorMessage={
														errors.activityIdentification?.additionalActivityList?.[bootcampIndex]
															?.supportForecastList?.[index]?.description
													}
												/>
											</td>

											<td>
												<DecimalInputField
													value={
														formikValues.activityIdentification.additionalActivityList[bootcampIndex]
															.supportForecastList[index].value
													}
													name={`activityIdentification.additionalActivityList[${bootcampIndex}].supportForecastList[${index}].value`}
													handleChange={handleChange}
													isInvalid={
														errors.activityIdentification?.additionalActivityList?.[bootcampIndex]
															?.supportForecastList?.[index]?.value
													}
													errorMessage={
														errors.activityIdentification?.additionalActivityList?.[bootcampIndex]
															?.supportForecastList?.[index]?.value
													}
												/>
											</td>
										</tr>
									))}
									<tr>
										<td>
											<p className='mb-0'>Total</p>
										</td>
										<td>
											<p className='mb-0 px-2'>
												{maxFixed(
													totalCostsGeneral(
														formikValues,
														bootcampIndex,
														'value'
													)
												) + ' €'}
											</p>
										</td>
									</tr>
								</React.Fragment>
							) : (
								<tr>
									<td colSpan={5}>
										<FormattedMessage
											id={`${getFormTextPrefix(
												formText
											)}.supportPrediction.noData${readMode ? '.readMode' : ''}`}
										/>
									</td>
								</tr>
							)}
						</tbody>
						<tfoot>
							<tr>
								<td colSpan={12}>
									<Row className='justify-content-between'>
										<Col md='2'>
											<button
												className='btn btn-outline-primary'
												type='button'
												onClick={() =>
													handlerAddSupportPredictionEvent(bootcampIndex)
												}
											>
												<FormattedMessage id={'all.addButton'} />
											</button>
										</Col>
										{formikValues.activityIdentification.additionalActivityList[bootcampIndex]
											.supportForecastList.length > 0 && (
											<Col md='4' className='text-end'>
												<button
													className='btn btn-link'
													type='button'
													onClick={() =>
														removeSupportForecastLastElem(bootcampIndex)
													}
												>
													<FormattedMessage id={'annualComplementaryActivitiesPlanForm.button.removeLastEntry'} />
												</button>
											</Col>
										)}
									</Row>
								</td>
							</tr>
						</tfoot>
					</Table>
				</Col>
				</div>
				{typeof errors.activityIdentification?.additionalActivityList?.[bootcampIndex]?.supportForecastList === 'string' &&
				<div className='invalid-feedback'>
              {errors.activityIdentification.additionalActivityList[bootcampIndex].supportForecastList}
				</div>
				}
			</Row>

			{/* === ATIVIDADES DE DIVULGAÇÃO: WORKSHOPS, SEMINÁRIOS E AÇÕES DE FORMAÇÃO EM EMPREENDEDORISMO === */}
			<Row className='mt-4'>
				<small className='text-primary text-uppercase'>
					<FormattedMessage
						id={`${getFormTextPrefix(formText)}.disclosureActivities.section`}
					/>
				</small>
			</Row>
			{/* <Row>
				<Col>
					<p className='mt-3 mb-0 text-secondary infoText'>
						<FaInfoCircle /><FormattedMessage
										id={`${getFormTextPrefix(
											formText
										)}.disclosureActivitiesHours.toolTip`}
									/>
					</p>
				</Col>
			</Row> */}
			<Row>
				<Col md='4'>
					<NumberInputField
						className='w-50'
						labelId={`${getFormTextPrefix(formText)}.numberOfParticipants`}
						name={`activityIdentification.additionalActivityList[${disclosureIndex}].participants`}
						handleChange={handleChange}
						value={
							formikValues.activityIdentification.additionalActivityList[disclosureIndex].participants
						}
						isInvalid={
							errors.activityIdentification?.additionalActivityList?.[disclosureIndex]?.participants
						}
						errorMessage={
							errors.activityIdentification?.additionalActivityList?.[disclosureIndex]?.participants
						}
						toolTip={
							<ToolTip
								message={
									<FormattedMessage
										id={`${getFormTextPrefix(
											formText
										)}.numberOfParticipants.toolTip`}
									/>
								}
							/>
						}
					/>
				</Col>
				<Col md='4'>
					<NumberInputField
						className='w-50'
						labelId={`${getFormTextPrefix(formText)}.numberOfHoursInRoom`}
						name={`activityIdentification.additionalActivityList[${disclosureIndex}].hours`}
						handleChange={handleChange}
						value={formikValues.activityIdentification.additionalActivityList[disclosureIndex].hours}
						isInvalid={errors.activityIdentification?.additionalActivityList?.[disclosureIndex]?.hours}
						errorMessage={
							errors.activityIdentification?.additionalActivityList?.[disclosureIndex]?.hours
						}
					/>
				</Col>

				<Col md='4'>
					<NumberInputField
						className='w-50'
						labelId={`${getFormTextPrefix(formText)}.numberOfHoursOnline`}
						name={`activityIdentification.additionalActivityList[${disclosureIndex}].onlineHours`}
						handleChange={handleChange}
						value={
							formikValues.activityIdentification.additionalActivityList[disclosureIndex].onlineHours
						}
						isInvalid={
							errors.activityIdentification?.additionalActivityList?.[disclosureIndex]?.onlineHours
						}
						errorMessage={
							errors.activityIdentification?.additionalActivityList?.[disclosureIndex]?.onlineHours
						}
					/>
				</Col>
			</Row>
			<Row>
				<Col md='12'>
					<TextAreaInputField
						labelId={`${getFormTextPrefix(formText)}.activityDescription`}
						placeholder={intl.formatMessage({id:'annualComplementaryActivitiesPlanForm.characters.placeholder'}, {nChars: '1000'})}
						name={`activityIdentification.additionalActivityList[${disclosureIndex}].description`}
						handleChange={handleChange}
						value={
							formikValues.activityIdentification.additionalActivityList[disclosureIndex].description
						}
						isInvalid={
							errors.activityIdentification?.additionalActivityList?.[disclosureIndex]?.description
						}
						errorMessage={
							errors.activityIdentification?.additionalActivityList?.[disclosureIndex]?.description
						}
						maxLength={1000}
					/>
				</Col>
			</Row>
			<Row className={'mt-4'}>
				<div className={typeof errors.activityIdentification?.additionalActivityList?.[disclosureIndex]?.supportForecastList === 'string'
					? 'form-control is-invalid' : ''}>
					<Col md='12'>
						<Form.Label>
							<FormattedMessage
								id={`${getFormTextPrefix(formText)}.supportPrediction`}
							/>
						</Form.Label>
					</Col>
					<Col md='12'>
						<Table className='d-table' striped responsive='md'>
							<thead>
								<tr>
									<th>
										<FormattedMessage
											id={`${getFormTextPrefix(
												formText
											)}.disclosureActivities.supportPrediction.predictedEvents`}
										/>
									</th>
									<th>
										<FormattedMessage
											id={`${getFormTextPrefix(formText)}.inRoom`}
										/>
										<ToolTip
											message={
												<FormattedMessage
													id={`${getFormTextPrefix(
														formText
													)}.disclosureActivitiesInRoomSupportPrediction.toolTip`}
												/>
											}
										/>
									</th>
									<th>
										Online
										<ToolTip
											message={
												<FormattedMessage
													id={`${getFormTextPrefix(
														formText
													)}.disclosureActivitiesOnlineSupportPrediction.toolTip`}
												/>
											}
										/>
									</th>
								</tr>
							</thead>
							<tbody>
								{formikValues.activityIdentification.additionalActivityList[disclosureIndex]
									.supportForecastList.length > 0 ? (
									<React.Fragment>
										{formikValues.activityIdentification.additionalActivityList[
											disclosureIndex
										].supportForecastList.map((predictedEvent, index) => (
											<tr key={index}>
												{/* Funcionality to delete elements<td className='align-items-center'>
													<FaRegTimesCircle
														onClick={() =>
															removeSupportForecastLine(disclosureIndex, index)
														}
														size={25}
														style={buttonStyle}
													/>
													</td>*/}
												<td className='w-50'>
													<TextInputField
														value={
															formikValues.activityIdentification.additionalActivityList[disclosureIndex]
																.supportForecastList[index].description
														}
														name={`activityIdentification.additionalActivityList[${disclosureIndex}].supportForecastList[${index}].description`}
														handleChange={handleChange}
														isInvalid={
															errors.activityIdentification?.additionalActivityList?.[disclosureIndex]
																?.supportForecastList?.[index]?.description
														}
														errorMessage={
															errors.activityIdentification?.additionalActivityList?.[disclosureIndex]
																?.supportForecastList?.[index]?.description
														}
													/>
												</td>

												<td>
													<DecimalInputField
														placeHolder={intl.formatMessage({
															id: `${getFormTextPrefix(
																formText
															)}.supportPrediction.amount`,
														})}
														value={
															formikValues.activityIdentification.additionalActivityList[disclosureIndex]
																.supportForecastList[index].value
														}
														name={`activityIdentification.additionalActivityList[${disclosureIndex}].supportForecastList[${index}].value`}
														handleChange={handleChange}
														isInvalid={
															errors.activityIdentification?.additionalActivityList?.[disclosureIndex]
																?.supportForecastList?.[index]?.value
														}
														errorMessage={
															errors.activityIdentification?.additionalActivityList?.[disclosureIndex]
																?.supportForecastList?.[index]?.value
														}
													/>
												</td>
												<td>
													<DecimalInputField
														placeHolder={intl.formatMessage({
															id: `${getFormTextPrefix(
																formText
															)}.supportPrediction.amount`,
														})}
														value={
															formikValues.activityIdentification.additionalActivityList[disclosureIndex]
																.supportForecastList[index].onlineValue
														}
														name={`activityIdentification.additionalActivityList[${disclosureIndex}].supportForecastList[${index}].onlineValue`}
														handleChange={handleChange}
														isInvalid={
															errors.activityIdentification?.additionalActivityList?.[disclosureIndex]
																?.supportForecastList?.[index]?.onlineValue
														}
														errorMessage={
															errors.activityIdentification?.additionalActivityList?.[disclosureIndex]
																?.supportForecastList?.[index]?.onlineValue
														}
													/>
												</td>
											</tr>
										))}
										<tr>
											<td>
												<p className='mb-0'>Total</p>
											</td>
											<td>
												<p className='mb-0 px-2'>
													{maxFixed(
														totalCostsGeneral(
															formikValues,
															disclosureIndex,
															'value'
														)
													) + ' €'}
												</p>
											</td>
											<td>
												<p className='mb-0 px-2'>
													{maxFixed(
														totalCostsGeneral(
															formikValues,
															disclosureIndex,
															'onlineValue'
														)
													) + ' €'}
												</p>
											</td>
										</tr>
									</React.Fragment>
								) : (
									<tr>
										<td colSpan={5}>
											<FormattedMessage
												id={`${getFormTextPrefix(
													formText
												)}.supportPrediction.noData${readMode ? '.readMode' : ''}`}
											/>
										</td>
									</tr>
								)}
							</tbody>
							<tfoot>
								<tr>
									<td colSpan={12}>
										<Row className='justify-content-between'>
											<Col md='2'>
												<button
													className='btn btn-outline-primary'
													type='button'
													onClick={() =>
														handlerAddSupportPredictionEvent(disclosureIndex)
													}
												>
													<FormattedMessage id={'all.addButton'} />
												</button>
											</Col>
											{formikValues.activityIdentification.additionalActivityList[disclosureIndex]
												.supportForecastList.length > 0 && (
												<Col md='4' className='text-end'>
													<button
														className='btn btn-link'
														type='button'
														onClick={() =>
															removeSupportForecastLastElem(disclosureIndex)
														}
													>
														<FormattedMessage id={'annualComplementaryActivitiesPlanForm.button.removeLastEntry'} />
													</button>
												</Col>
											)}
										</Row>
									</td>
								</tr>
							</tfoot>
						</Table>
					</Col>
				</div>
				{typeof errors.activityIdentification?.additionalActivityList?.[disclosureIndex]?.supportForecastList === 'string' &&
				<div className='invalid-feedback'>
              {errors.activityIdentification.additionalActivityList[disclosureIndex].supportForecastList}
				</div>
				}
			</Row>

			{/* === DESENVOLVIMENTO DE MATERIAIS DIDÁTICOS === */}
			<Row className='mt-4'>
				<small className='text-primary text-uppercase'>
					<FormattedMessage
						id={`${getFormTextPrefix(formText)}.didacticMaterials.section`}
					/>
				</small>
			</Row>
			<Row>
				<Col md='12'>
					<TextAreaInputField
						labelId={`${getFormTextPrefix(formText)}.activityDescription`}
						placeholder={intl.formatMessage({id:'annualComplementaryActivitiesPlanForm.characters.placeholder'}, {nChars: '1000'})}
						name={`activityIdentification.additionalActivityList[${didaticIndex}].description`}
						handleChange={handleChange}
						value={
							formikValues.activityIdentification.additionalActivityList[didaticIndex].description
						}
						isInvalid={
							errors.activityIdentification?.additionalActivityList?.[didaticIndex]?.description
						}
						errorMessage={
							errors.activityIdentification?.additionalActivityList?.[didaticIndex]?.description
						}
						maxLength={1000}
					/>
				</Col>
			</Row>
			<Row className={'mt-4'}>
				<div className={typeof errors.activityIdentification?.additionalActivityList?.[didaticIndex]?.supportForecastList === 'string'
					? 'form-control is-invalid' : ''}>
					<Col md='12'>
						<Form.Label>
							<FormattedMessage
								id={`${getFormTextPrefix(formText)}.supportPrediction`}
							/>
						</Form.Label>
					</Col>
					<Col>
						<Table className='d-table' striped responsive='md'>
							<thead>
								<tr>
									<th>
										<FormattedMessage
											id={`${getFormTextPrefix(
												formText
											)}.supportPrediction.didacticMaterialsToDevelop`}
										/>
									</th>
									<th>
										<FormattedMessage
											id={`${getFormTextPrefix(
												formText
											)}.supportPrediction.amount`}
										/>
									</th>
								</tr>
							</thead>
							<tbody>
								{formikValues.activityIdentification.additionalActivityList[didaticIndex]
									.supportForecastList.length > 0 ? (
									<React.Fragment>
										{formikValues.activityIdentification.additionalActivityList[
											didaticIndex
										].supportForecastList.map((didacticMaterial, index) => (
											<tr key={index}>
												{/* Funcionality to delete elements<td className='align-items-center'>
													<FaRegTimesCircle
														onClick={() =>
															removeSupportForecastLine(didaticIndex, index)
														}
														size={25}
														style={buttonStyle}
													/>
													</td>*/}
												<td>
													<TextInputField
														value={
															formikValues.activityIdentification.additionalActivityList[didaticIndex]
																.supportForecastList[index].description
														}
														name={`activityIdentification.additionalActivityList[${didaticIndex}].supportForecastList[${index}].description`}
														handleChange={handleChange}
														isInvalid={
															errors.activityIdentification?.additionalActivityList?.[didaticIndex]
																?.supportForecastList?.[index]?.description
														}
														errorMessage={
															errors.activityIdentification?.additionalActivityList?.[didaticIndex]
																?.supportForecastList?.[index]?.description
														}
													/>
												</td>

												<td>
													<DecimalInputField
														value={
															formikValues.activityIdentification.additionalActivityList[didaticIndex]
																.supportForecastList[index].value
														}
														name={`activityIdentification.additionalActivityList[${didaticIndex}].supportForecastList[${index}].value`}
														handleChange={handleChange}
														isInvalid={
															errors.activityIdentification?.additionalActivityList?.[didaticIndex]
																?.supportForecastList?.[index]?.value
														}
														errorMessage={
															errors.activityIdentification?.additionalActivityList?.[didaticIndex]
																?.supportForecastList?.[index]?.value
														}
													/>
												</td>
											</tr>
										))}
										<tr>
											<td>
												<p className='mb-0'>
													Total{' '}
													<ToolTip
														message={
															<FormattedMessage
																id={`${getFormTextPrefix(
																	formText
																)}.didacticalMaterialsSupportPredictionTotal.toolTip`}
															/>
														}
													/>
												</p>
											</td>
											<td>
												<p className='mb-0 px-2'>
													{maxFixed(
														totalCostsGeneral(formikValues, didaticIndex, 'value')
													) + ' €'}
												</p>
											</td>
										</tr>
									</React.Fragment>
								) : (
									<tr>
										<td colSpan={5}>
											<FormattedMessage
												id={`${getFormTextPrefix(
													formText
												)}.didacticMaterialsSupportPrediction.noData${readMode ? '.readMode' : ''}`}
											/>
										</td>
									</tr>
								)}
							</tbody>
							<tfoot>
								<tr>
									<td colSpan={12}>
										<Row className='justify-content-between'>
											<Col md='2'>
												<button
													className='btn btn-outline-primary'
													type='button'
													onClick={() =>
														handlerAddSupportPredictionEvent(didaticIndex)
													}
												>
													<FormattedMessage id={'all.addButton'} />
												</button>
											</Col>
											{formikValues.activityIdentification.additionalActivityList[didaticIndex]
												.supportForecastList.length > 0 && (
												<Col md='4' className='text-end'>
													<button
														className='btn btn-link'
														type='button'
														onClick={() =>
															removeSupportForecastLastElem(didaticIndex)
														}
													>
														<FormattedMessage id={'annualComplementaryActivitiesPlanForm.button.removeLastEntry'} />
													</button>
												</Col>
											)}
										</Row>
									</td>
								</tr>
							</tfoot>
						</Table>
					</Col>
				</div>
				{typeof errors.activityIdentification?.additionalActivityList?.[didaticIndex]?.supportForecastList === 'string' &&
				<div className='invalid-feedback'>
              {errors.activityIdentification.additionalActivityList[didaticIndex].supportForecastList}
				</div>
				}
			</Row>

			{/* === CUSTOS DE INCUBAÇÃO DE NOVOS PROJETOS EMPREENDE XXI === */}
			<Row className='mt-4'>
				<small className='text-primary text-uppercase'>
					<FormattedMessage
						id={`${getFormTextPrefix(formText)}.incubationCosts.section`}
					/>
				</small>
			</Row>
			<Row>
				<Col md='12'>
					<Form.Label>
						<FormattedMessage
							id={`${getFormTextPrefix(formText)}.description`}
						/>
					</Form.Label>
				</Col>
				<Col>
					<Table className='d-table' striped responsive='md'>
						<thead>
							<tr>
								<th>
									<FormattedMessage
										id={`${getFormTextPrefix(formText)}.orderNumber`}
									/>
								</th>
								<th>
									<FormattedMessage
										id={`${getFormTextPrefix(formText)}.district`}
									/>
								</th>
								<th>
									<FormattedMessage
										id={`${getFormTextPrefix(formText)}.incubatorDesignation`}
									/>
								</th>
								<th>
									<FormattedMessage
										id={`${getFormTextPrefix(formText)}.startMonth`}
									/>
								</th>
								<th>
									<FormattedMessage
										id={`${getFormTextPrefix(formText)}.endMonth`}
									/>
								</th>
							</tr>
						</thead>
						<tbody>
							{formikValues.activityIdentification.incubationProjectList.length > 0 ? (
								<React.Fragment>
									{formikValues.activityIdentification.incubationProjectList.map((project, index) => (
										<tr key={index}>
											{/* Funcionality to delete and order change of elements
											
											<td className='align-items-center'>
												<FaRegTimesCircle
													onClick={() => removeIncubationProject(index)}
													size={25}
													style={buttonStyle}
												/>
												{index != 0 && (
													<FaRegArrowAltCircleUp
														onClick={() =>
															decrementOrderOfIncubationProject(index)
														}
														size={25}
														style={buttonStyle}
													/>
												)}
												{index !==
													formikValues.activityIdentification.incubationProjectList.length - 1 && (
													<FaRegArrowAltCircleDown
														onClick={() =>
															incrementOrderOfIncubationProject(index)
														}
														size={25}
														style={buttonStyle}
													/>
												)}
													</td>*/}
											<td>{index + 1}</td>
											<td>
												<EnumSelectField
													name={`activityIdentification.incubationProjectList[${index}].districtCode`}
													handleChange={(e) => districtChangeHandler(e)}
													value={
														project.districtCode === '-1'
															? '-1'
															: districts.filter(
																	(d) => d.code === project.districtCode
															  )[0].description
													}
													nullOption={true}
													options={districtsOptions}
													isInvalid={
														errors.activityIdentification?.incubationProjectList?.[index]?.districtCode
													}
													errorMessage={
														errors.activityIdentification?.incubationProjectList?.[index]?.districtCode
													}
												/>
											</td>
											<td>
												<TextInputField
													value={
														formikValues.activityIdentification.incubationProjectList[index]
															.description
													}
													name={`activityIdentification.incubationProjectList[${index}].description`}
													handleChange={handleChange}
													isInvalid={
														errors.activityIdentification?.incubationProjectList?.[index]?.description
													}
													errorMessage={
														errors.activityIdentification?.incubationProjectList?.[index]?.description
													}
												/>
											</td>
											<td>
												<EnumSelectField
													name={`activityIdentification.incubationProjectList[${index}].startMonth`}
													handleChange={(e) => handleStartMonthChange(e, index)}
													value={
														formikValues.activityIdentification.incubationProjectList[index].startMonth
													}
													nullOption={true}
													options={months}
													isInvalid={
														errors.activityIdentification?.incubationProjectList?.[index]?.startMonth
													}
													errorMessage={
														errors.activityIdentification?.incubationProjectList?.[index]?.startMonth
													}
												/>
											</td>
											<td>
												<EnumSelectField
													disabled={
														formikValues.activityIdentification.incubationProjectList[index]
															.startMonth === '-1'
													}
													name={`activityIdentification.incubationProjectList[${index}].endMonth`}
													handleChange={handleChange}
													value={
														formikValues.activityIdentification.incubationProjectList[index].endMonth
													}
													nullOption={true}
													options={setEndMonthOptionsBasedOnStartMonth(index)}
													isInvalid={
														errors.activityIdentification?.incubationProjectList?.[index]?.endMonth
													}
													errorMessage={
														errors.activityIdentification?.incubationProjectList?.[index]?.endMonth
													}
												/>
											</td>
										</tr>
									))}
								</React.Fragment>
							) : (
								<tr>
									<td colSpan={5}>
										<FormattedMessage
											id={`${getFormTextPrefix(
												formText
											)}.incubationProjectsList.noData${readMode ? '.readMode' : ''}`}
										/>
									</td>
								</tr>
							)}
						</tbody>
						{formikValues.activityIdentification.incubationProjectList.length < 15 && (
							<tfoot>
								<tr>
									<td colSpan={12}>
										<Row className='justify-content-between'>
											<Col md='2'>
												<button
													className='btn btn-outline-primary'
													type='button'
													onClick={() => handlerAddSupportPredictionEvent()}
												>
													<FormattedMessage id={'all.addButton'} />
												</button>
											</Col>
											{formikValues.activityIdentification.incubationProjectList.length > 0 && (
												<Col md='4' className='text-end'>
													<button
														className='btn btn-link'
														type='button'
														onClick={() =>
															removeLastElemIncubationProjectList()
														}
													>
														<FormattedMessage id={'annualComplementaryActivitiesPlanForm.button.removeLastEntry'} />
													</button>
												</Col>
											)}
										</Row>
									</td>
								</tr>
							</tfoot>
						)}
					</Table>
				</Col>
			</Row>
			<Row className={'mt-4'}>
				<Col md='12'>
					<Form.Label>
						<FormattedMessage
							id={
								getFormTextPrefix(formText) ===
								'annualComplementaryActivitiesPlanForm'
									? 'annualComplementaryActivitiesPlanForm.ammountToRequest'
									: 'complementaryActivitiesPaymentRequest.ammountToRequest'
							}
						/>
					</Form.Label>
				</Col>
				<Col md='4'>
					<Form.Label>
						<FormattedMessage
							id={`${getFormTextPrefix(formText)}.IASNextYear`}
						/>
					</Form.Label>
					{maxFixed(parseFloat(formikValues.activityIdentification.ias) || 0) + ' €'}
				</Col>
				<Col md='4'>
					<Form.Label>
						<FormattedMessage
							id={`${getFormTextPrefix(formText)}.maxValuePerProject`}
						/>
						<ToolTip
							message={
								<FormattedMessage
									id={`${getFormTextPrefix(
										formText
									)}.maxValuePerProject.toolTip`}
								/>
							}
						/>
					</Form.Label>
					{maxFixed((parseFloat(formikValues.activityIdentification.ias) || 0) * 3) + ' €'}
				</Col>
				<Col md='4'>
					<Form.Label>
						<FormattedMessage
							id={`${getFormTextPrefix(formText)}.proportional`}
						/>
					</Form.Label>
					{maxFixed(calculateProportional(formikValues)) + ' €'}
				</Col>
				<Col md='4'>
					<NumberInputField
						labelId={`${getFormTextPrefix(formText)}.totalOfMonths`}
						value={incubationSupportPredictionTotalOfMonths(
							formikValues,
							months
						)}
						disabled={true}
					/>
				</Col>
				<Col md='4'>
					<Form.Label>
						<FormattedMessage
							id={`${getFormTextPrefix(formText)}.supportTotal`}
						/>
					</Form.Label>
					{maxFixed(
						totalCostsIncubationSupport(
							formikValues,
							formikValues.activityIdentification.ias || 0,
							months
						)
					) + ' €'}
				</Col>
			</Row>

			</fieldset>
			<div className='form-actions mt-5'>
				<button
					className='btn btn-link'
					type='button'
					onClick={handlePreviousStep}
				>
					<FormattedMessage id={'all.back'} />
				</button>

				<button
					className='btn btn-primary'
					type='button'
					onClick={handleNextStep}
				>
					<FormattedMessage id={'all.nextStep'} />
				</button>
			</div>
		</div>
	);
}
