import { Button, Modal } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { refuseByPromoter } from "../../../rest/candidature";
import { useParams } from "react-router-dom";
import { useState } from "react";

export function CandidatureFlowRefuseByPromoter({ callbackHandler }) {
  const { externalId } = useParams();
  const [show, setShow] = useState(false);

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  const setRefuseByPromoterHandler = async () => {
    callbackHandler(() => {
      return refuseByPromoter(externalId);
    }, 'promoterCandidatureNotValidated');
  };

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header>
          <FormattedMessage id="candidature.flow.refuseByPromoter" />
        </Modal.Header>
        <Modal.Body>
          <FormattedMessage id="candidature.message.confirmationToRefuseByPromoter" />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-secondary" onClick={handleClose}>
            <FormattedMessage id="all.no" />
          </Button>
          <Button variant="outline-primary" onClick={setRefuseByPromoterHandler}>
            <FormattedMessage id="all.yes" />
          </Button>
        </Modal.Footer>
      </Modal>
      <Button variant="outline-primary" onClick={handleShow}>
        <FormattedMessage id="candidature.flow.refuseByPromoter" />
      </Button>
    </>
  );
}
