import React from 'react';
import { DateTimeInputField } from '../../bootstrap/DateTimeInputField';
import { Row, Col } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';

export function FinalReportFormStep4({
	formStep,
	numberOfSteps,
	errors,
	formikValues,
	handlePreviousStep,
	handleNextStep,
	setFieldValue,
	initialDateAsInput,
	isFinalReport=false,
	readMode
}) {
	const intl = useIntl();

	return (
		<div>
			<h4 className='text-primary mb-0'>
				<FormattedMessage id='eaevaluationform.mcesupport' />
			</h4>
			<small className='text-secondary text-uppercase'>
				{intl.formatMessage(
					{ id: 'all.step' },
					{ step: formStep, numberOfSteps: numberOfSteps }
				)}
			</small>
			<fieldset disabled={readMode==true}>
				<Row>
					<Col md='6'>
						<DateTimeInputField
							disabled={initialDateAsInput === undefined || isFinalReport === true}
							labelId='finalReportForm.mceServicesStartDate'
							value={formikValues.mceSupportPeriod.initialDateMceSupport}
							name='mceSupportPeriod.initialDateMceSupport'
							setFieldValue={setFieldValue}
							isDateWithNoTime={true}
							isInvalid={errors.mceSupportPeriod?.initialDateMceSupport}
							errorMessage={errors.mceSupportPeriod?.initialDateMceSupport}
						/>
					</Col>
					<Col md='6'>
						<DateTimeInputField
							labelId='finalReportForm.mceServicesEndDate'
							name='mceSupportPeriod.endDateMceSupport'
							value={formikValues.mceSupportPeriod.endDateMceSupport}
							setFieldValue={setFieldValue}
							isDateWithNoTime={true}
							isInvalid={errors.mceSupportPeriod?.endDateMceSupport}
							errorMessage={errors.mceSupportPeriod?.endDateMceSupport}
							disabled={isFinalReport}
						/>
					</Col>
				</Row>
			</fieldset>
			<div className='form-actions mt-5'>
				<button
					className='btn btn-link'
					type='button'
					onClick={handlePreviousStep}
				>
					<FormattedMessage id='all.back' />
				</button>

				<button
					className='btn btn-primary'
					type='button'
					onClick={handleNextStep}
				>
					<FormattedMessage id='all.nextStep' />
				</button>
			</div>
		</div>
	);
}
