import React, {useEffect, useState} from 'react'
import {Navigate, useParams} from 'react-router-dom';
import {AlertError} from '../../components/bootstrap/AlertError';
import Loading from '../../components/general/Loading';
import {SubTemplate} from '../../components/general/SubTemplate';
import {submitExpirationApprovalDecision} from '../../rest/candidature';
import {listDocumentsByTypeAndCandidatureExternalId} from '../../rest/document';
import {handleError, isNotBusinessError} from '../../utils/handleError';
import {DocumentType} from "../../models/DocumentType";
import {AddExpirationApprovalDecisionForm} from "../../components/candidature/AddExpirationApprovalDecisionForm";


export function CandidatureSubmitExpirationApprovalDecision() {
    const {externalId} = useParams();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [userDocuments, setUserDocuments] = useState([]);
    const [submit, setSubmit] = useState(false);


    async function fetchData() {
        try {
            const [
                {data: userDocuments}
            ] = await Promise.all([
                await listDocumentsByTypeAndCandidatureExternalId(DocumentType.EXPIRATION_DECISION_APPROVAL, externalId)
            ]);
            setUserDocuments(userDocuments);
        } catch (error) {
            setError(error);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        fetchData();
    }, []);


    if (submit) {
        sessionStorage.setItem('submitExpirationApprovalDecision','true');
        return <Navigate replace to={`/candidatura/${externalId}`}/>
    }


    async function submitDocuments() {
        try {
            await submitExpirationApprovalDecision(externalId);
            setSubmit(true)


        } catch (error) {
            setError(error);
        }
    }


    function handleSubmit() {
        submitDocuments();
    }

    if (loading) {
        return <Loading/>;
    }

    if (error && isNotBusinessError(error)) {
        return handleError(error);
    }

    return (
        <SubTemplate>
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-8 offset-lg-2 mb-5'>
                        <AlertError error={error}/>
                        <AddExpirationApprovalDecisionForm
                            onSubmit={handleSubmit}
                            userDocuments={userDocuments}
                            externalId={externalId}
                            setDocuments={setUserDocuments}
                        />
                    </div>
                </div>
            </div>
        </SubTemplate>
    );
}
