import React, { useEffect, useState } from 'react';
import { FaRegFile, FaUserCheck, FaUserPlus,FaRegWindowClose } from 'react-icons/fa';
import { FormattedMessage } from 'react-intl';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { AlertSuccess } from '../../components/bootstrap/AlertSuccess';
import SubmitDeclarationModal from '../../components/candidature/SubmitDeclarationModal';
import Loading from '../../components/general/Loading';
import { SubTemplate } from '../../components/general/SubTemplate';
import { CandidatureState } from '../../models/CandidatureState';
import { DocumentType } from '../../models/DocumentType';
import { getUserCandidature } from '../../rest/candidature';
import { listDocumentsByDocumentType } from '../../rest/document';
import {getPromoter, savePromoter} from '../../rest/promoter';
import { isJustMentioned, mustUploadIefpDocument } from '../../rest/promoterMetaData';
import { Arrays } from '../../utils/Arrays';
import { handleError, isNotBusinessError } from '../../utils/handleError';
import {ConfigObject} from "../../config";

export function Candidature() {
  const location = useLocation();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [candidature, setCandidature] = useState(null);
  const [promoter,setPromoter] = useState (null)
  const [justMentioned, setJustMentioned] = useState(false);
  const [lsItem, setLsItem] = useState(null);
  const [userDocuments, setUserDocuments] = useState([]);
  const [mustUploadIefpDeclaration, setMustUploadIefpDeclaration] = useState([]);

  const [showSubmitDeclarationModal, setShowSubmitDeclarationModal] = useState(false);

  async function fetchData() {
    try {
      const [{ data: candidature }, { data: userDocuments },{data:promoter}] = await Promise.all([
        await getUserCandidature(),
        await listDocumentsByDocumentType(DocumentType.IEFP_DECLARATION),
          await getPromoter()
      ]);
      setCandidature(candidature);
      setUserDocuments(userDocuments);
      setPromoter(promoter);

      const [{ data: mentioned }, { data: mustUploadIefpDeclaration }] = await Promise.all([
        await isJustMentioned(candidature.externalId),
        await mustUploadIefpDocument(candidature.externalId)
      ]);
      setJustMentioned(mentioned.flag);
      setMustUploadIefpDeclaration(mustUploadIefpDeclaration.flag);

      checkStorageItems();
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (location.state) {
      navigate(0);
    } else {
      fetchData();
    }
  }, []);

  function checkStorageItems() {
    if (sessionStorage.getItem('registrationCompleted')) {
      setLsItem('registrationCompleted');
    }

    if (sessionStorage.getItem('promoterRemoved')) {
      setLsItem('promoterRemoved');

    }
    if (sessionStorage.getItem('promoterCandidatureNotValidated')) {
			setLsItem('promoterCandidatureNotValidated');
		}
    if (sessionStorage.getItem('candidatureWithdrawed')) {
			setLsItem('candidatureWithdrawed');
		}
    if (sessionStorage.getItem('candidatureCancel')) {
      setLsItem('candidatureCancel');
    }
  }

  function handleCreateCandidatureClick() {
    if (Arrays.isNotEmpty(userDocuments) &&  promoter.iefpNumber) {
      navigate('/candidatura/adicionar');
    } else {
      setShowSubmitDeclarationModal(true);
    }
  }

  async function updatePromoter(values) {
    setLoading(true);
    try {
      await savePromoter(values);
      await fetchData();
      setShowSubmitDeclarationModal(false);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  }

  function isAllowedToCreateCandidature() {

    return !candidature && !justMentioned ;
  }

  function redirectToDetailCandidature() {

    if ((mustUploadIefpDeclaration===false || (Arrays.isNotEmpty(userDocuments) &&  promoter.iefpNumber && promoter.iefpNumber!==''))) {
      navigate(`/candidatura/${candidature.externalId}`);
    } else {
      setShowSubmitDeclarationModal(true);
    }
  }

  if (loading) {
    return <Loading />;
  }

  if (error && isNotBusinessError(error)) {
    return handleError(error);
  }

  if (candidature && !justMentioned) {
    if (CandidatureState.activeStates().includes(candidature.state))
      return <Navigate replace to={`/candidatura/${candidature.externalId}`} />;
    else return <Navigate replace to='/candidatura/adicionar' />;
  }

  return (
    <div>
      <SubTemplate>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-8 offset-lg-2 mb-5 py-4'>
              <div className='mx-2 mb-4'>
                <AlertSuccess lsItem={lsItem} />
              </div>

              <SubmitDeclarationModal
                show={showSubmitDeclarationModal}
                handleClose={() => setShowSubmitDeclarationModal(false)}
                titleId='promoterForm.step3.title'
                updatePromoter={updatePromoter}
                userDocuments={userDocuments}
                setUserDocuments={setUserDocuments}
              />

              {ConfigObject.get().closed !==true ? (
<>
                  {isAllowedToCreateCandidature() && (
                      <div className='row'>
                        <div className='col-lg-6 mb-5'>
                          <div className='infoBlock mx-2'>
                            <FaRegFile />
                            <h4>
                              <FormattedMessage id='candidature.add' />
                            </h4>
                            <p className='text-secondary mb-4'>
                              <FormattedMessage id='candidature.add.message' />
                            </p>
                            <button
                                className='btn btn-primary mb-2'
                                type='button'
                                onClick={() => handleCreateCandidatureClick()}>
                              <FormattedMessage id='candidature.create' />
                            </button>
                          </div>
                        </div>

                        <div className='col-lg-6 mb-5'>
                          <div className='infoBlock mx-2'>
                            <FaUserPlus />
                            <h4>
                              <FormattedMessage id='candidature.validate' />
                            </h4>
                            <p className='text-secondary mb-4'>
                              <FormattedMessage id='candidature.validate.message' />
                            </p>
                          </div>
                        </div>
                      </div>
                  )}


              </>

              ) :
                (

                    <div className='row'>
                      <div className='col-lg-6 offset-lg-3 mb-5'>
                        <div className='infoBlock mx-2'>
                          <FaRegWindowClose />
                          <h4>
                            <FormattedMessage id='candidature.closeCandidatures' />
                          </h4>
                          <p className='text-secondary mb-4'>
                            <FormattedMessage id='candidature.closeCandidatures.message' />
                          </p>
                        </div>
                      </div>
                    </div>



                )

              }

              {justMentioned && (
                  <div className='row'>
                    <div className='col-lg-6 offset-lg-3 mb-5'>
                      <div className='infoBlock mx-2'>
                        <FaUserCheck />
                        <h4>
                          <FormattedMessage id='candidature.validateParticipation' />
                        </h4>
                        <p className='text-secondary mb-4'>
                          <FormattedMessage id='candidature.validateParticipation.message' />
                        </p>
                        <button
                            className='btn btn-primary mb-2'
                            type='button'
                            onClick={redirectToDetailCandidature}>
                          <FormattedMessage id='candidature.validateParticipation.action' />
                        </button>
                      </div>
                    </div>
                  </div>
              )}



            </div>
          </div>
        </div>
      </SubTemplate>
    </div>
  );
}
