import React, {useEffect, useState} from 'react';
import {Accordion, Alert, Col, Container, Modal, Row} from 'react-bootstrap';
import {FormattedMessage, useIntl} from 'react-intl';
import {useNavigate, useParams} from 'react-router-dom';
import {AlertError} from '../../../components/bootstrap/AlertError';
import {AlertSuccess} from '../../../components/bootstrap/AlertSuccess';
import Loading from '../../../components/general/Loading';
import {SubTemplate} from '../../../components/general/SubTemplate';
import {handleError, isNotBusinessError} from '../../../utils/handleError';
import {isIEFPUser} from '../../../authentication/authenticationHelper';
import {EntityChangeState} from "../../../models/EntityChangeState";
import {canCreateGeoChange, getEntityChange} from "../../../rest/entityChanges";
import {TextAreaInputField} from "../../../components/bootstrap/TextAreaInputField";
import {TextInputField} from "../../../components/bootstrap/TextInputField";
import {DateTimeInputField} from "../../../components/bootstrap/DateTimeInputField";
import {EntityChangeType} from "../../../models/EntityChangeType";
import {EntityChangeGeoData} from "../../../components/entityChange/EntityChangeGeoData";
import {EntityChangeKind} from "../../../models/EntityChangeKind";
import {Arrays} from "../../../utils/Arrays";
import {AuthenticatedDownloadLink} from "../../../components/document/AuthenticatedDownloadLink";
import {DocumentType} from '../../../models/DocumentType';
import {EntityChangeRequestValidationModal} from "./EntityChangeRequestValidation";
import {EntityChangeRequestContestation} from "./EntityChangeRequestContestation";
import {EntityChangeContactData} from "../../../components/entityChange/EntityChangeContactData";
import {EntityChangeLocationData} from "../../../components/entityChange/EntityChangeLocationData";
import {EntityChangeHRData} from "../../../components/entityChange/EntityChangeHRData";
import {FaInfoCircle} from "react-icons/fa";

export function DetailEntityChange() {
    const {nif, type, externalId} = useParams();
    const navigate = useNavigate();
    const isIEFP = isIEFPUser();

    const [entityChange, setEntityChange] = useState();
    const [entityChangeDocuments, setEntityChangeDocuments] =
        useState();
    const [lsItem, setLsItem] = useState(null);
    const [activeKey, setActiveKey] = useState('changeInitial');
    const [availableGeoChange, setAvailableGeoChange] = useState(false);

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const intl = useIntl();

    const [showDecisionModal, setShowDecisionModal] = useState(false);
    const [showContestationModal, setShowContestationModal] = useState(false);


    const [isApprove, setIsApprove] = useState(null);

    const [showApprovalDecisionDialog, setShowApprovalDecisionDialog] =
        useState(false);

    const fetchData = async () => {
        try {
            let [
                {data: entityChangeRequest},
                {data: availableGeoChange}
            ] =
                await Promise.all([
                    getEntityChange(
                        nif,
                        type,
                        externalId
                    ),
                    canCreateGeoChange(nif)
                ]);
            setAvailableGeoChange(availableGeoChange);
            setEntityChange(entityChangeRequest);

            switch (entityChangeRequest.state) {
                case EntityChangeState.APPROVED:
                case EntityChangeState.REJECTED:
                    setActiveKey('finalDecision');
                    break;
                case EntityChangeState.ANALYZE_CONTESTATION:
                    setActiveKey('clarification')
                    break;
                case EntityChangeState.WAIT_CONTESTATION:
                    setActiveKey('changeInitial')
            }

            checkLocalStorageItems();
        } catch (error) {
            setError(error);
        } finally {
            setLoading(false);
        }
    };


    function checkLocalStorageItems() {
        [
            'entityChangeApproved',
            'entityChangeReject1',
            'entityChangeReject2',
            'entityChangeContestation'
        ].forEach((s) => {
            if (sessionStorage.getItem(s)) {
                setLsItem(s);
            }
        });
    }

    useEffect(() => {
        fetchData();
    }, []);

    if (loading) {
        return <Loading/>;
    }

    if (error && isNotBusinessError(error)) {
        return handleError(error);
    }

    function handleCloseDecisionModal() {
        setShowDecisionModal(false);
        setIsApprove(null);
    }

    function modalOpenningHandler(isApprove) {
        setIsApprove(isApprove);
        setShowDecisionModal(true);
    }

    const entityChangeIsWaitContestation = () => {
        return (
            !isIEFP && EntityChangeState.WAIT_CONTESTATION === entityChange?.state
        );
    };


    const showApproveAndRejectButtons = () => {
        return (
            isIEFP &&
            [EntityChangeState.SUBMITTED, EntityChangeState.ANALYZE_CONTESTATION].includes(entityChange?.state)
        );
    };

    const canApproveGeoChange = () => {
        return type === EntityChangeType.GEO ? availableGeoChange : true;
    };

    function renderEaProposal() {
        switch (type) {
            case EntityChangeType.GEO:
                return <EntityChangeGeoData entityChange={entityChange} kind={EntityChangeKind.EA}
                                            showInfo={showApproveAndRejectButtons()}/>
            case EntityChangeType.CONTACT:
                return <EntityChangeContactData entityChange={entityChange}/>
            case EntityChangeType.LOCATION:
                return <EntityChangeLocationData entityChange={entityChange}/>
            case EntityChangeType.HR:
                return <EntityChangeHRData entityChange={entityChange} setError={setError}/>

        }
    }

    function approvalDecisionDialog() {
        return (
            <Modal
                show={showApprovalDecisionDialog}
                onHide={() => setShowApprovalDecisionDialog(false)}
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        <FormattedMessage
                            id={'candidatureChangeRequest.decisionDialog.title'}
                        />
                    </Modal.Title>
                </Modal.Header>
                <AlertError error={error}/>
                <Modal.Body>
                    <FormattedMessage
                        id={'candidatureChangeRequest.decisionDialog.body'}
                    />
                </Modal.Body>
                <Modal.Footer className='justify-content-between'>
                    <button
                        className={'btn btn-outline-primary px-5'}
                        onClick={() => approvalDecisionDialogTriage(false)}
                    >
                        <FormattedMessage id='all.no'/>
                    </button>
                    <button
                        className={'btn btn-outline-primary px-5'}
                        onClick={() => approvalDecisionDialogTriage(true)}
                    >
                        <FormattedMessage id='all.yes'/>
                    </button>
                </Modal.Footer>
            </Modal>
        );
    }

    function approvalDecisionDialogTriage(hasFinancialChanges) {

        modalOpenningHandler(true);
        setShowApprovalDecisionDialog(false);
    }

    return (
        <>
            <SubTemplate hasBackButton titleId={'entityChangeDetails.' + type}>
                <Container>
                    {approvalDecisionDialog()}
                    <AlertError error={error}/>
                    {lsItem && <AlertSuccess lsItem={lsItem}/>}


                    {showDecisionModal && (
                        <EntityChangeRequestValidationModal
                            entityChange={entityChange}
                            isApprove={isApprove}
                            handleClose={handleCloseDecisionModal}
                        />
                    )}
                    {showContestationModal && (
                        <EntityChangeRequestContestation
                            entityChange={entityChange}
                            setShowModal={setShowContestationModal}
                        />
                    )}
                    {!availableGeoChange && type === EntityChangeType.GEO &&
                        (showApproveAndRejectButtons() || entityChangeIsWaitContestation()) &&
                        <Row className="mt-4">
                            <Col>
                                <Alert className='d-flex align-items-center justified' variant='danger'>
                                    <div className='px-3'><FaInfoCircle/></div>
                                    <div className='px-3 '>
                                        <p className='mb-0'>
                                            {showApproveAndRejectButtons() ?
                                            <FormattedMessage id="entityChange.GEO.invalidChange.alert.body.iefp"/>
                                            :
                                            <FormattedMessage id="entityChange.GEO.invalidChange.alert.body.entity"/>}
                                        </p>
                                    </div>
                                </Alert>
                            </Col>
                        </Row>}
                    <Row>
                        <Col md='9' className='mb-5 summaryTabs'>
                            <div className={'form bg-white'}>
                                <Row>
                                    <Col md={6}>
                                        <TextInputField
                                            labelId='economicViabilityFormStep1.regionalDelegation'
                                            name='regionalDelegationCode'
                                            value={intl.formatMessage({
                                                id: 'regionalDelegation.' + entityChange.regionalDelegationCode
                                            })}
                                            disabled={true}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <DateTimeInputField
                                            className={'w-50'}
                                            labelId='candidatureTabs.submissionDate'
                                            name='submssionDate'
                                            disabled={true}
                                            value={entityChange.submissionDate}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={12}>
                                        <TextAreaInputField
                                            controlId={'reason'}
                                            labelId={'entityChange.reason'}
                                            name={'reason'}
                                            value={entityChange.reason}
                                            maxLength={1000}
                                            disabled={true}
                                        />
                                    </Col>
                                </Row>
                                {!Arrays.isEmpty(entityChange.documents.filter(document => document.documentType === DocumentType.REASON_ENTITY_CHANGE)) &&
                                    entityChange.documents.filter(document => document.documentType === DocumentType.REASON_ENTITY_CHANGE).map((document, index) => (
                                        <div key={index} className='border-top py-2'>
                                            <div className='d-flex justify-content-between'>
                                                <div>
                                                    <label>
                                                        <FormattedMessage id={`documentType.${document.documentType}`}/>
                                                    </label>
                                                    {document.fileDescription}
                                                    <span className='mx-2'>·</span>
                                                    <AuthenticatedDownloadLink
                                                        url={document.documentPath}
                                                        filename={document.name}
                                                    >
                                                        {document.name}
                                                    </AuthenticatedDownloadLink>
                                                </div>
                                            </div>
                                        </div>
                                    ))}


                                <Row>
                                    <Col md={12}>
                                        <h5 className='text-secondary mt-3 mb-3'><FormattedMessage
                                            id={'entityChangeDetails.EA.proposal'}/></h5>
                                    </Col>
                                </Row>
                                {renderEaProposal()}


                                {entityChange.state !== EntityChangeState.SUBMITTED &&
                                    <Accordion defaultActiveKey={[activeKey]} alwaysOpen>
                                        {!Arrays.isEmpty(entityChange.documents.filter(document => document.documentType === DocumentType.ENTITY_CHANGE_DECISION)) && (
                                            <Accordion.Item eventKey="changeInitial">
                                                <Accordion.Header><FormattedMessage
                                                    id='entityChangeDetails.IEFP.rejectFirst'/></Accordion.Header>
                                                <Accordion.Body>
                                                    {entityChange.documents.filter(document => [DocumentType.ENTITY_CHANGE_DECISION, DocumentType.ENTITY_CHANGE_DECISION_PRIVATE].includes(document.documentType)).map((document, index) => (
                                                        <div key={index} className='py-2'>
                                                            <div className='d-flex justify-content-between'>
                                                                <div>
                                                                    <label>
                                                                        <FormattedMessage
                                                                            id={`documentType.${document.documentType}`}/>
                                                                    </label>
                                                                    {document.fileDescription}
                                                                    <span className='mx-2'>·</span>
                                                                    <AuthenticatedDownloadLink
                                                                        url={document.documentPath}
                                                                        filename={document.name}
                                                                    >
                                                                        {document.name}
                                                                    </AuthenticatedDownloadLink>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        )}
                                        {(entityChange.clarification && entityChange.clarification.length > 0) && (
                                            <Accordion.Item eventKey="clarification">
                                                <Accordion.Header><FormattedMessage
                                                    id='entityChangeDetails.clarificationStep'/></Accordion.Header>
                                                <Accordion.Body>
                                                    <Row>
                                                        <Col md={12}>
                                                            <TextAreaInputField
                                                                controlId={'clarification'}
                                                                labelId={'entityChange.clarification'}
                                                                name={'reason'}
                                                                value={entityChange.clarification}
                                                                maxLength={1000}
                                                                disabled={true}
                                                            />
                                                        </Col>
                                                    </Row>
                                                    {entityChange.documents.filter(document => DocumentType.CLARIFICATION_ENTITY_CHANGE === document.documentType).map((document, index) => (
                                                        <div key={index} className='py-2'>
                                                            <div className='d-flex justify-content-between'>
                                                                <div>
                                                                    <label>
                                                                        <FormattedMessage
                                                                            id={`documentType.${document.documentType}`}/>
                                                                    </label>
                                                                    {document.fileDescription}
                                                                    <span className='mx-2'>·</span>
                                                                    <AuthenticatedDownloadLink
                                                                        url={document.documentPath}
                                                                        filename={document.name}
                                                                    >
                                                                        {document.name}
                                                                    </AuthenticatedDownloadLink>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        )}
                                        {EntityChangeState.finalStates().includes(entityChange.state) &&
                                            !Arrays.isEmpty(entityChange.documents.filter(document => document.documentType === DocumentType.ENTITY_CHANGE_FINAL_DECISION)) && (
                                                <Accordion.Item eventKey="finalDecision">
                                                    <Accordion.Header><FormattedMessage
                                                        id={EntityChangeState.APPROVED ? 'entityChangeDetails.IEFP.approved' : 'entityChangeDetails.IEFP.rejectSecond'}/></Accordion.Header>
                                                    <Accordion.Body>
                                                        <Row>
                                                            <Col md={6}>
                                                                <DateTimeInputField
                                                                    className={'w-50'}
                                                                    labelId='entityChange.decisionDate'
                                                                    name='approvalDate'
                                                                    disabled={true}
                                                                    value={entityChange.approvalDate}
                                                                />
                                                            </Col>
                                                        </Row>
                                                        {entityChange.documents.filter(document => [DocumentType.ENTITY_CHANGE_FINAL_DECISION, DocumentType.ENTITY_CHANGE_FINAL_DECISION_PRIVATE].includes(document.documentType)).map((document, index) => (
                                                            <div key={index} className='py-2'>
                                                                <div className='d-flex justify-content-between'>
                                                                    <div>
                                                                        <label>
                                                                            <FormattedMessage
                                                                                id={`documentType.${document.documentType}`}/>
                                                                        </label>
                                                                        {document.fileDescription}
                                                                        <span className='mx-2'>·</span>
                                                                        <AuthenticatedDownloadLink
                                                                            url={document.documentPath}
                                                                            filename={document.name}
                                                                        >
                                                                            {document.name}
                                                                        </AuthenticatedDownloadLink>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            )}
                                    </Accordion>
                                }
                            </div>
                        </Col>
                        <Col md='3'>
                            <div className='px-2 mb-5'>
                                <div className='d-grid gap-4'>
                                    {showApproveAndRejectButtons() && (
                                        <>
                                            {canApproveGeoChange() &&
                                                <button
                                                    onClick={() => modalOpenningHandler(true)}
                                                    className='btn btn-outline-primary mb-2'
                                                >
                                                    <FormattedMessage id='candidature.change.approve'/>
                                                </button>
                                            }
                                            <button
                                                onClick={() => modalOpenningHandler(false)}
                                                className='btn btn-outline-primary mb-2'
                                            >
                                                <FormattedMessage id='candidature.change.deny'/>
                                            </button>
                                        </>
                                    )}


                                    {entityChangeIsWaitContestation() && (
                                        <button
                                            onClick={() => setShowContestationModal(true)}
                                            className='btn btn-outline-primary mb-2'
                                        >
                                            <FormattedMessage id='candidature.button.contest'/>
                                        </button>
                                    )}
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </SubTemplate>
        </>
    )
        ;
}
