import React from 'react';
import { Accordion, Col, Row, Table } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { ForeCastType } from '../../models/ForeCastType';
import { RemunerationType } from '../../models/RemunerationType';
import { Arrays } from '../../utils/Arrays';
import { CandidatureFormUtils } from '../../utils/CandidatureFormUtils';
import { maxFixed } from '../../utils/CurrencyUtils';
import DecimalInputField from '../bootstrap/DecimalInputField';
import { EnumSelectField } from '../bootstrap/EnumSelectField';
import ToolTip from '../bootstrap/ToolTip';
import {TextAreaInputField} from "../bootstrap/TextAreaInputField";

export function CandidatureFormStepForeCastDemonstration({
  errors,
  setFieldValue,
  handleChange,
  values,
  handleSave,
  handleGoBack,
  numberOfSteps
}) {
  let columns;
  if (values.forecastDemonstrationList?.length > 0) {
    columns =
      values.forecastDemonstrationList[0].type === ForeCastType.SNC
        ? values.forecastDemonstrationList[0].forecastDemonstrationSNCColumnList
        : values.forecastDemonstrationList[0].forecastDemonstrationNCMColumnList;



    let finalGrouped = [];
    finalGrouped['Total'] = {};
    finalGrouped['Total'].amortizationFirstYear = 0;
    finalGrouped['Total'].value = 0;
    finalGrouped['Total'].amortizationSecondYear = 0;
    finalGrouped['Total'].amortizationThirdYear = 0;


    finalGrouped['Total'].amortizationFourthYear = 0;
    finalGrouped['Total'].amortizationFifthYear = 0;


    values.amortizationExpensesList[0].amortizationExpensesLines.reduce(function (obj, item) {



      finalGrouped['Total'].amortizationFirstYear += parseFloat(item.amortizationFirstYear);
      finalGrouped['Total'].value += parseFloat(item.value);
      finalGrouped['Total'].amortizationSecondYear += parseFloat(item.amortizationSecondYear);
      finalGrouped['Total'].amortizationThirdYear += parseFloat(item.amortizationThirdYear);



      if (CandidatureFormUtils.isProjectTotalValueAboveLimitValue(values)) {
          finalGrouped['Total'].amortizationFourthYear += parseFloat(item.amortizationFourthYear);
          finalGrouped['Total'].amortizationFifthYear += parseFloat(item.amortizationFifthYear);
      }



        return obj;
      }, {});




    columns[0].depreciationAmortization = finalGrouped['Total'].amortizationFirstYear;
    columns[1].depreciationAmortization = finalGrouped['Total'].amortizationSecondYear;
    columns[2].depreciationAmortization = finalGrouped['Total'].amortizationThirdYear;


    if (CandidatureFormUtils.isProjectTotalValueAboveLimitValue(values)) {
      columns[3].depreciationAmortization= finalGrouped['Total'].amortizationFourthYear;
      columns[4].depreciationAmortization = finalGrouped['Total'].amortizationFifthYear;
    }
    



  }

  const totalsSellInformation = getTotalSellInformation(
    values,
    values.sellInformationList.filter((sellInformation) => sellInformation.type === 'SELL')
  );
  const totalsConsumeInformation = getTotalSellInformation(
    values,
    values.sellInformationList.filter((sellInformation) => sellInformation.type === 'CONSUME')
  );

  const totalsProvideInformation = getProviderInformationTotal(values);

  function getProviderInformationTotal(values) {
    let totals = [];
    values.forecastDemonstrationList[0].forecastDemonstrationSNCColumnList.forEach((column) => {
      if (!CandidatureFormUtils.is3YearsCoverageOnly(values, column.year)) {
        totals[column.year] = 0;
      }
    });
    if (values.provideInformationList?.length > 0) {
      let providerListGrouped = Arrays.groupBy(
        values.provideInformationList[0].provideInformationCellList,
        (pInformation) => pInformation.year
      );
      providerListGrouped.forEach((yearGroup) => {
        totals[yearGroup[0].year] = maxFixed(
          yearGroup.map((y) => parseFloat(y.value)).reduce((total, count) => total + count)
        );
      });
      return totals;
    }
  }

  function getTotalSellInformation(values, sellInformationsParam) {
    let valuesFinal = [];

    values.forecastDemonstrationList[0].forecastDemonstrationSNCColumnList.forEach((column) => {
      if (!CandidatureFormUtils.is3YearsCoverageOnly(values, column.year)) {
        valuesFinal.push({ year: column.year, quantity: 0, value: 0 });
      }
    });

    sellInformationsParam?.forEach((sellInformation) => {
      sellInformation.sellInformationLines?.forEach((line) => {
        line.sellInformationLineYears.sort((a, b) => a['year'] - b['year']);
        line.sellInformationLineYears.forEach((year) => {
          valuesFinal.forEach((value) => {
            if (value.year === year.year) {
              value.quantity += year.quantity;
              value.value = parseFloat(value.value) +  parseFloat(year.value);
            }
          });
          valuesFinal.sort((a, b) => a.year - b.year);
        });
      });
    });
    return valuesFinal;
  }

  const intl = useIntl();

  function handleNextStep() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    handleSave(true);
  }

  function handleType(e) {
    values.forecastDemonstrationList[0].type = e.target.value != -1 ? e.target.value : null;
    if (values.forecastDemonstrationList[0].type != null) {
      columns =
        values.forecastDemonstrationList[0].type === ForeCastType.SNC
          ? values.forecastDemonstrationList[0].forecastDemonstrationSNCColumnList
          : values.forecastDemonstrationList[0].forecastDemonstrationNCMColumnList;
    }
    setFieldValue('forecastDemonstrationList', [...values.forecastDemonstrationList]);
  }


  function handleReason(e) {
    values.forecastDemonstrationList[0].reasonToForecastDemonstration = e.target.value;
    setFieldValue('forecastDemonstrationList', [...values.forecastDemonstrationList]);
  }

  function handleField(e, year, key) {

    console.log(e.target.value)


    if (values.forecastDemonstrationList[0].type === ForeCastType.SNC) {
      values.forecastDemonstrationList[0].forecastDemonstrationSNCColumnList.find(
        (oC) => oC.year === year
      )[key] = e.target.value;
    } else {
      values.forecastDemonstrationList[0].forecastDemonstrationNCMColumnList.find(
        (oC) => oC.year === year
      )[key] = e.target.value;
    }

    setFieldValue('forecastDemonstrationList', [...values.forecastDemonstrationList]);
  }

  const peopleRemunerationTotals = getPeopleRemunerationTotals(values);

  function getPeopleRemunerationTotals(values) {
    let finalGrouped = [];
    values.forecastDemonstrationList[0].forecastDemonstrationSNCColumnList.forEach((column) => {
      if (!CandidatureFormUtils.is3YearsCoverageOnly(values, column.year)) {
        RemunerationType.values().forEach((rT) => {
          finalGrouped[rT + column.year] = 0;
        });
      }
    });

    if (values.peopleRemunerationList?.length > 0) {
      let grouped = Arrays.groupBy(
        values.peopleRemunerationList[0].peopleRemunerationCellList,
        (pRemuneration) => pRemuneration.remunerationType + pRemuneration.year
      );
      grouped.forEach((group) => {
        finalGrouped[group[0].remunerationType + group[0].year] = group
          .map((t2) => t2.value)
          .reduce((total, count) => parseFloat(total) + parseFloat(count));
      });
    }
    return finalGrouped;
  }

  const resultsBeforeTaxFinance = totalResultsBeforeTaxFinance();

  function totalResultsBeforeTaxFinance() {
    let totais = totalsSellInformation.map(
      (totalsSellInformationYear, index) =>
        parseFloat(totalsSellInformationYear.value) +
        parseFloat(columns[index].operationSubvention) +
          ( (columns[index].inventoryProductionVariation ==='' || columns[index].inventoryProductionVariation==='')? 0 :  parseFloat(columns[index].inventoryProductionVariation)) +
        parseFloat(columns[index].itselfWork) -
        parseFloat(totalsConsumeInformation[index].value) -
        parseFloat(totalsProvideInformation[columns[index].year]) -
        parseFloat(peopleRemunerationTotals['YEARLY' + columns[index].year]) -
        (values.forecastDemonstrationList[0].type === ForeCastType.SNC
          ? parseFloat(columns[index].impairmentInventory) +
            parseFloat(columns[index].impairmentReceivables) + parseFloat(columns[index].otherImpairments)
          : parseFloat(columns[index].impairments)) -
        parseFloat(columns[index].provisions) +



        (values.forecastDemonstrationList[0].type === ForeCastType.SNC
          ? (columns[index].fairValueFluctuation === '-' || columns[index].fairValueFluctuation === ''
              ? 0
              :  parseFloat(columns[index].fairValueFluctuation)):0) +
        parseFloat(columns[index].otherIncomes) +
        parseFloat(columns[index].otherSpending) +
        parseFloat(values.otherChargeList[0].otherChargeLineList[index].financialCharges)
    );

    return totais;
  }

  return (
    <div className='financialBoards'>
      <h4 className='text-primary mb-0'>
        <FormattedMessage id='candidatureTabs.accordion.forecastDemonstration' />
      </h4>
      <small className='text-secondary text-uppercase'>
        {intl.formatMessage(
          { id: 'all.step' },
          { step: values.step, numberOfSteps: numberOfSteps }
        )}
      </small>

      <Row>
        <Col md='6'>
          <EnumSelectField
            nullOption={true}
            labelId='foreCastDemonstration.type'
            preffixDescriptionId={'forecastModel'}
            name='forecastDemonstrationList.type'
            options={ForeCastType.values()}
            value={values.forecastDemonstrationList[0].type}
            handleChange={(e) => handleType(e)}
            isInvalid={errors.forecastDemonstrationList?.[0]?.type}
            errorMessage={errors.forecastDemonstrationList?.[0]?.type}
          />
        </Col>
      </Row>

      {values.forecastDemonstrationList[0].type && (

        <div className='py-3 mb-4 financialBoards'>
          <Accordion defaultActiveKey={[columns[0].year.toString()]} alwaysOpen>
            {columns.map((column, index) => (
              <React.Fragment key={index}>
                {!CandidatureFormUtils.is3YearsCoverageOnly(values, column.year) && (
                  <Accordion.Item eventKey={column.year.toString()}>
                    <Accordion.Header>
                      {CandidatureFormUtils.getYearLabel(values, column.year)}
                    </Accordion.Header>
                    <Accordion.Body>
                      <Table className='w-100 mb-5 mt-4 d-table'>
                        <thead>
                          <tr className='border-1 border-bottom '>
                            <th className='p-lg-1 w-75'>
                              {values.forecastDemonstrationList[0].type === ForeCastType.SNC ? (
                                <FormattedMessage id='foreCastDemonstration.description.SNC' />
                              ) : (
                                <FormattedMessage id='foreCastDemonstration.description.NCM' />
                              )}
                            </th>
                            <th className='text-center'>
                              {' '}
                              <FormattedMessage id='foreCastDemonstration.value' />{' '}
                            </th>
                          </tr>
                          <tr className='border-1 border-bottom '>
                            <td>
                              <FormattedMessage id='foreCastDemonstration.sellInformation' />
                              <ToolTip message={<FormattedMessage id={'step14.tooltip.sell'}/>}/>





                            </td>

                            <td className='text-center'>
                              {maxFixed(totalsSellInformation[index].value)} €
                            </td>
                          </tr>

                          <tr className='border-1 border-bottom '>
                            <td>
                              <FormattedMessage id='foreCastDemonstration.operationSubvention' />
                            </td>

                            <td>
                              <DecimalInputField
                                controlId={`foreCastDemonstration.${column.year}.operationSubvention`}
                                name={`foreCastDemonstration.${column.year}.operationSubvention`}
                                handleChange={(e) =>
                                  handleField(e, column.year, 'operationSubvention')
                                }
                                value={columns[index].operationSubvention}
                                suffixLabel={'€'}
                                size={'md'}

                              />
                            </td>
                          </tr>

                          <tr className='border-1 border-bottom '>
                            <td>
                              <FormattedMessage id='foreCastDemonstration.inventoryProductionVariation' />
                            </td>
                            <td>
                              <DecimalInputField
                                controlId={`foreCastDemonstration.${column.year}.inventoryProductionVariation`}
                                name={`foreCastDemonstration.${column.year}.inventoryProductionVariation`}
                                handleChange={(e) =>
                                  handleField(e, column.year, 'inventoryProductionVariation')
                                }
                                value={columns[index].inventoryProductionVariation}
                                suffixLabel={'€'}
                                allowNegative={true}
                              />
                            </td>
                          </tr>
                          <tr className='border-1 border-bottom '>
                            <td>
                              <FormattedMessage id='foreCastDemonstration.itselfWork' />
                            </td>
                            <td>
                              <DecimalInputField
                                controlId={`foreCastDemonstration.${column.year}.itselfWork`}
                                name={`foreCastDemonstration.${column.year}.itselfWork`}
                                handleChange={(e) => handleField(e, column.year, 'itselfWork')}
                                value={columns[index].itselfWork}
                                suffixLabel={'€'}
                              />
                            </td>
                          </tr>
                          <tr className='border-1 border-bottom '>
                            <td>
                              <FormattedMessage id='foreCastDemonstration.consumeInformation' />
                              <ToolTip message={<FormattedMessage id={'step14.tooltip.consume'}/>}/>

                            </td>
                            <td className='text-center'>
                              {maxFixed(totalsConsumeInformation[index].value)} €
                            </td>
                          </tr>
                          <tr className='border-1 border-bottom '>
                            <td>
                              <FormattedMessage id='foreCastDemonstration.provideInformation' />
                              <ToolTip message={<FormattedMessage id={'step14.tooltip.provide'}/>}/>
                            </td>
                            <td className='text-center'>
                              {maxFixed(parseFloat(totalsProvideInformation[column.year]))} €

                            </td>
                          </tr>
                          <tr className='border-1 border-bottom '>
                            <td>
                              <FormattedMessage id='foreCastDemonstration.peopleRemuneration' />
                              <ToolTip message={<FormattedMessage id={'step14.tooltip.remuneration'}/>}/>

                            </td>

                            <td className='text-center'>
                              {maxFixed(
                                parseFloat(peopleRemunerationTotals['YEARLY' + column.year])
                              )}{' '}
                              €
                            </td>
                          </tr>

                          {values.forecastDemonstrationList[0].type === ForeCastType.SNC ? (
                            <>
                              <tr className='border-1 border-bottom '>
                                <td>
                                  <FormattedMessage id='foreCastDemonstration.impairmentInventory' />
                                  <ToolTip message={<FormattedMessage id={'tooltip.positivesLosses'}/>}/>
                                </td>
                                <td>
                                  <DecimalInputField
                                    controlId={`foreCastDemonstration.${column.year}.impairmentInventory`}
                                    name={`foreCastDemonstration.${column.year}.impairmentInventory`}
                                    handleChange={(e) =>
                                      handleField(e, column.year, 'impairmentInventory')
                                    }
                                    value={columns[index].impairmentInventory}
                                    suffixLabel={'€'}
                                    allowNegative={true}
                                  />
                                </td>
                              </tr>
                              <tr className='border-1 border-bottom '>
                                <td>
                                  <FormattedMessage id='foreCastDemonstration.impairmentReceivables' />
                                  <ToolTip message={<FormattedMessage id={'tooltip.positivesLosses'}/>}/>
                                </td>
                                <td>
                                  <DecimalInputField
                                    controlId={`foreCastDemonstration.${column.year}.impairmentReceivables`}
                                    name={`foreCastDemonstration.${column.year}.impairmentReceivables`}
                                    handleChange={(e) =>
                                      handleField(e, column.year, 'impairmentReceivables')
                                    }
                                    value={columns[index].impairmentReceivables}
                                    suffixLabel={'€'}
                                    allowNegative={true}
                                  />
                                </td>
                              </tr>
                            </>
                          ) : (
                            <tr className='border-1 border-bottom '>
                              <td>
                                <FormattedMessage id='foreCastDemonstration.impairments' />
                                <ToolTip message={<FormattedMessage id={'tooltip.positivesLosses'}/>}/>
                              </td>
                              <td>
                                <DecimalInputField
                                  controlId={`foreCastDemonstration.${column.year}.impairments`}
                                  name={`foreCastDemonstration.${column.year}.impairments`}
                                  handleChange={(e) => handleField(e, column.year, 'impairments')}
                                  value={columns[index].impairments}
                                  suffixLabel={'€'}
                                  allowNegative={true}
                                />
                              </td>
                            </tr>
                          )}

                          <tr className='border-1 border-bottom '>
                            <td>
                              <FormattedMessage id='foreCastDemonstration.provisions' />
                              <ToolTip message={<FormattedMessage id={'tooltip.positivesEarnings'}/>}/>
                            </td>
                            <td>
                              <DecimalInputField
                                controlId={`foreCastDemonstration.${column.year}.provisions`}
                                name={`foreCastDemonstration.${column.year}.provisions`}
                                handleChange={(e) => handleField(e, column.year, 'provisions')}
                                value={columns[index].provisions}
                                suffixLabel={'€'}
                                allowNegative={true}
                              />
                            </td>
                          </tr>

                          {values.forecastDemonstrationList[0].type === ForeCastType.SNC && (
                            <>
                              <tr className='border-1 border-bottom '>
                                <td>
                                  <FormattedMessage id='foreCastDemonstration.otherImpairments' />
                                  <ToolTip message={<FormattedMessage id={'tooltip.positivesLosses'}/>}/>

                                </td>
                                <td>
                                  <DecimalInputField
                                    controlId={`foreCastDemonstration.${column.year}.otherImpairments`}
                                    name={`foreCastDemonstration.${column.year}.otherImpairments`}
                                    handleChange={(e) =>
                                      handleField(e, column.year, 'otherImpairments')
                                    }
                                    value={columns[index].otherImpairments}
                                    suffixLabel={'€'}
                                    allowNegative={true}


                                  />
                                </td>
                              </tr>
                              <tr className='border-1 border-bottom '>
                                <td>
                                  <FormattedMessage id='foreCastDemonstration.fairValueFluctuation' />
                                  <ToolTip
                                      message={intl.formatMessage({
                                        id: 'tooltip.positivesEarnings'
                                      })}
                                  />
                                </td>
                                <td>
                                  <DecimalInputField
                                    controlId={`foreCastDemonstration.${column.year}.fairValueFluctuation`}
                                    name={`foreCastDemonstration.${column.year}.fairValueFluctuation`}
                                    handleChange={(e) =>
                                      handleField(e, column.year, 'fairValueFluctuation')
                                    }
                                    value={columns[index].fairValueFluctuation}
                                    suffixLabel={'€'}
                                    allowNegative={true}
                                  />
                                </td>
                              </tr>
                            </>
                          )}

                          <tr className='border-1 border-bottom '>
                            <td>
                              <FormattedMessage id='foreCastDemonstration.otherIncomes' />
                            </td>
                            <td>
                              <DecimalInputField
                                controlId={`foreCastDemonstration.${column.year}.otherIncomes`}
                                name={`foreCastDemonstration.${column.year}.otherIncomes`}
                                handleChange={(e) => handleField(e, column.year, 'otherIncomes')}
                                value={columns[index].otherIncomes}
                                suffixLabel={'€'}
                              />
                            </td>
                          </tr>

                          <tr className='border-1 border-bottom '>
                            <td>
                              <FormattedMessage id='foreCastDemonstration.otherSpending' />
                            </td>
                            <td>
                              <DecimalInputField
                                controlId={`foreCastDemonstration.${column.year}.otherSpending`}
                                name={`foreCastDemonstration.${column.year}.otherSpending`}
                                handleChange={(e) => handleField(e, column.year, 'otherSpending')}
                                value={columns[index].otherSpending}
                                suffixLabel={'€'}
                              />
                            </td>
                          </tr>
                          <tr className='border-1 border-bottom '>
                            <td>
                              <FormattedMessage id='foreCastDemonstration.financialCharges' />
                              <ToolTip message={<FormattedMessage id={'step14.tooltip.otherCharge'}/>}/>
                            </td>
                            <td className='text-center'>
                              {maxFixed(
                                values.otherChargeList[0].otherChargeLineList[index]
                                  .financialCharges
                              )}{' '}
                              €
                            </td>
                          </tr>

                          <tr className='border-1 border-bottom'>
                            <td>
                              <strong>
                                <p className='text-end mb-0'>
                                  <FormattedMessage id='foreCastDemonstration.resultBeforeTaxFinance1' />
                                  <FormattedMessage id='foreCastDemonstration.resultBeforeTaxFinance2' />
                                </p>
                              </strong>
                            </td>

                            <td className='text-center' key={index}>
                              {maxFixed(resultsBeforeTaxFinance[index])}€
                            </td>
                          </tr>

                          <tr className='border-1 border-bottom '>
                            <td>
                              <FormattedMessage id='foreCastDemonstration.depreciationAmortization' />
                              <ToolTip message={<FormattedMessage id={'step14.tooltip.amortization'}/>}/>



                            </td>
                            <td className='text-center'>
                                {maxFixed(
                                    columns[index].depreciationAmortization
                                )}
                                €


                            </td>
                          </tr>
                          <tr className='border-1 border-bottom '>
                            <td>
                              <strong>
                                <p className='text-end mb-0'>
                                  <FormattedMessage id='foreCastDemonstration.operacionalResult' /></p>
                              </strong>
                            </td>

                            <td className='text-center ' key={index}>
                              {' '}
                              {maxFixed(
                                resultsBeforeTaxFinance[index] -
                                  columns[index].depreciationAmortization
                              )}{' '}
                              €
                            </td>
                          </tr>

                          {values.forecastDemonstrationList[0].type === ForeCastType.SNC ? (
                            <>
                              <tr className='border-1 border-bottom '>
                                <td>
                                  <FormattedMessage id='foreCastDemonstration.earnedFees' />
                                </td>
                                <td>
                                  <DecimalInputField
                                    controlId={`foreCastDemonstration.${column.year}.earnedFees`}
                                    name={`foreCastDemonstration.${column.year}.earnedFees`}
                                    handleChange={(e) => handleField(e, column.year, 'earnedFees')}
                                    value={columns[index].earnedFees}
                                    suffixLabel={'€'}
                                    allowNegative={true}
                                  />
                                </td>
                              </tr>
                              <tr className='border-1 border-bottom '>
                                <td>
                                  <FormattedMessage id='foreCastDemonstration.supportedFees' />
                                </td>
                                <td>
                                  <DecimalInputField
                                    controlId={`foreCastDemonstration.${column.year}.supportedFees`}
                                    name={`foreCastDemonstration.${column.year}.supportedFees`}
                                    handleChange={(e) =>
                                      handleField(e, column.year, 'supportedFees')
                                    }
                                    value={columns[index].supportedFees}
                                    suffixLabel={'€'}
                                    allowNegative={true}
                                  />
                                </td>
                              </tr>
                            </>
                          ) : (
                            <tr className='border-1 border-bottom '>
                              <td>
                                <FormattedMessage id='foreCastDemonstration.netExpense' />
                              </td>
                              <td>
                                <DecimalInputField
                                  controlId={`foreCastDemonstration.${column.year}.netExpense`}
                                  name={`foreCastDemonstration.${column.year}.netExpense`}
                                  handleChange={(e) => handleField(e, column.year, 'netExpense')}
                                  value={columns[index].netExpense}
                                  suffixLabel={'€'}
                                />
                              </td>
                            </tr>
                          )}

                          <tr className='border-1 border-bottom '>
                            <td className='text-end'>
                              <strong>
                                <FormattedMessage id='foreCastDemonstration.restultBeforeTax' />
                              </strong>
                            </td>
                            <td className='text-center ' key={index}>
                              {' '}
                              {maxFixed(
                                parseFloat(resultsBeforeTaxFinance[index]) -
                                  parseFloat(columns[index].depreciationAmortization) +
                                  (values.forecastDemonstrationList[0].type === ForeCastType.SNC
                                    ? parseFloat(columns[index].earnedFees)
                                    : 0) -
                                  (values.forecastDemonstrationList[0].type === ForeCastType.SNC
                                    ? parseFloat(columns[index].supportedFees)
                                    : parseFloat(columns[index].netExpense))
                              )}{' '}
                              €
                            </td>
                          </tr>
                          <tr className='border-1 border-bottom '>
                            <td>
                              <FormattedMessage id='foreCastDemonstration.taxNoIncomePeriod' />
                            </td>
                            <td>
                              <DecimalInputField
                                controlId={`foreCastDemonstration.${column.year}.taxNoIncomePeriod`}
                                name={`foreCastDemonstration.${column.year}.taxNoIncomePeriod`}
                                handleChange={(e) =>
                                  handleField(e, column.year, 'taxNoIncomePeriod')
                                }
                                value={columns[index].taxNoIncomePeriod}
                                suffixLabel={'€'}
                                allowNegative={true}
                              />
                            </td>
                          </tr>
                          <tr className='border-1 border-bottom '>
                            <td className='text-end'>
                              <strong>
                                <FormattedMessage id='foreCastDemonstration.netResult' />
                              </strong>
                            </td>
                            <td className='text-center' key={index}>
                              {' '}
                              {maxFixed(
                                parseFloat(resultsBeforeTaxFinance[index]) -
                                  parseFloat(columns[index].depreciationAmortization) +
                                  (values.forecastDemonstrationList[0].type === ForeCastType.SNC
                                    ? parseFloat(columns[index].earnedFees)
                                    : 0) -
                                  (values.forecastDemonstrationList[0].type === ForeCastType.SNC
                                    ? parseFloat(columns[index].supportedFees)
                                    : parseFloat(columns[index].netExpense)) -
                                  parseFloat(columns[index].taxNoIncomePeriod)
                              )}{' '}
                              €
                            </td>
                          </tr>
                        </thead>
                        <tbody></tbody>
                      </Table>
                    </Accordion.Body>
                  </Accordion.Item>
                )}
              </React.Fragment>
            ))}
          </Accordion>

        </div>


      )}

      <Row>
        <Col md='12'>
          <TextAreaInputField
              labelId={'foreCastDemonstration.reason'}
              name={'candidatureForm.otherCharge.foreCastDemonstration'}
              value={values.forecastDemonstrationList[0].reasonToForecastDemonstration}
              handleChange={(e) => handleReason(e)}
              placeholder={intl.formatMessage({ id: 'all.maxLength.placeholder' })}
              maxLength={10000}
              isInvalid={
                errors.forecastDemonstrationList ? errors.forecastDemonstrationList[0]?.reasonToForecastDemonstration : false
              }
              errorMessage={
                errors.forecastDemonstrationList ? errors.forecastDemonstrationList[0]?.reasonToForecastDemonstration : ''
              }
          />
        </Col>
      </Row>

      <div className='form-actions mt-5'>
        <button
          className='btn btn-link'
          type='button'
          onClick={() => handleGoBack(values, setFieldValue)}>
          <FormattedMessage id='all.back' />
        </button>

        <button className='btn btn-primary' type='button' onClick={() => handleNextStep()}>
          <FormattedMessage id='all.nextStep' />
        </button>
      </div>
    </div>
  );
}
