export const NonComplianceState = {
    INITIATED: 'INITIATED',
    APPEALED: 'APPEALED',
    APPEAL_DEADLINE_MISSED: 'APPEAL_DEADLINE_MISSED',
    FINAL: 'FINAL',
    CANCELED: 'CANCELED',

    values: () => {
        return ['INITIATED', 'APPEALED', 'APPEAL_DEADLINE_MISSED', 'FINAL', 'CANCELED'];
    }
}
