import {FormattedMessage} from "react-intl";
import React, {useState} from "react";
import {Modal} from "react-bootstrap";
import {EaProtocolForm} from "./form/EaProtocolForm";

export function EaProtocolModal({entityNif, show, setShow, onSubmitCallback, documents, setDocuments, entity}) {
    const [error, setError] = useState(null);


    async function onSubmit() {
        try {
            setShow(false);
            onSubmitCallback?.()
        } catch (error) {
            setError(error);
        }
    }

    function handleClose() {
        setShow(false);
    }

    return (
        <Modal show={show} onHide={handleClose} dialogClassName=''>
            <Modal.Header closeButton>
                <Modal.Title><FormattedMessage id='eaAccreditationForm.button.uploadProtocol' /></Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <EaProtocolForm
                    documents={documents}
                    setDocuments={setDocuments}
                    onSubmitCallback={onSubmit}
                    entityNif={entityNif}
                    onCancel={handleClose}
                    entity={entity}
                />
            </Modal.Body>
        </Modal>

    );
}