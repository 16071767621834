import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';

export function CloseableModalDialog({
    show,
    setShow,
    closeCallback,
    titleId,
    children
}) {
    function close() {
        setShow(false);
        closeCallback?.();
    }

    if (!show) return <></>;

    return (
        <Modal show={show} onHide={close}>
            <Modal.Header closeButton>
                <Modal.Title>
                    {titleId && <FormattedMessage id={titleId} />}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {children}
            </Modal.Body>
            <Modal.Footer className='text-end'>
                <button className={'btn btn-outline-primary px-5'} onClick={close}>
                    <FormattedMessage id='all.close' />
                </button>
            </Modal.Footer>
        </Modal>
    );
}
