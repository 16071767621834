import React, {useState} from 'react'
import * as yup from 'yup';
import {Button, Col, Row} from 'react-bootstrap';
import {useNavigate} from 'react-router-dom';
import {FormattedMessage} from 'react-intl';
import {AuthenticatedDownloadLink} from '../document/AuthenticatedDownloadLink';
import {Arrays} from '../../utils/Arrays';
import Loading from "../general/Loading";
import {handleError, isNotBusinessError} from "../../utils/handleError";
import parse from "html-react-parser";

export function AdditionalDueDiligenceAnalysisForm({externalId, additionalDueDiligence}) {

    const navigate = useNavigate();

    const schema = yup.object().shape({
        reason: yup
            .string()
            .required(<FormattedMessage id='errors.fieldRequiredText'/>),
    });

    const [showReject, setShowReject] = useState(false);


    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    function gotToAdditionalDueDiligenceAnswer() {
        navigate(`/candidatura/${externalId}/responderDiligenciasComplementares`);
    }

    if (loading) {
        return <Loading/>;
    }

    if (error && isNotBusinessError(error)) {
        return handleError(error);
    }


    return (
        <Row>
            <Col md={{span: 8}}>
                <div className='form mb-5'>
                    <label>
                        <FormattedMessage id='candidature.addAdditionalDueDiligence.motive'/>
                    </label>
                    <p>
                        {additionalDueDiligence.motive && parse(additionalDueDiligence.motive) }
                    </p>

                    <div className='border-top py-2'>
                        {Arrays.isNotEmpty(additionalDueDiligence.documents) ? (
                            <label>
                                <FormattedMessage id='candidature.addAdditionalDueDiligence.documents'/>
                            </label>
                        ) : (
                            <p className='text-secondary'>
                                <FormattedMessage id='candidature.addAdditionalDueDiligence.emptyDocuments'/>
                            </p>
                        )}
                    </div>
                    <div>
                        {Arrays.isNotEmpty(additionalDueDiligence.documents) &&
                            additionalDueDiligence.documents.map((document, index) => (
                                <Row className='mb-3' key={index}>
                                    <Col>
                                        <label>
                                            {document.fileDescription}
                                        </label>
                                        <div>
                                            <AuthenticatedDownloadLink
                                                url={document.documentPath}
                                                filename={document.name}
                                            >
                                                {document.name}
                                            </AuthenticatedDownloadLink>
                                        </div>
                                    </Col>
                                </Row>
                            ))}
                    </div>
                </div>
            </Col>
            <Col md='3'>
                <div className="leftButtonsContainer">
                    <Button
                        variant='btn btn-outline-primary d-flex align-items-center justify-content-center w-100 mb-4'
                        onClick={() => {
                            gotToAdditionalDueDiligenceAnswer();
                        }}>
                        <FormattedMessage id='all.reply'/>
                    </Button>
                </div>
            </Col>
        </Row>
    )
}
