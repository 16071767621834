import { useKeycloak } from '@react-keycloak/web';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  constructAccessInfoFromKeycloak,
  saveInStorageAccessInfo,
  saveUserInfoInStorage,
} from '../authentication/authenticationHelper';
import { useGlobalContext } from '../context';
import { useQuery } from '../hooks/useQuery';
import { getUserPermissions } from '../rest/authentication';
import { getOrCreateUser } from '../rest/user';

const getRedirectPath = (redirectPath) => {
  if (!redirectPath) {
    return '';
  }

  if (redirectPath === 'null' || redirectPath === 'undefined') {
    return '';
  } else {
    return redirectPath;
  }
};

function KeycloakAuthentication(props) {
  const { keycloak, initialized } = useKeycloak();
  const { setAccessInfo, setGlobalLoading } = useGlobalContext();
  const query = useQuery();

  const redirectPath = query.get('r');

  let navigate = useNavigate();

  let from = getRedirectPath(redirectPath);

  const fetchPermissionsAndSave = async () => {
    try {
      const [{ data: permissions }, { data: user }] = await Promise.all([
        getUserPermissions(keycloak.token),
        getOrCreateUser(keycloak.token),
      ]);

      const access_info = constructAccessInfoFromKeycloak(
        keycloak,
        permissions
      );
      saveInStorageAccessInfo(access_info);
      setAccessInfo(access_info);
      if (user.userType) {
        console.log(user.userType)
        const userInfo = {
          name: user.name,
          email: user.email,
          nif: user.nif,
          userState: user.userState,
          userType: user.userType,
          rd:user.rd
        };
        saveUserInfoInStorage(userInfo);
      }

      setGlobalLoading(false);
      navigate(`/${from}`);
    } catch (error) {
      setGlobalLoading(false);
      navigate('/');
    }
  };

  useEffect(() => {
    setGlobalLoading(true);

    if (!initialized) {
      return;
    }
    if (!keycloak.authenticated) {
      keycloak.login();
    } else {
      fetchPermissionsAndSave();
    }
  }, [initialized]);

  return <div />;
}

export default KeycloakAuthentication;
