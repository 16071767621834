import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { SingleUploadArea } from '../../../components/SingleUploadArea';
import { AlertError } from '../../../components/bootstrap/AlertError';
import Loading from '../../../components/general/Loading';
import { AuthenticatedDownloadLink } from '../../../components/document/AuthenticatedDownloadLink';
import { CandidatureState } from '../../../models/CandidatureState';
import { DocumentType } from '../../../models/DocumentType';
import {
	deleteCandidatureChangeRequestDocument,
	listCandidatureChangeRequestDocumentsByType,
	setToAcceptanceTerm,
	submitAcceptanceTerm,
	submitInitialDecision,
	uploadCandidatureChangeRequestDocument,
} from '../../../rest/candidatureChangeRequest';

export function CandidatureChangeRequestAcceptanceTermSubmission({
	candidatureChange,
	setShowModal,
}) {
	const intl = useIntl();

	const { candidatureExternalId, externalId } = useParams();
	const [loading, setLoading] = useState(true);

	const location = useLocation();

	const navigate = useNavigate();

	const [error, setError] = useState(null);

	const [termAcceptDocument, setTermAcceptDocument] = useState([]);
	const [termAcceptDraftDocument, setTermAcceptDraftDocument] = useState([]);

	async function fetchData() {
		try {
			const [{ data: termAccept }, { data: termAcceptDraft }] =
				await Promise.all([
					await listCandidatureChangeRequestDocumentsByType(
						DocumentType.TERM_ACCEPT,
						candidatureExternalId,
						externalId
					),
					await listCandidatureChangeRequestDocumentsByType(
						DocumentType.TERM_ACCEPT_DRAFT,
						candidatureExternalId,
						externalId
					),
				]);

			loadDocument(termAccept, setTermAcceptDocument, DocumentType.TERM_ACCEPT);
			loadDocument(
				termAcceptDraft,
				setTermAcceptDraftDocument,
				DocumentType.TERM_ACCEPT_DRAFT
			);
		} catch (error) {
			setError(error);
		} finally {
			setLoading(false);
		}
	}

	function loadDocument(data, setDocument, documentType) {
		if (data.length !== 0) {
			const document = data[0];
			setDocument([
				{
					documentType: document.documentType,
					externalId: document.externalId,
					name: document.name,
					documentPath: document.documentPath,
					content: '',
					submited: true,
					error: '',
				},
			]);
		} else {
			setDocument([
				{
					documentType: documentType,
					externalId: '',
					name: '',
					content: null,
					submited: false,
					error: '',
				},
			]);
		}
	}

	function canSubmit() {
		return termAcceptDocument[0]?.submited;
	}

	async function handleConfirm() {
		try {
			await submitAcceptanceTerm(candidatureExternalId, externalId);

			//refresh page
			navigate(0);
		} catch (error) {
			setError(error);
		}
	}

	useEffect(() => {
		fetchData();
	}, []);

	const uploadCallback = async (formData) => {
		return await uploadCandidatureChangeRequestDocument(
			candidatureExternalId,
			externalId,
			formData
		);
	};

	const deleteCallback = async (fileExternalId) => {
		return await deleteCandidatureChangeRequestDocument(
			candidatureExternalId,
			externalId,
			fileExternalId
		);
	};

	return (
		<Modal show={true} onHide={() => setShowModal(false)}>
			<Modal.Header closeButton>
				<FormattedMessage
					id={`candidature.flow.submitTermsOfAccept`}
				/>
			</Modal.Header>
			<Modal.Body>
				{loading ? (
					<span>
						<Loading isDialog />
					</span>
				) : (
					<>
						<Row className='mt-3 mb-4'>
							<Col>
								<label>
									<FormattedMessage id='newAcceptanceTerm.download.label' />
								</label>
								<AuthenticatedDownloadLink
									url={termAcceptDraftDocument[0].documentPath}
									filename={termAcceptDraftDocument[0].name}
								>
									<FormattedMessage id='newAcceptanceTerm.download' />
								</AuthenticatedDownloadLink>
							</Col>
						</Row>
						<Row className='px-2'>
							<Col>
								<SingleUploadArea
									documents={termAcceptDocument}
									setDocuments={setTermAcceptDocument}
									documentType={DocumentType.TERM_ACCEPT}
									setError={setError}
									processUploadCallback={uploadCallback}
									processDeleteCallback={deleteCallback}
								/>
							</Col>
							<AlertError error={error} />
						</Row>
					</>
				)}
			</Modal.Body>
			<Modal.Footer className='d-flex justify-content-between'>
				<Button variant='outline-secondary' onClick={() => setShowModal(false)}>
					<FormattedMessage id='all.cancel' />
				</Button>
				<Button
					variant='primary'
					onClick={handleConfirm}
					disabled={!canSubmit()}
				>
					<FormattedMessage id='all.submitButtonText' />
				</Button>
			</Modal.Footer>
		</Modal>
	);
}
