import { CandidatureChangeStateWithReason } from "../CandidatureChangeStateWithReason";
import { useParams } from "react-router-dom";
import { setToRejectPromoters } from "../../../rest/candidature";
import {listDocumentsByTypeAndCandidatureExternalId} from "../../../rest/document";
import React, {useEffect, useState} from "react";
import {DocumentType} from "../../../models/DocumentType";
import {handleError, isNotBusinessError} from "../../../utils/handleError";

export function CandidatureFlowRejectCandidature({ callbackHandler }) {
  const { externalId } = useParams();
  const [userDocumentPublic, setUserDocumentPublic] = useState(null);
  const [userDocumentPrivate, setUserDocumentPrivate] = useState(null);
  const [error, setError] = useState(null);






  async function fetchData() {
    try {



      const [ { data: publicDocument }, { data: privateDocument }] = await Promise.all([
        await listDocumentsByTypeAndCandidatureExternalId(publicType, externalId),
        await listDocumentsByTypeAndCandidatureExternalId(privateType, externalId)
      ]);
      setUserDocumentPublic(publicDocument);
      setUserDocumentPrivate(privateDocument);

      loadDocument(publicDocument, setUserDocumentPublic, publicType);
      loadDocument(privateDocument, setUserDocumentPrivate, privateType);

    } catch (error) {
      setError(error);
    }



  }

  useEffect(() => {
    fetchData();
  }, []);



  function loadDocument(data, setDocument, documentType) {
    if (data.length !== 0) {
      const document = data[0];
      setDocument([
        {
          documentType: document.documentType,
          externalId: document.externalId,
          name: document.name,
          documentPath: document.documentPath,
          content: '',
          submited: true,
          error: ''
        }
      ]);
    } else {
      setDocument([
        {
          documentType: documentType,
          externalId: '',
          name: '',
          content: null,
          submited: false,
          error: ''
        }
      ]);
    }
  }


  if (error && isNotBusinessError(error)) {
    return handleError(error);
  }

  const publicType = DocumentType.DECISION_ELIGIBILITY;
  const privateType= DocumentType.DECISION_ELIGIBILITY_PRIVATE;

  function rejectCandidadure(reasonData) {
    callbackHandler(() => {
      return setToRejectPromoters(externalId, reasonData);
    });
  }


  return (
    <>
      <CandidatureChangeStateWithReason
        modalTitleId="candidature.flow.rejectEligiblePromoters"
        modalReasonTextId="candidature.message.setReasonToRejectCandidature"
        flowActionId="candidature.flow.rejectEligiblePromoters"
        submitHandler={rejectCandidadure}
        userDocuments={userDocumentPublic}
        userDocuments2={userDocumentPrivate}
        documentType={publicType}
        documentType2={privateType}
        externalId={externalId}
        setUserDocuments={setUserDocumentPublic}
        setUserDocuments2={setUserDocumentPrivate}
        singles={true}
        setError={setError}
        documentsRequired={true}
      />
    </>
  );
}
