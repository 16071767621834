import React from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { FaInfoCircle } from 'react-icons/fa';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { DocumentType } from '../../models/DocumentType';
import { Arrays } from "../../utils/Arrays";
import { MultipleUploadArea } from '../MultipleUploadArea';
import ContratoMCE from '../../documents/ANEXO B3 - CONTRATO DE PRESTACAO DE MCE A CRIACAO E ESTRUTURACAO DE PROJETOS.docx';

export function AddContractPriorSupportForm({
  onSubmit,
  userDocuments,
  setDocuments,
  externalId,
}) {
  return (
    <div className='mb-5'>
      <h2 className='mb-1 text-secondary'>
        <FormattedMessage id='candidature.submitContractPriorSupport.title' />
      </h2>
      <p className='mb-5 text-secondary'>
        <FormattedMessage id='candidature.submitContractPriorSupport.addDocuments' />
      </p>

      <Form>
        <div className='mb-3'>
          <Row>
            <Col>
              <p className='mt-3 text-secondary infoText'>
                <FaInfoCircle size={40} />
                <FormattedMessage id='contratactMCE.promoter.info' />
              </p>
            </Col>
          </Row>
          <Row>
            <Col>
              <a target='_blank' rel='noreferrer' href={ContratoMCE}>
                <FormattedMessage id='contractMCE.download' />
              </a>
            </Col>
          </Row>
        </div>

        <MultipleUploadArea
          documents={userDocuments}
          documentType={DocumentType.CONTRACT_PRIOR_SUPPORT}
          candidatureExternalId={externalId}
          setDocuments={setDocuments}
        />

        <div className='form-actions'>
          <Link to={'/'}>
            <button className='btn btn-link' type='button'>
              <FormattedMessage id='all.backButtonText' />
            </button>
          </Link>

          {Arrays.isNotEmpty(userDocuments) && (
            <button
              className='btn btn-primary'
              type='button'
              onClick={onSubmit}
            >
              <FormattedMessage id='all.submitButtonText' />
            </button>
          )}
        </div>
      </Form>
    </div>
  );
}
