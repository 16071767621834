import {Formik} from 'formik';
import React from 'react';
import {Accordion, Button, Col, Form, Row} from 'react-bootstrap';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import {FormattedMessage, useIntl} from 'react-intl';
import {Link} from 'react-router-dom';
import * as yup from 'yup';
import {SyncMultipleUploadArea} from "../SyncMultipleUploadArea";
import {DocumentType} from "../../models/DocumentType";
import {TextInputField} from "../bootstrap/TextInputField";
import {TextAreaInputField} from "../bootstrap/TextAreaInputField";
import {EnumSelectField} from "../bootstrap/EnumSelectField";
import {NumberInputField} from "../bootstrap/NumberInputField";
import {Validator} from "../../utils/Validator";
import {FaExclamationCircle} from 'react-icons/fa';
import ToolTip from "../bootstrap/ToolTip";

export function EntityChangeHRForm({
                                       entityChange,
                                       onSubmit,
                                       documents,
                                       setDocuments,
                                       qualificationLevelOptions,
                                       setError,
                                       managerQualificationLevelOptions
                                   }) {


    const intl = useIntl();


    function removeTGP(index, values, setFieldValue) {
        values.tgpList.splice(index, 1);
        setFieldValue({...values});
    }


    const schema = yup.object().shape({
        reason: yup
            .string()
            .required(
                <FormattedMessage id='errors.fieldRequiredText'/>
            ),
        getList: yup.array().of(
            yup.object().shape({
                name: yup
                    .string()
                    .required(<FormattedMessage id='errors.fieldRequiredText'/>),
                phone: yup
                    .string()
                    .test(
                        'phoneRequired',
                        <FormattedMessage id='errors.fieldRequiredText' />,
                        (value, context) => value !== undefined || context.parent.mobile !== undefined
                    )
                    .test(
                        'isPhoneValid',
                        <FormattedMessage id='errors.eaAccreditationForm.phoneAndMobile' />,
                        (value, context) => Validator.validateCommsNumber(value) || (context.parent.mobile !== undefined && value === undefined)
                    ),
                mobile: yup
                    .string()
                    .test(
                        'mobileRequired',
                        <FormattedMessage id='errors.fieldRequiredText' />,
                        (value, context) => value !== undefined || context.parent.phone !== undefined
                    )
                    .test(
                        'isMobileValid',
                        <FormattedMessage id='errors.eaAccreditationForm.phoneAndMobile' />,
                        (value, context) => Validator.validateCommsNumber(value) || (context.parent.phone !== undefined && value === undefined)
                    ),
                email: yup
                    .string()
                    .required(<FormattedMessage id='errors.fieldRequiredText'/>)
                    .test(
                        'isEmailValid',
                        <FormattedMessage id='errors.candidatureForm.email'/>,
                        (value) => Validator.validateEmail(value)
                    ),
                managementExperience: yup.boolean(),
                qualificationLevelCode: yup
                    .string()
                    .test(
                        'isQualificationLevelValid',
                        <FormattedMessage id='errors.eaAccreditationForm.qualificationLevelCode'/>,
                        (value) => value !== '-1' && value !== undefined
                    ),
            })
        ),
        tgpList: yup.array().of(
            yup.object().shape({
                name: yup
                    .string()
                    .required(<FormattedMessage id='errors.fieldRequiredText'/>),
                qualificationLevelCode: yup
                    .string()
                    .test(
                        'isQualificationLevelValid',
                        <FormattedMessage id='errors.eaAccreditationForm.qualificationLevelCode'/>,
                        (value) => value !== '-1' && value !== undefined
                    ),
                hre: yup.object().shape({
                    nif: yup
                        .string()
                        .required(<FormattedMessage id='errors.fieldRequiredText'/>)
                        .test(
                            'isNifValid',
                            <FormattedMessage id='errors.promoterForm.nif'/>,
                            (value) => Validator.validateNif(value)
                        ),
                    bondType: yup
                        .string()
                        .test(
                            'isBondTypeValid',
                            <FormattedMessage id='errors.eaAccreditationForm.bondType'/>,
                            (value) => value !== '-1' && value !== undefined
                        ),
                    projectCreationCount: yup
                        .number()
                        .required(<FormattedMessage id='errors.fieldRequiredText'/>)
                        .test(
                            'isNumberValid',
                            <FormattedMessage id='errors.eaAccreditationForm.number'/>,
                            (value) => value >= 0
                        ),
                    approvedProjectFollowUpCount: yup
                        .number()
                        .required(<FormattedMessage id='errors.fieldRequiredText'/>)
                        .test(
                            'isNumberValid',
                            <FormattedMessage id='errors.eaAccreditationForm.number'/>,
                            (value) => value >= 0
                        ),
                    consultingCount: yup
                        .number()
                        .required(<FormattedMessage id='errors.fieldRequiredText'/>)
                        .test(
                            'isNumberValid',
                            <FormattedMessage id='errors.eaAccreditationForm.number'/>,
                            (value) => value >= 0
                        ),
                    expandSkillsCount: yup
                        .number()
                        .required(<FormattedMessage id='errors.fieldRequiredText'/>)
                        .test(
                            'isNumberValid',
                            <FormattedMessage id='errors.eaAccreditationForm.number'/>,
                            (value) => value >= 0
                        ),
                    analysisCount: yup
                        .number()
                        .required(<FormattedMessage id='errors.fieldRequiredText'/>)
                        .test(
                            'isNumberValid',
                            <FormattedMessage id='errors.eaAccreditationForm.number'/>,
                            (value) => value >= 0
                        ),
                }),
            })
        ),


    });


    function checkBoxOnChangeHandler(index, property, values, setFieldValue) {
        if (values.getList[index][property]) {
            setFieldValue(
                `getList[${index}].${property}`,
                !values.getList[index][property]
            );
        } else {
            setFieldValue(`getList[${index}].${property}`, true);
        }
    }


    function addTGP(values, setFieldValue) {
        let newTGP = {
            name: '',
            qualificationLevelCode: '-1',
            hre: {
                nif: '',
                bondType: '-1',
                projectCreationCount: 0,
                approvedProjectFollowUpCount: 0,
                consultingCount: 0,
                expandSkillsCount: 0,
                analysisCount: 0,
            }
        };
        values.tgpList.push(newTGP);
        setFieldValue({...values});
    }


    return (
        <Formik
            initialValues={entityChange}
            validationSchema={schema}
            validateOnBlur={false}
            validateOnChange={false}
            onSubmit={(values, {setSubmitting, setErrors}) => {
                onSubmit(values, setSubmitting, setErrors);
            }}
        >
            {({values, errors, touched, handleChange, handleSubmit, setFieldValue}) => (
                <form onSubmit={handleSubmit}>
                    <Row>
                        <Col>
                            <TextAreaInputField
                                controlId={'reason'}
                                labelId={'entityChange.reason'}
                                name={'reason'}
                                handleChange={handleChange}
                                value={values?.reason}
                                isInvalid={errors.reason}
                                errorMessage={errors.reason}
                                maxLength={1000}
                            />
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <SyncMultipleUploadArea
                                name='files'
                                documents={documents}
                                setDocuments={setDocuments}
                                documentType={DocumentType.REASON_ENTITY_CHANGE}
                            />
                        </Col>
                    </Row>


                    <Row>
                        <Col>
                            <Form.Label>
                                <FormattedMessage id='eaAccreditationForm.step4.eaHumanResources'/>
                            </Form.Label>
                        </Col>
                    </Row>


                    {values.getList.map((get, index) => (
                        <Row key={index}>
                            <Col>
                                <Accordion>
                                    <Accordion.Item eventKey='0'>
                                        <Accordion.Header>
									<span className='d-flex align-items-center'>
										{errors.getList?.[index] && (
                                            <FaExclamationCircle/>
                                        )}
                                        <FormattedMessage id='eaAccreditationForm.step4.accordion.function.get'/>{' '}
                                        {' - ' + get.regionalDelegationCode}
									</span>
                                        </Accordion.Header>
                                        <Accordion.Body>

                                            <>
                                                <Row>
                                                    <Col>
                                                        <TextInputField
                                                            labelId={'eaAccreditationForm.step1.getList.name'}
                                                            name={`getList[${index}].name`}
                                                            handleChange={handleChange}
                                                            value={values.getList[index].name}
                                                            isInvalid={errors.getList?.[index]?.name}
                                                            errorMessage={errors.getList?.[index]?.name}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <NumberInputField
                                                            labelId={'eaAccreditationForm.step1.getList.phone'}
                                                            name={`getList[${index}].phone`}
                                                            handleChange={handleChange}
                                                            value={values.getList[index].phone}
                                                            isInvalid={errors.getList?.[index]?.phone}
                                                            errorMessage={errors.getList?.[index]?.phone}
                                                            maxLength={9}
                                                        />
                                                    </Col>
                                                    <Col>
                                                        <NumberInputField
                                                            labelId={'eaAccreditationForm.step1.getList.mobile'}
                                                            name={`getList[${index}].mobile`}
                                                            handleChange={handleChange}
                                                            value={values.getList[index].mobile}
                                                            isInvalid={errors.getList?.[index]?.mobile}
                                                            errorMessage={errors.getList?.[index]?.mobile}
                                                            maxLength={9}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={6}>
                                                        <TextInputField
                                                            labelId={'eaAccreditationForm.step1.getList.email'}
                                                            name={`getList[${index}].email`}
                                                            handleChange={handleChange}
                                                            value={values.getList[index].email}
                                                            isInvalid={errors.getList?.[index]?.email}
                                                            errorMessage={errors.getList?.[index]?.email}
                                                        />
                                                    </Col>
                                                    <Col md='6'>
                                                        <EnumSelectField
                                                            options={managerQualificationLevelOptions}
                                                            preffixDescriptionId='eaAccreditationForm.qualificationLevelCode.option'
                                                            nullOption={true}
                                                            value={get.qualificationLevelCode}
                                                            name={`getList[${index}].qualificationLevelCode`}
                                                            labelId={
                                                                'eaAccreditationForm.step4.accordion.header.academicQualification'
                                                            }
                                                            handleChange={handleChange}
                                                            isInvalid={
                                                                errors.getList?.[index]?.qualificationLevelCode
                                                            }
                                                            errorMessage={
                                                                errors.getList?.[index]?.qualificationLevelCode
                                                            }
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row className='mt-2'>
                                                    <Col>
                                                        <Form.Check
                                                            label={intl.formatMessage({
                                                                id: 'eaAccreditationForm.step4.accordion.header.managementExperience',
                                                            })}
                                                            checked={get.managementExperience === true}
                                                            onChange={() =>
                                                                checkBoxOnChangeHandler(
                                                                    index,
                                                                    'managementExperience', values, setFieldValue
                                                                )
                                                            }
                                                        />
                                                    </Col>
                                                </Row>
                                            </>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </Col>
                        </Row>
                    ))}


                    {values.tgpList.map((tgp, index) => (
                        <Row key={index}>
                            <Col>
                                <Accordion>
                                    <Accordion.Item eventKey='0'>
                                        <Accordion.Header>
									<span className='d-flex align-items-center'>
										{errors.tgpList?.[index] && <FaExclamationCircle/>}
                                        <FormattedMessage id='eaAccreditationForm.step4.accordion.function.tgp'/>{' '}
                                        {' - ' + (index + 1)}
									</span>
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <Row>
                                                <Col>
                                                    <TextInputField
                                                        name={`tgpList[${index}].name`}
                                                        labelId={
                                                            'eaAccreditationForm.step4.accordion.header.name'
                                                        }
                                                        handleChange={handleChange}
                                                        isInvalid={errors.tgpList?.[index]?.name}
                                                        errorMessage={errors.tgpList?.[index]?.name}
                                                        value={tgp.name}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md='6'>
                                                    <EnumSelectField
                                                        options={qualificationLevelOptions}
                                                        nullOption={true}
                                                        preffixDescriptionId='eaAccreditationForm.qualificationLevelCode.option'
                                                        value={tgp.qualificationLevelCode}
                                                        name={`tgpList[${index}].qualificationLevelCode`}
                                                        labelId={
                                                            'eaAccreditationForm.step4.accordion.header.academicQualification'
                                                        }
                                                        handleChange={handleChange}
                                                        isInvalid={
                                                            errors.tgpList?.[index]?.qualificationLevelCode
                                                        }
                                                        errorMessage={
                                                            errors.tgpList?.[index]?.qualificationLevelCode
                                                        }
                                                    />
                                                </Col>
                                                <Col md='3'>
                                                    <NumberInputField
                                                        value={tgp.hre.nif}
                                                        name={`tgpList[${index}].hre.nif`}
                                                        labelId={
                                                            'eaAccreditationForm.step4.accordion.header.nif'
                                                        }
                                                        handleChange={handleChange}
                                                        isInvalid={errors.tgpList?.[index]?.hre?.nif}
                                                        errorMessage={errors.tgpList?.[index]?.hre?.nif}
                                                        maxLength={9}
                                                    />
                                                </Col>
                                                <Col md='3'>
                                                    <EnumSelectField
                                                        preffixDescriptionId='eaAccreditationForm.step4.accordion.header.bondType'
                                                        options={['INTERN', 'EXTERN']}
                                                        nullOption={true}
                                                        value={tgp.hre.bondType}
                                                        name={`tgpList[${index}].hre.bondType`}
                                                        labelId={
                                                            'eaAccreditationForm.step4.accordion.header.bondType'
                                                        }
                                                        handleChange={handleChange}
                                                        isInvalid={errors.tgpList?.[index]?.hre?.bondType}
                                                        errorMessage={errors.tgpList?.[index]?.hre?.bondType}
                                                    />
                                                </Col>
                                            </Row>

                                            <Row className='mt-5'>
                                                <Col>
                                                    <small className='text-uppercase text-primary'>
                                                        <FormattedMessage
                                                            id='eaAccreditationForm.step4.accordion.header.experience'/>
                                                        <ToolTip
                                                            message={
                                                                <FormattedMessage
                                                                    id='eaAccreditationForm.step4.accordion.header.experience.tooltip'/>
                                                            }
                                                        />
                                                    </small>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md='4'>
                                                    <NumberInputField
                                                        maxLength={2}
                                                        className='w-25'
                                                        value={tgp.hre.projectCreationCount}
                                                        name={`tgpList[${index}].hre.projectCreationCount`}
                                                        labelId={
                                                            'eaAccreditationForm.step4.accordion.header.projectCreation'
                                                        }
                                                        handleChange={handleChange}
                                                        isInvalid={
                                                            errors.tgpList?.[index]?.hre?.projectCreationCount
                                                        }
                                                        errorMessage={
                                                            errors.tgpList?.[index]?.hre?.projectCreationCount
                                                        }
                                                    />
                                                </Col>
                                                <Col md='4'>
                                                    <NumberInputField
                                                        maxLength={2}
                                                        className='w-25'
                                                        value={tgp.hre.analysisCount}
                                                        name={`tgpList[${index}].hre.analysisCount`}
                                                        labelId={
                                                            'eaAccreditationForm.step4.accordion.header.candidatureReview'
                                                        }
                                                        handleChange={handleChange}
                                                        isInvalid={errors.tgpList?.[index]?.hre?.analysisCount}
                                                        errorMessage={
                                                            errors.tgpList?.[index]?.hre?.analysisCount
                                                        }
                                                    />
                                                </Col>
                                                <Col md='4'>
                                                    <NumberInputField
                                                        maxLength={2}
                                                        className='w-25'
                                                        value={tgp.hre.consultingCount}
                                                        name={`tgpList[${index}].hre.consultingCount`}
                                                        labelId={
                                                            'eaAccreditationForm.step4.accordion.header.consultancy'
                                                        }
                                                        handleChange={handleChange}
                                                        isInvalid={
                                                            errors.tgpList?.[index]?.hre?.consultingCount
                                                        }
                                                        errorMessage={
                                                            errors.tgpList?.[index]?.hre?.consultingCount
                                                        }
                                                    />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md='6'>
                                                    <NumberInputField
                                                        maxLength={2}
                                                        className='w-25'
                                                        value={tgp.hre.expandSkillsCount}
                                                        name={`tgpList[${index}].hre.expandSkillsCount`}
                                                        labelId={
                                                            'eaAccreditationForm.step4.accordion.header.skillsExtension'
                                                        }
                                                        handleChange={handleChange}
                                                        isInvalid={
                                                            errors.tgpList?.[index]?.hre?.expandSkillsCount
                                                        }
                                                        errorMessage={
                                                            errors.tgpList?.[index]?.hre?.expandSkillsCount
                                                        }
                                                    />
                                                </Col>
                                                <Col md='6'>
                                                    <NumberInputField
                                                        maxLength={2}
                                                        className='w-25'
                                                        value={tgp.hre.approvedProjectFollowUpCount}
                                                        name={`tgpList[${index}].hre.approvedProjectFollowUpCount`}
                                                        labelId={
                                                            'eaAccreditationForm.step4.accordion.header.followUp'
                                                        }
                                                        handleChange={handleChange}
                                                        isInvalid={
                                                            errors.tgpList?.[index]?.hre
                                                                ?.approvedProjectFollowUpCount
                                                        }
                                                        errorMessage={
                                                            errors.tgpList?.[index]?.hre
                                                                ?.approvedProjectFollowUpCount
                                                        }
                                                    />
                                                </Col>
                                            </Row>

                                            <Row className='mt-4'>
                                                <Col>
                                                    <button
                                                        className='btn btn-link px-0'
                                                        type='button'
                                                        onClick={() => removeTGP(index, values, setFieldValue)}
                                                    >
                                                        <FormattedMessage id='all.remove'/>
                                                    </button>
                                                </Col>
                                            </Row>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </Col>
                        </Row>
                    ))}

                    <Row className='mt-4'>
                        <Col>
                            <button
                                className='btn btn-outline-primary'
                                type='button'
                                onClick={() => addTGP(values, setFieldValue)}
                            >
                                <FormattedMessage id='eaAccreditationForm.step4.button.addTGP'/>
                            </button>
                        </Col>
                    </Row>


                    <div className='form-actions'>
                        <div className='mr-auto'>
                            <Link to={'/templates'} variant='secondary'>
                                <FormattedMessage id='all.backButtonText'/>
                            </Link>
                        </div>
                        <div className='ml-auto'>
                            <Button variant='primary' type='submit'>
                                <FormattedMessage id='all.submitButtonText'/>
                            </Button>
                        </div>
                    </div>
                </form>
            )}
        </Formik>
    );
}
