import {CandidatureFinancialUtils} from "./CandidatureFinancialUtils";
import {formatValue} from "react-currency-input-field";

export function currencyFormat(num) {
  return formatValue({value:num.toString(), groupSeparator:' ', decimalSeparator:'.', suffix:' €'});

}

export function maxFixed(num){
  if(num=== undefined){
    num='0'
  }
  let indexOf = num.toString().indexOf('.');
  if (indexOf!==-1 && num.toString().length-1-indexOf >2){
    return Math.round( num * 100 + Number.EPSILON ) / 100
  }
  return num;
}