import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { SingleUploadArea } from '../../../components/SingleUploadArea';
import { AlertError } from '../../../components/bootstrap/AlertError';
import Loading from '../../../components/general/Loading';
import { CandidatureState } from '../../../models/CandidatureState';
import { DocumentType } from '../../../models/DocumentType';
import {
	deleteCandidatureChangeRequestDocument,
	listCandidatureChangeRequestDocumentsByType,
	setToAcceptanceTerm,
	submitInitialDecision,
	uploadCandidatureChangeRequestDocument,
} from '../../../rest/candidatureChangeRequest';
import {Arrays} from "../../../utils/Arrays";

export function CandidatureChangeRequestValidationModal({
	candidatureChange,
	isApprove,
	handleClose,
}) {
	const intl = useIntl();

	const { candidatureExternalId, externalId } = useParams();
	const [loading, setLoading] = useState(true);

	const location = useLocation();

	const navigate = useNavigate();

	const [error, setError] = useState(null);

	const [publicDocument, setPublicDocument] = useState([]);
	const [privateDocument, setPrivateDocument] = useState([]);
	const [publicDocumentType, setPublicDocumentType] = useState(
		DocumentType.VALIDATION_DOCUMENT
	);
	const [privateDocumentType, setPrivateDocumentType] = useState(
		DocumentType.VALIDATION_DOCUMENT_PRIVATE
	);

	const [newAcceptanceTerm, setNewAcceptanceTerm] = useState(false);
	const [acceptanceTermDocument, setAcceptanceTermDocument] = useState([]);

	const isContestationAnalysisState =
		CandidatureState.CONTESTATION_ANALYSIS === candidatureChange.state;

	async function fetchData() {
		try {
			const publicType =
				isContestationAnalysisState || isApprove === true
					? DocumentType.FINAL_DECISION
					: DocumentType.VALIDATION_DOCUMENT;
			const privateType =
				isContestationAnalysisState || isApprove === true
					? DocumentType.FINAL_DECISION_PRIVATE
					: DocumentType.VALIDATION_DOCUMENT_PRIVATE;

			const [
				{ data: publicDocument },
				{ data: privateDocument },
				{ data: newAcceptanceTermDocument },
			] = await Promise.all([
				await listCandidatureChangeRequestDocumentsByType(
					publicType,
					candidatureExternalId,
					externalId
				),
				await listCandidatureChangeRequestDocumentsByType(
					privateType,
					candidatureExternalId,
					externalId
				),
				await listCandidatureChangeRequestDocumentsByType(
					DocumentType.CANDIDATURE_CHANGE_TERM_ACCEPT,
					candidatureExternalId,
					externalId
				),
			]);
			setPublicDocumentType(publicType);
			setPrivateDocumentType(privateType);

			loadDocument(publicDocument, setPublicDocument, publicType);
			loadDocument(privateDocument, setPrivateDocument, privateType);
			loadDocument(
				newAcceptanceTermDocument,
				setAcceptanceTermDocument,
				DocumentType.CANDIDATURE_CHANGE_TERM_ACCEPT
			);
			setNewAcceptanceTerm(Arrays.isNotEmpty(newAcceptanceTermDocument) );
		} catch (error) {
			setError(error);
		} finally {
			setLoading(false);
		}
	}

	function loadDocument(data, setDocument, documentType) {
		if (data.length !== 0) {
			const document = data[0];
			setDocument([
				{
					documentType: document.documentType,
					externalId: document.externalId,
					name: document.name,
					documentPath: document.documentPath,
					content: '',
					submited: true,
					error: '',
				},
			]);
		} else {
			setDocument([
				{
					documentType: documentType,
					externalId: '',
					name: '',
					content: null,
					submited: false,
					error: '',
				},
			]);
		}
	}

	function canSubmit() {
		if (newAcceptanceTerm && !acceptanceTermDocument[0]?.submited) {
			return false;
		}

		return publicDocument[0]?.submited && privateDocument[0]?.submited;
	}

	async function handleConfirm() {
		try {
			if (isApprove && newAcceptanceTerm) {
				await setToAcceptanceTerm(candidatureExternalId, externalId);
			} else {
				let flag = {
					flag: isApprove,
				};

				await submitInitialDecision(candidatureExternalId, externalId, flag);
			}

			sessionStorage.setItem(
				'candidatureChangeRequestDecisionSubmited',
				true
			)

			//refresh page
			navigate(0);
		} catch (error) {
			setError(error);
		}
	}

	useEffect(() => {
		fetchData();
	}, []);

	const uploadCallback = async (formData) => {
		return await uploadCandidatureChangeRequestDocument(
			candidatureExternalId,
			externalId,
			formData
		);
	};

	const deleteCallback = async (fileExternalId) => {
		return await deleteCandidatureChangeRequestDocument(
			candidatureExternalId,
			externalId,
			fileExternalId
		);
	};

	return (
		<Modal show={true} dialogClassName='modal-65' onHide={handleClose}>
			<Modal.Header closeButton>
				<FormattedMessage
					id={`candidatureChangeRequestValidation.title.${
						isApprove ? 'approve' : 'reject'
					}`}
				/>
			</Modal.Header>
			<Modal.Body>
				{loading ? (
					<span>
						<Loading isDialog/>
					</span>
				) : (
					<>
						<div className='my-4'>
							<FormattedMessage
								id={`candidatureChangeRequestValidation.message.${
									isApprove ? 'approve' : 'reject'
								}`}
							/>
						</div>
						{isApprove && (
							<Row className='mb-4'>
								<Col>
									<Form.Check
										type='checkbox'
										defaultChecked={newAcceptanceTerm}
										onChange={(e) => {
											setNewAcceptanceTerm(e.target.checked);
										}}
										label={
											<FormattedMessage id='candidatureChangeRequestValidation.newAcceptanceTerm' />
										}
										disabled={acceptanceTermDocument[0].submited}
									/>
								</Col>
							</Row>
						)}
						<Row className='px-2'>
							<Col>
								<small className='text-primary text-uppercase'>
									<FormattedMessage id='candidatureValidation.decisionDocuments' />
								</small>
								<SingleUploadArea
									documents={publicDocument}
									setDocuments={setPublicDocument}
									documentType={publicDocumentType}
									setError={setError}
									processUploadCallback={uploadCallback}
									processDeleteCallback={deleteCallback}
								/>
								<SingleUploadArea
									documents={privateDocument}
									setDocuments={setPrivateDocument}
									documentType={privateDocumentType}
									setError={setError}
									processUploadCallback={uploadCallback}
									processDeleteCallback={deleteCallback}
								/>
								{newAcceptanceTerm && (
									<SingleUploadArea
										documents={acceptanceTermDocument}
										setDocuments={setAcceptanceTermDocument}
										documentType={DocumentType.CANDIDATURE_CHANGE_TERM_ACCEPT}
										setError={setError}
										processUploadCallback={uploadCallback}
										processDeleteCallback={deleteCallback}
									/>
								)}
							</Col>
							<AlertError error={error} />
						</Row>
					</>
				)}
			</Modal.Body>
			<Modal.Footer className='d-flex justify-content-between'>
				<Button variant='outline-secondary' onClick={handleClose}>
					<FormattedMessage id='all.cancel' />
				</Button>
				<Button
					variant='primary'
					onClick={handleConfirm}
					disabled={!canSubmit()}
				>
					<FormattedMessage id='all.submitButtonText' />
				</Button>
			</Modal.Footer>
		</Modal>
	);
}
