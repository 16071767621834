import {Formik} from "formik";
import {Col, Form, Row} from "react-bootstrap";
import {DateTimeInputField} from "../bootstrap/DateTimeInputField";
import {FormattedMessage} from "react-intl";
import React, {useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import * as yup from "yup";
import {restitutionNonCompliance} from "../../rest/nonCompliance";
import {AlertError} from "../bootstrap/AlertError";
import {restitutionAccreditationRevocation} from "../../rest/accreditationRevocation";


export function AccreditationRevocationRestitutionForm({accreditationRevocation, onCancel, onSubmitCallback}) {
    const [error, setError] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const {nif, externalId} = useParams();

    const navigate = useNavigate();


    async function onSubmit(values, setSubmitting, setErrors) {
        console.log(values)
        setSubmitting(true);
        try {
            await restitutionAccreditationRevocation(nif, accreditationRevocation.externalId, values);
            onSubmitCallback?.()
            setSubmitted(true);
            sessionStorage.setItem('accreditationRevocationRestitution',true);
        } catch (err) {
            console.log(err)
            if (err.response.data.exception === 'ValidationException') {
                let errors = err.response.data.message
                    .split(',')
                    .map((value) => Number.parseInt(value));
                setErrors(errors);
            }
            ;
            setErrors(err);
        }
        setSubmitting(false);
    }


    const validationSchema = yup.object().shape({
        restitutionDone: yup.boolean().nullable(),
        restitutionDate: yup
            .mixed()
            .when('restitutionDone', {
                is: (s) => (s === true),
                then: yup.date()
                    .required(<FormattedMessage id='errors.fieldRequiredText'/>).max(new Date(), <FormattedMessage
                        id='error.identification.dateBeforeNow'/>)
                    .transform((v) => (v instanceof Date && !isNaN(v) ? v : undefined)),
            }),
        coerciveDone: yup.boolean().nullable(),
        dateSentToCoerciveCollection: yup
            .mixed()
            .when('coerciveDone', {
                is: (s) => (s === true),
                then: yup
                    .date()
                    .required(<FormattedMessage id='errors.fieldRequiredText'/>).max(new Date(), <FormattedMessage
                        id='error.identification.dateBeforeNow'/>)
                    .transform((v) => (v instanceof Date && !isNaN(v) ? v : undefined)),
            }),
    });

    const initialValues = {
        reimbursementPlan: accreditationRevocation.restitutionRecord === undefined ? null : accreditationRevocation.restitutionRecord.reimbursementPlan,
        hasReimbursementPlan: accreditationRevocation.restitutionRecord === undefined ? false : accreditationRevocation.restitutionRecord.hasReimbursementPlan,
        restitutionDone: accreditationRevocation.restitutionRecord === undefined ? null : accreditationRevocation.restitutionRecord.restitutionDone,
        restitutionDate: accreditationRevocation.restitutionRecord === undefined ? null : accreditationRevocation.restitutionRecord.restitutionDate,
        hasRestitutionDate: accreditationRevocation.restitutionRecord === undefined ? false : accreditationRevocation.restitutionRecord.hasRestitutionDate,
        coerciveDone: accreditationRevocation.restitutionRecord === undefined ? null : accreditationRevocation.restitutionRecord.coerciveDone,
        dateSentToCoerciveCollection: accreditationRevocation.restitutionRecord === undefined ? null : accreditationRevocation.restitutionRecord.dateSentToCoerciveCollection,
        hasDateSentToCoerciveCollection: accreditationRevocation.restitutionRecord === undefined ? false : accreditationRevocation.restitutionRecord.hasDateSentToCoerciveCollection
    };


    function changeRestitution(value, setFieldValue) {

        setFieldValue('restitutionDone', value);
        if (value !== true) {
            setFieldValue('restitutionDate', null)
        }

    }


    function changeCoercive(value, setFieldValue) {

        setFieldValue('coerciveDone', value);
        if (value !== true) {
            setFieldValue('dateSentToCoerciveCollection', null)
        }

    }


    return (
        <>
            <AlertError error={error}/>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                validateOnBlur={false}
                validateOnChange={false}
                onSubmit={(values, {setSubmitting, setErrors}) => {
                    onSubmit(values, setSubmitting, setErrors);

                }}>
                {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleSubmit,
                      setFieldValue
                  }) => (
                    <Form onSubmit={handleSubmit}>
                        <AlertError error={errors}/>
                        <Row>
                            <Col>
                                <label>
                                    <FormattedMessage id='nonCompliance.reimbursementPlan'/>
                                </label>
                                <Form.Check
                                    inline
                                    name={'reimbursementPlan'}
                                    type={'radio'}
                                    label={<FormattedMessage id='all.yes'/>}
                                    checked={values.reimbursementPlan === true}
                                    onChange={({target}) => setFieldValue('reimbursementPlan', true)}
                                    disabled={values.hasReimbursementPlan === true}
                                />
                                <Form.Check
                                    inline
                                    name={'reimbursementPlan'}
                                    type={'radio'}
                                    label={<FormattedMessage id='all.no'/>}
                                    checked={values.reimbursementPlan === false}
                                    onChange={({target}) => setFieldValue('reimbursementPlan', false)}
                                    disabled={values.hasReimbursementPlan === true}
                                />
                                <Form.Control.Feedback type='invalid'>
                                    {errors.reimbursementPlan}
                                </Form.Control.Feedback>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>

                                <Form.Group>
                                    <Form.Label className='col-sm-8'><FormattedMessage
                                        id='nonCompliance.restitutionDone'/>
                                    </Form.Label>

                                    <Form.Check
                                        inline
                                        name={'restitutionDone'}
                                        type={'radio'}
                                        label={<FormattedMessage id='all.yes'/>}
                                        checked={values.restitutionDone === true}
                                        onChange={({target}) => changeRestitution(true, setFieldValue)}
                                        disabled={values.hasRestitutionDate === true}
                                    />
                                    <Form.Check
                                        inline
                                        name={'restitutionDone'}
                                        type={'radio'}
                                        label={<FormattedMessage id='all.no'/>}
                                        checked={values.restitutionDone === false}
                                        onChange={({target}) => changeRestitution(false, setFieldValue)}
                                        disabled={values.hasRestitutionDate === true}
                                    />
                                    <Form.Control.Feedback type='invalid'>
                                        {errors.restitutionDone}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col md='6'>
                                <DateTimeInputField
                                    name={'restitutionDate'}
                                    labelId='nonCompliance.restitutionDate'
                                    value={values.restitutionDate}
                                    setFieldValue={setFieldValue}
                                    isDateWithNoTime={true}
                                    isInvalid={errors.restitutionDate}
                                    errorMessage={errors.restitutionDate}
                                    disabled={values.hasRestitutionDate === true || values.restitutionDone !== true}
                                />
                            </Col>
                        </Row>

                        <Row>
                            <Col md={6}>

                                <Form.Group>
                                    <Form.Label className='col-sm-8'><FormattedMessage id='nonCompliance.coerciveDone'/>
                                    </Form.Label>

                                    <Form.Check
                                        inline
                                        name={'coerciveDone'}
                                        type={'radio'}
                                        label={<FormattedMessage id='all.yes'/>}
                                        checked={values.coerciveDone === true}
                                        onChange={({target}) => changeCoercive(true, setFieldValue)}
                                        disabled={values.hasDateSentToCoerciveCollection === true}
                                    />
                                    <Form.Check
                                        inline
                                        name={'coerciveDone'}
                                        type={'radio'}
                                        label={<FormattedMessage id='all.no'/>}
                                        checked={values.coerciveDone === false}
                                        onChange={({target}) => changeCoercive(false, setFieldValue)}
                                        disabled={values.hasDateSentToCoerciveCollection === true}
                                    />
                                    <Form.Control.Feedback type='invalid'>
                                        {errors.coerciveDone}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col md='6'>
                                <DateTimeInputField
                                    labelId='nonCompliance.dateSentToCoerciveCollection'
                                    name='dateSentToCoerciveCollection'
                                    value={values.dateSentToCoerciveCollection}
                                    setFieldValue={setFieldValue}
                                    isDateWithNoTime={true}
                                    isInvalid={errors.dateSentToCoerciveCollection}
                                    errorMessage={errors.dateSentToCoerciveCollection}
                                    disabled={values.hasDateSentToCoerciveCollection === true || values.coerciveDone !== true}
                                />
                            </Col>
                        </Row>


                        <Row className='form-actions'>
                            <Col className='justify-content-between d-flex'>
                                <button className='btn btn-outline-primary' type='button' onClick={() => onCancel?.()}>
                                    <FormattedMessage id='all.cancel'/>
                                </button>
                                <button className='btn btn-primary' type='submit'>
                                    <FormattedMessage id='all.submitButtonText'/>
                                </button>
                            </Col>
                        </Row>
                    </Form>
                )}
            </Formik>
        </>
    );
}