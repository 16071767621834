import React, { useState } from 'react';
import { TextInputField } from '../../bootstrap/TextInputField';
import { Row, Col, Form, Accordion } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { EnumSelectField } from '../../bootstrap/EnumSelectField';
import {
	getCountiesByDistrictCode,
	getParishesByCountyCode,
} from '../../../rest/administrativeDivision';
import { FaExclamationCircle } from 'react-icons/fa';
import { useEffect } from 'react';
import Loading from '../../../components/general/Loading';

export function EaAccreditationFormStep5({
	formStep,
	numberOfSteps,
	errors,
	formikValues,
	handleChange,
	handlePreviousStep,
	handleNextStep,
	setFieldValue,
	districts,
	setError,
	readMode = false
}) {
	const intl = useIntl();

	const districtOptions = districts.map(d => d.description)

	const [loadedDistrictCounties, setLoadedDistrictCounties] = useState({});
	const [loadedCountyParishes, setLoadedCountyParishes] = useState({});

	const [locationCurrentChosenOptions, setLocationCurrentChosenOptions] = useState({});
	
	const [loading, setLoading] = useState(true);

	useEffect( () =>{
		setDistrictsAndCountiesFromParishCodes();
	}, [])
	
	async function setDistrictsAndCountiesFromParishCodes() {
		try {
			let districtCode;
			let countyCode;

			let newLoadedDistrictCounties = {}
			let newLoadedCountyParishes = {}
			let newLocationCurrentChosenOptions = {}

			for(let index in formikValues.incubatorLocations){
				let loc = formikValues.incubatorLocations[index]
				if(loc.parishCode !== "-1"){

					districtCode = loc.parishCode.substring(0, 2);
					const { data: countyList } = await getCountiesByDistrictCode(districtCode);
					newLoadedDistrictCounties[districtCode] = countyList;
		
					countyCode = loc.parishCode.substring(0, 4);
					const { data: parishList } = await getParishesByCountyCode(countyCode);
					newLoadedCountyParishes[countyCode] = parishList;


					newLocationCurrentChosenOptions[index] = {districtCode: districtCode, countyCode: countyCode}
				}
				else{
					newLocationCurrentChosenOptions[index] = {districtCode: '-1', countyCode: '-1'}
				}
			}

			setLocationCurrentChosenOptions(newLocationCurrentChosenOptions)
			setLoadedDistrictCounties(newLoadedDistrictCounties);
			setLoadedCountyParishes(newLoadedCountyParishes);

		} catch (error) {
			setError(error);
		}finally {
      setLoading(false);
    }
	}

	async function handleDistrictChange(districtName, locationIndex) {
		try {
			if (districtName !== '-1') {
				let districtCode = districts.filter((district) => district.description === districtName)[0].code;
				
				const { data: counties } = await getCountiesByDistrictCode(districtCode);
				
				locationCurrentChosenOptions[locationIndex].districtCode = districtCode

				if(!loadedDistrictCounties.hasOwnProperty(districtCode)){
					setLoadedDistrictCounties({...loadedDistrictCounties, [districtCode]: counties})
				}
			}
			else{
				locationCurrentChosenOptions[locationIndex].districtCode = '-1';
			}
			locationCurrentChosenOptions[locationIndex].countyCode = '-1'; 

			setLocationCurrentChosenOptions({...locationCurrentChosenOptions})
			formikValues.incubatorLocations[locationIndex].parishCode = '-1';
			setFieldValue({ ...formikValues });
		} catch (error) {
			setError(error);
		}
	}

	async function handleCountyChange(countyName, locationIndex) {
		try {

			if (countyName !== '-1') {
				let countyCode = loadedDistrictCounties[locationCurrentChosenOptions[locationIndex].districtCode].filter((county) => county.description === countyName)[0].code
				
				const { data: parishes } = await getParishesByCountyCode(countyCode);

				locationCurrentChosenOptions[locationIndex].countyCode = countyCode

				if(!loadedCountyParishes.hasOwnProperty(countyCode)){
					setLoadedCountyParishes({...loadedCountyParishes, [countyCode]: parishes})
				}
			}
			else{
				locationCurrentChosenOptions[locationIndex].countyCode = '-1';
			} 

			setLocationCurrentChosenOptions({...locationCurrentChosenOptions})
			formikValues.incubatorLocations[locationIndex].parishCode = '-1';
			setFieldValue({ ...formikValues });
		} catch (error) {
			setError(error);
		}
	}

	function handleParishChange(parishName, locationIndex) {
		if (parishName !== '-1') {
			formikValues.incubatorLocations[locationIndex].parishCode = loadedCountyParishes[locationCurrentChosenOptions[locationIndex].countyCode].filter((parish) => parish.description === parishName)[0].code;
		} else {
			formikValues.incubatorLocations[locationIndex].parishCode = '-1';
		}
		setFieldValue({ ...formikValues });
	}

	function addLocationHandler() {
		let newLocation = {
			parishCode: '-1',
			extraLocation: '',
		};
		
		locationCurrentChosenOptions[formikValues.incubatorLocations.length] = {districtCode: '-1', countyCode: '-1'};
		setLocationCurrentChosenOptions({...locationCurrentChosenOptions})

		formikValues.incubatorLocations.push(newLocation);
		setFieldValue({ ...formikValues });
	}

	function removeLocationHandler(index) {
		let lengthBeforeRemoval = formikValues.incubatorLocations.length
		delete locationCurrentChosenOptions[index];
		if(lengthBeforeRemoval > 1){
			for (let i = index+1; i < lengthBeforeRemoval; i++){
				locationCurrentChosenOptions[i-1] = locationCurrentChosenOptions[i];
			}
			delete locationCurrentChosenOptions[lengthBeforeRemoval - 1]
		}
		setLocationCurrentChosenOptions({...locationCurrentChosenOptions})

		formikValues.incubatorLocations.splice(index, 1);
		setFieldValue({ ...formikValues });
	}

	if (loading) {
    return <Loading/>;
  }

	return (
		<div>
			<h4 className='text-primary mb-0'>
				<FormattedMessage id='eaAccreditationForm.step5.title' />
			</h4>
			<small className='text-secondary text-uppercase'>
				{intl.formatMessage(
					{ id: 'all.step' },
					{ step: formStep, numberOfSteps: numberOfSteps }
				)}
			</small>
			<Row>
				<Col>
					<Form.Label>
						<FormattedMessage id='eaAccreditationForm.step5.incubatorLocations' />
					</Form.Label>
				</Col>
			</Row>
			{formikValues.incubatorLocations.map((location, index) => (
				<Accordion
					alwaysOpen
					key={index}
				>
					<Accordion.Item eventKey={index}>
						<Accordion.Header>
							<span className='d-flex align-items-center'>
								{errors.incubatorLocations?.[index] && <FaExclamationCircle />}
								<FormattedMessage id='eaAccreditationForm.step5.accordion.title' />{' '}
								{index + 1}
							</span>
						</Accordion.Header>
						<Accordion.Body>
							<Row>
								<Col md='4'>
									<EnumSelectField
										nullOption={true}
										labelId='all.chooseOneDistrict'
										options={districtOptions}
										handleChange={(e) =>
											handleDistrictChange(e.target.value, index)
										}
										value={locationCurrentChosenOptions[index].districtCode !== '-1' ? 
										districts.filter(d => d.code === locationCurrentChosenOptions[index].districtCode)[0].description : '-1'}
										disabled={readMode}
									/>
								</Col>
								<Col md='4'>
									<EnumSelectField
										disabled={readMode || locationCurrentChosenOptions[index].districtCode === '-1'}
										nullOption={true}
										options={locationCurrentChosenOptions[index].districtCode !== '-1' ? 
										loadedDistrictCounties[locationCurrentChosenOptions[index].districtCode].map((countyObj) => countyObj.description) : []}
										handleChange={(e) =>
											handleCountyChange(e.target.value, index)
										}
										labelId='all.chooseOneCounty'
										value={locationCurrentChosenOptions[index].countyCode !== '-1' ?
										loadedDistrictCounties[locationCurrentChosenOptions[index].districtCode].filter( countyObj =>
											locationCurrentChosenOptions[index].countyCode === countyObj.code)[0].description : '-1'}
									/>
								</Col>
								<Col md='4'>
									<EnumSelectField
										disabled={readMode || locationCurrentChosenOptions[index].countyCode === '-1'}
										nullOption={true}
										labelId='all.chooseOneParish'
										handleChange={(e) =>
											handleParishChange(e.target.value, index)
										}
										options={locationCurrentChosenOptions[index].countyCode !== '-1' ? 
										loadedCountyParishes[locationCurrentChosenOptions[index].countyCode].map((parishObj) => parishObj.description) : []}
										value={
											location.parishCode !== '-1'
												? loadedCountyParishes[locationCurrentChosenOptions[index].countyCode].filter(
														(parish) => parish.code === location.parishCode
												  )[0].description
												: '-1'
										}
										isInvalid={errors.incubatorLocations?.[index]?.parishCode}
										errorMessage={
											errors.incubatorLocations?.[index]?.parishCode
										}
									/>
								</Col>
							</Row>
							<Row>
								<Col>
									<TextInputField
										value={location.extraLocation}
										name={`incubatorLocations[${index}].extraLocation`}
										labelId={'all.address'}
										handleChange={handleChange}
										isInvalid={
											errors.incubatorLocations?.[index]?.extraLocation
										}
										errorMessage={
											errors.incubatorLocations?.[index]?.extraLocation
										}
										disabled={readMode}
									/>
								</Col>
							</Row>
							{!readMode && (
							<Row className='mt-4'>
								<Col>
									<button
										className='px-0 btn btn-link'
										type='button'
										onClick={() => removeLocationHandler(index)}
									>
										<FormattedMessage id='all.remove' />
									</button>
								</Col>
							</Row>
							)}
						</Accordion.Body>
					</Accordion.Item>
				</Accordion>
			))}
			{!readMode && (
			<Row className='mt-4'>
				<Col>
					<button
						className='btn btn-outline-primary'
						type='button'
						onClick={addLocationHandler}
					>
						<FormattedMessage id='eaAccreditationForm.step5.button.addIncubatorLocation' />
					</button>
				</Col>
			</Row>
			)}

			<div className='form-actions mt-5'>
				<button
					className='btn btn-link'
					type='button'
					onClick={handlePreviousStep}
				>
					<FormattedMessage id='all.back' />
				</button>

				<button
					className='btn btn-primary'
					type='button'
					onClick={handleNextStep}
				>
					<FormattedMessage id='all.nextStep' />
				</button>
			</div>
		</div>
	);
}
