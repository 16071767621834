import {Button} from "react-bootstrap";
import {FormattedMessage} from "react-intl";
import React, {useEffect, useState} from "react";
import {getParentProcessId} from "../../rest/payableProcess";
import {useNavigate} from "react-router-dom";

export function PayableProcessButton({payableExternalId, processExternalId}) {
    const [loading, setLoading] = useState(true);
    const [actualProcessId, setActualProcessId] = useState();
    const navigate = useNavigate();

    const needToGetProcess = processExternalId === undefined && payableExternalId !== undefined;

    async function fetchData() {
        if(needToGetProcess) {
            try {
                setLoading(true);
                const [{data: procId}] = await Promise.all([
                    getParentProcessId(payableExternalId)
                ]);
                setActualProcessId(procId);
            } catch (error) {
                console.log(error);
            } finally {
                setLoading(false);
            }
        } else {
            setActualProcessId(processExternalId);
            setLoading(false);
        }
    }

    useEffect(() => {
        fetchData();
    }, []);


    if(loading) {
        return (<></>);
    }

    return (
        <>
            <Button variant='outline-primary'
                    onClick={async () =>  navigate(`/processos/${actualProcessId}/pagamentos`)}
            >
                <FormattedMessage id='candidature.button.payments' />
            </Button>
        </>
    )
}