import React from 'react';
import { FaInfoCircle } from 'react-icons/fa';
import { FormattedMessage } from 'react-intl';

export function EmptyResults({message}) {
  return (
    <div className='empty-state my-4'>
      <FaInfoCircle />
      <p>
        <FormattedMessage id={message? message :'all.noData'} />
      </p>
    </div>
  );
}
