import { CandidatureChangeStateWithReason } from "../CandidatureChangeStateWithReason";
import { useParams } from "react-router-dom";
import { sendToDoubtsClarificationForValidation } from "../../../rest/candidature";
import {CandidatureState} from "../../../models/CandidatureState";

export function CandidatureFlowSendToClarificationDoubtsForValidation({ callbackHandler, candidatureState }) {
  const { externalId } = useParams();

  const sendCandidatureToPromoterForDoubtsClarificationHandler = (reasonData) => {
    callbackHandler(() => {
        return sendToDoubtsClarificationForValidation(externalId, reasonData);
    });
  }


  return (
    <>
      <CandidatureChangeStateWithReason
        modalTitleId="candidature.title.sendToPromoterForDoubtsClarification"
        modalReasonTextId="candidature.message.sendToPromoterForDoubtsClarification"
        flowActionId=  {(CandidatureState.VALIDATE_REQUIRED_DOCUMENTS === candidatureState) ? "candidature.title.sendToPromoterForDoubtsClarification2":"candidature.title.sendToPromoterForDoubtsClarification"}
        submitHandler={sendCandidatureToPromoterForDoubtsClarificationHandler}
      />
    </>
  );
}
