import { apiAxios as authAxios } from '../axios.config';

export const listEaEvaluations = () => {
  return authAxios.get('/api/evaluation/list/')
}

export const getEvaluationByExternalId = (b8ExternalId) => {
  return authAxios.get(`/api/evaluation/${b8ExternalId}`)
}

export const getEvaluationByProcessExternalId = (b8ProcessExternalId) => {
  return authAxios.get(`/api/evaluation/process/${b8ProcessExternalId}`)
}

export const saveEvaluation = (b8, queryParams) => {
  return authAxios.post('/api/evaluation/', b8, queryParams ? {params: queryParams}: null)
}

export const getPromoterHasPendingEvaluationsForCandidature = (candidatureExternalId) => {
  return authAxios.get(`/api/evaluation/candidature/${candidatureExternalId}/hasPending`)
}
