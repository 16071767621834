import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { MultipleUploadArea } from '../MultipleUploadArea';
import { deleteB18Document, uploadB18Document } from '../../rest/additionalActivitiesPlanPayment';

export function DocumentsAttachment({
	formStep,
	numberOfSteps,
	formikValues,
	handlePreviousStep,
	handleNextStep,
	showNextStepButton,
	readMode,
	documents,
	setDocuments
}) {
	const intl = useIntl();

	const uploadCallback = async (formData) => {
		return await uploadB18Document(formikValues.externalId, formData);
	};

	const deleteCallback = async (documentExternalId) => {
		return await deleteB18Document(formikValues.externalId, documentExternalId);
	};

	return (
		<>
			<h4 className='text-primary mb-0'>
				<FormattedMessage id='complementaryActivitiesPaymentRequest.documentsAttachment.title' />
			</h4>

			<small className='text-secondary text-uppercase'>
				{intl.formatMessage(
					{ id: 'all.step' },
					{ step: formStep, numberOfSteps: numberOfSteps }
				)}
			</small>

			<fieldset disabled={readMode==true}>
				<Row>
					<Col>
						<MultipleUploadArea
							documents={documents}
							setDocuments={setDocuments}
							documentType='B18_ANNEX'
							uploadCallback={uploadCallback}
							deleteCallback={deleteCallback}
							readMode={readMode}
						/>
					</Col>
				</Row>
			</fieldset>

			{/* Voltar */}
			<div className='form-actions mt-5'>
				<button
					className='btn btn-link'
					type='button'
					onClick={handlePreviousStep}
				>
					<FormattedMessage id='all.back' />
				</button>
				{showNextStepButton && (
					<button
						className='btn btn-primary'
						type='button'
						onClick={handleNextStep}
					>
						<FormattedMessage id='all.nextStep' />
					</button>
				)}
			</div>
		</>
	);
}
