import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { cleanAccessInfo } from '../authentication/authenticationHelper';

function Login(props) {
  cleanAccessInfo();

  let location = useLocation();

  let { from } = location.state || { from: '/' };

  const path = from ? `/login/keycloak/?r=${from}` : `/login/keycloak`;
  return (
    <Navigate
      to={{
        pathname: path,
      }}
    />
  );
}

export default Login;
