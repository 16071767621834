import dateFormat from 'date-fns/format';
import React from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import DateTimePicker from 'react-datetime-picker';
import { FormattedMessage, useIntl } from 'react-intl';
import { ConfigObject } from '../../config';
import DecimalInputField from '../bootstrap/DecimalInputField';
import { TextInputField } from '../bootstrap/TextInputField';
import { FaInfoCircle } from 'react-icons/fa';

export function CandidatureFormStep7Test({
  errors,
  setFieldValue,
  values,
  handleSave,
  handleGoBack,
  numberOfSteps
}) {
  const intl = useIntl();

  function handleOtherIncentiveChange(e, index, fieldName) {
    values.otherIncentives[index][fieldName] = e.target.value;
    setFieldValue('otherIncentives', [...values.otherIncentives]);
  }

  function handleDispatchDateChange(e, otherIncentive) {
    otherIncentive.dispatchDate = dateFormat(e, ConfigObject.get().DATE_PATTERN);
    setFieldValue('contactedPromoterDetails', [...values.otherIncentives]);
  }

  function addOtherIncentive() {
    values.otherIncentives.push({
      externalId: '',
      supportOrganization: '',
      organism: '',
      dispatchDate: '',
      amount: 0
    });

    setFieldValue('otherIncentives', [...values.otherIncentives]);
  }

  function removeOtherIncentive(otherIncentive) {
    values.otherIncentives = values.otherIncentives.filter((oi) => oi !== otherIncentive);
    setFieldValue('otherIncentives', [...values.otherIncentives]);
  }

  function handleNextStep() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    handleSave(true);
  }

  return (
    <div>
      <h4 className='text-primary mb-0'>
        <FormattedMessage id='candidatureForm.otherIncentives.title' />
      </h4>
      <small className='text-secondary text-uppercase d-block'>
        {intl.formatMessage(
          { id: 'all.step' },
          { step: values.step, numberOfSteps: numberOfSteps }
        )}
      </small>

      <p className='mt-3 mb-0 text-secondary infoText'>
        <FaInfoCircle size={25} /> <FormattedMessage id='step5.infoText' />
      </p>

      {values.otherIncentives.map((otherIncentive, index) => (
        <div key={index} className='border-bottom pb-4 mx-1 mb-3'>
          <Row>
            <Col>
              <p className='mt-4 mb-0 text-uppercase'>Incentivo {index + 1} </p>
            </Col>
            <Col className='d-flex justify-content-end'>
              <button
                className='mt-4 py-0 btn btn-link'
                type='button'
                onClick={() => removeOtherIncentive(otherIncentive)}>
                <FormattedMessage id='all.remove' />
              </button>
            </Col>
          </Row>
          <Row>
            <Col md='6'>
              <TextInputField
                controlId={'supportOrganization'}
                labelId={'candidatureForm.otherIncentive.supportOrganization'}
                name={'supportOrganization'}
                handleChange={(e) => handleOtherIncentiveChange(e, index, 'supportOrganization')}
                value={otherIncentive.supportOrganization}
                isInvalid={
                  errors.otherIncentives
                    ? errors.otherIncentives[index]?.supportOrganization
                    : false
                }
                errorMessage={
                  errors.otherIncentives ? errors.otherIncentives[index]?.supportOrganization : ''
                }
              />
            </Col>
            <Col md='6'>
              <TextInputField
                controlId={'organism'}
                labelId={'candidatureForm.otherIncentive.organism'}
                name={'organism'}
                handleChange={(e) => handleOtherIncentiveChange(e, index, 'organism')}
                value={otherIncentive.organism}
                isInvalid={errors.otherIncentives ? errors.otherIncentives[index]?.organism : false}
                errorMessage={errors.otherIncentives ? errors.otherIncentives[index]?.organism : ''}
              />
            </Col>
            <Col md='6'>
              <Form.Group controlId={'dispatchDate'}>
                <Form.Label>
                  <FormattedMessage id='candidatureForm.otherIncentive.dispatchDate' />
                </Form.Label>
                <Form.Control
                  className='datetimepicker'
                  as={DateTimePicker}
                  format={ConfigObject.get().DATE_PATTERN}
                  clearIcon={null}
                  value={otherIncentive.dispatchDate ? new Date(otherIncentive.dispatchDate) : ''}
                  onChange={(e) => handleDispatchDateChange(e, otherIncentive)}
                  isInvalid={
                    errors.otherIncentives ? errors.otherIncentives[index]?.dispatchDate : false
                  }
                />
                <Form.Control.Feedback type='invalid'>
                  {errors.otherIncentives ? errors.otherIncentives[index]?.organism : ''}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col md='3'>
              <DecimalInputField
                controlId={'amount'}
                labelId={'candidatureForm.otherIncentive.amount'}
                name={'amount'}
                handleChange={(e) => handleOtherIncentiveChange(e, index, 'amount')}
                value={otherIncentive.amount}
                placeHolder={0}
                suffixLabel='€'
              />
            </Col>
          </Row>
        </div>
      ))}

      <button
        className='mt-4 btn btn-outline-primary'
        type='button'
        onClick={() => addOtherIncentive()}>
        <FormattedMessage id='all.plusSymbol' />
        <FormattedMessage id='candidatureForm.addOtherIncentive' />
      </button>

      <div className='form-actions mt-5'>
        <button
          className='btn btn-link'
          type='button'
          onClick={() => handleGoBack(values, setFieldValue)}>
          <FormattedMessage id='all.back' />
        </button>

        <button className='btn btn-primary' type='button' onClick={() => handleNextStep()}>
          <FormattedMessage id='all.nextStep' />
        </button>
      </div>
    </div>
  );
}
