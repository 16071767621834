import {Col, Container, Row} from "react-bootstrap";
import {FormattedMessage} from "react-intl";
import {AuthenticatedDownloadLink} from "../../../document/AuthenticatedDownloadLink";
import React from "react";
import {DocumentType} from "../../../../models/DocumentType";

export function EADocsTab({documents}) {
    const fileTypes = [
        DocumentType.EAA_SERVICE_INFO,
        DocumentType.EAA_IEFP_CONFIRMATION,
        DocumentType.EAA_DIRECTIVE_COUNCIL_DELIBERATION,
        DocumentType.EAA_DECISION_NOTIFICATION,
        DocumentType.COOPERATION_PROTOCOL
    ];


    return (
        <Container>
            {fileTypes.map(ft => documents.filter( d => d.documentType === ft).filter(d=> d.content!== null).map(d => (
            <Row key={d.externalId}>
                <Col className={'mb-4'}>
                    <label><FormattedMessage id={`documentType.${d.documentType}`}/></label>
                    <AuthenticatedDownloadLink url={d.documentPath} filename={d.name}>
                        <small>{d.name}</small>
                    </AuthenticatedDownloadLink>
                </Col>
            </Row>
            )))}
        </Container>
    );
}