import React, { useEffect, useState } from 'react';
import { Col, Container, Modal, Row } from 'react-bootstrap';
import { handleError, isNotBusinessError } from '../../../utils/handleError';
import { AlertError } from '../../../components/bootstrap/AlertError';
import { Arrays } from '../../../utils/Arrays';
import { FormattedMessage, useIntl } from 'react-intl';
import { EnumSelectField } from '../../../components/bootstrap/EnumSelectField';
import { AlertSuccess } from '../../../components/bootstrap/AlertSuccess';
import { useNavigate } from 'react-router-dom';
import { LabelToolTip } from '../../../components/bootstrap/LabelToolTip';
import { EmptyResults } from '../../../components/general/EmptyResults';
import { SubTemplate } from '../../../components/general/SubTemplate';
import Loading from '../../../components/general/Loading';
import { CustomPagination } from '../../../components/general/CustomPagination';
import { ListFilterBar } from '../../../components/general/ListFilterBar';
import { ProcessState } from '../../../models/ProcessState';
import { developmentPlanStatus, listDevelopmentPlans } from '../../../rest/developmentPlan';

const MAX_RESULTS = 10;
const DEFAULT_INITIAL_PAGE = 0;

export function ListDevelopmentPlans() {
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);
	const [numberOfResults, setNumberOfResults] = useState(null);
	const [totalResults, setTotalResults] = useState(null);
	const [currentPage, setCurrentPage] = useState(DEFAULT_INITIAL_PAGE);

	const [formList, setFormList] = useState([]);

	const orderByOptionsIdPrefix = 'form.list.orderOption';
	const orderByOptions = ['changeDate_desc', 'changeDate_asc'];

	const [currentOrderBy, setCurrentOrderBy] = useState(orderByOptions[0]);

	const [filters, setFilters] = useState({
		state: {
			type: 'enum',
			preffixDescriptionId: 'processState',
			options: ProcessState.valuesExcludingRejectAndWithdrawn(),
			value: '-1',
			nullOption: true,
			nullOptionSuffix: 'state',
			colSpace: 3,
		},
		candidatureProcessNumber: {
			type: 'text',
			value: '',
			placeholderId: 'consultingRequest.candidatureProcessId',
			colSpace: 3,
		},
	});

	const hoverpreffixId = 'developmentPlan.list.hover';

	const navigate = useNavigate();

	const intl = useIntl();

	const [lsItem, setLsItem] = useState(null);

	const [candidatureProcessNumberToFormExternalIdMapping, setCandidatureProcessNumberToFormExternalIdMapping] = useState({});
	const [showFormSelectionModal, setShowFormSelectionModal ] = useState(false);
	const [selectedCandidature, setSelectedCandidature] = useState(null);

	async function fetchData(fromFilter) {
		try {
			if (fromFilter === true) {
				setCurrentPage(0);
			}

			let orderParams = currentOrderBy.split('_');

			let queryparams = {
				index: fromFilter ? 0 : currentPage,
				size: MAX_RESULTS,
				orderBy: orderParams[0],
				orderType: orderParams[1],
			};

			if (filters.state.value !== '-1') {
				queryparams.state = filters.state.value;
			}

			queryparams.candidatureProcessNumber =
				filters.candidatureProcessNumber.value;

			const [{ data: list }] = await Promise.all([
				await listDevelopmentPlans(queryparams),
			]);

			setFormList(list.results);
			setNumberOfResults(list.numberOfResults);
			setTotalResults(list.totalResults);
		} catch (error) {
			setError(error);
		} finally {
			setLoading(false);
		}
	}

	const onPageChange = (pageNumber) => {
		setCurrentPage(pageNumber);
	};

	function handleOrderByChange(e) {
		setCurrentOrderBy(e.target.value);
	}

	function checkStorageItems() {
		if (sessionStorage.getItem('developmentPlanSubmited')) {
			setLsItem('developmentPlanSubmited');
		}
	}

	async function getFormStatus() {
		try{
			const {data: candidaturesToFormsMapping} = await developmentPlanStatus();
			setSelectedCandidature(Object.keys(candidaturesToFormsMapping)[0])
			setCandidatureProcessNumberToFormExternalIdMapping(candidaturesToFormsMapping)
		} catch (error) {
			setError(error);
		}
	}

	useEffect(() => {
		checkStorageItems();
		getFormStatus();
	}, []);

	useEffect(() => {
		fetchData();
	}, [currentPage]);

	if (loading) {
		return <Loading />;
	}

	if (error && isNotBusinessError(error)) {
		return handleError(error);
	}

	function redirectToFormPage(externalId) {
		navigate(`/planodesenvolvimento/${externalId}`);
	}

	return (
		<SubTemplate titleId={'developmentPlan.list.title'} hasBackButton>
			<Container>
			<Modal show={showFormSelectionModal} onHide={()=> setShowFormSelectionModal(false)}>
      <Modal.Header closeButton>
        <Modal.Title>
          <FormattedMessage id='developmentPlan.list.modal.title' />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
          <div>
            <FormattedMessage id='developmentPlan.list.modal.body' />
            <EnumSelectField
							nullOption={false}
							options={Object.keys(candidatureProcessNumberToFormExternalIdMapping)}
							labelId='candidature.text'
							handleChange={(e) => setSelectedCandidature(e.target.value)}
							value={selectedCandidature}
						/>
          </div>

        <div className='form-actions mt-5'>
          <button
            className='btn btn-link'
            type='button'
            onClick={()=> setShowFormSelectionModal(false)}
          >
            <FormattedMessage id='all.back' />
          </button>
            <button
              className='btn btn-primary'
              type='button'
              onClick={() => redirectToFormPage(candidatureProcessNumberToFormExternalIdMapping[selectedCandidature])}
            >
              <FormattedMessage id='all.select' />
            </button>
        </div>
      </Modal.Body>
    </Modal>
				<div className='mx-2 mb-4'>
					<AlertSuccess lsItem={lsItem} />
				</div>
				<Row>
					<Col md='10'>
						<ListFilterBar
							filters={filters}
							setFilters={setFilters}
							search={fetchData}
						/>
					</Col>
				</Row>
				<Row className='searchResults'>
					<Col md='10'>
						<AlertError error={error} />

						{Arrays.isNotEmpty(formList) ? (
							<>
								<Row className='mt-5 mb-1 d-flex justify-content-between px-2'>
									<Col className='text-muted'>
										{totalResults}{' '}
										<FormattedMessage
											id={`annualComplementaryActivitiesPlan.list.header.requests.${
												totalResults === 1 ? 'singular' : 'plural'
											}`}
										/>
									</Col>
									<Col md='4'>
										<EnumSelectField
											preffixDescriptionId={orderByOptionsIdPrefix}
											handleChange={handleOrderByChange}
											options={orderByOptions}
											value={currentOrderBy}
										/>
									</Col>
								</Row>

								<ul className='results'>
									{formList.map((form, index) => (
										<li key={form.externalId}>
											<Row
												className='py-3'
												onClick={() => redirectToFormPage(form.externalId)}
											>
												<Col md='7' className='d-flex align-items-center'>
													<LabelToolTip
														labelId={`${hoverpreffixId}.request`}
														value={
															intl.formatMessage({
																id: `${hoverpreffixId}.request.value`,
															}) + form.candidatureProcessNumber
														}
													/>
												</Col>
												<Col md='2' className='d-flex align-items-center'>
													<LabelToolTip
														labelId={`${hoverpreffixId}.state`}
														value={
															<FormattedMessage
																id={`processState.${form.processState}`}
															/>
														}
													/>
												</Col>
												<Col
													md='3'
													className='d-flex align-items-center justify-content-end'
												>
													<LabelToolTip
														labelId={`${hoverpreffixId}.changeDate`}
														value={form.changeDate}
													/>
												</Col>
											</Row>
										</li>
									))}
								</ul>
							</>
						) : (
							<EmptyResults />
						)}
					</Col>
					{Object.keys(candidatureProcessNumberToFormExternalIdMapping).length > 0 && (
						<Col md='2'>
							<div className='mt-4 py-3'>
								<button
									className='btn btn-outline-primary d-flex align-items-center justify-content-center mb-4 w-100'
									type='button'
									onClick={() => setShowFormSelectionModal(true)}
								>
									<FormattedMessage id={`developmentPlan.list.button.select`} />
								</button>
							</div>
						</Col>
					)}
				</Row>
				<Row className='mb-5'>
					<Col md='10' className='d-flex'>
						<div className='mx-2'>
							<CustomPagination
								dataPerPage={MAX_RESULTS}
								totalData={totalResults}
								onPageChange={onPageChange}
								currentPage={currentPage}
							/>
						</div>
					</Col>
				</Row>
			</Container>
		</SubTemplate>
	);
}
