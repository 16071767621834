import {Accordion, Col, Row} from "react-bootstrap";
import AccordionItem from "react-bootstrap/AccordionItem";
import AccordionHeader from "react-bootstrap/AccordionHeader";
import {FormattedMessage} from "react-intl";
import AccordionBody from "react-bootstrap/AccordionBody";
import React from "react";
import {DocumentTable} from "./DocumentTable";
import {DocumentType} from "../../../models/DocumentType";
import {Arrays} from "../../../utils/Arrays";

let decisionDocTypes = new Set([
  DocumentType.EAA_DECISION_NOTIFICATION,
  DocumentType.EAA_PROP_DECISION_NOTIFICATION,
  DocumentType.EAA_SERVICE_INFO,
  DocumentType.EAA_PROP_SERVICE_INFO
]);
export function EAADecision({accreditationDecision}) {
    return (
        <Accordion className='mt-0 mb-3'>
            <AccordionItem className='mt-0 mb-0' eventKey={'result'}>
                <AccordionHeader>
                    <FormattedMessage id={'ea.accreditation.results.decision'} />
                </AccordionHeader>
                <AccordionBody>
                    <Row className={'mb-3'}>
                        <Col>
                            <label><FormattedMessage id={'ea.accreditation.results.decision.date'}/></label>
                            {accreditationDecision.decisionDate}
                        </Col>
                        <Col>
                            <label><FormattedMessage id={'ea.accreditation.results.decision.decision'}/></label>
                            <FormattedMessage id={`accreditationDecisionState.${accreditationDecision.state}`}/>
                        </Col>
                    </Row>
                    {accreditationDecision?.decisionComment && <Row>
                        <Col>
                            <label><FormattedMessage id={'ea.accreditation.results.justification'}/></label>
                            {accreditationDecision.decisionComment}
                        </Col>
                    </Row>}
                    {!Arrays.isEmpty(accreditationDecision?.documents) && (
                    <>
                        <Row>
                            <Col>
                                <label><FormattedMessage id={'all.requiredDocuments2'}/></label>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <DocumentTable documents={accreditationDecision?.documents.filter(d => decisionDocTypes.has(d.documentType))} />
                            </Col>
                        </Row>
                    </>)}
                </AccordionBody>
            </AccordionItem>
        </Accordion>
    );
}