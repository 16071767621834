import {Col, Form, Row, Table} from "react-bootstrap";
import {FormattedMessage} from "react-intl";
import React from "react";
import {FaInfoCircle} from "react-icons/fa";
import {MappingType} from "../../../models/MappingType";
import {Arrays} from "../../../utils/Arrays";

function listToMapCount(elements) {
    return elements.reduce((countMap, element) => {
        countMap.set(element, (countMap.get(element) || 0) + 1);
        return countMap;
    }, new Map());
}

export function EaHRMappingTotals({
    tgps,
    districtOptions,
}) {
    const skillCount = listToMapCount(tgps.flatMap(tgp => tgp.hrMapList).map(s => s.mappingType));
    const skillCountByDistrict = listToMapCount(tgps.flatMap(tgp => tgp.hrMapList).map(s => s.districtCode + s.mappingType));

    const TotalBySkillAndDistrict = ({skillType, district}) => {
        if (Arrays.isEmpty(tgps)) return 0;
        return skillCountByDistrict.get(district.code + skillType) || 0;
    }

    return (
        <>
        <Row>
            <Col md={12}>
                <Table className={'w-100 d-table'}>
                    <thead>
                    <tr>
                        <th align={'center'} className={'text-left'}><label><FormattedMessage id={'eaAccreditation.View.Geo.locations.district'}/></label></th>
                        <th align={'center'} className={'text-center'}><label><FormattedMessage id={'eaAccreditationForm.step4.accordion.header.projectCreation.short'}/></label></th>
                        <th align={'center'} className={'text-center'}><label><FormattedMessage id={'eaAccreditationForm.step4.accordion.header.followUp.short'}/></label></th>
                        <th align={'center'} className={'text-center'}><label><FormattedMessage id={'eaAccreditationForm.step4.accordion.header.consultancy.short'}/></label></th>
                        <th align={'center'} className={'text-center'}><label><FormattedMessage id={'eaAccreditationForm.step4.accordion.header.skillsExtension.short'}/></label></th>
                    </tr>
                    </thead>
                    <tbody>
                    {districtOptions.map( d => (
                        <tr key={d.code}>
                            <td className={'text-left'}>{d.description}</td>
                            <td align={'center'} className={'text-center'}><TotalBySkillAndDistrict skillType={MappingType.CREATION} district={d}/></td>
                            <td align={'center'} className={'text-center'}><TotalBySkillAndDistrict skillType={MappingType.FOLLOW_UP} district={d}/></td>
                            <td align={'center'} className={'text-center'}><TotalBySkillAndDistrict skillType={MappingType.CONSULTING} district={d}/></td>
                            <td align={'center'} className={'text-center'}><TotalBySkillAndDistrict skillType={MappingType.EXPAND_SKILLS} district={d}/></td>
                        </tr>
                    ))}
                        <tr>
                            <td className={'text-left font-weight-bold'}><FormattedMessage id={'all.total'}/> </td>
                            <td align={'center'} className={'text-center'}>{skillCount.get(MappingType.CREATION)}</td>
                            <td align={'center'} className={'text-center'}>{skillCount.get(MappingType.FOLLOW_UP)}</td>
                            <td align={'center'} className={'text-center'}>{skillCount.get(MappingType.CONSULTING)}</td>
                            <td align={'center'} className={'text-center'}>{skillCount.get(MappingType.EXPAND_SKILLS)}</td>
                        </tr>
                    </tbody>
                </Table>
            </Col>
        </Row>
        </>
    );
}