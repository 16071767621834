import React, {useState} from "react";
import {Col, Form, Row} from "react-bootstrap";
import {FormattedMessage, useIntl} from "react-intl";
import {DateTimeInputField} from "../../bootstrap/DateTimeInputField";
import {SingleUploadArea} from "../../SingleUploadArea";
import {AlertError} from "../../bootstrap/AlertError";
import {Formik} from "formik";
import * as yup from "yup";
import {fullyApproveEntity, uploadFinalDocument} from "../../../rest/entity";
import {createCustomErrorMessage} from "../../../hooks/errorMessage";
import {DocumentType} from "../../../models/DocumentType";
import {ConfigObject} from "../../../config";
import dateFormat from "date-fns/format";

export function EaProtocolForm({entityNif, onCancel, onSubmitCallback, documents, setDocuments, entity}) {
    const intl = useIntl();
    const [error, setError] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [submitting, setSubmitting] = useState(false);


    let initialValues = {
        accreditationDate: entity?.accreditationDate,
        contractEndDate: entity?.contractEndDate
    }

    const validationSchema = yup.object().shape({
        accreditationDate: yup
            .date()
            .typeError(<FormattedMessage id='errors.fieldRequiredText'/>)
            .required(<FormattedMessage id='errors.fieldRequiredText'/>),
        contractEndDate: yup
            .date()
            .typeError(<FormattedMessage id='errors.fieldRequiredText'/>)
            .required(<FormattedMessage id='errors.fieldRequiredText'/>)
    });

    function validation (values, props) {
        const errors = {};
        if(documents.length === 0 || !documents.filter(d => d.submited === true).map(d => d.documentType).includes(DocumentType.COOPERATION_PROTOCOL)){
            errors.documents = <FormattedMessage id='need.documents'/>;
            setError(createCustomErrorMessage(intl.formatMessage({ id: 'need.documents'})))
        }
        return errors;
    }

    async function onSubmit(values, setSubmitting, setErrors) {
        setSubmitting(true);
        const dto = {
            accreditationDate: values.accreditationDate,
            contractEndDate: values.contractEndDate
        };

        try {
            await fullyApproveEntity(entityNif, dto);
            onSubmitCallback?.()
            setSubmitted(true);
            sessionStorage.setItem('eaAccreditationFullyApproved', true);
        } catch (error) {
            console.log(error);
            setErrors(error);
        }
        setSubmitting(false);
    }
    function getStartingDateHandler(values, setFieldValue) {
        return (name, value) => {
            setFieldValue(name, value);
             let start = new Date(value);
            if(values.contractEndDate == null) {
                let endDate = new Date(start);
                endDate.setFullYear(start.getFullYear() + 3);
                setFieldValue('contractEndDate', dateFormat(endDate, ConfigObject.get().DATE_PATTERN));
            }
        }

    }

    const uploadFunction = (formData) => uploadFinalDocument(entityNif, formData);

    return (
        <>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                validateOnBlur={false}
                validate={validation}
                validateOnChange={false}
                onSubmit={(values, {setSubmitting, setErrors}) => {
                    onSubmit(values, setSubmitting, setErrors);
                }}>
                {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleSubmit,
                      setFieldValue
                  }) => (
                    <Form onSubmit={handleSubmit}>
                        <AlertError error={error}/>
                        <AlertError error={errors} />
                        <Row>
                            <Col>
                                <DateTimeInputField
                                    labelId='entity.accreditationDate'
                                    value={values.accreditationDate}
                                    name='accreditationDate'
                                    setFieldValue={getStartingDateHandler(values, setFieldValue)}
                                    isDateWithNoTime={true}
                                    isInvalid={errors.accreditationDate}
                                    errorMessage={errors.accreditationDate}
                                />
                            </Col>
                            <Col>
                                <DateTimeInputField
                                    labelId='entity.contractEndDate'
                                    value={values.contractEndDate}
                                    name='contractEndDate'
                                    setFieldValue={setFieldValue}
                                    isDateWithNoTime={true}
                                    isInvalid={errors.contractEndDate}
                                    errorMessage={errors.contractEndDate}
                                />
                            </Col>
                        </Row>
                        <Row className={'mt-3 mb-0'}>
                            <Col>
                                <SingleUploadArea
                                    documents={documents}
                                    setDocuments={setDocuments}
                                    documentType={DocumentType.COOPERATION_PROTOCOL}
                                    setError={setError}
                                    processUploadCallback={uploadFunction}
                                    entityRelated={true}
                                />
                            </Col>
                        </Row>
                        <Row className='form-actions mt-2'>
                            <Col className='justify-content-between d-flex'>
                                <button className='btn btn-outline-primary' type='button' onClick={() => onCancel?.()}>
                                    <FormattedMessage id='all.cancel'/>
                                </button>
                                <button className='btn btn-primary' type='submit'>
                                    <FormattedMessage id='all.submitButtonText'/>
                                </button>
                            </Col>
                        </Row>
                    </Form>
                )}
            </Formik>
        </>
    );
}