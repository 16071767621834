import React, { useEffect, useMemo, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { AlertError } from '../bootstrap/AlertError';
import { TextInputField } from '../bootstrap/TextInputField';

export function ConfirmationAuthenticationModal({
	idsPreffix,
	confirmButtonCompleteLabelId,
	show,
	handleClose,
	submitHandler,
	IEFPdecisionApprove,
}) {
	const intl = useIntl();
	const [user, setUser] = useState();
	const [password, setPassword] = useState();
	const [modalError, setModalError] = useState(null);

	const [modalTitle, setModalTitle] = useState(
		intl.formatMessage({ id: `${idsPreffix}.title` })
	);
	const [modalBody, setModalBody] = useState(
		intl.formatMessage({ id: `${idsPreffix}.body` })
	);

	const [confirmButtonText, setConfirmButtonText] = useState(
		intl.formatMessage({ id: confirmButtonCompleteLabelId })
	);

	useEffect(() => {
		if (show) {
			setModalTitle(intl.formatMessage({ id: `${idsPreffix}.title` }));
			setModalBody(intl.formatMessage({ id: `${idsPreffix}.body` }));
			setConfirmButtonText(
				intl.formatMessage({ id: confirmButtonCompleteLabelId })
			);
		}
	}, [show]);

	function handleCloseModal() {
		setUser('');
		setPassword('');
		handleClose();
	}

	return (
		<Modal show={show} onHide={handleCloseModal}>
			<Modal.Header closeButton>
				<Modal.Title>{modalTitle}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<AlertError error={modalError} />
				<div>
					{modalBody}
					<TextInputField
						controlId={'user'}
						labelId={'messages.emailText'}
						name={'user'}
						handleChange={(e) => setUser(e.target.value)}
					/>
					<TextInputField
						controlId={'password'}
						labelId={'all.password'}
						name={'password'}
						handleChange={(e) => setPassword(e.target.value)}
						isPassword={true}
					/>
				</div>

				<div className='form-actions mt-5'>
					<button
						className='btn btn-link'
						type='button'
						onClick={() => handleCloseModal()}
					>
						<FormattedMessage id='all.back' />
					</button>
					<button
						className='btn btn-primary'
						type='button'
						onClick={() =>
							submitHandler(user, password, setModalError, IEFPdecisionApprove)
						}
					>
						{confirmButtonText}
					</button>
				</div>
			</Modal.Body>
		</Modal>
	);
}
