import {CandidatureChangeStateWithReason} from "../CandidatureChangeStateWithReason";
import {useParams} from "react-router-dom";
import {clarifyDoubts} from "../../../rest/candidature";
import React, {useEffect, useState} from "react";
import {listDocumentsByTypeAndCandidatureExternalId} from "../../../rest/document";
import Loading from "../../general/Loading";

export function CandidatureFlowClarifyDoubts({callbackHandler,documentType, setError}) {
    const {externalId} = useParams();
    const [userDocuments, setUserDocuments] = useState(null);
    const [loading, setLoading] = useState(true);

    const clarifyDoubtsHandler = (reasonData) => {
        callbackHandler(() => {
            return clarifyDoubts(externalId, reasonData);
        });
    }


    async function fetchData() {
        try {
            setLoading(true);
            const [
                {data: userDocuments},

            ] = await Promise.all([
                await listDocumentsByTypeAndCandidatureExternalId(documentType, externalId),
            ]);

            setUserDocuments(userDocuments);
        } catch (error) {
            setError(error);
        }finally {
            setLoading(false)
        }
    }



    useEffect(() => {
        fetchData();
    }, []);

    if (loading) {
        return <Loading />;
    }

    return (
        <>
            <CandidatureChangeStateWithReason
                modalTitleId="candidature.title.clarifyDoubts"
                modalReasonTextId="candidature.message.clarifyDoubts"
                flowActionId="candidature.flow.clarifyDoubts"
                submitHandler={clarifyDoubtsHandler}
                documentType={documentType}
                userDocuments={userDocuments}
                externalId={externalId}
            />
        </>
    );
}
