import React, { useEffect, useState } from 'react';
import { TextInputField } from '../../bootstrap/TextInputField';
import { NumberInputField } from '../../bootstrap/NumberInputField';
import { Row, Col, Table, Form } from 'react-bootstrap';
import { FaInfoCircle } from 'react-icons/fa';
import { FormattedMessage, useIntl } from 'react-intl';
import { TextAreaInputField } from '../../bootstrap/TextAreaInputField';

export function EaAccreditationFormStep2({
	formStep,
	numberOfSteps,
	errors,
	handleChange,
	formikValues,
	handlePreviousStep,
	handleNextStep,
	setFieldValue,
	tableHeaders,
    readMode = false
}) {
	const intl = useIntl();

	const entitiesPerYearRowIds = [
		'entitiesPerYearInvestment',
		'entitiesPerYearFollowUp',
		'entitiesPerYearConsultancy',
		'entitiesPerYearIncubation',
	];

	const actionsPerYearRowIds = [
		'actionsPerYearWorkshops',
		'actionsPerYearSeminaries',
		'actionsPerYearDidacticMaterials',
		'actionsPerYearBootCamps',
	];

	function handleCheckboxChange(e) {
		if (!e.target.checked) {
			setFieldValue('entity.connectionWithIEFPDesc', '');
		}

		handleChange(e);
	}

	function removeConsultancyAreaHandler(index) {
		formikValues.entity.consultingAreas.splice(index, 1);
		setFieldValue({ ...formikValues });
	}

	function addConsultancyAreaHandler() {
		formikValues.entity.consultingAreas.push('');
		setFieldValue({ ...formikValues });
	}

	function calculateTotal(rowsIds, column) {
		let sum = 0;
		rowsIds.map((row) => (sum += formikValues[row][column].value || 0));
		return sum;
	}

	function generateTable(rowsIds) {
		return (
			<Table>
				<thead>
					<tr>
						<th className='w-50' />
						{tableHeaders.map((header) => (
							<th key={header}>{header}</th>
						))}
					</tr>
				</thead>
				<tbody>
					{rowsIds.map((rowId) => (
						<tr key={rowId}>
							<th>
								<FormattedMessage
									id={`eaAccreditationForm.step2.table.${rowId}`}
								/>
							</th>
							{tableHeaders.map((header) => (
								<td key={header}>
									<NumberInputField
										name={`${rowId}.${header}.value`}
										handleChange={handleChange}
										value={formikValues[rowId][header].value}
										isInvalid={errors[rowId]?.[header]?.value}
										errorMessage={errors[rowId]?.[header]?.value}
										disabled={readMode}
									/>
								</td>
							))}
						</tr>
					))}
				</tbody>
				<tfoot>
					<tr>
						<th>
							<FormattedMessage id='eaAccreditationForm.step2.table.total' />
						</th>
						{tableHeaders.map((header) => (
							<th key={header}>{calculateTotal(rowsIds, header)}</th>
						))}
					</tr>
				</tfoot>
			</Table>
		);
	}

	return (
		<div>
			<h4 className='text-primary mb-0'>
				<FormattedMessage id='eaAccreditationForm.step2.title' />
			</h4>
			<small className='text-secondary text-uppercase'>
				{intl.formatMessage(
					{ id: 'all.step' },
					{ step: formStep, numberOfSteps: numberOfSteps }
				)}
			</small>
			<Row>
				<Col>
					<Form.Label>
						<FormattedMessage id='eaAccreditationForm.step2.entitiesPerYear' />
					</Form.Label>
					{generateTable(entitiesPerYearRowIds)}
				</Col>
			</Row>

			<Row>
				<Col>
					<Form.Label>
						<FormattedMessage id='eaAccreditationForm.step2.actionsPerYear' />
					</Form.Label>
					{generateTable(actionsPerYearRowIds)}
				</Col>
			</Row>

			<Row className='mt-4'>
				<Col>
					<Form.Check
						label={intl.formatMessage({
							id: 'eaAccreditationForm.step2.experienceWithIEFP',
						})}
						name='entity.connectionWithIEFP'
						onChange={handleCheckboxChange}
						value={formikValues.entity.connectionWithIEFP}
						checked={formikValues.entity.connectionWithIEFP}
						disabled={readMode}
					/>
				</Col>
			</Row>
			{formikValues.entity.connectionWithIEFP && (
				<Row>
					<Col>
						<TextAreaInputField
							placeholder={intl.formatMessage({
								id: 'eaAccreditationForm.step2.experienceWithIEFP.placeholder',
							})}
							name={'entity.connectionWithIEFPDesc'}
							handleChange={handleChange}
							value={formikValues.entity.connectionWithIEFPDesc}
							isInvalid={errors.entity?.connectionWithIEFPDesc}
							errorMessage={errors.entity?.connectionWithIEFPDesc}
							disabled={readMode}
						/>
					</Col>
				</Row>
			)}
			<Row className='mt-4'>
				<Col>
					<Form.Label>
						<FormattedMessage id='eaAccreditationForm.step2.consultancyAreas' />
					</Form.Label>
				</Col>
			</Row>
			{formikValues.entity.consultingAreas.map((area, index) => (
				<Row className='mt-4 align-items-center' key={index}>
					<Col md='2'>
						{intl.formatMessage({ id: 'eaAccreditationForm.step2.area' }) +
							' ' +
							(index+1)}
					</Col>
					<Col md='8'>
						<TextInputField
							name={`entity.consultingAreas[${index}]`}
							handleChange={handleChange}
							value={formikValues.entity.consultingAreas[index]}
							isInvalid={errors.entity?.consultingAreas?.[index]}
							errorMessage={errors.entity?.consultingAreas?.[index]}
							disabled={readMode}
						/>

					</Col>
					<Col md='2'>
						{!readMode && (
						<button
							className='btn btn-link'
							type='button'
							onClick={() => removeConsultancyAreaHandler(index)}
						>
							<FormattedMessage id='all.remove' />
						</button>
						)}
					</Col>
				</Row>
			))}
			{!readMode && (
			<Row className='mt-3'>
				<Col>
					<button
						className='btn btn-outline-primary'
						type='button'
						onClick={addConsultancyAreaHandler}
					>
						Adicionar
					</button>
				</Col>
			</Row>
			)}
			<div className='form-actions mt-5'>
				<button
					className='btn btn-link'
					type='button'
					onClick={handlePreviousStep}
				>
					<FormattedMessage id='all.back' />
				</button>

				<button
					className='btn btn-primary'
					type='button'
					onClick={handleNextStep}
				>
					<FormattedMessage id='all.nextStep' />
				</button>
			</div>
		</div>
	);
}
