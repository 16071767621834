import React, {useEffect, useState} from 'react';

import {useNavigate, useParams} from 'react-router-dom';
import {createActionNotification} from "../../../rest/templates";
import {handleError, isBusinessError} from "../../../utils/handleError";
import Loading from "../../../components/general/Loading";
import {SubTemplate} from "../../../components/general/SubTemplate";
import {ActionNotificationForm} from "../../../components/actionNotification/ActionNotificationForm";
import {AlertError} from "../../../components/bootstrap/AlertError";
import {getEntityDetails, getEntityDistricts, getEntityGetList} from "../../../rest/entity";
import {
  getDefaultProjectIdentification,
  getProjectIdentificationProcessExternalId
} from "../../../rest/projectIdentification";
import {getHumanResourceByExternalId} from "../../../rest/humanResource";
import {EntityChangeGeoForm} from "../../../components/entityChange/EntityChangeGeoForm";
import {Arrays} from "../../../utils/Arrays";
import {createMultiPartWithFilesAndJsonObject} from "../../../components/SyncMultipleUploadArea";
import {contactPromoter, contactPromoterWithFiles} from "../../../rest/candidature";
import {addEntityChange, addEntityChangeWithFiles} from "../../../rest/entityChanges";
import {EntityChangeContactForm} from "../../../components/entityChange/EntityChangeContactForm";

export function AddEntityChangeContact() {

  const {nif} = useParams();
  const [ec,setEc] = useState(null);
  const [documents, setDocuments] = useState([]);
  const [entityDetails,setEntityDetails] = useState(null)


  async function fetchData() {

      try {

        let [
          { data: entityDetails }
        ] = await Promise.all([
          await getEntityDetails(nif)
        ])
        setEntityDetails(entityDetails);
        let entityChange = {address:entityDetails.address,
          postalCode4Digits:entityDetails.postalCode4Digits,
          postalCode3Digits:entityDetails.postalCode3Digits,
          location: entityDetails.location,
          phone:entityDetails.phone,
          email: entityDetails.email,
          districtCode:entityDetails.districtCode
        }
        setEc(entityChange)
        //setFormikInitialValues(getFormikInitialValues(projectIdentification));
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }

    //setShowSubmitModal(false);
  };



  useEffect(() => {
    fetchData();
  }, []);




  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [submited, setSubmited] = useState(false);

  const onSubmit = async (values, setSubmitting, setErrors) => {
    try {
      let initialValues = values;
      initialValues.type= 'CONTACT';

      const hasFiles = Arrays.isNotEmpty(documents);
      if(hasFiles) {
        const formData = createMultiPartWithFilesAndJsonObject(documents, initialValues, 'EntityChangeDto');
        await addEntityChangeWithFiles(nif, formData);
      } else {
        await addEntityChange(nif,initialValues);
      }
      setSubmited(true);

      /*await createActionNotification(actionNotification);

      setActionNotification(actionNotification);*/
      /*setSubmitting(false);

      setLoading(false);*/
    } catch (error) {
     /* setSubmitting(false);
      setError(error);
      setLoading(false);*/
    }
  };


  if (loading) {
    return <Loading />;
  }

  if (error && !isBusinessError(error)) {
    return handleError(error);
  }

  if (submited) {
    sessionStorage.setItem('entityChangeSubmitContact', submited);
    navigate('/alteracoesEntidade/'+nif);
  }

  return (
    <SubTemplate hasBackButton titleId={'entityChange.title.CONTACT'}>
      <div className={'container'}>
        <div className={'row'}>
          <div className={'col-lg-8 offset-lg-2'}>
            <AlertError error={error} />
            {ec &&
            <EntityChangeContactForm entityChange={ec} documents={documents} setDocuments={setDocuments}
                                     setError={error} onSubmit={onSubmit}/>
            }
          </div>
        </div>
      </div>
    </SubTemplate>
  );
}
