import dateFormat from 'date-fns/format';
import {useEffect, useState} from 'react';
import { Form } from 'react-bootstrap';
import DateTimePicker from 'react-datetime-picker';
import { FormattedMessage } from 'react-intl';
import { ConfigObject } from '../../config';

export function DateTimeInputField({
	name,
	value,
	errorMessage,
	setFieldValue,
	isInvalid,
	disabled,
	labelId,
	isDateWithNoTime,
	tooltip
}) {
	const dateTimePattern = ConfigObject.get().DATE_TIME_PATTERN;
	const datePattern = ConfigObject.get().DATE_PATTERN;

	useEffect(() => {
		setDate((value === null) || (!value) ? '' : new Date(value))
	}, [value]);

  const [date, setDate] = useState((value === null) || (!value) ? '' : new Date(value));


	const handleChange = (e) => {
		setDate(e);
		if (e) {
			setFieldValue(name, dateFormat(e, getDateFormat()));
		} else {
			setFieldValue(name, '');
		}
	};

	function getDateFormat() {
		if (isDateWithNoTime) {
			return datePattern;
		}
		return dateTimePattern;
	}

	return (
		<Form.Group controlId={name}>
			{labelId && (
				<Form.Label>
					<FormattedMessage id={labelId} />
					{tooltip? tooltip :''}
				</Form.Label>
			)}
			<Form.Control
				className={'datetimepicker' + (isDateWithNoTime ? ' datepicker' : '')}
				as={DateTimePicker}
				format={getDateFormat()}
				clearIcon={null}
				value={date}
				onChange={handleChange}
				isInvalid={isInvalid}
				disabled={disabled}
			/>
			<Form.Control.Feedback type='invalid'>
				{errorMessage}
			</Form.Control.Feedback>
		</Form.Group>
	);
}
