import { apiAxios as authAxios } from '../axios.config';

export const listAdditionalActivitiesPlanStartCommunication = (queryParams) => {
  return authAxios.get('/api/additionalActivitiesPlan/startCommunication/list/', {params:  queryParams})
}

export const saveAdditionalActivitiesPlanStartCommunication = (additionalActivitiesPlanStartCommunication, queryParams) => {
  return authAxios.post('/api/additionalActivitiesPlan/startCommunication', additionalActivitiesPlanStartCommunication, {params: queryParams})
}

export const getAdditionalActivitiesPlanStartCommunication = (b17StartCommunicationExternalId, entityNif) => {
  return authAxios.get(`/api/additionalActivitiesPlan/startCommunication/${b17StartCommunicationExternalId !== undefined ? b17StartCommunicationExternalId : ''}`, entityNif ? {
    params: {
      entityNif: entityNif
    }
  } : null)
}

export const getAdditionalActivitiesPlanStartCommunicationByProcessExternalId = (b17StartCommunicationProcessExternalId) => {
  return authAxios.get(`/api/additionalActivitiesPlan/startCommunication/process/${b17StartCommunicationProcessExternalId}`)
}

export const listAdditionalActivitiesPlanStartCommunicationDocuments = (b17StartCommunicationProcessExternalId) => {
  return authAxios.get(`/api/additionalActivitiesPlan/startCommunication/${b17StartCommunicationProcessExternalId}/document`)
}

export const uploadB17StartCommunicationDocument = (b17StartCommunicationProcessExternalId, formData) => {
  return authAxios.post(`/api/additionalActivitiesPlan/startCommunication/${b17StartCommunicationProcessExternalId}/document`, formData)
}

export const deleteB17StartCommunicationDocument = (b17StartCommunicationProcessExternalId, documentExternalId) => {
  return authAxios.delete(`/api/additionalActivitiesPlan/startCommunication/${b17StartCommunicationProcessExternalId}/document/${documentExternalId}`)
}

export const additionalActivitiesPlanStartCommunicationFormStatus = (entityNif) => {
  return authAxios.get('/api/additionalActivitiesPlan/startCommunication/formStatus', entityNif ? {
    params: {
      entityNif: entityNif
    }
  } : null)
}