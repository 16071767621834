import React from 'react';

const Table = ({ children }) => {
    let subComponentList = Object.keys(Table);

    let subComponents = subComponentList.map((key) => {
        return React.Children.map(children, (child) =>
            child.type.name === key ? child : null
        );
    });

    return (
        <>
            <div className='div-table'>
                {subComponents.map((component) => component)}
            </div>
        </>
    );
};

const Caption = (props) => <div className='div-table-caption'>{props.children}</div>;
Table.Caption = Caption;

const Header = (props) => <div className='div-table-header-row'>{props.children}</div>;
Table.Header = Header;

const HeaderCell = (props) => <div className='div-table-header-cell'>{props.children}</div>;
Table.HeaderCell = HeaderCell;

const Body = (props) => <div className='div-table-body'>{props.children}</div>;
Table.Body = Body;

const Row = (props) => <div className='div-table-row'>{props.children}</div>;
Table.Row = Row;

const Cell = (props) => <div className='div-table-cell'>{props.children}</div>;
Table.Cell = Cell;

const Footer = (props) => <div className='div-table-footer'>{props.children}</div>;
Table.Footer = Footer;

const FooterCell = (props) => <div className='div-table-footer-cell'>{props.children}</div>;
Table.FooterCell = FooterCell;


export default Table;