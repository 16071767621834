/*
    B11,Identificacao da EA
    B11,Identificacao da Empresa
    B11, Financiamento da MCE
    B11, Pedido Pagamento
    B11 Analise e Parecer Tecnico
*/
import React, {useEffect, useRef, useState} from 'react';
import {Form, Row, Container, Col, ToastContainer, Toast, Button} from 'react-bootstrap';
import { Formik } from 'formik';
import {FormattedMessage, useIntl} from 'react-intl';
import Loading from '../../components/general/Loading';
import { SubTemplate } from '../general/SubTemplate';
import {FaCheck, FaRegCheckCircle, FaRegSave,FaAngleLeft} from 'react-icons/fa';
import { handleError, isNotBusinessError } from '../../utils/handleError';
import { FormSideNavBar } from '../general/FormSideNavBar';
import {
    SemestralReportFormStep1,
    SemestralReportFormStep2,
} from '../semestralReport';
import * as formSteps from '../../components/semestralPaymentRequest';

import {Link, useNavigate, useParams} from "react-router-dom";
import {isEntityUser, isIEFPUser, logout} from "../../authentication/authenticationHelper";
import * as yup from "yup";
import {Month} from "../../models/Month";
import {DecisionDialog} from "../bootstrap/DecisionDialog";
import {ProcessState} from "../../models/ProcessState";
import {AlertError} from "../bootstrap/AlertError";
import {PaymentRequestType} from "./PaymentRequestType";
import {getParentProcessId} from "../../rest/payableProcess";
import {PayableProcessButton} from "../payableProcess/PayableProcessButton";
import {createCustomErrorMessage} from "../../hooks/errorMessage";
import {maxFixed} from "../../utils/CurrencyUtils";
import {StateAndDateBadge, stateAndDateBadgePropsFromProcess} from "../general/stateBadge/StateAndDateBadge";


export function AddPaymentRequest({
    paymentRequestType,
    getPaymentRequest,
    listPaymentRequestDocuments,
    uploadDocument,
    deleteDocument,
    savePaymentRequest,
    decidePaymentRequest,
    submitPaymentRequest
}) {
    const {externalId} = useParams();

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [navErrors, setNavErrors] = useState([]);
    const [formStep, setFormStep] = useState(0);
    const [paymentRequest, setPaymentRequest] = useState(null);
    const [showSaveToast, setShowSaveToast] = useState(false);
    const [showSubmitModal, setShowSubmitModal] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [showAccept, setShowAccept] = useState(false);
    const [showReject, setShowReject] = useState(false);
    const [accept, setAccept] = useState(false);
    const [reject, setReject] = useState(false);
    const [documents, setDocuments] = useState([]);
    const navigate = useNavigate();

    const tabsIds = [
        'semestralReportForm.sideNavBar.identificationEA',
        'semestralReportForm.sideNavBar.identificationCompany',
        'semestralPaymentRequestForm.sideNavBar.MCEFinancing',
        'semestralPaymentRequestForm.sideNavBar.paymentRequest',

    ];
    const intl = useIntl();
    const isIEFP = isIEFPUser();
    const isEntity = isEntityUser();
    const isFinal = paymentRequestType === PaymentRequestType.FINAL;

    function getIntlPrefix() {
        return isFinal ? 'finalPaymentRequestForm' : 'semestralPaymentRequestForm';
    }

    function getFormText() {
        return isFinal ? 'AddFinalPaymentRequest' : 'AddSemestralPaymentRequest';
    }

    //TODO(jaime)🧔🏻: This is kind of messy, I don't really understand it in depth but it's repeated in a bunch of places
    function loadDocument(data, documentType) {
        if (data.length !== 0) {
            const document = data[0];
            return {
                documentType: document.documentType,
                externalId: document.externalId,
                name: document.name,
                documentPath: document.documentPath,
                content: '',
                submited: true,
                error: '',
            };
        }

        return {
            documentType: documentType,
            externalId: '',
            name: '',
            content: null,
            submited: false,
            error: '',
        };
    }


    const formikPropsRef = useRef(null);

    async function fetchData() {
        try {
            const [{ data: preq }] = await Promise.all([
                getPaymentRequest(externalId)
            ]);

            console.log('preq', preq);

            preq.paymentType = paymentRequestType;
            setPaymentRequest(preq);


            let count = 0;
            preq.visits.forEach((visit)=>{
                if((visit.bimonthly === true || visit.bimonthly === 'true') && count<3){
                        visit.requestedValue=maxFixed(1/(parseFloat(preq.mceFinancing.contractDuration)/2)*parseFloat(preq.plafondFollowUp));
                    count++;
                }else{
                    visit.requestedValue=0;
                }
            })



            let bimestralFollowup=  preq.visits.reduce((n, visit) => (visit.bimonthly === true || visit.bimonthly === 'true')  ? n+(visit.requestedValue !==undefined ? parseFloat(visit.requestedValue):0) : n, 0);
            preq.bimestralFollowUpPayment=bimestralFollowup;


            let consultingPaymentAsk = maxFixed(parseFloat(preq.plafondConsulting) / 120 * ((parseInt(preq.spentHours===undefined?0:preq.spentHours))>120 ? 120 : parseInt(preq.spentHours===undefined?0:preq.spentHours)) );
            preq.consultingPayment= consultingPaymentAsk;
            preq.bimestralProposalPayment = ((preq.numberOfReportsProducedThisSemester===undefined || preq.numberOfReportsProducedThisSemester==='')?0: parseInt(preq.numberOfReportsProducedThisSemester)) * ((preq.byReportValue===undefined || preq.byReportValue==='')?0: parseFloat(preq.byReportValue))

            preq.skillsExtensionProposalPayment = ((preq.numberOfHoursSkillsExtension===undefined || preq.numberOfHoursSkillsExtension==='') ?0: parseInt(preq.numberOfHoursSkillsExtension)) * ((preq.byHourValue===undefined || preq.byHourValue==='')?0: parseFloat(preq.byHourValue))

            console.log(preq);

            if(listPaymentRequestDocuments != null) {
                console.log('LoadingDocs');
                const [{data: docs}] = await Promise.all([
                    listPaymentRequestDocuments(externalId)
                ]);
                setDocuments([loadDocument(docs, 'DECISION_DISPATCH')]);
            }
        } catch (error) {
            console.log('AddPaymentRequest::fetchData', error);
            setError(error);
        } finally {
            setLoading(false);
        }
    }

    function scrollPageToTop() {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }

    function handlePreviousStep() {
        scrollPageToTop();
        setFormStep(formStep - 1);
    }

    function handleNextStep() {
        scrollPageToTop();
        setFormStep(formStep + 1);
        console.log('[TODO] handle form save by step');
    }


    useEffect(() => {
        fetchData();
    }, []);


    if(!PaymentRequestType.values().includes(paymentRequestType)) {
        console.log('Wrong Type');
        return null;
    }

    if (loading) {
        return <Loading />;
    }

    const entityReadMode = paymentRequest?.processState !== ProcessState.DRAFT;
    const IEFPReadMode = paymentRequest?.processState !== ProcessState.SUBMITTED;

    if(isIEFP || ProcessState.finalStates().includes(paymentRequest.processState)  ) {
        tabsIds.push('semestralPaymentRequestForm.sideNavBar.technicalAnalysis',)
    }

    if (submitted || accept || reject) {
        const prefix =  (PaymentRequestType.SEMESTRAL === paymentRequestType) ? 'semester' : 'final';
        if (submitted) {
            sessionStorage.setItem(
                prefix+'ReportPaymentSubmit',
                submitted
            );
        } else  if(accept){
            sessionStorage.setItem(
                prefix+'ReportPaymentAccept',
                accept
            );
        }else{
            sessionStorage.setItem(
                prefix+'ReportPaymentReject',
                reject);
        }
        return navigate('/relatorioSemestral/' + externalId, {
            state: { refresh: true }
        });
    }




    if (error && isNotBusinessError(error)) {
        return handleError(error);
    }

    function dtoGen() {
        const dto = {}
        const v = formikPropsRef.current.values;
        const userInput = {
            bimestralFollowUpPayment: v.bimestralFollowUpPayment,
            consultingPayment: v.consultingPayment,
            spentHours: v.spentHours,
            observationsEA: v.observationsEA,
            visits: v.visits.map( i => {
                return {
                    month: Month.values().includes(i.month) ? i.month : null,
                    bimonthly: i.bimonthly,
                    otherActivities: i.otherActivities,
                    requestedValue: i.requestedValue
                }
            }),
            bimestralProposalPayment: v.bimestralProposalPayment,
            skillsExtensionProposalPayment: v.skillsExtensionProposalPayment,
            numberOfReportsToProduceFollowUp: v.numberOfReportsToProduceFollowUp,
            numberOfReportsProducedThisSemester: v.numberOfReportsProducedThisSemester,
            byReportValue: v.byReportValue,
            numberOfHoursSkillsExtension: v.numberOfHoursSkillsExtension,
            byHourValue: v.byHourValue,
            observationsIEFP: v.observationsIEFP,
            serviceInformationReference: v.serviceInformationReference,
            dispatchDate: v.dispatchDate,
            directorName: v.directorName,

        }
        Object.assign(dto, paymentRequest);
        Object.assign(dto, userInput);
        return dto;
    }

    async function handleSave() {
        const dto = dtoGen();
        try {
            await savePaymentRequest(externalId, dto);
            setShowSaveToast(true)
        } catch (err) {
            console.log(err);
            if (err.response.data.exception === 'ValidationException') {
                let errors = err.response.data.message
                    .split(',')
                    .map((value) => Number.parseInt(value));
                setNavErrors(errors);
            }
        }
    }

    async function submitData() {

    }

    async function validateBeforeSubmit(setShowSubmitModal) {
        const formikErrors = await formikPropsRef.current.validateForm();
        console.log(formikErrors);
        const errorCount = Object.keys(formikErrors).length;
        let errors = new Set();
        Object.keys(formikErrors).forEach((key) => {
            switch (key) {
                case 'bimestralFollowUpPayment':
                case 'consultingPayment':
                case 'spentHours':
                case 'visits':
                case 'observationsEA':
                    isEntity && errors.add(3);
                    break;
                case 'bimestralProposalPayment':
                case 'byReportValue':
                case 'directorName':
                case 'dispatchDate':
                case 'numberOfHoursSkillsExtension':
                case 'numberOfReportsToProduceFollowUp':
                case 'numberOfReportsProducedThisSemester':
                case 'observationsIEFP':
                case 'serviceInformationReference':
                case 'skillsExtensionProposalPayment':
                    isIEFPUser() && errors.add(4);
            }
        });

        if((documents.length !==1 || documents[0].submited===false)  && isIEFPUser()){

            formikErrors.documents=<FormattedMessage id='errors.fieldRequiredText' />
            formikPropsRef.current.setErrors(formikErrors);
            errors.add(4);

        }



    console.log(errorCount)
        setNavErrors(Array.from(errors));
        if(errorCount === 0 && setShowSubmitModal!==undefined) {
            setShowSubmitModal(true);
        }else{
            setError(
                createCustomErrorMessage(intl.formatMessage({ id: 'paymentRequest.someErrors' }))
            );
        }
        return errorCount===0;

    }

    async function handleSubmit() {
        const dto = dtoGen();
        dto.processState = ProcessState.SUBMITTED;
        try {
            await submitPaymentRequest(externalId, dto);
            setSubmitted(true);
        } catch (err) {
            console.log('error submiting', err);
            if (err.response.data.exception === 'ValidationException') {
                let errors = err.response.data.message
                    .split(',')
                    .map((value) => Number.parseInt(value));
                setNavErrors(errors);
            }
        }
        
    }

    const formikInitialValues = {
        accumulatedPayments: [],
        spentHours: 0,
        numberOfReportsProducedThisSemester: 0,
        numberOfReportsToProduceFollowUp: 0,
        numberOfHoursSkillsExtension: 0,
        byHourValue: 0,
        byReportValue: 0,
    }

    async function submitDecision(decisionApprove) {

            const dto = dtoGen();
            dto.processState = decisionApprove ? ProcessState.APPROVED : ProcessState.REJECTED;
            try {
                await decidePaymentRequest(externalId, dto)
                if(decisionApprove){
                    setAccept(true);
                }else{
                    setReject(true);
                }
            } catch (err) {
                if (err.response.data.exception === 'ValidationException') {
                    let errors = err.response.data.message
                        .split(',')
                        .map((value) => Number.parseInt(value));
                    setNavErrors(errors);
                }
                setError(err);

        }

    }
    async function submitAccept(){
        setShowAccept(false);
        await submitDecision(true);
    }

    async function acceptDecision(){
        const  isValid = await validateBeforeSubmit(setShowAccept);
    }


    async function rejectDecision() {
       const  isValid = await validateBeforeSubmit(setShowReject);
    }



        async function submitReject(){
        setShowReject(false);
        await submitDecision(false);
    }

    function getSaveToastInformation(message) {
        return (
            <ToastContainer className='fixed-top'>
                <Toast autohide delay={3000} show={showSaveToast} onClose={() => setShowSaveToast(false)}>
                    <Toast.Header>
                        <strong className='me-auto text-primary'>
              <span className='mr-2'>
                <FaCheck/>
              </span>
                            {message}
                        </strong>
                    </Toast.Header>
                </Toast>
            </ToastContainer>
        );
    }



    Object.assign(formikInitialValues, structuredClone(paymentRequest));
    console.log(formikInitialValues)



    const validationSchema = yup.object().shape({
        bimestralFollowUpPayment: yup
            .number()
            .typeError(<FormattedMessage id='errors.fieldRequiredText'/>)
            .required(<FormattedMessage id='errors.fieldRequiredText'/>),
        consultingPayment: yup
            .number()
            .typeError(<FormattedMessage id='errors.fieldRequiredText'/>)
            .required(<FormattedMessage id='errors.fieldRequiredText'/>),
        spentHours: yup.mixed().when('paymentType', {
            is: (paymentType) => paymentType === PaymentRequestType.SEMESTRAL,
            then:yup
            .number()
            .typeError(<FormattedMessage id='errors.fieldRequiredText'/>)}),
        observationsEA: yup
            .string()
            .typeError(<FormattedMessage id='errors.fieldRequiredText'/>)
            .required(<FormattedMessage id='errors.fieldRequiredText'/>),

        visits: yup.mixed().when('paymentType', {
            is: (paymentType) => paymentType === PaymentRequestType.SEMESTRAL,
            then:  yup.array()
                .of(
                    yup.object().shape({
                        month: yup
                            .string()
                            .typeError(<FormattedMessage id='errors.fieldRequiredText'/>)
                            .oneOf(Month.values(), <FormattedMessage id='errors.fieldRequiredText'/>)
                            .required(<FormattedMessage id='errors.fieldRequiredText'/>),
                        bimonthly: yup
                            .boolean()
                            .required(<FormattedMessage id='errors.fieldRequiredText'/>),
                        otherActivities: yup
                            .boolean()
                            .required(<FormattedMessage id='errors.fieldRequiredText'/>),
                        requestedValue: yup
                            .number()
                            .typeError(<FormattedMessage id='errors.fieldRequiredText'/>)
                            .min(0.01, <FormattedMessage id='errors.fieldRequiredText'/>)
                            .required(<FormattedMessage id='errors.fieldRequiredText'/>),
                    }),
                )
        }),



    });


    const IEFPvalidationSchema = yup.object().shape({
        bimestralProposalPayment: yup
            .number()
            .typeError(<FormattedMessage id='errors.fieldRequiredText'/>)
            .required(<FormattedMessage id='errors.fieldRequiredText'/>),
        skillsExtensionProposalPayment: yup
            .number()
            .typeError(<FormattedMessage id='errors.fieldRequiredText'/>)
            .required(<FormattedMessage id='errors.fieldRequiredText'/>),
        numberOfReportsToProduceFollowUp: yup
            .number()
            .typeError(<FormattedMessage id='errors.fieldRequiredText'/>)
            .required(<FormattedMessage id='errors.fieldRequiredText'/>),
        numberOfReportsProducedThisSemester: yup
            .number()
            .typeError(<FormattedMessage id='errors.fieldRequiredText'/>)
            .required(<FormattedMessage id='errors.fieldRequiredText'/>),
        byReportValue: yup
            .number()
            .typeError(<FormattedMessage id='errors.fieldRequiredText'/>)
            .required(<FormattedMessage id='errors.fieldRequiredText'/>),
        numberOfHoursSkillsExtension: yup
            .number()
            .typeError(<FormattedMessage id='errors.fieldRequiredText'/>)
            .required(<FormattedMessage id='errors.fieldRequiredText'/>),
        byHourValue: yup
            .number()
            .typeError(<FormattedMessage id='errors.fieldRequiredText'/>)
            .required(<FormattedMessage id='errors.fieldRequiredText'/>),
        observationsIEFP: yup
            .string()
            .typeError(<FormattedMessage id='errors.fieldRequiredText'/>)
            .required(<FormattedMessage id='errors.fieldRequiredText'/>),

        serviceInformationReference: yup
            .string()
            .typeError(<FormattedMessage id='errors.fieldRequiredText'/>)
            .required(<FormattedMessage id='errors.fieldRequiredText'/>),

        dispatchDate: yup
            .date()
            .typeError(<FormattedMessage id='errors.fieldRequiredText'/>)
            .required(<FormattedMessage id='errors.fieldRequiredText'/>),

        directorName: yup
            .string()
            .typeError(<FormattedMessage id='errors.fieldRequiredText'/>)
            .required(<FormattedMessage id='errors.fieldRequiredText'/>),

    });

    function renderStep(formikProps) {
        formikPropsRef.current = formikProps;
        switch (formStep) {
            case 0:
                return (
                    <SemestralReportFormStep1
                        formStep={1}
                        numberOfSteps={tabsIds.length}
                        errors={formikProps.errors}
                        formikValues={formikProps.values}
                        handleChange={formikProps.handleChange}
                        handleNextStep={handleNextStep}
                        entityGetList={[paymentRequest.technicalTeamManager]}
                        goBackPath={''}
                        readMode={true}
                    />
                );
            case 1:
                return (
                    <SemestralReportFormStep2
                        formStep={2}
                        numberOfSteps={tabsIds.length}
                        errors={formikProps.errors}
                        formikValues={formikProps.values}
                        handleChange={formikProps.handleChange}
                        handlePreviousStep={handlePreviousStep}
                        handleNextStep={handleNextStep}
                        readMode={true}
                        hasCandidatureProcessNumber
                    />
                );
            case 2:
                return (
                    <formSteps.SemestralPaymentRequestFormStep3
                        formStep={3}
                        numberOfSteps={tabsIds.length}
                        errors={formikProps.errors}
                        formikValues={formikProps.values}
                        handleChange={formikProps.handleChange}
                        handlePreviousStep={handlePreviousStep}
                        handleNextStep={handleNextStep}
                        setFieldValue={formikProps.setFieldValue}
                        readMode={true}
                    />
                );
            case 3:
                return (
                    <formSteps.SemestralPaymentRequestFormStep4
                        formStep={4}
                        numberOfSteps={tabsIds.length}
                        errors={formikProps.errors}
                        formikValues={formikProps.values}
                        handleChange={formikProps.handleChange}
                        handlePreviousStep={handlePreviousStep}
                        handleNextStep={handleNextStep}
                        setFieldValue={formikProps.setFieldValue}
                        readMode={entityReadMode}
                    />
                );
            case 4:
                return (
                    <formSteps.SemestralPaymentRequestFormStep5
                        formStep={5}
                        numberOfSteps={tabsIds.length}
                        consultingRequestExternalId={externalId}
                        errors={formikProps.errors}
                        setError={setError}
                        uploadDocument={uploadDocument}
                        deleteDocument={deleteDocument}
                        formikValues={formikProps.values}
                        handleChange={formikProps.handleChange}
                        setFieldValue={formikProps.setFieldValue}
                        handlePreviousStep={handlePreviousStep}
                        formText={getFormText()}
                        documents={documents}
                        setDocuments={setDocuments}
                        readMode={IEFPReadMode}
                    />
                );
            default:
                console.log('Something went wrong rendering the form step');
        }
    }


    return (
        <SubTemplate
            //TODO(jaime)🧔🏻: Which version should we use? Code is duplicated in a bunch of places.
            hasBackButton={true}
            titleId={getIntlPrefix() + '.title'}
            subTitleId={getIntlPrefix() + '.subTitle'}
            centerContent={true}
            stateBadgeProps={StateAndDateBadge.propsFromProcess(formikInitialValues)}

        >
            <Container>
                <DecisionDialog
                    show = {showSubmitModal}
                    handleConfirm={handleSubmit}
                    handleClose = {() => {setShowSubmitModal(false)}}
                    titleId = {getIntlPrefix() + '.submitModal.title'}
                    bodyId = {getIntlPrefix() + '.submitModal.body'}
                    needReason = {false}
                />
                <DecisionDialog
                    show = {showAccept}
                    handleConfirm={submitAccept}
                    handleClose = {() => {setShowAccept(false)}}
                    titleId = 'decisionDialog.paymentRequest.accept.title'
                    bodyId = 'decisionDialog.paymentRequest.accept.body'
                    needReason = {false}
                />
                <DecisionDialog
                    show = {showReject}
                    handleConfirm={submitReject}
                    handleClose = {() => {setShowReject(false)}}
                    titleId = 'decisionDialog.paymentRequest.reject.title'
                    bodyId = 'decisionDialog.paymentRequest.reject.body'
                    needReason = {false}
                />

                <Row className='mb-5'>
                    <Col md='2'>
                        <FormSideNavBar
                            tabsIdsArray={tabsIds}
                            formStep={formStep}
                            setFormStep={setFormStep}
                            navErrors={navErrors}
                            setNavErrors={setNavErrors}
                        />
                    </Col>
                    <Col md='8'>
                        <AlertError error={error}/>
                        {getSaveToastInformation(
                            intl.formatMessage({id: 'finaReport.saveToastInformation'})
                        )}
                        <Formik
                            initialValues={formikInitialValues}
                            validateOnBlur={false}
                            validateOnChange={false}
                            validationSchema={isIEFP ? IEFPvalidationSchema : validationSchema}
                            onSubmit={handleSubmit}
                        >
                            {(formikProps) => (
                                <Form onSubmit={formikProps.handleSubmit}>
                                    {renderStep(formikProps)}
                                </Form>
                            )}
                        </Formik>
                    </Col>
                    <Col md='2'>

                        <div>
                            { ((isEntity && paymentRequest.processState === ProcessState.DRAFT) || (isIEFP && paymentRequest.processState === ProcessState.SUBMITTED)) && <>
                                <button
                                    className='btn btn-outline-primary d-flex align-items-center justify-content-center mb-4 w-100'
                                    type='button'
                                    onClick={() => handleSave()}
                                >
                                    <FaRegSave/>
                                    <FormattedMessage id='all.save'/>
                                </button>
                            </>}
                            {isEntity && paymentRequest.processState === ProcessState.DRAFT &&
                                <>
                                    <button
                                        className='btn btn-primary d-flex align-items-center justify-content-center w-100'
                                        type='button'
                                        onClick={() => validateBeforeSubmit(setShowSubmitModal)}
                                    >
                                        <FaRegCheckCircle />
                                        <FormattedMessage id='all.submitButtonText' />
                                    </button>
                                </>
                            }
                            {isIEFP && paymentRequest.processState === ProcessState.SUBMITTED &&
                                <>

                                    <button
                                        className='btn btn-outline-primary d-flex align-items-center justify-content-center mb-4 w-100'
                                        type='button'
                                        onClick={() => acceptDecision()}
                                    >
                                        <FormattedMessage id='all.accept'/>
                                    </button>
                                    <button
                                        className='btn btn-primary d-flex align-items-center justify-content-center w-100'
                                        type='button'
                                        onClick={() =>  rejectDecision()}
                                    >
                                        <FormattedMessage id='all.reject'/>
                                    </button>
                                </>
                            }

                            {paymentRequest.processState === ProcessState.APPROVED &&
                                <PayableProcessButton payableExternalId={paymentRequest.externalId} />
                            }
                            <Row className={'mt-3'}>
                                <Col>
                                    <Link to={'/relatorioSemestral/' + paymentRequest.originatingFormExternalId}>
                                        <FormattedMessage id={'paymentRequest.semestralReportForm.button'}/>
                                    </Link>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </Container>
        </SubTemplate>
    );
}
