import React, { useEffect, useState } from 'react';
import { Accordion, Col, Form, Row } from 'react-bootstrap';
import { FaCheckCircle } from 'react-icons/fa';
import { FormattedMessage } from 'react-intl';
import { PromoterType } from '../../models/PromoterType';
import { TranslateBoolean } from '../../utils/TranslateBoolean';
import { maxFixed } from '../../utils/CurrencyUtils';
import { AuthenticatedDownloadLink } from '../document/AuthenticatedDownloadLink';
import { DocumentType } from '../../models/DocumentType';
import { EmptyResults } from '../general/EmptyResults';

export function ResumeTab({ candidatureChange, candidatureChangeDocuments }) {
	const requestDocuments = candidatureChangeDocuments.filter(
		(d) => d.documentType === DocumentType.CANDIDATURE_CHANGE_ANNEX
	);

	const documentTerms = candidatureChangeDocuments.filter(d=> d.documentType==='CANDIDATURE_CHANGE_TERM_ACCEPT_SIGNED');

	return (
		<>
			<div className='py-3 mb-4'>
				<Row>
					<Col md='6'>
						<label>
							<FormattedMessage id='consultingRequest.candidatureProcessId' />
						</label>
						<p>{candidatureChange.candidatureProcessNumber}</p>
					</Col>
					<Col md='6'>
						<label>
							<FormattedMessage id='all.changeCode' />
						</label>
						<p>{candidatureChange.externalId}</p>
					</Col>
				</Row>
				<Row>
					<Col md='6'>
						<label>
							<FormattedMessage id='messages.stateText' />
						</label>
						<p>
							<FormattedMessage
								id={`candidatureChangeState.${candidatureChange.state}`}
							/>
						</p>
					</Col>

					<Col md='6'>
						<label>
							<FormattedMessage id='candidatureTabs.changeDate' />
						</label>
						<p>{candidatureChange.changeDate}</p>
					</Col>
				</Row>
				<Row>
					<Col>
						<label>
							<FormattedMessage id='candidatureChangeRequest.step1.description' />
						</label>
						<p>{candidatureChange.description}</p>
					</Col>
				</Row>

				<Row>
					<Col>
						<label>
							<FormattedMessage id='candidatureChangeRequest.step1.financialChanges' />
						</label>
						<p>
							<FormattedMessage
								id={`all.${candidatureChange.financialChanges ? 'yes' : 'no'}`}
							/>
						</p>
					</Col>
				</Row>


				{candidatureChange.term && (
					<div className='border-top border-1 mt-2 pt-3'>
						<p >
							<strong className='mt-3'><FormattedMessage id={'candidatureForm.resumeTab.termAcceptanceRegister.title'}/></strong>
						</p>
						<Row>
							<Col md='4'>
								<label>
									<FormattedMessage id='termAcceptanceRegister.isAccordingTo'/>
								</label>
								<p>  {candidatureChange.term.isAccordingTo=== true ? <FormattedMessage id='all.yes'/> : <FormattedMessage id='all.no'/>}</p>
							</Col>
							<Col md='4'>
								<label>
									<FormattedMessage id='termAcceptanceRegister.sendDate'/>
								</label>
								<p>  {candidatureChange.term.sendDate}</p>
							</Col>
							<Col md='4'>
								{candidatureChange.term.receptionDate && (
									<>
										<label>
											<FormattedMessage id='termAcceptanceRegister.receptionDate'/>
										</label>
										<p>  {candidatureChange.term.receptionDate}</p>
									</>
								)
								}

							</Col>
						</Row>
						{documentTerms?.[0]?.documentPath &&
						<Row className={'mb-3'}>
							<Col md={12}>
								<div>
									<label>
										<FormattedMessage id='candidatureForm.resumeTab.termAcceptanceRegister.document'/>
									</label>
									<p>
										<AuthenticatedDownloadLink url={documentTerms[0].documentPath} filename={documentTerms[0].name}>
											<small>{documentTerms[0].name}</small>
										</AuthenticatedDownloadLink>
									</p>
								</div>


							</Col>
						</Row>
						}

					</div>
				)

				}



			</div>
		</>
	);
}
