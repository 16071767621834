
import React, {useEffect, useState} from 'react';
import {AddPaymentRequest} from "./AddPaymentRequest";
import {PaymentRequestType} from "./PaymentRequestType";
import {
    getPaymentRequestByProcessExternalId,
    listDocumentsByProcessExternalId
} from "../../rest/paymentRequest";
import {useNavigate, useParams} from "react-router-dom";
import Loading from "../general/Loading";


export function ProcessPaymentRequest() {
    const {externalId} = useParams();
    const [paymentRequest, setPaymentRequest] = useState();
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    async function fetchData() {
        try {
            const [{data: preq}] = await Promise.all([
                getPaymentRequestByProcessExternalId(externalId)
            ]);
            setPaymentRequest(preq);
        }  catch (error) {
            console.log('getPaymentRequest', error);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        fetchData();
    }, []);

    if (loading) return <Loading />;

    return (
        <AddPaymentRequest
            paymentRequestType={paymentRequest.type}
            getPaymentRequest={(e) => ({data: paymentRequest})}
            listPaymentRequestDocuments={listDocumentsByProcessExternalId}
        />
    );
}