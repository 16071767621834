import React, { useState } from 'react';
import { Accordion, Col, Form, Row, Table } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import {currencyFormat, maxFixed} from '../../utils/CurrencyUtils';
import { DateTimeInputField } from '../bootstrap/DateTimeInputField';
import DecimalInputField from '../bootstrap/DecimalInputField';
import { NumberInputField } from '../bootstrap/NumberInputField';
import { TextAreaInputField } from '../bootstrap/TextAreaInputField';
import { TextInputField } from '../bootstrap/TextInputField';

export function InvestmentConfirmationStep({
	formStep,
	numberOfSteps,
	errors,
	handleChange,
	formikValues,
	handlePreviousStep,
	handleNextStep,
											   entityGetList,
	readMode
}) {
	const intl = useIntl();

	const tableHeadersIds = [
		'investment',
		'forecast',
		'real',
		'deviationValue',
		'deviationPercent',
	];

	const tableRowTitleIds = [
		'investmentProperties',
		'tangibleActives',
		'intangibleActives',
		'Several',
		'PettyCash',
	];

	const tableSubTitleRowIds = {
		0: ['InvestmentPropertiesNaturalResources', 'InvestmentPropertiesBuildings', 'InvestmentPropertiesOthers'],
		1: [
			'TangibleNaturalResources',
			'TangibleBuildings',
			'TangibleBasicEquipment',
			'TangibleTransportEquipment',
			'TangibleAdministrativeEquipment',
			'TangibleBiologicEquipment',
			'TangibleOthers',
		],
		2: [
			'IntangibleGoodwill',
			'IntangibleDevelopmentProjects',
			'IntangibleComputerPrograms',
			'IntangibleIntellectualProperty',
			'IntangibleOthers',
		],
	};


	function getGetName() {
		let getInfo = entityGetList.filter(
			(get) =>
				get.externalId ===
				formikValues.identificationEa.technicalTeamManagerExternalId
		)[0];

		return getInfo!==undefined ? getInfo.name :'';
	}



	function calculateDeviationAbsoluteValue(hasSubRows, index, subRow) {





		if (hasSubRows) {
			return (
				maxFixed(columnTotalOfRowTitle(index, 'real') -
					columnTotalOfRowTitle(index, 'forecast') )|| 0
			);
		}

		if (subRow) {

			return (
				maxFixed(parseFloat(formikValues['real'+subRow]) -  parseFloat(formikValues['forecast'+subRow]) ) || 0
			);
		}

		return (
				      maxFixed(parseFloat(formikValues['real'+tableRowTitleIds[index]]) -  parseFloat(formikValues['forecast'+tableRowTitleIds[index]]) ) || 0
		);

	}

	function calculateDeviationPercentValue(hasSubRows, index, subRow) {

		let divRes;
		if (hasSubRows) {
			divRes =
				columnTotalOfRowTitle(index, 'real') /
					columnTotalOfRowTitle(index, 'forecast') || 0;
		} else if (subRow) {
			divRes =
				parseFloat(formikValues['real'+subRow]) /  parseFloat(formikValues['forecast'+subRow])  || 0;
		} else {
			divRes= (parseFloat(formikValues['real'+tableRowTitleIds[index]]) /  parseFloat(formikValues['forecast'+tableRowTitleIds[index]])) || 0;

		}



		if (divRes === Infinity || divRes === 0) {
			return 0;
		}
		if (divRes > 1) {
			return maxFixed(  (divRes - 1) * 100);
		}
		return maxFixed( (1 - divRes) * 100 * -1);
	}

	function columnTotalOfRowTitle(index, colHeader) {
		let sum = 0;

		tableSubTitleRowIds[index].forEach((value) => {
			sum += parseFloat(formikValues[colHeader+value]) || 0;
		})
		return sum;
	}

	function calculateTotal(column) {
		let total = 0;
		if (column === tableHeadersIds[3]) {
			return calculateTotal('real') - calculateTotal('forecast');
		} else if (column === tableHeadersIds[4]) {
			let divRes = calculateTotal('real') / calculateTotal('forecast');
			if (isNaN(divRes) || divRes === Infinity) {
				return 0;
			}
			if (divRes > 1) {
				return (divRes - 1) * 100;
			}
			return (1 - divRes) * 100 * -1;
		} else {

			[0,1,2].forEach(index=>{
				total+= columnTotalOfRowTitle(index,column);
			})
			total +=  parseFloat(formikValues[column+"Several"]);
			total += parseFloat(formikValues[column+"PettyCash"]);


		}
		return total;
	}

	return (
		<div>
			<h4 className='text-primary mb-0'>
				<FormattedMessage id='investmentConfirmationForm.sideNavBar.investmentConfirmationStep' />
			</h4>
			<small className='text-secondary text-uppercase'>
				{intl.formatMessage(
					{ id: 'all.step' },
					{ step: formStep, numberOfSteps: numberOfSteps }
				)}
			</small>
			<fieldset disabled={readMode==true}>
			<Row className='mt-4'>
				<Col>
					<Table className='d-table ' striped responsive='md'>
						<thead>
							<tr>
								{tableHeadersIds.map((header, index) => (
									<th
										key={header}
										className={
											index > 0 && index < 4
												? 'w-20'
												: index === 4
												? 'w-15'
												: ''
										}
									>
										<FormattedMessage
											id={`investmentConfirmationForm.investmentConfirmationStep.table.${header}`}
										/>
									</th>
								))}
							</tr>
						</thead>
						<tbody>
							{tableRowTitleIds.map((rowtitleId, index) => (
								<React.Fragment key={rowtitleId}>
									<tr>
										<th>
											<strong>
												<FormattedMessage
													id={`investmentConfirmationForm.investmentConfirmationStep.table.${rowtitleId}`}
												/>
											</strong>
										</th>
										{tableHeadersIds
											.slice(1)
											.map((columnHeaderId, colIndex) => (
												<th key={columnHeaderId}>
													<strong>
														{colIndex === 2 ? (
															currencyFormat(
																calculateDeviationAbsoluteValue(
																	index > 2 ? false : true,
																	index
																)
															)
														) : colIndex === 3 ? (

																calculateDeviationPercentValue(
																	index > 2 ? false : true,
																	index
																)
															 + ' %'
														) : index > 2 ? (

															<>
																{colIndex===0 ? (
																	<>
																		{currencyFormat(parseFloat(formikValues[columnHeaderId+rowtitleId]))}
																	</>
																) : (
																	<DecimalInputField
																		suffixLabel='€'
																		handleChange={handleChange}
																		value={formikValues[columnHeaderId+rowtitleId]}
																		name={`${columnHeaderId+rowtitleId}`}
																	/>)}
															</>





														) : (
															currencyFormat(
																columnTotalOfRowTitle(
																	index,
																	columnHeaderId
																)
															)
														)}
													</strong>
												</th>
											))}
									</tr>
									{index < 3 &&
										tableSubTitleRowIds[index].map((subRowId) => (
											<tr key={subRowId}>
												<td>
													<FormattedMessage
														id={`investmentConfirmationForm.investmentConfirmationStep.table.${subRowId}`}
													/>
												</td>
												{tableHeadersIds
													.slice(1)
													.map((columnHeaderId, colIndex) => (
														<td key={columnHeaderId}>
															{colIndex === 2 ? (
																currencyFormat(
																	calculateDeviationAbsoluteValue(
																		false,
																		rowtitleId,
																		subRowId
																	)
																)
															) : colIndex === 3 ? (

																	calculateDeviationPercentValue(
																		false,
																		rowtitleId,
																		subRowId
																	)
																+ ' %'
															) : (

																<>
																{colIndex===0 ? (
																	<>
																		{currencyFormat(parseFloat(formikValues[columnHeaderId+subRowId]))}
																		</>
																) : (
																	<DecimalInputField
																		suffixLabel='€'
																		handleChange={handleChange}
																		value={
																			formikValues[columnHeaderId+subRowId]
																		}
																		name={`${columnHeaderId}${subRowId}`}
																	/>)}
																</>




															)}
														</td>
													))}
											</tr>
										))}
								</React.Fragment>
							))}
						</tbody>
						<tfoot>
							<tr>
								<td>
									<strong>
										<FormattedMessage id='investmentConfirmationForm.investmentConfirmationStep.table.total' />
									</strong>
								</td>
								{tableHeadersIds.slice(1).map((columnHeaderId, colIndex) => (
									<td key={columnHeaderId}>
										<strong>
											{colIndex ===3 ?  maxFixed(calculateTotal(columnHeaderId)) + " %" :   currencyFormat(maxFixed(calculateTotal(columnHeaderId)))}
										</strong>
									</td>
								))}
							</tr>
						</tfoot>
					</Table>
				</Col>
			</Row>

			<Row>
				<Col>

					<TextAreaInputField
						labelId={'investmentConfirmationForm.observations'}
						name={'observations'}
						value={formikValues.observations}
						handleChange={handleChange}
						maxLength={1000}
						isInvalid={errors.observations}
						errorMessage={errors.observations}
					/>
				</Col>
			</Row>

			<Row>
				<Col>
					<TextInputField labelId='investmentConfirmationForm.technicalTeamManager' value={getGetName()}  disabled={true}/>
				</Col>
			</Row>
			</fieldset>
			<div className='form-actions mt-5'>
				<button
					className='btn btn-link'
					type='button'
					onClick={() => handlePreviousStep()}
				>
					<FormattedMessage id='all.back' />
				</button>
			</div>
		</div>
	);
}
