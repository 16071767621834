import React from 'react';
import { Col, Row, Table } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { maxFixed } from '../../utils/CurrencyUtils';
import DecimalInputField from '../bootstrap/DecimalInputField';

export function CommercialActivitiesForm({
	errors,
	handleChange,
	formikValues,
	readMode,
}) {
	const intl = useIntl();

	const salesStructurePreffix =
		'strategicDiagnosis.businessActivity.salesStructure';

	const salesStructureIds = ['local', 'regional', 'national', 'otherSalesType'];

	const clientsTypePreffix = 'strategicDiagnosis.businessActivity.clientsType';

	const clientsTypeIds = [
		'warehouses',
		'retailers',
		'endConsumer',
		'industry',
		'otherClients',
	];

	const salesMeanPreffix = 'strategicDiagnosis.businessActivity.meansSale';

	const salesMeansIds = [
		'standardEstablishedMeans',
		'salesPersons',
		'byCatalog',
		'fairs',
		'atFacilities',
		'commissioners',
		'internet',
		'otherSalesMeans',
	];

	const nIndex = 0;
	const nIndexPlus1 = 1;
	const nIndexPlus2 = 2;
	const predictionIndex = 3;

	function calculateTotal(rowsToSum, index) {
		let sum = 0;
		rowsToSum.forEach((row) => {
			sum += (parseFloat(formikValues.commercialActivities[index][row]) || 0);
		});
		return sum;
	}

	function generateTableRows(rowsIdsPreffix, rowsIds) {
		return (
			<React.Fragment key={rowsIdsPreffix}>
				<tr className='bg-light'>
					<th className='w-40'>
						<FormattedMessage id={rowsIdsPreffix} />
					</th>
					<th>
						{maxFixed(calculateTotal(rowsIds, predictionIndex)) + ' %'}
					</th>
					<th>{maxFixed(calculateTotal(rowsIds, nIndex)) + ' %'}</th>
					<th>
						{maxFixed(calculateTotal(rowsIds, nIndexPlus1)) + ' %'}
					</th>
					<th>
						{maxFixed(calculateTotal(rowsIds, nIndexPlus2)) + ' %'}
					</th>
				</tr>
				{rowsIds.map((rowId) => (
					<tr key={rowId}>
						<td>
							<FormattedMessage id={`${rowsIdsPreffix}.${rowId}`} />
						</td>
						<td>
							<DecimalInputField
								name={`commercialActivities[${predictionIndex}][${rowId}]`}
								value={
									formikValues.commercialActivities[predictionIndex][rowId]
								}
								handleChange={handleChange}
								isInvalid={
									errors.commercialActivities?.[predictionIndex]?.[rowId]
								}
								errorMessage={
									errors.commercialActivities?.[predictionIndex]?.[rowId]
								}
								suffixLabel='%'
							/>
						</td>
						<td>
							<DecimalInputField
								name={`commercialActivities[${nIndex}][${rowId}]`}
								value={formikValues.commercialActivities[nIndex][rowId]}
								handleChange={handleChange}
								isInvalid={errors.commercialActivities?.[nIndex]?.[rowId]}
								errorMessage={errors.commercialActivities?.[nIndex]?.[rowId]}
								suffixLabel='%'
							/>
						</td>
						<td>
							<DecimalInputField
								name={`commercialActivities[${nIndexPlus1}][${rowId}]`}
								value={formikValues.commercialActivities[nIndexPlus1][rowId]}
								handleChange={handleChange}
								isInvalid={errors.commercialActivities?.[nIndexPlus1]?.[rowId]}
								errorMessage={
									errors.commercialActivities?.[nIndexPlus1]?.[rowId]
								}
								suffixLabel='%'
							/>
						</td>
						<td>
							<DecimalInputField
								name={`commercialActivities[${nIndexPlus2}][${rowId}]`}
								value={formikValues.commercialActivities[nIndexPlus2][rowId]}
								handleChange={handleChange}
								isInvalid={errors.commercialActivities?.[nIndexPlus2]?.[rowId]}
								errorMessage={
									errors.commercialActivities?.[nIndexPlus2]?.[rowId]
								}
								suffixLabel='%'
							/>
						</td>
					</tr>
				))}
			</React.Fragment>
		);
	}

	function generateTableBody() {
		let elemsToRender = [];
		elemsToRender.push(
			generateTableRows(salesStructurePreffix, salesStructureIds)
		);
		elemsToRender.push(generateTableRows(clientsTypePreffix, clientsTypeIds));
		elemsToRender.push(generateTableRows(salesMeanPreffix, salesMeansIds));
		return elemsToRender;
	}

	return (
		<fieldset disabled={readMode==true}>
			<Row>
				<Col>
					<Table className='mt-4'>
						<thead>
							<tr>
								<th className='w-40'></th>
								<th>
									<FormattedMessage id='strategicDiagnosis.businessActivity.forecast' />
								</th>
								<th>
									<FormattedMessage id='strategicDiagnosis.businessActivity.nyear' />
								</th>
								<th>
									<FormattedMessage id='strategicDiagnosis.businessActivity.n1year' />
								</th>
								<th>
									<FormattedMessage id='strategicDiagnosis.businessActivity.n2year' />
								</th>
							</tr>
						</thead>
						<tbody>{generateTableBody()}</tbody>
					</Table>
				</Col>
			</Row>
		</fieldset>
	);
}
