import React, { useEffect } from 'react';
import {Col, Form, Row, Table} from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { CandidatureFormUtils } from '../../utils/CandidatureFormUtils';
import { EconomicViabilityUtils } from '../../utils/EconomicViabilityUtils';
import DecimalInputField from '../bootstrap/DecimalInputField';
import { TextAreaInputField } from '../bootstrap/TextAreaInputField';
import {CandidatureState} from "../../models/CandidatureState";
import ToolTip from "../bootstrap/ToolTip";
import {formatValue} from "react-currency-input-field";
import {ConfigObject} from "../../config";
import {currencyFormat, maxFixed} from "../../utils/CurrencyUtils";
import { FaInfoCircle } from 'react-icons/fa';

export function CandidatureFormStep6Test({
  values,
  setFieldValue,
  handleChange,
  handleSave,
  handleGoBack,
  numberOfSteps,
  errors,
  isValidate
}) {
  const intl = useIntl();

  useEffect(() => {}, [values.financialInvestmentDetails]);






  const CURRENT_YEAR = 0;
  const NEXT_YEAR = 1;

  const KEYS = [
    'ownCapital',
    'ownSocialCapital',
    'ownCapitalUnemploymentBenefits',
    'ownCapitalOthers',
    'foreignCapital',
    'foreignCapitalBankLoans',
    'foreignCapitalPartnerLoans',
    'foreignCapitalProvidersLoans',
    'foreignCapitalOthers',
    'iefpAskedAmount',
    'investmentSupport',
    'workStationsCreationBenefits',
    'globalTotal'
  ];

  const BOLD_KEYS = ['ownCapital', 'foreignCapital', 'iefpAskedAmount', 'globalTotal'];

  function calculateFinancialInvestmentDetailTotals() {
    values.financialInvestmentDetails.forEach((fid) => {
      EconomicViabilityUtils.calculateFinancialInvestmentDetailTotals(fid);
    });
  }

  function getValueByYearAndFieldName(year, fieldName) {
    if(fieldName ==='ownCapitalUnemploymentBenefits' && (!CandidatureFormUtils.canAskUnemploymentBenefits(values.promotersMetaData ) && values.state === CandidatureState.DRAFT) ){
      return 0;
    }
      return getFinancialDetailByYear(year)[fieldName];
  }

  function handleFinancialInvestmentChange(e, year, fieldName) {
    getFinancialDetailByYear(year)[fieldName] = e.target.value;

    calculateFinancialInvestmentDetailTotals();

    EconomicViabilityUtils.calculateFinancialInvestmentDetailTotalFields(
      getFinancialDetailByYear(CURRENT_YEAR),
      getFinancialDetailByYear(NEXT_YEAR),
      getFinancialDetailByYear('-1')
    );

    setFieldValue('financialInvestmentDetails', [...values.financialInvestmentDetails]);
  }



  function handleChangeValidation() {
      setFieldValue('unemploymentBenefitsValidation',(values.unemploymentBenefitsValidation === false || values.unemploymentBenefitsValidation ===undefined))
  }

  function getFinancialDetailByYear(year) {
    const investmentDetail = values.financialInvestmentDetails.filter((fid) => {
      if (year === '-1') {
        return fid.financialInvestmentType === 'TOTAL';
      } else {
        return fid.year === year;
      }
    });
    return investmentDetail[0];
  }

  function getTableRowsByKey(key) {
    return (
        <>
      <tr key={key}>
        <td className={`w-40 ${BOLD_KEYS.includes(key) && 'table-item-bold'}`}>
          <FormattedMessage id={`economicViabilityFormStep4.${key}`} />
          {
            (key === 'ownCapitalUnemploymentBenefits' ||  key==='workStationsCreationBenefits') &&(
                <ToolTip message={   intl.formatMessage(
                    { id: 'step4.tooltip.'+key }
                )  }/>
            )
          }
          {
            (key === 'ownCapital') &&(
                <ToolTip message={   intl.formatMessage(
                    { id: 'owCapitals.tooltip' }
                )  }/>
            )
          }



        </td>
        <td className='w-20'>
          <DecimalInputField
            name={`financialInvestmentDetails.${key}`}
            handleChange={(e) => handleFinancialInvestmentChange(e, CURRENT_YEAR, key)}
            value={getValueByYearAndFieldName(CURRENT_YEAR, key)}
            suffixLabel='€'
            disabled={BOLD_KEYS.includes(key)   || (key ==='ownCapitalUnemploymentBenefits' && (!CandidatureFormUtils.canAskUnemploymentBenefits(values.promotersMetaData ) && values.state === CandidatureState.DRAFT) )   }

          />
        </td>
        <td className='w-20'>
          <DecimalInputField
            name={`financialInvestmentDetails.${key}`}
            handleChange={(e) => handleFinancialInvestmentChange(e, NEXT_YEAR, key)}
            value={getValueByYearAndFieldName(NEXT_YEAR, key)}
            suffixLabel='€'
            disabled={BOLD_KEYS.includes(key)   || (key ==='ownCapitalUnemploymentBenefits' && (!CandidatureFormUtils.canAskUnemploymentBenefits(values.promotersMetaData ) && values.state === CandidatureState.DRAFT) )   }
          />
        </td>
        <td className='text-end'>
          <span>{currencyFormat(maxFixed( getValueByYearAndFieldName('-1', key)))} </span>
        </td>
      </tr>
          {
            (key === 'ownCapitalUnemploymentBenefits') &&(
                <td colSpan={4}>

                  <div className='d-flex align-items-center mt-1'>
                    <Form.Check
                        type='checkbox'
                        checked={values.unemploymentBenefitsValidation ? 1 : 0}
                        onChange={() => handleChangeValidation()}
                    />

                    <label>
                      <FormattedMessage id='economicViabilityFormStep4.unemploymentBenefitsValidation' />
                    </label>
                  </div>

                </td>
            )
          }
        </>
    );
  }

  function handleNextStep() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    handleSave(true);
  }

  return (
    <div>
      <h4 className='text-primary mb-0'>
        {isValidate ? (
          <FormattedMessage id='economicViabilityForm.financialInvestmentTabApproved' />
        ) : (
          <FormattedMessage id='economicViabilityForm.financialInvestmentTab' />
        )}
      </h4>
      <small className='text-secondary text-uppercase'>
        {intl.formatMessage(
          { id: 'all.step' },
          { step: values.step, numberOfSteps: numberOfSteps }
        )}
      </small>

      <p className='mt-3 mb-0 text-secondary infoText'>
        <FaInfoCircle size={120} /> {intl.formatMessage(
          { id: 'step6.infoText' },
          { value:   formatValue({
              value: ConfigObject.get().maxFinancedValue,
              groupSeparator: ' ',
              decimalSeparator: '.',
              suffix: '€',
            })     }
      )}
      </p>

      <Table className='mt-4'>
        <thead>
          <tr>
            <th className='w-40'>
              <FormattedMessage id='candidatureTabs.investment' />
            </th>
            <th>{CandidatureFormUtils.getYearLabel(values, 0)}</th>
            <th>{CandidatureFormUtils.getYearLabel(values, 1)}</th>
            <th className='text-end'>
              <FormattedMessage id='finalReportForm.step7.table.total' />
            </th>
          </tr>
        </thead>
        <tbody>{KEYS.map((key) => getTableRowsByKey(key))}</tbody>
      </Table>

      {!isValidate && (
        <>
          <Row>
            <Col md='12'>
              <TextAreaInputField
                labelId={'candidatureForm.financialInvestmentReasoning'}
                name={'candidatureDetails.financialInvestmentReasoning'}
                value={values.candidatureDetails.financialInvestmentReasoning}
                handleChange={handleChange}
                placeholder={intl.formatMessage({ id: 'all.maxLength.placeholder' })}
                maxLength={10000}
                isInvalid={errors.candidatureDetails?.financialInvestmentReasoning}
                errorMessage={errors.candidatureDetails?.financialInvestmentReasoning}
              />
            </Col>
          </Row>

          <div className='form-actions mt-5'>
            <button
              className='btn btn-link'
              type='button'
              onClick={() => handleGoBack(values, setFieldValue)}>
              <FormattedMessage id='all.back' />
            </button>

            <button className='btn btn-primary' type='button' onClick={() => handleNextStep()}>
              <FormattedMessage id='all.nextStep' />
            </button>
          </div>
        </>
      )}
    </div>
  );
}
