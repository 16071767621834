import React, { useState } from 'react';
import { Form, Modal } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { TextAreaInputField } from '../bootstrap/TextAreaInputField';
import { TextInputField } from '../bootstrap/TextInputField';
import {MultipleUploadArea} from "../MultipleUploadArea";
import {SingleUploadArea} from "../SingleUploadArea";
import {SyncMultipleUploadArea} from "../SyncMultipleUploadArea";
import {Arrays} from "../../utils/Arrays";
import {DocumentType} from "../../models/DocumentType";

export function ReplyMessageDialog({ message, onSendMessage, onSendMessageWithFiles, handleClose }) {
  const [body, setBody] = useState('');
  const [title, setTitle] = useState('');
  const [documents, setDocuments] = useState([]);

  const handleSend = () => {
    if(Arrays.isEmpty(documents)) {
      onSendMessage({ body, title }, message);
    } else {
      onSendMessageWithFiles({ body, title, documents }, message)
    }
    setBody('');
  };

  return (
    <Modal show={true} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>
          <FormattedMessage id='reply.dialog.title' />
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Form onSubmit={(e) => e.preventDefault}>
          <TextInputField
            labelId='reply.title'
            value={title}
            handleChange={(e) => setTitle(e.target.value)}
          />
          <TextAreaInputField
            labelId='clarificationRequestForm.bodyText'
            value={body}
            handleChange={(e) => setBody(e.target.value)}
          />

          <SyncMultipleUploadArea
            name='files'
            documents={documents}
            setDocuments={setDocuments}
            documentType={DocumentType.MESSAGE_ATTACHMENT}
          />

          <div className='form-actions'>
            <div className='mr-auto'>
              <button
                type='button'
                className='btn btn-link'
                onClick={handleClose}
              >
                <FormattedMessage id='all.backButtonText' />
              </button>
            </div>
            <div className='ml-auto'>
              <button
                className='btn btn-outline-primary'
                type='button'
                onClick={() => handleSend(body)}
                disabled={body.length < 1 || title.length < 1}
              >
                <FormattedMessage id='all.send' />
              </button>
            </div>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
}
