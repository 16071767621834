import {IrregularityList} from "./irregularities/IrregularityList";
import {Button, Col, Row} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {getIrregularities, getIrregularityCauseToRegulationMap} from "../../../rest/irregularities";
import Loading from "../../general/Loading";
import {FormattedMessage} from "react-intl";
import {AddIrregularityModal} from "./irregularities/AddIrregularityModal";
import {hasPermission, isIEFPUser} from "../../../authentication/authenticationHelper";
import {CandidatureState} from "../../../models/CandidatureState";
import {useGlobalContext} from "../../../context";

export function IrregularitiesTab({candidature}) {
    const [irregularityCauseToRegulationMap, setIrregularityCauseToRegulationMap,] = useState(null);
    const [irregularities, setIrregularities,] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [addModalShow, setAddModalShow]  = useState(false);
    const [modalSubmitted, setModalSubmitted] = useState(false);
    const { accessInfo } = useGlobalContext();

    const fetchData = async () => {
        try {
            setModalSubmitted(false);
            setLoading(true);
            const [{ data: irregularities }, { data: causeMap }] = await Promise.all([
                getIrregularities(candidature.externalId),
                getIrregularityCauseToRegulationMap(' ')
            ]);
            setIrregularities(irregularities);
            setIrregularityCauseToRegulationMap(causeMap);
            setLoading(false);
        } catch (error) {
            setError(error);
        }
    };

    useEffect(() => {
        fetchData();
    }, [modalSubmitted]);

    if (loading) {
        return <Loading />;
    }





    return (
        <>
            <Row>

                <Col className='justify-content-end d-flex'>
                    {isIEFPUser() && candidature.state === CandidatureState.FULLY_APPROVED &&
                    hasPermission('MANAGE_IRREGULARITY', accessInfo) && (
                    <Button variant='outline-primary' onClick={ () => setAddModalShow(true)}>
                        <FormattedMessage id='candidatureTabs.oversight.tabs.irregularities.add' />
                    </Button>
                    )}
                </Col>
            </Row>
            <Row>
                <Col>
                    <IrregularityList irregularities={irregularities} irregularityCauseToRegulationMap={irregularityCauseToRegulationMap}/>
                </Col>
            </Row>
            <Row>
                <Col>
                    <AddIrregularityModal
                        show={addModalShow}
                        setShow={setAddModalShow}
                        candidature={candidature}
                        irregularityCauseToRegulationMap={irregularityCauseToRegulationMap}
                        onSubmitCallback={() => setModalSubmitted(true)}
                    />
                </Col>
            </Row>
        </>
    );
}