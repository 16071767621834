import {Link, useParams} from "react-router-dom";
import {SubTemplate} from "../../../general/SubTemplate";
import {Accordion, Button, Col, Container, Row} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import Loading from "../../../general/Loading";
import {AlertError} from "../../../bootstrap/AlertError";
import {FormattedMessage} from "react-intl";
import {hasPermission, isPromoterUser} from "../../../../authentication/authenticationHelper";
import {useGlobalContext} from "../../../../context";
import {getNonCompliance, getNonComplianceCauseToRegulationMap} from "../../../../rest/nonCompliance";
import {currencyFormat} from "../../../../utils/CurrencyUtils";
import {AuthenticatedDownloadLink} from "../../../document/AuthenticatedDownloadLink";
import {NonComplianceAppealForm} from "./NonComplianceAppealForm";
import {NonComplianceAppealModal} from "./NonComplianceAppealModal";
import {NonComplianceDecisionModal} from "./NonComplianceDecisionModal";
import {NonComplianceState} from "./NonComplianceState";
import {canChangeRestitution, isAppealableNonCompliance, isDecidableNonCompliance} from "./nonComplianceFunctions";
import {NonComplianceRestitutionModal} from "./NonComplianceRestitutionModal";
import {TranslateBoolean} from "../../../../utils/TranslateBoolean";

export function NonComplianceDetail() {
    const { nonComplianceExternalId } = useParams();
    const { candidatureExternalId } = useParams();
    const [nonComplianceCauseToRegulationMap, setNonComplianceCauseToRegulationMap,] = useState(null);
    const [nonCompliance, setNonCompliance,] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const { accessInfo } = useGlobalContext();
    const [decisionModalShow, setDecisionModalShow]  = useState(false);
    const [decisionModalSubmitted, setDecisionModalSubmitted]  = useState(false);
    const [restitutionModalShow, setRestitutionModalShow]  = useState(false);
    const [restitutionModalSubmitted, setRestitutionModalSubmitted]  = useState(false);
    const [appealModalShow, setAppealModalShow]  = useState(false);
    const [appealModalSubmitted, setAppealModalSubmitted] = useState(false);
    const [documents, setDocuments] = useState([]);
    const [activeKey,setActiveKey]= useState('appeal');


    const fetchData = async () => {
        try {
            if(appealModalSubmitted===true){
                setActiveKey('appeal');
            }else if(decisionModalSubmitted===true){
                setActiveKey('decision')
            }else if(restitutionModalSubmitted===true){
                setActiveKey('restitution')
            }

            setAppealModalSubmitted(false);
            setDecisionModalSubmitted(false);
            setRestitutionModalSubmitted(false);
            setLoading(true);
            const [{ data: nonCompliance }, { data: causeMap }] = await Promise.all([
                getNonCompliance(candidatureExternalId, nonComplianceExternalId),
                getNonComplianceCauseToRegulationMap(' ')
            ]);
            setNonCompliance(nonCompliance);
            setNonComplianceCauseToRegulationMap(causeMap);
            setDocuments(nonCompliance.documents);
            setLoading(false);
        } catch (error) {
            setError(error);
        }
    };

    useEffect(() => {
        fetchData();
    }, [appealModalSubmitted, decisionModalSubmitted,restitutionModalSubmitted]);





    if (loading) {
        return <Loading />;
    }


    const initialDocs = nonCompliance.documents.filter(d => ['NON_COMP_SERVICE_INFO', 'NON_COMP_NOTIFICATION'].includes(d.documentType));
    const appealDocs = nonCompliance.documents.filter(d => ['NON_COMP_APPEAL'].includes(d.documentType));
    const decisionDocs = nonCompliance.documents.filter(d => ['NON_COMP_DECISION', 'NON_COMP_DECISION_NOTIFICATION'].includes(d.documentType));

    return (
        <SubTemplate titleId='candidatureTabs.oversight.tabs.noncompliance' hasBackButton={true}>
            <Container>
                <Row>
                    <AlertError error={error} />
                </Row>
                <Row>
                    <Col>
                        <NonComplianceAppealModal
                            nonCompliance={nonCompliance}
                            candidatureExternalId={candidatureExternalId}
                            show={appealModalShow}
                            documents={documents}
                            setDocuments={setDocuments}
                            setShow={setAppealModalShow}
                            onSubmitCallback={() => setAppealModalSubmitted(true)}
                            />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <NonComplianceDecisionModal
                            nonCompliance={nonCompliance}
                            candidatureExternalId={candidatureExternalId}
                            show={decisionModalShow}
                            setShow={setDecisionModalShow}
                            onSubmitCallback={() => setDecisionModalSubmitted(true)}
                            nonComplianceCauseToRegulationMap={nonComplianceCauseToRegulationMap}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <NonComplianceRestitutionModal
                            nonCompliance={nonCompliance}
                            candidatureExternalId={candidatureExternalId}
                            show={restitutionModalShow}
                            setShow={setRestitutionModalShow}
                            onSubmitCallback={() => setRestitutionModalSubmitted(true)}
                        />
                    </Col>
                </Row>




                <Row>
                    <Col  md='9' className='border-bottom py-3 mb-5'>
                        <div className=' border px-3'>
                            <Row>
                                <Col>
                                    <h4 className='text-primary'>
                                        <FormattedMessage id='candidatureTabs.oversight.tabs.noncompliance'/>
                                    </h4>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <label>
                                        <FormattedMessage id='candidature.stateText' />
                                    </label>
                                    <FormattedMessage id={'candidatureTabs.oversight.tabs.noncompliance.state.' + nonCompliance.state}/>
                                </Col>
                                <Col>
                                    <label>
                                        <FormattedMessage id='all.dateDescription'/>
                                    </label>
                                    {nonCompliance.date}
                                </Col>
                                <Col>
                                    <label>
                                        <FormattedMessage id='candidatureTabs.oversight.tabs.noncompliance.form.leadsToRevogation'/>
                                    </label>
                                    <FormattedMessage id={'candidatureTabs.oversight.tabs.noncompliance.form.revVal.' + nonCompliance.revocation}/>
                                </Col>
                                <Col>
                                    <label>
                                        <FormattedMessage id='candidatureTabs.oversight.tabs.noncompliance.form.aidRestitution'/>
                                    </label>
                                    <FormattedMessage id={'candidatureTabs.oversight.tabs.noncompliance.form.restVal.' + nonCompliance.restitution}/>
                                </Col>
                                {'restitutionValue' in nonCompliance && (
                                <Col>
                                    <label>
                                        <FormattedMessage id='candidatureTabs.oversight.tabs.noncompliance.form.restitutionValue'/>
                                    </label>
                                    {currencyFormat(nonCompliance.restitutionValue.toFixed(2))}
                                </Col>
                                )}
                            </Row>
                            <Row>
                                <label>
                                    <FormattedMessage id='documents.list'/>
                                </label>
                                {initialDocs.map( d => (
                                <Col key={d.externalId}>
                                    <label><FormattedMessage id={'candidatureTabs.oversight.tabs.noncompliance.doc.type.'+d.documentType} /></label>
                                    <AuthenticatedDownloadLink
                                        title={d.name} className='payment-entry-document-link'
                                        url={d.documentPath}
                                        filename={d.name}
                                    >
                                        {d.name}
                                    </AuthenticatedDownloadLink>
                                </Col>
                                ))}
                            </Row>
                            <Row>
                                <Col>
                                    <label>
                                        <FormattedMessage id='candidatureTabs.oversight.tabs.noncompliances' />
                                    </label>
                                    <ul>
                                        {nonCompliance.causes.map( c => (
                                            <li key={c.toString()}>
                                                <FormattedMessage id={'candidatureTabs.oversight.tabs.noncompliance.cause.' + c} />
                                            </li>
                                        ))}
                                    </ul>
                                </Col>
                            </Row>
                        </div>
                        <Accordion defaultActiveKey={[activeKey]}   alwaysOpen>
                        {nonCompliance.appeal !== undefined && (
                            <Accordion.Item eventKey="appeal">
                                <Accordion.Header><FormattedMessage
                                    id='candidatureTabs.oversight.tabs.noncompliance.appeal'/></Accordion.Header>
                                <Accordion.Body>


                            <Row>
                                <Col>
                                    <label>
                                        <FormattedMessage id='all.dateDescription'/>
                                    </label>
                                    {nonCompliance.appeal.date}
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <label>
                                        <FormattedMessage id='candidatureTabs.oversight.tabs.noncompliance.appeal.text' />
                                    </label>
                                    {nonCompliance.appeal.text}
                                </Col>
                            </Row>
                            <Row>
                                <label><FormattedMessage id={'candidatureTabs.oversight.tabs.noncompliance.doc.type.NON_COMP_APPEAL'} /></label>
                                {appealDocs.map( d => (
                                    <Col key={d.externalId}>
                                        <AuthenticatedDownloadLink
                                            title={d.name} className='payment-entry-document-link'
                                            url={d.documentPath}
                                            filename={d.name}
                                        >
                                            {d.name}
                                        </AuthenticatedDownloadLink>
                                    </Col>
                                ))}
                            </Row>
                                </Accordion.Body>
                            </Accordion.Item>
                        )}



                        {nonCompliance.decision !== undefined && (
                            <Accordion.Item eventKey="decision">
                                <Accordion.Header><FormattedMessage
                                    id='candidatureTabs.oversight.tabs.noncompliance.decision'/></Accordion.Header>
                                <Accordion.Body>

                            <Row>
                                <Col>
                                    <label>
                                        <FormattedMessage id='all.dateDescription'/>
                                    </label>
                                    {nonCompliance.decision.date}
                                </Col>
                                <Col>
                                    <label>
                                        <FormattedMessage id='candidatureTabs.oversight.tabs.noncompliance.form.leadsToRevogation'/>
                                    </label>
                                    <FormattedMessage id={'candidatureTabs.oversight.tabs.noncompliance.form.revVal.' + nonCompliance.decision.revocation}/>
                                </Col>

                                {nonCompliance.decision.restitution !== undefined && (
                                <Col>
                                    <label>
                                        <FormattedMessage id='candidatureTabs.oversight.tabs.noncompliance.form.aidRestitution'/>
                                    </label>
                                    <FormattedMessage id={'candidatureTabs.oversight.tabs.noncompliance.form.restVal.' + nonCompliance.decision.restitution}/>
                                </Col>
                                )}
                                {'restitutionValue' in nonCompliance.decision && (
                                    <Col>
                                        <label>
                                            <FormattedMessage id='candidatureTabs.oversight.tabs.noncompliance.form.restitutionValue'/>
                                        </label>
                                        {currencyFormat(nonCompliance.decision.restitutionValue.toFixed(2))}
                                    </Col>
                                )}


                            </Row>


                            <Row>
                                <label>
                                    <FormattedMessage id='documents.list'/>
                                </label>
                                {decisionDocs.map( d => (
                                    <Col key={d.externalId}>
                                        <label><FormattedMessage id={'candidatureTabs.oversight.tabs.noncompliance.doc.type.'+d.documentType} /></label>
                                        <AuthenticatedDownloadLink
                                            title={d.name} className='payment-entry-document-link'
                                            url={d.documentPath}
                                            filename={d.name}
                                        >
                                            {d.name}
                                        </AuthenticatedDownloadLink>
                                    </Col>
                                ))}
                            </Row>
                            <Row>
                                <Col>
                                    <label>
                                        <FormattedMessage id='candidatureTabs.oversight.tabs.noncompliances' />
                                    </label>
                                    <ul>
                                        {nonCompliance.decision?.causes.map( c => (
                                            <li key={c.toString()}>
                                                <FormattedMessage id={'candidatureTabs.oversight.tabs.noncompliance.cause.' + c} />
                                            </li>
                                        ))}
                                    </ul>
                                </Col>
                            </Row>

                                </Accordion.Body>
                            </Accordion.Item>
                        )}


                        {nonCompliance.restitutionRecord !== undefined && (

                            <Accordion.Item eventKey="restitution">
                                <Accordion.Header><FormattedMessage
                                    id='nonCompliance.restitutionRecord'/></Accordion.Header>
                                <Accordion.Body>
                                <Row>
                                    <Col md={4}>
                                        <label>
                                            <FormattedMessage id='nonCompliance.reimbursementPlan' />
                                        </label>
                                        <TranslateBoolean
                                            value={nonCompliance.restitutionRecord.reimbursementPlan}
                                        />
                                    </Col>
                                    <Col md={4}>
                                        <label>
                                            <FormattedMessage id='nonCompliance.restitutionDoneLong' />
                                        </label>
                                        <TranslateBoolean
                                            value={nonCompliance.restitutionRecord.restitutionDone}
                                        />
                                    </Col>
                                    <Col md={4}>
                                        <label>
                                            <FormattedMessage id='nonCompliance.restitutionDate' />
                                        </label>
                                        {(nonCompliance.restitutionRecord.restitutionDate!==undefined && nonCompliance.restitutionRecord.restitutionDate!==null)? nonCompliance.restitutionRecord.restitutionDate: ' - '}
                                    </Col>

                                </Row>



                                <Row>
                                    <Col md={4}>
                                        <label>
                                            <FormattedMessage id='nonCompliance.coerciveDone' />
                                        </label>
                                        <TranslateBoolean
                                            value={nonCompliance.restitutionRecord.coerciveDone}
                                        />
                                    </Col>
                                    <Col md={4}>
                                        <label>
                                            <FormattedMessage id='nonCompliance.dateSentToCoerciveCollection' />
                                        </label>
                                        {(nonCompliance.restitutionRecord.dateSentToCoerciveCollection!==undefined && nonCompliance.restitutionRecord.dateSentToCoerciveCollection!==null)? nonCompliance.restitutionRecord.dateSentToCoerciveCollection: ' - '}
                                    </Col>
                                </Row>
                                </Accordion.Body>
                            </Accordion.Item>
                        )}
                        </Accordion>




                    </Col>
                    <Col md='3'>
                        <div className='px-2 mb-5'>
                            <div className='d-grid gap-4'>
                                {hasPermission('MANAGE_NONCOMPLIANCE', accessInfo) && isDecidableNonCompliance(nonCompliance)  &&  (
                                    <Button variant='outline-primary' onClick={() => setDecisionModalShow(true)}>
                                        <FormattedMessage id='candidatureTabs.oversight.tabs.noncompliance.decide' />
                                    </Button>
                                )}
                                {isPromoterUser() && isAppealableNonCompliance(nonCompliance) && (
                                    <Button variant='outline-primary' onClick={() => setAppealModalShow(true)}>
                                        <FormattedMessage id='candidatureTabs.oversight.tabs.noncompliance.appeal' />
                                    </Button>
                                )}
                                {hasPermission('MANAGE_NONCOMPLIANCE', accessInfo) && canChangeRestitution(nonCompliance)  &&  (
                                    <Button variant='outline-primary' onClick={() => setRestitutionModalShow(true)}>
                                        <FormattedMessage id='candidatureTabs.oversight.tabs.noncompliance.restitution' />
                                    </Button>
                                )}

                            </div>
                        </div>
                    </Col>
                </Row>

            </Container>
        </SubTemplate>
    )
}
