export const ConsultingRequestState = {
  SUBMITTED: 'SUBMITTED',
  APPROVED: 'APPROVED',
  REJECTED: 'REJECTED',
  COMPLETED: 'COMPLETED',
  REVOKED :'REVOKED',
  AWAIT_REVOKE_DECISION :'AWAIT_REVOKE_DECISION',

  values: () => {
    return ['SUBMITTED', 'APPROVED', 'REJECTED', 'COMPLETED','AWAIT_REVOKE_DECISION','REVOKED'];
  },

  getValidStates: () => {
    return ['SUBMITTED', 'APPROVED']
  }
};
