import React from 'react';
import { Accordion, Col, Form, Row } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { DateTimeInputField } from '../bootstrap/DateTimeInputField';
import { NumberInputField } from '../bootstrap/NumberInputField';
import { TextAreaInputField } from '../bootstrap/TextAreaInputField';
import { TextInputField } from '../bootstrap/TextInputField';
import ToolTip from '../bootstrap/ToolTip';
import { MceFinancingForm } from '../general/MceFinancingForm';

export function CompanyMentoringDiagnosisStep({
	step,
	numberOfSteps,
	errors,
	handleChange,
	formikValues,
	handleGoBack,
	handleNextStep,
	setFieldValue,
	readMode,
	updatePeriods
}) {
	const intl = useIntl();

	function handleAddAspect() {
		formikValues.mentoringAndConsultancy.aspects.push({
			description: '',
			resultsToAchieve: '',
			area: '',
			hours: '',
		});
		setFieldValue({ ...formikValues });
	}

	function removeAspect(index) {
		formikValues.mentoringAndConsultancy.aspects.splice(index, 1);
		setFieldValue({ ...formikValues });
	}

	function setEndDate(value) {
		formikValues.mceSupportPeriod.endDateMceSupport= value;
		setFieldValue('mceSupportPeriod.endDateMceSupport',value);
		updatePeriods(formikValues)
		setFieldValue({ ...formikValues });

	}

	return (

		<div>
			<h4 className='text-primary mb-0'>
				<FormattedMessage id='developmentPlan.companyMentoringDiagnosis' />
			</h4>
			<small className='text-secondary text-uppercase'>
				{intl.formatMessage(
					{ id: 'all.step' },
					{ step: step, numberOfSteps: numberOfSteps }
				)}
			</small>
			<fieldset disabled={readMode == true}>
				<Row>
					<Col>
						<Form.Group>
							<div className='mt-4'>
								<Form.Check
									inline
									name={'mentoringAndConsultancy.followUp'}
									onChange={handleChange}
									className={'d-flex align-items-center'}
									type={'checkbox'}
									label={
										<FormattedMessage id='companyMentoringDiagnosis.mentory.accompaniment' />
									}
									checked={formikValues.mentoringAndConsultancy.followUp}
									isInvalid={false}
								/>
								<Form.Check
									inline
									name={'mentoringAndConsultancy.consultancy'}
									onChange={handleChange}
									type={'checkbox'}
									className={'d-flex align-items-center'}
									label={
										<FormattedMessage id='companyMentoringDiagnosis.mentory.consulting' />
									}
									checked={formikValues.mentoringAndConsultancy.consultancy}
									isInvalid={false}
								/>
								<Form.Check
									inline
									name={'mentoringAndConsultancy.skillExtension'}
									onChange={handleChange}
									type={'checkbox'}
									className={'d-flex align-items-center'}
									label={
										<FormattedMessage id='companyMentoringDiagnosis.mentory.enlargementOfCompetencies' />
									}
									checked={formikValues.mentoringAndConsultancy.skillExtension}
									isInvalid={false}
								/>
							</div>
						</Form.Group>
					</Col>
				</Row>
				<Row>
					<Col>
						<p className='mt-4 mb-0'>
							<FormattedMessage id='companyMentoringDiagnosis.provisionPeriod' />
						</p>
					</Col>
				</Row>
				<Row>
					<Col md='6'>
						<DateTimeInputField
							labelId='finalReportForm.initialActivityDate'
							value={formikValues.mceSupportPeriod.initialActivityDate}
							name='mceSupportPeriod.initialActivityDate'
							setFieldValue={setFieldValue}
							isDateWithNoTime={true}
							isInvalid={errors.mceSupportPeriod?.initialActivityDate}
							errorMessage={errors.mceSupportPeriod?.initialActivityDate}
						/>
					</Col>
				</Row>
				<Row>

					<Col md='6'>
						<DateTimeInputField
							disabled={true}
							labelId='finalReportForm.mceServicesStartDate'
							value={formikValues.mceSupportPeriod.initialDateMceSupport}
							name='mceSupportPeriod.initialDateMceSupport'
							setFieldValue={setFieldValue}
							isDateWithNoTime={true}
							isInvalid={errors.mceSupportPeriod?.initialDateMceSupport}
							errorMessage={errors.mceSupportPeriod?.initialDateMceSupport}
						/>
					</Col>
					<Col md='6'>
						<DateTimeInputField
							labelId='finalReportForm.mceServicesEndDate'
							name='mceSupportPeriod.endDateMceSupport'
							value={formikValues.mceSupportPeriod.endDateMceSupport}
							setFieldValue={(name,value) => setEndDate(value)}
							isDateWithNoTime={true}
							isInvalid={errors.mceSupportPeriod?.endDateMceSupport}
							errorMessage={errors.mceSupportPeriod?.endDateMceSupport}
						/>
					</Col>
				</Row>
				<Row>
					<Col>
						<p className='mt-5 mb-0'>
							<FormattedMessage id='companyMentoringDiagnosis.improveAspects' />
						</p>
					</Col>
				</Row>
				{formikValues.mentoringAndConsultancy.aspects.map((aspect, index) => (
					<Row key={index}>
						<Col>
							<Accordion defaultActiveKey='0'>
								<Accordion.Item eventKey='0' className='mt-4'>
									<Accordion.Header>Aspeto {index + 1}</Accordion.Header>
									<Accordion.Body>
										<Row>
											<Col>
												<TextInputField
													name={`mentoringAndConsultancy.aspects[${index}].description`}
													handleChange={handleChange}
													labelId={
														'companyMentoringDiagnosis.improveAspects.improve'
													}
													value={
														formikValues.mentoringAndConsultancy.aspects[index]
															.description
													}
													isInvalid={
														errors.mentoringAndConsultancy?.aspects?.[index]
															?.description
													}
													errorMessage={
														errors.mentoringAndConsultancy?.aspects?.[index]
															?.description
													}
												/>
											</Col>
										</Row>
										<Row>
											<Col>
												<p className='mt-4 mb-0'>
													<FormattedMessage id='companyMentoringDiagnosis.improveAspects.text' />
												</p>
											</Col>
										</Row>
										<Row>
											<Col md='4'>
												<TextInputField
													name={`mentoringAndConsultancy.aspects[${index}].area`}
													handleChange={handleChange}
													labelId={
														'companyMentoringDiagnosis.improveAspects.area'
													}
													value={
														formikValues.mentoringAndConsultancy.aspects[index]
															.area
													}
													isInvalid={
														errors.mentoringAndConsultancy?.aspects?.[index]
															?.area
													}
													errorMessage={
														errors.mentoringAndConsultancy?.aspects?.[index]
															?.area
													}
													maxLength={30}
												/>
											</Col>
											<Col md='6'>
												<TextInputField
													name={`mentoringAndConsultancy.aspects[${index}].resultsToAchieve`}
													handleChange={handleChange}
													labelId={
														'companyMentoringDiagnosis.improveAspects.resultsAchieved'
													}
													value={
														formikValues.mentoringAndConsultancy.aspects[index]
															.resultsToAchieve
													}
													isInvalid={
														errors.mentoringAndConsultancy?.aspects?.[index]
															?.resultsToAchieve
													}
													errorMessage={
														errors.mentoringAndConsultancy?.aspects?.[index]
															?.resultsToAchieve
													}
												/>
											</Col>
											<Col md='2'>
												<NumberInputField
													labelId={
														'companyMentoringDiagnosis.improveAspects.hours'
													}
													name={`mentoringAndConsultancy.aspects[${index}].hours`}
													handleChange={handleChange}
													value={
														formikValues.mentoringAndConsultancy.aspects[index]
															.hours
													}
													isInvalid={
														errors.mentoringAndConsultancy?.aspects?.[index]
															?.hours
													}
													errorMessage={
														errors.mentoringAndConsultancy?.aspects?.[index]
															?.hours
													}
													maxLength={3}
												/>
											</Col>
										</Row>
										<Row>
											<Col>
												<button
													className='mt-5 px-0 btn btn-link'
													type='button'
													onClick={() => removeAspect(index)}
												>
													<FormattedMessage id='all.remove' />
												</button>
											</Col>
										</Row>
									</Accordion.Body>
								</Accordion.Item>
							</Accordion>
						</Col>
					</Row>
				))}
				<Row>
					<Col>
						<button
							className='mt-4 btn btn-outline-primary'
							type='button'
							onClick={() => handleAddAspect()}
						>
							<FormattedMessage id='all.plusSymbol' />
							<FormattedMessage id='companyMentoringDiagnosis.addAspect' />
						</button>
					</Col>
				</Row>

				<Row className='mt-4'>
					<Col>
						<TextAreaInputField
							name={'mentoringAndConsultancy.description'}
							value={formikValues.mentoringAndConsultancy.description}
							handleChange={handleChange}
							isInvalid={errors.mentoringAndConsultancy?.description}
							errorMessage={errors.mentoringAndConsultancy?.description}
							labelId='companyMentoringDiagnosis.description'
							toolTip={
								<ToolTip
									message={
										<FormattedMessage id='companyMentoringDiagnosis.descriptionNote' />
									}
								/>
							}
							maxLength={1500}
							placeholder={intl.formatMessage(
								{
									id: 'annualComplementaryActivitiesPlanForm.characters.placeholder',
								},
								{ nChars: '1500' }
							)}
						/>
					</Col>
				</Row>

				<Row className='mt-5 mb-0'>
					<Col>
						<small className='text-primary text-uppercase'>
							<FormattedMessage id='companyMentoringDiagnosis.plafond' />
						</small>
					</Col>
				</Row>
				<MceFinancingForm
					errors={errors}
					handleChange={handleChange}
					formikValues={formikValues}
					readMode={readMode}
					pathToMceFinancingDataObject='mentoringAndConsultancy.mceFinancing'
					hasConsultingPlafond={
						formikValues.mentoringAndConsultancy.consultancy ||
						formikValues.mentoringAndConsultancy.skillExtension
					}
					hasFollowUpPlafond={formikValues.mentoringAndConsultancy.followUp}
				/>
			</fieldset>
			<div className='form-actions mt-5'>
				<button
					className='btn btn-link'
					type='button'
					onClick={() => handleGoBack()}
				>
					<FormattedMessage id='all.back' />
				</button>

				<button
					className='btn btn-primary'
					type='button'
					onClick={() => handleNextStep()}
				>
					<FormattedMessage id='all.nextStep' />
				</button>
			</div>
		</div>
	);
}
