import React, {useState} from 'react';
import {Accordion, Col, Row} from 'react-bootstrap';
import {FormattedMessage} from 'react-intl';
import {CandidatureEmployeeSupportUtils, IAS} from '../../utils/CandidatureEmployeeSupportUtils';
import {TranslateBoolean} from '../../utils/TranslateBoolean';
import {EnumSelectField} from '../bootstrap/EnumSelectField';

export function SellInformationViewer({sellInformations, totalLabel, reasonLabel,totals,yearNames}) {






    return (
        <>
            {sellInformations.map((sellInformation,index1) => (
                <div key={index1}>

                    {sellInformation.sellInformationLines?.map((line,index) => (
                        <div className='tableWithBorders' key={index}>
                            <p>
                                <strong>{line.designation} ({line.unit}) </strong>
                            </p>
                            <table className='w-100 mb-5'>
                                <thead>
                                <tr className='text-center'>
                                    <th className='w-15'> <FormattedMessage id='all.year'/></th>
                                    <th> <FormattedMessage id='all.quantity.full'/></th>
                                    <th> <FormattedMessage id='all.unitPrice'/></th>
                                    <th> <FormattedMessage id='all.value'/></th>
                                </tr>

                                </thead>
                                <tbody>
                                {line.sellInformationLineYears.map((year, index2) =>(
                                    <tr key={index2}>
                                        <td className='text-center withBorderLeft'>{yearNames[year.year] }</td>
                                        <td className='text-center withBorderLeft'>{year.quantity}</td>
                                        <td className='text-center withBorderLeft'>{(year.unitPrice===undefined ? 0 :year.unitPrice).toFixed(2)} €</td>
                                        <td className='text-center withBorderLeft'>{year.value.toFixed(2)} €</td>
                                    </tr>


                                ))}
                                </tbody>
                            </table>
                        </div>

                    ))}

                    <div className='tableWithBorders'>
                        <p>
                            <strong>{totalLabel}</strong>

                        </p>
                        <table className='w-100 mb-5'>
                            <thead>
                            <tr>
                                {totals.map((total,index) => (
                                    <th key={index} colSpan="2" className='text-center'>{yearNames[total.year]}</th>
                                ))}

                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                {totals.map((total, index2) => {
                                    return (
                                        <React.Fragment key={index2}>
                                        <td className='text-center'><FormattedMessage id='sellInformation.quantity'/></td>
                                        <td className='text-center'><FormattedMessage id='sellInformation.value'/></td>
                                        </React.Fragment>
                                    )
                                })}

                            </tr>
                            <tr>
                                {totals.map((total, index2) => {
                                    return (
                                        <React.Fragment key={index2}>
                                        <td className='text-center'>{total.quantity}</td>
                                        <td className='text-center'>{total.value.toFixed(2)}</td>
                                        </React.Fragment>
                                    )
                                })}
                            </tr>
                            </tbody>

                        </table>


                        <Row>
                            <Col md='12'>
                                <label>
                                    {reasonLabel}
                                </label>
                                <p>
                                    {sellInformation.reasonToSell}
                                </p>
                            </Col>
                        </Row>

                    </div>
                </div>
            ))}

        </>


    );
}
