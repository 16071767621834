import React, { useEffect, useRef, useState } from 'react';
import {Form, Row, Container, Col, Button} from 'react-bootstrap';
import { Formik } from 'formik';
import { FormattedMessage, useIntl } from 'react-intl';
import Loading from '../../components/general/Loading';
import { SubTemplate } from '../../components/general/SubTemplate';
import {
	FaRegCheckCircle,
	FaRegSave,
	FaAngleLeft, FaCheck
} from 'react-icons/fa';
import {
	hasPermission,
	isIEFPUser,
	isPromoterUser,
} from '../../authentication/authenticationHelper';
import { isEntityUser } from '../../authentication/authenticationHelper';
import {
	getSemestralReport,
	saveSemestralReport,
	semestralReportFormStatus, submitDecisionSemestralReport,
	submitSemestralReportDecision,
} from '../../rest/semestralReport';
import { getEntityGetList, getEntityTgpList } from '../../rest/entity';
import { handleError, isNotBusinessError } from '../../utils/handleError';
import { FormSideNavBar } from '../../components/general/FormSideNavBar';
import * as formSteps from '../../components/semestralReport';
import { FinalReportFormStep12 } from '../../components/finalReport';
import {useNavigate, useParams} from 'react-router-dom';
import * as yup from "yup";
import { MceYearType } from '../../models/MceYearType';
import { CompanyIdentificationFormUtils } from '../../utils/CompanyIdentificationFormUtils';
import { SuccessToast } from '../../components/general/SuccessToast';
import { AlertError } from '../../components/bootstrap/AlertError';
import { createCustomErrorMessage } from '../../hooks/errorMessage';
import { DecisionDialog } from '../../components/bootstrap/DecisionDialog';
import { ConfirmationAuthenticationModal } from '../../components/general/ConfirmationAuthenticationModal';
import { ProcessState } from '../../models/ProcessState';
import {useGlobalContext} from "../../context";
import {AlertSuccess} from "../../components/bootstrap/AlertSuccess";
import {getSemestralPaymentListRequestByReport} from "../../rest/paymentRequest";
import {PaymentRequestLinksModal} from "../../components/paymentRequest/PaymentRequestLinksModal";

export function AddSemestralReport() {
	const isEntity = isEntityUser();
	const isPromoter = isPromoterUser();
	const isIEFP = isIEFPUser();

	let {
		consultingRequestExternaId: consultingRequestExternaId,
		externalId: externalId,
	} = useParams();
	const [showSaveToast, setShowSaveToast] = useState(false);
	const [showSubmitModal, setShowSubmitModal] = useState(false);
	const [externalIdFinal, setExternalIdFinal] = useState(null);

	const [formikInitialValues, setFormikInitialValues] = useState(null);
	const [allowActions, setAllowActions] = useState(null);
	const [getList, setGetList] = useState([]);
	const [tgpList, setTgpList] = useState([]);

	const intl = useIntl();
	const formikPropsRef = useRef(null);
	const [showAccept, setShowAccept] = useState(false);
	const [showReject, setShowReject] = useState(false);
	const [submited, setSubmited] = useState(false);
	const [accept, setAccept] = useState(false);
	const [reject, setReject] = useState(false);
	const { accessInfo,setCanEditProfile } = useGlobalContext();
	const [semestralReports, setSemestralReports] = useState([]);
	const [showRejectedPRs, setShowRejectedPRs] = useState(false);
	const [isLastReport, setIsLastReport] = useState();
	const [paymentRequests, setPaymentRequests] = useState([]);

	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);
	const [navErrors, setNavErrors] = useState([]);
	const [formStep, setFormStep] = useState(0);
	const navigate = useNavigate();
	const [lsItem, setLsItem] = useState(null);

	const tabsIds = [
		'semestralReportForm.sideNavBar.identificationEA',
		'semestralReportForm.sideNavBar.identificationCompany',
		'semestralReportForm.sideNavBar.projectApproval',
		'semestralReportForm.sideNavBar.followUpVisits',
		'semestralReportForm.sideNavBar.followUpResults',
		'semestralReportForm.sideNavBar.consultingActivitiesResults',
		'semestralReportForm.sideNavBar.globalStatus',
		'semestralReportForm.sideNavBar.activitiesTimeline',
		'finalReportForm.sideNavBar.projectManagersList',
	];

	function isValidStartDateActionByStartDate(value) {
		let initialDate = new Date(formikPropsRef.current.values.semesterInitialDate);
		initialDate.setHours(0);
		initialDate.setMilliseconds(0);
		initialDate.setMinutes(0);
		initialDate.setSeconds(0);
		return new Date(value) >= initialDate;
	}

	function isValidStartDateActionByEndDate(value) {
		let endDate = new Date(formikPropsRef.current.values.semesterEndDate);
		endDate.setHours(0);
		endDate.setMilliseconds(0);
		endDate.setMinutes(0);
		endDate.setSeconds(0);
		return new Date(value) <=endDate;
	}


	const validationSchema = yup.object().shape({
		identificationEa: yup.object().shape({
			technicalTeamManagerExternalId: yup
				.string()
				.test(
					'isGETValid',
					<FormattedMessage id='errors.finalReport.GET' />,
					(value) => value !== '-1'
				),
		}),

		company: CompanyIdentificationFormUtils.validationObject(true),

		semesterFollowUpVisits: yup.array().of(
			yup.object().shape({
				month: yup.number().required(<FormattedMessage id='errors.fieldRequiredText' />),
				numberOfVisits: yup.number().required(<FormattedMessage id='errors.fieldRequiredText' />),
				duration: yup.number().required(<FormattedMessage id='errors.fieldRequiredText' />),
			})).nullable(),


		semesterFollowUpResults: yup.object().shape({
			research: yup.boolean(),
			management: yup.boolean(),
			informationSystems: yup.boolean(),
			people: yup.boolean(),
			marketing: yup.boolean(),
			equipment: yup.boolean(),
			financial: yup.boolean(),

			companyStatus: yup.object().shape({
				workPositionsPredicted: yup.number().required(<FormattedMessage id='errors.fieldRequiredText' />),
				workPositionsCreated: yup.number().required(<FormattedMessage id='errors.fieldRequiredText' />),
				workPositionsKept: yup.number().required(<FormattedMessage id='errors.fieldRequiredText' />),
			}),

			semesterDifficultyOvercomeActivities:
				yup.array().of(
					yup.object().shape({
						description: yup.string().required(<FormattedMessage id='errors.fieldRequiredText' />),
						area: yup.string()
							.test(
								'isActivityAreaValid',
								<FormattedMessage id='errors.fieldRequiredText' />,
								(value) => value !== '-1' && value !== undefined && value !== null
							).nullable(),
					})).nullable()
		}),

		consultancyActivitiesResults: yup.object().shape({
			semesterConsultancyResultsDescription: yup.string().required(<FormattedMessage id='errors.fieldRequiredText' />),

			consultancyActivities: yup.array().of(
				yup.object().shape({
					area: yup.string()
						.test(
							'isActivityAreaValid',
							<FormattedMessage id='errors.fieldRequiredText' />,
							(value) => value !== '-1' && value !== undefined && value !== null
						).nullable(),
					objectives: yup.string().required(<FormattedMessage id='errors.fieldRequiredText' />),
					results: yup.string().required(<FormattedMessage id='errors.fieldRequiredText' />),
					hoursPredicted: yup.number().required(<FormattedMessage id='errors.fieldRequiredText' />),
					hoursSpent: yup.number().required(<FormattedMessage id='errors.fieldRequiredText' />),
				})).nullable(),
		}),

		semesterDifficultyOvercomeActivities:
			yup.array().of(
				yup.object().shape({
					description: yup.string().required(<FormattedMessage id='errors.fieldRequiredText' />),
					area: yup.string()
						.test(
							'isActivityAreaValid',
							<FormattedMessage id='errors.fieldRequiredText' />,
							(value) => value !== '-1' && value !== undefined
						),
				})).nullable(),


		followUpPredictionHours: yup.number().min(0, <FormattedMessage id='errors.fieldRequiredText' />),
		followUpEvaluation: yup.string().required(<FormattedMessage id='errors.fieldRequiredText' />),
		followUpFutureActions: yup.string().required(<FormattedMessage id='errors.fieldRequiredText' />),
		consultancyPredictionHours: yup.number().min(0, <FormattedMessage id='errors.fieldRequiredText' />),
		consultancyEvaluation: yup.string().required(<FormattedMessage id='errors.fieldRequiredText' />),
		consultancyFutureActions: yup.string().required(<FormattedMessage id='errors.fieldRequiredText' />),
		last: yup.boolean().required(<FormattedMessage id='errors.fieldRequiredText' />),

		semesterTimelineEntryB11s:
			yup.array().of(
				yup.object().shape({
					actions: yup.string().required(<FormattedMessage id='errors.fieldRequiredText' />),
					activityType: yup.string()
						.test(
							'isTimelineActivityTypeValid',
							<FormattedMessage id='errors.fieldRequiredText' />,
							(value) => value !== '-1' && value !== undefined
						),
					forecastSemester: yup.number().required(<FormattedMessage id='errors.fieldRequiredText' />),
					realSemester: yup.number().required(<FormattedMessage id='errors.fieldRequiredText' />)
				})).nullable(),

		projectManagersList: yup.array().of(
			yup.object().shape({
				name: yup.string().required(<FormattedMessage id='errors.fieldRequiredText' />),
				actionDesignation: yup.string().required(<FormattedMessage id='errors.fieldRequiredText' />),
				numberOfHoursByMonth: yup.number().min(1, <FormattedMessage id='errors.notPositive' />),
				mceActivity: yup.string()
					.test(
						'isMceActivityValid',
						<FormattedMessage id='errors.fieldRequiredText' />,
						(value) => value !== '-1' && value !== undefined
					).nullable(),
				internalOrExternal: yup.string()
					.test(
						'isInternalOrExternalValid',
						<FormattedMessage id='errors.fieldRequiredText' />,
						(value) => value !== '-1' && value !== undefined
					).nullable(),
				startDateAction: yup.date().test(
					'isStartDateActionValid',
					<FormattedMessage id='error.finalReportForm.startDateActionBeforeContract' />,
					(value) => {
						return (isValidStartDateActionByStartDate(value));
					}
				).test(
					'isStartDateActionValidAfter',
					<FormattedMessage id='error.finalReportForm.startDateActionBeforeContract' />,
					(value) => {
						return (isValidStartDateActionByEndDate(value));
					}
				).nullable(),
				endDateAction: yup.date().test(
					'isEndDateActionValid',
					<FormattedMessage id='error.finalReportForm.startDateActionBeforeContract' />,
					(value) => {
						return (isValidStartDateActionByStartDate(value));
					}
				).test(
					'isEndDateActionValidAfter',
					<FormattedMessage id='error.finalReportForm.startDateActionBeforeContract' />,
					(value) => {
						return (isValidStartDateActionByEndDate(value));
					}
				).test(
					'isEndDateActionValidAfterInitial',
					<FormattedMessage id='error.finalReportForm.dateActionsIncorrect' />,
					(value, context) => (value >= new Date(context.parent.startDateAction))
				).nullable()
			}))

	});
	console.log(externalIdFinal)

	async function fetchData() {
		try {

			console.log(consultingRequestExternaId);
			console.log(externalIdFinal);
			let {data: form} = await getSemestralReport(
				externalIdFinal === null ?externalId: externalIdFinal, consultingRequestExternaId
			);
			setExternalIdFinal(form.externalId)
			setIsLastReport(form.last);

			let entityHumanResourceParamsForNonEntityUsers = {
				entityNif: form.identificationEa.entity.nif,
				all:form.processState !== ProcessState.DRAFT
			};

			let [
				{data: entityGetList},
				{data: entityTPList},
				{data: allowActionsIn},
				{data: paymentRequestList}

			] = await Promise.all([
				isEntity
					? await getEntityGetList({all:form.processState !== ProcessState.DRAFT})
					: await getEntityGetList(entityHumanResourceParamsForNonEntityUsers),
				isEntity
					? await getEntityTgpList({all:form.processState !== ProcessState.DRAFT})
					: await getEntityTgpList(entityHumanResourceParamsForNonEntityUsers),
				await semestralReportFormStatus(form.externalId),
				await getSemestralPaymentListRequestByReport((form && form?.externalId) ? form?.externalId : externalId),
			]);

			setGetList(entityGetList);
			setTgpList(entityTPList);
			setPaymentRequests(paymentRequestList);
			console.log(allowActionsIn)
			setAllowActions(allowActionsIn)
			setFormikInitialValues(getFormikInitialValues(form));

			checkLocalStorageItems();
		} catch (error) {
			setError(error);
		} finally {
			setLoading(false);
		}
	}

	function hasRejectedPaymentRequests() {
		return paymentRequests.some(r => r.processState === ProcessState.REJECTED);
	}

	function hasCurrentPaymentRequest() {
		let validStates = [
			ProcessState.DRAFT,
			ProcessState.SUBMITTED,
			ProcessState.APPROVED
		];
		return paymentRequests.some(r => validStates.includes(r.processState))
	}

	console.log(hasRejectedPaymentRequests(), hasCurrentPaymentRequest());




	function scrollPageToTop() {
		document.body.scrollTop = 0;
		document.documentElement.scrollTop = 0;
	}

	function handlePreviousStep() {
		scrollPageToTop();
		setFormStep(formStep - 1);
	}

	function handleNextStep() {
		scrollPageToTop();
		setFormStep(formStep + 1);
	}

	useEffect(() => {
		fetchData();
	}, []);

	if (loading) {
		return <Loading />;
	}




		if (submited) {
			sessionStorage.setItem(
				'semesterReportSubmited',
				accept
			);
			return navigate('relatorioSemestral/lista', {
				state: {refresh: true}
			});
		}


	if (accept) {
		sessionStorage.setItem(
			'semesterReportAccept',
			accept
		);
		return navigate(-1, {
			state: {refresh: true}
		});
	}


	if (reject) {
		sessionStorage.setItem(
			'semesterReportReject',
			reject
		);
		return navigate(-1, {
			state: {refresh: true}
		});
	}




	function checkLocalStorageItems() {
		[
			'semesterReportPaymentSubmit',
			'semesterReportPaymentAccept',
			'semesterReportPaymentReject',
			'finalReportPaymentSubmit',
			'finalReportPaymentAccept',
			'finalReportPaymentReject'
		].forEach((s) => {
			if (sessionStorage.getItem(s)) {
				setLsItem(s);
			}
		});
	}

	if (error && isNotBusinessError(error)) {
		return handleError(error);
	}

	function getFormikInitialValues(form) {
		let initialValues = form;

		CompanyIdentificationFormUtils.getFormikInitialValuesCompanyIdentification(
			form,
			true
		);

		// Step 4
		initialValues.semesterFollowUpVisits.forEach((followUpVisit) => {
			followUpVisit.month ??= undefined;
			followUpVisit.numberOfVisits ??= '';
			followUpVisit.duration ??= '';
		});


		// Step 5
		initialValues.semesterFollowUpResults.companyStatus.operation ??= undefined;
		initialValues.semesterFollowUpResults.companyStatus.facilitiesQuality ??= undefined;
		initialValues.semesterFollowUpResults.companyStatus.equipmentQuality ??= undefined;
		initialValues.semesterFollowUpResults.companyStatus.facilitiesPrediction ??= undefined;
		initialValues.semesterFollowUpResults.companyStatus.equipmentPrediction ??= undefined;
		initialValues.semesterFollowUpResults.companyStatus.workPositionsPredicted ??= '';
		initialValues.semesterFollowUpResults.companyStatus.workPositionsCreated ??= '';
		initialValues.semesterFollowUpResults.companyStatus.workPositionsKept ??= '';
		initialValues.semesterFollowUpResults.companyStatus.promotersHaveMajority ??= '';
		initialValues.semesterFollowUpResults.semesterDifficultyOvercomeActivities ??= []

		// Step 6
		initialValues.consultancyActivitiesResults.semesterConsultancyResultsDescription ??= '';
		initialValues.last ??= false;
		initialValues.consultancyActivitiesResults.consultancyActivities.forEach((consultancyActivity) => {
			consultancyActivity.area ??= undefined;
			consultancyActivity.objectives ??= '';
			consultancyActivity.results ??= '';
			consultancyActivity.description ??= '';
			consultancyActivity.hoursPredicted ??= '';
			consultancyActivity.duration ??= '';

		});

		// Step 7
		initialValues.followUpEvaluation ??= '';
		initialValues.followUpFutureActions ??= '';
		initialValues.followUpPredictionHours ??= 0;
		initialValues.consultancyEvaluation ??= '';
		initialValues.consultancyFutureActions ??= '';
		initialValues.consultancyPredictionHours ??= 0;

		initialValues.semesterTimelineEntryB11s.forEach((semesterTimelineEntryB11) => {
			semesterTimelineEntryB11.activityType ??= undefined;
			semesterTimelineEntryB11.actions ??= '';
			semesterTimelineEntryB11.forecastSemester ??= 0;
			semesterTimelineEntryB11.realSemester ??= 0;
		});

		// Step 9
		initialValues.projectManagersList ??= []

		return initialValues;
	}




	async function handleSave() {
		if (!isEntity || (isEntity && formikPropsRef.current.values.processState !== ProcessState.DRAFT))
			return;
		let values = formikPropsRef.current.values;
		const submissionValues = { ...values };

		submissionValues.consultancyActivitiesResults.consultancyActivities.forEach(c=>{
			c.area =(c.area!=='-1' && c.area!=='') ? c.area: null
           
		})

		submissionValues.semesterTimelineEntryB11s.forEach(c=>{
			c.activityType =(c.activityType!=='-1' && c.activityType!=='') ? c.activityType: null

		})

		submissionValues.projectManagersList.forEach(p=>{
			p.mceActivity =(p.mceActivity!=='-1' && p.mceActivity!=='') ? p.mceActivity: null;
			p.internalOrExternal =(p.internalOrExternal!=='-1' && p.internalOrExternal!=='') ? p.internalOrExternal: null;


		})

		submissionValues.semesterFollowUpResults.companyStatus.equipmentPrediction= (submissionValues.semesterFollowUpResults.companyStatus.equipmentPrediction!=='-1' && submissionValues.semesterFollowUpResults.companyStatus.equipmentPrediction!=='') ? submissionValues.semesterFollowUpResults.companyStatus.equipmentPrediction: null
		submissionValues.semesterFollowUpResults.companyStatus.equipmentQuality= (submissionValues.semesterFollowUpResults.companyStatus.equipmentQuality!=='-1' && submissionValues.semesterFollowUpResults.companyStatus.equipmentQuality!=='') ? submissionValues.semesterFollowUpResults.companyStatus.equipmentQuality: null
		submissionValues.semesterFollowUpResults.companyStatus.facilitiesPrediction= (submissionValues.semesterFollowUpResults.companyStatus.facilitiesPrediction!=='-1' && submissionValues.semesterFollowUpResults.companyStatus.facilitiesPrediction!=='') ? submissionValues.semesterFollowUpResults.companyStatus.facilitiesPrediction: null
		submissionValues.semesterFollowUpResults.companyStatus.facilitiesQuality= (submissionValues.semesterFollowUpResults.companyStatus.facilitiesQuality!=='-1' && submissionValues.semesterFollowUpResults.companyStatus.facilitiesQuality!=='') ? submissionValues.semesterFollowUpResults.companyStatus.facilitiesQuality: null
		submissionValues.semesterFollowUpResults.companyStatus.facilitiesQuality= (submissionValues.semesterFollowUpResults.companyStatus.facilitiesQuality!=='-1' && submissionValues.semesterFollowUpResults.companyStatus.facilitiesQuality!=='') ? submissionValues.semesterFollowUpResults.companyStatus.facilitiesQuality: null
		submissionValues.semesterFollowUpResults.companyStatus.operation= (submissionValues.semesterFollowUpResults.companyStatus.operation!=='-1' && submissionValues.semesterFollowUpResults.companyStatus.operation!=='') ? submissionValues.semesterFollowUpResults.companyStatus.operation: null

		submissionValues.projectManagersList.forEach(hR => {
			if (hR.internalOrExternal === undefined || hR.internalOrExternal === '') {
				delete hR['internalOrExternal'];
			}
			if (hR.mceActivity === undefined || hR.mceActivity === '') {
				delete hR['mceActivity'];
			}
			if (hR.startDateAction === '') {
				delete hR['startDateAction'];
			}

			if (hR.endDateAction === '') {
				delete hR['endDateAction'];
			}
			if (hR.humanExternalId === '-1' || hR.humanExternalId === undefined) {
				delete hR['humanExternalId'];
			}
		})



		console.log('AddSemesterReport.js::handleSave', submissionValues);

		try {
			let { data: res } = await saveSemestralReport(
				externalIdFinal,
				submissionValues
			);

			setNavErrors([]);
			formikPropsRef.current.setErrors({});

			setShowSaveToast(true);
		} catch (error) {
			setError(error);
		}
	}

	async function submitDecision(decisionApprove) {
		try {
			let data = {
				flag: decisionApprove,
			};
			await submitSemestralReportDecision(
				formikPropsRef.current.values.externalId,
				data
			);
			setSubmited(true);
		} catch (error) {
			setError(error);
		}
	}

	async function submitAccept() {
	try{
		let data = {
			reason: ''
		};
		await submitDecisionSemestralReport(externalIdFinal,data);
		setAccept(true);
	}catch (error) {
		setError(error);
	}
	}


	async function submitReject(reason) {
		try{
			setShowReject(false);
			let data = {
				reason: reason
			};
			await submitDecisionSemestralReport(externalIdFinal,data);
			setReject(true);
		}catch (error) {
			setError(error);
		}


	}

	async function handleSubmit(user, password, setModalError, decisionApprove) {
		let values = formikPropsRef.current.values;


		['equipmentPrediction','equipmentQuality','facilitiesPrediction','facilitiesQuality','operation'].forEach(s=>{
			if(values.semesterFollowUpResults.companyStatus[s] ==='-1'){
				delete values.semesterFollowUpResults.companyStatus[s]
			}
		})

		const submissionValues = { ...values };

		submissionValues.credentials = {
			user: user,
			password: password,
		};

		if (isIEFP) {
			submissionValues.decisionApprove = decisionApprove;
		}

		let queryParams = {
			submit: true,
		};

		try {
			await saveSemestralReport(externalIdFinal, submissionValues, queryParams);
			setSubmited(true);
			setShowSubmitModal(false);
			navigate(-1);
		} catch (err) {
			let exceptionType = err.response.data.exception;
			if (exceptionType === 'ValidationException') {
				handleNavError(err);
			} else {
				setModalError(err);
			}
		}
	}

	function goToSemestralPaymentRequest() {
		navigate('/relatorioSemestral/' + externalIdFinal + '/pagamento', { state: { refresh: true } });
	}

	function goToFinalPaymentRequest() {
		navigate('/relatorioSemestral/' + externalIdFinal + '/pagamentoFinal', { state: { refresh: true } });
	}

	async function formValidationHandler(setShowModal) {
		let errors = await validateForm();
		setNavErrors(Array.from(errors));
		if (errors.size === 0) {
			setShowModal(true);
		}
	}

	async function validateForm() {
		let errors = new Set();

		const formikErrors = await formikPropsRef.current.validateForm();

		let errors2 = formikErrors;
		if (formikPropsRef.current.values.semesterFollowUpVisits?.length !== 0) {
			console.log(errors2);

			let arrayError = [];
			['equipmentPrediction','equipmentQuality','facilitiesPrediction','facilitiesQuality','operation','promotersHaveMajority'].forEach(s=>{
				if(formikPropsRef.current.values.semesterFollowUpResults.companyStatus[s]  === undefined || formikPropsRef.current.values.semesterFollowUpResults.companyStatus[s]  === '-1'   || formikPropsRef.current.values.semesterFollowUpResults.companyStatus[s]  === ''){
					arrayError.push(s);
				}
			})

			if(arrayError.length>0){
				errors2['semesterFollowUpResults']= {companyStatus:{}};
				arrayError.forEach(e=> {
					errors2.semesterFollowUpResults.companyStatus[e]= <FormattedMessage id='errors.fieldRequiredText' />;
				})
				formikPropsRef.current.setErrors(errors2);
			}


		}



		console.log(errors2)
		Object.keys(errors2).forEach((key) => {
			switch (key) {
				case 'identificationEa':
					isEntity && errors.add(0);
					break;
				case 'company':
					isEntity && errors.add(1);
					break;
				case 'semesterFollowUpVisits':
					isEntity && errors.add(3);
					break;
				case 'semesterFollowUpResults':
					isEntity && errors.add(4);
					break;
				case 'consultancyActivitiesResults':
					isEntity && errors.add(5);
					break;
				case 'last':
				case 'followUpPredictionHours':
				case 'followUpEvaluation':
				case 'followUpFutureActions':
				case 'consultancyPredictionHours':
				case 'consultancyEvaluation':
				case 'consultancyFutureActions':
					isEntity && errors.add(6);
					break;
				case 'semesterTimelineEntryB11s':
					isEntity && errors.add(7);
					break;
				case 'projectManagersList':
					isEntity && errors.add(8);
					break;
			}
		});

		let values = formikPropsRef.current.values;
		let errorMessages = [];

		if (values.candidatureProcessNumber === '-1') {
			errorMessages.push(intl.formatMessage({ id: 'errors.associateCandidature' }))
			isEntity && errors.add(1);
		}



		let checkAreas = [];
		['research', 'management', 'informationSystems', 'people', 'marketing', 'equipment', 'financial', 'otherDifficulty'].forEach(s => {
			if ((s == 'otherDifficulty' && values.semesterFollowUpResults[s] !== '' && values.semesterFollowUpResults[s] !== undefined)
				|| values.semesterFollowUpResults[s] === true) {

				checkAreas.push(s);
			}

			Object.values(values.semesterFollowUpResults.semesterDifficultyOvercomeActivities).map(
				(difficultyAreaActivity) => {

					if (difficultyAreaActivity.area === s
						&& difficultyAreaActivity.description !== ''
						&& difficultyAreaActivity.description !== undefined) {

						checkAreas = checkAreas.filter((area) => difficultyAreaActivity.area !== area)
					}
				})
		})

		checkAreas.forEach(area => {
			errorMessages.push(intl.formatMessage({
				id: 'error.semestralReportForm.' + area + '.mustHaveDescription'
			}, {
				otherDifficulty: values.semesterFollowUpResults[area]
			}));
			errors.add(4);
		})


		if (values.semesterTimelineEntryB11s === undefined || values.semesterTimelineEntryB11s.length < 1) {
			errorMessages.push(intl.formatMessage({
				id: 'error.semestralReportForm.semesterTimelineEntryB11s.noOneChoose'
			}));
			errors.add(7);
		}

		if (values.projectManagersList === undefined || values.projectManagersList.length === 0) {
			errorMessages.push(intl.formatMessage({
				id: 'error.semestralReportForm.projectManagersList.noOneChoose'
			}));
			errors.add(8);
		}

		if (errorMessages.length > 0) {
			let message =
				errorMessages.length === 1
					? createCustomErrorMessage(errorMessages[0])
					: createCustomErrorMessage(
						'<p>' +
						intl.formatMessage({
							id: 'errors.more',
						}) +
						'</p><p> - ' +
						errorMessages.join('</p><p> - ') +
						'</p>'
					);

			setError(message);
		}
		return errors;
	}

	function handleNavError(error) {
		let errors = error.response.data.message
			.split(',')
			.map((value) => Number.parseInt(value));
		setNavErrors(errors);
	}

	function renderStep(formikProps) {
		formikPropsRef.current = formikProps;
		const readMode = !(
			isEntity && formikProps.values.processState === ProcessState.DRAFT
		);


		switch (formStep) {
			case 0:
				return (
					<formSteps.SemestralReportFormStep1
						formStep={1}
						numberOfSteps={tabsIds.length}
						errors={formikProps.errors}
						formikValues={formikProps.values}
						handleChange={formikProps.handleChange}
						handleNextStep={handleNextStep}
						entityGetList={getList}
						goBackPath={'/relatioSemestral/lista'}
						setFieldValue={formikProps.setFieldValue}
						readMode={readMode}
					/>
				);
			case 1:
				return (
					<formSteps.SemestralReportFormStep2
						formStep={2}
						numberOfSteps={tabsIds.length}
						errors={formikProps.errors}
						formikValues={formikProps.values}
						handleChange={formikProps.handleChange}
						handlePreviousStep={handlePreviousStep}
						handleNextStep={handleNextStep}
						setError={setError}
						setFieldValue={formikProps.setFieldValue}
						readMode={readMode}
					/>
				);
			case 2:
				return (
					<formSteps.SemestralReportFormStep3
						formStep={3}
						numberOfSteps={tabsIds.length}
						errors={formikProps.errors}
						formikValues={formikProps.values}
						handleChange={formikProps.handleChange}
						handlePreviousStep={handlePreviousStep}
						handleNextStep={handleNextStep}
						setFieldValue={formikProps.setFieldValue}
						readMode={readMode}
					/>
				);
			case 3:
				return (
					<formSteps.SemestralReportFormStep4
						formStep={4}
						numberOfSteps={tabsIds.length}
						errors={formikProps.errors}
						formikValues={formikProps.values}
						handleChange={formikProps.handleChange}
						handlePreviousStep={handlePreviousStep}
						handleNextStep={handleNextStep}
						setFieldValue={formikProps.setFieldValue}
						readMode={readMode}

					/>
				);
			case 4:
				return (
					<formSteps.SemestralReportFormStep5
						formStep={5}
						numberOfSteps={tabsIds.length}
						errors={formikProps.errors}
						formikValues={formikProps.values}
						handleChange={formikProps.handleChange}
						handlePreviousStep={handlePreviousStep}
						handleNextStep={handleNextStep}
						setFieldValue={formikProps.setFieldValue}
						readMode={readMode}
					/>
				);
			case 5:
				return (
					<formSteps.SemestralReportFormStep6
						formStep={6}
						numberOfSteps={tabsIds.length}
						errors={formikProps.errors}
						formikValues={formikProps.values}
						handleChange={formikProps.handleChange}
						handlePreviousStep={handlePreviousStep}
						handleNextStep={handleNextStep}
						setFieldValue={formikProps.setFieldValue}
						readMode={readMode}
					/>
				);
			case 6:
				return (
					<formSteps.SemestralReportFormStep7
						formStep={7}
						numberOfSteps={tabsIds.length}
						entityGetList={getList}
						errors={formikProps.errors}
						formikValues={formikProps.values}
						handleChange={formikProps.handleChange}
						handlePreviousStep={handlePreviousStep}
						handleNextStep={handleNextStep}
						readMode={readMode}
					/>
				);
			case 7:
				return (
					<formSteps.SemestralReportFormStep8
						formStep={8}
						numberOfSteps={tabsIds.length}
						errors={formikProps.errors}
						formikValues={formikProps.values}
						handleChange={formikProps.handleChange}
						handlePreviousStep={handlePreviousStep}
						handleNextStep={handleNextStep}
						setFieldValue={formikProps.setFieldValue}
						readMode={readMode}
					/>
				);
			case 8:
				return (
					<FinalReportFormStep12
						formStep={9}
						numberOfSteps={tabsIds.length}
						errors={formikProps.errors}
						formikValues={formikProps.values}
						handleChange={formikProps.handleChange}
						handlePreviousStep={handlePreviousStep}
						setFieldValue={formikProps.setFieldValue}
						showYearAndSemester
						tpgList={tgpList}
						readMode={readMode}
					/>
				);
			default:
				console.log('Something went wrong rendering the form step');
		}
	}

	return (
		<SubTemplate>
			<Container>

				<div className='mx-2 mb-4'>
					<AlertSuccess lsItem={lsItem} />
				</div>
				<ConfirmationAuthenticationModal
					show={showSubmitModal}
					handleClose={() => setShowSubmitModal(false)}
					submitHandler={handleSubmit}
					idsPreffix={'semestralReport.submitModal'}
					confirmButtonCompleteLabelId={'all.submitButtonText'}
				/>
				<DecisionDialog
					show={showAccept}
					handleConfirm={submitAccept}
					handleClose={() => {
						setShowAccept(false)
					}}
					titleId='decisionDialog.semesterReport.accept.title'
					bodyId='decisionDialog.semesterReport.accept.body'
					needReason={false}
				/>
				<DecisionDialog
					show={showReject}
					handleConfirm={submitReject}
					handleClose={() => {
						setShowReject(false)
					}}
					titleId='decisionDialog.semesterReport.reject.title'
					bodyId='decisionDialog.semesterReport.reject.body'
					needReason={true}
					reasonLabelId={'decisionDialog.finalReport.reject.reason'}
				/>
				<PaymentRequestLinksModal
					show={showRejectedPRs}
					setShow={setShowRejectedPRs}
					paymentRequestList={paymentRequests}
				/>


				<Row>
					<Col md={{ span: 8, offset: 2 }}>
						<h2 className='mb-1 text-secondary'>
							<button className={'back-button btn-link'} onClick={()=>navigate(-1)}>
								<FaAngleLeft />
							</button>
							<FormattedMessage id={!isLastReport ? 'semestralReportForm.title' : 'lastSemestralReportForm.title'} />
						</h2>
						<p className='mb-5 text-secondary'>
							<FormattedMessage id='semestralReportForm.subTitle' />
							<span
								className="badge rounded-pill bg-secondary fw-normal ms-2"><span
								className="text-uppercase fw-bold"><FormattedMessage
								id={`processState.${formikInitialValues.processState}`}
							/> </span>  {formikInitialValues.submissionDate !== undefined && formikInitialValues.submissionDate !== null && (<> · <FormattedMessage id={'submitted.process.at'} values={{date:formikInitialValues.submissionDate}}/>  </>) } {formikInitialValues.approvalDate !== undefined && formikInitialValues.approvalDate !== null && ProcessState.SUBMITTED!==formikInitialValues.processState && (<> · {ProcessState.APPROVED===formikInitialValues.processState ? <FormattedMessage id={'approvedPromoter2.process.at'} values={{date:formikInitialValues.approvalDate}}/>:<FormattedMessage id={'rejectedPromoter2.process.at'} values={{date:formikInitialValues.approvalDate}}/>}   </>) }  </span>
						</p>
					</Col>
				</Row>
				<Row className='mb-5'>
					<Col md='2'>
						<FormSideNavBar
							tabsIdsArray={tabsIds}
							formStep={formStep}
							setFormStep={setFormStep}
							navErrors={navErrors}
							setNavErrors={setNavErrors}
						/>
					</Col>
					<Col md='8'>
						<AlertError error={error} />
						<SuccessToast
							message={intl.formatMessage({
								id: 'all.saveToastInformation',
							})}
							show={showSaveToast}
							setShow={setShowSaveToast}
						/>
						<Formik
							initialValues={formikInitialValues}
							validateOnBlur={false}
							validateOnChange={false}
							validationSchema={validationSchema}
							onSubmit={handleSubmit}
						>
							{(formikProps) => (
								<Form onSubmit={formikProps.handleSubmit}>
									{renderStep(formikProps)}
								</Form>
							)}
						</Formik>
					</Col>
					<Col md='2'>
						<div>
							{isEntity && formikInitialValues?.processState === ProcessState.DRAFT &&
								<>
									<button
										className='btn btn-outline-primary d-flex align-items-center justify-content-center mb-4 w-100'
										type='button'
										onClick={() => formValidationHandler((b) => (b))}
									>
										<FaCheck />
										<FormattedMessage id='all.validateButtonText' />
									</button>
									<button
										className='btn btn-outline-primary d-flex align-items-center justify-content-center mb-4 w-100'
										type='button'
										onClick={() => handleSave()}
									>
										<FaRegSave />
										<FormattedMessage id='all.save' />
									</button>
									<button disabled={allowActions.canSubmit===false}
										className='btn btn-primary d-flex align-items-center justify-content-center w-100'
										type='button'
										onClick={() => formValidationHandler(setShowSubmitModal)}
									>
										<FaRegCheckCircle />
										<FormattedMessage id='all.submitButtonText' />
									</button>
								</>
							}

							{allowActions.canValidate === true &&
								<>

									<button
										className='btn btn-outline-primary d-flex align-items-center justify-content-center mb-4 w-100'
										type='button'
										onClick={() => setShowAccept(true)}
									>
										<FormattedMessage id='finalReport.accept.button' />
									</button>
									<button
										className='btn btn-primary d-flex align-items-center justify-content-center w-100'
										type='button'
										onClick={() => setShowReject(true)}
									>
										<FormattedMessage id='finalReport.reject.button' />
									</button>
								</>

							}
							{ formikInitialValues?.processState === ProcessState.APPROVED && (
								isEntityUser() || (
									formikInitialValues.paymentState !== ProcessState.DRAFT
									&& formikInitialValues.paymentState !== undefined
								)
							) &&
								<>
									<Button variant='outline-primary' onClick={
										isLastReport
											? goToFinalPaymentRequest
											: goToSemestralPaymentRequest
									}>
										{!hasCurrentPaymentRequest() && <><FormattedMessage id={'all.new'}/>&nbsp;</>}
										<FormattedMessage id={
											isLastReport
												? 'finalPaymentRequestForm.title'
												: 'semestralReport.action.requestPayment'
										} />
									</Button>
								</>
							}
							{hasRejectedPaymentRequests() &&
								<button
									className='btn btn-outline-primary d-flex align-items-center justify-content-center mb-4 w-100 mt-3'
									onClick={() => setShowRejectedPRs(true)}
								>
									<FormattedMessage id={'semestralReport.action.rejectedPaymentList'}/>
								</button>
							}
						</div>
					</Col>
				</Row>
			</Container>
		</SubTemplate>
	);
}
