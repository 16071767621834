import {Formik} from 'formik';
import React, {useState} from 'react'
import {Button, Form} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import {FormattedMessage} from 'react-intl';
import {DocumentType} from '../../models/DocumentType';
import {MultipleUploadArea} from '../MultipleUploadArea';
import {Arrays} from "../../utils/Arrays";
import {CandidatureFinancialUtils} from "../../utils/CandidatureFinancialUtils";
import {currencyFormat, maxFixed} from "../../utils/CurrencyUtils";
import {ConfigObject} from "../../config";

export function AccretionViewer({investment, plan, accretionTotal,index,noTitle}) {
    return (
        <React.Fragment key={index}>
            {!noTitle &&(
            <h5 className='text-secondary mt-4'>
                <FormattedMessage id={`candidatureForm.accretion.resume`} />
            </h5>

            )}

        <table className='w-100 mb-5 mt-4 d-table table'>
            <thead>
            <tr>
                <th><FormattedMessage id={'candidatureForm.accretion.investment'}/></th>

                <th className='w-40'>{currencyFormat(parseFloat(plan.globalTotal))}</th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td><strong><FormattedMessage id={'projectdescription.owncapitals'}/></strong></td>
                <td>
                    {currencyFormat(CandidatureFinancialUtils.getOwnCapitalTotalAbsolute(investment))}
                </td>
            </tr>
            <tr>
                <td><strong><FormattedMessage id={'candidatureForm.accretion.financial'}/></strong></td>
                <td>
                    {currencyFormat(isNaN(investment.iefpAskedAmount) ? 0 : maxFixed(parseFloat(investment.iefpAskedAmount) > parseFloat(ConfigObject.get().maxFinancedValue)? parseFloat(ConfigObject.get().maxFinancedValue) :  parseFloat(investment.iefpAskedAmount) ))}
                    ({CandidatureFinancialUtils.calculateIefpGivePercentage(investment)}%)
                </td>
            </tr>
            <tr>
                <td><FormattedMessage id={'candidatureForm.accretion.nonRefundableSubsidy'}/></td>
                <td>
                    {currencyFormat(CandidatureFinancialUtils.calculateNotRefundAbsolute(investment,plan,accretionTotal))}
                </td>
            </tr>

            {CandidatureFinancialUtils.calculateNotRefundAbsolute(investment,plan,accretionTotal) !== investment.investmentSupport && (
                <React.Fragment>
                    <tr>
                        <td className='ps-5'><FormattedMessage id={'accretion.title'}/></td>
                        <td>
                            {currencyFormat(CandidatureFinancialUtils.calculateNotRefundAbsolute(investment,plan,accretionTotal))} *
                            ({ parseFloat(accretionTotal)} %)
                            = {currencyFormat(maxFixed(CandidatureFinancialUtils.calculateNotRefundAbsolute(investment,plan,accretionTotal) * accretionTotal / 100))}
                        </td>
                    </tr>
                    <tr>
                        <td className='text-end'><strong><FormattedMessage id={'candidatureForm.accretion.nonRefundableSubsidy.total'}/></strong></td>
                        <td>
                            {currencyFormat(maxFixed(parseFloat(CandidatureFinancialUtils.calculateNotRefundAbsolute(investment,plan,accretionTotal)) + parseFloat(maxFixed(CandidatureFinancialUtils.calculateNotRefundAbsolute(investment,plan,accretionTotal) * accretionTotal / 100))))}
                        </td>
                    </tr>
                    <tr>
                        <td><FormattedMessage id={'candidatureForm.accretion.interestFreeLoan'}/></td>
                        <td>

                            {currencyFormat(CandidatureFinancialUtils.getInterestFreeLoan(investment,plan,accretionTotal))}
                        </td>
                    </tr>



                </React.Fragment>
            )}
            <tr>
                <td><FormattedMessage id={'candidatureForm.accretion.cpe'}/></td>
                <td>

                    {currencyFormat(investment.workStationsCreationBenefits)}
                </td>
            </tr>

            </tbody>
        </table>
        </React.Fragment>
    )

}