import React from "react";
import {useNavigate} from "react-router-dom";
import {IrregularityState} from "./IrregularityState";
import {FormattedMessage} from "react-intl";

export function IrregularityEntry({irregularity, irregularityCauseToRegulationMap}) {
    const navigate = useNavigate();

    return (
        <tr style={{cursor: 'pointer'}} onClick={() => navigate('irregularidade/' + irregularity.externalId)}>
            <td>{irregularity.leadsToAidSuspension ? 'Sim' : 'Não'}</td>
            <td>{irregularity.deadline}</td>
            <td><FormattedMessage id={'candidatureTabs.oversight.tabs.irregularities.stateCol.' + irregularity.state}/></td>
            <td className={'text-truncate'}>
                <ul className="list-inline text-truncate">
                    {irregularity.causes.map(c =>
                        <li key={irregularity.externalId + c} className="list-inline-item">
                            <small>{irregularityCauseToRegulationMap[c]}</small>
                        </li>
                    )}
                </ul>
            </td>
        </tr>
    );
}