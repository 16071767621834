import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { FaInfoCircle } from 'react-icons/fa';
import { FormattedMessage, useIntl } from 'react-intl';
import { CandidatureFormUtils } from '../../utils/CandidatureFormUtils';
import { MultipleUploadArea } from '../MultipleUploadArea';
import { SingleUploadArea } from '../SingleUploadArea';

export function CandidatureFormStep19({
  values,
  setFieldValue,
  documentTypes,
  candidatureDocuments,
  setCandidatureDocuments,
  handleSave,
  handleGoBack,
  setError,
  numberOfSteps
}) {
  const intl = useIntl();
  //const [files, setFiles] = useState(getInitialFiles());

  //const filteredDocumentTypes = getDocumentTypes();


  values.step19Documents = CandidatureFormUtils.getStep19Documents(values, candidatureDocuments);
  // function getDocumentTypes() {
  //   let optionalDocumentTypes = [
  //     'AUTHORIZED_SELLER_DECLARATION',
  //     'OWNERSHIP_COPY',
  //     'SPACE_ALLOCATION_PROOF',
  //     'CONSTRUCTIONS_AUTHORIZATION',
  //     'PROMISSORY_CONTRACT_COPY',
  //     'FRANCHISE_AGREEMENT_COPY',
  //     '8.7_DECLARATION',
  //     'MINIMIS_DECLARATION',
  //     'BUDGETS_INVOICES'
  //   ];

  //   let documentTypesAux = [];
  //   optionalDocumentTypes.forEach((oD) => {
  //     documentTypesAux.push(documentTypes.filter((dt) => dt.description === oD)[0]);
  //   });
  //   return documentTypesAux;
  // }

  // function getInitialFiles() {
  //   let files = [];

  //   let candidatureDocumentsTypes = candidatureDocuments.map((cd) => cd.documentType);

  //   filteredDocumentTypes.forEach((fdt) => {
  //     if (candidatureDocumentsTypes.includes(fdt.description)) {
  //       const document = candidatureDocuments.find((cd) => cd.documentType === fdt.description);
  //       files.push({
  //         externalId: document.externalId,
  //         documentType: document.documentType,
  //         documentPath: document.documentPath,
  //         name: document.name,
  //         content: '',
  //         submited: true,
  //         error: ''
  //       });
  //     } else {
  //       files.push({
  //         externalId: '',
  //         documentType: fdt.description,
  //         name: '',
  //         content: null,
  //         submited: false,
  //         error: ''
  //       });
  //     }
  //   });
  //   return files;
  // }

  function handleNextStep() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    handleSave(true);
  }

  return (
    <div>
      <h4 className='text-primary mb-0'>
        <FormattedMessage id='candidatureForm.regroupRequiredDocuments' />
      </h4>
      <small className='text-secondary text-uppercase'>
        {intl.formatMessage(
          { id: 'all.step' },
          { step: values.step, numberOfSteps: numberOfSteps }
        )}
      </small>

      <Row>
        <Col>
          <p className='mt-3 mb-1 text-secondary infoText'>
            <FaInfoCircle size={40} />
            <FormattedMessage id='candidatureForm.step7.info' />
          </p>
        </Col>
      </Row>

      <Row>
        <Col>
          {/* <div className='mt-3'>
            {filteredDocumentTypes.map((dt, index) => (
              <div key={index}>
                <SingleUploadArea
                  documents={files}
                  setDocuments={setFiles}
                  documentType={dt.description}
                  setError={setError}
                  candidatureExternalId={values.externalId}
                  setFieldValue={setFieldValue}
                />
              </div>
            ))}
          </div> */}
          {CandidatureFormUtils.getStep19RequiredDocumentTypes(values, candidatureDocuments).map(
            (dt, index) => (
              <div key={index}>
                {dt === 'DISTRICT_APPLICATION' ? (
                  <MultipleUploadArea
                    documents={candidatureDocuments}
                    setDocuments={setCandidatureDocuments}
                    setFieldValue={setFieldValue}
                    documentType={'DISTRICT_APPLICATION'}
                    candidatureExternalId={values.externalId}
                    labelId={'documentType.DISTRICT_APPLICATION'}
                    index={1}
                    candidatureFormStep={values.step}
                  />
                ) : (
                  <SingleUploadArea

                    documents={values.step19Documents}
                    setFieldValue={setFieldValue}
                    candidatureDocuments={candidatureDocuments}
                    setCandidatureDocuments={setCandidatureDocuments}
                    documentType={dt}
                    setError={setError}
                    candidatureExternalId={values.externalId}
                  />
                )}
              </div>
            )
          )}
        </Col>
      </Row>

      <div className='form-actions mt-5'>
        <button
          className='btn btn-link'
          type='button'
          onClick={() => handleGoBack(values, setFieldValue)}>
          <FormattedMessage id='all.back' />
        </button>

        <button className='btn btn-primary' type='button' onClick={() => handleNextStep()}>
          <FormattedMessage id='all.nextStep' />
        </button>
      </div>
    </div>
  );
}
