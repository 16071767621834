import React, {useEffect, useState} from 'react';
import {Button, Col, Container, Row} from 'react-bootstrap';
import {FormattedMessage, useIntl} from 'react-intl';
import {useNavigate, useParams} from 'react-router-dom';

import {AlertSuccess} from '../../components/bootstrap/AlertSuccess';
import {EnumSelectField} from '../../components/bootstrap/EnumSelectField';
import {LabelToolTip} from '../../components/bootstrap/LabelToolTip';
import {CustomPagination} from '../../components/general/CustomPagination';
import {EmptyResults} from '../../components/general/EmptyResults';
import Loading from '../../components/general/Loading';
import {SubTemplate} from '../../components/general/SubTemplate';
import {Arrays} from '../../utils/Arrays';
import {handleError, isNotBusinessError} from '../../utils/handleError';
import {listAccreditationRevocations} from "../../rest/accreditationRevocation";
import {isIEFPUser} from "../../authentication/authenticationHelper";
import {AlertError} from "../../components/bootstrap/AlertError";
import {AccreditationRevocationState} from "../../models/AccreditationRevocationState";
import {AccreditationRevocationRequestValidation} from "../public/accreditationRevocation/AccreditationRevocationRequestValidation";

const MAX_RESULTS = 25;
const DEFAULT_INITIAL_PAGE = 0;

export function ListAccreditationRevocation() {
    const intl = useIntl();
    const {nif} = useParams();

    const navigate = useNavigate();

    const [accreditationRevocations, setAccreditationRevocations] = useState(null);
    const [showCreateDialog, setShowCreateDialog] = useState(false);

    const [filters, setFilters] = useState({
        type: '-1',
        state: '-1',
        ordination: 'ASC',
    });
    const [currentPage, setCurrentPage] = useState(DEFAULT_INITIAL_PAGE);

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const [lsItem, setLsItem] = useState(null);

    useEffect(() => {
        fetchData();
    }, [currentPage]);

    useEffect(() => {
        checkStorageItems();
    })

    async function fetchData() {
        try {
            const {data: accreditations} = await listAccreditationRevocations(
                currentPage,
                MAX_RESULTS,
                filters, nif
            );
            setAccreditationRevocations(accreditations)
        } catch (error) {
            setError(error);
        } finally {
            setLoading(false);
        }
    }

    const onPageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    function handleSearch() {
        fetchData();
    }

    function handleOrdinationChange(e) {
        filters.ordination = e.target.value;
        setFilters({...filters});

        if (e.target.value === 'ASC') {
            accreditationRevocations.list = accreditationRevocations.list.sort(
                (a, b) => new Date(a.submissionDate) - new Date(b.submissionDate)
            );
        }

        if (e.target.value === 'DESC') {
            accreditationRevocations.list = accreditationRevocations.list.sort(
                (a, b) => new Date(b.submissionDate) - new Date(a.submissionDate)
            );
        }

        setAccreditationRevocations({...accreditationRevocations});
    }

    if (loading) {
        return <Loading/>;
    }

    if (error && isNotBusinessError(error)) {
        return handleError(error);
    }

    function handleChangeRedirect(revocation) {
        navigate(`/revogacoesEA/${nif}/${revocation.externalId}`);
    }

    function checkStorageItems() {
        [
            'accreditationRevocationCreated',
        ].forEach((s) => {
            if (sessionStorage.getItem(s)) {
                setLsItem(s);
            }
        });


    }

    return (
        <SubTemplate titleId={'accreditationRevocation.list'} hasBackButton>
            <Container>
                <div className='mx-2 mb-4'>
                    <AlertSuccess lsItem={lsItem}/>
                </div>

                {showCreateDialog && (
                    <AccreditationRevocationRequestValidation
                        isApproval={false}
                        handleClose={() => setShowCreateDialog(false)}
                    />
                )}


                <Row className='searchResults'>
                    <Col md='10'>
                        <AlertError error={error}/>

                        {Arrays.isNotEmpty(accreditationRevocations.list) ? (
                            <>
                                <Row className='mt-5 mb-1 d-flex justify-content-between px-2'>
                                    <Col className='text-muted'>
                                        {accreditationRevocations.totalResults}{' '}
                                        <FormattedMessage
                                            id={`all.results.lowCase.${accreditationRevocations.totalResults === 1 ? 'singular' : 'plural'}`}/>
                                    </Col>
                                    <Col md='3'>
                                        <EnumSelectField
                                            controlId={'ordination'}
                                            preffixDescriptionId={'orderType'}
                                            name={'ordination'}
                                            handleChange={(e) => handleOrdinationChange(e)}
                                            options={['ASC', 'DESC']}
                                            value={filters.ordination}
                                        />
                                    </Col>
                                </Row>

                                <ul className='results'>
                                    {accreditationRevocations.list.map((revocation, index) => (
                                        <li key={index}>
                                            <Row className={'py-2'} onClick={() => handleChangeRedirect(revocation)}>
                                                <Col
                                                    md='6'
                                                    className='d-flex align-items-center text-secondary'
                                                >
                                                    <LabelToolTip
                                                        labelId='priorSupport.state'
                                                        value={intl.formatMessage({
                                                            id: `accreditationRevocationState.${revocation.state}`,
                                                        })}
                                                    />
                                                </Col>
                                                <Col
                                                    md='6'
                                                    className='d-flex align-items-center justify-content-end text-secondary'
                                                >
                                                    <LabelToolTip
                                                        labelId='candidatureTabs.submissionDate'
                                                        value={revocation.submissionDate}
                                                    />
                                                </Col>
                                            </Row>
                                        </li>
                                    ))}
                                </ul>
                            </>
                        ) : (
                            <EmptyResults/>
                        )}
                    </Col>
                    <Col md={2}>
                        {accreditationRevocations?.list.filter(accreditationRevocation => AccreditationRevocationState.REJECTED !== accreditationRevocation.state).length === 0 && isIEFPUser() &&


                        <Button type="submit"
                                variant="btn btn-primary d-flex align-items-center justify-content-center mb-4 w-100"
                                onClick={() => setShowCreateDialog(true)}>
                            <FormattedMessage id='accreditationRevocation.add'/>
                        </Button>


                        }


                    </Col>
                </Row>
                <Row className='mb-5'>
                    <Col className='d-flex justify-content-center'>
                        <CustomPagination
                            dataPerPage={MAX_RESULTS}
                            totalData={accreditationRevocations.totalResults}
                            onPageChange={onPageChange}
                            currentPage={currentPage}
                        />
                    </Col>
                </Row>
            </Container>
        </SubTemplate>
    );
}
