import { Formik } from 'formik';
import React, {useEffect, useState} from 'react';
import {Button, Col, Form, Row, Table} from 'react-bootstrap';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import {FormattedMessage, useIntl} from 'react-intl';
import { Link } from 'react-router-dom';
import * as yup from 'yup';
import {TranslateBoolean} from "../../utils/TranslateBoolean";
import {EditorInputField} from "../bootstrap/EditorInputField";
import {SyncMultipleUploadArea} from "../SyncMultipleUploadArea";
import {DocumentType} from "../../models/DocumentType";
import {TextInputField} from "../bootstrap/TextInputField";
import {TextAreaInputField} from "../bootstrap/TextAreaInputField";
import {EnumSelectField} from "../bootstrap/EnumSelectField";
import {MceActivityType} from "../../models/MceActivityType";
import {getDistricts} from "../../rest/administrativeDivision";
import {getEntityDistricts} from "../../rest/entity";
import {Arrays} from "../../utils/Arrays";
import {NumberInputField} from "../bootstrap/NumberInputField";
import {Validator} from "../../utils/Validator";

export function EntityChangeContactForm({
  entityChange,
  onSubmit,
    documents,
    setDocuments,
    setError
}) {


  const intl = useIntl();







  const schema = yup.object().shape({
    reason: yup
        .string()
        .required(
            <FormattedMessage id='errors.fieldRequiredText' />
        ),
    address: yup
        .string()
        .required(<FormattedMessage id='errors.fieldRequiredText' />),

    postalCode4Digits: yup
        .string()
        .required(<FormattedMessage id='errors.fieldRequiredText' />)
        .test(
            'isPostalCode4DigitsValid',
            <FormattedMessage id='errors.eaAccreditationForm.postalCode4Digits' />,
            (value) => Validator.validateFixedSizeNumber(value, 4)
        ),

    postalCode3Digits: yup
        .string()
        .required(<FormattedMessage id='errors.fieldRequiredText' />)
        .test(
            'isPostalCode3DigitsValid',
            <FormattedMessage id='errors.eaAccreditationForm.postalCode3Digits' />,
            (value) => Validator.validateFixedSizeNumber(value, 3)
        ),

    location: yup
        .string()
        .required(<FormattedMessage id='errors.fieldRequiredText' />),

    phone: yup
        .string()
        .required(<FormattedMessage id='errors.fieldRequiredText' />)
        .test(
            'isPhoneValid',
            <FormattedMessage id='errors.eaAccreditationForm.phoneAndMobile' />,
            (value) => Validator.validateCommsNumber(value)
        ),

    email: yup
        .string()
        .required(<FormattedMessage id='errors.fieldRequiredText' />)
        .test(
            'isEmailValid',
            <FormattedMessage id='errors.candidatureForm.email' />,
            (value) => Validator.validateEmail(value)
        ),




  });


  return (
    <Formik
      initialValues={entityChange}
      validationSchema={schema}
      validateOnBlur={false}
      validateOnChange={false}
      onSubmit={(values, { setSubmitting, setErrors }) => {
        onSubmit(values, setSubmitting, setErrors);
      }}
    >
      {({ values, errors, touched, handleChange, handleSubmit,setFieldValue }) => (
        <form onSubmit={handleSubmit}>
          <Row>
            <Col>
              <TextAreaInputField
                  controlId={'reason'}
                  labelId={'entityChange.reason'}
                  name={'reason'}
                  handleChange={handleChange}
                  value={values?.reason}
                  isInvalid={errors.reason}
                  errorMessage={errors.reason}
                  maxLength={1000}
              />
            </Col>
          </Row>

          <Row>
          <Col>
                <SyncMultipleUploadArea
                    name='files'
                    documents={documents}
                    setDocuments={setDocuments}
                    documentType={DocumentType.REASON_ENTITY_CHANGE}
                />
            </Col>
          </Row>

          <Row>
            <Col md='12'>
              <TextInputField
                  labelId={'eaAccreditationForm.step1.address'}
                  name={'address'}
                  handleChange={handleChange}
                  value={values.address}
                  isInvalid={errors.address}
                  errorMessage={errors.address}
              />
            </Col>

          </Row>


          <Row className='align-items-end'>
            <Col md='4'>
              <Form.Label>
                <FormattedMessage id='eaAccreditationForm.step1.postalCode' />
              </Form.Label>
              <div className='d-inline d-flex align-items-center'>
                <TextInputField
                    name={'postalCode4Digits'}
                    handleChange={handleChange}
                    value={values.postalCode4Digits}
                    isInvalid={errors.postalCode4Digits}
                    errorMessage={errors.postalCode4Digits}
                    maxLength={4}
                />
                <span className='ms-2 me-2'>{'-'}</span>
                <TextInputField
                    name={'postalCode3Digits'}
                    handleChange={handleChange}
                    value={values.postalCode3Digits}
                    isInvalid={errors.postalCode3Digits}
                    errorMessage={errors.postalCode3Digits}
                    maxLength={3}
                />
              </div>
            </Col>
            <Col md='8'>
              <TextInputField
                  placeholder={intl.formatMessage({
                    id: 'eaAccreditationForm.step1.location.placeholder',
                  })}
                  name={'location'}
                  handleChange={handleChange}
                  value={values.location}
                  isInvalid={errors.location}
                  errorMessage={errors.location}
              />
            </Col>
          </Row>



          <Row>
            <Col md='8'>
              <TextInputField
                  labelId={'eaAccreditationForm.step1.email'}
                  name={'email'}
                  handleChange={handleChange}
                  value={values.email}
                  isInvalid={errors.email}
                  errorMessage={errors.email}
              />
            </Col>

            <Col md='4'>
              <NumberInputField
                  labelId={'eaAccreditationForm.step1.phone'}
                  name={'phone'}
                  handleChange={handleChange}
                  value={values.phone}
                  isInvalid={errors.phone}
                  errorMessage={errors.phone}
                  maxLength={9}
              />
            </Col>
          </Row>



          <div className='form-actions'>
            <div className='mr-auto'>
              <Link to={'/templates'} variant='secondary'>
                <FormattedMessage id='all.backButtonText' />
              </Link>
            </div>
            <div className='ml-auto'>
              <Button variant='primary' type='submit'>
                <FormattedMessage id='all.submitButtonText' />
              </Button>
            </div>
          </div>
        </form>
      )}
    </Formik>
  );
}
