import React, { useEffect, useState } from 'react';
import { FaEdit, FaTrashAlt } from 'react-icons/fa';
import { TiPlus } from 'react-icons/ti';
import { FormattedMessage } from 'react-intl';
import { Link, Outlet } from 'react-router-dom';

import Loading from '../../../components/general/Loading';

import { DeleteDialog } from '../../../components/bootstrap/DeleteDialog';
import { SubTemplate } from '../../../components/general/SubTemplate';
import {
  deleteActionNotification,
  getActionNotificationsMetaData,
} from '../../../rest/templates';
import { handleError, isBusinessError } from '../../../utils/handleError';

export function ListActionNotifications() {
  const [actionNotificationsMetaData, setActionNotificationsMetaData] =
    useState([]);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [show, setShow] = useState(false);
  const [toDelete, setToDelete] = useState(null);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const fetchData = async () => {
    try {
      const { data: metaData } = await getActionNotificationsMetaData();

      setActionNotificationsMetaData(metaData);
      setLoading(false);
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const deleteNotification = async (code) => {
    try {
      const { data: deletedActionNotification } =
        await deleteActionNotification(code);

      setActionNotificationsMetaData(
        actionNotificationsMetaData.filter(
          (p) => p.code !== deletedActionNotification.code
        )
      );
      setLoading(false);
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  };

  const handleOnClickDelete = (actionNotification) => {
    setToDelete(actionNotification);
    handleShow();
  };

  const handleConfirmDelete = () => {
    deleteNotification(encodeURIComponent(toDelete.code));
    handleClose();
  };

  if (loading) {
    return <Loading />;
  }

  if (error && !isBusinessError(error)) {
    return handleError(error);
  }

  return (
      <SubTemplate hasBackButton titleId={'template.manage'}>
        <div className={'container'}>
          <div className={'row'}>
            <div className={'col-md-9'}>
              <ul className={'results'}>
                {actionNotificationsMetaData.map((actionNotification) => (
                  <li key={actionNotification.code}>
                    <div className={'row my-3'}>
                      <div className={'col-md-8'}>{actionNotification.description}</div>

                      <div className={'col-md-4 text-right'}>
                        {!actionNotification.systemOperational && (
                          <span>
                            <button
                              onClick={() => handleOnClickDelete(actionNotification)}
                              className={'btn btn-link mr-3'}>
                              <FaTrashAlt />
                            </button>
                          </span>
                        )}
                        <Link
                          to={`/notificacoes/${encodeURIComponent(
                            actionNotification.code
                          )}/editar`}>
                          <FaEdit />
                        </Link>
                      </div>
                    </div>
                  </li>
                ))}
                <DeleteDialog
                  show={show}
                  handleClose={handleClose}
                  handleConfirmDelete={handleConfirmDelete}
                />
              </ul>
            </div>
            <div className='col-md-3'>
              <div className='mx-2 mb-4'>
                <Link to={`/notificacoes/adicionar`} className={'btn btn-outline-primary mb-3'}>
                  <TiPlus aria-hidden='true' />
                  <FormattedMessage id='template.add' />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </SubTemplate>
  );
}
