import React, { createRef } from 'react';
import { useGlobalContext } from '../../context';
import {cleanAccessInfo, getAccessInfo, isTokenExpired} from "../../authentication/authenticationHelper";

export function AuthenticatedDownloadLink({ url, filename, children, className }) {
  const link = createRef();


  const classes = ['file-download'];
  classes.push(className)

  const handleAction = async () => {
    if (isTokenExpired()) {
      cleanAccessInfo();
    }


    if (link.current.href) {
      return;
    }
    const headers = {};



    headers.Authorization = `Bearer ${getAccessInfo().access_token}`;

    const result = await fetch(url, { headers: headers });

    const blob = await result.blob();
    const href = window.URL.createObjectURL(blob);

    link.current.download = filename;
    link.current.href = href;

    link.current.click();
  };

  return (
    <>
      <a
        role='button'
        className={classes.join(' ')}
        ref={link}
        onClick={handleAction}
      >
        {children}
      </a>
    </>
  );
}
