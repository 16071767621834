import React, {useEffect, useState} from 'react';
import {Col, Container, Row} from 'react-bootstrap';
import Loading from '../../../components/general/Loading';
import {SubTemplate} from '../../../components/general/SubTemplate';
import {
    listEntityEconomicViability
} from '../../../rest/consultingRequest';
import {handleError, isNotBusinessError} from '../../../utils/handleError';
import {FormattedMessage} from "react-intl";
import data from "bootstrap/js/src/dom/data";
import {Link} from "react-router-dom";


export function ListEconomicViabilityRequests({entityNif}) {
    const [economicViabilityRequest, setEconomicViabilityRequest] = useState([]);


    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);


    useEffect(() => {
        fetchData();
    },[]);

    const fetchData = async () => {
        try {
            const {data: requests} =

                await listEntityEconomicViability(
                    entityNif
                );

            setEconomicViabilityRequest(requests);
        } catch (error) {
            setError(error);
        } finally {
            setLoading(false);
        }
    };

    if (loading) {
        return <Loading/>;
    }




    if (error && isNotBusinessError(error)) {
        return handleError(error);
    }

    function getLinkPath(dataObj) {
        if(dataObj.code ==='NOT'){
            return `/candidaturas/${entityNif}/OPINION/${dataObj.description}`;
        }
        return `/viabilidadeEconomica/lista/${entityNif}/SUBMITTED`;
    }

    return (
        <SubTemplate titleId='economicViability.list.title' hasBackButton internal={entityNif !== undefined}>
            <Container>
                <ul className='results'>
                    {economicViabilityRequest.map((dataObj, index) => (
                        <li key={index}>
                            <Row className={dataObj.counter>0 ? 'py-3' : 'notPointer py-3'} >
                                <Col md={10}>
                                    {(dataObj.counter>0) ? (
                                            <Link to={getLinkPath(dataObj)}>
                                                {dataObj.code ==='NOT'   ?
                                                    (
                                                        <FormattedMessage
                                                            id={`economicViabilityRequest.state.${
                                                                dataObj.description
                                                            }`}
                                                        />
                                                        )
                                                    :
                                                    (<FormattedMessage id={'economicViabilityRequest.status.FINISH'}/>)
                                                }
                                            </Link>
                                        )

                                        :
                                        (
                                           <>
                                               {dataObj.code ==='NOT'   ?
                                                   (
                                                       <FormattedMessage
                                                           id={`economicViabilityRequest.state.${
                                                               dataObj.description
                                                           }`}
                                                       />
                                                   )
                                                   :
                                                   (<FormattedMessage id={'economicViabilityRequest.status.FINISH'}/>)
                                               }
                                           </>


                                        )

                                    }

                                </Col>
                                <Col md={2} className={'text-end pe-4'}>{dataObj.counter} </Col>
                            </Row>
                        </li>
                    ))}

                </ul>



            </Container>
        </SubTemplate>
    );
}
