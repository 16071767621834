import React from 'react';
import { Col, Row, Table } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { FunctionType } from '../../models/FunctionType';
import { RemunerationType } from '../../models/RemunerationType';
import { CandidatureFinancialUtils } from '../../utils/CandidatureFinancialUtils';
import { CandidatureFormUtils } from '../../utils/CandidatureFormUtils';
import DecimalInputField from '../bootstrap/DecimalInputField';
import { TextAreaInputField } from '../bootstrap/TextAreaInputField';
import ToolTip from "../bootstrap/ToolTip";
import {currencyFormat} from "../../utils/CurrencyUtils";

export function CandidatureFormStep12({
  errors,
  setFieldValue,
  handleChange,
  values,
  handleSave,
  handleGoBack,
  numberOfSteps
}) {
  const KEYS = [...FunctionType.values(), 'TOTAL'];

  const BOLD_KEYS = 'TOTAL';

  const YEARS = [
    ...new Set(values.peopleRemunerationList[0].peopleRemunerationCellList.map((prcl) => prcl.year))
  ];

  const intl = useIntl();

  function getPeopleRemunerationCell(year, fieldName, remunerationType) {
    return values.peopleRemunerationList[0].peopleRemunerationCellList.find(
      (prcl) =>
        prcl.year === year &&
        prcl.functionType === fieldName &&
        prcl.remunerationType === remunerationType
    );
  }

  function handlePoepleRemunerationChange(e, year, fieldName, remunerationType) {
    getPeopleRemunerationCell(year, fieldName, remunerationType).value = e.target.value;

    setFieldValue('peopleRemunerationList', [...values.peopleRemunerationList]);
  }

  function handleReasonChange(e) {
    values.peopleRemunerationList[0].reasonToRemuneration = e.target.value;
    setFieldValue('peopleRemunerationList', [...values.peopleRemunerationList]);
  }

  function getPeopleRemunerationCellValue(year, fieldName, remunerationType) {
    return getPeopleRemunerationCell(year, fieldName, remunerationType).value;
  }

  function getTableRowsByKey(key, remunerationType) {
    return (
      <tr key={key}>
        <td className={`w-20 ${BOLD_KEYS.includes(key) && 'table-item-bold'}`}>
          <FormattedMessage id={`functionType.${key}`} />
        </td>
        {YEARS.map((year,index) => (

            <React.Fragment key={index}>
            {!CandidatureFormUtils.is3YearsCoverageOnly(values, year) && (
              <td className='w-10'>
                {key === 'TOTAL' ? (
                  <div className='text-center'>
                    {currencyFormat(CandidatureFinancialUtils.calculatePeopleRemunerationTotalValue(
                      values,
                      year,
                      remunerationType
                    ))}
                  </div>
                ) : (
                  <DecimalInputField
                    controlId={`peopleRemunerationCell.${key}`}
                    name={`peopleRemunerationCell.${key}`}
                    value={getPeopleRemunerationCellValue(year, key, remunerationType)}
                    handleChange={(e) =>
                      handlePoepleRemunerationChange(e, year, key, remunerationType)
                    }
                    suffixLabel='€'
                  />
                )}
              </td>
            )}
            </React.Fragment>
        ))}
      </tr>
    );
  }

  function handleNextStep() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    handleSave(true);
  }

  return (
    <div>
      <h4 className='text-primary mb-0'>
        <FormattedMessage id='candidatureForm.peopleRemuneration.title' />
      </h4>
      <small className='text-secondary text-uppercase'>
        {intl.formatMessage(
          { id: 'all.step' },
          { step: values.step, numberOfSteps: numberOfSteps }
        )}
      </small>

      {RemunerationType.values().map((rt,index) => (
        <Row key={index}>
          <Col>
            <h5 className='text-secondary mt-4'>
              <FormattedMessage id={`remunerationType.${rt}`} />
            </h5>
            <Table key={rt} className='mt-4'>
              <thead>
                <tr>
                  <th className='w-20'>
                    <FormattedMessage id='candidatureForm.peopleBoard.functions' />
                  </th>
                  {YEARS.map((year,index1) => (
                    <React.Fragment key={index1}>
                      {!CandidatureFormUtils.is3YearsCoverageOnly(values, year) && (
                        <th className='w-10 text-center' key={year}>
                          {CandidatureFormUtils.getYearLabel(values, year)}
                        </th>
                      )}
                    </React.Fragment>
                  ))}
                </tr>
              </thead>
              <tbody>{KEYS.map((key) => getTableRowsByKey(key, rt))}</tbody>
            </Table>
          </Col>
        </Row>
      ))}

      <>
        <Row>
          <Col md='12'>
            <TextAreaInputField
              labelId={'candidatureForm.peopleRemuneration.reasonToRemuneration'}
              name={'candidatureForm.peopleRemuneration.reasonToRemuneration'}
              value={values.peopleRemunerationList[0].reasonToRemuneration}
              handleChange={(e) => handleReasonChange(e)}
              placeholder={intl.formatMessage({ id: 'all.maxLength.placeholder' })}
              maxLength={10000}
              isInvalid={
                errors.peopleRemunerationList
                  ? errors.peopleRemunerationList[0]?.reasonToRemuneration
                  : false
              }
              errorMessage={
                errors.peopleRemunerationList
                  ? errors.peopleRemunerationList[0]?.reasonToRemuneration
                  : ''
              }
            />

          </Col>
        </Row>

        <div className='form-actions mt-5'>
          <button
            className='btn btn-link'
            type='button'
            onClick={() => handleGoBack(values, setFieldValue)}>
            <FormattedMessage id='all.back' />
          </button>

          <button className='btn btn-primary' type='button' onClick={() => handleNextStep()}>
            <FormattedMessage id='all.nextStep' />
          </button>
        </div>
      </>
    </div>
  );
}
