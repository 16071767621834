import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { isBackOfficeAdmin, isIEFPUser } from '../../authentication/authenticationHelper';
import { DocumentType } from '../../models/DocumentType';
import { EnumSelectField } from '../bootstrap/EnumSelectField';
import { AuthenticatedDownloadLink } from '../document/AuthenticatedDownloadLink';
import {Link} from "react-router-dom";

export function DocumentationTab({ documents }) {
  const [candidatureDocuments, setCandidatureDocuments] = useState([
    {groupedName:'REGISTER',documents:documents.registerDocuments},
    {groupedName:'CANDIDATURE',documents:documents.beforeSubmission},
    {groupedName:'IEFP',documents:documents.iefpDocuments},
    {groupedName:'PROMOTER',documents:documents.promoterDocuments}
  ]);



  function handleChange(e) {
    switch (e.target.value) {
      case 'CANDIDATURE':
          setCandidatureDocuments( [{groupedName:'CANDIDATURE',documents:documents.beforeSubmission}]);
        break;

      case 'IEFP':
        setCandidatureDocuments( [{groupedName:'IEFP',documents:documents.iefpDocuments}]);
        break;

      case 'PROMOTER':
        setCandidatureDocuments( [{groupedName:'PROMOTER',documents:documents.promoterDocuments}]);
        break;

      case 'REGISTER':
        setCandidatureDocuments( [{groupedName:'REGISTER',documents:documents.registerDocuments}]);
        break;

      case 'ALL':
        setCandidatureDocuments( [{groupedName:'REGISTER',documents:documents.registerDocuments},{groupedName:'CANDIDATURE',documents:documents.beforeSubmission},{groupedName:'IEFP',documents:documents.iefpDocuments},{groupedName:'PROMOTER',documents:documents.promoterDocuments}]);
        break;

      default:
        break;
    }
  }


  function getDocumentTypeFilterOptions() {
    let options = ['ALL'];
    options.push('REGISTER');

    options.push('CANDIDATURE');

    if(documents.iefpDocuments.length>0){
      options.push('IEFP')
    }
    if(documents.promoterDocuments.length>0) {
      options.push('PROMOTER');
    }

    return options;
  }

  return (
    <div className='mb-5'>
      <Row>
        <Col md='4'>
          <EnumSelectField
            labelId={'candidatureTabs.documentation.filter'}
            controlId={'documentTypeFilter'}
            name={'documentTypeFilter'}
            preffixDescriptionId={'candidatureTabs.documentation'}
            handleChange={(e) => handleChange(e)}
            options={getDocumentTypeFilterOptions()}
          />
        </Col>
      </Row>

      {candidatureDocuments.map((udt, index) => (
          <>
          {udt.documents.length>0 &&
          <div key={index} className='mt-5 border-1 border-top mb-3'>
            <small className='text-primary text-uppercase mt-2 mb-2 d-block'>
              <FormattedMessage id={`candidatureTabs.documentation.${udt.groupedName}`} />
            </small>

            {udt.documents
                .map((d, index) => (
                    <div key={index} className={'mb-3'}>
                      <label className={'mb-0'}>
                        <FormattedMessage id={`documentType.show.${d.documentType}`} />
                      </label>
                      <small>{d.fileDescription===undefined ? '' : d.fileDescription+ ' - '}</small>
                      <AuthenticatedDownloadLink url={d.documentPath} filename={d.name}>
                        <small>{d.name}</small>
                      </AuthenticatedDownloadLink>

                      <small>
                        {
                          d.documentType === DocumentType.MESSAGE_ATTACHMENT ? (
                              <>
                                  &nbsp;
                                  <FormattedMessage id={'messages.messageText'} />
                                  <FormattedMessage id={'stateBadge.temporalStateAt'}
                                                    values={{
                                                      date: d.messageDate,
                                                      state: <FormattedMessage id={`message.suffix.direction.${d.messageDirection}`} />
                                  }} />
                              </>
                          ) : (
                              d.candidatureState !== undefined && (<> (<FormattedMessage id={`candidatureState.${d.candidatureState}`}/> - {d.creationTs}) </>)
                          )
                        }
                      </small>

                    </div>

                ))}
          </div>
          }
          </>

      ))}
    </div>
  );
}

export default DocumentationTab;
