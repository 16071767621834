import React, { useEffect, useRef, useState } from 'react';
import { Col, Container, Form, Row } from 'react-bootstrap';
import { FaCheck, FaRegCheckCircle, FaRegSave, FaTimes } from 'react-icons/fa';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';
import { isIEFPUser } from '../../../authentication/authenticationHelper';
import { AlertError } from '../../../components/bootstrap/AlertError';
import { CandidatureChangeForm } from '../../../components/candidatureChangeRequest/CandidatureChangeForm';
import { FormSideNavBar } from '../../../components/general/FormSideNavBar';
import Loading from '../../../components/general/Loading';
import { SubTemplate } from '../../../components/general/SubTemplate';
import { SuccessToast } from '../../../components/general/SuccessToast';
import {
	getCandidatureChangeRequestWithOrWithoutExternalId,
	listCandidatureChangeRequestDocuments,
	listCandidatureChangeRequestDocumentsByType,
	saveCandidatureChangeRequest,
	withdrawCandidatureChangeRequest,
} from '../../../rest/candidatureChangeRequest';
import { handleError, isNotBusinessError } from '../../../utils/handleError';
import * as yup from 'yup';
import { createCustomErrorMessage } from '../../../hooks/errorMessage';
import { withdrawPriorSupportPaymentRequest } from '../../../rest/priorSupportPaymentRequest';
import { ConfirmationAuthenticationModal } from '../../../components/general/ConfirmationAuthenticationModal';
import { Formik } from 'formik';
import { CandidatureChangeRequestValidation } from './CandidatureChangeRequestValidation';
import { DocumentType } from '../../../models/DocumentType';

const validationSchema = yup.object().shape({
	description: yup
		.string()
		.required(<FormattedMessage id='errors.fieldRequiredText' />),
});

export function CandidatureChange() {
	const {
		candidatureExternalId: candidatureExternalId,
		externalId: externalId,
	} = useParams();

	//errors
	const [error, setError] = useState(null);
	const [navErrors, setNavErrors] = useState([]);

	//modals
	const [showWithdrawModal, setShowWithdrawModal] = useState(false);
	const [showSubmitModal, setShowSubmitModal] = useState(false);
	const [showApproveModal, setShowApproveModal] = useState(false);
	const [showRejectModal, setShowRejectModal] = useState(false);

	//formik
	const [formikInitialValues, setFormikInitialValues] = useState(null);
	const formikPropsRef = useRef(null);

	//form control
	const [formStep, setFormStep] = useState(0);
	const [showSaveToast, setShowSaveToast] = useState(false);
	const [loading, setLoading] = useState(true);
	const [submited, setSubmited] = useState(false);

	const [documents, setDocuments] = useState([]);

	const navigate = useNavigate();
	const intl = useIntl();

	const tabsIds = ['candidature.changeCadidature.sideMenu.step1'];

	const isIEFP = isIEFPUser();

	async function fetchData() {
		try {
			let [{ data: candidatureChangeRequest }] = await Promise.all([
				await getCandidatureChangeRequestWithOrWithoutExternalId(
					candidatureExternalId,
					externalId
				),
			]);

			let { data: requestDocuments } =
				 await listCandidatureChangeRequestDocumentsByType(
					DocumentType.CANDIDATURE_CHANGE_ANNEX,
					candidatureExternalId,
					candidatureChangeRequest.externalId
				);

			let formDocuments = [];

			requestDocuments.forEach((d) => {
				let doc = {
					externalId: d.externalId,
					documentType: d.documentType,
					documentPath: d.documentPath,
					name: d.name,
					content: '',
					submited: true,
					error: '',
					fileDescription: d.fileDescription,
				};

				formDocuments.push(doc);
			});
			setDocuments(formDocuments);

			setFormikInitialValues(getFormikInitialValues(candidatureChangeRequest));
		} catch (error) {
			setError(error);
		} finally {
			setLoading(false);
		}
	}

	useEffect(() => {
		fetchData();
	}, []);

	function getFormikInitialValues(candidatureChangeRequest) {
		let initialValues = candidatureChangeRequest;

		initialValues.description ??= '';
		return initialValues;
	}

	function scrollPageToTop() {
		document.body.scrollTop = 0;
		document.documentElement.scrollTop = 0;
	}

	function handlePreviousStep() {
		scrollPageToTop();
		setFormStep(formStep - 1);
	}

	function handleNextStep() {
		scrollPageToTop();
		setFormStep(formStep + 1);
	}

	if (submited) {
			sessionStorage.setItem(
				'candidatureChangeRequestSubmited',
				submited
			);
			navigate(`/candidatura/${candidatureExternalId}/alterarCandidatura/${formikInitialValues.externalId}/detalhe`)
	}

	if (loading) {
		return <Loading />;
	}

	if (error && isNotBusinessError(error)) {
		return handleError(error);
	}

	async function handleWithdrawal(user, password, setModalError) {
		try {
			let authenticationCredentials = {
				user: user,
				password: password,
			};

			await withdrawCandidatureChangeRequest(
				candidatureExternalId,
				formikPropsRef.current.values.externalId,
				authenticationCredentials
			);

			sessionStorage.setItem('candidatureChangeRequestWithdrawed', true);
			navigate(`/candidatura/${candidatureExternalId}/acompanhamento/changes`)
		} catch (error) {
			setModalError(error);
		}
	}

	async function handleSave() {
		try {
			let { data: res } = await saveCandidatureChangeRequest(
				candidatureExternalId,
				formikPropsRef.current.values
			);

			let processedRes = getFormikInitialValues(res);

			formikPropsRef.current.setValues(processedRes);
			formikPropsRef.current.setErrors({});

			setShowSaveToast(true);
		} catch (error) {
			setError(error);
		}
	}

	async function handleSubmit(user, password, setModalError) {
		let values = formikPropsRef.current.values;
		const submissionValues = { ...values };

		submissionValues.credentials = {
			user: user,
			password: password,
		};

		let queryParams = {
			submit: true,
		};

		try {
			await saveCandidatureChangeRequest(
				candidatureExternalId,
				submissionValues,
				queryParams
			);
			setSubmited(true);
		} catch (err) {
			let exceptionType = err.response.data.exception;
			if (exceptionType === 'ValidationException') {
				handleNavError(err);
			} else {
				setModalError(err);
			}
		}
	}

	async function formValidationHandler(setShowModal) {
		let errors = await validateForm();
		setNavErrors(Array.from(errors));
		if (errors.size === 0) {
			setShowModal(true);
		}
	}

	async function validateForm() {
		let errors = new Set();

		const formikErrors = await formikPropsRef.current.validateForm();

		Object.keys(formikErrors).forEach((key) => {
			switch (key) {
				case 'description':
					!isIEFP && errors.add(0);
					break;
				default:
					isIEFP && errors.add(1);
					break;
			}
		});
		
		return errors;
	}

	function handleNavError(error) {
		let errors = error.response.data.message
			.split(',')
			.map((value) => Number.parseInt(value));
		setNavErrors(errors);
	}

	function renderStep(formikProps) {
		formikPropsRef.current = formikProps;

		switch (formStep) {
			case 0:
				return (
					<CandidatureChangeForm
						formStep={formStep + 1}
						numberOfSteps={tabsIds.length}
						documents={documents}
						setDocuments={setDocuments}
						candidatureExternalId={candidatureExternalId}
						formikValues={formikProps.values}
						readMode={false}
						handlePreviousStep={handlePreviousStep}
						handleNextStep={handleNextStep}
						showNextStepButton={false}
						handleChange={formikProps.handleChange}
						errors={formikProps.errors}
					/>
				);
			default:
				console.log('Something went wrong rendering the form step');
		}
	}

	return (
		<SubTemplate>
			<Container>
			<ConfirmationAuthenticationModal
					show={
						showWithdrawModal ||
						showSubmitModal ||
						showApproveModal ||
						showRejectModal
					}
					handleClose={() =>
						showSubmitModal
							? setShowSubmitModal(false)
							: showApproveModal
							? setShowApproveModal(false)
							: showWithdrawModal
							? setShowWithdrawModal(false)
							: setShowRejectModal(false)
					}
					submitHandler={showWithdrawModal ? handleWithdrawal : handleSubmit}
					idsPreffix={
						showApproveModal
							? 'annualComplementaryActivitiesPayment.iefp.acceptModal'
							: showRejectModal
							? 'annualComplementaryActivitiesPayment.iefp.rejectModal'
							: showWithdrawModal
							? 'candidatureChangeRequest.withdrawModal'
							: 'candidatureChangeRequest.submitModal'
					}
					confirmButtonCompleteLabelId={
						showApproveModal
							? 'all.button.approve'
							: showRejectModal
							? 'all.button.reject'
							: showWithdrawModal
							? 'all.cancel'
							: 'all.submitButtonText'
					}
					IEFPdecisionApprove={
						showApproveModal ? true : showRejectModal ? false : undefined
					}
				/>
				<Row>
					<Col md={{ span: 8, offset: 2 }}>
						<h2 className='mb-5 text-secondary'>
							<FormattedMessage id='candidature.changeCadidature.title' />
						</h2>
					</Col>
				</Row>

				<Row className='mb-5'>
					<Col md='2'>
						<FormSideNavBar
							tabsIdsArray={tabsIds}
							formStep={formStep}
							setFormStep={setFormStep}
							navErrors={navErrors}
							setNavErrors={setNavErrors}
						/>
					</Col>
					<Col md='8'>
						<AlertError error={error} />
						<SuccessToast
							message={intl.formatMessage({
								id: 'annualComplementaryActivitiesPlan.saveToastInformation',
							})}
							show={showSaveToast}
							setShow={setShowSaveToast}
						/>
						<Formik
							initialValues={formikInitialValues}
							validateOnBlur={false}
							validateOnChange={false}
							validationSchema={validationSchema}
							onSubmit={handleSubmit}
						>
							{(formikProps) => (
								<Form onSubmit={formikProps.handleSubmit}>
									{renderStep(formikProps)}
								</Form>
							)}
						</Formik>
					</Col>
					<Col md='2'>
						<div>
							{!isIEFP && (
								<button
									className='btn btn-outline-primary d-flex align-items-center justify-content-center mb-4 w-100'
									type='button'
									onClick={() => setShowWithdrawModal(true)}
								>
									<FormattedMessage id='all.cancel' />
								</button>
							)}
							<button
								className='btn btn-outline-primary d-flex align-items-center justify-content-center mb-4 w-100'
								type='button'
								onClick={() => handleSave()}
							>
								<FaRegSave />
								<FormattedMessage id='all.save' />
							</button>
							{isIEFP ? (
								<>
									<button
										className='btn btn-primary d-flex align-items-center justify-content-center mb-4 w-100'
										type='button'
										onClick={() => formValidationHandler(setShowRejectModal)}
									>
										<FaTimes /> <FormattedMessage id='all.button.reject' />
									</button>
									<button
										className='btn btn-primary d-flex align-items-center justify-content-center mb-4 w-100'
										type='button'
										onClick={() => formValidationHandler(setShowApproveModal)}
									>
										<FaCheck /> <FormattedMessage id='all.button.approve' />
									</button>
								</>
							) : (
								<button
									className='btn btn-primary d-flex align-items-center justify-content-center w-100'
									type='button'
									onClick={() => formValidationHandler(setShowSubmitModal)}
								>
									<FaRegCheckCircle />
									<FormattedMessage id='all.submitButtonText' />
								</button>
							)}
						</div>
					</Col>
				</Row>
				<Row className='py-4'>
					<Col></Col>
				</Row>
			</Container>
		</SubTemplate>
	);
}
