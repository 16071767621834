import React, { useEffect } from 'react';

function Loading({ complex, noScroll, isDialog }) {
  useEffect(() => {
    if(!(noScroll === true)){
      window.scrollTo(0, 0);
    }
  }, []);

  if (!complex) {
    return (
      <div className={`loading ${isDialog ? 'py-4' : ''}`}>
        <div className="loader" />
      </div>
    );
  }
  return (
    <div id="wrapper">
      <div className="loading">
        <div className="loader" />
      </div>
    </div>
  );
}

export default Loading;
