import React, { useState } from 'react';
import { Navigate } from 'react-router-dom';
import { AlertError } from '../../../components/bootstrap/AlertError';
import Loading from '../../../components/general/Loading';
import { SubTemplate } from '../../../components/general/SubTemplate';
import { ParameterForm } from '../../../components/parameter/ParameterForm';
import { addParameter } from '../../../rest/parameter';
import { handleError, isNotBusinessError } from '../../../utils/handleError';

export function AddParameter() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [submited, setSubmited] = useState(false);

  const createParameter = async (values, setSubmitting) => {
    setLoading(true);
    try {
      await addParameter(values);
      setSubmited(true);
      setSubmitting(false);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  function handleSubmit(values, setSubmitting) {
    setSubmitting(true);
    createParameter(values, setSubmitting);
  }

  if (loading) {
    return <Loading />;
  }

  if (error && isNotBusinessError(error)) {
    return handleError(error);
  }

  if (submited) {
    return <Navigate to='/parametros' />;
  }

  return (
    <SubTemplate hasBackButton titleId={'parameter.add'}>
      <div className={'container'}>
        <div className={'row'}>
          <div className={'col-lg-8 offset-lg-2'}>
            <AlertError error={error} />
            <ParameterForm
              onSubmit={handleSubmit}
            />
          </div>
        </div>
      </div>
    </SubTemplate>
  );
}
