export const PENDING = 'PENDING';
export const SENT = 'SENT';
export const ERROR = 'ERROR';
export const REPLIED = 'REPLIED';

export const MessageState = {
  PENDING: 'PENDING',
  SENT: 'SENT',
  ERROR: 'ERROR',
  REPLIED: 'REPLIED',
  values: () => {
    return ['PENDING', 'SENT', 'REPLIED', 'ERROR'];
  },
};
