import { CandidatureChangeStateWithReason } from "../CandidatureChangeStateWithReason";
import { useParams } from "react-router-dom";
import {
  acceptByPromoter,
  rejectValidateEligibility,
  sendFinalDeleteDecision,
  setToRejectPromoters
} from "../../../rest/candidature";
import {listDocumentsByTypeAndCandidatureExternalId} from "../../../rest/document";
import React, {useEffect, useState} from "react";
import {DocumentType} from "../../../models/DocumentType";
import {handleError, isNotBusinessError} from "../../../utils/handleError";

export function CandidatureFlowDeleteAddDocumentsCandidature({
  callbackHandler
}) {
  const { externalId } = useParams();
  const [userDocumentPublic, setUserDocumentPublic] = useState(null);
  const [userDocumentPrivate, setUserDocumentPrivate] = useState(null);
  const [error, setError] = useState(null);

  async function fetchData() {
    try {
      const [ { data: publicDocument }, { data: privateDocument }] = await Promise.all([
        await listDocumentsByTypeAndCandidatureExternalId(publicType, externalId),
        await listDocumentsByTypeAndCandidatureExternalId(privateType, externalId)
      ]);
      setUserDocumentPublic(publicDocument);
      setUserDocumentPrivate(privateDocument);

      loadDocument(publicDocument, setUserDocumentPublic, publicType);
      loadDocument(privateDocument, setUserDocumentPrivate, privateType);
    } catch (error) {
      setError(error);
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  function loadDocument(data, setDocument, documentType) {
    if (data.length !== 0) {
      const document = data[0];
      setDocument([
        {
          documentType: document.documentType,
          externalId: document.externalId,
          name: document.name,
          documentPath: document.documentPath,
          content: '',
          submited: true,
          error: ''
        }
      ]);
    } else {
      setDocument([
        {
          documentType: documentType,
          externalId: '',
          name: '',
          content: null,
          submited: false,
          error: ''
        }
      ]);
    }
  }


  if (error && isNotBusinessError(error)) {
    return handleError(error);
  }

  function sendFinalDeleteDecisionHandler() {
    callbackHandler(() => {
      return sendFinalDeleteDecision(externalId);
    }, 'sentFinalDeleteDecision');
  }

  const publicType = DocumentType.FINAL_DECISION_CLOSED;
  const privateType= DocumentType.FINAL_DECISION_CLOSED_PRIVATE;



  return (
    <>
      <CandidatureChangeStateWithReason
        modalTitleId="finalDecision.closed.title"
        flowActionId="finalDecision.closed.button"
        userDocuments={userDocumentPublic}
        userDocuments2={userDocumentPrivate}
        documentType={publicType}
        documentType2={privateType}
        externalId={externalId}
        setUserDocuments={setUserDocumentPublic}
        setUserDocuments2={setUserDocumentPrivate}
        singles={true}
        setError={setError}
        documentsRequired={true}
        submitHandler={sendFinalDeleteDecisionHandler}
      />
    </>
  );
}
