import { apiAxios as authAxios } from '../axios.config';

export const candidatureChangeRequestStatus = (candidatureExternalId) => {
  return authAxios.get(`/api/candidature/${candidatureExternalId}/change/status`)
}

export const listCandidatureChangeRequests = (candidatureExternalId, queryParams) => {
  return authAxios.get(`/api/candidature/${candidatureExternalId}/change/list`, { params: queryParams })
}

export const withdrawCandidatureChangeRequest = (candidatureExternalId, changeRequestExternalId, authenticationCredentials) => {
  return authAxios.patch(`/api/candidature/${candidatureExternalId}/change/${changeRequestExternalId}/withdrawal`, authenticationCredentials)
}

export const getCandidatureChangeRequestWithOrWithoutExternalId = (candidatureExternalId, changeRequestExternalId) => {
  return authAxios.get(`/api/candidature/${candidatureExternalId}/change/${changeRequestExternalId !== undefined ? changeRequestExternalId : ''}`)
}

export const saveCandidatureChangeRequest = (candidatureExternalId, changeRequest , queryParams) => {
  return authAxios.post(`/api/candidature/${candidatureExternalId}/change`, changeRequest, {params: queryParams})
}

export const listCandidatureChangeRequestDocuments = (candidatureExternalId, changeRequestExternalId) => {
  return authAxios.get(`/api/candidature/${candidatureExternalId}/change/${changeRequestExternalId}/document`)
}

export const uploadCandidatureChangeRequestDocument = (candidatureExternalId, changeRequestExternalId, formData) => {
  return authAxios.post(`/api/candidature/${candidatureExternalId}/change/${changeRequestExternalId}/document`, formData)
}

export const deleteCandidatureChangeRequestDocument = (candidatureExternalId, changeRequestExternalId, documentExternalId) => {
  return authAxios.delete(`/api/candidature/${candidatureExternalId}/change/${changeRequestExternalId}/document/${documentExternalId}`)
}

export const listCandidatureChangeRequestDocumentsByType = (documentType, candidatureExternalId, changeRequestExternalId) => {
  return authAxios.get(`/api/candidature/${candidatureExternalId}/change/${changeRequestExternalId}/document/type/${documentType}`)
};

export const submitInitialDecision = (candidatureExternalId, changeRequestExternalId, flag) => {
  return authAxios.patch(`/api/candidature/${candidatureExternalId}/change/${changeRequestExternalId}/initialDecision`, flag);
};

export const submitCandidatureChangeRequestContestation = (candidatureExternalId, changeRequestExternalId , changeRequest) => {
  return authAxios.post(`/api/candidature/${candidatureExternalId}/change/${changeRequestExternalId}/submitContestation`, changeRequest)
}

export const setToAcceptanceTerm = (candidatureExternalId, changeRequestExternalId) => {
  return authAxios.patch(`/api/candidature/${candidatureExternalId}/change/${changeRequestExternalId}/setToAcceptanceTerm`);
};


export const submitApproveDecisionWithFinancialChanges = (candidatureExternalId, changeRequestExternalId, candidatureDtoObj, queryParams) => {
  return authAxios.post(
    `/api/candidature/${candidatureExternalId}/change/${changeRequestExternalId}/approveDecisionWithFinancialChanges`, 
    candidatureDtoObj,
    {params: queryParams}
  );
};

export const getTermOfAcceptOfChange = (candidatureExternalId,externalId) => {
  return authAxios.get(`/api/candidature/${candidatureExternalId}/change/${externalId}/getAcceptanceTerm`);
};

export const submitTermOfAcceptOnChange = (candidatureExternalId,externalId,data) => {
  return authAxios.post(`/api/candidature/${candidatureExternalId}/change/${externalId}/submitTermOfAccept`,data);
};



export const submitAcceptanceTerm = (candidatureExternalId, changeRequestExternalId) => {
  return authAxios.patch(`/api/candidature/${candidatureExternalId}/change/${changeRequestExternalId}/submitAcceptanceTerm`);
};