import React, { useState } from 'react';
import { Alert } from 'react-bootstrap';
import { FaInfoCircle } from 'react-icons/fa';
import { ScrollToTop } from '../ScrollToTop';

export function AlertInfo({ header, message, show }) {
  const [messageObject, setMessageObject] = useState({
    show: show,
    header: header,
    message: message
  });

  function handleClose() {
    setMessageObject({
      show: false
    });
  }

  return (
    <>
      <ScrollToTop />
      {messageObject.show && (
        <Alert
          dismissible
          className='d-flex align-items-center mb-5'
          variant='warning'
          onClose={() => handleClose()}>
          <div className='px-3'>
            <FaInfoCircle />
          </div>

          <div className='px-3'>
            <p className='mb-0'>
              <strong>{messageObject.header}</strong>
            </p>
            <p className='mb-0'>{messageObject.message}</p>
          </div>
        </Alert>
      )}
    </>
  );
}

export default AlertInfo;
