import { FormattedMessage } from 'react-intl';
import * as yup from 'yup';

export const PaymentRecordFormUtils = {
	validationObject: () => validationObject(),
	getFormikInitialValuesPaymentRecord: (initialValues) =>
	getFormikInitialValuesPaymentRecord(initialValues),
};

function validationObject() {
	let validationObject = {
		date: yup
			.date()
			.nullable()
			.required(<FormattedMessage id='errors.fieldRequiredText' />)
			.test(
				'isDateNotAfterToday',
				'A data não pode ser superior à data de hoje',
				(value) => !(new Date(value) > Date.now())
			),
		value: yup
			.number()
			.required(<FormattedMessage id='errors.fieldRequiredText' />)
			.test(
				'isNumberValid',
				<FormattedMessage id='errors.eaAccreditationForm.number' />,
				(value) => value > 0
			),
	};
	return yup.object().shape(validationObject);
}

function getFormikInitialValuesPaymentRecord(initialValues) {
	initialValues.paymentRecord ??= {
		date: null,
		value: '',
	};
}
