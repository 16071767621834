import {Formik} from 'formik';
import React, {useEffect, useState} from 'react';
import {Accordion, Button, Col, Form, Row} from 'react-bootstrap';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import {FormattedMessage} from 'react-intl';
import {Link, useParams} from 'react-router-dom';
import * as yup from 'yup';
import {FaExclamationCircle} from 'react-icons/fa';
import {SyncMultipleUploadArea} from "../SyncMultipleUploadArea";
import {DocumentType} from "../../models/DocumentType";
import {TextInputField} from "../bootstrap/TextInputField";
import {TextAreaInputField} from "../bootstrap/TextAreaInputField";
import {EnumSelectField} from "../bootstrap/EnumSelectField";
import {
    getDistrictsByEntity,
    getCountiesByDistrictCode,
    getDistricts,
    getParishesByCountyCode
} from "../../rest/administrativeDivision";
import {Arrays} from "../../utils/Arrays";

export function EntityChangeLocationForm({
                                             entityChange,
                                             onSubmit,
                                             documents,
                                             setDocuments,
                                             drs, setError
                                         }) {


    const [districts, setDistricts] = useState([]);
    const {nif } = useParams();

    const [loadedDistrictCounties, setLoadedDistrictCounties] = useState([]);
    const [loadedCountyParishes, setLoadedCountyParishes] = useState([]);
    const [districtOptions, setDistrictOptions] = useState([]);

    const [locationCurrentChosenOptions, setLocationCurrentChosenOptions] = useState([]);

    async function fetchData() {

        try {
            const [{data: districtsIn}] = await Promise.all([
                await getDistrictsByEntity(true, nif),
            ]);

            setDistricts(Arrays.groupBy(
                districtsIn,
                (district) => district.regionalDelegationCode
            ));

        } catch (error) {
            setError(error);
        }

        //setShowSubmitModal(false);
    };
    useEffect(() => {
        fetchData();
    }, []);


    const schema = yup.object().shape({
        reason: yup
            .string()
            .required(
                <FormattedMessage id='errors.fieldRequiredText'/>
            ),

        regionalDelegationCode: yup
            .string()
            .test(
                'value-is-empty',
                <FormattedMessage id='errors.fieldRequiredText'/>,
                (value) => value !== '-1' && typeof value !== 'undefined'
            )
            .required(
                <FormattedMessage id='errors.fieldRequiredText'/>
            ),
        locationsChange: yup.array().of(
            yup.object().shape({
                parishCode: yup
                    .string()
                    .test(
                        'isValidParish',
                        <FormattedMessage id='errors.promoterForm.parish' />,
                        (value) => value !== '-1'
                    ),
                extraLocation: yup
                    .string()
                    .required(<FormattedMessage id='errors.fieldRequiredText' />),
            })
        ),

    });


    function addLocationHandler(values, setFieldValue) {
      let newLocation = {
        parishCode: '-1',
        extraLocation: '',
      };

      locationCurrentChosenOptions[values.locationsChange.length] = {districtCode: '-1', countyCode: '-1'};
      setLocationCurrentChosenOptions({...locationCurrentChosenOptions})

        values.locationsChange.push(newLocation);
        setFieldValue({...values});
    }


    async function handleDrChange(e, values, setFieldValue) {
      values.regionalDelegationCode = e.target.value;

      if (values.regionalDelegationCode === '-1') {
        console.log('empty')
        values.locationsChange = [];
        setDistrictOptions([]);
        setLocationCurrentChosenOptions([])
        setLoadedDistrictCounties([]);
        setLoadedCountyParishes([]);
      } else {
        console.log('full')
        setLocationCurrentChosenOptions([])
        values.locationsChange = drs.filter(d => d.regionalDelegationCode === e.target.value)[0].locations;

        setDistrictOptions(districts.get(e.target.value).map(d => d.description));
        await setDistrictsAndCountiesFromParishCodes(values);

        if (Arrays.isEmpty(values.locationsChange)) {
              setLoadedDistrictCounties([]);
              setLoadedCountyParishes([]);
        }



      }
      setFieldValue('locationsChange', [...values.locationsChange]);
    }


    async function setDistrictsAndCountiesFromParishCodes(values) {
        try {
            let districtCode;
            let countyCode;

            let newLoadedDistrictCounties = {}
            let newLoadedCountyParishes = {}
            let newLocationCurrentChosenOptions = {}

            for (let index in values.locationsChange) {
                let loc = values.locationsChange[index]
                if (loc.parishCode !== "-1") {

                    districtCode = loc.parishCode.substring(0, 2);
                    const {data: countyList} = await getCountiesByDistrictCode(districtCode);
                    newLoadedDistrictCounties[districtCode] = countyList;

                    countyCode = loc.parishCode.substring(0, 4);
                    const {data: parishList} = await getParishesByCountyCode(countyCode);
                    newLoadedCountyParishes[countyCode] = parishList;


                    newLocationCurrentChosenOptions[index] = {districtCode: districtCode, countyCode: countyCode}
                } else {
                    newLocationCurrentChosenOptions[index] = {districtCode: '-1', countyCode: '-1'}
                }
            }

            setLocationCurrentChosenOptions(newLocationCurrentChosenOptions)
            setLoadedDistrictCounties(newLoadedDistrictCounties);
            setLoadedCountyParishes(newLoadedCountyParishes);

        } catch (error) {
            setError(error);
        }
    }

    async function handleDistrictChange(districtName, locationIndex, values, setFieldValue) {
        try {
            if (districtName !== '-1') {
                let districtCode = districts.get(values.regionalDelegationCode).filter((district) => district.description === districtName)[0].code;

                const {data: counties} = await getCountiesByDistrictCode(districtCode);

                locationCurrentChosenOptions[locationIndex].districtCode = districtCode

                if (!loadedDistrictCounties.hasOwnProperty(districtCode)) {
                    setLoadedDistrictCounties({...loadedDistrictCounties, [districtCode]: counties})
                }
            } else {
                locationCurrentChosenOptions[locationIndex].districtCode = '-1';
            }
            locationCurrentChosenOptions[locationIndex].countyCode = '-1';

            setLocationCurrentChosenOptions({...locationCurrentChosenOptions})
            values.locationsChange[locationIndex].parishCode = '-1';
            setFieldValue({...values});
        } catch (error) {
            setError(error);
        }
    }

    async function handleCountyChange(countyName, locationIndex, values, setFieldValue) {
        try {

            if (countyName !== '-1') {
                let countyCode = loadedDistrictCounties[locationCurrentChosenOptions[locationIndex].districtCode].filter((county) => county.description === countyName)[0].code

                const {data: parishes} = await getParishesByCountyCode(countyCode);

                locationCurrentChosenOptions[locationIndex].countyCode = countyCode

                if (!loadedCountyParishes.hasOwnProperty(countyCode)) {
                    setLoadedCountyParishes({...loadedCountyParishes, [countyCode]: parishes})
                }
            } else {
                locationCurrentChosenOptions[locationIndex].countyCode = '-1';
            }

            setLocationCurrentChosenOptions({...locationCurrentChosenOptions})
            values.locationsChange[locationIndex].parishCode = '-1';
            setFieldValue({...values});
        } catch (error) {
            setError(error);
        }
    }

    function handleParishChange(parishName, locationIndex, values, setFieldValue) {
        if (parishName !== '-1') {
            values.locationsChange[locationIndex].parishCode = loadedCountyParishes[locationCurrentChosenOptions[locationIndex].countyCode].filter((parish) => parish.description === parishName)[0].code;
        } else {
            values.locationsChange[locationIndex].parishCode = '-1';
        }
        setFieldValue({...values});
    }


    function removeLocationHandler(index, values, setFieldValue) {
        let lengthBeforeRemoval = values.locationsChange.length
        delete locationCurrentChosenOptions[index];
        if (lengthBeforeRemoval > 1) {
            for (let i = index + 1; i < lengthBeforeRemoval; i++) {
                locationCurrentChosenOptions[i - 1] = locationCurrentChosenOptions[i];
            }
            delete locationCurrentChosenOptions[lengthBeforeRemoval - 1]
        }
        setLocationCurrentChosenOptions({...locationCurrentChosenOptions})

        values.locationsChange.splice(index, 1);
        setFieldValue({...values});
    }


    return (
        <Formik
            initialValues={entityChange}
            validationSchema={schema}
            validateOnBlur={false}
            validateOnChange={false}
            onSubmit={(values, {setSubmitting, setErrors}) => {
                onSubmit(values, setSubmitting, setErrors);
            }}
        >
            {({values, errors, touched, handleChange, handleSubmit, setFieldValue}) => (
                <form onSubmit={handleSubmit}>
                    <Row>
                        <Col>
                            <TextAreaInputField
                                controlId={'reason'}
                                labelId={'entityChange.reason'}
                                name={'reason'}
                                handleChange={handleChange}
                                value={values?.reason}
                                isInvalid={errors.reason}
                                errorMessage={errors.reason}
                                maxLength={1000}
                            />
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <SyncMultipleUploadArea
                                name='files'
                                documents={documents}
                                setDocuments={setDocuments}
                                documentType={DocumentType.REASON_ENTITY_CHANGE}
                            />
                        </Col>
                    </Row>

                    <Row>
                        <Col md='6'>
                            <EnumSelectField
                                controlId={'regionalDelegationCode'}
                                nullOption={true}
                                preffixDescriptionId='regionalDelegation'
                                labelId='economicViabilityFormStep1.regionalDelegation'
                                name='regionalDelegationCode'
                                options={drs?.map(dr => dr.regionalDelegationCode)}
                                value={values?.regionalDelegationCode}
                                handleChange={(e) => handleDrChange(e, values, setFieldValue)}
                                isInvalid={errors.regionalDelegationCode}
                                errorMessage={errors.regionalDelegationCode}
                            />
                        </Col>

                    </Row>
                    {values.regionalDelegationCode !== '-1' &&
                    <>
                        <Row>
                            <Col>
                                <Form.Label>
                                    <FormattedMessage id='eaAccreditationForm.step3.entityDistricts'/>
                                </Form.Label>
                            </Col>
                        </Row>


                      {Arrays.isNotEmpty(locationCurrentChosenOptions) &&

                      <>
                        {values.locationsChange.map((location, index) => (
                            <Accordion
                                alwaysOpen
                                key={index}
                            >
                              <Accordion.Item eventKey={index}>
                                <Accordion.Header>
							<span className='d-flex align-items-center'>
								{errors.locationsChange?.[index] && <FaExclamationCircle/>}
                              <FormattedMessage id='eaAccreditationForm.step5.accordion.title'/>{' '}
                              {index + 1}
							</span>
                                </Accordion.Header>
                                <Accordion.Body>
                                  <Row>
                                    <Col md='4'>
                                      <EnumSelectField
                                          nullOption={true}
                                          labelId='all.chooseOneDistrict'
                                          options={districtOptions}
                                          handleChange={(e) =>
                                              handleDistrictChange(e.target.value, index, values, setFieldValue)
                                          }
                                          value={locationCurrentChosenOptions[index].districtCode !== '-1' ?
                                              districts.get(values.regionalDelegationCode).filter(d => d.code === locationCurrentChosenOptions[index].districtCode)[0].description : '-1'}
                                      />
                                    </Col>
                                    <Col md='4'>
                                      <EnumSelectField
                                          disabled={values.locationsChange[index].districtCode === '-1'}
                                          nullOption={true}
                                          options={locationCurrentChosenOptions[index].districtCode !== '-1' ?
                                              loadedDistrictCounties[locationCurrentChosenOptions[index].districtCode].map((countyObj) => countyObj.description) : []}
                                          handleChange={(e) =>
                                              handleCountyChange(e.target.value, index, values, setFieldValue)
                                          }
                                          labelId='all.chooseOneCounty'
                                          value={locationCurrentChosenOptions[index].countyCode !== '-1' ?
                                              loadedDistrictCounties[locationCurrentChosenOptions[index].districtCode].filter(countyObj =>
                                                  locationCurrentChosenOptions[index].countyCode === countyObj.code)[0].description : '-1'}
                                      />
                                    </Col>
                                    <Col md='4'>
                                      <EnumSelectField
                                          disabled={locationCurrentChosenOptions[index].countyCode === '-1'}
                                          nullOption={true}
                                          labelId='all.chooseOneParish'
                                          handleChange={(e) =>
                                              handleParishChange(e.target.value, index, values, setFieldValue)
                                          }
                                          options={locationCurrentChosenOptions[index].countyCode !== '-1' ?
                                              loadedCountyParishes[locationCurrentChosenOptions[index].countyCode].map((parishObj) => parishObj.description) : []}
                                          value={
                                            location.parishCode !== '-1'
                                                ? loadedCountyParishes[locationCurrentChosenOptions[index].countyCode].filter(
                                                (parish) => parish.code === location.parishCode
                                                )[0].description
                                                : '-1'
                                          }
                                          isInvalid={errors.locationsChange?.[index]?.parishCode}
                                          errorMessage={
                                            errors.locationsChange?.[index]?.parishCode
                                          }
                                      />
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col>
                                      <TextInputField
                                          value={location.extraLocation}
                                          name={`locationsChange[${index}].extraLocation`}
                                          labelId={'all.address'}
                                          handleChange={handleChange}
                                          isInvalid={
                                            errors.locationsChange?.[index]?.extraLocation
                                          }
                                          errorMessage={
                                            errors.locationsChange?.[index]?.extraLocation
                                          }
                                      />
                                    </Col>
                                  </Row>
                                  <Row className='mt-4'>
                                    <Col>
                                      <button
                                          className='px-0 btn btn-link'
                                          type='button'
                                          onClick={() => removeLocationHandler(index, values, setFieldValue)}
                                      >
                                        <FormattedMessage id='all.remove'/>
                                      </button>
                                    </Col>
                                  </Row>
                                </Accordion.Body>
                              </Accordion.Item>
                            </Accordion>
                        ))}
                      </>
                      }


                        <Row className='mt-4'>
                            <Col>
                                <button
                                    className='btn btn-outline-primary'
                                    type='button'
                                    onClick={() => addLocationHandler(values, setFieldValue)}
                                >
                                    <FormattedMessage id='eaAccreditationForm.step5.button.addIncubatorLocation'/>
                                </button>
                            </Col>
                        </Row>

                    </>
                    }


                    <div className='form-actions'>
                        <div className='mr-auto'>
                            <Link to={'/templates'} variant='secondary'>
                                <FormattedMessage id='all.backButtonText'/>
                            </Link>
                        </div>
                        <div className='ml-auto'>
                            <Button variant='primary' type='submit'>
                                <FormattedMessage id='all.submitButtonText'/>
                            </Button>
                        </div>
                    </div>
                </form>
            )}
        </Formik>
    );
}
