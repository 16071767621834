import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { handleError, isNotBusinessError } from '../../../utils/handleError';
import { AlertError } from '../../../components/bootstrap/AlertError';
import { Arrays } from '../../../utils/Arrays';
import { FormattedMessage, useIntl } from 'react-intl';
import { EnumSelectField } from '../../../components/bootstrap/EnumSelectField';
import { AlertSuccess } from '../../../components/bootstrap/AlertSuccess';
import {useNavigate, useParams} from 'react-router-dom';
import { LabelToolTip } from '../../../components/bootstrap/LabelToolTip';
import { EmptyResults } from '../../../components/general/EmptyResults';
import { SubTemplate } from '../../../components/general/SubTemplate';
import Loading from '../../../components/general/Loading';
import { CustomPagination } from '../../../components/general/CustomPagination';
import { ListFilterBar } from '../../../components/general/ListFilterBar';
import { ProcessState } from '../../../models/ProcessState';
import { listEntityEconomicViabilities } from '../../../rest/economicViability';

const MAX_RESULTS = 10;
const DEFAULT_INITIAL_PAGE = 0;

export function ListEconomicViability() {
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);
	const [numberOfResults, setNumberOfResults] = useState(null);
	const [totalResults, setTotalResults] = useState(null);
	const [currentPage, setCurrentPage] = useState(DEFAULT_INITIAL_PAGE);

	const [economicViabilityList, setEconomicViabilityList] = useState([]);

	const orderByOptionsIdPrefix = 'economicViability.list.orderOption';
	const orderByOptions = ['creationDate_desc', 'creationDate_asc'];

	const [currentOrderBy, setCurrentOrderBy] = useState(orderByOptions[0]);

	const { entityNif: entityNif,inputState:inputState,candidatureState:candidatureState } = useParams();

	const [filters, setFilters] = useState({
		state: {
			type: 'enum',
			preffixDescriptionId: 'processState',
			options: ProcessState.values().filter(p=> p=== ProcessState.DRAFT || p===ProcessState.SUBMITTED),
			value: '-1',
			nullOption: true,
			nullOptionSuffix: 'state',
			colSpace: 3,
		},
	});

	const hoverpreffixId = 'economicViability.list.hover';

	const navigate = useNavigate();

	const intl = useIntl();

	const [lsItem, setLsItem] = useState(null);

	async function fetchData(fromFilter) {
		try {
			if(fromFilter === true){
				setCurrentPage(0);
			}

			let orderParams = currentOrderBy.split('_');

			let queryparams = {
				index: fromFilter ? 0 : currentPage,
				size: MAX_RESULTS,
				orderBy: orderParams[0],
				orderType: orderParams[1]
			};

			console.log(queryparams)

			if(entityNif!==undefined &&  entityNif!=='undefined'){
				queryparams.entityNif=  entityNif;
			}

			if (filters.state.value !== '-1' && inputState=== undefined) {
				queryparams.state = filters.state.value;
			}
			if(inputState!==undefined){
				queryparams.state = inputState;
			}

			if(candidatureState!==undefined){
				queryparams.candidatureState =  candidatureState;
			}

			const [{ data: formList }] = await Promise.all([
				await listEntityEconomicViabilities(queryparams),
			]);

			setEconomicViabilityList(formList.results);
			setNumberOfResults(formList.numberOfResults);
			setTotalResults(formList.totalResults);
		} catch (error) {
			setError(error);
		} finally {
			setLoading(false);
		}
	}

	const onPageChange = (pageNumber) => {
		setCurrentPage(pageNumber);
	};

	function handleOrderByChange(e) {
		setCurrentOrderBy(e.target.value);
	}
	
	useEffect(() => {
		fetchData();
	}, [currentPage]);

	if (loading) {
		return <Loading />;
	}

	if (error && isNotBusinessError(error)) {
		return handleError(error);
	}

	function redirectToFormPage(economicViabilityAssociatedCandidatureExternalId) {
		navigate(
			`/candidatura/${economicViabilityAssociatedCandidatureExternalId}/emitirParecer`
		);
	}

	return (
		<SubTemplate
			titleId={'economicViability.list.title'}
			hasBackButton
		>
			<Container>
				<div className='mx-2 mb-4'>
					<AlertSuccess lsItem={lsItem} />
				</div>
				{inputState === undefined &&
				<Row>
					<Col md='10'>
						<ListFilterBar
							filters={filters}
							setFilters={setFilters}
							search={fetchData}
						/>
					</Col>
				</Row>
				}

				<Row className='searchResults'>
					<Col md='10'>
						<AlertError error={error} />

						{Arrays.isNotEmpty(economicViabilityList) ? (
							<>
								<Row className='mt-5 mb-1 d-flex justify-content-between px-2'>
									<Col className='text-muted'>
										{totalResults}{' '}
										<FormattedMessage
											id={`economicViability.list.header.requests.${
												totalResults === 1 ? 'singular' : 'plural'
											}`}
										/>
									</Col>
									<Col md='4'>
										<EnumSelectField
											preffixDescriptionId={orderByOptionsIdPrefix}
											handleChange={handleOrderByChange}
											options={orderByOptions}
											value={currentOrderBy}
										/>
									</Col>
								</Row>

								<ul className='results'>
									{economicViabilityList.map((economicViability, index) => (
										<li key={economicViability.candidatureExternalId}>
											<Row
												className='py-3'
												onClick={() => redirectToFormPage(economicViability.candidatureExternalId)}
											>
												<Col md='7' className='d-flex align-items-center'>
													<LabelToolTip
														labelId={`${hoverpreffixId}.request`}
														value={economicViability.processNumber}
													/>
												</Col>
												<Col md='2' className='d-flex align-items-center'>
													<LabelToolTip
														labelId={`${hoverpreffixId}.state`}
														value={
															<FormattedMessage
																id={`processState.${economicViability.state}`}
															/>
														}
													/>
												</Col>
												<Col
													md='3'
													className='d-flex align-items-center justify-content-end'
												>
													<LabelToolTip
														labelId={`${hoverpreffixId}.creationDate`}
														value={economicViability.creationDate}
													/>
												</Col>
											</Row>
										</li>
									))}
								</ul>
							</>
						) : (
							<EmptyResults />
						)}
					</Col>
				</Row>
				<Row className='mb-5'>
					<Col md='10' className='d-flex'>
						<div className='mx-2'>
							<CustomPagination
								dataPerPage={MAX_RESULTS}
								totalData={totalResults}
								onPageChange={onPageChange}
								currentPage={currentPage}
							/>
						</div>
					</Col>
				</Row>
			</Container>
		</SubTemplate>
	);
}
