import React from 'react';
import {Accordion, Col, Row} from 'react-bootstrap';
import {FormattedMessage, useIntl} from 'react-intl';
import {Arrays} from "../../utils/Arrays";
import {ProviderType} from "../../models/ProviderType";

export function OtherChargeAccordionItem({otherChargeList,yearNames}) {
    const intl = useIntl();
    const totals = getTotal();


    function getTotal() {
        if (otherChargeList?.length > 0) {
            let totals = otherChargeList[0].otherChargeLineList.reduce(function (obj, item) {
                       obj.refund+=item.refund;
                       obj.financialCharges+=item.financialCharges;
                       obj.debtCapitalInitial+=item.debtCapitalInitial;
                       obj.debtCapitalFinal+=item.debtCapitalFinal;
                    return obj;
                }, {refund:0,financialCharges:0,debtCapitalInitial:0,debtCapitalFinal:0});

            return totals;
        }
    }



    return (
        <>
            {(otherChargeList?.length >0) && (
                <Accordion.Item eventKey="otherCharge">
                    <Accordion.Header><FormattedMessage
                        id='candidatureTabs.accordion.otherCharge'/></Accordion.Header>
                    <Accordion.Body>
                        <div className='tableWithBorders'>
                            <table className='w-100 mb-5'>
                                <thead>
                                <tr  className='border-1 border-bottom '>
                                    <th  rowSpan="2" className='p-lg-1 text-center'><FormattedMessage id='otherCharge.year'/></th>
                                    <th colSpan={3} className='text-center'><FormattedMessage id='otherCharge.render'/></th>
                                    <th colSpan={2} className='text-center'><FormattedMessage id='otherCharge.debtCapital'/></th>
                                </tr>
                                <tr>
                                    <th  className='text-center'><FormattedMessage id='otherCharge.refund'/></th>
                                    <th  className='text-center'><FormattedMessage id='otherCharge.financialCharges'/></th>
                                    <th  className='text-center'><FormattedMessage id='otherCharge.total'/></th>
                                    <th  className='text-center'><FormattedMessage id='otherCharge.debtCapitalInitial'/></th>
                                    <th  className='text-center'><FormattedMessage id='otherCharge.debtCapitalFinal'/></th>
                                </tr>
                                </thead>
                                <tbody>

                                {otherChargeList[0].otherChargeLineList.map((line,index)=>(
                                    <tr key={index} className='border-1 border-bottom '>
                                        <td className="text-center withBorderLeft">{yearNames[line.year]}</td>
                                        <td className="text-center withBorderLeft">{line.refund.toFixed(2)} €</td>
                                        <td className="text-center withBorderLeft">{line.financialCharges.toFixed(2)} €</td>
                                        <td className="text-center withBorderLeft">{(line.financialCharges+line.refund).toFixed(2)} €</td>
                                        <td className="text-center withBorderLeft">{line.debtCapitalInitial.toFixed(2)} €</td>
                                        <td className="text-center withBorderLeft">{line.debtCapitalFinal.toFixed(2)} €</td>
                                    </tr>
                                ))

                                }
                                <tr className='border-1 border-bottom '>
                                <td className="text-center withBorderLeft"><strong><FormattedMessage id='otherCharge.total'/></strong></td>
                                <td className="text-center withBorderLeft">{totals.refund.toFixed(2)} €</td>
                                <td className="text-center withBorderLeft">{totals.financialCharges.toFixed(2)} €</td>
                                <td className="text-center withBorderLeft">{(totals.financialCharges+totals.refund).toFixed(2)}€</td>
                                <td className="text-center withBorderLeft">{totals.debtCapitalInitial.toFixed(2)} €</td>
                                <td className="text-center withBorderLeft">{totals.debtCapitalFinal.toFixed(2)} €</td>
                                </tr>

                                </tbody>


                            </table>
                        </div>


                        <Row>
                            <Col md='12'>
                                <label>
                                    <FormattedMessage id='otherCharge.reason'/>
                                </label>
                                <p>
                                    {otherChargeList[0].reasonToOtherCharge}
                                </p>
                            </Col>
                        </Row>
                    </Accordion.Body>
                </Accordion.Item>

            )}
        </>
    );
}
