import React from 'react';
import { Row, Col, Table, Form } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { maxFixed } from '../../../utils/CurrencyUtils';
import {
	totalCostsDisclosureActivities,
	totalCostsGeneral,
	totalCostsIncubationSupport,
	totalEABudget,
} from '../../../utils/AnnualComplementaryActivitiesPlanFormUtils';
import { TextAreaInputField } from '../../bootstrap/TextAreaInputField';
import { TextInputField } from '../../bootstrap/TextInputField';
import { ProcessState } from '../../../models/ProcessState';
import { DateTimeInputField } from '../../bootstrap/DateTimeInputField';

export function AnnualComplementaryActivitiesPlanFormStep3({
	formStep,
	numberOfSteps,
	errors,
	handleChange,
	formikValues,
	handlePreviousStep,
	handleNextStep,
	months,
	showNextStepButton,
	entityGetList,
	readMode
}) {
	const intl = useIntl();

	return (
		<div>
			<h4 className='text-primary mb-0'>
				<FormattedMessage id='annualComplementaryActivitiesPlanForm.sideNavBar.globalBudget' />
			</h4>
			<small className='text-secondary text-uppercase'>
				{intl.formatMessage(
					{ id: 'all.step' },
					{ step: formStep, numberOfSteps: numberOfSteps }
				)}
			</small>
			<fieldset disabled={readMode==true}>
			<Row className={'mt-4'}>
				<Col md='12'>
					<Table striped responsive='md'>
						<thead>
							<tr>
								<th>
									<FormattedMessage id='annualComplementaryActivitiesPlanForm.complementaryActivities' />
								</th>
								<th className='w-25'>
									<FormattedMessage id='annualComplementaryActivitiesPlanForm.supportPrediction.amount' />
								</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>Bootcamp</td>
								<td>
									{maxFixed(
										totalCostsGeneral(
											formikValues,
											0,
											'value'
										)
									) + ' €'}
								</td>
							</tr>

							<tr>
								<td>
									<FormattedMessage id='annualComplementaryActivitiesPlanForm.disclosureActivities' />
								</td>
								<td>
									{maxFixed(
										totalCostsDisclosureActivities(formikValues)
									) + ' €'}
								</td>
							</tr>

							<tr>
								<td>
									<FormattedMessage id='annualComplementaryActivitiesPlanForm.didacticMaterials' />
								</td>
								<td>
									{maxFixed(
										totalCostsGeneral(
											formikValues,
											1,
											'value'
										)
									) + ' €'}
								</td>
							</tr>

							<tr>
								<td>
									<FormattedMessage id='annualComplementaryActivitiesPlanForm.incubationCosts' />
								</td>
								<td>
									{maxFixed(
										totalCostsIncubationSupport(
											formikValues,
											formikValues.activityIdentification.ias || 0,
											months
										)
									) + ' €'}
								</td>
							</tr>

							<tr>
								<td>
									<FormattedMessage id='annualComplementaryActivitiesPlanForm.totalBudget' />
								</td>
								<td>
									{maxFixed(
										totalEABudget(formikValues, formikValues.activityIdentification.ias || 0, months)
									) + ' €'}
								</td>
							</tr>

							<tr>
								<td>
									<FormattedMessage id='annualComplementaryActivitiesPlanForm.totalBudget.toolTip' />
								</td>
								<td>{maxFixed((formikValues.activityIdentification.ias || 0) * 60) + ' €'}</td>
							</tr>
						</tbody>
					</Table>
				</Col>
			</Row>
			<Row>
				<Col md='12'>
					<Form.Label>
						<FormattedMessage id='annualComplementaryActivitiesPlanForm.toAntecipateValue' />
					</Form.Label>
					{maxFixed(totalEABudget(formikValues, formikValues.activityIdentification.ias || 0, months)*0.5) + ' €'}
				</Col>
			</Row>
			<Row>
				<Col>
					<TextAreaInputField
						labelId={'annualComplementaryActivitiesPlanForm.EAObservations'}
						name={'observationsEa'}
						handleChange={handleChange}
						value={formikValues.observationsEa}
						isInvalid={errors.observationsEa}
						errorMessage={errors.observationsEa}
						maxLength={1000}
						placeholder={intl.formatMessage({id:'annualComplementaryActivitiesPlanForm.characters.placeholder'}, {nChars: '1000'})}
					/>
				</Col>
			</Row>
			{formikValues.identificationEa.technicalTeamManagerExternalId !== '-1' &&
			<Row>
				<Col md='8'>
					<TextInputField
						labelId='annualComplementaryActivitiesPlanForm.technicalTeamManager'
						value={entityGetList.filter(get => get.externalId === formikValues.identificationEa.technicalTeamManagerExternalId)?.[0].name}
						disabled
					/>
				</Col>
				{(ProcessState.finalStates().includes(formikValues.processState) || formikValues.processState === ProcessState.SUBMITTED) && 
				<Col md='4'>
						<DateTimeInputField
							disabled
							labelId='candidatureTabs.submissionDate'
							value={formikValues.submissionDate}
							isDateWithNoTime={true}
						/>
					</Col>
				}
			</Row>
}
</fieldset>
			<div className='form-actions mt-5'>
				<button
					className='btn btn-link'
					type='button'
					onClick={handlePreviousStep}
				>
					<FormattedMessage id='all.back' />
				</button>

				{showNextStepButton && (
					<button
						className='btn btn-primary'
						type='button'
						onClick={handleNextStep}
					>
						<FormattedMessage id='all.nextStep' />
					</button>
				)}
			</div>
		</div>
	);
}
