import React, { useEffect, useState } from 'react';
import { Row, Col, Form, Accordion, Container } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { MCESupportKind } from '../../models/MCESupportKind';
import { getEntityContractsListingForIefpView } from '../../rest/contractsListing';
import { getEntityTgpList } from '../../rest/entity';
import { handleError, isNotBusinessError } from '../../utils/handleError';
import { OptionsUtils } from '../../utils/OptionsUtils';
import { DateTimeInputField } from '../bootstrap/DateTimeInputField';
import DecimalInputField from '../bootstrap/DecimalInputField';
import { EnumSelectField } from '../bootstrap/EnumSelectField';
import { NumberInputField } from '../bootstrap/NumberInputField';
import { TextInputField } from '../bootstrap/TextInputField';
import ToolTip from '../bootstrap/ToolTip';
import { ListFilterBar } from '../general/ListFilterBar';
import Loading from '../general/Loading';
import {
	getCountiesByDistrictCode,
	getDistricts,
	getParishesByCountyCode,
} from '../../rest/administrativeDivision';
import { getParishByCode } from '../../rest/administrativeDivision';

export function ListContractsIEFPView({ entityNif }) {
	const intl = useIntl();
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);
	const [contractListing, setContractListing] = useState([]);
	const [entityTGPList, setEntityTGPList] = useState([]);

	const booleanOptions = ['true', 'false'];

	const [filters, setFilters] = useState({
		year: {
			type: 'enum',
			value: OptionsUtils.years10_OptionsForEnum[0],
			colSpace: 2,
			options: OptionsUtils.years10_OptionsForEnum,
		},
	});

	const [districtMap, setDistrictMap] = useState({});
	const [countyMap, setCountyMap] = useState({});
	const [parishMap, setParishMap] = useState({});

	async function setDistrictsAndCountiesFromParishCodes(contractsListing) {
		try {
			let county;

			for (let monthIndex in contractsListing) {
				for (let districtKey in contractsListing[monthIndex]) {
					for (let contract of contractsListing[monthIndex][districtKey]) {
						county = contract.parishCode.substring(0, 4);

						if (!countyMap.hasOwnProperty(county)) {
							const { data: countyReceived } = await getParishByCode(county);
							countyMap[countyReceived.code] = countyReceived.description;
						}

						if (!parishMap.hasOwnProperty(contract.parishCode)) {
							const { data: parishReceived } = await getParishByCode(
								contract.parishCode
							);
							parishMap[parishReceived.code] = parishReceived.description;
						}
					}
				}
			}

			setCountyMap({ ...countyMap });
			setParishMap({ ...parishMap });
		} catch (error) {
			setError(error);
		}
	}

	async function fetchData() {
		try {
			let queryparams = {};

			queryparams.year = filters.year.value;

			let tgpListParams = { entityNif: entityNif, all:true };

			const [{ data: contractAndProjectListing }, { data: entityTgpList }] =
				await Promise.all([
					await getEntityContractsListingForIefpView(entityNif, queryparams),
					await getEntityTgpList(tgpListParams),
				]);

			setDistrictsAndCountiesFromParishCodes(contractAndProjectListing);
			setContractListing(contractAndProjectListing);
			setEntityTGPList(entityTgpList);
		} catch (error) {
			setError(error);
		}
	}

	async function getDistrictsMapping() {
		try {
			const [{ data: districts }] = await Promise.all([
				await getDistricts(true),
			]);

			let districtsMapping = {};

			districts.forEach((d) => (districtsMapping[d.code] = d.description));

			setDistrictMap(districtsMapping);
		} catch (error) {
			setError(error);
		}
	}

	async function initializer() {
		await Promise.all([await getDistrictsMapping(), await fetchData()]);
		setLoading(false);
	}

	useEffect(() => {
		initializer();
	}, []);

	if (loading) {
		return <Loading />;
	}

	if (error && isNotBusinessError(error)) {
		return handleError(error);
	}

	return (
		<Container>
			<Row className='mt-3'>
				<Col>
					<ListFilterBar
						filters={filters}
						setFilters={setFilters}
						search={async () => {
							setLoading(true);
							await fetchData();
							setLoading(false);
						}}
					/>
				</Col>
			</Row>
			{OptionsUtils.months0To11.map((monthNum) => (
				<React.Fragment key={monthNum}>
					<Row>
						<Col>
							<small className='d-block text-secondary border-bottom text-uppercase mt-5'>
								<FormattedMessage id={`month.${parseInt(monthNum) + 1}`} />
								{!contractListing.hasOwnProperty(monthNum) && (
									<span className='float-end'>
										<FormattedMessage id='all.noData' />
									</span>
								)}
							</small>
						</Col>
					</Row>
					{/* contracts */}
					{contractListing[monthNum] !== undefined &&
						Object.keys(contractListing[monthNum]).map((districtKey) => (
							<div className='bg-card-panel p-3 mt-3' key={`contract${monthNum}${districtKey}`}>
								<Row >
									<Col><strong>{districtKey}</strong></Col>
								</Row>
								{Object.values(contractListing[monthNum][districtKey]).map(
									(contract, index) => (
										<Row key={`contract${monthNum}${districtKey}${index}`}>
											<Col md='12'>
												<Accordion>
													<Accordion.Item eventKey='0'>
														<Accordion.Header>
															<FormattedMessage id='listContracts.step2.accordion.contract' />{' '}
															{index + 1}
														</Accordion.Header>
														<Accordion.Body>
															<Row>
																<Col md='4'>
																	<TextInputField
																		disabled={true}
																		value={contract.contractNumber}
																		labelId='listContracts.step2.accordion.contractNumber'
																	/>
																</Col>
																<Col md='8'>
																	<EnumSelectField
																		labelId='listContracts.step2.accordion.modality'
																		value={contract.contractModality}
																		preffixDescriptionId='mceSupportKind'
																		options={MCESupportKind.values()}
																		disabled={true}
																	/>
																</Col>
															</Row>
															<Row>
																<Col md='8'>
																	<TextInputField
																		disabled={true}
																		value={contract.projectCompanyName}
																		labelId='listContracts.step2.accordion.promotersOrCompanyIdentification'
																	/>
																</Col>
																<Col md='4'>
																	<NumberInputField
																		disabled={true}
																		value={contract.projectCompanyNIF}
																		labelId='listContracts.step2.accordion.nifOrNipc'
																	/>
																</Col>
															</Row>
															<Row>
																<Col md='8'>
																	<Form.Group>
																		<Form.Label>
																			<FormattedMessage id='listContracts.step2.accordion.projectManagerIdentification' />
																		</Form.Label>
																		<Form.Control
																			disabled={true}
																			as='select'
																			value={contract.projectManagerExternalId}
																		>
																			<option value={'-1'}>
																				<FormattedMessage id='all.chooseOne' />
																			</option>
																			{entityTGPList.map((tgp) => (
																				<option
																					key={tgp.externalId}
																					value={tgp.externalId}
																				>
																					{tgp.name}
																				</option>
																			))}
																		</Form.Control>
																	</Form.Group>
																</Col>
																<Col md='4'>
																	<NumberInputField
																		disabled={true}
																		labelId='listContracts.step2.accordion.nif'
																		value={
																			entityTGPList.filter(
																				(tgp) =>
																					tgp.externalId ===
																					contract.projectManagerExternalId
																			)?.[0]?.hre?.nif
																		}
																	/>
																</Col>
															</Row>
															<Row className='mt-5 mb-0'>
																<Col md='12'>
																	<small className='text-secondary text-uppercase'>
																		<FormattedMessage id='listContracts.step2.accordion.activeProjectsNumberByProjectManager' />
																	</small>
																</Col>
															</Row>
															<Row className='mb-3'>
																<Col md='4'>
																	<NumberInputField
																		disabled={true}
																		value={contract.anProjects}
																		labelId='listContracts.step2.accordion.anNumber'
																	/>
																</Col>
																<Col md='4'>
																	<NumberInputField
																		disabled={true}
																		value={contract.currentProjects}
																		labelId='listContracts.step2.accordion.actualNumber'
																	/>
																</Col>
																<Col md='4'>
																	<NumberInputField
																		disabled={true}
																		value={contract.totalProjects}
																		labelId='listContracts.step2.accordion.totalNumber'
																	/>
																</Col>
															</Row>
															<Row className='mt-5'>
																<small className='text-primary text-uppercase'>
																	<FormattedMessage id='listContracts.step3.title' />
																</small>
															</Row>
															<Row>
																<Col md='4'>
																	<Form.Label>
																		<FormattedMessage id='all.chooseOneDistrict' />
																	</Form.Label>
																	<p>
																		{
																			districtMap[
																				contract.parishCode.substring(0, 2)
																			]
																		}
																	</p>
																</Col>
																<Col md='4'>
																	<Form.Label>
																		<FormattedMessage id='all.chooseOneCounty' />
																	</Form.Label>
																	<p>
																		{
																			countyMap[
																				contract.parishCode.substring(0, 4)
																			]
																		}
																	</p>
																</Col>
																<Col md='4'>
																	<Form.Label>
																		<FormattedMessage id='all.chooseOneParish' />
																	</Form.Label>
																	<p>{parishMap[contract.parishCode]}</p>
																</Col>
															</Row>
															<Row>
																<Col md='5'>
																	<DateTimeInputField
																		labelId='listContracts.step3.accordion.contractStart'
																		value={contract.effectiveContractStartDate}
																		isDateWithNoTime={true}
																		disabled={true}
																	/>
																</Col>
																<Col md='5'>
																	<DateTimeInputField
																		labelId='listContracts.step3.accordion.contractEnd'
																		disabled={true}
																		value={contract.effectiveContractEndDate}
																		isDateWithNoTime={true}
																	/>
																</Col>
															</Row>
															<Row>
																<Col md='5'>
																	<DateTimeInputField
																		disabled={true}
																		isDateWithNoTime={true}
																		labelId='listContracts.step3.accordion.taxationActivityStart'
																		value={
																			contract.companyFiscalActivityStartDate
																		}
																		tooltip={
																			<ToolTip
																				message={
																					<FormattedMessage id='listContracts.step3.accordion.taxationActivityStart.tooltip' />
																				}
																			/>
																		}
																	/>
																</Col>
																<Col md='6'>
																	<EnumSelectField
																		className='w-50'
																		labelId='listContracts.step3.accordion.unemploymentAntecipation'
																		disabled={true}
																		value={contract.unemploymentAnticipation}
																		preffixDescriptionId='all'
																		options={booleanOptions}
																	/>
																</Col>
															</Row>
															<Row className='mt-5 mb-0'>
																<Col md='12'>
																	<small className='text-secondary text-uppercase'>
																		<FormattedMessage id='listContracts.step3.accordion.empreendeMeasure' />
																	</small>
																</Col>
															</Row>
															<Row>
																<Col md='4'>
																	<DecimalInputField
																		labelId='listContracts.step3.accordion.feesFreeLoan'
																		value={contract.interestFreeLoan}
																		disabled={true}
																		suffixLabel=' €'
																	/>
																</Col>
																<Col md='4'>
																	<DecimalInputField
																		labelId='listContracts.step3.accordion.nonRefundableSubsidy'
																		disabled={true}
																		value={contract.nonRefundableSubsidy}
																		suffixLabel=' €'
																	/>
																</Col>
															</Row>
															<Row className='mb-3'>
																<Col md='4'>
																	<NumberInputField
																		labelId='listContracts.step3.accordion.workPosts'
																		disabled={true}
																		value={contract.workStations}
																	/>
																</Col>
																<Col md='7'>
																	<EnumSelectField
																		labelId='listContracts.step3.accordion.artsAndCraftsProject'
																		className='w-50'
																		disabled={true}
																		value={contract.artsAndCrafts}
																		preffixDescriptionId='all'
																		options={booleanOptions}
																	/>
																</Col>
															</Row>
														</Accordion.Body>
													</Accordion.Item>
												</Accordion>
											</Col>
										</Row>
									)
								)}
							</div>
						))}
				</React.Fragment>
			))}
		</Container>
	);
}
