import React, {useRef, useState} from 'react';
import {SubTemplate} from '../../../components/general/SubTemplate';
import {handleError, isNotBusinessError} from '../../../utils/handleError';
import {ConfigObject} from "../../../config";
import {createCustomErrorMessage} from "../../../hooks/errorMessage";
import {FormattedMessage, useIntl} from "react-intl";
import {uploadEntities} from "../../../rest/entity";


export function EntityUpload() {
  const [error, setError] = useState(null);
  const [documentError, setDocumentError] = useState('');
  const [submit, setSubmit] = useState(false);
  const intl = useIntl();
  const ref = useRef(null);



  if (error && isNotBusinessError(error)) {
    return handleError(error);
  }

  async function handleUpload() {
    console.log('Upload Function');
    const isFileValid = validateFile();

    if (!isFileValid) {
      console.log('Failed to Upload.');
      return;
    }


    try {
      // Create formData
      const formData = new FormData();
      formData.append('file', ref.current.files[0]);
      formData.append('fileName', ref.current.files[0].name);

      // Make the request
      console.log('Uploading...');
      const { data: document } = await uploadEntities(formData);
      setDocumentError(document);

      setSubmit(true);
      ref.current.value = null;
    } catch (error) {
      setError(error);
    }
  }
  function openInput() {
    ref.current.click();
  }


  function validateFile() {
    if (ref.current.files.length === 0) {
      return false;
    }

    const documentExtensions = ['csv'];
    const documentsSize = ConfigObject.get().documentsSize * 1000000;

    const file = ref.current.files[0];

    if (!documentExtensions.includes(file.type.split('/')[1])) {
      setError(
          createCustomErrorMessage(
              intl.formatMessage({ id: 'errors.uploadArea.fileExtension' })
          )
      );
      return false;
    }

    if (file.size > documentsSize) {
      setError(createCustomErrorMessage(intl.formatMessage({ id: 'errors.uploadArea.fileSize'},{value: ConfigObject.get().documentsSize} )));
      return false;
    }

    return true;
  }


  return (
    <SubTemplate hasBackButton titleId='entities.upload'>
      <div className={'container'}>
        <div className={'row pb-5'}>
          <input
              type='file'
              name={document}
              onChange={() => handleUpload()}
              ref={ref}
              className='d-none'
          />
          <div className='d-flex'>
            <button
                type='button'
                onClick={() => openInput()}
                className='btn btn-outline-primary'
            >
              <FormattedMessage id='entities.upload' />
            </button>
          </div>
          {submit && (
              <span className="mt-3">
                {documentError ? (
                    <FormattedMessage id='entities.errors.lines' values={{lines:documentError}} />
                ) : (
                    <FormattedMessage id='entities.added' />
                )}
              </span>
          )

          }

        </div>
      </div>
    </SubTemplate>
  );
}
