import {FormattedMessage} from "react-intl";
import React, {useEffect, useState} from "react";
import {IrregularityEntry} from "./IrregularityEntry";
import {EmptyResults} from "../../../general/EmptyResults";

export function IrregularityList({irregularities, irregularityCauseToRegulationMap}) {
    const [causeToRegulation, setCauseToRegulation,] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);


    return irregularities?.length ? (
        <table className='table d-table'>
            <thead>
                <tr>
                    <th>
                        <label>
                            <FormattedMessage id='candidatureTabs.oversight.tabs.irregularities.leadsToAidSuspension' />
                        </label>
                    </th>
                    <th>
                        <label>
                            <FormattedMessage id='candidatureTabs.oversight.tabs.irregularities.deadline' />
                        </label>
                    </th>

                    <th>
                        <label>
                            <FormattedMessage id='candidatureTabs.oversight.tabs.irregularities.fixed' />
                        </label>
                    </th>
                    <th>
                        <label>
                            <FormattedMessage id='candidatureTabs.oversight.tabs.irregularities.causes' />
                        </label>
                    </th>
                </tr>
            </thead>
            <tbody>
            {irregularities.map((i) => <IrregularityEntry
                key={i.externalId} irregularity={i}
                irregularityCauseToRegulationMap={irregularityCauseToRegulationMap}
            />)}
            </tbody>
        </table>
    ) : (<EmptyResults />);
}