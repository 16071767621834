import React, { useEffect, useState } from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { DecimalInputField } from '../../bootstrap/DecimalInputField';
import { currencyFormat } from '../../../utils/CurrencyUtils';
import { NumberInputField } from '../../bootstrap/NumberInputField';
import {formatValue} from "react-currency-input-field";
import {ConfigObject} from "../../../config";
import { FaInfoCircle } from 'react-icons/fa';

export function SemestralPaymentRequestFormStep3({
	formStep,
	numberOfSteps,
	errors,
	handleChange,
	formikValues,
	handlePreviousStep,
	handleNextStep,
	setFieldValue,
    readMode = false,
}) {
	const intl = useIntl();

	return (
		<div>
			<h4 className='text-primary mb-0'>
				<FormattedMessage id='semestralPaymentRequestForm.sideNavBar.MCEFinancing' />
			</h4>
			<small className='text-secondary text-uppercase'>
				{intl.formatMessage(
					{ id: 'all.step' },
					{ step: formStep, numberOfSteps: numberOfSteps }
				)}
			</small>
			<p className='mt-3 mb-0 text-secondary infoText'>
				<FaInfoCircle size={40} /> {intl.formatMessage(
				{ id: 'addSemestral.step3.infoText' },

			)}
			</p>
			<Row>
				<Col md='6'>
					<DecimalInputField
						className='w-25'
						suffixLabel='€'
						labelId='semestralPaymentRequestForm.MCEFinancingPlafond'
						name='plafond'
						value={formikValues.plafond}
						handleChange={handleChange}
						isInvalid={errors.financingPlafondMCE ? true : false}
						errorMessage={
							errors.financingPlafondMCE ? errors.financingPlafondMCE : ''
						}
						disabled={readMode}
					/>
				</Col>
				<Col md='6'>
					<Form.Label>
						<FormattedMessage id='semestralPaymentRequestForm.IASAtTheContractCelebration' />
					</Form.Label>
					<p className='mt-2'>{currencyFormat(formikValues.mceFinancing.ias)}</p>
				</Col>

				<Col md='6'>
					<NumberInputField
						className='w-25'
						labelId='semestralPaymentRequestForm.howManyTimesIAS'
						name='howManyTimesIAS'
						value={formikValues.mceFinancing.timesIas}
						handleChange={handleChange}
						isInvalid={errors.howManyTimesIAS ? true : false}
						errorMessage={errors.howManyTimesIAS ? errors.howManyTimesIAS : ''}
						disabled={readMode}
					/>
				</Col>
				<Col md='6'>
					<NumberInputField
						className='w-25'
						labelId='semestralPaymentRequestForm.contractDuration'
						name='contractDuration'
						value={formikValues.mceFinancing.contractDuration}
						handleChange={handleChange}
						disabled={readMode}
						isInvalid={errors.contractDuration ? true : false}
						errorMessage={
							errors.contractDuration ? errors.contractDuration : ''
						}
					/>
				</Col>
			</Row>
			<Row className='mt-5 mb-0'>
				<Col>
					<small className='text-secondary text-uppercase'>
						<FormattedMessage id='semestralPaymentRequestForm.plafondDistributionByActivityType' />
					</small>
				</Col>
			</Row>
			<Row>
				<Col md='3'>
					<Form.Label>
						<FormattedMessage id='semestralPaymentRequestForm.followUp' />
					</Form.Label>
					<p>
						{currencyFormat(formikValues.plafondFollowUp)}
					</p>
				</Col>
				<Col>
					<Form.Label>
						<FormattedMessage id='semestralPaymentRequestForm.skillsExtension' />
					</Form.Label>
					<p>
						{currencyFormat(formikValues.plafondConsulting)}
					</p>
				</Col>
			</Row>
			<div className='form-actions mt-5'>
				<button
					className='btn btn-link'
					type='button'
					onClick={handlePreviousStep}
				>
					<FormattedMessage id='all.back' />
				</button>
				<button
					className='btn btn-primary'
					type='button'
					onClick={handleNextStep}
				>
					<FormattedMessage id='all.nextStep' />
				</button>
			</div>
		</div>
	);
}
