import { useIntl } from 'react-intl';

/**
 * Creates an empty error message
 *
 * @returns {{ show: boolean, type: string, message: string }}
 */
export const createEmptyErrorMessage = () => {
  return { show: true, type: 'danger', message: null };
};

export const useMessageObjectCreator = () => {
  const intl = useIntl();

  return {
    create: (error) => {
      if (error?.response?.status === 400) {
        const message = createEmptyErrorMessage();
        const data = error.response.data;
        if (data) {
          let messageObject = {id: `exceptions.${data.exception}`};
          let valuesObject = {}

          if (data.message !== undefined) {
            valuesObject['value'] = data.message;
          }
          if (data.fieldName !== undefined) {
            valuesObject['fieldName'] = data.fieldName;
            messageObject['id'] += 2;
          }

          if (data.exception === 'ObjectNotFoundException' && data.message === 'candidatureNif') {
            message.message =intl.formatMessage({id:'error.reports.candidatureNifNotExists'});
          }else  if (data.exception === 'OperationNotAllowedException' && data.message === 'pendings') {
            message.message =intl.formatMessage({id:'error.reports.pendings'});
          }else if (data.exception === 'OperationNotAllowedException' && data.message === 'revoked') {
            message.message =intl.formatMessage({id:'error.reports.revoked'});
          } else {
          message.message =
              data.exception === 'InconsistentFieldException' ? intl.formatMessage(
                  {id: `exceptions.${data.exception}.${data.message}`}) :
                  intl.formatMessage(
                      messageObject, valuesObject
                  );
        }
          return message;
        }
      }
      return { show: false, type: null, message: null };
    },
  };
};

export const createCustomErrorMessage = (message, status) => {
  return {
    response: {
      status: status || 400,
      data: { exception: 'FrontendCustomException', message: message },
    },
  };
};

export const createExceptionErrorMessage = (exception, message) => {
  return {
    response: {
      status: 400,
      data: { exception: exception, message: message },
    },
  };
};
