import {apiAxios as authAxios} from "../axios.config";

export const getNonComplianceCauseToRegulationMap = (externalId) => {
    return authAxios.get(`/api/candidature/${externalId}/noncompliance/causemap`);
};


export const getNonCompliances = (externalId) => {
    return authAxios.get(`/api/candidature/${externalId}/noncompliance`);
};

export const getNonCompliance = (candidatureExternalId, nonComplianceExternalId) => {
    return authAxios.get(`/api/candidature/${candidatureExternalId}/noncompliance/${nonComplianceExternalId}`);
};


export const addNonCompliance = (externalId, formData) => {
    return authAxios.post(`/api/candidature/${externalId}/noncompliance`, formData);
};

export const addAppealDocument = (candidatureExternalId, nonComplianceExternalId, formData) => {
    return authAxios.post(`api/candidature/${candidatureExternalId}/noncompliance/${nonComplianceExternalId}/appeal/documents`, formData);
};

export const deleteAppealDocument = (candidatureExternalId, nonComplianceExternalId, documentExternalId) => {
    return authAxios.delete(`api/candidature/${candidatureExternalId}/noncompliance/${nonComplianceExternalId}/appeal/documents/${documentExternalId}`);
};

export const appealNonCompliance = (candidatureExternalId, nonComplianceExternalId, appeal) => {
    return authAxios.post(`api/candidature/${candidatureExternalId}/noncompliance/${nonComplianceExternalId}/appeal`, appeal);
}

export const decideNonCompliance = (candidatureExternalId, nonComplianceExternalId, formData) => {
    return authAxios.post(`api/candidature/${candidatureExternalId}/noncompliance/${nonComplianceExternalId}/decision`, formData);
}

export const restitutionNonCompliance = (candidatureExternalId, nonComplianceExternalId, values) => {
    return authAxios.post(`api/candidature/${candidatureExternalId}/noncompliance/${nonComplianceExternalId}/restitution`, values);
}
