import { apiAxios as authAxios } from '../axios.config';

export const listContestationDocumentsByCandidature = (externalId) => {
  return authAxios.get(`api/candidature/${externalId}/contestation/documents`);
};

export const saveContestation = (externalId, contestation) => {
  return authAxios.post(`api/candidature/${externalId}/contestation`, contestation);
};

export const saveContestationWithDocuments = (externalId, formData) => {
  return authAxios.post(`/api/candidature/${externalId}/contestation`, formData);
};

export const getLastPriorSupport = (externalId) => {
  return authAxios.get(`api/candidature/${externalId}/priorSupport`);
};


export const saveCandidature = (candidature, params) => {
  return authAxios.post('/api/candidature', candidature, { params: params }); //params are not required. If set, include: preSavePriorEntity
};

export const getUserCandidature = () => {
  return authAxios.get('/api/candidature/user');
};

export const getUserCandidatureExternalId = () => {
  return authAxios.get('/api/candidature/externalId/user');
};

export const createDefaultCandidature = () => {
  return authAxios.get('/api/candidature/user/createDefault');
};

export const getCandidatureByExternalId = (externalId) => {
  return authAxios.get(`/api/candidature/${externalId}`);
};

export const setCandidatureToEmitOpinion = (externalId) => {
  return authAxios.patch(`/api/candidature/${externalId}/setToEmitOpinion`);
};


export const setToRejectPromoters = (externalId, reasonData) => {
  return authAxios.patch(`/api/candidature/${externalId}/setToRejectPromoters`, reasonData);
};

export const submitFinalDecision = (externalId) => {
  return authAxios.post(`api/candidature/${externalId}/submitFinalDecision`);
};

export const submitArtsCraftOpinion = (externalId) => {
  return authAxios.post(`api/candidature/${externalId}/submitArtsCraftOpinion`);
};

export const submitTermOfAcceptDraft = (externalId) => {
  return authAxios.post(`api/candidature/${externalId}/submitTermOfAcceptDraft`);
};

export const submitExpirationApprovalDecision = (externalId) => {
  return authAxios.post(`api/candidature/${externalId}/submitApprovalDecisionExpiration`);
};

export const cancelApprovalDecisionExpiration = (externalId) => {
  return authAxios.post(`api/candidature/${externalId}/cancelApprovalDecisionExpiration`);
};

export const submitTermOfAccept = (externalId,data) => {
  console.log(data)
  return authAxios.post(`api/candidature/${externalId}/submitTermOfAccept`,data);
};

export const getTermOfAccept = (externalId) => {
  return authAxios.get(`api/candidature/${externalId}/getTermOfAccept`);
};

export const sendToDoubtsClarificationForOpinion = (externalId, reasonData) => {
  return authAxios.patch(
    `/api/candidature/${externalId}/sendToDoubtsClarificationForOpinion`,
    reasonData
  );
};

export const setToOpinionValidation = (externalId) => {
  return authAxios.patch(`/api/candidature/${externalId}/setToOpinionValidation`);
};

export const setToValidation = (externalId) => {
  return authAxios.patch(`/api/candidature/${externalId}/setToValidation`);
};

export const sendToOpinionReview = (externalId, reasonData) => {
  return authAxios.patch(`/api/candidature/${externalId}/sendToOpinionReview`, reasonData);
};

export const sendToDoubtsClarificationForValidation = (externalId, reasonData) => {
  return authAxios.patch(
    `/api/candidature/${externalId}/sendToDoubtsClarificationForValidation`,
    reasonData
  );
};

export const finishCandidatureValidation = (externalId) => {
  return authAxios.patch(`/api/candidature/${externalId}/finishValidation`);
};

export const clarifyDoubts = (externalId, reasonData) => {
  return authAxios.patch(`/api/candidature/${externalId}/clarifyDoubt`, reasonData);
};

export const setToValidateRequiredDocuments = (externalId, documentsMetaData) => {
  return authAxios.patch(
    `/api/candidature/${externalId}/setToValidateRequiredDocuments`,
    documentsMetaData
  );
};

export const setToAcceptanceTermIssuance = (externalId, documentsMetaData) => {
  return authAxios.patch(
    `/api/candidature/${externalId}/setToAcceptanceTermIssuance`,
    documentsMetaData
  );
};

export const acceptByPromoter = (externalId) => {
  return authAxios.patch(`/api/candidature/${externalId}/acceptByPromoter`);
};

export const refuseByPromoter = (externalId) => {
  return authAxios.patch(`/api/candidature/${externalId}/refuseByPromoter`);
};


export const getListCandidatures = (index, size, state,search,entityNif,entityType,rd) => {
  return authAxios.get('/api/candidature', {
    params: {
      index: index,
      size: size,
      state: state,
      search:search,
      rd:rd,
      entityNif:entityNif,
      entityType:entityType
    }
  });
};

export const approve = (externalId, formData) => {
  return authAxios.patch(`/api/candidature/${externalId}/approve`, formData);
};

export const acceptedValidateEligibility = (externalId) => {
  return authAxios.patch(`/api/candidature/${externalId}/acceptedValidateEligibility`);
};

export const rejectValidateEligibility = (externalId) => {
  return authAxios.patch(`/api/candidature/${externalId}/rejectValidateEligibility`);
};


export const reject = (externalId, formData) => {
  return authAxios.patch(`/api/candidature/${externalId}/reject`, formData);
};

export const submitProposalDecisionExpiration = (externalId) => {
  return authAxios.patch(`/api/candidature/${externalId}/submitProposalDecisionExpiration`);
};

export const getCandidatureHistory = (candidatureExternalId) => {
  return authAxios.get(`/api/candidature/${candidatureExternalId}/history`);
};

export const rejectContestation = (externalId) => {
  return authAxios.patch(`/api/candidature/${externalId}/rejectContestation`);
};

export const askPriorSupport = (externalId, dataAskPriorSupport) => {
  return authAxios.post(`/api/candidature/${externalId}/priorSupport`, dataAskPriorSupport);
};

export const finalizePriorSupport = (externalId) => {
  return authAxios.post(`/api/candidature/${externalId}/finalizePriorSupport`);
};

export const answerReviewPriorSupport = (externalId) => {
  return authAxios.post(`/api/candidature/${externalId}/answerReviewPriorSupport`);
};

export const candidatureWithdrawal = (authenticationCredentials, candidatureExternalId) => {
  return authAxios.patch(
    `/api/candidature/${candidatureExternalId}/withdrawal`,
    authenticationCredentials
  );
};

export const submitPartialApproval = (formData) => {
  return authAxios.post('/api/candidature/submitPartialApproval', formData);
};

export const contactPromoter = (externalId, message) => {
  return authAxios.post(`/api/candidature/${externalId}/contactPromoter`, message);
};

export const contactPromoterWithFiles = (externalId, formData) => {
  return authAxios.post(`/api/candidature/${externalId}/contactPromoter`, formData);
};

export const isValidCpp = (cpp) => {
  return authAxios.get(`/api/candidature/isValidCpp/${cpp}`);
}

export const isValidCae = (cae) => {
  return authAxios.get(`/api/cae/${cae}/valid`);
}

export const getCae = (cae) => {
  return authAxios.get(`/api/cae/${cae}`);
}

export const getProjectIdentificationInitial = (externalId) => {
  return authAxios.get(`/api/projectIdentificationForm/${externalId}`);
};

export const submitProjectIdentificationInitial = (projectIdentification) => {
  console.log(projectIdentification)
  //return authAxios.post(`/api/projectIdentificationForm`, projectIdentification);
};


export const getPrimaryPromoterByCandidatureExternalId = (externalId) => {
  return authAxios.get(`/api/candidature/${externalId}/primaryPromoter`);
};

export const sendFinalDeleteDecision = (externalId) => {
  return authAxios.patch(`/api/candidature/${externalId}/finalDeleteDecision`);
};