import {Alert, Col, Container, Row} from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { DocumentType } from '../../models/DocumentType';
import { NumberInputField } from '../bootstrap/NumberInputField';
import { TextInputField } from '../bootstrap/TextInputField';
import { AuthenticatedDownloadLink } from '../document/AuthenticatedDownloadLink';
import {FaInfoCircle} from "react-icons/fa";
import React from "react";
import {useNavigate} from "react-router-dom";


export function EntityResumeTab({ entityDetails, districtOptions, documents }) {
	const intl = useIntl();

	console.log(entityDetails)

	const requiredFileTypes = [
		DocumentType.COOPERATION_PROTOCOL,
		DocumentType.NIB_PROOF,
	];

	return (
		<Container>
			<Row>
				<Col md='8'>
					<TextInputField
						disabled
						labelId={'eaAccreditationForm.step1.description'}
						value={entityDetails.description}
					/>
				</Col>
				<Col md='4'>
					<NumberInputField
						disabled
						labelId={'eaAccreditationForm.step1.nif'}
						value={entityDetails.nif}
					/>
				</Col>
			</Row>
			<Row>

				<Col md='8'>
					<NumberInputField
						className='w-25'
						labelId={'eaAccreditationForm.step1.nationalMemberYear'}
						value={entityDetails.nationalMemberYear}
						disabled
					/>
				</Col>
				<Col md='4'>
					<TextInputField
						className='w-25'
						labelId={'eaAccreditationForm.step1.processId'}
						value={entityDetails.processId}
						disabled
					/>
				</Col>
			</Row>
			<Row>
				<Col md='4'>
					<TextInputField
						disabled
						labelId={'eaAccreditationForm.step1.district'}
						value={
							districtOptions.filter(
								(district) => district.code === entityDetails.districtCode
							)[0].description
						}
					/>
				</Col>
				<Col md='4'>
					<TextInputField
						disabled
						value={entityDetails.location}
						labelId='eaAccreditationForm.step1.location.placeholder'
					/>
				</Col>
				<Col md='4'>
					<TextInputField
						disabled
						value={
							entityDetails.postalCode4Digits +
							' - ' +
							entityDetails.postalCode3Digits
						}
						labelId='eaAccreditationForm.step1.postalCode'
					/>
				</Col>
			</Row>
			<Row>
				<Col md={entityDetails.nib ? '8' : '12'}>
					<TextInputField
						disabled
						labelId={'eaAccreditationForm.step1.address'}
						value={entityDetails.address}
					/>
				</Col>
				{entityDetails.nib &&
					<Col md='4'>
						<TextInputField
							className='w-25'
							labelId={'eaAccreditationForm.step1.nib'}
							value={entityDetails.nib}
							disabled
						/>
					</Col>}

			</Row>
			<Row className='align-items-end'>


			</Row>
			<Row>
				<Col md='8'>
					<TextInputField
						labelId={'eaAccreditationForm.step1.email'}
						value={entityDetails.email}
						disabled
					/>
				</Col>

				<Col md='4'>
					<NumberInputField
						labelId={'eaAccreditationForm.step1.phone'}
						value={entityDetails.phone}
						disabled
					/>
				</Col>
			</Row>
			<Row>
				<Col md='8'>
					<TextInputField
						disabled
						labelId={'eaAccreditationForm.step1.type'}
						value={intl.formatMessage({
							id: `eaAccreditationForm.step1.type.option.${entityDetails.profile}`,
						})}
					/>
					{entityDetails.profileDescription && (
						<div className='mt-1'>
							<TextInputField
								disabled
								value={entityDetails.profileDescription}
							/>
						</div>
					)}
				</Col>
				<Col md='4'>
					<NumberInputField
						disabled
						className='w-50'
						labelId={'eaAccreditationForm.step1.cae'}
						value={entityDetails.cae}
					/>
				</Col>
			</Row>
			<Row className='mb-3'>
				<Col>
					<small className='d-block text-uppercase text-secondary border-bottom mt-5'>
						<FormattedMessage id={`documents.list`} />
					</small>
				</Col>
			</Row>
			{requiredFileTypes.map(ft => documents.filter(d => d.documentType === ft).map(d => (
				<Row key={d.externalId} className='mb-3'>
					<Col>
						<AuthenticatedDownloadLink
							url={d.documentPath}
							filename={d.name}
						>
							<FormattedMessage id={`documentType.${d.documentType}`} />

						</AuthenticatedDownloadLink>
					</Col>
				</Row>
			)))}
		</Container>
	);
}
