import { apiAxios as authAxios } from '../axios.config';

export const listAdditionalActivitiesPayments = (queryParams) => {
  return authAxios.get('/api/additionalActivitiesPlan/payment/list/', {params:  queryParams})
}

export const additionalActivitiesPaymentRequestYearStatus = (entityNif) => {
  return authAxios.get('/api/additionalActivitiesPlan/payment/yearStatus', entityNif ? {params: {
    entityNif: entityNif
  }}: null)
}

export const getUserAdditionalActivitiesPaymentRequest = () => {
  return authAxios.get('/api/additionalActivitiesPlan/payment')
}

export const getAdditionalActivitiesPaymentRequestByExternalId = (b18ExternalId, entityNif) => {
  return authAxios.get(`/api/additionalActivitiesPlan/payment/${b18ExternalId}`, entityNif ? {params: {
    entityNif: entityNif
  }}: null)
}

export const getAdditionalActivitiesPaymentRequestByProcessExternalId = (b18ProcessExternalId) => {
  return authAxios.get(`/api/additionalActivitiesPlan/payment/process/${b18ProcessExternalId}`)
}

export const listAdditionalActivitiesPaymentRequestDocuments = (b18ExternalId) => {
  return authAxios.get(`/api/additionalActivitiesPlan/payment/${b18ExternalId}/document`)
}

export const saveAdditionalActivitiesPaymentRequest = (additionalActivitiesPaymentRequest, queryParams) => {
  return authAxios.post('/api/additionalActivitiesPlan/payment', additionalActivitiesPaymentRequest, queryParams ? {params: queryParams}: null)
}

export const uploadB18Document = (b18ExternalId, formData) => {
  return authAxios.post(`/api/additionalActivitiesPlan/payment/${b18ExternalId}/document`, formData)
}

export const deleteB18Document = (b18ExternalId, documentExternalId) => {
  return authAxios.delete(`/api/additionalActivitiesPlan/payment/${b18ExternalId}/document/${documentExternalId}`)
}


export const withdrawAdditionalActivitiesPaymentRequest = (b18ExternalId, authenticationCredentials) => {
  return authAxios.patch(`/api/additionalActivitiesPlan/payment/${b18ExternalId}/withdrawal`, authenticationCredentials)
}
