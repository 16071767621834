import React from 'react';
import { Row, Col, Form, Button, Accordion } from 'react-bootstrap';
import { FaExclamationCircle } from 'react-icons/fa';
import { FormattedMessage, useIntl } from 'react-intl';
import { MCESupportKind } from '../../models/MCESupportKind';
import { EnumSelectField } from '../bootstrap/EnumSelectField';
import { NumberInputField } from '../bootstrap/NumberInputField';
import { TextInputField } from '../bootstrap/TextInputField';
import ToolTip from '../bootstrap/ToolTip';
import { DateTimeInputField } from '../bootstrap/DateTimeInputField';
import DecimalInputField from '../bootstrap/DecimalInputField';
import { getCountiesByDistrictCode, getParishesByCountyCode } from '../../rest/administrativeDivision';
import IntInputField from "../bootstrap/IntInputField";

export function ListContractsStep2({
	formStep,
	numberOfSteps,
	errors,
	handleChange,
	formikValues,
	handlePreviousStep,
	setFieldValue,
	entityTGPList,
	readMode,
	districts,
	districtToCountiesMap,
	countyToParishesMap,
	setDistrictToCountyMap,
	setCountyToParishMap,
	currentChosenLocations,
	setCurrentChosenLocations,
	setError
}) {
	const intl = useIntl();

	const booleanOptions = ['true', 'false'];

	function addNewContractHandler() {
		currentChosenLocations[formikValues.contractIdentificationList.length] = {districtCode: '-1', countyCode: '-1'};
		setCurrentChosenLocations({...currentChosenLocations})

		
		formikValues.contractIdentificationList.push({
			contractNumber: '',
			contractModality: '-1',
			projectCompanyName: '',
			projectCompanyNIF: '',
			projectManagerExternalId: '-1',
			anProjects: '',
			currentProjects: '',
			totalProjects: '',

			companyFiscalActivityStartDate: null,
			effectiveContractStartDate: null,
			effectiveContractEndDate: null,
			unemploymentAnticipation: '-1',
			interestFreeLoan: '',
			nonRefundableSubsidy: '',
			workStations: '',
			artsAndCrafts: '-1',

			parishCode: '-1',
		});
		setFieldValue({ ...formikValues });
		
	}

	function removeContractHandler(index) {
		let lengthBeforeRemoval = formikValues.contractIdentificationList.length;
		delete currentChosenLocations[index];
		if (lengthBeforeRemoval > 1) {
			for (let i = index + 1; i < lengthBeforeRemoval; i++) {
				currentChosenLocations[i - 1] = currentChosenLocations[i];
			}
			delete currentChosenLocations[lengthBeforeRemoval - 1];
		}
		setCurrentChosenLocations({ ...currentChosenLocations });

		formikValues.contractIdentificationList.splice(index, 1);
		setFieldValue({ ...formikValues });
	}

	async function handleDistrictChange(districtName, index) {
		try {
			if (districtName !== '-1') {
				let districtCode = districts.filter(
					(district) => district.description === districtName
				)[0].code;

				currentChosenLocations[index].districtCode = districtCode

				if (!districtToCountiesMap.hasOwnProperty(districtCode)) {
					const { data: counties } = await getCountiesByDistrictCode(
						districtCode
					);
					setDistrictToCountyMap({
						...districtToCountiesMap,
						[districtCode]: counties,
					});
				}
			} else {
				currentChosenLocations[index].districtCode = '-1';
			}

			currentChosenLocations[index].countyCode = '-1';
			console.log(currentChosenLocations)
			setCurrentChosenLocations({ ...currentChosenLocations });

			formikValues.contractIdentificationList[index].parishCode = '-1';
			setFieldValue({ ...formikValues });
		} catch (error) {
			setError(error);
		}
	}

	async function handleCountyChange(countyName, index) {
		try {
			if (countyName !== '-1') {
				let countyCode = districtToCountiesMap[
					currentChosenLocations[index].districtCode
				].filter((county) => county.description === countyName)[0].code;

				currentChosenLocations[index].countyCode = countyCode;

				if (!countyToParishesMap.hasOwnProperty(countyCode)) {
					const { data: parishes } = await getParishesByCountyCode(countyCode);
					setCountyToParishMap({
						...countyToParishesMap,
						[countyCode]: parishes,
					});
				}
			} else {
				currentChosenLocations[index].countyCode = '-1';
			}

			setCurrentChosenLocations({ ...currentChosenLocations });
			formikValues.contractIdentificationList[index].parishCode = '-1';
			setFieldValue({ ...formikValues });
		} catch (error) {
			setError(error);
		}
	}

	function handleParishChange(parishName, index) {
		if (parishName !== '-1') {
			formikValues.contractIdentificationList[index].parishCode =
				countyToParishesMap[currentChosenLocations[index].countyCode].filter(
					(parish) => parish.description === parishName
				)[0].code;
		} else {
			formikValues.contractIdentificationList[index].parishCode = '-1';
		}
		setFieldValue({ ...formikValues });
	}

	return (
		<div>
			<h4 className='text-primary mb-0'>
				<FormattedMessage id='listContracts.step2.title' />
			</h4>
			<small className='text-secondary text-uppercase'>
				{intl.formatMessage(
					{ id: 'all.step' },
					{ step: formStep, numberOfSteps: numberOfSteps }
				)}
			</small>
			<fieldset disabled={readMode == true}>
				{formikValues.contractIdentificationList.map((contract, index) => (
					<Row key={index}>
						<Col md='12'>
							<Accordion defaultActiveKey='0'>
								<Accordion.Item eventKey='0'>
									<Accordion.Header>
										<span className='d-flex align-items-center'>
											{errors.contractIdentificationList?.[index] && (
												<FaExclamationCircle />
											)}
											<FormattedMessage id='listContracts.step2.accordion.contract' />{' '}
											{index + 1}
										</span>
									</Accordion.Header>
									<Accordion.Body>
										<Row>
											<Col md='4'>
												<TextInputField
													value={contract.contractNumber}
													name={`contractIdentificationList[${index}].contractNumber`}
													handleChange={handleChange}
													isInvalid={
														errors.contractIdentificationList?.[index]
															?.contractNumber
													}
													errorMessage={
														errors.contractIdentificationList?.[index]
															?.contractNumber
													}
													labelId='listContracts.step2.accordion.contractNumber'
												/>
											</Col>
											<Col md='8'>
												<EnumSelectField
													labelId='listContracts.step2.accordion.modality'
													name={`contractIdentificationList[${index}].contractModality`}
													handleChange={handleChange}
													value={contract.contractModality}
													nullOption={true}
													preffixDescriptionId='mceSupportKind'
													options={MCESupportKind.values()}
													isInvalid={
														errors.contractIdentificationList?.[index]
															?.contractModality
													}
													errorMessage={
														errors.contractIdentificationList?.[index]
															?.contractModality
													}
												/>
											</Col>
										</Row>
										<Row>
											<Col md='8'>
												<TextInputField
													value={contract.projectCompanyName}
													name={`contractIdentificationList[${index}].projectCompanyName`}
													handleChange={handleChange}
													isInvalid={
														errors.contractIdentificationList?.[index]
															?.projectCompanyName
													}
													errorMessage={
														errors.contractIdentificationList?.[index]
															?.projectCompanyName
													}
													labelId='listContracts.step2.accordion.promotersOrCompanyIdentification'
												/>
											</Col>
											<Col md='4'>
												<NumberInputField
													value={contract.projectCompanyNIF}
													name={`contractIdentificationList[${index}].projectCompanyNIF`}
													handleChange={handleChange}
													isInvalid={
														errors.contractIdentificationList?.[index]
															?.projectCompanyNIF
													}
													errorMessage={
														errors.contractIdentificationList?.[index]
															?.projectCompanyNIF
													}
													labelId='listContracts.step2.accordion.nifOrNipc'
													maxLength={9}
												/>
											</Col>
										</Row>
										<Row>
											<Col md='8'>
												<Form.Group>
													<Form.Label>
														<FormattedMessage id='listContracts.step2.accordion.projectManagerIdentification' />
													</Form.Label>
													<Form.Control
														as='select'
														name={`contractIdentificationList[${index}].projectManagerExternalId`}
														onChange={handleChange}
														value={contract.projectManagerExternalId}
														isInvalid={
															errors.contractIdentificationList?.[index]
																?.projectManagerExternalId
														}
													>
														<option value={'-1'}>
															<FormattedMessage id='all.chooseOne' />
														</option>
														{entityTGPList.map((tgp) => (
															<option
																key={tgp.externalId}
																value={tgp.externalId}
															>
																{tgp.name}
															</option>
														))}
													</Form.Control>
													<Form.Control.Feedback type='invalid'>
														{
															errors.contractIdentificationList?.[index]
																?.projectManagerExternalId
														}
													</Form.Control.Feedback>
												</Form.Group>
											</Col>
											{contract.projectManagerExternalId !== '-1' && (
												<Col md='4'>
													<NumberInputField
														labelId='listContracts.step2.accordion.nif'
														disabled={true}
														value={
															entityTGPList.filter(
																(tgp) =>
																	tgp.externalId ===
																	contract.projectManagerExternalId
															)?.[0]?.hre?.nif
														}
													/>
												</Col>
											)}
										</Row>
										<Row className='mt-5 mb-0'>
											<Col md='12'>
												<small className='text-secondary text-uppercase'>
													<FormattedMessage id='listContracts.step2.accordion.activeProjectsNumberByProjectManager' />
												</small>
											</Col>
										</Row>
										<Row className='mb-3'>
											<Col md='4'>
												<IntInputField
													value={contract.anProjects}
													name={`contractIdentificationList[${index}].anProjects`}
													handleChange={handleChange}
													isInvalid={
														errors.contractIdentificationList?.[index]
															?.anProjects
													}
													errorMessage={
														errors.contractIdentificationList?.[index]
															?.anProjects
													}
													labelId='listContracts.step2.accordion.anNumber'
												/>
											</Col>
											<Col md='4'>
												<IntInputField
													value={contract.currentProjects}
													name={`contractIdentificationList[${index}].currentProjects`}
													handleChange={handleChange}
													isInvalid={
														errors.contractIdentificationList?.[index]
															?.currentProjects
													}
													errorMessage={
														errors.contractIdentificationList?.[index]
															?.currentProjects
													}
													labelId='listContracts.step2.accordion.actualNumber'
												/>
											</Col>
											<Col md='4'>
												<IntInputField
													value={contract.totalProjects}
													name={`contractIdentificationList[${index}].totalProjects`}
													handleChange={handleChange}
													isInvalid={
														errors.contractIdentificationList?.[index]
															?.totalProjects
													}
													errorMessage={
														errors.contractIdentificationList?.[index]
															?.totalProjects
													}
													labelId='listContracts.step2.accordion.totalNumber'
												/>
											</Col>
										</Row>

										<Row className='mt-5'>
											<small className='text-primary text-uppercase'>
												<FormattedMessage id='listContracts.step3.title' />
											</small>
										</Row>
										<Row>
											<Col md='4'>
												<EnumSelectField
													nullOption={true}
													labelId={readMode ? 'promoterForm.district' : 'all.chooseOneDistrict'}
													options={districts.map((d) => d.description)}
													handleChange={(e) =>
														handleDistrictChange(e.target.value, index)
													}
													value={
														currentChosenLocations[index].districtCode !== '-1'
															? districts.filter(
																	(d) =>
																		d.code ===
																		currentChosenLocations[index].districtCode
															  )[0].description
															: '-1'
													}
												/>
											</Col>
											<Col md='4'>
												<EnumSelectField
													disabled={
														currentChosenLocations[index].districtCode === '-1'
													}
													nullOption={true}
													options={
														currentChosenLocations[index].districtCode !== '-1'
															? districtToCountiesMap[
																	currentChosenLocations[index].districtCode
															  ].map((countyObj) => countyObj.description)
															: []
													}
													handleChange={(e) =>
														handleCountyChange(e.target.value, index)
													}
													labelId={readMode ? 'promoterForm.county' : 'all.chooseOneCounty'}
													value={
														currentChosenLocations[index].countyCode !== '-1'
															? districtToCountiesMap[
																	currentChosenLocations[index].districtCode
															  ].filter(
																	(countyObj) =>
																		currentChosenLocations[index].countyCode ===
																		countyObj.code
															  )[0].description
															: '-1'
													}
												/>
											</Col>
											<Col md='4'>
												<EnumSelectField
													disabled={
														currentChosenLocations[index].countyCode === '-1'
													}
													nullOption={true}
													labelId={readMode ? 'promoterForm.parish' : 'all.chooseOneParish'}
													handleChange={(e) =>
														handleParishChange(e.target.value, index)
													}
													options={
														currentChosenLocations[index].countyCode !== '-1'
															? countyToParishesMap[
																	currentChosenLocations[index].countyCode
															  ].map((parishObj) => parishObj.description)
															: []
													}
													value={
														contract.parishCode !== '-1'
															? countyToParishesMap[
																	currentChosenLocations[index].countyCode
															  ].filter(
																	(parish) =>
																		parish.code === contract.parishCode
															  )[0].description
															: '-1'
													}
													isInvalid={
														errors.contractIdentificationList?.[index]
															?.parishCode
													}
													errorMessage={
														errors.contractIdentificationList?.[index]
															?.parishCode
													}
												/>
											</Col>
										</Row>
										<Row>
											<Col md='5'>
												<DateTimeInputField
													labelId='listContracts.step3.accordion.contractStart'
													name={`contractIdentificationList[${index}].effectiveContractStartDate`}
													value={contract.effectiveContractStartDate}
													setFieldValue={setFieldValue}
													isDateWithNoTime={true}
													isInvalid={
														errors.contractIdentificationList?.[index]
															?.effectiveContractStartDate
													}
													errorMessage={
														errors.contractIdentificationList?.[index]
															?.effectiveContractStartDate
													}
												/>
											</Col>


											{contract.contractModality === MCESupportKind.POST_PROJECT_APPROVAL &&

											<Col md='5'>
												<DateTimeInputField
													labelId='listContracts.step3.accordion.contractEnd'
													name={`contractIdentificationList[${index}].effectiveContractEndDate`}
													value={contract.effectiveContractEndDate}
													setFieldValue={setFieldValue}
													isDateWithNoTime={true}
													isInvalid={
														errors.contractIdentificationList?.[index]
															?.effectiveContractEndDate
													}
													errorMessage={
														errors.contractIdentificationList?.[index]
															?.effectiveContractEndDate
													}
												/>
											</Col>

											}



										</Row>
										<Row>
											<Col md='5'>
												<DateTimeInputField
													labelId='listContracts.step3.accordion.taxationActivityStart'
													name={`contractIdentificationList[${index}].companyFiscalActivityStartDate`}
													value={contract.companyFiscalActivityStartDate}
													setFieldValue={setFieldValue}
													isDateWithNoTime={true}
													isInvalid={
														errors.contractIdentificationList?.[index]
															?.companyFiscalActivityStartDate
													}
													errorMessage={
														errors.contractIdentificationList?.[index]
															?.companyFiscalActivityStartDate
													}
													tooltip={
														<ToolTip
															message={
																<FormattedMessage id='listContracts.step3.accordion.taxationActivityStart.tooltip' />
															}
														/>
													}
												/>
											</Col>
											<Col md='6'>
												<EnumSelectField
													className='w-50'
													labelId='listContracts.step3.accordion.unemploymentAntecipation'
													name={`contractIdentificationList[${index}].unemploymentAnticipation`}
													handleChange={handleChange}
													value={contract.unemploymentAnticipation}
													nullOption={true}
													preffixDescriptionId='all'
													options={booleanOptions}
													isInvalid={
														errors.contractIdentificationList?.[index]
															?.unemploymentAnticipation
													}
													errorMessage={
														errors.contractIdentificationList?.[index]
															?.unemploymentAnticipation
													}
												/>
											</Col>
										</Row>


										{contract.contractModality === MCESupportKind.POST_PROJECT_APPROVAL && (
											<>

												<Row className='mt-5 mb-0'>
													<Col md='12'>
														<small className='text-secondary text-uppercase'>
															<FormattedMessage id='listContracts.step3.accordion.empreendeMeasure' />
														</small>
													</Col>
												</Row>
												<Row>
													<Col md='4'>
														<DecimalInputField
															labelId='listContracts.step3.accordion.feesFreeLoan'
															name={`contractIdentificationList[${index}].interestFreeLoan`}
															value={contract.interestFreeLoan}
															handleChange={handleChange}
															isInvalid={
																errors.contractIdentificationList?.[index]
																	?.interestFreeLoan
															}
															errorMessage={
																errors.contractIdentificationList?.[index]
																	?.interestFreeLoan
															}
															suffixLabel=' €'
														/>
													</Col>
													<Col md='4'>
														<DecimalInputField
															labelId='listContracts.step3.accordion.nonRefundableSubsidy'
															name={`contractIdentificationList[${index}].nonRefundableSubsidy`}
															value={contract.nonRefundableSubsidy}
															handleChange={handleChange}
															isInvalid={
																errors.contractIdentificationList?.[index]
																	?.nonRefundableSubsidy
															}
															errorMessage={
																errors.contractIdentificationList?.[index]
																	?.nonRefundableSubsidy
															}
															suffixLabel=' €'
														/>
													</Col>
												</Row>
												<Row className='mb-3'>
													<Col md='4'>
														<NumberInputField
															labelId='listContracts.step3.accordion.workPosts'
															placeholder={intl.formatMessage({
																id: 'listContracts.step3.accordion.workPosts.placeholder',
															})}
															name={`contractIdentificationList[${index}].workStations`}
															value={contract.workStations}
															handleChange={handleChange}
															isInvalid={
																errors.contractIdentificationList?.[index]
																	?.workStations
															}
															errorMessage={
																errors.contractIdentificationList?.[index]
																	?.workStations
															}
														/>
													</Col>
													<Col md='7'>
														<EnumSelectField
															labelId='listContracts.step3.accordion.artsAndCraftsProject'
															className='w-50'
															name={`contractIdentificationList[${index}].artsAndCrafts`}
															handleChange={handleChange}
															value={contract.artsAndCrafts}
															nullOption={true}
															preffixDescriptionId='all'
															options={booleanOptions}
															isInvalid={
																errors.contractIdentificationList?.[index]
																	?.artsAndCrafts
															}
															errorMessage={
																errors.contractIdentificationList?.[index]
																	?.artsAndCrafts
															}
														/>
													</Col>
												</Row>


											</>
										)}





										<Row>
											<Col>
												<button
													className='mt-5 px-0 btn btn-link'
													type='button'
													onClick={() => removeContractHandler(index)}
												>
													<FormattedMessage id='all.remove' />
												</button>
											</Col>
										</Row>
									</Accordion.Body>
								</Accordion.Item>
							</Accordion>
						</Col>
					</Row>
				))}
				<Row className='py-4'>
					<Col md='4'>
						<Button variant='outline-primary' onClick={addNewContractHandler}>
							<FormattedMessage id='listContracts.step2.button.addContract' />
						</Button>
					</Col>
				</Row>
			</fieldset>
			<div className='form-actions mt-5'>
				<button
					className='btn btn-link'
					type='button'
					onClick={handlePreviousStep}
				>
					<FormattedMessage id='all.back' />
				</button>
			</div>
		</div>
	);
}
