import {Formik} from 'formik';
import React, {useEffect, useState} from 'react';
import {Alert, Button, Col, Form, Row, Table} from 'react-bootstrap';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import {FormattedMessage} from 'react-intl';
import {Link} from 'react-router-dom';
import * as yup from 'yup';
import {SyncMultipleUploadArea} from "../SyncMultipleUploadArea";
import {DocumentType} from "../../models/DocumentType";
import {TextAreaInputField} from "../bootstrap/TextAreaInputField";
import {EnumSelectField} from "../bootstrap/EnumSelectField";
import {getDistricts} from "../../rest/administrativeDivision";
import {Arrays} from "../../utils/Arrays";
import {FaInfoCircle} from "react-icons/fa";

export function EntityChangeGeoForm({
                                        entityChange,
                                        onSubmit,
                                        documents,
                                        setDocuments,
                                        drs,
                                        canCreateGeoChange,
                                        setError
                                    }) {


    const [districts, setDistricts] = useState([]);

    async function fetchData() {

        try {
            const [{data: districtsIn}] = await Promise.all([
                await getDistricts(true),
            ]);

            setDistricts(Arrays.groupBy(
                districtsIn,
                (district) => district.regionalDelegationCode
            ));
        } catch (error) {
            setError(error);
        }

        //setShowSubmitModal(false);
    };


    useEffect(() => {
        fetchData();
    }, []);


    const schema = yup.object().shape({
        reason: yup
            .string()
            .required(
                <FormattedMessage id='errors.fieldRequiredText'/>
            ),

        regionalDelegationCode: yup
            .string()
            .test(
                'value-is-empty',
                <FormattedMessage id='errors.fieldRequiredText'/>,
                (value) => value !== '-1' && typeof value !== 'undefined'
            )
            .required(
                <FormattedMessage id='errors.fieldRequiredText'/>
            ),

        administrativeDivisionChanges: yup.array()
            .min(1, <FormattedMessage id='entityChange.errors.atLeastOneDistrict'/>),

    });

    function handleCheckboxChange(e, district, values, setFieldValue) {
        if (e.target.checked) {
            values.administrativeDivisionChanges.push(district);
        } else {
            values.administrativeDivisionChanges = values.administrativeDivisionChanges.filter(aC => aC.code !== district.code);
        }
        setFieldValue({...values});
    }


    function handleDrChange(e, values, setFieldValue) {
        values.regionalDelegationCode = e.target.value;

        if (values.regionalDelegationCode === '-1') {
            values.administrativeDivisionChanges = [];
        } else {
            values.administrativeDivisionChanges = drs.filter(d => d.regionalDelegationCode === e.target.value)[0].districtCodes.map((dC) => {
                return {code: dC}
            });
        }
        setFieldValue('administrativeDivisionChanges', [...values.administrativeDivisionChanges]);
    }

    return (
        <>
            {!canCreateGeoChange &&
                <Row className="mt-4">
                    <Col>
                        <Alert className='d-flex align-items-center justified' variant='danger'>
                            <div className='px-3'><FaInfoCircle/></div>
                            <div className='px-3 '>
                                <p className='mb-0'><FormattedMessage
                                    id="entityChange.GEO.invalidChange.alert.body.entity"/></p>
                            </div>
                        </Alert>
                    </Col>
                </Row>}
            <Formik
                initialValues={entityChange}
                validationSchema={schema}
                validateOnBlur={false}
                validateOnChange={false}
                onSubmit={(values, {setSubmitting, setErrors}) => {
                    onSubmit(values, setSubmitting, setErrors);
                }}
            >
                {({values, errors, touched, handleChange, handleSubmit, setFieldValue}) => (
                    <form onSubmit={handleSubmit}>
                        <Row>
                            <Col>
                                <TextAreaInputField
                                    controlId={'reason'}
                                    labelId={'entityChange.reason'}
                                    name={'reason'}
                                    handleChange={handleChange}
                                    value={values?.reason}
                                    isInvalid={errors.reason}
                                    errorMessage={errors.reason}
                                    maxLength={1000}
                                />
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <SyncMultipleUploadArea
                                    name='files'
                                    documents={documents}
                                    setDocuments={setDocuments}
                                    documentType={DocumentType.REASON_ENTITY_CHANGE}
                                />
                            </Col>
                        </Row>

                        <Row>
                            <Col md='6'>
                                <EnumSelectField
                                    controlId={'regionalDelegationCode'}
                                    nullOption={true}
                                    preffixDescriptionId='regionalDelegation'
                                    labelId='economicViabilityFormStep1.regionalDelegation'
                                    name='regionalDelegationCode'
                                    options={drs?.map(dr => dr.regionalDelegationCode)}
                                    value={values?.regionalDelegationCode}
                                    handleChange={(e) => handleDrChange(e, values, setFieldValue)}
                                    isInvalid={errors.regionalDelegationCode}
                                    errorMessage={errors.regionalDelegationCode}
                                />
                            </Col>

                        </Row>
                        {values.regionalDelegationCode !== '-1' &&
                            <>
                                <Row className="mt-4">
                                    <Col>
                                        <Alert className='d-flex align-items-center justified' variant='danger'>
                                            <div className='px-3'><FaInfoCircle/></div>
                                            <div className='px-3 '>
                                                <strong><FormattedMessage
                                                    id='entityChange.removeDistricts.alert.title'/></strong>
                                                <p className='mb-0'><FormattedMessage
                                                    id="entityChange.removeDistricts.alert.body.entity"/></p>
                                            </div>
                                        </Alert>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Form.Label>
                                            <FormattedMessage id='eaAccreditationForm.step3.entityDistricts'/>
                                        </Form.Label>
                                    </Col>
                                </Row>

                                <Row className='mt-3'>
                                    <Col>
                                        <Table striped>
                                            <thead>
                                            <tr>
                                                <th/>
                                                <th>
                                                    <FormattedMessage
                                                        id='eaAccreditationForm.step3.continentDistricts'/>
                                                </th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {districts.get(values.regionalDelegationCode).map((district) => (
                                                <tr key={district.description} className='align-items-center'>
                                                    <td>
                                                        <Form.Check
                                                            checked={values.administrativeDivisionChanges?.map(a => a.code).includes(district.code)}
                                                            onChange={(e) =>
                                                                handleCheckboxChange(e, district, values, setFieldValue)
                                                            }
                                                            isInvalid={errors.administrativeDivisionChanges}
                                                        />
                                                    </td>
                                                    <td>{district.description}</td>
                                                </tr>
                                            ))}
                                            </tbody>
                                            <tfoot>
                                            {errors.administrativeDivisionChanges && <tr>
                                                <td colSpan={3} className=" text-danger">
                                                    {errors.administrativeDivisionChanges}
                                                </td>
                                            </tr>
                                            }
                                            <tr>
                                                <td colSpan={3}>
                                                    {values.administrativeDivisionChanges.length}{' '}
                                                    <FormattedMessage id='eaAccreditationForm.step3.total'/>
                                                </td>
                                            </tr>
                                            </tfoot>
                                        </Table>
                                    </Col>
                                </Row>
                            </>
                        }


                        <div className='form-actions'>
                            <div className='mr-auto'>
                                <Link to={'/templates'} variant='secondary'>
                                    <FormattedMessage id='all.backButtonText'/>
                                </Link>
                            </div>
                            <div className='ml-auto'>
                                <Button variant='primary' type='submit'>
                                    <FormattedMessage id='all.submitButtonText'/>
                                </Button>
                            </div>
                        </div>
                    </form>
                )}
            </Formik>
        </>
    );
}
