import React from 'react';
import { Row, Col, Table, Form } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { maxFixed } from '../../utils/CurrencyUtils';
import {
	totalCostsDisclosureActivities,
	totalCostsGeneral,
	totalCostsIncubationSupport,
	totalEABudget,
} from '../../utils/AnnualComplementaryActivitiesPlanFormUtils';
import { TextAreaInputField } from '../bootstrap/TextAreaInputField';
import { TextInputField } from '../bootstrap/TextInputField';
import DecimalInputField from '../bootstrap/DecimalInputField';

export function ApprovedExpenses({
	formStep,
	numberOfSteps,
	errors,
	handleChange,
	formikValues,
	handlePreviousStep,
	handleNextStep,
	months,
	showNextStepButton,
	entityGetList,
	readMode,
}) {
	const intl = useIntl();

	const bootcampIndex = 0;
	const didacticIndex = 1;
	const disclosureIndex = 2;
	const incubationIndex = 3;
	const paidInAdvance = formikValues.paymentTechnicalAnalysis.paidAdvanceAmount || 0;
	const totalExecuted = totalEABudget(formikValues, formikValues.activityIdentification.ias || 0, months);

	function totalApprovedAmmount() {
		let total = 0;
		formikValues.expensesStatusList.forEach(
			(status) => (total += (parseFloat(status.approvedAmount) || 0))
		);
		return total;
	}

	function totalToPayReturn() {
		return maxFixed(maxFixed(Math.min(maxFixed(totalApprovedAmmount()), maxFixed(totalExecuted))) - paidInAdvance);
	}

	return (
		<>
			<h4 className='text-primary mb-0'>
				<FormattedMessage id='complementaryActivitiesPaymentRequest.approvedExpenses.title' />
			</h4>

			{/*Steps*/}
			<small className='text-secondary text-uppercase'>
				{intl.formatMessage(
					{ id: 'all.step' },
					{ step: formStep, numberOfSteps: numberOfSteps }
				)}
			</small>

			{/*expenses*/}
			<fieldset disabled={readMode==true}>
				<Row className={'mt-4'}>
					<Col md='12'>
						<Table striped hover responsive='md'>
							<thead>
								<tr>
									<th>
										<FormattedMessage id='complementaryActivitiesPaymentRequest.approvedExpenses.complementaryActivities' />
									</th>
									<th className='w-25'>
										<FormattedMessage id='complementaryActivitiesPaymentRequest.approvedExpenses.approvedAmount' />
									</th>
									<th className='w-20'>
										<FormattedMessage id='complementaryActivitiesPaymentRequest.approvedExpenses.actualAmount' />
									</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>Bootcamp</td>
									<td>
										<DecimalInputField
											disabled
											name={`expensesStatusList[${bootcampIndex}].approvedAmount`}
											value={
												formikValues.expensesStatusList[bootcampIndex]
													.approvedAmount
											}
											handleChange={handleChange}
											isInvalid={
												errors.expensesStatusList?.[bootcampIndex]
													?.approvedAmount
											}
											errorMessage={
												errors.expensesStatusList?.[bootcampIndex]
													?.approvedAmount
											}
											suffixLabel=' €'
										/>
									</td>
									<td>
										{maxFixed(
											totalCostsGeneral(formikValues, bootcampIndex, 'value')
										) + '€'}
									</td>
								</tr>

								<tr>
									<td>
										<FormattedMessage id='complementaryActivitiesPaymentRequest.approvedExpenses.disclosureActivities' />
									</td>
									<td>
										<DecimalInputField
											disabled
											name={`expensesStatusList[${disclosureIndex}].approvedAmount`}
											value={
												formikValues.expensesStatusList[disclosureIndex]
													.approvedAmount
											}
											handleChange={handleChange}
											isInvalid={
												errors.expensesStatusList?.[disclosureIndex]
													?.approvedAmount
											}
											errorMessage={
												errors.expensesStatusList?.[disclosureIndex]
													?.approvedAmount
											}
											suffixLabel=' €'
										/>
									</td>
									<td>
										{maxFixed(totalCostsDisclosureActivities(formikValues)) +
											'€'}
									</td>
								</tr>

								<tr>
									<td>
										<FormattedMessage id='complementaryActivitiesPaymentRequest.approvedExpenses.didacticMaterials' />
									</td>
									<td>
										<DecimalInputField
											disabled
											name={`expensesStatusList[${didacticIndex}].approvedAmount`}
											value={
												formikValues.expensesStatusList[didacticIndex]
													.approvedAmount
											}
											handleChange={handleChange}
											isInvalid={
												errors.expensesStatusList?.[didacticIndex]
													?.approvedAmount
											}
											errorMessage={
												errors.expensesStatusList?.[didacticIndex]
													?.approvedAmount
											}
											suffixLabel=' €'
										/>
									</td>
									<td>
										{maxFixed(
											totalCostsGeneral(formikValues, didacticIndex, 'value')
										) + '€'}
									</td>
								</tr>

								<tr>
									<td>
										<FormattedMessage id='complementaryActivitiesPaymentRequest.approvedExpenses.incubationCosts' />
									</td>
									<td>
										<DecimalInputField
											disabled
											name={`expensesStatusList[${incubationIndex}].approvedAmount`}
											value={
												formikValues.expensesStatusList[incubationIndex]
													.approvedAmount
											}
											handleChange={handleChange}
											isInvalid={
												errors.expensesStatusList?.[incubationIndex]
													?.approvedAmount
											}
											errorMessage={
												errors.expensesStatusList?.[incubationIndex]
													?.approvedAmount
											}
											suffixLabel=' €'
										/>
									</td>
									<td>
										{maxFixed(
											totalCostsIncubationSupport(
												formikValues,
												formikValues.activityIdentification.ias || 0,
												months
											)
										) + '€'}
									</td>
								</tr>

								<tr>
									<td>
										<FormattedMessage id='complementaryActivitiesPaymentRequest.approvedExpenses.totalBudget' />
									</td>
									<td>{totalApprovedAmmount() + '€'}</td>
									<td>
										{maxFixed(totalExecuted) + '€'}
									</td>
								</tr>

								<tr>
									<td>
										<FormattedMessage id='complementaryActivitiesPaymentRequest.approvedExpenses.toolTip' />
									</td>
									<td colSpan={2}>
										{maxFixed(
											formikValues.activityIdentification.ias * 60
										) + '€'}
									</td>
								</tr>
							</tbody>
						</Table>
					</Col>
				</Row>
				<Row>
					<Col md='6'>
						<Form.Label>
							<FormattedMessage id='complementaryActivitiesPaymentRequest.approvedExpenses.antecipatedValue' />
						</Form.Label>
						{maxFixed(paidInAdvance) + '€'}
					</Col>
					<Col md='6'>
						<Form.Label>
							<FormattedMessage id='complementaryActivitiesPaymentRequest.approvedExpenses.valuetoPay' />
						</Form.Label>
						{maxFixed(totalToPayReturn()) + '€'}
					</Col>
				</Row>
				<Row>
					<Col md='12'>
						<TextAreaInputField
							labelId={'annualComplementaryActivitiesPlanForm.EAObservations'}
							name={'observationsEa'}
							handleChange={handleChange}
							value={formikValues.observationsEa}
							isInvalid={errors.observationsEa}
							errorMessage={errors.observationsEa}
							maxLength={1000}
							placeholder={intl.formatMessage(
								{
									id: 'annualComplementaryActivitiesPlanForm.characters.placeholder',
								},
								{ nChars: '1000' }
							)}
						/>
					</Col>
				</Row>
				{formikValues.identificationEa.technicalTeamManagerExternalId !==
					'-1' && (
						<Row>
							<Col>
								<TextInputField
									labelId={
										'complementaryActivitiesPaymentRequest.approvedExpenses.technicalTeamManager'
									}
									value={
										entityGetList.filter(
											(get) =>
												get.externalId ===
												formikValues.identificationEa
													.technicalTeamManagerExternalId
										)?.[0]?.name
									}
									disabled
								/>
							</Col>
						</Row>
					)}
			</fieldset>

			{/* Voltar */}
			<div className='form-actions mt-5'>
				<button
					className='btn btn-link'
					type='button'
					onClick={handlePreviousStep}
				>
					<FormattedMessage id='all.back' />
				</button>
				{showNextStepButton && (
					<button
						className='btn btn-primary'
						type='button'
						onClick={handleNextStep}
					>
						<FormattedMessage id='all.nextStep' />
					</button>
				)}
			</div>
		</>
	);
}
