import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { createCustomErrorMessage } from '../../hooks/errorMessage';
import { Strings } from '../../utils/Strings';
import { AlertError } from './AlertError';
import { TextAreaInputField } from './TextAreaInputField';

export function DecisionDialog({ show, handleClose, handleConfirm, titleId, bodyId, needReason,reasonLabelId,bodyValue, dialogClassName }) {
  const intl = useIntl();
  const [reason, setReason] = useState(null);
  const [error, setError] = useState(null);
  function handleReason(event) {
    setReason(event.target.value);
  }

  function validateAndConfirm() {
    if (Strings.isBlank(reason)) {
      setError(
        createCustomErrorMessage(
          intl.formatMessage({ id: 'errors.rejectPriorSupportModal.motiveRequired' })
        )
      );
      return;
    } else {
      handleConfirm(reason);
    }
  }

  function close() {
    setError(null);
    handleClose();
  }

  if (!show) {
    return <></>;
  } else {
    return (
      <Modal show={show} onHide={close} dialogClassName={dialogClassName}>
        <Modal.Header closeButton>
          <Modal.Title>
            <FormattedMessage id={titleId} />
          </Modal.Title>
        </Modal.Header>
        <AlertError error={error} />
        <Modal.Body>
          {bodyValue ? <FormattedMessage id={bodyId}  values={{value:bodyValue}}/> : <FormattedMessage id={bodyId} />}

          {needReason && (
            <TextAreaInputField
              controlId={'reason'}
              label={reasonLabelId? <FormattedMessage id={reasonLabelId}/> :'Motivo'}
              name={'reason'}
              value={reason ?? ''}
              handleChange={handleReason}
            />
          )}
        </Modal.Body>
        <Modal.Footer className='justify-content-between'>
          <button className={'btn btn-outline-primary px-5'} onClick={close}>
            <FormattedMessage id='all.no' />
          </button>
          {needReason ? (
            <button className={'btn btn-outline-primary px-5'} onClick={() => validateAndConfirm()}>
              <FormattedMessage id='all.yes' />
            </button>
          ) : (
            <button className={'btn btn-outline-primary px-5'} onClick={handleConfirm}>
              <FormattedMessage id='all.yes' />
            </button>
          )}
        </Modal.Footer>
      </Modal>
    );
  }
}
