import React from 'react';
import Footer from '../general/Footer';
import NavBar from '../general/NavBar';
import { useGlobalContext } from '../../context';
import Loading from '../general/Loading';

export function Template({ children }) {
  const { globalLoading } = useGlobalContext();
  if (globalLoading) {
    return <Loading complex />;
  }

  return (
    <div id="wrapper">

      <NavBar />

      <div id="content">
        {children}
      </div>

      <Footer />

    </div>
  );
}
