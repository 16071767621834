import React from 'react';
import { Accordion, Col, Row } from 'react-bootstrap';
import { FaInfoCircle } from 'react-icons/fa';
import { FormattedMessage } from 'react-intl';
import { EnumSelectField } from '../components/bootstrap/EnumSelectField';
import { NumberInputField } from '../components/bootstrap/NumberInputField';
import { TextInputField } from '../components/bootstrap/TextInputField';
import { MceActivityType } from '../models/MceActivityType';
import { MceYearType } from '../models/MceYearType';

const firstYearTimelineIndex = 0;
const secondYearTimelineIndex = 1;
const thirdYearTimelineIndex = 2;

export function getArrayIndexOfMceYearType(mceYearType) {
	switch (mceYearType) {
		case MceYearType.FIRST:
			return firstYearTimelineIndex;
		case MceYearType.SECOND:
			return secondYearTimelineIndex;
		case MceYearType.THIRD:
			return thirdYearTimelineIndex;
	}
}

function addTimelineEntry(mceYearTypeIndex, formikValues, setFormikValues) {
	formikValues.timelines[mceYearTypeIndex].entries.push({
		mceActivityType: '-1',
		actions: '',
		firstSemesterPrediction: '',
		secondSemesterPrediction: '',
	});
	setFormikValues({ ...formikValues });
}

function removeTimelineEntry(
	mceYearTypeIndex,
	entryIndex,
	formikValues,
	setFormikValues
) {
	formikValues.timelines[mceYearTypeIndex].entries.splice(entryIndex, 1);
	setFormikValues({ ...formikValues });
}

export function generateTimeline(
	mceYearTypeIndex,
	formikValues,
	setFormikValues,
	handleChange,
	errors,
	intl
) {
	return (
		<>
			{formikValues.timelines[mceYearTypeIndex].entries.length > 0 ? (
				formikValues.timelines[mceYearTypeIndex].entries.map((entry, index) => (
					<Row key={index}>
						<Col md='12'>
							<Accordion defaultActiveKey='0'>
								<Accordion.Item eventKey='0' className='mt-4'>
									<Accordion.Header>
										Ação a realizar {index + 1}
									</Accordion.Header>
									<Accordion.Body>
										<Row>
											<Col>
												<EnumSelectField
													labelId='developmentPlan.timeline.mceActivities'
													name={`timelines[${mceYearTypeIndex}].entries[${index}].mceActivityType`}
													preffixDescriptionId={'mceActivityType'}
													handleChange={handleChange}
													options={MceActivityType.values()}
													value={entry.mceActivityType}
													nullOption={true}
													isInvalid={
														errors.timelines?.[mceYearTypeIndex]?.entries?.[
															index
														]?.mceActivityType
													}
													errorMessage={
														errors.timelines?.[mceYearTypeIndex]?.entries?.[
															index
														]?.mceActivityType
													}
												/>
											</Col>
											<Col>
												<NumberInputField
													labelId='developmentPlan.timeline.semester.first'
													handleChange={handleChange}
													name={`timelines[${mceYearTypeIndex}].entries[${index}].firstSemesterPrediction`}
													value={entry.firstSemesterPrediction}
													isInvalid={
														errors.timelines?.[mceYearTypeIndex]?.entries?.[
															index
														]?.firstSemesterPrediction
													}
													errorMessage={
														errors.timelines?.[mceYearTypeIndex]?.entries?.[
															index
														]?.firstSemesterPrediction
													}
													placeholder={intl.formatMessage({
														id: 'developmentPlan.timeline.placeholder',
													})}
												/>
											</Col>

											<Col>
												<NumberInputField
													labelId='developmentPlan.timeline.semester.second'
													handleChange={handleChange}
													name={`timelines[${mceYearTypeIndex}].entries[${index}].secondSemesterPrediction`}
													value={entry.secondSemesterPrediction}
													isInvalid={
														errors.timelines?.[mceYearTypeIndex]?.entries?.[
															index
														]?.secondSemesterPrediction
													}
													errorMessage={
														errors.timelines?.[mceYearTypeIndex]?.entries?.[
															index
														]?.secondSemesterPrediction
													}
													placeholder={intl.formatMessage({
														id: 'developmentPlan.timeline.placeholder',
													})}
												/>
											</Col>
										</Row>
										<Row>
											<Col>
												<TextInputField
													labelId='developmentPlan.timeline.actionsToExecute'
													handleChange={handleChange}
													name={`timelines[${mceYearTypeIndex}].entries[${index}].actions`}
													value={entry.actions}
													isInvalid={
														errors.timelines?.[mceYearTypeIndex]?.entries?.[
															index
														]?.actions
													}
													errorMessage={
														errors.timelines?.[mceYearTypeIndex]?.entries?.[
															index
														]?.actions
													}
												/>
											</Col>
										</Row>
										<Row>
											<Col>
												<button
													className='mt-5 px-0 btn btn-link'
													type='button'
													onClick={() =>
														removeTimelineEntry(
															mceYearTypeIndex,
															index,
															formikValues,
															setFormikValues
														)
													}
												>
													<FormattedMessage id='all.remove' />
												</button>
											</Col>
										</Row>
									</Accordion.Body>
								</Accordion.Item>
							</Accordion>
						</Col>
					</Row>
				))
			) : (
				<Row>
				<Col>
					<p className='mt-3 text-secondary infoText'>
						<FaInfoCircle />
						<FormattedMessage id='developmentPlan.timeline.noData' />
					</p>
				</Col>
			</Row>
			)}

			{(formikValues.timelines[mceYearTypeIndex].firstSemesterInitialDate !== null) &&
				<Row className='mt-4'>
					<Col>
						<button
							className='btn btn-outline-primary'
							type='button'
							onClick={() =>
								addTimelineEntry(mceYearTypeIndex, formikValues, setFormikValues)
							}
						>
							<FormattedMessage id='all.addButton' />
						</button>
					</Col>
				</Row>
			}


		</>
	);
}
