import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Row, Tab, Tabs } from 'react-bootstrap';
import { FaInfoCircle } from 'react-icons/fa';
import { FormattedMessage, useIntl } from 'react-intl';
import {Link, useNavigate, useParams} from 'react-router-dom';
import { AlertError } from '../../components/bootstrap/AlertError';
import { DecisionDialog } from '../../components/bootstrap/DecisionDialog';
import { PriorSupportMessageToPromoterModal } from '../../components/candidature/PriorSupportMessageToPromoterModal ';
import { AuthenticatedDownloadLink } from '../../components/document/AuthenticatedDownloadLink';
import Loading from '../../components/general/Loading';
import { SubTemplate } from '../../components/general/SubTemplate';
import { MessageList } from '../../components/messages/MessageList';
import { PaginationComponent } from '../../components/PaginationComponent';
import ContratoMCE from '../../documents/ANEXO B3 - CONTRATO DE PRESTACAO DE MCE A CRIACAO E ESTRUTURACAO DE PROJETOS.docx';
import { PriorSupportState } from '../../models/PriorSupportState';
import {
  acceptPriorSupportRequest,
  allowManageB6,
  getPriorSupportByExternalId,
  getPriorSupportDocuments,
  getPriorSupportMessages,
  rejectPriorSupportRequest,
  sendReviewPriorSupport
} from '../../rest/priorSupport';
import { handleError, isNotBusinessError } from '../../utils/handleError';
import {AlertSuccess} from "../../components/bootstrap/AlertSuccess";
import {isEntityUser, isIEFPUser, isPromoterUser} from "../../authentication/authenticationHelper";
import {RevocationState} from "../../models/RevocationState";
import {
  getRevocation, getRevocationDocuments,
  submitClarificationRevocation,
  submitCommunicationRevocation,
  submitDecisionRevocation
} from "../../rest/revocation";
import {TextAreaInputField} from "../../components/bootstrap/TextAreaInputField";
import {ToolTip} from "../../components/bootstrap/ToolTip";
import {DecisionRevocation} from "../../components/candidature/DecisionRevocation";
import {RevocationDirection} from "../../models/RevocationDirection";

const MAX_RESULTS = 15;
const DEFAULT_INITIAL_PAGE = 0;

export function DetailRevocation() {
  const { externalId } = useParams();
  const intl = useIntl();
  const navigate = useNavigate();

  const [revocation, setRevocation] = useState();
  const [documents, setDocuments] = useState();
  const [showClarification, setShowClarification] = useState(false);
  const [showFinalSubmission, setShowFinalSubmission] = useState(false);

  const [currentPage, setCurrentPage] = useState(DEFAULT_INITIAL_PAGE);

  const [loading, setLoading] = useState(true);
  const [showAccept, setShowAccept] = useState(false);
  const [showReject, setShowReject] = useState(false);
  const [error, setError] = useState(null);
  const [lsItem, setLsItem] = useState(null);


  function acceptRevocation() {
    setShowAccept(true);
  }

  function clarifyRevocation() {
    setShowClarification(true);
  }

  function finalDocumentRevocation() {
    setShowFinalSubmission(true);
  }


  const handleConfirmAccept = async (reason) => {
    try {
      let reqBody = {
        reason: reason,
        rejected: false
      };
      const [{ data: revocation }] = await Promise.all([submitDecisionRevocation(externalId,reqBody)]);
      setRevocation(revocation);
      setShowAccept(false);
      setLsItem('revocationAccept');
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  function rejectRevocation() {
    setShowReject(true);
  }


  const handleConfirmReject = async (reason) => {
    try {
      let reqBody = {
        reason: reason,
        rejected: true
      };

      const [{ data: revocation }] = await Promise.all([
        submitDecisionRevocation(externalId, reqBody)
      ]);
      setRevocation(revocation);
      setShowReject(false);
      setLsItem('revocationReject');
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };


  const handleConfirmClarification = async (reason) => {
    try {
      let reqBody = {
        reason: reason,
      };

      const [{ data: revocation }] = await Promise.all([
        submitClarificationRevocation(externalId, reqBody)
      ]);
      setRevocation(revocation);
      setShowClarification(false);
      setLsItem('revocationClarify');
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const handleConfirmFinalSubmission = async (initialDate) => {
    try {
      let reqBody = {
        date: initialDate,
      };

      const [{ data: revocation }] = await Promise.all([
        submitCommunicationRevocation(externalId, reqBody)
      ]);
      setRevocation(revocation);
      setShowFinalSubmission(false);

      setLsItem(RevocationDirection.FROM_ENTITY===revocation.direction ?  'revocationFinalDocumentEntity': 'revocationFinalDocumentPromoter');
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };





  const fetchData = async () => {
    try {
      const [{ data: revocation },{data:documentsIn}] =
        await Promise.all([
          getRevocation(externalId),
            getRevocationDocuments(externalId)
        ]);
      setRevocation(revocation);
      setDocuments(documentsIn.results);

      checkStorageItems();
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };


  function checkStorageItems() {


  }



  useEffect(() => {
    fetchData();
  }, [currentPage]);

  if (loading) {
    return <Loading />;
  }

  if (error && isNotBusinessError(error)) {
    return handleError(error);
  }

  const onPageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <SubTemplate titleId={'revocationForm.view'} hasBackButton>

      <Container>

        {lsItem && (
          <Row>
            <Col md={10}>
              <div className='mx-2 mb-4'>
                <AlertSuccess lsItem={lsItem} />
              </div>
            </Col>
          </Row>
      )}


        {!isIEFPUser() && revocation.state===RevocationState.ACCEPT && <Row>
          <Col md={10}>
            <p className='mt-3 text-secondary infoText'>
              <FaInfoCircle size={70}/>
              <FormattedMessage id='revocation.warning.info'/>
            </p>
          </Col>
        </Row>
        }

        <Row>
          <Col md='10' className='mb-4'>
            <AlertError error={error} />

            <Tabs defaultActiveKey='resume' justify>
              <Tab
                eventKey='resume'
                title={<FormattedMessage id='revocation.tabs.resume' />}
                className='mb-4 p-3 border'>
                <div>

                  <Row>
                    <Col md={6}>
                      <label>
                        <FormattedMessage id='revocation.from' />
                      </label>
                      <p>{revocation.from}</p>
                    </Col>

                    <Col md={6}>
                      <label>
                        <FormattedMessage id='revocation.to' />
                      </label>
                      <p>{revocation.to}</p>
                    </Col>


                  </Row>
                  <Row>
                    <Col md={4}>
                      <label>
                        <FormattedMessage id='revocationState.title' />
                      </label>
                      <p>{intl.formatMessage({
                        id: `revocationState.${revocation.state}`
                      })} ({revocation.changeDate})</p>
                    </Col>
                    <Col md={4}>
                      <label>
                        <FormattedMessage id='revocationForm.reason' />
                      </label>
                      <p>{intl.formatMessage({
                        id: `revocationReason.${revocation.reason}`
                      })}</p>
                    </Col>

                    <Col md={4}>
                      <label>
                        <FormattedMessage id='revocationForm.submissionDate' />
                      </label>
                      <p>{revocation.submissionDate}</p>
                    </Col>

                  </Row>
                  <Row>
                    <Col md={6}>
                      {revocation.priorSupportExternalId !== undefined ? (
                          <>
                            <label>
                              <FormattedMessage id='revocationForm.priorSupport' />
                            </label>
                            <Link to={`/pedidos/${revocation.priorSupportExternalId}`}>
                              {revocation.priorSupportExternalId}
                            </Link>
                          </>
                      ):(
                          <>
                            <label>
                              <FormattedMessage id='revocationForm.consultingRequest' />
                            </label>
                            <Link to={`/pedidosMCE/${revocation.consultingRequestExternalId}`}>
                              {revocation.consultingRequestExternalId}
                            </Link>
                          </>

                      )

                      }



                    </Col>
                    {revocation.initialDate &&
                    <Col md={6}>
                      <label>
                        <FormattedMessage id='revocationForm.initialDate' />
                      </label>
                      <p>{revocation.initialDate}</p>
                    </Col>

                    }
                  </Row>
                  <Row>
                    <Col md={12}>
                      <TextAreaInputField
                          labelId={'revocationForm.justification'}
                          name={'justification'}
                          value={revocation.justification}
                          disabled={true}
                      />
                    </Col>
                  </Row>
                  {revocation.validationJustification &&
                      <Row className={'border-1 border-top mt-4'}>
                        <Col md={12}>
                          <TextAreaInputField
                              labelId={'revocationForm.validationJustification'}
                              name={'validationJustification'}
                              value={revocation.validationJustification}
                              disabled={true}
                          />
                        </Col>
                      </Row>

                  }

                  {revocation.contestation &&
                  <Row className={'border-1 border-top mt-4'}>
                    <Col md={12}>
                      <TextAreaInputField
                          labelId={'revocationForm.contestation'}
                          name={'contestation'}
                          value={revocation.contestation}
                          disabled={true}
                      />
                    </Col>
                  </Row>

                  }



                  <DecisionRevocation
                  externalId={externalId}
                  setError={setError}
                  show={showAccept}
                  type={'accept'}
                  handleClose={()=> setShowAccept(false)}
                  submitHandler={handleConfirmAccept}
                  finalState={true}
                  titleLabelId={'revocation.accept.title'}
                  submitButtonLabelId={'revocation.accept.button'}
                  />



                  <DecisionRevocation
                      externalId={externalId}
                      setError={setError}
                      show={showReject}
                      type={'reject'}
                      handleClose={()=> setShowReject(false)}
                      submitHandler={handleConfirmReject}
                      finalState={RevocationState.CONTEST_ANALYSIS === revocation?.state}
                      titleLabelId={'revocation.reject.title'}
                      submitButtonLabelId={'revocation.reject.button'}
                  />

                  <DecisionRevocation
                      externalId={externalId}
                      setError={setError}
                      show={showClarification}
                      type={'clarification'}
                      handleClose={()=> setShowClarification(false)}
                      submitHandler={handleConfirmClarification}
                      finalState={false}
                      titleLabelId={'revocation.clarification.title'}
                      submitButtonLabelId={'revocation.clarification.button'}

                  />

                  <DecisionRevocation
                      externalId={externalId}
                      setError={setError}
                      show={showFinalSubmission}
                      type={'finalDocument'}
                      handleClose={()=> setShowFinalSubmission(false)}
                      submitHandler={handleConfirmFinalSubmission}
                      finalState={false}
                      titleLabelId={'revocation.finalDocument.title'}
                      submitButtonLabelId={'revocation.finalDocument.button'}
                  />


                  {/*
                  <Row className='mt-4'>
                    <Col md={5}>
                      <label>
                        <FormattedMessage id='priorSupport.externalId' />
                      </label>
                      <p>{priorSupport.externalId}</p>
                    </Col>
                    <Col md={4}>
                      <label>
                        <FormattedMessage id='priorSupport.state' />
                      </label>
                      {priorSupport.state === PriorSupportState.ASKED &&
                      documents?.filter(
                        (document) => document.documentType === 'REVIEW_PRIOR_SUPPORT'
                      ).length > 0 ? (
                        <FormattedMessage id={`priorSupportState.WAIT_DOC`} />
                      ) : (
                        <FormattedMessage id={`priorSupportState.${priorSupport.state}`} />
                      )}
                    </Col>
                    <Col md={3}>
                      <label>
                        <FormattedMessage id='priorSupport.candidatureState' />
                      </label>
                      {priorSupport.state === PriorSupportState.FINISH &&
                      <FormattedMessage id={`candidatureState.${priorSupport.candidatureState}`}/>}
                    </Col>
                  </Row>
                  <Row>
                    <Col md={5}>
                      <label>
                        <FormattedMessage id='priorSupport.owner.name' />
                      </label>
                      <p>{priorSupport.promoterName}</p>
                    </Col>
                    <Col md={4}>
                      <label>
                        <FormattedMessage id='priorSupport.owner.email' />
                      </label>
                      <p>{priorSupport.promoterEmail}</p>
                    </Col>
                    <Col md={3}>
                      <label>
                        <FormattedMessage id='priorSupport.owner.NIF' />
                      </label>
                      <p>{priorSupport.nifTitular}</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <label>
                        <FormattedMessage id='priorSupport.location' />
                      </label>
                      <p>{priorSupport.location}</p>
                    </Col>



                  </Row>
                  {priorSupport.multiPromoters!== undefined &&
                  <Row>
                    <Col md={6}>
                      <label>
                        <FormattedMessage id='priorSupportForm.multiPromoters' />
                      </label>
                      <p>{priorSupport.multiPromoters? <FormattedMessage id={'all.yes'}/> : <FormattedMessage id={'all.no'}/>  }</p>
                    </Col>
                  </Row>

                  }
                  {priorSupport.reasonToReject &&
                  <Row>
                    <Col md={12}>
                      <label>
                        <FormattedMessage id='priorSupport.reasonToReject' />
                      </label>
                      <p>{priorSupport.reasonToReject}</p>
                    </Col>
                  </Row>
                  }

                  {(priorSupport.state === PriorSupportState.ASKED ||
                    priorSupport.state === PriorSupportState.FINISH) && (
                    <>
                      <Row className='mt-3'>
                        <Col md={5}>
                          <label>
                            <FormattedMessage id='candidature.history.document.file' />
                          </label>
                        </Col>
                        <Col md={7}>
                          <label>
                            <FormattedMessage id='all.file' />
                          </label>
                        </Col>
                      </Row>

                      {documents?.map((d, index) => (
                        <Row key={index} className='border-top pt-2 pb-2'>
                          <Col md={5}>
                            {' '}
                            {intl.formatMessage({
                              id: 'documentType.' + d.documentType
                            })}
                          </Col>
                          <Col md={7}>
                            <AuthenticatedDownloadLink url={d.documentPath} filename={d.name}>
                              <>{(d.fileDescription ? d.fileDescription + ' : ' : '') + d.name}</>
                            </AuthenticatedDownloadLink>
                          </Col>
                        </Row>
                      ))}
                    </>
                  )}


                     <DecisionDialog
                  bodyId={'accept.modal.question'}
                  titleId={'accept.modal.title'}
                  handleConfirm={handleConfirmAccept}
                  show={showAccept}
                  handleClose={handleCloseAccept}
                />


                <DecisionDialog
                  bodyId={'reject.modal.question'}
                  titleId={'reject.modal.title'}
                  handleConfirm={handleConfirmReject}
                  show={showReject}
                  handleClose={handleCloseReject}
                  needReason={true}
                />

                  */}




                </div>


              </Tab>
              <Tab style={{minHeight: '100px'}}
                  eventKey='documents'
                  title={<FormattedMessage id='revocation.tabs.documents' />}
                  className='mb-4 p-3 border'>
                  {documents?.map((d, index) => (
                        <div key={index}>
                          <label className='mb-0'>
                            <FormattedMessage id={`documentType.${d.documentType}`} />
                          </label>
                          <small>{d.fileDescription===undefined ? '' : d.fileDescription+ ' - '}</small>
                          <AuthenticatedDownloadLink url={d.documentPath} filename={d.name}>
                            <small>{d.name}</small>
                          </AuthenticatedDownloadLink>
                        </div>
                    ))}


                <div>
                </div>
              </Tab>

            </Tabs>
          </Col>
          <Col md='2'>
            <div className='px-2 mb-5'>
              {(isIEFPUser() &&  (revocation?.state === RevocationState.SUBMITTED || revocation?.state === RevocationState.CONTEST_ANALYSIS) ) && (
                <>
                  <Button
                    className='btn btn-outline-primary d-flex align-items-center justify-content-center mb-4 w-100'
                    variant='outline-primary'
                    onClick={acceptRevocation}>
                    <FormattedMessage id='revocation.accept.button' />
                  </Button>


                  <Button
                    className='btn btn-outline-primary d-flex align-items-center justify-content-center mb-4 w-100'
                    variant='outline-primary mt-2'
                    onClick={rejectRevocation}>
                    <FormattedMessage id='revocation.reject.button' />
                  </Button>
                </>
              )}
              {(revocation?.state === RevocationState.WAIT_CONTESTATION && ( (isEntityUser() && revocation?.direction === RevocationDirection.FROM_ENTITY ) || (isPromoterUser() && revocation?.direction === RevocationDirection.TO_ENTITY)   ) ) &&
              <Button
                  className='btn btn-outline-primary d-flex align-items-center justify-content-center mb-4 w-100'
                  variant='outline-primary'
                  onClick={clarifyRevocation}>
                <FormattedMessage id='revocation.clarify.button' />
              </Button>

              }
              {(revocation?.state === RevocationState.ACCEPT && ( (isEntityUser() && revocation?.direction === RevocationDirection.FROM_ENTITY ) || (isPromoterUser() && revocation?.direction === RevocationDirection.TO_ENTITY)   ) ) &&
              <Button
                  className='btn btn-outline-primary d-flex align-items-center justify-content-center mb-4 w-100'
                  variant='outline-primary'
                  onClick={finalDocumentRevocation}>
                <FormattedMessage id='revocation.finalDocument.button' />
              </Button>

              }

            </div>
          </Col>
        </Row>
      </Container>
    </SubTemplate>
)
}
