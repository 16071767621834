import React, { useEffect, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import ReactStars from 'react-rating-stars-component';
import { FormattedMessage, useIntl } from 'react-intl';
import { TextAreaInputField } from '../bootstrap/TextAreaInputField';
import { FaInfoCircle } from 'react-icons/fa';
import ToolTip from '../bootstrap/ToolTip';
import {StartEvaluationItem} from "./startEvaluationItem";

export function DeclarationEndFirstYearFormStep3({
	formStep,
	numberOfSteps,
	errors,
	handleChange,
	formikValues,
	handlePreviousStep,
 	setFieldValue,
													 readMode,
}) {
	const intl = useIntl();

	const labelsEvaluation = [
		'',
		'declarationEndFirstYear.Step3.bullet.2',
		'declarationEndFirstYear.Step3.bullet.3',
	];

	const labelsEvaluationSubsection = [
		'',
		'declarationEndFirstYear.Step3.bullet.2.subsection',
		'declarationEndFirstYear.Step3.bullet.3.subsection',
	];

	return (
		<>
			<h4 className='text-primary mb-0'>
				<FormattedMessage id='declarationEndFirstYear.step3.title' />
			</h4>

			{/*Steps*/}
			<small className='text-secondary text-uppercase'>
				{intl.formatMessage(
					{ id: 'all.step' },
					{ step: formStep, numberOfSteps: numberOfSteps }
				)}
			</small>
			<fieldset disabled={readMode==true}>

			<Row>
				<Col>
					<p className='mt-3 text-secondary infoText'>
						<FaInfoCircle />
						<FormattedMessage id='declarationEndFirstYear.step3.info' />
					</p>
				</Col>
			</Row>

			{/*Starts*/}
			<StartEvaluationItem
				formikValues={formikValues}
				name={'ratingFollowUp'}
				setFieldValue={setFieldValue}
				label={'declarationEndFirstYear.Step3.bullet.1'}
				sublabel={'declarationEndFirstYear.Step3.bullet.1.subsection'}
				errorMessage={errors.ratingFollowUp}
				readMode={readMode}

			/>

			<StartEvaluationItem
				formikValues={formikValues}
				name={'ratingConsultancy'}
				setFieldValue={setFieldValue}
				label={'declarationEndFirstYear.Step3.bullet.2'}
				sublabel={'declarationEndFirstYear.Step3.bullet.2.subsection'}
				errorMessage={errors.ratingConsultancy}
				readMode={readMode}
			/>

			<StartEvaluationItem
				formikValues={formikValues}
				name={'ratingSkillExtension'}
				setFieldValue={setFieldValue}
				label={'declarationEndFirstYear.Step3.bullet.3'}
				sublabel={'declarationEndFirstYear.Step3.bullet.3.subsection'}
				errorMessage={errors.ratingSkillExtension}
				readMode={readMode}
			/>

			{/* Observations */}
			<Row>
				<Col md='12'>
					<Form.Label>
						<FormattedMessage id='declarationEndFirstYear.Step3.observations' />
					</Form.Label>
					<TextAreaInputField
						controlId={'companyObservations'}
						name={'companyObservations'}
						handleChange={handleChange}
						value={formikValues.companyObservations}
						errorMessage={errors.companyObservations}
						isInvalid={errors.companyObservations}
					/>
				</Col>
			</Row>

			{/* Declaracao */}
			<Row className={errors.interestedInContinuing? 'mt-5 square border border-danger border-2' : 'mt-5'}>
				<Col>
					<small className='text-uppercase text-primary'>
						<FormattedMessage id='declarationEndFirstYear.Step3.declaration.title' />
					</small>
					<Form.Check
						type={'checkbox'}
						label={intl.formatMessage({
							id: 'declarationEndFirstYear.Step3.declaration.text',
						})}
						defaultChecked={formikValues.interestedInContinuing === true}
						name={'interestedInContinuing'}
						onChange={({target}) =>
							setFieldValue('interestedInContinuing', target.checked)
						}
					/>
					<Form.Control.Feedback type='invalid'>{errors.interestedInContinuing}</Form.Control.Feedback>
				</Col>
			</Row>
			</fieldset>
			{/* Voltar */}
			<div className='form-actions mt-5'>
				<button
					className='btn btn-link'
					type='button'
					onClick={handlePreviousStep}
				>
					<FormattedMessage id='all.back' />
				</button>
			</div>
		</>
	);
}
