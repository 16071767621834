import {Modal} from "react-bootstrap";
import React, {useState} from "react";
import {FormattedMessage} from "react-intl";
import {AccreditationRevocationRestitutionForm} from "./AccreditationRevocationRestitutionForm";

export function AccreditationRevocationRestitutionModal({show, setShow, onSubmitCallback, accreditationRevocation}) {
    const [error, setError] = useState(null);

    async function onSubmit() {
        try {
            setShow(false);
            onSubmitCallback?.()
        } catch (error) {
            setError(error);
        }
    }

    function handleClose() {
        setShow(false);
    }

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title><FormattedMessage
                    id='candidatureTabs.oversight.tabs.noncompliance.restitution'/></Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <AccreditationRevocationRestitutionForm
                    accreditationRevocation={accreditationRevocation}
                    onSubmitCallback={onSubmit}
                    onCancel={handleClose}
                />
            </Modal.Body>
        </Modal>

    );
}