import React, {useEffect, useRef, useState} from 'react';
import {Button, Col, Form, Modal, Row} from 'react-bootstrap';
import {FormattedMessage, useIntl} from 'react-intl';
import {useNavigate, useParams} from 'react-router-dom';
import Loading from '../../../components/general/Loading';
import {DocumentType} from '../../../models/DocumentType';
import {handleError, isNotBusinessError} from '../../../utils/handleError';
import {Formik} from 'formik';
import * as yup from 'yup';
import {MultipleUploadArea} from '../../../components/MultipleUploadArea';
import {TextAreaInputField} from '../../../components/bootstrap/TextAreaInputField';
import {
	deleteAccreditationRevocationDocument,
	submitAccreditationRevocationontestation,
	uploadAccreditationRevocationDocument
} from "../../../rest/accreditationRevocation";

const validationSchema = yup.object().shape({
    motive: yup
        .string()
        .required(<FormattedMessage id='errors.fieldRequiredText'/>),
});

export function AccreditationRevocationRequestContestation({
                                                               accreditationRevocation,
                                                               setShowModal,
                                                           }) {
    const {
        nif: nif,
    } = useParams();

    //errors
    const [error, setError] = useState(null);

    //formik
    const [formikInitialValues, setFormikInitialValues] = useState(null);
    const formikPropsRef = useRef(null);

    //form control
    const [loading, setLoading] = useState(true);
    const [submited, setSubmited] = useState(false);

    const [documents, setDocuments] = useState([]);

    const navigate = useNavigate();
    const intl = useIntl();

    const docsUploadCallback = async (formData) => {
        return await uploadAccreditationRevocationDocument(
            nif,
            accreditationRevocation.externalId,
            formData
        );
    };

    const docsDeleteCallback = async (documentExternalId) => {
        return await deleteAccreditationRevocationDocument(
            nif,
            accreditationRevocation.externalId,
            documentExternalId
        );
    };

    async function fetchData() {
        try {
            let requestDocuments = accreditationRevocation.documents.filter(d => DocumentType.CLARIFICATION_ACCRED_REVOCATION === d.documentType);

            let formDocuments = [];

            requestDocuments.forEach((d) => {
                let doc = {
                    externalId: d.externalId,
                    documentType: d.documentType,
                    documentPath: d.documentPath,
                    name: d.name,
                    content: '',
                    submited: true,
                    error: '',
                    fileDescription: d.fileDescription,
                };

                formDocuments.push(doc);
            });
            setDocuments(formDocuments);

            setFormikInitialValues({motive: ''});
        } catch (error) {
            setError(error);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        fetchData();
    }, []);

    if (submited) {
        sessionStorage.setItem(
            'accreditationRevocationContestation',
            true
        );

        //refresh page
        navigate(0)
    }

    if (error && isNotBusinessError(error)) {
        return handleError(error);
    }

    async function handleSubmit() {
        try {
            await submitAccreditationRevocationontestation(
                nif,
                accreditationRevocation.externalId,
                formikPropsRef.current.values
            );
            setSubmited(true);
        } catch (err) {
            setError(err);
        }
    }

    async function validateFormAndSubmit() {
        let errors = await validateForm();
        if (!errors) {
            await handleSubmit(true);
        }
    }

    async function validateForm() {
        let errors = false;

        const formikErrors = await formikPropsRef.current.validateForm();

        if (Object.keys(formikErrors).length > 0) {
            errors = true;
        }

        return errors;
    }

    function renderForm(formikProps) {
        formikPropsRef.current = formikProps;
        let formikValues = formikProps.values;

        return (
            <>
                <Row>
                    <Col>
                        <TextAreaInputField
                            labelId={'candidatureChangeRequest.contestation.contestationMotive'}
                            name={'motive'}
                            handleChange={formikProps.handleChange}
                            value={formikValues.motive}
                            isInvalid={formikProps.errors.motive}
                            errorMessage={formikProps.errors.motive}
                            placeholder={intl.formatMessage(
                                {
                                    id: 'annualComplementaryActivitiesPlanForm.characters.placeholder',
                                },
                                {nChars: '1000'}
                            )}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <MultipleUploadArea
                            documents={documents}
                            setDocuments={setDocuments}
                            documentType='CLARIFICATION_ACCRED_REVOCATION'
                            uploadCallback={docsUploadCallback}
                            deleteCallback={docsDeleteCallback}
                        />
                    </Col>
                </Row>
            </>
        );
    }

    return (
        <Modal
            show={true}
            dialogClassName='modal-65'
            onHide={() => setShowModal(false)}
        >
            <Modal.Header closeButton>
                <FormattedMessage
                    id={`candidature.button.contest`}
                />
            </Modal.Header>
            <Modal.Body>
                {loading ? (
                    <span>
						<Loading isDialog/>
					</span>
                ) : (
                    <Row>
                        <Col>
                            <Formik
                                initialValues={formikInitialValues}
                                validateOnBlur={false}
                                validateOnChange={false}
                                validationSchema={validationSchema}
                                onSubmit={handleSubmit}
                            >
                                {(formikProps) => (
                                    <Form onSubmit={formikProps.handleSubmit}>
                                        {renderForm(formikProps)}
                                    </Form>
                                )}
                            </Formik>
                        </Col>
                    </Row>
                )}
            </Modal.Body>
            <Modal.Footer className='d-flex justify-content-between'>
                <Button variant='outline-secondary' onClick={() => setShowModal(false)}>
                    <FormattedMessage id='all.cancel'/>
                </Button>
                <Button
                    variant='primary'
                    onClick={validateFormAndSubmit}
                >
                    <FormattedMessage id='all.submitButtonText'/>
                </Button>
            </Modal.Footer>
        </Modal>
    );
}
