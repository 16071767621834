import React, {useEffect, useState} from 'react';
import {Container} from 'react-bootstrap';
import {useNavigate, useParams} from 'react-router-dom';
import {AlertSuccess} from "../../components/bootstrap/AlertSuccess";
import {SubTemplate} from "../../components/general/SubTemplate";
import {RevocationFilterBar} from "../../components/revocation/RevocationFilterBar";
import {listRevocations} from "../../rest/revocation";
import {isEntityUser, isIEFPUser, isPromoterUser} from "../../authentication/authenticationHelper";
import {RevocationDirection} from "../../models/RevocationDirection";
import {EmptyResults} from "../../components/general/EmptyResults";
import Row from "react-bootstrap/Row";
import {CustomPagination} from "../../components/general/CustomPagination";
import {handleError, isNotBusinessError} from "../../utils/handleError";
import Loading from "../../components/general/Loading";
import {Arrays} from "../../utils/Arrays";
import {AlertError} from "../../components/bootstrap/AlertError";
import {FormattedMessage, useIntl} from 'react-intl';
import Col from "react-bootstrap/Col";
import {LabelToolTip} from "../../components/bootstrap/LabelToolTip";
import {PriorSupportState} from "../../models/PriorSupportState";
import {RevocationState} from "../../models/RevocationState";
const MAX_RESULTS = 25;
const DEFAULT_INITIAL_PAGE = 0;

export function ListRevocation({isPriorSupport}) {
    const intl = useIntl();

    const {externalId} = useParams();
    const navigate = useNavigate();
    const [item, setItem] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [revocations,setRevocations]= useState([]);
    const [filters, setFilters] = useState(getInitialFilters());
    const [currentPage, setCurrentPage] = useState(DEFAULT_INITIAL_PAGE);
    const [canCreate,setCanCreate] = useState(false);


    function getInitialFilters(){
       let initialFilter = {
            direction: null,
                state: null,
        };
       if(isPriorSupport===true){
           initialFilter.priorSupportExternalId= externalId;
       }else{
           initialFilter.consultingRequestExternalId = externalId;
       }
       return initialFilter;
    }


    useEffect(() => {
        fetchData();
    },[currentPage])



    function handleSearch() {
        fetchData();
    }


    async function fetchData() {
        try {
            const { data: revocationsIn } = await listRevocations(
                currentPage,
                MAX_RESULTS,
                filters
            );
          setRevocations(revocationsIn);


            let all = {
                direction: null,
                state: null,
            };

            if(isPriorSupport===true){
                all.priorSupportExternalId= externalId;
            }else{
                all.consultingRequestExternalId = externalId;
            }

            const { data: otherRevocations } = await listRevocations(
                currentPage,
                MAX_RESULTS,
                all
            );



          setCanCreate (otherRevocations.results.length===0 || otherRevocations.results.filter(r => r.state !==  RevocationState.REFUSE).length ===0);



            checkStorageItems();

        } catch (error) {
            setError(error);
        } finally {
            setLoading(false);
        }
    }




    function checkStorageItems() {
        if (sessionStorage.getItem('saveRevocation')) {
            setItem('saveRevocation');
        }
    }


    function goToRevocation(revocation) {
        navigate('/revogacoes/' + revocation.externalId , {state: { refresh: true }});
    }

    const onPageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };



    if (loading) {
        return <Loading />;
    }

    if (error && isNotBusinessError(error)) {
        return handleError(error);
    }

    return (
        <SubTemplate titleId={'revocationForm.list'} hasBackButton>
            <Container>
                <div className='mx-2 mb-4'>
                    <AlertSuccess lsItem={item} />
                </div>
                <Row className='searchResults'>
					<Col md='10'>
						<AlertError error={error} />

						{Arrays.isNotEmpty(revocations.results) ? (
							<>
								<Row className='mt-5 mb-1 d-flex justify-content-between px-2'>
									<Col className='text-muted'>
										{revocations.totalResults}{' '}
										<FormattedMessage id={`all.results.lowCase.${revocations.totalResults === 1 ? 'singular' : 'plural'}`} />
									</Col>
								</Row>
                
								<ul className='results'>
									{revocations.results.map((revocation, index) => (
										<li key={index}>





											<Row className={(isIEFPUser() || (isEntityUser() && RevocationDirection.FROM_ENTITY=== revocation.direction) || (isPromoterUser() && RevocationDirection.TO_ENTITY=== revocation.direction)) ? 'py-2' : 'py-2 notPointer'     } onClick={() => (isIEFPUser() || (isEntityUser() && RevocationDirection.FROM_ENTITY=== revocation.direction) || (isPromoterUser() && RevocationDirection.TO_ENTITY=== revocation.direction)) ? goToRevocation(revocation) : ''}>
												<Col md='6' className='d-flex align-items-center'>
                                                    <FormattedMessage id='from.to' values={{from: revocation.from,to:revocation.to}} />
												</Col>
												<Col
													md='3'
													className='d-flex align-items-center text-secondary'
												>
													<LabelToolTip
														labelId='revocationState.title'
														value={intl.formatMessage({
															id: `revocationState.${revocation.state}`,
														})}
													/>
												</Col>
												<Col
													md='3'
													className='d-flex align-items-center justify-content-end text-secondary'
												>
													<LabelToolTip
														labelId='candidatureTabs.submissionDate'
														value={revocation.submissionDate}
													/>
												</Col>
											</Row>
										</li>
									))}
								</ul>
							</>
						) : (
							<EmptyResults />
						)}
					</Col>
                    <Col md={2}>
                        {
                            canCreate===true &&(
                                <button
                                    className='btn btn-outline-primary d-flex align-items-center justify-content-center mb-4 mt-4 w-100'
                                    type='button'
                                    onClick={() => {
                                        navigate((isPriorSupport===true ?'/apoioPrevio/' : "/pedidosMCE/")+externalId+'/adicionarRevogacao');
                                    }}>

                                    <FormattedMessage id='revocationForm.add' />
                                </button>

                            )
                        }
                    </Col>
				</Row>
				<Row className='mb-5'>
					<Col className='d-flex justify-content-center'>
						<CustomPagination
							dataPerPage={MAX_RESULTS}
							totalData={revocations.totalResults}
							onPageChange={onPageChange}
							currentPage={currentPage}
						/>
					</Col>
				</Row>
            </Container>
        </SubTemplate>
    );
}
