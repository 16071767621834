import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { handleError, isNotBusinessError } from '../../../utils/handleError';
import {
	yearStatus,
	listAdditionalActivitiesPlan,
} from '../../../rest/additionalActivitiesPlan';
import { ListFilterBar } from '../../../components/annualComplementaryActivitiesPlan/ListFilterBar';
import { AlertError } from '../../../components/bootstrap/AlertError';
import { Arrays } from '../../../utils/Arrays';
import { FormattedMessage, useIntl } from 'react-intl';
import { EnumSelectField } from '../../../components/bootstrap/EnumSelectField';
import { AlertSuccess } from '../../../components/bootstrap/AlertSuccess';
import { useNavigate } from 'react-router-dom';
import { LabelToolTip } from '../../../components/bootstrap/LabelToolTip';
import { EmptyResults } from '../../../components/general/EmptyResults';
import { SubTemplate } from '../../../components/general/SubTemplate';
import Loading from '../../../components/general/Loading';
import { CustomPagination } from '../../../components/general/CustomPagination';
import { Strings } from '../../../utils/Strings';
import { isIEFPUser } from '../../../authentication/authenticationHelper';
import { ProcessState } from '../../../models/ProcessState';


const MAX_RESULTS = 10;
const DEFAULT_INITIAL_PAGE = 0;

export function ListAnnualComplementaryActivitiesPlan({ entityNif }) {
	const isIEFP = isIEFPUser();

	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);
	const [numberOfResults, setNumberOfResults] = useState(null);
	const [totalResults, setTotalResults] = useState(null);
	const [currentPage, setCurrentPage] = useState(DEFAULT_INITIAL_PAGE);

	const [b17List, setB17List] = useState([]);

	const orderByOptionsIdPrefix =
		'annualComplementaryActivitiesPlan.list.orderOption';
	const orderByOptions = ['changeDate_desc', 'changeDate_asc'];

	const [currentOrderBy, setCurrentOrderBy] = useState(orderByOptions[0]);

	const [filters, setFilters] = useState({
		year: '',
		replacement: '-1',
		state: '-1',
	});

	const hoverpreffixId = 'annualComplementaryActivitiesPlan.list.hover';

	const [yearStatusB17, setYearStatusB17] = useState(null);

	const navigate = useNavigate();

	const intl = useIntl();

	const [lsItem, setLsItem] = useState(null);

	async function getB17Info() {
		const { data: status } = await yearStatus(entityNif);
		setYearStatusB17(status);
	}

	async function fetchData(fromFilter) {
		try {
			if(fromFilter === true){
				setCurrentPage(0);
			}

			let orderParams = currentOrderBy.split('_');

			let queryparams = {
				index: fromFilter ? 0 : currentPage,
				size: MAX_RESULTS,
				orderBy: orderParams[0],
				orderType: orderParams[1],
			};

			if (filters.state !== '-1') {
				queryparams.state = filters.state;
			}

			if (!Strings.isBlank(filters.year)) {
				queryparams.year = filters.year;
			}

			if (entityNif !== undefined && entityNif !== null) {
				queryparams.entityNif = entityNif;
			}

			if (filters.replacement !== '-1') {
				queryparams.replacement =
					filters.replacement === 'replacement' ? true : false;
			}

			const [{ data: formList }] = await Promise.all([
				await listAdditionalActivitiesPlan(queryparams),
			]);

			setB17List( isIEFP ? formList.results.filter(b17 => b17.processState !== ProcessState.DRAFT) : formList.results);
			setNumberOfResults(formList.numberOfResults);
			setTotalResults(formList.totalResults);
		} catch (error) {
			setError(error);
		} finally {
			setLoading(false);
		}
	}

	const onPageChange = (pageNumber) => {
		setCurrentPage(pageNumber);
	};

	function handleOrderByChange(e) {
		setCurrentOrderBy(e.target.value);
	}

	useEffect(() => {
		checkStorageItems();
		getB17Info();
	}, []);

	useEffect(() => {
		fetchData();
	}, [currentPage]);

	if (loading) {
		return <Loading />;
	}

	if (error && isNotBusinessError(error)) {
		return handleError(error);
	}

	function redirectToFormPage(isReplacement) {
		let state = {
			replacement: isReplacement,
		};

		if (yearStatusB17.inDraft) {
			state.getDraft = true;
		}

		navigate(`/atividadesComplementares/plano/`, {
			state: state,
		});
	}

	function redirectToFormPageWithExternalId(b17ExternalId) {
		if (isIEFP) {
			navigate(`/atividadesComplementares/entidade/${entityNif}/plano/${b17ExternalId}`);
		} else {
			navigate(`/atividadesComplementares/plano/${b17ExternalId}`);
		}
	}

	function checkStorageItems() {
		if (sessionStorage.getItem('annualComplementaryActivitiesPlanSubmited')) {
			setLsItem('annualComplementaryActivitiesPlanSubmited');
		}
		if (sessionStorage.getItem('additionalActivitiesPlanWithdrawed')) {
			setLsItem('additionalActivitiesPlanWithdrawed');
		}
		if (sessionStorage.getItem('annualComplementaryActivitiesPlanSubmitedReplacement')) {
			setLsItem('annualComplementaryActivitiesPlanSubmitedReplacement');
		}
	}

	return (
		<SubTemplate
			titleId={'annualComplementaryActivitiesPlan.list.title'}
			forceClass={isIEFP && 'd-none'}
			hasBackButton={!isIEFP}
		>
			<Container>
				<div className='mx-2 mb-4'>
					<AlertSuccess lsItem={lsItem} />
				</div>
				<Row>
					<Col md={isIEFP ? '12' : '10'}>
						<ListFilterBar
							filters={filters}
							setFilters={setFilters}
							search={fetchData}
							isB17={true}
						/>
					</Col>
				</Row>
				<Row className='searchResults'>
					<Col md={isIEFP ? '12' : '10'}>
						<AlertError error={error} />

						{Arrays.isNotEmpty(b17List) ? (
							<>
								<Row className='mt-5 mb-1 d-flex justify-content-between px-2'>
									<Col className='text-muted'>
										{totalResults}
										<FormattedMessage id={`annualComplementaryActivitiesPlan.list.header.requests.${totalResults === 1 ? 'singular' : 'plural'}`} />
									</Col>
									<Col md='4'>
										<EnumSelectField
											preffixDescriptionId={orderByOptionsIdPrefix}
											handleChange={handleOrderByChange}
											options={orderByOptions}
											value={currentOrderBy}
										/>
									</Col>
								</Row>

								<ul className='results'>
									{b17List.map((b17, index) => (
										<li key={b17.externalId}>
											<Row
												className='py-3'
												onClick={() =>
													redirectToFormPageWithExternalId(b17.externalId)
												}
											>
												<Col md='7' className='d-flex align-items-center'>
													<LabelToolTip
														labelId={`${hoverpreffixId}.plan`}
														value={
															intl.formatMessage({
																id: 'annualComplementaryActivitiesPlan.list.plan',
															}) +
															` ${b17.year}${
																b17.replacement
																	? ' - ' +
																	  intl.formatMessage({
																			id: 'versionType.replacement',
																	  })
																	: ''
															}`
														}
													/>
												</Col>
												<Col md='2' className='d-flex align-items-center'>
													<LabelToolTip
														labelId={`${hoverpreffixId}.state`}
														value={
															<FormattedMessage
																id={`processState.${b17.processState}`}
															/>
														}
													/>
												</Col>
												<Col
													md='3'
													className='d-flex align-items-center justify-content-end'
												>
													<LabelToolTip
														labelId={`${hoverpreffixId}.changeDate`}
														value={b17.changeDate}
													/>
												</Col>
											</Row>
										</li>
									))}
								</ul>
							</>
						) : (
							<EmptyResults />
						)}
					</Col>
					{yearStatusB17 !== null && (
						<Col md='2' className={isIEFP && 'd-none'}>
							<div className='mt-4 py-3'>
								{(yearStatusB17.countNotRejected === 0 ||
									(yearStatusB17.countNotRejected == 1 &&
										yearStatusB17.inDraft)) && (
									<button
										className='btn btn-outline-primary d-flex align-items-center justify-content-center mb-4 w-100'
										type='button'
										onClick={() => redirectToFormPage(false)}
									>
										<FormattedMessage
											id={`annualComplementaryActivitiesPlan.button.firstPlan${
												yearStatusB17.inDraft ? '.continue' : ''
											}`}
										/>
									</button>
								)}
								{((yearStatusB17.countNotRejected === 1 &&
									!yearStatusB17.inDraft &&
									!yearStatusB17.submitted) ||
									(yearStatusB17.countNotRejected == 2 &&
										yearStatusB17.inDraft)) && (
									<button
										className='btn btn-outline-primary d-flex align-items-center justify-content-center w-100'
										type='button'
										onClick={() => redirectToFormPage(true)}
									>
										<FormattedMessage
											id={`annualComplementaryActivitiesPlan.button.replacementPlan${
												yearStatusB17.inDraft ? '.continue' : ''
											}`}
										/>
									</button>
								)}
							</div>
						</Col>
					)}
				</Row>
				<Row className='mb-5'>
					<Col md={isIEFP ? '12' : '10'} className='d-flex'>
						<div className='mx-2'>
							<CustomPagination
								dataPerPage={MAX_RESULTS}
								totalData={totalResults}
								onPageChange={onPageChange}
								currentPage={currentPage}
							/>
						</div>
					</Col>
				</Row>
			</Container>
		</SubTemplate>
	);
}
