import React, { useState } from 'react';
import { NumberInputField } from '../../bootstrap/NumberInputField';
import { Row, Col, Accordion, Form } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { EnumSelectField } from '../../bootstrap/EnumSelectField';
import { FaExclamationCircle } from 'react-icons/fa';

export function EaAccreditationFormStep6({
	formStep,
	numberOfSteps,
	errors,
	formikValues,
	handleChange,
	handlePreviousStep,
	handleNextStep,
	setFieldValue,
	districts,
	readMode = false
}) {
	const intl = useIntl();

	const districtOptions = districts
		.filter(
			(d) =>
				!Object.keys(formikValues.equipmentDistribution).includes(d.description)
		)
		.map((d) => d.description);

	const [accordionKey, setAccordionKey] = useState(-1);
	const [showAccordion, setShowAccordion] = useState(false);
	const [addedDistricts, setAddedDistricts] = useState(
		Object.keys(formikValues.equipmentDistribution)
	);

	let selectedDistrict = '-1';

	function addDistrictHandler() {
		if (selectedDistrict === '-1') return;

		addedDistricts.push(selectedDistrict);
		setAddedDistricts([...addedDistricts]);
		let newDistrict = {
			attendanceRooms: {
				value: 0,
			},
			meetingRooms: {
				value: 0,
			},
			formationRooms: {
				value: 0,
			},
			incubators: {
				value: 0,
			},
			incubationSpaces: {
				value: 0,
			},
			phones: {
				value: 0,
			},
			computers: {
				value: 0,
			},
			internetPoints: {
				value: 0,
			},
			wifis: {
				value: 0,
			},
			scanners: {
				value: 0,
			},
			printers: {
				value: 0,
			},
			vehicles: {
				value: 0,
			},
		};
		formikValues.equipmentDistribution[selectedDistrict] = newDistrict;
		setFieldValue({ ...formikValues });
	}

	function removeDistrictHandler(districtName) {
		addedDistricts.splice(addedDistricts.indexOf(districtName), 1);
		setAddedDistricts([...addedDistricts]);

		delete formikValues.equipmentDistribution[districtName];
		setFieldValue({ ...formikValues });
		setAccordionKey('-1');
	}

	async function accordionOnClickHandler(accordionClickedIndex) {
		if (accordionClickedIndex === accordionKey) {
			setShowAccordion(!showAccordion);
		} else {
			setAccordionKey(accordionClickedIndex);
			setShowAccordion(true);
		}
	}

	return (
		<div>
			<h4 className='text-primary mb-0'>
				<FormattedMessage id='eaAccreditationForm.step6.title' />
			</h4>
			<small className='text-secondary text-uppercase'>
				{intl.formatMessage(
					{ id: 'all.step' },
					{ step: formStep, numberOfSteps: numberOfSteps }
				)}
			</small>
			{!readMode && (
			<Row className='align-items-end'>
				<Col md='4'>
					<EnumSelectField
						disabled={readMode || districtOptions.length === 0}
						nullOption={true}
						labelId='all.chooseOneDistrict'
						options={districtOptions.filter(
							(district) => !addedDistricts.includes(district)
						)}
						handleChange={(e) => {
							selectedDistrict = e.target.value;
						}}
					/>
				</Col>
				<Col md='4'>
					<button
						disabled={districtOptions.length === 0}
						className='btn btn-outline-primary'
						type='button'
						onClick={addDistrictHandler}
					>
						<FormattedMessage id='eaAccreditationForm.step6.button.addDistric' />
					</button>
				</Col>
			</Row>
			)}
			{Object.keys(formikValues.equipmentDistribution).length > 0 && (
				<Row className='mt-4'>
					<Col>
						<Form.Label>
							<FormattedMessage id='eaAccreditationForm.step6.addDistricEquipmentDistribuiton' />
						</Form.Label>
					</Col>
				</Row>
			)}
			
			{Object.keys(formikValues.equipmentDistribution).map(
				(districtName, index) => (
					<Accordion
						activeKey={showAccordion ? accordionKey : -1}
						key={index}
						onSelect={() => accordionOnClickHandler(index)}
					>
						<Accordion.Item eventKey={index}>
							<Accordion.Header>
								<span className='d-flex align-items-center'>
									{errors.equipmentDistribution?.[districtName] && (
										<FaExclamationCircle />
									)}
									{districtName}{' '}
								</span>
							</Accordion.Header>
							<Accordion.Body>
								<Row>
									<Col md='4'>
										<NumberInputField
											name={`equipmentDistribution[${districtName}].attendanceRooms.value`}
											handleChange={handleChange}
											labelId='eaAccreditationForm.step6.accordion.attendanceRooms'
											value={
												formikValues.equipmentDistribution[districtName]
													.attendanceRooms.value
											}
											isInvalid={
												errors.equipmentDistribution?.[districtName]
													?.attendanceRooms?.value
											}
											errorMessage={
												errors.equipmentDistribution?.[districtName]
													?.attendanceRooms?.value
											}
											disabled={readMode}
											maxLength={3}
										/>
									</Col>
									<Col md='4'>
										<NumberInputField
											name={`equipmentDistribution[${districtName}].meetingRooms.value`}
											handleChange={handleChange}
											labelId='eaAccreditationForm.step6.accordion.meetingRooms'
											value={
												formikValues.equipmentDistribution[districtName]
													.meetingRooms.value
											}
											isInvalid={
												errors.equipmentDistribution?.[districtName]
													?.meetingRooms?.value
											}
											errorMessage={
												errors.equipmentDistribution?.[districtName]
													?.meetingRooms?.value
											}
											disabled={readMode}
											maxLength={3}
										/>
									</Col>
									<Col md='4'>
										<NumberInputField
											name={`equipmentDistribution[${districtName}].formationRooms.value`}
											handleChange={handleChange}
											labelId='eaAccreditationForm.step6.accordion.formationRooms'
											value={
												formikValues.equipmentDistribution[districtName]
													.formationRooms.value
											}
											isInvalid={
												errors.equipmentDistribution?.[districtName]
													?.formationRooms?.value
											}
											errorMessage={
												errors.equipmentDistribution?.[districtName]
													?.formationRooms?.value
											}
											disabled={readMode}
											maxLength={3}
										/>
									</Col>
								</Row>
								<Row>
									<Col md='4'>
										<NumberInputField
											name={`equipmentDistribution[${districtName}].incubators.value`}
											handleChange={handleChange}
											labelId='eaAccreditationForm.step6.accordion.incubators'
											value={
												formikValues.equipmentDistribution[districtName]
													.incubators.value
											}
											isInvalid={
												errors.equipmentDistribution?.[districtName]?.incubators?.value
											}
											errorMessage={
												errors.equipmentDistribution?.[districtName]?.incubators?.value
											}
											disabled={readMode}
											maxLength={3}
										/>
									</Col>
									<Col md='4'>
										<NumberInputField
											name={`equipmentDistribution[${districtName}].incubationSpaces.value`}
											handleChange={handleChange}
											labelId='eaAccreditationForm.step6.accordion.incubationSpaces'
											value={
												formikValues.equipmentDistribution[districtName]
													.incubationSpaces.value
											}
											isInvalid={
												errors.equipmentDistribution?.[districtName]
													?.incubationSpaces?.value
											}
											errorMessage={
												errors.equipmentDistribution?.[districtName]
													?.incubationSpaces?.value
											}
											disabled={readMode}
											maxLength={3}
										/>
									</Col>
									<Col md='4'>
										<NumberInputField
											name={`equipmentDistribution[${districtName}].phones.value`}
											handleChange={handleChange}
											labelId='eaAccreditationForm.step6.accordion.phones'
											value={
												formikValues.equipmentDistribution[districtName].phones.value
											}
											isInvalid={
												errors.equipmentDistribution?.[districtName]?.phones?.value
											}
											errorMessage={
												errors.equipmentDistribution?.[districtName]?.phones?.value
											}
											disabled={readMode}
											maxLength={3}
										/>
									</Col>
								</Row>
								<Row>
									<Col md='4'>
										<NumberInputField
											name={`equipmentDistribution[${districtName}].computers.value`}
											handleChange={handleChange}
											labelId='eaAccreditationForm.step6.accordion.computers'
											value={
												formikValues.equipmentDistribution[districtName]
													.computers.value
											}
											isInvalid={
												errors.equipmentDistribution?.[districtName]?.computers?.value
											}
											errorMessage={
												errors.equipmentDistribution?.[districtName]?.computers?.value
											}
											disabled={readMode}
											maxLength={3}
										/>
									</Col>
									<Col md='4'>
										<NumberInputField
											name={`equipmentDistribution[${districtName}].internetPoints.value`}
											handleChange={handleChange}
											labelId='eaAccreditationForm.step6.accordion.internetPoints'
											value={
												formikValues.equipmentDistribution[districtName]
													.internetPoints.value
											}
											isInvalid={
												errors.equipmentDistribution?.[districtName]
													?.internetPoints?.value
											}
											errorMessage={
												errors.equipmentDistribution?.[districtName]
													?.internetPoints?.value
											}
											disabled={readMode}
											maxLength={3}
										/>
									</Col>
									<Col md='4'>
										<NumberInputField
											name={`equipmentDistribution[${districtName}].wifis.value`}
											handleChange={handleChange}
											labelId='eaAccreditationForm.step6.accordion.wifis'
											value={
												formikValues.equipmentDistribution[districtName].wifis.value
											}
											isInvalid={
												errors.equipmentDistribution?.[districtName]?.wifis?.value
											}
											errorMessage={
												errors.equipmentDistribution?.[districtName]?.wifis?.value
											}
											disabled={readMode}
											maxLength={3}
										/>
									</Col>
								</Row>
								<Row>
									<Col md='4'>
										<NumberInputField
											name={`equipmentDistribution[${districtName}].scanners.value`}
											handleChange={handleChange}
											labelId='eaAccreditationForm.step6.accordion.scanners'
											value={
												formikValues.equipmentDistribution[districtName]
													.scanners.value
											}
											isInvalid={
												errors.equipmentDistribution?.[districtName]?.scanners?.value
											}
											errorMessage={
												errors.equipmentDistribution?.[districtName]?.scanners?.value
											}
											disabled={readMode}
											maxLength={3}
										/>
									</Col>
									<Col md='4'>
										<NumberInputField
											name={`equipmentDistribution[${districtName}].printers.value`}
											handleChange={handleChange}
											labelId='eaAccreditationForm.step6.accordion.printers'
											value={
												formikValues.equipmentDistribution[districtName]
													.printers.value
											}
											isInvalid={
												errors.equipmentDistribution?.[districtName]?.printers?.value
											}
											errorMessage={
												errors.equipmentDistribution?.[districtName]?.printers?.value
											}
											disabled={readMode}
											maxLength={3}
										/>
									</Col>
									<Col md='4'>
										<NumberInputField
											name={`equipmentDistribution[${districtName}].vehicles.value`}
											handleChange={handleChange}
											labelId='eaAccreditationForm.step6.accordion.vehicles'
											value={
												formikValues.equipmentDistribution[districtName]
													.vehicles.value
											}
											isInvalid={
												errors.equipmentDistribution?.[districtName]?.vehicles?.value
											}
											errorMessage={
												errors.equipmentDistribution?.[districtName]?.vehicles?.value
											}
											disabled={readMode}
											maxLength={3}
										/>
									</Col>
								</Row>
								{!readMode && (
								<Row className='mt-4'>
									<Col>
										<button
											className='px-0 btn btn-link'
											type='button'
											onClick={() => removeDistrictHandler(districtName)}
										>
											<FormattedMessage id='all.remove' />
										</button>
									</Col>
								</Row>
								)}
							</Accordion.Body>
						</Accordion.Item>
					</Accordion>
				)
			)}
			<div className='form-actions mt-5'>
				<button
					className='btn btn-link'
					type='button'
					onClick={handlePreviousStep}
				>
					<FormattedMessage id='all.back' />
				</button>

				<button
					className='btn btn-primary'
					type='button'
					onClick={handleNextStep}
				>
					<FormattedMessage id='all.nextStep' />
				</button>
			</div>
		</div>
	);
}
