import parse from 'html-react-parser';
import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { AlertError } from '../../../components/bootstrap/AlertError';
import { AlertSuccess } from '../../../components/bootstrap/AlertSuccess';
import Loading from '../../../components/general/Loading';
import { SubTemplate } from '../../../components/general/SubTemplate';
import { ReplyMessageDialog } from '../../../components/messages/ReplyMessageDialog';
import { useGlobalContext } from '../../../context';
import { BoundType } from '../../../models/BoundType';
import { MessageType } from '../../../models/MessageType';
import {
	getMessage,
	getUserMessagesPending,
	replyMessage, replyMessageWithFiles,
} from '../../../rest/messages';
import {
	TranslateMessageBoundType,
	TranslateMessageType,
} from '../../../utils/TranslateStates';
import {Arrays} from "../../../utils/Arrays";
import {createMultiPart} from "../../../components/SyncMultipleUploadArea";
import {AuthenticatedDownloadLink} from "../../../components/document/AuthenticatedDownloadLink";

const showReplyButton = (message) => {
	if (
		message.type !== MessageType.INTERNAL ||
		message.boundType !== BoundType.IN ||
		message.direction.split('_')[0] === 'SYSTEM'
	) {
		return false;
	}
	return true;
};

export function MessageDetails() {
	const { setNMessages } = useGlobalContext();
	const [messageDetails, setMessageDetails] = useState(null);
	const [loading, setLoading] = useState(true);
	const [showReplyForm, setShowReplyForm] = useState(false);
	const [error, setError] = useState(null);
	const [submit, setSubmit] = useState(false);
	const [successMessage, setSuccessMessage] = useState(null);
	const intl = useIntl();
	const navigate = useNavigate();

	const fetchDetails = async () => {
		setSubmit(false);
		try {
			const [{ data: message }, { data: nMessages }] = await Promise.all([
				await getMessage(externalId),
				await getUserMessagesPending(),
			]);
			setMessageDetails(message);
			setNMessages(nMessages);
		} catch (error) {
			setError(error);
		} finally {
			setLoading(false);
		}
	};

	const { externalId } = useParams();

	useEffect(() => {
		fetchDetails();
	}, [submit, externalId]);

	const onSendMessage = async (newMessage, parentMessage) => {
		setLoading(true);
		try {
			if('documents' in newMessage && Arrays.isNotEmpty(newMessage.documents)) {
				const formData = createMultiPart(newMessage.documents);
				delete newMessage.documents;
				formData.append('ReplyMessageDto', JSON.stringify(newMessage));
				await replyMessageWithFiles(parentMessage.externalId, formData);
			} else {
				await replyMessage(parentMessage.externalId, newMessage);
			}

			setSuccessMessage(intl.formatMessage({ id: 'message.success' }));
			setShowReplyForm(false);
			setSubmit(true);
		} catch (error) {
			setSuccessMessage(null);
			setError(error);
			setLoading(false);
		} finally {
			setLoading(false);
		}
	};

	if (loading) {
		return <Loading />;
	}


	return (
		<SubTemplate
			centerContent
			hasBackButton
			title={messageDetails.title ? parse(messageDetails.title) : 'No title'}
			subTitle={intl.formatMessage({ id: 'messages.detailsText' })}
		>
			<Container>
				<Row>
					<Col md={{ span: 8, offset: 2 }}>
						<AlertError error={error} />
						<AlertSuccess message={successMessage} />
						<div className={'form bg-white'}>
							<Row>
								<Col>
									<Row>
										<Col>
										<span className='text-secondary'>
											<FormattedMessage id='messages.personNameText' />{': '}
										</span>
											{messageDetails.from}
										</Col>
									</Row>
									<Row>
										<Col>
									<span className='text-secondary'>
										<FormattedMessage id='messages.toText' />{': '}
									</span>
											{messageDetails.to}
										</Col>
									</Row>
									<Row>
										<Col>
											<Row>
												<Col>
													<small>
											<span className='text-secondary'>
												<FormattedMessage id='messages.boundType' />
												{': '}
											</span>
														<TranslateMessageBoundType
															value={messageDetails.boundType}
														/>
														<span className='text-secondary'>
												{' · '}
															<FormattedMessage id='messages.typeText' />
															{': '}
											</span>
														<TranslateMessageType value={messageDetails.type} />
													</small>
												</Col>
											</Row>
										</Col>
									</Row>
								</Col>
								<Col className='text-end'>
									<Row>
										<Col>
											{messageDetails.emailAddressFrom != null && (
												<>
											<span className='text-secondary'>
												<FormattedMessage id='messages.fromText' />{': '}
											</span>
													{messageDetails.emailAddressFrom}
												</>
											)}
											{messageDetails.emailAddressTo != null && (
												<>
											<span className='text-secondary'>
												<FormattedMessage id='messages.toText' />{': '}
											</span>
													{messageDetails.emailAddressTo}
												</>
											)}
											{messageDetails.mobile != null && (
												<>
											<span className='text-secondary'>
												<FormattedMessage id='messages.mobileText' />{': '}
											</span>
													{messageDetails.mobile}
												</>
											)}
											{messageDetails.candidaturePath != null && (
												<>
											<span className='text-secondary'>
												<FormattedMessage id='candidature.candidatureTitle' />{': '}
											</span>
													<Link to={messageDetails.candidaturePath}>
														{messageDetails.processId != null
															? messageDetails.processId
															: messageDetails.candidatureCode}
													</Link>
												</>
											)}
											{messageDetails.priorSupportPath != null && (
												<>
											<span className='text-secondary'>
												<FormattedMessage id='candidature.priorSupportTitle' />{': '}
											</span><br/>
													<Link to={messageDetails.priorSupportPath}>
														{messageDetails.promoterName ? messageDetails.promoterName : messageDetails.priorSupportExternalId}
													</Link>
												</>
											)}
											{messageDetails.requestConsultingPath != null && (
												<>
											<span className='text-secondary'>
												<FormattedMessage id='candidature.mceTitle' />{': '}
											</span><br/>
													<Link to={messageDetails.requestConsultingPath}>
														{messageDetails.promoterName ? messageDetails.promoterName : messageDetails.requestConsultingExternalId}
													</Link>
												</>
											)}
											<Row>
												<Col>
											<span className='text-secondary'>
												<FormattedMessage id='messages.dateText' />{': '}
											</span>
													{messageDetails.creationTs}
												</Col>
											</Row>
										</Col>
									</Row>
								</Col>
							</Row>




							<div className={'mt-4'}>
								<label>
									<FormattedMessage id='messages.messageText' />
									<FormattedMessage
										id={`message.suffix.direction.${messageDetails.direction}`}
									/>
								</label>
								{messageDetails.body
									? parse(messageDetails.body)
									: 'Sem conteúdo'}
							</div>

							{messageDetails?.parentExternalId && (
								<div className={'mt-4'}>
									<a
										href={`/perfil/mensagem/${messageDetails.parentExternalId}`}
									>
										<FormattedMessage id='messages.previous.message' />
									</a>
								</div>
							)}

							{Arrays.isNotEmpty(messageDetails.documents) && (
								<>
									<legend className={'mt-4 mb-2'}>
										<FormattedMessage id='messages.documents' />
									</legend>
									{messageDetails.documents.map( (d, index) => (
									<p>
										<span>
											{d.fileDescription}
										</span>: &nbsp;
										<AuthenticatedDownloadLink
											key={index}
											url={d.documentPath}
											filename={d.name}
										>

											{d.name}
										</AuthenticatedDownloadLink>
									</p>
									))}
								</>
							)}
							{messageDetails.messages.length > 0 && (
								<>
									<legend className={'mt-4 mb-2'}>
										<FormattedMessage id='messages.replies' />
									</legend>
									<table className='table'>
										<thead>
											<tr>
												<th>
													<label>
														<FormattedMessage id='messages.titleText' />
													</label>
												</th>
												<th>
													<label>
														<FormattedMessage id='messages.boundType' />
													</label>
												</th>

												<th>
													<label>
														<FormattedMessage id='messages.typeText' />
													</label>
												</th>
												<th>
													<label>
														<FormattedMessage id='messages.dateText' />
													</label>
												</th>
											</tr>
										</thead>
										<tbody>
											{messageDetails.messages.map((message) => (
												<tr key={message.externalId}>
													<td>
														<Link
															to={`/perfil/mensagem/${message.externalId}`}
															style={{
																fontWeight:
																	message?.state === 'PENDING'
																		? 'bolder'
																		: 'normal',
															}}
														>
															{message.title
																? parse(message.title)
																: 'No title'}
														</Link>
													</td>
													<td>
														{' '}
														<TranslateMessageBoundType
															value={message.boundType}
														/>{' '}
													</td>

													<td>
														<TranslateMessageType value={message.type} />
													</td>
													<td>{message.creationTs}</td>
												</tr>
											))}
										</tbody>
									</table>
								</>
							)}
						</div>

						{showReplyForm && (
							<ReplyMessageDialog
								message={messageDetails}
								onSendMessage={onSendMessage}
								onSendMessageWithFiles={onSendMessage}
								handleClose={() => setShowReplyForm(false)}
							/>
						)}

						<div className='form-actions'>
							<div className='mr-auto'>
								<button
									className='btn btn-link'
									type='button'
									onClick={() => {
										navigate('/perfil/mensagens');
									}}
								>
									<FormattedMessage id='all.backButtonText' />
								</button>
							</div>

							{!showReplyForm && showReplyButton(messageDetails) && (
								<div className='ml-auto'>
									<button
										className={'btn btn-outline-primary'}
										type='button'
										onClick={() => setShowReplyForm(true)}
									>
										<FormattedMessage id='all.reply' />
									</button>
								</div>
							)}
						</div>
					</Col>
				</Row>
			</Container>
		</SubTemplate>
	);
}
