import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AlertError } from '../../components/bootstrap/AlertError';
import { FinalDecisionForm } from '../../components/candidature/FinalDecisionForm';
import Loading from '../../components/general/Loading';
import { SubTemplate } from '../../components/general/SubTemplate';
import {getCandidatureByExternalId,  submitFinalDecision} from '../../rest/candidature';
import {listCandidatureDocumentsByDescriptions, listDocumentsByTypeAndCandidatureExternalId} from '../../rest/document';
import { handleError, isNotBusinessError } from '../../utils/handleError';
import {createCustomErrorMessage} from '../../hooks/errorMessage';
import {useIntl} from 'react-intl';
import {CandidatureState} from "../../models/CandidatureState";


export function CandidatureFinalDecision() {

  const { externalId } = useParams();
  const navigate = useNavigate();
  const intl = useIntl();

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [userDocuments, setUserDocuments] = useState([]);
  const [candidature, setCandidature] = useState(null);

  const [stateFinalDecision, setStateFinalDecision] = useState(false);

  async function fetchData() {
    try {
      const [
        { data: userDocuments }
      ] = await Promise.all([
        await listCandidatureDocumentsByDescriptions('CANDIDATURE',externalId, ['FINAL_DECISION','FINAL_DECISION_PRIVATE'] )
      ]);
      setUserDocuments(userDocuments);

    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  }

  async function checkCandidatureState() {
    try {
      const [{ data: candidature }] = await Promise.all([
        await getCandidatureByExternalId(externalId),
      ]);
      setCandidature(candidature)
      if (candidature.state === CandidatureState.FINAL_DECISION || candidature.state === CandidatureState.FINAL_DECISION_WAIT_RESPONSE) {
        setStateFinalDecision(true);
        fetchData();
      } else {
        setLoading(false);
      }
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  }

  useEffect(() => {
    checkCandidatureState();
  }, []);

  async function submitDecision() {
    try {
      await submitFinalDecision(externalId);
      navigate('/candidatura/' + externalId);
    } catch (error) {
      setError(error);
    }
  }

  function handleSubmit() {
      submitDecision();
  }

  if (loading) {
    return <Loading />;
  }

  if (error && isNotBusinessError(error)) {
    return handleError(error);
  }

  return (
    <SubTemplate>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-8 offset-lg-2 mb-5'>
            <AlertError error={error} />
            {stateFinalDecision && <FinalDecisionForm
              onSubmit={handleSubmit}
              userDocuments={userDocuments}
              externalId={externalId}
              setError={setError}
              approvalType ={candidature.approvalType}
            />}
          </div>
        </div>
      </div>
    </SubTemplate>
  );
}
