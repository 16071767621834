import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { CompanyIdentificationForm } from '../../general/CompanyIdentificationForm';
import { SearchInputDropdown } from '../../general/SearchInputDropdown';
import { getConsultingRequestsCandidatureMappingToMainPromoterName } from '../../../rest/consultingRequest';
import { Col, Dropdown, Form, Row } from 'react-bootstrap';
import { TextInputField } from '../../bootstrap/TextInputField';

export function SemestralReportFormStep2({
	formStep,
	numberOfSteps,
	errors,
	handleChange,
	formikValues,
	handlePreviousStep,
	handleNextStep,
	readMode,
	hasCandidatureSearch,
	setError,
	setFieldValue,
	hasCandidatureProcessNumber,
}) {
	const intl = useIntl();

	function candidatureProcessNumberSelectionHandler(
		candidatureProcessNumber,
		mainPromoterName
	) {
		formikValues.candidatureProcessNumber = candidatureProcessNumber;
		formikValues.company.personInChargeName = mainPromoterName;
		setFieldValue({ ...formikValues });
	}

	function dropdownItemsGenerator(candidatureProcessNumbersMappings) {
		return Object.keys(candidatureProcessNumbersMappings).map((key, index) => (
			<Dropdown.Item
				key={index}
				onClick={() =>
					candidatureProcessNumberSelectionHandler(
						key,
						candidatureProcessNumbersMappings[key]
					)
				}
			>
				{key}
			</Dropdown.Item>
		));
	}

	return (
		<div>
			<h4 className='text-primary mb-0'>
				<FormattedMessage id='semestralReportForm.step2.title' />
			</h4>
			<small className='text-secondary text-uppercase'>
				{intl.formatMessage(
					{ id: 'all.step' },
					{ step: formStep, numberOfSteps: numberOfSteps }
				)}
			</small>
			<fieldset disabled={readMode == true}>
			<CompanyIdentificationForm
				errors={errors}
				formikValues={formikValues}
				handleChange={handleChange}
				showPostalCode={true}
				readMode={readMode}
				hasCandidatureProcessNumber={hasCandidatureProcessNumber}
			/>
			{hasCandidatureSearch === true && (
				<Row>
					<Col md={formikValues.candidatureProcessNumber !== '-1' ? '12' : '4'}>
						<Form.Label>
							{intl.formatMessage({
										id: 'consultingRequest.candidatureProcessId',
								  })
							}
						</Form.Label>
						{formikValues.candidatureProcessNumber !== '-1' ? (
							<div className='d-flex align-items-center'>
									{formikValues.candidatureProcessNumber}
								<button
									className='px-0 btn btn-link ms-3'
									type='button'
									onClick={() => {
										setFieldValue('candidatureProcessNumber', '-1');
										setFieldValue('company.personInChargeName', '');
									}}
								>
									<FormattedMessage id='all.disassociate' />
								</button>
							</div>
						) : (
							<SearchInputDropdown
								filterQueryParamName='candidatureProcessNumber'
								searchCallback={
									getConsultingRequestsCandidatureMappingToMainPromoterName
								}
								setError={setError}
								itemsFunctionGenerator={dropdownItemsGenerator}
							/>
						)}
					</Col>
				</Row>
			)}
			</fieldset>
			<div className='form-actions mt-5'>
				<button
					className='btn btn-link'
					type='button'
					onClick={handlePreviousStep}
				>
					<FormattedMessage id='all.back' />
				</button>

				<button
					className='btn btn-primary'
					type='button'
					onClick={handleNextStep}
				>
					<FormattedMessage id='all.nextStep' />
				</button>
			</div>
		</div>
	);
}
