import { ContentState, convertToRaw, EditorState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import React, { useState } from 'react';
import { Form, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { FaInfoCircle } from 'react-icons/fa';
import { FormattedMessage } from 'react-intl';
//import 'reactjs-popup/dist/index.css';

export function EditorInputField({
  name,
  value,
  labelId,
  handleChange,
  isInvalid,
  errorMessage,
  hideOvelay,
}) {
  const [editorState, setEditorState] = useState(valueToState(value));

  const onEditorStateChange = (editorState) => {
    const data = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    handleChange({ target: { name, value: data } });
    setEditorState(editorState);
  };

  const getContent = (name) => {
    switch (name) {
      case 'templateEmailTitle':
        return <FormattedMessage id='actionNotificationForm.toolTip.message' />;

      case 'templateEmailBody':
        return <FormattedMessage id='actionNotificationForm.toolTip.message' />;

      case 'templateInternalNoteTitle':
        return <FormattedMessage id='actionNotificationForm.toolTip.message' />;

      case 'templateInternalNoteBody':
        return <FormattedMessage id='actionNotificationForm.toolTip.message' />;

      default:
        return '';
    }
  };

  return (
    <Form.Group controlId={name}>
      <Form.Label className='mt-4'>
        {labelId && <FormattedMessage id={labelId} />}
        {!hideOvelay && (
          <OverlayTrigger overlay={<Tooltip>{getContent(name)}</Tooltip>}>
            <FaInfoCircle className='ml-1 pb-1' />
          </OverlayTrigger>
        )}
      </Form.Label>
      <Form.Control
        wrapperClassName={`form-control ${isInvalid ? 'is-invalid' : ''}`}
        as={Editor}
        name={name}
        editorState={editorState}
        onEditorStateChange={onEditorStateChange}
        isInvalid={isInvalid}
      />
      <Form.Control.Feedback type='invalid'>
        {errorMessage}
      </Form.Control.Feedback>
    </Form.Group>
  );
}

/**
 * @param  {String} value
 */
const valueToState = (value) => {
  const blocksFromHtml = htmlToDraft(value ? value : '');
  const { contentBlocks, entityMap } = blocksFromHtml;
  const contentState = ContentState.createFromBlockArray(
    contentBlocks,
    entityMap
  );

  return EditorState.createWithContent(contentState);
};
