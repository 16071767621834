import {NonComplianceState} from "./NonComplianceState";
import {RestitutionType} from "./RestitutionType";

export function isDecidableNonCompliance(nonCompliance) {
    const correctState = nonCompliance.state === NonComplianceState.APPEALED || nonCompliance.state === NonComplianceState.APPEAL_DEADLINE_MISSED;
    return correctState && nonCompliance.decision === undefined;
}


export function canChangeRestitution(nonCompliance) {
    const correctState = nonCompliance.state === NonComplianceState.FINAL;
    return correctState
        && [RestitutionType.PARTIAL,RestitutionType.TOTAL].includes(nonCompliance.decision.restitution)
        && (
            nonCompliance.restitutionRecord === undefined
            || nonCompliance.restitutionRecord.hasReimbursementPlan === false
            || nonCompliance.restitutionRecord.hasRestitutionDate === false
            || nonCompliance.restitutionRecord.hasDateSentToCoerciveCollection === false
            || nonCompliance.restitutionRecord.restitutionDone === false
        );
}




export function isAppealableNonCompliance(nonCompliance) {
    const correctState = nonCompliance.state === NonComplianceState.INITIATED;
    return correctState && nonCompliance.appeal === undefined;
}