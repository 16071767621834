import React, { useEffect, useRef, useState } from 'react';
import {Form, Row, Container, Col, Button} from 'react-bootstrap';
import { Formik } from 'formik';
import { FormattedMessage, useIntl } from 'react-intl';
import Loading from '../../components/general/Loading';
import { SubTemplate } from '../../components/general/SubTemplate';
import { FaCheck, FaRegCheckCircle, FaRegSave, FaTimes } from 'react-icons/fa';
import { handleError, isNotBusinessError } from '../../utils/handleError';
import { FormSideNavBar } from '../../components/general/FormSideNavBar';
import { PriorSupportPaymentRequestStep3 } from '../../components/priorSupportPaymentRequest/PriorSupportPaymentRequestStep3';
import { PriorSupportPaymentRequestStep4 } from '../../components/priorSupportPaymentRequest/PriorSupportPaymentRequestStep4';
import { SemestralReportFormStep1 } from '../../components/semestralReport/form/SemestralReportFormStep1';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { ConfirmationAuthenticationModal } from '../../components/general/ConfirmationAuthenticationModal';
import { AlertError } from '../../components/bootstrap/AlertError';
import { SuccessToast } from '../../components/general/SuccessToast';
import { ProcessState } from '../../models/ProcessState';
import { getEntityGetList } from '../../rest/entity';
import { getHumanResourceByExternalId } from '../../rest/humanResource';
import { isIEFPUser } from '../../authentication/authenticationHelper';
import { createCustomErrorMessage } from '../../hooks/errorMessage';
import * as yup from 'yup';
import {
	getEconomicViabilityPaymentRequest,
	getEconomicViabilityPaymentRequestByProcessExternalId,
	howManyEligibleEconomicViabilitiesHaveNoPayment,
	listEconomicViabilityPaymentRequestDocuments,
	saveEconomicViabilityPaymentRequest,
	withdrawEconomicViabilityPaymentRequest,
} from '../../rest/economicViabilityPaymentRequest';
import { EconomicViabilityPaymentRequestFormStep2 } from '../../components/economicViabilityPaymentRequest/EconomicViabilityPaymentRequestFormStep2';
import {PayableProcessButton} from "../../components/payableProcess/PayableProcessButton";

const validationSchema = yup.object().shape({
	identificationEa: yup.object().shape({
		technicalTeamManagerExternalId: yup
			.string()
			.test(
				'isGETValid',
				<FormattedMessage id='errors.annualComplementaryActivitiesPlan.GET' />,
				(value) => value !== '-1'
			),
	}),

	observationsEa: yup
		.string()
		.required(<FormattedMessage id='errors.fieldRequiredText' />),

	paymentTechnicalAnalysis: yup.object().shape({
		observations: yup
			.string()
			.required(<FormattedMessage id='errors.fieldRequiredText' />),
		technicianName: yup
			.string()
			.required(<FormattedMessage id='errors.fieldRequiredText' />),
		directorName: yup
			.string()
			.required(<FormattedMessage id='errors.fieldRequiredText' />),
		iasHowManyTimes: yup
			.number()
			.required(<FormattedMessage id='errors.fieldRequiredText' />)
			.min(0, <FormattedMessage id='errors.number.notNegative' />),
	}),

	approvedAmount: yup
		.number()
		.test(
			'isNumberValid',
			<FormattedMessage id='errors.eaAccreditationForm.number' />,
			(value) => value >= 0
		),

	proposalDispatchDate: yup
		.date()
		.nullable()
		.required(<FormattedMessage id='errors.fieldRequiredText' />),

	decisionDispatchNumber: yup
		.string()
		.required(<FormattedMessage id='errors.fieldRequiredText' />),

	decisionDispatchDate: yup
		.date()
		.nullable()
		.required(<FormattedMessage id='errors.fieldRequiredText' />),
	regionalDelegateName: yup
		.string()
		.required(<FormattedMessage id='errors.fieldRequiredText' />),
});

export function EconomicViabilityPaymentRequest() {
	const isIEFP = isIEFPUser();

	//for both iefp and entity users. In case of iefp, it is the processExternalId. Also, if it got here without the externalId, then its value is undefined
	const { externalId: externalId } = useParams();

	const [loading, setLoading] = useState(true);
	const [submited, setSubmited] = useState(false);

	//errors
	const [error, setError] = useState(null);
	const [navErrors, setNavErrors] = useState([]);

	const [formStep, setFormStep] = useState(0);

	const [formikInitialValues, setFormikInitialValues] = useState(null);

	const [showSaveToast, setShowSaveToast] = useState(false);

	//modals
	const [showWithdrawModal, setShowWithdrawModal] = useState(false);
	const [showSubmitModal, setShowSubmitModal] = useState(false);
	const [showApproveModal, setShowApproveModal] = useState(false);
	const [showRejectModal, setShowRejectModal] = useState(false);

	const [getList, setGetList] = useState([]);

	const requiredIEFPDocuments = ['DECISION_DISPATCH'];
	const [documents, setDocuments] = useState([]);

	const navigate = useNavigate();
	const intl = useIntl();

	const formikPropsRef = useRef(null);

	const additionalTabsIds = [
		'economicViabilityPaymentRequest.sideMenu.analysis',
	];

	const [tabsIds, setTabsIds] = useState([
		'economicViabilityPaymentRequest.sideMenu.identificationEA',
		'economicViabilityPaymentRequest.sideMenu.candidatureAnalyzedIdentification',
		'economicViabilityPaymentRequest.sideMenu.paymentRequest',
	]);

	async function fetchData() {
		try {
			let [
				{ data: economicViabilityPaymentRequest },
			] = await Promise.all([
				isIEFP
					? await getEconomicViabilityPaymentRequestByProcessExternalId(
							externalId
					  )
					: await getEconomicViabilityPaymentRequest(externalId),

			]);

			let [
				{ data: entityGetList },
			] = await Promise.all([
				isIEFP ? { data: [] } : await getEntityGetList({all: economicViabilityPaymentRequest.processState !== ProcessState.DRAFT }),
			]);

			if (isIEFP) {
				const { data: get } = await getHumanResourceByExternalId(
					economicViabilityPaymentRequest.identificationEa
						.technicalTeamManagerExternalId
				);
				entityGetList.push(get);
			}

			if (
				(isIEFP &&
					economicViabilityPaymentRequest.processState !==
						ProcessState.DRAFT) ||
				ProcessState.finalStates().includes(
					economicViabilityPaymentRequest.processState
				)
			) {
				setTabsIds((prevStateValue) => {
					return prevStateValue.concat(additionalTabsIds);
				});
			}

			if (
				ProcessState.finalStates().includes(
					economicViabilityPaymentRequest.processState
				) ||
				isIEFP
			) {
				let { data: processDocuments } =
					await listEconomicViabilityPaymentRequestDocuments(
						economicViabilityPaymentRequest.externalId
					);
				let formDocumentList = requiredIEFPDocuments.map((rd) => {
					let dTypeIndex = processDocuments.findIndex(
						(d) => d.documentType === rd
					);
					if (dTypeIndex < 0) {
						//it means the document wasnt found
						return {
							externalId: '',
							documentType: rd,
							name: '',
							content: null,
							submited: false,
							error: '',
						};
					} else {
						return {
							externalId: processDocuments[dTypeIndex].externalId,
							documentType: processDocuments[dTypeIndex].documentType,
							documentPath: processDocuments[dTypeIndex].documentPath,
							name: processDocuments[dTypeIndex].name,
							content: '',
							submited: true,
							error: '',
						};
					}
				});
				setDocuments(formDocumentList);
			}

			setGetList(entityGetList);
			setFormikInitialValues(
				getFormikInitialValues(economicViabilityPaymentRequest)
			);
		} catch (error) {
			setError(error);
		} finally {
			setLoading(false);
		}
	}

	useEffect(() => {
		fetchData();
	}, []);

	function getFormikInitialValues(b5) {
		let initialValues = b5;

		if (isIEFP || ProcessState.finalStates().includes(b5.processState)) {
			initialValues.paymentTechnicalAnalysis.iasHowManyTimes ??= '';
			initialValues.paymentTechnicalAnalysis.totalAmount ??= '';
			initialValues.paymentTechnicalAnalysis.observations ??= '';
			initialValues.paymentTechnicalAnalysis.technicianName ??= '';
			initialValues.paymentTechnicalAnalysis.directorName ??= '';

			initialValues.approvedAmount ??= '';
			initialValues.regionalDelegateName ??= '';
			initialValues.proposalDispatchDate ??= null;
			initialValues.decisionDispatchNumber ??= '';
			initialValues.decisionDispatchDate ??= null;
			initialValues.decisionDate ??= null;
		}

		//general and step 1
		initialValues.externalId = b5.externalId;
		initialValues.processState = b5.processState;
		initialValues.identificationEa = b5.identificationEa;

		//step 2
		initialValues.candidatures ??= [];

		//step 3
		initialValues.iasAtContractCelebration ??= '';
		initialValues.observationsEa ??= '';

		return initialValues;
	}

	function scrollPageToTop() {
		document.body.scrollTop = 0;
		document.documentElement.scrollTop = 0;
	}

	function handlePreviousStep() {
		scrollPageToTop();
		setFormStep(formStep - 1);
	}

	function handleNextStep() {
		scrollPageToTop();
		setFormStep(formStep + 1);
	}

	if (submited) {
		if (isIEFP) {
			sessionStorage.setItem(
				'economicViabilityPaymentRequestDecisionSubmited',
				submited
			);
		} else {
			sessionStorage.setItem('economicViabilityRequestSubmited', submited);
		}
		return isIEFP ? (
			<Navigate to='/processos' />
		) : (
			<Navigate to='/pagamento/viabilidadeEconomica/lista' />
		);
	}

	if (loading) {
		return <Loading />;
	}

	if (error && isNotBusinessError(error)) {
		return handleError(error);
	}

	async function handleWithdrawal(user, password, setModalError) {
		try {
			let authenticationCredentials = {
				user: user,
				password: password,
			};

			await withdrawEconomicViabilityPaymentRequest(
				formikPropsRef.current.values.externalId,
				authenticationCredentials
			);

			sessionStorage.setItem('economicViabilityPaymentRequestWithdrawed', true);
			navigate('/pagamento/viabilidadeEconomica/lista');
		} catch (error) {
			setModalError(error);
		}
	}

	async function handleSave() {
		try {
			let { data: res } = await saveEconomicViabilityPaymentRequest(
				formikPropsRef.current.values
			);

			let processedRes = getFormikInitialValues(res);

			formikPropsRef.current.setValues(processedRes);
			setNavErrors([]);
			formikPropsRef.current.setErrors({});

			setShowSaveToast(true);
		} catch (error) {
			setError(error);
		}
	}

	async function handleSubmit(user, password, setModalError, decisionApprove) {
		let values = formikPropsRef.current.values;
		const submissionValues = { ...values };

		submissionValues.credentials = {
			user: user,
			password: password,
		};

		if (isIEFP) {
			submissionValues.decisionApprove = decisionApprove;
		}

		let queryParams = {
			submit: true,
		};

		try {
			await saveEconomicViabilityPaymentRequest(submissionValues, queryParams);
			setSubmited(true);
		} catch (err) {
			let exceptionType = err.response.data.exception;
			if (exceptionType === 'ValidationException') {
				handleNavError(err);
			} else if (
				exceptionType === 'CandidaturesNotInSameRegionalDelegationException' ||
				exceptionType === 'EconomicViabilitiesNotInSameYearException'
			) {
				setError(err);
				setNavErrors([1]);
				setShowSubmitModal(false);
			} else {
				setModalError(err);
			}
		}
	}

	async function formValidationHandler(setShowModal) {
		let errors = await validateForm();
		setNavErrors(Array.from(errors));
		if (errors.size === 0) {
			setShowModal(true);
		}
	}

	async function validateForm() {
		let errors = new Set();

		const formikErrors = await formikPropsRef.current.validateForm();

		Object.keys(formikErrors).forEach((key) => {
			switch (key) {
				case 'identificationEa':
					!isIEFP && errors.add(0);
					break;
				case 'observationsEa':
					!isIEFP && errors.add(2);
					break;
				default:
					isIEFP && errors.add(3);
					break;
			}
		});

		let values = formikPropsRef.current.values;

		let errorMessages = [];

		//validate that both dispatch documents have been uploaded. For the entity's user, documents = []
		documents.forEach((d) => {
			if (d.submited === false) {
				errors.add(3);
				errorMessages.push(
					intl.formatMessage({
						id: `errors.economicViabilityPaymentRequest.dispatchDocuments.${d.documentType}`,
					})
				);
			}
		});

		if (!isIEFP && values.candidatures.length === 0) {
			errors.add(1);
			errorMessages.push(
				intl.formatMessage({
					id: 'errors.economicViabilityPaymentRequest.candidatureMentionsEmpty',
				})
			);
		}

		if (errorMessages.length > 0) {
			let message =
				errorMessages.length === 1
					? createCustomErrorMessage(errorMessages[0])
					: createCustomErrorMessage(
							'<p>' +
								intl.formatMessage({
									id: 'errors.more',
								}) +
								'</p><p> - ' +
								errorMessages.join('</p><p> - ') +
								'</p>'
					  );

			setError(message);
		}

		return errors;
	}

	function handleNavError(error) {
		let errors = error.response.data.message
			.split(',')
			.map((value) => Number.parseInt(value));
		setNavErrors(errors);
	}

	function renderStep(formikProps) {
		formikPropsRef.current = formikProps;

		const readModeStep1To3 =
			formikProps.values.processState !== ProcessState.DRAFT || isIEFP;

		switch (formStep) {
			case 0:
				return (
					<SemestralReportFormStep1
						formStep={formStep + 1}
						numberOfSteps={tabsIds.length}
						errors={formikProps.errors}
						formikValues={formikProps.values}
						handleChange={formikProps.handleChange}
						handleNextStep={handleNextStep}
						entityGetList={getList}
						readMode={readModeStep1To3}
						goBackPath={
							isIEFP ? '/processos' : '/pagamento/viabilidadeEconomica/lista'
						}
					/>
				);
			case 1:
				return (
					<EconomicViabilityPaymentRequestFormStep2
						formStep={formStep + 1}
						numberOfSteps={tabsIds.length}
						formikValues={formikProps.values}
						handleChange={formikProps.handleChange}
						handlePreviousStep={handlePreviousStep}
						handleNextStep={handleNextStep}
						setFieldValue={formikProps.setFieldValue}
						setError={setError}
						readMode={readModeStep1To3}
					/>
				);
			case 2:
				return (
					<PriorSupportPaymentRequestStep3
						formStep={formStep + 1}
						numberOfSteps={tabsIds.length}
						errors={formikProps.errors}
						formikValues={formikProps.values}
						handleChange={formikProps.handleChange}
						handlePreviousStep={handlePreviousStep}
						handleNextStep={handleNextStep}
						readMode={readModeStep1To3}
						showNextStepButton={
							ProcessState.finalStates().includes(
								formikProps.values.processState
							) ||
							(isIEFP && formikProps.values.processState !== ProcessState.DRAFT)
						}
						entityGetList={getList}
						b5
					/>
				);
			case 3:
				return (
					<PriorSupportPaymentRequestStep4
						formStep={4}
						numberOfSteps={tabsIds.length}
						errors={formikProps.errors}
						formikValues={formikProps.values}
						handleChange={formikProps.handleChange}
						setFieldValue={formikProps.setFieldValue}
						setError={setError}
						handlePreviousStep={handlePreviousStep}
						readMode={ProcessState.finalStates().includes(
							formikProps.values.processState
						)}
						b5
						documents={documents}
						setDocuments={setDocuments}
						isIEFP={isIEFP}
					/>
				);
			default:
				console.log('Something went wrong rendering the form step');
		}
	}

	return (
		<SubTemplate>
			<Container>
				<ConfirmationAuthenticationModal
					show={
						showWithdrawModal ||
						showSubmitModal ||
						showApproveModal ||
						showRejectModal
					}
					handleClose={() =>
						showSubmitModal
							? setShowSubmitModal(false)
							: showApproveModal
							? setShowApproveModal(false)
							: showWithdrawModal
							? setShowWithdrawModal(false)
							: setShowRejectModal(false)
					}
					submitHandler={showWithdrawModal ? handleWithdrawal : handleSubmit}
					idsPreffix={
						showApproveModal
							? 'annualComplementaryActivitiesPayment.iefp.acceptModal'
							: showRejectModal
							? 'annualComplementaryActivitiesPayment.iefp.rejectModal'
							: showWithdrawModal
							? 'annualComplementaryActivitiesPayment.withdrawModal'
							: 'annualComplementaryActivitiesPayment.submitModal'
					}
					confirmButtonCompleteLabelId={
						showApproveModal
							? 'all.button.approve'
							: showRejectModal
							? 'all.button.reject'
							: showWithdrawModal
							? 'all.candidatureWithdrawal'
							: 'all.submitButtonText'
					}
					IEFPdecisionApprove={
						showApproveModal ? true : showRejectModal ? false : undefined
					}
				/>
				<Row>
					<Col md={{ span: 8, offset: 2 }}>
						<h2 className='mb-1 text-secondary'>
							<FormattedMessage id='economicViabilityPaymentRequest.title' />
						</h2>
						<p className='mb-5 text-secondary'>
							<FormattedMessage id='economicViabilityPaymentRequest.subTitle' />
							<span
								className="badge rounded-pill bg-secondary fw-normal ms-2"><span
								className="text-uppercase fw-bold"><FormattedMessage
								id={`processState.${formikInitialValues.processState}`}
							/> </span>  {formikInitialValues.submissionDate !== undefined && formikInitialValues.submissionDate !== null && (<> · <FormattedMessage id={'submitted.process.at'} values={{date:formikInitialValues.submissionDate}}/>  </>) } {formikInitialValues.decisionDate !== undefined && formikInitialValues.decisionDate !== null && (<> · {ProcessState.APPROVED===formikInitialValues.processState ? <FormattedMessage id={'approvedIEFP.process.at'} values={{date:formikInitialValues.decisionDate}}/>:<FormattedMessage id={'rejectedIEFP.process.at'} values={{date:formikInitialValues.decisionDate}}/>}   </>) }  </span>
						</p>
					</Col>
				</Row>

				<Row className='mb-5'>
					<Col md='2'>
						<FormSideNavBar
							tabsIdsArray={tabsIds}
							formStep={formStep}
							setFormStep={setFormStep}
							navErrors={navErrors}
							setNavErrors={setNavErrors}
						/>
					</Col>
					<Col md='8'>
						<AlertError error={error} />
						<SuccessToast
							message={intl.formatMessage({
								id: 'annualComplementaryActivitiesPlan.saveToastInformation',
							})}
							show={showSaveToast}
							setShow={setShowSaveToast}
						/>
						<Formik
							initialValues={formikInitialValues}
							validateOnBlur={false}
							validateOnChange={false}
							validationSchema={validationSchema}
							onSubmit={handleSubmit}
						>
							{(formikProps) => (
								<Form onSubmit={formikProps.handleSubmit}>
									{renderStep(formikProps)}
								</Form>
							)}
						</Formik>
					</Col>
					{formikInitialValues.processState === ProcessState.APPROVED &&
						<Col md='2'>
							{isIEFP ? <PayableProcessButton processExternalId={externalId}/> : <PayableProcessButton payableExternalId={externalId}/>}
						</Col>
					}
					{((isIEFP &&
						formikInitialValues.processState === ProcessState.SUBMITTED) ||
						(!isIEFP &&
							formikInitialValues.processState === ProcessState.DRAFT)) && (
						<Col md='2'>
							<div>
								{!isIEFP && (
									<button
										className='btn btn-outline-primary d-flex align-items-center justify-content-center mb-4 w-100'
										type='button'
										onClick={() => setShowWithdrawModal(true)}
									>
										<FormattedMessage id='all.candidatureWithdrawal' />
									</button>
								)}
								<button
									className='btn btn-outline-primary d-flex align-items-center justify-content-center mb-4 w-100'
									type='button'
									onClick={() => handleSave()}
								>
									<FaRegSave />
									<FormattedMessage id='all.save' />
								</button>
								{isIEFP ? (
									<>
										<button
											className='btn btn-primary d-flex align-items-center justify-content-center mb-4 w-100'
											type='button'
											onClick={() => formValidationHandler(setShowRejectModal)}
										>
											<FaTimes /> <FormattedMessage id='all.button.reject' />
										</button>
										<button
											className='btn btn-primary d-flex align-items-center justify-content-center mb-4 w-100'
											type='button'
											onClick={() => formValidationHandler(setShowApproveModal)}
										>
											<FaCheck /> <FormattedMessage id='all.button.approve' />
										</button>
									</>
								) : (
									<button
										className='btn btn-primary d-flex align-items-center justify-content-center w-100'
										type='button'
										onClick={() => formValidationHandler(setShowSubmitModal)}
									>
										<FaRegCheckCircle />
										<FormattedMessage id='all.submitButtonText' />
									</button>
								)}
							</div>
						</Col>
					)}
				</Row>
			</Container>
		</SubTemplate>
	);
}
