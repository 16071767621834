import React, {useEffect, useState} from 'react';
import {Card, Col, Container, Row} from 'react-bootstrap';
import {
    FaClipboardCheck, FaCompass,
    FaEye,
    FaFolderOpen,
    FaPlane,
    FaPlaneDeparture,
    FaBuilding,FaAngleDoubleRight
} from 'react-icons/fa';
import {FormattedMessage} from 'react-intl';
import {Link, Navigate} from 'react-router-dom';
import {AlertSuccess} from '../../../components/bootstrap/AlertSuccess';
import {UserState} from "../../../models/UserState";
import {getAccessInfo, getUserInfo} from "../../../authentication/authenticationHelper";
import {UserType} from "../../../models/UserType";
import {HomeAccordion} from "../../../components/home/HomeAccordion";

export function HomeEntity() {
    const [lsItem, setLsItem] = useState(null);
    const accessInfo = getAccessInfo();
    const userInfo = getUserInfo();
    const [nif, setNif] = useState(null);

    useEffect(() => {
        checkLocalStorageItems();
        if (userInfo != null) {
            setNif(userInfo.nif);
        }
    }, []);

    function checkLocalStorageItems() {
        [
            'cooperationProtocolSubmited',
            'consultingRequestAccepted',
            'consultingRequestDeclined'
        ].forEach((s) => {
            if (sessionStorage.getItem(s)) {
                setLsItem(s);
            }
        });
    }

    if (
        userInfo !== null &&
        userInfo.userType === UserType.ENTITY &&
        userInfo.userState === UserState.VALIDATE
    ) {
        return <Navigate replace to='/identificarEntidadeDeApoio'/>
    }

    return (
        <Container>
            <Row className='py-4'>
                <Col></Col>
            </Row>
            <Row>
                <Col md={{span: 8, offset: 2}}>
                    <h2 className='mb-1 text-secondary'>
                        <FormattedMessage id='entityHome.userArea'/>
                    </h2>
                    <p className='mb-2 text-secondary'>
                        <FormattedMessage id='entityHome.myActions'/>
                    </p>
                    <div className='user-area pb-5'>
                        {lsItem && <AlertSuccess lsItem={lsItem}/>}
                        <Row>
                            <Col>
                                <HomeAccordion
                                    icon={<FaFolderOpen/>}
                                    titleId={'entityHome.myDocuments'}
                                    subtitleId={'entityHome.contractsAndPayment'}
                                >
                                    <Link to={'/entidade/minutaProtocolo'}><FormattedMessage
                                        id={'entityHome.eaProtocolCooperation'}/></Link>
                                    <Link to={'/listagemContratos/lista'}><FormattedMessage
                                        id={'entityHome.contractsList'}/></Link>
                                    <Link to={'/entidade/nib'}><FormattedMessage id={'entityHome.entityNIB'}/></Link>
                                </HomeAccordion>

                                <HomeAccordion
                                    icon={<FaClipboardCheck/>}
                                    titleId={'entityHome.accreditation'}
                                    subtitleId={'entityHome.accreditationDocuments'}
                                >
                                    <Link to='/entidade/credenciacao'><FormattedMessage
                                        id='entityHome.accreditationDocuments'/></Link>
                                    <Link to={`/alteracoesEntidade/${nif ? nif : ''}`}><FormattedMessage
                                        id='entityChange.button'/></Link>
                                    <Link to={`/revogacoesEA/${nif ? nif : ''}`}><FormattedMessage
                                        id='ea.accreditation.revocation.button'/></Link>
                                </HomeAccordion>

                                <HomeAccordion
                                    icon={<FaCompass/>}
                                    titleId={'entityHome.complementaryActivites'}
                                    subtitleId={'entityHome.annualPlan'}
                                >
                                    <Link to='/atividadesComplementares/plano/lista'><FormattedMessage
                                        id='entityHome.annualPlanComplementaryActivites'/></Link>
                                    <Link to='/atividadesComplementares/comunicacaoInicio/lista'><FormattedMessage
                                        id='entityHome.annualPlanComplementaryActivitesStartCommunication'/></Link>
                                    <Link to='pedidoPagamento/atividadesComplementares/lista'><FormattedMessage
                                        id='entityHome.payments'/></Link>
                                </HomeAccordion>

                                <HomeAccordion
                                    icon={<FaEye/>}
                                    titleId={'entityHome.economicViability'}
                                    subtitleId={'entityHome.viabilityAnalysisAndPayments'}
                                >
                                    <Link to='viabilidadeEconomica'><FormattedMessage id='analyses.text'/></Link>
                                    <Link to='/pagamento/viabilidadeEconomica/lista'><FormattedMessage
                                        id='entityHome.payments'/></Link>
                                </HomeAccordion>

                                <HomeAccordion
                                    icon={<FaPlaneDeparture/>}
                                    titleId={'entityHome.priorSupports'}
                                    subtitleId={'entityHome.requestedSupportsAndPayments'}
                                >
                                    <Link to='/pedidos'><FormattedMessage id='entityHome.requestedSupports'/></Link>
                                    <Link to='pagamento/apoioPrevio/lista'><FormattedMessage id='entityHome.payments'/></Link>
                                </HomeAccordion>
                                <HomeAccordion
                                    icon={<FaPlane/>}
                                    titleId={'entityHome.mceSupports'}
                                    subtitleId={'entityHome.first3Years'}
                                >
                                    <Link to='entidade/pedidosMCE'><FormattedMessage id='entityHome.requestedSupports'/></Link>
                                    <Link to='planodesenvolvimento/lista'><FormattedMessage
                                        id='entityHome.developmentPlans'/></Link>
                                    <Link to='fichaAtividade/lista'><FormattedMessage
                                        id='entityHome.activitySheets'/></Link>
                                    <Link to='relatorioSemestral/lista'><FormattedMessage
                                        id='entityHome.semestralReport'/></Link>
                                </HomeAccordion>
                                <Card className='text-primary mb-4'>
                                    <Card.Body>
                                        <Row>
                                            <Col className='d-flex justify-content-end py-2 col-lg-1 col-2'>
                                                <Link to='/entidades'>
                                                    <FaBuilding/>
                                                </Link>
                                            </Col>
                                            <Col className='d-flex align-items-center col-lg-10 col-8'>
                                                <Link to='/incubacao/contratos'>
                                                    <FormattedMessage id='interestExpression.contracts'/>
                                                </Link>
                                            </Col>
                                            <Col className='after-icon col-lg-1 col-2'>
                                                <FaAngleDoubleRight/>
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
        </Container>
    );
}
