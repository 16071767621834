import React, {useEffect, useState} from 'react';
import {Accordion, Col, Form, Row, Table} from 'react-bootstrap';
import {FaSearch} from 'react-icons/fa';
import {FormattedMessage, useIntl} from 'react-intl';
import {EnumSelectField} from '../bootstrap/EnumSelectField';
import {isIEFPUser} from "../../authentication/authenticationHelper";
import {EntityChangeState} from "../../models/EntityChangeState";
import {EntityChangeType} from "../../models/EntityChangeType";
import {TextAreaInputField} from "../bootstrap/TextAreaInputField";
import {SyncMultipleUploadArea} from "../SyncMultipleUploadArea";
import {DocumentType} from "../../models/DocumentType";
import {TextInputField} from "../bootstrap/TextInputField";
import {NumberInputField} from "../bootstrap/NumberInputField";
import {getDistricts} from "../../rest/administrativeDivision";
import {listQualificationLevels} from "../../rest/qualificationLevel";
import {getEntityAccreditation} from "../../rest/entity";
import {listEntityDocuments} from "../../rest/document";
import ToolTip from "../bootstrap/ToolTip";

export function EntityChangeHRData({entityChange,setError}) {


    const [qualificationLevelOptions, setQualificationLevelOptions] = useState(
        []
    );
    const [qualificationLevelList, setQualificationLevelList] = useState([]);

    const acceptedQualificationLevels = ['4', '6', '7', '8'];


    const intl = useIntl();


    useEffect(() => {
        fetchData();
    }, []);

    async function fetchData() {
        try {
            let [
                { data: qualificationLevels },
            ] = await Promise.all([
                await listQualificationLevels(),
            ]);

            qualificationLevels = qualificationLevels.filter((qualificationLevel) =>
                acceptedQualificationLevels.includes(qualificationLevel.code)
            );
            setQualificationLevelList(qualificationLevels);

            let qualificationLevelCodeList = [];
            qualificationLevels.map((qualificationLevel) =>
                qualificationLevelCodeList.push(qualificationLevel.code)
            );
            setQualificationLevelOptions(qualificationLevelCodeList);
        } catch (error) {
            setError(error);
        }
    }




    return (
        <>
            {entityChange.getList.map((get, index) => (
                <Row key={index} className={'mb-5 bg-light border p-2'}>
                    <Col>
                        <h5 className={'mb-0 text-primary'}><FormattedMessage id='eaAccreditationForm.step4.accordion.function.get'/>{' '}
                            {' - ' + get.regionalDelegationCode}

                        </h5>



                                    <>
                                        <Row>
                                            <Col>
                                                <TextInputField
                                                    labelId={'eaAccreditationForm.step1.getList.name'}
                                                    value={get.name}
                                                    disabled={true}
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <NumberInputField
                                                    labelId={'eaAccreditationForm.step1.getList.phone'}
                                                    value={get.phone}
                                                    disabled={true}
                                                />
                                            </Col>
                                            <Col>
                                                <NumberInputField
                                                    labelId={'eaAccreditationForm.step1.getList.mobile'}
                                                    value={get.mobile}
                                                    disabled={true}
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={6}>
                                                <TextInputField
                                                    labelId={'eaAccreditationForm.step1.getList.email'}
                                                    value={get.email}
                                                    disabled={true}
                                                />
                                            </Col>
                                            <Col md='6'>
                                                <EnumSelectField
                                                    options={qualificationLevelOptions}
                                                    labelId={
                                                        'eaAccreditationForm.step4.accordion.header.academicQualification'
                                                    }
                                                    preffixDescriptionId='eaAccreditationForm.qualificationLevelCode.option'
                                                    value={get.qualificationLevelCode}
                                                    disabled={true}
                                                />
                                            </Col>
                                        </Row>
                                        <Row className='mt-2'>
                                            <Col>
                                                <Form.Check
                                                    label={intl.formatMessage({
                                                        id: 'eaAccreditationForm.step4.accordion.header.managementExperience',
                                                    })}
                                                    checked={get.managementExperience === true}
                                                    disabled={true}
                                                />
                                            </Col>
                                        </Row>
                                    </>
                    </Col>
                </Row>

                ))}


            {entityChange.tgpList.map((tgp, index) => (
                <Row key={index} className={'mb-5 bg-light border p-2'}>
                    <Col>
                        <h5 className={'mb-0 text-primary'}><FormattedMessage id='eaAccreditationForm.step4.accordion.function.tgp'/>{' '}
                            {' - ' + (index + 1)}

                        </h5>
                                    <Row>
                                        <Col>
                                            <TextInputField
                                                labelId={
                                                    'eaAccreditationForm.step4.accordion.header.name'
                                                }
                                                value={tgp.name}
                                                disabled={true}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md='6'>
                                            <EnumSelectField
                                                options={qualificationLevelOptions}
                                                preffixDescriptionId='eaAccreditationForm.qualificationLevelCode.option'
                                                value={tgp.qualificationLevelCode}
                                                labelId={
                                                    'eaAccreditationForm.step4.accordion.header.academicQualification'
                                                }
                                                disabled={true}
                                            />
                                        </Col>
                                        <Col md='3'>
                                            <NumberInputField
                                                value={tgp.hre.nif}
                                                labelId={
                                                    'eaAccreditationForm.step4.accordion.header.nif'
                                                }
                                                disabled={true}
                                            />
                                        </Col>
                                        <Col md='3'>
                                            <EnumSelectField
                                                preffixDescriptionId='eaAccreditationForm.step4.accordion.header.bondType'
                                                options={['INTERN', 'EXTERN']}
                                                value={tgp.hre.bondType}
                                                name={`tgpList[${index}].hre.bondType`}
                                                labelId={
                                                    'eaAccreditationForm.step4.accordion.header.bondType'
                                                }
                                                disabled={true}
                                            />
                                        </Col>
                                    </Row>

                                    <Row className='mt-5'>
                                        <Col>
                                            <small className='text-uppercase text-primary'>
                                                <FormattedMessage
                                                    id='eaAccreditationForm.step4.accordion.header.experience'/>
                                                <ToolTip
                                                    message={
                                                        <FormattedMessage
                                                            id='eaAccreditationForm.step4.accordion.header.experience.tooltip'/>
                                                    }
                                                />
                                            </small>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md='4'>
                                            <NumberInputField
                                                className='w-25'
                                                value={tgp.hre.projectCreationCount}
                                                labelId={
                                                    'eaAccreditationForm.step4.accordion.header.projectCreation'
                                                }
                                                disabled={true}
                                            />
                                        </Col>
                                        <Col md='4'>
                                            <NumberInputField
                                                className='w-25'
                                                value={tgp.hre.analysisCount}
                                                labelId={
                                                    'eaAccreditationForm.step4.accordion.header.candidatureReview'
                                                }
                                                disabled={true}
                                            />
                                        </Col>
                                        <Col md='4'>
                                            <NumberInputField
                                                maxLength={2}
                                                className='w-25'
                                                value={tgp.hre.consultingCount}
                                                labelId={
                                                    'eaAccreditationForm.step4.accordion.header.consultancy'
                                                }
                                                disabled={true}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md='6'>
                                            <NumberInputField
                                                className='w-25'
                                                value={tgp.hre.expandSkillsCount}
                                                name={`tgpList[${index}].hre.expandSkillsCount`}
                                                labelId={
                                                    'eaAccreditationForm.step4.accordion.header.skillsExtension'
                                                }
                                                disabled={true}
                                            />
                                        </Col>
                                        <Col md='6'>
                                            <NumberInputField
                                                className='w-25'
                                                value={tgp.hre.approvedProjectFollowUpCount}
                                                labelId={
                                                    'eaAccreditationForm.step4.accordion.header.followUp'
                                                }
                                                disabled={true}
                                            />
                                        </Col>
                                    </Row>
                    </Col>
                </Row>
            ))}






            </>
    );
}
