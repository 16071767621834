export const ProcessState = {
  DRAFT: 'DRAFT',
  SUBMITTED: 'SUBMITTED',
  APPROVED: 'APPROVED',
  REJECTED: 'REJECTED',
  WITHDRAWN: 'WITHDRAWN',

  values: () => {
    return ['DRAFT', 'SUBMITTED', 'APPROVED', 'REJECTED', 'WITHDRAWN'];
  },

  finalStates: () => {
    return ['APPROVED', 'REJECTED'];
  },

  valuesExcludingRejectAndWithdrawn: () => {
    return ['DRAFT', 'SUBMITTED', 'APPROVED'];
  },
};
