import React, { useEffect, useState } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { AlertError } from '../../components/bootstrap/AlertError';
import { AddContractPriorSupportForm } from "../../components/candidature/AddContractPriorSupportForm";
import Loading from '../../components/general/Loading';
import { SubTemplate } from '../../components/general/SubTemplate';
import { DocumentType } from '../../models/DocumentType';
import {
  finalizePriorSupport,
  getCandidatureByExternalId, getLastPriorSupport
} from '../../rest/candidature';
import { handleError, isNotBusinessError } from '../../utils/handleError';
import {listDocumentsByTypeAndPriorSupport} from "../../rest/priorSupport";

export function CandidatureSubmitContractPriorSupport() {
  const { externalId } = useParams();
  const [loading, setLoading] = useState(true);
  const [priorSupport, setPriorSupport]= useState(null);
  const [error, setError] = useState(null);
  const [userDocuments, setUserDocuments] = useState([]);
  const [canSubmitContractPriorSupport, setCanSubmitContractPriorSupport] =
    useState(false);
  const [submit, setSubmit] = useState(false);

  async function fetchData() {
    try {
      const [{ data: priorSupport }] = await Promise.all([
        await getLastPriorSupport(
            externalId
        ),
      ]);
      setPriorSupport(priorSupport);


      const [{ data: userDocuments }] = await Promise.all([
        await listDocumentsByTypeAndPriorSupport(
          DocumentType.CONTRACT_PRIOR_SUPPORT,
          priorSupport.externalId
        ),
      ]);
      setUserDocuments(userDocuments);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  }
  useEffect(() => {
    checkCandidature();
  }, []);

  async function checkCandidature() {
    try {
      const [{ data: candidature }] = await Promise.all([
        await getCandidatureByExternalId(externalId),
      ]);
      if (candidature.waitingContractSubmit === true) {
        setCanSubmitContractPriorSupport(true);
        fetchData();
      } else {
        setLoading(false);
      }
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  }

  if (submit) {
    return <Navigate replace to={`/candidatura/adicionar`} />;
  }

  async function submitContract() {
    try {
      await finalizePriorSupport(externalId);
      setSubmit(true);
    } catch (error) {
      setError(error);
    }
  }

  function handleSubmit() {
    submitContract();
  }

  if (loading) {
    return <Loading />;
  }

  if (error && isNotBusinessError(error)) {
    return handleError(error);
  }

  return (
    <SubTemplate>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-8 offset-lg-2 mb-5'>
            <AlertError error={error} />
            {canSubmitContractPriorSupport && (
                <AddContractPriorSupportForm
                  onSubmit={handleSubmit}
                  userDocuments={userDocuments}
                  externalId={externalId}
                  setDocuments={setUserDocuments}
                />
            )}
          </div>
        </div>
      </div>
    </SubTemplate>
  );
}
