import { Navigate } from 'react-router-dom';
import {
  cleanAccessInfo,
  cleanUserInfo,
  getAccessInfo,
} from '../authentication/authenticationHelper';

export const handleError = (error, context) => {
  if (!error) {
    return <div></div>;
  }

  const accessInfo = getAccessInfo();

  if (error.response) {
    switch (error.response.status) {
      case 404:
        return <Navigate to='/404' replace />;
      case 401:
        if (!accessInfo) {
          return <Navigate to='/' replace />;
        } else {
          cleanAccessInfo();
          cleanUserInfo();
          return <Navigate to='/' replace />;
        }
      default:
        return <div>{error.message}</div>;
    }
  }
  return <div>{error.message}</div>;
};

export const isBusinessError = (error) => {
  return error?.response?.status === 400;
};

export const isNotBusinessError = (error) => {
  return !isBusinessError(error);
};

export const isConstraintViolationException = (error) => {
  return (
    error?.response?.status === 400 &&
    error.response.data?.exception === 'ConstraintViolationException'
  );
};
