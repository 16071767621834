import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { AlertError } from '../../components/bootstrap/AlertError';
import { ContestationAnalysisEligibilityForm } from '../../components/candidature/ContestationAnalysisEligibilityForm';
import Loading from '../../components/general/Loading';
import { SubTemplate } from '../../components/general/SubTemplate';
import { getCandidatureByExternalId, rejectContestation,  sendToOpinionReview } from '../../rest/candidature';
import { listDocumentsByTypeAndCandidatureExternalId } from '../../rest/document';
import { handleError, isNotBusinessError } from '../../utils/handleError';
import {CandidatureState} from "../../models/CandidatureState";


export function CandidatureContestationEligibility() {

  const { externalId } = useParams();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [userDocuments, setUserDocuments] = useState([]);
  const [stateContestationAnalysis, setStateContestationAnalysis] = useState(false);
  const [contestationMotive, setContestationMotive] = useState(null);

  async function fetchData() {
    try {
      const [
        { data: userDocuments }
      ] = await Promise.all([
        await listDocumentsByTypeAndCandidatureExternalId('CONTESTATION_ELIGIBILITY', externalId)
      ]);
      setUserDocuments(userDocuments);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  }

  async function checkCandidatureState() {
    try {
      const [{ data: candidature }] = await Promise.all([
        await getCandidatureByExternalId(externalId),
      ]);
      if (candidature.state ===CandidatureState.CONTESTATION_ANALYSIS_ELIGIBILITY_VALIDATION) {
        setStateContestationAnalysis(true);
        setContestationMotive(candidature.contestationMotive);
        fetchData();
      } else {
        setLoading(false);
      }
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  }

  useEffect(() => {
    checkCandidatureState();
  }, []);

  async function submitRejectContestation() {
    try {
      await rejectContestation(externalId);
      navigate('/candidatura/' + externalId);
    } catch (error) {
      setError(error);
    }
  }



  async function submitSendToOpinionReview(values) {
    let dataToSend = { ...values };
    try {
      await sendToOpinionReview(externalId, dataToSend);
      navigate('/candidatura/' + externalId);
    } catch (error) {
      setError(error);
    }
  }

  function handleRejectSubmit() {
    submitRejectContestation();
  }



  function handleSendToOpinionReview(values) {
    submitSendToOpinionReview(values);
  }

  if (loading) {
    return <Loading />;
  }

  if (error && isNotBusinessError(error)) {
    return handleError(error);
  }

  return (
    <SubTemplate  hasBackButton
      titleId='candidature.title.contestationEligibility'>
      <Container>
        <AlertError error={error} />
        {stateContestationAnalysis && <ContestationAnalysisEligibilityForm
          userDocuments={userDocuments}
          externalId={externalId}
          contestationMotive={contestationMotive}
        />}
      </Container>
    </SubTemplate>
  );
}
