import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { handleError, isNotBusinessError } from '../../../utils/handleError';
import { AlertError } from '../../../components/bootstrap/AlertError';
import { Arrays } from '../../../utils/Arrays';
import { FormattedMessage, useIntl } from 'react-intl';
import { EnumSelectField } from '../../../components/bootstrap/EnumSelectField';
import { AlertSuccess } from '../../../components/bootstrap/AlertSuccess';
import { useNavigate } from 'react-router-dom';
import { LabelToolTip } from '../../../components/bootstrap/LabelToolTip';
import { EmptyResults } from '../../../components/general/EmptyResults';
import { SubTemplate } from '../../../components/general/SubTemplate';
import Loading from '../../../components/general/Loading';
import { CustomPagination } from '../../../components/general/CustomPagination';
import { listPriorSupportPaymentRequest, priorSupportPaymentRequestFormStatus } from '../../../rest/priorSupportPaymentRequest';
import { ListFilterBar } from '../../../components/general/ListFilterBar';
import { ProcessState } from '../../../models/ProcessState';

const MAX_RESULTS = 10;
const DEFAULT_INITIAL_PAGE = 0;

export function ListPriorSupportPaymentRequest() {
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);
	const [numberOfResults, setNumberOfResults] = useState(null);
	const [totalResults, setTotalResults] = useState(null);
	const [currentPage, setCurrentPage] = useState(DEFAULT_INITIAL_PAGE);

	const [b7List, setB7List] = useState([]);

	const orderByOptionsIdPrefix = 'priorSupportPaymentRequest.list.orderOption';
	const orderByOptions = ['changeDate_desc', 'changeDate_asc'];

	const [currentOrderBy, setCurrentOrderBy] = useState(orderByOptions[0]);

	const [filters, setFilters] = useState({
		state: {
			type: 'enum',
			preffixDescriptionId: 'processState',
			options: ProcessState.values(),
			value: '-1',
			nullOption: true,
			nullOptionSuffix: 'state',
			colSpace: 3,
		},
	});

	const hoverpreffixId = 'priorSupportPaymentRequest.list.hover';

	const [statusFormB7, setStatusFormB7] = useState(null);

	const navigate = useNavigate();

	const intl = useIntl();

	const [lsItem, setLsItem] = useState(null);

	async function fetchData(fromFilter) {
		try {

			if(fromFilter === true){
				setCurrentPage(0);
			}

			let orderParams = currentOrderBy.split('_');

			let queryparams = {
				index: fromFilter ? 0 : currentPage,
				size: MAX_RESULTS,
				orderBy: orderParams[0],
				orderType: orderParams[1],
			};

			if (filters.state.value !== '-1') {
				queryparams.state = filters.state.value;
			}

			const [{ data: formList }] = await Promise.all([
				await listPriorSupportPaymentRequest(queryparams),
			]);

			setB7List(formList.results);
			setNumberOfResults(formList.numberOfResults);
			setTotalResults(formList.totalResults);
		} catch (error) {
			setError(error);
		} finally {
			setLoading(false);
		}
	}

	const onPageChange = (pageNumber) => {
		setCurrentPage(pageNumber);
	};

	function handleOrderByChange(e) {
		setCurrentOrderBy(e.target.value);
	}

	async function getB7FormStatus() {
		const { data: status } = await priorSupportPaymentRequestFormStatus();
		setStatusFormB7(status);
	}

	useEffect(() => {
		checkStorageItems();
		getB7FormStatus();
	}, []);

	useEffect(() => {
		fetchData();
	}, [currentPage]);

	if (loading) {
		return <Loading />;
	}

	if (error && isNotBusinessError(error)) {
		return handleError(error);
	}

	function redirectToFormPage(b7ExternalId) {
		navigate(
			`/pagamento/apoioPrevio/${b7ExternalId !== undefined ? b7ExternalId : ''}`
		);
	}

	function checkStorageItems() {
		if (sessionStorage.getItem('priorSupportPaymentRequestSubmited')) {
			setLsItem('priorSupportPaymentRequestSubmited');
		}
		if (sessionStorage.getItem('priorSupportPaymentRequestWithdrawed')) {
			setLsItem('priorSupportPaymentRequestWithdrawed');
		}
	}

	return (
		<SubTemplate
			titleId={'priorSupportPaymentRequest.list.title'}
			hasBackButton
		>
			<Container>
				<div className='mx-2 mb-4'>
					<AlertSuccess lsItem={lsItem} />
				</div>
				<Row>
					<Col md='10'>
						<ListFilterBar
							filters={filters}
							setFilters={setFilters}
							search={fetchData}
						/>
					</Col>
				</Row>
				<Row className='searchResults'>
					<Col md='10'>
						<AlertError error={error} />

						{Arrays.isNotEmpty(b7List) ? (
							<>
								<Row className='mt-5 mb-1 d-flex justify-content-between px-2'>
									<Col className='text-muted'>
										{totalResults}{' '}
										<FormattedMessage
											id={`list.header.requests.${
												totalResults === 1 ? 'singular' : 'plural'
											}`}
										/>
									</Col>
									<Col md='4'>
										<EnumSelectField
											preffixDescriptionId={orderByOptionsIdPrefix}
											handleChange={handleOrderByChange}
											options={orderByOptions}
											value={currentOrderBy}
										/>
									</Col>
								</Row>

								<ul className='results'>
									{b7List.map((b7, index) => (
										<li key={b7.externalId}>
											<Row
												className='py-3'
												onClick={() => redirectToFormPage(b7.externalId)}
											>
												<Col md='7' className='d-flex align-items-center'>
													<LabelToolTip
														labelId={`${hoverpreffixId}.request`}
														value={
															intl.formatMessage({
																id: 'priorSupportPaymentRequest.title',
															})
														}
													/>
												</Col>
												<Col md='2' className='d-flex align-items-center'>
													<LabelToolTip
														labelId={`${hoverpreffixId}.state`}
														value={
															<FormattedMessage
																id={`processState.${b7.processState}`}
															/>
														}
													/>
												</Col>
												<Col
													md='3'
													className='d-flex align-items-center justify-content-end'
												>
													<LabelToolTip
														labelId={`${hoverpreffixId}.changeDate`}
														value={b7.changeDate}
													/>
												</Col>
											</Row>
										</li>
									))}
								</ul>
							</>
						) : (
							<EmptyResults />
						)}
					</Col>
					{statusFormB7 !== null && statusFormB7.canFill && (
						<Col md='2'>
							<div className='mt-4 py-3'>
								<button
									className='btn btn-outline-primary d-flex align-items-center justify-content-center mb-4 w-100'
									type='button'
									onClick={() => redirectToFormPage()}
								>
									<FormattedMessage id={`annualComplementaryActivitiesPayment.button.${statusFormB7.inDraft ? 'continue' : 'create'}`} />
								</button>
							</div>
						</Col>
					)}
				</Row>
				<Row className='mb-5'>
					<Col md='10' className='d-flex'>
						<div className='mx-2'>
							<CustomPagination
								dataPerPage={MAX_RESULTS}
								totalData={totalResults}
								onPageChange={onPageChange}
								currentPage={currentPage}
							/>
						</div>
					</Col>
				</Row>
			</Container>
		</SubTemplate>
	);
}
