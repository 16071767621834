import React, {useState} from 'react';
import {Form} from 'react-bootstrap';
import {FormattedMessage, useIntl} from 'react-intl';
import {Link} from 'react-router-dom';
import {TextAreaInputField} from '../bootstrap/TextAreaInputField';
import {SyncMultipleUploadArea} from "../SyncMultipleUploadArea";
import {DocumentType} from "../../models/DocumentType";

export function AdditionalDueDiligenceAnswerForm({
                                                     additionalDueDiligence,
                                                     setAdditionalDueDiligence,
                                                     onSubmit,
                                                     candidatureExternalId
                                                 }) {
    const intl = useIntl();
    const [documents, setDocuments] = useState([]);

    const handleChangeMotive = (event) => {
        const newResponse = event.target.value;
        setAdditionalDueDiligence(prevObject => ({
            ...prevObject,
            response: newResponse,
        }));
    };

    return (
        <div className='mb-5'>
            <Form
                onSubmit={(e) => {
                    e.preventDefault();
                    onSubmit(additionalDueDiligence, documents)
                }}
            >
                <TextAreaInputField
                    controlId={'response'}
                    label={'Resposta'}
                    name={'response'}
                    value={additionalDueDiligence.response ??= ''}
                    handleChange={handleChangeMotive}
                />

                <div className="mt-3">
                    <span
                        className={"labelCompact"}>{intl.formatMessage({id: `documentType.DUE_DILIGENCE_DOCUMENT_RESPONSE`})}</span>
                    <SyncMultipleUploadArea
                        name='files'
                        documents={documents}
                        setDocuments={setDocuments}
                        documentType={DocumentType.DUE_DILIGENCE_DOCUMENT_RESPONSE}
                    />
                </div>


                <div className='form-actions'>
                    <Link to={`/candidatura/${candidatureExternalId}`}>
                        <button className='btn btn-link' type='button'>
                            <FormattedMessage id='all.backButtonText'/>
                        </button>
                    </Link>

                    <button
                        className='btn btn-primary'
                        type='submit'
                        disabled={additionalDueDiligence.response.length < 1}
                    >
                        <FormattedMessage id='all.submitButtonText'/>
                    </button>
                </div>
            </Form>
        </div>
    );
}
