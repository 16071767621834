import { Formik } from 'formik';
import React, {useEffect, useReducer} from 'react';
import { Form } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import * as yup from 'yup';
import { EconomicViabilityFormStep1 } from './EconomicViabilityFormStep1';
import { EconomicViabilityFormStep2 } from './EconomicViabilityFormStep2';
import { EconomicViabilityFormStep3 } from './EconomicViabilityFormStep3';
import { EconomicViabilityFormStep4 } from './EconomicViabilityFormStep4';
import { EconomicViabilityFormStep6 } from './EconomicViabilityFormStep6';
import { EconomicViabilityFormStep7 } from './EconomicViabilityFormStep7';
import { EconomicViabilityFormStep8 } from './EconomicViabilityFormStep8';
import {EconomicViabilityFormStep5} from "./EconomicViabilityFormStep5";
import {isValidCae} from "../../rest/candidature";
import {CandidatureState} from "../../models/CandidatureState";

export function EconomicViabilityForm({
  candidatureExternalId,
  economicViability,
  formikPropsRef,
  error,
  setError,
  handleSave,
  numberOfSteps
}) {



  return (
    <div className='mb-5'>
      <Formik
          enableReinitialize={true}
        initialValues={economicViability}
        validateOnBlur={false}
        validateOnChange={false}
        validationSchema={economicViabilityFormSchema}>
        {(formikProps) => (
          <Form>
            <fieldset disabled={economicViability.state === 'SUBMITTED' || (economicViability.candidatureState!==CandidatureState.EMIT_OPINION && economicViability.candidatureState!==CandidatureState.NEW_EMIT_OPINION )}>
              {renderStep(
                { ...formikProps },
                formikPropsRef,
                handleSave,
                error,
                setError,
                numberOfSteps,
                economicViability.step,
                candidatureExternalId
              )}
            </fieldset>
          </Form>
        )}
      </Formik>
    </div>
  );
}

function renderStep(
  props,
  formikPropsRef,
  handleSave,
  error,
  setError,
  numberOfSteps,
  currentStep,
  candidatureExternalId
) {
  props.values.step = currentStep;
  formikPropsRef.current = props;

  switch (props.values.step) {
    case 1:
      return (
        <EconomicViabilityFormStep1
          candidatureExternalId={candidatureExternalId}
          {...props}
          values={props.values}
          error={error}
          setError={setError}
          numberOfSteps={numberOfSteps}
          handleSave={handleSave}
        />
      );
    case 2:
      return (
        <EconomicViabilityFormStep2
          {...props}
          values={props.values}
          error={error}
          setError={setError}
          numberOfSteps={numberOfSteps}
          handleSave={handleSave}
        />
      );
    case 3:
      return (
        <EconomicViabilityFormStep3
          {...props}
          values={props.values}
          error={error}
          setError={setError}
          numberOfSteps={numberOfSteps}
          handleSave={handleSave}
        />
      );
    case 4:
      return (
        <EconomicViabilityFormStep4
          {...props}
          values={props.values}
          error={error}
          setError={setError}
          numberOfSteps={numberOfSteps}
          handleSave={handleSave}
        />
      );
    case 5:
      return (
          <EconomicViabilityFormStep5
              {...props}
              values={props.values}
              error={error}
              setError={setError}
              numberOfSteps={numberOfSteps}
              handleSave={handleSave}
              context={'opinion'}
          />
      );
    case 6:
      return (
        <EconomicViabilityFormStep6
          {...props}
          values={props.values}
          error={error}
          setError={setError}
          numberOfSteps={numberOfSteps}
          handleSave={handleSave}
          handleChange={props.handleChange}
        />
      );
    case 7:
      return (
        <EconomicViabilityFormStep7
          {...props}
          values={props.values}
          error={error}
          setError={setError}
          numberOfSteps={numberOfSteps}
          handleSave={handleSave}
        />
      );
    case 8:
      return (
        <EconomicViabilityFormStep8
          {...props}
          values={props.values}
          error={error}
          setError={setError}
          numberOfSteps={numberOfSteps}
          handleSave={handleSave}
        />
      );

    default:
      return (
        <EconomicViabilityFormStep1
          {...props}
          values={props.values}
          error={error}
          setError={setError}
          numberOfSteps={numberOfSteps}
          handleSave={handleSave}
        />
      );
  }
}

const economicViabilityFormSchema = yup.object().shape({
  processNumber: yup.string().required(<FormattedMessage id='errors.fieldRequiredText' />),
  legalNature: yup.string().required(<FormattedMessage id='errors.fieldRequiredText' />),
  activity: yup.string().required(<FormattedMessage id='errors.fieldRequiredText' />),
  cae: yup
      .mixed()
      .test(
          'isValidCae',
          <FormattedMessage id='errors.candidatureForm.caeNeedDigits' />,
          (value) => new RegExp(/^\d{5}$/).test(value) && typeof value !== 'undefined'
      ).test(
          'isCaeOnDb',
          <FormattedMessage id='errors.candidatureForm.caeNotOnDB' />,
          async (value) => {
            const { data: valid } = await isValidCae(value);
            return valid.flag;
          }
      ),
  contactedPromoterDetails: yup.array().of(
    yup.object().shape({
      functionDescription: yup
        .string()
        .required(<FormattedMessage id='errors.fieldRequiredText' />),
      phone: yup.string().required(<FormattedMessage id='errors.fieldRequiredText' />),
      contactType: yup
        .string()
        .test(
          'isValidContactType',
          <FormattedMessage id='errors.fieldRequiredText' />,
          (value) => typeof value === 'string' && value !== '-1'    
        )
    })
  ),

  projectDescription: yup.string().required(<FormattedMessage id='errors.fieldRequiredText' />),
  projectLocalization: yup.string().required(<FormattedMessage id='errors.fieldRequiredText' />),
  projectInstallations: yup.string().required(<FormattedMessage id='errors.fieldRequiredText' />),
  projectEquipments: yup.string().required(<FormattedMessage id='errors.fieldRequiredText' />),
  innovativeCharacter: yup.string().required(<FormattedMessage id='errors.fieldRequiredText' />),
  promotersProfileEvaluation: yup
    .string()
    .required(<FormattedMessage id='errors.fieldRequiredText' />),

  changesJustification: yup.string().required(<FormattedMessage id='errors.fieldRequiredText' />),

  earlyLoanRepayment: yup
    .mixed()
    .test(
      'isValidEarlyLoanRepayment',
      <FormattedMessage id='errors.fieldRequiredText' />,
      (value) => typeof value === 'boolean'
    ),

  earlyLoanDescription: yup.string().when("earlyLoanRepayment", {
    is: true,
    then: yup.string().required(<FormattedMessage id='errors.fieldRequiredText' />)
  }),

  deferral: yup
    .mixed()
    .test(
      'isValidDeferral',
      <FormattedMessage id='errors.fieldRequiredText' />,
      (value) => typeof value === 'boolean'
    ),

  financialDescription: yup.string().when("deferral", {
  is: true,
  then: yup.string().required(<FormattedMessage id='errors.fieldRequiredText' />)
  }),


  analysisMarkersDetails: yup.object().shape({
    consistencyAnalysis: yup.string().required(<FormattedMessage id='errors.fieldRequiredText' />)
  }),


  economicViabilityAccretionValidation: yup.object().shape({
    isSubRepresentationValid: yup
        .mixed()
        .test(
            'isValidAisSubRepresentationValid',
            <FormattedMessage id='errors.fieldRequiredText' />,
            (value) => value !== '-1' && typeof value !== 'undefined' && value!==null
        ),
    isInnovationProjectValid: yup
        .mixed()
        .test(
            'isInnovationProjectValid',
            <FormattedMessage id='errors.fieldRequiredText' />,
            (value) => value !== '-1' && typeof value !== 'undefined' && value!==null
        ),
    isLevelsValid: yup
        .mixed()
        .test(
            'isLevelsValid',
            <FormattedMessage id='errors.fieldRequiredText' />,
            (value) => value !== '-1' && typeof value !== 'undefined' && value!==null
        ),

    levelsValue:yup.number().when('isLevelsValid',{
      is: (val) => val !== undefined && val!=='-1' && val ===true && val !== null,
      then : yup.number().test(
          'isValidlevelsValue',
          <FormattedMessage id='errors.invalidValue' />,
          (value) =>  value !== '-1' &&  value!=='' && value%2.5===0,

      ).nullable()
    }).nullable(),


    isInsideValid: yup
        .mixed()
        .test(
            'isInsideValid',
            <FormattedMessage id='errors.fieldRequiredText' />,
            (value) => value !== '-1' && typeof value !== 'undefined' && value!==null
        ),
    isIefpWorkstationsValid: yup
        .mixed()
        .test(
            'isIefpWorkstationsValid',
            <FormattedMessage id='errors.fieldRequiredText' />,
            (value) => value !== '-1' && typeof value !== 'undefined' && value!==null
        ),
    iefpWorkstationsValue:yup.number().when('isIefpWorkstationsValid',{
      is: (val) => val !== undefined && val!=='-1' && val ===true && val !== null,
      then : yup.number().test(
          'iefpWorkstationsValue',
          <FormattedMessage id='errors.invalidValue' />,
          (value) =>  value !== '-1' &&  value!=='' && value%2.5===0,

      ).nullable()
    }).nullable(),

  }),

  globalOpinion: yup.string().required(<FormattedMessage id='errors.fieldRequiredText' />),
  technicianName: yup.string().required(<FormattedMessage id='errors.fieldRequiredText' />)
});
