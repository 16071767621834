import {Col, Form} from "react-bootstrap";
import {FormattedMessage} from "react-intl";
import React, {useEffect, useRef, useState} from "react";
import useIntl from "react-intl/lib/src/components/useIntl";
import {RevocationReason} from "../../models/RevocationReason";
import {Formik} from "formik";
import {TextAreaInputField} from "../bootstrap/TextAreaInputField";
import {Link, useNavigate, useParams} from "react-router-dom";
import {isEntityUser, isPromoterUser} from "../../authentication/authenticationHelper";
import * as yup from "yup";
import {SubTemplate} from "../general/SubTemplate";
import {AlertError} from "../bootstrap/AlertError";
import Row from "react-bootstrap/Row";
import {EnumSelectField} from "../bootstrap/EnumSelectField";
import {RevocationDirection} from "../../models/RevocationDirection";
import {saveRevocation} from "../../rest/revocation";
import {saveContestation} from "../../rest/candidature";
import {DecisionDialog} from "../bootstrap/DecisionDialog";
import {
    getAllowActionsFinalReportByConsultingRequest,
    getConsultingRequestActors,
    getFinalReportByConsultingRequest
} from "../../rest/consultingRequest";
import {getPriorSupportActors} from "../../rest/priorSupport";
import {getEntityGetList, getEntityTgpList} from "../../rest/entity";
import Loading from "../general/Loading";

export function AddRevocation({isPriorSupport}) {
    const [show, setShow] = useState(false);
    const [validated, setValidated] = useState(false);
    const[showRevoke,setShowRevoke] = useState(false);
    const intl = useIntl();

    const handleClose = () => setShow(false);
    const [error, setError] = useState(null);
    const reasonList = getReasons();
    const {externalId} = useParams();
    const navigate = useNavigate();
    const formikPropsRef = useRef({});
    const [actors,setActors] = useState(null);
    const [loading, setLoading] = useState(true);


    function getReasons() {
        return isEntityUser() === true ? RevocationReason.entity() : RevocationReason.values();
    }

    const schema = yup.object().shape({
        justification: yup
            .string()
            .required(<FormattedMessage id='errors.fieldRequiredText'/>),
        reason:yup
            .string()
            .test(
                'isReasonValid',
                <FormattedMessage id='errors.fieldRequiredText' />,
                (value) => value !== '-1'
            ),
    });

    const initialData = {
        justification: '',
        reason: '-1',
        consultingRequestExternalId: isPriorSupport===true ?  null : externalId,
        priorSupportExternalId: isPriorSupport===true ?  externalId : null,
    };


    useEffect(() => {
        fetchData();
    }, []);



    if (loading) {
        return <Loading/>;
    }

    async function fetchData() {
        try {
            setLoading(true)
            let [
                {data: actorsIn}
            ] = await Promise.all([
                initialData.consultingRequestExternalId
                    ? await getConsultingRequestActors(initialData.consultingRequestExternalId)
                    : await getPriorSupportActors(initialData.priorSupportExternalId)
            ]);

           setActors(actorsIn)
        } catch (error) {
            setError(error);
        } finally {
            setLoading(false);
        }
    }







console.log(actors)
    async function onSubmit(values) {
        if(formikPropsRef.current=== undefined){
            formikPropsRef.current={};
        }
        console.log(values)
        formikPropsRef.current.values = values;
        console.log(formikPropsRef);
        setShowRevoke(true)
    }

    async function finalSubmission() {
        try {
            await saveRevocation(formikPropsRef.current.values);
            sessionStorage.setItem('saveRevocation',true)
            navigate((isPriorSupport===true? '/apoioPrevio/' : '/pedidosMCE/') + externalId + '/revogacoes', {state: { refresh: true }});
        } catch (error) {
            setError(error);
        }
    }


    return (

        <SubTemplate hasBackButton={true} titleId={'revocationForm.add'}>
            <div className='container'>
                <DecisionDialog
                    show={showRevoke}
                    handleConfirm={finalSubmission}
                    handleClose={() => {
                        setShowRevoke(false)
                    }}
                    titleId='decisionDialog.revocation.ask.title'
                    bodyId= {isPromoterUser() ? 'decisionDialog.revocation.ask.body' : 'decisionDialog.revocation.ask.entity.body'}
                    bodyValue={isPromoterUser() ? actors.entity : actors.promoter}
                    needReason={false}
                />


                <div className='row'>
                    <div className='col-lg-8 mb-5 offset-lg-2'>
                        <AlertError error={error}/>
                        <div className='mb-5'>
                            <h2 className='mb-1 text-secondary'>
                                <FormattedMessage id={'revocationForm.title'}/> {isPriorSupport === true ?
                                <FormattedMessage id={'revocationForm.type.priorSupport'}/> :
                                <FormattedMessage id={'revocationForm.type.mce'}/>}     </h2>
                            <p className='mb-5 text-secondary'>
                                <FormattedMessage
                                    id={`revocationForm.subtitle`}
                                /></p>

                            <Formik
                                initialValues={initialData}
                                validateOnBlur={false}
                                validateOnChange={false}
                                validationSchema={schema}
                                onSubmit={(values, {setSubmitting, setErrors}) => {
                                    onSubmit(values, setSubmitting, setErrors);
                                }}
                            >
                                {({
                                      values,
                                      errors,
                                      touched,
                                      handleChange,
                                      handleSubmit,
                                      setFieldValue,
                                  }) => (
                                    <Form
                                        onSubmit={(e) => {
                                            e.preventDefault();
                                            handleSubmit();
                                        }}
                                    >


                                        <Row>
                                            <Col md={6}>
                                                <EnumSelectField
                                                    nullOption={true}
                                                    preffixDescriptionId='revocationReason'
                                                    labelId='revocationForm.reason'
                                                    name='reason'
                                                    options={reasonList}
                                                    value={values.reason}
                                                    handleChange={handleChange}
                                                    isInvalid={errors.reason}
                                                    errorMessage={errors.reason}
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={12}>

                                                <TextAreaInputField
                                                    controlId={'justification'}
                                                    label={'Justificação'}
                                                    name={'justification'}
                                                    handleChange={handleChange}
                                                    value={values.justification}
                                                    isInvalid={errors.justification}
                                                    errorMessage={errors.justification}
                                                />

                                            </Col>
                                        </Row>


                                        <div className='form-actions'>
                                            {isPriorSupport === true ? (
                                                <Link to={`/apoioPrevio/${externalId}`}>
                                                    <button className='btn btn-link' type='button'>
                                                        <FormattedMessage id='all.backButtonText'/>
                                                    </button>
                                                </Link>


                                            ) : (

                                                <Link to={`/pedidosMCE/${externalId}`}>
                                                    <button className='btn btn-link' type='button'>
                                                        <FormattedMessage id='all.backButtonText'/>
                                                    </button>
                                                </Link>
                                            )

                                            }
                                            <button
                                                className='btn btn-primary'
                                                type='submit'
                                                //onClick={() => handleClick(values)}
                                            >
                                                <FormattedMessage id='all.submitButtonText'/>
                                            </button>
                                        </div>


                                    </Form>
                                )}
                            </Formik>
                        </div>


                    </div>
                </div>
            </div>
        </SubTemplate>


    );
}
