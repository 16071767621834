import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { MultipleUploadArea } from '../MultipleUploadArea';
import { FaInfoCircle } from 'react-icons/fa';
import { Col, Row } from 'react-bootstrap';

export function CandidatureFormStep18({
  values,
  setFieldValue,
  candidatureDocuments,
                                          setCandidatureDocuments,
  handleSave,
  handleGoBack,
  numberOfSteps,
}) {
  const intl = useIntl();

   function handleNextStep() {
     document.body.scrollTop = 0;
     document.documentElement.scrollTop = 0;
     handleSave(true);
   }

  return (
    <div>
      <h4 className='text-primary mb-0'>
        <FormattedMessage id='candidatureForm.regroupSupportDocuments' />
      </h4>
      <small className='text-secondary text-uppercase'>
        {intl.formatMessage(
          { id: 'all.step' },
          { step: values.step, numberOfSteps: numberOfSteps }
        )}
      </small>

      <Row>
        <Col>
          <p className='mt-3 mb-0 text-secondary infoText'>
            <FaInfoCircle size={40}/>
            <FormattedMessage id='candidatureForm.step8infoText' />
          </p>
        </Col>
      </Row>

        <MultipleUploadArea
            documents={values.step18Documents}
            setFieldValue={setFieldValue}
            documentType={'CANDIDATURE_SUPPORT'}
            candidatureExternalId={values.externalId}
            candidatureFormStep={values.step}
        />


      <div className='form-actions mt-5'>
        <button
          className='btn btn-link'
          type='button'
          onClick={() => handleGoBack(values, setFieldValue)}
        >
          <FormattedMessage id='all.back' />
        </button>

        <button
          className='btn btn-primary'
          type='button'
          onClick={() => handleNextStep()}
        >
          <FormattedMessage id='all.nextStep' />
        </button>
      </div>
    </div>
  );
}
