import React, { useEffect, useState } from 'react';
import {Alert, Col, Container, Row} from 'react-bootstrap';
import { handleError, isNotBusinessError } from '../../../utils/handleError';
import { ListFilterBar } from '../../../components/annualComplementaryActivitiesPlan/ListFilterBar';
import { AlertError } from '../../../components/bootstrap/AlertError';
import { Arrays } from '../../../utils/Arrays';
import { FormattedMessage, useIntl } from 'react-intl';
import { EnumSelectField } from '../../../components/bootstrap/EnumSelectField';
import { AlertSuccess } from '../../../components/bootstrap/AlertSuccess';
import { useNavigate } from 'react-router-dom';
import { LabelToolTip } from '../../../components/bootstrap/LabelToolTip';
import { EmptyResults } from '../../../components/general/EmptyResults';
import { SubTemplate } from '../../../components/general/SubTemplate';
import Loading from '../../../components/general/Loading';
import { CustomPagination } from '../../../components/general/CustomPagination';
import { Strings } from '../../../utils/Strings';
import { additionalActivitiesPaymentRequestYearStatus, listAdditionalActivitiesPayments } from '../../../rest/additionalActivitiesPlanPayment';
import {FaInfoCircle} from "react-icons/fa";
import { isIEFPUser } from '../../../authentication/authenticationHelper';
import { ProcessState } from '../../../models/ProcessState';


const MAX_RESULTS = 10;
const DEFAULT_INITIAL_PAGE = 0;

export function ListAnnualComplementaryActivitiesPaymentRequests({ entityNif }) {
	const isIEFP = isIEFPUser();

	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);
	const [numberOfResults, setNumberOfResults] = useState(null);
	const [totalResults, setTotalResults] = useState(null);
	const [currentPage, setCurrentPage] = useState(DEFAULT_INITIAL_PAGE);

	const [b18List, setB18List] = useState([]);

	const orderByOptionsIdPrefix =
		'annualComplementaryActivitiesPayment.list.orderOption';
	const orderByOptions = ['changeDate_desc', 'changeDate_asc'];

	const [currentOrderBy, setCurrentOrderBy] = useState(orderByOptions[0]);

	const [filters, setFilters] = useState({
		year: '',
		state: '-1',
	});

	const hoverpreffixId = 'annualComplementaryActivitiesPayment.list.hover';

	const [yearStatusB18, setYearStatusB18] = useState(null);

	const navigate = useNavigate();

	const intl = useIntl();

	const [lsItem, setLsItem] = useState(null);

	async function getB18YearStatus() {
		const { data: yearStatus } = await additionalActivitiesPaymentRequestYearStatus(entityNif);
		setYearStatusB18(yearStatus);
	}

	async function fetchData(fromFilter) {
		try {
			if(fromFilter === true){
				setCurrentPage(0);
			}

			let orderParams = currentOrderBy.split('_');

			let queryparams = {
				index: fromFilter ? 0 : currentPage,
				size: MAX_RESULTS,
				orderBy: orderParams[0],
				orderType: orderParams[1],
			};

			if (filters.state !== '-1') {
				queryparams.state = filters.state;
			}

			if (!Strings.isBlank(filters.year)) {
				queryparams.year = filters.year;
			}

			if(entityNif !== undefined && entityNif !== null){
				queryparams.entityNif = entityNif;
			}

			const [{ data: formList }] = await Promise.all([
				await listAdditionalActivitiesPayments(queryparams),
			]);

			setB18List( isIEFP ? formList.results.filter(b18 => b18.processState !== ProcessState.DRAFT) : formList.results);
			setNumberOfResults(formList.numberOfResults);
			setTotalResults(formList.totalResults);
		} catch (error) {
			setError(error);
		} finally {
			setLoading(false);
		}
	}

	const onPageChange = (pageNumber) => {
		setCurrentPage(pageNumber);
	};

	function handleOrderByChange(e) {
		setCurrentOrderBy(e.target.value);
	}

	useEffect(() => {
		checkStorageItems();
		getB18YearStatus();
	}, []);

	useEffect(() => {
		fetchData();
	}, [currentPage]);

	if (loading) {
		return <Loading />;
	}

	if (error && isNotBusinessError(error)) {
		return handleError(error);
	}

	function redirectToFormPage(b18ExternalId) {
		if (isIEFP) {
			navigate(`/pedidoPagamento/atividadesComplementares/${b18ExternalId !== undefined ? b18ExternalId : ''}/entidade/${entityNif}`);
		} else {
			navigate(`/pedidoPagamento/atividadesComplementares/${b18ExternalId !== undefined ? b18ExternalId : ''}/`);
		}

	}

	function checkStorageItems() {
		if (sessionStorage.getItem('additionalActivitiesPaymentRequestSubmited')) {
			setLsItem('additionalActivitiesPaymentRequestSubmited');
		}
		if (sessionStorage.getItem('additionalActivitiesPaymentRequestWithdrawed')) {
			setLsItem('additionalActivitiesPaymentRequestWithdrawed');
		}
	}

	console.log(yearStatusB18);

	return (
		<SubTemplate
			titleId={'annualComplementaryActivitiesPayment.list.title'}
			forceClass={isIEFP && 'd-none'}
			hasBackButton={!isIEFP}
		>
			<Container>
				<div className='mx-2 mb-4'>
					<AlertSuccess lsItem={lsItem} />
				</div>
				{yearStatusB18 && !yearStatusB18.canFillB18 && (
					<Row>
						<Col>
							<Alert
								className='d-flex align-items-center mb-5'
								variant='warning'
								onClose={() => (console.log("closed the alert"))}
							>
								<div className='px-3'>
									<FaInfoCircle />
								</div>

								<div className='px-3'>
									<p className='mb-0'>
										<strong>
											<FormattedMessage id='annualComplementaryActivitiesPayment.cannotfill.title'/>
										</strong>
									</p>
									<p className='mb-0'><FormattedMessage id='annualComplementaryActivitiesPayment.cannotfill.body'/></p>
								</div>
							</Alert>
						</Col>
					</Row>
				)}
				<Row>
					<Col md={isIEFP ? '12' : '10'}>
						<ListFilterBar
							filters={filters}
							setFilters={setFilters}
							search={fetchData}
              				isB17={false}
						/>
					</Col>
				</Row>

				<Row className='searchResults'>
					<Col md={isIEFP ? '12' : '10'}>
						<AlertError error={error} />

						{Arrays.isNotEmpty(b18List) ? (
							<>
								<Row className='mt-5 mb-1 d-flex justify-content-between px-2'>
									<Col className='text-muted'>
										{totalResults}{' '}
										<FormattedMessage id={`annualComplementaryActivitiesPayment.list.header.requests.${totalResults === 1 ? 'singular' : 'plural'}`} />
									</Col>
									<Col md='4'>
										<EnumSelectField
											preffixDescriptionId={orderByOptionsIdPrefix}
											handleChange={handleOrderByChange}
											options={orderByOptions}
											value={currentOrderBy}
										/>
									</Col>
								</Row>

								<ul className='results'>
									{b18List.map((b18, index) => (
										<li key={b18.externalId}>
											<Row
												className='py-3'
												onClick={() =>
													redirectToFormPage(b18.externalId)
												}
											>
												<Col md='7' className='d-flex align-items-center'>
													<LabelToolTip
														labelId={`${hoverpreffixId}.plan`}
														value={
															intl.formatMessage({
																id: 'annualComplementaryActivitiesPayment.list.plan',
															}) + ' ' + b18.year
														}
													/>
												</Col>
												<Col md='2' className='d-flex align-items-center'>
													<LabelToolTip
														labelId={`${hoverpreffixId}.state`}
														value={
															<FormattedMessage
																id={`processState.${b18.processState}`}
															/>
														}
													/>
												</Col>
												<Col
													md='3'
													className='d-flex align-items-center justify-content-end'
												>
													<LabelToolTip
														labelId={`${hoverpreffixId}.changeDate`}
														value={b18.changeDate}
													/>
												</Col>
											</Row>
										</li>
									))}
								</ul>
							</>
						) : (
							<EmptyResults />
						)}
					</Col>
					{yearStatusB18 && yearStatusB18.canFillB18 && (
						<Col md='2' className={isIEFP && 'd-none'}>
							<div className='mt-4 py-3'>
									<button
										className='btn btn-outline-primary d-flex align-items-center justify-content-center mb-4 w-100'
										type='button'
										onClick={() => redirectToFormPage()}
									>
										<FormattedMessage
											id={`annualComplementaryActivitiesPayment.button.${
												yearStatusB18.b18InDraft ? 'continue' : 'create'
											}`}
										/>
									</button>
							</div>
						</Col>
					)}
				</Row>
				<Row className='mb-5'>
					<Col md={isIEFP ? '12' : '10'} className='d-flex'>
						<div className='mx-2'>
							<CustomPagination
								dataPerPage={MAX_RESULTS}
								totalData={totalResults}
								onPageChange={onPageChange}
								currentPage={currentPage}
							/>
						</div>
					</Col>
				</Row>
			</Container>
		</SubTemplate>
	);
}
