import { Formik } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import { Col, Container, Form, Row } from 'react-bootstrap';
import { FaInfoCircle, FaRegCheckCircle, FaRegSave } from 'react-icons/fa';
import { FormattedMessage, useIntl } from 'react-intl';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import * as yup from 'yup';
import { isIEFPUser } from '../../authentication/authenticationHelper';
import { AlertError } from '../../components/bootstrap/AlertError';
import { AlertSuccess } from '../../components/bootstrap/AlertSuccess';
import { EnumSelectField } from '../../components/bootstrap/EnumSelectField';
import { TextAreaInputField } from '../../components/bootstrap/TextAreaInputField';
import { ConfirmationAuthenticationModal } from '../../components/general/ConfirmationAuthenticationModal';
import Loading from '../../components/general/Loading';
import { PaymentRecord } from '../../components/general/PaymentRecord';
import { SubTemplate } from '../../components/general/SubTemplate';
import { SuccessToast } from '../../components/general/SuccessToast';
import { MultipleUploadArea } from '../../components/MultipleUploadArea';
import { createCustomErrorMessage } from '../../hooks/errorMessage';
import { ProcessState } from '../../models/ProcessState';
import { listAdditionalActivitiesPlanNotStarted } from '../../rest/additionalActivitiesPlan';
import {
	deleteB17StartCommunicationDocument,
	getAdditionalActivitiesPlanStartCommunication,
	getAdditionalActivitiesPlanStartCommunicationByProcessExternalId,
	listAdditionalActivitiesPlanStartCommunicationDocuments,
	uploadB17StartCommunicationDocument,
	saveAdditionalActivitiesPlanStartCommunication,
} from '../../rest/additionalActivitiesPlanStartCommunication';
import { handleError, isNotBusinessError } from '../../utils/handleError';
import { PaymentRecordFormUtils } from '../../utils/PaymentRecordFormUtils';

const validationSchema = yup.object().shape({
	b17: yup.object().shape({
		externalId: yup
			.string()
			.test(
				'isB17ExternalIdValid',
				<FormattedMessage id='errors.complementaryActivitiesStartCommunication.b17ExternalId' />,
				(value) => value !== '-1'
			),
	}),

	observations: yup
		.string()
		.required(<FormattedMessage id='errors.fieldRequiredText' />),

	paymentRecord: PaymentRecordFormUtils.validationObject(),
});

export function ComplementaryActivitiesStartCommunication() {
	const isIEFP = isIEFPUser();

	//for both iefp and entity users. In case of IEFP, its the processExternalId. Also, if it got here without the externalId, then its value is undefined
	const { externalId: externalId, entityNif: entityNif } = useParams();

	const [b17NotStartedInfo, setB17NotStartedInfo] = useState({});

	const [loading, setLoading] = useState(true);
	const [submited, setSubmited] = useState(false);

	//errors
	const [error, setError] = useState(null);

	const [formikInitialValues, setFormikInitialValues] = useState(null);

	const [showSaveToast, setShowSaveToast] = useState(false);

	//modals
	const [showSubmitModal, setShowSubmitModal] = useState(false);
	const [showPaymentRegisterModal, setShowPaymentRegisterModal] =
		useState(false);

	const [documents, setDocuments] = useState([]);

	const navigate = useNavigate();
	const intl = useIntl();
	const [lsItem, setLsItem] = useState(null);

	const formikPropsRef = useRef(null);

	const docsUploadCallback = async (formData) => {
		return await uploadB17StartCommunicationDocument(
			formikPropsRef.current.values.externalId,
			formData
		);
	};

	const docsDeleteCallback = async (documentExternalId) => {
		return await deleteB17StartCommunicationDocument(
			formikPropsRef.current.values.externalId,
			documentExternalId
		);
	};

	async function fetchData() {
		try {
			let [{ data: b17StartCommunication }] = await Promise.all([
				isIEFP && entityNif === undefined
					? await getAdditionalActivitiesPlanStartCommunicationByProcessExternalId(
							externalId
					  )
					  : await getAdditionalActivitiesPlanStartCommunication(externalId, entityNif),
			]);

			if (b17StartCommunication.processState === ProcessState.DRAFT) {
				let { data: b17NotStarted } =
					await listAdditionalActivitiesPlanNotStarted();
				setB17NotStartedInfo(b17NotStarted);
			}

			let { data: processDocuments } =
				await listAdditionalActivitiesPlanStartCommunicationDocuments(
					b17StartCommunication.externalId
				);

			let formDocuments = [];

			processDocuments.forEach((d) => {
				let doc = {
					externalId: d.externalId,
					documentType: d.documentType,
					documentPath: d.documentPath,
					name: d.name,
					content: '',
					submited: true,
					error: '',
					fileDescription: d.fileDescription,
				};

				formDocuments.push(doc);
			});
			setDocuments(formDocuments);

			setFormikInitialValues(getFormikInitialValues(b17StartCommunication));
		} catch (error) {
			setError(error);
		} finally {
			setLoading(false);
		}
	}

	useEffect(() => {
		fetchData();
	}, []);

	function getFormikInitialValues(b17StartCommunication) {
		let initialValues = b17StartCommunication;

		initialValues.observations ??= '';
		initialValues.b17 ??= {
			externalId: '-1',
			year: '-1',
		};

		if (isIEFP) {
			PaymentRecordFormUtils.getFormikInitialValuesPaymentRecord(initialValues);
		}

		return initialValues;
	}

	if (submited) {
		if (isIEFP) {
			setLsItem('paymentRecordRegistered');
			let updatedFormikInitialValues = {...formikInitialValues}
			updatedFormikInitialValues.paymentRecord = formikPropsRef.current.values.paymentRecord
			setFormikInitialValues(updatedFormikInitialValues);
			setShowPaymentRegisterModal(false);
			setSubmited(false);
		} else {
			sessionStorage.setItem(
				'complementaryActivitiesStartCommunicationDecisionSubmited',
				submited
			);
		}
		return <Navigate to='/atividadesComplementares/comunicacaoInicio/lista' />;
	}

	if (loading) {
		return <Loading />;
	}

	if (error && isNotBusinessError(error)) {
		return handleError(error);
	}

	async function handleSave() {
		try {
			let { data: res } = await saveAdditionalActivitiesPlanStartCommunication(
				formikPropsRef.current.values
			);

			let processedRes = getFormikInitialValues(res);

			formikPropsRef.current.setValues(processedRes);
			formikPropsRef.current.setErrors({});

			setShowSaveToast(true);
		} catch (error) {
			setError(error);
		}
	}

	async function handleSubmit(user, password, setModalError) {
		let values = formikPropsRef.current.values;
		const submissionValues = { ...values };

		submissionValues.credentials = {
			user: user,
			password: password,
		};

		let queryParams = {
			submit: true,
		};

		try {
			await saveAdditionalActivitiesPlanStartCommunication(
				submissionValues,
				queryParams
			);
			setSubmited(true);
		} catch (err) {
			setModalError(err);
		}
	}

	async function formValidationHandler(setShowModal) {
		if ((await validateForm()) === true) {
			setShowModal(true);
		}
	}

	async function validateForm() {
		let valid = true;
		const formikErrors = await formikPropsRef.current.validateForm();

		Object.keys(formikErrors).forEach((key) => {
			switch (key) {
				case 'b17':
					if (!isIEFP) {
						valid = false;
					}
					break;
				case 'observations':
					if (!isIEFP) {
						valid = false;
					}
					break;
				default:
					if (isIEFP) {
						valid = false;
					}
					break;
			}
		});

		if (
			!isIEFP &&
			!formikErrors.hasOwnProperty('b17') &&
			documents.length === 0
		) {
			setError(
				createCustomErrorMessage(
					intl.formatMessage({
						id: 'errors.complementaryActivitiesStartCommunication.atLeastOneDocument',
					})
				)
			);
			valid = false;
		}

		return valid;
	}

	function handleB17SelectionChange(e) {
		formikPropsRef.current.setFieldValue(
			'b17.externalId',
			e.target.value !== '-1' ? b17NotStartedInfo[e.target.value] : '-1'
		);
		formikPropsRef.current.setFieldValue('b17.year', e.target.value);
	}

	function handlePreviousStep() {
		navigate(
			isIEFP
				? '/processos'
				: '/atividadesComplementares/comunicacaoInicio/lista'
		);
	}

	function renderStep(formikProps) {
		formikPropsRef.current = formikProps;
		let formikValues = formikProps.values;
		let errors = formikProps.errors;

		const readModeUntilPayment =
			formikValues.processState !== ProcessState.DRAFT || isIEFP;

		const readModePayment =
			readModeUntilPayment &&
			formikInitialValues.paymentRecord &&
			formikInitialValues.paymentRecord.date !== null;

		return (
			<>
				<p className='mb-0 text-secondary infoText'>
					<FaInfoCircle size={30} />{' '}
					<FormattedMessage id='complementaryActivitiesStartCommunication.infoText' />
				</p>


					<fieldset disabled={readModeUntilPayment==true}>
					<Row>
						<Col md='6'>
							<EnumSelectField
								nullOption={true}
								labelId='all.year'
								options={
									readModeUntilPayment
										? [formikValues.b17.year]
										: Object.keys(b17NotStartedInfo)
								}
								value={formikValues.b17.year}
								handleChange={handleB17SelectionChange}
								isInvalid={errors.b17?.externalId}
								errorMessage={errors.b17?.externalId}
							/>
						</Col>
					</Row>
					<Row>
						<Col>
							<TextAreaInputField
								name='observations'
								value={formikValues.observations}
								labelId='priorSupportPaymentRequest.step5.observations'
								handleChange={formikProps.handleChange}
								isInvalid={errors.observations}
								errorMessage={errors.observations}
								placeholder={intl.formatMessage(
									{
										id: 'annualComplementaryActivitiesPlanForm.characters.placeholder',
									},
									{ nChars: '1000' }
								)}
							/>
						</Col>
					</Row>
					<Row>
						<Row>
							<Col>
								<MultipleUploadArea
									documents={documents}
									setDocuments={setDocuments}
									documentType='B17_START_COMMUNICATION_ANNEX'
									uploadCallback={docsUploadCallback}
									deleteCallback={docsDeleteCallback}
									readMode={readModeUntilPayment}
								/>
							</Col>
						</Row>
					</Row>
				</fieldset>

				{(isIEFP || readModePayment) && (
					<>
						<Row className='mt-4'>
							<Col>
								<small className='text-primary text-uppercase'>
									<FormattedMessage id={`all.payment`} />
								</small>
							</Col>
						</Row>
						<PaymentRecord
							errors={errors}
							formikValues={formikValues}
							handleChange={formikPropsRef.current.handleChange}
							setFieldValue={formikPropsRef.current.setFieldValue}
							readMode={readModePayment}
						/>
					</>
				)}

				<div className='form-actions mt-5'>
					<button
						className='btn btn-link'
						type='button'
						onClick={handlePreviousStep}
					>
						<FormattedMessage id='all.back' />
					</button>
				</div>
			</>
		);
	}

	return (
		<SubTemplate>
			<Container>
				<ConfirmationAuthenticationModal
					show={showSubmitModal || showPaymentRegisterModal}
					handleClose={() =>
						showSubmitModal
							? setShowSubmitModal(false)
							: setShowPaymentRegisterModal(false)
					}
					submitHandler={handleSubmit}
					idsPreffix={
						showSubmitModal
							? 'annualComplementaryActivitiesPayment.submitModal'
							: 'annualComplementaryActivitiesStartCommunication.paymentRegisterModal'
					}
					confirmButtonCompleteLabelId={
						showSubmitModal ? 'all.submitButtonText' : 'all.register'
					}
				/>
				<Row>
					<Col md={{ span: 8, offset: 2 }}>
						<h2 className='mb-1 text-secondary'>
							<FormattedMessage id='annualComplementaryActivitiesPlanStartCommunication.title' />
						</h2>
					</Col>
				</Row>

				<Row className='mb-5'>
					<Col md='2'></Col>
					<Col md='8'>
						{lsItem && <AlertSuccess lsItem={lsItem} />}
						<AlertError error={error} />
						<SuccessToast
							message={intl.formatMessage({
								id: 'annualComplementaryActivitiesPlan.saveToastInformation',
							})}
							show={showSaveToast}
							setShow={setShowSaveToast}
						/>
						<Formik
							initialValues={formikInitialValues}
							validateOnBlur={false}
							validateOnChange={false}
							validationSchema={validationSchema}
							onSubmit={handleSubmit}
						>
							{(formikProps) => (
								<Form onSubmit={formikProps.handleSubmit}>
									{renderStep(formikProps)}
								</Form>
							)}
						</Formik>
					</Col>
					<Col md='2'>
						<div>
							{!isIEFP &&
								formikInitialValues.processState === ProcessState.DRAFT && (
									<>
										<button
											className='btn btn-outline-primary d-flex align-items-center justify-content-center mb-4 w-100'
											type='button'
											onClick={() => handleSave()}
										>
											<FaRegSave />
											<FormattedMessage id='all.save' />
										</button>
										<button
											className='btn btn-primary d-flex align-items-center justify-content-center w-100'
											type='button'
											onClick={() => formValidationHandler(setShowSubmitModal)}
										>
											<FaRegCheckCircle />
											<FormattedMessage id='all.submitButtonText' />
										</button>
									</>
								)}
							{isIEFP &&
								formikInitialValues.processState === ProcessState.SUBMITTED &&
								formikInitialValues.paymentRecord.date === null && (
									<button
										className='btn btn-primary d-flex align-items-center justify-content-center w-100'
										type='button'
										onClick={() =>
											formValidationHandler(setShowPaymentRegisterModal)
										}
									>
										<FaRegCheckCircle />
										<FormattedMessage id='all.registerPayment' />
									</button>
								)}
						</div>
					</Col>
				</Row>
			</Container>
		</SubTemplate>
	);
}
