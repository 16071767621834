import {Modal} from "react-bootstrap";
import React, {useState} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {NonComplianceRestitutionForm} from "./NonComplianceRestitutionForm";

export function NonComplianceRestitutionModal({show, setShow, candidatureExternalId, nonCompliance, onSubmitCallback}) {
    const intl = useIntl();
    const [error, setError] = useState(null);
    const [entity, setEntity] = useState(null);

    async function onSubmit() {
        try {
            setShow(false);
            onSubmitCallback?.()
        } catch (error) {
            setError(error);
        }
    }

    function handleClose() {
        setShow(false);
    }

    return (
        <Modal show={show} onHide={handleClose} >
            <Modal.Header closeButton>
                <Modal.Title><FormattedMessage
                    id='candidatureTabs.oversight.tabs.noncompliance.restitution'/></Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <NonComplianceRestitutionForm
                    candidatureExternalId={candidatureExternalId}
                    onSubmitCallback={onSubmit}
                    onCancel={handleClose}
                    nonCompliance={nonCompliance}
                />
            </Modal.Body>
        </Modal>

    );
}