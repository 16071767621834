import React from 'react';
import {Accordion, Col, Row} from 'react-bootstrap';
import {FormattedMessage, useIntl} from 'react-intl';
import {ForeCastType} from "../../models/ForeCastType";
import {OtherChargeAccordionItem} from "./OtherChargeAccordionItem";

export function ForeCastBalanceAccordionItem({forecastBalanceList, yearNames}) {


    if(forecastBalanceList?.length>0){
        forecastBalanceList[0].columns = forecastBalanceList[0].type===ForeCastType.SNC ?
            forecastBalanceList[0].forecastBalanceSNCColumnList : forecastBalanceList[0].forecastBalanceNCMColumnList;
        forecastBalanceList[0].columns.forEach(column=>{
            column.totalActiveNoCurrent = column.tangibleFixedAssets + column.intangibleAssets+ column.financialInvestments + column.shareholders;
            if(forecastBalanceList[0].type===ForeCastType.SNC){
                column.totalActiveNoCurrent +=column.investmentProperties;
            }
            column.totalActiveCurrent = column.inventories+ column.clients+column.deferrals+ column.activePublicEntities + column.otherFinancialAssets+ column.cashAndBankDeposits;
            if(forecastBalanceList[0].type===ForeCastType.SNC){
                column.totalActiveCurrent +=column.activeSuppliersAdvances+ column.currentShareholders+column.otherAccountsReceivable;
            }
            column.totalActive = column.totalActiveCurrent + column.totalActiveNoCurrent;

            column.totalOwnCapital= column.paidInCapital+ column.otherEquityInstruments+ column.transitedResults+ column.otherChangesOwnCapital+column.netIncome;

            if(forecastBalanceList[0].type===ForeCastType.SNC){
                column.totalOwnCapital+=column.ownShares  + column.opinionReward + column.legalReservations + column.otherReservations + column.revaluationSurpluses;
            }else{
                column.totalOwnCapital+=column.reservations;
            }

            column.totalPassiveNoCurrent = column.provisions + column.financingObtained + column.otherBillsToPay;
            column.totalPassiveCurrent = column.providers + column.passivePublicEntities + column.passiveDeferrals;
            if(forecastBalanceList[0].type===ForeCastType.SNC){
                column.totalPassiveCurrent += column.advancesFromCustomers + column.passiveSuppliersAdvances+ column.currentFinancingObtained + column.currentOtherBillsToPay+ column.otherPassives;
            }else{
                column.totalPassiveCurrent+=column.otherCurrentPassive;
            }
            column.totalPassive =  column.totalPassiveCurrent + column.totalPassiveNoCurrent
            column.totalPassiveOwnCapital = column.totalPassive  +  column.totalOwnCapital;
        })
    }







    return (
        <>
            {(forecastBalanceList?.length>0 ) && (
                <Accordion.Item eventKey="forecastBalance">
                    <Accordion.Header><FormattedMessage
                        id='candidatureTabs.accordion.forecastBalance'/></Accordion.Header>
                    <Accordion.Body>
                        <div className='tableWithBorders'>
                            <table className='w-100 mb-5 border-top border-0'>
                                <thead>
                                <tr className='border-1 border-bottom border-end'>
                                    <th className='p-lg-1'>
                                        {forecastBalanceList[0].type===ForeCastType.SNC ? ( <FormattedMessage id= 'foreCastBalance.description.SNC'/>):(<FormattedMessage id= 'foreCastBalance.description.NCM'/>)}




                                    </th>
                                    {forecastBalanceList[0].columns.map((line,index) => (
                                        <th className='text-center' key={index}> {yearNames[line.year]} </th>
                                    ))}
                                </tr>
                                </thead>
                                <tbody>
                                <tr className='border-1 border-bottom '>
                                    <td><strong><FormattedMessage id= 'foreCastBalance.active.NoCurrent'/></strong></td>
                                    {forecastBalanceList[0].columns.map((line, index) => (
                                            <td className="text-center withBorderLeft" key={index}>{line.totalActiveNoCurrent.toFixed(2)} €</td>
                                        )
                                    )}
                                </tr>
                                <tr className='border-1 border-bottom '>
                                    <td className='px-lg-5'><FormattedMessage id= 'foreCastBalance.tangibleFixedAssets'/></td>
                                    {forecastBalanceList[0].columns.map((line, index) => (
                                            <td className="text-center withBorderLeft " key={index}>{line.tangibleFixedAssets.toFixed(2)} €</td>
                                        )
                                    )}
                                </tr>
                                {forecastBalanceList[0].type===ForeCastType.SNC &&(
                                    <tr className='border-1 border-bottom '>
                                        <td className='px-lg-5'><FormattedMessage id= 'foreCastBalance.investmentProperties'/></td>
                                        {forecastBalanceList[0].columns.map((line, index) => (
                                                <td className="text-center withBorderLeft " key={index}>{line.investmentProperties.toFixed(2)} €</td>
                                            )
                                        )}
                                    </tr>
                                )

                                }

                                <tr className='border-1 border-bottom '>
                                    <td className='px-lg-5'><FormattedMessage id= 'foreCastBalance.intangibleAssets'/></td>
                                    {forecastBalanceList[0].columns.map((line, index) => (
                                            <td className="text-center withBorderLeft " key={index}>{line.intangibleAssets.toFixed(2)} €</td>
                                        )
                                    )}
                                </tr>
                                <tr className='border-1 border-bottom '>
                                    <td className='px-lg-5'><FormattedMessage id= 'foreCastBalance.financialInvestments'/></td>
                                    {forecastBalanceList[0].columns.map((line, index) => (
                                            <td className="text-center withBorderLeft " key={index}>{line.financialInvestments.toFixed(2)} €</td>
                                        )
                                    )}
                                </tr>

                                <tr className='border-1 border-bottom '>
                                    <td className='px-lg-5'><FormattedMessage id= 'foreCastBalance.shareholders'/></td>
                                    {forecastBalanceList[0].columns.map((line, index) => (
                                            <td className="text-center withBorderLeft " key={index}>{line.shareholders.toFixed(2)} €</td>
                                        )
                                    )}
                                </tr>


                                <tr className='border-1 border-bottom '>
                                    <td><strong><FormattedMessage id= 'foreCastBalance.active.Current'/></strong></td>
                                    {forecastBalanceList[0].columns.map((line, index) => (
                                            <td className="text-center withBorderLeft" key={index}>{line.totalActiveCurrent.toFixed(2)} €</td>
                                        )
                                    )}
                                </tr>
                                <tr className='border-1 border-bottom '>
                                    <td className='px-lg-5'><FormattedMessage id= 'foreCastBalance.inventories'/></td>
                                    {forecastBalanceList[0].columns.map((line, index) => (
                                            <td className="text-center withBorderLeft " key={index}>{line.inventories.toFixed(2)} €</td>
                                        )
                                    )}
                                </tr>
                                <tr className='border-1 border-bottom '>
                                    <td className='px-lg-5'><FormattedMessage id= 'foreCastBalance.clients'/></td>
                                    {forecastBalanceList[0].columns.map((line, index) => (
                                            <td className="text-center withBorderLeft " key={index}>{line.clients.toFixed(2)} €</td>
                                        )
                                    )}
                                </tr>

                                {forecastBalanceList[0].type===ForeCastType.SNC &&(
                                    <tr className='border-1 border-bottom '>
                                        <td className='px-lg-5'><FormattedMessage id= 'foreCastBalance.activeSuppliersAdvances'/></td>
                                        {forecastBalanceList[0].columns.map((line, index) => (
                                                <td className="text-center withBorderLeft " key={index}>{line.activeSuppliersAdvances.toFixed(2)} €</td>
                                            )
                                        )}
                                    </tr>
                                )

                                }

                                <tr className='border-1 border-bottom '>
                                    <td className='px-lg-5'><FormattedMessage id= 'foreCastBalance.activePublicEntities'/></td>
                                    {forecastBalanceList[0].columns.map((line, index) => (
                                            <td className="text-center withBorderLeft " key={index}>{line.activePublicEntities.toFixed(2)} €</td>
                                        )
                                    )}
                                </tr>

                                {forecastBalanceList[0].type === ForeCastType.SNC && (
                                    <>
                                        <tr className='border-1 border-bottom '>
                                            <td className='px-lg-5'><FormattedMessage
                                                id='foreCastBalance.currentShareholders'/></td>
                                            {forecastBalanceList[0].columns.map((line, index) => (
                                                    <td className="text-center withBorderLeft "
                                                        key={index}>{line.currentShareholders.toFixed(2)} €</td>
                                                )
                                            )}
                                        </tr>
                                        <tr className='border-1 border-bottom '>
                                            <td className='px-lg-5'><FormattedMessage
                                                id='foreCastBalance.otherAccountsReceivable'/></td>
                                            {forecastBalanceList[0].columns.map((line, index) => (
                                                    <td className="text-center withBorderLeft "
                                                        key={index}>{line.otherAccountsReceivable.toFixed(2)} €</td>
                                                )
                                            )}
                                        </tr>
                                    </>
                                )

                                }

                                <tr className='border-1 border-bottom '>
                                    <td className='px-lg-5'><FormattedMessage
                                        id='foreCastBalance.deferrals'/></td>
                                    {forecastBalanceList[0].columns.map((line, index) => (
                                            <td className="text-center withBorderLeft "
                                                key={index}>{line.deferrals.toFixed(2)} €</td>
                                        )
                                    )}
                                </tr>
                                <tr className='border-1 border-bottom '>
                                    <td className='px-lg-5'><FormattedMessage
                                        id='foreCastBalance.otherFinancialAssets'/></td>
                                    {forecastBalanceList[0].columns.map((line, index) => (
                                            <td className="text-center withBorderLeft "
                                                key={index}>{line.otherFinancialAssets.toFixed(2)} €</td>
                                        )
                                    )}
                                </tr>
                                <tr className='border-1 border-bottom '>
                                    <td className='px-lg-5'><FormattedMessage
                                        id='foreCastBalance.cashAndBankDeposits'/></td>
                                    {forecastBalanceList[0].columns.map((line, index) => (
                                            <td className="text-center withBorderLeft "
                                                key={index}>{line.cashAndBankDeposits.toFixed(2)} €</td>
                                        )
                                    )}
                                </tr>
                                <tr className="border-1 border-bottom ">
                                    <td className='text-end'><strong><FormattedMessage
                                        id='foreCastBalance.active.total'/></strong></td>
                                    {forecastBalanceList[0].columns.map((line, index) => (
                                            <td className="text-center withBorderLeft "
                                                key={index}>{line.totalActive.toFixed(2)} €</td>
                                        )
                                    )}
                                </tr>
                                <tr className="border-1 border-bottom ">
                                    <td colSpan="6" className="text-primary  border-start-0"></td>
                                </tr>
                                <tr className='border-1 border-bottom  border-end'>
                                    <th className='p-lg-1'>
                                        {forecastBalanceList[0].type===ForeCastType.SNC ? ( <FormattedMessage id= 'foreCastBalance.ownCapital.SNC'/>):(<FormattedMessage id= 'foreCastBalance.ownCapital.NCM'/>)}




                                    </th>
                                    {forecastBalanceList[0].columns.map((line,index) => (
                                        <th className='text-center' key={index}> {yearNames[line.year]} </th>
                                    ))}
                                </tr>

                                <tr className="border-1 border-bottom ">
                                    <td className='px-lg-5'><FormattedMessage
                                        id='foreCastBalance.paidInCapital'/></td>
                                    {forecastBalanceList[0].columns.map((line, index) => (
                                            <td className="text-center withBorderLeft "
                                                key={index}>{line.paidInCapital.toFixed(2)} €</td>
                                        )
                                    )}
                                </tr>
                                {forecastBalanceList[0].type === ForeCastType.SNC && (

                                        <tr className='border-1 border-bottom '>
                                            <td className='px-lg-5'><FormattedMessage
                                                id='foreCastBalance.ownShares'/></td>
                                            {forecastBalanceList[0].columns.map((line, index) => (
                                                    <td className="text-center withBorderLeft "
                                                        key={index}>{line.ownShares.toFixed(2)} €</td>
                                                )
                                            )}
                                        </tr>

                                )}
                                <tr className='border-1 border-bottom '>
                                    <td className='px-lg-5'><FormattedMessage
                                        id='foreCastBalance.otherEquityInstruments'/></td>
                                    {forecastBalanceList[0].columns.map((line, index) => (
                                            <td className="text-center withBorderLeft "
                                                key={index}>{line.otherEquityInstruments.toFixed(2)} €</td>
                                        )
                                    )}
                                </tr>


                                {forecastBalanceList[0].type === ForeCastType.SNC ? (
                                    <>
                                        <tr className='border-1 border-bottom '>
                                            <td className='px-lg-5'><FormattedMessage
                                                id='foreCastBalance.opinionReward'/></td>
                                            {forecastBalanceList[0].columns.map((line, index) => (
                                                    <td className="text-center withBorderLeft "
                                                        key={index}>{line.opinionReward.toFixed(2)} €</td>
                                                )
                                            )}
                                        </tr>
                                        <tr className='border-1 border-bottom '>
                                            <td className='px-lg-5'><FormattedMessage
                                                id='foreCastBalance.legalReservations'/></td>
                                            {forecastBalanceList[0].columns.map((line, index) => (
                                                    <td className="text-center withBorderLeft "
                                                        key={index}>{line.legalReservations.toFixed(2)} €</td>
                                                )
                                            )}
                                        </tr>
                                        <tr className='border-1 border-bottom '>
                                            <td className='px-lg-5'><FormattedMessage
                                                id='foreCastBalance.otherReservations'/></td>
                                            {forecastBalanceList[0].columns.map((line, index) => (
                                                    <td className="text-center withBorderLeft "
                                                        key={index}>{line.otherReservations.toFixed(2)} €</td>
                                                )
                                            )}
                                        </tr>
                                    </>

                                ) : ( <tr className='border-1 border-bottom '>
                                    <td className='px-lg-5'><FormattedMessage
                                        id='foreCastBalance.reservations'/></td>
                                    {forecastBalanceList[0].columns.map((line, index) => (
                                            <td className="text-center withBorderLeft "
                                                key={index}>{line.reservations.toFixed(2)} €</td>
                                        )
                                    )}
                                </tr>)}
                                <tr className='border-1 border-bottom '>
                                    <td className='px-lg-5'><FormattedMessage
                                        id='foreCastBalance.transitedResults'/></td>
                                    {forecastBalanceList[0].columns.map((line, index) => (
                                            <td className="text-center withBorderLeft "
                                                key={index}>{line.transitedResults.toFixed(2)} €</td>
                                        )
                                    )}
                                </tr>
                                {forecastBalanceList[0].type === ForeCastType.SNC && (

                                    <tr className='border-1 border-bottom '>
                                        <td className='px-lg-5'><FormattedMessage
                                            id='foreCastBalance.revaluationSurpluses'/></td>
                                        {forecastBalanceList[0].columns.map((line, index) => (
                                                <td className="text-center withBorderLeft "
                                                    key={index}>{line.revaluationSurpluses.toFixed(2)} €</td>
                                            )
                                        )}
                                    </tr>

                                )}
                                <tr className='border-1 border-bottom '>
                                    <td className='px-lg-5'><FormattedMessage
                                        id='foreCastBalance.otherChangesOwnCapital'/></td>
                                    {forecastBalanceList[0].columns.map((line, index) => (
                                            <td className="text-center withBorderLeft "
                                                key={index}>{line.otherChangesOwnCapital.toFixed(2)} €</td>
                                        )
                                    )}
                                </tr>
                                <tr className='border-1 border-bottom '>
                                    <td className='px-lg-5'><FormattedMessage
                                        id='foreCastBalance.netIncome'/></td>
                                    {forecastBalanceList[0].columns.map((line, index) => (
                                            <td className="text-center withBorderLeft "
                                                key={index}>{line.netIncome.toFixed(2)} €</td>
                                        )
                                    )}
                                </tr>
                                <tr className="border-1 border-bottom ">
                                    <td className='text-end'><strong><FormattedMessage
                                        id='foreCastBalance.ownCapital.total'/></strong></td>
                                    {forecastBalanceList[0].columns.map((line, index) => (
                                            <td className="text-center withBorderLeft "
                                                key={index}>{line.totalOwnCapital.toFixed(2)} €</td>
                                        )
                                    )}
                                </tr>
                                <tr className="border-1 border-bottom ">
                                    <td colSpan="6" className="text-primary  border-start-0"></td>
                                </tr>
                                <tr className='border-1 border-bottom  border-end'>
                                    <th className='p-lg-1'>
                                        {forecastBalanceList[0].type===ForeCastType.SNC ? ( <FormattedMessage id= 'foreCastBalance.passive.SNC'/>):(<FormattedMessage id= 'foreCastBalance.passive.NCM'/>)}




                                    </th>
                                    {forecastBalanceList[0].columns.map((line,index) => (
                                        <th className='text-center' key={index}> {yearNames[line.year]} </th>
                                    ))}
                                </tr>

                                <tr className='border-1 border-bottom '>
                                    <td><strong><FormattedMessage id= 'foreCastBalance.passive.NoCurrent'/></strong></td>
                                    {forecastBalanceList[0].columns.map((line, index) => (
                                            <td className="text-center withBorderLeft" key={index}>{line.totalPassiveNoCurrent.toFixed(2)} €</td>
                                        )
                                    )}
                                </tr>



                                <tr className='border-1 border-bottom '>
                                    <td className='px-lg-5'><FormattedMessage
                                        id='foreCastBalance.provisions'/></td>
                                    {forecastBalanceList[0].columns.map((line, index) => (
                                            <td className="text-center withBorderLeft "
                                                key={index}>{line.provisions.toFixed(2)} €</td>
                                        )
                                    )}
                                </tr>

                                <tr className='border-1 border-bottom '>
                                    <td className='px-lg-5'><FormattedMessage
                                        id='foreCastBalance.financingObtained'/></td>
                                    {forecastBalanceList[0].columns.map((line, index) => (
                                            <td className="text-center withBorderLeft "
                                                key={index}>{line.financingObtained.toFixed(2)} €</td>
                                        )
                                    )}
                                </tr>


                                <tr className='border-1 border-bottom '>
                                    <td className='px-lg-5'><FormattedMessage
                                        id='foreCastBalance.otherBillsToPay'/></td>
                                    {forecastBalanceList[0].columns.map((line, index) => (
                                            <td className="text-center withBorderLeft "
                                                key={index}>{line.otherBillsToPay.toFixed(2)} €</td>
                                        )
                                    )}
                                </tr>

                                <tr className='border-1 border-bottom '>
                                    <td><strong><FormattedMessage id= 'foreCastBalance.passive.Current'/></strong></td>
                                    {forecastBalanceList[0].columns.map((line, index) => (
                                            <td className="text-center withBorderLeft" key={index}>{line.totalPassiveCurrent.toFixed(2)} €</td>
                                        )
                                    )}
                                </tr>

                                <tr className='border-1 border-bottom '>
                                    <td className='px-lg-5'><FormattedMessage
                                        id='foreCastBalance.providers'/></td>
                                    {forecastBalanceList[0].columns.map((line, index) => (
                                            <td className="text-center withBorderLeft "
                                                key={index}>{line.providers.toFixed(2)} €</td>
                                        )
                                    )}
                                </tr>

                                {forecastBalanceList[0].type === ForeCastType.SNC && (

                                    <tr className='border-1 border-bottom '>
                                        <td className='px-lg-5'><FormattedMessage
                                            id='foreCastBalance.advancesFromCustomers'/></td>
                                        {forecastBalanceList[0].columns.map((line, index) => (
                                                <td className="text-center withBorderLeft "
                                                    key={index}>{line.advancesFromCustomers.toFixed(2)} €</td>
                                            )
                                        )}
                                    </tr>

                                )}


                                <tr className='border-1 border-bottom '>
                                    <td className='px-lg-5'><FormattedMessage
                                        id='foreCastBalance.passivePublicEntities'/></td>
                                    {forecastBalanceList[0].columns.map((line, index) => (
                                            <td className="text-center withBorderLeft "
                                                key={index}>{line.passivePublicEntities.toFixed(2)} €</td>
                                        )
                                    )}
                                </tr>


                                {forecastBalanceList[0].type === ForeCastType.SNC && (
                                    <>
                                        <tr className='border-1 border-bottom '>
                                            <td className='px-lg-5'><FormattedMessage
                                                id='foreCastBalance.passiveSuppliersAdvances'/></td>
                                            {forecastBalanceList[0].columns.map((line, index) => (
                                                    <td className="text-center withBorderLeft "
                                                        key={index}>{line.passiveSuppliersAdvances.toFixed(2)} €</td>
                                                )
                                            )}
                                        </tr>
                                        <tr className='border-1 border-bottom '>
                                            <td className='px-lg-5'><FormattedMessage
                                                id='foreCastBalance.currentFinancingObtained'/></td>
                                            {forecastBalanceList[0].columns.map((line, index) => (
                                                    <td className="text-center withBorderLeft "
                                                        key={index}>{line.currentFinancingObtained.toFixed(2)} €</td>
                                                )
                                            )}
                                        </tr>
                                        </>
                                        )}

                                <tr className='border-1 border-bottom '>
                                    <td className='px-lg-5'><FormattedMessage
                                        id='foreCastBalance.passiveDeferrals'/></td>
                                    {forecastBalanceList[0].columns.map((line, index) => (
                                            <td className="text-center withBorderLeft "
                                                key={index}>{line.passiveDeferrals.toFixed(2)} €</td>
                                        )
                                    )}
                                </tr>

                                {forecastBalanceList[0].type === ForeCastType.SNC ?(
                                    <>
                                        <tr className='border-1 border-bottom '>
                                            <td className='px-lg-5'><FormattedMessage
                                                id='foreCastBalance.currentOtherBillsToPay'/></td>
                                            {forecastBalanceList[0].columns.map((line, index) => (
                                                    <td className="text-center withBorderLeft "
                                                        key={index}>{line.currentOtherBillsToPay.toFixed(2)} €</td>
                                                )
                                            )}
                                        </tr>
                                        <tr className='border-1 border-bottom '>
                                            <td className='px-lg-5'><FormattedMessage
                                                id='foreCastBalance.otherPassives'/></td>
                                            {forecastBalanceList[0].columns.map((line, index) => (
                                                    <td className="text-center withBorderLeft "
                                                        key={index}>{line.otherPassives.toFixed(2)} €</td>
                                                )
                                            )}
                                        </tr>
                                    </>
                                ):(
                                    <tr className='border-1 border-bottom '>
                                        <td className='px-lg-5'><FormattedMessage
                                            id='foreCastBalance.otherCurrentPassive'/></td>
                                        {forecastBalanceList[0].columns.map((line, index) => (
                                                <td className="text-center withBorderLeft "
                                                    key={index}>{line.otherCurrentPassive.toFixed(2)} €</td>
                                            )
                                        )}
                                    </tr>
                                )}






                                <tr className="border-1 border-bottom ">
                                    <td className='text-end'><strong><FormattedMessage
                                        id='foreCastBalance.passive.total'/></strong></td>
                                    {forecastBalanceList[0].columns.map((line, index) => (
                                            <td className="text-center withBorderLeft "
                                                key={index}>{line.totalPassive.toFixed(2)} €</td>
                                        )
                                    )}
                                </tr>
                                <tr className="border-1 border-bottom ">
                                    <td colSpan="6" className="text-primary  border-start-0"></td>
                                </tr>
                                <tr className='border-1 border-bottom  border-end'>
                                    <td className='text-end'>
                                         <strong><FormattedMessage id= 'foreCastBalance.balance.total'/></strong>
                                    </td>
                                    {forecastBalanceList[0].columns.map((line, index) => (
                                            <td className="text-center withBorderLeft "
                                                key={index}>{line.totalPassiveOwnCapital.toFixed(2)} €</td>
                                        )
                                    )}
                                </tr>


                                </tbody>





                            </table>
                        </div>


                        <Row>
                            <Col md='12'>
                                <label>
                                    <FormattedMessage id='foreCastBalance.reason'/>
                                </label>
                                <p>
                                    {forecastBalanceList[0].reasonToForecastBalance}
                                </p>
                            </Col>
                        </Row>
                    </Accordion.Body>
                </Accordion.Item>

            )}
        </>
    );
}
