import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';
import { AlertError } from '../../../components/bootstrap/AlertError';
import { AlertSuccess } from '../../../components/bootstrap/AlertSuccess';
import { EnumSelectField } from '../../../components/bootstrap/EnumSelectField';
import { LabelToolTip } from '../../../components/bootstrap/LabelToolTip';
import { CustomPagination } from '../../../components/general/CustomPagination';
import { EmptyResults } from '../../../components/general/EmptyResults';
import { ListFilterBar } from '../../../components/general/ListFilterBar';
import Loading from '../../../components/general/Loading';
import { SubTemplate } from '../../../components/general/SubTemplate';
import { ProcessState } from '../../../models/ProcessState';
import { Arrays } from '../../../utils/Arrays';
import { handleError, isNotBusinessError } from '../../../utils/handleError';
import {canCreateSemestralReport, listSemestralReports} from '../../../rest/semestralReport';
import {isEntityUser} from "../../../authentication/authenticationHelper";
import ToolTip from "../../../components/bootstrap/ToolTip";
import {formatValue} from "react-currency-input-field";
import {ConfigObject} from "../../../config";
import { FaInfoCircle } from 'react-icons/fa';

const MAX_RESULTS = 10;
const DEFAULT_INITIAL_PAGE = 0;

export function ListSemestralReport() {
	const {
		consultingRequestExternalId
	} = useParams();
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);
	const [numberOfResults, setNumberOfResults] = useState(null);
	const [totalResults, setTotalResults] = useState(null);
	const [currentPage, setCurrentPage] = useState(DEFAULT_INITIAL_PAGE);

	const [formList, setFormList] = useState([]);

	const orderByOptionsIdPrefix = 'activitySheet.list.orderOption';
	const orderByOptions = ['changeDate_desc', 'changeDate_asc'];

	const [currentOrderBy, setCurrentOrderBy] = useState(orderByOptions[0]);

	const [filters, setFilters] = useState(

	getFilters()

	);


	function getFilters() {
		let filterTo = {
				state: {
					type: 'enum',
					preffixDescriptionId: 'processState',
					options: ProcessState.valuesExcludingRejectAndWithdrawn(),
					value: '-1',
					nullOption: true,
					nullOptionSuffix: 'state',
					colSpace: 3,
				}
		};
		if(isEntityUser() && consultingRequestExternalId===undefined){
			filterTo.candidatureProcessNumber={
				type: 'text',
				value: '',
				placeholderId: 'consultingRequest.candidatureProcessId',
				colSpace: 3,
			}
		}
		return filterTo;

	}


	const hoverpreffixId = 'semestralReport.list.hover';


	const navigate = useNavigate();

	const intl = useIntl();

	const [lsItem, setLsItem] = useState(null);
	const [canCreate, setCanCreate] = useState(false);

	async function fetchData(fromFilter) {
		try {
			if (fromFilter === true) {
				setCurrentPage(0);
			}

			console.log(filters)

			let orderParams = currentOrderBy.split('_');

			let queryparams = {
				index: fromFilter ? 0 : currentPage,
				size: MAX_RESULTS,
				orderBy: orderParams[0],
				orderType: orderParams[1],
			};

			if (filters.state.value !== '-1') {
				queryparams.state = filters.state.value;
			}
			if(filters?.candidatureProcessNumber?.value!=='' && filters?.candidatureProcessNumber?.value!==undefined){
				queryparams.candidatureProcessNumber =filters.candidatureProcessNumber.value;
			}

			queryparams.consultingRequestExternalId= consultingRequestExternalId

			if(consultingRequestExternalId!== undefined){
				const [{ data: formList },{data:canCreateIn}] = await Promise.all([
					await listSemestralReports(
						queryparams
					),
					canCreateSemestralReport(consultingRequestExternalId)
				]);
				setFormList(formList.results);

				setNumberOfResults(formList.numberOfResults);
				setTotalResults(formList.totalResults);
				setCanCreate(canCreateIn.flag);

			}else{
				const [{ data: formList }] = await Promise.all([
					await listSemestralReports(
						queryparams
					)
				]);
				setFormList(formList.results);

				setNumberOfResults(formList.numberOfResults);
				setTotalResults(formList.totalResults);
			}




			checkLocalStorageItems();
		} catch (error) {
			setError(error);
		} finally {
			setLoading(false);
		}
	}


	function checkLocalStorageItems() {
		[
			'semesterReportAccept',
			'semesterReportReject',
			'semesterReportSubmited'
		].forEach((s) => {
			if (sessionStorage.getItem(s)) {
				setLsItem(s);
			}
		});
	}


	const onPageChange = (pageNumber) => {
		setCurrentPage(pageNumber);
	};

	function handleOrderByChange(e) {
		setCurrentOrderBy(e.target.value);
	}

	/* async function getFormStatus() {
		const { data: status } = await activitySheetFormStatus();
		setStatusForm(status);
	} */


	useEffect(() => {
		fetchData();
	}, [currentPage]);

	if (loading) {
		return <Loading />;
	}

	if (error && isNotBusinessError(error)) {
		return handleError(error);
	}

	function redirectToFormPage(formExternalId) {
		console.log(formExternalId)

		if(formExternalId=== undefined){
			navigate(
				`/pedidosMCE/${consultingRequestExternalId}/relatorioSemestral`
			);
		}else{
			navigate(
				`/relatorioSemestral/${formExternalId}`
			);
		}

	}

	console.log(formList);

	return (
		<SubTemplate titleId={'semestralReport.list.title'} hasBackButton>
			<Container>
				{(Arrays.isEmpty(formList) && canCreate!==true ) &&
				<p className='mb-3text-secondary infoText'>
					<FaInfoCircle size={35} /> <FormattedMessage
					id={`semestralReports.no.plan.info`}
				/>
				</p>

				}


				<div className='mx-2 mb-4'>
					<AlertSuccess lsItem={lsItem} />
				</div>


				<Row>
					<Col md='10'>
						<ListFilterBar
							filters={filters}
							setFilters={setFilters}
							search={fetchData}
						/>
					</Col>
				</Row>
				<Row className='searchResults'>
					<Col md='10'>
						<AlertError error={error} />

						{Arrays.isNotEmpty(formList) ? (
							<>
								<Row className='mt-5 mb-1 d-flex justify-content-between px-2'>
									<Col className='text-muted'>
										{totalResults}{' '}
										<FormattedMessage
											id={`list.header.requests.${
												totalResults === 1 ? 'singular' : 'plural'
											}`}
										/>
									</Col>
									<Col md='4'>
										<EnumSelectField
											preffixDescriptionId={orderByOptionsIdPrefix}
											handleChange={handleOrderByChange}
											options={orderByOptions}
											value={currentOrderBy}
										/>
									</Col>
								</Row>

								<ul className='results'>
									{formList.map((form, index) => (
										<li key={form.externalId}>
											<Row
												className='py-3'
												onClick={() => redirectToFormPage(form.externalId)}
											>
												<Col md='7' className='d-flex align-items-center'>
													<LabelToolTip
														labelId={`${hoverpreffixId}.report`}
														value={`${intl.formatMessage({
															id: 'semestralReportForm.title',
														})} - ${
															form.candidatureProcessNumber === '-1'
																? intl.formatMessage({
																		id: 'all.noCandidatureAssociated',
																  })
																: `${intl.formatMessage({
																		id: 'candidature.text',
																  })} ${form.candidatureProcessNumber}`
														}   (     ${form.mceYearType==='FIRST' ?1 :form.mceYearType==='SECOND'?2:3}${intl.formatMessage({id:'all.numberYear'}) } - ${(form.semester===true? 2 :1)}${intl.formatMessage({id:'all.numberSemester'}) })   `   }
													/>


												</Col>
												<Col md='2' className='d-flex align-items-center'>
													<LabelToolTip
														labelId={`${hoverpreffixId}.state`}
														value={
															<FormattedMessage
																id={`processState.${form.processState}`}
															/>
														}
													/>
												</Col>
												<Col
													md='3'
													className='d-flex align-items-center justify-content-end'
												>
													<LabelToolTip
														labelId={`${hoverpreffixId}.changeDate`}
														value={form.changeDate}
													/>
												</Col>
											</Row>
										</li>
									))}
								</ul>
							</>
						) : (
							<EmptyResults />
						)}
					</Col>

					{canCreate===true && (
						<Col md='2'>
						<div className='mt-3 py-3'>
							<button
								className='btn btn-outline-primary d-flex align-items-center justify-content-center mb-4 w-100'
								type='button'
								onClick={() => redirectToFormPage()}
							>
								<FormattedMessage
									id={`semestralReportForm.button.create`}
								/>
							</button>
						</div>
						</Col>
					)}


				</Row>
				<Row className='mb-5'>
					<Col md='10' className='d-flex'>
						<div className='mx-2'>
							<CustomPagination
								dataPerPage={MAX_RESULTS}
								totalData={totalResults}
								onPageChange={onPageChange}
								currentPage={currentPage}
							/>
						</div>
					</Col>
				</Row>
			</Container>
		</SubTemplate>
	);
}
