export const Month = {
    JANUARY: 'JANUARY',
    FEBRUARY: 'FEBRUARY',
    MARCH: 'MARCH',
    APRIL: 'APRIL',
    MAY: 'MAY',
    JUNE: 'JUNE',
    JULY: 'JULY',
    AUGUST: 'AUGUST',
    SEPTEMBER: 'SEPTEMBER',
    OCTOBER: 'OCTOBER',
    NOVEMBER: 'NOVEMBER',
    DECEMBER: 'DECEMBER',

    values: () => {
        return ['JANUARY', 'FEBRUARY', 'MARCH', 'APRIL', 'MAY', 'JUNE', 'JULY', 'AUGUST', 'SEPTEMBER', 'OCTOBER', 'NOVEMBER', 'DECEMBER'];
    },
};
