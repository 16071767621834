import React, { useRef, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { FaRegFileAlt } from 'react-icons/fa';
import { FormattedMessage, useIntl } from 'react-intl';
import { ConfigObject } from '../config';
import { createCustomErrorMessage } from '../hooks/errorMessage';
import {
	deleteDocument,
	deleteDocumentbyCandidatureId,
	uploadDocument,
	uploadDocumentByCandidatureId,
} from '../rest/document';
import { AuthenticatedDownloadLink } from './document/AuthenticatedDownloadLink';

export function SingleUploadArea({
	documents,
	setDocuments,
	documentType,
	setError,
	candidatureExternalId,
	nonApplicableOptionValue,
	candidatureDocuments,
	setCandidatureDocuments,
	setFieldValue,
	toolTip,
	entityRelated,
	processUploadCallback,
	processDeleteCallback,
	acceptExtensions
}) {
	const intl = useIntl();
	const ref = useRef(null);

	const [nonApplicableOption, setNonApplicableOption] = useState(
		nonApplicableOptionValue || null
	);

	const [uploadButtonDisabled, setUploadButtonDisabled] = useState(false);

	const nonApplicableChangeOnDeleteBlocked = !nonApplicableOptionValue;

	const [localFile, setLocalFile] = useState(null);

	let filteredDocument = documents.find(
		(document) => document.documentType === documentType
	);
	function openInput() {
		ref.current.click();
	}

	if (acceptExtensions === undefined) {
		acceptExtensions = ConfigObject.get().documentExtensions.split('|').map(e => '.' + e).join(',');
	}


	async function handleUpload() {
		const isFileValid = validateFile();

		if (!isFileValid) {
			return;
		}

		try {
			// Create formData
			const formData = new FormData();
			formData.append('file', ref.current.files[0]);
			formData.append('fileName', ref.current.files[0].name);
			formData.append('documentType', documentType);
			formData.append('size', ref.current.files[0].size);
			formData.append('extension', ref.current.files[0].type);

			setLocalFile(ref.current.files[0]);
			// Make the request
			const docUploadResult = candidatureExternalId
				? await uploadDocumentByCandidatureId(formData, candidatureExternalId)
				: processUploadCallback
					? await processUploadCallback(formData)
					: await uploadDocument(formData, entityRelated);
			if(docUploadResult && 'data' in docUploadResult) {
				const { data: document } = docUploadResult;
				if (setCandidatureDocuments) {
					candidatureDocuments.push(document);
					setCandidatureDocuments([...candidatureDocuments]);
				}

				let file = documents.find((file) => file.documentType === documentType);

				file.externalId = document.externalId;
				file.content = ref.current.files[0];
				file.name = document.name;
				file.documentPath = document.documentPath;
				file.submited = true;
				file.error = '';

				if (nonApplicableOptionValue) {
					file.nonApplicable = true;
				}
			}

			if (setDocuments) {
				setDocuments([...documents]);
			}

			if (setFieldValue) {
				setFieldValue('step19Documents', [...documents]);
			}

			setNonApplicableOption(false);

			ref.current.value = null;
		} catch (error) {
			setError(error);
		}
	}

	async function deleteFile() {
		let file = documents.find((file) => file.documentType === documentType);

		setLocalFile(null);
		try {
			candidatureExternalId
				? await deleteDocumentbyCandidatureId(
						file.externalId,
						candidatureExternalId
				  )
				: processDeleteCallback
				? await processDeleteCallback(file.externalId)
				: await deleteDocument(file.externalId, entityRelated);

      if (setCandidatureDocuments) {
        setCandidatureDocuments(
          candidatureDocuments.filter((cd) => cd.externalId !== file.externalId)
        );
      }


			file.externalId = '';
			file.content = null;
			file.name = '';
			file.documentPath = '';
			file.submited = false;
			file.error = '';

			if (nonApplicableOptionValue) {
				file.nonApplicable = false;
			}

			if (setDocuments) {
				setDocuments([...documents]);
			}
      if (setFieldValue) {
        setFieldValue('step19Documents', [...documents]);
      }

			if (!nonApplicableChangeOnDeleteBlocked) {
				setNonApplicableOption(true);
			}
		} catch (error) {
			setError(error);
		}
	}

	function validateFile() {
		if (ref.current.files.length === 0) {
			return false;
		}

		const documentExtensions = ConfigObject.get().documentExtensions.split('|');
		const documentsSize = ConfigObject.get().documentsSize * 1000000;

		const file = ref.current.files[0];

		let lastIndex = file.name.lastIndexOf('.');
		let extension = file.name.substring(lastIndex+1);

		if (!documentExtensions.includes(extension)) {
		  setError(
			createCustomErrorMessage(intl.formatMessage({ id: 'errors.uploadArea.fileExtension' }))
		  );
		  return false;
		}




		if (file.size > documentsSize) {
			setError(createCustomErrorMessage(intl.formatMessage({ id: 'errors.uploadArea.fileSize'},{value: ConfigObject.get().documentsSize} )));
			return false;
		}

		return true;
	}

	function handleNonApplicableChange() {
		setUploadButtonDisabled(uploadButtonDisabled ? false : true);
		let document = documents.find(
			(document) => document.documentType === documentType
		);
		document.nonApplicable = document.nonApplicable===true ? false : true;
		setDocuments([...documents]);
	}

	return (
		<Row className='bg-light mb-3 py-2' key={documentType}>
			<Col>
				<Form.Group controlId={documentType}>
					<Row className='align-items-end'>
						<Col md='9'>
							<Form.Label className='my-2'>
								<FormattedMessage id={`documentType.${documentType}`} />
								{toolTip && toolTip}
								{!filteredDocument?.submited && (
									<p>
										<small className='text-secondary'>
											<FormattedMessage id='all.documentTypesAllowed' />
											{ConfigObject.get()
												.documentExtensions.split('|')
												.join(', ')}
										</small>
									</p>
								)}
							</Form.Label>
							{nonApplicableOption && (!filteredDocument.submited || localFile !== null) && (
								<Form.Check
									type={'checkbox'}
									label={'Não aplicável'}
									onClick={() => handleNonApplicableChange()}
								/>
							)}
						</Col>
						<Col md='3'>
							<input
								type='file'
								name={documentType}
								onChange={() => handleUpload()}
								ref={ref}
								accept={acceptExtensions}
								className='d-none'
							/>
							{!(filteredDocument?.submited || localFile !== null) && !uploadButtonDisabled && (
								<div className='d-flex justify-content-end'>
									<button
										type='button'
										onClick={() => openInput()}
										className='btn btn-outline-primary'
									>
										<FormattedMessage id='all.upload' />
									</button>
								</div>
							)}
						</Col>
					</Row>

					{ (filteredDocument?.submited || localFile !== null) && (
						<div className='d-flex justify-content-between align-items-end mt-1'>
							{ filteredDocument?.submited ? (
							<div>
								<FaRegFileAlt className='text-secondary h5' />{' '}
								<AuthenticatedDownloadLink
									url={filteredDocument.documentPath}
									filename={filteredDocument.name}
								>
									{filteredDocument.name}
								</AuthenticatedDownloadLink>
							</div>
							) : (
								<>{localFile.name}</>
							)}
							<button
								className='btn btn-link p-0'
								type='button'
								onClick={() => deleteFile()}
							>
								<FormattedMessage id='all.remove' />
							</button>
						</div>
					)}
				</Form.Group>
			</Col>
		</Row>
	);
}




export function generateSingleAreaUploadDocument(documentType, document) {
	return (document != null)
		? {
			externalId: document.externalId,
			documentType: document.documentType,
			documentPath: document.documentPath,
			name: document.name,
			content: '',
			submited: true,
			error: '',
		}
		: {
			externalId: '',
			documentType: documentType,
			name: '',
			content: null,
			submited: false,
			error: '',
		};
}
export function generateSingleUploadAreaDocumentObjectList(requiredDocumentTypes, documentList) {
	const findOccurrences = (array, value) => (array.filter(item => item === value));
	return requiredDocumentTypes.flatMap(
		requiredType => (l => l.length ? l : [null])(documentList.filter( d => d.documentType === requiredType ))
			.map(d => generateSingleAreaUploadDocument(requiredType, d))
	);
}
