import React from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { maxMonthsMceContractDuration } from '../../utils/ConstantsUtils';
import { maxFixed } from '../../utils/CurrencyUtils';
import DecimalInputField from '../bootstrap/DecimalInputField';
import { NumberInputField } from '../bootstrap/NumberInputField';
import IntInputField from '../bootstrap/IntInputField';

export function MceFinancingForm({
	errors,
	handleChange,
	formikValues,
	readMode,
	pathToMceFinancingDataObject,
	hasFollowUpPlafond,
	hasConsultingPlafond,
}) {
	const intl = useIntl();

	const plafondDistributionConsultingRatio = 0.6;
	const plafondDistributionFollowUpRatio =
		1 - plafondDistributionConsultingRatio;

	function calculatePlafondDistributionConsulting() {
		if (!hasConsultingPlafond) {
			return 0;
		}
		return calculateMaxPlafond() * plafondDistributionConsultingRatio;
	}

	function calculatePlafondDistributionFollowUp() {
		if (!hasFollowUpPlafond) {
			return 0;
		}

		let contractDurationProportion =
			(getObjectPropertyGivenPath(
				formikValues,
				`${pathToMceFinancingDataObject}.contractDuration`
			) || 0) / maxMonthsMceContractDuration;

		return (
			calculateMaxPlafond() *
			plafondDistributionFollowUpRatio *
			contractDurationProportion
		);
	}

	function calculateTotalPlafond() {
		return (
			calculatePlafondDistributionConsulting() +
			calculatePlafondDistributionFollowUp()
		);
	}

	function calculateMaxPlafond() {
		return (
			getObjectPropertyGivenPath(
				formikValues,
				`${pathToMceFinancingDataObject}.ias`
			) *
			getObjectPropertyGivenPath(
				formikValues,
				`${pathToMceFinancingDataObject}.timesIas`
			)
		);
	}

	function getObjectPropertyGivenPath(initialObject, path) {
		let property = initialObject;

		for (var i = 0, path = path.split('.'), len = path.length; i < len; i++) {
			property = property[path[i]];
		}
		return property;
	}

	function getErrorGivenPath(initialObject, path) {
		let errorMsg = initialObject;

		for (var i = 0, path = path.split('.'), len = path.length; i < len; i++) {
			if (errorMsg[path[i]] !== undefined) {
				errorMsg = errorMsg[path[i]];
			} else {
				return undefined;
			}
		}
		return errorMsg;
	}

	return (
		<fieldset disabled={readMode==true}>
			<Row>
				<Col md='6'>
					<Form.Label>
						<FormattedMessage id='companyMentoringDiagnosis.plafond.value' />
					</Form.Label>
					{maxFixed(calculateTotalPlafond()) + ' €'}
				</Col>
				<Col md='6'>
					<DecimalInputField
						disabled={true}
						className='w-25'
						handleChange={handleChange}
						labelId={'companyMentoringDiagnosis.plafond.IASValue'}
						value={getObjectPropertyGivenPath(
							formikValues,
							`${pathToMceFinancingDataObject}.ias`
						)}
					/>
				</Col>
			</Row>
			<Row>
				<Col md='6'>
					<NumberInputField
						className='w-25'
						handleChange={handleChange}
						labelId={'companyMentoringDiagnosis.plafond.IASTimes'}
						value={getObjectPropertyGivenPath(
							formikValues,
							`${pathToMceFinancingDataObject}.timesIas`
						)}
						disabled={true}
					/>
				</Col>
				<Col md='6'>
					<IntInputField
						className='w-25'
						name={`${pathToMceFinancingDataObject}.contractDuration`}
						handleChange={handleChange}
						labelId={'companyMentoringDiagnosis.plafond.contractDuration'}
						value={getObjectPropertyGivenPath(
							formikValues,
							`${pathToMceFinancingDataObject}.contractDuration`
						)}
						isInvalid={getErrorGivenPath(
							errors,
							`${pathToMceFinancingDataObject}.contractDuration`
						)}
						errorMessage={getErrorGivenPath(
							errors,
							`${pathToMceFinancingDataObject}.contractDuration`
						)}
						maxSize={2}
						maxValue={maxMonthsMceContractDuration}
						disabled={true}
					/>
				</Col>
			</Row>
			<Row>
				<Col>
					<Form.Label>
						<FormattedMessage id='companyMentoringDiagnosis.plafond.plafondDistribution' />
					</Form.Label>
					<p className='mb-0'>
						<FormattedMessage id='companyMentoringDiagnosis.plafond.plafondDistribution.accompaniment' />{' '}
						{maxFixed(calculatePlafondDistributionFollowUp()) + ' €'}
					</p>
					<p>
						<FormattedMessage id='companyMentoringDiagnosis.plafond.plafondDistribution.consulting' />{' '}
						{maxFixed(calculatePlafondDistributionConsulting()) + ' €'}
					</p>
				</Col>
			</Row>
		</fieldset>
	);
}
