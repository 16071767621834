import React, { useEffect, useState } from 'react';
import { TextInputField } from '../../bootstrap/TextInputField';
import { NumberInputField } from '../../bootstrap/NumberInputField';
import {Row, Col, Form, Accordion, Table} from 'react-bootstrap';
import { FaExclamationCircle, FaInfoCircle } from 'react-icons/fa';
import { FormattedMessage, useIntl } from 'react-intl';
import { EnumSelectField } from '../../bootstrap/EnumSelectField';
import ToolTip from '../../bootstrap/ToolTip';
import {Arrays} from "../../../utils/Arrays";
import {EaHRMapping} from "./EaHRMapping";
import {EaHRMappingTotals} from "./EaHRMappingTotals";

export function EaAccreditationFormStep4({
	formStep,
	numberOfSteps,
	errors,
	formikValues,
	handleChange,
	handlePreviousStep,
	handleNextStep,
	setFieldValue,
	qualificationLevelOptions,
	managerQualificationLevelOptions,
	districts,
	readMode = false
}) {
	const intl = useIntl();
	const internalOrExternalOptions = ['INTERN', 'EXTERN'];

	const districtOptions = districts.filter((d) => formikValues.interventionDistrictCodes.includes(d.code));
	const otherDistrictOptions = districts.filter((d) => !formikValues.interventionDistrictCodes.includes(d.code));

	function addTGP() {
		let newTGP = {
			name: '',
			qualificationLevelCode: '-1',
			hre: {
				nif: '',
				bondType: '-1',
				projectCreationCount: 0,
				approvedProjectFollowUpCount: 0,
				consultingCount: 0,
				expandSkillsCount: 0,
				analysisCount: 0,
			},
			hrMapList: [],
		};
		formikValues.tgpList.push(newTGP);
		setFieldValue({ ...formikValues });
	}

	function removeTGP(index) {
		formikValues.tgpList.splice(index, 1);
		setFieldValue({ ...formikValues });
	}

	function checkBoxOnChangeHandler(index, property) {
		if (formikValues.getList[index][property]) {
			setFieldValue(
				`getList[${index}].${property}`,
				!formikValues.getList[index][property]
			);
		} else {
			setFieldValue(`getList[${index}].${property}`, true);
		}
	}


	function totalTgpListExperienceByArea(area) {
		let total = 0;
		formikValues.tgpList.forEach((tgp) => {
			total += tgp.hre[area] || 0;
		});
		return total;
	}


	const tgpHasMappings = (tgp) => Arrays.isNotEmpty(tgp.hrMapList) && tgp.hrMapList.length > 4;

	return (
		<div>
			<h4 className='text-primary mb-0'>
				<FormattedMessage id='eaAccreditationForm.step4.title' />
			</h4>
			<small className='text-secondary text-uppercase'>
				{intl.formatMessage(
					{ id: 'all.step' },
					{ step: formStep, numberOfSteps: numberOfSteps }
				)}
			</small>
			<Row>
				<Col>
					<Form.Label>
						<FormattedMessage id='eaAccreditationForm.step4.eaHumanResources' />
					</Form.Label>
				</Col>
			</Row>
			{formikValues.getList.map((get, index) => (
				<Row key={index}>
					<Col>
						<Accordion>
							<Accordion.Item eventKey='0'>
								<Accordion.Header>
									<span className='d-flex align-items-center'>
										{errors.getList?.[index]?.qualificationLevelCode && (
											<FaExclamationCircle />
										)}
										<FormattedMessage id='eaAccreditationForm.step4.accordion.function.get' />{' '}
										{' - ' + get.regionalDelegationCode}
									</span>
								</Accordion.Header>
								<Accordion.Body>
									{get.name ? (
										<>
											<Row>
												<Col>
													<TextInputField
														disabled={true}
														value={get.name}
														labelId='eaAccreditationForm.step4.accordion.header.name'
														disabled={readMode}
													/>
												</Col>
											</Row>
											<Row>
												<Col md='6'>
													<EnumSelectField
														options={managerQualificationLevelOptions}
														preffixDescriptionId='eaAccreditationForm.qualificationLevelCode.option'
														nullOption={true}
														value={get.qualificationLevelCode}
														name={`getList[${index}].qualificationLevelCode`}
														labelId={
															'eaAccreditationForm.step4.accordion.header.academicQualification'
														}
														handleChange={handleChange}
														isInvalid={
															errors.getList?.[index]?.qualificationLevelCode
														}
														errorMessage={
															errors.getList?.[index]?.qualificationLevelCode
														}
														disabled={readMode}
													/>
												</Col>
											</Row>
											<Row className='mt-2'>
												<Col>
													<Form.Check
														label={intl.formatMessage({
															id: 'eaAccreditationForm.step4.accordion.header.managementExperience',
														})}
														checked={get.managementExperience === true}
														onChange={() =>
															checkBoxOnChangeHandler(
																index,
																'managementExperience'
															)
														}
														disabled={readMode}
													/>
												</Col>
											</Row>
										</>
									) : (
										<>
											<div className='text-secondary'>
												<FaInfoCircle size='30' />
											</div>
											<p className='mt-3 text-secondary'>
												Tem de preencher no passo 1 (Identificação da EA) a
												informação do gestor técnico
											</p>
										</>
									)}
								</Accordion.Body>
							</Accordion.Item>
						</Accordion>
					</Col>
				</Row>
			))}
			{formikValues.tgpList.map((tgp, index) => (
				<Row key={index}>
					<Col>
						<Accordion>
							<Accordion.Item eventKey='0'>
								<Accordion.Header>
									<span className='d-flex align-items-center'>
										{errors.tgpList?.[index] && <FaExclamationCircle />}
										<FormattedMessage id='eaAccreditationForm.step4.accordion.function.tgp' />{' '}
										{' - ' + (index + 1)}
									</span>
								</Accordion.Header>
								<Accordion.Body>
									<Row>
										<Col>
											<TextInputField
												name={`tgpList[${index}].name`}
												labelId={
													'eaAccreditationForm.step4.accordion.header.name'
												}
												handleChange={handleChange}
												isInvalid={errors.tgpList?.[index]?.name}
												errorMessage={errors.tgpList?.[index]?.name}
												value={tgp.name}
												disabled={readMode}
											/>
										</Col>
									</Row>
									<Row>
										<Col md='6'>
											<EnumSelectField
												options={qualificationLevelOptions}
												nullOption={true}
												preffixDescriptionId='eaAccreditationForm.qualificationLevelCode.option'
												value={tgp.qualificationLevelCode}
												name={`tgpList[${index}].qualificationLevelCode`}
												labelId={
													'eaAccreditationForm.step4.accordion.header.academicQualification'
												}
												handleChange={handleChange}
												isInvalid={
													errors.tgpList?.[index]?.qualificationLevelCode
												}
												errorMessage={
													errors.tgpList?.[index]?.qualificationLevelCode
												}
												disabled={readMode}
											/>
										</Col>
										<Col md='3'>
											<NumberInputField
												value={tgp.hre.nif}
												name={`tgpList[${index}].hre.nif`}
												labelId={
													'eaAccreditationForm.step4.accordion.header.nif'
												}
												handleChange={handleChange}
												isInvalid={errors.tgpList?.[index]?.hre?.nif}
												errorMessage={errors.tgpList?.[index]?.hre?.nif}
												maxLength={9}
												disabled={readMode}
											/>
										</Col>
										<Col md='3'>
											<EnumSelectField
												preffixDescriptionId='eaAccreditationForm.step4.accordion.header.bondType'
												options={internalOrExternalOptions}
												nullOption={true}
												value={tgp.hre.bondType}
												name={`tgpList[${index}].hre.bondType`}
												labelId={
													'eaAccreditationForm.step4.accordion.header.bondType'
												}
												handleChange={handleChange}
												isInvalid={errors.tgpList?.[index]?.hre?.bondType}
												errorMessage={errors.tgpList?.[index]?.hre?.bondType}
												disabled={readMode}
											/>
										</Col>
									</Row>

									<Row className='mt-5'>
										<Col>
											<small className='text-uppercase text-primary'>
												<FormattedMessage id='eaAccreditationForm.step4.accordion.header.experience' />
												<ToolTip
													message={
														<FormattedMessage id='eaAccreditationForm.step4.accordion.header.experience.tooltip' />
													}
												/>
											</small>
										</Col>
									</Row>
									<Row>
										<Col md='4'>
											<NumberInputField
												maxLength={2}
												className='w-25'
												value={tgp.hre.projectCreationCount}
												name={`tgpList[${index}].hre.projectCreationCount`}
												labelId={
													'eaAccreditationForm.step4.accordion.header.projectCreation'
												}
												handleChange={handleChange}
												isInvalid={
													errors.tgpList?.[index]?.hre?.projectCreationCount
												}
												errorMessage={
													errors.tgpList?.[index]?.hre?.projectCreationCount
												}
												disabled={readMode}
											/>
										</Col>
										<Col md='4'>
											<NumberInputField
												maxLength={2}
												className='w-25'
												value={tgp.hre.analysisCount}
												name={`tgpList[${index}].hre.analysisCount`}
												labelId={
													'eaAccreditationForm.step4.accordion.header.candidatureReview'
												}
												handleChange={handleChange}
												isInvalid={errors.tgpList?.[index]?.hre?.analysisCount}
												errorMessage={
													errors.tgpList?.[index]?.hre?.analysisCount
												}
												disabled={readMode}
											/>
										</Col>
										<Col md='4'>
											<NumberInputField
												maxLength={2}
												className='w-25'
												value={tgp.hre.consultingCount}
												name={`tgpList[${index}].hre.consultingCount`}
												labelId={
													'eaAccreditationForm.step4.accordion.header.consultancy'
												}
												handleChange={handleChange}
												isInvalid={
													errors.tgpList?.[index]?.hre?.consultingCount
												}
												errorMessage={
													errors.tgpList?.[index]?.hre?.consultingCount
												}
												disabled={readMode}
											/>
										</Col>
									</Row>
									<Row>
										<Col md='6'>
											<NumberInputField
												maxLength={2}
												className='w-25'
												value={tgp.hre.expandSkillsCount}
												name={`tgpList[${index}].hre.expandSkillsCount`}
												labelId={
													'eaAccreditationForm.step4.accordion.header.skillsExtension'
												}
												handleChange={handleChange}
												isInvalid={
													errors.tgpList?.[index]?.hre?.expandSkillsCount
												}
												errorMessage={
													errors.tgpList?.[index]?.hre?.expandSkillsCount
												}
												disabled={readMode}
											/>
										</Col>
										<Col md='6'>
											<NumberInputField
												maxLength={2}
												className='w-25'
												value={tgp.hre.approvedProjectFollowUpCount}
												name={`tgpList[${index}].hre.approvedProjectFollowUpCount`}
												labelId={
													'eaAccreditationForm.step4.accordion.header.followUp'
												}
												handleChange={handleChange}
												isInvalid={
													errors.tgpList?.[index]?.hre
														?.approvedProjectFollowUpCount
												}
												errorMessage={
													errors.tgpList?.[index]?.hre
														?.approvedProjectFollowUpCount
												}
												disabled={readMode}
											/>
										</Col>
									</Row>

									{(!readMode || tgpHasMappings(tgp)) && (<>
									<Row className='mt-5'>
										<Col>
											<small className='text-uppercase text-primary'>
												<FormattedMessage id='eaAccreditationForm.step4.accordion.header.districtAssignment' />
											</small>
										</Col>
									</Row>
									<EaHRMapping
										tgp={tgp}
										index={index}
										errors={errors}
										districtOptions={districtOptions}
										otherDistrictOptions={otherDistrictOptions}
										districts={districts}
										setFieldValue={setFieldValue}
									/>
									</>)}



									{!readMode &&(
									<Row className='mt-4'>
										<Col>
											<button
												className='btn btn-link px-0'
												type='button'
												onClick={() => removeTGP(index)}
											>
												<FormattedMessage id='all.remove' />
											</button>
										</Col>
									</Row>
									)}
								</Accordion.Body>
							</Accordion.Item>
						</Accordion>
					</Col>
				</Row>
			))}
			{formikValues.tgpList.length > 0 && (
				<>
					<Row className='mt-5'>
						<Col>
							<FormattedMessage id='eaAccreditationForm.step4.accordion.tgpTotalExperience' />{' '}
						</Col>
					</Row>
					<Row>
						<Col md='4'>
							<Form.Label>
								<FormattedMessage id='eaAccreditationForm.step4.accordion.header.projectCreation' />
							</Form.Label>
							{totalTgpListExperienceByArea('projectCreationCount')}
						</Col>
						<Col md='4'>
							<Form.Label>
								<FormattedMessage id='eaAccreditationForm.step4.accordion.header.candidatureReview' />
							</Form.Label>
							{totalTgpListExperienceByArea('analysisCount')}
						</Col>
						<Col md='4'>
							<Form.Label>
								<FormattedMessage id='eaAccreditationForm.step4.accordion.header.consultancy' />
							</Form.Label>
							{totalTgpListExperienceByArea('consultingCount')}
						</Col>
					</Row>
					<Row>
						<Col md='6'>
							<Form.Label>
								<FormattedMessage id='eaAccreditationForm.step4.accordion.header.skillsExtension' />
							</Form.Label>
							{totalTgpListExperienceByArea('expandSkillsCount')}
						</Col>
						<Col md='6'>
							<Form.Label>
								<FormattedMessage id='eaAccreditationForm.step4.accordion.header.followUp' />
							</Form.Label>
							{totalTgpListExperienceByArea('approvedProjectFollowUpCount')}
						</Col>
					</Row>
				</>
			)}


			<Row className='mt-5'>
				<Col>
					<FormattedMessage id='eaAccreditationForm.step4.accordion.tgpTotalSkills' />{' '}
				</Col>
			</Row>
			<Row>
				<Col>
					<EaHRMappingTotals tgps={formikValues.tgpList} districtOptions={districtOptions} />
				</Col>
			</Row>

			<Row className='mt-5'>
				<Col>
					<p>
						<FormattedMessage id='eaAccreditationForm.step4.accordion.total' />{' '}
						{' ' + (formikValues.getList.length + formikValues.tgpList.length)}
					</p>
				</Col>
			</Row>
			{!readMode &&(
			<Row className='mt-4'>
				<Col>
					<button
						className='btn btn-outline-primary'
						type='button'
						onClick={addTGP}
					>
						<FormattedMessage id='eaAccreditationForm.step4.button.addTGP' />
					</button>
				</Col>
			</Row>
			)}
			<div className='form-actions mt-5'>
				<button
					className='btn btn-link'
					type='button'
					onClick={handlePreviousStep}
				>
					<FormattedMessage id='all.back' />
				</button>

				<button
					className='btn btn-primary'
					type='button'
					onClick={handleNextStep}
				>
					<FormattedMessage id='all.nextStep' />
				</button>
			</div>
		</div>
	);
}
