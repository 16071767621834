import is from 'date-fns/esm/locale/is/index.js';
import { Formik } from 'formik';
import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import * as yup from 'yup';
import { ParameterType } from '../../models/ParameterType';
import { EnumSelectField } from '../bootstrap/EnumSelectField';
import { SelectBooleanField } from '../bootstrap/SelectBooleanField';
import { TextInputField } from '../bootstrap/TextInputField';

export function ParameterForm({ parameter, onSubmit, isUpdate }) {
  const INITIAL_FORM_DATA = {
    code: '',
    name: '',
    value: '',
    type: 'STRING',
    systemOperational: true,
  };

  const schema = yup.object().shape({
    code: yup
      .string()
      .required(<FormattedMessage id='errors.fieldRequiredText' />),
    name: yup
      .string()
      .required(<FormattedMessage id='errors.fieldRequiredText' />),
    value: yup
      .string()
      .required(<FormattedMessage id='errors.fieldRequiredText' />),
  });

  const [parameterValues, setParameterValues] = useState(
    getMultipleValues(isUpdate ? parameter : INITIAL_FORM_DATA)
  );

  const handlePlusButtonClick = (e) => {
    e.preventDefault();

    let tempValues = [...parameterValues];

    if (!tempValues) {
      tempValues = [];
    }

    tempValues.push('');
    setParameterValues(tempValues);
  };

  const handleTypeChange = (e, setFieldValue) => {
    setParameterValues([]);
    setFieldValue('value', '');
    setFieldValue('type', e.target.value);
  };

  const handleChangeMultipleValues = (e, index, setFieldValue) => {
    const tempValues = [...parameterValues];

    tempValues[index] = e.target.value;

    setParameterValues(tempValues);

    const value = joinMultipleValues(tempValues);

    setFieldValue('value', value);
  };

  const isDisabled = () => {
    return isUpdate ? parameter.systemOperational : false;
  };

  return (
    <Formik
      initialValues={isUpdate ? parameter : INITIAL_FORM_DATA}
      validationSchema={schema}
      validateOnBlur={false}
      validateOnChange={true}
      onSubmit={(values, { setSubmitting }) => {
        onSubmit(values, setSubmitting);
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleSubmit,
        setFieldValue,
        isSubmitting,
      }) => (
        <form onSubmit={handleSubmit}>
          <TextInputField
            name='code'
            labelId='all.code'
            value={values.code}
            handleChange={handleChange}
            disabled={isDisabled()}
            isInvalid={touched.code && errors.code}
            errorMessage={errors.code}
          />

          <TextInputField
            name='name'
            labelId='all.name'
            value={values.name}
            handleChange={handleChange}
            isInvalid={touched.name && errors.name}
            errorMessage={errors.name}
          />

          {values.type !== 'MULTI_VALUE' ? (
            <TextInputField
              name='value'
              labelId='all.value'
              value={values.value}
              handleChange={handleChange}
              isInvalid={touched.value && errors.value}
              errorMessage={errors.value}
            />
          ) : (
            <>
              <Button className="mt-4" onClick={handlePlusButtonClick}>
                <FormattedMessage id='all.plusSymbol' /> Adicionar valor
              </Button>
              {parameterValues.map((value, index) => (
                <TextInputField
                  key={index}
                  name='value'
                  labelId='all.value'
                  value={value}
                  handleChange={(e) =>
                    handleChangeMultipleValues(e, index, setFieldValue)
                  }
                  isInvalid={touched.value && errors.value}
                  errorMessage={errors.value}
                />
              ))}
            </>
          )}

          <EnumSelectField
            name='type'
            labelId='all.type'
            preffixDescriptionId='parameterType'
            value={values.type}
            options={ParameterType.values()}
            disabled={isDisabled()}
            handleChange={(e) => handleTypeChange(e, setFieldValue)}
          />

          <SelectBooleanField
            name='systemOperational'
            labelId='parameterForm.systemOperational'
            value={values.systemOperational}
            setFieldValue={setFieldValue}
            disabled={isDisabled()}
          />

          <div className='form-actions'>
            <div className='ml-auto'>
              <Button variant='primary' type='submit' disabled={isSubmitting}>
                <FormattedMessage id='all.submitButtonText' />
              </Button>
            </div>
          </div>
        </form>
      )}
    </Formik>
  );
}

/**
 * @param  {Array<String>} arr
 */
function joinMultipleValues(arr) {
  if (arr.length === 0) {
    return '';
  }

  arr = arr.filter((value) => value.length > 0);

  return '|' + arr.join('|') + '|';
}

function getMultipleValues(parameter) {
  if (parameter.type === 'MULTI_VALUE') {
    if (parameter.value && parameter.value.length > 0) {
      if (parameter.value.startsWith('|') && parameter.value.endsWith('|')) {
        return parameter.value.slice(1, parameter.value.length - 1).split('|');
      }
      return [parameter.value];
    }
  }
  return [];
}
