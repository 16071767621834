import React from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import DocumentationTab from './DocumentationTab';
import { EmployeeSupportDetailsTab } from './EmployeeSupportDetailsTab';
import { FinancialBoardsTab } from './FinancialBoardsTab';
import { InvestmentTabTest } from './InvestmentTabTest';
import { ResumeTab } from './ResumeTab';

export function CandidatureTabs({ candidature, documents }) {
  return (
    <Tabs defaultActiveKey='resume' justify>
      <Tab
        eventKey='resume'
        title={<FormattedMessage id='candidatureTabs.resume' />}
        className='mb-4 p-3 border'>
        <ResumeTab candidature={candidature} documents={documents} />
      </Tab>

      <Tab
        eventKey='investment'
        title={<FormattedMessage id='candidatureTabs.investment' />}
        className='mb-4 p-3 border'>
        <InvestmentTabTest candidature={candidature} />
      </Tab>

      <Tab
        eventKey='workStations'
        title={<FormattedMessage id='candidatureTabs.workStations' />}
        className='mb-4 p-3 border'>
        <EmployeeSupportDetailsTab
          candidature={candidature}
        />
      </Tab>

      <Tab
        eventKey='financialBoards'
        title={<FormattedMessage id='candidatureTabs.financialBoards' />}
        className='mb-4 p-3 border'>
        <FinancialBoardsTab candidature={candidature} />
      </Tab>

      <Tab
        eventKey='documentation'
        title={<FormattedMessage id='candidatureTabs.documentation' />}
        className='mb-4 p-3 border'>
        <DocumentationTab documents={documents} />
      </Tab>
    </Tabs>
  );
}
