import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { CommercialActivitiesForm } from '../general/CommercialActivitiesForm';

export function BusinessActivityStep({
	step,
	numberOfSteps,
	errors,
	handleChange,
	formikValues,
	handleGoBack,
	handleNextStep,
	readMode
}) {
	const intl = useIntl();

	return (
		<div>
			<h4 className='text-primary mb-0'>
				<FormattedMessage id='developmentPlan.businessActivity' />
			</h4>
			<small className='text-secondary text-uppercase'>
				{intl.formatMessage(
					{ id: 'all.step' },
					{ step: step, numberOfSteps: numberOfSteps }
				)}
			</small>
			<CommercialActivitiesForm
				errors={errors}
				handleChange={handleChange}
				formikValues={formikValues}
				readMode={readMode}
			/>

			<div className='form-actions mt-5'>
				<button
					className='btn btn-link'
					type='button'
					onClick={() => handleGoBack()}
				>
					<FormattedMessage id='all.back' />
				</button>

				<button
					className='btn btn-primary'
					type='button'
					onClick={() => handleNextStep()}
				>
					<FormattedMessage id='all.nextStep' />
				</button>
			</div>
		</div>
	);
}
