import { SubTemplate } from '../../components/general/SubTemplate';
import { Container, Button, Modal } from 'react-bootstrap';
import { AlertError } from '../../components/bootstrap/AlertError';
import { useState, useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { SingleUploadArea } from '../../components/SingleUploadArea';
import { listDocumentsByTypeAndCandidatureExternalId } from '../../rest/document';
import { DocumentType } from '../../models/DocumentType';
import { getCandidatureByExternalId } from '../../rest/candidature';
import { currencyFormat } from '../../utils/CurrencyUtils';
import { CandidatureEmployeeSupportUtils, IAS } from '../../utils/CandidatureEmployeeSupportUtils';
import {CandidatureState} from "../../models/CandidatureState";

export function CandidatureValidationElegibility({ validationTitle, messageConfirmation, callbackHandler, isApprove }) {
  const intl = useIntl();

  const { externalId } = useParams();

  const navigate = useNavigate();

  const [show, setShow] = useState(false);
  const [error, setError] = useState(null);

  const [publicDocument, setPublicDocument] = useState([]);
  const [privateDocument, setPrivateDocument] = useState([]);
  const [publicDocumentType, setPublicDocumentType] = useState(DocumentType.VALIDATION_DOCUMENT);
  const [privateDocumentType, setPrivateDocumentType] = useState(DocumentType.VALIDATION_DOCUMENT_PRIVATE);


  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  async function fetchData() {
    try {

      const publicType = DocumentType.FINAL_DECISION_ELIGIBILITY;
      const privateType= DocumentType.FINAL_DECISION_ELIGIBILITY_PRIVATE;



      const [ { data: publicDocument }, { data: privateDocument }] =
        await Promise.all([
          await listDocumentsByTypeAndCandidatureExternalId(publicType, externalId),
          await listDocumentsByTypeAndCandidatureExternalId(privateType, externalId)
        ]);
      setPublicDocumentType(publicType);
      setPrivateDocumentType(privateType);

      loadDocument(publicDocument, setPublicDocument, publicType);
      loadDocument(privateDocument, setPrivateDocument, privateType);
    } catch (error) {
      setError(error);
    }
  }



  function loadDocument(data, setDocument, documentType) {
    if (data.length !== 0) {
      const document = data[0];
      setDocument([
        {
          documentType: document.documentType,
          externalId: document.externalId,
          name: document.name,
          documentPath: document.documentPath,
          content: '',
          submited: true,
          error: ''
        }
      ]);
    } else {
      setDocument([
        {
          documentType: documentType,
          externalId: '',
          name: '',
          content: null,
          submited: false,
          error: ''
        }
      ]);
    }
  }


  function canSubmit() {
    return publicDocument[0]?.submited && privateDocument[0]?.submited;
  }

  async function handleConfirm() {
    try {
      await callbackHandler();
      navigate(`/candidatura/${externalId}`);
    } catch (error) {
      setError(error);
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <SubTemplate hasBackButton>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header>
          <FormattedMessage id={`${validationTitle}`} />
        </Modal.Header>
        <Modal.Body>
          <FormattedMessage id={`${messageConfirmation}`} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant='outline-secondary' onClick={handleClose}>
            <FormattedMessage id='all.no' />
          </Button>
          <Button variant='outline-primary' onClick={handleConfirm}>
            <FormattedMessage id='all.yes' />
          </Button>
        </Modal.Footer>
      </Modal>

      <Container>
        <h2 className='mb-1 text-secondary md-10 offset-1'>
          <FormattedMessage id={`${validationTitle}`} />
        </h2>

        <AlertError error={error} />

        <Row className='md-10 offset-1 mt-4'>
          <Col md='10'>
            <small className='text-primary text-uppercase'>
              <FormattedMessage id='candidatureValidation.decisionDocuments' />
            </small>
            <SingleUploadArea
              documents={publicDocument}
              setDocuments={setPublicDocument}
              documentType={publicDocumentType}
              setError={setError}
              candidatureExternalId={externalId}
            />
            <SingleUploadArea
              documents={privateDocument}
              setDocuments={setPrivateDocument}
              documentType={privateDocumentType}
              setError={setError}
              candidatureExternalId={externalId}
            />
          </Col>
        </Row>
        <div className='form-actions'>

          <Button variant='primary' onClick={handleShow} disabled={!canSubmit()}>
            <FormattedMessage id='all.submitButtonText' />
          </Button>
        </div>
      </Container>
    </SubTemplate>
  );
}
