import {FormattedMessage, useIntl} from "react-intl";
import React, {useRef, useState} from "react";
import {ConfigObject} from "../config";
import {DocumentType} from "../models/DocumentType";
import {Col, Row} from "react-bootstrap";
import {FaRegFileAlt} from "react-icons/fa";
import {AuthenticatedDownloadLink} from "./document/AuthenticatedDownloadLink";
import {AlertError} from "./bootstrap/AlertError";
import {createCustomErrorMessage} from "../hooks/errorMessage";


export function createMultiPartWithFileAndJsonObject(file, object, objectFormParamName) {
    const formData = createMultiPart(file);
    formData.append(objectFormParamName, JSON.stringify(object));
    return formData;
}

export function createMultiPart(files) {
    const formData = new FormData();
    files.forEach( (file) => {
        formData.append('file', file.content);
        formData.append('fileName', file.content.name);
        formData.append('documentType', file.documentType);
        formData.append('size', file.content.size);
        formData.append('extension', file.content.type);
    });
    return formData;
}

export function SyncSingleUploadArea({
                                         document,
                                         setDocument,
                                         acceptExtensions,
                                         documentType
                                     }) {
    const ref = useRef(null);
    const intl = useIntl();
    const [error, setError] = useState(null);
    const [file, setFile] = useState({
        content: null,
        documentType: documentType
    });

    function removeFile() {
        setDocument(null)
    }

    function handleChange(e, field) {
        const isFileValid = validateFile();

        if (!isFileValid) {
            return;
        }

        file[field] = e.target.files[0];
        setFile({ ...file });
        setDocument({
                content: e.target.files[0],
                documentType: documentType
            }
        );
    }

    function validateFile() {
        if (ref.current.files.length === 0) {
            return false;
        }

        const documentExtensions = ConfigObject.get().documentExtensions.split('|');
        const documentsSize = ConfigObject.get().documentsSize * 1000000;

        const file = ref.current.files[0];

        let lastIndex = file.name.lastIndexOf('.');
        let extension = file.name.substring(lastIndex+1);

        if (!documentExtensions.includes(extension)) {
            setError(
                createCustomErrorMessage(intl.formatMessage({ id: 'errors.uploadArea.fileExtension' }))
            );
            return false;
        }




        if (file.size > documentsSize) {
            setError(createCustomErrorMessage(intl.formatMessage({ id: 'errors.uploadArea.fileSize'},{value: ConfigObject.get().documentsSize} )));
            return false;
        }

        return true;
    }


    function openInput() {
        ref.current.click();
    }

    if (acceptExtensions === undefined) {
        acceptExtensions = ConfigObject.get().documentExtensions.split('|').map(e => '.' + e).join(',');
    }

    return (
            <Row className='bg-light mb-3 py-2' key={documentType}>
                <Col>
                    <Row className='align-items-end my-2'>
                        <AlertError error={error} />
                        <Col md='9'>
                            <label><FormattedMessage id={`documentType.${documentType}`}/></label>
                            {!document ? (
                                    <p>
                                        <small className='text-secondary'>
                                            <FormattedMessage id='all.documentTypesAllowed'/>
                                            {ConfigObject.get()
                                                .documentExtensions.split('|')
                                                .join(', ')}
                                        </small>
                                    </p>
                                ) :
                                <div className='py-2'>
                                    <div>
                                        <FaRegFileAlt className='text-secondary h5'/>{' '}
                                        {document.content.documentPath ?
                                        <AuthenticatedDownloadLink
                                            url={document.content.documentPath}
                                            filename={document.content.name}
                                        >
                                            <small>{(document.content.fileDescription ? (document.content.fileDescription + ' : ') : '') + document.content.name}</small>
                                        </AuthenticatedDownloadLink>
                                        :
                                        <span>{document.content.name}</span> }
                                    </div>
                                </div>
                            }
                        </Col>
                        <Col md='3'>
                            {!document ? (
                                <><input
                                    type='file'
                                    name='file'
                                    accept={acceptExtensions}
                                    ref={ref}
                                    onChange={(e) => handleChange(e, 'content')}
                                    className='d-none'
                                />
                                    <div className='d-flex justify-content-end'>
                                        <button
                                            type='button'
                                            onClick={() => openInput()}
                                            className='btn btn-outline-primary'
                                        >
                                            <FormattedMessage id='all.upload'/>
                                        </button>
                                    </div>
                                </> ) : (
                                <div className='d-flex justify-content-end py-2'>
                                    <button
                                        className='btn btn-link p-0'
                                        type='button'
                                        onClick={removeFile}
                                    >
                                        <FormattedMessage id='all.remove'/>
                                    </button>
                                </div>
                            )}
                        </Col>
                    </Row>
                </Col>
            </Row>
    );
}