import React from 'react';
import { Form } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { Strings } from '../../utils/Strings';

export function NumberInputField({
	name,
	value,
	handleChange,
	labelId,
	label,
	errorMessage,
	isInvalid,
	controlId,
	disabled,
	placeholder,
	labelClass,
	maxLength,
	className,
	toolTip,
}) {
	function limitDigitsOfNumberInput(e) {
		e.target.value = e.target.value.slice(0, maxLength);
	}

  const charsNotAllowed = ['.','e','+','-',]

	const regex= /^0$|^[1-9][0-9]*$/;

	function checkInput(e){
		if(e.clipboardData.getData('text/plain').match(regex) === null){
			e.preventDefault()
			e.stopPropagation()
		}
	}

	return (
		<Form.Group controlId={controlId || name}>
			{(labelId || label) && (
				<Form.Label className={labelClass}>
					{labelId && <FormattedMessage id={labelId} />}
					{label && label}
					{toolTip && toolTip}
				</Form.Label>
			)}
			<Form.Control
				className={className}
				type='number'
				name={name}
				value={value}
				onChange={handleChange}
				isInvalid={isInvalid}
				disabled={disabled}
				placeholder={placeholder}
				maxLength={maxLength ? maxLength : null}
				onKeyDown={(e) => {
					if (charsNotAllowed.includes(e.key)) {
						e.preventDefault();
					}
				}}
				onInput={maxLength ? (e) => limitDigitsOfNumberInput(e) : null}
				onPaste={(e) => checkInput(e)}
			/>
			{errorMessage && (
				<Form.Control.Feedback type='invalid'>
					{errorMessage}
				</Form.Control.Feedback>
			)}
		</Form.Group>
	);
}
