import React from 'react';
import { FaAngleLeft } from 'react-icons/fa';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import {StateAndDateBadge} from "./stateBadge/StateAndDateBadge";

const showBackButton = (hasBackButton,internal) => {
  return internal!==true && hasBackButton;
};

export function SubTemplate({
    internal,
  hasBackButton,
  titleId,
  title,
  subTitleId,
  subTitle,
  children,
  forceRedirect,
  forceClass,
  titleValues,
  centerContent,
  stateBadgeProps
}) {
  //const history = useHistory();
  const navigate = useNavigate();

  const handleBackClick = () => {
    if (forceRedirect) {
      //history.replace(forceRedirect);
      navigate(forceRedirect, { replace: true });
    } else {
      //history.goBack();
      navigate(-1);
    }
  };

  return (
    <>
      <div className={`container page-title ${forceClass ? forceClass : ''}`}>
        <div className={'row'}>
          <div className={centerContent ? 'offset-md-2 col-md-8' : 'col-md-12'}>
            {showBackButton(hasBackButton,internal) && (
              <button className={'back-button btn-link'} onClick={handleBackClick}>
                <FaAngleLeft />
              </button>
            )}

            <h2 className='mb-1 text-secondary'>
              {titleId ? (
                <FormattedMessage id={titleId} values={titleValues} />
              ) : (
                <span>{title}</span>
              )}
            </h2>
            <p className='mb-3 text-secondary'>
              {subTitleId && <FormattedMessage id={subTitleId} />}
              {subTitle && subTitle}
              {stateBadgeProps != null && StateAndDateBadge(stateBadgeProps)}
            </p>
          </div>
        </div>
      </div>
      <div className='pb-5'>{children}</div>
    </>
  );
}
