import {AccreditationDecisionState} from "../../models/AccreditationDecisionState";
import React from "react";
import {EAAValidation} from "./results/EAAValidation";
import {EAAAppeal} from "./results/EAAAppeal";
import {EAADecision} from "./results/EAADecision";

export function EAAResults({accreditationDecision}) {
    if(accreditationDecision == null || accreditationDecision?.state === AccreditationDecisionState.SUBMITTED) return(null);
    const hasValidation = accreditationDecision?.validationDate != null && accreditationDecision?.state !== AccreditationDecisionState.SUBMITTED;
    const hasAppeal = accreditationDecision?.appealDate != null && accreditationDecision?.state !== AccreditationDecisionState.SUBMITTED;
    const hasResult = accreditationDecision?.decisionDate != null && accreditationDecision?.state !== AccreditationDecisionState.SUBMITTED;
    return(
        <>
            {hasValidation && <EAAValidation accreditationDecision={accreditationDecision}/>}
            {hasAppeal && <EAAAppeal accreditationDecision={accreditationDecision}/>}
            {hasResult && <EAADecision accreditationDecision={accreditationDecision}/>}
        </>
    )
}