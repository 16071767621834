import React, { useEffect, useState } from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { DecimalInputField } from '../../bootstrap/DecimalInputField';
import {currencyFormat, maxFixed} from '../../../utils/CurrencyUtils';
import { NumberInputField } from '../../bootstrap/NumberInputField';
import { TextAreaInputField } from '../../bootstrap/TextAreaInputField';
import { TextInputField } from '../../bootstrap/TextInputField';
import {DateTimeInputField} from "../../bootstrap/DateTimeInputField";
import {SingleUploadArea} from "../../SingleUploadArea";
import {AuthenticatedDownloadLink} from "../../document/AuthenticatedDownloadLink";

export function SemestralPaymentRequestFormStep5({
	formStep,
	numberOfSteps,
 	consultingRequestExternalId,
	errors,
	handleChange,
	formikValues,
	handlePreviousStep,
	formText,
    documents,
    setDocuments,
 	setError,
 	readMode = false,
	uploadDocument,
	deleteDocument,
 	setFieldValue
}) {

	const intl = useIntl();

	function getFormTextPrefix() {
		//returns translation prefix

		if (formText == 'AddSemestralPaymentRequest') {
			return 'semestralPaymentRequestForm';
		} else if (formText == 'AddFinalPaymentRequest') {
			return 'finalPaymentRequestForm';
		} else {
			//shouldn't exit this way
			return 'kkk';
		}
	}

	const uploadCallback = async (formData) => {
		return await uploadDocument(consultingRequestExternalId, formData);
	};

	const deleteCallback = async (documentExternalId) => {
		return await deleteDocument(consultingRequestExternalId, documentExternalId);
	};

	function totalPayments() {
		let sum = 0;
		formikValues.accumulatedPayments.map((payment) => (sum += payment));
		return maxFixed(sum);
	}

	function totalProposalPayment() {
		let sum = 0;
		let bimProp = parseFloat(formikValues.bimestralProposalPayment) || 0;
		let skiProp = parseFloat(formikValues.skillsExtensionProposalPayment) || 0;

		sum = maxFixed(bimProp + skiProp);
		return sum;
	}

	function refreshBimestralFollowUp(e, name) {
		formikValues[name] = e.target.value;
		setFieldValue({...formikValues});
		formikValues.bimestralProposalPayment = ((formikValues.numberOfReportsProducedThisSemester === undefined || formikValues.numberOfReportsProducedThisSemester === '')
			? 0 : parseInt(formikValues.numberOfReportsProducedThisSemester)) * ((formikValues.byReportValue === undefined
			|| formikValues.byReportValue === '') ? 0 : parseFloat(formikValues.byReportValue))
		setFieldValue({...formikValues});

	}

	function refreshSkillsExtension(e, name) {
		formikValues[name] = e.target.value;
		setFieldValue({...formikValues});
		formikValues.skillsExtensionProposalPayment = ((formikValues.numberOfHoursSkillsExtension === undefined || formikValues.numberOfHoursSkillsExtension === '')
			? 0 : parseInt(formikValues.numberOfHoursSkillsExtension)) * ((formikValues.byHourValue === undefined
			|| formikValues.byHourValue === '') ? 0 : parseFloat(formikValues.byHourValue))
		setFieldValue({...formikValues});

	}




	return (
		<div>
			<h4 className='text-primary mb-0'>
				<FormattedMessage id='semestralPaymentRequestForm.sideNavBar.technicalAnalysis' />
			</h4>
			<small className='text-secondary text-uppercase'>
				{intl.formatMessage(
					{ id: 'all.step' },
					{ step: formStep, numberOfSteps: numberOfSteps }
				)}
			</small>
			<Row>
				<Col md='6'>
					<Form.Label>
						<FormattedMessage id='semestralPaymentRequestForm.verificationOfEAPlafond' />
					</Form.Label>
					<p>{currencyFormat(formikValues.plafond || 0)}</p>
				</Col>
				<Col md='6'>
					<Form.Label>
						<FormattedMessage id='semestralPaymentRequestForm.IASAtTheContractCelebration' />
					</Form.Label>
					<p>{currencyFormat(formikValues.mceFinancing.ias)}</p>
				</Col>
			</Row>
			<Row>
				<Col md='6'>
					<NumberInputField
						labelId='semestralPaymentRequestForm.howManyTimesIAS'
						value={formikValues.mceFinancing.timesIas || 0}
						disabled={true}
					/>
				</Col>
				<Col md='6'>
					<NumberInputField
						labelId='semestralPaymentRequestForm.contractDuration'
						disabled={true}
						value={formikValues.mceFinancing.contractDuration || 0}
					/>
				</Col>
			</Row>

			<Row className='mt-5 mb-0'>
				<Col>
					<small className='text-secondary text-uppercase'>
						<FormattedMessage id='semestralPaymentRequestForm.plafondDistributionByActivityType' />
					</small>
				</Col>
			</Row>
			<Row>
				<Col md='3'>
					<Form.Label>
						<FormattedMessage id='semestralPaymentRequestForm.followUp' />
					</Form.Label>
					<p>
						{currencyFormat(formikValues.plafondFollowUp)}
					</p>
				</Col>
				<Col>
					<Form.Label>
						<FormattedMessage id='semestralPaymentRequestForm.skillsExtension' />
					</Form.Label>
					<p>
						{currencyFormat(formikValues.plafondConsulting)}
					</p>
				</Col>
			</Row>

			<Row className='mt-4'>
				<Col>
					<Form.Label>
						<FormattedMessage id='semestralPaymentRequestForm.accumulatedPayments' />
					</Form.Label>
					{formikValues.accumulatedPayments.length > 1 ? (
						<React.Fragment>
							{formikValues.accumulatedPayments.map((payment, index) =>
								index > 0 ? (
									<React.Fragment key={index}>
										{' + ' + currencyFormat(payment)}
									</React.Fragment>
								) : (
									currencyFormat(payment)
								)
							)}
							{' = '}
							<strong>{currencyFormat(totalPayments())}</strong>
						</React.Fragment>
					) : (
						<strong>{currencyFormat(totalPayments())}</strong>
					)}
				</Col>
			</Row>

			<Row className='mt-4'>
				<Col>
					<Form.Label>
						<FormattedMessage
							id={`${getFormTextPrefix()}.availablePaymentAmount`}
						/>
					</Form.Label>
					{currencyFormat( maxFixed(parseFloat(formikValues.plafond)-parseFloat(totalPayments()))  )}
				</Col>
			</Row>

			<Row className='mt-5'>
				<small className='text-primary text-uppercase'>
					<FormattedMessage id={`${getFormTextPrefix()}.proposal.section`}/>
				</small>
			</Row>

			<Row>
				<Col md={12}>
					<h5 className='text-secondary mt-4'>
						<FormattedMessage id={`semestralPaymentRequestForm.bimestralFollowUp`}/>
					</h5>

				</Col>


			</Row>
				<Row>
				<Col md='6'>
					<DecimalInputField
						labelId='semestralPaymentRequestForm.payment'
						handleChange={handleChange}
						value={formikValues.bimestralProposalPayment}
						name='bimestralProposalPayment'
						suffixLabel='€'
						disabled={true}

					/>
				</Col>

					<Col md='6'>
						<NumberInputField
							labelId='semestralPaymentRequestForm.numberOfReportsToProduceFollowUp'
							handleChange={handleChange}
							disabled={readMode}
							value={formikValues.numberOfReportsToProduceFollowUp}
							name='numberOfReportsToProduceFollowUp'
							isInvalid={errors.numberOfReportsToProduceFollowUp ? true : false}
							errorMessage={
								errors.numberOfReportsToProduceFollowUp
									? errors.numberOfReportsToProduceFollowUp
									: ''
							}
						/>
					</Col>



			</Row>

			<Row className='mt-4'>
				<Col md='6'>
					<NumberInputField
						labelId='semestralPaymentRequestForm.numberOfReportsProducedThisSemester'
						handleChange={handleChange}
						value={formikValues.numberOfReportsProducedThisSemester}
						name='numberOfReportsProducedThisSemester'
						handleChange={e=>refreshBimestralFollowUp(e,'numberOfReportsProducedThisSemester')}
						disabled={readMode}
						isInvalid={
							errors.numberOfReportsProducedThisSemester ? true : false
						}
						errorMessage={
							errors.numberOfReportsProducedThisSemester
								? errors.numberOfReportsProducedThisSemester
								: ''
						}
					/>
				</Col>
				<Col md='6'>
					<DecimalInputField
						labelId='semestralPaymentRequestForm.byReportValue'
						handleChange={e=>refreshBimestralFollowUp(e,'byReportValue')}
						value={formikValues.byReportValue}
						name='byReportValue'
						disabled={readMode}
						suffixLabel='€'
						isInvalid={errors.byReportValue ? true : false}
						errorMessage={errors.byReportValue ? errors.byReportValue : ''}
					/>
				</Col>
			</Row>


			<Row className={'mt-5 border-1 border-top'}>
				<Col md={12}>
					<h5 className='text-secondary mt-4'>
						<FormattedMessage id={`semestralPaymentRequestForm.skillsExtension`}/>
					</h5>

				</Col>

			</Row>

			<Row className='mt-4'>
				<Col md='6'>
					<DecimalInputField
						labelId='semestralPaymentRequestForm.payment'
						handleChange={handleChange}
						value={formikValues.skillsExtensionProposalPayment}
						name='skillsExtensionProposalPayment'
						suffixLabel='€'
						disabled={true}

					/>
				</Col>
			</Row>



			<Row className='mt-4'>
				<Col md='6'>
					<NumberInputField
						labelId='semestralPaymentRequestForm.numberOfHoursSkillsExtension'
						handleChange={e=>refreshSkillsExtension(e,'numberOfHoursSkillsExtension')}
						disabled={readMode}
						value={formikValues.numberOfHoursSkillsExtension}
						name='numberOfHoursSkillsExtension'
						isInvalid={errors.numberOfHoursSkillsExtension ? true : false}
						errorMessage={
							errors.numberOfHoursSkillsExtension
								? errors.numberOfHoursSkillsExtension
								: ''
						}
					/>
				</Col>
				<Col md='6'>
					<DecimalInputField
						labelId='semestralPaymentRequestForm.byHourValue'
						handleChange={e=>refreshSkillsExtension(e,'byHourValue')}
						value={formikValues.byHourValue}
						name='byHourValue'
						disabled={readMode}
						decimalScale={3}
						suffixLabel='€'
						isInvalid={errors.byHourValue ? true : false}
						errorMessage={errors.byHourValue ? errors.byHourValue : ''}
					/>
				</Col>
			</Row>

			<Row className='mt-4 border-1 border-top'>
				<Col>
					<Form.Label>
						<FormattedMessage id='semestralPaymentRequestForm.proposalTotalPayment' />
					</Form.Label>
					{currencyFormat(totalProposalPayment())}
				</Col>
			</Row>

			<Row className='mt-4'>
				<Col>
					<TextAreaInputField
						labelId='semestralPaymentRequestForm.observations'
						handleChange={handleChange}
						disabled={readMode}
						value={formikValues.observationsIEFP}
						name='observationsIEFP'
						isInvalid={errors.observationsIEFP ? true : false}
						errorMessage={
							errors.observationsIEFP ? errors.observationsIEFP : ''
						}
					/>
				</Col>
			</Row>


			<Row>
				<Col md='6'>
					<TextInputField
						className='w-25'
						disabled={readMode}
						name='serviceInformationReference'
						value={formikValues.serviceInformationReference}
						labelId='annualComplementaryActivitiesPlanForm.disptachInformationNumber'
						handleChange={handleChange}
						isInvalid={errors.serviceInformationReference}
						errorMessage={errors.serviceInformationReference}
					/>
				</Col>
				<Col md='6'>
					<DateTimeInputField
						labelId='annualComplementaryActivitiesPlanForm.disptachDate'
						name='dispatchDate'
						disabled={readMode}
						value={formikValues.dispatchDate}
						setFieldValue={setFieldValue}
						isDateWithNoTime={true}
						isInvalid={errors.dispatchDate}
						errorMessage={errors.dispatchDate}
					/>
				</Col>
			</Row>
			<Row>
				<Col md='12' >
					{readMode ? (
						documents.length === 1 && (
							<AuthenticatedDownloadLink
								url={documents[0].documentPath}
								filename={documents[0].name}
							>
								<FormattedMessage id='newAcceptanceTerm.download' />
							</AuthenticatedDownloadLink>
						)
					) : (
						<>
						<SingleUploadArea
							documents={documents}
							setDocuments={setDocuments}
							documentType={'DECISION_DISPATCH'}
							processUploadCallback={uploadCallback}
							processDeleteCallback={deleteCallback}
							setError={setError}

						/>
						<Form.Control.Feedback type='invalid'>{errors.documents}</Form.Control.Feedback>
						</>
					)}
				</Col>
			</Row>
			<Row>
				<Col>
					<TextInputField
						labelId='semestralPaymentRequestForm.director'
						name='directorName'
						disabled={readMode}
						value={formikValues.directorName}
						handleChange={handleChange}
						isInvalid={errors.directorName}
						errorMessage={errors.directorName}
					/>
				</Col>
			</Row>


			<div className='form-actions mt-5'>
				<button
					className='btn btn-link'
					type='button'
					onClick={handlePreviousStep}
				>
					<FormattedMessage id='all.back' />
				</button>
			</div>
		</div>
	);
}
