import { apiAxios as authAxios } from '../axios.config';



export const getIndicatorStatesCounter = () => {
  return authAxios.get('/api/indicator/states');
};


export const getIndicatorGenderCounter = () => {
  return authAxios.get('/api/indicator/genders');
};

export const getIndicatorAgeCounter = () => {
  return authAxios.get('/api/indicator/ages');
};

export const getIndicatorSchoolQualificationsCounter = () => {
  return authAxios.get('/api/indicator/schoolQualifications');
};

export const getIndicatorQualificationLevelsCounter = () => {
  return authAxios.get('/api/indicator/qualificationLevels');
};

export const getIndicatorCandidaturesAdCounter = (code) => {
  return authAxios.get('/api/indicator/administrativeDivisions',code ? { params: {code:code} } : null);
};


export const getIndicatorCandidaturesStatesAdCounter = (code) => {
  return authAxios.get('/api/indicator/administrativeDivisionsStates',code ? { params: {code:code} } : null);
};

export const getIndicatorCandidaturesGenderAdCounter = (code) => {
  return authAxios.get('/api/indicator/administrativeDivisionsCandidatureGenders',code ? { params: {code:code} } : null);
};

export const getIndicatorCandidaturesAgesAdCounter = (code) => {
  return authAxios.get('/api/indicator/administrativeDivisionsCandidatureAges',code ? { params: {code:code} } : null);
};


export const getIndicatorCandidaturesSchoolQualificationAdCounter = (code) => {
  return authAxios.get('/api/indicator/administrativeDivisionsCandidatureSchoolQualification',code ? { params: {code:code} } : null);
};

export const getIndicatorCandidaturesQualificationLevelsAdCounter = (code) => {
  return authAxios.get('/api/indicator/administrativeDivisionsCandidatureQualificationLevels',code ? { params: {code:code} } : null);
};

export const getIndicatorRegionalDelegationGroupsCounter = () => {
  return authAxios.get('/api/indicator/regionalDelegationsGroups');
};

export const getIndicatorCaes = (index,size,cae) => {
  return authAxios.get('/api/indicator/caes', {params:{index:index, size:size, code:cae}});
};



export const getIndicatorPriorSupport = (index,size,nif,regionalDelegationCode) => {
  return authAxios.get('/api/indicator/priorSupport', {params:{index:index, size:size, nif:nif, regionalDelegationCode:regionalDelegationCode}});
};

export const getIndicatorOpinionSupport = (index,size,nif,regionalDelegationCode) => {
  return authAxios.get('/api/indicator/opinion', {params:{index:index, size:size, nif:nif, regionalDelegationCode:regionalDelegationCode}});
};

export const getIndicatorConsulting = (index,size,nif,regionalDelegationCode) => {
  return authAxios.get('/api/indicator/consulting', {params:{index:index, size:size, nif:nif, regionalDelegationCode:regionalDelegationCode}});
};

export const getIndicatorWorkStations = () => {
  return authAxios.get('/api/indicator/workstations');
};

export const getIndicatorPayments = () => {
  return authAxios.get('/api/indicator/payments');
};

export const getIndicatorRestitutions = () => {
  return authAxios.get('/api/indicator/restitutions');
};

export const getIndicatorInvestments = () => {
  return authAxios.get('/api/indicator/investments');
};































