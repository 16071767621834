import {apiAxios as authAxios} from "../axios.config";

export const getDeclaration = (consultingRequestExternalId) => {
    return authAxios.get(`/api/consultingRequest/${consultingRequestExternalId}/declarationEndFirstYear`);
};


export const canReadFunction = (consultingRequestExternalId) => {
    return authAxios.get(`/api/consultingRequest/${consultingRequestExternalId}/declarationEndFirstYear/canRead`);
};



export const saveDeclaration = (consultingRequestExternalId, data) => {
    return authAxios.patch(`/api/consultingRequest/${consultingRequestExternalId}/declarationEndFirstYear`, data);
};

export const submitDeclaration = (consultingRequestExternalId, data) => {
    return authAxios.post(`/api/consultingRequest/${consultingRequestExternalId}/declarationEndFirstYear/submit`, data);
};