export const DocumentTypeDescriptor = {
    GENERAL: 'GENERAL',
    USER: 'USER',
    CANDIDATURE: 'CANDIDATURE',
    CANDIDATURE_PAYMENT: 'CANDIDATURE_PAYMENT',
    NON_COMPLIANCE: 'NON_COMPLIANCE',
    IRREGULARITY: 'IRREGULARITY',

    values: () => {
        return ['GENERAL', 'USER', 'CANDIDATURE', 'CANDIDATURE_PAYMENT', 'NON_COMPLIANCE', 'IRREGULARITY'];
    }
}
