import React, {useEffect, useState} from 'react';
import {Col, Container, Row, Button} from 'react-bootstrap';
import {FormattedMessage, useIntl} from 'react-intl';
import {Link, useParams} from 'react-router-dom';
import {AlertError} from '../../../components/bootstrap/AlertError';
import AlertInfo from '../../../components/bootstrap/AlertInfo';
import {AlertSuccess} from '../../../components/bootstrap/AlertSuccess';
import {TextInputField} from '../../../components/bootstrap/TextInputField';
import Loading from '../../../components/general/Loading';
import {SubTemplate} from '../../../components/general/SubTemplate';
import {SingleUploadArea} from '../../../components/SingleUploadArea';
import {createCustomErrorMessage} from '../../../hooks/errorMessage';
import {ProcessState} from '../../../models/ProcessState';
import {getEntity} from '../../../rest/entity';
import {
    createDefaultNibRequest,
    getLastInDraftOrCreate,
    getNibRequest, getNibRequestByProcessExternalId, submitDecisionNibRequest,
    submitNibRequest
} from '../../../rest/nibRequest';
import {handleError, isNotBusinessError} from '../../../utils/handleError';
import {Validator} from '../../../utils/Validator';
import {isEntityUser, isIEFPUser} from "../../../authentication/authenticationHelper";

export function UploadNib() {
    const intl = useIntl();

    const [nibRequest, setNibRequest] = useState(null);
    const [nibDocuments, setNibDocuments] = useState([]);

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [submited, setSubmited] = useState(false);
    const {processExternalId} = useParams();
    const [submitDecision,setSubmitDecision] = useState(false)

    useEffect(() => {
        fetchData();
    }, [submitDecision]);

    async function fetchData() {
        try {
            let [{data: nibRequest}] = await Promise.all([
                (processExternalId !== undefined) ? getNibRequestByProcessExternalId(processExternalId) : getNibRequest()
            ]);

            if (processExternalId === undefined && !nibRequest) {
                const {data: defaultNibRequest} = await createDefaultNibRequest();
                nibRequest = defaultNibRequest;
            }

            setNibRequest(getInitialValues(nibRequest, false));
            setNibDocuments(getInitialFiles(nibRequest));
        } catch (error) {
            setError(error);
        } finally {
            setLoading(false);
        }
    }

    function getInitialFiles(nibRequest) {
        let files = [];

        if (nibRequest.document) {
            files.push({
                externalId: nibRequest.document.externalId,
                documentType: nibRequest.document.documentType,
                documentPath: nibRequest.document.documentPath,
                name: nibRequest.document.name,
                content: '',
                submited: true,
                error: ''
            });
        } else {
            files.push({
                externalId: '',
                documentType: 'NIB_PROOF',
                name: '',
                content: null,
                submited: false,
                error: ''
            });
        }
        return files;
    }

    function getInitialValues(nibRequestResponse, isEdit) {
        return {
            externalId: nibRequestResponse.externalId,
            state: nibRequestResponse.state,
            nib: {value: isEdit ? '' : nibRequestResponse.nib, errorMessage: ''}
        };
    }

    async function handleSubmit() {
        const isFieldsValid = validateFields();

        if (isFieldsValid) {
            try {
                let dataToSend = {
                    externalId: nibRequest.externalId,
                    nib: nibRequest.nib.value
                };
                const {data: nibRequestResponse} = await submitNibRequest(dataToSend);
                nibRequest.state = nibRequestResponse.state;
                setNibRequest({...nibRequest});
                setSubmited(true);
            } catch (error) {
                setError(error);
            }
        }
    }

    async function handleEditNib() {
        try {
            const {data: nibRequest} = await getLastInDraftOrCreate();
            setNibRequest(getInitialValues(nibRequest, true));
            setNibDocuments(getInitialFiles(nibRequest));
        } catch (error) {
            setError(error);
        }
    }

    function handleNibChange(e) {
        nibRequest.nib.value = e.target.value;
        setNibRequest({...nibRequest});
    }

    function validateFields() {
        let valid = true;

        if (!nibDocuments[0].submited) {
            valid = false;
            setError(
                createCustomErrorMessage(intl.formatMessage({id: 'errors.candidatureForm.requiredFiles'}))
            );
        }

        if (!Validator.validateNib(nibRequest.nib.value)) {
            valid = false;
            nibRequest.nib.errorMessage = intl.formatMessage({
                id: 'errors.entity.uploadNib.nibInvalid'
            });
            setNibRequest({...nibRequest});
        }
        return valid;
    }

    if (loading) {
        return <Loading/>;
    }

    if (error && isNotBusinessError(error)) {
        return handleError(error);
    }

    async function submitDecisionWithValue(value) {
        const flag = {flag: value};
        try {
            const {data: nibRequest} = await submitDecisionNibRequest(processExternalId,flag);
            setNibRequest(getInitialValues(nibRequest, true));
            setNibDocuments(getInitialFiles(nibRequest));
            setSubmitDecision(true);
        } catch (error) {
            setError(error);
        }
    }

    return (
        <SubTemplate
            titleId={'entity.uploadNIB.title'}
            subTitleId={`entity.uploadNIB.subTitle${
                nibRequest.state !== ProcessState.DRAFT ? '2' : ''
            }`}
            centerContent
            hasBackButton>
            <Container>
                <Row>
                    <div className='col-lg-8 offset-lg-2 mb-5'>
                        <AlertError error={error}/>
                        {submited && (
                            <AlertSuccess
                                header={<FormattedMessage id='alert.header.nibRequestSubmitted'/>}
                                message={<FormattedMessage id='alert.message.nibRequestSubmitted'/>}
                            />
                        )}

                        <AlertInfo
                            show={nibRequest.state === ProcessState.SUBMITTED && isEntityUser()}
                            header={<FormattedMessage id='alert.header.nibRequestAnalysis'/>}
                            message={<FormattedMessage id='alert.message.nibRequestAnalysis'/>}
                        />

                        <div className='form'>
                            <div className={`${nibRequest.state !== ProcessState.DRAFT && 'disabled'}`}>
                                <SingleUploadArea
                                    documents={nibDocuments}
                                    setDocuments={setNibDocuments}
                                    documentType={'NIB_PROOF'}
                                    setError={setError}
                                />
                            </div>

                            <Row className='mb-4'>
                                <Col lg='6'>
                                    <TextInputField
                                        controlId={'nib'}
                                        labelId={'entity.nib'}
                                        name={'nib'}
                                        handleChange={(e) => handleNibChange(e)}
                                        value={nibRequest.nib.value}
                                        isInvalid={nibRequest.nib.errorMessage}
                                        errorMessage={nibRequest.nib.errorMessage}
                                        disabled={nibRequest.state !== ProcessState.DRAFT}
                                    />
                                </Col>
                            </Row>

                            <div className='form-actions mt-5'>
                                <Link className='btn btn-link' to={`/`}>
                                    <FormattedMessage id='all.back'/>
                                </Link>

                                {nibRequest.state === ProcessState.DRAFT && (
                                    <button className='btn btn-primary' type='button' onClick={() => handleSubmit()}>
                                        <FormattedMessage id='all.submitButtonText'/>
                                    </button>
                                )}

                                {(nibRequest.state === ProcessState.APPROVED || nibRequest.state === ProcessState.REJECTED) && isEntityUser() && (
                                    <button className='btn btn-primary' type='button' onClick={() => handleEditNib()}>
                                        <FormattedMessage id='entity.uploadNIB.editNib'/>
                                    </button>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-2 mb-5'>
                        {isIEFPUser() && ProcessState.SUBMITTED === nibRequest.state &&
                        <>
                            <div>
                                <button
                                    className='btn btn-primary d-flex align-items-center justify-content-center mb-4 w-100'
                                    type='button' onClick={() => submitDecisionWithValue(true)}>
                                    <FormattedMessage id='all.button.approve'/>
                                </button>
                            </div>
                            <div>
                                <button
                                    className='btn btn-outline-primary d-flex align-items-center justify-content-center mb-4 w-100'
                                    type='button' onClick={() => submitDecisionWithValue(false)}>
                                    <FormattedMessage id='all.button.reject'/>
                                </button>
                            </div>
                        </>
                        }


                    </div>

                </Row>
            </Container>
        </SubTemplate>
    );
}
