import React, { useState } from 'react';
import { Alert } from 'react-bootstrap';
import { FaInfoCircle } from 'react-icons/fa';
import { useIntl } from 'react-intl';
import { CandidatureState } from '../../models/CandidatureState';
import { ScrollToTop } from '../ScrollToTop';
import { ConfigObject } from '../../config';

export function CandidatureAlertInfo({ candidatureState,dateSubmissionLimit,isMainPromoter}) {

  const intl = useIntl();
  const [messageObject, setMessageObject] = useState(getMessageObjectValue());

  function getMessageObjectValue() {
    let messageObjectToReturn = { show: true, header: '', message: '' };
    switch (candidatureState) {
      case CandidatureState.VALIDATION:
      case CandidatureState.WAIT_CONTESTATION:
        messageObjectToReturn.header = intl.formatMessage({
          id:  `candidatureAlertInfoHeader.${candidatureState}`,
        });

          messageObjectToReturn.message = intl.formatMessage(
              {id: `candidatureAlertInfo.${candidatureState}`});
        break;
      case CandidatureState.SUBMITTED:
        messageObjectToReturn.header = intl.formatMessage({
          id: 'candidatureAlertInfo.header',
        });
        if(isMainPromoter){
          messageObjectToReturn.message = intl.formatMessage(
              {id: `candidatureAlertInfoMain.${candidatureState}`},
              {date: dateSubmissionLimit});
        }else{
          messageObjectToReturn.message = intl.formatMessage(
              {id: `candidatureAlertInfo.${candidatureState}`},
              {date: dateSubmissionLimit});
        }

        break;
      case CandidatureState.EMIT_OPINION:
      case CandidatureState.NEW_EMIT_OPINION:
        messageObjectToReturn.header = intl.formatMessage({
          id:  `candidatureAlertInfoHeader.${candidatureState}`,
        });
        messageObjectToReturn.message = intl.formatMessage(
            {id: `candidatureAlertInfo.${candidatureState}`},
            {days: ConfigObject.get().daysToEmitOpinion}
        );
        break;
      case CandidatureState.WAIT_CONTESTATION_EXPIRATION:
      case CandidatureState.WAIT_ADDITIONAL_DUE_DILIGENCE_RESPONSE:
        messageObjectToReturn.header = intl.formatMessage({
          id:  `candidatureAlertInfoHeader.${candidatureState}`,
        });
        messageObjectToReturn.message = intl.formatMessage(
            {id: `candidatureAlertInfo.${candidatureState}`},
            {daysToContest: ConfigObject.get().daysToContest}
        );
        break;
      default:
        break;
    }

    return messageObjectToReturn;
  }

  function handleClose() {
    setMessageObject({
      show: false,
    });
  }

  return (
    <>
      <ScrollToTop />
      {messageObject.show && (
        <Alert
          dismissible
          className='d-flex align-items-center mb-5'
          variant='warning'
          onClose={() => handleClose()}
        >
          <div className='px-3'>
            <FaInfoCircle />
          </div>

          <div className='px-3'>
            <p className='mb-0'>
              <strong>
                {messageObject.header}
              </strong>
            </p>
            <p className='mb-0'>{messageObject.message}</p>
          </div>
        </Alert>
      )}
    </>
  );
}
