import React from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {Col, Form, Row, Table} from 'react-bootstrap';
import DecimalInputField from '../bootstrap/DecimalInputField';
import ToolTip from '../bootstrap/ToolTip';
import {maxFixed} from '../../utils/CurrencyUtils';
import {
	totalCostsDisclosureActivities,
	totalCostsGeneral,
	totalCostsIncubationSupport,
} from '../../utils/AnnualComplementaryActivitiesPlanFormUtils';
import {TextAreaInputField} from '../bootstrap/TextAreaInputField';
import {TextInputField} from '../bootstrap/TextInputField';
import {DateTimeInputField} from '../bootstrap/DateTimeInputField';
import {SingleUploadArea} from '../SingleUploadArea';
import {deleteB18Document, uploadB18Document,} from '../../rest/additionalActivitiesPlanPayment';
import {AdditionalActivityControlType} from '../../models/AdditionalActivityControlType';
import {DocumentType} from "../../models/DocumentType";
import {isIEFPUser} from "../../authentication/authenticationHelper";

export function TechnicalAnalysis({
	formStep,
	numberOfSteps,
	errors,
	formikValues,
	handleChange,
	handlePreviousStep,
	IAS,
	months,
	setFieldValue,
	setError,
	documents,
	setDocuments,
	readMode,
}) {
	const bootcampIndex = 0;
	const didaticIndex = 1;
	const disclosureIndex = 2;
	const incubationProjectsIndex = 3;

	const intl = useIntl();

	const uploadCallback = async (formData) => {
		return await uploadB18Document(formikValues.externalId, formData);
	};

	const deleteCallback = async (documentExternalId) => {
		return await deleteB18Document(formikValues.externalId, documentExternalId);
	};

	function totalDeviation() {
		let sum = 0;
		sum +=
			calculateDeviation(
				AdditionalActivityControlType.BOOTCAMP,
				bootcampIndex
			) +
			calculateDeviation(
				AdditionalActivityControlType.DISCLOSURE,
				disclosureIndex
			) +
			calculateDeviation(AdditionalActivityControlType.DIDACTIC, didaticIndex) +
			calculateDeviation(
				AdditionalActivityControlType.INCUBATION_PROJECTS,
				incubationProjectsIndex
			);
		return sum;
	}

	function calculateDeviation(activityControlType, activityControlIndex) {
		let limitsControl = parseFloat(
			formikValues.paymentTechnicalAnalysis.technicalAnalysis
				.additionalActivityControlList[activityControlIndex].limitsControl
		);
		let EAActivityBudget;

		if (limitsControl) {
			if (activityControlType === AdditionalActivityControlType.BOOTCAMP) {
				EAActivityBudget = totalCostsGeneral(
					formikValues,
					bootcampIndex,
					'value'
				);
			} else if (
				activityControlType === AdditionalActivityControlType.DISCLOSURE
			) {
				EAActivityBudget = totalCostsDisclosureActivities(formikValues);
			} else if (
				activityControlType === AdditionalActivityControlType.DIDACTIC
			) {
				EAActivityBudget = totalCostsGeneral(
					formikValues,
					didaticIndex,
					'value'
				);
			} else {
				EAActivityBudget = totalCostsIncubationSupport(
					formikValues,
					IAS,
					months
				);
			}

			return limitsControl - EAActivityBudget;
		} else {
			return 0;
		}
	}

	function totalComplementaryActivities(field) {
		let sum = 0;
		formikValues.paymentTechnicalAnalysis.technicalAnalysis.additionalActivityControlList.forEach(
			(activityControl) => (sum += parseFloat(activityControl[field]) || 0)
		);
		return sum;
	}

	const calculateDecisionAmountToPay = () => {
		const decisionProposal = totalComplementaryActivities('decisionProposal');
		const paidAdvanceAmount = formikValues.paymentTechnicalAnalysis.paidAdvanceAmount;
		return decisionProposal - paidAdvanceAmount;
	};

	const payoutValue = () => Math.max(0, calculateDecisionAmountToPay());
	const collectValue = () => Math.abs(Math.min(0, calculateDecisionAmountToPay()));

	// Set the calculated value in formikValues
	formikValues.paymentTechnicalAnalysis.decisionAmountToPay = payoutValue();
	formikValues.paymentTechnicalAnalysis.amountToPay = payoutValue();
	formikValues.paymentTechnicalAnalysis.amountToReturn = collectValue();
	formikValues.paymentTechnicalAnalysis.decisionAmountToReturn = collectValue();

	return (
		<>
			<h4 className='text-primary mb-0'>
				<FormattedMessage id='complementaryActivitiesPaymentRequest.technicalAnalysis.title' />
			</h4>

			{/*Steps*/}
			<small className='text-secondary text-uppercase'>
				{intl.formatMessage(
					{ id: 'all.step' },
					{ step: formStep, numberOfSteps: numberOfSteps }
				)}
			</small>

			<fieldset disabled={readMode==true}>
				{/*Activity Identification*/}
				<Row className='mt-4'>
					<Col md='4'>
						<Form.Label>
							<FormattedMessage id='complementaryActivitiesPaymentRequest.technicalAnalysis.globalExpenseVerification' />
							<ToolTip
								message={
									<FormattedMessage id='annualComplementaryActivitiesPlanForm.globalBudgetVerification.toolTip' />
								}
							/>
						</Form.Label>
						{maxFixed(
							(formikValues.paymentTechnicalAnalysis.technicalAnalysis.iasConfirmation || 0) * 60
						) + ' €'}
					</Col>
					<Col md='8'>
						<DecimalInputField
							suffixLabel=' €'
							name={
								'paymentTechnicalAnalysis.technicalAnalysis.iasConfirmation'
							}
							handleChange={handleChange}
							labelId='complementaryActivitiesPaymentRequest.technicalAnalysis.IASconfirmedAtTheTime'
							value={
								formikValues.paymentTechnicalAnalysis.technicalAnalysis
									.iasConfirmation
							}
							isInvalid={
								errors.paymentTechnicalAnalysis?.technicalAnalysis
									?.iasConfirmation
							}
							errorMessage={
								errors.paymentTechnicalAnalysis?.technicalAnalysis
									?.iasConfirmation
							}
							inputClass='w-50'
						/>
					</Col>
				</Row>

				<Row className={'mt-4'}>
					<Col md='4'>
						<DecimalInputField
							suffixLabel=' €'
							name={'paymentTechnicalAnalysis.paidAdvanceAmount'}
							handleChange={handleChange}
							labelId='complementaryActivitiesPaymentRequest.technicalAnalysis.upFrontPayment'
							value={formikValues.paymentTechnicalAnalysis.paidAdvanceAmount}
							isInvalid={errors.paymentTechnicalAnalysis?.paidAdvanceAmount}
							errorMessage={errors.paymentTechnicalAnalysis?.paidAdvanceAmount}
						/>
					</Col>

					<Col md='4'>
						<DateTimeInputField
							labelId='complementaryActivitiesPaymentRequest.technicalAnalysis.dateAproved'
							name='paymentTechnicalAnalysis.advancePaymentDate'
							value={formikValues.paymentTechnicalAnalysis.advancePaymentDate}
							setFieldValue={setFieldValue}
							isDateWithNoTime={true}
							isInvalid={errors.paymentTechnicalAnalysis?.advancePaymentDate}
							errorMessage={errors.paymentTechnicalAnalysis?.advancePaymentDate}
						/>
					</Col>
				</Row>

				<Row className={'mt-4'}>
					<Col>
						<Form.Label>
							<FormattedMessage id='complementaryActivitiesPaymentRequest.technicalAnalysis.globalBudgetActivitiesControl' />
						</Form.Label>
					</Col>
				</Row>
				<Row>
					<Col>
						<Table striped responsive='md'>
							<thead>
								<tr>
									<th>
										<FormattedMessage id='annualComplementaryActivitiesPlanForm.complementaryActivities' />
									</th>
									<th>
										<FormattedMessage id='complementaryActivitiesPaymentRequest.technicalAnalysis.approvedBudget' />
									</th>
									<th>
										<FormattedMessage id='complementaryActivitiesPaymentRequest.technicalAnalysis.carriedOutExpenses' />
									</th>
									<th>
										<FormattedMessage id='annualComplementaryActivitiesPlanForm.deviation' />
									</th>
									<th>
										<FormattedMessage id='annualComplementaryActivitiesPlanForm.technicalProposal' />
									</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>Bootcamp</td>

									<td>
										<DecimalInputField
											disabled
											name={`paymentTechnicalAnalysis.technicalAnalysis.additionalActivityControlList[${bootcampIndex}].budget`}
											value={
												formikValues.paymentTechnicalAnalysis.technicalAnalysis
													.additionalActivityControlList[bootcampIndex]
													.budget
											}
											handleChange={handleChange}
											isInvalid={
												errors.paymentTechnicalAnalysis?.technicalAnalysis
													?.additionalActivityControlList?.[bootcampIndex]
													?.budget
											}
											errorMessage={
												errors.paymentTechnicalAnalysis?.technicalAnalysis
													?.additionalActivityControlList?.[bootcampIndex]
													?.budget
											}
											suffixLabel=' €'
										/>
									</td>

									<td>
										<DecimalInputField
											name={`paymentTechnicalAnalysis.technicalAnalysis.additionalActivityControlList[${bootcampIndex}].limitsControl`}
											value={
												formikValues.paymentTechnicalAnalysis.technicalAnalysis
													.additionalActivityControlList[bootcampIndex]
													.limitsControl
											}
											handleChange={handleChange}
											isInvalid={
												errors.paymentTechnicalAnalysis?.technicalAnalysis
													?.additionalActivityControlList?.[bootcampIndex]
													?.limitsControl
											}
											errorMessage={
												errors.paymentTechnicalAnalysis?.technicalAnalysis
													?.additionalActivityControlList?.[bootcampIndex]
													?.limitsControl
											}
											suffixLabel=' €'
										/>
									</td>

									<td>
										{maxFixed(
											calculateDeviation(
												AdditionalActivityControlType.BOOTCAMP,
												bootcampIndex
											)
										) + '€'}
									</td>

									<td>
										<DecimalInputField
											name={`paymentTechnicalAnalysis.technicalAnalysis.additionalActivityControlList[${bootcampIndex}].decisionProposal`}
											value={
												formikValues.paymentTechnicalAnalysis.technicalAnalysis
													.additionalActivityControlList[bootcampIndex]
													.decisionProposal
											}
											handleChange={handleChange}
											isInvalid={
												errors.paymentTechnicalAnalysis?.technicalAnalysis
													?.additionalActivityControlList?.[bootcampIndex]
													?.decisionProposal
											}
											errorMessage={
												errors.paymentTechnicalAnalysis?.technicalAnalysis
													?.additionalActivityControlList?.[bootcampIndex]
													?.decisionProposal
											}
											suffixLabel=' €'
										/>
									</td>
								</tr>

								<tr>
									<td>
										<FormattedMessage id='complementaryActivitiesPaymentRequest.approvedExpenses.disclosureActivities' />
									</td>

									<td>
										<DecimalInputField
											disabled
											name={`paymentTechnicalAnalysis.technicalAnalysis.additionalActivityControlList[${disclosureIndex}].budget`}
											value={
												formikValues.paymentTechnicalAnalysis.technicalAnalysis
													.additionalActivityControlList[disclosureIndex]
													.budget
											}
											handleChange={handleChange}
											isInvalid={
												errors.paymentTechnicalAnalysis?.technicalAnalysis
													?.additionalActivityControlList?.[disclosureIndex]
													?.budget
											}
											errorMessage={
												errors.paymentTechnicalAnalysis?.technicalAnalysis
													?.additionalActivityControlList?.[disclosureIndex]
													?.budget
											}
											suffixLabel=' €'
										/>
									</td>

									<td>
										<DecimalInputField
											name={`paymentTechnicalAnalysis.technicalAnalysis.additionalActivityControlList[${disclosureIndex}].limitsControl`}
											value={
												formikValues.paymentTechnicalAnalysis.technicalAnalysis
													.additionalActivityControlList[disclosureIndex]
													.limitsControl
											}
											handleChange={handleChange}
											isInvalid={
												errors.paymentTechnicalAnalysis?.technicalAnalysis
													?.additionalActivityControlList?.[disclosureIndex]
													?.limitsControl
											}
											errorMessage={
												errors.paymentTechnicalAnalysis?.technicalAnalysis
													?.additionalActivityControlList?.[disclosureIndex]
													?.limitsControl
											}
											suffixLabel=' €'
										/>
									</td>

									<td>
										{maxFixed(
											calculateDeviation(
												AdditionalActivityControlType.DISCLOSURE,
												disclosureIndex
											)
										) + '€'}
									</td>

									<td>
										<DecimalInputField
											name={`paymentTechnicalAnalysis.technicalAnalysis.additionalActivityControlList[${disclosureIndex}].decisionProposal`}
											value={
												formikValues.paymentTechnicalAnalysis.technicalAnalysis
													.additionalActivityControlList[disclosureIndex]
													.decisionProposal
											}
											handleChange={handleChange}
											isInvalid={
												errors.paymentTechnicalAnalysis?.technicalAnalysis
													?.additionalActivityControlList?.[disclosureIndex]
													?.decisionProposal
											}
											errorMessage={
												errors.paymentTechnicalAnalysis?.technicalAnalysis
													?.additionalActivityControlList?.[disclosureIndex]
													?.decisionProposal
											}
											suffixLabel=' €'
										/>
									</td>
								</tr>

								<tr>
									<td>
										<FormattedMessage id='annualComplementaryActivitiesPlanForm.didacticMaterials' />
									</td>

									<td>
										<DecimalInputField
											disabled
											name={`paymentTechnicalAnalysis.technicalAnalysis.additionalActivityControlList[${didaticIndex}].budget`}
											value={
												formikValues.paymentTechnicalAnalysis.technicalAnalysis
													.additionalActivityControlList[didaticIndex]
													.budget
											}
											handleChange={handleChange}
											isInvalid={
												errors.paymentTechnicalAnalysis?.technicalAnalysis
													?.additionalActivityControlList?.[didaticIndex]
													?.budget
											}
											errorMessage={
												errors.paymentTechnicalAnalysis?.technicalAnalysis
													?.additionalActivityControlList?.[didaticIndex]
													?.budget
											}
											suffixLabel=' €'
										/>
									</td>

									<td>
										<DecimalInputField
											name={`paymentTechnicalAnalysis.technicalAnalysis.additionalActivityControlList[${didaticIndex}].limitsControl`}
											value={
												formikValues.paymentTechnicalAnalysis.technicalAnalysis
													.additionalActivityControlList[didaticIndex]
													.limitsControl
											}
											handleChange={handleChange}
											isInvalid={
												errors.paymentTechnicalAnalysis?.technicalAnalysis
													?.additionalActivityControlList?.[didaticIndex]
													?.limitsControl
											}
											errorMessage={
												errors.paymentTechnicalAnalysis?.technicalAnalysis
													?.additionalActivityControlList?.[didaticIndex]
													?.limitsControl
											}
											suffixLabel=' €'
										/>
									</td>

									<td>
										{maxFixed(
											calculateDeviation(
												AdditionalActivityControlType.DIDACTIC,
												didaticIndex
											)
										) + '€'}
									</td>

									<td>
										<DecimalInputField
											name={`paymentTechnicalAnalysis.technicalAnalysis.additionalActivityControlList[${didaticIndex}].decisionProposal`}
											value={
												formikValues.paymentTechnicalAnalysis.technicalAnalysis
													.additionalActivityControlList[didaticIndex]
													.decisionProposal
											}
											handleChange={handleChange}
											isInvalid={
												errors.paymentTechnicalAnalysis?.technicalAnalysis
													?.additionalActivityControlList?.[didaticIndex]
													?.decisionProposal
											}
											errorMessage={
												errors.paymentTechnicalAnalysis?.technicalAnalysis
													?.additionalActivityControlList?.[didaticIndex]
													?.decisionProposal
											}
											suffixLabel=' €'
										/>
									</td>
								</tr>

								<tr>
									<td>
										<FormattedMessage id='annualComplementaryActivitiesPlanForm.incubationCosts' />
									</td>

									<td>
										<DecimalInputField
											disabled
											name={`paymentTechnicalAnalysis.technicalAnalysis.additionalActivityControlList[${incubationProjectsIndex}].budget`}
											value={
												formikValues.paymentTechnicalAnalysis.technicalAnalysis
													.additionalActivityControlList[incubationProjectsIndex]
													.budget
											}
											handleChange={handleChange}
											isInvalid={
												errors.paymentTechnicalAnalysis?.technicalAnalysis
													?.additionalActivityControlList?.[incubationProjectsIndex]
													?.budget
											}
											errorMessage={
												errors.paymentTechnicalAnalysis?.technicalAnalysis
													?.additionalActivityControlList?.[incubationProjectsIndex]
													?.budget
											}
											suffixLabel=' €'
										/>
									</td>

									<td>
										<DecimalInputField
											name={`paymentTechnicalAnalysis.technicalAnalysis.additionalActivityControlList[${incubationProjectsIndex}].limitsControl`}
											value={
												formikValues.paymentTechnicalAnalysis.technicalAnalysis
													.additionalActivityControlList[
													incubationProjectsIndex
												].limitsControl
											}
											handleChange={handleChange}
											isInvalid={
												errors.paymentTechnicalAnalysis?.technicalAnalysis
													?.additionalActivityControlList?.[
													incubationProjectsIndex
												]?.limitsControl
											}
											errorMessage={
												errors.paymentTechnicalAnalysis?.technicalAnalysis
													?.additionalActivityControlList?.[
													incubationProjectsIndex
												]?.limitsControl
											}
											suffixLabel=' €'
										/>
									</td>

									<td>
										{maxFixed(
											calculateDeviation(
												AdditionalActivityControlType.INCUBATION_PROJECTS,
												incubationProjectsIndex
											)
										) + '€'}
									</td>

									<td>
										<DecimalInputField
											name={`paymentTechnicalAnalysis.technicalAnalysis.additionalActivityControlList[${incubationProjectsIndex}].decisionProposal`}
											value={
												formikValues.paymentTechnicalAnalysis.technicalAnalysis
													.additionalActivityControlList[
													incubationProjectsIndex
												].decisionProposal
											}
											handleChange={handleChange}
											isInvalid={
												errors.paymentTechnicalAnalysis?.technicalAnalysis
													?.additionalActivityControlList?.[
													incubationProjectsIndex
												]?.decisionProposal
											}
											errorMessage={
												errors.paymentTechnicalAnalysis?.technicalAnalysis
													?.additionalActivityControlList?.[
													incubationProjectsIndex
												]?.decisionProposal
											}
											suffixLabel=' €'
										/>
									</td>
								</tr>

								<tr>
									<td>Total</td>

									<td>
										{maxFixed(totalComplementaryActivities('budget')) +
											'€'}
									</td>

									<td>
										{maxFixed(totalComplementaryActivities('limitsControl')) +
											'€'}
									</td>

									<td>{maxFixed(totalDeviation()) + '€'}</td>

									<td>
										{maxFixed(
											totalComplementaryActivities('decisionProposal')
										) + '€'}
									</td>
								</tr>
							</tbody>
						</Table>
					</Col>
				</Row>

				<Row className={'mt-4'}>
					<Col md='6'>
						<DecimalInputField
							disabled
							suffixLabel=' €'
							name={'paymentTechnicalAnalysis.amountToPay'}
							handleChange={handleChange}
							labelId='complementaryActivitiesPaymentRequest.technicalAnalysis.valueToPay'
							value={payoutValue()}
							isInvalid={errors.paymentTechnicalAnalysis?.amountToPay}
							errorMessage={errors.paymentTechnicalAnalysis?.amountToPay}
							toolTip={
								<ToolTip
									message={
										<FormattedMessage id='complementaryActivitiesPaymentRequest.technicalAnalysis.valueToPay.toolTip' />
									}
								/>
							}
						/>
					</Col>

					<Col md='6'>
						<DecimalInputField
							disabled
							suffixLabel=' €'
							name={'paymentTechnicalAnalysis.amountToReturn'}
							handleChange={handleChange}
							labelId='complementaryActivitiesPaymentRequest.technicalAnalysis.refund'
							value={collectValue()}
							isInvalid={errors.paymentTechnicalAnalysis?.amountToReturn}
							errorMessage={errors.paymentTechnicalAnalysis?.amountToReturn}
							toolTip={
								<ToolTip
									message={
										<FormattedMessage id='complementaryActivitiesPaymentRequest.technicalAnalysis.refund.toolTip' />
									}
								/>
							}
						/>
					</Col>
				</Row>

				<Row className='mt-5'>
					<small className='text-primary text-uppercase'>
						<FormattedMessage id='annualComplementaryActivitiesPlanForm.analysisAndOpinion' />
					</small>
				</Row>
				<Row>
					<Col>
						<TextAreaInputField
							name='paymentTechnicalAnalysis.technicalAnalysis.technicalProposalJustification'
							value={
								formikValues.paymentTechnicalAnalysis.technicalAnalysis
									.technicalProposalJustification
							}
							labelId='annualComplementaryActivitiesPlanForm.proposalReason'
							handleChange={handleChange}
							isInvalid={
								errors.paymentTechnicalAnalysis?.technicalAnalysis
									?.technicalProposalJustification
							}
							errorMessage={
								errors.paymentTechnicalAnalysis?.technicalAnalysis
									?.technicalProposalJustification
							}
							maxLength={5000}
							placeholder={intl.formatMessage(
								{
									id: 'annualComplementaryActivitiesPlanForm.characters.placeholder',
								},
								{ nChars: '5000' }
							)}
						/>
					</Col>
				</Row>

				<Row>
					<Col>
						<TextInputField
							labelId='annualComplementaryActivitiesPlanForm.technician'
							name='paymentTechnicalAnalysis.technicalAnalysis.technicianName'
							value={
								formikValues.paymentTechnicalAnalysis.technicalAnalysis
									.technicianName
							}
							handleChange={handleChange}
							isInvalid={
								errors.paymentTechnicalAnalysis?.technicalAnalysis
									?.technicianName
							}
							errorMessage={
								errors.paymentTechnicalAnalysis?.technicalAnalysis
									?.technicianName
							}
						/>
					</Col>
				</Row>

				<Row className='mt-5'>
					<small className='text-primary text-uppercase'>
						<FormattedMessage id='annualComplementaryActivitiesPlanForm.decision' />
					</small>
				</Row>

				<Row>
					<Col md='6'>
						<DecimalInputField
							name='paymentTechnicalAnalysis.technicalAnalysis.approvedAmount'
							value={
								formikValues.paymentTechnicalAnalysis.technicalAnalysis
									.approvedAmount
							}
							handleChange={handleChange}
							isInvalid={
								errors.paymentTechnicalAnalysis?.technicalAnalysis
									?.approvedAmount
							}
							errorMessage={
								errors.paymentTechnicalAnalysis?.technicalAnalysis
									?.approvedAmount
							}
							labelId='annualComplementaryActivitiesPlanForm.totalApprovedAmount'
							suffixLabel=' €'
						/>
					</Col>
					<Col md='6'>
						<DecimalInputField
							name='paymentTechnicalAnalysis.technicalAnalysis.advanceAmount'
							value={
								formikValues.paymentTechnicalAnalysis.technicalAnalysis
									.advanceAmount
							}
							handleChange={handleChange}
							isInvalid={
								errors.paymentTechnicalAnalysis?.technicalAnalysis
									?.advanceAmount
							}
							errorMessage={
								errors.paymentTechnicalAnalysis?.technicalAnalysis
									?.advanceAmount
							}
							labelId='annualComplementaryActivitiesPlanForm.advanceAmount'
							suffixLabel=' €'
							toolTip={
								<ToolTip
									message={
										<FormattedMessage id='annualComplementaryActivitiesPlanForm.advanceAmount.toolTip' />
									}
								/>
							}
						/>
					</Col>
				</Row>

				<Row>
					<Col md='6'>
						<DecimalInputField
							disabled
							name='paymentTechnicalAnalysis.decisionAmountToPay'
							value={payoutValue()}
							handleChange={handleChange}
							isInvalid={errors.paymentTechnicalAnalysis?.decisionAmountToPay}
							errorMessage={
								errors.paymentTechnicalAnalysis?.decisionAmountToPay
							}
							labelId='complementaryActivitiesPaymentRequest.technicalAnalysis.valueToPay'
							suffixLabel=' €'
						/>
					</Col>
					<Col md='6'>
						<DecimalInputField
							disabled
							name='paymentTechnicalAnalysis.decisionAmountToReturn'
							value={collectValue()}
							handleChange={handleChange}
							isInvalid={
								errors.paymentTechnicalAnalysis?.decisionAmountToReturn
							}
							errorMessage={
								errors.paymentTechnicalAnalysis?.decisionAmountToReturn
							}
							labelId='complementaryActivitiesPaymentRequest.technicalAnalysis.refund'
							suffixLabel=' €'
						/>
					</Col>
				</Row>
				<Row className='mb-3'>
					<Col md='6'>
						<TextInputField
							className='w-25'
							name='paymentTechnicalAnalysis.technicalAnalysis.decisionDispatchNumber'
							value={formikValues.paymentTechnicalAnalysis.technicalAnalysis.decisionDispatchNumber}
							labelId='annualComplementaryActivitiesPlanForm.disptachInformationNumber'
							handleChange={handleChange}
							isInvalid={errors.paymentTechnicalAnalysis?.technicalAnalysis?.decisionDispatchNumber}
							errorMessage={errors.paymentTechnicalAnalysis?.technicalAnalysis?.decisionDispatchNumber}
						/>
					</Col>
					<Col md='6'>
						<DateTimeInputField
							labelId='annualComplementaryActivitiesPlanForm.disptachDate'
							name='paymentTechnicalAnalysis.technicalAnalysis.decisionDispatchDate'
							value={formikValues.paymentTechnicalAnalysis.technicalAnalysis.decisionDispatchDate}
							setFieldValue={setFieldValue}
							isDateWithNoTime={true}
							isInvalid={errors.paymentTechnicalAnalysis?.technicalAnalysis?.decisionDispatchDate}
							errorMessage={errors.paymentTechnicalAnalysis?.technicalAnalysis?.decisionDispatchDate}
						/>
					</Col>
				</Row>

				{isIEFPUser() && <Row>
					<Col md='12'>
						<SingleUploadArea
							documents={documents}
							setDocuments={setDocuments}
							documentType={DocumentType.SERVICE_INFORMATION}
							setError={setError}
							processUploadCallback={uploadCallback}
							processDeleteCallback={deleteCallback}
						/>
					</Col>
				</Row>}

				<Row>
					<Col md='12'>
						<SingleUploadArea
							documents={documents}
							setDocuments={setDocuments}
							documentType={DocumentType.NOTIFICATION_TO_EA}
							setError={setError}
							processUploadCallback={uploadCallback}
							processDeleteCallback={deleteCallback}
						/>
					</Col>
				</Row>
			</fieldset>

			{/* Voltar */}
			<div className='form-actions mt-5'>
				<button
					className='btn btn-link'
					type='button'
					onClick={handlePreviousStep}
				>
					<FormattedMessage id='all.back' />
				</button>
			</div>
		</>
	);
}
