import React from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import ToolTip from './ToolTip';

export function TextInputField({
  name,
  value,
  handleChange,
  labelId,
  label,
  errorMessage,
  isInvalid,
  controlId,
  disabled,
  placeholder,
  labelClass,
  isPassword,
  maxLength,
  toolTip,
  className,
  showErrorOnToolTip,onlyNumber
}) {


  const onChange = (e) => {

    if(maxLength && e.target.value.length >maxLength){
      e.target.value=e.target.value.substring(0,maxLength)
    }

    handleChange(e);
  };


  return (
    <Form.Group controlId={controlId || name}>
      {(labelId || label) && (
        <Form.Label className={labelClass}>
          {labelId && <FormattedMessage id={labelId} />}
          {label && label}
          {toolTip && toolTip}
        </Form.Label>
      )}
      <InputGroup>
        <Form.Control
          className={className}
          type={isPassword ? 'password' : onlyNumber? 'number': 'text'}
          name={name}
          value={value}
          isInvalid={isInvalid}
          disabled={disabled}
          placeholder={placeholder}
          onChange={onChange}
          maxLength={maxLength ? maxLength : null}
        />
        {showErrorOnToolTip && isInvalid && <ToolTip error='true' message={errorMessage} />}
      </InputGroup>
      {errorMessage && !showErrorOnToolTip && (
        <Form.Control.Feedback type='invalid'>{errorMessage}</Form.Control.Feedback>
      )}
    </Form.Group>
  );
}
