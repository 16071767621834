import React from "react";
import {FormattedMessage} from "react-intl";
import {currencyFormat} from "../../utils/CurrencyUtils";
import {CandidaturePaymentType} from "../../models/CandidaturePaymentType";
import {PaymentEntryDocumentLink} from "./PaymentEntryDocumentLink";

export function PaymentEntry({payment}) {
    return (
        <tr>
            <td>{payment.date}</td>
            <td>
                <FormattedMessage id={`candidature.payment.purpose.${payment.purpose}`} />
            </td>
            <td>
                <FormattedMessage id={`candidature.payment.type.${payment.type}`} />
                &nbsp;
                {payment.type === CandidaturePaymentType.FINAL && (
                    <PaymentEntryDocumentLink document={payment.document}/>
                )}
            </td>
            <td className='text-end'>
                {currencyFormat(payment.value.toFixed(2))}
            </td>
        </tr>
    );
}