import {Modal} from "react-bootstrap";
import React, {useState} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {EaADAppealForm} from "./EaADAppealForm";

export function EaADAppealModal({entityNif, show, setShow, onSubmitCallback, documents, setDocuments}) {
    const intl = useIntl();
    const [error, setError] = useState(null);
    const [entity, setEntity] = useState(null);

    async function onSubmit() {
        try {
            setShow(false);
            onSubmitCallback?.()
        } catch (error) {
            setError(error);
        }
    }

    function handleClose() {
        setShow(false);
    }

    return (
        <Modal show={show} onHide={handleClose} dialogClassName=''>
            <Modal.Header closeButton>
                <Modal.Title><FormattedMessage id='candidature.button.contest' /></Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <EaADAppealForm
                    entityNif={entityNif}
                    documents={documents}
                    setDocuments={setDocuments}
                    onCancel={() => setShow(false)}
                    onSubmitCallback={onSubmit}
                />
            </Modal.Body>
        </Modal>

    );
}