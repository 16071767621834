import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import {Link, Route, Routes, useNavigate} from 'react-router-dom';
import {
  getUserInfo,
  hasAnyPermission,
  hasPermission, isBackOfficeAdmin,
  isEntityUser,
  isIEFPUser,
  isPromoterUser
} from '../../authentication/authenticationHelper';
import { useGlobalContext } from '../../context';
import { CandidatureState } from '../../models/CandidatureState';
import { candidatureWithdrawal } from '../../rest/candidature';
import { isJustMentioned } from '../../rest/promoterMetaData';
import { CandidatureWithdrawModal } from './CandidatureWithdrawModal';
import { EntityMessageToPromoterModal } from './EntityMessageToPromoterModal';
import { CandidatureFlowAcceptByPromoter } from './flow/CandidatureFlowAcceptByPromoter';
import { CandidatureFlowApproveEligiblePromoters } from './flow/CandidatureFlowApproveEligiblePromoters';
import { CandidatureFlowClarifyDoubts } from './flow/CandidatureFlowClarifyDoubts';
import { CandidatureFlowRefuseByPromoter } from './flow/CandidatureFlowRefuseByPromoter';
import { CandidatureFlowRejectCandidature } from './flow/CandidatureFlowRejectCandidature';
import { CandidatureFlowSendToClarificationDoubtsForOpinion } from './flow/CandidatureFlowSendToClarificationDoubtsForOpinion';
import { CandidatureFlowSendToClarificationDoubtsForValidation } from './flow/CandidatureFlowSendToClarificationDoubtsForValidation';
import { CandidatureFlowSendToEntityOpinionReview } from './flow/CandidatureFlowSendToEntityOpinionReview';
import { PriorSupportMessageModal } from './PriorSupportMessageModal ';
import { ConsultingRequestState } from '../../models/ConsultingRequestState';
import {IEFPMessageToPromoterModal} from "./IEFPMessageToPromoterModal";
import {DeclarationEndFirstYearButton} from "../declarationEndFirstYear/DeclarationEndFirstYearButton";
import {CandidatureFlowDeleteAddDocumentsCandidature} from "./flow/CandidatureFlowDeleteAddDocumentsCandidature";

export function CandidatureActionsMenuBar({
  candidature,
  callbackHandler,
  setError,
  canValidate,
  consultingRequest,
  promoterHasPendingEvaluations
}) {

  const { accessInfo,setCanEditProfile } = useGlobalContext();


  const navigate = useNavigate();

  const [justMentioned, setJustMentioned] = useState(false);
  const [showEntityMessagePromoterModal, setShowEntityMessagePromoterModal] = useState(false);
  const [showIEFPMessagePromoterModal, setShowIEFPMessagePromoterModal] = useState(false);
  const [showApprovalChoicesModal, setShowApprovalChoicesModal] = useState(false);
  const [showMCEEntityMessageModal, SetShowMCEEntityMessageModal] = useState(false);


  const [showWithdrawModal, setShowWithdrawModal] = useState(false);
  const userInfo = getUserInfo();


  useEffect(() => {
    const fetchIsMentionedData = async () => {
      if (candidature && CandidatureState.SUBMITTED === candidature.state) {
        const { data } = await isJustMentioned(candidature.externalId);
        setJustMentioned(data.flag);
      }
    };
    fetchIsMentionedData();




console.log(candidature?.submissionDate)
  }, [candidature]);

  const candidatureIsWaitPromoterEligibilityValidation = () => {
    return CandidatureState.WAIT_PROMOTER_ELIGIBILITY_VALIDATION === candidature?.state   && candidature.regionalDelegationCode === userInfo?.rd;
  };

  const candidatureCanSentOpinionArtsAndCrafts = () => {
    return candidature?.canSentOpinionArtsAndCrafts === true;
  };

  const isMainPromoter = () => {
    return candidature?.mainPromoter === true;
  };

  const candidatureIsEmitOpinion = () => {
    return CandidatureState.EMIT_OPINION === candidature?.state || CandidatureState.NEW_EMIT_OPINION === candidature?.state;
  };

  const candidatureIsWaitContestation = () => {
    return (
      CandidatureState.WAIT_CONTESTATION === candidature?.state ||
      CandidatureState.WAIT_CONTESTATION_EXPIRATION === candidature?.state ||
      CandidatureState.WAIT_CONTESTATION_ELIGIBILITY_VALIDATION === candidature?.state

    );
  };

  const candidatureIsContestationAnalysis = () => {
    return (
      CandidatureState.CONTESTATION_ANALYSIS === candidature?.state ||
      CandidatureState.CONTESTATION_ANALYSIS_EXPIRATION === candidature?.state ||
      CandidatureState.CONTESTATION_ANALYSIS_ELIGIBILITY_VALIDATION === candidature?.state
    );
  };

  const candidatureIsFinalDecision = () => {
    return (CandidatureState.FINAL_DECISION === candidature?.state || CandidatureState.FINAL_DECISION_WAIT_RESPONSE === candidature?.state) && candidature.regionalDelegationCode === userInfo?.rd;
  };

  const candidatureIsWaitingClarifyDoubt = () => {
    return [
      CandidatureState.WAIT_PROMOTER_RESPONSE_TO_OPINION,
      CandidatureState.WAIT_PROMOTER_RESPONSE_TO_VALIDATION,
      CandidatureState.WAIT_PROMOTER_RESPONSE_TO_ELIGIBILITY
    ].includes(candidature?.state);
  };

  function goToSubmitArtCraftOpinion() {
    navigate('/candidatura/' + candidature.externalId + '/parecerArtesOficios', {
      state: { refresh: true }
    });
  }

  const candidatureIsValidation = () => {
    return candidature?.state === CandidatureState.VALIDATION && candidature.regionalDelegationCode === userInfo?.rd;
  };

  function goToSubmitTermsOfAcceptDraft() {
    navigate('/candidatura/' + candidature.externalId + '/minutaTermoAceitacao', {
      state: { refresh: true }
    });
  }

  function handleCloseApprovalChoicesModal() {
    setShowApprovalChoicesModal(false);
  }

  function goToSubmitTermsOfAccept() {
    navigate('/candidatura/' + candidature.externalId + '/termoAceitacao', {
      state: { refresh: true }
    });
  }

  function goToSubmitExpirationApprovalDecision() {
    navigate('/candidatura/' + candidature.externalId + '/emitirDecisaoFinalCadudcidade', {
      state: { refresh: true }
    });
  }

  function goToHistory() {
    navigate('/candidatura/' + candidature.externalId + '/historico', { state: { refresh: true } });
  }


  function goToOversight() {
    navigate('/candidatura/' + candidature.externalId + '/acompanhamento', { state: { refresh: true } });
  }

  function candidatureIsWaitingToSubmitTermsOfAcceptDraft() {
    return CandidatureState.WAIT_TERM_ACCEPT === candidature?.state && candidature.regionalDelegationCode === userInfo?.rd;
  }

  function candidatureIsWaitingToSubmitTermsOfAccept() {
    return CandidatureState.WAIT_TERM_ACCEPT_SUBMISSION === candidature?.state && candidature.regionalDelegationCode === userInfo?.rd;
  }

  function goToSubmitRequiredDocuments() {
    return navigate(`/candidatura/${candidature.externalId}/documentosObrigatorios`);
  }

  function goToContestation() {
    return navigate(`/candidatura/${candidature.externalId}/contestacao`);
  }

  function goToAdditionalDueDiligence() {
    return navigate(`/candidatura/${candidature.externalId}/diligenciasComplementares`);
  }

  function goToAdditionalDueDiligenceAnalysis() {
    return navigate(`/candidatura/${candidature.externalId}/analiseDiligenciasComplementares`);
  }

  function goToFinalDecision() {
    return navigate(`/candidatura/${candidature.externalId}/finalDecision`);
  }

  function candidatureStateMatches(state) {
    return candidature.state === state;
  }

  function candidatureIsWaitingToSubmitDecisionExpiration() {
    return CandidatureState.APPROVAL_DECISION_EXPIRATION === candidature?.state && candidature.regionalDelegationCode === userInfo?.rd;
  }

  function candidatureIsWaitingPromotersValidate() {
    return candidature && CandidatureState.SUBMITTED === candidature.state && justMentioned;
  }

  function isCandidatureStateBeforeVALIDATION() {
    return candidature && CandidatureState.statesBeforeVALIDATION().includes(candidature.state);
  }
  function isCandidatureAvailableForAdditionalDueDiligence() {
    return candidature && CandidatureState.additionalDueDiligenceStates().includes(candidature.state);
  }

  function goToContestationAnalysis() {
    if (CandidatureState.CONTESTATION_ANALYSIS_EXPIRATION === candidature.state) {
      navigate('/candidatura/' + candidature.externalId + '/analiseContestacaoCaducidade', {
        state: { refresh: true }
      });
    }else if(CandidatureState.CONTESTATION_ANALYSIS_ELIGIBILITY_VALIDATION=== candidature.state){
      navigate('/candidatura/' + candidature.externalId + '/analiseContestacaoEligibilidade', {
        state: { refresh: true }
      });
    } else {
      navigate('/candidatura/' + candidature.externalId + '/analiseContestacao', {
        state: { refresh: true }
      });
    }
  }

  function goToEmitDecisionExpiration() {
    navigate('/candidatura/' + candidature.externalId + '/emitirCaducidade', {
      state: { refresh: true }
    });
  }


  function goToIncubation() {
    navigate('/candidatura/' + candidature.externalId + '/incubacao', {
      state: { refresh: true }
    });
  }






  function goToOpinionEntity() {
    navigate(`/candidatura/${candidature.externalId}/parecer/contactos`, {
      state: { refresh: true }
    });
  }


  function  goToB6() {
    navigate(`/identificacaoprojeto/${candidature.processB6ExternalId}/validar`, {
      state: { refresh: true }
    });
  }



  function goToValidateRequiredDocuments() {
    navigate(`/candidatura/${candidature.externalId}/validarDocumentosObrigatorios`);
  }

  function goToIssueFeasibility() {
    navigate(`/candidatura/${candidature.externalId}/emitirParecer`);
  }

  async function handleCandidatureWithdrawal(user, password, setModalError) {
    try {
      let authenticationCredentials = {
        user: user,
        password: password
      };
      let isCancel = candidature.state === CandidatureState.DRAFT;
      await candidatureWithdrawal(authenticationCredentials, candidature.externalId);
      if(isCancel===true){
        sessionStorage.setItem('candidatureCancel', true);
      }else{
        sessionStorage.setItem('candidatureWithdrawed', true);
      }
      setCanEditProfile(true)
      navigate('/candidatura');
    } catch (error) {
      setModalError(error);
    }
  }

  return (
    <div className='d-grid gap-4'>
      <CandidatureWithdrawModal
        show={showWithdrawModal}
        handleClose={() => setShowWithdrawModal(false)}
        withdrawSubmitHandler={handleCandidatureWithdrawal}
        candidature={candidature}
      />
      <PriorSupportMessageModal
        show={showMCEEntityMessageModal}
        setShow={SetShowMCEEntityMessageModal}
        candidatureExternalId={candidature.externalId}
        isMCE={true}
      />
      {(
          CandidatureState.DELETED === candidature.state
          && isIEFPUser()
          && candidature.regionalDelegationCode === userInfo?.rd
          && candidature.finalDeleteDecisionEmitted !== true
      ) &&(
          <CandidatureFlowDeleteAddDocumentsCandidature
              callbackHandler={callbackHandler}
          />
      )}


      {candidatureIsWaitingPromotersValidate() && (
        <>
          {hasPermission('FLOW_CANDIDATURE_PROMOTER_ACCEPT', accessInfo) && (
            <CandidatureFlowAcceptByPromoter
              callbackHandler={callbackHandler}
              canValidate={canValidate}
            />
          )}
          {hasPermission('FLOW_CANDIDATURE_PROMOTER_REFUSE', accessInfo) && (
            <CandidatureFlowRefuseByPromoter callbackHandler={callbackHandler} />
          )}
        </>
      )}
      {candidatureIsWaitPromoterEligibilityValidation() && (
        <>
          {hasPermission('FLOW_CANDIDATURE_SET_TO_ENTITY_OPINION', accessInfo) && (
            <CandidatureFlowApproveEligiblePromoters callbackHandler={callbackHandler} />
          )}
          {hasPermission('FLOW_CANDIDATURE_SET_TO_FINAL_DECISION', accessInfo) && (
            <CandidatureFlowRejectCandidature callbackHandler={callbackHandler} />
          )}
        </>
      )}
      {candidature.processB6ExternalId &&
      <Button variant='outline-primary' onClick={goToB6}>
        <FormattedMessage id='detailPriorSupport.button.identificationSheet' />
      </Button>
      }
      {candidatureIsEmitOpinion() && (
        <>


        {(hasPermission('FLOW_CANDIDATURE_SET_TO_ENTITY_OPINION', accessInfo) && candidature.regionalDelegationCode === userInfo?.rd) && (
            <Button variant='outline-primary' onClick={goToOpinionEntity}>
              <FormattedMessage id='candidature.flow.opinionEntity' />
            </Button>
        )}



          {hasPermission('FLOW_CANDIDATURE_SET_TO_OPINION_VALIDATION', accessInfo) && (

            <Button variant='outline-primary' onClick={goToIssueFeasibility}>
              <FormattedMessage id='candidature.flow.issueFeasibilityOpinion' />
            </Button>
          )}
          {hasPermission(
            'FLOW_CANDIDATURE_SEND_TO_PROMOTER_FOR_DOUBTS_CLARIFICATION',
            accessInfo
          ) && (
            <CandidatureFlowSendToClarificationDoubtsForOpinion callbackHandler={callbackHandler} />
          )}
        </>
      )}
      {(isIEFPUser() || isBackOfficeAdmin()) && candidature.hasFeasibilityOpinion &&
          <Button variant='outline-primary' onClick={goToIssueFeasibility}>
            <FormattedMessage id='candidature.flow.visualizeFeasibilityOpinion' />
          </Button>
      }
      {candidatureIsValidation() && (
        <>
          {hasPermission('FLOW_CANDIDATURE_SEND_TO_ENTITY_OPINION_REVIEW', accessInfo) && (
            <CandidatureFlowSendToEntityOpinionReview
              callbackHandler={callbackHandler}
              setError={setError}
            />
          )}

          {hasPermission('FLOW_CANDIDATURE_APPROVE', accessInfo) &&
            candidature?.canFinishValidation && (
              <Button
                variant='outline-primary'
                onClick={() => {
                  setShowApprovalChoicesModal(true);
                }}>
                <FormattedMessage id='candidatureForm.emitDecision' />
              </Button>
            )}

          {hasPermission(
            'FLOW_CANDIDATURE_IN_VALIDATION_SEND_PROMOTER_DOUBTS_CLARIFICATION',
            accessInfo
          ) && (
            <CandidatureFlowSendToClarificationDoubtsForValidation
              callbackHandler={callbackHandler}
              setError={setError}
            />
          )}
        </>
      )}





      {candidatureIsWaitingClarifyDoubt() &&
        isMainPromoter() &&
        hasPermission('FLOW_CANDIDATURE_CLARIFY_DOUBT', accessInfo) && (
          <CandidatureFlowClarifyDoubts
            callbackHandler={callbackHandler}
            documentType={'CLARIFICATION'}
            setError={setError}
          />
        )}
      {candidatureCanSentOpinionArtsAndCrafts() && (
        <>
          <Button variant='outline-primary' onClick={goToSubmitArtCraftOpinion}>
            <FormattedMessage id='candidature.flow.submitArtCraftOpinion' />
          </Button>
        </>
      )}
      {candidatureStateMatches(CandidatureState.APPROVED) && isMainPromoter() && (
        <Button variant='outline-primary' onClick={goToSubmitRequiredDocuments}>
          <FormattedMessage id='candidature.flow.submitRequiredDocuments' />
        </Button>
      )}
      {(candidatureIsWaitContestation() && isMainPromoter()) && (
          <>
            {hasPermission('ADD_CONTESTATION', accessInfo) && (
                <Button variant='outline-primary' onClick={goToContestation}>
                  <FormattedMessage id='candidature.button.contest' />
                </Button>
            )}
          </>
      )}
      {candidatureIsFinalDecision() && (
        <>
          {hasPermission('ADD_FINAL_DECISION', accessInfo) && (
            <Button variant='outline-primary' onClick={goToFinalDecision}>
              <FormattedMessage id='candidature.button.finalDecision' />
            </Button>
          )}
        </>
      )}
      {candidatureIsWaitingToSubmitTermsOfAcceptDraft() &&
        hasPermission('ADD_TERM_ACCEPT_DRAFT', accessInfo) && (
          <>
            <Button variant='outline-primary' onClick={goToSubmitTermsOfAcceptDraft}>
              <FormattedMessage id='candidature.flow.submitTermsOfAcceptDraft' />
            </Button>
          </>
        )}
      {candidatureIsWaitingToSubmitTermsOfAccept() &&
        hasPermission('ADD_TERM_ACCEPT_DRAFT', accessInfo) && (
          <>
            <Button variant='outline-primary' onClick={goToSubmitTermsOfAccept}>
              <FormattedMessage id='candidature.flow.submitTermsOfAccept' />
            </Button>
          </>
        )}
      {candidatureIsWaitingToSubmitDecisionExpiration() &&
        hasPermission('ADD_APPROVAL_DECISION_EXPIRATION', accessInfo) && (
          <>
            <Button variant='outline-primary' onClick={goToSubmitExpirationApprovalDecision}>
              <FormattedMessage id='candidature.flow.submitExpirationApprovalDecision' />
            </Button>
          </>
        )}
      {hasPermission('GET_CANDIDATURE_HISTORY', accessInfo) && (
        <Button variant='outline-primary' onClick={goToHistory}>
          <FormattedMessage id='candidature.button.history' />
        </Button>
      )}


      {  (!isBackOfficeAdmin() && ( candidatureStateMatches(CandidatureState.FULLY_APPROVED) || candidatureStateMatches(CandidatureState.REVOKED_NON_COMPLIANCE) )) && (
              <Button variant='outline-primary' onClick={goToOversight}>
                <FormattedMessage id='candidatureTabs.oversight' />
              </Button>
      )}

      {isCandidatureStateBeforeVALIDATION() &&
        isPromoterUser() &&
        !justMentioned &&
        !candidature.approvalType && (
          <>
            <Button variant='outline-primary' onClick={() => setShowWithdrawModal(true)}>
              <FormattedMessage id={candidature.state === CandidatureState.DRAFT ? 'all.cancel':'all.candidatureWithdrawal' } />
            </Button>
          </>
        )}


      {isIEFPUser() &&
      (candidatureStateMatches(CandidatureState.VALIDATE_REQUIRED_DOCUMENTS) ) &&
      hasPermission('VALIDATE_REQUIRED_DOCUMENTS', accessInfo) && candidature.regionalDelegationCode === userInfo?.rd &&(
          <Button variant='outline-primary' onClick={goToValidateRequiredDocuments}>
            <FormattedMessage id='candidature.button.validateRequiredDocuments' />
          </Button>
      )}

      {isIEFPUser() &&
      (candidatureStateMatches(CandidatureState.VALIDATE_REQUIRED_DOCUMENTS)  || candidatureStateMatches(CandidatureState.WAIT_PROMOTER_ELIGIBILITY_VALIDATION)) &&
        hasPermission('VALIDATE_REQUIRED_DOCUMENTS', accessInfo) && candidature.regionalDelegationCode === userInfo?.rd &&(
            <CandidatureFlowSendToClarificationDoubtsForValidation
              callbackHandler={callbackHandler}
              setError={setError}
              candidatureState={candidature.state}
            />
        )}

      {candidatureIsContestationAnalysis() &&
        hasPermission('FLOW_CANDIDATURE_CONTESTATION_ANALYSIS', accessInfo) && candidature.regionalDelegationCode === userInfo?.rd &&(
          <>
            <Button variant='outline-primary' onClick={goToContestationAnalysis}>
              <FormattedMessage id='candidature.flow.contestationAnalysis' />
            </Button>
          </>
        )}

      {isEntityUser() && (
        <>
          <Button
            variant='outline-primary'
            onClick={() => {
              setShowEntityMessagePromoterModal(true);
            }}>
            <FormattedMessage id='candidatureForm.messagePriorSupportPromoter' />
          </Button>
        </>
      )}

      {isIEFPUser() && candidature.regionalDelegationCode === userInfo?.rd &&(
          <>
            <Button
                variant='outline-primary'
                onClick={() => {
                  setShowIEFPMessagePromoterModal(true);
                }}>
              <FormattedMessage id='candidature.title.contactPromoter' />
            </Button>
          </>
      )}

      {isIEFPUser() && isCandidatureAvailableForAdditionalDueDiligence() &&(
          <>
            <Button
                variant='outline-primary'
                onClick={goToAdditionalDueDiligence}>
              <FormattedMessage id='candidature.flow.addAdditionalDueDiligence' />
            </Button>
          </>
      )}





      {isIEFPUser() &&
        hasPermission('ADD_APPROVAL_DECISION_EXPIRATION', accessInfo) &&
        candidature.state === CandidatureState.EMIT_DECISION_EXPIRATION && candidature.regionalDelegationCode === userInfo?.rd &&(
          <>
            <Button variant='outline-primary' onClick={goToEmitDecisionExpiration}>
              <FormattedMessage id='candidature.flow.emitDecisionExpiration' />
            </Button>
          </>
        )}

      {candidatureStateMatches(CandidatureState.FULLY_APPROVED) &&
        isMainPromoter() &&
        hasPermission('ASK_MCE_CONSULTING', accessInfo) &&
        (!consultingRequest ||
          ConsultingRequestState.getValidStates().includes(consultingRequest.state)) && (
          <Button
            variant='outline-primary'
            onClick={() =>
              navigate(`/candidatura/${candidature.externalId}/consultoria`, {
                state: {
                  districtCode: candidature.parish.substring(0, 2)
                }
              })
            }>
            <FormattedMessage id='candidature.flow.askMceConsulting' />
          </Button>
        )}

      {candidature.state === CandidatureState.FULLY_APPROVED &&
        consultingRequest &&
        consultingRequest.state !== ConsultingRequestState.REJECTED   &&
        isMainPromoter() && (
          <button
            className='btn btn-outline-primary d-flex align-items-center justify-content-center mb-0 w-100'
            type='button'
            onClick={() => {
              SetShowMCEEntityMessageModal(true);
            }}>
            <FormattedMessage id='candidatureForm.messagePriorSupportEntity' />
          </button>
        )}

      {(candidature.state !== CandidatureState.DRAFT &&
      isIEFPUser() && candidature.regionalDelegationCode === userInfo?.rd) && (
          <Button
              variant='outline-primary'
              onClick={() =>
                  navigate(`/candidatura/${candidature.externalId}/documentosExtra`)
              }>
           Documentos Extra
          </Button>
      )}

      {candidature.state === CandidatureState.FULLY_APPROVED &&
      consultingRequest &&
      (consultingRequest.state === ConsultingRequestState.COMPLETED || consultingRequest.state === ConsultingRequestState.REVOKED || consultingRequest.state===ConsultingRequestState.AWAIT_REVOKE_DECISION) && (
          <button
              className='btn btn-outline-primary d-flex align-items-center justify-content-center mb-0 w-100'
              type='button'
              onClick={() => { navigate('/pedidosMCE/'+consultingRequest.externalId);
              }}>
            <FormattedMessage id='candidatureForm.consultingRequest' />
          </button>
      )}


      {candidature.canListInterestExpression===true &&
      <Button variant='outline-primary' onClick={goToIncubation}>
        <FormattedMessage id='candidature.flow.incubation' />
      </Button>


      }


        {isPromoterUser() && promoterHasPendingEvaluations && (
          <button
            className='btn btn-outline-primary d-flex align-items-center justify-content-center mb-4 w-100'
            type='button'
            onClick={() => { navigate('/avaliacoes')
            }}>
            <FormattedMessage id='evaluations.text' />
          </button>
        )}

      {isPromoterUser() && candidature.state === CandidatureState.WAIT_ADDITIONAL_DUE_DILIGENCE_RESPONSE && (
          <button
              className='btn btn-outline-primary d-flex align-items-center justify-content-center mb-4 w-100'
              type='button'
              onClick={goToAdditionalDueDiligenceAnalysis}>
            <FormattedMessage id='candidature.flow.additionalDueDiligenceAnalysis' />
          </button>
      )}





      <EntityMessageToPromoterModal
        show={showEntityMessagePromoterModal}
        setShow={setShowEntityMessagePromoterModal}
        externalId={candidature.externalId}
      />

      <IEFPMessageToPromoterModal
          show={showIEFPMessagePromoterModal}
          setShow={setShowIEFPMessagePromoterModal}
          externalId={candidature.externalId}
      />



      <Modal show={showApprovalChoicesModal} onHide={handleCloseApprovalChoicesModal}>
        <Modal.Header closeButton>
          <Modal.Title>
            <FormattedMessage id='candidatureForm.emitDecision' />
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className='text-center'>
            {hasPermission('FLOW_CANDIDATURE_APPROVE', accessInfo) &&
              candidature?.canFinishValidation && (
                <Link
                  to={candidature?.submissionDate && new Date(candidature.submissionDate).getFullYear()!=new Date().getFullYear() ? `/candidatura/${candidature.externalId}/aprovar/parcial` : `/candidatura/${candidature.externalId}/aprovar`}
                  className='btn btn-outline-primary mb-2 px-5'>
                  <FormattedMessage id='candidature.flow.approve' />
                </Link>
              )}
            {hasPermission('FLOW_CANDIDATURE_PARTIALLY_APPROVE', accessInfo) &&
              candidature?.canFinishValidation && (
                <Link
                  to={`/candidatura/${candidature.externalId}/validacao/parcial`}
                  className='btn btn-outline-primary mb-2 px-5'>
                  <FormattedMessage id='candidature.flow.partiallyApprove' />
                </Link>
              )}

            {hasPermission('FLOW_CANDIDATURE_REJECT', accessInfo) &&
              candidature?.canFinishValidation && (
                <Link
                  to={`/candidatura/${candidature.externalId}/indeferir`}
                  className='btn btn-outline-primary mb-2 px-5'>
                  <FormattedMessage id='candidature.flow.rejectCandidature' />
                </Link>
              )}
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
