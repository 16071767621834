import {GenericBadge} from "./GenericBadge";
import {FormattedMessage, useIntl} from "react-intl";
import {ProcessState} from "../../../models/ProcessState";
import {Arrays} from "../../../utils/Arrays";
import {AccreditationDecisionState} from "../../../models/AccreditationDecisionState";

function propsFromProcess(process) {
    const {processState, submissionDate, approvalDate} = process;
    return {
        current: <FormattedMessage id={`processState.${processState}`}/>,
        stateDateList: [
            ...submissionDate != null && [{
                state: <FormattedMessage id={`processState.${ProcessState.SUBMITTED}`}/>,
                date: submissionDate
            }] || [],
            ...approvalDate != null && ProcessState.finalStates().includes(processState) && [{
                state: <FormattedMessage id={`processState.${processState}`}/>,
                id: `${processState.toLowerCase()}.by.IEFP.at`,
                date: approvalDate
            }] || [],
        ]
    }
}

function propsFromAccreditation(accreditation) {
    const {entity: { state: entityState, revoked: revoked}} = accreditation;
    let decision = accreditation?.accreditationDecisions?.[accreditation?.accreditationDecisions?.length - 1];
    const {creationDate, validationDate, decisionDate, appealDate, state: decisionState} = decision || {};

    let validationResult = AccreditationDecisionState.postValidation().includes(decisionState)
        ? AccreditationDecisionState.VALIDATED
        : (decisionState === AccreditationDecisionState.INVALIDATED ? AccreditationDecisionState.INVALIDATED : null);
    let appealStates = [AccreditationDecisionState.AWAITING_APPEAL, AccreditationDecisionState.APPEAL_EXPIRED];
    var current =  <FormattedMessage id={(decisionState != null) ? `accreditationDecisionState.${decisionState}` : `entityState.${entityState}`}/>;
    if(revoked === true) {
        current = <FormattedMessage id={'accreditationRevocationState.APPROVED'}/>;
    }

    return {
        current: current,
        stateDateList: [
            ...creationDate != null && [{
                state: <FormattedMessage id={`processState.${ProcessState.SUBMITTED}`}/>,
                date: creationDate
            }] || [],
            ...validationDate != null && [{
                state: <FormattedMessage id={`accreditationDecisionState.${AccreditationDecisionState.VALIDATED}`}/>,
                id: `${validationResult.toLowerCase()}.by.IEFP.at`,
                date: validationDate
            }] || [],
            ...appealDate != null && [{
                state: <FormattedMessage id={'stateBadge.state.pronounced'}/>,
                date: appealDate
            }] || [],
            ...decisionDate && appealStates.includes(decisionState)  && [{
                state: <FormattedMessage id={'stateBadge.state.indefProp'} />,
                date: decisionDate
            }] || [],
            ...decisionDate != null && AccreditationDecisionState.decisionStates().includes(decisionState) && [{
                state: <FormattedMessage id={`accreditationDecisionState.${decisionState}`}/>,
                date: decisionDate
            }] || [],
        ]
    }
}

function propsFromAccreditationRenewal(renewal) {
    let {state, startDate, endDate} = renewal || {};
    return {
        current: <FormattedMessage  id={`accreditationRenewalState.${state}`} />,
        stateDateList: [
            ...startDate != null && [{
                state: <FormattedMessage id={'stateBadge.state.starting'} />,
                date: startDate
            }] || [],
            ...endDate != null && [{
                state: <FormattedMessage id={'stateBadge.state.ending'} />,
                date: endDate
            }] || [],
        ]
    };
}

export function StateAndDateBadge({current, stateDateList}) {
    const fmt = useIntl().formatMessage;
    const format = ({state, date, id}) => fmt({id: id ?? 'stateBadge.temporalStateAt'}, {state: state, date: date});
    const Header = ({state}) => <span key='state' className="text-uppercase fw-bold">{state}</span>;
    if(Arrays.isEmpty(stateDateList) && current == null) return <></>
    return <GenericBadge>{[<Header key={0} state={current}/>, ...stateDateList.map(format)]}</GenericBadge>;
}

StateAndDateBadge.propsFromProcess = propsFromProcess;
StateAndDateBadge.propsFromAccreditation = propsFromAccreditation;
StateAndDateBadge.propsFromAccreditationRenewal = propsFromAccreditationRenewal;
StateAndDateBadge.fromProcess = (process) => StateAndDateBadge(propsFromProcess(process));