import {Container, Row} from "react-bootstrap";
import {FormattedMessage} from "react-intl";
import React from "react";
import {LCol} from "../ViewEaAccreditation";

export function EAInfoTab({accreditation, district}) {
    const {entity} = accreditation || {};
    return (
        <Container>
            <Row className={'mb-4'}>
                <LCol md={4} id={'eaAccreditationForm.step1.description'}>{entity.description}</LCol>
                <LCol md={4} id={'eaAccreditationForm.step1.nif'}>{entity.nif}</LCol>

            </Row>

            <Row className={'mb-4'}>
                <LCol md={4} id={'eaAccreditationForm.step1.score'}>{entity.score}%</LCol>
                <LCol md={4} id={'eaAccreditation.ProtocolPeriod'}>{entity.accreditationDate} a {entity.contractEndDate}</LCol>
                {accreditation?.revoked === true && <LCol md={4} id={'eaAccreditation.View.Info.RevocationDate'}>{accreditation?.revocationDate}</LCol>}
            </Row>

            <Row className={'mb-4'}>
                <LCol md={8} id={'eaAccreditationForm.step1.nationalMemberYear'}>{entity.nationalMemberYear}</LCol>
                <LCol md={4} id={'eaAccreditationForm.step1.processId'}>{entity.processId}</LCol>
            </Row>

            <Row className={'mb-4'}>
                <LCol md={4} id={'eaAccreditationForm.step1.district'}>{district.get(entity.districtCode).description}</LCol>
                <LCol md={4} id={'eaAccreditationForm.step1.location.placeholder'}>{entity.location}</LCol>
                <LCol md={4} id={'eaAccreditationForm.step1.postalCode'}>
                    {entity.postalCode4Digits}<span className='ms-2 me-2'>{'-'}</span>{entity.postalCode3Digits}
                </LCol>
            </Row>

            <Row className={'mb-4'}>
                <LCol md={12} id={'eaAccreditationForm.step1.address'}>{entity.address}</LCol>
            </Row>

            <Row className={'mb-4'}>
                <LCol md={8} id={'eaAccreditationForm.step1.email'}>{entity.email}</LCol>
                <LCol md={4} id={'eaAccreditationForm.step1.phone'}>{entity.phone}</LCol>
            </Row>

            <Row className={'mb-4'}>
                <LCol md={4} id={'eaAccreditationForm.step1.type'}>
                    <FormattedMessage id={`eaAccreditationForm.step1.type.option.${entity.profile}`}/>
                </LCol>
                <LCol md={4} id={'eaAccreditationForm.step1.cae'}>{entity.cae}</LCol>
            </Row>
        </Container>
    );
}