import React, {useEffect, useState} from 'react';
import {Accordion, Button, Col, Container, Modal, Row} from 'react-bootstrap';
import {FormattedMessage, useIntl} from 'react-intl';
import {useNavigate, useParams} from 'react-router-dom';
import {AlertError} from '../../../components/bootstrap/AlertError';
import {AlertSuccess} from '../../../components/bootstrap/AlertSuccess';
import Loading from '../../../components/general/Loading';
import {SubTemplate} from '../../../components/general/SubTemplate';
import {handleError, isNotBusinessError} from '../../../utils/handleError';
import {getUserInfo, isIEFPUser} from '../../../authentication/authenticationHelper';
import {TextAreaInputField} from "../../../components/bootstrap/TextAreaInputField";
import {TextInputField} from "../../../components/bootstrap/TextInputField";
import {DateTimeInputField} from "../../../components/bootstrap/DateTimeInputField";
import {Arrays} from "../../../utils/Arrays";
import {AuthenticatedDownloadLink} from "../../../components/document/AuthenticatedDownloadLink";
import {DocumentType} from '../../../models/DocumentType';
import {getAccreditationRevocation} from "../../../rest/accreditationRevocation";
import {AccreditationRevocationState} from "../../../models/AccreditationRevocationState";
import {AccreditationRevocationRequestValidation} from "./AccreditationRevocationRequestValidation";
import {AccreditationRevocationRequestContestation} from "./AccreditationRevocationRequestContestation";
import {RestitutionType} from "../../../components/oversight/tabs/noncompliance/RestitutionType";
import {AccreditationRevocationRestitutionModal} from "../../../components/accreditationRevocation/AccreditationRevocationRestitutionModal";
import {TranslateBoolean} from "../../../utils/TranslateBoolean";
import {currencyFormat} from "../../../utils/CurrencyUtils";


export function DetailAccredidationRevocation() {
    const {nif, externalId} = useParams();
    const navigate = useNavigate();
    const isIEFP = isIEFPUser();
    const userInfo = getUserInfo();


    const [restitutionModalShow, setRestitutionModalShow] = useState(false);

    const [accreditationRevocation, setAccreditationRevocation] = useState();
    const [restitutionModalSubmitted, setRestitutionModalSubmitted] = useState(false);
    const [lsItem, setLsItem] = useState(null);
    const [activeKey, setActiveKey] = useState('changeInitial');

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const intl = useIntl();

    const [showDecisionModal, setShowDecisionModal] = useState(false);
    const [showContestationModal, setShowContestationModal] = useState(false);


    const [isApprove, setIsApprove] = useState(null);


    const fetchData = async () => {
        try {
            let [{data: accreditationRevocationIn}] =
                await Promise.all([
                    await getAccreditationRevocation(
                        nif,
                        externalId
                    ),

                ]);

            setAccreditationRevocation(accreditationRevocationIn);

            switch (accreditationRevocationIn.state) {
                case AccreditationRevocationState.APPROVED:
                case AccreditationRevocationState.REJECTED:
                    setActiveKey('finalDecision');
                    break;
                case AccreditationRevocationState.ANALYZE_CONTESTATION:
                    setActiveKey('clarification')
                    break;
                case AccreditationRevocationState.WAIT_CONTESTATION:
                    setActiveKey('changeInitial')
            }


            setRestitutionModalSubmitted(false);

            checkLocalStorageItems();
        } catch (error) {
            setError(error);
        } finally {
            setLoading(false);
        }
    };


    function checkLocalStorageItems() {
        [
            'accreditationRevocationContestation',
            'accreditationRevocationContinue',
            'accreditationRevocationCancel',
            'accreditationRevocationRestitution'
        ].forEach((s) => {
            if (sessionStorage.getItem(s)) {
                setLsItem(s);
            }
        });
    }

    useEffect(() => {
        fetchData();
    }, [restitutionModalSubmitted]);

    if (loading) {
        return <Loading/>;
    }

    if (error && isNotBusinessError(error)) {
        return handleError(error);
    }

    function handleCloseDecisionModal() {
        setShowDecisionModal(false);
        setIsApprove(null);
    }

    function modalOpenningHandler(isApprove) {
        setIsApprove(isApprove);
        setShowDecisionModal(true);
    }


    function canChangeRestitution(accreditationRevocation) {
        const correctState = accreditationRevocation.state === AccreditationRevocationState.APPROVED;
        return correctState && accreditationRevocation.regionalDelegationCode === userInfo?.rd && [RestitutionType.PARTIAL, RestitutionType.TOTAL].includes(accreditationRevocation.restitution) && (accreditationRevocation.restitutionRecord === undefined ||
            accreditationRevocation.restitutionRecord.hasReimbursementPlan === false || accreditationRevocation.restitutionRecord.hasRestitutionDate === false || accreditationRevocation.restitutionRecord.hasDateSentToCoerciveCollection === false);
    }


    const entityChangeIsWaitContestation = () => {
        return (
            !isIEFP && AccreditationRevocationState.WAIT_CONTESTATION === accreditationRevocation?.state
        );
    };


    const showApproveAndRejectButtons = () => {
        return (
            isIEFP && accreditationRevocation.regionalDelegationCode === userInfo?.rd
            && AccreditationRevocationState.ANALYZE_CONTESTATION === accreditationRevocation?.state
        );
    };


    return (
        <>

            <SubTemplate hasBackButton titleId={'accreditationRevocation.details'}>
                <Container>
                    <AlertError error={error}/>
                    {lsItem && <AlertSuccess lsItem={lsItem}/>}


                    {showDecisionModal && (
                        <AccreditationRevocationRequestValidation
                            accreditationRevocation={accreditationRevocation}
                            isApproval={isApprove}
                            handleClose={handleCloseDecisionModal}
                        />
                    )}
                    {showContestationModal && (
                        <AccreditationRevocationRequestContestation
                            accreditationRevocation={accreditationRevocation}
                            setShowModal={setShowContestationModal}
                        />
                    )}

                    <AccreditationRevocationRestitutionModal
                        accreditationRevocation={accreditationRevocation}
                        show={restitutionModalShow}
                        setShow={setRestitutionModalShow}
                        onSubmitCallback={() => setRestitutionModalSubmitted(true)}
                    />


                    <Row>
                        <Col md='9' className='mb-5 summaryTabs'>
                            <div className={'form bg-white'}>
                                <Row>
                                    <Col md={6}>
                                        <TextInputField
                                            labelId='economicViabilityFormStep1.regionalDelegation'
                                            name='regionalDelegationCode'
                                            value={intl.formatMessage({
                                                id: 'regionalDelegation.' + accreditationRevocation.regionalDelegationCode
                                            })}
                                            disabled={true}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <DateTimeInputField
                                            className={'w-50'}
                                            labelId='candidatureTabs.submissionDate'
                                            name='submssionDate'
                                            disabled={true}
                                            value={accreditationRevocation.submissionDate}
                                        />
                                    </Col>
                                </Row>


                                <Accordion defaultActiveKey={[activeKey]} alwaysOpen>


                                    {!Arrays.isEmpty(accreditationRevocation.documents.filter(document => document.documentType === DocumentType.ACCREDITATION_REVOCATION_DECISION)) && (
                                        <Accordion.Item eventKey="changeInitial">
                                            <Accordion.Header><FormattedMessage
                                                id='accreditationRevocation.rejectFirst'/></Accordion.Header>
                                            <Accordion.Body>

                                                {accreditationRevocation.documents.filter(document => [DocumentType.ACCREDITATION_REVOCATION_DECISION, DocumentType.ACCREDITATION_REVOCATION_DECISION_PRIVATE].includes(document.documentType)).map((document, index) => (


                                                    <div key={index} className='py-2'>
                                                        <div className='d-flex justify-content-between'>
                                                            <div>
                                                                <label>
                                                                    <FormattedMessage
                                                                        id={`documentType.${document.documentType}`}/>
                                                                </label>
                                                                {document.fileDescription}
                                                                <span className='mx-2'>·</span>
                                                                <AuthenticatedDownloadLink
                                                                    url={document.documentPath}
                                                                    filename={document.name}
                                                                >
                                                                    {document.name}
                                                                </AuthenticatedDownloadLink>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </Accordion.Body>
                                        </Accordion.Item>

                                    )}


                                    {(accreditationRevocation.clarificationReason && accreditationRevocation.clarificationReason.length > 0) && (

                                        <Accordion.Item eventKey="clarification">
                                            <Accordion.Header><FormattedMessage
                                                id='entityChangeDetails.clarificationStep'/></Accordion.Header>
                                            <Accordion.Body>


                                                <Row>
                                                    <Col md={12}>
                                                        <TextAreaInputField
                                                            controlId={'clarification'}
                                                            labelId={'entityChange.clarification'}
                                                            name={'reason'}
                                                            value={accreditationRevocation.clarificationReason}
                                                            maxLength={1000}
                                                            disabled={true}
                                                        />
                                                    </Col>
                                                </Row>
                                                {accreditationRevocation.documents.filter(document => DocumentType.CLARIFICATION_ACCRED_REVOCATION === document.documentType).map((document, index) => (


                                                    <div key={index} className='py-2'>
                                                        <div className='d-flex justify-content-between'>
                                                            <div>
                                                                <label>
                                                                    <FormattedMessage
                                                                        id={`documentType.${document.documentType}`}/>
                                                                </label>
                                                                {document.fileDescription}
                                                                <span className='mx-2'>·</span>
                                                                <AuthenticatedDownloadLink
                                                                    url={document.documentPath}
                                                                    filename={document.name}
                                                                >
                                                                    {document.name}
                                                                </AuthenticatedDownloadLink>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </Accordion.Body>

                                        </Accordion.Item>

                                    )}

                                    {!Arrays.isEmpty(accreditationRevocation.documents.filter(document => document.documentType === DocumentType.ACCREDITATION_REVOCATION_FINAL_DECISION)) && (


                                        <Accordion.Item eventKey="finalDecision">
                                            <Accordion.Header><FormattedMessage
                                                id={AccreditationRevocationState.APPROVED ? 'accreditationRevocation.title.approve' : 'entityChangeDetails.IEFP.rejectSecond'}/></Accordion.Header>
                                            <Accordion.Body>

                                                <Row>
                                                    <Col md={6}>
                                                        <DateTimeInputField
                                                            className={'w-50'}
                                                            labelId='entityChange.decisionDate'
                                                            name='approvalDate'
                                                            disabled={true}
                                                            value={accreditationRevocation.decisionDate}
                                                        />
                                                    </Col>

                                                </Row>


                                                {accreditationRevocation.documents.filter(document => [DocumentType.ACCREDITATION_REVOCATION_FINAL_DECISION, DocumentType.ACCREDITATION_REVOCATION_FINAL_DECISION_PRIVATE].includes(document.documentType)).map((document, index) => (


                                                    <div key={index} className='py-2'>
                                                        <div className='d-flex justify-content-between'>
                                                            <div>
                                                                <label>
                                                                    <FormattedMessage
                                                                        id={`documentType.${document.documentType}`}/>
                                                                </label>
                                                                {document.fileDescription}
                                                                <span className='mx-2'>·</span>
                                                                <AuthenticatedDownloadLink
                                                                    url={document.documentPath}
                                                                    filename={document.name}
                                                                >
                                                                    {document.name}
                                                                </AuthenticatedDownloadLink>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}


                                                {accreditationRevocation.restitution !== undefined &&
                                                <>
                                                    <Row>
                                                        <Col><h5 className='text-secondary mt-3 mb-3 border-top pt-3'><FormattedMessage
                                                            id={'accreditationRevocation.restitution.title'}/></h5></Col>
                                                    </Row>

                                                    <Row>
                                                        <Col>
                                                            <label>
                                                                <FormattedMessage id='candidatureTabs.oversight.tabs.noncompliance.form.aidRestitution'/>
                                                            </label>
                                                            <FormattedMessage id={'candidatureTabs.oversight.tabs.noncompliance.form.restVal.' + accreditationRevocation.restitution}/>
                                                        </Col>
                                                        { [RestitutionType.TOTAL,RestitutionType.PARTIAL].includes(accreditationRevocation.restitution) && (
                                                            <Col>
                                                                <label>
                                                                    <FormattedMessage id='candidatureTabs.oversight.tabs.noncompliance.form.restitutionValue'/>
                                                                </label>
                                                                {currencyFormat(accreditationRevocation.restitutionValue.toFixed(2))}
                                                            </Col>
                                                        )}
                                                    </Row>


                                                        {accreditationRevocation?.restitutionRecord?.hasReimbursementPlan === true &&
                                                        <Row
                                                            className='d-flex justify-content-between align-items-center'>
                                                            <Col>
                                                                <label><FormattedMessage
                                                                    id={'nonCompliance.reimbursementPlan'}/></label>
                                                                <p>{TranslateBoolean({value: accreditationRevocation.restitutionRecord.reimbursementPlan})}</p>
                                                            </Col>
                                                        </Row>

                                                        }

                                                    <Row  className='d-flex justify-content-between align-items-center'>

                                                    {accreditationRevocation?.restitutionRecord?.hasReimbursementPlan === true &&
                                                        <Col md={4}>
                                                            <label><FormattedMessage
                                                                id={'nonCompliance.reimbursementPlan'}/></label>
                                                            <p>{TranslateBoolean({value: accreditationRevocation.restitutionRecord.reimbursementPlan})}</p>
                                                        </Col>
                                                    }
                                                        {accreditationRevocation?.restitutionRecord?.hasRestitutionDate === true &&
                                                       <>
                                                           <Col md={4}>
                                                               <label>
                                                                   <FormattedMessage id='nonCompliance.restitutionDoneLong' />
                                                               </label>
                                                               <TranslateBoolean
                                                                   value={accreditationRevocation.restitutionRecord.restitutionDone}
                                                               />
                                                           </Col>
                                                           <Col md={4}>
                                                               <label>
                                                                   <FormattedMessage id='nonCompliance.restitutionDate' />
                                                               </label>
                                                               {(accreditationRevocation.restitutionRecord.restitutionDate!==undefined && accreditationRevocation.restitutionRecord.restitutionDate!==null)? accreditationRevocation.restitutionRecord.restitutionDate: ' - '}
                                                           </Col>
                                                       </>
                                                        }





                                                        </Row>

                                                    {accreditationRevocation?.restitutionRecord?.hasDateSentToCoerciveCollection === true &&
                                                    <Row>
                                                        <Col md={4}>
                                                            <label>
                                                                <FormattedMessage id='nonCompliance.coerciveDone'/>
                                                            </label>
                                                            <TranslateBoolean
                                                                value={accreditationRevocation.restitutionRecord.coerciveDone}
                                                            />
                                                        </Col>
                                                        <Col md={4}>
                                                            <label>
                                                                <FormattedMessage
                                                                    id='nonCompliance.dateSentToCoerciveCollection'/>
                                                            </label>
                                                            {(accreditationRevocation.restitutionRecord.dateSentToCoerciveCollection !== undefined && accreditationRevocation.restitutionRecord.dateSentToCoerciveCollection !== null) ? accreditationRevocation.restitutionRecord.dateSentToCoerciveCollection : ' - '}
                                                        </Col>
                                                    </Row>
                                                    }






                                                </>
                                                }


                                            </Accordion.Body>
                                        </Accordion.Item>

                                    )}
                                </Accordion>


                            </div>
                        </Col>
                        <Col md='3'>
                            <div className='px-2 mb-5'>
                                <div className='d-grid gap-4'>
                                    {showApproveAndRejectButtons() && (
                                        <>
                                            <button
                                                onClick={() => modalOpenningHandler(true)}
                                                className='btn btn-outline-primary mb-2'
                                            >
                                                <FormattedMessage id='accreditationRevocation.continue'/>
                                            </button>
                                            <button
                                                onClick={() => modalOpenningHandler(false)}
                                                className='btn btn-outline-primary mb-2'
                                            >
                                                <FormattedMessage id='accreditationRevocation.cancel'/>
                                            </button>
                                        </>
                                    )}

                                    {canChangeRestitution(accreditationRevocation) && (
                                        <Button variant='outline-primary' onClick={() => setRestitutionModalShow(true)}>
                                            <FormattedMessage
                                                id='candidatureTabs.oversight.tabs.noncompliance.restitution'/>
                                        </Button>
                                    )}


                                    {entityChangeIsWaitContestation() && (
                                        <button
                                            onClick={() => setShowContestationModal(true)}
                                            className='btn btn-outline-primary mb-2'
                                        >
                                            <FormattedMessage id='candidature.button.contest'/>
                                        </button>
                                    )}
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </SubTemplate>
        </>
    );
}
