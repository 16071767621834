import React, {useState} from "react";
import {Col, Form, Row} from "react-bootstrap";
import {FormattedMessage} from "react-intl";
import ToolTip from "../bootstrap/ToolTip";
import ReactStars from "react-rating-stars-component";
import {FaRegTimesCircle} from 'react-icons/fa';


export function StartEvaluationItem({
                                        formikValues,
                                        name,
                                        setFieldValue,
                                        label,
                                        sublabel,
                                        errorMessage,
                                        readMode
                                    }) {

    const [starsKey, setStarsKey] = useState(Math.random());

    function removeHandler() {
        formikValues[name] = 0;
        setFieldValue(name, 0);
        setStarsKey(Math.random());

    }


    return (
        <>
            <Row className='mt-4'>
                <small className='text-primary text-uppercase'>
                    <FormattedMessage id={label}/>
                </small>
            </Row>
            <Row>
                <Form.Control.Feedback type='invalid'>{errorMessage}</Form.Control.Feedback>
            </Row>
            <Row>
                <Col md='12'>
                    <div className='d-flex justify-content-between align-items-center'>
                        <label>
                            <FormattedMessage id={sublabel}/>
                            <ToolTip message={<FormattedMessage id='starts.infoText'/>}/>
                        </label>
                        <div className={'d-flex justify-content-between align-items-center'}>
                            <div className={'me-2'}>
                                <ReactStars
                                    key={starsKey}
                                    count={5}
                                    size={24}
                                    activeColor='#eb4f24'
                                    value={formikValues[name]}
                                    edit={readMode === false || readMode === undefined}
                                    onChange={(e) => setFieldValue(name, e)}
                                    name={name}
                                />
                            </div>
                            {!readMode &&
                            <FaRegTimesCircle
                                onClick={() =>
                                    removeHandler()
                                }
                                color={"#eb4f24"}
                                cursor={"pointer"}
                            />
                            }

                        </div>
                    </div>
                </Col>
            </Row>
        </>
    );
}