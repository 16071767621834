import { apiAxios as authAxios } from '../axios.config';




export const getActionNotificationsMetaData = () => {
  return authAxios.get('api/actionNotifications/list');
};

export const deleteActionNotification = (code) => {
  return authAxios.delete(`api/actionNotifications/${code}`);
};

export const getActionNotification = (code) => {
  return authAxios.get(`api/actionNotifications/${code}`);
};

export const updateActionNotification = (code, actionNotification) => {
  return authAxios.put(`api/actionNotifications/${code}`, actionNotification);
};

export const createActionNotification = (actionNotification) => {
  return authAxios.post('api/actionNotifications', actionNotification);
};
