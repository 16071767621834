import React, { useEffect, useState } from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { DateTimeInputField } from '../bootstrap/DateTimeInputField';
import DecimalInputField from '../bootstrap/DecimalInputField';
import { NumberInputField } from '../bootstrap/NumberInputField';
import { TextInputField } from '../bootstrap/TextInputField';

export function PaymentRecord({
	errors,
	handleChange,
	setFieldValue,
	formikValues,
	readMode,
}) {
	const intl = useIntl();

	return (
		<fieldset disabled={readMode==true}>
			<Row>
				<Col md='4'>
					<DateTimeInputField
						labelId='all.paymentDate'
						name='paymentRecord.date'
						setFieldValue={setFieldValue}
						value={formikValues.paymentRecord.date}
						isDateWithNoTime={true}
						isInvalid={errors.paymentRecord?.date}
						errorMessage={errors.paymentRecord?.date}
					/>
				</Col>
				<Col md='4'>
					<DecimalInputField
						labelId='all.value'
						value={formikValues.paymentRecord.value}
						name={`paymentRecord.value`}
						handleChange={handleChange}
						isInvalid={errors.paymentRecord?.value}
						errorMessage={errors.paymentRecord?.value}
					/>
				</Col>
			</Row>
		</fieldset>
	);
}
