import React, {useState} from "react";

import {Col, Row, Tab, Tabs} from "react-bootstrap";
import {FormattedMessage} from "react-intl";
import {EAInfoTab} from "./tabs/EAInfoTab";
import {EaAccreditationDocuments} from "../EaAccreditationDocuments";
import {EADocsTab} from "./tabs/EADocsTab";
import {EAGeoTab} from "./tabs/EAGeoTab";
import {EAHRTab} from "./tabs/EAHRTab";

export function ViewEaAccreditation({accreditation, districtOptions, documents}) {
    const [tabActiveKey, setTabActiveKey] = useState('eaInfo');
    const {interventionDistrictCodes, incubatorLocations, interventions, getList, tgpList} = accreditation || {};
    const district = new Map(districtOptions.map(d => [d.code, d]))

    return (
        <Tabs activeKey={tabActiveKey} onSelect={(k) => setTabActiveKey(k)} justify>
            <Tab eventKey='eaInfo' title={<FormattedMessage id='entityTabs.resume' />} className='mb-4 p-3 border'>
                <EAInfoTab accreditation={accreditation} district={district}/>
            </Tab>
            <Tab eventKey='eaGeo' title={<FormattedMessage id='eaAccreditation.View.Geo' />} className='mb-4 p-3 border'>
                <EAGeoTab
                    districts={district}
                    interventionDistrictCodes={interventionDistrictCodes}
                    incubatorLocations={incubatorLocations}
                />
            </Tab>
            <Tab eventKey='eaHR' title={<FormattedMessage id='eaAccreditation.View.HR' />} className='mb-4 p-3 border'>
                <EAHRTab getList={getList} tgpList={tgpList} />
            </Tab>
            <Tab eventKey='eaDocs' title={<FormattedMessage id='eaAccreditation.View.Docs' />} className='mb-4 p-3 border'>
                <EADocsTab documents={documents} />
            </Tab>
        </Tabs>

    );
}

export const Labeled = ({value, id, children}) => <><label>{value || id && <FormattedMessage id={id}/>}</label>{children}</>
export const LCol = ({id, children, md}) => <Col md={md}><Labeled id={id}>{children}</Labeled></Col>;
