import { apiAxios as authAxios } from '../axios.config';

export const getDistricts = (regionalDelegation) => {
	return authAxios.get(
		'/api/administrativeDivision',
		regionalDelegation
			? { params: {level:1, regionalDelegation: regionalDelegation } }
			: { params: {level:1} }
	);
};

export const getDistrictsByEntity = (regionalDelegation, entityNif) => {
	return authAxios.get(
		`/api/administrativeDivision/entity/${entityNif}`,
		regionalDelegation
			? { params: {level:1, regionalDelegation: regionalDelegation } }
			: { params: {level:1} }
	);
};

export const getCountiesByDistrictCode = (districtCode) => {
    return authAxios.get(`/api/administrativeDivision/2/${districtCode}`)
}

export const getParishesByCountyCode = (countyCode) => {
    return authAxios.get(`/api/administrativeDivision/3/${countyCode}`)
}

export const getParishByCode = (code) => {
	return authAxios.get(`/api/administrativeDivision/${code}`)
}