import { ConfigObject } from '../config';

export const IAS = () => {
  return parseFloat(ConfigObject.get().IAS);
};

export const CandidatureEmployeeSupportUtils = {

  getSubsidyValue: (name) => {
    let value;
    switch (name) {
      case 'underRepresentedGender':
        value = (15 * IAS() * 0.3).toFixed(2);
        return parseFloat(value);

      case 'inlandTerritory':
        value = (15 * IAS() * 0.25).toFixed(2);
        return parseFloat(value);

      case 'moreThanOnePromoter':
        value = (15 * IAS() * 0.2).toFixed(2);
        return parseFloat(value);

      default:
        break;
    }
  },

  getTotalJobSubsidyValue: (candidatureEmployeeSupportDetails) => {
    let value = 0;

    candidatureEmployeeSupportDetails.forEach((detail) => {
      value += detail.totalSubsidy;
    });

    return value;
  },
};
