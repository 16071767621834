import { Formik } from 'formik';
import React, { useState } from 'react'
import * as yup from 'yup';
import { Form, Col, Row, Modal, Button } from 'react-bootstrap';
import {Link, useNavigate} from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { AuthenticatedDownloadLink } from '../document/AuthenticatedDownloadLink';
import { TextAreaInputField } from '../bootstrap/TextAreaInputField';
import { Arrays } from '../../utils/Arrays';
import {CandidatureFlowSendToEntityOpinionReview} from "./flow/CandidatureFlowSendToEntityOpinionReview";
import {CandidatureState} from "../../models/CandidatureState";
import {hasPermission, isIEFPUser} from "../../authentication/authenticationHelper";
import Loading from "../general/Loading";
import {handleError, isNotBusinessError} from "../../utils/handleError";
import {cancelApprovalDecisionExpiration, saveContestation} from "../../rest/candidature";

export function ContestationAnalysisExpirationForm({userDocuments, externalId, contestationMotive }) {

  const navigate = useNavigate();




  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [showApprovalChoicesModal, setShowApprovalChoicesModal] = useState(false);

  function goToIssueFeasibility() {
    navigate(`/candidatura/${externalId}/emitirParecer`);
  }


  function handleCloseApprovalChoicesModal() {
    setShowApprovalChoicesModal(false);
  };


  async function handleCancelApprovalChoicesModal() {
    try {
      await cancelApprovalDecisionExpiration(externalId);
      sessionStorage.setItem('cancelApprovalDecisionExpiration', 'true');
      setShowApprovalChoicesModal(false);
      navigate('/candidatura/' + externalId);
    } catch (error) {
      setError(error);
    }
  }





  const callbackHandler = async (callback, sessionStorageItem) => {
    setLoading(true);

    try {
      const response = await callback();
      if (sessionStorageItem) {
        sessionStorage.setItem(sessionStorageItem, true);
      }
      navigate('/candidatura/'+externalId);

    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };



  if (loading) {
    return <Loading />;
  }

  if (error && isNotBusinessError(error)) {
    return handleError(error);
  }


  return (
    <>
      <Row>
        <Col md={{ span: 8}}>
          <div className='form mb-5'>
            <label>
              <FormattedMessage id='candidature.contestationAnalysis.motive' />
            </label>
            <p>
              {contestationMotive}
            </p>

            <div className='border-top py-2'>
              {Arrays.isNotEmpty(userDocuments) ? (
                <label>
                  <FormattedMessage id='candidature.contestationAnalysis.documents' />
                </label>
              ) : (
                <p className='text-secondary'>
                  <FormattedMessage id='candidature.contestationAnalysis.emptyDocuments' />
                </p>
              )}
            </div>
            {userDocuments.map((d, index) => (
              <div key={index}>
                <Row className='mb-3'>
                  <Col>
                    <label>
                      {d.fileDescription}
                    </label>
                    <div>
                      <AuthenticatedDownloadLink
                        url={d.documentPath}
                        filename={d.name}
                      >
                        {d.name}
                      </AuthenticatedDownloadLink>
                    </div>
                  </Col>
                </Row>
              </div>

            ))}
          </div>
        </Col>
        <Col md='3'>
          <div className="leftButtonsContainer">
                <Button
                    variant='btn btn-outline-primary d-flex align-items-center justify-content-center w-100 mb-4'
                    onClick={() => {
                      setShowApprovalChoicesModal(true);
                    }}>
                  <FormattedMessage id='candidatureForm.emitDecision' />
                </Button>











          </div>
        </Col>
      </Row>

      <Modal show={showApprovalChoicesModal} onHide={handleCloseApprovalChoicesModal}>
        <Modal.Header closeButton>
          <Modal.Title>
            <FormattedMessage id='candidatureForm.emitDecision' />
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className='text-center'>

            <Button onClick={() => handleCancelApprovalChoicesModal()} className={'btn btn-outline-primary d-flex align-items-center justify-content-center w-100 mb-4'}>
              <FormattedMessage id='candidature.flow.no.expiration' />
            </Button>


            <Link
                to={`/candidatura/${externalId}/emitirDecisaoFinalCadudcidade`}
                className='btn btn-outline-primary d-flex align-items-center justify-content-center w-100 mb-4'>
              <FormattedMessage id='candidature.flow.expiration' />
            </Link>


          </div>
        </Modal.Body>
      </Modal>

    </>
  )
}
