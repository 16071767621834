import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { SingleUploadArea } from '../../../components/SingleUploadArea';
import { AlertError } from '../../../components/bootstrap/AlertError';
import Loading from '../../../components/general/Loading';
import { CandidatureState } from '../../../models/CandidatureState';
import { DocumentType } from '../../../models/DocumentType';
import {
	deleteCandidatureChangeRequestDocument,
	listCandidatureChangeRequestDocumentsByType,
	setToAcceptanceTerm,
	submitInitialDecision,
	uploadCandidatureChangeRequestDocument,
} from '../../../rest/candidatureChangeRequest';
import {Arrays} from "../../../utils/Arrays";
import {EntityChangeState} from "../../../models/EntityChangeState";
import {
	decisionEntityChange,
	deleteEntityChangeChangeRequestDocument,
	uploadEntityChangeRequestDocument
} from "../../../rest/entityChanges";

export function EntityChangeRequestValidationModal({
	entityChange,
	isApprove,
	handleClose
}) {
	const intl = useIntl();
	const { nif } = useParams();

	const [loading, setLoading] = useState(true);


	const navigate = useNavigate();

	const [error, setError] = useState(null);

	const [publicDocument, setPublicDocument] = useState([]);
	const [privateDocument, setPrivateDocument] = useState([]);
	const [publicDocumentType, setPublicDocumentType] = useState(
		DocumentType.ENTITY_CHANGE_DECISION
	);
	const [privateDocumentType, setPrivateDocumentType] = useState(
		DocumentType.ENTITY_CHANGE_DECISION_PRIVATE
	);

	const isContestationAnalysisState =
		EntityChangeState.ANALYZE_CONTESTATION === entityChange.state;

	async function fetchData() {
		try {
			const publicType =
				isContestationAnalysisState || isApprove === true
					? DocumentType.ENTITY_CHANGE_FINAL_DECISION
					: DocumentType.ENTITY_CHANGE_DECISION;
			const privateType =
				isContestationAnalysisState || isApprove === true
					? DocumentType.ENTITY_CHANGE_FINAL_DECISION_PRIVATE
					: DocumentType.ENTITY_CHANGE_DECISION_PRIVATE;

			setPublicDocumentType(publicType);
			setPrivateDocumentType(privateType);

			loadDocument(entityChange.documents.filter(d=> d.documentType===publicType), setPublicDocument, publicType);
			loadDocument(entityChange.documents.filter(d=> d.documentType===privateType), setPrivateDocument, privateType);

		} catch (error) {
			setError(error);
		} finally {
			setLoading(false);
		}
	}

	function loadDocument(data, setDocument, documentType) {
		if (data.length !== 0) {
			const document = data[0];
			setDocument([
				{
					documentType: document.documentType,
					externalId: document.externalId,
					name: document.name,
					documentPath: document.documentPath,
					content: '',
					submited: true,
					error: '',
				},
			]);
		} else {
			setDocument([
				{
					documentType: documentType,
					externalId: '',
					name: '',
					content: null,
					submited: false,
					error: '',
				},
			]);
		}
	}

	function canSubmit() {
		return publicDocument[0]?.submited;
	}

	async function handleConfirm() {
		try {

				let flag = {
					flag: isApprove,
				};

				await decisionEntityChange(nif,entityChange.type,entityChange.externalId, flag);

				if(isApprove){
					sessionStorage.setItem(
						'entityChangeApproved',
						true
					)
				}else{
					sessionStorage.setItem(EntityChangeState.SUBMITTED === entityChange.state ? 'entityChangeReject1': "entityChangeReject2",true);
				}

		/*	*/

			//refresh page
			navigate(0);
		} catch (error) {
			setError(error);
		}
	}

	useEffect(() => {
		fetchData();
	}, []);

	const uploadCallback = async (formData) => {
		return await uploadEntityChangeRequestDocument(
			nif,
			entityChange.externalId,
			formData
		);
	};

	const deleteCallback = async (fileExternalId) => {
		return await deleteEntityChangeChangeRequestDocument(
			nif,
			entityChange.externalId,
			fileExternalId
		);
	};

	return (
		<Modal show={true} dialogClassName='modal-65' onHide={handleClose}>
			<Modal.Header closeButton>
				<FormattedMessage
					id={`candidatureChangeRequestValidation.title.${
						isApprove ? 'approve' : 'reject'
					}`}
				/>
			</Modal.Header>
			<Modal.Body>
				{loading ? (
					<span>
						<Loading isDialog/>
					</span>
				) : (
					<>
						<div className='my-4'>
							<FormattedMessage
								id={`candidatureChangeRequestValidation.message.${
									isApprove ? 'approve' : 'reject'
								}`}
							/>
						</div>
						<Row className='px-2'>
							<Col>
								<small className='text-primary text-uppercase'>
									<FormattedMessage id='candidatureValidation.decisionDocuments' />
								</small>
								<SingleUploadArea
									documents={publicDocument}
									setDocuments={setPublicDocument}
									documentType={publicDocumentType}
									setError={setError}
									processUploadCallback={uploadCallback}
									processDeleteCallback={deleteCallback}
								/>
								<SingleUploadArea
									documents={privateDocument}
									setDocuments={setPrivateDocument}
									documentType={privateDocumentType}
									setError={setError}
									processUploadCallback={uploadCallback}
									processDeleteCallback={deleteCallback}
								/>
							</Col>
							<AlertError error={error} />
						</Row>
					</>
				)}
			</Modal.Body>
			<Modal.Footer className='d-flex justify-content-between'>
				<Button variant='outline-secondary' onClick={handleClose}>
					<FormattedMessage id='all.cancel' />
				</Button>
				<Button
					variant='primary'
					onClick={handleConfirm}
					disabled={!canSubmit()}
				>
					<FormattedMessage id='all.submitButtonText' />
				</Button>
			</Modal.Footer>
		</Modal>
	);
}
