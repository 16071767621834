import React from 'react';
import { Col, Form, Row, Table } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import DecimalInputField from '../bootstrap/DecimalInputField';
import { EnumSelectField } from '../bootstrap/EnumSelectField';
import { TermType } from '../../models/TermType';
import { NumberInputField } from '../bootstrap/NumberInputField';
import IntInputField from '../bootstrap/IntInputField';
import IntInputFormattedField from '../bootstrap/IntInputFormattedField';
export function EconomicStatusesForm({
	errors,
	handleChange,
	formikValues,
	readMode,
}) {
	const intl = useIntl();

	const rowsIdsPreffix = 'strategicDiagnosis.financialSituation';

	const rowsIdsObjects = [
		{ id: 'salesVolume', suffix: '', type: 'number' },
		{ id: 'salesGrossMargin', suffix: '%', type: 'decimal', allowNegative: true},
		{ id: 'netResults', type: 'decimal', allowNegative: true },
		{ id: 'salesProfitability', type: 'decimal', allowNegative: true },
		{ id: 'generalLiquidity', type: 'decimal', allowNegative: true },
		{ id: 'solvency', type: 'decimal', allowNegative: true },
		{ id: 'financialAutonomy', type: 'decimal', allowNegative: true },
		{ id: 'paymentMeanTerm', type: 'number', maxLength: 2 },
		{ id: 'receiptMeanTerm', type: 'number', maxLength: 2 },
	];

	const nIndex = 0;
	const nIndexPlus1 = 1;
	const nIndexPlus2 = 2;
	const predictionIndex = 3;

	function inputGenerator(rowIdObj, arrayIndex) {
		return rowIdObj.type === 'number' ? (
			<IntInputFormattedField
				name={`economicStatusRatios[${arrayIndex}][${rowIdObj.id}]`}
				handleChange={handleChange}
				value={formikValues.economicStatusRatios[arrayIndex][rowIdObj.id]}
				maxSize={rowIdObj.maxLength}
				isInvalid={errors.economicStatusRatios?.[arrayIndex]?.[rowIdObj.id]}
				errorMessage={errors.economicStatusRatios?.[arrayIndex]?.[rowIdObj.id]}
			/>
		) : (
			<DecimalInputField
				name={`economicStatusRatios[${arrayIndex}][${rowIdObj.id}]`}
				value={formikValues.economicStatusRatios[arrayIndex][rowIdObj.id]}
				handleChange={handleChange}
				isInvalid={errors.economicStatusRatios?.[arrayIndex]?.[rowIdObj.id]}
				errorMessage={errors.economicStatusRatios?.[arrayIndex]?.[rowIdObj.id]}
				suffixLabel={rowIdObj.suffix}
				allowNegative={rowIdObj.allowNegative}
			/>
		);
	}

	function generateTermTypeInput(arrayIndex, termTypeAttribute) {
		return (
			<EnumSelectField
				className='form-select selectWithIconAtRight'
				preffixDescriptionId={'termType'}
				name={`economicStatusRatios[${arrayIndex}][${termTypeAttribute}]`}
				handleChange={handleChange}
				options={TermType.values()}
				value={formikValues.economicStatusRatios[arrayIndex][termTypeAttribute]}
				isInvalid={
					errors.economicStatusRatios?.[arrayIndex]?.[termTypeAttribute]
				}
				errorMessage={
					errors.economicStatusRatios?.[arrayIndex]?.[termTypeAttribute]
				}
			/>
		);
	}

	function generateTableRows() {
		return rowsIdsObjects.map((rowIdObj) => (
			<tr key={rowIdObj.id}>
				<td>
					<FormattedMessage id={`${rowsIdsPreffix}.${rowIdObj.id}`} />
				</td>
				<td>
					{rowIdObj.id.endsWith('Term') ? (
						<Row>
							<Col md='6' className='pe-0'>
								{inputGenerator(rowIdObj, predictionIndex)}
							</Col>
							<Col md='6' className='ps-0'>
								{generateTermTypeInput(
									predictionIndex,
									rowIdObj.id.concat('Type')
								)}
							</Col>
						</Row>
					) : (
						inputGenerator(rowIdObj, predictionIndex)
					)}
				</td>
				<td>
					{rowIdObj.id.endsWith('Term') ? (
						<Row>
							<Col md='6' className='pe-0'>
								{inputGenerator(rowIdObj, nIndex)}
							</Col>
							<Col md='6' className='ps-0'>
								{generateTermTypeInput(nIndex, rowIdObj.id.concat('Type'))}
							</Col>
						</Row>
					) : (
						inputGenerator(rowIdObj, nIndex)
					)}
				</td>
				<td>
					{rowIdObj.id.endsWith('Term') ? (
						<Row>
							<Col md='6' className='pe-0'>
								{inputGenerator(rowIdObj, nIndexPlus1)}
							</Col>
							<Col md='6' className='ps-0'>
								{generateTermTypeInput(nIndexPlus1, rowIdObj.id.concat('Type'))}
							</Col>
						</Row>
					) : (
						inputGenerator(rowIdObj, nIndexPlus1)
					)}
				</td>
				<td>
					{rowIdObj.id.endsWith('Term') ? (
						<Row>
							<Col md='6' className='pe-0'>
								{inputGenerator(rowIdObj, nIndexPlus2)}
							</Col>
							<Col md='6' className='ps-0'>
								{generateTermTypeInput(nIndexPlus2, rowIdObj.id.concat('Type'))}
							</Col>
						</Row>
					) : (
						inputGenerator(rowIdObj, nIndexPlus2)
					)}
				</td>
			</tr>
		));
	}

	return (
		<fieldset disabled={readMode==true}>
			<Row>
				<Col>
					<Table className='mt-4'>
						<thead>
							<tr>
								<th className='w-20'>
									<FormattedMessage id='strategicDiagnosis.financialSituationSub' />
								</th>
								<th className='w-20'>
									<FormattedMessage id='strategicDiagnosis.businessActivity.forecast' />
								</th>
								<th className='w-20'>
									<FormattedMessage id='strategicDiagnosis.businessActivity.nyear' />
								</th>
								<th className='w-20'>
									<FormattedMessage id='strategicDiagnosis.businessActivity.n1year' />
								</th>
								<th className='w-20'>
									<FormattedMessage id='strategicDiagnosis.businessActivity.n2year' />
								</th>
							</tr>
						</thead>
						<tbody>{generateTableRows()}</tbody>
					</Table>
				</Col>
			</Row>
		</fieldset>
	);
}
