import React, {useEffect, useState} from 'react';
import {Accordion, Col, Form, Row, Table} from 'react-bootstrap';
import {FormattedMessage, useIntl} from 'react-intl';
import {Arrays} from '../../utils/Arrays';
import {EnumSelectField} from '../bootstrap/EnumSelectField';
import {CandidatureFinancialUtils} from "../../utils/CandidatureFinancialUtils";

import {AccretionViewer} from "./AccretionViewer";
import {DateUtils} from "../../utils/DateUtils";
import {currencyFormat, maxFixed} from "../../utils/CurrencyUtils";

export function InvestmentTabTest({candidature}) {

    const [title, setTitle] = useState('ALL');

    const iefpValues = candidature.candidatureEmployeeSupportOtherDetails.filter(detail => detail.kind === 'IEFP');
    const finalValues = candidature.candidatureEmployeeSupportOtherDetails.filter(detail => detail.kind === 'FINAL');


    const [accretionResults, setAccretionResults] = useState(null);


    useEffect(() => {
        fetchDataAccretion('ALL');
    }, []);


    async function fetchDataAccretion(title) {
        let result = [];
        let canditureESODEtails = (title === 'ALL') ? candidature.candidatureEmployeeSupportOtherDetails : candidature.candidatureEmployeeSupportOtherDetails.filter(detail => detail.kind === title);

        if (canditureESODEtails.length > 0) {
            canditureESODEtails = canditureESODEtails.filter(detail => !detail.kind.startsWith('TEMPORARY'));
            for (const detail of canditureESODEtails) {
                let valueToPush = {}
                valueToPush['kind'] = detail.kind;
                let candidatureEmployeeSupportOtherDetailsArray = [];
                candidatureEmployeeSupportOtherDetailsArray.push(detail)


                const [
                    subRepresentation, isInside, levelsAccretion, iefpAccretion
                ] = await Promise.all([
                    await CandidatureFinancialUtils.getSubRepresentation(candidature.candidatureDetails.cae, candidature.promotersMetaData),
                    await CandidatureFinancialUtils.getIsInside(candidature.parish),
                    await CandidatureFinancialUtils.getLevelsAccretion(candidatureEmployeeSupportOtherDetailsArray),
                    await CandidatureFinancialUtils.getIefpAccretion(candidatureEmployeeSupportOtherDetailsArray)
                ]);
                valueToPush['subRepresentation'] = subRepresentation;
                valueToPush['isInside'] = isInside;

                valueToPush['levelsAccretion'] = levelsAccretion;
                valueToPush['iefpAccretion'] = iefpAccretion;
                valueToPush['iSInnovationProject'] = detail.iSInnovationProject;
                valueToPush['reasonToInnovationProject'] = detail.reasonToInnovationProject;

                console.log(detail)

                if (detail.kind !== 'PROMOTER') {
                    const details = candidature.candidatureEmployeeSupportOtherDetails.filter(detail => detail.kind === 'PROMOTER')[0];
                    const economicDetails = candidature.economicViabilityAccretionValidations.filter(d => d.kind === detail.kind)[0]
                    valueToPush['subRepresentation'] = economicDetails.isSubRepresentationValid === true;
                    valueToPush['isInside'] = economicDetails.isInsideValid === true ;
                    valueToPush['levelsAccretion'] = economicDetails.isLevelsValid === true ? economicDetails.levelsValue : 0;

                    valueToPush['iefpAccretion'] = economicDetails.isIefpWorkstationsValid === true ? economicDetails.iefpWorkstationsValue : 0;
                    valueToPush['iSInnovationProject'] = economicDetails.isInnovationProjectValid === true;
                    console.log(details)
                }


                const accretionTotal = await CandidatureFinancialUtils.getAccretionTotal(valueToPush['subRepresentation'], valueToPush['isInside'], valueToPush['levelsAccretion'], valueToPush['iefpAccretion'], {iSInnovationProject: valueToPush['iSInnovationProject']});
                valueToPush['accretionTotal'] = accretionTotal;


                let investment = candidature.financialInvestmentDetails.filter(investment => investment.kind === detail.kind && investment.financialInvestmentType == 'TOTAL')
                let plan = candidature.investmentPlanDetails.filter(plan => plan.kind === detail.kind && plan.investmentType == 'TOTAL')
                console.log(plan);
                console.log(candidature.investmentPlanDetails)
                if (investment?.length !== 0) {
                    valueToPush['investment'] = investment[0];
                    valueToPush['plan'] = plan[0];
                }
                result.push(valueToPush);

            }


        }
        setAccretionResults(result);


    }

    function handleChange(e) {
        switch (e.target.value) {
            case 'PROMOTER':
                setTitle('PROMOTER');
                break;

            case 'IEFP':
                setTitle('IEFP');
                break;

            case 'FINAL':
                setTitle('FINAL');
                break;

            case 'ALL':
                setTitle('ALL');
                break;

            default:
                break;
        }
    }


    function getWorkstationsTypeFilterOptions() {
        let options = ['ALL', 'PROMOTER'];

        if (iefpValues.length > 0) {
            options.push('IEFP');
        }

        if (finalValues.length > 0) {
            options.push('FINAL');
        }

        return options;
    }

    function displayDetails() {

        console.log(accretionResults)
        if (accretionResults === null)
            return ''
        return (
            <div>{(title === 'ALL' ? accretionResults : accretionResults.filter(a => a.kind === title)).map((detail, index) => (
                <div className='border mb-3'>

                    {title === 'ALL' && (
                        <h5 className='text-secondary mt-2 mx-3'>
                            <FormattedMessage id={`candidatureTabs.accretion.${detail.kind}`}/>
                        </h5>
                    )

                    }

                    <label className='mx-3'><FormattedMessage
                        id={'candidatureForm.accretion.subRepresentation'}/></label>
                    <p className='mx-3'>
                        <strong>

                            {detail.subRepresentation === true ? (intl.formatMessage(
                                {id: 'accretion.with.value'},
                                {value: 15}
                            )) : <FormattedMessage id={'accretion.without'}/>}
                        </strong>
                    </p>

                    <label className='mx-3'><FormattedMessage
                        id={'candidatureForm.accretion.isInnovationProject'}/></label>
                    <p className='mx-3'>


                        {detail.iSInnovationProject === true ? (<><strong><FormattedMessage id={'accretion.with.value'}
                                                                                            values={{value: '15'}}/></strong>
                                {detail.reasonToInnovationProject !== undefined && (' - ' + detail.reasonToInnovationProject)}  </>) :
                            <FormattedMessage id={'accretion.without'}/>}
                    </p>


                    <label className='mx-3'><FormattedMessage id={'candidatureForm.accretion.levels'}/></label>
                    <p className='mx-3'>
                        <strong>

                            {detail.levelsAccretion !== 0 ? (intl.formatMessage(
                                {id: 'accretion.with.value'},
                                {value: detail.levelsAccretion}
                            )) : <FormattedMessage id={'accretion.without'}/>}
                        </strong>
                    </p>
                    <label className='mx-3'><FormattedMessage id={'candidatureForm.accretion.isInside'}/></label>
                    <p className='mx-3'>
                        <strong>

                            {detail.isInside === true ? (intl.formatMessage(
                                {id: 'accretion.with.value'},
                                {value: 25}
                            )) : <FormattedMessage id={'accretion.without'}/>}
                        </strong>
                    </p>
                    <label className='mx-3'><FormattedMessage id={'candidatureForm.accretion.iefpAccretion'}/></label>
                    <p className='mx-3'>
                        <strong>

                            {detail.iefpAccretion !== 0 ? (intl.formatMessage(
                                {id: 'accretion.with.value'},
                                {value: detail.iefpAccretion}
                            )) : <FormattedMessage id={'accretion.without'}/>}
                        </strong>
                    </p>

                    <div className='mx-3 '>

                        <AccretionViewer investment={detail.investment} plan={detail.plan}
                                         accretionTotal={detail.accretionTotal} index={index}/>


                    </div>

                </div>


            ))

            }</div>
        )


    }


    const CURRENT_YEAR = DateUtils.removeDays(new Date(candidature.candidatureDetails.forecastDate), 15).getFullYear();
    const NEXT_YEAR = CURRENT_YEAR + 1;

    const intl = useIntl();

    const [investmentPlanFilter, setInvestmentPlanFilter] = useState(0);
    const [financialInvestmentFilter, setFinancialInvestmentFilter] = useState(0);

    function getInvestmentValueByKey(values, key) {
        let investments = values.filter((value) => {

            if (investmentPlanFilter === 'Total') {
                return value.investmentType === 'TOTAL';
            } else {

                return value.year === parseInt(investmentPlanFilter);
            }
        });
        return investments.length > 0 ? currencyFormat(investments[0][key]) : 0;
    }

    function getFinancialInvestmentValueByKey(values, key) {
        let financials = values.filter((value) => {
            if (financialInvestmentFilter === 'Total') {
                return value.financialInvestmentType === 'TOTAL';
            } else {
                return value.year === parseInt(financialInvestmentFilter);
            }
        });
        return financials.length > 0 ? currencyFormat(financials[0][key]) : "0.0€ ";
    }

    function getInvestmentDetailsTableRowsByKey(key) {
        const promoterValues = candidature.investmentPlanDetails.filter(
            (ipd) => ipd.kind === 'PROMOTER'
        );

        const iefpValues = candidature.investmentPlanDetails.filter((ipd) => ipd.kind === 'IEFP');

        const finalValues = candidature.investmentPlanDetails.filter((ipd) => ipd.kind === 'FINAL');

        return (
            <tr key={key}>
                <td className={`${INVESTMENT_PLAN_DETAILS_BOLD_KEYS.includes(key) && 'table-item-bold'}`}>
                    <FormattedMessage id={`economicViabilityFormStep3.${key}`}/>
                </td>
                <td className='w-20 text-end'>
                    <span>{getInvestmentValueByKey(promoterValues, key)}</span>
                </td>
                {Arrays.isNotEmpty(iefpValues) && (
                    <td className='w-20 text-end'>
                        <span>{getInvestmentValueByKey(iefpValues, key)}</span>
                    </td>
                )}
                {Arrays.isNotEmpty(finalValues) && (
                    <td className='w-20 text-end'>
                        <span>{getInvestmentValueByKey(finalValues, key)}</span>
                    </td>
                )}
            </tr>
        );
    }

    function getFinancialInvestmentDetailsTableRowsByKey(key) {
        const promoterValues = candidature.financialInvestmentDetails.filter(
            (fid) => fid.kind === 'PROMOTER'
        );

        const iefpValues = candidature.financialInvestmentDetails.filter((fid) => fid.kind === 'IEFP');

        const finalValues = candidature.financialInvestmentDetails.filter(
            (fid) => fid.kind === 'FINAL'
        );

        return (
            <tr key={key}>
                <td
                    className={`${
                        FINANCIAL_INVESTMENT_DETAILS_BOLD_KEYS.includes(key) && 'table-item-bold'
                    }`}>
                    <FormattedMessage id={`economicViabilityFormStep4.${key}`}/>
                </td>
                <td className='w-20 text-end'>
                    <span>{getFinancialInvestmentValueByKey(promoterValues, key)}</span>
                </td>
                {Arrays.isNotEmpty(iefpValues) && (
                    <td className='w-20 text-end'>
                        <span>{getFinancialInvestmentValueByKey(iefpValues, key)}</span>
                    </td>
                )}
                {Arrays.isNotEmpty(finalValues) && (
                    <td className='w-20 text-end'>
                        <span>{getFinancialInvestmentValueByKey(finalValues, key)}</span>
                    </td>
                )}
            </tr>
        );
    }

    function getInvestmentPlanTableHeads(type) {
        const iefpValues =
            type === 'investmentPlan'
                ? candidature.investmentPlanDetails.filter((ipd) => ipd.kind === 'IEFP')
                : candidature.financialInvestmentDetails.filter((fid) => fid.kind === 'IEFP');

        const finalValues =
            type === 'investmentPlan'
                ? candidature.investmentPlanDetails.filter((ipd) => ipd.kind === 'FINAL')
                : candidature.financialInvestmentDetails.filter((fid) => fid.kind === 'FINAL');

        return (
            <tr>
                <th>
                    <FormattedMessage id='candidatureTabs.investment'/>
                </th>
                <th className='w-20 text-end'>
                    <FormattedMessage id='candidatureTabs.promoterValues'/>
                </th>
                {Arrays.isNotEmpty(iefpValues) && (
                    <th className='w-20 text-end'>
                        <FormattedMessage id='candidatureTabs.iefpValues'/>
                    </th>
                )}
                {Arrays.isNotEmpty(finalValues) && (
                    <th className='w-20 text-end'>
                        <FormattedMessage id='candidatureTabs.finalValues'/>
                    </th>
                )}
            </tr>
        );
    }

    return (
        <>
            <Accordion defaultActiveKey='0' alwaysOpen>
                <Accordion.Item eventKey='0'>
                    <Accordion.Header>
                        <FormattedMessage id='economicViabilityForm.investmentPlanTab'/>
                    </Accordion.Header>
                    <Accordion.Body>
                        <Row className='mb-3'>
                            <Col md='3'>

                                <Form.Control
                                    as='select'
                                    name={'investmentPlanFilter'}
                                    onChange={(e) => setInvestmentPlanFilter(e.target.value)}
                                >
                                    <option value='0'>
                                        {CURRENT_YEAR}
                                    </option>
                                    <option value='1'>
                                        {NEXT_YEAR}
                                    </option>
                                    <option value='Total'>
                                        Total
                                    </option>


                                </Form.Control>
                            </Col>
                        </Row>

                        <Table className='mt-4'>
                            <thead>{getInvestmentPlanTableHeads('investmentPlan')}</thead>
                            <tbody>
                            {INVESTMENT_PLAN_DETAILS_KEYS.map((key) => getInvestmentDetailsTableRowsByKey(key))}
                            </tbody>
                        </Table>

                        <Row className='mt-5'>
                            <Col md='12'>
                                <label>
                                    <FormattedMessage id='candidatureForm.investmentPlanReasoning'/>
                                </label>
                                <p>{candidature.candidatureDetails.investmentPlanReasoning}</p>
                            </Col>
                        </Row>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey='1'>
                    <Accordion.Header>
                        <FormattedMessage id='economicViabilityForm.financialInvestmentTab'/>
                    </Accordion.Header>
                    <Accordion.Body>
                        <Row className='mb-3'>
                            <Col md='3'>
                                <Form.Control
                                    as='select'
                                    name={'financialInvestmentFilter'}
                                    onChange={(e) => setFinancialInvestmentFilter(e.target.value)}
                                >
                                    <option value='0'>
                                        {CURRENT_YEAR}
                                    </option>
                                    <option value='1'>
                                        {NEXT_YEAR}
                                    </option>
                                    <option value='Total'>
                                        Total
                                    </option>

                                </Form.Control>

                            </Col>
                        </Row>

                        <Table className='mt-4'>
                            <thead>{getInvestmentPlanTableHeads('financialInvestment')}</thead>
                            <tbody>
                            {FINANCIAL_INVESTMENT_DETAILS_KEYS.map((key) =>
                                getFinancialInvestmentDetailsTableRowsByKey(key)
                            )}
                            </tbody>
                        </Table>

                        <Row className='mt-5'>
                            <Col md='12'>
                                <label>
                                    <FormattedMessage id='candidatureForm.financialInvestmentReasoning'/>
                                </label>
                                <p>{candidature.candidatureDetails.financialInvestmentReasoning}</p>
                            </Col>
                        </Row>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey='2'>
                    <Accordion.Header>
                        <FormattedMessage id='accretion.previsions'/>
                    </Accordion.Header>
                    <Accordion.Body>
                        <div className='py-3 mb-4'>
                            <Row className='mb-5'>
                                <Col md='6'>
                                    <EnumSelectField
                                        labelId={'candidatureTabs.accretion.filter'}
                                        controlId={'workStationTypeFilter'}
                                        name={'workStationTypeFilter'}
                                        preffixDescriptionId={'candidatureTabs.accretion'}
                                        handleChange={(e) => handleChange(e)}
                                        options={getWorkstationsTypeFilterOptions()}
                                    />
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <small className='text-primary text-uppercase mt-2 mb-2'>
                                        <FormattedMessage id={`candidatureTabs.accretion.title.${title}`}/>
                                    </small>
                                </Col>
                            </Row>

                            {displayDetails()}


                        </div>

                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </>
    );
}

const INVESTMENT_PLAN_DETAILS_KEYS = [
    'investmentProperties',
    'investmentPropertiesNaturalResources',
    'investmentPropertiesBuildings',
    'investmentPropertiesOthers',
    'tangibleAssets',
    'tangibleNaturalResources',
    'tangibleBuildings',
    'tangibleBasicEquipment',
    'tangibleTransportEquipment',
    'tangibleAdministrativeEquipment',
    'tangibleBiologicEquipment',
    'tangibleOthers',
    'intangibleAssets',
    'intangibleGoodwill',
    'intangibleDevelopmentProjects',
    'intangibleComputerPrograms',
    'intangibleIntellectualProperty',
    'intangibleOthers',
    'several',
    'pettyCash',
    'globalTotal'
];

const INVESTMENT_PLAN_DETAILS_BOLD_KEYS = [
    'investmentProperties',
    'tangibleAssets',
    'intangibleAssets',
    'several',
    'pettyCash',
    'globalTotal'
];

const FINANCIAL_INVESTMENT_DETAILS_KEYS = [
    'ownCapital',
    'ownSocialCapital',
    'ownCapitalUnemploymentBenefits',
    'ownCapitalOthers',
    'foreignCapital',
    'foreignCapitalBankLoans',
    'foreignCapitalPartnerLoans',
    'foreignCapitalProvidersLoans',
    'foreignCapitalOthers',
    'iefpAskedAmount',
    'investmentSupport',
    'workStationsCreationBenefits',
    'globalTotal'
];

const FINANCIAL_INVESTMENT_DETAILS_BOLD_KEYS = [
    'ownCapital',
    'foreignCapital',
    'iefpAskedAmount',
    'globalTotal'
];
