import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { ConfigObject } from '../config';
import { createCustomErrorMessage } from '../hooks/errorMessage';
import {
	deleteDocument,
	deleteDocumentbyCandidatureId,
	uploadDocument,
	uploadDocumentByCandidatureId,
} from '../rest/document';
import { Arrays } from '../utils/Arrays';
import { Strings } from '../utils/Strings';
import { AlertError } from './bootstrap/AlertError';
import { AuthenticatedDownloadLink } from './document/AuthenticatedDownloadLink';
import { UploadDocument } from './UploadDocument';

export function MultipleUploadArea({
	documents,
	documentType,
	candidatureExternalId,
	setDocuments,
	labelId,
	index,
	nonApplicableOptionValue,
	nonApplicableMultipleRef,
	setFieldValue,
	candidatureFormStep,
	uploadCallback,
	deleteCallback,
	readMode,
   	acceptExtensions,
	overrideClass
}) {
	const intl = useIntl();
	const [notApplicable,setNotApplicable] = useState(false);
	const [error, setError] = useState(null);
	const [filteredDocuments, setFilteredDocuments] = useState(
		getInitialValues()
	);

	const [nonApplicableOption, setNonApplicableOption] = useState(
		nonApplicableOptionValue || null
	);

	const [uploadButtonDisabled, setUploadButtonDisabled] = useState(false);

	const nonApplicableChangeOnDeleteBlocked = !nonApplicableOptionValue;

	if (acceptExtensions === undefined) {
		acceptExtensions = ConfigObject.get().documentExtensions.split('|').map(e => '.' + e).join(',');
	}

	function getInitialValues() {
		return documents.filter(
			(document) => document.documentType === documentType
		);
	}

	async function handleUpload(file, setFile, hiddenFileInput) {
		const isFieldsValid = validateFields(file);
		if (!isFieldsValid) {
			return;
		}
		try {
			// Create formData
			const formData = new FormData();
			formData.append('file', file.content);
			formData.append('fileName', file.content.name);
			formData.append('documentType', documentType);
			formData.append('fileDescription', file.fileDescription);
			formData.append('size', file.content.size);
			formData.append('extension', file.content.type);

			// Make the request
			const { data: document } =
				typeof candidatureExternalId === 'undefined'
					? uploadCallback
						? await uploadCallback(formData)
						: await uploadDocument(formData)
					: await uploadDocumentByCandidatureId(
							formData,
							candidatureExternalId
					  );

			let fileToPush = {
				externalId: document.externalId,
				name: document.name,
				fileDescription: file.fileDescription,
				documentType: documentType,
				documentPath: document.documentPath,
			};

			if (nonApplicableOptionValue) {
				fileToPush.nonApplicable = false;
				fileToPush.submited = true;
			}

			filteredDocuments.push(fileToPush);
			setFilteredDocuments([...filteredDocuments]);
			setDefaultFileValues(setFile, hiddenFileInput);

			if (setDocuments && !setFieldValue) {
				documents.push(fileToPush);
				setDocuments([...documents]);
			}



		  if (setFieldValue) {
			documents.push(fileToPush);

			switch (candidatureFormStep) {
			  case 20:
				setFieldValue('step10Documents', [...documents]);
				break;
			  case 19:
				setFieldValue('step19Documents', [...documents]);
				if(setDocuments){
				  setDocuments([...documents]);
				}
				break;
			  case 18:
				setFieldValue('step18Documents', [...documents]);
				break;
						default:
							break;
					}
				}

				setNonApplicableOption(false);
				setError(null);
			} catch (error) {
				setError(error);
			}
		}

	async function deleteFile(externalId) {
		try {
			if (typeof candidatureExternalId === 'undefined') {
				deleteCallback ? await deleteCallback(externalId) : await deleteDocument(externalId);
			} else {
				await deleteDocumentbyCandidatureId(externalId, candidatureExternalId);
			}
			setFilteredDocuments(
				filteredDocuments.filter(
					(document) => document.externalId !== externalId
				)
			);

			if (setDocuments && !setFieldValue) {
				setDocuments(
					documents.filter((document) => document.externalId !== externalId)
				);
			}

      if (setFieldValue) {
        switch (candidatureFormStep) {
          case 20:
            setFieldValue(
              'step10Documents',
              documents.filter((document) => document.externalId !== externalId)
            );
            break;
          case 19:

            setFieldValue(
                'step19Documents',
                documents.filter((document) => document.externalId !== externalId)
            );
           if(setDocuments){
              setDocuments(documents.filter((document) => document.externalId !== externalId));
            }
            break;
          case 18:
            setFieldValue(
                'step18Documents',
                documents.filter((document) => document.externalId !== externalId)
            );
            break

					default:
						break;
				}
			}

			if (
				!nonApplicableChangeOnDeleteBlocked &&
				filteredDocuments.length === 1
			) {
				setNonApplicableOption(true);
			}
		} catch (error) {
			setError(error);
		}
	}

	function validateFields(data) {
		if (Strings.isBlank(data.fileDescription)) {
			setError(
				createCustomErrorMessage(
					intl.formatMessage({
						id: 'errors.candidatureForm.requiredFileDescription',
					})
				)
			);
			return false;
		}

		if (data.content === null) {
			setError(
				createCustomErrorMessage(
					intl.formatMessage({
						id: 'errors.uploadArea.fileRequired',
					})
				)
			);
			return false;
		}

		const documentExtensions = ConfigObject.get().documentExtensions.split('|');
		const documentsSize = ConfigObject.get().documentsSize * 1000000;

		const file = data.content;

		let lastIndex = file.name.lastIndexOf('.');
		let extension = file.name.substring(lastIndex+1);

		if (!documentExtensions.includes(extension)) {
			setError(
				createCustomErrorMessage(intl.formatMessage({ id: 'errors.uploadArea.fileExtension' }))
			);
			return false;
		}


		if (file.size > documentsSize) {
			setError(createCustomErrorMessage(intl.formatMessage({ id: 'errors.uploadArea.fileSize'},{value: ConfigObject.get().documentsSize} )));
			return false;
		}

		return true;
	}

	function setDefaultFileValues(setFile, hiddenFileInput) {
		setFile({
			content: null,
			fileDescription: '',
		});
		hiddenFileInput.current.value = '';
	}

	function handleNonApplicableChange() {
		setUploadButtonDisabled(uploadButtonDisabled ? false : true);
		nonApplicableMultipleRef.current = uploadButtonDisabled ? false : true;
		documents[0].nonApplicable=!notApplicable;

		setNotApplicable(!notApplicable);

	}

	return (
		<div className={overrideClass? overrideClass : 'pt-2'} >
			<AlertError error={error} />
			{labelId && (
				<Form.Label>
					<FormattedMessage id={labelId} />
				</Form.Label>
			)}
			{nonApplicableOption && filteredDocuments.length === 0 && (
				<Form.Check
					type={'checkbox'}
					label={'Não aplicável'}
					onClick={() => handleNonApplicableChange()}
				/>
			)}
			{!uploadButtonDisabled && (
				<div className={`bg-light px-3 pt-1 pb-2 ${labelId ? 'mb-4' : 'my-4'}`}>
					{!Arrays.isEmpty(filteredDocuments) ? (
						<div className='row mt-3'>
							<div className='col-lg-12'>
								<span className='font-small text-secondary'>
									{filteredDocuments.length}{' '}
									{filteredDocuments.length > 1
										? 'ficheiros carregados'
										: 'ficheiro carregado'}
								</span>
							</div>
						</div>
					) : (
						<div className='row my-3'>
							<div className='col-lg-12'>
								<p className='font-small text-secondary font-weight-italic text-center mb-0'>
									<FormattedMessage id='all.noFilesUploaded' />
								</p>
							</div>
						</div>
					)}
					<p>
						<small className='text-secondary'>
							<FormattedMessage id='all.documentTypesAllowed' />
							{ConfigObject.get().documentExtensions.split('|').join(', ')}
						</small>
					</p>
					{!Arrays.isEmpty(filteredDocuments) &&
						filteredDocuments.map((document, index) => (
							<div key={index} className='border-top py-2'>
								<div className='d-flex justify-content-between'>
									<div>
										{document.fileDescription}
										<span className='mx-2'>·</span>
										<AuthenticatedDownloadLink
											url={document.documentPath}
											filename={document.name}
										>
											{document.name}
										</AuthenticatedDownloadLink>
									</div>
									{document.canDelete!==false &&
									<div>
										<button
											className='btn btn-link p-0'
											type='button'
											onClick={(e) => deleteFile(document.externalId)}
										>
											<FormattedMessage id='all.remove' />
										</button>
									</div>
									}

								</div>
							</div>
						))}
				</div>
			)}
			{!readMode &&
				<div className='px-3'>
					{!uploadButtonDisabled && (
						<UploadDocument acceptExtensions={acceptExtensions} onSubmit={handleUpload} index={index && index} />
					)}
				</div>
			}
		</div>
	);
}
