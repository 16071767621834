import React, {useState} from 'react';
import {Accordion, Col, Row} from 'react-bootstrap';
import {FormattedMessage, useIntl} from 'react-intl';
import {SellInformationViewer} from "./SellInformationViewer";
import {Arrays} from "../../utils/Arrays";
import {ProviderType} from "../../models/ProviderType";
import {PeopleType} from "../../models/PeopleType";
import {FunctionType} from "../../models/FunctionType";
import {ProviderInformationAccordionItem} from "./ProviderInformationAccordionItem";

export function PeopleBoardAccordionItem({peopleBoardList,yearNames}) {
    const intl = useIntl();


    function getTransformInformation() {
        if (peopleBoardList?.length > 0) {
            let grouped = Arrays.groupBy(peopleBoardList[0].peopleBoardCellList, pBoard => pBoard.functionType + pBoard.peopleType);
            grouped.forEach(pBoard => (pBoard.sort((a, b) => a.year - b.year)));
            return grouped;
        }
        return null;
    }


    function getPeopleBoardTotals() {
        if (peopleBoardList?.length > 0) {
            let grouped = Arrays.groupBy(peopleBoardList[0].peopleBoardCellList, pBoard => pBoard.peopleType + pBoard.year);


            let finalGrouped = [];

            grouped.forEach(group => {
                finalGrouped[group[0].peopleType + group[0].year] = group.map((t2) => (t2.value)).reduce((total, count) => total + count)
            })

            return finalGrouped;
        }
        return null;
    }

    const peopleBoardFinal = getTransformInformation();
    const peopleBoardTotals = getPeopleBoardTotals();


    return (
        <>
            {(peopleBoardFinal != null) && (
                <Accordion.Item eventKey="peopleBoard">
                    <Accordion.Header><FormattedMessage
                        id='candidatureTabs.accordion.peopleBoard'/></Accordion.Header>
                    <Accordion.Body>

                        {PeopleType.values().map((pType, index) => (
                            <div key={index} className='tableWithBorders'>
                                <p>
                                    <strong>{intl.formatMessage({id: `peopleType.${pType}`})}</strong>
                                </p>
                                <table className='w-100 mb-5 border-top border-1'>

                                    <thead>
                                    <tr className='border-1 border-bottom '>
                                        <th><FormattedMessage id='peopleBoard.function'/></th>
                                        {peopleBoardFinal.get('OTHEROTHER').map((year, index1) => (
                                            <th key={index1} className='text-center'>{yearNames[year.year]}</th>
                                        ))}
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {FunctionType.values().map((fType, index1) => (
                                                <tr key={index1} className='border-1 border-bottom '>
                                                    <td>{intl.formatMessage({id: `functionType.${fType}`})}</td>
                                                    {peopleBoardFinal.get(fType + pType).map((year,index2) => (
                                                        <td key={index2} className='text-center withBorderLeft'>{year.value}</td>
                                                    ))}
                                                </tr>

                                    ))}
                                    <tr className='border-1 border-bottom '>
                                        <td className='text-end'><strong><FormattedMessage
                                            id='peopleBoard.total'/></strong></td>

                                        {peopleBoardFinal.get('OTHEROTHER').map((year, index1) => (
                                            <td key={index1}
                                                className='text-center withBorderLeft'>{peopleBoardTotals[pType + year.year]}</td>
                                        ))}

                                    </tr>
                                    </tbody>
                                </table>


                            </div>


                        ))


                        }


                        <Row>
                            <Col md='12'>
                                <label>
                                    <FormattedMessage id='peopleBoard.reason'/>
                                </label>
                                <p>
                                    {peopleBoardList[0].reasonToPeopleBoard}
                                </p>
                            </Col>
                        </Row>
                    </Accordion.Body>
                </Accordion.Item>

            )}
        </>
    );
}
