import {Accordion, Col, Row} from "react-bootstrap";
import AccordionItem from "react-bootstrap/AccordionItem";
import AccordionHeader from "react-bootstrap/AccordionHeader";
import {FormattedMessage} from "react-intl";
import AccordionBody from "react-bootstrap/AccordionBody";
import React from "react";
import {AuthenticatedDownloadLink} from "../../document/AuthenticatedDownloadLink";
import {DocumentTable} from "./DocumentTable";
import {DocumentType} from "../../../models/DocumentType";

export function EAAAppeal({accreditationDecision}) {
    return (
        <Accordion className='mt-0 mb-3'>
            <AccordionItem className='mt-0 mb-0' eventKey={'appeal'}>
                <AccordionHeader>
                    <FormattedMessage id={'ea.accreditation.results.appeal'} />
                </AccordionHeader>
                <AccordionBody>
                    <Row className={'mb-3'}>
                        <Col>
                            <label><FormattedMessage id={'ea.accreditation.results.decision.date'}/></label>
                            {accreditationDecision.appealDate}
                        </Col>
                    </Row>
                    <Row className={'mb-3'}>
                        <Col>
                            <label><FormattedMessage id={'ea.accreditation.results.justification'}/></label>
                            {accreditationDecision.appealComment}
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <label><FormattedMessage id={'all.requiredDocuments2'}/></label>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <DocumentTable documents={accreditationDecision?.documents.filter(d => d.documentType === DocumentType.EAA_APPEAL_DOCUMENT)} />
                        </Col>
                    </Row>
                </AccordionBody>
            </AccordionItem>
        </Accordion>
    );
}