import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import useIntl from 'react-intl/lib/src/components/useIntl';
import { Link } from 'react-router-dom';
import { AlertError } from '../../../components/bootstrap/AlertError';
import Loading from '../../../components/general/Loading';
import { SubTemplate } from '../../../components/general/SubTemplate';
import { listDocumentsByDocumentType } from '../../../rest/document';
import { getEntity } from '../../../rest/entity';
import { handleError, isNotBusinessError } from '../../../utils/handleError';
import {AuthenticatedDownloadLink} from "../../../components/document/AuthenticatedDownloadLink";

export function ViewCooperationProtocol() {
  const intl = useIntl();

  const [cooperationProtocolDocuments, setCooperationProtocolDocuments] = useState([]);
  const [contractDates, setContractDates] = useState({});

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchData();
  }, []);

  async function fetchData() {
    try {
      const [{ data: entity }, { data: documents }] = await Promise.all([
        await getEntity(),
        await listDocumentsByDocumentType('COOPERATION_PROTOCOL')
      ]);

      setContractDates(getInitialDates(entity));
      setCooperationProtocolDocuments(getInitialFiles(documents));
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  }

  function getInitialFiles(documents) {
    let files = [];

    if (documents.length > 0) {
      files.push({
        externalId: documents[0].externalId,
        documentType: documents[0].documentType,
        documentPath: documents[0].documentPath,
        name: documents[0].name,
        content: '',
        submited: true,
        error: ''
      });
    } else {
      files.push({
        externalId: '',
        documentType: 'COOPERATION_PROTOCOL',
        name: '',
        content: null,
        submited: false,
        error: ''
      });
    }
    return files;
  }

  function getInitialDates(entity) {
    let dates = {};

    if (entity.hasOwnProperty('accreditationDate')) {
      dates.accreditationDate = entity.accreditationDate;
    }

    if (entity.hasOwnProperty('contractEndDate')) {
      dates.contractEndDate = entity.contractEndDate;
    }

    return dates;
  }

  if (loading) {
    return <Loading />;
  }

  if (error && isNotBusinessError(error)) {
    return handleError(error);
  }

  return (
    <SubTemplate titleId={'entityHome.eaProtocolCooperation'} centerContent hasBackButton>
      <Container>
        <Row>
          <div className='col-lg-8 offset-lg-2 mb-5'>
            <AlertError error={error} />
            <div className='form'>
              <Row className={'mb-4'}>
                <Col>
                  {cooperationProtocolDocuments.map((d, index) =>(
                      <React.Fragment key={index}>
                        <label><FormattedMessage id={`documentType.${d.documentType}`}/></label>
                        <AuthenticatedDownloadLink url={d.documentPath} filename={d.name}>
                          <small>{d.name}</small>
                        </AuthenticatedDownloadLink>
                      </React.Fragment>
                  ))}
                </Col>
              </Row>
              <Row className='mb-4'>
                <Col>
                  <label>
                    <FormattedMessage id='entity.accreditationDate' />
                  </label>
                  <p>{contractDates.accreditationDate}</p>
                </Col>
                <Col>
                  <label>
                    <FormattedMessage id='entity.contractEndDate' />
                  </label>
                  <p>{contractDates.contractEndDate}</p>
                </Col>
              </Row>

              <Row>
                <Col>
                  <div className='form-actions mt-1'>
                    <Link className='btn btn-link' to={`/`}>
                      <FormattedMessage id='all.back' />
                    </Link>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </Row>
      </Container>
    </SubTemplate>
  );
}
