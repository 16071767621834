import React, {useEffect, useState} from 'react';
import {Button, Col, Form, Modal, Row} from 'react-bootstrap';
import {FormattedMessage, useIntl} from 'react-intl';
import {useNavigate, useParams} from 'react-router-dom';
import {SingleUploadArea} from '../../../components/SingleUploadArea';
import Loading from '../../../components/general/Loading';
import {DocumentType} from '../../../models/DocumentType';
import * as yup from "yup";
import {Formik} from 'formik';
import {AccreditationRevocationState} from "../../../models/AccreditationRevocationState";
import {
    addAccreditationRevocations,
    decisionAccrediationRevocation,
    unilateralAccrediationRevocation
} from "../../../rest/accreditationRevocation";
import {EnumSelectField} from "../../../components/bootstrap/EnumSelectField";
import {RestitutionType} from "../../../components/oversight/tabs/noncompliance/RestitutionType";
import DecimalInputField from "../../../components/bootstrap/DecimalInputField";
import {RevocationType} from "../../../components/oversight/tabs/noncompliance/RevocationType";

export function AccreditationRevocationRequestValidation({
                                                             accreditationRevocation,
                                                             isApproval,
                                                             handleClose
                                                         }) {
    const intl = useIntl();
    const {nif} = useParams();

    const [loading, setLoading] = useState(true);


    const navigate = useNavigate();

    const [error, setError] = useState(null);
    const [isApprove, setIsApprove] = useState(isApproval);
    const [isUnilateral, setIsUnilateral] = useState(false);
    const [publicDocument, setPublicDocument] = useState([]);
    const [privateDocument, setPrivateDocument] = useState([]);
    const [publicDocumentType, setPublicDocumentType] = useState(
        DocumentType.ACCREDITATION_REVOCATION_DECISION
    );
    const [privateDocumentType, setPrivateDocumentType] = useState(
        DocumentType.ACCREDITATION_REVOCATION_DECISION_PRIVATE
    );

    const isContestationAnalysisState = accreditationRevocation !== undefined &&
        AccreditationRevocationState.ANALYZE_CONTESTATION === accreditationRevocation.state;

    const isCreation = !isContestationAnalysisState && accreditationRevocation == null;

    if(isCreation && isUnilateral && !isApprove) {
        setIsApprove(true);
    } else if(isCreation && !isUnilateral && isApprove) {
        setIsApprove(false);
    }

    async function fetchData() {
        try {
            const publicType =
                isContestationAnalysisState || isApprove === true
                    ? DocumentType.ACCREDITATION_REVOCATION_FINAL_DECISION
                    : DocumentType.ACCREDITATION_REVOCATION_DECISION;
            const privateType =
                isContestationAnalysisState || isApprove === true
                    ? DocumentType.ACCREDITATION_REVOCATION_FINAL_DECISION_PRIVATE
                    : DocumentType.ACCREDITATION_REVOCATION_DECISION_PRIVATE;

            setPublicDocumentType(publicType);
            setPrivateDocumentType(privateType);

            loadDocument(isContestationAnalysisState !== true ? [] : accreditationRevocation.documents.filter(d => d.documentType === publicType), setPublicDocument, publicType);
            loadDocument(isContestationAnalysisState !== true ? [] : accreditationRevocation.documents.filter(d => d.documentType === privateType), setPrivateDocument, privateType);

        } catch (error) {
            setError(error);
        } finally {
            setLoading(false);
        }
    }

    function loadDocument(data, setDocument, documentType) {
        if (data.length !== 0) {
            const document = data[0];
            setDocument([
                {
                    documentType: document.documentType,
                    externalId: document.externalId,
                    name: document.name,
                    documentPath: document.documentPath,
                    content: '',
                    submited: true,
                    error: '',
                },
            ]);
        } else {
            setDocument([
                {
                    documentType: documentType,
                    externalId: '',
                    name: '',
                    content: null,
                    submited: false,
                    error: '',
                },
            ]);
        }
    }

    function canSubmit() {
        return publicDocument[0]?.submited && privateDocument[0]?.submited;
    }


    const handleConfirm = async (values, setSubmitting, setErrors) => {
        try {


            let formData = values.filePrivate;
            for (const pair of values.filePublic.entries()) {
                formData.append(pair[0], pair[1]);
            }

            if (isUnilateral) {
                let reqBody = {...values};
                reqBody.approved = true;
                console.log(reqBody)
                formData.append('AccreditationRevocationDecisionDto', JSON.stringify(reqBody));
                await unilateralAccrediationRevocation(nif, formData);
                sessionStorage.setItem(
                    'accreditationRevocationContinue',
                    true
                )
            } else if (isContestationAnalysisState !== true) {
                await addAccreditationRevocations(nif, formData);

                sessionStorage.setItem(
                    'accreditationRevocationCreated',
                    true
                )
            } else {
                let reqBody = {...values};
                console.log(reqBody)

                formData.append('AccreditationRevocationDecisionDto', JSON.stringify(reqBody));
                await decisionAccrediationRevocation(nif, accreditationRevocation.externalId, formData);
                if (isApprove === true) {
                    sessionStorage.setItem(
                        'accreditationRevocationContinue',
                        true
                    )
                } else {
                    sessionStorage.setItem(
                        'accreditationRevocationCancel',
                        true)
                }
            }

            //refresh page
            navigate(0);
        } catch (error) {
            console.log(error)
            setError(error);
        }
    }

    useEffect(() => {
        fetchData();
    }, [isUnilateral]);

    const schema = yup.object().shape({
        filePrivate: yup.mixed().required(<FormattedMessage id='errors.fieldRequiredText'/>),
        filePublic: yup.mixed().required(<FormattedMessage id='errors.fieldRequiredText'/>),
        restitution: yup
            .mixed()
            .when('approved', {
                is: (s) => (s === true),
                then: (schema) => schema
                    .oneOf(RestitutionType.values(), <FormattedMessage id='errors.fieldRequiredText'/>)
                    .required(<FormattedMessage id='errors.fieldRequiredText'/>),
            }).nullable(),

        restitutionValue: yup
            .number()
            .when('approved', {
                is: (s) => (s === true),
                then: (schema) => schema
                    .when('restitution', {
                        is: (r) => (r === RestitutionType.PARTIAL || r === RestitutionType.TOTAL),
                        then: (schema) => schema.typeError(<FormattedMessage id='errors.fieldRequiredText'/>)
                            .required(<FormattedMessage id='errors.fieldRequiredText'/>)
                            .min(0.01, <FormattedMessage id='candidature.payments.form.error.zeroPayment'/>),
                        otherwise: (schema) => schema.nullable()
                    }),
            }).nullable(),
    });


    function handleRestitutionChange(e, setFieldValue) {
        console.log(e.target.value);
        if (e.target.value === '-1' || e.target.value === 'NO') {
            setFieldValue('restitutionValue', 0)
        }
        setFieldValue('restitution', e.target.value)
    }

    return (
        <Modal show={true} dialogClassName='modal-65' onHide={handleClose}>
            <Modal.Header closeButton>
                <FormattedMessage
                    id={`accreditationRevocation.title.${
                        isContestationAnalysisState === false ? 'rejectFirst' : (isApprove ? 'approve' : 'rejectSecond')
                    }`}
                />
            </Modal.Header>
            <Modal.Body>
                {loading ? (
                    <span>
						<Loading isDialog/>
					</span>
                ) : (
                    <Formik
                        validateOnBlur={false}
                        initialValues={{approved: isApprove}}
                        validationSchema={schema}
                        validateOnChange={false}
                        onSubmit={(values, {setSubmitting, setErrors}) => {
                            handleConfirm(values, setSubmitting, setErrors);
                        }}
                    >
                        {({
                              values,
                              errors,
                              touched,
                              handleChange,
                              handleSubmit,
                              setFieldValue,
                          }) => (
                            <form onSubmit={handleSubmit}>
                                {isCreation &&
                                <Row>
                                    <Col>
                                        <Form.Check
                                            checked={isUnilateral}
                                            onChange={(e) => setIsUnilateral(e.target.checked)}
                                            label={<FormattedMessage id={'revocationForm.add.unilateral'}/>}
                                        />
                                    </Col>
                                    <Col>

                                    </Col>
                                </Row>}
                                {isApprove === true &&
                                <Row>
                                    <Col md={6}>
                                        <EnumSelectField
                                            name='restitution'
                                            labelId='candidatureTabs.oversight.tabs.noncompliance.form.aidRestitution'
                                            options={RestitutionType.values()}
                                            preffixDescriptionId='candidatureTabs.oversight.tabs.noncompliance.form.restVal'
                                            nullOption={true}
                                            errorMessage={errors.restitution}
                                            isInvalid={errors.restitution}
                                            handleChange={(e) => handleRestitutionChange(e, setFieldValue)}
                                            value={values.restitution}
                                        />
                                    </Col>
                                    <Col md={6}>

                                        <DecimalInputField
                                            name='restitutionValue'
                                            labelId='candidatureTabs.oversight.tabs.noncompliance.form.restitutionValue'
                                            suffixLabel='€'
                                            errorMessage={errors.restitutionValue}
                                            value={values.restitutionValue}
                                            handleChange={(e) => setFieldValue('restitutionValue', e.target.value)}
                                            disabled={![RevocationType.PARTIAL, RevocationType.TOTAL].includes(values?.restitution)}
                                        />
                                    </Col>
                                </Row>
                                }

                                <div
                                    className={errors.filePrivate ? ' ps-3  pe-3 square border border-danger border-2 mt-3' : 'mt-3'}>
                                    <SingleUploadArea
                                        documents={privateDocument}
                                        setDocuments={setPrivateDocument}
                                        documentType={privateDocumentType}
                                        setError={setError}
                                        processUploadCallback={formData => setFieldValue('filePrivate', formData)}
                                    />
                                    {errors.filePrivate && <span className={'text-danger'}> {errors.filePrivate}</span>}
                                </div>

                                <div
                                    className={errors.filePublic ? ' ps-3  pe-3 square border border-danger border-2 mt-3' : 'mt-3'}>
                                    <SingleUploadArea
                                        documents={publicDocument}
                                        setDocuments={setPublicDocument}
                                        documentType={publicDocumentType}
                                        setError={setError}
                                        processUploadCallback={formData => setFieldValue('filePublic', formData)}
                                    />
                                    {errors.filePublic && <span className={'text-danger'}> {errors.filePublic}</span>}
                                </div>


                                <Row className='form-actions'>
                                    <Col className='justify-content-between d-flex'>
                                        <button className='btn btn-outline-primary' type='button' onClick={handleClose}>
                                            <FormattedMessage id='all.cancel'/>
                                        </button>
                                        <Button variant='primary' type='submit'>
                                            <FormattedMessage id='all.submitButtonText'/>
                                        </Button>
                                    </Col>
                                </Row>
                            </form>
                        )}
                    </Formik>
                )}
            </Modal.Body>
        </Modal>
    );
}
