import React, { useEffect, useState } from 'react';
import { Form, Row, Container, Col } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { AlertError } from '../../components/bootstrap/AlertError';
import Loading from '../../components/general/Loading';
import { SubTemplate } from '../../components/general/SubTemplate';
import {getOpinionEntityContacts, getPriorEntityContacts} from '../../rest/entityCandidature';
import { getUserCandidatureExternalId } from '../../rest/candidature';
import { handleError, isNotBusinessError } from '../../utils/handleError';
import {PriorSupportMessageModal} from "../../components/candidature/PriorSupportMessageModal ";
import {PriorSupportState} from "../../models/PriorSupportState";
import {useNavigate, useParams} from "react-router-dom";

export function OpinionEntityContacts() {
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);
	const [opinionEntityContacts, setOpinionEntityContacts] = useState(null);
	const { candidatureExternalId } = useParams();

	async function fetchData() {
		try {
			console.log(candidatureExternalId)
			let params = { candidatureExternalId: candidatureExternalId };
			let { data: associatedPriorEntityContacts } =
				await getOpinionEntityContacts(params);
			setOpinionEntityContacts(associatedPriorEntityContacts);
		} catch (error) {
			setError(error);
		} finally {
			setLoading(false);
		}
	}

	useEffect(() => {
		fetchData();
	}, []);

	if (loading) {
		return <Loading />;
	}

	if (error && isNotBusinessError(error)) {
		return handleError(error);
	}

	return (
		<SubTemplate hasBackButton={true} titleId='candidatureForm.showOpinionContactsDetails'>
			<Container>
				<Row>
					<Col lg={{ span: 8, offset: 2 }} className='mb-5'>
						<AlertError error={error} />
						<Form>
							<h4 className='text-primary mb-0'>
								<FormattedMessage id='candidatureForm.showOpinionEntityContacts' />
							</h4>

							<Row>
								<Col md='12'>
									<Form.Label className='text-secondary'>
										<FormattedMessage id='candidatureForm.PriorSupportContactsModal.body.name' />
									</Form.Label>
									<p>{opinionEntityContacts.description}</p>
								</Col>

							</Row>
							<Row>
								<Col md='4'>
									<Form.Label className='text-secondary'>
										<FormattedMessage id='candidatureForm.PriorSupportContactsModal.body.nif' />
									</Form.Label>
									<p>{opinionEntityContacts.nif}</p>
								</Col>
								<Col md='4'>
									<Form.Label className='text-secondary'>
										<FormattedMessage id='candidatureForm.PriorSupportContactsModal.body.phone' />
									</Form.Label>
									<p>{opinionEntityContacts.phone}</p>
								</Col>
								<Col md='4'>
									<Form.Label className='text-secondary'>
										<FormattedMessage id='candidatureForm.PriorSupportContactsModal.body.email' />
									</Form.Label>
									<p>{opinionEntityContacts.email}</p>
								</Col>
							</Row>
							<Row>
								<Form.Label className='text-secondary'>
									<FormattedMessage id='candidatureForm.PriorSupportContactsModal.body.address' />
								</Form.Label>
								<p>{opinionEntityContacts.address}</p>
							</Row>
						</Form>
					</Col>

				</Row>
			</Container>
		</SubTemplate>
	);
}
