import {Accordion, Col, Container, Form, Row} from "react-bootstrap";
import {FormattedMessage} from "react-intl";
import React from "react";
import {LCol} from "../ViewEaAccreditation";
import {FaExclamationCircle, FaInfoCircle} from "react-icons/fa";
import {TextInputField} from "../../../bootstrap/TextInputField";
import {EnumSelectField} from "../../../bootstrap/EnumSelectField";
import {NumberInputField} from "../../../bootstrap/NumberInputField";
import ToolTip from "../../../bootstrap/ToolTip";
import {Arrays} from "../../../../utils/Arrays";

export function EAHRTab({tgpList, getList}) {

    let sum = (lst) => lst.reduce((ac, cv) => ac + cv, 0);

    let combinedTGPExperience  = {
        analysisCount: 0,
        approvedProjectFollowUpCount: 0,
        consultingCount: 0,
        expandSkillsCount: 0,
        projectCreationCount: 0,
    };


    if(Arrays.isNotEmpty(tgpList)) {
        combinedTGPExperience = {
            analysisCount: sum(tgpList.map(t => t.hre.analysisCount)),
            approvedProjectFollowUpCount: sum(tgpList.map(t => t.hre.approvedProjectFollowUpCount)),
            consultingCount: sum(tgpList.map(t => t.hre.consultingCount)),
            expandSkillsCount: sum(tgpList.map(t => t.hre.expandSkillsCount)),
            projectCreationCount: sum(tgpList.map(t => t.hre.projectCreationCount)),
        }
    }

    return (
        <Container>
            <Row>
                <Col>
                    <small className='d-block text-uppercase text-secondary border-bottom mt-5'>
                        <FormattedMessage id={`eaAccreditationForm.step4.accordion.function.get`} />
                    </small>
                </Col>
            </Row>


            {getList.map(get =>
            <Row key={get.externalId} className={'mt-3'}>
                <Accordion>
                    <Accordion.Item eventKey='0'>
                        <Accordion.Header>
                            <span className='d-flex align-items-center'>
                                <FormattedMessage id='eaAccreditationForm.step4.accordion.function.get' />{' '}{' - ' + get.regionalDelegationCode}
                            </span>
                        </Accordion.Header>
                        <Accordion.Body>
                            <Row>
                                <LCol id={'eaAccreditationForm.step4.accordion.header.name'}>{get.name}</LCol>
                            </Row>
                            <Row className='mt-2'>
                                <LCol md={4} id={'strategicDiagnosis.humanResources.qualificationLevel'}><FormattedMessage id={`eaAccreditationForm.qualificationLevelCode.option.${get.qualificationLevelCode}`}/></LCol>
                                <LCol md={8} id={'eaAccreditationForm.step4.accordion.header.managementExperience'}><FormattedMessage id={get.managementExperience ? 'all.yes' : 'all.no'}/></LCol>
                            </Row>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </Row>
            )}

            <Row className='mb-3'>
                <Col>
                    <small className='d-block text-uppercase text-secondary border-bottom mt-5'>
                        <FormattedMessage id={`eaAccreditationForm.step4.accordion.function.tgp`} />
                    </small>
                </Col>
            </Row>

            {tgpList.map((tgp, index) =>
                <Row key={tgp.externalId} className={'mt-3'}>
                    <Accordion>
                        <Accordion.Item eventKey='0'>
                            <Accordion.Header>
                                <span className='d-flex align-items-center'>
                                    <FormattedMessage id='eaAccreditationForm.step4.accordion.function.tgp' />{' '}{' - ' + (index + 1)}
                                </span>
                            </Accordion.Header>
                            <Accordion.Body>
                                <Row>
                                    <LCol id={'eaAccreditationForm.step4.accordion.header.name'}>{tgp.name}</LCol>
                                </Row>
                                <Row>
                                    <LCol md={6} id={'strategicDiagnosis.humanResources.qualificationLevel'}><FormattedMessage id={`eaAccreditationForm.qualificationLevelCode.option.${tgp.qualificationLevelCode}`}/></LCol>
                                    <LCol md={3} id={'eaAccreditationForm.step4.accordion.header.nif'}>{tgp.hre.nif}</LCol>
                                    <LCol md={3} id={'eaAccreditationForm.step4.accordion.header.bondType'}><FormattedMessage id={`eaAccreditationForm.step4.accordion.header.bondType.${tgp.hre.bondType}`}/></LCol>
                                </Row>

                                <Row className='mt-5'>
                                    <Col>
                                        <small className='text-uppercase text-primary'>
                                            <FormattedMessage id='eaAccreditationForm.step4.accordion.header.experience' />
                                        </small>
                                    </Col>
                                </Row>
                                <Row>
                                    <LCol md='4' id={'eaAccreditationForm.step4.accordion.header.projectCreation'}>{tgp.hre.projectCreationCount}</LCol>
                                    <LCol md='4' id={'eaAccreditationForm.step4.accordion.header.candidatureReview'}>{tgp.hre.analysisCount}</LCol>
                                    <LCol md='4' id={'eaAccreditationForm.step4.accordion.header.consultancy'}>{tgp.hre.consultingCount}</LCol>
                                </Row>
                                <Row>
                                    <LCol md='6' id={'eaAccreditationForm.step4.accordion.header.skillsExtension'}>{tgp.hre.expandSkillsCount}</LCol>
                                    <LCol md='6' id={'eaAccreditationForm.step4.accordion.header.followUp'}>{tgp.hre.approvedProjectFollowUpCount}</LCol>
                                </Row>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </Row>

            )}

            <Row className='mb-3'>
                <Col>
                    <small className='d-block text-uppercase text-secondary border-bottom mt-5'>
                        <FormattedMessage id='eaAccreditationForm.step4.accordion.tgpTotalExperience' />
                    </small>
                </Col>
            </Row>

            <Row>
                <LCol md='4' id='eaAccreditationForm.step4.accordion.header.projectCreation'>{combinedTGPExperience.projectCreationCount}</LCol>
                <LCol md='4' id='eaAccreditationForm.step4.accordion.header.candidatureReview'>{combinedTGPExperience.analysisCount}</LCol>
                <LCol md='4' id='eaAccreditationForm.step4.accordion.header.consultancy'>{combinedTGPExperience.consultingCount}</LCol>
            </Row>
            <Row className={'mt-3'}>
                <LCol md='6' id='eaAccreditationForm.step4.accordion.header.skillsExtension'>{combinedTGPExperience.expandSkillsCount}</LCol>
                <LCol md='6' id='eaAccreditationForm.step4.accordion.header.followUp'>{combinedTGPExperience.approvedProjectFollowUpCount}</LCol>
            </Row>

        </Container>
    );
}

