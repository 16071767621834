import { apiAxios as authAxios } from '../axios.config';

export const getNibRequest = () => {
  return authAxios.get('/api/nibRequest');
};

export const getNibRequestByProcessExternalId = (processExternalId) => {
  return authAxios.get(   `/api/nibRequest/${processExternalId}`);
};


export const submitDecisionNibRequest = (processExternalId,data) => {
  return authAxios.post(   `/api/nibRequest/${processExternalId}/submitDecision`,data);
};

export const createDefaultNibRequest = () => {
  return authAxios.post('/api/nibRequest/default');
};

export const submitNibRequest = (data) => {
  return authAxios.post('/api/nibRequest', data);
};

export const getLastInDraftOrCreate = () => {
  return authAxios.get('/api/nibRequest/lastInDraftOrCreate');
};