export const RevocationReason = {
    ANSWER_TIME: 'ANSWER_TIME',
    QUALITY_TECH: 'QUALITY_TECH',
    AVAI_TECH_SUPPORT:'AVAILABLE_TECH_SUPPORT',
    AVAI_PROMOTER:'AVAILABLE_PROMOTER',
    OTHER:'OTHER',





values: () => {
    return [
      'ANSWER_TIME',
      'QUALITY_TECH',
        'AVAI_TECH_SUPPORT',
        'AVAI_PROMOTER',
        'OTHER'
    ];
  },


    entity: () => {
    return [
        'ANSWER_TIME',
        'AVAI_PROMOTER',
        'OTHER'
    ];
},




};
