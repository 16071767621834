import React, { useEffect, useState } from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { TextInputField } from '../../bootstrap/TextInputField';
import { SingleUploadArea } from '../../SingleUploadArea';
import {AuthenticatedDownloadLink} from "../../document/AuthenticatedDownloadLink";
export function EaAccreditationFormStep7({
	formStep,
	numberOfSteps,
	errors,
	formikValues,
	handleChange,
	handlePreviousStep,
	handleNextStep,
	setError,
	documents,
	setDocuments,
	readMode = false
}) {
	const intl = useIntl();

	function handleCheckBoxChange(e) {
		if (e.target.checked) {
			e.target.value = true;
		} else {
			e.target.value = false;
		}
		handleChange(e);
	}
	return (
		<div>
			<h4 className='text-primary mb-0'>
				<FormattedMessage id='eaAccreditationForm.step7.title' />
			</h4>
			<small className='text-secondary text-uppercase'>
				{intl.formatMessage(
					{ id: 'all.step' },
					{ step: formStep, numberOfSteps: numberOfSteps }
				)}
			</small>
			<Form.Label className='mb-3'>
				<FormattedMessage id='eaAccreditationForm.veracityDeclaration.label' />
			</Form.Label>
			<div className='p-3 bg-light mb-3'>
				<p>
					<FormattedMessage id='eaAccreditationForm.veracityDeclaration.text1' />
				</p>
				<p>
					<FormattedMessage id='eaAccreditationForm.veracityDeclaration.text2' />
				</p>
				<p>
					<FormattedMessage id='eaAccreditationForm.veracityDeclaration.text3' />
				</p>
				<p>
					<FormattedMessage id='eaAccreditationForm.veracityDeclaration.text4' />
				</p>
				<p>
					<FormattedMessage id='eaAccreditationForm.veracityDeclaration.text5' />
				</p>
				<p>
					<FormattedMessage id='eaAccreditationForm.veracityDeclaration.text6' />
				</p>
				<p>
					<FormattedMessage id='eaAccreditationForm.veracityDeclaration.text7' />
				</p>
				<p>
					<FormattedMessage id='eaAccreditationForm.veracityDeclaration.text8' />
				</p>
				<p>
					<FormattedMessage id='eaAccreditationForm.veracityDeclaration.text9' />
				</p>
			</div>

			<Row className='mt-4'>
				<small className='text-primary text-uppercase'>
					<FormattedMessage id='eaAccreditationForm.step7.authorizationDeclarations.subtitle' />
				</small>
			</Row>
			<Row>
				<Col>
					<Form.Label>
						<FormattedMessage id='eaAccreditationForm.step7.declarationsNote' />
					</Form.Label>
				</Col>
			</Row>
			<Row className='text-secondary font-weight-bold mt-2'>
				<Col className='d-flex'>
					<Form.Check
						type='checkbox'
						checked={formikValues.entity.atDeclarationAuthorized}
						name='entity.atDeclarationAuthorized'
						onChange={handleCheckBoxChange}
						disabled={readMode}
					/>
					<FormattedMessage id='eaAccreditationForm.step7.at' />
				</Col>
			</Row>
			<Row className='text-secondary font-weight-bold mt-2'>
				<Col className='d-flex'>
					<Form.Check
						type='checkbox'
						checked={formikValues.entity.ssDeclarationAuthorized}
						name='entity.ssDeclarationAuthorized'
						onChange={handleCheckBoxChange}
						disabled={readMode}
					/>
					<FormattedMessage id='eaAccreditationForm.step7.ss' />
				</Col>
			</Row>
			<Row className='mt-4'>
				<Col>
					{!formikValues.entity.atDeclarationAuthorized && readMode === false && (
						<SingleUploadArea
							documents={documents}
							setDocuments={setDocuments}
							documentType={'AT_DECLARATION'}
							setError={setError}
							entityRelated
						/>
					)}

					{!formikValues.entity.atDeclarationAuthorized && readMode === true && (
						<>
							{documents.filter(d=> d.documentType==='SS_DECLARATION' || d.documentType==='AT_DECLARATION').map((d,index)=>(
								<p key={index}>
									<label className={'mb-0'}>
										<FormattedMessage id={`documentType.${d.documentType}`} />
									</label>
								<AuthenticatedDownloadLink url={d.documentPath} filename={d.name}>
									<small>{d.name}</small>
								</AuthenticatedDownloadLink>
								</p>
							))}
						</>

					)}





					{!formikValues.entity.ssDeclarationAuthorized && readMode === false && (
						<SingleUploadArea
							documents={documents}
							setDocuments={setDocuments}
							documentType={'SS_DECLARATION'}
							setError={setError}
							entityRelated
						/>
					)}
				</Col>
			</Row>

			<Row className='mt-5'>
				<small className='text-primary text-uppercase'>
					<FormattedMessage id='eaAccreditationForm.step7.responsibles.subtitle' />
				</small>
			</Row>

			<Row className='mt-5'>
				<Col>
					<small className='text-uppercase'>
						<FormattedMessage id='eaAccreditationForm.step7.responsible' /> 1
					</small>
				</Col>
			</Row>
			<Row>
				<Col>
					<TextInputField
						labelId={'eaAccreditationForm.step7.responsible.name'}
						name={'entity.responsibles[0].name'}
						handleChange={handleChange}
						value={formikValues.entity.responsibles[0].name}
						isInvalid={errors.entity?.responsibles?.[0]?.name}
						errorMessage={errors.entity?.responsibles?.[0]?.name}
						disabled={readMode}
					/>
				</Col>
			</Row>
			<Row>
				<Col>
					<TextInputField
						labelId={'eaAccreditationForm.step7.responsible.duty'}
						name={'entity.responsibles[0].duty'}
						handleChange={handleChange}
						value={formikValues.entity.responsibles[0].duty}
						isInvalid={errors.entity?.responsibles?.[0]?.duty}
						errorMessage={errors.entity?.responsibles?.[0]?.duty}
						disabled={readMode}
					/>
				</Col>
			</Row>

			{formikValues?.entity?.responsibles?.[0].name !== undefined &&  formikValues?.entity?.responsibles?.[0].name !== null && formikValues?.entity?.responsibles?.[0].name !== '' && formikValues?.entity?.responsibles?.[0].duty !== undefined &&  formikValues?.entity?.responsibles?.[0].duty !== null && formikValues?.entity?.responsibles?.[0].duty !== '' &&
			<>
				<Row className='mt-5'>
					<Col>
						<small className='text-uppercase'>
							<FormattedMessage id='eaAccreditationForm.step7.responsible' /> 2
						</small>
					</Col>
				</Row>
				<Row>
					<Col>
						<TextInputField
							labelId={'eaAccreditationForm.step7.responsible.name'}
							name={'entity.responsibles[1].name'}
							handleChange={handleChange}
							value={formikValues.entity.responsibles[1].name}
							isInvalid={errors.entity?.responsibles?.[1]?.name}
							errorMessage={errors.entity?.responsibles?.[1]?.name}
							disabled={readMode}
						/>
					</Col>
				</Row>
				<Row>
					<Col>
						<TextInputField
							labelId={'eaAccreditationForm.step7.responsible.duty'}
							name={'entity.responsibles[1].duty'}
							handleChange={handleChange}
							value={formikValues.entity.responsibles[1].duty}
							isInvalid={errors.entity?.responsibles?.[1]?.duty}
							errorMessage={errors.entity?.responsibles?.[1]?.duty}
							disabled={readMode}
						/>
					</Col>
				</Row>

			</>

			}



			<div className='form-actions mt-5'>
				{handlePreviousStep != null && <button
					className='btn btn-link'
					type='button'
					onClick={handlePreviousStep}
				>
					<FormattedMessage id='all.back' />
				</button>}

				{handleNextStep != null && <button
					className='btn btn-primary'
					type='button'
					onClick={handleNextStep}
				>
					<FormattedMessage id='all.nextStep' />
				</button>}
			</div>
		</div>
	);
}
