import dateFormat from 'date-fns/format';
import React, { useEffect, useState } from 'react';
import { Col, Container, Form, Row } from 'react-bootstrap';
import DateTimePicker from 'react-datetime-picker';
import { FaInfoCircle } from 'react-icons/fa';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link, Navigate, useLocation, useParams } from 'react-router-dom';
import { AlertError } from '../../components/bootstrap/AlertError';
import Loading from '../../components/general/Loading';
import { SubTemplate } from '../../components/general/SubTemplate';
import { SingleUploadArea } from '../../components/SingleUploadArea';
import { ConfigObject } from '../../config';
import ContratoMCE from '../../documents/ANEXO B3-A -CONTRATO DE PRESTAÇÃO DE MCE APOIO Á CONSOLIDAÇÃO DO PROJETO.docx';
import { createCustomErrorMessage } from '../../hooks/errorMessage';
import { DocumentType } from '../../models/DocumentType';
import { ConsultingRequestState } from '../../models/ConsultingRequestState';
import {
  askMCEConsulting,
  getCandidatureConsultingRequest,
  submitConsultingRequestContract
} from '../../rest/consultingRequest.js';
import { listDocumentsByDocumentType } from '../../rest/document';
import { getPriorEntitiesByDistrictCode } from '../../rest/entity';
import { handleError, isNotBusinessError } from '../../utils/handleError';
import {isEntityUser} from "../../authentication/authenticationHelper";
import {getConsultingRequestDocumentsByType} from "../../rest/consultingRequest";

export function RequestConsulting() {
  const { candidatureExternalId } = useParams();
  const { districtCode } = useLocation().state;

  const intl = useIntl();

  const [consultingRequest, setConsultingRequest] = useState(null);
  const [availableEntities, setAvailableEntities] = useState(null);
  const [files, setFiles] = useState([]);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [submited, setSubmited] = useState(false);


  useEffect(() => {
    fetchData();
  }, []);

  async function fetchData() {
    try {
      const { data: consultingRequest } = await getCandidatureConsultingRequest(
        candidatureExternalId
      );

      if (
        Object.keys(consultingRequest).length === 0 ||
        consultingRequest?.state === ConsultingRequestState.DRAFT
      ) {
        const { data: entities } = await getPriorEntitiesByDistrictCode({
          districtCode: districtCode,  type:'CONSULTING', candidatureExternalId:candidatureExternalId
        });

        setAvailableEntities(entities);
      }

      if (consultingRequest?.state === ConsultingRequestState.APPROVED) {
        const { data: documents } = await getConsultingRequestDocumentsByType(consultingRequest.externalId,DocumentType.MCE_CONTRACT);
        setFiles(getInitialFiles(documents[0]));
      }
      setConsultingRequest(consultingRequest ? consultingRequest : {});
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  }

  function getInitialFiles(document) {
    let files = [];

    if (document) {
      files.push({
        externalId: document.externalId,
        documentType: document.documentType,
        documentPath: document.documentPath,
        name: document.name,
        content: '',
        submited: true,
        error: ''
      });
    } else {
      files.push({
        externalId: '',
        documentType: 'MCE_CONTRACT',
        name: '',
        content: null,
        submited: false,
        error: ''
      });
    }

    return files;
  }

  async function handleSubmit() {
    const validFields = validateFields();

    if (validFields) {
      try {
        setLoading(true);

        if (consultingRequest.externalId) {
          await submitConsultingRequestContract(
            consultingRequest.externalId,
            candidatureExternalId,
            consultingRequest
          );
        } else {
          await askMCEConsulting(candidatureExternalId, consultingRequest);
        }

        setSubmited(true);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    }
  }

  function handleChange(e, fieldName, booleanValue) {
    switch (fieldName) {
      case 'canEntityViewCandidature':
        consultingRequest[fieldName] = booleanValue;
        break;

      case 'entityNif':
        consultingRequest[fieldName] = e.target.value;
        break;

      case 'initialContractDate':
        consultingRequest[fieldName] = dateFormat(e, ConfigObject.get().DATE_PATTERN);
        break;

      default:
        break;
    }
    setConsultingRequest({ ...consultingRequest });
  }

  function validateFields() {
    if (
      !consultingRequest.entityNif ||
      consultingRequest.entityNif === '-1' ||
      typeof consultingRequest.canEntityViewCandidature === 'undefined'
    ) {
      setError(
        createCustomErrorMessage(
          intl.formatMessage({ id: 'errors.submitDeclarationModal.required' })
        )
      );
      return false;
    }
    if (consultingRequest.externalId) {
      if (!consultingRequest.initialContractDate) {
        setError(
          createCustomErrorMessage(
            intl.formatMessage({ id: 'errors.consultingRequest.initialContractDate.invalid' })
          )
        );
        return false;
      }

      const currentDate = new Date();
      currentDate.setHours(0, 0, 0, 0);
      if (new Date(consultingRequest.initialContractDate).getTime() > Date.now()) {
        setError(
          createCustomErrorMessage(
            intl.formatMessage({
              id: 'errors.consultingRequest.initialContractDate.afterActualDate'
            })
          )
        );
        return false;
      }
      if (!files[0].submited) {
        setError(
          createCustomErrorMessage(
            intl.formatMessage({
              id: 'errors.fieldsRequired'
            })
          )
        );
        return false;
      }
    }
    return true;
  }

  if (submited) {
    if (consultingRequest.externalId) {
      sessionStorage.setItem('consultingRequestContractSubmitted', submited);
    } else {
      sessionStorage.setItem('consultingRequestSubmitted', submited);
    }
    return <Navigate replace to={`/candidatura/${candidatureExternalId}`} />;
  }

  if (loading) {
    return <Loading />;
  }

  if (error && isNotBusinessError(error)) {
    return handleError(error);
  }

  return (
    <SubTemplate
      titleId='consultingRequest.title'
      subTitleId='consultingRequest.subTitle'
      centerContent
      hasBackButton>
      <Container>
        <Row>
          <div className='col-lg-8 offset-lg-2 mb-5 form'>
            <AlertError error={error} />
            <h5 className='text-secondary'>
              <FormattedMessage id='consultingRequest.introText' />
            </h5>

            {consultingRequest.state === ConsultingRequestState.APPROVED && (
              <div>
                <Row>
                  <Col>
                    <p className='mt-3 text-secondary infoText'>
                      <FaInfoCircle size={40} />

                      {isEntityUser() ? <FormattedMessage id='contratactMCE.entity.info' /> : <FormattedMessage id='contratactMCE.promoter.info' />}
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <a target='_blank' rel='noreferrer' href={ContratoMCE}>
                      <FormattedMessage id='contractMCE.download' />
                    </a>
                  </Col>
                </Row>
              </div>
            )}

            <Row className='mt-5'>
              {consultingRequest.externalId ? (
                <>
                  <Col md='4'>
                    <label>
                      <FormattedMessage id='consultingRequest.selectedEntity' />
                    </label>
                    <p>{consultingRequest.entityDescription}</p>
                  </Col>
                  <Col md='4'>
                    <label>
                      <FormattedMessage id='messages.stateText' />
                    </label>
                    <p>
                      <FormattedMessage id={`consultingRequestState.${consultingRequest.state}`} />
                    </p>
                  </Col>
                  <Col md='4'>
                    <label>
                      <FormattedMessage id='candidatureTabs.submissionDate' />
                    </label>
                    <p>{consultingRequest.creationDate}</p>
                  </Col>
                </>
              ) : (
                <Col md='6'>
                  <Form.Group controlId={'entities'}>
                    <Form.Label>
                      <FormattedMessage id='consultingRequest.entity' />
                    </Form.Label>
                    <Form.Control
                      as='select'
                      name={'entities'}
                      value={consultingRequest.entityNif}
                      onChange={(e) => handleChange(e, 'entityNif', null)}>
                      <option value={'-1'}>
                        <FormattedMessage id='all.chooseOne' />
                      </option>
                      {availableEntities.map((entity) => (
                        <option key={entity.nif} value={entity.nif}>
                          {entity.description}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </Col>
              )}
            </Row>

            <Row>
              <Col>
                {consultingRequest.externalId ? (
                  <>
                    <label>
                      <FormattedMessage id='consultingRequest.canEntityViewCandidature.viewMode' />
                    </label>
                    <p>
                      <FormattedMessage
                        id={`all.${consultingRequest.canEntityViewCandidature ? 'yes' : 'no'}`}
                      />
                    </p>
                  </>
                ) : (
                  <div className='d-flex justify-content-between align-items-center mt-4'>
                    <label>
                      <FormattedMessage id='consultingRequest.canEntityViewCandidature' />
                    </label>
                    <div>
                      <Form.Check
                        inline
                        type={'radio'}
                        label={<FormattedMessage id='all.yes' />}
                        checked={consultingRequest.canEntityViewCandidature === true}
                        onChange={(e) => handleChange(null, 'canEntityViewCandidature', true)}
                        disabled={consultingRequest.state === ConsultingRequestState.APPROVED}
                      />
                      <Form.Check
                        inline
                        type={'radio'}
                        label={<FormattedMessage id='all.no' />}
                        checked={consultingRequest.canEntityViewCandidature === false}
                        onChange={(e) => handleChange(null, 'canEntityViewCandidature', false)}
                        disabled={consultingRequest.state === ConsultingRequestState.APPROVED}
                      />
                    </div>
                  </div>
                )}
              </Col>
            </Row>

            <Row>
              {consultingRequest.externalId &&
                consultingRequest.state !== ConsultingRequestState.SUBMITTED && (
                  <Col>
                    <label>
                      <FormattedMessage
                        id={`consultingRequest.${
                          consultingRequest.state === ConsultingRequestState.APPROVED
                            ? 'acceptedDate'
                            : 'rejectionDate'
                        }`}
                      />
                    </label>
                    <p>
                      {consultingRequest.state === ConsultingRequestState.APPROVED
                        ? consultingRequest.acceptedDate
                        : consultingRequest.rejectionDate}
                    </p>
                  </Col>
                )}
            </Row>

            {consultingRequest.state === ConsultingRequestState.APPROVED && (
              <Row>
                <Row>
                  <Col md='4'>
                    <Form.Group controlId={'initialContractDate'}>
                      <Form.Label>
                        <FormattedMessage id='entity.accreditationDate' />
                      </Form.Label>
                      <Form.Control
                        className='datetimepicker'
                        as={DateTimePicker}
                        format={ConfigObject.get().DATE_PATTERN}
                        clearIcon={null}
                        value={
                          consultingRequest.initialContractDate
                            ? new Date(consultingRequest.initialContractDate)
                            : ''
                        }
                        onChange={(e) => handleChange(e, 'initialContractDate', null)}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row className='mt-3'>
                  <Col>
                    <SingleUploadArea
                      documents={files}
                      setDocuments={setFiles}
                      documentType={'MCE_CONTRACT'}
                      setError={setError}
                    />
                  </Col>
                </Row>
              </Row>
            )}

            {(!consultingRequest.externalId ||
              consultingRequest.state === ConsultingRequestState.APPROVED) && (
              <div className='form-actions mt-5'>
                <Link className='btn btn-link' to={`/candidatura/${candidatureExternalId}`}>
                  <FormattedMessage id='all.back' />
                </Link>
                <button className='btn btn-primary' type='button' onClick={() => handleSubmit()}>
                  <FormattedMessage id='all.submitButtonText' />
                </button>
              </div>
            )}
          </div>
        </Row>
      </Container>
    </SubTemplate>
  );
}
