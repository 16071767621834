import React from 'react'
import { Modal, ModalBody, ModalTitle } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl';

export function ProfileDescriptionDialog({description, setShowProfileDescriptionDialog}) {

  return (
    <Modal show={true} onHide={() => setShowProfileDescriptionDialog(false)}>
      <Modal.Header closeButton>
        <ModalTitle>
          <FormattedMessage id='profileDialog.title' />
        </ModalTitle>
      </Modal.Header>
      <ModalBody>{description}</ModalBody>
    </Modal>
  );
}