import {useNavigate} from "react-router-dom";
import {FormattedMessage} from "react-intl";
import {Button} from "react-bootstrap";
import React from "react";

export function ConditionalButton({condition, children, onClick, variant='outline-primary'}) {
    if (condition) return (
        <Button variant={variant} onClick={onClick} className='d-flex align-items-center justify-content-center mb-4 w-100'>
            {children}
        </Button>
    );
}

export function CondFmtNavButton({condition, id, to, children}) {
    const navigate = useNavigate();
    return (
        <ConditionalButton condition={condition} onClick={() => navigate(to)}>
            <FormattedMessage id={id} />
            {children}
        </ConditionalButton>
    );
}

