import React, {useEffect, useRef, useState} from 'react';
import { Form, Row, Container, Col } from 'react-bootstrap';
import { Formik } from 'formik';
import {FormattedMessage, useIntl} from 'react-intl';
import Loading from '../../components/general/Loading';
import { SubTemplate } from '../../components/general/SubTemplate';
import { FaRegCheckCircle, FaRegSave } from 'react-icons/fa';
import { handleError, isNotBusinessError } from '../../utils/handleError';
import { FormSideNavBar } from '../../components/general/FormSideNavBar';
import { SemestralReportFormStep2 } from '../../components/semestralReport';
import { EAPromoterStep } from '../../components/promoter/EAPromoterStep';
import { DeclarationEndFirstYearFormStep3 } from '../../components/declarationEndFirstYear/DeclarationEndFirstYearFormStep3';
import {useNavigate, useParams} from "react-router-dom";
import {getEntityGetList, getEntityTgpList} from "../../rest/entity";
import {getDeclaration, saveDeclaration, submitDeclaration} from "../../rest/declarationEndOfFirstYear";
import {CompanyIdentificationFormUtils} from "../../utils/CompanyIdentificationFormUtils";
import {MCESupportKind} from "../../models/MCESupportKind";
import {AlertError} from "../../components/bootstrap/AlertError";
import {SuccessToast} from "../../components/general/SuccessToast";
import * as yup from "yup";
import {Validator} from "../../utils/Validator";
import {isIEFPUser} from "../../authentication/authenticationHelper";
import {createCustomErrorMessage} from "../../hooks/errorMessage";
import {ProcessState} from "../../models/ProcessState";
import {ConfirmationAuthenticationModal} from "../../components/general/ConfirmationAuthenticationModal";
import {saveFinalReportByConsultingRequest} from "../../rest/consultingRequest";


const ratingValidationSchema = yup.number()
	.typeError(<FormattedMessage id='errors.fieldRequiredText'/>)
	.max(5);

const validationSchema = yup.object().shape({
	identificationEa: yup.object().shape({
		technicalTeamManagerExternalId: yup
			.string()
			.test(
				'isGETValid',
				<FormattedMessage id='errors.annualComplementaryActivitiesPlan.GET'/>,
				(value) => value !== '-1'
			),
		projectManagerExternalId: yup
			.string()
			.test(
				'isTGPValid',
				<FormattedMessage id='errors.evaluationEa.TGP'/>,
				(value) => value !== '-1'
			),
		projectManagerEmail: yup.mixed().when('projectManagerExternalId', {
			is: (tgp) => tgp !== '-1',
			then: yup
				.string()
				.required(<FormattedMessage id='errors.fieldRequiredText'/>)
				.test(
					'isEmailValid',
					<FormattedMessage id='errors.candidatureForm.email'/>,
					(value) => Validator.validateEmail(value)
				),
		}),
		projectManagerPhone: yup.mixed().when('projectManagerExternalId', {
			is: (tgp) => tgp !== '-1',
			then: yup
				.string()
				.required(<FormattedMessage id='errors.fieldRequiredText'/>)
				.test(
					'isPhoneValid',
					<FormattedMessage id='errors.eaAccreditationForm.phoneAndMobile'/>,
					(value) => Validator.validateCommsNumber(value)
				),
		}),
	}),

	company: CompanyIdentificationFormUtils.validationObject(true),

	ratingFollowUp: ratingValidationSchema,
	ratingConsultancy: ratingValidationSchema,
	ratingSkillExtension: ratingValidationSchema,
	companyObservations: yup
		.string()
		.typeError(<FormattedMessage id='errors.fieldRequiredText'/>)
		.required(<FormattedMessage id='errors.fieldRequiredText'/>),
	interestedInContinuing: yup
		.boolean()
		.typeError(<FormattedMessage id='errors.fieldRequiredText'/>)
		.required(<FormattedMessage id='errors.fieldRequiredText'/>)
		.test('isInterestedInContinuingValid', <FormattedMessage id='errors.fieldRequiredText' />, (value) =>
			value === true
		)
});

export function AddDeclarationEndFirstYear() {
	const { externalId: externalId } = useParams();

	const [error, setError] = useState(null);
	const [navErrors, setNavErrors] = useState([]);

	//modals
	const [showSubmitModal, setShowSubmitModal] = useState(false);

	//formik
	const [formikInitialValues, setFormikInitialValues] = useState(null);
	const formikPropsRef = useRef(null);

	//form control
	const [formStep, setFormStep] = useState(0);
	const [showSaveToast, setShowSaveToast] = useState(false);
	const [loading, setLoading] = useState(true);
	const [submited, setSubmited] = useState(false);

	const [getList, setGetList] = useState([]);
	const [tgpList, setTgpList] = useState([]);

	const intl = useIntl();
	const navigate = useNavigate();


	const tabsIds = [
		'declarationEndFirstYear.sideNavBar.identificationEA',
		'declarationEndFirstYear.sideNavBar.identificationCompany',
		'declarationEndFirstYear.sideNavBar.measurementInterest',
	];

	const readMode = false;

	async function fetchData() {
		try {
			let [{ data: decl }] = await Promise.all([
					getDeclaration(externalId)
			]);

			let tgpListParams = {
				entityNif: decl.identificationEa.entity.nif,
				toIdentificationStep: true,
				all:decl.processState !== ProcessState.DRAFT
			};

			let getListParams = {
				entityNif: decl.identificationEa.entity.nif,
				all:decl.processState !== ProcessState.DRAFT
			};



			let [{ data: entityGetList }, { data: entityTgpList }] =
				await Promise.all([
					await getEntityGetList(getListParams),
					await getEntityTgpList(tgpListParams),
				]);
			setGetList(entityGetList);
			setTgpList(entityTgpList);

			setFormikInitialValues(getFormikInitialValues(decl));
		} catch (error) {
			setError(error);
		} finally {
			setLoading(false);
		}
	}

	function getFormikInitialValues(iniVal) {
		let initialValues = iniVal;

		initialValues.identificationEa.projectManagerEmail ??= '';
		initialValues.identificationEa.projectManagerPhone ??= '';
		initialValues.interestedInContinuing ??= false;

		CompanyIdentificationFormUtils.getFormikInitialValuesCompanyIdentification(
			initialValues,
			true
		);

		return initialValues;
	}
	function scrollPageToTop() {
		document.body.scrollTop = 0;
		document.documentElement.scrollTop = 0;
	}

	function handlePreviousStep() {
		scrollPageToTop();
		setFormStep(formStep - 1);
	}

	function handleNextStep() {
		scrollPageToTop();
		setFormStep(formStep + 1);
		console.log('[TODO] handle form save by step');
	}




	useEffect(() => {
		fetchData();
	}, []);

	if (loading) {
		return <Loading />;
	}

	if (error && isNotBusinessError(error)) {
		return handleError(error);
	}

	async function handleSave() {
		console.log(formikPropsRef.current.values)
		try {
			let { data: res } = await saveDeclaration(externalId, formikPropsRef.current.values);
			console.log(res);

			setNavErrors([]);
			setShowSaveToast(true);
		} catch (err) {
			setError(err);
		}
	}


	async function validateBeforeSubmit(setShowModal) {
		const formikErrors = await formikPropsRef.current.validateForm();
		console.log(formikErrors);
		const errorCount = Object.keys(formikErrors).length;
		let errors = new Set();
		Object.keys(formikErrors).forEach((key) => {
			console.log(key);
			switch (key) {
				case 'identificationEa':
					errors.add(0);
					break;
				case 'company':
					errors.add(1);
					break;

				case 'ratingFollowUp':
				case 'ratingConsultancy':
				case 'ratingSkillExtension':
				case 'interestedInContinuing':
				case 'companyObservations':
					errors.add(2);
					break;
			}
		});




		setNavErrors(Array.from(errors));
		if(errorCount === 0) {
			if (errors.size === 0) {
				setShowModal(true);
			}
		}else{
			setError(
				createCustomErrorMessage(intl.formatMessage({ id: 'paymentRequest.someErrors' }))
			);
		}
		return errorCount===0;

	}

	async function handleSubmit(user, password, setModalError, decisionApprove) {
		try {
			let toSend = formikPropsRef.current.values;

			toSend.credentials = {
				user: user,
				password: password,
			};

			let { data: res } = await submitDeclaration(externalId, toSend);

			setNavErrors([]);
			setSubmited(true);
		} catch (err) {
			if (err.response.data.exception === 'ValidationException') {
				let errors = error.response.data.message
					.split(',')
					.map((value) => Number.parseInt(value));
				setNavErrors(errors);
			} else {
				setModalError(err);
			}
		}
	}

	if( submited ) {
		sessionStorage.setItem(
			'declarationFirstYear',
			submited
		);
		navigate('/pedidosMCE/' + externalId);
	}





	function renderStep(formikProps) {
		formikPropsRef.current = formikProps;

		const readMode = !(
			 formikProps.values.processState === ProcessState.DRAFT
		);

		switch (formStep) {
			case 0:
				return (
					<EAPromoterStep
						formStep={formStep + 1}
						numberOfSteps={tabsIds.length}
						errors={formikProps.errors}
						formikValues={formikProps.values}
						handleChange={formikProps.handleChange}
						setFieldValue={formikProps.setFieldValue}
						handleNextStep={handleNextStep}
						entityGetList={getList}
						entityTgpList={tgpList}
						readMode={readMode}
						goBackPath={'/pedidosMCE/' + externalId}
					/>
				);
			case 1:
				return (
					<SemestralReportFormStep2
						formStep={2}
						numberOfSteps={tabsIds.length}
						errors={formikProps.errors}
						formikValues={formikProps.values}
						handleChange={formikProps.handleChange}
						handlePreviousStep={handlePreviousStep}
						handleNextStep={handleNextStep}
						readMode={readMode}
					/>
				);

			case 2:
				return (
					<DeclarationEndFirstYearFormStep3
						formStep={3}
						numberOfSteps={tabsIds.length}
						errors={formikProps.errors}
						formikValues={formikProps.values}
						setFieldValue={formikProps.setFieldValue}
						handleChange={formikProps.handleChange}
						handlePreviousStep={handlePreviousStep}
						readMode={readMode}

					/>
				);

			default:
				console.log('Something went wrong rendering the form step');
		}
	}

	return (
		<SubTemplate>
			<Container>
				<ConfirmationAuthenticationModal
					show={showSubmitModal}
					handleClose={() => setShowSubmitModal(false)}
					submitHandler={handleSubmit}
					idsPreffix={'declaration1Year.submitModal'}
					confirmButtonCompleteLabelId={'all.submitButtonText'}
				/>
				<Row>
					<Col md={{ span: 8, offset: 2 }}>
						<h2 className='mb-1 text-secondary'>
							<FormattedMessage id='declarationEndFirstYear.title' />
						</h2>
						<p className='mb-5 text-secondary'>
							<FormattedMessage id='declarationEndFirstYear.subTitle' />
							<span
								className="badge rounded-pill bg-secondary fw-normal ms-2"><span
								className="text-uppercase fw-bold"><FormattedMessage
								id={`processState.${formikInitialValues.processState}`}
							/> </span>  {formikInitialValues.submissionDate !== undefined && formikInitialValues.submissionDate !== null && (<> · <FormattedMessage id={'submitted.process.at'} values={{date:formikInitialValues.submissionDate}}/>  </>) }  </span>
						</p>
					</Col>
				</Row>
				<Row className='mb-5'>
					<Col md='2'>
						<FormSideNavBar
							tabsIdsArray={tabsIds}
							formStep={formStep}
							setFormStep={setFormStep}
							navErrors={navErrors}
							setNavErrors={setNavErrors}
						/>
					</Col>
					<Col md='8'>
						<AlertError error={error} />
						<SuccessToast
							message={intl.formatMessage({
								id: 'annualComplementaryActivitiesPlan.saveToastInformation',
							})}
							show={showSaveToast}
							setShow={setShowSaveToast}
						/>
						<Formik
							initialValues={formikInitialValues}
							validateOnBlur={false}
							validateOnChange={false}
							validationSchema={validationSchema}
							onSubmit={handleSubmit}
						>
							{(formikProps) => (
								<Form onSubmit={formikProps.handleSubmit}>
									{renderStep(formikProps)}
								</Form>
							)}
						</Formik>
					</Col>
					<Col md='2'>
						{formikInitialValues.processState === ProcessState.DRAFT && (
						<div>
							<button
								className='btn btn-outline-primary d-flex align-items-center justify-content-center mb-4 w-100'
								type='button'
								onClick={() => handleSave()}
							>
								<FaRegSave />
								<FormattedMessage id='all.save' />
							</button>
							<button
								className='btn btn-primary d-flex align-items-center justify-content-center w-100'
								type='button'
								onClick={() => validateBeforeSubmit(setShowSubmitModal)}
							>
								<FaRegCheckCircle />
								<FormattedMessage id='all.submitButtonText' />
							</button>
						</div>
						)}
					</Col>
				</Row>
			</Container>
		</SubTemplate>
	);
}
