import {Accordion, Col, Row} from "react-bootstrap";
import AccordionItem from "react-bootstrap/AccordionItem";
import AccordionHeader from "react-bootstrap/AccordionHeader";
import {FormattedMessage} from "react-intl";
import AccordionBody from "react-bootstrap/AccordionBody";
import React from "react";

export function EAAValidation({accreditationDecision}) {
    return (
        <Accordion className='mt-0 mb-3'>
            <AccordionItem className='mt-0 mb-0' eventKey={'validation'} >
                <AccordionHeader>
                    <FormattedMessage id={'ea.accreditation.results.validation'} />
                </AccordionHeader>
                <AccordionBody>
                    <Row className={'mb-3'}>
                        <Col>
                            <label><FormattedMessage id={'ea.accreditation.results.validationDate'}/></label>
                            {accreditationDecision.validationDate}
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <label><FormattedMessage id={'ea.accreditation.results.decision.decisions'}/></label>
                        </Col>
                    </Row>
                    <Row >
                        <Col>
                            <table className='table d-table'>
                                <thead>
                                <tr>
                                    <th>
                                        <label>
                                            <FormattedMessage id={'regionalDelegation.column'} />
                                        </label>
                                    </th>
                                    <th>
                                        <label>
                                            <FormattedMessage id={'all.dateDescription'} />
                                        </label>
                                    </th>
                                    <th>
                                        <label>
                                            <FormattedMessage id={'ea.accreditation.results.validation'} />
                                        </label>
                                    </th>
                                    <th>
                                        <label>
                                            <FormattedMessage id={'all.motive'} />
                                        </label>
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                {accreditationDecision?.delegationDecisions.map(((dd) => (
                                    <tr key={dd.regionalDelegationCode}>
                                        <td><FormattedMessage id={`regionalDelegation.${dd.regionalDelegationCode}`} /></td>
                                        <td>{dd.decisionDate}</td>
                                        <td><FormattedMessage id={`accreditationDecisionState1.${dd.result}`}/></td>
                                        <td>{dd.comment}</td>
                                    </tr>
                                )))}
                                </tbody>
                            </table>
                        </Col>
                    </Row>


                </AccordionBody>
            </AccordionItem>
        </Accordion>
    );
}