import {Formik} from 'formik';
import React, {useState} from 'react';
import {Button, Form, Modal, Row} from 'react-bootstrap';
import {FormattedMessage} from 'react-intl';
import {FaInfoCircle} from 'react-icons/fa';
import Col from "react-bootstrap/Col";
import {TextInputField} from "../bootstrap/TextInputField";
import * as yup from "yup";
import {Validator} from "../../utils/Validator";
import {SingleUploadArea} from "../SingleUploadArea";

export function IncubatorContractDialog({
                                             show,
                                             handleClose,
                                             handleSubmit
                                         }) {


    const [error, setError] = useState();
    const [documents, setDocuments] = useState([]);

    const schema = yup.object().shape({
        promoterNif: yup
        .string()
        .required(<FormattedMessage id='errors.fieldRequiredText'/>)
        .test(
            'isNifValid',
            <FormattedMessage id='errors.promoterForm.nif'/>,
            (value) => Validator.validateNif(value)
        ),
        file: yup.mixed().required(<FormattedMessage id='errors.fieldRequiredText'/>),
    });



    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>
                    <FormattedMessage id={`incubator.contract.add`}/>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Formik
                    initialValues={{promoterNif: ''}}
                    validateOnBlur={false}
                    validationSchema={schema}
                    validateOnChange={false}
                    onSubmit={(values, {setSubmitting, setErrors}) => {
                        handleSubmit(values, setSubmitting, setErrors);
                    }}
                >
                    {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleSubmit,
                          setFieldValue,
                      }) => (
                        <form onSubmit={handleSubmit}>

                            <TextInputField
                                controlId={'promoterNif'}
                                labelId={'incubator.nifPromoter'}
                                name={'promoterNif'}
                                handleChange={handleChange}
                                value={values.promoterNif}
                                isInvalid={errors.promoterNif}
                                errorMessage={errors.promoterNif}
                            />

                            <div className={errors.file ? ' ps-3  pe-3 square border border-danger border-2 mt-3' : 'mt-3'}>
                                <SingleUploadArea
                                    documents={documents}
                                    setDocuments={setDocuments}
                                    documentType={'INTEREST_EXPRESSION'}
                                    setError={setError}
                                    processUploadCallback={formData => setFieldValue('file', formData)}
                                />
                                {errors.file && <span className={'text-danger'}> {errors.file}</span>}
                            </div>


                            <Row className='form-actions'>
                                <Col className='justify-content-between d-flex'>
                                    <button className='btn btn-outline-primary' type='button' onClick={handleClose}>
                                        <FormattedMessage id='all.cancel'/>
                                    </button>
                                    <Button variant='primary' type='submit'>
                                        <FormattedMessage id='all.submitButtonText'/>
                                    </Button>
                                </Col>
                            </Row>
                        </form>
                    )}
                </Formik>
            </Modal.Body>
        </Modal>
    );
}
