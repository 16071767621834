import React from 'react';
import { Col, Row, Table } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { Form } from 'react-bootstrap';
import DecimalInputField from '../bootstrap/DecimalInputField';
import { TextAreaInputField } from '../bootstrap/TextAreaInputField';
import ToolTip from '../bootstrap/ToolTip';
import {SelectBooleanField} from "../bootstrap/SelectBooleanField";
import {Strings} from "../../utils/Strings";

export function EconomicViabilityFormStep6({
  handleChange,
  setFieldValue,
  values,
  error,
  setError,
  numberOfSteps,
  handleSave,
  handleGoBack
}) {
  const intl = useIntl();

  console.log(values);

  const KEYS = ['projectFreeMeans', 'updateTax', 'val', 'tir', 'payback'];
  const KINDS = [1, 2, 3, 4, 5];

  function getValueByKindAndFieldName(kind, fieldName) {
    const value = values.profitabilityMarkerDetails.filter((pmd) => pmd.kind === kind)[0][fieldName];
    return fieldName.startsWith('nA') ? value === true : value;
  }

  function handleProfitabilityMarkerChange(e, kind, fieldName) {
      values.profitabilityMarkerDetails.filter((pmd) => pmd.kind === kind)[0][fieldName] =
        fieldName.startsWith('nA')?   e.target.value=== 'true' : e.target.value;

    if(fieldName.startsWith('nA') && e.target.value=== 'true'){
      let result = fieldName.slice(2);
      let finalResult = result.charAt(0).toLowerCase() + result.slice(1);
      values.profitabilityMarkerDetails.filter((pmd) => pmd.kind === kind)[0][finalResult]=0;
    }


    setFieldValue('profitabilityMarkerDetails', [...values.profitabilityMarkerDetails]);
  }


  function getTableRowsByKey(key) {
    return (
        <React.Fragment>
          <tr>
            <td className='w-40'>
              <FormattedMessage id={`economicViabilityFormStep5.${key}`}/>
            </td>

            {key === 'projectFreeMeans' ? (
                <>
                  {KINDS.map((kind) => (
                      <td>
                        <DecimalInputField
                            controlId={`profitabilityMarkerDetails.${key}`}
                            name={`profitabilityMarkerDetails.${key}`}
                            handleChange={(e) => handleProfitabilityMarkerChange(e, kind, key)}
                            value={getValueByKindAndFieldName(kind, key)}
                            placeHolder={0}
                            allowNegative={true}
                        />
                      </td>
                  ))}
                </>

            ) : (<td colSpan={5}>
              <Row>
                <Col md={8}>
                  <DecimalInputField
                      controlId={`profitabilityMarkerDetails${key}`}
                      name={`profitabilityMarkerDetails${key}`}
                      handleChange={(e) => handleProfitabilityMarkerChange(e, 1, key)}
                      value={ getValueByKindAndFieldName(1, key)}
                      placeHolder={0}
                      disabled={     getValueByKindAndFieldName(1, 'nA'+  Strings.capsFirstLetter(key)  )===true}
                      allowNegative={true}
                  />
                </Col>
                <Col md={4}>
                  <Form.Control
                      as='select'
                      name={'profitabilityMarkerDetails.na'+ Strings.capsFirstLetter(key) }
                      onChange={(e) => handleProfitabilityMarkerChange(e, 1,'nA'+ Strings.capsFirstLetter(key))}
                      value={getValueByKindAndFieldName(1, 'nA'+Strings.capsFirstLetter(key))}
                  >

                    <option value='true'>{intl.formatMessage({ id: 'all.na' })}</option>
                    <option value='false'>{intl.formatMessage({ id: 'all.applicable' })}</option>
                  </Form.Control>

                </Col>









              </Row>


            </td>)}

          </tr>
          {key === 'projectFreeMeans' &&
          <tr className="border-1 border-bottom ">
            <td colSpan="6" className="text-primary  border-start-0  border-end-0"></td>
          </tr>

          }
        </React.Fragment>






    );
  }

  function handleNAChange(e,fieldName) {
    values.analysisMarkersDetails[fieldName] = e.target.value=== 'true';
    if(e.target.value=== 'true'){
      let result = fieldName.slice(2);
      let finalResult = result.charAt(0).toLowerCase() + result.slice(1);
      values.analysisMarkersDetails[finalResult]=-0;
      setFieldValue('values.analysisMarkersDetails.'+finalResult, 0);
    }
    setFieldValue('values.analysisMarkersDetails.'+fieldName, e.target.value=== 'true');
  }

  return (
    <div>
      <h4 className='text-primary mb-0'>
        <FormattedMessage id='economicViabilityForm.analysisMarkersTab1' />
      </h4>
      <small className='text-secondary text-uppercase d-block'>
        {intl.formatMessage(
          { id: 'all.step' },
          { step: values.step, numberOfSteps: numberOfSteps }
        )}
      </small>

      <div className='mt-4'>
        <small className='text-primary text-uppercase mt-4'>
          <FormattedMessage id='economicViabilityFormStep5.profitability.title' />
        </small>

        <Table className='mt-2' bordered>
          <thead>
            <tr>
              <th className='w-40'>
                <FormattedMessage id='economicViabilityFormStep6.indicator' />
              </th>
              {KINDS.map((kind) => (
                <th className='text-center'>{kind}</th>
              ))}
            </tr>
          </thead>
          <tbody>{KEYS.map((key) => getTableRowsByKey(key))}</tbody>
        </Table>

        <Row>
          <Col md='12'>
            <TextAreaInputField
              labelId={'economicViabilityFormStep5.profitabilityComments'}
              name={'analysisMarkersDetails.profitabilityComments'}
              value={values.analysisMarkersDetails.profitabilityComments}
              handleChange={handleChange}
            />
          </Col>
        </Row>
      </div>

      <div className='mt-4'>
        <small className='text-primary text-uppercase mt-5'>
          <FormattedMessage id='economicViabilityFormStep5.economic.title' />
          <ToolTip message={<FormattedMessage id='economicViabilityFormStep5.infoToolTip' />} />
        </small>

        <Row>
          <Col md='6'>
            <Row>
              <Col md={9}>
                <DecimalInputField
                    labelId={'economicViabilityFormStep5.businessGrowTax'}
                    controlId={'analysisMarkersDetails.businessGrowTax'}
                    name={'analysisMarkersDetails.businessGrowTax'}
                    handleChange={handleChange}
                    value={values.analysisMarkersDetails.businessGrowTax}
                    placeHolder={0}
                    disabled={values.analysisMarkersDetails.nABusinessGrowTax===true}
                    allowNegative={true}
                />
              </Col>
              <Col md={3}>
                <Form.Label className=''>
                  &nbsp;
                </Form.Label>
                <Form.Control
                    as='select'
                    className={'ps-1'}
                    name={'analysisMarkersDetails.nABusinessGrowTax'}
                    onChange={(e) => handleNAChange(e,'nABusinessGrowTax')}
                    value={values.analysisMarkersDetails.nABusinessGrowTax===true}
                >

                  <option value='true'>{intl.formatMessage({ id: 'all.na' })}</option>
                  <option value='false'>{intl.formatMessage({ id: 'all.applicable' })}</option>
                </Form.Control>
              </Col>
            </Row>

          </Col>











          <Col md='6'>
            <Row>
              <Col md={9}>
                <DecimalInputField
                    labelId={'economicViabilityFormStep5.profitabilitySales'}
                    controlId={'analysisMarkersDetails.profitabilitySales'}
                    name={'analysisMarkersDetails.profitabilitySales'}
                    handleChange={handleChange}
                    value={values.analysisMarkersDetails.profitabilitySales}
                    placeHolder={0}
                    disabled={values.analysisMarkersDetails.nAProfitabilitySales===true}
                    allowNegative={true}
                />
              </Col>
              <Col md={3}>
                <Form.Label className=''>
                  &nbsp;
                </Form.Label>
                <Form.Control
                    as='select'
                    name={'analysisMarkersDetails.nAProfitabilitySales'}
                    onChange={(e) => handleNAChange(e,'nAProfitabilitySales')}
                    value={values.analysisMarkersDetails.nAProfitabilitySales===true}
                    className={'ps-1'}
                >

                  <option value='true'>{intl.formatMessage({ id: 'all.na' })}</option>
                  <option value='false'>{intl.formatMessage({ id: 'all.applicable' })}</option>
                </Form.Control>
              </Col>
            </Row>






          </Col>
        </Row>
      </div>

      <div className='mt-4'>
        <small className='text-primary text-uppercase mt-5'>
          <FormattedMessage id='economicViabilityFormStep5.economicFinancial.title' />
          <ToolTip message={<FormattedMessage id='economicViabilityFormStep5.infoToolTip' />} />
        </small>

        <Row>
          <Col md='6'>
            <Row>
            <Col md={9}>
              <DecimalInputField
                  labelId={'economicViabilityFormStep5.investmentReturn'}
                  controlId={'analysisMarkersDetails.investmentReturn'}
                  name={'analysisMarkersDetails.investmentReturn'}
                  handleChange={handleChange}
                  value={values.analysisMarkersDetails.investmentReturn}
                  placeHolder={0}
                  disabled={values.analysisMarkersDetails.nAInvestmentReturn===true}
                  allowNegative={true}
              />
            </Col>
            <Col md={3}>
              <Form.Label className=''>
                &nbsp;
              </Form.Label>
              <Form.Control
                  as='select'
                  name={'analysisMarkersDetails.nAInvestmentReturn'}
                  onChange={(e) => handleNAChange(e,'nAInvestmentReturn')}
                  value={values.analysisMarkersDetails.nAInvestmentReturn===true}
                  className={'ps-1'}
              >

                <option value='true'>{intl.formatMessage({ id: 'all.na' })}</option>
                <option value='false'>{intl.formatMessage({ id: 'all.applicable' })}</option>
              </Form.Control>
            </Col>

            </Row>





          </Col>
          <Col md='6'>

            <Row>
              <Col md={9}>
                <DecimalInputField
                  labelId={'economicViabilityFormStep5.activeProfitability'}
                  controlId={'analysisMarkersDetails.activeProfitability'}
                  name={'analysisMarkersDetails.activeProfitability'}
                  handleChange={handleChange}
                  value={values.analysisMarkersDetails.activeProfitability}
                  placeHolder={0}
                  disabled={values.analysisMarkersDetails.nAActiveProfitability===true}
                  allowNegative={true}
                  />
              </Col>
              <Col md={3}>
                <Form.Label className=''>
                  &nbsp;
                </Form.Label>
                <Form.Control
                    as='select'
                    name={'analysisMarkersDetails.nAActiveProfitability'}
                    onChange={(e) => handleNAChange(e,'nAActiveProfitability')}
                    value={values.analysisMarkersDetails.nAActiveProfitability===true}
                    className={'ps-1'}
                >

                  <option value='true'>{intl.formatMessage({ id: 'all.na' })}</option>
                  <option value='false'>{intl.formatMessage({ id: 'all.applicable' })}</option>
                </Form.Control>
              </Col>

            </Row>



          </Col>
          <Col md='6'>
            <Row>
              <Col md={9}>
                <DecimalInputField
                    labelId={'economicViabilityFormStep5.activeRotation'}
                    controlId={'analysisMarkersDetails.activeRotation'}
                    name={'analysisMarkersDetails.activeRotation'}
                    handleChange={handleChange}
                    value={values.analysisMarkersDetails.activeRotation}
                    placeHolder={0}
                    disabled={values.analysisMarkersDetails.nAActiveRotation === true}
                    allowNegative={true}
                />
              </Col>
              <Col md={3}>
                <Form.Label className=''>
                  &nbsp;
                </Form.Label>
                <Form.Control
                    as='select'
                    name={'analysisMarkersDetails.nAActiveRotation'}
                    onChange={(e) => handleNAChange(e,'nAActiveRotation')}
                    value={values.analysisMarkersDetails.nAActiveRotation===true}
                    className={'ps-1'}
                >

                  <option value='true'>{intl.formatMessage({ id: 'all.na' })}</option>
                  <option value='false'>{intl.formatMessage({ id: 'all.applicable' })}</option>
                </Form.Control>
              </Col>

            </Row>


          </Col>
          <Col md='6'>
            <Row>
              <Col md={9}>
                <DecimalInputField
                    labelId={'economicViabilityFormStep5.ownCapitalsProfitability'}
                    controlId={'analysisMarkersDetails.ownCapitalsProfitability'}
                    name={'analysisMarkersDetails.ownCapitalsProfitability'}
                    handleChange={handleChange}
                    value={values.analysisMarkersDetails.ownCapitalsProfitability}
                    placeHolder={0}
                    disabled={values.analysisMarkersDetails.nAOwnCapitalsProfitability===true}
                    allowNegative={true}
                />
              </Col>
              <Col md={3}>
                <Form.Label className=''>
                  &nbsp;
                </Form.Label>
                <Form.Control
                    as='select'
                    name={'analysisMarkersDetails.nAOwnCapitalsProfitability'}
                    onChange={(e) => handleNAChange(e,'nAOwnCapitalsProfitability')}
                    value={values.analysisMarkersDetails.nAOwnCapitalsProfitability===true}
                    className={'ps-1'}
                >

                  <option value='true'>{intl.formatMessage({ id: 'all.na' })}</option>
                  <option value='false'>{intl.formatMessage({ id: 'all.applicable' })}</option>
                </Form.Control>
              </Col>

            </Row>


          </Col>
        </Row>
      </div>

      <div className='mt-4'>
        <small className='text-primary text-uppercase mt-5'>
          <FormattedMessage id='economicViabilityFormStep5.financial.title' />
          <ToolTip message={<FormattedMessage id='economicViabilityFormStep5.infoToolTip' />} />
        </small>

        <Row>
          <Col md='3'>
            <DecimalInputField
              labelId={'economicViabilityFormStep5.financialAutonomy'}
              controlId={'analysisMarkersDetails.financialAutonomy'}
              name={'analysisMarkersDetails.financialAutonomy'}
              handleChange={handleChange}
              value={values.analysisMarkersDetails.financialAutonomy}
              placeHolder={0}
              allowNegative={true}
            />
          </Col>
          <Col md='3'>
            <DecimalInputField
              labelId={'economicViabilityFormStep5.totalSolvency'}
              controlId={'analysisMarkersDetails.totalSolvency'}
              name={'analysisMarkersDetails.totalSolvency'}
              handleChange={handleChange}
              value={values.analysisMarkersDetails.totalSolvency}
              placeHolder={0}
              allowNegative={true}
            />
          </Col>
          <Col md='6'>
            <DecimalInputField
              labelId={'economicViabilityFormStep5.financialChargesCover'}
              controlId={'analysisMarkersDetails.financialChargesCover'}
              name={'analysisMarkersDetails.financialChargesCover'}
              handleChange={handleChange}
              value={values.analysisMarkersDetails.financialChargesCover}
              placeHolder={0}
              allowNegative={true}
            />
          </Col>
        </Row>
      </div>

      <div className='mt-4'>
        <small className='text-primary text-uppercase mt-5'>
          <FormattedMessage id='economicViabilityFormStep5.liquidity.title' />
          <ToolTip message={<FormattedMessage id='economicViabilityFormStep5.infoToolTip' />} />
        </small>

        <Row>
          <Col md='6'>
            <Row>
              <Col md={9}>
                <DecimalInputField
                    labelId={'economicViabilityFormStep5.currentLiquidity'}
                    controlId={'analysisMarkersDetails.currentLiquidity'}
                    name={'analysisMarkersDetails.currentLiquidity'}
                    handleChange={handleChange}
                    value={values.analysisMarkersDetails.currentLiquidity}
                    placeHolder={0}
                    disabled={values.analysisMarkersDetails.nACurrentLiquidity===true}
                    allowNegative={true}
                />
              </Col>
              <Col md={3}>
                <Form.Label className=''>
                  &nbsp;
                </Form.Label>
                <Form.Control
                    as='select'
                    name={'analysisMarkersDetails.nACurrentLiquidity'}
                    onChange={(e) => handleNAChange(e,'nACurrentLiquidity')}
                    value={values.analysisMarkersDetails.nACurrentLiquidity===true}
                    className={'ps-1'}
                >

                  <option value='true'>{intl.formatMessage({ id: 'all.na' })}</option>
                  <option value='false'>{intl.formatMessage({ id: 'all.applicable' })}</option>
                </Form.Control>
              </Col>

            </Row>




          </Col>
          <Col md='6'>

            <Row>
              <Col md={9}>
                <DecimalInputField
                    labelId={'economicViabilityFormStep5.reducedLiquidity'}
                    controlId={'analysisMarkersDetails.reducedLiquidity'}
                    name={'analysisMarkersDetails.reducedLiquidity'}
                    handleChange={handleChange}
                    value={values.analysisMarkersDetails.reducedLiquidity}
                    placeHolder={0}
                    disabled={values.analysisMarkersDetails.nAReducedLiquidity===true}
                    allowNegative={true}
                />
              </Col>
              <Col md={3}>
                <Form.Label className=''>
                  &nbsp;
                </Form.Label>
                <Form.Control
                    as='select'
                    name={'analysisMarkersDetails.nAReducedLiquidity'}
                    onChange={(e) => handleNAChange(e,'nAReducedLiquidity')}
                    value={values.analysisMarkersDetails.nAReducedLiquidity===true}
                    className={'ps-1'}
                >

                  <option value='true'>{intl.formatMessage({ id: 'all.na' })}</option>
                  <option value='false'>{intl.formatMessage({ id: 'all.applicable' })}</option>
                </Form.Control>
              </Col>

            </Row>



          </Col>
        </Row>
      </div>

      <div className='mt-4'>
        <small className='text-primary text-uppercase mt-5'>
          <FormattedMessage id='economicViabilityFormStep5.risk.title' />
          <ToolTip message={<FormattedMessage id='economicViabilityFormStep5.infoToolTip' />} />
        </small>

        <Row>
          <Col md='6'>

            <Row>
              <Col md={9}>
                <DecimalInputField
                    labelId={'economicViabilityFormStep5.grossMargin'}
                    controlId={'analysisMarkersDetails.grossMargin'}
                    name={'analysisMarkersDetails.grossMargin'}
                    handleChange={handleChange}
                    value={values.analysisMarkersDetails.grossMargin}
                    placeHolder={0}
                    disabled={values.analysisMarkersDetails.nAGrossMargin===true}
                    allowNegative={true}
                />
              </Col>
              <Col md={3}>
                <Form.Label className=''>
                  &nbsp;
                </Form.Label>
                <Form.Control
                    as='select'
                    name={'analysisMarkersDetails.nAGrossMargin'}
                    onChange={(e) => handleNAChange(e,'nAGrossMargin')}
                    value={values.analysisMarkersDetails.nAGrossMargin===true}
                    className={'ps-1'}
                >

                  <option value='true'>{intl.formatMessage({ id: 'all.na' })}</option>
                  <option value='false'>{intl.formatMessage({ id: 'all.applicable' })}</option>
                </Form.Control>
              </Col>

            </Row>



          </Col>
          <Col md='6'>

            <Row>
              <Col md={9}>
                <DecimalInputField
                    labelId={'economicViabilityFormStep5.operatingDegreeLeverage'}
                    controlId={'analysisMarkersDetails.operatingDegreeLeverage'}
                    name={'analysisMarkersDetails.operatingDegreeLeverage'}
                    handleChange={handleChange}
                    value={values.analysisMarkersDetails.operatingDegreeLeverage}
                    placeHolder={0}
                    disabled={values.analysisMarkersDetails.nAOperatingDegreeLeverage===true}
                    allowNegative={true}
                />
              </Col>
              <Col md={3}>
                <Form.Label className=''>
                  &nbsp;
                </Form.Label>
                <Form.Control
                    as='select'
                    name={'analysisMarkersDetails.nAOperatingDegreeLeverage'}
                    onChange={(e) => handleNAChange(e,'nAOperatingDegreeLeverage')}
                    value={values.analysisMarkersDetails.nAOperatingDegreeLeverage===true}
                    className={'ps-1'}
                >

                  <option value='true'>{intl.formatMessage({ id: 'all.na' })}</option>
                  <option value='false'>{intl.formatMessage({ id: 'all.applicable' })}</option>
                </Form.Control>
              </Col>

            </Row>



          </Col>
          <Col md='6'>
            <Row>
              <Col md={9}>
                <DecimalInputField
                    labelId={'economicViabilityFormStep5.financialDegreeLeverage'}
                    controlId={'analysisMarkersDetails.financialDegreeLeverage'}
                    name={'analysisMarkersDetails.financialDegreeLeverage'}
                    handleChange={handleChange}
                    value={values.analysisMarkersDetails.financialDegreeLeverage}
                    placeHolder={0}
                    disabled={values.analysisMarkersDetails.nAFinancialDegreeLeverage===true}
                    allowNegative={true}
                />
              </Col>
              <Col md={3}>
                <Form.Label className=''>
                  &nbsp;
                </Form.Label>
                <Form.Control
                    as='select'
                    name={'analysisMarkersDetails.nAFinancialDegreeLeverage'}
                    onChange={(e) => handleNAChange(e,'nAFinancialDegreeLeverage')}
                    value={values.analysisMarkersDetails.nAFinancialDegreeLeverage===true}
                    className={'ps-1'}
                >

                  <option value='true'>{intl.formatMessage({ id: 'all.na' })}</option>
                  <option value='false'>{intl.formatMessage({ id: 'all.applicable' })}</option>
                </Form.Control>
              </Col>

            </Row>



          </Col>
        </Row>
      </div>
    </div>
  );
}
