import { Formik } from 'formik';
import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import * as yup from 'yup';
import { CandidatureFormStep3 } from '../../../components/candidature/CandidatureFormStep3';
import { CandidatureFormStep5Test } from '../../../components/candidature/CandidatureFormStep5Test';
import { CandidatureFormStep6Test } from '../../../components/candidature/CandidatureFormStep6Test';
import { ApprovalDecisionDocumentsStep } from '../../../components/candidatureChangeRequestDetail/ApprovalDecisionDocumentsStep';
import { EconomicViabilityFormStep5 } from '../../../components/economicViability/EconomicViabilityFormStep5';
import { ResumeValidationTab } from '../../../pages/backoffice/validation/ResumeValidationTab';

export function CandidatureChangeRequestApprovalForm({
	candidatureChange,
	documentTypes,
	candidatureChangeDocuments,
	setCandidatureChangeDocuments,
	formikPropsRef,
	setError,
	handleSubmit,
	numberOfSteps,
	currentStep,
  newAcceptanceTerm, 
  setNewAcceptanceTerm
}) {

	return (
		<div className='mb-5'>
			<Formik
				initialValues={candidatureChange}
				validateOnBlur={false}
				validateOnChange={false}
				validationSchema={candidatureChangeFormSchema}
			>
				{(formikProps) => (
					<Form onSubmit={formikProps.handleSubmit}>
						<fieldset>
							{renderStep(
								{ ...formikProps },
								documentTypes,
								candidatureChangeDocuments,
								setCandidatureChangeDocuments,
								formikPropsRef,
								handleSubmit,
								setError,
								numberOfSteps,
								currentStep,
								newAcceptanceTerm,
								setNewAcceptanceTerm
							)}
						</fieldset>
					</Form>
				)}
			</Formik>
		</div>
	);
}

function renderStep(
	props,
	documentTypes,
	candidatureChangeDocuments,
	setCandidatureChangeDocuments,
	formikPropsRef,
	handleSubmit,
	setError,
	numberOfSteps,
	currentStep,
	newAcceptanceTerm,
	setNewAcceptanceTerm
) {
	props.values.step = currentStep;
	formikPropsRef.current = props;

	switch (currentStep) {
		case 1:
			return (
				<CandidatureFormStep5Test
					{...props}
					values={props.values}
					handleSubmit={handleSubmit}
					numberOfSteps={numberOfSteps}
					setError={setError}
					isValidate={true}
				/>
			);
		case 2:
			return (
				<CandidatureFormStep6Test
					{...props}
					values={props.values}
					handleSubmit={handleSubmit}
					numberOfSteps={numberOfSteps}
					setError={setError}
					isValidate={true}
				/>
			);

		case 3:
			return (
				<CandidatureFormStep3
					{...props}
					values={props.values}
					handleSubmit={handleSubmit}
					numberOfSteps={numberOfSteps}
					setError={setError}
					isCandidatureChange
					hideNavigation={true}
				/>
			);

		case 4:
			return (
				<EconomicViabilityFormStep5
					{...props}
					values={props.values}
					setError={setError}
					numberOfSteps={numberOfSteps}
					context={'validation'}
				/>
			);
		case 5:
			return (
				<ResumeValidationTab
					{...props}
					values={props.values}
					setError={setError}
					numberOfSteps={numberOfSteps}
				/>
			);

		case 6:
			return (
				<ApprovalDecisionDocumentsStep
					{...props}
					values={props.values}
					documentTypes={documentTypes}
					candidatureChangeDocuments={candidatureChangeDocuments}
					setCandidatureChangeDocuments={setCandidatureChangeDocuments}
					handleSubmit={handleSubmit}
					setError={setError}
					numberOfSteps={numberOfSteps}
					newAcceptanceTerm={newAcceptanceTerm}
					setNewAcceptanceTerm={setNewAcceptanceTerm}
				/>
			);

		default:
			console.log(
				'Something went wrong while rendering the form step for the candidature change request approval form'
			);
	}
}

const candidatureChangeFormSchema = yup.object().shape({
	economicViabilityAccretionValidation: yup.object().shape({
		isSubRepresentationValid: yup
			.mixed()
			.test(
				'isValidAisSubRepresentationValid',
				<FormattedMessage id='errors.fieldRequiredText' />,
				(value) =>
					value !== '-1' && typeof value !== 'undefined' && value !== null
			),
		isInnovationProjectValid: yup
			.mixed()
			.test(
				'isInnovationProjectValid',
				<FormattedMessage id='errors.fieldRequiredText' />,
				(value) =>
					value !== '-1' && typeof value !== 'undefined' && value !== null
			),
		isLevelsValid: yup
			.mixed()
			.test(
				'isLevelsValid',
				<FormattedMessage id='errors.fieldRequiredText' />,
				(value) =>
					value !== '-1' && typeof value !== 'undefined' && value !== null
			),

		levelsValue: yup
			.number()
			.when('isLevelsValid', {
				is: (val) =>
					val !== undefined && val !== '-1' && val === true && val !== null,
				then: yup
					.number()
					.test(
						'isValidlevelsValue',
						<FormattedMessage id='errors.invalidValue' />,
						(value) => value !== '-1' && value !== '' && value % 2.5 === 0
					)
					.nullable(),
			})
			.nullable(),

		isInsideValid: yup
			.mixed()
			.test(
				'isInsideValid',
				<FormattedMessage id='errors.fieldRequiredText' />,
				(value) =>
					value !== '-1' && typeof value !== 'undefined' && value !== null
			),
		isIefpWorkstationsValid: yup
			.mixed()
			.test(
				'isIefpWorkstationsValid',
				<FormattedMessage id='errors.fieldRequiredText' />,
				(value) =>
					value !== '-1' && typeof value !== 'undefined' && value !== null
			),
		iefpWorkstationsValue: yup
			.number()
			.when('isIefpWorkstationsValid', {
				is: (val) =>
					val !== undefined && val !== '-1' && val === true && val !== null,
				then: yup
					.number()
					.test(
						'iefpWorkstationsValue',
						<FormattedMessage id='errors.invalidValue' />,
						(value) => value !== '-1' && value !== '' && value % 2.5 === 0
					)
					.nullable(),
			})
			.nullable(),
	}),

	candidatureEmployeeSupportDetails: yup.array().of(
		yup.object().shape({
			promoterMetaData: yup
				.mixed()
				.test(
					'isValidValue',
					<FormattedMessage id='errors.fieldRequiredText' />,
					(value) => value !== '-1'
				),
			timeType: yup
				.mixed()
				.test(
					'isValidValue',
					<FormattedMessage id='errors.fieldRequiredText' />,
					(value) => value !== '-1'
				),

			hours: yup.number().when('timeType', {
				is: 'PARTIAL',
				then: yup.number().min(1, <FormattedMessage id='errors.notPositive' />),
			}),
		})
	),
});
