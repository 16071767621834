import React, { useEffect, useRef, useState } from 'react';
import { Form, Row, Container, Col } from 'react-bootstrap';
import { Formik } from 'formik';
import { FormattedMessage, useIntl } from 'react-intl';
import Loading from '../../components/general/Loading';
import { SubTemplate } from '../../components/general/SubTemplate';
import { FaCheck, FaRegCheckCircle, FaRegSave, FaTimes } from 'react-icons/fa';
import { handleError, isNotBusinessError } from '../../utils/handleError';
import { FormSideNavBar } from '../../components/general/FormSideNavBar';
import { SemestralReportFormStep1 } from '../../components/semestralReport';
import { ApprovedExpenses } from '../../components/complementaryActivitiesPaymentRequest/ApprovedExpenses.js';
import { TechnicalAnalysis } from '../../components/complementaryActivitiesPaymentRequest/TechnicalAnalysis.js';
import { AnnualComplementaryActivitiesPlanFormStep2 } from '../../components/annualComplementaryActivitiesPlan';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import {
	getAdditionalActivitiesPaymentRequestByExternalId,
	getAdditionalActivitiesPaymentRequestByProcessExternalId,
	getUserAdditionalActivitiesPaymentRequest,
	listAdditionalActivitiesPaymentRequestDocuments,
	saveAdditionalActivitiesPaymentRequest,
	withdrawAdditionalActivitiesPaymentRequest,
} from '../../rest/additionalActivitiesPlanPayment';
import { getHumanResourceByExternalId } from '../../rest/humanResource';
import { ConfirmationAuthenticationModal } from '../../components/general/ConfirmationAuthenticationModal';
import { isIEFPUser } from '../../authentication/authenticationHelper';
import { getDistricts } from '../../rest/administrativeDivision';
import { getEntityGetList } from '../../rest/entity';
import { ProcessState } from '../../models/ProcessState';
import { AlertError } from '../../components/bootstrap/AlertError';
import { SuccessToast } from '../../components/general/SuccessToast';
import { AdditionalActivityType } from '../../models/AdditionalActivityType';
import { createCustomErrorMessage } from '../../hooks/errorMessage';
import * as yup from 'yup';
import { DocumentsAttachment } from '../../components/complementaryActivitiesPaymentRequest/DocumentsAttachment';
import {PayableProcessButton} from "../../components/payableProcess/PayableProcessButton";
import {DocumentType} from "../../models/DocumentType";

const validationSchema = yup.object().shape({
	paymentTechnicalAnalysis: yup.object().shape({
		technicalAnalysis: yup.object().shape({
			iasConfirmation: yup
				.number()
				.required(<FormattedMessage id='errors.fieldRequiredText' />)
				.test(
					'isNumberValid',
					<FormattedMessage id='errors.eaAccreditationForm.number' />,
					(value) => value > 0
				),

			approvedAmount: yup
				.number()
				.test(
					'isNumberValid',
					<FormattedMessage id='errors.eaAccreditationForm.number' />,
					(value) => value >= 0
				),

			advanceAmount: yup
				.number()
				.test(
					'isNumberValid',
					<FormattedMessage id='errors.eaAccreditationForm.number' />,
					(value) => value >= 0
				)
				.test(
					'isLessOrEqualToApprovedAmount',
					<FormattedMessage id='errors.annualComplementaryActivitiesPlan.advanceHigherThanApproved' />,
					(value, context) => !(value > context.parent.approvedAmount)
				),

			technicalProposalJustification: yup
				.string()
				.required(<FormattedMessage id='errors.fieldRequiredText' />),

			decisionDispatchNumber: yup
				.string()
				.required(<FormattedMessage id='errors.fieldRequiredText' />),

			decisionDispatchDate: yup
				.date()
				.nullable()
				.required(<FormattedMessage id='errors.fieldRequiredText' />),

			technicianName: yup
				.string()
				.required(<FormattedMessage id='errors.fieldRequiredText' />),

			additionalActivityControlList: yup.array().of(
				yup.object({
					limitsControl: yup
						.number()
						.test(
							'isNumberValid',
							<FormattedMessage id='errors.eaAccreditationForm.number' />,
							(value) => value >= 0
						),

					decisionProposal: yup
						.number()
						.test(
							'isNumberValid',
							<FormattedMessage id='errors.eaAccreditationForm.number' />,
							(value) => value >= 0
						)
						.test(
							'isLessOrEqualToLimitsControl',
							<FormattedMessage id='errors.annualComplementaryActivitiesPayment.decisionHigherThanExpenses' />,
							(value, context) => !(value > context.parent.limitsControl)
						)
						.test(
							'isLessOrEqualToLimitsControl',
							<FormattedMessage id='errors.annualComplementaryActivitiesPayment.decisionHigherThanBudget' />,
							(value, context) => !(value > context.parent.budget)
						),

					budget: yup
						.number()
						.test(
							'isNumberValid',
							<FormattedMessage id='errors.eaAccreditationForm.number' />,
							(value) => value >= 0
						),
				})
			),
		}),

		paidAdvanceAmount: yup
			.number()
			.required(<FormattedMessage id='errors.fieldRequiredText' />)
			.test(
				'isNumberValid',
				<FormattedMessage id='errors.eaAccreditationForm.number' />,
				(value) => value >= 0
			),

		amountToReturn: yup
			.number()
			.required(<FormattedMessage id='errors.fieldRequiredText' />)
			.test(
				'isNumberValid',
				<FormattedMessage id='errors.eaAccreditationForm.number' />,
				(value) => value >= 0
			),
		amountToPay: yup
			.number()
			.required(<FormattedMessage id='errors.fieldRequiredText' />)
			.test(
				'isNumberValid',
				<FormattedMessage id='errors.eaAccreditationForm.number' />,
				(value) => value >= 0
			),
		decisionAmountToReturn: yup
			.number()
			.required(<FormattedMessage id='errors.fieldRequiredText' />)
			.test(
				'isNumberValid',
				<FormattedMessage id='errors.eaAccreditationForm.number' />,
				(value) => value >= 0
			),
		decisionAmountToPay: yup
			.number()
			.required(<FormattedMessage id='errors.fieldRequiredText' />)
			.test(
				'isNumberValid',
				<FormattedMessage id='errors.eaAccreditationForm.number' />,
				(value) => value >= 0
			),
		advancePaymentDate: yup
			.date()
			.nullable()
			.required(<FormattedMessage id='errors.fieldRequiredText' />),
	}),

	activityIdentification: yup.object().shape({
		additionalActivityList: yup.array().of(
			yup.lazy((obj) => {
				const validationObj = {
					participants:
						obj.type !== AdditionalActivityType.DIDACTIC
							? yup
									.number()
									.test(
										'isNumberOfParticipantsValid',
										<FormattedMessage id='errors.annualComplementaryActivitiesPlan.numberOfParticipants' />,
										(value, context) => value >= 10 || context.parent.supportForecastList.length === 0
									)
							: null,

					hours:
						obj.type === AdditionalActivityType.BOOTCAMP
							? yup
									.number()
									.test(
										'isNumberValid',
										<FormattedMessage id='errors.annualComplementaryActivitiesPlan.bootcamp.hours' />,
										(value, context) => value >= 14 || context.parent.supportForecastList.length === 0
									)
							: obj.type === AdditionalActivityType.DISCLOSURE
							? yup
									.number()
									.test(
										'isNumberValid',
										<FormattedMessage id='errors.annualComplementaryActivitiesPlan.disclosure.hours' />,
										(value, context) => (value >= 3 || context.parent.onlineHours >= 3 )  || context.parent.supportForecastList.length === 0
									)
							: null,
					onlineHours:
						obj.type === AdditionalActivityType.DISCLOSURE
							? yup
									.number()
									.test(
										'isNumberValid',
										<FormattedMessage id='errors.annualComplementaryActivitiesPlan.disclosure.hours' />,
										(value, context) => (value >= 3 || context.parent.hours >= 3 ) || context.parent.supportForecastList.length === 0
									)
							: null,
					description: yup
						.string()
						.required(<FormattedMessage id='errors.fieldRequiredText' />),

					supportForecastList: yup.array().of(
						yup.object().shape({
							description: yup
								.string()
								.required(<FormattedMessage id='errors.fieldRequiredText' />),

							/* value: yup
								.number()
								.required(<FormattedMessage id='errors.fieldRequiredText' />)
								.test(
									'isNumberValid',
									<FormattedMessage id='errors.eaAccreditationForm.number' />,
									(value) => value > 0
								),

							onlineValue:
								obj.type === AdditionalActivityType.DISCLOSURE
									? yup
										.number()
										.required(
											<FormattedMessage id='errors.fieldRequiredText' />
										)
										.test(
											'isNumberValid',
											<FormattedMessage id='errors.eaAccreditationForm.number' />,
											(value) => value > 0
										)
									: null, */
						})
					),
				};
				return yup.object().shape(validationObj);
			})
		),

		incubationProjectList: yup.array().of(
			yup.object().shape({
				districtCode: yup
					.string()
					.test(
						'isDistrictCodeValid',
						<FormattedMessage id='errors.annualComplementaryActivitiesPlan.district' />,
						(value) => value !== '-1'
					),

				startMonth: yup
					.string()
					.test(
						'isMonthValid',
						<FormattedMessage id='errors.annualComplementaryActivitiesPlan.month' />,
						(value) => value !== '-1'
					),

				endMonth: yup
					.string()
					.test(
						'isMonthValid',
						<FormattedMessage id='errors.annualComplementaryActivitiesPlan.month' />,
						(value) => value !== '-1'
					),

				description: yup
					.string()
					.required(<FormattedMessage id='errors.fieldRequiredText' />),
			})
		),
	}),
	observationsEa: yup
		.string()
		.required(<FormattedMessage id='errors.fieldRequiredText' />),

	identificationEa: yup.object().shape({
		technicalTeamManagerExternalId: yup
			.string()
			.test(
				'isGETValid',
				<FormattedMessage id='errors.annualComplementaryActivitiesPlan.GET' />,
				(value) => value !== '-1'
			),
	}),
});

export function AddComplementaryActivitiesPaymentRequest() {
	const isIEFP = isIEFPUser();

	//for both iefp and entity users. In case of iefp, it is the processExternalId. Also, if it got here without the externalId, then its value is undefined
	const { externalId: externalId, entityNif: entityNif } = useParams();

	const [loading, setLoading] = useState(true);
	const [submited, setSubmited] = useState(false);

	//errors
	const [error, setError] = useState(null);
	const [navErrors, setNavErrors] = useState([]);

	const [formStep, setFormStep] = useState(0);
	const [districts, setDistricts] = useState([]);
	const [districtsOptions, setDistrictsOptions] = useState([]);
	const [formikInitialValues, setFormikInitialValues] = useState(null);

	const [showSaveToast, setShowSaveToast] = useState(false);

	//modals
	const [showWithdrawModal, setShowWithdrawModal] = useState(false);
	const [showSubmitModal, setShowSubmitModal] = useState(false);
	const [showApproveModal, setShowApproveModal] = useState(false);
	const [showRejectModal, setShowRejectModal] = useState(false);

	const [getList, setGetList] = useState([]);

	const requiredIEFPDocuments = [DocumentType.SERVICE_INFORMATION, DocumentType.NOTIFICATION_TO_EA];
	const entityDocumentsType = 'B18_ANNEX';
	const [documentsIEFP, setDocumentsIEFP] = useState([]);
	const [documentsEntity, setDocumentsEntity] = useState([]);

	const intl = useIntl();
	const navigate = useNavigate();

	const formikPropsRef = useRef(null);

	const additionalTabsIds = [
		'complementaryActivitiesPaymentRequest.sideNavBar.technicalAnalysis',
	];

	const [tabsIds, setTabsIds] = useState([
		'complementaryActivitiesPaymentRequest.sideNavBar.identificationEA',
		'complementaryActivitiesPaymentRequest.sideNavBar.activityIdentification',
		'complementaryActivitiesPaymentRequest.sideNavBar.approvedExpenses',
		'complementaryActivitiesPaymentRequest.sideNavBar.documentsAttachment',
	]);

	const toDeleteThisRandomIAS = 430.25;

	const months = [
		'1',
		'2',
		'3',
		'4',
		'5',
		'6',
		'7',
		'8',
		'9',
		'10',
		'11',
		'12',
	];

	async function fetchData() {
		try {
			let [
				{ data: districtsFromAPI },
				{ data: additionalActivitiesPaymentRequest },
			] = await Promise.all([
				await getDistricts(),

				externalId !== undefined
					? isIEFP && entityNif === undefined
						? await getAdditionalActivitiesPaymentRequestByProcessExternalId(
								externalId
						  )
						  : await getAdditionalActivitiesPaymentRequestByExternalId(
							externalId, entityNif
					  )

					: await getUserAdditionalActivitiesPaymentRequest(),

			]);

			let [
				{ data: entityGetList },
			] = await Promise.all([
				isIEFP ? { data: [] } : await getEntityGetList({all: additionalActivitiesPaymentRequest.processState !== ProcessState.DRAFT }),
			]);



			if (isIEFP) {
				const { data: get } = await getHumanResourceByExternalId(
					additionalActivitiesPaymentRequest.identificationEa
						.technicalTeamManagerExternalId
				);
				entityGetList.push(get);
			}

			if (
				(isIEFP &&
					additionalActivitiesPaymentRequest.processState !==
						ProcessState.DRAFT) ||
				ProcessState.finalStates().includes(
					additionalActivitiesPaymentRequest.processState
				)
			) {
				setTabsIds((prevStateValue) => {
					return prevStateValue.concat(additionalTabsIds);
				});
			}

			let { data: processDocuments } =
				await listAdditionalActivitiesPaymentRequestDocuments(
					additionalActivitiesPaymentRequest.externalId
				);

			let IEFPDocuments = [];
			let entityDocuments = [];

			processDocuments.forEach((d) => {
				let doc = {
					externalId: d.externalId,
					documentType: d.documentType,
					documentPath: d.documentPath,
					name: d.name,
					content: '',
					submited: true,
					error: '',
					fileDescription: d.fileDescription,
				};

				d.documentType === entityDocumentsType
					? entityDocuments.push(doc)
					: IEFPDocuments.push(doc);
			});

			requiredIEFPDocuments.forEach((rd) => {
				let dTypeIndex = IEFPDocuments.findIndex((d) => d.documentType === rd);
				if (dTypeIndex < 0) {
					//it means the document wasnt found
					IEFPDocuments.push({
						externalId: '',
						documentType: rd,
						name: '',
						content: null,
						submited: false,
						error: '',
					});
				}
			});

			setDocumentsIEFP(IEFPDocuments);
			setDocumentsEntity(entityDocuments);

			setGetList(entityGetList);

			console.log(additionalActivitiesPaymentRequest);
			setFormikInitialValues(
				getFormikInitialValues(additionalActivitiesPaymentRequest)
			);

			setDistricts(districtsFromAPI);
			let districtsNames = [];

			districtsFromAPI.forEach((districtObj) => {
				districtsNames.push(districtObj.description);
			});
			setDistrictsOptions(districtsNames);
		} catch (error) {
			setError(error);
		} finally {
			setLoading(false);
		}
	}

	function getFormikInitialValues(b18) {
		let initialValues = {
			activityIdentification: {},
			paymentTechnicalAnalysis: {
				technicalAnalysis: {},
			},
		};


		initialValues.submissionDate=b18.submissionDate;
		initialValues.approvalDate = b18.approvalDate;
		initialValues.paymentTechnicalAnalysis.paidAdvanceAmount =
			b18.paymentTechnicalAnalysis.paidAdvanceAmount !== undefined
				? b18.paymentTechnicalAnalysis.paidAdvanceAmount
				: '';

		if (isIEFP || ProcessState.finalStates().includes(b18.processState)) {
			initialValues.paymentTechnicalAnalysis.advancePaymentDate =
				b18.paymentTechnicalAnalysis.advancePaymentDate !== undefined
					? b18.paymentTechnicalAnalysis.advancePaymentDate
					: null;
			initialValues.paymentTechnicalAnalysis.amountToReturn =
				b18.paymentTechnicalAnalysis.amountToReturn !== undefined
					? b18.paymentTechnicalAnalysis.amountToReturn
					: '';
			initialValues.paymentTechnicalAnalysis.amountToPay =
				b18.paymentTechnicalAnalysis.amountToPay !== undefined
					? b18.paymentTechnicalAnalysis.amountToPay
					: '';
			initialValues.paymentTechnicalAnalysis.decisionAmountToReturn =
				b18.paymentTechnicalAnalysis.decisionAmountToReturn !== undefined
					? b18.paymentTechnicalAnalysis.decisionAmountToReturn
					: '';
			initialValues.paymentTechnicalAnalysis.decisionAmountToPay =
				b18.paymentTechnicalAnalysis.decisionAmountToPay !== undefined
					? b18.paymentTechnicalAnalysis.decisionAmountToPay
					: '';

			initialValues.paymentTechnicalAnalysis.technicalAnalysis.iasConfirmation =
				b18.paymentTechnicalAnalysis.technicalAnalysis.iasConfirmation !==
				undefined
					? b18.paymentTechnicalAnalysis.technicalAnalysis.iasConfirmation
					: '';
			initialValues.paymentTechnicalAnalysis.technicalAnalysis.approvedAmount =
				b18.paymentTechnicalAnalysis.technicalAnalysis.approvedAmount !==
				undefined
					? b18.paymentTechnicalAnalysis.technicalAnalysis.approvedAmount
					: '';
			initialValues.paymentTechnicalAnalysis.technicalAnalysis.advanceAmount =
				b18.paymentTechnicalAnalysis.technicalAnalysis.advanceAmount !==
				undefined
					? b18.paymentTechnicalAnalysis.technicalAnalysis.advanceAmount
					: '';
			initialValues.paymentTechnicalAnalysis.technicalAnalysis.technicalProposalJustification =
				b18.paymentTechnicalAnalysis.technicalAnalysis
					.technicalProposalJustification
					? b18.paymentTechnicalAnalysis.technicalAnalysis
							.technicalProposalJustification
					: '';
			initialValues.paymentTechnicalAnalysis.technicalAnalysis.additionalActivityControlList =
				b18.paymentTechnicalAnalysis.technicalAnalysis.additionalActivityControlList;
			initialValues.paymentTechnicalAnalysis.technicalAnalysis.proposalDispatchNumber =
				b18.paymentTechnicalAnalysis.technicalAnalysis.proposalDispatchNumber
					? b18.paymentTechnicalAnalysis.technicalAnalysis
							.proposalDispatchNumber
					: '';
			initialValues.paymentTechnicalAnalysis.technicalAnalysis.proposalDispatchDate =
				b18.paymentTechnicalAnalysis.technicalAnalysis.proposalDispatchDate
					? b18.paymentTechnicalAnalysis.technicalAnalysis.proposalDispatchDate
					: null;
			initialValues.paymentTechnicalAnalysis.technicalAnalysis.decisionDispatchNumber =
				b18.paymentTechnicalAnalysis.technicalAnalysis.decisionDispatchNumber
					? b18.paymentTechnicalAnalysis.technicalAnalysis
							.decisionDispatchNumber
					: '';
			initialValues.paymentTechnicalAnalysis.technicalAnalysis.decisionDispatchDate =
				b18.paymentTechnicalAnalysis.technicalAnalysis.decisionDispatchDate
					? b18.paymentTechnicalAnalysis.technicalAnalysis.decisionDispatchDate
					: null;

			initialValues.paymentTechnicalAnalysis.technicalAnalysis.technicianName =
				b18.paymentTechnicalAnalysis.technicalAnalysis.technicianName
					? b18.paymentTechnicalAnalysis.technicalAnalysis.technicianName
					: '';
			initialValues.paymentTechnicalAnalysis.technicalAnalysis.directorName =
				b18.paymentTechnicalAnalysis.technicalAnalysis.directorName
					? b18.paymentTechnicalAnalysis.technicalAnalysis.directorName
					: '';
			initialValues.paymentTechnicalAnalysis.technicalAnalysis.regionalDelegateName =
				b18.paymentTechnicalAnalysis.technicalAnalysis.regionalDelegateName
					? b18.paymentTechnicalAnalysis.technicalAnalysis.regionalDelegateName
					: '';

			initialValues.paymentTechnicalAnalysis.technicalAnalysis.additionalActivityControlList.forEach(
				(activityControl) => {
					if (activityControl.limitsControl === undefined) {
						activityControl.limitsControl = '';
					}

					if (activityControl.decisionProposal === undefined) {
						activityControl.decisionProposal = '';
					}

					if (activityControl.budget === undefined) {
						activityControl.budget = '';
					}
				}
			);
		}

		initialValues.processState = b18.processState;
		initialValues.activityIdentification.ias = b18.activityIdentification.ias
			? b18.activityIdentification.ias
			: '';
		initialValues.observationsEa = b18.observationsEa ? b18.observationsEa : '';

		initialValues.activityIdentification.additionalActivityList =
			b18.activityIdentification.additionalActivityList;

		initialValues.activityIdentification.incubationProjectList =
			b18.activityIdentification.incubationProjectList;
		initialValues.year = b18.year;
		initialValues.externalId = b18.externalId;
		initialValues.identificationEa = b18.identificationEa;

		initialValues.activityIdentification.incubationProjectList.forEach(
			(proj) => {
				proj.startMonth = proj.startMonth.toString(10);
				proj.endMonth = proj.endMonth.toString(10);
			}
		);

		initialValues.activityIdentification.additionalActivityList.forEach(
			(activity) => {
				if (
					activity.type !== AdditionalActivityType.DIDACTIC &&
					activity.participants === undefined
				) {
					activity.participants = '';
				}

				if (
					activity.type !== AdditionalActivityType.DIDACTIC &&
					activity.hours === undefined
				) {
					activity.hours = '';
				}

				if (
					activity.type === AdditionalActivityType.DISCLOSURE &&
					activity.onlineHours === undefined
				) {
					activity.onlineHours = '';
				}

				if (activity.description === undefined) {
					activity.description = '';
				}
			}
		);

		initialValues.expensesStatusList = b18.expensesStatusList;
		return initialValues;
	}

	function scrollPageToTop() {
		document.body.scrollTop = 0;
		document.documentElement.scrollTop = 0;
	}

	function handlePreviousStep() {
		scrollPageToTop();
		setFormStep(formStep - 1);
	}

	function handleNextStep() {
		scrollPageToTop();
		setFormStep(formStep + 1);
	}

	useEffect(() => {
		fetchData();
	}, []);

	if (submited) {
		if (isIEFP) {
			sessionStorage.setItem(
				'additionalActivitiesPaymentRequestDecisionSubmited',
				submited
			);
		} else {
			sessionStorage.setItem(
				'additionalActivitiesPaymentRequestSubmited',
				submited
			);
		}
		return isIEFP ? (
			<Navigate to='/processos' />
		) : (
			<Navigate to='/pedidoPagamento/atividadesComplementares/lista' />
		);
	}

	if (loading) {
		return <Loading />;
	}

	if (error && isNotBusinessError(error)) {
		return handleError(error);
	}

	async function handleWithdrawal(user, password, setModalError) {
		try {
			let authenticationCredentials = {
				user: user,
				password: password,
			};

			await withdrawAdditionalActivitiesPaymentRequest(
				formikPropsRef.current.values.externalId,
				authenticationCredentials
			);

			sessionStorage.setItem(
				'additionalActivitiesPaymentRequestWithdrawed',
				true
			);
			navigate('/pedidoPagamento/atividadesComplementares/lista');
		} catch (error) {
			setModalError(error);
		}
	}

	async function handleSave() {
		try {
			let { data: res } = await saveAdditionalActivitiesPaymentRequest(
				formikPropsRef.current.values
			);

			let processedRes = getFormikInitialValues(res);

			formikPropsRef.current.setValues(processedRes);
			setNavErrors([]);
			formikPropsRef.current.setErrors({});

			setShowSaveToast(true);
		} catch (error) {
			setError(error);
		}
	}

	async function handleSubmit(user, password, setModalError, decisionApprove) {
		let values = formikPropsRef.current.values;
		const submissionValues = { ...values };

		submissionValues.credentials = {
			user: user,
			password: password,
		};

		if (isIEFP) {
			submissionValues.decisionApprove = decisionApprove;
		}

		let queryParams = {
			submit: true,
		};

		try {
			await saveAdditionalActivitiesPaymentRequest(
				submissionValues,
				queryParams
			);
			setSubmited(true);
		} catch (err) {
			if (err.response.data.exception === 'ValidationException') {
				handleNavError(err);
			} else {
				setModalError(err);
			}
		}
	}

	async function formValidationHandler(setShowModal) {
		let errors = await validateForm();
		setNavErrors(errors);
		if (errors.length === 0) {
			setShowModal(true);
		}
	}

	async function validateForm() {
		let errors = [];

		const formikErrors = await formikPropsRef.current.validateForm();

		Object.keys(formikErrors).forEach((key) => {
			switch (key) {
				case 'identificationEa':
					!isIEFP && errors.push(0);
					break;
				case 'activityIdentification':
					!isIEFP && errors.push(1);
					break;
				case 'observationsEa':
					!isIEFP && errors.push(2);
					break;
				case 'expensesStatusList':
					!isIEFP && errors.push(2);
					break;
				default:
					isIEFP && errors.push(4);
					break;
			}
		});

		//validate that both dispatch documents have been uploaded for the IEFP user
		if (isIEFP) {
			documentsIEFP.forEach((d) => {
				if (d.submited === false) {
					errors.push(4);
					setError(
						createCustomErrorMessage(
							intl.formatMessage({
								id: 'errors.annualComplementaryActivitiesPlan.dispatchDocument',
							})
						)
					);
				}
			});
		}

		if (!isIEFP) {

			formikPropsRef.current.values.activityIdentification.additionalActivityList.forEach(
				(additionalActivity) => {
					if (additionalActivity.type === AdditionalActivityType.DISCLOSURE) {

						let hasRoomValue = 0;
						let hasOnlineValue = 0;
						additionalActivity.supportForecastList.forEach((event) => {
							hasRoomValue += event.value;
							hasOnlineValue += event.onlineValue
						})

						if ((additionalActivity.hours > 0 && hasRoomValue <= 0) || (additionalActivity.onlineHours > 0 && hasOnlineValue <= 0)) {
							errors.push(1);
							setError(
								createCustomErrorMessage(
									intl.formatMessage({
										id: `errors.annualComplementaryActivitiesPlan.additionalActivity.disclosure.supportForecast`,
									})
								)
							);
						} else if ((additionalActivity.hours <= 0 && hasRoomValue > 0) || (additionalActivity.onlineHours <= 0 && hasOnlineValue > 0)) {
							errors.push(1);
							setError(
								createCustomErrorMessage(
									intl.formatMessage({
										id: `errors.annualComplementaryActivitiesPlan.additionalActivity.disclosure.supportForecastAndHoursInconsistent`,
									})
								)
							);
						}
					}
				})


			/* if (
				formikPropsRef.current.values.activityIdentification
					.incubationProjectList.length === 0
			) {
				errors.push(1);
				setError(
					createCustomErrorMessage(
						intl.formatMessage({
							id: 'errors.annualComplementaryActivitiesPlan.incubationProjectList',
						})
					)
				);
			} */
			/* formikPropsRef.current.values.activityIdentification.additionalActivityList.forEach(
				(additionalActivity) => {
					if (additionalActivity.supportForecastList.length === 0) {
						errors.push(1);
						setError(
							createCustomErrorMessage(
								intl.formatMessage({
									id: `errors.annualComplementaryActivitiesPlan.additionalActivity.${additionalActivity.type}`,
								})
							)
						);
					}
				}
			); */
		}
		return errors;
	}

	function handleNavError(error) {
		let errors = error.response.data.message
			.split(',')
			.map((value) => Number.parseInt(value));
		setNavErrors(errors);
	}

	function renderStep(formikProps) {
		formikPropsRef.current = formikProps;

		const readModeStep1To4 =
			formikProps.values.processState !== ProcessState.DRAFT || isIEFP;

		switch (formStep) {
			case 0:
				return (
					<SemestralReportFormStep1
						formStep={1}
						numberOfSteps={tabsIds.length}
						errors={formikProps.errors}
						formikValues={formikProps.values}
						handleChange={formikProps.handleChange}
						handleNextStep={handleNextStep}
						entityGetList={getList}
						readMode={readModeStep1To4}
						goBackPath={
							isIEFP
								? '/processos'
								: '/pedidoPagamento/atividadesComplementares/lista'
						}
					/>
				);
			case 1:
				return (
					<AnnualComplementaryActivitiesPlanFormStep2
						formStep={2}
						numberOfSteps={tabsIds.length}
						errors={formikProps.errors}
						formikValues={formikProps.values}
						handleChange={formikProps.handleChange}
						handlePreviousStep={handlePreviousStep}
						handleNextStep={handleNextStep}
						setFieldValue={formikProps.setFieldValue}
						districts={districts}
						districtsOptions={districtsOptions}
						months={months}
						formText={'AddComplementaryActivitiesPaymentRequest'}
						readMode={readModeStep1To4}
					/>
				);

			case 2:
				return (
					<ApprovedExpenses
						formStep={3}
						numberOfSteps={tabsIds.length}
						errors={formikProps.errors}
						formikValues={formikProps.values}
						handleChange={formikProps.handleChange}
						handleNextStep={handleNextStep}
						handlePreviousStep={handlePreviousStep}
						IAS={toDeleteThisRandomIAS}
						months={months}
						showNextStepButton={true}
						entityGetList={getList}
						readMode={readModeStep1To4}
					/>
				);
			case 3:
				return (
					<DocumentsAttachment
						formStep={4}
						numberOfSteps={tabsIds.length}
						formikValues={formikProps.values}
						handleNextStep={handleNextStep}
						handlePreviousStep={handlePreviousStep}
						showNextStepButton={
							ProcessState.finalStates().includes(
								formikProps.values.processState
							) ||
							(isIEFP && formikProps.values.processState !== ProcessState.DRAFT)
						}
						readMode={readModeStep1To4}
						documents={documentsEntity}
						setDocuments={setDocumentsEntity}
					/>
				);

			case 4:
				return (
					(ProcessState.finalStates().includes(
						formikProps.values.processState
					) ||
						(isIEFP &&
							formikProps.values.processState === ProcessState.SUBMITTED)) && (
						<TechnicalAnalysis
							formStep={5}
							numberOfSteps={tabsIds.length}
							errors={formikProps.errors}
							formikValues={formikProps.values}
							handleChange={formikProps.handleChange}
							handlePreviousStep={handlePreviousStep}
							IAS={toDeleteThisRandomIAS}
							months={months}
							setFieldValue={formikProps.setFieldValue}
							setError={setError}
							documents={documentsIEFP}
							setDocuments={setDocumentsIEFP}
							readMode={ProcessState.finalStates().includes(
								formikProps.values.processState
							)}
						/>
					)
				);

			default:
				console.log('Something went wrong rendering the form step');
		}
	}

	return (
		<SubTemplate>
			<Container>
				<ConfirmationAuthenticationModal
					show={
						showWithdrawModal ||
						showSubmitModal ||
						showApproveModal ||
						showRejectModal
					}
					handleClose={() =>
						showSubmitModal
							? setShowSubmitModal(false)
							: showApproveModal
							? setShowApproveModal(false)
							: showWithdrawModal
							? setShowWithdrawModal(false)
							: setShowRejectModal(false)
					}
					submitHandler={showWithdrawModal ? handleWithdrawal : handleSubmit}
					idsPreffix={
						showApproveModal
							? 'annualComplementaryActivitiesPayment.iefp.acceptModal'
							: showRejectModal
							? 'annualComplementaryActivitiesPayment.iefp.rejectModal'
							: showWithdrawModal
							? 'annualComplementaryActivitiesPayment.withdrawModal'
							: 'annualComplementaryActivitiesPayment.submitModal'
					}
					confirmButtonCompleteLabelId={
						showApproveModal
							? 'all.button.approve'
							: showRejectModal
							? 'all.button.reject'
							: showWithdrawModal
							? 'all.candidatureWithdrawal'
							: 'all.submitButtonText'
					}
					IEFPdecisionApprove={
						showApproveModal ? true : showRejectModal ? false : undefined
					}
				/>
				<Row>
					<Col md={{ span: 8, offset: 2 }}>
						<h2 className='mb-1 text-secondary'>
							<FormattedMessage id='complementaryActivitiesPaymentRequest.title' />
						</h2>
						<p className='mb-5 text-secondary'>
							<FormattedMessage id='complementaryActivitiesPaymentRequest.subTitle' />
							<span
								className="badge rounded-pill bg-secondary fw-normal ms-2"><span
								className="text-uppercase fw-bold"><FormattedMessage
								id={`processState.${formikInitialValues.processState}`}
							/> </span>  {formikInitialValues.submissionDate !== undefined && formikInitialValues.submissionDate !== null && (<> · <FormattedMessage id={'submitted.process.at'} values={{date:formikInitialValues.submissionDate}}/>  </>) } {formikInitialValues.approvalDate !== undefined && formikInitialValues.approvalDate !== null && (<> · {ProcessState.APPROVED===formikInitialValues.processState ? <FormattedMessage id={'approvedIEFP.process.at'} values={{date:formikInitialValues.approvalDate}}/>:<FormattedMessage id={'rejectedIEFP.process.at'} values={{date:formikInitialValues.approvalDate}}/>}   </>) }  </span>
						</p>
					</Col>
				</Row>
				<Row className='mb-5'>
					<Col md='2'>
						<FormSideNavBar
							tabsIdsArray={tabsIds}
							formStep={formStep}
							setFormStep={setFormStep}
							navErrors={navErrors}
							setNavErrors={setNavErrors}
						/>
					</Col>
					<Col md='8'>
						<AlertError error={error} />
						<SuccessToast
							message={intl.formatMessage({
								id: 'annualComplementaryActivitiesPlan.saveToastInformation',
							})}
							show={showSaveToast}
							setShow={setShowSaveToast}
						/>
						<Formik
							initialValues={formikInitialValues}
							validateOnBlur={false}
							validateOnChange={false}
							validationSchema={validationSchema}
							onSubmit={handleSubmit}
						>
							{(formikProps) => (
								<Form onSubmit={formikProps.handleSubmit}>
									{renderStep(formikProps)}
								</Form>
							)}
						</Formik>
					</Col>
					{formikInitialValues.processState === ProcessState.APPROVED &&
						<Col md='2'>
							{isIEFP ? <PayableProcessButton processExternalId={externalId}/> : <PayableProcessButton payableExternalId={externalId}/>}
						</Col>
					}
					{((isIEFP &&
						formikInitialValues.processState === ProcessState.SUBMITTED) ||
						(!isIEFP &&
							formikInitialValues.processState === ProcessState.DRAFT)) && (
						<Col md='2'>
							<div>
								{!isIEFP && (
									<button
										className='btn btn-outline-primary d-flex align-items-center justify-content-center mb-4 w-100'
										type='button'
										onClick={() => setShowWithdrawModal(true)}
									>
										<FormattedMessage id='all.candidatureWithdrawal' />
									</button>
								)}
								<button
									className='btn btn-outline-primary d-flex align-items-center justify-content-center mb-4 w-100'
									type='button'
									onClick={() => handleSave()}
								>
									<FaRegSave />
									<FormattedMessage id='all.save' />
								</button>
								{isIEFP ? (
									<>
										<button
											className='btn btn-primary d-flex align-items-center justify-content-center mb-4 w-100'
											type='button'
											onClick={() => formValidationHandler(setShowRejectModal)}
										>
											<FaTimes /> <FormattedMessage id='all.button.reject' />
										</button>
										<button
											className='btn btn-primary d-flex align-items-center justify-content-center mb-4 w-100'
											type='button'
											onClick={() => formValidationHandler(setShowApproveModal)}
										>
											<FaCheck /> <FormattedMessage id='all.button.approve' />
										</button>
									</>
								) : (
									<button
										className='btn btn-primary d-flex align-items-center justify-content-center w-100'
										type='button'
										onClick={() => formValidationHandler(setShowSubmitModal)}
									>
										<FaRegCheckCircle />
										<FormattedMessage id='all.submitButtonText' />
									</button>
								)}
							</div>
						</Col>
					)}
				</Row>
			</Container>
		</SubTemplate>
	);
}
