import { useIntl } from 'react-intl';
import {SelectBooleanField} from "../components/bootstrap/SelectBooleanField";
import React from "react";

export function TranslateBoolean({ value, trueLabelId,falseLabelId }) {



  const intl = useIntl();
  switch (value) {
    case true:
      return intl.formatMessage({
        id: trueLabelId ? trueLabelId :'all.yes',
      });
    case false:
      return intl.formatMessage({
        id: falseLabelId ? falseLabelId: 'all.no',
      });
    case null:
    case undefined:
      return 'N/A';
    default:
      return value;
  }
}
