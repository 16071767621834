import React, {useEffect, useState} from 'react';
import {DateTimeInputField} from '../../bootstrap/DateTimeInputField';
import {Row, Col, Table, Form} from 'react-bootstrap';
import {FormattedMessage, useIntl} from 'react-intl';
import {currencyFormat, maxFixed} from '../../../utils/CurrencyUtils';
import DecimalInputField from '../../bootstrap/DecimalInputField';
import {TextAreaInputField} from '../../bootstrap/TextAreaInputField';
import IntInputField from "../../bootstrap/IntInputField";

export function FinalReportFormStep6({
                                         formStep,
                                         numberOfSteps,
                                         errors,
                                         formikValues,
                                         handleChange,
                                         handlePreviousStep,
                                         handleNextStep,
                                         setFieldValue,
                                         subRowsIdsOfRowTitle,
                                         totalPercentComercialActivity,
                                            readMode
                                     }) {
    const intl = useIntl();




    const tableHeaders = ['predicted', 'yearN', 'yearNplus1', 'yearNplus2'];

    const deviationTableHeaders = [
        'deviationYearN',
        'deviationYearNplus1',
        'deviationYearNplus2',
    ];

    const economicRatioTableHeaders = ['economicStatusRatios', ...tableHeaders];

    const economicRatioDeviationTableHeaders = [
        'deviationEconomicStatusRatios',
        ...deviationTableHeaders,
    ];

    const evaluationParametersHeaders = ['VAL', 'TIR', 'PRC'];

    const strengthsAndWeaknessesHeaders = ['areas', 'strong', 'weak'];

    const rowTitlesIds = ['salesByMarketStructure', 'clientTypes', 'salesMeans'];





    const economicRowTitlesIds = [
        'salesAmount',
        'grossMargin',
        'netIncome',
        'salesProfitability',
        'generalLiquidity',
        'solvency',
        'financialAutonomy',
        'averagePaymentPeriod',
        'averageReceiptPeriod',
    ];

    const economicRowTitlesIdsInt = [
        'averagePaymentPeriod',
        'averageReceiptPeriod',
    ];



    const evaluationParametersRowTitlesIds = [
        'predicted',
        'atTheEndOfTheContract',
        'deviations',
    ];

    const strengthsAndWeaknessesRowTitlesIds = [
        'PointsIAndD',
        'PointsManagement',
        'PointsInformationSystem',
        'PointsHumanResources',
        'PointsMarketing',
        'PointsLogistic',
        'PointsFinance',
        'PointsOther',
    ];


    function calculateDeviation(
        isEconomic,
        dataAtt,
        subRowDataAtt,
        numeratorYearAtt,
        denominatorYearAtt
    ) {
        return isEconomic
            ? formikValues.economicRatios[dataAtt][numeratorYearAtt] /
            formikValues.economicRatios[dataAtt][denominatorYearAtt] || 0
            : formikValues[dataAtt][subRowDataAtt][numeratorYearAtt] /
            formikValues[dataAtt][subRowDataAtt][denominatorYearAtt] || 0;
    }

    function calculateEvaluationParametersDeviation(header) {
        let dev =
            formikValues.evaluationParameters.atTheEndOfTheContract[header] /
            formikValues.evaluationParameters.predicted[header] || 0;

        if (dev === 0 || dev === Infinity) {
            return 0;
        }

        if (dev < 1) {
            dev *= -1;
        } else {
            dev -= 1;
        }
        dev = (dev * 100).toFixed(2);
        return dev > 0 ? '+ ' + dev : '- ' + Math.abs(dev);
    }



    function generateTableHeaders(headers, doNotAddEmptyCell) {
        return (
            <thead>
            <tr>
                {doNotAddEmptyCell ? null : <th className='w-35'/>}
                {headers.map((header, index) => (
                    <th key={header}>
                        <FormattedMessage id={`finalReportForm.step6.table.${header}`}/>
                    </th>
                ))}
            </tr>
            </thead>
        );
    }

    //generate the rows that hold the total %
    function generateTableRows(isDeviation) {
        return rowTitlesIds.map((rowtitleId, index) => (
            <React.Fragment key={rowtitleId}>
                <tr className='bg-light'>
                    <th>
                        <FormattedMessage
                            id={`finalReportForm.step6.table.${isDeviation?'deviation.':''}${rowtitleId}`}
                        />
                    </th>
                    {isDeviation ? (
                        <th colSpan={3}></th>


                    ) : (
                        tableHeaders.map((header, index2) => (
                            <th key={index2}>
                                {maxFixed(totalPercentComercialActivity(rowtitleId, index2,formikValues)).toFixed(2) + ' %'}
                            </th>
                        ))
                    )}
                </tr>
                {generateRowSubItems(isDeviation, index)}
            </React.Fragment>
        ));
    }

    function generateRowSubItems(isDeviation, index) {
        let dataAtt =
            index === 0
                ? 'salesByMarketStructure'
                : index === 1
                ? 'clientTypes'
                : 'salesMeans';

        return subRowsIdsOfRowTitle[index].map((subRowTitleId) => {
            //handle the required id for translation into data prop
            let subRowDataAtt = subRowTitleId.startsWith('others')
                ? 'others'
                : subRowTitleId;

            return (
                <tr key={subRowTitleId}>
                    <th>
                        <FormattedMessage
                            id={`finalReportForm.step6.table.${subRowTitleId}`}
                        />
                    </th>
                    {isDeviation
                        ? deviationTableHeaders.map((numeratorHeader, index2) => (
                            <td key={numeratorHeader}>
                                {
                                     parseFloat(formikValues.commercialActivityColumns[index2+1][subRowTitleId] - formikValues.commercialActivityColumns[index2][subRowTitleId] ).toFixed(2) +' %'
                                }
                            </td>
                        ))
                        : tableHeaders.map((tableHeader,index2) => (
                            <td key={index2}>
                                <DecimalInputField
                                    handleChange={handleChange}
                                    name={`commercialActivityColumns.${index2}.${subRowTitleId}`}
                                    suffixLabel='%'
                                    value={formikValues.commercialActivityColumns[index2][subRowTitleId]}
									isInvalid={
										errors.commercialActivityColumns?.[index2]?.[subRowTitleId]
									}
									errorMessage={
										errors.commercialActivityColumns?.[index2]?.[subRowTitleId]
									}
                                />
                            </td>
                        ))}
                </tr>
            );
        });
    }

    function generateEconomicTableRows(isDeviation) {
        return economicRowTitlesIds.map((rowTitleId) => {
            return (
                <tr key={rowTitleId}>
                    <th>
                        <FormattedMessage
                            id={`finalReportForm.step6.table.${rowTitleId}`}
                        />
                    </th>
                    {isDeviation
                        ? deviationTableHeaders.map((numeratorHeader, index2) => (
                            <td key={numeratorHeader}>
                                { economicRowTitlesIdsInt.includes(rowTitleId) ? parseInt (formikValues.indicatorEconomicSituations[index2+1][rowTitleId] - formikValues.indicatorEconomicSituations[index2][rowTitleId]): (parseFloat(formikValues.indicatorEconomicSituations[index2+1][rowTitleId] - formikValues.indicatorEconomicSituations[index2][rowTitleId]).toFixed(2) ) }
                                 {(rowTitleId==='salesAmount'  || rowTitleId==='netIncome')? ' €' :  (rowTitleId==='grossMargin' || rowTitleId==='solvency')? ' %' : '' }

                            </td>
                        ))
                        : tableHeaders.map((header,index2) => (
                            <td key={rowTitleId + header}>
                                {  economicRowTitlesIdsInt.includes(rowTitleId) ?
                                    <IntInputField
                                        handleChange={handleChange}
                                        name={`indicatorEconomicSituations.${index2}.${rowTitleId}`}
                                        suffixLabel='%'
                                        value={formikValues.indicatorEconomicSituations[index2][rowTitleId]}
                                        isInvalid={
                                            errors.indicatorEconomicSituations?.[index2]?.[rowTitleId]
                                        }
                                        errorMessage={
                                            errors.indicatorEconomicSituations?.[index2]?.[rowTitleId]
                                        }
                                    />
                                    :



                                    <DecimalInputField
                                        handleChange={handleChange}
                                        name={`indicatorEconomicSituations.${index2}.${rowTitleId}`}
                                        value={formikValues.indicatorEconomicSituations[index2][rowTitleId]}
                                        suffixLabel= {(rowTitleId==='salesAmount'  || rowTitleId==='netIncome')? ' €' :  (rowTitleId==='grossMargin' || rowTitleId==='solvency')? ' %' : '' }
                                        isInvalid={
                                            errors.indicatorEconomicSituations?.[index2]?.[rowTitleId]
                                        }
                                        errorMessage={
                                            errors.indicatorEconomicSituations?.[index2]?.[rowTitleId]
                                        }
                                        allowNegative={rowTitleId==='netIncome'}
                                        showErrorOnToolTip={true}
                                    />

                                }



                            </td>
                        ))}
                </tr>
            );
        });
    }

    return (
        <div>
            <h4 className='text-primary mb-0'>
                <FormattedMessage id='finalReportForm.sideNavBar.commercialActivity'/>
            </h4>
            <small className='text-secondary text-uppercase'>
                {intl.formatMessage(
                    {id: 'all.step'},
                    {step: formStep, numberOfSteps: numberOfSteps}
                )}
            </small>
            <fieldset disabled={readMode == true}>
            <Row className='mt-5'>
                <Col>
                    <small className='text-primary text-uppercase'>
                        <FormattedMessage id='finalReportForm.step6.border'/>
                    </small>

                    <Table className='d-table mt-2' responsive='md'>
                        {generateTableHeaders(tableHeaders)}
                        <tbody>{generateTableRows(false)}</tbody>
                    </Table>
                </Col>
            </Row>

			<Row className='mt-5'>
				<Col>
					<small className='text-primary text-uppercase'>
						<FormattedMessage id='finalReportForm.step6.annualComparison' />
					</small>
					<Table className='d-table mt-2' responsive='md'>
						{generateTableHeaders(deviationTableHeaders)}
						<tbody>{generateTableRows(true)}</tbody>
					</Table>
				</Col>
			</Row>


            <Row className='mt-5'>
                <Col>
                    <small className='text-primary text-uppercase'>
                        <FormattedMessage id='finalReportForm.step6.economicStatus' />
                    </small>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Table className='d-table mt-4' responsive='md'>
                        {generateTableHeaders(economicRatioTableHeaders, true)}
                        <tbody>{generateEconomicTableRows(false)}</tbody>
                    </Table>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Table className='d-table mt-4' responsive='md'>
                        {generateTableHeaders(economicRatioDeviationTableHeaders, true)}
                        <tbody>{generateEconomicTableRows(true)}</tbody>
                    </Table>
                </Col>
            </Row>

            <Row className='mt-5'>
                <Col>
                    <small className='text-primary text-uppercase'>
                        <FormattedMessage id='finalReportForm.step6.evaluationParameters' />
                    </small>
                    <Table className='d-table mt-2' responsive='md'>
                        {generateTableHeaders(evaluationParametersHeaders)}
                        <tbody>
                            <tr>
                                <th><FormattedMessage id={'finalReportForm.step6.table.predicted'}/></th>
                                <td>
                                    <DecimalInputField
                                        handleChange={handleChange}
                                        name={`initialVAL`}
                                        value={
                                            formikValues.initialVAL
                                        }
                                        isInvalid={errors.initialVAL}
                                        errorMessage={errors.initialVAL}
                                    />
                                    

                                </td>
                                <td>    <DecimalInputField
                                    handleChange={handleChange}
                                    name={`initialTIR`}
                                    value={
                                        formikValues.initialTIR
                                    }
                                    isInvalid={errors.initialTIR}
                                    errorMessage={errors.initialTIR}
                                /></td>
                                <td><IntInputField
                                    handleChange={handleChange}
                                    name={`initialPRC`}
                                    value={
                                        formikValues.initialPRC
                                    }
                                    isInvalid={errors.initialPRC}
                                    errorMessage={errors.initialPRC}
                                /></td>
                            </tr>
                            <tr>
                                <th><FormattedMessage id={'finalReportForm.step6.table.atTheEndOfTheContract'}/></th>
                                <td>
                                    <DecimalInputField
                                        handleChange={handleChange}
                                        name={`finalVAL`}
                                        value={
                                            formikValues.finalVAL
                                        }
                                        isInvalid={errors.finalVAL}
                                        errorMessage={errors.finalVAL}
                                    />


                                </td>
                                <td>    <DecimalInputField
                                    handleChange={handleChange}
                                    name={`finalTIR`}
                                    value={
                                        formikValues.finalTIR
                                    }
                                    isInvalid={errors.finalTIR}
                                    errorMessage={errors.finalTIR}
                                /></td>
                                <td><IntInputField
                                    handleChange={handleChange}
                                    name={`finalPRC`}
                                    value={
                                        formikValues.finalPRC
                                    }
                                    isInvalid={errors.finalPRC}
                                    errorMessage={errors.finalPRC}
                                /></td>
                            </tr>
                            <tr>
                                <th><FormattedMessage id={'finalReportForm.step6.table.deviations'}/></th>
                                <td> {    maxFixed(formikValues.initialVAL ==0 ? 0 :(formikValues.finalVAL - formikValues.initialVAL )*100/ formikValues.initialVAL )} %</td>
                                <td>{maxFixed(  formikValues.initialTIR ==0 ? 0 :(formikValues.finalTIR - formikValues.initialTIR )*100/ formikValues.initialTIR )} %</td>
                                <td>{ maxFixed(  formikValues.initialPRC ==0 ? 0 :(formikValues.finalPRC - formikValues.initialPRC )*100/ formikValues.initialPRC )} %</td>
                            </tr>




                        </tbody>
                    </Table>
                </Col>
            </Row>
            <Row className='mt-5'>
                <Col>
                    <small className='text-primary text-uppercase'>
                        <FormattedMessage id='finalReportForm.step6.strengthsAndWeaknessesByAreas' />
                    </small>
                    <Table className='d-table mt-2' responsive='md'>
                        {generateTableHeaders(strengthsAndWeaknessesHeaders, true)}
                        <tbody>
                        {strengthsAndWeaknessesRowTitlesIds.map((rowTitleId) => (
                            <tr key={rowTitleId}>
                                <th>
                                    <FormattedMessage
                                        id={`finalReportForm.step6.table.${rowTitleId}`}
                                    />
                                </th>
                                {strengthsAndWeaknessesHeaders.slice(1).map((header) => (
                                    <td key={header}>
                                        <TextAreaInputField
                                            handleChange={handleChange}
                                            name={`strongWeakPoints.${header + rowTitleId}`}
                                            value={formikValues.strongWeakPoints[header+rowTitleId]}
                                            isInvalid={errors.strongWeakPoints?.[header+rowTitleId]}
                                            errorMessage={errors.strongWeakPoints?.[header+rowTitleId]}
                                        />
                                    </td>
                                ))}
                            </tr>
                        ))}
                        </tbody>
                    </Table>
                </Col>
            </Row>







            <Row className='mt-4'>
                <Col>
                    <small className='text-primary text-uppercase'>
                        <FormattedMessage id='finalReportForm.step6.opportunitiesAndThreats'/>
                    </small>
                </Col>
            </Row>
            <Row>
                <Col md='6'>
                    <TextAreaInputField
                        name='strongWeakPoints.opportunities'
                        labelId='finalReportForm.step6.opportunities'
                        handleChange={handleChange}
                        value={formikValues.strongWeakPoints.opportunities}
                        isInvalid={errors.strongWeakPoints?.opportunities}
                        errorMessage={errors.strongWeakPoints?.opportunities}
                    />
                </Col>
                <Col md='6'>
                    <TextAreaInputField
                        name='strongWeakPoints.threats'
                        labelId='finalReportForm.step6.threats'
                        handleChange={handleChange}
                        value={formikValues.strongWeakPoints?.threats}
                        isInvalid={errors.strongWeakPoints?.threats}
                        errorMessage={errors.strongWeakPoints?.threats}
                    />
                </Col>
            </Row>
            <Row className='mt-5 mb-2'>
                <Col>
                    <small className='text-primary text-uppercase'>
                        <FormattedMessage id='finalReportForm.step6.companyAppreciationByEA'/>
                    </small>
                </Col>
            </Row>
            <Row>
                <Col>
                    <TextAreaInputField
                        name='companyAppreciationByEA'
                        handleChange={handleChange}
                        value={formikValues.companyAppreciationByEA}
                        isInvalid={errors.companyAppreciationByEA}
                        errorMessage={errors.companyAppreciationByEA}
                    />
                </Col>
            </Row>
            </fieldset>
            <div className='form-actions mt-5'>
                <button
                    className='btn btn-link'
                    type='button'
                    onClick={handlePreviousStep}
                >
                    <FormattedMessage id='all.back'/>
                </button>

                <button
                    className='btn btn-primary'
                    type='button'
                    onClick={handleNextStep}
                >
                    <FormattedMessage id='all.nextStep'/>
                </button>
            </div>
        </div>
    );
}
