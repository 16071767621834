import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { TextInputField } from '../bootstrap/TextInputField';

export function SinglePromoterDetails({ promoter }) {
	return (
		<>
			<Row>
				<Col md='8'>
					<TextInputField
						disabled={true}
						labelId={'eaPromoterStep.name'}
						value={promoter.name}
					/>
				</Col>
				<Col md='4'>
					<TextInputField
						labelId={'eaPromoterStep.ccbi'}
						disabled={true}
						value={promoter.ccbi}
					/>
				</Col>
			</Row>
			<Row>
				<Col md='6'>
					<TextInputField
						disabled={true}
						labelId={'eaPromoterStep.nif'}
						value={promoter.nif}
					/>
				</Col>
				<Col md='6'>
					<TextInputField
						disabled={true}
						labelId={'eaPromoterStep.niss'}
						value={promoter.niss}
					/>
				</Col>
			</Row>
			<Row>
				<Col md='6'>
					<TextInputField
						disabled={true}
						labelId={'eaPromoterStep.address'}
						value={promoter.address}
					/>
				</Col>
				<Col md='6'>
					<TextInputField
						disabled={true}
						labelId={'eaPromoterStep.email'}
						value={promoter.email}
					/>
				</Col>
			</Row>
		</>
	);
}
