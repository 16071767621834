import { apiAxios as authAxios } from '../axios.config';

export const listEconomicViabilityPaymentRequest = (queryParams) => {
  return authAxios.get('/api/economicViability/payment/list/', {params:  queryParams})
}

export const getEconomicViabilityPaymentRequest = (b7ExternalId) => {
  return authAxios.get(`/api/economicViability/payment/${b7ExternalId !== undefined ? b7ExternalId : ''}`)
}

export const getEconomicViabilityPaymentRequestByProcessExternalId = (b7ProcessExternalId) => {
  return authAxios.get(`/api/economicViability/payment/process/${b7ProcessExternalId}`)
}

export const saveEconomicViabilityPaymentRequest = (b7, queryParams) => {
  return authAxios.post('/api/economicViability/payment/', b7, queryParams ? {params: queryParams}: null)
}

export const withdrawEconomicViabilityPaymentRequest = (b7ExternalId, authenticationCredentials) => {
  return authAxios.patch(`/api/economicViability/payment/${b7ExternalId}/withdrawal`, authenticationCredentials)
}

export const economicViabilityPaymentRequestFormStatus = () => {
  return authAxios.get('/api/economicViability/payment/formStatus/')
}

export const howManyEligibleEconomicViabilitiesHaveNoPayment = () => {
  return authAxios.get('/api/economicViability/payment/howManyWaitingPayment/')
}

export const listEconomicViabilityPaymentRequestDocuments = (economicViabilityExternalId) => {
  return authAxios.get(`/api/economicViability/payment/${economicViabilityExternalId}/document`)
}

export const uploadDocument = (economicViabilityExternalId, formData) => {
  return authAxios.post(`/api/economicViability/payment/${economicViabilityExternalId}/document`, formData)
}

export const deleteDocument = (economicViabilityExternalId, documentExternalId) => {
  return authAxios.delete(`/api/economicViability/payment/${economicViabilityExternalId}/document/${documentExternalId}`)
}
