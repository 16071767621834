import React, { useState } from 'react';
import { TextInputField } from '../../bootstrap/TextInputField';
import { NumberInputField } from '../../bootstrap/NumberInputField';
import { Row, Col, Form, Accordion } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { EnumSelectField } from '../../bootstrap/EnumSelectField';
import ToolTip from '../../bootstrap/ToolTip';
import { FaExclamationCircle } from 'react-icons/fa';
import IntInputField from '../../bootstrap/IntInputField';
import DecimalInputField from "../../bootstrap/DecimalInputField";
import {isIEFPUser} from "../../../authentication/authenticationHelper";

export function EaAccreditationFormStep1({
	formStep,
	numberOfSteps,
	errors,
	handleChange,
	formikValues,
	handleNextStep,
	setFieldValue,
	districts,
	districtOptions,
	readMode = false
}) {
	const intl = useIntl();
	const [entityTypeOtherOptionActive, setEntityTypeOtherOptionActive] =
		useState(false);

	const entityTypeOptions = [
		'PRIVATE_WITHOUT_MONEY',
		'PRIVATE_WITH_MONEY',
		'PUBLIC',
		'LOCAL_AUTHORITY',
		'OTHER',
	];

	const isIEFP = isIEFPUser();

	const regionalDelegationsAbbreviations = ['DN', 'DC', 'DL', 'DA', 'DG'];

	function addGET(regionalDelegationCode) {
		let newGET = {
			name: '',
			phone: '',
			mobile: '',
			email: '',
			type: 'GET',
			regionalDelegationCode: regionalDelegationCode,
			managementExperience: false,
			qualificationLevelCode: '-1',
		};

		formikValues.getList.push(newGET);

		setFieldValue({ ...formikValues });
	}

	function removeGET(regionalDelegationCode) {
		formikValues.getList.splice(
			formikValues.getList.findIndex(
				(get) => get.regionalDelegationCode === regionalDelegationCode
			),
			1
		);
		setFieldValue({ ...formikValues });
	}

	function entityTypeChangeHandler(e) {
		if (e.target.value === 'OTHER') {
			setEntityTypeOtherOptionActive(true);
		} else {
			formikValues.entity.profileDescription = '';
			setFieldValue({ ...formikValues });
			setEntityTypeOtherOptionActive(false);
		}
		handleChange(e);
	}

	function regionalDelegationSelectionChangeHandler(e, regionalDelegationCode) {
		if (e.target.checked === true) {
			addGET(regionalDelegationCode);
		} else {
			removeGET(regionalDelegationCode);
		}
	}

	function districtChangeHandler(e) {
		setFieldValue(
			e.target.name,
			districts.filter((district) => district.description === e.target.value)[0]
				.code
		);
	}

	function handleChangeCae(e){
		setFieldValue('entity.cae',e.target.value)
	}

	return (
		<div>
			<h4 className='text-primary mb-0'>
				<FormattedMessage id='eaAccreditationForm.step1.title' />
			</h4>
			<small className='text-secondary text-uppercase'>
				{intl.formatMessage(
					{ id: 'all.step' },
					{ step: formStep, numberOfSteps: numberOfSteps }
				)}
			</small>

			<Row>
				<Col md='8'>
					<TextInputField
						labelId={'eaAccreditationForm.step1.description'}
						name={'entity.description'}
						handleChange={handleChange}
						value={formikValues.entity.description}
						isInvalid={errors.entity?.description}
						errorMessage={errors.entity?.description}
						disabled={readMode}
					/>
				</Col>
				<Col md='4'>
					<NumberInputField
						labelId={'eaAccreditationForm.step1.nif'}
						name={'entity.nif'}
						value={formikValues.entity.nif}
						maxLength={9}
						isInvalid={errors.entity?.nif}
						errorMessage={errors.entity?.nif}
						disabled={true}
					/>
				</Col>
			</Row>
			<Row>
				<Col md='4'>
					<EnumSelectField
						nullOption={true}
						options={districtOptions}
						labelId={'eaAccreditationForm.step1.district'}
						name={'entity.districtCode'}
						handleChange={(e) => districtChangeHandler(e)}
						value={
							formikValues.entity.districtCode !== '-1'
								? districts.filter(
										(district) =>
											district.code === formikValues.entity.districtCode
								  )[0].description
								: '-1'
						}
						isInvalid={errors.entity?.districtCode}
						errorMessage={errors.entity?.districtCode}
						disabled={readMode}
					/>
				</Col>
				<Col md='6'>
					<NumberInputField
						labelId={'eaAccreditationForm.step1.nationalMemberYear'}
						name={'entity.nationalMemberYear'}
						handleChange={handleChange}
						value={formikValues.entity.nationalMemberYear}
						maxLength={4}
						isInvalid={errors.entity?.nationalMemberYear}
						errorMessage={errors.entity?.nationalMemberYear}
						disabled={readMode}
					/>
				</Col>
				{isIEFP && (
				<Col md='2'>
					<DecimalInputField
						labelId={'eaAccreditationForm.step1.score'}
						controlId={'entity.score'}
						name={'entity.score'}
						handleChange={handleChange}
						value={formikValues.entity.score}
						placeHolder={0}
						maxValue={100}
						suffixLabel={'%'}
						isInvalid={errors.entity?.score}
						errorMessage={errors.entity?.score}
						disabled={true}
					/>
				</Col>
				)}


			</Row>
			<Row>
				<Col md='12'>
					<TextInputField
						labelId={'eaAccreditationForm.step1.address'}
						name={'entity.address'}
						handleChange={handleChange}
						value={formikValues.entity.address}
						isInvalid={errors.entity?.address}
						errorMessage={errors.entity?.address}
						disabled={readMode}
					/>
				</Col>
			</Row>
			<Row className='align-items-end'>
				<Col md='4'>
					<Form.Label>
						<FormattedMessage id='eaAccreditationForm.step1.postalCode' />
					</Form.Label>
					<div className='d-inline d-flex align-items-center'>
						<TextInputField
							name={'entity.postalCode4Digits'}
							handleChange={handleChange}
							value={formikValues.entity.postalCode4Digits}
							isInvalid={errors.entity?.postalCode4Digits}
							errorMessage={errors.entity?.postalCode4Digits}
							maxLength={4}
							disabled={readMode}
						/>
						<span className='ms-2 me-2'>{'-'}</span>
						<TextInputField
							name={'entity.postalCode3Digits'}
							handleChange={handleChange}
							value={formikValues.entity.postalCode3Digits}
							isInvalid={errors.entity?.postalCode3Digits}
							errorMessage={errors.entity?.postalCode3Digits}
							maxLength={3}
							disabled={readMode}
						/>
					</div>
				</Col>
				<Col md='8'>
					<TextInputField
						placeholder={intl.formatMessage({
							id: 'eaAccreditationForm.step1.location.placeholder',
						})}
						name={'entity.location'}
						handleChange={handleChange}
						value={formikValues.entity.location}
						isInvalid={errors.entity?.location}
						errorMessage={errors.entity?.location}
						disabled={readMode}
					/>
				</Col>
			</Row>
			<Row>
				<Col md='8'>
					<TextInputField
						labelId={'eaAccreditationForm.step1.email'}
						name={'entity.email'}
						value={formikValues.entity.email}
						isInvalid={errors.entity?.email}
						errorMessage={errors.entity?.email}
						disabled={true}
					/>
				</Col>

				<Col md='4'>
					<NumberInputField
						labelId={'eaAccreditationForm.step1.phone'}
						name={'entity.phone'}
						handleChange={handleChange}
						value={formikValues.entity.phone}
						isInvalid={errors.entity?.phone}
						errorMessage={errors.entity?.phone}
						maxLength={9}
						disabled={readMode}
					/>
				</Col>
			</Row>
			<Row>
				<Col md='8'>
					<EnumSelectField
						nullOption={true}
						preffixDescriptionId='eaAccreditationForm.step1.type.option'
						options={entityTypeOptions}
						labelId={'eaAccreditationForm.step1.type'}
						name={'entity.profile'}
						handleChange={entityTypeChangeHandler}
						value={formikValues.entity.profile}
						isInvalid={errors.entity?.profile}
						errorMessage={errors.entity?.profile}
						disabled={readMode}
					/>
					{entityTypeOtherOptionActive && (
						<div className='mt-1'>
							<TextInputField
								placeholder={intl.formatMessage({
									id: 'eaAccreditationForm.step1.type.option.other.placeholder',
								})}
								name={'entity.profileDescription'}
								handleChange={handleChange}
								value={formikValues.entity.profileDescription}
								isInvalid={errors.entity?.profileDescription}
								errorMessage={errors.entity?.profileDescription}
								disabled={readMode}
							/>
						</div>
					)}
				</Col>
				<Col md='4'>
					<IntInputField
						className='w-50'
						labelId={'eaAccreditationForm.step1.cae'}
						name={'entity.cae'}
						handleChange={(e) => handleChangeCae(e)}
						value={formikValues.entity.cae}
						isInvalid={errors.entity?.cae}
						errorMessage={errors.entity?.cae}
						maxSize={5}
						leadingZero={true}
						disabled={readMode}
					/>
				</Col>
			</Row>
			<Row>
				<Col>
					<Form.Label>
						<FormattedMessage id='eaAccreditationForm.step1.getList' />
						<ToolTip
							message={intl.formatMessage({
								id: 'eaAccreditationForm.step1.getList.tooltip',
							})}
						/>
					</Form.Label>
					{regionalDelegationsAbbreviations.map((rDAbbv) => (
						<Form.Check
							key={rDAbbv}
							type={'checkbox'}
							label={rDAbbv}
							checked={
								formikValues.getList.findIndex(
									(get) => get.regionalDelegationCode === rDAbbv
								) !== -1
							}
							onChange={(e) =>
								regionalDelegationSelectionChangeHandler(e, rDAbbv)
							}
							disabled={readMode}
							isInvalid={rDAbbv in errors}
						/>
					))}
				</Col>
				<Row>
					<Col>
						<small>
							<FormattedMessage id='eaAccreditationForm.step1.getList.regionalDelegationsLegend' />
						</small>
					</Col>
				</Row>
			</Row>
			{formikValues.getList.map((get, index) => (
				<Row key={index}>
					<Col>
						<Accordion>
							<Accordion.Item eventKey='0'>
								<Accordion.Header>
									<span className='d-flex align-items-center'>
										{errors.getList?.[index] &&
											(Object.keys(errors.getList[index]).length > 1 ||
												!errors.getList[index].hasOwnProperty(
													'qualificationLevelCode'
												)) && <FaExclamationCircle />}
										<FormattedMessage id='eaAccreditationForm.step1.accordion.get' />
										{get.regionalDelegationCode}
									</span>
								</Accordion.Header>
								<Accordion.Body>
									<Row>
										<Col>
											<TextInputField
												labelId={'eaAccreditationForm.step1.getList.name'}
												name={`getList[${index}].name`}
												handleChange={handleChange}
												value={formikValues.getList[index].name}
												isInvalid={errors.getList?.[index]?.name}
												errorMessage={errors.getList?.[index]?.name}
												disabled={readMode}
											/>
										</Col>
									</Row>
									<Row>
										<Col>
											<NumberInputField
												labelId={'eaAccreditationForm.step1.getList.phone'}
												name={`getList[${index}].phone`}
												handleChange={handleChange}
												value={formikValues.getList[index].phone}
												isInvalid={errors.getList?.[index]?.phone}
												errorMessage={errors.getList?.[index]?.phone}
												maxLength={9}
												disabled={readMode}
											/>
										</Col>
										<Col>
											<NumberInputField
												labelId={'eaAccreditationForm.step1.getList.mobile'}
												name={`getList[${index}].mobile`}
												handleChange={handleChange}
												value={formikValues.getList[index].mobile}
												isInvalid={errors.getList?.[index]?.mobile}
												errorMessage={errors.getList?.[index]?.mobile}
												maxLength={9}
												disabled={readMode}
											/>
										</Col>
									</Row>
									<Row>
										<Col>
											<TextInputField
												labelId={'eaAccreditationForm.step1.getList.email'}
												name={`getList[${index}].email`}
												handleChange={handleChange}
												value={formikValues.getList[index].email}
												isInvalid={errors.getList?.[index]?.email}
												errorMessage={errors.getList?.[index]?.email}
												disabled={readMode}
											/>
										</Col>
									</Row>
								</Accordion.Body>
							</Accordion.Item>
						</Accordion>
					</Col>
				</Row>
			))}
			<div className='form-actions mt-5'>
				<Link to={'/'}>
					<button className='btn btn-link' type='button'>
						<FormattedMessage id='all.back' />
					</button>
				</Link>
				<button
					className='btn btn-primary'
					type='button'
					onClick={handleNextStep}
				>
					<FormattedMessage id='all.nextStep' />
				</button>
			</div>
		</div>
	);
}
