import {acceptedValidateEligibility, approve, rejectValidateEligibility} from '../../rest/candidature';
import {useParams} from 'react-router-dom';
import React from "react";
import {CandidatureValidationElegibility} from "../../components/candidature/CandidatureValidationElegibility";

export function RejectEligibility() {
    const {externalId} = useParams();

    const rejectCandidatureHandler = async () => {
        await rejectValidateEligibility(externalId);
    }

    return (
        <CandidatureValidationElegibility
            validationTitle='candidature.flow.invalidate.elegibility'
            messageConfirmation='candidature.flow.invalidate.elegibility.message'
            callbackHandler={rejectCandidatureHandler}
            isApprove={false}
        />
    );

}
