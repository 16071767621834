import { FormattedMessage } from 'react-intl';
import * as yup from 'yup';

export const FinancialModalityFormUtils = {
	validationObject: () => validationObject(),
	getFormikInitialValuesFinancialModality: (initialValues) =>
		getFormikInitialValuesCompanyIdentification(initialValues),
	validateAtLeastOneOptionFromEmpreendeXXIMeasureIsSelected: (
		formikValues,
		intl,
		errorMessages
	) =>
		validateAtLeastOneOptionFromEmpreendeXXIMeasureIsSelected(
			formikValues,
			intl,
			errorMessages
		),
};

function validationObject() {
	let validationObject = {
		unemploymentAntecipation: yup
			.boolean()
			.required(<FormattedMessage id='errors.fieldRequiredText' />),

		interestFreeLoan: yup
			.boolean()
			.required(<FormattedMessage id='errors.fieldRequiredText' />),

		nonRefundableSubsidy: yup
			.boolean()
			.required(<FormattedMessage id='errors.fieldRequiredText' />),

		financialEmployment: yup
			.boolean()
			.required(<FormattedMessage id='errors.fieldRequiredText' />),

		classificationAxis: yup
			.boolean()
			.required(<FormattedMessage id='errors.fieldRequiredText' />),

		mais: yup
			.boolean()
			.required(<FormattedMessage id='errors.fieldRequiredText' />),

		emigrantSupport: yup
			.boolean()
			.required(<FormattedMessage id='errors.fieldRequiredText' />),

		contractSupport: yup
			.boolean()
			.required(<FormattedMessage id='errors.fieldRequiredText' />),

		others: yup.string(),
	};

	return yup.object().shape(validationObject);
}

function getFormikInitialValuesCompanyIdentification(initialValues) {
	initialValues.financialModality.unemploymentAntecipation ??= null;
	initialValues.financialModality.interestFreeLoan ??= false;
	initialValues.financialModality.nonRefundableSubsidy ??= false;
	initialValues.financialModality.financialEmployment ??= false;
	initialValues.financialModality.classificationAxis ??= false;
	initialValues.financialModality.mais ??= false;
	initialValues.financialModality.emigrantSupport ??= false;
	initialValues.financialModality.contractSupport ??= false;
	initialValues.financialModality.others ??= '';
}

function validateAtLeastOneOptionFromEmpreendeXXIMeasureIsSelected(
	formikValues,
	intl,
	errorMessages
) {
	if (
		!(
			formikValues.financialModality.classificationAxis === true ||
			formikValues.financialModality.nonRefundableSubsidy === true ||
			formikValues.financialModality.financialEmployment === true ||
			formikValues.financialModality.interestFreeLoan === true
		)
	) {
		errorMessages.push(
			intl.formatMessage({
				id: 'financialModality.needAtLeastOne',
			})
		);
		return false;
	}

	return true;
}
