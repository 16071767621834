import React from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { GenderType } from '../../models/GenderType';
import { IdentificationType } from '../../models/IdentificationType';
import { Strings } from '../../utils/Strings';
import { DateTimeInputField } from '../bootstrap/DateTimeInputField';
import { EnumSelectField } from '../bootstrap/EnumSelectField';
import { TextInputField } from '../bootstrap/TextInputField';
import {useGlobalContext} from "../../context";

export function PromoterFormStep2({
  values,
  countries,
  schoolQualifications,
  handleChange,
  setFieldValue,
  errors,
  validateForm,
  savePromoter,
  numberOfSteps
}) {

  const intl = useIntl();
  const countriesDescriptions = countries.map((country) => country.description);
  const { setName } = useGlobalContext();

  const handleNextStep = async () => {
    const errors = await validateForm();
    if (Object.keys(errors).length === 0) {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
      savePromoter(values, setFieldValue);
      setName(values.name)
    }
  };

  function getIdNumberPlaceHolder() {
    if (Strings.isBlank(values.identificationType)) {
      return '';
    }
    switch (values.identificationType) {
      case IdentificationType.CITIZEN_CARD:
        return 'Formato xxxxxxxx x xxx';

      case IdentificationType.IDENTITY_CARD:
        return 'Formato xxxxxxxx x';

      default:
        break;
    }
  }

  function showEmissionDate(identificationType) {
    switch (identificationType) {
      case IdentificationType.CITIZEN_CARD:
        return false;
      case IdentificationType.IDENTITY_CARD:
        return true;
      case IdentificationType.RESIDENCE_TITLE:
        return true;
      case IdentificationType.PASSPORT:
        return true;
      default:
        break;
    }
  }

  function handleSchoolQualificationChange(e) {
    setFieldValue('schoolQualification', e.target.value);
    setFieldValue('qualificationLevel', getCorrespondentLevel(e.target.value));
  }

  function getCorrespondentLevel(value) {
    switch (value) {
      case '01':
        return '0';
      case '02':
        return '1';
      case '03':
        return '2';
      case '04':
        return '3';
      case '05':
      case '06':
        return '4';
      case '07':
        return '5';
      case '08':
        return '6';
      case '09':
        return '7';
      case '10':
        return '8';
      default:
        return '';
    }
  }

  return (
    <div>
      <h4 className='text-primary mb-0'>
        <FormattedMessage id='promoterForm.personalData' />
      </h4>
      <small className='text-secondary text-uppercase'>
        {intl.formatMessage(
          { id: 'all.step' },
          { step: values.step, numberOfSteps: numberOfSteps }
        )}
      </small>
      <Row>
        <Col md='12'>
          <TextInputField
              controlId={'name'}
              labelId={'promoterForm.name'}
              name={'name'}
              handleChange={handleChange}
              value={values.name}
              isInvalid={errors.name}
              errorMessage={errors.name}
          />
        </Col>
      </Row>



      <Row>
        <Col md='4'>
          <EnumSelectField
            controlId={'nationality'}
            labelId={'promoterForm.nationality'}
            name={'nationality'}
            handleChange={handleChange}
            options={countriesDescriptions}
            value={values.nationality}
            nullOption={true}
            isInvalid={errors.nationality}
            errorMessage={errors.nationality}
          />
        </Col>
        <Col md='4'>
          <TextInputField
            controlId={'naturality'}
            labelId={'promoterForm.naturality'}
            name={'naturality'}
            handleChange={handleChange}
            value={values.naturality}
            isInvalid={errors.naturality}
            errorMessage={errors.naturality}
          />
        </Col>
      </Row>
      <Row>
        <Col md='4'>
          <Form.Group controlId={'schoolQualification'}>
            <Form.Label>
              <FormattedMessage id='promoterForm.schoolQualification' />
            </Form.Label>
            <Form.Control
              as='select'
              name={'schoolQualification'}
              onChange={(e) => handleSchoolQualificationChange(e)}
              value={values.schoolQualification}
              isInvalid={errors.schoolQualification}>
              <option value={'-1'}>
                <FormattedMessage id='all.chooseOne' />
              </option>
              {schoolQualifications.map((schoolQualification) => (
                <option key={schoolQualification.code} value={schoolQualification.code}>
                  {schoolQualification.description}
                </option>
              ))}
            </Form.Control>
            <Form.Control.Feedback type='invalid'>
              {errors.schoolQualification}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        {!Strings.isBlank(values.qualificationLevel) && (
          <Col md='4'>
            <div>
            <label className='form-label'><FormattedMessage id={'promoterForm.qualificationLevel'}/></label>
            <p className='mt-2'><FormattedMessage
                id={`qualificationLevel.${values.qualificationLevel}`}
            /></p>
            </div>
          </Col>
        )}
      </Row>
      <Row>
        <Col md='4'>
          <DateTimeInputField
            name={'birthDate'}
            labelId={'promoterForm.birthDate'}
            value={values.birthDate}
            isDateWithNoTime={true}
            setFieldValue={setFieldValue}
            isInvalid={errors.birthDate}
            errorMessage={errors.birthDate}
          />
        </Col>
        <Col md='4'>
          <EnumSelectField
            controlId={'gender'}
            labelId={'promoterForm.gender'}
            preffixDescriptionId={'promoterForm.gender'}
            name={'gender'}
            handleChange={handleChange}
            options={GenderType.values()}
            value={values.gender}
            nullOption={true}
            isInvalid={errors.gender}
            errorMessage={errors.gender}
          />
        </Col>
      </Row>
      <Row>
        <Col md='4'>
          <TextInputField
            controlId={'niss'}
            label={'NISS'}
            name={'niss'}
            handleChange={handleChange}
            value={values.niss}
            maxLength={11}
            isInvalid={errors.niss}
            errorMessage={errors.niss}
          />
        </Col>
        <Col md='4'>
          <TextInputField
            controlId={'nif'}
            label={'NIF'}
            name={'nif'}
            handleChange={handleChange}
            value={values.nif}
            isInvalid={errors.nif}
            maxLength={9}
            errorMessage={errors.nif}
          />
        </Col>
      </Row>
      <Row>
        <Col md='4'>
          <EnumSelectField
            controlId={'identificationType'}
            labelId={'promoterForm.identificationType'}
            preffixDescriptionId={'promoterForm.identificationType'}
            name={'identificationType'}
            handleChange={handleChange}
            options={IdentificationType.values()}
            value={values.identificationType}
            nullOption={true}
            isInvalid={errors.identificationType}
            errorMessage={errors.identificationType}
          />
        </Col>
        <Col md='4'>
          <TextInputField
            controlId={'identificationNumber'}
            labelId={'promoterForm.identificationNumber'}
            name={'identificationNumber'}
            placeholder={getIdNumberPlaceHolder()}
            handleChange={handleChange}
            value={values.identificationNumber}
            isInvalid={errors.identificationNumber}
            errorMessage={errors.identificationNumber}
          />
        </Col>
        <Col md='4'>
          <DateTimeInputField
            name={'identificationValidity'}
            labelId={'promoterForm.identificationValidity'}
            value={values.identificationValidity}
            isDateWithNoTime={true}
            setFieldValue={setFieldValue}
            isInvalid={errors.identificationValidity}
            errorMessage={errors.identificationValidity}
          />
        </Col>
      </Row>

      <Row>
        <Col md='4'>
          {showEmissionDate(values.identificationType) && (
            <DateTimeInputField
              name={'emissionDate'}
              labelId={'promoterForm.emissionDate'}
              value={values.emissionDate}
              isDateWithNoTime={true}
              setFieldValue={setFieldValue}
              isInvalid={errors.emissionDate}
              errorMessage={errors.emissionDate}
            />
          )}
        </Col>
      </Row>

      <div className='form-actions'>
        <button
          className='btn btn-link px-0'
          type='button'
          onClick={(e) => setFieldValue('step', values.step - 1)}>
          <FormattedMessage id='all.back' />
        </button>
        <button className='btn btn-primary' type='button' onClick={(e) => handleNextStep()}>
          <FormattedMessage id='all.nextStep' />
        </button>
      </div>
    </div>
  );
}
