import { Formik } from 'formik';
import React, { useState } from 'react'
import {Button, Form} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { DocumentType } from '../../models/DocumentType';
import { MultipleUploadArea } from '../MultipleUploadArea';
import {Arrays} from "../../utils/Arrays";

export function AddReviewPriorSupportForm({ onSubmit, userDocuments,setDocuments, externalId }) {
  return (
    <div className='mb-5'>
      <h2 className='mb-1 text-secondary'> <FormattedMessage id="candidature.submitReviewPriorSupport.title" /></h2>
      <p className='mb-5 text-secondary'>
          <FormattedMessage id="candidature.submitReviewPriorSupport.addDocuments" />
      </p>



          <Form>

            <MultipleUploadArea
              documents={userDocuments}
              documentType={DocumentType.REVIEW_PRIOR_SUPPORT}
              candidatureExternalId={externalId}
              setDocuments={setDocuments}

            />
            <div className='form-actions'>
              <Link to={'/'}>
                <button
                  className='btn btn-link'
                  type='button'
                >
                    <FormattedMessage id="all.backButtonText" />

                </button>
              </Link>

                { Arrays.isNotEmpty(userDocuments) && (
                    <button
                        className='btn btn-primary'
                        type='button'
                        onClick={onSubmit}
                    >
                        <FormattedMessage id="all.submitButtonText" />

                    </button>
                )

                }

            </div>

          </Form>




    </div>
  )
}
