import {useParams} from "react-router-dom";
import {SubTemplate} from "../../../general/SubTemplate";
import {Button, Col, Container, Row} from "react-bootstrap";
import {FaInfoCircle} from 'react-icons/fa';
import React, {useEffect, useState} from "react";
import {
    addIrregularity,
    addIrregularityWithDocument,
    getIrregularity,
    getIrregularityCauseToRegulationMap, responseIrregularity, responseIrregularityWithDocument
} from "../../../../rest/irregularities";
import Loading from "../../../general/Loading";
import {AlertError} from "../../../bootstrap/AlertError";
import {FormattedMessage} from "react-intl";
import {hasPermission, isEntityUser, isIEFPUser, isPromoterUser} from "../../../../authentication/authenticationHelper";
import {useGlobalContext} from "../../../../context";
import {IrregularityDecisionModal} from "./IrregularityDecisionModal";
import {AuthenticatedDownloadLink} from "../../../document/AuthenticatedDownloadLink";
import {IrregularityState} from "./IrregularityState";
import {TextAreaInputField} from "../../../bootstrap/TextAreaInputField";
import {SingleUploadArea} from "../../../SingleUploadArea";
import {AlertSuccess} from "../../../bootstrap/AlertSuccess";

export function IrregularityDetail() {
    const {irregularityExternalId} = useParams();
    const {candidatureExternalId} = useParams();
    const [irregularityCauseToRegulationMap, setIrregularityCauseToRegulationMap,] = useState(null);
    const [irregularity, setIrregularity,] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const {accessInfo} = useGlobalContext();
    const [decisionModalShow, setDecisionModalShow] = useState(false);
    const [modalSubmitted, setModalSubmitted] = useState(false);
    const [errors, setErrors] = useState([])
    const [documents, setDocuments] = useState([]);
    const [formData, setFormData] = useState(null);
    const [reason, setReason] = useState('');
    const [response, setResponse] = useState(false);
    const [lsItem, setLsItem] = useState(null);


    const fetchData = async () => {
        try {
            setModalSubmitted(false);
            setLoading(true);
            const [{data: irregularity}, {data: causeMap}] = await Promise.all([
                getIrregularity(candidatureExternalId, irregularityExternalId),
                getIrregularityCauseToRegulationMap(' ')
            ]);
            setIrregularity(irregularity);
            if (irregularity.response !== undefined) {
                setReason(irregularity.response);
            }
            setIrregularityCauseToRegulationMap(causeMap);
            setLoading(false);
        } catch (error) {
            setError(error);
        }
    };

    useEffect(() => {
        fetchData();
    }, [modalSubmitted, response]);


    if (loading) {
        return <Loading/>;
    }


    async function submitResponse() {
        irregularity.response = reason;
        try {
            if (formData === null) {
                await responseIrregularity(candidatureExternalId, irregularity.externalId, irregularity)
            } else {
                formData.set('irregularity', JSON.stringify(irregularity));
                await responseIrregularityWithDocument(candidatureExternalId, irregularity.externalId, formData);
            }

            setLsItem("responseIrregularity");
            setResponse(true);
        } catch (error) {
            console.log(error);
            setErrors(error);
        }
    }


    function changeResponse(e) {
        setReason(e.target.value);
    }




    return (
        <SubTemplate titleId='candidatureTabs.oversight.tabs.irregularity' hasBackButton={true}>
            <Container>
                <Row>
                    <AlertError error={error}/>
                    <AlertSuccess lsItem={lsItem}/>
                </Row>
                <Row>
                    <Col>
                        <IrregularityDecisionModal
                            candidatureExternalId={candidatureExternalId}
                            irregularity={irregularity}
                            show={decisionModalShow}
                            setShow={setDecisionModalShow}
                            onSubmitCallback={() => setModalSubmitted(true)}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col md='9' className='border-bottom py-3 mb-5'>
                        <Row>
                            <Col md='4'>
                                <label>
                                    <FormattedMessage
                                        id='candidatureTabs.oversight.tabs.irregularities.leadsToAidSuspension'/>
                                </label>
                                {irregularity.leadsToAidSuspension ? <FormattedMessage id='all.yes'/> :
                                    <FormattedMessage id='all.no'/>}
                            </Col>
                            <Col md='4'>
                                <label>
                                    <FormattedMessage id='candidatureTabs.oversight.tabs.irregularities.deadline'/>
                                </label>
                                {irregularity.deadline}
                            </Col>
                            <Col md='4'>
                                <label>
                                    <FormattedMessage id='candidatureTabs.oversight.tabs.irregularities.fixed'/>
                                </label>
                                <FormattedMessage
                                    id={'candidatureTabs.oversight.tabs.irregularities.state.' + irregularity.state}/>
                            </Col>
                        </Row>

                        {'document' in irregularity && (
                            <Row>
                                <label>
                                    <FormattedMessage id='documents.list'/>
                                </label>
                                <Col>
                                    <label><FormattedMessage id={'documentType.' + irregularity.document.documentType}/></label>
                                    <AuthenticatedDownloadLink
                                        title={irregularity.document.name} className='payment-entry-document-link'
                                        url={irregularity.document.documentPath}
                                        filename={irregularity.document.name}
                                    >
                                        {irregularity.document.name}
                                    </AuthenticatedDownloadLink>
                                </Col>
                            </Row>
                        )}


                        <Row>
                            <Col>
                                <label>
                                    <FormattedMessage id='candidatureTabs.oversight.tabs.irregularities.causes'/>
                                </label>
                                <ul>
                                    {irregularity.causes.map(c => (
                                        <li key={c.toString()}>
                                            <FormattedMessage
                                                id={'candidatureTabs.oversight.tabs.irregularities.cause.' + c}/>
                                        </li>
                                    ))}
                                </ul>
                            </Col>
                        </Row>

                        {((isPromoterUser() && (irregularity.hasResponse || irregularity.state === 'NOT_FIXED')) || (isIEFPUser() && irregularity.hasResponse)) &&
                        <>
                            <Row>
                                <Col md={12}>
                                    <h5 className='text-secondary mt-4'>
                                        <FormattedMessage id={`irregularity.response.section.title`}/>
                                    </h5>

                                </Col>

                            </Row>

                            {(isPromoterUser() && !irregularity.hasResponse && irregularity.state === 'NOT_FIXED') &&
                            <Row>
                                <Col>

                                    <p className='text-secondary infoText'>
                                        <FaInfoCircle size={30}/>

                                        <FormattedMessage id='irregularity.reason.info'/>
                                    </p>

                                </Col>

                            </Row>
                            }
                            {((!irregularity.hasResponse && irregularity.state === 'NOT_FIXED') || irregularity.hasResponse) &&
                            <Row>
                                <Col md='12'>
                                    <TextAreaInputField
                                        name='reason'
                                        value={reason}
                                        handleChange={(e) => changeResponse(e)}
                                        labelId='irregularity.response'
                                        isInvalid={errors.response}
                                        errorMessage={errors.response}
                                        disabled={irregularity.hasResponse}
                                    />
                                </Col>
                            </Row>
                            }
                            {(irregularity.hasResponse === false && isPromoterUser() && irregularity.state === 'NOT_FIXED') &&
                            <Row>
                                <Col md='12'>
                                    <SingleUploadArea
                                        documents={documents}
                                        setDocuments={setDocuments}
                                        documentType={'IRREGULARITY_RESPONSE'}
                                        setError={setError}
                                        acceptExtensions='.pdf,.docx'
                                        processUploadCallback={formDataIn => setFormData(formDataIn)}
                                        processDeleteCallback={() => setFormData(null)}
                                    />
                                </Col>


                            </Row>

                            }
                            {'responseDocument' in irregularity && (
                                <Row className={'mt-3 mb-5'}>
                                    <Col>
                                        <label><FormattedMessage
                                            id={'documentType.' + irregularity.responseDocument.documentType}/></label>
                                        <AuthenticatedDownloadLink
                                            title={irregularity.responseDocument.name}
                                            className='payment-entry-document-link'
                                            url={irregularity.responseDocument.documentPath}
                                            filename={irregularity.responseDocument.name}
                                        >
                                            {irregularity.responseDocument.name}
                                        </AuthenticatedDownloadLink>
                                    </Col>
                                </Row>
                            )}



                        </>

                        }


                    </Col>
                    <Col md='3'>
                        <div className='px-2 mb-5'>
                            <div className='d-grid gap-4'>
                                {hasPermission('MANAGE_IRREGULARITY', accessInfo) && irregularity.state !== IrregularityState.FIXED && (
                                    <Button variant='outline-primary' onClick={() => setDecisionModalShow(true)}>
                                        <FormattedMessage id='candidatureTabs.oversight.tabs.irregularities.decide'/>
                                    </Button>
                                )}
                                {(irregularity.hasResponse === false && isPromoterUser() && irregularity.state === 'NOT_FIXED') &&
                                        <Button variant='outline-primary' onClick={() => submitResponse()}
                                                disabled={reason.trim().length === 0}>
                                            <FormattedMessage
                                                id='irregularity.response.action'/>
                                        </Button>
                                }
                            </div>
                        </div>
                    </Col>
                </Row>

            </Container>
        </SubTemplate>
    )
}
