export const EntityChangeKind = {
    EA:'EA',
    IEFP:'IEFP',
    FINAL:'FINAL',


  values: () => {
    return ['EA', 'IEFP','FINAL'];

  }
};
