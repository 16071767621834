export const AdditionalActivityControlType = {
  BOOTCAMP: 'BOOTCAMP',
  DISCLOSURE: 'DISCLOSURE',
  DIDACTIC: 'DIDACTIC',
  INCUBATION_PROJECTS: 'INCUBATION_PROJECTS',
  
  values: () => {
    return [
      'BOOTCAMP',
      'DISCLOSURE',
      'DIDACTIC',
      'INCUBATION_PROJECTS'
    ];
  },
};