import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import {Link, useNavigate, useParams} from 'react-router-dom';
import { AlertError } from '../../../components/bootstrap/AlertError';
import { AlertSuccess } from '../../../components/bootstrap/AlertSuccess';
import { EnumSelectField } from '../../../components/bootstrap/EnumSelectField';
import { LabelToolTip } from '../../../components/bootstrap/LabelToolTip';
import { CustomPagination } from '../../../components/general/CustomPagination';
import { EmptyResults } from '../../../components/general/EmptyResults';
import { ListFilterBar } from '../../../components/general/ListFilterBar';
import Loading from '../../../components/general/Loading';
import { SubTemplate } from '../../../components/general/SubTemplate';
import { ProcessState } from '../../../models/ProcessState';
import { Arrays } from '../../../utils/Arrays';
import { handleError, isNotBusinessError } from '../../../utils/handleError';
import {canCreateSemestralReport, listSemestralReports} from '../../../rest/semestralReport';
import {isEntityUser, isIEFPUser} from "../../../authentication/authenticationHelper";
import ToolTip from "../../../components/bootstrap/ToolTip";
import {formatValue} from "react-currency-input-field";
import {ConfigObject} from "../../../config";
import { FaInfoCircle } from 'react-icons/fa';
import {getPaymentRequestByConsultingRequest} from "../../../rest/consultingRequest";

const MAX_RESULTS = 10;
const DEFAULT_INITIAL_PAGE = 0;

export function ListPaymentRequest() {
	const {
		consultingRequestExternalId
	} = useParams();
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);


	const [formList, setFormList] = useState([]);







	const navigate = useNavigate();

	const intl = useIntl();


	async function fetchData() {
		try {
			const [{ data: formList }] = await Promise.all([
				await getPaymentRequestByConsultingRequest(
					consultingRequestExternalId
				)
			]);
			setFormList(formList);





		} catch (error) {
			setError(error);
		} finally {
			setLoading(false);
		}
	}

	useEffect(() => {
		fetchData();
	}, [consultingRequestExternalId]);

	if (loading) {
		return <Loading />;
	}

	if (error && isNotBusinessError(error)) {
		return handleError(error);
	}

	function redirectToFormPage(formExternalId) {
		console.log(formExternalId)

		if(formExternalId=== undefined){
			navigate(
				`/pedidosMCE/${consultingRequestExternalId}/relatorioSemestral`
			);
		}else{
			navigate(
				`/relatorioSemestral/${formExternalId}`
			);
		}

	}

    function getPaymentRequestURL(form) {
        if(form == null || form.last == null) {
            console.log("From with null last property. Check this ListPaymentRequest.js");
            return '/';
        }
        return form.last
            ? `/relatorioSemestral/${form.reportExternalId}/pagamentoFinal`
            : `/relatorioSemestral/${form.reportExternalId}/pagamento`;

    }


	console.log(formList);

	return (
		<SubTemplate titleId={'paymentRequest.list.title'} hasBackButton>
			<Container>
				<Row className='searchResults'>
					<Col md='10'>


						{Arrays.isNotEmpty(formList) ? (
							<>
									{formList.map((form, index) => (
											<Row key={form.reportExternalId}
												className='py-3 notPointer border-1 border-top'
											>
												<Col md={6}>
													{form.year=== undefined ? (
															<>
															<div><FormattedMessage id='final.report.toPaymentList'/></div>

																	<Link to={'/pedidosMCE/'+consultingRequestExternalId+'/relatorioFinal'}>
																			<FormattedMessage id='watch.report' />
																	</Link>

															</>

													) :
														(
															<>
																<div><FormattedMessage id='ano.semester' values={{year: form.year,semester:form.semester}} /></div>
																<Link to={'/relatorioSemestral/'+form.reportExternalId}>
																		<FormattedMessage id='watch.report' />
																</Link>

															</>
														)}

												</Col>
												<Col md={6} className={'justify-content-end align-items-center d-flex'}>
													{
														isEntityUser() ? (
															<>
																{form.paymentProcessState===undefined ?(
																	<Link to={ getPaymentRequestURL(form) }>
																			<FormattedMessage id='payment.create.report' />
																	</Link>
																): (
																	<div className={'d-block text-end'}>
																		{form.paymentProcessState!==ProcessState.DRAFT &&  <div className={'text-secondary'}><FormattedMessage id={'processState.'+form.paymentProcessState}	/> - <FormattedMessage id={'changeAt'}	/> {form.paymentRequestDate}</div>}
																	<Link to={ getPaymentRequestURL(form) }>
																			<FormattedMessage id={'payment.report.'+form.paymentProcessState} />
																	</Link>
																		</div>
																)}
															</>




															)
															:
															(
																<>
																{(form.paymentProcessState!==undefined && form.paymentProcessState!== ProcessState.DRAFT) ?
																(
																	<>
																		{form.paymentProcessState===ProcessState.SUBMITTED ?(
																			<Link to={ getPaymentRequestURL(form) }>
																					<FormattedMessage id='payment.approve.report' />
																			</Link>
																			):(
																				<div className={'d-block text-end'}>
																			{form.paymentProcessState!==ProcessState.DRAFT &&  <div className={'text-secondary'}><FormattedMessage id={'processState.'+form.paymentProcessState}	/> - <FormattedMessage id={'changeAt'}	/> {form.paymentRequestDate}</div>}
																			<Link to={ getPaymentRequestURL(form) }>
																					<FormattedMessage id={'payment.report.'+form.paymentProcessState} />
																			</Link>
																					</div>
																		)}


																	</>
																):(
																	<>
																		<FormattedMessage id={'no.payment.report'} />
																		</>
																	)}

																</>
															)



													}

												</Col>

											</Row>
									))}
							</>
						) : (
							<EmptyResults message={'paymentRequest.no.reports.info'}/>
						)}
					</Col>



				</Row>
			</Container>
		</SubTemplate>
	);
}
