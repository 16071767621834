import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { DateTimeInputField } from '../bootstrap/DateTimeInputField';
import { TextAreaInputField } from '../bootstrap/TextAreaInputField';
import { TextInputField } from '../bootstrap/TextInputField';
import { isIEFPUser } from '../../authentication/authenticationHelper';

export function EconomicViabilityFormStep8({
  handleChange,
  setFieldValue,
  errors,
  values,
  error,
  setError,
  numberOfSteps,
  handleSave,
  handleGoBack
}) {
  const intl = useIntl();

  return (
    <div>
      <h4 className='text-primary mb-0'>
        <FormattedMessage id='economicViabilityForm.overallLookTab' />
      </h4>
      <small className='text-secondary text-uppercase d-block'>
        {intl.formatMessage(
          { id: 'all.step' },
          { step: values.step, numberOfSteps: numberOfSteps }
        )}
      </small>

      <Row>
        <small className='text-primary text-uppercase mt-4 mb-4'>
          <FormattedMessage id='economicViabilityFormStep7.globalOpinion' />
        </small>
        <TextAreaInputField
          name={'globalOpinion'}
          value={values.globalOpinion}
          handleChange={handleChange}
          isInvalid={errors.globalOpinion}
          errorMessage={errors.globalOpinion}
        />
      </Row>

      <Row>
        <Col md='6'>
          <TextInputField
            name={'technicianName'}
            labelId='economicViabilityFormStep7.technicianName'
            placeholder={intl.formatMessage({
              id: 'economicViabilityFormStep7.technicianName.placeHolder'
            })}
            value={values.technicianName}
            handleChange={handleChange}
            isInvalid={errors.technicianName}
            errorMessage={errors.technicianName}
          />
        </Col>
      </Row>

      {isIEFPUser() && (
        <Row>
          <Col md='4'>
            <DateTimeInputField
              labelId='economicViabilityFormStep7.creationDate'
              name='candidatureDate'
              value={values.candidatureDate}
              setFieldValue={setFieldValue}
              isDateWithNoTime={true}
            />
          </Col>
        </Row>
      )}
    </div>
  );
}
