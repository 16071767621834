import React, { useEffect, useState } from 'react';
import { Accordion, Col, Form, Row, Table } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { Arrays } from '../../utils/Arrays';
import { EnumSelectField } from '../bootstrap/EnumSelectField';
import { CandidatureFinancialUtils } from '../../utils/CandidatureFinancialUtils';

import { DateUtils } from '../../utils/DateUtils';
import { currencyFormat, maxFixed } from '../../utils/CurrencyUtils';
import { AccretionViewer } from '../candidature/AccretionViewer';
import { PromoterType } from '../../models/PromoterType';
import { TimeType } from '../../models/TimeType';
import { CandidatureEmployeeSupportUtils } from '../../utils/CandidatureEmployeeSupportUtils';
import { EmptyResults } from '../general/EmptyResults';

export function FinancialTab({ candidatureChange }) {

	const [accretionResults, setAccretionResults] = useState(null);
	const intl = useIntl();

	

	useEffect(() => {
		if(candidatureChange.financialChanges === true){
			fetchDataAccretion();
		}
	}, []);

	if(candidatureChange.financialChanges !== true){
		return <EmptyResults />;
	}
	
const employeeDetails = getInitialValuesEmployeeDetails();
	const employeeOtherDetails = candidatureChange.employeeSupportOtherDetails;

	function fetchDataAccretion() {
		let result = [];
		let canditureChangeESODEtails =
			candidatureChange.employeeSupportOtherDetails;

		if (canditureChangeESODEtails.length > 0) {
			for (const detail of canditureChangeESODEtails) {
				let valueToPush = {};
				valueToPush['kind'] = detail.kind;
				let candidatureEmployeeSupportOtherDetailsArray = [];
				candidatureEmployeeSupportOtherDetailsArray.push(detail);

				const economicDetails =
					candidatureChange.economicViabilityAccretionValidation;
				valueToPush['subRepresentation'] =
					economicDetails.isSubRepresentationValid === true;
				valueToPush['isInside'] = economicDetails.isInsideValid === true;
				valueToPush['levelsAccretion'] =
					economicDetails.isLevelsValid === true
						? economicDetails.levelsValue
						: 0;

				valueToPush['iefpAccretion'] =
					economicDetails.isIefpWorkstationsValid === true
						? economicDetails.iefpWorkstationsValue
						: 0;
				valueToPush['iSInnovationProject'] =
					economicDetails.isInnovationProjectValid === true;

				const accretionTotal = CandidatureFinancialUtils.getAccretionTotal(
					valueToPush['subRepresentation'],
					valueToPush['isInside'],
					valueToPush['levelsAccretion'],
					valueToPush['iefpAccretion'],
					{ iSInnovationProject: valueToPush['iSInnovationProject'] }
				);
				valueToPush['accretionTotal'] = accretionTotal;

				let investment = candidatureChange.financialInvestmentDetails.filter(
					(investment) =>
						investment.kind === detail.kind &&
						investment.financialInvestmentType == 'TOTAL'
				);
				let plan = candidatureChange.investmentPlanDetails.filter(
					(plan) => plan.kind === detail.kind && plan.investmentType == 'TOTAL'
				);

				if (investment?.length !== 0) {
					valueToPush['investment'] = investment[0];
					valueToPush['plan'] = plan[0];
				}
				result.push(valueToPush);
			}
		}
		setAccretionResults(result);
	}

	function getInitialValuesEmployeeDetails() {
		let values = [];
		console.log(candidatureChange);
		let promotersFiltered = candidatureChange.promotersMetaData.filter(
			(p) =>
				p.promoterType === PromoterType.PRIMARY ||
				p.promoterType === PromoterType.SECONDARY
		);
		candidatureChange.employeeSupportDetails.forEach((detail) => {
			let valueToPush = {};

			valueToPush = {
				externalId: detail.externalId,
				internalId: detail.internalId,
				kind: detail.kind,
				promoterMetaData: promotersFiltered.find(
					(p) => p.externalId === detail.promoterMetaData
				).name,
				timeType: detail.timeType,
				hours: detail.hours,
			};

			let totalSubsidyValue =
				detail.timeType === 'TOTAL'
					? 15 * detail.IAS
					: (parseFloat(15 * detail.IAS) * parseInt(detail.hours)) / 40;

            valueToPush.totalSubsidy = parseFloat(totalSubsidyValue.toFixed(2));
            console.log(detail.IAS)

			values.push(valueToPush);
		});

		return values;
	}

	function displayDetails() {
		if (accretionResults === null) return '';
		return (
			<div>
				{accretionResults.map((detail, index) => (
					<div key={index}>
						<label className='mx-3'>
							<FormattedMessage
								id={'candidatureForm.accretion.subRepresentation'}
							/>
						</label>
						<p className='mx-3'>
							<strong>
								{detail.subRepresentation === true ? (
									intl.formatMessage(
										{ id: 'accretion.with.value' },
										{ value: 15 }
									)
								) : (
									<FormattedMessage id={'accretion.without'} />
								)}
							</strong>
						</p>

						<label className='mx-3'>
							<FormattedMessage
								id={'candidatureForm.accretion.isInnovationProject'}
							/>
						</label>
						<p className='mx-3'>
							{detail.iSInnovationProject === true ? (
								<>
									<strong>
										<FormattedMessage
											id={'accretion.with.value'}
											values={{ value: '15' }}
										/>
									</strong>
									{detail.reasonToInnovationProject !== undefined &&
										' - ' + detail.reasonToInnovationProject}{' '}
								</>
							) : (
								<FormattedMessage id={'accretion.without'} />
							)}
						</p>

						<label className='mx-3'>
							<FormattedMessage id={'candidatureForm.accretion.levels'} />
						</label>
						<p className='mx-3'>
							<strong>
								{detail.levelsAccretion !== 0 ? (
									intl.formatMessage(
										{ id: 'accretion.with.value' },
										{ value: detail.levelsAccretion }
									)
								) : (
									<FormattedMessage id={'accretion.without'} />
								)}
							</strong>
						</p>
						<label className='mx-3'>
							<FormattedMessage id={'candidatureForm.accretion.isInside'} />
						</label>
						<p className='mx-3'>
							<strong>
								{detail.isInside === true ? (
									intl.formatMessage(
										{ id: 'accretion.with.value' },
										{ value: 25 }
									)
								) : (
									<FormattedMessage id={'accretion.without'} />
								)}
							</strong>
						</p>
						<label className='mx-3'>
							<FormattedMessage
								id={'candidatureForm.accretion.iefpAccretion'}
							/>
						</label>
						<p className='mx-3'>
							<strong>
								{detail.iefpAccretion !== 0 ? (
									intl.formatMessage(
										{ id: 'accretion.with.value' },
										{ value: detail.iefpAccretion }
									)
								) : (
									<FormattedMessage id={'accretion.without'} />
								)}
							</strong>
						</p>

						<div className='mx-3 '>
							<AccretionViewer
								investment={detail.investment}
								plan={detail.plan}
								accretionTotal={detail.accretionTotal}
								index={index}
							/>
						</div>
					</div>
				))}
			</div>
		);
	}

	function displayDetailsWorkStations(arr, otherArr) {
		if (arr.length === 0 && otherArr.length === 0) {
			return '';
		}
		return (
			<div className='mb-5 px-3'>
				{arr.map((detail, index) => (
					<div key={index} className='border-bottom'>
						{index === 0 && (
							<h5 className='text-secondary mt-4'>
								<FormattedMessage id={`candidatureForm.workStations.ownJobs`} />
							</h5>
						)}

						<p className='mt-4 mb-3'>Posto de trabalho {index + 1}</p>

						<Row>
							<Col md='6'>
								<label>
									<FormattedMessage id='workStations.promoter' />
								</label>
								<p>{detail.promoterMetaData}</p>
							</Col>
							<Col md={detail.timeType !== TimeType.TOTAL ? '3' : '6'}>
								<label>
									<FormattedMessage id='workStations.timeType' />
								</label>
								<p>
									<FormattedMessage
										id={`workStations.timeType.${detail.timeType}`}
									/>
								</p>
							</Col>
							{detail.timeType !== TimeType.TOTAL && (
								<Col md='3'>
									<label>
										<FormattedMessage id='workStations.hours' />
									</label>
									<p>{detail.hours}</p>
								</Col>
							)}
						</Row>

						{employeeDetails.length !== 0 && (
							<div className='d-flex justify-content-between align-items-center mb-2'>
								<p className='mb-0'>
									<span className='text-secondary'>Total: </span>{' '}
									{detail.totalSubsidy}€
								</p>
							</div>
						)}
					</div>
				))}
				{arr.length > 0 && (
					<p className='mt-3'>
						<span className='text-secondary'>
							<FormattedMessage id='candidatureTabs.totalJobSubsidyValue' />
						</span>
						{CandidatureEmployeeSupportUtils.getTotalJobSubsidyValue(arr)}€
					</p>
				)}

				{otherArr.map((detail, index) => (
					<React.Fragment key={index}>
						{index === 0 && (
							<h5 className='text-secondary mt-4'>
								<FormattedMessage
									id={`candidatureForm.workStations.otherJobs`}
								/>
							</h5>
						)}

						<Row>
							<Col md='4'>
								<label>
									<FormattedMessage id='workStations.level5to7' />
								</label>
								<p>{detail.level5to7}</p>
							</Col>
							<Col md='4'>
								<label>
									<FormattedMessage id='workStations.level8' />
								</label>
								<p>{detail.level8}</p>
							</Col>
							<Col md='4'>
								<label>
									<FormattedMessage id='workStations.jobsOnIEFP' />
								</label>
								<p>{detail.jobsOnIEFP}</p>
							</Col>
						</Row>
						<Row>
							<Col md={'4'}>
								<label>
									<FormattedMessage id='workStations.newJobs' />
								</label>
								<p>{detail.newJobs}</p>
							</Col>
							{candidatureChange.candidatureDetails.wasCreated === true && (
								<Col md={'4'}>
									<label>
										<FormattedMessage id='workStations.oldJobs' />
									</label>
									<p>{detail.oldJobs}</p>
								</Col>
							)}
						</Row>
					</React.Fragment>
				))}
			</div>
		);
	}

	const CURRENT_YEAR = DateUtils.removeDays(
		new Date(candidatureChange.candidatureDetails.forecastDate),
		15
	).getFullYear();
	const NEXT_YEAR = CURRENT_YEAR + 1;


	function getInvestmentValueByKey(values, key, column) {
		let investments = values.filter((value) => {
			if (column === 'Total') {
				return value.investmentType === 'TOTAL';
			} else {
				return value.year === parseInt(column);
			}
		});
		return investments.length > 0 ? currencyFormat(investments[0][key]) : 0;
	}

	function getFinancialInvestmentValueByKey(values, key, column) {
		let financials = values.filter((value) => {
			if (column === 'Total') {
				return value.financialInvestmentType === 'TOTAL';
			} else {
				return value.year === parseInt(column);
			}
		});
		return financials.length > 0 ? currencyFormat(financials[0][key]) : '0.0€ ';
	}

	function getInvestmentDetailsTableRowsByKey(key) {
		const values = candidatureChange.investmentPlanDetails;

		return (
			<tr key={key}>
				<td
					className={`${
						INVESTMENT_PLAN_DETAILS_BOLD_KEYS.includes(key) && 'table-item-bold'
					}`}
				>
					<FormattedMessage id={`economicViabilityFormStep3.${key}`} />
				</td>
				<td className='w-20 text-end'>
					<span>{getInvestmentValueByKey(values, key, 0)}</span>
				</td>
				<td className='w-20 text-end'>
					<span>{getInvestmentValueByKey(values, key, 1)}</span>
				</td>
				<td className='w-20 text-end'>
					<span>{getInvestmentValueByKey(values, key, 'Total')}</span>
				</td>
			</tr>
		);
	}

	function getFinancialInvestmentDetailsTableRowsByKey(key) {
		const values = candidatureChange.financialInvestmentDetails;

		return (
			<tr key={key}>
				<td
					className={`${
						FINANCIAL_INVESTMENT_DETAILS_BOLD_KEYS.includes(key) &&
						'table-item-bold'
					}`}
				>
					<FormattedMessage id={`economicViabilityFormStep4.${key}`} />
				</td>
				<td className='w-20 text-end'>
					<span>{getFinancialInvestmentValueByKey(values, key, 0)}</span>
				</td>
				<td className='w-20 text-end'>
					<span>{getFinancialInvestmentValueByKey(values, key, 1)}</span>
				</td>
				<td className='w-20 text-end'>
					<span>{getFinancialInvestmentValueByKey(values, key, 'Total')}</span>
				</td>
			</tr>
		);
	}

	function getInvestmentPlanTableHeads() {
		return (
			<tr>
				<th>
					<FormattedMessage id='candidatureTabs.investment' />
				</th>
				<th className='w-20 text-end'>{CURRENT_YEAR}</th>
				<th className='w-20 text-end'>{NEXT_YEAR}</th>
				<th className='w-20 text-end'>TOTAL</th>
			</tr>
		);
	}

	return candidatureChange.financialChanges === true ? (
		<Accordion defaultActiveKey='0' alwaysOpen>
			<Accordion.Item eventKey='0'>
				<Accordion.Header>
					<FormattedMessage id='economicViabilityForm.investmentPlanTab' />
				</Accordion.Header>
				<Accordion.Body>
					<Table className='mt-4 d-table'>
						<thead>{getInvestmentPlanTableHeads('investmentPlan')}</thead>
						<tbody>
							{INVESTMENT_PLAN_DETAILS_KEYS.map((key) =>
								getInvestmentDetailsTableRowsByKey(key)
							)}
						</tbody>
					</Table>
				</Accordion.Body>
			</Accordion.Item>
			<Accordion.Item eventKey='1'>
				<Accordion.Header>
					<FormattedMessage id='economicViabilityForm.financialInvestmentTab' />
				</Accordion.Header>
				<Accordion.Body>
					<Table className='mt-4'>
						<thead>{getInvestmentPlanTableHeads('financialInvestment')}</thead>
						<tbody>
							{FINANCIAL_INVESTMENT_DETAILS_KEYS.map((key) =>
								getFinancialInvestmentDetailsTableRowsByKey(key)
							)}
						</tbody>
					</Table>
				</Accordion.Body>
			</Accordion.Item>
			<Accordion.Item eventKey='2'>
				<Accordion.Header>
					<FormattedMessage id='accretion.previsions' />
				</Accordion.Header>
				<Accordion.Body>{displayDetails()}</Accordion.Body>
			</Accordion.Item>
			<Accordion.Item eventKey='3'>
				<Accordion.Header>
					<FormattedMessage id='candidatureTabs.workStations' />
				</Accordion.Header>
				<Accordion.Body>
					{displayDetailsWorkStations(employeeDetails, employeeOtherDetails)}
				</Accordion.Body>
			</Accordion.Item>
		</Accordion>
	) : (
		<EmptyResults />
	);
}

const INVESTMENT_PLAN_DETAILS_KEYS = [
	'investmentProperties',
	'investmentPropertiesNaturalResources',
	'investmentPropertiesBuildings',
	'investmentPropertiesOthers',
	'tangibleAssets',
	'tangibleNaturalResources',
	'tangibleBuildings',
	'tangibleBasicEquipment',
	'tangibleTransportEquipment',
	'tangibleAdministrativeEquipment',
	'tangibleBiologicEquipment',
	'tangibleOthers',
	'intangibleAssets',
	'intangibleGoodwill',
	'intangibleDevelopmentProjects',
	'intangibleComputerPrograms',
	'intangibleIntellectualProperty',
	'intangibleOthers',
	'several',
	'pettyCash',
	'globalTotal',
];

const INVESTMENT_PLAN_DETAILS_BOLD_KEYS = [
	'investmentProperties',
	'tangibleAssets',
	'intangibleAssets',
	'several',
	'pettyCash',
	'globalTotal',
];

const FINANCIAL_INVESTMENT_DETAILS_KEYS = [
	'ownCapital',
	'ownSocialCapital',
	'ownCapitalUnemploymentBenefits',
	'ownCapitalOthers',
	'foreignCapital',
	'foreignCapitalBankLoans',
	'foreignCapitalPartnerLoans',
	'foreignCapitalProvidersLoans',
	'foreignCapitalOthers',
	'iefpAskedAmount',
	'investmentSupport',
	'workStationsCreationBenefits',
	'globalTotal',
];

const FINANCIAL_INVESTMENT_DETAILS_BOLD_KEYS = [
	'ownCapital',
	'foreignCapital',
	'iefpAskedAmount',
	'globalTotal',
];
