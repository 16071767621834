import {FormattedMessage} from "react-intl";
import React from "react";
import {useNavigate} from "react-router-dom";
import {CondFmtNavButton, ConditionalButton} from "../../general/ConditionalButton";
import {EntityState} from "../../../models/EntityState";
import {AccreditationDecisionState} from "../../../models/AccreditationDecisionState";
import {getUserInfo, isIEFPUser} from "../../../authentication/authenticationHelper";
import {UserType} from "../../../models/UserType";

export function DetailEntityAccreditationMenu({accreditation}) {
    const {entity, canRenew, renewalOverview} = accreditation || {}
    const {nif: nipc, state, revoked} = entity || {};
    const decision = accreditation?.accreditationDecisions?.[accreditation?.accreditationDecisions?.length - 1];
    const userInfo = getUserInfo();

    const isIEFP = isIEFPUser();
    const regionalDelegationCode = isIEFP && userInfo?.rd;
    const isAccredited = state === EntityState.FULLY_APPROVED && (decision == null || decision?.state === AccreditationDecisionState.ACCEPTED);
    const isMainDelegation = isIEFP && ((decision != null && decision?.mainRegionalDelegationCode === regionalDelegationCode) || (entity?.regionalDelegationCode === regionalDelegationCode));
    const isSelf = userInfo?.userType === UserType.ENTITY && userInfo.nif === nipc;
    const hasRenewal = renewalOverview != null && renewalOverview?.state !== 'DRAFT'; //Need to check this here, to make sure other people can see their renewal.

    return (
        <>
            <CondFmtNavButton condition={true} id={'eaAccreditation.A1Form.Button'} to={`/credenciacaoEntidade/adicionar/${nipc}`}/>
            <CondFmtNavButton condition={isAccredited} id={'ea.accreditation.revocation.button'} to={`/revogacoesEA/${nipc}`}/>
            <CondFmtNavButton condition={isAccredited} id={'entityChange.button'} to={`/alteracoesEntidade/${nipc}`}/>
            <CondFmtNavButton
                condition={isAccredited && (isMainDelegation && !revoked && canRenew) || (isSelf && !revoked && hasRenewal)}
                id={'ea.accreditation.renewal.button'}
                to={`/credenciacaoEntidade/renovar/${nipc}`}
            />
        </>
    );
}
