import { apiAxios as authAxios } from '../axios.config';

export const getDefaultProjectIdentification = (queryparams) => {
    return authAxios.get('/api/projectIdentificationForm',{params:  queryparams})
}

export const getDefaultProjectIdentificationList = () => {
    return authAxios.get('/api/projectIdentificationForm/list')
}


export const getDefaultProjectIdentificationExternalId = (b6ExternalId) => {
    return authAxios.get(`/api/projectIdentificationForm/${b6ExternalId}`)
}

export const getDefaultProjectIdentificationWithdrawal = (b6ExternalId) => {
    return authAxios.patch(`/api/projectIdentificationForm/${b6ExternalId}/withdrawal`)
}

export const getProjectIdentificationProcessExternalId = (b6ProcessExternalId) => {
    return authAxios.get(`/api/projectIdentificationForm/process/${b6ProcessExternalId}`)
}

export const saveProjectIdentification = (projectIdentification,queryParams) => {
    return authAxios.post(`/api/projectIdentificationForm`, projectIdentification, queryParams ? {params: queryParams}: null)
}

export const validateProjectIdentification = (processId,queryParams) => {
    return authAxios.post(`/api/projectIdentificationForm/form/${processId}`, {}, queryParams ? {params: queryParams}: null)
}