import React, { useEffect, useState } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { AlertError } from '../../../components/bootstrap/AlertError';
import Loading from '../../../components/general/Loading';
import { SubTemplate } from '../../../components/general/SubTemplate';
import { ParameterForm } from '../../../components/parameter/ParameterForm';
import {
  getParameterByExternalId,
  updateParameter,
} from '../../../rest/parameter';
import { handleError, isNotBusinessError } from '../../../utils/handleError';

export function EditParameter() {
  const { externalId } = useParams();

  const [parameter, setParameter] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [submited, setSubmited] = useState(false);

  const fetchData = async () => {
    try {
      const { data } = await getParameterByExternalId(externalId);
      setParameter(data);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const editParameter = async (values, setSubmitting) => {
    setLoading(true);
    try {
      await updateParameter(externalId, values);
      setSubmited(true);
      setSubmitting(false);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = (values, setSubmitting) => {
    setSubmitting(true);
    editParameter(values, setSubmitting);
  };

  if (loading) {
    return <Loading />;
  }

  if (error && isNotBusinessError(error)) {
    return handleError(error);
  }

  if (submited) {
    return <Navigate to='/parametros' />;
  }

  return (
    <SubTemplate hasBackButton titleId={'parameter.edit'}>
      <div className={'container'}>
        <div className={'row'}>
          <div className={'col-lg-8 offset-lg-2'}>
            <AlertError error={error} />
            <ParameterForm
              parameter={parameter}
              onSubmit={handleSubmit}
              isUpdate={true}
            />
          </div>
        </div>
      </div>
    </SubTemplate>
  );
}
