import React from 'react'
import { Modal } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

export function PriorSuppotSubmissionModal({ show, handleClose, handleSubmit }) {
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Submeter apoio prévio</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          Após a submissão do pedido de apoio não vai poder escolher uma
          entidade diferente daquela que selecionou nesta fase, para lhe prestar
          apoio prévio.
        </p>
        <p>Tem a certeza que pretende continuar?</p>
        <div className='form-actions mt-5'>
          <button
            className='btn btn-outline-primary px-5'
            type='button'
            onClick={() => handleClose()}
          >
            <FormattedMessage id='all.no' />
          </button>

          <button
            className='btn btn-primary px-5'
            type='button'
            onClick={handleSubmit}
          >
            <FormattedMessage id='all.yes' />
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default PriorSuppotSubmissionModal