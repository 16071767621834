import {AlertError} from "../../../bootstrap/AlertError";
import {Formik} from "formik";
import {Col, Form, Row} from "react-bootstrap";
import {FormattedMessage} from "react-intl";
import React, {useState} from "react";
import * as yup from "yup";
import {useNavigate} from "react-router-dom";
import {decideIrregularity} from "../../../../rest/irregularities";
import {IrregularityState} from "./IrregularityState";

export function IrregularityDecisionForm({candidatureExternalId, irregularity, onCancel, onSubmitCallback}) {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [navErrors, setNavErrors] = useState([]);
    const navigate = useNavigate();

    const validationSchema = yup.object().shape({
        state: yup
            .mixed()
            .oneOf([IrregularityState.FIXED, IrregularityState.NOT_FIXED], <FormattedMessage id='errors.fieldRequiredText'/>)
            .required(<FormattedMessage id='errors.fieldRequiredText'/>),
    });

    async function onSubmit(values, setSubmitting, setErrors) {
        setSubmitting(true);
        const copyIrreg = {...irregularity}
        copyIrreg.state = values.state;
        try {
            await decideIrregularity(candidatureExternalId, irregularity.externalId, copyIrreg)
            onSubmitCallback?.()
            setSubmitted(true);
        } catch (error) {
            console.log(error);
            setErrors(error);
        }
        setSubmitting(false);
    }


    return (
        <>
            <AlertError error={error} />
            <Formik
                initialValues={{state: null}}
                validateOnBlur={false}
                validateOnChange={false}
                validationSchema={validationSchema}
                onSubmit={(values, { setSubmitting, setErrors }) => {
                    onSubmit(values, setSubmitting, setErrors);
                }}>
                {({ values,
                      errors,
                      touched,
                      handleChange,
                      handleSubmit,
                      setFieldValue,
                  }) => (
                    <Form onSubmit={handleSubmit}>
                        <AlertError error={errors} />
                        <Row>
                            <Col>
                                <label>
                                    <FormattedMessage id='candidatureTabs.oversight.tabs.irregularities.fixed' />
                                </label>
                                <Form.Check
                                    inline
                                    name={'state'}
                                    type={'radio'}
                                    label={<FormattedMessage id='all.yes' />}
                                    value={IrregularityState.FIXED}
                                    onChange={ ({target}) => setFieldValue('state', target.value)}
                                />
                                <Form.Check
                                    inline
                                    name={'state'}
                                    type={'radio'}
                                    label={<FormattedMessage id='all.no' />}
                                    value={IrregularityState.NOT_FIXED}
                                    onChange={ ({target}) => setFieldValue('state', target.value)}
                                />
                                <Form.Control.Feedback type='invalid'>
                                    {errors.state}
                                </Form.Control.Feedback>
                            </Col>
                        </Row>
                        <Row className='form-actions'>
                            <Col className='justify-content-between d-flex'>
                                <button className='btn btn-outline-primary' type='button' onClick={() => onCancel?.()} >
                                    <FormattedMessage id='all.cancel' />
                                </button>
                                <button className='btn btn-primary' type='submit' >
                                    <FormattedMessage id='all.submitButtonText' />
                                </button>
                            </Col>
                        </Row>
                    </Form>
                )}
            </Formik>
        </>
    );
}