import { Formik } from 'formik';
import React, { useState } from 'react';
import { Form, Modal, Row, Col, Toast, ToastContainer } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { handleError, isNotBusinessError } from '../../utils/handleError';
import { TextAreaInputField } from '../bootstrap/TextAreaInputField';
import { TextInputField } from '../bootstrap/TextInputField';
import * as yup from 'yup';
import { FaCheck } from 'react-icons/fa';
import {contactPromoter, contactPromoterWithFiles} from '../../rest/candidature';
import {createMultiPartWithFilesAndJsonObject, SyncMultipleUploadArea} from "../SyncMultipleUploadArea";
import {Arrays} from "../../utils/Arrays";
import {DocumentType} from "../../models/DocumentType";

export function EntityMessageToPromoterModal({
  show,
  setShow,
  externalId,
}) {
  const messageToSendStructure = { subject: '', body: '' };
  const [showSaveToast, setShowSaveToast] = useState(false);
  const intl = useIntl();
  const [error, setError] = useState(null);
  const [documents, setDocuments] = useState([]);

  async function handleSubmit(values, formikObj) {
    try {
      //set request body structure and its values
      let reqBody = { ...values };
      reqBody.title = reqBody.subject;
      delete reqBody.subject;

      const hasFiles = Arrays.isNotEmpty(documents);
      if(hasFiles) {
        const formData = createMultiPartWithFilesAndJsonObject(documents, reqBody, 'ReplyMessageDto');
        await contactPromoterWithFiles(externalId, formData);
      } else {
        await contactPromoter(externalId,reqBody);
      }

      formikObj.setSubmitting(false);
      setShowSaveToast(true);
      setShow(false);
    } catch (error) {
      setError(error);
    }
  }

  function handleClose() {
    setShow(false);
  }

  if (error && isNotBusinessError(error)) {
    return handleError(error);
  }

  const messagePriorSupportPromoterFormSchema = yup.object().shape({
    subject: yup
      .string()
      .required(<FormattedMessage id='errors.fieldRequiredText' />)
      .test(
        'isMessageSubjectLengthValid',
        <FormattedMessage id='errors.messagePriorSupportEntityForm.subject' />,
        (value) => value && value.length < 101
      ),
    body: yup
      .string()
      .required(<FormattedMessage id='errors.fieldRequiredText' />)
      .test(
        'isMessageBodyLengthValid',
        <FormattedMessage id='errors.messagePriorSupportEntityForm.body' />,
        (value) => value && value.length < 501
      ),
  });

  return (
    <div>
      <ToastContainer className='fixed-top'>
        <Toast
          autohide
          delay={3000}
          show={showSaveToast}
          onClose={() => setShowSaveToast(false)}
        >
          <Toast.Header>
            <strong className='me-auto text-primary'>
              <span className='mr-2'>
                <FaCheck />
              </span>
              <FormattedMessage id='message.success' />
            </strong>
          </Toast.Header>
        </Toast>
      </ToastContainer>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            <FormattedMessage id='candidatureForm.PriorSupportMessagePromoterModal.title' />
          </Modal.Title>
        </Modal.Header>
        <Formik
          initialValues={messageToSendStructure}
          validateOnBlur={false}
          validateOnChange={false}
          validationSchema={messagePriorSupportPromoterFormSchema}
          onSubmit={(values, formikObj) => {
            handleSubmit(values, formikObj);
          }}
        >
          {(formikProps) => (
            <Form onSubmit={formikProps.handleSubmit}>
              <Modal.Body>
                <Row>
                  <Col md='12'>
                    <TextInputField
                      placeholder={intl.formatMessage({
                        id: 'candidatureForm.PriorSupportMessageModal.messageForm.subject.placeholder',
                      })}
                      labelId={
                        'candidatureForm.PriorSupportMessageModal.messageForm.subject'
                      }
                      name={'subject'}
                      value={formikProps.values.subject}
                      isInvalid={
                        formikProps.errors.subject
                          ? formikProps.errors.subject
                          : null
                      }
                      errorMessage={
                        formikProps.errors.subject
                          ? formikProps.errors.subject
                          : null
                      }
                      handleChange={formikProps.handleChange}
                    />
                  </Col>
                  <Col md='12'>
                    <TextAreaInputField
                      placeholder={intl.formatMessage({
                        id: 'candidatureForm.PriorSupportMessageModal.messageForm.body.placeholder',
                      })}
                      labelId={
                        'candidatureForm.PriorSupportMessageModal.messageForm.body'
                      }
                      name={'body'}
                      value={formikProps.values.body}
                      isInvalid={
                        formikProps.errors.body ? formikProps.errors.body : null
                      }
                      errorMessage={
                        formikProps.errors.body ? formikProps.errors.body : null
                      }
                      handleChange={formikProps.handleChange}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <SyncMultipleUploadArea
                        name='files'
                        documents={documents}
                        setDocuments={setDocuments}
                        documentType={DocumentType.MESSAGE_ATTACHMENT}
                    />
                  </Col>
                </Row>
              </Modal.Body>
              <Modal.Footer className='d-flex justify-content-between'>
                <button
                  className={'btn btn-outline-primary px-5'}
                  onClick={handleClose}
                  type='button'
                >
                  <FormattedMessage id='all.cancel' />
                </button>
                <button className='btn btn-primary px-5' type='submit'>
                  <FormattedMessage id='all.send' />
                </button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </div>
  );
}
