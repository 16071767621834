import { ConfigObject } from '../config';
import { KeycloakObject } from '../keycloak';
import {UserState} from "../models/UserState";

const LS_ACCESS_INFO_KEY = 'access_info';
const LS_USER_INFO_KEY = 'user_info';

export const getAccessInfo = () => {
  const accessInfoStr = localStorage.getItem(LS_ACCESS_INFO_KEY);
  return accessInfoStr ? JSON.parse(accessInfoStr) : null;
};

export const getUserInfo = () => {
  const userInfoStr = localStorage.getItem(LS_USER_INFO_KEY);
  return userInfoStr ? JSON.parse(userInfoStr) : null;
};

export const getAccessToken = () => {
  const accessInfo = getAccessInfo();
  if (accessInfo) {
    return accessInfo.access_token;
  }
  return null;
};

export const getPermissions = () => {
  const accessInfo = getAccessInfo();
  if (accessInfo) {
    return accessInfo.permissions;
  }
};

export const isTokenExpired = () => {
  const accessInfo = getAccessInfo();

  if (!accessInfo) {
    return true;
  }

  if (
    accessInfo.type === 'keycloak' &&
    KeycloakObject.get().authenticated &&
    !KeycloakObject.get().isTokenExpired(1)
  ) {
    return false;
  }
  return true;
};

export const saveInStorageAccessInfo = (accessInfo) => {
  if (!accessInfo.refreshTs) {
    accessInfo.refreshTs = Date.now() + 1 * 60 * 1000;
  }

  localStorage.setItem(LS_ACCESS_INFO_KEY, JSON.stringify(accessInfo));
};

export const saveUserInfoInStorage = (userInfo) => {
  localStorage.setItem(LS_USER_INFO_KEY, JSON.stringify(userInfo));
};

export const hasPermission = (permission, accessInfo) => {
  if (!accessInfo || !accessInfo.permissions) {
    return false;
  }

  return accessInfo.permissions.includes(permission);
};

export const hasAnyPermission = (permissions) => {
  const accessInfo = getAccessInfo();
  if (!accessInfo) {
    return false;
  }
  const { permissions: userPermissions } = accessInfo;
  if (!userPermissions || userPermissions.length <= 0) {
    return false;
  }

  if (!permissions || permissions.length <= 0) {
    return true;
  }

  return permissions.some((p) => {
    return hasPermission(p, accessInfo);
  });
};

export const refreshToken = async () => {
  const accessInfo = getAccessInfo();
  if (!accessInfo) {
    throw Error('Cannot refresh token, unexisting user');
  }
  return handleKeycloakRefresh(accessInfo);
};

export const logout = async () => {
  const accessInfo = getAccessInfo();
  if (accessInfo) {
    //Remove the access info from the local storage
    localStorage.removeItem(LS_ACCESS_INFO_KEY);
    localStorage.removeItem(LS_USER_INFO_KEY);
    sessionStorage.clear();
    
    if (accessInfo.type === 'keycloak') {
      await KeycloakObject.get().logout({
        redirectUri: ConfigObject.get().baseUrl,
      });
    }
  }
};

export const cleanAccessInfo = () => {
  localStorage.removeItem(LS_ACCESS_INFO_KEY);
};

export const cleanUserInfo = () => {
  localStorage.removeItem(LS_USER_INFO_KEY);
};

const handleKeycloakRefresh = async () => {
  try {
    const keycloak = KeycloakObject.get();
    await keycloak.updateToken(60);
    constructAccessInfoFromKeycloak(keycloak);
    return getAccessInfo();
  } catch (error) {
    localStorage.removeItem(LS_ACCESS_INFO_KEY);
    throw error;
  }
};

export const constructAccessInfoFromKeycloak = (keycloak, permissions) => {
  return {
    type: 'keycloak',
    access_token: keycloak.token,
    permissions: permissions,
    name: (keycloak.tokenParsed!== undefined && keycloak.token!== undefined )  ?  keycloak.tokenParsed.fullName :getAccessInfo().name ,
    refresh_token: keycloak.refreshToken,
  };
};

export const isIEFPUser = () => {
  return KeycloakObject.get().realmAccess.roles.includes('IEFP');
};


export const isBackOfficeAdmin = () => {
  return KeycloakObject.get().realmAccess.roles.includes('BACKOFFICE_ADMIN');
};

export const isPromoterUser = () => {
  return KeycloakObject.get().realmAccess.roles.includes('PROMOTER');
};

export const isEntityUser = () => {
  return KeycloakObject.get().realmAccess.roles.includes('ENTITY');
};

export const hasRole = (role) => {
  return KeycloakObject.get().realmAccess.roles.includes(role);
}

export const isEntityAndUnaccredited = () => {
  return getUserInfo() != null && isEntityUser() && getUserInfo().userState === UserState.VALIDATE
}