import { apiAxios as authAxios } from '../axios.config';

export const getPromoterCandidature = (externalId) => {
    return authAxios.get(`/api/promoter_candidature/${externalId}`)
}

export const getPrimaryByCandidatureExternalId = (externalId) => {
  return authAxios.get(`/api/promoter_candidature/primary/candidature/${externalId}`);
};

export const getCanEditProfile = () => {
  return authAxios.get('api/promoter/canEditProfile');
}
