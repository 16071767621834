import React, {useState} from 'react';
import {Accordion, Col, Row} from 'react-bootstrap';
import {FormattedMessage, useIntl} from 'react-intl';
import {SellInformationViewer} from "./SellInformationViewer";
import {Arrays} from "../../utils/Arrays";
import {ProviderType} from "../../models/ProviderType";
import {PeopleType} from "../../models/PeopleType";
import {FunctionType} from "../../models/FunctionType";
import {ProviderInformationAccordionItem} from "./ProviderInformationAccordionItem";
import {AmortizationTopic} from "../../models/AmortizationTopic";

export function AmortizationExpensesAccordionItem({amortizationExpensesList, yearNames}) {
    const intl = useIntl();


    function getTransformInformation() {
        if (amortizationExpensesList?.length > 0) {
            let grouped = Arrays.groupBy(amortizationExpensesList[0].amortizationExpensesLines, aExpenses => aExpenses.topic);
            return grouped;
        }
        return null;
    }


    function getAmortizationTotals() {

        if (amortizationExpensesList?.length > 0) {


            let grouped = Arrays.groupBy(amortizationExpensesList[0].amortizationExpensesLines, aExpense => aExpense.topic);
            let finalGrouped = [];
            finalGrouped['Total'] = {};
            finalGrouped['Total'].amortizationFirstYear = 0;
            finalGrouped['Total'].value = 0;
            finalGrouped['Total'].amortizationSecondYear = 0;
            finalGrouped['Total'].amortizationThirdYear = 0;

            grouped.forEach(topic => {
                finalGrouped[topic[0].topic] = grouped.get(topic[0].topic).reduce(function (obj, item) {
                    if (!obj[item.topic]) {
                        obj[item.topic] = {};
                        obj[item.topic].amortizationFirstYear = item.amortizationFirstYear;
                        obj[item.topic].value = item.value;
                        obj[item.topic].amortizationSecondYear = item.amortizationSecondYear;
                        obj[item.topic].amortizationThirdYear = item.amortizationThirdYear;
                        if (item.amortizationFourthYear !== undefined) {
                            obj[item.topic].amortizationFourthYear = item.amortizationFourthYear;
                        }
                        if (item.amortizationFifthYear !== undefined) {
                            obj[item.topic].amortizationFifthYear = item.amortizationFifthYear;
                        }
                    } else {
                        obj[item.topic].amortizationFirstYear += item.amortizationFirstYear;
                        obj[item.topic].value += item.value;
                        obj[item.topic].amortizationSecondYear += item.amortizationSecondYear;
                        obj[item.topic].amortizationThirdYear += item.amortizationThirdYear;
                        if (item.amortizationFourthYear !== undefined) {
                            obj[item.topic].amortizationFourthYear += item.amortizationFourthYear;
                        }
                        if (item.amortizationFifthYear !== undefined) {
                            obj[item.topic].amortizationFifthYear += item.amortizationFifthYear;
                        }

                    }
                    return obj;
                }, {});

                finalGrouped['Total'].amortizationFirstYear += finalGrouped[topic[0].topic][topic[0].topic].amortizationFirstYear;
                finalGrouped['Total'].value += finalGrouped[topic[0].topic][topic[0].topic].value;
                finalGrouped['Total'].amortizationSecondYear += finalGrouped[topic[0].topic][topic[0].topic].amortizationSecondYear;
                finalGrouped['Total'].amortizationThirdYear += finalGrouped[topic[0].topic][topic[0].topic].amortizationThirdYear;
                if(finalGrouped[topic[0].topic][topic[0].topic].amortizationFourthYear!== undefined){
                    if(finalGrouped['Total'].amortizationFourthYear===undefined){
                        finalGrouped['Total'].amortizationFourthYear=0;
                    }
                    finalGrouped['Total'].amortizationFourthYear+= finalGrouped[topic[0].topic][topic[0].topic].amortizationFourthYear;
                }

                if(finalGrouped[topic[0].topic][topic[0].topic].amortizationFifthYear!== undefined){
                    if(finalGrouped['Total'].amortizationFifthYear===undefined){
                        finalGrouped['Total'].amortizationFifthYear=0;
                    }
                    finalGrouped['Total'].amortizationFifthYear+= finalGrouped[topic[0].topic][topic[0].topic].amortizationFifthYear;
                }

            })
            return finalGrouped;

        }
        return null;
    }

    const amortizationTotal = getAmortizationTotals();

    const amortizationExpensesFinal = getTransformInformation();


    return (
        <>
            {(amortizationExpensesFinal != null) && (
                <Accordion.Item eventKey="amortizationExpenses">
                    <Accordion.Header><FormattedMessage
                        id='candidatureTabs.accordion.amortizationExpenses'/></Accordion.Header>
                    <Accordion.Body>
                        <div className='tableWithBorders'>
                            <table className='w-100 mb-5 border-top border-0'>

                                {AmortizationTopic.values().map((topic, index) => {


                                    return (
                                        <React.Fragment key={index}>
                                            {amortizationExpensesFinal.get(topic) && (
                                                <>
                                                    <thead>
                                                    <tr className='border-1 border-bottom pt-3 pb-3 ' style={{
                                                        background:
                                                            '#ccc'
                                                    }}>
                                                        <th className='w-35'>{intl.formatMessage({id: `amortizationComponent.${topic}`})}</th>
                                                        <th className='text-center withBorderLeft'><FormattedMessage
                                                            id='amortizationExpenses.value'/></th>
                                                        <th className='text-center withBorderLeft'>{yearNames[0]}</th>
                                                        <th className='text-center withBorderLeft'>{yearNames[1]}</th>
                                                        <th className='text-center withBorderLeft'>{yearNames[2]}</th>
                                                        {amortizationExpensesFinal.get(topic)[0].amortizationFourthYear != null && (
                                                            <th className='text-center withBorderLeft'>{yearNames[3]}</th>
                                                        )}
                                                        {amortizationExpensesFinal.get(topic)[0].amortizationFifthYear != null && (
                                                            <th className='text-center withBorderLeft'>{yearNames[4]}</th>
                                                        )}
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {amortizationExpensesFinal.get(topic).map((line, index2) => (
                                                        <tr className='border-1 border-bottom ' key={index2}>
                                                            <td className='text-left withBorderLeft'>
                                                                <strong> {intl.formatMessage({id: `investmentType.${line.investmentType}`})}</strong>
                                                                <legend className='mt-0 border-0'>  {intl.formatMessage(
                                                                    {id: 'amortizationExpenses.invoice'},
                                                                    {invoice: line.invoice,rate:line.rate})}
                                                                </legend>
                                                            </td>
                                                            <td className='text-center withBorderLeft'> {line.value.toFixed(2)} €</td>
                                                            <td className='text-center withBorderLeft'>{line.amortizationFirstYear.toFixed(2)} €</td>
                                                            <td className='text-center withBorderLeft'>{line.amortizationSecondYear.toFixed(2)} €</td>
                                                            <td className='text-center withBorderLeft'>{line.amortizationThirdYear.toFixed(2)} €</td>
                                                            {line.amortizationFourthYear !== undefined && (
                                                                <td className='text-center withBorderLeft'>{line.amortizationFourthYear.toFixed(2)} €</td>
                                                            )}
                                                            {line.amortizationFifthYear !== undefined && (
                                                                <td className='text-center withBorderLeft'>{line.amortizationFifthYear.toFixed(2)} €</td>
                                                            )}
                                                        </tr>

                                                    ))}
                                                    <tr className='border-1 border-bottom '>
                                                        <td className='text-end withBorderLeft'><strong><FormattedMessage
                                                            id='amortizationExpenses.total'/></strong></td>
                                                        <td className='text-center withBorderLeft'> {amortizationTotal[topic][topic].value.toFixed(2)} €</td>
                                                        <td className='text-center withBorderLeft'> {amortizationTotal[topic][topic].amortizationFirstYear.toFixed(2)} €</td>
                                                        <td className='text-center withBorderLeft'> {amortizationTotal[topic][topic].amortizationSecondYear.toFixed(2)} €</td>
                                                        <td className='text-center withBorderLeft'> {amortizationTotal[topic][topic].amortizationThirdYear.toFixed(2)} €</td>
                                                        {amortizationTotal[topic][topic].amortizationFourthYear !== undefined && (
                                                            <td className='text-center withBorderLeft'>{amortizationTotal[topic][topic].amortizationFourthYear.toFixed(2)} €</td>
                                                        )}
                                                        {amortizationTotal[topic][topic].amortizationFifthYear !== undefined && (
                                                            <td className='text-center withBorderLeft'>{amortizationTotal[topic][topic].amortizationFifthYear.toFixed(2)} €</td>
                                                        )}


                                                    </tr>
                                                    <tr className='border-1 border-bottom '>

                                                        {(amortizationTotal['Total'].amortizationFourthYear !== undefined)? (
                                                            <td colSpan={7} className='text-primary  border-start-0'></td>
                                                        ):<td colSpan={5} className='text-primary  border-start-0'></td>}


                                                    </tr>

                                                    </tbody>
                                                </>
                                            )

                                            }

                                        </React.Fragment>
                                    )
                                })}

                                <thead>

                                </thead>
                                <tbody>
                                <tr className='border-1 border-bottom '>
                                    <td className='text-end withBorderLeft'><strong><FormattedMessage
                                        id='amortizationExpenses.totalofTotal'/></strong></td>
                                    <td className='text-center withBorderLeft'> {amortizationTotal['Total'].value.toFixed(2)} €</td>
                                    <td className='text-center withBorderLeft'> {amortizationTotal['Total'].amortizationFirstYear.toFixed(2)} €</td>
                                    <td className='text-center withBorderLeft'> {amortizationTotal['Total'].amortizationSecondYear.toFixed(2)} €</td>
                                    <td className='text-center withBorderLeft'> {amortizationTotal['Total'].amortizationThirdYear.toFixed(2)} €</td>
                                    {amortizationTotal['Total'].amortizationFourthYear !== undefined && (
                                        <td className='text-center withBorderLeft'>{amortizationTotal['Total'].amortizationFourthYear.toFixed(2)} €</td>
                                    )}
                                    {amortizationTotal['Total'].amortizationFifthYear !== undefined && (
                                        <td className='text-center withBorderLeft'>{amortizationTotal['Total'].amortizationFifthYear.toFixed(2)} €</td>
                                    )}


                                </tr>
                                </tbody>
                            </table>
                        </div>


                        <Row>
                            <Col md='12'>
                                <label>
                                    <FormattedMessage id='candidatureForm.amortizationExpenses.reasonToAmortizationExpenses'/>
                                </label>
                                <p>
                                    {amortizationExpensesList[0].reasonToAmortizationExpenses}
                                </p>
                            </Col>
                        </Row>
                    </Accordion.Body>
                </Accordion.Item>

            )}
        </>
    );
}
