import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { AlertError } from '../../../components/bootstrap/AlertError';
import { AlertSuccess } from '../../../components/bootstrap/AlertSuccess';
import { EnumSelectField } from '../../../components/bootstrap/EnumSelectField';
import { LabelToolTip } from '../../../components/bootstrap/LabelToolTip';
import { CustomPagination } from '../../../components/general/CustomPagination';
import { EmptyResults } from '../../../components/general/EmptyResults';
import Loading from '../../../components/general/Loading';
import { SubTemplate } from '../../../components/general/SubTemplate';
import { ProcessFilterBar } from '../../../components/process/ProcessFilterBar';
import { ProcessType } from '../../../models/ProcessType';
import { listProcesses } from '../../../rest/process';
import { Arrays } from '../../../utils/Arrays';
import { handleError, isNotBusinessError } from '../../../utils/handleError';
import {ProcessState} from "../../../models/ProcessState";
import {useLocation} from "react-router";

const MAX_RESULTS = 25;
const DEFAULT_INITIAL_PAGE = 0;

export function ListProcesses() {
	const intl = useIntl();

	const navigate = useNavigate();



	const { search } = useLocation();
	const params = new URLSearchParams(search);
	const [processes, setProcesses] = useState(null);
	const [filters, setFilters] = useState({
		type:  params.get('type')!==null && params.get('type')!=-'1' ? params.get('type')  : '-1',
		state: params.get('state')!==null && params.get('state')!=-'1' ? params.get('state')  : '-1' ,
		nif: params.get('nif')!==null && params.get('state')!=-'' ? params.get('state')  : '' ,
		ordination: params.get('ordination')!==null && params.get('ordination')!=-'ASC' ? params.get('ordination')  :'ASC',
	});
	const [currentPage, setCurrentPage] = useState(DEFAULT_INITIAL_PAGE);

	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);

	const [lsItem, setLsItem] = useState(null);

	useEffect(() => {
		fetchData();
	}, [currentPage]);

	useEffect(() => {
		checkStorageItems();
	})

	async function fetchData() {
		try {
			const { data: processes } = await listProcesses(
				currentPage,
				MAX_RESULTS,
				filters
			);
			setProcesses(processes);
		} catch (error) {
			setError(error);
		} finally {
			setLoading(false);
		}
	}

	const onPageChange = (pageNumber) => {
		setCurrentPage(pageNumber);
	};

	function handleSearch() {
		const history = window.history;


		let currentUrlParams = new URLSearchParams(window.location.search);
		if(filters.type!== null && filters.type!== '-1'){
			currentUrlParams.set('type',filters.type);
		}else{
			currentUrlParams.delete('type')
		}
		if(filters.state!== null && filters.state!== '-1'){
			currentUrlParams.set('state',filters.state);
		}else{
			currentUrlParams.delete('state')
		}
		if(filters.nif!== null && filters.nif!== ''){
			currentUrlParams.set('nif',filters.nif);
		}else{
			currentUrlParams.delete('nif')
		}

		if(filters.ordination!== null && filters.nif!== 'ASC'){
			currentUrlParams.set('ordination',filters.ordination);
		}else{
			currentUrlParams.delete('ordination')
		}


		console.log(currentUrlParams)


		const url = currentUrlParams.size==0 ? window.location.pathname : window.location.pathname + '?' + currentUrlParams.toString();
		history.replaceState({}, '', url);
		fetchData();
	}

	function handleOrdinationChange(e) {
		filters.ordination = e.target.value;
		setFilters({ ...filters });

		if (e.target.value === 'ASC') {
			processes.list = processes.list.sort(
				(a, b) => new Date(a.submissionDate) - new Date(b.submissionDate)
			);
		}

		if (e.target.value === 'DESC') {
			processes.list = processes.list.sort(
				(a, b) => new Date(b.submissionDate) - new Date(a.submissionDate)
			);
		}

		setProcesses({ ...processes });
	}

	if (loading) {
		return <Loading />;
	}

	if (error && isNotBusinessError(error)) {
		return handleError(error);
	}

	function handleProcessRedirect(process){
		console.log(process)
		if(ProcessState.DRAFT === process.state){
			return;
		}
		switch(process.type){
			case ProcessType.B4_FORM:
				navigate(`/listagemContratos/${process.externalId}`);
			break;
			case ProcessType.NIB_UPLOAD:
				navigate(`/entidade/nib/${process.externalId}`);
				break;
			case ProcessType.B5_FORM:
				navigate(`/pagamento/viabilidadeEconomica/${process.externalId}`);
			break;
			case ProcessType.B6_FORM:
				navigate(`/identificacaoprojeto/${process.externalId}/validar`);
				break;

			case ProcessType.B7_FORM:
				navigate(`/pagamento/apoioPrevio/${process.externalId}`);
			break;
			case ProcessType.B8_FORM:
				navigate(`/avaliacoes/${process.externalId}`);
			break;
			case ProcessType.B9_FORM:
				navigate(`/planoDesenvolvimento/${process.originalExternalId}`);
			break;
			case ProcessType.B10_FORM:
				navigate(`/fichaAtividade/${process.externalId}`);
			break;
			case ProcessType.B11_FORM:
				navigate(`/relatorioSemestral/${process.originalExternalId}`);
			break;
			case ProcessType.B12_FORM:
				if(process.state === ProcessState.REJECTED) navigate(`/pedidoDePagamento/${process.externalId}`);
				else navigate(`/relatorioSemestral/${process.originatingReportExternalId}/pagamento`);
				break;
			case ProcessType.B17_FORM:
				navigate(`/atividadesComplementares/plano/${process.externalId}`);
			break;
			case ProcessType.B18_FORM:
				navigate(`/pedidoPagamento/atividadesComplementares/${process.externalId}`);
			break;
			case ProcessType.B17StartCommunication_FORM:
				navigate(`/atividadesComplementares/comunicacaoInicio/${process.externalId}`);
			break;
			case ProcessType.B13_FORM:
				navigate(`/pedidosMCE/${process.consultingRequestExternalId}/relatorioFinal`);
				break;
			case ProcessType.B16_FORM:
				navigate(`/pedidosMCE/${process.consultingRequestExternalId}/investimento/confirmacao`);
				break;
			case ProcessType.B14_FORM:
				if(process.state === ProcessState.REJECTED) navigate(`/pedidoDePagamento/${process.externalId}`);
				else navigate(`/relatorioSemestral/${process.originatingReportExternalId}/pagamentoFinal`);
				break;
			case ProcessType.ECONOMIC_VIABILITY:
				navigate(`/candidatura/${process.candidatureExternalId}/emitirParecer`);
				break;





			default:
				console.log('There is no handling for this process type')
		}
	}

	function checkStorageItems() {
		if (sessionStorage.getItem('additionalActivitiesPlanDecisionSubmited')) {
			setLsItem('additionalActivitiesPlanDecisionSubmited');
		}

		if (sessionStorage.getItem('additionalActivitiesPlanDecisionSubmitedReplacement')) {
			setLsItem('additionalActivitiesPlanDecisionSubmitedReplacement');
		}

		if (sessionStorage.getItem('additionalActivitiesPaymentRequestDecisionSubmited')) {
			setLsItem('additionalActivitiesPaymentRequestDecisionSubmited');
		}

		if (sessionStorage.getItem('priorSupportPaymentRequestDecisionSubmited')) {
			setLsItem('priorSupportPaymentRequestDecisionSubmited');
		}

		if (sessionStorage.getItem('economicViabilityPaymentRequestDecisionSubmited')) {
			setLsItem('economicViabilityPaymentRequestDecisionSubmited');
		}
	}

	return (
		<SubTemplate titleId={'process.list'} hasBackButton>
			<Container>
				<div className='mx-2 mb-4'>
					<AlertSuccess lsItem={lsItem} />
				</div>
				<ProcessFilterBar
					filters={filters}
					setFilters={setFilters}
					search={handleSearch}
					handleOrdinationChange={handleOrdinationChange}
				/>

				<Row className='searchResults'>
					<Col md='10'>
						<AlertError error={error} />

						{Arrays.isNotEmpty(processes.list) ? (
							<>
								<Row className='mt-5 mb-1 d-flex justify-content-between px-2'>
									<Col className='text-muted'>
										{processes.totalResults}{' '}
										<FormattedMessage id={`all.results.lowCase.${processes.totalResults === 1 ? 'singular' : 'plural'}`} />
									</Col>
									<Col md='3'>
										<EnumSelectField
											controlId={'ordination'}
											preffixDescriptionId={'orderType'}
											name={'ordination'}
											handleChange={(e) => handleOrdinationChange(e)}
											options={['ASC', 'DESC']}
											value={filters.ordination}
										/>
									</Col>
								</Row>
                
								<ul className='results'>
									{processes.list.map((process, index) => (
										<li key={index}>
											<Row className={ProcessState.DRAFT=== process.state? 'py-2 notPointer' : 'py-2'} onClick={() => handleProcessRedirect(process)}>
												<Col md='6' className='d-flex align-items-center'>
													<LabelToolTip
														labelId='process.type'
														value={intl.formatMessage({
															id: `processType.${process.type}`,
														}) + (process.candidatureProcess ? ' (' +process.candidatureProcess + ')' : '')}
													/>
												</Col>
												<Col
													md='3'
													className='d-flex align-items-center text-secondary'
												>
													<LabelToolTip
														labelId='priorSupport.state'
														value={intl.formatMessage({
															id: `processState.${process.state}`,
														})}
													/>
												</Col>
												<Col
													md='3'
													className='d-flex align-items-center justify-content-end text-secondary'
												>
													<LabelToolTip
														labelId='candidatureTabs.submissionDate'
														value={process.submissionDate}
													/>
												</Col>
											</Row>
										</li>
									))}
								</ul>
							</>
						) : (
							<EmptyResults />
						)}
					</Col>
				</Row>
				<Row className='mb-5'>
					<Col className='d-flex justify-content-center'>
						<CustomPagination
							dataPerPage={MAX_RESULTS}
							totalData={processes.totalResults}
							onPageChange={onPageChange}
							currentPage={currentPage}
						/>
					</Col>
				</Row>
			</Container>
		</SubTemplate>
	);
}
