import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { FaSearch } from 'react-icons/fa';
import { FormattedMessage } from 'react-intl';
import { ProcessState } from '../../models/ProcessState';
import { ProcessType } from '../../models/ProcessType';
import { EnumSelectField } from '../bootstrap/EnumSelectField';
import { TextInputField } from '../bootstrap/TextInputField';
import {isIEFPUser} from "../../authentication/authenticationHelper";

export function ProcessFilterBar({ filters, setFilters, search }) {
  function handleFilterChange(e, name) {
    filters[name] = e.target.value;
    setFilters({ ...filters });
  }

  const processStates = isIEFPUser() ? ProcessState.values().filter(v=> v!=='DRAFT') : ProcessType.values();
  return (
    <>
      <Row className='searchFilters'>
        <Col md='4'>
          <EnumSelectField
            controlId={'type'}
            preffixDescriptionId={'processTypes'}
            name={'type'}
            handleChange={(e) => handleFilterChange(e, 'type')}
            options={ProcessType.values()}
            value={filters.type}
            nullOption={true}
            nullOptionSuffix={isIEFPUser()? 'typeIEFP':'type'}
          />
        </Col>
        <Col md='2'>
          <EnumSelectField
            controlId={'state'}
            preffixDescriptionId={'processState'}
            name={'state'}
            handleChange={(e) => handleFilterChange(e, 'state')}
            options={processStates}
            value={filters.state}
            nullOption={true}
            nullOptionSuffix={'state'}
          />
        </Col>
        <Col md='2'>
          <TextInputField
            controlId={'nif'}
            name={'nif'}
            handleChange={(e) => handleFilterChange(e, 'nif')}
            value={filters.nif}
            maxLength={9}
            placeholder={'NIF'}
          />
        </Col>
        <Col md='3'>
          <button className='btn btn-outline-primary' onClick={search} type='button'>
            <FaSearch /> <FormattedMessage id='all.search' />
          </button>
        </Col>
      </Row>
    </>
  );
}
