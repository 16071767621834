import React, { useState } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { ListConsultingRequests } from "../../pages/backoffice/entity/ListConsultingRequests";
import { ListEconomicViabilityRequests } from "../../pages/backoffice/entity/ListEconomicViabilityRequests";
import { ListPriorSupportRequest } from "../../pages/public/ListPriorSupportRequest";
import { ComplementaryActivitesIEFPView } from './ComplementaryActivitesIEFPView';
import { EntityResumeTab } from './EntityResumeTab';
import { ListContractsIEFPView } from './ListContractsIEFPView';
import {ListContractIncubation} from "../../pages/backoffice/entity/ListContractIncubation";


export function EntityTabs({ entityDetails, districtOptions, documents }) {
  const [tabActiveKey, setTabActiveKey] = useState('resume');

  return (
    <Tabs activeKey={tabActiveKey} onSelect={(k) => setTabActiveKey(k)} justify>
      <Tab
        eventKey='resume'
        title={<FormattedMessage id='entityTabs.resume' />}
        className='mb-4 p-3 border'>
        <EntityResumeTab entityDetails={entityDetails} districtOptions={districtOptions} documents={documents} />
      </Tab>

      <Tab
        eventKey='priorSupport'
        title={<FormattedMessage id='entityTabs.priorSupport' />}
        className='mb-4 p-3 border'>
        {tabActiveKey === 'priorSupport' && <ListPriorSupportRequest entityNif={entityDetails.nif} />}
      </Tab>

      <Tab
        eventKey='consulting'
        title={<FormattedMessage id='entityTabs.consulting' />}
        className='mb-4 p-3 border'>
        {tabActiveKey === 'consulting' && <ListConsultingRequests entityNif={entityDetails.nif} />}
      </Tab>
      <Tab
        eventKey='opinion'
        title={<FormattedMessage id='entityTabs.opinion' />}
        className='mb-4 p-3 border'>
        {tabActiveKey === 'opinion' && <ListEconomicViabilityRequests entityNif={entityDetails.nif} />}
      </Tab>

      <Tab
        eventKey='contractListing'
        title={<FormattedMessage id='entityTabs.contractListing' />}
        className='mb-4 p-3 border'>
        {tabActiveKey === 'contractListing' && <ListContractsIEFPView entityNif={entityDetails.nif} />}
      </Tab>

      <Tab
        eventKey='complementaryActivites'
        title={<FormattedMessage id='entityTabs.complementaryActivites' />}
        className='mb-4 p-3 border'>
        {tabActiveKey === 'complementaryActivites' && <ComplementaryActivitesIEFPView entityDetails={entityDetails} />}
      </Tab>

        <Tab
        eventKey='contracts'
        title={<FormattedMessage id='entityTabs.contracts' />}
        className='mb-4 p-3 border'>
        {tabActiveKey === 'contracts' && <ListContractIncubation entityNif={entityDetails.nif} />}
      </Tab>

    </Tabs>
  );
}
