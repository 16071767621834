import React, { useEffect, useState } from 'react';
import { Row, Col, Table } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { TextInputField } from '../../bootstrap/TextInputField';
import {
	generateTimelineTableBody,
	generateTimelineTableFoot,
	generateTimelineTableHeaders,
} from '../../../utils/FinalReportTimelineUtils';

export function FinalReportFormYearTimeline({
	formStep,
	numberOfSteps,
	errors,
	formikValues,
	handleChange,
	handlePreviousStep,
	handleNextStep,
	setFieldValue,
	yearTimeline,
	readMode
}) {
	const intl = useIntl();






	return (
		<div>
			<h4 className='text-primary mb-0'>
				<FormattedMessage id={`finalReportForm.${yearTimeline}`} />
			</h4>
			<small className='text-secondary text-uppercase'>
				{intl.formatMessage(
					{ id: 'all.step' },
					{ step: formStep, numberOfSteps: numberOfSteps }
				)}
			</small>
			<fieldset disabled={readMode == true}>

			<Row>
				<Col>
					<TextInputField
						labelId='finalReportForm.companyDesignation'
						disabled={true}
						handleChange={handleChange}
						value={formikValues.company.designation}
					/>
				</Col>
			</Row>
			<Row className='mt-4'>
				<Col>
					<Table className='d-table' responsive='md'>
						{generateTimelineTableHeaders()}
						{generateTimelineTableBody(
							yearTimeline,
							formikValues,
							handleChange,
							errors,setFieldValue
						)}
						{generateTimelineTableFoot(
							yearTimeline,
							formikValues,
							setFieldValue
						)}
					</Table>
				</Col>
			</Row>
			</fieldset>
			<div className='form-actions mt-5'>
				<button
					className='btn btn-link'
					type='button'
					onClick={handlePreviousStep}
				>
					<FormattedMessage id='all.back' />
				</button>

				<button
					className='btn btn-primary'
					type='button'
					onClick={handleNextStep}
				>
					<FormattedMessage id='all.nextStep' />
				</button>
			</div>
		</div>
	);
}
