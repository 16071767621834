import React from 'react';
import { Form } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';

export function EnumSelectField({
  name,
  labelId,
  label,
  options,
  value,
  preffixDescriptionId,
  handleChange,
  isInvalid,
  errorMessage,
  nullOption,
  disabled,
  nullOptionSuffix,
  toolTip,
  labelClass,
  className,
    keys

}) {
  const intl = useIntl();

  return (
    <Form.Group controlId={name}>
      {(label || labelId) && (
        <Form.Label className={labelClass}>
          {labelId && <FormattedMessage id={labelId} />}
          {label && label}
          {toolTip && toolTip}
        </Form.Label>
      )}
      <Form.Control
        as='select'
        name={name}
        onChange={handleChange}
        value={value}
        className={className}
        isInvalid={isInvalid}
        disabled={disabled}>
        {nullOption && (
          <option value={'-1'}>
            {intl.formatMessage({
              id: `all.select${nullOptionSuffix ? `.${nullOptionSuffix}` : ''}`
            })}
          </option>
        )}
        {options.map((option,index) => (
          <option key={keys?keys[index]: option} value={option}>
            {preffixDescriptionId
              ? intl.formatMessage({ id: `${preffixDescriptionId}.${option}` })
              : option}
          </option>
        ))}
      </Form.Control>
      {errorMessage && <Form.Control.Feedback type='invalid'>{errorMessage}</Form.Control.Feedback>}
    </Form.Group>
  );
}
