import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { getUserInfo, saveUserInfoInStorage } from '../../authentication/authenticationHelper';
import { AlertError } from '../../components/bootstrap/AlertError';
import Loading from '../../components/general/Loading';
import { SubTemplate } from '../../components/general/SubTemplate';
import { PromoterForm } from '../../components/promoter/PromoterForm';
import { DocumentTypeDescriptor } from '../../models/DocumentTypeDescriptor';
import { UserState } from '../../models/UserState';
import {
  getCountiesByDistrictCode,
  getDistricts,
  getParishesByCountyCode
} from '../../rest/administrativeDivision';
import { listCountries } from '../../rest/country';
import { listDocumentsByDescriptor } from '../../rest/document';
import { listProfiles } from '../../rest/profile';
import { getPromoter, savePromoter } from '../../rest/promoter';
import { listSchoolQualifications } from '../../rest/schoolQualification';
import { handleError, isNotBusinessError } from '../../utils/handleError';

export function AddUserData() {
  let userInfo = getUserInfo();

  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [submited, setSubmited] = useState(false);

  const [promoter, setPromoter] = useState(null);
  const [profiles, setProfiles] = useState([]);
  const [countries, setCountries] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [counties, setCounties] = useState([]);
  const [parishes, setParishes] = useState([]);
  const [userDocuments, setUserDocuments] = useState([]);
  const [schoolQualifications, setSchoolQualifications] = useState([]);
  const intl = useIntl();

  const pageState = useLocation().state; //if it contains key "editProfileAllowed", redirect shouldnt be performed

  async function fetchData() {
    try {
      const [
        { data: promoter },
        { data: profiles },
        { data: countries },
        { data: districts },
        { data: userDocuments },
        { data: schoolQualifications }
      ] = await Promise.all([
        await getPromoter(),
        await listProfiles(),
        await listCountries(),
        await getDistricts(),
        await listDocumentsByDescriptor(DocumentTypeDescriptor.USER),
        await listSchoolQualifications()
      ]);
      setPromoter(await getPromoterFormValues(promoter));

      profiles.sort((a,b) => a.code - b.code).forEach(
        (profile) =>
          (profile.description = intl.formatMessage({ id: 'promoterProfile.code.' + profile.code }))
      );

      setProfiles(profiles);
      setCountries(countries);
      setDistricts(districts);
      setUserDocuments(userDocuments);
      setSchoolQualifications(schoolQualifications);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  async function getPromoterFormValues(promoter) {
    if (promoter.parish) {
      await handleDistrictChange(promoter.parish.substring(0, 2));
      await handleCountyChange(promoter.parish.substring(0, 4));
    }



    const values = {
      step: 1,

      profile: promoter.profile
        ? { code: promoter.profile, checked: true }
        : { code: '', checked: false },

      country: promoter.country ? promoter.country : '-1',

      name: userInfo.name,

      city: promoter.city ? promoter.city : '',

      address: promoter.address ? promoter.address : '',

      district: promoter.parish ? promoter.parish.substring(0, 2) : '-1',

      county: promoter.parish ? promoter.parish.substring(0, 4) : '-1',

      parish: promoter.parish ? promoter.parish : '-1',

      postalCode: promoter.postalCode ? promoter.postalCode : '',

      birthDate: promoter.birthDate ? promoter.birthDate : null,

      gender: promoter.gender ? promoter.gender : '',

      niss: promoter.niss ? promoter.niss : '',

      nif: promoter.nif ? promoter.nif : '',

      identificationType: promoter.identificationType ? promoter.identificationType : '',

      identificationNumber: promoter.identificationNumber ? promoter.identificationNumber : '',

      emissionDate: promoter.emissionDate ? promoter.emissionDate : null,

      identificationValidity: promoter.identificationValidity
        ? promoter.identificationValidity
        : null,

      nationality: promoter.nationality ? promoter.nationality : '',

      naturality: promoter.naturality ? promoter.naturality : '',

      schoolQualification: promoter.schoolQualification ? promoter.schoolQualification : '',

      qualificationLevel: promoter.qualificationLevel ? promoter.qualificationLevel : '',

      iefpNumber: promoter.iefpNumber ? promoter.iefpNumber : ''
    };
    return values;
  }

  async function updatePromoter(values, setFieldValue) {
    let dataToSend = { ...values };
    dataToSend.profile = dataToSend.profile.code;
    try {
      await savePromoter(dataToSend);
      if (values.step === 3) {
        setSubmited(true);
      }
      setFieldValue('step', values.step + 1);
    } catch (error) {
      setError(error);
    }
  }

  function handleSubmit(values) {
    updatePromoter(values);
  }

  async function handleDistrictChange(districtCode) {
    try {
      const { data: counties } = await getCountiesByDistrictCode(districtCode);
      setCounties(counties);
      setParishes([]);
    } catch (error) {
      setError(error);
    }
  }

  async function handleCountyChange(countyCode) {
    try {
      const { data: parishes } = await getParishesByCountyCode(countyCode);
      setParishes(parishes);
    } catch (error) {
      setError(error);
    }
  }

  if (
    userInfo.userState === UserState.CREATED &&
    (pageState === null || !('editProfileAllowed' in pageState))
  ) {
    return <Navigate to='/' />;
  }

  if (submited) {
    userInfo.userState = UserState.CREATED;
    saveUserInfoInStorage(userInfo);
    sessionStorage.setItem('registrationCompleted', submited);
    navigate('/candidatura', { state: { refresh: true } });
  }

  if (loading) {
    return <Loading />;
  }

  if (error && isNotBusinessError(error)) {
    return handleError(error);
  }

  return (
    <SubTemplate>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-8 offset-lg-2 mb-5'>
            <AlertError error={error} />
            <PromoterForm
              promoter={promoter}
              profiles={profiles}
              countries={countries}
              districts={districts}
              counties={counties}
              parishes={parishes}
              userDocuments={userDocuments}
              schoolQualifications={schoolQualifications}
              handleDistrictChange={handleDistrictChange}
              handleCountyChange={handleCountyChange}
              onSubmit={handleSubmit}
              savePromoter={updatePromoter}
              setError={setError}
              numberOfSteps={3}
            />
          </div>
        </div>
      </div>
    </SubTemplate>
  );
}
