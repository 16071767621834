import { useIntl } from 'react-intl';

export function TranslateCandidatureState({ state }) {
  const intl = useIntl();
  return intl.formatMessage({ id: 'CandidatureStateTitle.' + state });
}


export function TranslateMessageState(props) {
  const intl = useIntl();
  switch (props.value) {
    case 'PENDING':
      return intl.formatMessage({
        id: 'messages.pendingText',
      });
    case 'SENT':
      return intl.formatMessage({
        id: 'messages.sentText',
      });
    case 'REPLIED':
      return intl.formatMessage({
        id: 'messages.repliedText',
      });
    case 'ERROR':
      return intl.formatMessage({
        id: 'messages.errorText',
      });
    default:
      return props.value;
  }
}

export function TranslateMessageStateInternal(props) {
  const intl = useIntl();
  switch (props.value) {
    case 'PENDING':
      return intl.formatMessage({
        id: 'messages.notRead',
      });
    case 'SENT':
      return intl.formatMessage({
        id: 'messages.read',
      });
    case 'REPLIED':
      return intl.formatMessage({
        id: 'messages.repliedText',
      });
    case 'ERROR':
      return intl.formatMessage({
        id: 'messages.errorText',
      });
    default:
      return props.value;
  }
}

export function TranslateMessageStateFilter(props) {
  const intl = useIntl();
  switch (props) {
    case 'PENDING':
      return intl.formatMessage({
        id: 'messages.pendingText',
      });
    case 'SENT':
      return intl.formatMessage({
        id: 'messages.sentText',
      });
    case 'REPLIED':
      return intl.formatMessage({
        id: 'messages.repliedText',
      });
    case 'ERROR':
      return intl.formatMessage({
        id: 'messages.errorText',
      });
    default:
      return props;
  }
}


export function TranslateMessageType(props) {
  const intl = useIntl();
  switch (props.value) {
    case 'INTERNAL':
      return intl.formatMessage({
        id: 'messages.internalText',
      });
    case 'EMAIL':
      return intl.formatMessage({
        id: 'messages.emailText',
      });
    default:
      return props.value;
  }
}

export function TranslateMessageTypeFilter(props) {
  const intl = useIntl();
  switch (props) {
    case 'INTERNAL':
      return intl.formatMessage({
        id: 'messages.internalTypeText',
      });
    case 'EMAIL':
      return intl.formatMessage({
        id: 'messages.emailTypeText',
      });
    default:
      return props;
  }
}


export function TranslateMessageBoundTypeFilter( {bound} ) {
  const intl = useIntl();
  return intl.formatMessage({ id: 'boundType.' + bound });
}


export function TranslateMessageBoundType(props) {
  const intl = useIntl();
  switch (props.value) {
    case 'IN':
      return intl.formatMessage({
        id: 'boundType.IN',
      });
    case 'OUT':
      return intl.formatMessage({
        id: 'boundType.OUT',
      });
    default:
      return props.value;
  }
}



