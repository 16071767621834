import React, {useState} from 'react';
import {Accordion, Col, Row} from 'react-bootstrap';
import {FormattedMessage, useIntl} from 'react-intl';
import {SellInformationViewer} from "./SellInformationViewer";
import {Arrays} from "../../utils/Arrays";
import {ProviderType} from "../../models/ProviderType";
import {PeopleType} from "../../models/PeopleType";
import {FunctionType} from "../../models/FunctionType";
import {ProviderInformationAccordionItem} from "./ProviderInformationAccordionItem";
import {RemunerationType} from "../../models/RemunerationType";

export function EarlyAmortizationAccordionItem({earlyAmortizationList,yearNames}) {
    const intl = useIntl();
    const  earlyAmortizationFinal = getTransformInformation();
    const  earlyAmortizationTotals = getEarlyAmortizationTotals();

    function getTransformInformation() {
        if ( earlyAmortizationList?.length > 0) {
            let grouped = Arrays.groupBy( earlyAmortizationList[0].earlyAmortizationCellList, eAmortization => eAmortization.month);
            grouped.forEach(eAmortization => (eAmortization.sort((a, b) => a.year - b.year)));

            return grouped;
        }
        return null;
    }


    function getEarlyAmortizationTotals() {
        if ( earlyAmortizationList?.length > 0) {
            let grouped = Arrays.groupBy( earlyAmortizationList[0].earlyAmortizationCellList, eAmortization => eAmortization.year);
            let finalGrouped=[]

           grouped.forEach(year => {
               finalGrouped[(year[0].year).toString()] = year.reduce(function (obj, item) {
                   let yearString = item.year.toString();
                   if (!obj[yearString]) {
                       obj[yearString] = {};
                       obj[yearString].value = item.value;
                       obj[yearString].debtCapital = item.debtCapital;

                   } else {

                       obj[yearString].value += item.value;
                       obj[yearString].debtCapital = item.debtCapital;
                   }
                   return obj;
               }, {});
           })

            return finalGrouped;
        }
        return null;
    }




    return (
        <>
            {( earlyAmortizationFinal != null) && (
                <Accordion.Item eventKey=" earlyAmortization">
                    <Accordion.Header><FormattedMessage
                        id='candidatureTabs.accordion.earlyAmortization'/> </Accordion.Header>
                    <Accordion.Body>


                        <Row className='border-bottom border-1 mb-4'>
                            <Col md='4'>
                                <label>
                                    <FormattedMessage
                                        id='earlyAmortization.view.period'/>
                                </label>
                               <p>
                                   { earlyAmortizationList[0].appliedDeferralPeriodBenefits ===true ? (<><FormattedMessage id='all.yes' /> -> {earlyAmortizationList[0].deferralPeriod} <FormattedMessage id={'all.months'}/> </>) : <FormattedMessage id='all.no' /> }
                               </p>
                            </Col>

                            <Col md='4'>
                                <label>
                                    <FormattedMessage
                                        id='earlyAmortization.view.earlyAmortization'/>
                                </label>
                                <p>
                                    { earlyAmortizationList[0].isEarlyAmortization ===true ? <FormattedMessage id='all.yes' /> : <FormattedMessage id='all.no' /> }
                                </p>
                            </Col>
                            <Col md='4'>
                                <label>
                                    <FormattedMessage
                                        id='candidatureForm.initialYear'/>
                                </label>
                                <p>
                                    {earlyAmortizationList[0].initialYear}
                                </p>
                            </Col>
                        </Row>

                        {[...earlyAmortizationFinal.values()].map((eAmortization,index) => (
                            <div  key={index} className='tableWithBorders'>
                                {earlyAmortizationList[0].initialYear !== undefined && (
                                    <>
                                        <p>
                                            <strong>{intl.formatMessage({id: `month.${eAmortization[0].month}`})}</strong>
                                        </p>

                                        <table className='w-100 mb-5 border-top border-1'>



                                            <thead>
                                            <tr>
                                                {eAmortization.map((year,index1) => (
                                                    <th key={index1} colSpan="2"  className='text-center'>{earlyAmortizationList[0].initialYear +parseInt(year.year)}</th>
                                                ))}

                                            </tr>

                                            </thead>
                                            <tbody>
                                            <tr className='text-center'>
                                                {eAmortization.map((year,index2) => {
                                                    return (
                                                        <React.Fragment key={index2}>
                                                            <td><FormattedMessage id='earlyAmortization.value'/></td>
                                                            <td><FormattedMessage id='earlyAmortization.debt'/></td>
                                                        </React.Fragment>
                                                    )
                                                })}

                                            </tr>
                                            <tr>
                                                {eAmortization.map((year,index2)=> {
                                                    return (
                                                        <React.Fragment key={index2}>
                                                            <td className='text-center'>{year.value.toFixed(2)} €</td>
                                                            <td className='text-center'>{year.debtCapital.toFixed(2)} €</td>
                                                        </React.Fragment>
                                                    )
                                                })}

                                            </tr>
                                            </tbody>
                                        </table>
                                    </>
                                )}





                            </div>


                        ))


                        }


                        <div className='tableWithBorders'>
                            <p>
                                <strong>total</strong>
                            </p>
                            <table className='w-100 mb-5'>
                                <thead>
                                <tr>
                                    {[...earlyAmortizationFinal.values()][0].map((year,index) => (
                                        <th key={index} colSpan="2" className='text-center'>{yearNames[year.year]}</th>
                                    ))}

                                </tr>
                                </thead>
                                <tbody>
                                <tr className='text-center'>
                                    {earlyAmortizationTotals.map((year, index2) => {
                                        return (
                                            <React.Fragment key={index2}>
                                                <td><FormattedMessage id='earlyAmortization.value'/></td>
                                                <td><FormattedMessage id='earlyAmortization.debt'/></td>
                                            </React.Fragment>
                                        )
                                    })}

                                </tr>
                                <tr>
                                    {[...earlyAmortizationFinal.values()][0].map((year,index2) => {
                                        return (
                                            <React.Fragment key={index2}>
                                                <td className='text-center'>{earlyAmortizationTotals[year.year][year.year].value.toFixed(2)} €</td>
                                                <td className='text-center'>{earlyAmortizationTotals[year.year][year.year].debtCapital.toFixed(2)} €</td>
                                            </React.Fragment>
                                        )
                                    })}
                                </tr>
                                </tbody>

                            </table>


                            <Row>
                                <Col md='12'>
                                    <label>
                                        <FormattedMessage id='earlyAmortization.reason'/>
                                    </label>
                                    <p>
                                        {earlyAmortizationList[0].reasonToEarlyAmortization}
                                    </p>
                                </Col>
                            </Row>

                        </div>
                    </Accordion.Body>
                </Accordion.Item>

            )}
        </>
    );
}
