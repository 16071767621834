import React from 'react';
import { Accordion, Col, Row } from 'react-bootstrap';
import AccordionBody from 'react-bootstrap/esm/AccordionBody';
import AccordionHeader from 'react-bootstrap/esm/AccordionHeader';
import AccordionItem from 'react-bootstrap/esm/AccordionItem';
import { FormattedMessage } from 'react-intl';
import { ListAnnualComplementaryActivitiesPaymentRequests } from '../../pages/backoffice/entity/ListAnnualComplementaryActivitiesPaymentRequests';
import { ListAnnualComplementaryActivitiesPlan } from '../../pages/backoffice/entity/ListAnnualComplementaryActivitiesPlan';
import { ListAnnualComplementaryActivitiesPlanStartCommunication } from '../../pages/backoffice/entity/ListAnnualComplementaryActivitiesPlanStartCommunication';


export function ComplementaryActivitesIEFPView({ entityDetails }) {
  return (
    <>
      <Row>
        <Col>
          <Accordion className='mt-0 mb-3'>
            <AccordionItem className='mt-0 mb-0' eventKey={'annualPlanComplementaryActivites'}>
              <AccordionHeader>
                <FormattedMessage id={'annualComplementaryActivitiesPlan.list.title'} />
              </AccordionHeader>
              <AccordionBody>
                <ListAnnualComplementaryActivitiesPlan entityNif={entityDetails.nif} />
              </AccordionBody>
            </AccordionItem>
          </Accordion>
          <Accordion className='mt-0 mb-3'>
            <AccordionItem className='mt-0 mb-0' eventKey={'annualPlanComplementaryActivites'}>
              <AccordionHeader>
                <FormattedMessage id={'annualComplementaryActivitiesPlanStartCommunication.list.title'} />
              </AccordionHeader>
              <AccordionBody>
                <ListAnnualComplementaryActivitiesPlanStartCommunication entityNif={entityDetails.nif} />
              </AccordionBody>
            </AccordionItem>
          </Accordion>
          <Accordion className='mt-0 mb-3'>
            <AccordionItem className='mt-0 mb-0' eventKey={'annualPlanComplementaryActivites'}>
              <AccordionHeader>
                <FormattedMessage id={'annualComplementaryActivitiesPayment.list.title'} />
              </AccordionHeader>
              <AccordionBody>
                <ListAnnualComplementaryActivitiesPaymentRequests entityNif={entityDetails.nif} />
              </AccordionBody>
            </AccordionItem>
          </Accordion>
        </Col>
      </Row>
    </>
  );
}
