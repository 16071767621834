import React, { useEffect, useState } from 'react';
import {Col, Container, Row, Toast, ToastContainer} from 'react-bootstrap';
import {FaCheck} from 'react-icons/fa';
import { FormattedMessage, useIntl } from "react-intl";
import { useNavigate, useParams } from 'react-router-dom';
import { AlertError } from '../../components/bootstrap/AlertError';
import { AlertSuccess } from '../../components/bootstrap/AlertSuccess';
import Loading from '../../components/general/Loading';
import { SubTemplate } from '../../components/general/SubTemplate';
import { EntityTabs } from '../../components/listContracts/EntityTabs';
import { EntityState } from "../../models/EntityState";
import { getDistricts } from '../../rest/administrativeDivision';
import { listEntityDetailDocuments } from '../../rest/document';
import { getEntityDetails, revokeEntity } from '../../rest/entity';
import { handleError, isNotBusinessError } from '../../utils/handleError';
import {EntityRevokedAlert} from "../../components/eaAccreditation/EntityRevokedAlert";


export function DetailEntity() {
  const { entityNif: entityNif } = useParams();

  const [lsItem, setLsItem] = useState(null);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [entityDetails, setEntityDetails] = useState(null);
  const [districtOptions, setDistrictOptions] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [showSaveToast, setShowSaveToast] = useState(false);
  const [revoked, setRevoked] = useState(false);
  const intl = useIntl();
  const navigate = useNavigate();

  const fetchData = async () => {
    try {
      const [
        { data: entityInfo },
        { data: districts },
        { data: entityDocuments }
      ] = await Promise.all([
        await getEntityDetails(entityNif),
        await getDistricts(),
        await listEntityDetailDocuments({ entityNif })
      ]);

      setEntityDetails(entityInfo);
      setDistrictOptions(districts);
      setDocuments(entityDocuments);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
    checkLocalStorageItems();
  }, []);

  function checkLocalStorageItems() {
    [].forEach((s) => {
      if (sessionStorage.getItem(s)) {
        setLsItem(s);
      }
    });
  }

  if (loading) {
    return <Loading />;
  }

  if (error && isNotBusinessError(error)) {
    return handleError(error);
  }

  async function revoke() {
    try{
      await revokeEntity(entityNif);
      setRevoked(true)
      setShowSaveToast(true);
    } catch (error) {
      setError(error);
    }
  }

  function getSaveToastInformation(message) {
    return (
        <ToastContainer className='fixed-top'>
          <Toast autohide delay={3000} show={showSaveToast} onClose={() => setShowSaveToast(false)}>
            <Toast.Header>
              <strong className='me-auto text-primary'>
              <span className='mr-2'>
                <FaCheck />
              </span>
                {message}
              </strong>
            </Toast.Header>
          </Toast>
        </ToastContainer>
    );
  }

  return (
    <SubTemplate title={entityDetails.description}  hasBackButton>
      <Container>
        <AlertError error={error} />
        {lsItem && <AlertSuccess lsItem={lsItem} />}
        <Row>
          <Col md='10' className='mb-5 summaryTabs'>
            {entityDetails.revoked === true &&
            <Row>
              <Col>
                <EntityRevokedAlert/>
              </Col>
            </Row>}
            {getSaveToastInformation(
                intl.formatMessage({ id: 'entity.revoked.saveToastInformation' })
            )}
            <EntityTabs entityDetails={entityDetails} districtOptions={districtOptions} documents={documents} />
          </Col>
          <Col md="2" >
            <div className='mb-5'></div>
            <div className='mb-3'></div>
            {(EntityState.requireDecision().includes(entityDetails.state)  && revoked===false) && (
            <button className={'btn btn-outline-primary w-100'} onClick={() => navigate(`/credenciacaoEntidade/adicionar/${entityDetails.nif}`) }>
              <FormattedMessage id='eaAccreditation.A1Form.Button' />
            </button>
            )}
             <div className='mb-3'></div>
            { (entityDetails.state === EntityState.FULLY_APPROVED && revoked===false) && (
            <button className={'btn btn-outline-primary w-100 mt-3'} onClick={() => navigate(`/entidades/${entityDetails.nif}/credenciacao`)}>
              <FormattedMessage id='eaAccreditation.View' />
            </button>
            )}
          </Col>
        </Row>
      </Container>
    </SubTemplate>
  );
}
