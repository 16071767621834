import React, { useState } from 'react';
import { Accordion, Col, Form, Row, Table } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { DateTimeInputField } from '../bootstrap/DateTimeInputField';
import { EnumSelectField } from '../bootstrap/EnumSelectField';
import { NumberInputField } from '../bootstrap/NumberInputField';
import { TextAreaInputField } from '../bootstrap/TextAreaInputField';
import { TextInputField } from '../bootstrap/TextInputField';

export function HumanResourcesStep({
	step,
	numberOfSteps,
	errors,
	handleChange,
	formikValues,
	handleGoBack,
	handleNextStep,
	setFieldValue,
	schoolQualifications,
	readMode,
}) {
	const intl = useIntl();

	function handleAddHumanResource() {
		formikValues.strategicDiagnosis.humanResources.push({
			name: '',
			gender: '',
			age: '',
			role: '',
			schoolQualificationCode: '-1',
		});
		setFieldValue({ ...formikValues });
	}

	function removeHuman(index) {
		formikValues.strategicDiagnosis.humanResources.splice(index, 1);
		setFieldValue({ ...formikValues });
	}

	return (
		<div>
			<h4 className='text-primary mb-0'>
				<FormattedMessage id='developmentPlan.humanResources' />
			</h4>
			<small className='text-secondary text-uppercase'>
				{intl.formatMessage(
					{ id: 'all.step' },
					{ step: step, numberOfSteps: numberOfSteps }
				)}
			</small>
			<fieldset disabled={readMode==true}>
				{formikValues.strategicDiagnosis.humanResources.map((human, index) => (
					<Row key={index}>
						<Col md='12'>
							<Accordion defaultActiveKey='0'>
								<Accordion.Item eventKey='0' className='mt-4'>
									<Accordion.Header>Pessoa {index + 1}</Accordion.Header>
									<Accordion.Body>
										<Row>
											<Col md='12'>
												<TextInputField
													name={`strategicDiagnosis.humanResources[${index}].name`}
													handleChange={handleChange}
													labelId={'strategicDiagnosis.humanResources.name'}
													value={
														formikValues.strategicDiagnosis.humanResources[
															index
														].name
													}
													isInvalid={
														errors.strategicDiagnosis?.humanResources?.[index]
															?.name
													}
													errorMessage={
														errors.strategicDiagnosis?.humanResources?.[index]
															?.name
													}
												/>
											</Col>
										</Row>
										<Row>
											<Col md='3'>
												<TextInputField
													name={`strategicDiagnosis.humanResources[${index}].gender`}
													handleChange={handleChange}
													labelId={'strategicDiagnosis.humanResources.gender'}
													value={
														formikValues.strategicDiagnosis.humanResources[
															index
														].gender
													}
													isInvalid={
														errors.strategicDiagnosis?.humanResources?.[index]
															?.gender
													}
													errorMessage={
														errors.strategicDiagnosis?.humanResources?.[index]
															?.gender
													}
												/>
											</Col>
											<Col md='3'>
												<NumberInputField
													name={`strategicDiagnosis.humanResources[${index}].age`}
													handleChange={handleChange}
													labelId={'strategicDiagnosis.humanResources.age'}
													value={
														formikValues.strategicDiagnosis.humanResources[
															index
														].age
													}
													isInvalid={
														errors.strategicDiagnosis?.humanResources?.[index]
															?.age
													}
													errorMessage={
														errors.strategicDiagnosis?.humanResources?.[index]
															?.age
													}
													maxLength={2}
												/>
											</Col>
											<Col md='6'>
												<TextInputField
													name={`strategicDiagnosis.humanResources[${index}].role`}
													handleChange={handleChange}
													labelId={
														'strategicDiagnosis.humanResources.functions'
													}
													value={
														formikValues.strategicDiagnosis.humanResources[
															index
														].role
													}
													isInvalid={
														errors.strategicDiagnosis?.humanResources?.[index]
															?.role
													}
													errorMessage={
														errors.strategicDiagnosis?.humanResources?.[index]
															?.role
													}
												/>
											</Col>
										</Row>
										<Row>
											<Col md='6'>
												<Form.Group>
													<Form.Label>
														<FormattedMessage id='strategicDiagnosis.humanResources.qualificationsAcademic' />
													</Form.Label>
													<Form.Control
														as='select'
														name={`strategicDiagnosis.humanResources[${index}].schoolQualificationCode`}
														onChange={handleChange}
														value={
															formikValues.strategicDiagnosis.humanResources[
																index
															].schoolQualificationCode
														}
														isInvalid={
															errors.strategicDiagnosis?.humanResources?.[index]
																?.schoolQualificationCode
														}
													>
														<option value={'-1'}>
															<FormattedMessage id={`all.select`} />
														</option>
														{schoolQualifications.map((qualification) => (
															<option
																key={qualification.code}
																value={qualification.code}
															>
																{qualification.description}
															</option>
														))}
													</Form.Control>
													{errors.strategicDiagnosis?.humanResources?.[index]
														?.schoolQualificationCode && (
														<Form.Control.Feedback type='invalid'>
															{
																errors.strategicDiagnosis.humanResources[index]
																	.schoolQualificationCode
															}
														</Form.Control.Feedback>
													)}
												</Form.Group>
											</Col>
											<Col md='6'>
												<Form.Label>
													<FormattedMessage id='strategicDiagnosis.humanResources.qualificationLevel' />
												</Form.Label>
												<p>
													{formikValues.strategicDiagnosis.humanResources[index]
														.schoolQualificationCode === '-1'
														? null
														: schoolQualifications.filter(
																(qualification) =>
																	qualification.code ===
																	formikValues.strategicDiagnosis
																		.humanResources[index]
																		.schoolQualificationCode
														  )[0].level}
												</p>
											</Col>
										</Row>
										<Row>
											<Col>
												<button
													className='mt-5 px-0 btn btn-link'
													type='button'
													onClick={() => removeHuman(index)}
												>
													<FormattedMessage id='all.remove' />
												</button>
											</Col>
										</Row>
									</Accordion.Body>
								</Accordion.Item>
							</Accordion>
						</Col>
					</Row>
				))}
				<Row>
					<Col>
						<button
							className='mt-4 btn btn-outline-primary'
							type='button'
							onClick={() => handleAddHumanResource()}
						>
							<FormattedMessage id='all.plusSymbol' />
							<FormattedMessage id='strategicDiagnosis.humanResources.addPerson' />
						</button>
					</Col>
				</Row>
			</fieldset>
			<div className='form-actions mt-5'>
				<button
					className='btn btn-link'
					type='button'
					onClick={() => handleGoBack()}
				>
					<FormattedMessage id='all.back' />
				</button>

				<button
					className='btn btn-primary'
					type='button'
					onClick={() => handleNextStep()}
				>
					<FormattedMessage id='all.nextStep' />
				</button>
			</div>
		</div>
	);
}
