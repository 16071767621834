import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Pagination from 'react-bootstrap/Pagination';

export function PaginationComponent({ dataPerPage, totalData, onPageChange, currentPage }) {
  let items = [];

  if (Math.ceil(totalData / dataPerPage) > 1) {
    if (currentPage !== 0) {
      items.push(<Pagination.First key={'first'} onClick={() => onPageChange(0)} />);
      items.push(<Pagination.Prev key={'prev'} onClick={() => onPageChange(currentPage - 1)} />);
    }
    if (currentPage === 1 || currentPage === 2) {
      items.push(
        <Pagination.Item onClick={() => onPageChange(0)} key={0}>
          {1}
        </Pagination.Item>
      );
    }
    if (currentPage - 2 > 0)
      items.push(
        <Pagination.Item onClick={() => onPageChange(currentPage - 2)} key={currentPage - 2}>
          {currentPage - 1}
        </Pagination.Item>
      );
    if (currentPage - 1 > 0)
      items.push(
        <Pagination.Item onClick={() => onPageChange(currentPage - 1)} key={currentPage - 1}>
          {currentPage}
        </Pagination.Item>
      );
    items.push(
      <Pagination.Item onClick={() => onPageChange(currentPage)} key={currentPage} active={true}>
        {currentPage + 1}
      </Pagination.Item>
    );
    if (currentPage + 1 < Math.ceil(totalData / dataPerPage))
      items.push(
        <Pagination.Item onClick={() => onPageChange(currentPage + 1)} key={currentPage + 1}>
          {currentPage + 2}
        </Pagination.Item>
      );
    if (currentPage + 2 < Math.ceil(totalData / dataPerPage))
      items.push(
        <Pagination.Item onClick={() => onPageChange(currentPage + 2)} key={currentPage + 2}>
          {currentPage + 3}
        </Pagination.Item>
      );
    if (currentPage !== Math.ceil(totalData / dataPerPage) - 1) {
      items.push(<Pagination.Next key={'next'} onClick={() => onPageChange(currentPage + 1)} />);
      items.push(
        <Pagination.Last
          key={'last'}
          onClick={() => onPageChange(Math.ceil(totalData / dataPerPage) - 1)}
        />
      );
    }
  }

  return (
    <>
      {totalData > 0 && (
        <Row>
          <Col>
            <p className='mx-2 text-secondary'>{totalData} resultados</p>
          </Col>
          <Col className='justify-content-end d-flex'>
            <Pagination className='mx-2' size='sm'>
              {items}
            </Pagination>
          </Col>
        </Row>
      )}
    </>
  );
}
