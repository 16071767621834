import React, { useEffect, useState } from 'react';
import { Row, Col, Table, Accordion, Form } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { TextInputField } from '../../bootstrap/TextInputField';
import { NumberInputField } from '../../bootstrap/NumberInputField';
import { EnumSelectField } from '../../bootstrap/EnumSelectField';
import { DateTimeInputField } from '../../bootstrap/DateTimeInputField';
import {MceActivityType} from "../../../models/MceActivityType";
import {IntInputField} from "../../bootstrap/IntInputField";
import { MceYearType } from '../../../models/MceYearType';

export function FinalReportFormStep12({
	formStep,
	numberOfSteps,
	errors,
	formikValues,
	handleChange,
	handlePreviousStep,
	setFieldValue,
	showYearAndSemester,
										  tpgList,
	readMode
}) {
	const intl = useIntl();

	const tableHeaders = [
		'name',
		'internalOrExternal',
		'nif',
		'mceActivity',
		'actionDesignation',
		'startDateAction',
		'endDateAction',
		'numberOfHoursByMonth',
	];

	const internalOrExternalOptions = ['INTERN', 'EXTERN'];

	const year = formikValues.mceYearType === MceYearType.FIRST && '1'
	|| formikValues.mceYearType === MceYearType.SECOND && '2'
	|| formikValues.mceYearType === MceYearType.THIRD && '3'

	const semester = formikValues.semester ===false ? '1' : '2'

	function addProjectManagerEntry() {
		formikValues.projectManagersList.push({
			name: '',
			internalOrExternal: '',
			nif: '',
			mceActivity: '',
			actionDesignation: '',
			startDateAction: '',
			endDateAction: '',
			numberOfHoursByMonth:0,
		});
		setFieldValue({ ...formikValues });
	}

	function removeProjectManager(index) {
		formikValues.projectManagersList.splice(index, 1);
		setFieldValue({ ...formikValues });
	}


	function handleHumanChange(index,value) {
		if(value==='-1'){
			formikValues.projectManagersList[index].nif= '';
			formikValues.projectManagersList[index].name= '';

		}else{
			formikValues.projectManagersList[index].nif= tpgList.filter(el=>el.externalId === value)[0].hre.nif;
			formikValues.projectManagersList[index].name = tpgList.filter(el=>el.externalId === value)[0].name;
		}
		formikValues.projectManagersList[index].humanExternalId=value;
		console.log(value)

		setFieldValue({ ...formikValues });
	}




	function handleInternalOrExternalChange(index,e) {
		console.log(formikValues.projectManagersList[index].internalOrExternal);
		formikValues.projectManagersList[index].internalOrExternal=  e.target.value==='-1'? undefined : e.target.value;

		setFieldValue({...formikValues});
	}

	function handleMceActivityChange(index,e) {
		formikValues.projectManagersList[index].mceActivity=  e.target.value==='-1'? undefined : e.target.value;
		setFieldValue({...formikValues});
	}

	function changeNumberHours(e, index) {
		formikValues.projectManagersList[index].numberOfHoursByMonth= e.target.value;
		setFieldValue({ ...formikValues });
	}

	return (
		<div>
			<h4 className='text-primary mb-0'>
				<FormattedMessage id='finalReportForm.step12.title' />
			</h4>
			<small className='text-secondary text-uppercase'>
				{intl.formatMessage(
					{ id: 'all.step' },
					{ step: formStep, numberOfSteps: numberOfSteps }
				)}
			</small>
			<fieldset disabled={readMode == true}>
			<Row>
				{showYearAndSemester && (
					<>
						<Col md='2'>
							<NumberInputField
								disabled={true}
								value={year}
								labelId='semestralReportForm.step4.year'
							/>
						</Col>
						<Col md='3'>
							<NumberInputField
								disabled={true}
								value={semester}
								labelId='semestralReportForm.step4.semester'
							/>
						</Col>
					</>
				)}
				<Col md='7'>
					<TextInputField
						labelId='finalReportForm.companyDesignation'
						disabled={true}
						handleChange={handleChange}
						value={formikValues.company.name}
					/>
				</Col>
			</Row>
			<Row>
				<Col>
					<Form.Label>
						<FormattedMessage id='finalReportForm.step12.managers' />
					</Form.Label>
				</Col>
			</Row>
			{formikValues.projectManagersList.length > 0 ? (
				formikValues.projectManagersList.map((elem, index) => (
					<Row key={index}>
						<Col>
							<Accordion defaultActiveKey='0' key={index}>
								<Accordion.Item eventKey='0' className='mt-4'>
									<Accordion.Header>
										<FormattedMessage id='finalReportForm.step12.accordion.manager' />{' '}
										{index + 1}
									</Accordion.Header>
									<Accordion.Body>
										<Form.Group>
											<Form.Label>
												<FormattedMessage id='finalReportForm.step12.accordion.name' />
											</Form.Label>
											<Form.Control
												as='select'
												name={'identificationEa.technicalTeamManagerExternalId'}
												onChange={(e) => handleHumanChange(index,e.target.value)}
												value={
													formikValues.projectManagersList[index].humanExternalId
												}
												isInvalid={
													errors?.projectManagersList?.[index]?.name
												}

											>
												<option value={'-1'}>
													<FormattedMessage id='all.chooseOne' />
												</option>
												{tpgList.map((tpg) => (
													<option key={tpg.externalId} value={tpg.externalId}>
														{tpg.name}
													</option>
												))}
											</Form.Control>
											<Form.Control.Feedback type='invalid'>
												{errors?.projectManagersList?.[index]?.name}
											</Form.Control.Feedback>
										</Form.Group>

										<Row>
											<Col md='3'>
												<EnumSelectField
													labelId='finalReportForm.step12.accordion.internalOrExternal'
													nullOption={true}
													options={internalOrExternalOptions}
													preffixDescriptionId='finalReportForm.step12.options'
													handleChange={(e) => handleInternalOrExternalChange(index,e)}
													name={`projectManagersList[${index}].internalOrExternal`}
													value={
														formikValues.projectManagersList[index]
															.internalOrExternal
													}
													isInvalid={
														errors?.projectManagersList?.[index]?.internalOrExternal
													}
													errorMessage={
														errors?.projectManagersList?.[index]?.internalOrExternal
													}
												/>
											</Col>

											<Col md='3'>
												<NumberInputField
													labelId='finalReportForm.step12.accordion.nif'
													handleChange={handleChange}
													name={`projectManagersList[${index}].nif`}
													value={formikValues.projectManagersList[index].nif}
													disabled={true}
													isInvalid={
														errors?.projectManagersList?.[index]?.nif
													}
													errorMessage={
														errors?.projectManagersList?.[index]?.nif
													}
													maxLength={9}
												/>
											</Col>
										</Row>

										<Row>
											<Col>

												<Form.Group>
													<Form.Label className='col-sm-8'>
														<FormattedMessage id={'finalReportForm.step12.accordion.mceActivity'} />
													</Form.Label>
													<Form.Control
														as='select'
														name={`projectManagersList.${index}].mceActivity`}
														onChange={(e) => handleMceActivityChange(index,e)}
														value={formikValues.projectManagersList[index].mceActivity}
														isInvalid={
															errors?.projectManagersList?.[index]?.mceActivity
														}


													>
														<option value={'-1'}>
															<FormattedMessage id='all.chooseOne'/>
														</option>
														{MceActivityType.values().map((action) => (
															<option key={action} value={action}>
																<FormattedMessage id={'mceActivityType.'+action}/>
															</option>
														))}

													</Form.Control>
													<Form.Control.Feedback type='invalid'>
														{errors?.projectManagersList?.[index]?.mceActivity}
													</Form.Control.Feedback>
												</Form.Group>
											</Col>
										</Row>

										<Row>
											<Col>
												<TextInputField
													labelId='finalReportForm.step12.accordion.actionDesignation'
													handleChange={handleChange}
													name={`projectManagersList[${index}].actionDesignation`}
													value={
														formikValues.projectManagersList[index]
															.actionDesignation
													}
													isInvalid={
														errors?.projectManagersList?.[index]?.actionDesignation													}
													errorMessage={
														errors?.projectManagersList?.[index]?.actionDesignation													}
												/>
											</Col>
										</Row>

										<Row>
											<Col md='6'>
												<DateTimeInputField
													labelId='finalReportForm.step12.accordion.startDateAction'
													name={`projectManagersList[${index}].startDateAction`}
													value={
														formikValues.projectManagersList[index]
															.startDateAction
													}
													isInvalid={
														errors?.projectManagersList?.[index]?.startDateAction

													}
													errorMessage={
														errors?.projectManagersList?.[index]?.startDateAction
													}
													setFieldValue={setFieldValue}
													isDateWithNoTime={true}
												/>
											</Col>

											<Col md='6'>
												<DateTimeInputField
													labelId='finalReportForm.step12.accordion.endDateAction'
													name={`projectManagersList[${index}].endDateAction`}
													value={
														formikValues.projectManagersList[index]
															.endDateAction
													}
													isInvalid={
														errors?.projectManagersList?.[index]?.endDateAction
													}
													errorMessage={
														errors?.projectManagersList?.[index]?.endDateAction
													}
													setFieldValue={setFieldValue}
													isDateWithNoTime={true}
												/>
											</Col>
										</Row>

										<Row>
											<Col md='6'>

												<IntInputField
													labelId='finalReportForm.step12.accordion.numberOfHoursByMonth'
													controlId={`projectManagersList[${index}].numberOfHoursByMonth`}
													name={`projectManagersList[${index}].numberOfHoursByMonth`}
													value={formikValues.projectManagersList[index]
														.numberOfHoursByMonth}
													handleChange={(e) => changeNumberHours(e, index)}

													isInvalid={errors?.projectManagersList?.[index]?.numberOfHoursByMonth}
													errorMessage={errors?.projectManagersList?.[index]?.numberOfHoursByMonth}
												/>
											</Col>
										</Row>

										<Row>
											<Col>
												<button
													className='mt-4 px-0 btn btn-link'
													type='button'
													onClick={() => removeProjectManager(index)}
												>
													<FormattedMessage id='all.remove' />
												</button>
											</Col>
										</Row>
									</Accordion.Body>
								</Accordion.Item>
							</Accordion>
						</Col>
					</Row>
				))
			) : (
				<Row className='mt-5'>
					<Col>
						<FormattedMessage id='finalReportForm.noData' />
					</Col>
				</Row>
			)}
			<Row className='mt-4'>
				<Col>
					<button
						className='btn btn-outline-primary'
						type='button'
						onClick={addProjectManagerEntry}
					>
						<FormattedMessage id='all.addButton' />
					</button>
				</Col>
			</Row>
			</fieldset>
			<div className='form-actions mt-5'>
				<button
					className='btn btn-link'
					type='button'
					onClick={handlePreviousStep}
				>
					<FormattedMessage id='all.back' />
				</button>
			</div>
		</div>
	);
}
