import React from 'react';
import {Col, Form, Row, Table} from 'react-bootstrap';
import {FaSearch} from 'react-icons/fa';
import {FormattedMessage, useIntl} from 'react-intl';
import {EnumSelectField} from '../bootstrap/EnumSelectField';
import {isIEFPUser} from "../../authentication/authenticationHelper";
import {EntityChangeState} from "../../models/EntityChangeState";
import {EntityChangeType} from "../../models/EntityChangeType";
import {TextAreaInputField} from "../bootstrap/TextAreaInputField";
import {SyncMultipleUploadArea} from "../SyncMultipleUploadArea";
import {DocumentType} from "../../models/DocumentType";
import {TextInputField} from "../bootstrap/TextInputField";
import {NumberInputField} from "../bootstrap/NumberInputField";

export function EntityChangeContactData({entityChange}) {


    const intl = useIntl();

    return (
        <>
            <Row>
                <Col md='12'>
                    <TextInputField
                        labelId={'eaAccreditationForm.step1.address'}
                        name={'address'}
                        value={entityChange.address}
                        disabled={true}
                    />
                </Col>

            </Row>


            <Row className='align-items-end'>
                <Col md='12'>
                    <Form.Label>
                        <FormattedMessage id='eaAccreditationForm.step1.postalCode' />
                    </Form.Label>
                    <div className='d-inline d-flex align-items-center'>
                        <TextInputField
                            name={'postalCode4Digits'}
                            value={entityChange.postalCode4Digits + ' - '  + entityChange.postalCode3Digits    + ' ' + entityChange.location}
                            disabled={true}
                        />
                    </div>
                </Col>
            </Row>



            <Row>
                <Col md='8'>
                    <TextInputField
                        labelId={'eaAccreditationForm.step1.email'}
                        name={'email'}
                        value={entityChange.email}
                        disabled={true}
                    />
                </Col>

                <Col md='4'>
                    <NumberInputField
                        labelId={'eaAccreditationForm.step1.phone'}
                        name={'phone'}
                        value={entityChange.phone}
                        maxLength={9}
                        disabled={true}
                    />
                </Col>
            </Row>
            </>
    );
}
